import { BreadcrumbModule } from 'primeng/breadcrumb';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AviCoreModule } from '@avi-x/avi-core';

import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

// import { BrowserModule } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';
import { AviBusinesscaseDataDefFormComponent } from './components/businesscasedatadef-form.component';
import { AviBusinesscaseDataDefSearcherComponent } from './components/businesscasedatadef-searcher.component';
import { AviBusinesscaseDefFormComponent } from './components/businesscasedef-form.component';
import { AviBusinesscaseDefSearcherComponent } from './components/businesscasedef-searcher.component';
import { AviBusinesscaseDefIndexComponent } from './components/businesscasedef-index.component';
import { AviBusinesscaseSearcherComponent } from './components/businesscase-searcher.component';
import { AviBusinesscaseFormComponent } from './components/businesscase-form.component';
import { AviBusinesscaseIndexComponent } from './components/businesscase-index.component';
import { AviBusinesscaseViewComponent } from './components/businesscase-view.component';
import { AviDokumentModule } from '../dokument/dokument.module';
import { AviBusinesscaseActivitiesViewComponent } from './components/businesscase-activities-view.component';
import { AviBusinesscaseDokumenteViewComponent } from './components/businesscase-dokumente-view.component';
import { AviBusinesscaseFormulareViewComponent } from './components/businesscase-formulare-view.component';
import { AviBusinesscaseActionFormComponent } from './components/businesscase-action-form.component';
import { AviBusinesscaseDataDetailKorrigierenFormComponent } from './components/businesscase-formular-korrigieren-form.component';
import { AviCommentModule } from '../comment/comment.module';
import { SplitterModule } from 'primeng/splitter';
import { AviVerlaufModule } from '../verlauf/verlauf.module';
import { AviFormularModule } from '../formular/formular.module';
import { ToolbarModule } from 'primeng/toolbar';
import { AviDocuMatrixModule } from '../documatrix/documatrix.module';

@NgModule({
    imports: [
        // BrowserModule,
        FormsModule,
        CommonModule,
        AviCoreModule,
        AviDokumentModule,
        AviDocuMatrixModule,
        AviCommentModule,
        AviVerlaufModule,
        AviFormularModule,

        FlexLayoutModule,
        PanelModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ButtonModule,
        SplitButtonModule,
        InputTextModule,
        MenuModule,
        BreadcrumbModule,
        SplitterModule,
        ToolbarModule,

        NgxPermissionsModule.forChild(),

        TranslateModule
    ],
    exports: [
        AviBusinesscaseDataDefFormComponent,
        AviBusinesscaseDataDefSearcherComponent,
        AviBusinesscaseDefFormComponent,
        AviBusinesscaseDefSearcherComponent,
        AviBusinesscaseDefIndexComponent,

        AviBusinesscaseSearcherComponent,
        AviBusinesscaseFormComponent,
        AviBusinesscaseIndexComponent,
        AviBusinesscaseViewComponent,

    ],
    declarations: [
        AviBusinesscaseDataDefFormComponent,
        AviBusinesscaseDataDefSearcherComponent,
        AviBusinesscaseDefFormComponent,
        AviBusinesscaseDefSearcherComponent,
        AviBusinesscaseDefIndexComponent,

        AviBusinesscaseSearcherComponent,
        AviBusinesscaseFormComponent,
        AviBusinesscaseIndexComponent,
        AviBusinesscaseViewComponent,
        AviBusinesscaseActivitiesViewComponent,
        AviBusinesscaseDokumenteViewComponent,
        AviBusinesscaseFormulareViewComponent,
        AviBusinesscaseActionFormComponent,
        AviBusinesscaseDataDetailKorrigierenFormComponent
    ]
})
export class AviBusinesscaseModule { }
