<div class="pb-3">
    <avi-core-base-searcher
        #formularListe
        [contents-padding]="false"
        [use-router-params]="false"
        [visible-columns]="VisibleColumns"
        [hide-when-noresults]="HideWhenNoresults"
        [search-delegate]="SearchDelegate"
        [rows]="0"
        [auto-search]="true"
        [show-autofilter]="false"
        [toolbar]="false"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [lazy-loading]="true"
        [card]="false"
        (onInit)="onFormularListInit($event)"
        searcher-title="Formulare"
        [show-totalrecords]="true"
        [select-first-result]="false"
    >
    </avi-core-base-searcher>
</div>

<!-- EDIT FORMULAR DIALOG -->
<avi-core-dialog
    #FormularFormDialog
    identifier="FormularFormDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
>
    <vwbp-public-formular-form
        *ngIf="FormularMode === 'vwbp'"
        #VwbPFormularForm
        [api]="Api"
        [contents-padding]="false"
        (onSavedSuccessful)="onFormularSavedSuccessful($event)"
        [action-buttons]="formActionButtons"
        [action-buttons-readonly]="formActionButtonsReadonly"
    >
    </vwbp-public-formular-form>

    <vwbp-public-vweg-formular-form
        *ngIf="FormularMode === 'vweg'"
        #VWeGFormularForm
        [api]="Api"
        [contents-padding]="false"
        (onSavedSuccessful)="onFormularSavedSuccessful($event)"
        [action-buttons]="formActionButtons"
        [action-buttons-readonly]="formActionButtonsReadonly"
    >
    </vwbp-public-vweg-formular-form>
</avi-core-dialog>
