import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { BasePartner } from '../partner/basepartner.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { Adresse } from './adresse.model';
import { Land } from './land.model';
import { Provinz } from './provinz.model';

export class AdresseHist extends BaseModel {

    // Adresse
    @Expose()
    public get AdresseDiscriminator(): string { return this.getProperty('AdresseDiscriminator'); }
    public set AdresseDiscriminator(value: string) { this.setProperty('AdresseDiscriminator', value); }

    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }

    @Expose()
    public get verwendeAdresseUeberst(): boolean { return this.getProperty('verwendeAdresseUeberst'); }
    public set verwendeAdresseUeberst(value: boolean) { this.setProperty('verwendeAdresseUeberst', value); }

    @Expose()
    public get Encrypted(): boolean { return this.getProperty('Encrypted'); }
    public set Encrypted(value: boolean) { this.setProperty('Encrypted', value); }

    @Expose()
    public get ExterneReferenz(): string { return this.getProperty('ExterneReferenz'); }
    public set ExterneReferenz(value: string) { this.setProperty('ExterneReferenz', value); }

    @Expose()
    public get vonPartner_ID(): string { return this.getModelId('vonPartner_ID'); }
    public set vonPartner_ID(value: string) { this.setModelId('vonPartner_ID', value); }

    @Expose()
    @Type(() => BasePartner)
    public get vonPartner(): BasePartner { return this.getModel('vonPartner'); }
    public set vonPartner(value: BasePartner) { this.setModel('vonPartner', value); }

    @Expose()
    public get verwendeAdresse_ID(): string { return this.getModelId('verwendeAdresse_ID'); }
    public set verwendeAdresse_ID(value: string) { this.setModelId('verwendeAdresse_ID', value); }

    @Expose()
    @Type(() => ListType)
    public get AdressTyp(): ListType { return this.getProperty('AdressTyp'); }
    public set AdressTyp(value: ListType) { this.setProperty('AdressTyp', value); }

    @Expose()
    @Type(() => ListType)
    public get AdressSync(): ListType { return this.getProperty('AdressSync'); }
    public set AdressSync(value: ListType) { this.setProperty('AdressSync', value); }

    @Expose()
    @Type(() => ListType)
    public get Klassifizierung(): ListType { return this.getProperty('Klassifizierung'); }
    public set Klassifizierung(value: ListType) { this.setProperty('Klassifizierung', value); }

    @Expose()
    @Type(() => ListType)
    public get DomizilArt(): ListType { return this.getProperty('DomizilArt'); }
    public set DomizilArt(value: ListType) { this.setProperty('DomizilArt', value); }

    // AdresseDetail

    @Expose()
    @TransformDate()
    public get ZustandAb(): Date { return this.getProperty('ZustandAb'); }
    public set ZustandAb(value: Date) { this.setProperty('ZustandAb', value); }

    @Expose()
    @TransformDate()
    public get ZustandBis(): Date { return this.getProperty('ZustandBis'); }
    public set ZustandBis(value: Date) { this.setProperty('ZustandBis', value); }

    @Expose()
    @TransformDate()
    public get ZustandBeginn(): Date { return this.getProperty('ZustandBeginn'); }
    public set ZustandBeginn(value: Date) { this.setProperty('ZustandBeginn', value); }

    @Expose()
    @TransformDate()
    public get ZustandEnde(): Date { return this.getProperty('ZustandEnde'); }
    public set ZustandEnde(value: Date) { this.setProperty('ZustandEnde', value); }

    @Expose()
    public get Strasse(): string { return this.getProperty('Strasse'); }
    public set Strasse(value: string) { this.setProperty('Strasse', value); }

    @Expose()
    public get HausNr(): string { return this.getProperty('HausNr'); }
    public set HausNr(value: string) { this.setProperty('HausNr', value); }

    @Expose()
    public get Adresszusatz1(): string { return this.getProperty('Adresszusatz1'); }
    public set Adresszusatz1(value: string) { this.setProperty('Adresszusatz1', value); }

    @Expose()
    public get Adresszusatz2(): string { return this.getProperty('Adresszusatz2'); }
    public set Adresszusatz2(value: string) { this.setProperty('Adresszusatz2', value); }

    @Expose()
    public get Adresszusatz3(): string { return this.getProperty('Adresszusatz3'); }
    public set Adresszusatz3(value: string) { this.setProperty('Adresszusatz3', value); }

    @Expose()
    public get Postfach(): string { return this.getProperty('Postfach'); }
    public set Postfach(value: string) { this.setProperty('Postfach', value); }

    @Expose()
    public get PLZ(): string { return this.getProperty('PLZ'); }
    public set PLZ(value: string) { this.setProperty('PLZ', value); }

    @Expose()
    public get PLZZusatz(): string { return this.getProperty('PLZZusatz'); }
    public set PLZZusatz(value: string) { this.setProperty('PLZZusatz', value); }

    @Expose()
    public get Ort(): string { return this.getProperty('Ort'); }
    public set Ort(value: string) { this.setProperty('Ort', value); }

    @Expose()
    public get Landwort(): string { return this.getProperty('Landwort'); }
    public set Landwort(value: string) { this.setProperty('Landwort', value); }

    @Expose()
    public get Land_ID(): string { return this.getModelId('Land_ID'); }
    public set Land_ID(value: string) { this.setModelId('Land_ID', value); }

    @Expose()
    @Type(() => Land)
    public get Land(): Land { return this.getModel('Land'); }
    public set Land(value: Land) { this.setModel('Land', value); }

    @Expose()
    public get Provinz_ID(): string { return this.getModelId('Provinz_ID'); }
    public set Provinz_ID(value: string) { this.setModelId('Provinz_ID', value); }

    @Expose()
    @Type(() => Provinz)
    public get Provinz(): Provinz { return this.getModel('Provinz'); }
    public set Provinz(value: Provinz) { this.setModel('Provinz', value); }

    @Expose()
    @Type(() => ListType)
    public get SteuerDomizil(): ListType { return this.getProperty('SteuerDomizil'); }
    public set SteuerDomizil(value: ListType) { this.setProperty('SteuerDomizil', value); }

}
