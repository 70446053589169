import { Component } from '@angular/core';

@Component({
    selector: 'vwbp-admin-textblock-manager',
    templateUrl: './vwbp-admin-textblock-manager.component.html'
})
export class VwbPAdminTextblockManagerComponent {

    constructor() { }
}
