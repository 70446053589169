<mat-tab-group
    *ngIf="sessionController.sessions.length > 0"
    [(selectedIndex)]="sessionController.activeSessionIndex"
    (selectedTabChange)="sessionController.selectedSessionChanged($event.index)"
    class="col-lg-12 mat-tab-secondary"
    animationDuration="0ms">

    <mat-tab *ngFor="let session of sessionController.sessions; let i = index" >
        <ng-template mat-tab-label>
            <div (auxclick)="onAuxClick($event, i)" class="flex align-items-center">
                <span [pTooltip]="session.Caption" [showDelay]="500" tooltipStyleClass="session-tooltip">{{ getCaption(session) }}</span>
                <i *ngIf="session.Fixed !== true" (click)="closeSession(i)" class="material-icons tab-close-btn">close</i>
            </div>
        </ng-template>

        <ng-container *ngTemplateOutlet="sessionTemplate; context: {$implicit: session}">
        </ng-container>
    </mat-tab>

</mat-tab-group>
