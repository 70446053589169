<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

    <avi-tabView #tabView *ngIf="ModelId" width="14rem" tabview-class="tabstyle-session" orientation="vertical" [session-modul]="true">

        <avi-tabPanel title="CORE.COMMON.MODEL.CAFEMAILDEF.CAFEMAILDEF_1" id="emaildef-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-emaildef-form [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true"></avi-emaildef-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
	
    </avi-tabView>

</avi-core-page>

