import { Injectable, Inject, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route, Router, CanActivate, CanActivateChild, CanLoad, CanDeactivate } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AviAuthService, AviCommonService } from '@avi-x/avi-core';
import { VwbPRoleConst } from '@avi-x/vwbp-public';


@Injectable({ providedIn: 'root' })
export class VwbPInternalAuthGuardService implements CanActivate {

    constructor(
        private commonService: AviCommonService,
        private authService: AviAuthService,
        private oauthService: OAuthService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        let canActivate = true;

        if (!this.oauthService.hasValidAccessToken())
            canActivate = false;

        if (!this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_AJU_SCHREIBEN)
            && !this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_AJU_LESEN)
            && !this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_BEHOERDEN)
            )
            canActivate = false;

        if (!canActivate) {

            this.commonService.logWarn('canActivate is false in VwbPPublicAuthGuardService for ' + state.url);
            this.router.navigate([this.commonService.loginPath, { source: state.url }]);
        }

        return canActivate;
    }
}
