import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AviApiService, AviCommonService, AviJobService, AviListDetailConst } from '@avi-x/avi-core';
import { Location } from '@angular/common';
import { VwbPRechtstraeger, VwbPVersion } from '@avi-x/vwbp-public';
import { DialogService } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { first, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { VwbPInternRechtstraegerGesamtAktDialogComponent } from './rechtstraeger-gesamtakt-dialog.component';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'vwbp-intern-rechtstraeger-dokumente',
    template: `<avi-core-page title="" [card]="false" [collapsible]="false" [expanded]="true" [contents-padding]="true" [no-box-shadow]="true">
        <button
            pButton
            type="button"
            icon="pi pi-chevron-left"
            aria-label="Go back"
            class="p-button-secondary p-button-text mt-8p back-button"
            (click)="NavigateBack()"
        ></button>

        <div>
            <avi-crm-dokumente
                *ngIf="Model"
                [hide-keywords]="[ 'Kategorie', 'docgrp', 'docart', 'rtId' ]"
                api="/v1/intern/vwbp/document"
                api-prefix="v1/vwbp"
                [local-service]="true"
                context-type="aviita.VwbP.common.modul.partner.model.VwbPRechtstraeger"
                [context-id]="Model.Id"
                [menuitems-export]="[ exportItem ]"
            >
            </avi-crm-dokumente>
        </div>
    </avi-core-page>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternRechtstraegerDokumenteComponent implements OnInit, OnDestroy {
    SelectedVersion: VwbPVersion = null;
    Model: VwbPRechtstraeger = null;

    subParent: Subscription;

    exportItem: MenuItem = { label: this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.GESAMTAKT_BUTTON'), icon: 'pi pi-briefcase', command: () => this.PerformAction() };

    constructor(private activatedRoute: ActivatedRoute, public commonService: AviCommonService, private apiService: AviApiService, private postfachService: VwbPInternPostfachService,
        private jobService: AviJobService, public dialogService: DialogService, private location: Location, private cdr: ChangeDetectorRef) {
    }

    NavigateBack() {
        this.location.back();
    }

    ngOnInit() {
        this.subParent = this.activatedRoute.parent.data.pipe(map(w => w.id)).subscribe(async res => {
            this.Model = res;
            this.SelectedVersion = res.SelectedVersion;

            setTimeout(() => {
                this.cdr.markForCheck();
            });
        });
    }

    ngOnDestroy() {
        this.subParent.unsubscribe();
    }

    async PerformAction() {
        const docList = await this.OpenDocAuswahlDialog(
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.GESAMTAKT_HDR'),
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.GESAMTAKT_MSG'),
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.GESAMTAKT_BUTTON'));
        if (docList && docList.length > 0) {
            const data: any = {
                RechtstraegerId: this.Model.Id,
                Documents: docList.map(w => {
                    return {ArchivId: w.vonArchivDef_ID, DocId: w.ArchivDocumentID };
                })
            };
            const jobId: string = await this.apiService.post('v1/intern/vwbp/document/gesamtakt', data);

            if (jobId) {
                const jobStatus = await this.jobService.OpenJobDialog(jobId, 'VWBP.INTERN.DOKUMENTE.GESAMTAKT_BUTTON', 'v1/intern/vwbp/job', false);
                if (jobStatus === AviListDetailConst.JOB_STATUS_SUCCEEDED)
                    this.postfachService.OpenPostfach();
            }
        }
    }

    async OpenDocAuswahlDialog(header: string, message: string, acceptlabel: string): Promise<any[]> {
        const ref = this.dialogService.open(VwbPInternRechtstraegerGesamtAktDialogComponent, {
            data: {
                rechtstraegerId: this.Model.Id,
                message: message,
                acceptLabel: acceptlabel,
                formType: VwbPInternRechtstraegerGesamtAktDialogComponent
            },
            header: header,
            width: '80%',
            closable: false,
            closeOnEscape: false,
            contentStyle: { 'max-height': '90vh', 'overflow': 'auto' },
            styleClass: 'p-input-filled',
            baseZIndex: 10000
        });

        const res = await ref
            .onClose
            .pipe(tap((data: any[]) => data), first())
            .toPromise();

            ref.destroy();

        return res;
    }
}
