import { Expose } from "class-transformer";
import { IDTO } from "../shared";

export class BusinesscaseFollowupProcessInfo extends IDTO {
    @Expose()
    public FollowupProcessId: string;

    @Expose()
    public Bezeichnung: string;
}
