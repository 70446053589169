import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AviCommonService, AviApiService } from '@avi-x/avi-core';
import { MatTabNav } from '@angular/material/tabs';
import { VwbPInternUebergabeService } from '../../service/uebergabe.service';

@Component({
    selector: 'vwbp-intern-uebergabe-index',
    templateUrl: './uebergabe-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPInternUebergabeIndexComponent implements OnInit {


    navLinks = [
        { path: './mit-verantwortlicher', label: 'VWBP.INTERN.UEBERGABE_MANAGEMENT.LINKS.NORMAL' },
        { path: './abgegeben', label: 'VWBP.INTERN.UEBERGABE_MANAGEMENT.LINKS.ABGEGEBEN' },
        { path: './angefragt', label: 'VWBP.INTERN.UEBERGABE_MANAGEMENT.LINKS.ANGEFRAGT' },
        { path: './ausgenommene', label: 'VWBP.INTERN.UEBERGABE_MANAGEMENT.LINKS.RT_INAKTIV' },
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        private uebergabeService: VwbPInternUebergabeService
    ) {}

    ngOnInit() {
    }
}
