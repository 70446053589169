<p-breadcrumb *ngIf="Page !== 'searcher'" [model]="getBreadcrumbs()" [home]="bcHome"></p-breadcrumb>

<div *ngIf="Page == 'searcher'">
    <avi-core-view-panel [toggleable]="false" [header]="Title | translate" class="w-100p" [enable-panel]="EnablePanel" panel-class="mt-2">
        <avi-core-base-searcher
            #searcher
            [settingskey]="SettingsKey"
            search-delegate="custompropcollectiondef"
            [default-sort-data]="[{ field: 'Bezeichnung1', order: 1 }]"
            (onInit)="onSearcherInit($event)"
            (onError)="onSearcherError($event)"
            [use-router-params]="false"
            (onDoubleClick)="onDoubleClickSearcher($event)"
            [rows]="50"
            [auto-search]="true"
            [show-autofilter]="true"
            [toolbar]="true"
            selectionMode="single"
            [toolbar-show-searchinput]="false"
            [toolbar-show-advanced]="false"
            [toolbar-show-columnselection]="false"
            [toolbar-show-inactivebutton]="false"
            [toolbar-show-generic-export]="true"
            [disable-inactive-filter]="true"
            [lazy-loading]="true"
            (onSelect)="onSelectionChanged($event)"
            [card]="!EnablePanel"
            [contents-padding]="EnablePanel"
            [searcher-title]="Title"
            [show-totalrecords]="false"
            [select-first-result]="false"
            [add-crud-menuitems]="true"
            [toolbar-show-createbutton]="true"
            crud-base-right="CAFCustomPropCollectionDef"
            (onCreateObject)="createObject()"
            (onEditObject)="editObject($event)"
            (onDeleteObject)="deleteObject($event)"
        >
        </avi-core-base-searcher>    

    </avi-core-view-panel>
</div>

<div *ngIf="Page == 'custompropcollectiondef'">
    <avi-custompropcollectiondef-view
        [model-id]="DetailModelId"
        (onTestCustomPropCollectionDef)="onTestCustomPropCollectionDef($event)"
    >
    </avi-custompropcollectiondef-view>
</div>

<div *ngIf="Page == 'collection'">
    <avi-core-view-panel [toggleable]="false" header="Attributsammlung" class="w-100p">
        <avi-custompropcollection-form
            [title]="null"
            [collectiondef-id]="PropCollectionDefId"
            [context-id]="PropCollectionContextId"
            [model-id]="PropCollectionModelId"            
            [readonly]="false"
            [contents-padding]="false"
            [show-buttons]="false"
        >
        </avi-custompropcollection-form>
    </avi-core-view-panel>
</div>