import { DividerModule } from 'primeng/divider';
import { FileUploadModule } from 'primeng/fileupload';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';

import {MatIconModule} from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';

// avi-x und vwbp-public
import { AviCommentModule, AviEmailModule } from '@avi-x/avi-crm';
import { AviCoreModule, AviSysMessageSearcherComponent, ParentIdResolver } from '@avi-x/avi-core';

// PrimeNG
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { EditorModule } from 'primeng/editor';

// VwbP Admin
import { VwbPAdminDatenImportComponent } from './import/components/daten-import/daten-import.component';
import { VwbPAdminIndexComponent } from './common/components/vwbp-admin-index/vwbp-admin-index.component';
import { VwbPAdminAuthGuardService } from './auth/services/vwbp-admin-auth-guard.service';
import { VwbPAdminTextblockManagerComponent } from './textblock/components/vwbp-admin-textblock-manager/vwbp-admin-textblock-manager.component';
import { VwbPAdminStartComponent } from './common/components/vwbp-admin-start/vwbp-admin-start.component';
import { VwbPAdminBehoerdenSucheComponent } from './behoerdensuche/components/behoerden-suche/behoerden-suche.component';
import { VwbPAdminVorlageDefFormComponent } from './vorlagen/components/vorlagedef-form/vorlagedef-form.component';
import { VwbPAdminVorlageDefSearcherComponent } from './vorlagen/components/vorlagedef-searcher/vorlagedef-searcher.component';
import { VwbPAdminVorlageDefIndexComponent } from './vorlagen/components/vorlagedef-index/vorlagedef-index.component';
import { VwbPAdminKeywordDefFormComponent } from './keyworddefs/components/keyworddef-form/keyworddef-form.component';
import { VwbPAdminKeywordDefSearcherComponent } from './keyworddefs/components/keyworddef-searcher/keyworddef-searcher.component';
import { VwbPAdminXMLImportJobSearcherComponent } from './xmlimport/components/xmlimport-searcher/xmlimport-searcher.component';
import { VwbPAdminXMLImportJobDetailsComponent } from './xmlimport/components/xmlimport-details/xmlimport-details.component';
import { VwbPAdminRechtsformDefSearcherComponent } from './rechtsformdefs/components/rechtsformdef-searcher/rechtsformdef-searcher.component';
import { VwbPAdminRechtsformDefFormComponent } from './rechtsformdefs/components/rechtsformdef-form/rechtsformdef-form.component';
import { VwbPAdminTechInfoViewComponent } from './techinfo/components/techinfo-view/techinfo-view.component';
import { VwbPAdminEmailDefSearcherComponent } from './emaildefs/components/emaildef-searcher/emaildef-searcher.component';
import { VwbPAdminEmailDefFormComponent } from './emaildefs/components/emaildef-form/emaildef-form.component';
import { VwbPAdminJobDefIndexComponent } from './jobs/vwbp-admin-jobdef-index.component';
import { VwbPAdminJobDefDefComponent } from './jobs/vwbp-admin-jobdef-def.component';
import { VwbPAdminJobIndexComponent } from './jobs/vwbp-admin-job-index.component';
import { VwbPAdminJobComponent } from './jobs/vwbp-admin-job.component';
import { VwbPAdminJobLoggingComponent } from './jobs/vwbp-admin-joblogging.component';

// AviCore
import { AviJobDefSearcherComponent } from 'libs/avi-core/src/lib/components/joblogging/jobdef-searcher.component';
import { AviJobSearcherComponent } from '@avi-x/avi-core';

const routes: Routes = [

    { path: '', redirectTo: 'admin/vwbp', pathMatch: 'full' },

    {
        path: 'admin/vwbp',
        canActivate: [VwbPAdminAuthGuardService],
        component: VwbPAdminIndexComponent,
        children: [
            { path: '', redirectTo: 'behoerdensuche', pathMatch: 'full' },

            /*            {
                            path: 'start',
                            component: VwbPAdminStartComponent,
                            resolve: { id: ParentIdResolver },
                            data: { 'submodul': true }
                        },*/
            {
                path: 'resources',
                component: VwbPAdminTextblockManagerComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true, 'apiprefix': 'v1/intern/vwbp' },               
            },
            {
                path: 'behoerdensuche',
                component: VwbPAdminBehoerdenSucheComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            },
            {
                path: 'document',
                component: VwbPAdminVorlageDefIndexComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true },
                children: [
                    { path: '', redirectTo: 'vorlagedefs', pathMatch: 'full' },
                    {
                        path: 'vorlagedefs',
                        component: VwbPAdminVorlageDefSearcherComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    }
                ]
            },
            {
                path: 'keyworddefs',
                component: VwbPAdminKeywordDefSearcherComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            },
            {
                path: 'rechtsformdefs',
                component: VwbPAdminRechtsformDefSearcherComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            },
            {
                path: 'emaildef',
                component: VwbPAdminEmailDefSearcherComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true, 'apiprefix': 'v1/intern/vwbp' }
            },
            {
                path: 'xmlimport',
                component: VwbPAdminXMLImportJobSearcherComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            },
            // {
            //     path: 'import',
            //     component: VwbPAdminDatenImportComponent,
            //     resolve: { id: ParentIdResolver },
            //     data: { 'submodul': true }
            // },
            {
                path: 'sysmessages',
                component: AviSysMessageSearcherComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true, 'apiprefix': 'v1/intern/vwbp' }
            },
            {
                path: 'jobdef',
                component: AviJobDefSearcherComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true, 'BaseUrl': 'v1/intern/vwbp/job/def', 'ApiPrefix': 'v1/vwbp', 'UseRouting': true, 'RouterPrefix': '/admin/vwbp' },
                // children: [
                //     { path: '', redirectTo: 'jobdef-index/:id', pathMatch: 'full' },
                //     {
                //         path: 'jobdef-index/:id',
                //         component: AviJobIndexComponent,
                //         resolve: { id: ParentIdResolver },
                //         data: { 'submodul': true }
                //     }
                // ]
            }
            ,
            {
                path: 'jobdef-index/:id',
                component: VwbPAdminJobDefIndexComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true },
                children: [
                    { path: '', redirectTo: 'def', pathMatch: 'full' },
                    {
                        path: 'def',
                        component: VwbPAdminJobDefDefComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'jobs',
                        component: AviJobSearcherComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true, 'BaseUrl': 'v1/intern/vwbp/job', 'ApiPrefix': 'v1/vwbp', 'UseRouting': true, 'RouterPrefix': '/admin/vwbp' }
                    }
                ]
            },
            {
                path: 'job-index/:id',
                component: VwbPAdminJobIndexComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true },
                children: [
                    { path: '', redirectTo: 'job', pathMatch: 'full' },
                    {
                        path: 'job',
                        component: VwbPAdminJobComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'job-logging',
                        component: VwbPAdminJobLoggingComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true, 'BaseUrl': 'v1/intern/vwbp/job/logging', 'ApiPrefix': 'v1/vwbp' }
                    }
                ]
            },
            {
                path: 'techinfo',
                component: VwbPAdminTechInfoViewComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            }
        ]
    }

];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,

        AviCoreModule,
        AviCommentModule,
        AviEmailModule,

        ButtonModule,
        ToggleButtonModule,
        ToolbarModule,
        MessageModule,
        CheckboxModule,
        PanelModule,
        ConfirmDialogModule,
        DropdownModule,
        InputTextareaModule,
        InputTextModule,
        MultiSelectModule,
        TooltipModule,
        EditorModule,
        FileUploadModule,
        DividerModule,

        MatTabsModule,
        MatIconModule,

        RouterModule.forChild(routes),
        TranslateModule
    ],
    exports: [

    ],
    declarations: [
        VwbPAdminIndexComponent,
        VwbPAdminStartComponent,
        VwbPAdminTextblockManagerComponent,
        VwbPAdminBehoerdenSucheComponent,
        VwbPAdminDatenImportComponent,
        VwbPAdminTechInfoViewComponent,

        VwbPAdminVorlageDefIndexComponent,
        VwbPAdminVorlageDefFormComponent,
        VwbPAdminVorlageDefSearcherComponent,

        VwbPAdminKeywordDefFormComponent,
        VwbPAdminKeywordDefSearcherComponent,

        VwbPAdminRechtsformDefFormComponent,
        VwbPAdminRechtsformDefSearcherComponent,

        VwbPAdminXMLImportJobSearcherComponent,
        VwbPAdminXMLImportJobDetailsComponent,

        VwbPAdminEmailDefSearcherComponent,
        VwbPAdminEmailDefFormComponent,

        VwbPAdminJobDefIndexComponent,
        VwbPAdminJobIndexComponent,
        VwbPAdminJobDefDefComponent,
        VwbPAdminJobComponent,
        VwbPAdminJobLoggingComponent
    ]
})
export class AviVwbPAdminModule { }
