<div class="pt-3">
    <avi-core-view-panel header="Server Information">
        <div *ngIf="ServerInfo"
            class="grid m-0">

            <div class="col-12 summary-header">Appserver</div>
            <div class="col-12 md:col-4 summary-label">Version</div>
            <div class="col-12 md:col-8">{{ ServerInfo.ServerVersion }}</div>
            <div class="col-12 md:col-4 summary-label">Build Zeit</div>
            <div class="col-12 md:col-8">{{ ServerInfo.ServerBuildDate }} {{ ServerInfo.ServerBuildTime }}</div>


            <div class="col-12 summary-header">Datenbank</div>
            <div class="col-12 md:col-4 summary-label">Server</div>
            <div class="col-12 md:col-8">{{ ServerInfo.DBServer }}</div>
            <div class="col-12 md:col-4 summary-label">Katalog</div>
            <div class="col-12 md:col-8">{{ ServerInfo.DBName }}</div>
            <div class="col-12 md:col-4 summary-label">Timeout</div>
            <div class="col-12 md:col-8">{{ ServerInfo.DBTimeout }}</div>

            <div class="col-12 summary-header">Schnittstellen</div>
            <div class="col-12 md:col-4 summary-label">HR Api URL</div>
            <div class="col-12 md:col-8">{{ ServerInfo.HrRestApiBaseUrl }}</div>
            <div class="col-12 md:col-4 summary-label">eSignatur Api URL</div>
            <div class="col-12 md:col-8">{{ ServerInfo.ESignaturRestApiBaseUrl }}</div>
            <div class="col-12 md:col-4 summary-label">eSignatur Profil</div>
            <div class="col-12 md:col-8">{{ ServerInfo.ESignaturProfilId }}</div>
            <div class="col-12 md:col-4 summary-label">eSignatur TypL</div>
            <div class="col-12 md:col-8">{{ ServerInfo.ESignaturTyp }}</div>
            <div class="col-12 md:col-4 summary-label">eSignatur Version</div>
            <div class="col-12 md:col-8">{{ ServerInfo.ESignaturVersion }}</div>

        </div>

    </avi-core-view-panel>

    <avi-core-view-panel header="Rechtsträger Technische Daten">

        <div fxLayout="row"
            fxLayoutGap="1em"
            fxLayoutAlign="space-around end"
            class="p-2">
            <div class="pl-4"
                fxFlex>
                <form (ngSubmit)="searchButtonPressed()"
                    #form="ngForm"
                    autocomplete="off"
                    name="lastpass-disable-search">
                    <label class="label-nonmd">Rechtsträger Id / Interne Id / Registernummer</label>
                    <input pInputText
                        style="width: 100%;"
                        [(ngModel)]="RechtstraegerId"
                        type="text"
                        name="SearchValue"
                        autocomplete="off" />
                </form>
            </div>
            <!-- <div class="pl-4" fxFlex>
            <label class="label-nonmd">Rechtsträger Id / Interne Id / Registernummer</label>
            <input
                pInputText
                style="width: 100%;"
                [(ngModel)]="RechtstraegerId"
                type="text"
                autocomplete="off"
            />
        </div> -->

            <div fxFlex="initial">
                <button pButton
                    type="button"
                    label="Suchen"
                    icon="pi pi-search"
                    aviThrottledClick
                    (throttledClick)="searchButtonPressed()"></button>
            </div>

        </div>

        <div class="tech-searchers">
            <h2 *ngIf="rtListe?.length > 0"
                class="p-2 pl-3 summary-header">Rechtsträger</h2>
            <avi-core-base-searcher #rtSearcher
                [no-word-wrap]="false"
                [use-router-params]="false"
                [hide-when-noresults]="true"
                [search-delegate]="SearchRTDelegate"
                [rows]="0"
                [auto-search]="true"
                [show-autofilter]="false"
                [toolbar]="false"
                [toolbar-show-searchinput]="false"
                [toolbar-show-advanced]="false"
                [lazy-loading]="true"
                [card]="false"
                searcher-title="Rechtsträger"
                [show-totalrecords]="false"
                [select-first-result]="false">
            </avi-core-base-searcher>

            <h2 *ngIf="rtDetListe?.length > 0"
                class="p-2 pl-3 summary-header">Details</h2>
            <avi-core-base-searcher #rtDetSearcher
                [no-word-wrap]="false"
                [use-router-params]="false"
                [hide-when-noresults]="true"
                [search-delegate]="SearchRTDetDelegate"
                [rows]="0"
                [auto-search]="true"
                [show-autofilter]="false"
                [toolbar]="false"
                [toolbar-show-searchinput]="false"
                [toolbar-show-advanced]="false"
                [lazy-loading]="true"
                [card]="false"
                searcher-title="Details"
                [show-totalrecords]="false"
                [select-first-result]="false">
            </avi-core-base-searcher>

            <h2 *ngIf="rtVerListe?.length > 0"
                class="p-2 pl-3 summary-header">Versionen</h2>
            <avi-core-base-searcher #rtVerSearcher
                [no-word-wrap]="false"
                [use-router-params]="false"
                [hide-when-noresults]="true"
                [search-delegate]="SearchRTVerDelegate"
                [rows]="0"
                [auto-search]="true"
                [show-autofilter]="false"
                [toolbar]="false"
                [toolbar-show-searchinput]="false"
                [toolbar-show-advanced]="false"
                [lazy-loading]="true"
                [card]="false"
                searcher-title="Versionen"
                [show-totalrecords]="false"
                [select-first-result]="false"
                (onSelect)="onSelectVersion()">
            </avi-core-base-searcher>

            <h2 *ngIf="rtFormListe?.length > 0"
                class="p-2 pl-3 summary-header">Formulare</h2>
            <avi-core-base-searcher #rtFormSearcher
                [no-word-wrap]="false"
                [use-router-params]="false"
                [hide-when-noresults]="true"
                [search-delegate]="SearchRTFormDelegate"
                [rows]="0"
                [auto-search]="true"
                [show-autofilter]="false"
                [toolbar]="false"
                [toolbar-show-searchinput]="false"
                [toolbar-show-advanced]="false"
                [lazy-loading]="true"
                [card]="false"
                searcher-title="Formulare"
                [show-totalrecords]="false"
                [select-first-result]="false">
            </avi-core-base-searcher>
        </div>

    </avi-core-view-panel>
</div>
