import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AviAbstractModelSearcherComponent, AviApiService, AviBaseSearcherComponent, AviCommonService, typed_nameof } from '@avi-x/avi-core';
import { CustomPropDefSearchResultDto } from '@avi-x/avi-dto/system/custompropdefsearchresultdto.model';
import { AviCustomPropDefFormComponent } from './custompropdef-form.component';

const nameof = (nameFunction: ((obj: CustomPropDefSearchResultDto) => any)) => typed_nameof<CustomPropDefSearchResultDto>(nameFunction);

@Component({
    selector: 'avi-custompropdef-searcher',
    templateUrl: './custompropdef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviCustomPropDefSearcherComponent extends AviAbstractModelSearcherComponent {

    ClassName = 'CORE.COMMON.MODEL.CAFCUSTOMPROPDEF';
    SettingsKey = 'settings.searcher.custompropdef';
    BaseUrl = 'custompropdef';
    FormType = AviCustomPropDefFormComponent;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
        protected sanitizer: DomSanitizer,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
        searcher.beginInit();

        searcher.addIntegerColumn(nameof(c => c.Sequenz), 'Sequenz');
        searcher.addTextColumn(nameof(c => c.PropertyName), 'Name');
        searcher.addListTypeColumn(nameof(c => c.PropertyType), 'Typ', 'property_type');
        searcher.addTextColumn(nameof(c => c.Bezeichnung1), 'Bezeichnung');
        searcher.addIntegerColumn(nameof(c => c.Columns), 'Spalten');

        searcher.addListTypeColumn(nameof(c => c.Visibility), 'Sichtbarkeit', 'caf_sichtbarkeit');
        searcher.addListTypeColumn(nameof(c => c.Mandatory), 'Pflichtfeld', 'janein');
        searcher.addListTypeColumn(nameof(c => c.IsReadOnlyInGui), 'Readonly', 'janein');

        searcher.addIntegerColumn(nameof(c => c.RadioGroupIndex), 'Checkbox-GruppeID');
        searcher.addTextColumn(nameof(c => c.DefaultAttributPath), 'Attribut-Pfad');

        searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        if (this.ContextId) {
            const cfg =  { ...searchConfig };
            cfg.Query = searchValue;
            cfg.ContextId = this.ContextId;
            const res = await this.apiService.postModelList(CustomPropDefSearchResultDto, `custompropdef/search`, cfg);
            return res;
        }

        return null;
    }
}
