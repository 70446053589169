import { BaseModelState } from '@avi-x/avi-dto/shared/basemodel.model';
import { Expose } from 'class-transformer';

export class EmailConfigTest extends BaseModelState {
    @Expose()
    public get TestRecipient(): string { return this.getProperty('TestRecipient'); }
    public set TestRecipient(value: string) { this.setProperty('TestRecipient', value); }

    @Expose()
    public get TestSubject(): string { return this.getProperty('TestSubject'); }
    public set TestSubject(value: string) { this.setProperty('TestSubject', value); }

    @Expose()
    public get TestMessage(): string { return this.getProperty('TestMessage'); }
    public set TestMessage(value: string) { this.setProperty('TestMessage', value); }
}
