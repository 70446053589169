import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RTVerMode, VwbPInternRechtstraegerAdresseSearcherComponent } from './rechtstraeger-adresse-searcher.component';

@Component({
    template: `
        <avi-core-page title="" [card]="false" [collapsible]="false" [expanded]="true" [contents-padding]="true" [no-box-shadow]="true">

            <div class="card-contents">{{ message }}</div>

            <vwbp-intern-rechtstraeger-adresse-searcher
                *ngIf="modelIds"
                #searcher
                [Mode]="mode"
                [ModelIds]="modelIds"
            ></vwbp-intern-rechtstraeger-adresse-searcher>

            <div class="grid m-0 justify-content-end">
                    <button
                        pButton
                        type="button"
                        icon="pi pi-check"
                        [label]="acceptLabel"
                        [disabled]="GetSelectedRows().length === 0"
                        (click)="Close()"
                        class="w-100p-m m ml-2 p-button-primary"
                    ></button>
                    <button
                        pButton
                        type="button"
                        icon="pi pi-times"
                        [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
                        [title]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
                        (click)="Abbrechen()"
                        class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                    ></button>
            </div>

        </avi-core-page>
    `
})
export class VwbPInternRechtstraegerAdresseDialogComponent implements OnInit {
    @ViewChild('searcher', { static: false })
    public searcher: VwbPInternRechtstraegerAdresseSearcherComponent;

    mode: RTVerMode = RTVerMode.RECHTSTRAEGER;

    modelIds: string[] = null;
    message: string;
    acceptLabel: string = 'OK';

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, public cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.modelIds = this.config.data.modelIds;
        this.message = this.config.data.message;
        this.acceptLabel = this.config.data.acceptLabel;
        this.mode = this.config.data.mode;
    }

    GetSelectedRows() {
        return this.searcher?.searcher.SelectedRows ?? [];
    }

    Close() {
        this.ref.close(this.GetSelectedRows().map(w => w.Id));
    }

    Abbrechen() {
        this.ref.close(null);
    }
}
