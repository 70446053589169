<avi-core-view-panel header="Partnerrollen" [enable-panel]="!Card">
    <div
        *ngIf="PartnerRolleDefs && PartnerRolleDefs.length > 1"
        class="pt-3 pl-3"
    >
        <mat-chip-list [multiple]="true" selectable>
            <mat-chip
                *ngFor="let item of PartnerRolleDefs"
                [removable]="false"
                [selected]="item.selected"
                (click)="togglePartnerRolleDef(item)"
            >
                {{ item.pdef }}
            </mat-chip>
        </mat-chip-list>
    </div>

    <avi-core-base-searcher
        #searcher
        settingskey="settings.searcher.partnerrollen"
        [use-router-params]="false"
        [toolbar]="true"
        [toolbar-show-searchinput]="false"
        [toolbar-show-inactivebutton]="true"
        [card]="Card"
        [rows]="0"
        [lazy-loading]="true"
        [search-delegate]="SearchDelegate"
        field-name-aktiv="RolleAktiv"
        (onInit)="onInit($event)"
    >
    </avi-core-base-searcher>
</avi-core-view-panel>
