import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviListDetailConst, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BusinesscaseDataDetailFormularChangesResultDto, BusinesscaseDataDetailKorrigierenDto } from '@avi-x/avi-dto/businesscase/businesscasedatadetailkorrigierendto.model';

const nameof = (nameFunction: ((obj: BusinesscaseDataDetailKorrigierenDto) => any)) => typed_nameof<BusinesscaseDataDetailKorrigierenDto>(nameFunction);

@Component({
    selector: 'avi-businesscase-statuswechsel-form',
    template: `
    <div *ngIf="Model && Model.IsCorrection" class="p-3">

        <div *ngIf="Model.NumVersions > 1" class="pb-3">
            <span class="mr-2">Änderungen im Vergleich zu</span>
            <p-dropdown
                [showClear]="false"
                [readonly]="false"
                [options]="VersionOptions"
                [required]="true"
                [itemSize]="30"
                [ngModel]="Model.SelectedVersion"
                (ngModelChange)="SetSelectedVersion($event)"
            >
            </p-dropdown>
        </div>

        <div *ngIf="HasChanges">
            <div class="pb-1 formchanges-caption">Änderungen:</div>
            <table class="formchanges-list">
                <tr>
                    <th>Attribut</th>
                    <th>Alter Wert</th>
                    <th>Neuer Wert</th>
                </tr>
                <tr *ngFor="let change of Model.FormChanges">
                    <td>{{ change.Field }}</td>
                    <td>{{ change.OldValue }}</td>
                    <td>{{ change.NewValue }}</td>
                    </tr>
            </table>
        </div>
        <div class="formchanges-caption" *ngIf="!HasChanges">
            Es wurden keine Änderungen festgestellt!
        </div>
    </div>
    
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    styleUrls: ['./businesscase-formular-korrigieren-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseDataDetailKorrigierenFormComponent extends AviAbstractModelFormComponent<BusinesscaseDataDetailKorrigierenDto> {  
    ClassName = 'CORE.COMMON.MODEL.CAFSTATUSWECHSEL';
    BaseUrl = 'businesscase';
    ClassType = BusinesscaseDataDetailKorrigierenDto;
    FormType = AviBusinesscaseDataDetailKorrigierenFormComponent;

    HasChanges = false;
    VersionOptions: any[] = [];

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFBusinesscase';
    }

    ngOnInit() {
        if (this.config && this.config.data) {
            if (this.config.data) {
                this.Model = this.config.data.model;
                this.Card = false;
                this.ContentsPadding = false;
                this.actionButtons = [...this.actionButtons, ...this.abbrechenButton];
                this.actionButtonsReadonly = [...this.actionButtonsReadonly, ...this.abbrechenButtonReadonly];
                this.isEditMode = true;
            }
        }

        this.initFields();

        if (this.Model) {
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Internal), this.Model.AlwaysInternal === true);
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Bemerkung), this.Model.IsCorrection === true);
        }

        this.HasChanges = this.Model && this.Model.FormChanges?.length > 0;

        setTimeout(() => this.RefreshEditors());
    }

    async saveModel(model: BusinesscaseDataDetailKorrigierenDto) {
        if (this.ref)
            this.ref.close(model);
    }

    public RefreshEditors() {
        this.Form?.RefreshEditors();
    }

    async SetSelectedVersion(version: number) {
        this.Model.SelectedVersion = version;

        const formChanges = await this.apiService.postModel(BusinesscaseDataDetailFormularChangesResultDto, `businesscase/data/formular/changes`, { vonBusinesscaseData_ID: this.Model.BusinessDataId, FormData: this.Model.FormData, Versionnumber: this.Model.SelectedVersion });
        if (formChanges) {
            this.Model.FormChanges = formChanges.FormChanges;
            this.Model.Bemerkung = formChanges.Bemerkung;
            this.HasChanges = this.Model.FormChanges?.length > 0;
            this.cdr.markForCheck();
        }
    }
    
    initFields() {
        if (this.Model.NumVersions > 0) {
            this.VersionOptions = Array.from(Array(this.Model.NumVersions).keys()).map(w => {
                return { label: `Version ${w+1}`, value: w+1};
            });
        }
      
        this.Fields.push(this.formFieldService.CreateRichText(nameof(c => c.Bemerkung), 'CORE.COMMON.MODEL.CAFSTATUSWECHSEL.BEMERKUNG', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.Internal), '', 'CORE.COMMON.MODEL.CAFBUSINESSCASE.INTERNAL', false).setMDSizeFull());
    }
}
