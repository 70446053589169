import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { RahmenPartner } from '../partnerdef/rahmenpartner.model';
import { Sprache } from '../system/sprache.model';
import { BasePartnerExtension } from '../partner/basepartnerextension.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class BasePartner extends BaseModel {
    @Expose()
    public get PartnerType(): string { return this.getProperty('PartnerType'); }
    public set PartnerType(value: string) { this.setProperty('PartnerType', value); }

    @Expose()
    @TransformDate()
    public get ZustandAb(): Date { return this.getProperty('ZustandAb'); }
    public set ZustandAb(value: Date) { this.setProperty('ZustandAb', value); }

    @Expose()
    public get ExterneReferenz(): string { return this.getProperty('ExterneReferenz'); }
    public set ExterneReferenz(value: string) { this.setProperty('ExterneReferenz', value); }

    @Expose()
    public get ExterneId(): string { return this.getProperty('ExterneId'); }
    public set ExterneId(value: string) { this.setProperty('ExterneId', value); }

    @Expose()
    public get PartnerNr(): string { return this.getProperty('PartnerNr'); }
    public set PartnerNr(value: string) { this.setProperty('PartnerNr', value); }

    @Expose()
    public get Encrypted(): boolean { return this.getProperty('Encrypted'); }
    public set Encrypted(value: boolean) { this.setProperty('Encrypted', value); }

    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get DomizilAdresseGUID(): string { return this.getProperty('DomizilAdresseGUID'); }
    public set DomizilAdresseGUID(value: string) { this.setProperty('DomizilAdresseGUID', value); }

    @Expose()
    public get Name(): string { return this.getProperty('Name'); }
    public set Name(value: string) { this.setProperty('Name', value); }

    @Expose()
    public get Suchbegriff(): string { return this.getProperty('Suchbegriff'); }
    public set Suchbegriff(value: string) { this.setProperty('Suchbegriff', value); }

    @Expose()
    public get ZusatzName(): string { return this.getProperty('ZusatzName'); }
    public set ZusatzName(value: string) { this.setProperty('ZusatzName', value); }

    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }

    @Expose()
    public get CustomPropXML(): string { return this.getProperty('CustomPropXML'); }
    public set CustomPropXML(value: string) { this.setProperty('CustomPropXML', value); }

    @Expose()
    public get vonRahmenPartner_ID(): string { return this.getModelId('vonRahmenPartner_ID'); }
    public set vonRahmenPartner_ID(value: string) { this.setModelId('vonRahmenPartner_ID', value); }

    @Expose()
    @Type(() => RahmenPartner)
    public get vonRahmenPartner(): RahmenPartner { return this.getModel('vonRahmenPartner'); }
    public set vonRahmenPartner(value: RahmenPartner) { this.setModel('vonRahmenPartner', value); }

    @Expose()
    public get Sprache_ID(): string { return this.getModelId('Sprache_ID'); }
    public set Sprache_ID(value: string) { this.setModelId('Sprache_ID', value); }

    @Expose()
    @Type(() => Sprache)
    public get Sprache(): Sprache { return this.getModel('Sprache'); }
    public set Sprache(value: Sprache) { this.setModel('Sprache', value); }

    @Expose()
    public get BasePartnerExtension_ID(): string { return this.getModelId('BasePartnerExtension_ID'); }
    public set BasePartnerExtension_ID(value: string) { this.setModelId('BasePartnerExtension_ID', value); }

    @Expose()
    @Type(() => BasePartnerExtension)
    public get BasePartnerExtension(): BasePartnerExtension { return this.getModel('BasePartnerExtension'); }
    public set BasePartnerExtension(value: BasePartnerExtension) { this.setModel('BasePartnerExtension', value); }

    @Expose()
    @Type(() => ListType)
    public get PartnerArt(): ListType { return this.getProperty('PartnerArt'); }
    public set PartnerArt(value: ListType) { this.setProperty('PartnerArt', value); }

    @Expose()
    @Type(() => ListType)
    public get VerantwortlichkeitenManuell(): ListType { return this.getProperty('VerantwortlichkeitenManuell'); }
    public set VerantwortlichkeitenManuell(value: ListType) { this.setProperty('VerantwortlichkeitenManuell', value); }

    @Expose()
    @Type(() => ListType)
    public get PVSPersBesteuerung(): ListType { return this.getProperty('PVSPersBesteuerung'); }
    public set PVSPersBesteuerung(value: ListType) { this.setProperty('PVSPersBesteuerung', value); }

    @Expose()
    @Type(() => ListType)
    public get PflichtCode(): ListType { return this.getProperty('PflichtCode'); }
    public set PflichtCode(value: ListType) { this.setProperty('PflichtCode', value); }

}
