import { IDTO } from './../shared/idto.model';
import { Expose } from "class-transformer";

export class ScriptDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;

    @Expose()
	public Bezeichnung: string;

    @Expose()
	public Script: string;
}