import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';

export class VwbPSchwaerzenAntragDaten extends BaseModel {
    // VwbPSchwaerzenAntrag
    @Expose()
    @Type(() => ListType)
    public get WBPartnerTyp(): ListType { return this.getProperty('WBPartnerTyp'); }
    public set WBPartnerTyp(value: ListType) { this.setProperty('WBPartnerTyp', value); }

    @Expose()
    @Type(() => Date)
    public get EntscheidAm(): Date { return this.getProperty('EntscheidAm'); }
    public set EntscheidAm(value: Date) { this.setProperty('EntscheidAm', value); }

    @Expose()
    @TransformDate()
    public get Rechtsmittelfrist(): Date { return this.getProperty('Rechtsmittelfrist'); }
    public set Rechtsmittelfrist(value: Date) { this.setProperty('Rechtsmittelfrist', value); }

    @Expose()
    @TransformDate()
    public get RechtskraftAm(): Date { return this.getProperty('RechtskraftAm'); }
    public set RechtskraftAm(value: Date) { this.setProperty('RechtskraftAm', value); }

    @Expose()
    @Type(() => Date)
    public get InformiertAm(): Date { return this.getProperty('InformiertAm'); }
    public set InformiertAm(value: Date) { this.setProperty('InformiertAm', value); }

    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get vonRechtstraeger_ID(): string { return this.getModelId('vonRechtstraeger_ID'); }
    public set vonRechtstraeger_ID(value: string) { this.setModelId('vonRechtstraeger_ID', value); }

    @Expose()
    @Type(() => ListType)
    public get Status(): ListType { return this.getProperty('Status'); }
    public set Status(value: ListType) { this.setProperty('Status', value); }

    @Expose()
    @Type(() => ListType)
    public get Entscheidung(): ListType { return this.getProperty('Entscheidung'); }
    public set Entscheidung(value: ListType) { this.setProperty('Entscheidung', value); }

    @Expose()
    @Type(() => ListType)
    public get Begruendung(): ListType { return this.getProperty('Begruendung'); }
    public set Begruendung(value: ListType) { this.setProperty('Begruendung', value); }

    @Expose()
    @Type(() => ListType)
    public get FristablaufGrund(): ListType { return this.getProperty('FristablaufGrund'); }
    public set FristablaufGrund(value: ListType) { this.setProperty('FristablaufGrund', value); }

    @Expose()
    @Type(() => ListType)
    public get AntragAktiv(): ListType { return this.getProperty('AntragAktiv'); }
    public set AntragAktiv(value: ListType) { this.setProperty('AntragAktiv', value); }

    @Expose()
    @Type(() => Date)
    public get AntragKontrolliertAm(): Date { return this.getProperty('AntragKontrolliertAm'); }
    public set AntragKontrolliertAm(value: Date) { this.setProperty('AntragKontrolliertAm', value); }

    @Expose()
    public get AntragKontrollieren(): boolean { return this.getProperty('AntragKontrollieren'); }
    public set AntragKontrollieren(value: boolean) { this.setProperty('AntragKontrollieren', value); }

    // JSON
    @Expose()
    public get Name(): string { return this.getProperty('Name'); }
    public set Name(value: string) { this.setProperty('Name', value); }

    @Expose()
    public get Land(): string { return this.getModelId('Land'); }
    public set Land(value: string) { this.setProperty('Land', value); }

    @Expose()
    public get Titel(): string { return this.getProperty('Titel'); }
    public set Titel(value: string) { this.setProperty('Titel', value); }

    @Expose()
    public get Vorname(): string { return this.getProperty('Vorname'); }
    public set Vorname(value: string) { this.setProperty('Vorname', value); }

    @Expose()
    public get Nationalitaet1(): string { return this.getModelId('Nationalitaet1'); }
    public set Nationalitaet1(value: string) { this.setModelId('Nationalitaet1', value); }

    @Expose()
    public get Nationalitaet2(): string { return this.getModelId('Nationalitaet2'); }
    public set Nationalitaet2(value: string) { this.setModelId('Nationalitaet2', value); }

    @Expose()
    public get Nationalitaet3(): string { return this.getModelId('Nationalitaet3'); }
    public set Nationalitaet3(value: string) { this.setModelId('Nationalitaet3', value); }

    @Expose()
    public get Nationalitaet4(): string { return this.getModelId('Nationalitaet4'); }
    public set Nationalitaet4(value: string) { this.setModelId('Nationalitaet4', value); }

    @Expose()
    @TransformDate()
    public get Geburtsdatum(): Date { return this.getProperty('Geburtsdatum'); }
    public set Geburtsdatum(value: Date) { this.setProperty('Geburtsdatum', value); }

    @Expose()
    @TransformDate()
    public get Gruendungsdatum(): Date { return this.getProperty('Gruendungsdatum'); }
    public set Gruendungsdatum(value: Date) { this.setProperty('Gruendungsdatum', value); }

    @Expose()
    public get Rechtsform(): string { return this.getModelId('Rechtsform'); }
    public set Rechtsform(value: string) { this.setModelId('Rechtsform', value); }

    @Expose()
    public get RegisterNummer(): string { return this.getProperty('RegisterNummer'); }
    public set RegisterNummer(value: string) { this.setProperty('RegisterNummer', value); }

    @Expose()
    @TransformDate()
    public get RegisterDatum(): Date { return this.getProperty('RegisterDatum'); }
    public set RegisterDatum(value: Date) { this.setProperty('RegisterDatum', value); }

    @Expose()
    public get RegisterOrt(): string { return this.getProperty('RegisterOrt'); }
    public set RegisterOrt(value: string) { this.setProperty('RegisterOrt', value); }

    @Expose()
    public get RegisterLand(): string { return this.getProperty('RegisterLand'); }
    public set RegisterLand(value: string) { this.setProperty('RegisterLand', value); }

    @Expose()
    public get RechtstraegerName(): string { return this.getProperty('RechtstraegerName'); }
    public set RechtstraegerName(value: string) { this.setProperty('RechtstraegerName', value); }

    @Expose()
    public get RechtstraegerSitz(): string { return this.getProperty('RechtstraegerSitz'); }
    public set RechtstraegerSitz(value: string) { this.setProperty('RechtstraegerSitz', value); }

    // Zustelladresse
    @Expose()
    public get RTZustellAdresseCO(): string { return this.getModelId('RTZustellAdresseCO'); }
    public set RTZustellAdresseCO(value: string) { this.setModelId('RTZustellAdresseCO', value); }

    @Expose()
    public get RTZustellAdresseStrasse(): string { return this.getModelId('RTZustellAdresseStrasse'); }
    public set RTZustellAdresseStrasse(value: string) { this.setModelId('RTZustellAdresseStrasse', value); }

    @Expose()
    public get RTZustellAdressePLZ(): string { return this.getModelId('RTZustellAdressePLZ'); }
    public set RTZustellAdressePLZ(value: string) { this.setModelId('RTZustellAdressePLZ', value); }

    @Expose()
    public get RTZustellAdresseOrt(): string { return this.getModelId('RTZustellAdresseOrt'); }
    public set RTZustellAdresseOrt(value: string) { this.setModelId('RTZustellAdresseOrt', value); }

    @Expose()
    public get RTZustellAdresseLand(): string { return this.getModelId('RTZustellAdresseLand'); }
    public set RTZustellAdresseLand(value: string) { this.setModelId('RTZustellAdresseLand', value); }

     // HELPER ATTRIBS
    // Inländische RT
    public get InlandRechtstraegerName(): string { return this.getProperty('RechtstraegerName'); }
    public set InlandRechtstraegerName(value: string) { this.setProperty('RechtstraegerName', value); }

    public get InlandRegisterNummer(): string { return this.getProperty('RegisterNummer'); }
    public set InlandRegisterNummer(value: string) { this.setProperty('RegisterNummer', value); }

    public get InlandRechtsform(): string { return this.getModelId('Rechtsform'); }
    public set InlandRechtsform(value: string) { this.setModelId('Rechtsform', value); }

    public get InlandRechtstraegerSitz(): string { return this.getProperty('RechtstraegerSitz'); }
    public set InlandRechtstraegerSitz(value: string) { this.setProperty('RechtstraegerSitz', value); }

    // Ausländische RT
    public get AuslandRechtstraegerName(): string { return this.getProperty('RechtstraegerName'); }
    public set AuslandRechtstraegerName(value: string) { this.setProperty('RechtstraegerName', value); }

    public get AuslandRechtsform(): string { return this.getModelId('Rechtsform'); }
    public set AuslandRechtsform(value: string) { this.setModelId('Rechtsform', value); }

    public get AuslandRechtstraegerSitz(): string { return this.getProperty('RechtstraegerSitz'); }
    public set AuslandRechtstraegerSitz(value: string) { this.setProperty('RechtstraegerSitz', value); }

    public get AuslandGruendungsdatum(): Date { return this.getProperty('Gruendungsdatum'); }
    public set AuslandGruendungsdatum(value: Date) { this.setProperty('Gruendungsdatum', value); }

    public get AuslandRegisterNummer(): string { return this.getProperty('RegisterNummer'); }
    public set AuslandRegisterNummer(value: string) { this.setProperty('RegisterNummer', value); }

    public get AuslandRegisterDatum(): Date { return this.getProperty('RegisterDatum'); }
    public set AuslandRegisterDatum(value: Date) { this.setProperty('RegisterDatum', value); }

    public get AuslandRegisterOrt(): string { return this.getProperty('RegisterOrt'); }
    public set AuslandRegisterOrt(value: string) { this.setProperty('RegisterOrt', value); }

    public get AuslandRegisterLand(): string { return this.getProperty('RegisterLand'); }
    public set AuslandRegisterLand(value: string) { this.setProperty('RegisterLand', value); }

    //
    @Expose()
    public get Verlaufskommentar(): string { return this.getProperty('Verlaufskommentar'); }
    public set Verlaufskommentar(value: string) { this.setProperty('Verlaufskommentar', value); }

    @Expose()
    public get FormularId(): string { return this.getProperty('FormularId'); }
    public set FormularId(value: string) { this.setProperty('FormularId', value); }
}
