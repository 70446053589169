import { NXBookmark } from '@avi-x/avi-dto/system/nxbookmark.model';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { typed_nameof } from '../utils/nameof';
import { AviBaseFormComponent } from '../base-form/base-form.component';
import { AviChangedAttr, AviFormField } from '../base-form/form-field';
import { AviCommonService } from '../../services/common.service';
import { AviFormFieldService } from '../../services/form-field.service';
import { AviBookmarkService } from '../../services/bookmark.service';
import { ObjectUtils } from '../utils/object-utils';
import { AviApiErrorObject } from '../../dto/aviapierrorobject';

const nameof = (nameFunction: ((obj: NXBookmark) => any)) => typed_nameof<NXBookmark>(nameFunction);

@Component({
    selector: 'avi-core-bookmark-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBookmarkFormComponent implements OnInit, OnDestroy {

    private _className = 'Bookmark';

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public FormTitle: string = null;

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: NXBookmark = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string { return this._ModelId; }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    constructor(private commonService: AviCommonService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formFieldService: AviFormFieldService,
        private bookmarkService: AviBookmarkService,
		private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {

        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                 this.Model = null;
                 this._ModelId = value;
                 this.isEditMode = false;
             }
             return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));

        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    async onAttrChange(data: AviChangedAttr) {
    }

    public async initNewModel(data?: any) {

        this.Model = await this.bookmarkService.createBookmark();
        this.isEditMode = false;

        if (data) {
            for (const key in data) {
                ObjectUtils.setByPath(this.Model, key, data[key]);
                this.onAttrChange({ field: key, value: data[key], model: this.Model, form: this.Form });
            }
        }
    }

    ngOnInit() {
        this.initFields();

        if (this.config && this.config.data) {

            this.actionButtons = [];
            this.actionButtons.push({  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.closeDialog(null); } });

            if (this.config.data.itemId) {
                this.loadModel(this.config.data.itemId).then(r => {
                    this.cdr.markForCheck();
                });
            } else {
                this.initNewModel(this.config.data).then(r => {
                    this.cdr.markForCheck();
                });
            }
        }
    }

    closeDialog(model: any) {
        if (this.ref)
            this.ref.close(model);
    }

    deleteModel(model: any) {
        this.bookmarkService.deleteBookmark(model.id);
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: NXBookmark) {

        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            delegate = this.bookmarkService.updateBookmark(model);
        } else {
            delegate = this.bookmarkService.writeBookmark(model);
        }
        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);
            this.closeDialog(r);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {

        this.commonService.showGlobalLoader();

        this.Model = await this.bookmarkService.loadBookmark(id);

		this.updateDropdownSources();

        this.commonService.hideGlobalLoader();
        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        return this.Model;
    }

    async updateDropdownSources() {
        if (this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Description), 'Beschreibung', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sorter), 'Sorter', 0, true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Session), 'Session', true).setMDSizeFull().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Modul), 'Modul', false).setMDSizeFull().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ContextId), 'Kontext', false).setMDSizeFull().setReadonly());
    }
}
