<div class="mt-2">
    <avi-core-view-panel [toggleable]="false" [header]="'About'" class="w-100p">

    <div *ngIf="authService.IsLoggedIn" class="p-2 grid">
        <div class="col-12 lg:col-6 p-2">
            <div class="col-12 lg:col-12 p-0">
                    <div class="p-3 grid">
                        <div class="p-0 mb-1 col-12 md:col-12 summary-header">Webclient</div>

                        <div class="ml-2 grid col-12 md:col-12">
                            <div class="p-0 col-12 md:col-4 summary-label">Name</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ appName }}</div>
                            <div class="p-0 col-12 md:col-4 summary-label">Version</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ appVersion.version }}</div>
                            <div class="p-0 col-12 md:col-4 summary-label">Build-Zeit</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ appVersion.buildDate }} {{ appVersion.buildTime }}</div>
                            <div class="p-0 col-12 md:col-4 summary-label">Angemeldet als</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ authService.CurrentUserName }}</div>
                        </div>

                        <div *ngIf="serverInfo" class="p-0 mb-1 col-12 md:col-12 summary-header mt-2">Application Server</div>
                        <div *ngIf="serverInfo" class="ml-2 grid col-12 md:col-12">
                            <div class="p-0 col-12 md:col-4 summary-label">Version</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ serverInfo.ServerVersion }}</div>

                            <div class="p-0 col-12 md:col-4 summary-label">Build-Zeit</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ serverInfo.ServerBuildDate }} {{ serverInfo.ServerBuildTime }}</div>

                            <div class="p-0 col-12 md:col-4 summary-label">Betriebsystem</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ serverInfo.ServerOSVersion }}</div>

                            <div class="p-0 col-12 md:col-4 summary-label">.NET Framework</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ serverInfo.ServerDotNetVersion }}</div>
                        </div>

                        <div *ngIf="serverInfo" class="p-0 mb-1 col-12 md:col-12 summary-header mt-2">Datenbank</div>
                        <div *ngIf="serverInfo" class="ml-2 grid col-12 md:col-12">
                            <div class="p-0 col-12 md:col-4 summary-label">Server</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ serverInfo.DBServer }}</div>

                            <div class="p-0 col-12 md:col-4 summary-label">Katalog</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ serverInfo.DBName }}</div>

                            <div class="p-0 col-12 md:col-4 summary-label">Timeout</div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ serverInfo.DBTimeout }}</div>
                        </div>

                        <div *ngIf="ShowThankyou" class="p-0  mb-1 col-12 md:col-12 pt-3">{{ appName }} wurde möglich gemacht durch die harte Arbeit und den Einsatz von einer Gruppe geili Sieche.</div>
                        <div *ngIf="ShowThankyou" class="p-0  mb-1 col-12 md:col-12">© 1753-9999 Aviita Establishment.</div>

                    </div>
            </div>
        </div>
    </div>
</avi-core-view-panel>
</div>