import { SqlQueryParameter } from './sqlqueryparameter.model';
import { ListType } from '../shared/listtype.model';
import { IAviSearcherParameter } from '../interface/avisearcher-parameter.interface';
import { AviListDetailConst } from '@avi-x/avi-core';

export class SqlQueryParameterVM extends SqlQueryParameter implements IAviSearcherParameter {
    public RefTypeName: string;

    constructor(paramName: string, paramType: string, label1: string, allowNull: string, hasControl: string, dateChoice: string = AviListDetailConst.CAF_DATE_CHOICE_DEFINED, defaultValueCurrentUser: boolean = true, refType: string = null, row: number = null, sorter: number = null) {
        super();
        this.ParamName = paramName;
        this.ParamType = new ListType(paramType);
        this.Row = row;
        this.Sorter = sorter;
        this.Label1 = this.Label2 = label1;
        this.AllowNull = new ListType(allowNull);
        this.HasControl = new ListType(hasControl);
        this.DefaultDateParamListChoice = new ListType(dateChoice);
        this.DefaultRefParamValueCurrentUser  = defaultValueCurrentUser;
        this.RefTypeName = refType;
    }

    public setProperty<T>(attrname: string, value: T) {
        super.setProperty<T>(attrname, value);
        if (attrname === 'RefType')
            this.RefTypeName = this.RefType ? this.RefType.Model_Name_Short : '';
    }

}
