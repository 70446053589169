import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppUserGroupLogEntry } from '@avi-x/avi-dto/system/appusergrouplogentry.model';
import { TechRoleLinkDeleteDto } from '@avi-x/avi-dto/system/techrolelinkdeletedto.model';
import { AppUserGroupLinkDeleteDto } from '@avi-x/avi-dto/system/appusergrouplinkdeletedto.model';
import { IDTO } from '@avi-x/avi-dto/shared';


const nameof = (nameFunction: ((obj: AppUserGroupLogEntry) => any)) => typed_nameof<AppUserGroupLogEntry>(nameFunction);

@Component({
    selector: 'avi-appusergrouplogentry-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [show-buttons]="showButtons"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAppUserGroupLogEntryFormComponent extends AviAbstractModelFormComponent<AppUserGroupLogEntry> {  
    ClassName = 'CORE.COMMON.MODEL.APPUSERGROUPLOGENTRY';
    BaseUrl = 'appusergroup/logentry';
    ClassType = AppUserGroupLogEntry;
	FormType = AviAppUserGroupLogEntryFormComponent;

    @Input('show-add-roles')
    showAddedRoles = false;

    @Input('show-removed-roles')
    showRemovedRoles = false;

    showButtons = false;

    private appUserGroupId: string;
    private techRoleLinkIdToDelete: string;
    private techRoleName: string;
    private userGroupLinkIdToDelete: string;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'AppUserGroup';
    }
	
    initParams(params: { [k: string]: any; }) {
        if (params) {
            if (params.AppUserGroupId)
                this.appUserGroupId = params.AppUserGroupId;

            if (params.TechRoleLinkIdToDelete) {
                this.techRoleLinkIdToDelete = params.TechRoleLinkIdToDelete;   
                this.showButtons = true;
            }

            if (params.TechRoleName)
                this.techRoleName = params.TechRoleName;

            if (params.UserGroupLinkToDelete) {
                this.userGroupLinkIdToDelete = params.UserGroupLinkToDelete;

                this.showButtons = true;
            }
        }
    }

    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        //this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonAppUserGroup_ID), 'CORE.COMMON.MODEL.APPUSERGROUPLOGENTRY.VONAPPUSERGROUP', `model/representations/AppUserGroup${this.ReadOnly ? '?id=$id' : ''}`, true, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        let field = this.formFieldService.CreateTextarea(nameof(c => c.Hintergrund), 'CORE.COMMON.MODEL.APPUSERGROUPLOGENTRY.HINTERGRUND', true).setMDSizeFull();
        field.CustomStyle = () => { return { 'min-height': "110px" }; };
        this.Fields.push(field);
        
        if (this.showAddedRoles) {
            field = this.formFieldService.CreateTextarea(nameof(c => c.rolesAddAsMultiLineString), 'CORE.COMMON.MODEL.APPUSERGROUPLOGENTRY.ROLESADDED', false).setMDSizeHalf().setReadonly();
            field.CustomStyle = () => { return { 'max-height': "400px", 'min-height': "110px" }; };
            this.Fields.push(field);
        }
        if (this.showRemovedRoles || this.techRoleLinkIdToDelete) {
            field = this.formFieldService.CreateTextarea(nameof(c => c.rolesRemovedAsMultiLineString), 'CORE.COMMON.MODEL.APPUSERGROUPLOGENTRY.ROLESREMOVED', false).setMDSizeHalf().setReadonly();
            field.CustomStyle = () => { return { 'max-height': "400px", 'min-height': "110px" }; };
            this.Fields.push(field); 
        }
    }

    public async afterCreateModel(bm: AppUserGroupLogEntry) {
        if (this.techRoleLinkIdToDelete && this.techRoleName)
            bm.RolesRemoved = this.techRoleName;
    }

    saveModel(model: AppUserGroupLogEntry) {
        let id: string;
        let dto: IDTO;
        let url: string;

        model.vonAppUserGroup_ID = this.appUserGroupId;

        if (this.techRoleLinkIdToDelete) {
            let saveDto = new TechRoleLinkDeleteDto();
            saveDto.TechRoleLinkId = this.techRoleLinkIdToDelete;
            saveDto.LogEntry = this.Model;

            id = this.techRoleLinkIdToDelete;
            dto = saveDto;
            url = this.getUrl("techrolelink");
        }
        else
        {
            let saveDto = new AppUserGroupLinkDeleteDto();
            saveDto.AppUserGroupLinkId = this.userGroupLinkIdToDelete;
            saveDto.LogEntry = this.Model;

            id = this.userGroupLinkIdToDelete;
            dto = saveDto;
            url = this.getUrl("appusergrouplink");
        }

        this.saveDto(id, dto, url, true);
    }
}
