import { Exclude, Expose, Type } from "class-transformer";
import { IDTO, ListType } from "../shared";
import { JobParam } from "../system/jobparam.model";

export class JobStartInfoDto extends IDTO {

    @Expose()
    Title: string;

    @Expose()
    JobDefId: string;

    @Expose()
    @Type(() => ListType)
    JobType: ListType;

    @Expose()
    @Type(() => JobParam)
    Params: JobParam[];

    @Exclude()
    FileName: string;

    @Exclude()
    File: File;
}
