import { Expose } from "class-transformer";
import { retry } from "rxjs/operators";
import { BaseModel } from "../shared/basemodel.model";

export enum AppLevel {
    AppServer = 0,
    WebClient = 1
}

export class ResourceLabel extends BaseModel {
    
    @Expose()
    public get Key(): string { return this.getProperty('Key'); }
    public set Key(value: string) { this.setProperty('Key', value); }

    @Expose()
    public get AppLevel(): AppLevel { return this.getProperty('AppLevel'); }
    public set AppLevel(value: AppLevel) { this.setProperty('AppLevel', value); }

    @Expose()
    public get OriginalValueCore(): string { return this.getProperty('OriginalValueCore'); }
    public set OriginalValueCore(value: string) { this.setProperty('OriginalValueCore', value); }
    
    @Expose()
    public get OriginalValueApp(): string { return this.getProperty('OriginalValueApp'); }
    public set OriginalValueApp(value: string) { this.setProperty('OriginalValueApp', value); }
    
    @Expose()
    public get OriginalValueCustom(): string { return this.getProperty('OriginalValueCustom'); }
    public set OriginalValueCustom(value: string) { this.setProperty('OriginalValueCustom', value); }

    @Expose()
    public get ValueCore(): string { return this.getProperty('ValueCore'); }
    public set ValueCore(value: string) { this.setProperty('ValueCore', value); }
	
    @Expose()
    public get ValueApp(): string { return this.getProperty('ValueApp'); }
    public set ValueApp(value: string) { this.setProperty('ValueApp', value); }
	
    @Expose()
    public get ValueCustom(): string { return this.getProperty('ValueCustom'); }
    public set ValueCustom(value: string) { this.setProperty('ValueCustom', value); }
    
    // transient
    public get Value(): string {
        if (this.ValueCustom)
            return this.removeHtmlTags(this.ValueCustom);

        if (this.ValueApp)
            return this.removeHtmlTags(this.ValueApp);

        if (this.ValueCore)
            return this.removeHtmlTags(this.ValueCore);

        return null;
    }

    private removeHtmlTags(input: string): string {
        if (input) {
            var div = document.createElement("div");
            div.innerHTML = input;
            return div.textContent || div.innerText;
        }
        return input;
    }

    public IsHtml: boolean = false;
}