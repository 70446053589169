import { Expose, Type } from 'class-transformer';

import { QueryDef } from '../reporting/querydef.model';
import { List } from '../system/list.model';
import { ModelMeta } from '../system/modelmeta.model';
import { TechRole } from '../system/techrole.model';
import { ListDetail } from '../system/listdetail.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared/genericref.model';

export class SqlQueryParameter extends BaseModel {  
    @Expose()
    public get Row(): number { return this.getProperty('Row'); }
    public set Row(value: number) { this.setProperty('Row', value); }
    
    @Expose()
    public get Sorter(): number { return this.getProperty('Sorter'); }
    public set Sorter(value: number) { this.setProperty('Sorter', value); }
    
    @Expose()
    public get Label1(): string { return this.getProperty('Label1'); }
    public set Label1(value: string) { this.setProperty('Label1', value); }
    
    @Expose()
    public get Label2(): string { return this.getProperty('Label2'); }
    public set Label2(value: string) { this.setProperty('Label2', value); }
    
    @Expose()
    public get ParamName(): string { return this.getProperty('ParamName'); }
    public set ParamName(value: string) { this.setProperty('ParamName', value); }
    
    @Expose()
    public get ParamLength(): number { return this.getProperty('ParamLength'); }
    public set ParamLength(value: number) { this.setProperty('ParamLength', value); }
    
    @Expose()
    public get Mask(): string { return this.getProperty('Mask'); }
    public set Mask(value: string) { this.setProperty('Mask', value); }
    
    @Expose()
    public get RegEx(): string { return this.getProperty('RegEx'); }
    public set RegEx(value: string) { this.setProperty('RegEx', value); }
    
    @Expose()
    public get minValue(): number { return this.getProperty('minValue'); }
    public set minValue(value: number) { this.setProperty('minValue', value); }
    
    @Expose()
    public get maxValue(): number { return this.getProperty('maxValue'); }
    public set maxValue(value: number) { this.setProperty('maxValue', value); }
    
    @Expose()
    public get DefaultBoolParamValue(): boolean { return this.getProperty('DefaultBoolParamValue'); }
    public set DefaultBoolParamValue(value: boolean) { this.setProperty('DefaultBoolParamValue', value); }
    
    @Expose()
    public get BoolRadioGroupID(): number { return this.getProperty('BoolRadioGroupID'); }
    public set BoolRadioGroupID(value: number) { this.setProperty('BoolRadioGroupID', value); }
    
    @Expose()
    public get DefaultIntParamValue(): number { return this.getProperty('DefaultIntParamValue'); }
    public set DefaultIntParamValue(value: number) { this.setProperty('DefaultIntParamValue', value); }
    
    @Expose()
    public get DefaultDecParamValue(): number { return this.getProperty('DefaultDecParamValue'); }
    public set DefaultDecParamValue(value: number) { this.setProperty('DefaultDecParamValue', value); }
    
    @Expose()
    public get DefaultTextParamValue(): string { return this.getProperty('DefaultTextParamValue'); }
    public set DefaultTextParamValue(value: string) { this.setProperty('DefaultTextParamValue', value); }
    
    @Expose()
    @Type(() => Date)
    public get DefaultDateParamValue(): Date { return this.getProperty('DefaultDateParamValue'); }
    public set DefaultDateParamValue(value: Date) { this.setProperty('DefaultDateParamValue', value); }
    
    @Expose()
    public get DefaultRefParamValueCurrentUser(): boolean { return this.getProperty('DefaultRefParamValueCurrentUser'); }
    public set DefaultRefParamValueCurrentUser(value: boolean) { this.setProperty('DefaultRefParamValueCurrentUser', value); }
    
    @Expose()
    public get vonQueryDef_ID(): string { return this.getModelId('vonQueryDef_ID'); }
    public set vonQueryDef_ID(value: string) { this.setModelId('vonQueryDef_ID', value); }
 
    @Expose()
    @Type(() => QueryDef)
    public get vonQueryDef(): QueryDef { return this.getModel('vonQueryDef'); }
    public set vonQueryDef(value: QueryDef) { this.setModel('vonQueryDef', value); }
    
    @Expose()
    public get List_ID(): string { return this.getModelId('List_ID'); }
    public set List_ID(value: string) { this.setModelId('List_ID', value); }
 
    @Expose()
    @Type(() => List)
    public get List(): List { return this.getModel('List'); }
    public set List(value: List) { this.setModel('List', value); }
    
    @Expose()
    public get RefType_ID(): string { return this.getModelId('RefType_ID'); }
    public set RefType_ID(value: string) { this.setModelId('RefType_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get RefType(): ModelMeta { return this.getModel('RefType'); }
    public set RefType(value: ModelMeta) { this.setModel('RefType', value); }
    
    @Expose()
    public get RefAbfrage_ID(): string { return this.getModelId('RefAbfrage_ID'); }
    public set RefAbfrage_ID(value: string) { this.setModelId('RefAbfrage_ID', value); }
 
    @Expose()
    @Type(() => QueryDef)
    public get RefAbfrage(): QueryDef { return this.getModel('RefAbfrage'); }
    public set RefAbfrage(value: QueryDef) { this.setModel('RefAbfrage', value); }
    
    @Expose()
    public get DefaultBoolTechRoleValue_ID(): string { return this.getModelId('DefaultBoolTechRoleValue_ID'); }
    public set DefaultBoolTechRoleValue_ID(value: string) { this.setModelId('DefaultBoolTechRoleValue_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get DefaultBoolTechRoleValue(): TechRole { return this.getModel('DefaultBoolTechRoleValue'); }
    public set DefaultBoolTechRoleValue(value: TechRole) { this.setModel('DefaultBoolTechRoleValue', value); }
    
    @Expose()
    public get DefaultListDetailParamValue_ID(): string { return this.getModelId('DefaultListDetailParamValue_ID'); }
    public set DefaultListDetailParamValue_ID(value: string) { this.setModelId('DefaultListDetailParamValue_ID', value); }
 
    @Expose()
    @Type(() => ListDetail)
    public get DefaultListDetailParamValue(): ListDetail { return this.getModel('DefaultListDetailParamValue'); }
    public set DefaultListDetailParamValue(value: ListDetail) { this.setModel('DefaultListDetailParamValue', value); }
    
    @Expose()
    public get TechRoleEdit_ID(): string { return this.getModelId('TechRoleEdit_ID'); }
    public set TechRoleEdit_ID(value: string) { this.setModelId('TechRoleEdit_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleEdit(): TechRole { return this.getModel('TechRoleEdit'); }
    public set TechRoleEdit(value: TechRole) { this.setModel('TechRoleEdit', value); }
    
    @Expose()
    public get TechRoleVisible_ID(): string { return this.getModelId('TechRoleVisible_ID'); }
    public set TechRoleVisible_ID(value: string) { this.setModelId('TechRoleVisible_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleVisible(): TechRole { return this.getModel('TechRoleVisible'); }
    public set TechRoleVisible(value: TechRole) { this.setModel('TechRoleVisible', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ParamType(): ListType { return this.getProperty('ParamType'); }
    public set ParamType(value: ListType) { this.setProperty('ParamType', value); }
    
    @Expose()
    @Type(() => ListType)
    public get RefList(): ListType { return this.getProperty('RefList'); }
    public set RefList(value: ListType) { this.setProperty('RefList', value); }
    
    @Expose()
    @Type(() => ListType)
    public get RefListSearchbar(): ListType { return this.getProperty('RefListSearchbar'); }
    public set RefListSearchbar(value: ListType) { this.setProperty('RefListSearchbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DefaultDateParamListChoice(): ListType { return this.getProperty('DefaultDateParamListChoice'); }
    public set DefaultDateParamListChoice(value: ListType) { this.setProperty('DefaultDateParamListChoice', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AutoSearch(): ListType { return this.getProperty('AutoSearch'); }
    public set AutoSearch(value: ListType) { this.setProperty('AutoSearch', value); }
    
    @Expose()
    @Type(() => ListType)
    public get SaveChanges(): ListType { return this.getProperty('SaveChanges'); }
    public set SaveChanges(value: ListType) { this.setProperty('SaveChanges', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AllowNull(): ListType { return this.getProperty('AllowNull'); }
    public set AllowNull(value: ListType) { this.setProperty('AllowNull', value); }
    
    @Expose()
    @Type(() => ListType)
    public get HasControl(): ListType { return this.getProperty('HasControl'); }
    public set HasControl(value: ListType) { this.setProperty('HasControl', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get DefaultRefParamValue(): GenericRef { return this.getProperty('DefaultRefParamValue'); }
    public set DefaultRefParamValue(value: GenericRef) { this.setProperty('DefaultRefParamValue', value); }
    
}
