import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TechRoleLink } from '@avi-x/avi-dto/system/techrolelink.model';
import { TechRoleLinkAddDto } from '@avi-x/avi-dto/system/techrolelinkadddto.model';
import { AviAppUserGroupLogEntryFormComponent } from './appusergrouplogentry-form.component';
import { TechRoleLinkInfo } from './TechRoleLinkInfo';

const nameof = (nameFunction: ((obj: TechRoleLink) => any)) => typed_nameof<TechRoleLink>(nameFunction);

@Component({
    selector: 'avi-techrolelink-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [validation-delegate]="ValidateData"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

        <div groupTemplate1>
            <avi-appusergrouplogentry-form [card]="false" [contents-padding]="false" [readonly]="false" [model-id]="'0'" [enable-taskmenu]="false" [show-add-roles]="true"></avi-appusergrouplogentry-form>
        </div>
    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviTechRoleLinkFormComponent extends AviAbstractModelFormComponent<TechRoleLink> {  
    ClassName = 'CORE.COMMON.MODEL.TECHROLELINK';
    BaseUrl = 'techrole/link';
    ClassType = TechRoleLink;
	FormType = AviTechRoleLinkFormComponent;

    @ViewChild(AviAppUserGroupLogEntryFormComponent)
    logEntryComponent: AviAppUserGroupLogEntryFormComponent;

    private techRoleLinkInfo: TechRoleLinkInfo;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'TechRole';
    }
	
    get showRemovedRolesInLogEntry(): boolean {
        return this.techRoleLinkInfo.isForAppUserGroup;
    }

    initParams(params: { [k: string]: any; }) {
        if (params) {
            this.techRoleLinkInfo = params.transferInfo;
        }
    }

    public async afterCreateModel(bm: TechRoleLink) {
        if (this.techRoleLinkInfo.isForAppUserGroup)
            bm.AppUserGroupRef_ID = this.techRoleLinkInfo.appUserGroupId;
        else
            bm.TechRoleRef_ID = this.techRoleLinkInfo.techRoleId;
    }

    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateStep("CORE.COMMON.MODEL.TECHROLELINK.STEP_ROLE"))

        if (this.techRoleLinkInfo.isForAppUserGroup) {
            if (this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.TechRoleRef_ID), 'CORE.COMMON.MODEL.TECHROLELINK.TECHROLEREF', true));
            else {
                this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.TechRoleRef_ID), 'CORE.COMMON.MODEL.TECHROLELINK.TECHROLEREF', true, "Representation", "Id", 3, `techrole/filteredbyusergroup/${this.ContextId}`).setMDSizeFull());
/*                 let field = this.formFieldService.CreateDropdown(nameof(c => c.TechRoleRef_ID), 'CORE.COMMON.MODEL.TECHROLELINK.TECHROLEREF', `techrole/filteredbyusergroup/${this.ContextId}`, true, true, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull();
                field.DropdownEnableVirtualScroll = true;

                this.Fields.push(field);  */
            }
        }    
        else {
            if (this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AppUserGroupRef_ID), 'CORE.COMMON.MODEL.TECHROLELINK.APPUSERGROUPREF', true));
            else
                this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.AppUserGroupRef_ID), 'CORE.COMMON.MODEL.TECHROLELINK.APPUSERGROUPREF', `appusergroup/filteredbytechrole/${this.ContextId}`, true, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull());
        }

        this.Fields.push(this.formFieldService.CreateStep("CORE.COMMON.MODEL.TECHROLELINK.STEP_LOG"));
        this.Fields.push(this.formFieldService.CreateGroupTemplate(1));
    }
    
    async onAttrChange(data: AviChangedAttr) {
        if (data.field == nameof(c => c.TechRoleRef_ID)) {
            let field = this.formFieldService.getField(this.Fields, nameof(c => c.TechRoleRef_ID));
            let roleName = this.formFieldService.GetDropdownEntryLabelFromDatasource(field, this.Model.TechRoleRef_ID);
            this.logEntryComponent.Model.RolesAdded = roleName;
            this.logEntryComponent.cdr.markForCheck();
        }
        else if (data.field == nameof(c => c.AppUserGroupRef_ID)) {
            this.logEntryComponent.Model.RolesAdded = this.techRoleLinkInfo.techRoleName;
            this.logEntryComponent.cdr.markForCheck();
        }
    }

    ValidateData = () => {
        return this.logEntryComponent && this.logEntryComponent.Form.isFormValid();
    }

    saveModel(model: TechRoleLink) {
        let saveDto = new TechRoleLinkAddDto();
        saveDto.TechRoleLink = this.Model;
        saveDto.LogEntry = this.logEntryComponent.Model;
        saveDto.LogEntry.vonAppUserGroup_ID = this.Model.AppUserGroupRef_ID;
       
        this.saveDto(model.Id, saveDto);
    }
}
