import { Injectable } from '@angular/core';
import { Calendar } from 'primeng/calendar';

import * as moment from 'moment-timezone';
import { AviCalendarComponent } from '../components/controls/calendar/calendar.component';

@Injectable({
    providedIn: 'root'
})
export class InputmaskService {
    constructor() {
    }

    public getMaskedTimeInput(inputValue: string): string {
        // Achtung: hardcoded für format hh:mm
        let value = inputValue;
        if (value.length > 5)
            value = value.substring(0, value.length - 1);

        let mask = value.toString();
        mask = mask.replace(/[^\d\.\:]/g, '');

        let result = '';
        let cnt = 0;
        for (let i = 0; i < mask.length; i++) {

            if (mask[i] === '.' || mask[i] === ':') continue;

            // t: -> 0t: oder t. -> 0t:
            if ((mask[i + 1] === '.' || mask[i + 1] === ':') && (i === 0)) {
                result += '0';
                ++cnt;
            }

            result += mask[i];
            ++cnt;

            if ((cnt === 2))
                result += ':';
        }

        return result;
    }

    public getMaskedDateInput(inputValue: string, includeTime: boolean): string {
        // Achtung: hardcoded für format dd.mm.yy
        const maxlen = includeTime ? 16 : 10;
        let value = inputValue;
        if (value.length > maxlen) {
            value = value.substring(0, value.length - 1);
        }

        let mask = value.toString();
        mask = includeTime ? mask.replace(/[^\d\.\:\s]/g, '') : mask.replace(/[^\d\.]/g, '');
        //    console.log(mask);

        let result = '';
        let cnt = 0;
        for (let i = 0; i < mask.length; i++) {

            if (mask[i] === '.' || mask[i] === ':' || mask[i] === ' ') continue;

            // d. -> 0d.
            // 0d.d. -> 0d.0d.
            if (mask[i + 1] === '.' && (cnt === 0 || cnt === 2)) {
                result += '0';
                ++cnt;
            }

            if (mask[i + 1] === ':' && (cnt === 10)) {
                result += '0';
                ++cnt;
            }

            result += mask[i];
            ++cnt;

            if ((cnt === 2 || cnt === 4))
                result += '.';

            if (cnt === 8 && includeTime)
                result += ' ';

            if (cnt === 10 && includeTime)
                result += ':';
        }

        return result;
    }

    public handleTimeInput(event, calendar: Calendar): void {
        let cal = calendar;
        if (calendar instanceof AviCalendarComponent)
            cal = (<AviCalendarComponent>calendar)._calendar;

        // Input Transformation für Zeit
        //
        // Input 1: -> 01: oder 01:2: -> 01:02:
        // . -> :
        // leerschlag -> aktuelle Zeit
        let value = '';
        if (event.inputType === 'insertText' && (event.target.value.length === 1 && event.data === ' ')) {
            value = moment(new Date()).format('HH:mm');

            const dateValue = cal.parseValueFromString(value);
            cal.updateModel(dateValue);
            cal.updateUI();
        } else {
            const cursorPosition = event.target.selectionEnd;
            if (event.inputType === 'deleteContentBackward' && cursorPosition === 2)
                event.target.value = event.target.value.substring(0, cursorPosition - 1) + event.target.value.substring(cursorPosition);

            value = this.getMaskedTimeInput(event.target.value);
        }

        event.target.value = value.toString();
    }

    public handleDateInput(event, calendar: Calendar): void {
        let cal = calendar;
        if (calendar instanceof AviCalendarComponent)
            cal = (<AviCalendarComponent>calendar)._calendar;

        // Input Transformation für Datum
        //
        // Input .: -> 01. oder 01.2. -> 01.02.
        // leerschlag -> Heute
        const cursorPosition = event.target.selectionEnd;

        let value = '';
        if (event.inputType === 'insertText' && (event.target.value.length === 1 && event.data === ' ')) {
            if (cal.showTime)
                value = moment(new Date()).format('DD.MM.YYYY HH:mm');
            else
                value = moment(new Date()).format('DD.MM.YYYY');

            const dateValue = cal.parseValueFromString(value);
            cal.updateModel(dateValue);
            cal.updateUI();
        } else {
            if (event.inputType === 'deleteContentBackward' && (cursorPosition === 2 || cursorPosition === 5))
                event.target.value = event.target.value.substring(0, cursorPosition - 1) + event.target.value.substring(cursorPosition);
            else {
                // console.log(cursorPosition);
                // console.log(event.target.value);
                // console.log(event.data);

                if (event.target.value.length > cursorPosition)
                    event.target.value = event.target.value.substring(0, cursorPosition) + event.target.value.substring(cursorPosition + 1);

                // console.log(event.target.value);

            }

            value = this.getMaskedDateInput(event.target.value, cal.showTime);
        }

        event.target.value = value.toString();

        // console.log(event);
    }

}
