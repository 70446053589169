import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class DocProperty extends BaseModel {  
    @Expose()
    public get PathToObject(): string { return this.getProperty('PathToObject'); }
    public set PathToObject(value: string) { this.setProperty('PathToObject', value); }
    
    @Expose()
    public get AttributName(): string { return this.getProperty('AttributName'); }
    public set AttributName(value: string) { this.setProperty('AttributName', value); }
    
    @Expose()
    public get SprachRelevant(): boolean { return this.getProperty('SprachRelevant'); }
    public set SprachRelevant(value: boolean) { this.setProperty('SprachRelevant', value); }
    
    @Expose()
    public get FormatString(): string { return this.getProperty('FormatString'); }
    public set FormatString(value: string) { this.setProperty('FormatString', value); }
    
    @Expose()
    public get FixedRegionCode(): string { return this.getProperty('FixedRegionCode'); }
    public set FixedRegionCode(value: string) { this.setProperty('FixedRegionCode', value); }
    
    @Expose()
    public get SqlText(): string { return this.getProperty('SqlText'); }
    public set SqlText(value: string) { this.setProperty('SqlText', value); }
    
    @Expose()
    public get TrennerRow(): string { return this.getProperty('TrennerRow'); }
    public set TrennerRow(value: string) { this.setProperty('TrennerRow', value); }
    
    @Expose()
    public get TrennerCol(): string { return this.getProperty('TrennerCol'); }
    public set TrennerCol(value: string) { this.setProperty('TrennerCol', value); }
    
    @Expose()
    public get vonDocTemplate_ID(): string { return this.getModelId('vonDocTemplate_ID'); }
    public set vonDocTemplate_ID(value: string) { this.setModelId('vonDocTemplate_ID', value); }
     
    @Expose()
    public get vonAviDocTemplate_ID(): string { return this.getModelId('vonAviDocTemplate_ID'); }
    public set vonAviDocTemplate_ID(value: string) { this.setModelId('vonAviDocTemplate_ID', value); }
     
    @Expose()
    public get vonDocPropContainer_ID(): string { return this.getModelId('vonDocPropContainer_ID'); }
    public set vonDocPropContainer_ID(value: string) { this.setModelId('vonDocPropContainer_ID', value); }

    @Expose()
    public get vonPropertyDef_ID(): string { return this.getModelId('vonPropertyDef_ID'); }
    public set vonPropertyDef_ID(value: string) { this.setModelId('vonPropertyDef_ID', value); }
     
    @Expose()
    public get defaultPropertyDef_ID(): string { return this.getModelId('defaultPropertyDef_ID'); }
    public set defaultPropertyDef_ID(value: string) { this.setModelId('defaultPropertyDef_ID', value); }
     
    @Expose()
    public get vonModelMeta_ID(): string { return this.getModelId('vonModelMeta_ID'); }
    public set vonModelMeta_ID(value: string) { this.setModelId('vonModelMeta_ID', value); }
         
    @Expose()
    public get vonAdressBlockDef_ID(): string { return this.getModelId('vonAdressBlockDef_ID'); }
    public set vonAdressBlockDef_ID(value: string) { this.setModelId('vonAdressBlockDef_ID', value); }

    @Expose()
    @Type(() => ListType)
    public get DocPropType(): ListType { return this.getProperty('DocPropType'); }
    public set DocPropType(value: ListType) { this.setProperty('DocPropType', value); }
    
    @Expose()
    public get PropertyName(): string { return this.getProperty('PropertyName'); }
    public set PropertyName(value: string) { this.setProperty('PropertyName', value); }
}
