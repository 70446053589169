import { AdressBlockZeileDef } from '@avi-x/avi-dto/partner/adressblockzeiledef.model';
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormField, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdressBlockDef } from '@avi-x/avi-dto/partner/adressblockdef.model';

const nameof = (nameFunction: ((obj: AdressBlockZeileDef) => any)) => typed_nameof<AdressBlockZeileDef>(nameFunction);

@Component({
    selector: 'avi-adressblockzeiledef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAdressBlockZeileDefFormComponent extends AviAbstractModelFormComponent<AdressBlockZeileDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFADRESSBLOCKZEILEDEF';
    BaseUrl = 'adressblockdef/zeiledef';
    ClassType = AdressBlockZeileDef;
	FormType = AviAdressBlockZeileDefFormComponent;

    AdressBlockDef: AdressBlockDef;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFAdressBlockDef';
    }
	
    public async afterCreateModel(bm: AdressBlockZeileDef) {
        bm.vonCAFAdressBlockDef_ID = this.ContextId;
    }

    public async afterInitForm() {
        this.AdressBlockDef = await this.apiService.getModel(AdressBlockDef, `adressblockdef/def/${this.ContextId}`);
    }

    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sequenz), 'CORE.COMMON.MODEL.CAFADRESSBLOCKZEILEDEF.SEQUENZ', 0, true).setMDSizeFull());
        
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.BeibehaltenWennLeer), 'CORE.COMMON.MODEL.CAFADRESSBLOCKZEILEDEF.BEIBEHALTENWENNLEER', '', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.SprachRelevant), 'CORE.COMMON.MODEL.CAFADRESSBLOCKZEILEDEF.SPRACHRELEVANT', '', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextWithButton(nameof(c => c.Zeile), 'CORE.COMMON.MODEL.CAFADRESSBLOCKZEILEDEF.ZEILE', true, '...', this.openMetaBrowser).setReadonly(false).setMDSizeFull().setMaxLength(255));        
    }
    
    openMetaBrowser = async (field: AviFormField) => {
        if (!this.AdressBlockDef.vonModelMeta_ID) return;

        const res = await this.commonService.openMetaBrowser(this.AdressBlockDef.vonModelMeta_ID, 'any');
        if (res) {
            if (this.Model.Zeile)
                this.Model.Zeile = `${this.Model.Zeile.trim()} $(${res.FullPath})`;
            else 
                this.Model.Zeile = `$(${res.FullPath})`;                
            this.cdr.markForCheck();
        }
    }
}
