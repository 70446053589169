import { BaseModelState } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { Expose, Type } from 'class-transformer';

export class EmailConfig extends BaseModelState {
    @Expose()
    public get Username(): string { return this.getProperty('Username'); }
    public set Username(value: string) { this.setProperty('Username', value); }

    @Expose()
    public get Host(): string { return this.getProperty('Host'); }
    public set Host(value: string) { this.setProperty('Host', value); }

    @Expose()
    public get Sender(): string { return this.getProperty('Sender'); }
    public set Sender(value: string) { this.setProperty('Sender', value); }

    @Expose()
    public get Port(): number { return this.getProperty('Port'); }
    public set Port(value: number) { this.setProperty('Port', value); }

    @Expose()
    @Type(() => ListType)
    public get SSL(): ListType { return this.getProperty('SSL'); }
    public set SSL(value: ListType) { this.setProperty('SSL', value); }

    // // Transients
    // @Expose()
    // public get TestRecipient(): string { return this.getProperty('TestRecipient'); }
    // public set TestRecipient(value: string) { this.setProperty('TestRecipient', value); }

    // @Expose()
    // public get TestSubject(): string { return this.getProperty('TestSubject'); }
    // public set TestSubject(value: string) { this.setProperty('TestSubject', value); }

    // @Expose()
    // public get TestMessage(): string { return this.getProperty('TestMessage'); }
    // public set TestMessage(value: string) { this.setProperty('TestMessage', value); }
}
