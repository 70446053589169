import { Injectable } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { MenuItem } from "primeng/api";
import { AviCommonService } from "./common.service";

export enum PermissionType {
    Add,
    Delete,
    Edit
}

export interface AviMenuItem extends MenuItem {
    evaluateIsAllowed();
    addIsAllowedFunction(isAllowed: () => boolean);
}

class MyMenuItem implements AviMenuItem {
    label?: string;
    disabled?: boolean;
    icon?: string;
    command?: (event?: any) => void;

    private isAllowedFunction: () => boolean;

    constructor(label: string, icon: string, command?: (event?: any) => void) {
        this.label = label;
        this.icon = icon;
        this.command = command;
    }

    addIsAllowedFunction(isAllowed: () => boolean) {
        this.isAllowedFunction = isAllowed;

        this.disabled = true;
    }

    evaluateIsAllowed() {
        if (this.isAllowedFunction) {
            this.disabled = !this.isAllowedFunction();
        }
        else {
            this.disabled = false;
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class AviMenuService {

    constructor(private permissionsService: NgxPermissionsService, private commonService: AviCommonService) {

    }

    async addIfPermissionTypeDisabled(menuItems: MenuItem[], caption: string, icon: string, command: () => any, isAllowed: () => boolean, permissionType: PermissionType, crudBaseRight: string): Promise<AviMenuItem> {
        let roles = this.getRoles(permissionType, crudBaseRight);
        return await this.addIfPermissionDisabled(menuItems, caption, icon, command, isAllowed, ...roles);
    }

    async addIfPermissionType(menuItems: MenuItem[], caption: string, icon: string, command: () => any, permissionType: PermissionType, crudBaseRight: string): Promise<AviMenuItem> {
        let roles = this.getRoles(permissionType, crudBaseRight);

        return await this.addIfPermission(menuItems, caption, icon, command, ...roles);
    }

    async createIfPermissionTypeDisabled(caption: string, icon: string, command: () => any, isAllowed: () => boolean, permissionType: PermissionType, crudBaseRight: string): Promise<AviMenuItem> {
        let roles = this.getRoles(permissionType, crudBaseRight);

        return await this.createIfPermissionDisabled(caption, icon, command, isAllowed, ...roles);
    }

    async createIfPermissionType(caption: string, icon: string, command: () => any, permissionType: PermissionType, crudBaseRight: string): Promise<AviMenuItem> {
        let roles = this.getRoles(permissionType, crudBaseRight);

        return await this.createIfPermission(caption, icon, command, ...roles);
    }

    async addIfPermissionDisabled(menuItems: MenuItem[], caption: string, icon: string, command: () => any, isAllowed: () => boolean, ...roles: string[]): Promise<AviMenuItem> {
        let menuItem = await this.addIfPermission(menuItems, caption, icon, command, ...roles);
        if (menuItem)
            menuItem.addIsAllowedFunction(isAllowed);

        return menuItem;
    }

    async createIfPermissionDisabled(caption: string, icon: string, command: () => any, isAllowed: () => boolean, ...roles: string[]): Promise<AviMenuItem> {
        let menuItem = await this.createIfPermission(caption, icon, command, ...roles);
        if (menuItem)
            menuItem.addIsAllowedFunction(isAllowed);

        return menuItem;
    }

    async addIfAndPermissionDisabled(menuItems: MenuItem[], caption: string, icon: string, command: () => any, isAllowed: () => boolean, ...andRoles: string[][]): Promise<AviMenuItem> {
        let menuItem = await this.addIfAndPermission(menuItems, caption, icon, command, ...andRoles);
        if (menuItem)
            menuItem.addIsAllowedFunction(isAllowed);

        return menuItem;
    }

    async createIfAndPermissionDisabled(caption: string, icon: string, command: () => any, isAllowed: () => boolean, ...andRoles: string[][]): Promise<AviMenuItem> {
        let menuItem = await this.createIfAndPermission(caption, icon, command, ...andRoles);
        if (menuItem)
            menuItem.addIsAllowedFunction(isAllowed);

        return menuItem;
    }

    async addIfPermission(menuItems: MenuItem[], caption: string, icon: string, command: () => any,  ...roles: string[]): Promise<AviMenuItem> {
        if (roles.length > 0) {
            let allowed = await this.permissionsService.hasPermission(roles);
            if (!allowed)
                return null;
        }

        return this.add(menuItems, caption, icon, command);
    }

    async createIfPermission(caption: string, icon: string, command: () => any,  ...roles: string[]): Promise<AviMenuItem> {
        if (roles.length > 0) {
            let allowed = await this.permissionsService.hasPermission(roles);
            if (!allowed)
                return null;
        }

        return this.create(caption, icon, command);
    }

    async addIfAndPermission(menuItems: MenuItem[], caption: string, icon: string, command: () => any, ...andRoles: string[][]): Promise<AviMenuItem> {
        for (let i = 0; i < andRoles.length; i++) {
            let roles = andRoles[i];
            let allowed = await this.permissionsService.hasPermission(roles);
            if (!allowed)
                return null;
        }

        return this.add(menuItems, caption, icon, command);
    }

    async createIfAndPermission(caption: string, icon: string, command: () => any, ...andRoles: string[][]): Promise<AviMenuItem> {
        for (let i = 0; i < andRoles.length; i++) {
            let roles = andRoles[i];
            let allowed = await this.permissionsService.hasPermission(roles);
            if (!allowed)
                return null;
        }

        return this.create(caption, icon, command);
    }

    addSeparator(menuItems: MenuItem[]) {
        menuItems.push({separator: true});

    }

    private add(menuItems: MenuItem[], caption: string, icon: string, command: () => any): AviMenuItem {
        let menuItem = this.create(caption, icon, command);
        menuItems.push(menuItem);

        return menuItem;
    }

    private create(caption: string, icon: string, command: () => any) {
        let menuItem = new MyMenuItem(this.commonService.translateInstant(caption), icon, command);
        return menuItem;
    }

    setMenuItemDisabled(menuItem: MenuItem, disabled: boolean) {
        if (menuItem == null)
            return;

        menuItem.disabled = disabled;
    }

    evaluateIsAllowed(menuItems: MenuItem[] | AviMenuItem[]) {
        if (!menuItems)
            return;

            for (let menuItem of menuItems) {
            if (menuItem instanceof MyMenuItem)
                menuItem.evaluateIsAllowed();
        }
    }

    private getRoles(permissionType: PermissionType, crudBaseRight: string): string[] {
        let roles: string[] = [];
        roles.push(`${crudBaseRight} schreiben`);

        if (permissionType == PermissionType.Add)
            roles.push(`${crudBaseRight} erfassen`);
        else if (permissionType == PermissionType.Delete)
            roles.push(`${crudBaseRight} löschen`);
        else
            roles.push(`${crudBaseRight} editieren`);

        return roles;
    }
}
