import { Component, Input, ChangeDetectorRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService, AviDialogComponent } from '@avi-x/avi-core';
import { VwbPListDetailConst, VwbPRechtstraeger, VwbPRechtstraegerDetail, VwbPVersion } from '@avi-x/vwbp-public';
import { VwbPMahnung } from '../../../dto/vwbpmahnung.model';
import { VwbPBusse } from '../../../dto/vwbpbusse.model';
import { MahnwesenRueckgaengigMode } from '../mahnwesen-tasks/mahnwesen-rueckgaengig-task.component';

export enum AviCommentMode {
    // eslint-disable-next-line no-unused-vars
    Readonly, Create, Edit
}

export enum MahnwesenTaskMode {
    // eslint-disable-next-line no-unused-vars
    MAHNEN, MAHNFRIST, BUESSEN, LOESCHVERFAHREN, RECHTSMITTELFRIST, EINZUGSAUFTRAG, BUSSFRIST, RUECKGAENGIG, NICHT_RELEVANT
}

@Component({
    selector: 'vwbp-intern-mahnwesen-taskbar',
    templateUrl: './mahnwesen-taskbar.component.html',
    styleUrls: ['./mahnwesen-taskbar.component.scss'],
    providers: [DecimalPipe, PercentPipe]
})
export class VwbPInternMahnwesenTaskbarComponent {
    @ViewChild('taskDialog', { static: false })
    taskDialog: AviDialogComponent;

    @Input('rechtstraeger-detail')
    public RechtstraegerDetail: VwbPRechtstraegerDetail;

    VersionId: string = null;
    _Version: VwbPVersion = null;
    public get Version(): VwbPVersion {
        return this._Version;
    }

    @Input('version')
    public set Version(value: VwbPVersion) {
        this._Version = value;
        this.VersionId = this.Version?.Id;
    }

    RechtstraegerId: string = null;
    _Rechtstraeger: VwbPRechtstraeger = null;
    public get Rechtstraeger(): VwbPRechtstraeger {
        return this._Rechtstraeger;
    }

    @Input('rechtstraeger')
    public set Rechtstraeger(value: VwbPRechtstraeger) {
        this._Rechtstraeger = value;
        this.RechtstraegerId = this.Rechtstraeger?.Id;
        this.LoadData();
    }

    @Input('readonly')
    public Readonly: boolean = false;

    @Output('onChanged')
    public OnChanged: EventEmitter<any> = new EventEmitter();

    selectedRT: any[] = [];

    LastMahnung: VwbPMahnung = null;
    LastBusse: VwbPBusse = null;
    Mahnfrist: Date = null;
    Rechtsmittelfrist: Date = null;
    Bussfrist: Date = null;
    ErneutBuessen: boolean = false;
    RueckgaengigMode: MahnwesenRueckgaengigMode;

    public MahnwesenTaskMode = MahnwesenTaskMode;
    Modus: MahnwesenTaskMode;

    VwbPListDetailConst = VwbPListDetailConst;

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, private cdr: ChangeDetectorRef) {
    }

    async LoadData() {
        if (this.Rechtstraeger) {
            this.LastMahnung = await this.apiService.getModel(VwbPMahnung, `v1/intern/vwbp/mahnwesen/letzte_mahnung/${this.RechtstraegerId}`);
            this.LastBusse = await this.apiService.getModel(VwbPBusse, `v1/intern/vwbp/mahnwesen/letzte_busse/${this.RechtstraegerId}`);

            this.Mahnfrist = this.LastMahnung?.Mahnfrist;
            this.Rechtsmittelfrist = this.LastBusse?.Rechtsmittelfrist;
            this.Bussfrist = this.LastBusse?.Bussfrist;
        }

        this.cdr.markForCheck();
    }

    doTask(selectedRT: any[], modus: MahnwesenTaskMode) {
        this.selectedRT = selectedRT;
        this.Modus = modus;
        this.cdr.detectChanges();
        this.taskDialog.open();
    }

    isMahnwesenAktivAndHasRecht() {
        return !this.Readonly && this.Rechtstraeger?.MahnwesenAktiv.Id === VwbPListDetailConst.VWBP_MAHNWESEN_AKTIV;
    }

    enableTaskNichtRelevant() {
        return this.isMahnwesenAktivAndHasRecht() &&
        (
            // VWBP-199: Button 'Nicht relevant' soll unabhängig vom Erfassungsstatus und MahnwesenStatus verfügbar sein
            
            // (this.Rechtstraeger.HrStatus.Id === VwbPListDetailConst.VWBP_RT_HRSTATUS_NICHT_VON_BENUTZER_IMPORTIERT && this.Rechtstraeger.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_OFFEN) ||
            // (this.VersionId && this.Rechtstraeger.CurrentVersionId === this.VersionId && this.Rechtstraeger.MahnwesenStatus.Id !== VwbPListDetailConst.VWBP_MAHNWESENSTATUS_NICHT_RELEVANT && this.Rechtstraeger.HrStatus.Id === VwbPListDetailConst.VWBP_RT_HRSTATUS_VON_BENUTZER_IMPORTIERT) ||
            // (this.LastMahnung && this.LastMahnung.Status.Id !== VwbPListDetailConst.VWBP_MAHNUNG_STATUS_ERLEDIGT) || 
            // (this.LastBusse && this.LastBusse.Status.Id !== VwbPListDetailConst.VWBP_BUSS_STATUS_ERLEDIGT)

            (this.Rechtstraeger.HrStatus.Id === VwbPListDetailConst.VWBP_RT_HRSTATUS_NICHT_VON_BENUTZER_IMPORTIERT && this.Rechtstraeger.MahnwesenStatus.Id !== VwbPListDetailConst.VWBP_MAHNWESENSTATUS_NICHT_RELEVANT) ||
            (this.VersionId && this.Rechtstraeger.CurrentVersionId === this.VersionId && this.Rechtstraeger.MahnwesenStatus.Id !== VwbPListDetailConst.VWBP_MAHNWESENSTATUS_NICHT_RELEVANT)
        );
    }

    doTaskNichtRelevant() {
        this.doTask([{ RechtstraegerId: this.RechtstraegerId }], MahnwesenTaskMode.NICHT_RELEVANT);
    }

    enableTaskMahnen() {
        return this.isMahnwesenAktivAndHasRecht() &&
        (
            (this.Rechtstraeger.HrStatus.Id === VwbPListDetailConst.VWBP_RT_HRSTATUS_NICHT_VON_BENUTZER_IMPORTIERT && this.Rechtstraeger.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_OFFEN) ||
            (this.VersionId && this.Rechtstraeger.CurrentVersionId === this.VersionId && this.Version.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_INBEARBEITUNG && this.Rechtstraeger.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_OFFEN)
        );
    }

    doTaskMahnen() {
        this.doTask([{ RechtstraegerId: this.RechtstraegerId }], MahnwesenTaskMode.MAHNEN);
    }

    enableTaskMahnfristSetzen() {
        return this.isMahnwesenAktivAndHasRecht() &&
            (this.LastMahnung?.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_OFFEN || this.LastMahnung?.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN);
    }

    doTaskMahnfristSetzen() {
        this.doTask([{ 'MahnungId': this.LastMahnung?.Id }], MahnwesenTaskMode.MAHNFRIST);
    }

    enableTaskBuessen() {
        return this.isMahnwesenAktivAndHasRecht() &&
            (this.LastMahnung?.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN || this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_BUSSFRIST_ABGELAUFEN);
    }

    doTaskBuessen() {
        this.ErneutBuessen = !this.LastMahnung || this.LastMahnung?.Status.Id !== VwbPListDetailConst.VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN;

        this.doTask(!this.ErneutBuessen ? [{ 'MahnungId': this.LastMahnung.Id }] : [{ 'BusseId': this.LastBusse.Id }], MahnwesenTaskMode.BUESSEN);
    }

    enableTaskLoeschverfahren() {
        return this.isMahnwesenAktivAndHasRecht() &&
            (this.LastMahnung?.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN || this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_BUSSFRIST_ABGELAUFEN);
    }

    doTaskLoeschverfahren() {
        this.doTask([{ RechtstraegerId: this.RechtstraegerId, Typ: this.RechtstraegerDetail.Typ }], MahnwesenTaskMode.LOESCHVERFAHREN);
    }

    enableTaskRechtsmittelfristSetzen() {
        return this.isMahnwesenAktivAndHasRecht() &&
            (this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_OFFEN || this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_RECHTSMITTELFRIST_ABGELAUFEN);
    }

    doTaskRechtsmittelfristSetzen() {
        this.doTask([{ 'BusseId': this.LastBusse?.Id }], MahnwesenTaskMode.RECHTSMITTELFRIST);
    }

    enableTaskEinzugsauftrag() {
        return this.isMahnwesenAktivAndHasRecht() &&
            this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_RECHTSMITTELFRIST_ABGELAUFEN;
    }

    doTaskEinzugsauftrag() {
        this.doTask([{ 'BusseId': this.LastBusse?.Id }], MahnwesenTaskMode.EINZUGSAUFTRAG);
    }

    enableTaskBussfristSetzen() {
        return this.isMahnwesenAktivAndHasRecht() &&
            this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_EINZUGSAUFTRAG;
    }

    doTaskBussfristSetzen() {
        this.doTask([{ 'BusseId': this.LastBusse?.Id }], MahnwesenTaskMode.BUSSFRIST);
    }

    // Rückgängig machen Tasks

    enableTaskNichtRelevantRueckgaengig() {
        return false; // this.isMahnwesenAktivAndHasRecht() && this.Rechtstraeger?.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_NICHT_RELEVANT;
    }

    doTaskNichtRelevantRueckgaengig() {
        this.RueckgaengigMode = MahnwesenRueckgaengigMode.NICHT_RELEVANT;
        this.doTask([{ RechtstraegerId: this.RechtstraegerId }], MahnwesenTaskMode.RUECKGAENGIG);
    }

    enableTaskMahnenRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && this.Rechtstraeger?.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_MAHNLAUF && this.LastMahnung && this.LastMahnung.Status.Id !== VwbPListDetailConst.VWBP_MAHNUNG_STATUS_ERLEDIGT;
    }

    doTaskMahnenRueckgaengig() {
        this.RueckgaengigMode = MahnwesenRueckgaengigMode.MAHNEN;
        this.doTask([{ RechtstraegerId: this.RechtstraegerId }], MahnwesenTaskMode.RUECKGAENGIG);
    }


    enableTaskBuessenRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && this.LastBusse && (this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_OFFEN || this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_RECHTSMITTELFRIST_ABGELAUFEN);
    }

    doTaskBuessenRueckgaengig() {
        this.RueckgaengigMode = MahnwesenRueckgaengigMode.BUESSEN;
        this.doTask([{ RechtstraegerId: this.RechtstraegerId }], MahnwesenTaskMode.RUECKGAENGIG);
    }

    enableTaskLoeschverfahrenRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && (this.Rechtstraeger?.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_LIQUIDATIONSVERFAHREN &&
            ((this.LastMahnung && this.LastMahnung.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_ERLEDIGT) ||
                (this.LastBusse && this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_ERLEDIGT)));
    }

    doTaskLoeschverfahrenRueckgaengig() {
        this.RueckgaengigMode = MahnwesenRueckgaengigMode.LOESCHVERFAHREN;
        this.doTask([{ RechtstraegerId: this.RechtstraegerId }], MahnwesenTaskMode.RUECKGAENGIG);
    }

    enableTaskEinzugsauftragRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && this.LastBusse && (this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_EINZUGSAUFTRAG || this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_BUSSFRIST_ABGELAUFEN);
    }

    doTaskEinzugsauftragRueckgaengig() {
        this.RueckgaengigMode = MahnwesenRueckgaengigMode.EINZUGSAUFTRAG;
        this.doTask([{ RechtstraegerId: this.RechtstraegerId }], MahnwesenTaskMode.RUECKGAENGIG);
    }

    OnSave() {
        this.CloseDialog();
        this.OnChanged.emit(null);
        this.LoadData();
    }

    CloseDialog() {
        this.selectedRT = [];
        this.taskDialog.close();
    }
}
