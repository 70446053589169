import { Expose, Type } from 'class-transformer';

import { TechRole } from '../system/techrole.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import TransformDate from '../shared/transformdate';
import { ModelMeta } from '../system/modelmeta.model';

export class BusinesscaseDef extends BaseModel {  
    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }
    
    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }
    
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get DefaultTitle(): string { return this.getProperty('DefaultTitle'); }
    public set DefaultTitle(value: string) { this.setProperty('DefaultTitle', value); }
    
    @Expose()
    public get StartNumber(): number { return this.getProperty('StartNumber'); }
    public set StartNumber(value: number) { this.setProperty('StartNumber', value); }
    
    @Expose()
    public get NumberPrefix(): string { return this.getProperty('NumberPrefix'); }
    public set NumberPrefix(value: string) { this.setProperty('NumberPrefix', value); }
    
    @Expose()
    public get BusinesscaseDefId(): string { return this.getProperty('BusinesscaseDefId'); }
    public set BusinesscaseDefId(value: string) { this.setProperty('BusinesscaseDefId', value); }

    @Expose()
    public get DueIn_Months(): number { return this.getProperty('DueIn_Months'); }
    public set DueIn_Months(value: number) { this.setProperty('DueIn_Months', value); }
    
    @Expose()
    public get DueIn_Days(): number { return this.getProperty('DueIn_Days'); }
    public set DueIn_Days(value: number) { this.setProperty('DueIn_Days', value); }
    
    @Expose()
    public get DueIn_Hours(): number { return this.getProperty('DueIn_Hours'); }
    public set DueIn_Hours(value: number) { this.setProperty('DueIn_Hours', value); }
    
    @Expose()
    public get DueIn_Minutes(): number { return this.getProperty('DueIn_Minutes'); }
    public set DueIn_Minutes(value: number) { this.setProperty('DueIn_Minutes', value); }
    
    @Expose()
    public get Context1ModelMeta_ID(): string { return this.getModelId('Context1ModelMeta_ID'); }
    public set Context1ModelMeta_ID(value: string) { this.setModelId('Context1ModelMeta_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get Context1ModelMeta(): ModelMeta { return this.getModel('Context1ModelMeta'); }
    public set Context1ModelMeta(value: ModelMeta) { this.setModel('Context1ModelMeta', value); }
    
    @Expose()
    public get Context2ModelMeta_ID(): string { return this.getModelId('Context2ModelMeta_ID'); }
    public set Context2ModelMeta_ID(value: string) { this.setModelId('Context2ModelMeta_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get Context2ModelMeta(): ModelMeta { return this.getModel('Context2ModelMeta'); }
    public set Context2ModelMeta(value: ModelMeta) { this.setModel('Context2ModelMeta', value); }
    
    @Expose()
    public get Context1Bezeichnung1(): string { return this.getProperty('Context1Bezeichnung1'); }
    public set Context1Bezeichnung1(value: string) { this.setProperty('Context1Bezeichnung1', value); }

    @Expose()
    public get Context1Bezeichnung2(): string { return this.getProperty('Context1Bezeichnung2'); }
    public set Context1Bezeichnung2(value: string) { this.setProperty('Context1Bezeichnung2', value); }

    @Expose()
    public get Context1Bezeichnung3(): string { return this.getProperty('Context1Bezeichnung3'); }
    public set Context1Bezeichnung3(value: string) { this.setProperty('Context1Bezeichnung3', value); }

    @Expose()
    public get Context1Bezeichnung4(): string { return this.getProperty('Context1Bezeichnung4'); }
    public set Context1Bezeichnung4(value: string) { this.setProperty('Context1Bezeichnung4', value); }

    @Expose()
    public get Context1Bezeichnung5(): string { return this.getProperty('Context1Bezeichnung5'); }
    public set Context1Bezeichnung5(value: string) { this.setProperty('Context1Bezeichnung5', value); }

    @Expose()
    public get Context1Bezeichnung6(): string { return this.getProperty('Context1Bezeichnung6'); }
    public set Context1Bezeichnung6(value: string) { this.setProperty('Context1Bezeichnung6', value); }

    @Expose()
    public get Context1Bezeichnung7(): string { return this.getProperty('Context1Bezeichnung7'); }
    public set Context1Bezeichnung7(value: string) { this.setProperty('Context1Bezeichnung7', value); }

    @Expose()
    public get Context2Bezeichnung1(): string { return this.getProperty('Context2Bezeichnung1'); }
    public set Context2Bezeichnung1(value: string) { this.setProperty('Context2Bezeichnung1', value); }

    @Expose()
    public get Context2Bezeichnung2(): string { return this.getProperty('Context2Bezeichnung2'); }
    public set Context2Bezeichnung2(value: string) { this.setProperty('Context2Bezeichnung2', value); }

    @Expose()
    public get Context2Bezeichnung3(): string { return this.getProperty('Context2Bezeichnung3'); }
    public set Context2Bezeichnung3(value: string) { this.setProperty('Context2Bezeichnung3', value); }

    @Expose()
    public get Context2Bezeichnung4(): string { return this.getProperty('Context2Bezeichnung4'); }
    public set Context2Bezeichnung4(value: string) { this.setProperty('Context2Bezeichnung4', value); }

    @Expose()
    public get Context2Bezeichnung5(): string { return this.getProperty('Context2Bezeichnung5'); }
    public set Context2Bezeichnung5(value: string) { this.setProperty('Context2Bezeichnung5', value); }

    @Expose()
    public get Context2Bezeichnung6(): string { return this.getProperty('Context2Bezeichnung6'); }
    public set Context2Bezeichnung6(value: string) { this.setProperty('Context2Bezeichnung6', value); }

    @Expose()
    public get Context2Bezeichnung7(): string { return this.getProperty('Context2Bezeichnung7'); }
    public set Context2Bezeichnung7(value: string) { this.setProperty('Context2Bezeichnung7', value); }

    @Expose()
    public get CreateRole_ID(): string { return this.getModelId('CreateRole_ID'); }
    public set CreateRole_ID(value: string) { this.setModelId('CreateRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get CreateRole(): TechRole { return this.getModel('CreateRole'); }
    public set CreateRole(value: TechRole) { this.setModel('CreateRole', value); }
    
    @Expose()
    public get vonWorkflowDef_ID(): string { return this.getModelId('vonWorkflowDef_ID'); }
    public set vonWorkflowDef_ID(value: string) { this.setModelId('vonWorkflowDef_ID', value); }
 
    @Expose()
    public get CustomPropCollectionDef_ID(): string { return this.getModelId('CustomPropCollectionDef_ID'); }
    public set CustomPropCollectionDef_ID(value: string) { this.setModelId('CustomPropCollectionDef_ID', value); }
 
    @Expose()
    @Type(() => ListType)
    public get Watcher(): ListType { return this.getProperty('Watcher'); }
    public set Watcher(value: ListType) { this.setProperty('Watcher', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DefaultPriority(): ListType { return this.getProperty('DefaultPriority'); }
    public set DefaultPriority(value: ListType) { this.setProperty('DefaultPriority', value); }
        
    @Expose()
    @Type(() => ListType)
    public get ShowDef(): ListType { return this.getProperty('ShowDef'); }
    public set ShowDef(value: ListType) { this.setProperty('ShowDef', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ShowDescription(): ListType { return this.getProperty('ShowDescription'); }
    public set ShowDescription(value: ListType) { this.setProperty('ShowDescription', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DescriptionMandatory(): ListType { return this.getProperty('DescriptionMandatory'); }
    public set DescriptionMandatory(value: ListType) { this.setProperty('DescriptionMandatory', value); }    
    
    @Expose()
    @Type(() => ListType)
    public get ShowPriority(): ListType { return this.getProperty('ShowPriority'); }
    public set ShowPriority(value: ListType) { this.setProperty('ShowPriority', value); }

    @Expose()
    @Type(() => ListType)
    public get ShowDueDate(): ListType { return this.getProperty('ShowDueDate'); }
    public set ShowDueDate(value: ListType) { this.setProperty('ShowDueDate', value); }
    
    @Expose()
    @Type(() => ListType)
    public get EditFormOnCreate(): ListType { return this.getProperty('EditFormOnCreate'); }
    public set EditFormOnCreate(value: ListType) { this.setProperty('EditFormOnCreate', value); }

    @Expose()
    @Type(() => ListType)
    public get CanCreateComments(): ListType { return this.getProperty('CanCreateComments'); }
    public set CanCreateComments(value: ListType) { this.setProperty('CanCreateComments', value); }
    
    @Expose()
    @Type(() => ListType)
    public get CanCreateActivities(): ListType { return this.getProperty('CanCreateActivities'); }
    public set CanCreateActivities(value: ListType) { this.setProperty('CanCreateActivities', value); }

    @Expose()
    @Type(() => ListType)
    public get Context1Mandatory(): ListType { return this.getProperty('Context1Mandatory'); }
    public set Context1Mandatory(value: ListType) { this.setProperty('Context1Mandatory', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Context2Mandatory(): ListType { return this.getProperty('Context2Mandatory'); }
    public set Context2Mandatory(value: ListType) { this.setProperty('Context2Mandatory', value); }
            
    @Expose()
    @Type(() => ListType)
    public get Context1CtxRights(): ListType { return this.getProperty('Context1CtxRights'); }
    public set Context1CtxRights(value: ListType) { this.setProperty('Context1CtxRights', value); }
            
    @Expose()
    @Type(() => ListType)
    public get Context2CtxRights(): ListType { return this.getProperty('Context2CtxRights'); }
    public set Context2CtxRights(value: ListType) { this.setProperty('Context2CtxRights', value); }
    
    @Expose()
    @Type(() => ListType)
    public get CreateVisibility(): ListType { return this.getProperty('CreateVisibility'); }
    public set CreateVisibility(value: ListType) { this.setProperty('CreateVisibility', value); }
    
}
