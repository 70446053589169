import { Expose, Type } from 'class-transformer';

import { ModelStateGroup } from '../system/modelstategroup.model';
import { ModelState } from '../system/modelstate.model';
import { ModelMeta } from '../system/modelmeta.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class BarcodeDocDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get DocIDPrefix(): string { return this.getProperty('DocIDPrefix'); }
    public set DocIDPrefix(value: string) { this.setProperty('DocIDPrefix', value); }
    
    @Expose()
    public get BarcodePosX(): number { return this.getProperty('BarcodePosX'); }
    public set BarcodePosX(value: number) { this.setProperty('BarcodePosX', value); }
    
    @Expose()
    public get BarcodePosY(): number { return this.getProperty('BarcodePosY'); }
    public set BarcodePosY(value: number) { this.setProperty('BarcodePosY', value); }
    
    @Expose()
    public get BarcodeWidth(): number { return this.getProperty('BarcodeWidth'); }
    public set BarcodeWidth(value: number) { this.setProperty('BarcodeWidth', value); }
    
    @Expose()
    public get BarcodeHeight(): number { return this.getProperty('BarcodeHeight'); }
    public set BarcodeHeight(value: number) { this.setProperty('BarcodeHeight', value); }
    
    @Expose()
    public get BarcodeFormat(): string { return this.getProperty('BarcodeFormat'); }
    public set BarcodeFormat(value: string) { this.setProperty('BarcodeFormat', value); }
    
    @Expose()
    public get BarcodeTrenner(): string { return this.getProperty('BarcodeTrenner'); }
    public set BarcodeTrenner(value: string) { this.setProperty('BarcodeTrenner', value); }
    
    @Expose()
    public get ModelStateGroup_ID(): string { return this.getModelId('ModelStateGroup_ID'); }
    public set ModelStateGroup_ID(value: string) { this.setModelId('ModelStateGroup_ID', value); }
 
    @Expose()
    @Type(() => ModelStateGroup)
    public get ModelStateGroup(): ModelStateGroup { return this.getModel('ModelStateGroup'); }
    public set ModelStateGroup(value: ModelStateGroup) { this.setModel('ModelStateGroup', value); }
    
    @Expose()
    public get NewDocModelState_ID(): string { return this.getModelId('NewDocModelState_ID'); }
    public set NewDocModelState_ID(value: string) { this.setModelId('NewDocModelState_ID', value); }
 
    @Expose()
    @Type(() => ModelState)
    public get NewDocModelState(): ModelState { return this.getModel('NewDocModelState'); }
    public set NewDocModelState(value: ModelState) { this.setModel('NewDocModelState', value); }
    
    @Expose()
    public get UpdateDocModelState_ID(): string { return this.getModelId('UpdateDocModelState_ID'); }
    public set UpdateDocModelState_ID(value: string) { this.setModelId('UpdateDocModelState_ID', value); }
 
    @Expose()
    @Type(() => ModelState)
    public get UpdateDocModelState(): ModelState { return this.getModel('UpdateDocModelState'); }
    public set UpdateDocModelState(value: ModelState) { this.setModel('UpdateDocModelState', value); }
    
    @Expose()
    public get Kontext1_ID(): string { return this.getModelId('Kontext1_ID'); }
    public set Kontext1_ID(value: string) { this.setModelId('Kontext1_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get Kontext1(): ModelMeta { return this.getModel('Kontext1'); }
    public set Kontext1(value: ModelMeta) { this.setModel('Kontext1', value); }
    
    @Expose()
    public get Kontext2_ID(): string { return this.getModelId('Kontext2_ID'); }
    public set Kontext2_ID(value: string) { this.setModelId('Kontext2_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get Kontext2(): ModelMeta { return this.getModel('Kontext2'); }
    public set Kontext2(value: ModelMeta) { this.setModel('Kontext2', value); }
    
    @Expose()
    public get Kontext3_ID(): string { return this.getModelId('Kontext3_ID'); }
    public set Kontext3_ID(value: string) { this.setModelId('Kontext3_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get Kontext3(): ModelMeta { return this.getModel('Kontext3'); }
    public set Kontext3(value: ModelMeta) { this.setModel('Kontext3', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Verwendung(): ListType { return this.getProperty('Verwendung'); }
    public set Verwendung(value: ListType) { this.setProperty('Verwendung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DocIDAufBarcodeDrucken(): ListType { return this.getProperty('DocIDAufBarcodeDrucken'); }
    public set DocIDAufBarcodeDrucken(value: ListType) { this.setProperty('DocIDAufBarcodeDrucken', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DocIDIstSchluesselProperty(): ListType { return this.getProperty('DocIDIstSchluesselProperty'); }
    public set DocIDIstSchluesselProperty(value: ListType) { this.setProperty('DocIDIstSchluesselProperty', value); }
    
    @Expose()
    @Type(() => ListType)
    public get BarcodePage(): ListType { return this.getProperty('BarcodePage'); }
    public set BarcodePage(value: ListType) { this.setProperty('BarcodePage', value); }
    
}
