import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'avi-core-serialization-overview',
    templateUrl: './serialization-overview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviSerializationOverviewComponent implements OnInit {

    constructor(
    ) { 
    }


    async ngOnInit() {
    }
}
