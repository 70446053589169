import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ScriptRuleDef } from '@avi-x/avi-dto/system/scriptruledef.model';
import { NgxPermissionsService } from 'ngx-permissions';

const nameof = (nameFunction: ((obj: ScriptRuleDef) => any)) => typed_nameof<ScriptRuleDef>(nameFunction);

@Component({
    selector: 'avi-scriptruledef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRuleDefFormComponent extends AviAbstractModelFormComponent<ScriptRuleDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFSCRIPTRULEDEF';
    BaseUrl = 'scriptruledef';
    ClassType = ScriptRuleDef;
    FormType = AviScriptRuleDefFormComponent;
	    
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
        public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

        this.CRUDBaseRight = 'CAFScriptRulesetDef';
    }

    public async createModel(): Promise<ScriptRuleDef> {
        return await this.apiService.getModel(this.ClassType, `${this.getBaseUrl()}/create/${this.ContextId}`);
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1) && this.Model.Bezeichnung1 && !this.Model.Bezeichnung2)
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'Gültig ab', false).setNullDateLow().setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'Gültig bis', false).setNullDateHigh().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.RuleID), 'Regel Nr.', 0, true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateEmpty('empty').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AutoClose), 'Auto-erledigen', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AutoCloseSameRule), 'Gleiche Regeln erledigen', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Assignment1), 'Zuweisung 1', 'caf_ruledef_assignment', true, true, this.ReadOnly).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Assignment2), 'Zuweisung 2', 'caf_ruledef_assignment', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'Bezeichnung 1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'Bezeichnung 2', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Expression), 'Expression', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Precondition), 'Precondition', false).setMDSizeFull());
    }
}
