import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AviApiService } from '../../../services/api.service';
import { AviCommonService } from '../../../services/common.service';
import { AviBaseFormComponent } from '../../base-form/base-form.component';
import { AviBaseSearcherComponent } from '../../base-searcher/base-searcher.component';
import { AviDialogComponent } from '../../dialog/dialog.component';
import { AviSysMessageFormComponent } from '../sysmessage-form/sysmessage-form.component';
import { ISessionData } from '../../../services/session-controller.service';


@Component({
    selector: 'avi-core-sysmessage-searcher',
    templateUrl: './sysmessage-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviSysMessageSearcherComponent implements OnInit {
    @Input('session-data')
    SessionData: ISessionData;

    public Loading: boolean = false;

    FormModelId: string = null;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @ViewChild('sysMessageFormDialog', { static: false })
    sysMessageFormDialog: AviDialogComponent;

    @ViewChild('sysMessageForm', { static: false })
    public sysMessageForm: AviSysMessageFormComponent;

    @Input('api-prefix')
    public ApiPrefix: string = '';

    public Card = true;

    private SelectedRows: any[] = [];
    public DetailModelId: string = null;

    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', action: () => { this.closeForm(); } }];

    constructor(
        private commonService: AviCommonService,
        private apiService: AviApiService,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) { }


    ngOnInit() {
        if ('apiprefix' in this.activatedRoute.snapshot.data)
            this.ApiPrefix = this.activatedRoute.snapshot.data['apiprefix'];
    }

    async onSelectionChanged(data: any[]) {
        this.SelectedRows = data;

        if (this.SelectedRows.length > 0) {
            this.DetailModelId = this.SelectedRows[0].Id;
        }
    }

    onSearcherInit() {
        this.searcher.beginInit();
        this.searcher.addDateColumn('GueltigAb', 'Gültig ab', 'short', true);
        this.searcher.addDateColumn('GueltigBis', 'Gültig bis', 'short', true);
        this.searcher.addTextColumn('Text', 'Mitteilung', true);
        this.searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get(`${this.ApiPrefix ?? ''}/sysmessage`);

        res = this.searcher.ApplyColumnFilters(res);
        res = this.searcher.ApplyColumnSorting(res);
        return res;
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    onDoubleClickSearcher(data) {
        this.editSysMessage();
    }

    public createSysMessage() {
        this.FormModelId = '0';
        this.sysMessageFormDialog.open();
    }

    public editSysMessage() {
        if (this.SelectedRows.length > 0) {
            this.FormModelId = this.SelectedRows[0].Id;
            this.sysMessageFormDialog.open();
        }
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.searcher.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.sysMessageFormDialog.close();
    }

    public deleteSysMessage() {
        if (this.SelectedRows.length > 0) {
            const id = this.SelectedRows[0].Id;

            this.commonService.confirmDelete(`Möchten Sie die Systemmitteilung wirklich löschen?`,
                () => {
                    this.apiService.delete(`${this.ApiPrefix ?? ''}/sysmessage/${id}`).then(r => {
                        this.commonService.notificateSuccess('Gelöscht');
                        this.searcher.forceRefresh();
                    });
                }
            );
        }
    }
}
