<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

    <avi-core-view-panel [toggleable]="true"
        [header]="'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.CAFDOCUMATRIXTEMPLATE_1' | translate" class="w-100p">

        <avi-documatrixtemplate-form [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId"
            [enable-taskmenu]="true" taskmenu-position="bottom"></avi-documatrixtemplate-form>
    </avi-core-view-panel>

    <avi-core-view-panel [toggleable]="true"
        [header]="'CORE.COMMON.MODEL.CAFDOCPROPCONTAINER.CAFDOCPROPCONTAINER_N' | translate"
        class="w-100p">
        <avi-docpropcontainertemplatelink-searcher [context-id]="ModelId" [card]="false" [contents-padding]="false" [enable-panel]="false"></avi-docpropcontainertemplatelink-searcher>
    </avi-core-view-panel>

</avi-core-page>