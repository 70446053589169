import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';

export class NXBookmark extends BaseModel {  
    @Expose()
    public get Sorter(): number { return this.getProperty('Sorter'); }
    public set Sorter(value: number) { this.setProperty('Sorter', value); }

    @Expose()
    public get Description(): string { return this.getProperty('Description'); }
    public set Description(value: string) { this.setProperty('Description', value); }
    
    @Expose()
    public get Session(): string { return this.getProperty('Session'); }
    public set Session(value: string) { this.setProperty('Session', value); }
    
    @Expose()
    public get Modul(): string { return this.getProperty('Modul'); }
    public set Modul(value: string) { this.setProperty('Modul', value); }
    
    @Expose()
    public get ContextId(): string { return this.getProperty('ContextId'); }
    public set ContextId(value: string) { this.setProperty('ContextId', value); }
    
}
