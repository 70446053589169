<avi-core-page
    title="XML Job Log"
    [card]="Card"
    [collapsible]="false"
    [expanded]="true"
    [no-box-shadow]="true"
    [contents-padding]="true"
>
    <avi-core-base-searcher
        #logSearcher
        [hide-when-noresults]="true"
        [use-router-params]="false"
        [search-delegate]="SearchDelegate"
        [rows]="0"
        [auto-search]="false"
        [show-autofilter]="true"
        [toolbar]="false"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [lazy-loading]="true"
        [card]="false"
        (onInit)="onLogInit($event)"
        searcher-title="Jobs"
        [show-totalrecords]="false"
        [select-first-result]="false"
    >
    </avi-core-base-searcher>
</avi-core-page>
