import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { Expose, Type } from 'class-transformer';

export class VwbPVerlauf {
    @Expose()
    public Id: string;

    @Expose()
    public Aktiv: string;

    @Expose()
    public ErstelltVon: string;

    @Expose()
    @Type(() => Date)
    public ErstellDatum: Date;

    @Expose()
    @Type(() => Date)
    public Datum: Date;

    @Expose()
    public StatusAenderungId: string;

    @Expose()
    public CommentId: string;

    @Expose()
    public Laufnummer: number;

    @Expose()
    @Type(() => ListType)
    public Event: ListType;

    @Expose()
    public Beschreibung: string;

    @Expose()
    public Bemerkung: string;

    @Expose()
    public RechtstraegerId: string;

}
