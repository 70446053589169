import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviJobFormComponent } from './job-form.component';

import { JobSearchResponseDto } from '@avi-x/avi-dto/system/jobsearchresponsedto.model';
import { typed_nameof } from '../utils/nameof';
import { AviAbstractModelSearcherComponent } from '../base-searcher/abstract-model-searcher.component';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviSessionControllerService } from '../../services/session-controller.service';
import { AviBaseSearcherComponent } from '../base-searcher/base-searcher.component';
import { ActivatedRoute } from '@angular/router';
import { AviJobService } from '../../services/job.service';
import { Subscription } from 'rxjs';

const nameof = (nameFunction: ((obj: JobSearchResponseDto) => any)) => typed_nameof<JobSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-job-searcher',
    templateUrl: './job-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJobSearcherComponent extends AviAbstractModelSearcherComponent implements OnDestroy {  
    ClassName = 'CORE.COMMON.MODEL.JOB';
    SettingsKey = 'settings.searcher.job';
    BaseUrl = 'job';
	FormType = AviJobFormComponent;

    CanEdit: boolean = false;
    ApiPrefix: string = null;

    sub: Subscription;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef,
        public jobService: AviJobService,
        public activatedRoute: ActivatedRoute
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('Job schreiben').then(w => this.CanEdit = w);
    }

    ngOnInit(): void {
        
        super.ngOnInit();

        if ('BaseUrl' in this.activatedRoute.snapshot.data)
            this.BaseUrl = this.activatedRoute.snapshot.data['BaseUrl'];

        if ('ApiPrefix' in this.activatedRoute.snapshot.data)
            this.ApiPrefix = this.activatedRoute.snapshot.data['ApiPrefix'];

        if ('UseRouting' in this.activatedRoute.snapshot.data)
            this.jobService.UseRouting = this.activatedRoute.snapshot.data['UseRouting'];

        if ('RouterPrefix' in this.activatedRoute.snapshot.data)
            this.jobService.RouterPrefix = this.activatedRoute.snapshot.data['RouterPrefix'];

        this.sub = this.activatedRoute.data.subscribe(params => {
            if (params && params['id']) {
                this.setModel(params['id']);
            }
        }); 
    }

    ngOnDestroy(): void {
        if (this.sub)
            this.sub.unsubscribe();
    }

    setModel(modelId: string) {
        this.ContextId = modelId || '0';
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            //this.sessionController.openSession('job-index', this.SelectedRows.Id);
            //this.editObject(this.SelectedRows);
            this.jobService.openJob(this.SelectedRows.Id);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        //searcher.addIntegerColumn(nameof(c => c.JobID), 'CORE.COMMON.MODEL.JOB.JOBID', true);
        
        searcher.addTextColumn(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.JOB.KURZBEZ1', true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.JOB.BEZEICHNUNG1', true);

        searcher.addListTypeColumn(nameof(c => c.Status), 'CORE.COMMON.MODEL.JOB.STATUS', 'job_status', true, true, null, this.ApiPrefix);

        searcher.addDateColumn(nameof(c => c.StartedAt), 'CORE.COMMON.MODEL.JOB.STARTEDAT', "short", true);
        searcher.addDateColumn(nameof(c => c.FinishedAt), 'CORE.COMMON.MODEL.JOB.FINISHEDAT', "short", true);
        
        searcher.addIntegerColumn(nameof(c => c.TotalItems), 'CORE.COMMON.MODEL.JOB.TOTALITEMS', true);
        searcher.addIntegerColumn(nameof(c => c.TotalWorkups), 'CORE.COMMON.MODEL.JOB.TOTALWORKUPS', true);

        searcher.addIntegerColumn(nameof(c => c.TotalSucceeded), 'CORE.COMMON.MODEL.JOB.TOTALSUCCEEDED', true);
        searcher.addIntegerColumn(nameof(c => c.TotalErrors), 'CORE.COMMON.MODEL.JOB.TOTALERRORS', true);

        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(JobSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
