import { Expose, Type } from 'class-transformer';

import { AppUser } from '../system/appuser.model';
import { AppUserGroup } from '../system/appusergroup.model';
import { BaseModel } from '../shared/basemodel.model';

export class AppUserGroupLink extends BaseModel {  
    @Expose()
    public get AppUserRef_ID(): string { return this.getModelId('AppUserRef_ID'); }
    public set AppUserRef_ID(value: string) { this.setModelId('AppUserRef_ID', value); }
 
    @Expose()
    @Type(() => AppUser)
    public get AppUserRef(): AppUser { return this.getModel('AppUserRef'); }
    public set AppUserRef(value: AppUser) { this.setModel('AppUserRef', value); }
    
    @Expose()
    public get AppUserGroupRef_ID(): string { return this.getModelId('AppUserGroupRef_ID'); }
    public set AppUserGroupRef_ID(value: string) { this.setModelId('AppUserGroupRef_ID', value); }
 
    @Expose()
    @Type(() => AppUserGroup)
    public get AppUserGroupRef(): AppUserGroup { return this.getModel('AppUserGroupRef'); }
    public set AppUserGroupRef(value: AppUserGroup) { this.setModel('AppUserGroupRef', value); }
    
}
