<avi-core-dialog
    #resourceFormDialog
    identifier="resourceFormDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
    (onOpenFinished)="onOpenEditForm()"
>
    <avi-core-resource-form
        #resourceForm
        [model]="formModel"
        [sprache]="selectedSprache"
        [api-prefix]="ApiPrefix"
        (onSavedSuccessful)="onSavedSuccessful($event)"
        [redirect-after-save]="null"
        [action-buttons]="formActionButtons"
    >
    </avi-core-resource-form>
</avi-core-dialog>

<div class="mt-2">
    <avi-core-view-panel [toggleable]="false" [header]="'Resourcen'" class="w-100p">

    <div class="grid">
        <div class="col-4" style="margin-top: 10px;">
            <span class="p-float-label">
                <p-dropdown [options]="sprachen"
                    [(ngModel)]="selectedSprache"
                    optionLabel="Representation"
                    inputId="avi-resource-sprache-selector-dropdown"
                    [styleClass]="'w-full'"
                    [virtualScroll]="true"
                    emptyMessage="Keine Sprachen vorhanden"
                    [itemSize]="8"
                >

                    <ng-template pTemplate="selectedItem">
                        <div class="sprache-item sprache-item-value"
                            *ngIf="selectedSprache">
                            <div>{{selectedSprache.Representation}}</div>
                        </div>
                    </ng-template>
                    <ng-template let-sprache
                        pTemplate="item">
                        <div class="sprache-item">
                            <div>{{sprache.Representation}}</div>
                        </div>
                    </ng-template>

                </p-dropdown>
                <label for="avi-resource-sprache-selector-dropdown">{{'CORE.COMMON.SPRACHE' | translate }}</label>
            </span>
        </div>

    </div>

    <div class="searcher-toolbar-toolbar"
        [style.display]="'inherit'">
        <div class="pb-2 pt-2"
            fxLayout="row"
            fxLayoutGap="1em"
            fxLayoutAlign="space-between start">

            <div fxFlex="initial">
                <button *ngIf="ShowCreateButton()"
                    pButton
                    icon="pi pi-plus"
                    class="p-button-secondary"
                    [pTooltip]="'CORE.COMMON.NEU' | translate"
                    tooltipPosition="left"       
                    (click)="createResource()"
                ></button>
            </div>

            <div fxFlex="initial"
                [style.display]="'inherit'">
                <button
                    pButton
                    icon="pi pi-refresh"
                    class="p-button-secondary p-button-flat searcher-refresh-button toolbar-button-icon-only"
                    [pTooltip]="'CORE.COMMON.AKTUALISIEREN' | translate"
                    tooltipPosition="left"       
                    (click)="DoSearch()"
                ></button>
            </div>
        </div>
    </div>

    <div>
        <p-table #table
            [value]="results"
            [loading]="loading"
            [paginator]="UsePaginator"
            [alwaysShowPaginator]="false"        
            [rows]="MaxRowsPerPage"
            selectionMode="single"
            [sortField]="'Key'"
            [sortOrder]="1"        
            [tableStyle]="getTableStyle"
            [tableStyleClass]="'avi-base-searcher'"
            (onPage)="onPage($event)"
            [(selection)]="SelectedRow"
            [selectionMode]="'single'"
            [contextMenu]="cm"
            contextMenuSelectionMode="joint"
            [(contextMenuSelection)]="SelectedRow"
            (onContextMenuSelect)="_onShowContextMenu($event)"
            dataKey="Key"
            >

            <ng-template pTemplate="header">
                <tr class="no-border-left no-border-right">
                    <th class="no-border-left no-border-right" width="10%" pSortableColumn="AppLevel">{{'CORE.RESOURCE.APPLEVEL' | translate}} <avi-core-sorticon [field]="'AppLevel'"></avi-core-sorticon>
                    </th>
                    <th class="no-border-left no-border-right" width="30%" pSortableColumn="Key">{{'CORE.RESOURCE.KEY' | translate}} <avi-core-sorticon [field]="'Key'"></avi-core-sorticon>
                    </th>
                    <th class="no-border-left no-border-right" width="60%" pSortableColumn="Value">{{'CORE.RESOURCE.VALUE' | translate}} <avi-core-sorticon [field]="'Value'"></avi-core-sorticon>
                    </th>
                </tr>
                <tr class="searcher-filter-row no-border-left no-border-right">
                    <td class="searcher-autofilter-color no-border-left no-border-right">
                        <!--
                        <div class="p-inputgroup">
                            <p-multiSelect scrollHeight="400px"
                                [(ngModel)]="appLevelFilter"
                                [options]="appLevelFilterItems"
                                defaultLabel="Filter"                            
                                optionLabel="label"
                                appendTo="body"
                                styleClass="searcher-filter"
                                class="searcher-filter"
                                [panelStyle]="{ 'z-index': 99999 }"
                                [autoZIndex]="false"
                                (onChange)="onEnumFilter(appLevelFilter, 'AppLevel')">
                                <ng-template let-value
                                    pTemplate="selectedItems">
                                    <div class="multiselect-item multiselect-item-value"
                                        *ngFor="let option of appLevelFilter">
                                        {{ option.label }}
                                    </div>
                                    <div *ngIf="!appLevelFilter || appLevelFilter.length === 0"
                                        class="multiselect-placeholder">
                                        Filter
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                            <div *ngIf="table.filters['AppLevel'] && table.filters['AppLevel']['value']"
                                class="p-inputgroup-addon color-red"
                                (click)="clearColumnFilter('AppLevel', 'equals')">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>
                        -->
                        <div class="p-inputgroup">
                            <p-dropdown
                                [(ngModel)]="appLevelFilter"
                                [options]="appLevelFilterItems"
                                placeholder="Filter"
                                [showClear]="false"
                                optionLabel="label"
                                appendTo="body"
                                styleClass="searcher-filter"
                                class="searcher-filter"
                                (onChange)="onEnumFilter(appLevelFilter, 'AppLevel')">
                            </p-dropdown>
                            <div *ngIf="table.filters['AppLevel'] && table.filters['AppLevel']['value']"
                                class="p-inputgroup-addon color-red"
                                (click)="clearColumnFilter('AppLevel', 'equals')">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>
                    </td>
                    <td class="searcher-autofilter-color ui-fluid no-border-left no-border-right">
                        <div class="p-inputgroup">
                            <input pInputText
                                class="searcher-filter"    
                                placeholder="Filter"
                                type="text"
                                (input)="onInputTextFilter($event.target['value'], 'Key', 'contains')"
                                [value]="table.filters['Key'] ? table.filters['Key']['value'] : null"/>
                        
                            <div *ngIf="table.filters['Key'] && table.filters['Key']['value']"
                                class="p-inputgroup-addon color-red"
                                (click)="clearColumnFilter('Key', 'contains')">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>
                    </td>
                    <td class="ui-fluid" class="searcher-autofilter-color no-border-left no-border-right">
                        <div class="p-inputgroup">
                            <input pInputText
                                class="searcher-filter"    
                                placeholder="Filter"
                                type="text"
                                (input)="onInputTextFilter($event.target['value'], 'Value', 'contains')"
                                [value]="table.filters['Value'] ? table.filters['Value']['value'] : null"/>
                        
                            <div *ngIf="table.filters['Value'] && table.filters['Value']['value']"
                                class="p-inputgroup-addon color-red"
                                (click)="clearColumnFilter('Value', 'contains')">
                                <i class="pi pi-times"></i>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="body"
                let-result>
                <tr class="no-border-left no-border-right"
                    [pSelectableRow]="result"
                    [pRowToggler]="result" 
                    (dblclick)="onDoubleClick(result)"
                    [pContextMenuRow]="result"
                >
                    <td class="no-border-left no-border-right ui-resizable-column">{{getAppLevelRepresentation(result)}}</td>
                    <td class="no-border-left no-border-right ui-resizable-column">{{result.Key}}</td>
                    <td class="no-border-left no-border-right ui-resizable-column">{{result.Value}}</td>
                </tr>
            </ng-template>

        </p-table>

        <div class="text-right m-1 searcher-records">
            <small>
                <span *ngIf="TotalRecords != null">
                    {{ 'CORE.COMMON.TOTAL' | translate }}: <span class="searcher-num-records">{{ TotalRecords | number }}</span><br />
                </span>
            </small>
        </div>
    </div>

</avi-core-view-panel>
</div>

<p-contextMenu #cm [model]="MenuItems" appendTo="body"></p-contextMenu>