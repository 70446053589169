<!-- <h1 class="pageTitle">{{ this.Model?.bezeichnung1 }}</h1> -->

<div class="ui-g ui-g-nopad " [ngStyle]="{'height': height}" style="min-height: 200px" >
<gridster class="background-transparent" [options]="options">
    <gridster-item class="no-pad inner card gridster-item-fix" [ngClass]="{'gridster-maximize': item.maximized}" [item]="item" *ngFor="let item of dashboard">
        <div class="inner overflow-auto" style="height:100%" [ngSwitch]="item.type">

            <!-- <mat-icon class="gridster-toolbar-button-drag">open_with</mat-icon> -->
            <mat-icon (click)="changeIcon(item)" class="gridster-toolbar-button-maximize">{{item.maximize_button}}</mat-icon>

            <avi-report-querydef-exec-graph *ngSwitchCase="InfoDeskDetailType.GRAPH" class="gridster-item-content" (onParameterValueChange)="onGraphParameterValueChange($event)" graph-min-height="100px" [showtable]="false" [card]="false" [collapsible]="false" [fill-height]="true" #graphs [id]="item.id"></avi-report-querydef-exec-graph>
            <avi-report-querydef-exec-searcher *ngSwitchCase="InfoDeskDetailType.QUERYDEF" #searchers class="gridster-item-content" [id]="item.id" (onParameterValueChange)="onGraphParameterValueChange($event)" [rows]=40 [card]="false" [collapsible]="false" [fill-height]="true"></avi-report-querydef-exec-searcher>
        </div>
    </gridster-item>
</gridster>

</div>
