import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, ObjectUtils, typed_nameof } from '@avi-x/avi-core';
import { ScriptRuleOutputCommentDto } from '@avi-x/avi-dto/system/scriptruleoutputcommentdto.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: ScriptRuleOutputCommentDto) => any)) => typed_nameof<ScriptRuleOutputCommentDto>(nameFunction);

@Component({
    selector: 'avi-scriptruleoutput-comment-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRuleOutputCommentFormComponent extends AviAbstractModelFormComponent<ScriptRuleOutputCommentDto> {  
    ClassName = 'Regelverletzung kommentieren';
    BaseUrl = 'scriptruleoutput';
    ClassType = ScriptRuleOutputCommentDto;
    FormType = AviScriptRuleOutputCommentFormComponent;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
        public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

        this.CRUDBaseRight = 'CAFScriptRuleOutput';
    }

    public async createModel(): Promise<ScriptRuleOutputCommentDto> {
        return null;
    }

    async getModel(id: string): Promise<ScriptRuleOutputCommentDto> {
        return await this.apiService.getModel(ScriptRuleOutputCommentDto, `scriptruleoutput/${id}/comment/create`);
    }

    async saveModel(model: ScriptRuleOutputCommentDto) {
        await this.apiService.post('scriptruleoutput/comment', model);

        if (this.ref)
            this.ref.close(model);
    }

    public async afterInitForm() {
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Vermerk), !!this.Model.Vermerk);
    }
    
    initFields() {
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.RuleId), 'Regel Nr.', 0, false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Status), 'Status', 'caf_scriptruleoutput_status', true, false, true).setMDSizeHalf().setReadonly());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Message1), 'Bezeichnung', false).setMDSizeFull().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Vermerk), 'Vermerk', false).setMDSizeFull().setReadonly());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Kommentar), 'Kommentar', false).setMDSizeFull());
    }
}
