import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviDokumentAblageListDetailConst, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocuMatrixTemplate } from '@avi-x/avi-dto/documatrix/documatrixtemplate.model';
import { MenuItem } from 'primeng/api';
import { GenericRef } from '@avi-x/avi-dto/shared';
import { TranslateService } from '@ngx-translate/core';
import { AviDocuMatrixService } from '../services/documatrix.service';
import { AviDokumentService } from '../../dokument/services/dokument.service';

const nameof = (nameFunction: ((obj: DocuMatrixTemplate) => any)) => typed_nameof<DocuMatrixTemplate>(nameFunction);

@Component({
    selector: 'avi-documatrixtemplate-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [taskmenu-position]="TaskMenuPosition"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocuMatrixTemplateFormComponent extends AviAbstractModelFormComponent<DocuMatrixTemplate> {  
    ClassName = 'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE';
    BaseUrl = 'documatrixdef/template';
    ClassType = DocuMatrixTemplate;
	FormType = AviDocuMatrixTemplateFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
        public dialogService: DialogService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        public translateService: TranslateService,
        public docuMatrixService: AviDocuMatrixService,
        public dokumentService: AviDokumentService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFDocuMatrixTemplate';
    }

    public async afterInitForm() {        
        this.setFromTyp();
    }

    private setFromTyp() {
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Mime), this.Model.Typ.Id === AviDokumentAblageListDetailConst.CAF_DOCUMATRIX_TEMPLATE_TYP_DMDOC);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Bulkroot), this.Model.Typ.Id === AviDokumentAblageListDetailConst.CAF_DOCUMATRIX_TEMPLATE_TYP_DMDOC);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Mime), this.Model.Typ.Id === AviDokumentAblageListDetailConst.CAF_DOCUMATRIX_TEMPLATE_TYP_DMDOC);
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Typ))
            this.setFromTyp();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.GUELTIGAB', true).setNullDateLow().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.GUELTIGBIS', true).setNullDateHigh().setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.BEZEICHNUNG', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.TemplateName), 'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.TEMPLATENAME', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Typ), 'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.TYP', 'caf_documatrix_template_typ', true, true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Mime), 'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.MIME', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bulkroot), 'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.BULKROOT', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ContainerTyp), 'CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.CONTAINERTYP', 'caf_docpropcontainer_typ', true, true, this.ReadOnly).setMDSizeHalf());
    }

    async getTaskMenuItems(): Promise<MenuItem[]> {

        let items: MenuItem[] = await super.getTaskMenuItems();
        
        if (this.ModelId && this.EnableTasks && this.CRUDCanEdit)
            items.push({ label: this.translateService.instant('CORE.COMMON.MODEL.CAFDOCUMATRIXTEMPLATE.VORLAGETESTEN'), icon: 'pi pi-eye', command: () => this.TestTemplate() });

        return items;
    }

    async TestTemplate() {
        if (this.Model.TemplateName) {
            const draft = await this.docuMatrixService.createDraft(this.Model.Id, new GenericRef('f9648ed3-b784-47ae-a523-e18e3ab5c534', 'aviita.CAF.common.modul.businesscase.model.CAFBusinesscase'));
            
            //const draft = await this.docuMatrixService.createDraft(this.Model.Id, new GenericRef('b33f014b-e49d-4bfd-99e3-251e2b4721d2', 'aviita.emw.common.modul.abrechnungen.model.EMWAbrechnung'));
            if (draft.DraftId) {
                const res = await this.docuMatrixService.editDraft(draft.EditorUrl);            
                await this.apiService.delete(`documatrix/draft/${draft.DraftId}`);
            } else if (draft.Contents && draft.Contents.length > 0) {
                await this.dokumentService.openBase64PreviewDialog(draft.Contents);;
            }
        }
    }

}
