import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviBaseSearcherComponent, AviCommonService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { BusinesscaseDefSearchResponseDto } from '@avi-x/avi-dto/businesscase/businesscasedefsearchresponsedto.model';
import { AviBusinesscaseDefFormComponent } from './businesscasedef-form.component';

const nameof = (nameFunction: ((obj: BusinesscaseDefSearchResponseDto) => any)) => typed_nameof<BusinesscaseDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-businesscasedef-searcher',
    templateUrl: './businesscasedef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF';
    SettingsKey = 'settings.searcher.businesscasedef';
    BaseUrl = 'businesscase/businesscasedef';
    FormType = AviBusinesscaseDefFormComponent;

    CanEdit: boolean = false;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFBusinesscaseDef schreiben').then(w => this.CanEdit = w);
    }

    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.sessionController.openSession('businesscasedef-index', this.SelectedRows.Id);
            // this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();        
        
        searcher.addDateColumn(nameof(c => c.GueltigAb), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.GUELTIGAB', null, true);
        searcher.addDateColumn(nameof(c => c.GueltigBis), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.GUELTIGBIS', null, true);

        searcher.addTextColumn(nameof(c => c.KurzBez), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.KURZBEZ1', true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.BEZEICHNUNG1', true);
        
        searcher.addTextColumn(nameof(c => c.DefaultTitle), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.DEFAULTTITLE', true);
        searcher.addIntegerColumn(nameof(c => c.StartNumber), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.STARTNUMBER', true);
        
        searcher.addTextColumn(nameof(c => c.NumberPrefix), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.NUMBERPREFIX', true);

        searcher.addListTypeColumn(nameof(c => c.CreateVisibility), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CREATEVISIBILITY', 'caf_statusdef_sichtbarkeit', true, true);
        
        searcher.addTextColumn(nameof(c => c.Context1ModelName), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1MODELMETA', true);
        searcher.addTextColumn(nameof(c => c.Context2ModelName), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2MODELMETA', true);
        searcher.addTextColumn(nameof(c => c.WorkflowDefName), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.VONWORKFLOWDEF', true);

		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
        cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(BusinesscaseDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
