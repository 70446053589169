import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AviCommonService, AviApiService } from '@avi-x/avi-core';
import { MatTabNav } from '@angular/material/tabs';

@Component({
    selector: 'vwbp-intern-rechtstraeger-index',
    templateUrl: './rechtstraeger-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPInternRechtstraegerIndexComponent implements OnInit {
    navLinks = [
        { path: './kontrollwesen', label: 'Kontrollwesen' },
        { path: './uebergabe', label: 'Übergabe' },
        { path: './unstimmigkeit', label: 'Unstimmigkeiten' },
        { path: './mahnwesen', label: 'Mahnwesen' },
        { path: './schwaerzen', label: 'Einschränkung der Offenlegung' }
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
    ) {
    }

    ngOnInit() {
    }
}
