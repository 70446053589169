import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { AviCommonService, AviApiService, AviFormField, AviFormFieldService, AviChangedAttr, typed_nameof, AviListDetailConst } from '@avi-x/avi-core';
import { AviDocArt } from '@avi-x/avi-dto/avidoc_def/avidocart.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { VwbPSerienbriefSettingsDto } from '../../../dto/vwbpserienbriefsettingsdto.model';
import { VwbPVorlageDef } from '../../../dto/vwbpvorlagedef.model';
import { RTVerMode } from '../rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';
import { AviPreviewType } from 'libs/avi-crm/src/lib/modules/dokument/components/dokument-preview/dokument-preview.component';

const nameof = (nameFunction: ((obj: VwbPSerienbriefSettingsDto) => any)) => typed_nameof<VwbPSerienbriefSettingsDto>(nameFunction);

@Component({
    selector: 'vwbp-intern-rechtstraeger-serienbrief-dialog',
    templateUrl: './rechtstraeger-serienbrief-dialog.component.html',
    styleUrls: ['./rechtstraeger-serienbrief-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternRechtstraegerSerienbriefDialogComponent {
    public Fields: AviFormField[] = [];

    public Preview: any;
    public PreviewType = AviPreviewType;
    public Previewer: AviPreviewType = AviPreviewType.UNKNOWN;
    _previewVorlageId: string;
    _lastPreviewTime: Date;

    currentStep: number = 1;

    modelIds: string[] = null;

    selectedVorlage: VwbPVorlageDef;
    selectedRT;

    SerienbriefSettings: VwbPSerienbriefSettingsDto = new VwbPSerienbriefSettingsDto();

    steps = [
        {
            label: 'Vorlage wählen',
            command: (event: any) => { this.currentStep = 1; }
        },
        {
            label: 'Rechtsträger wählen',
            command: (event: any) => { this.currentStep = 2; }
        }
    ];

    mode: RTVerMode = RTVerMode.RECHTSTRAEGER;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        public cdr: ChangeDetectorRef,
        private vwbpCommonService: VwbPCommonService,
        private formFieldService: AviFormFieldService,
        private sanitizer: DomSanitizer
    ) {
    }

    onRTSelectionChanged(evt) {
        this.selectedRT = evt;
    }

    private async loadPreview(vorlageId: string) {
        if (vorlageId && this._previewVorlageId !== vorlageId) {
            this._previewVorlageId = vorlageId;

            const currentTime = new Date();
            const blob = this.mode === RTVerMode.VERSION ?
                await this.apiService.getBlob(`v1/intern/vwbp/vorlagedef/${vorlageId}/version/${this.modelIds[0]}/preview`) :
                (this.mode === RTVerMode.ANTRAG ?
                    await this.apiService.getBlob(`v1/intern/vwbp/vorlagedef/${vorlageId}/antrag/${this.modelIds[0]}/preview`) :
                    await this.apiService.getBlob(`v1/intern/vwbp/vorlagedef/${vorlageId}/rechtstraeger/${this.modelIds[0]}/preview`));
            if (blob && (this._lastPreviewTime == null || currentTime > this._lastPreviewTime)) {
                this._lastPreviewTime = new Date();
                this.Preview = null;

                if (blob.type === 'text/xml' || blob.type === 'text/html' || blob.type === 'text/plain') {
                    this.Previewer = blob.type === 'text/plain' ? AviPreviewType.TEXT : AviPreviewType.CODE;
                    this.cdr.detectChanges();
                    const reader = new FileReader();

                    reader.addEventListener('loadend', (e) => {
                        const text = reader.result;
                        setTimeout(() => this.setPreview(text));
                    });

                    reader.readAsText(blob);
                } else if (blob.type === 'application/pdf') {
                    this.Previewer = AviPreviewType.PDF;
                    this.cdr.detectChanges();
                    setTimeout(() => this.setPreview(URL.createObjectURL(blob)));
                } else if (blob.type.startsWith('image/')) {
                    this.Previewer = AviPreviewType.IMAGE;
                    this.cdr.detectChanges();
                    setTimeout(() => this.setPreview(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))));
                } else {
                    this.Previewer = AviPreviewType.UNKNOWN;
                    this.cdr.detectChanges();
                }
            }
        }
    }

    setFromFormat() {
        const isPDF = this.SerienbriefSettings.Format.Id === VwbPListDetailConst.VWBP_VORLAGE_FORMAT_PDF;
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DigitalSignieren), !isPDF);
        if (!isPDF)
            this.SerienbriefSettings.DigitalSignieren = new ListType(AviListDetailConst.NEIN, 'janein', 'Nein');
    }

    async onSerienbriefChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.VorlageDefId) && this.SerienbriefSettings.VorlageDefId) {
            this.selectedVorlage = await this.apiService.getModel(VwbPVorlageDef, `v1/intern/vwbp/vorlagedef/${this.SerienbriefSettings.VorlageDefId}`);
            this.loadPreview(this.selectedVorlage.Id);
            this.SerienbriefSettings.Format = this.selectedVorlage.Format;
            this.SerienbriefSettings.DigitalSignieren = this.selectedVorlage.DigitalSignieren;
            if (this.selectedVorlage.KannAbgelegtWerden.Id === AviListDetailConst.NEIN)
                this.SerienbriefSettings.DocArtId = null;
            else
                this.SerienbriefSettings.DocArtId = this.selectedVorlage.DocArt_ID;
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DocArtId), this.selectedVorlage.KannAbgelegtWerden.Id === AviListDetailConst.JA);
            this.setFromFormat();
        }

        if (data.field === nameof(c => c.Format))
            this.setFromFormat();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.VorlageDefId), 'Vorlage', 'v1/intern/vwbp/vorlagedef/serienbrief', true, false, 'Vorlage wählen...', (m) => (`${m.Bezeichnung1}`), 'Id', true, false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Format), 'VWBP.INTERN.DOKUMENTE.SERIENBRIEF_DATEI_FORMAT', 'vwbp_vorlage_format', true, true, false, 'v1/vwbp').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DigitalSignieren), 'Digital signieren', 'janein', true, true, false, 'v1/vwbp').setMDSizeHalf());

        const colDocArt = this.formFieldService.CreateDropdown(nameof(c => c.DocArtId), 'Ablage Dokumentenart',
        async (field) => {
            const ret = [];

            const path = `v1/intern/vwbp/document/docart/list`;

            const p = await this.apiService.getModelList(AviDocArt, path);
            p.forEach(elem => ret.push({ label: elem.Bezeichnung1, value: elem.Id, tag: elem }));

            const sorted = ret.sort((a, b) => {
                if (a.tag.KeywordGruppeName && !b.tag.KeywordGruppeName)
                    return 1;
                else if (!a.tag.KeywordGruppeName && b.tag.KeywordGruppeName)
                    return -1;

                if (a.tag.KeywordGruppeName && b.tag.KeywordGruppeName) {
                    if (a.tag.KeywordGruppeName > b.tag.KeywordGruppeName)
                        return 1;
                    else if (a.tag.KeywordGruppeName < b.tag.KeywordGruppeName)
                        return -1;
                }

                if (a.tag.KeywordUntergruppeName && !b.tag.KeywordUntergruppeName)
                    return 1;
                else if (!a.tag.KeywordUntergruppeName && b.tag.KeywordUntergruppeName)
                    return -1;

                if (a.tag.KeywordUntergruppeName && b.tag.KeywordUntergruppeName) {
                    if (a.tag.KeywordUntergruppeName > b.tag.KeywordUntergruppeName)
                        return 1;
                    else if (a.tag.KeywordUntergruppeName < b.tag.KeywordUntergruppeName)
                        return -1;
                }

                return a.label > b.label ? 1 : -1;
            });

            sorted.unshift({ label: 'hdr', disabled: true, tag: 'HEADER' });

            return sorted;
        },
        false, false, 'DocArt wählen...', (m) => (`${m.Bezeichnung1}`), 'Id', true, false).setMDSizeFull();
        this.Fields.push(colDocArt);

        const hasGruppe = true;
        const hasUntergruppe = false;

        colDocArt.CustomTemplateDelegate = (item) => {
            if (item?.tag === 'HEADER') {
                if (hasGruppe && hasUntergruppe)
                    return `<div class="row"><div class="col33p">Dokumentenart</div><div class="col33p">Ordner</div><div class="col33p"Unterordner</div></div>`;
                else if (hasGruppe && !hasUntergruppe)
                    return `<div class="row row-hdr"><div class="col50p">Dokumentenart</div><div class="col50p">Ordner</div></div>`;
                else if (!hasGruppe && hasUntergruppe)
                    return `<div class="row row-hdr"><div class="col50p">Dokumentenart</div><div class="col50p">Unterordner</div></div>`;
            } else if (hasGruppe || hasUntergruppe) {
                if (item.value) {
                    if (hasGruppe && hasUntergruppe)
                        return `<div class="row"><div class="col33p">${item.label}</div><div class="col33p">${item.tag.KeywordGruppeName}</div><div class="col33p">${item.tag.KeywordUntergruppeName}</div></div>`;
                    else if (hasGruppe && !hasUntergruppe)
                        return `<div class="row"><div class="col50p">${item.label}</div><div class="col50p">${item.tag.KeywordGruppeName}</div></div>`;
                    else if (!hasGruppe && hasUntergruppe)
                        return `<div class="row"><div class="col50p">${item.label}</div><div class="col50p">${item.tag.KeywordUntergruppeName}</div></div>`;
                }
            }
            return item.label;
        };

        colDocArt.CustomTemplateSelectedItemDelegate = (item) => {
            return `${item.label} (${item.tag.KeywordGruppeName})`;
        };
    }

    async ngOnInit() {
        this.mode = this.config.data.mode;
        this.modelIds = this.config.data.modelIds;
        this.initFields();

        this.InitForm();
    }

    public async InitForm() {
        await this.initNewModel();
    }

    public async initNewModel() {        
        this.SerienbriefSettings = new VwbPSerienbriefSettingsDto();

        // die Pflichtfelder dürfen nicht undefined sein, da ansonsten direkt nachdem das Form angezeigt wird, die Fehlermeldungen angezeigt werden
        this.SerienbriefSettings.VorlageDefId = null;
        this.SerienbriefSettings.Format = null;
        this.SerienbriefSettings.DigitalSignieren = null;
        this.SerienbriefSettings.DocArtId = null;

        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.SerienbriefSettings);
        this.cdr.markForCheck();
    }

    private setPreview(preview: any) {
        this.Preview = preview;
        this.cdr.markForCheck();
    }

    nextPage() {
        this.currentStep = this.currentStep + 1;
        this.cdr.markForCheck();
    }

    prevPage() {
        this.Preview = null;
        this.Previewer = AviPreviewType.UNKNOWN;
        this.currentStep = this.currentStep - 1;
        this.cdr.markForCheck();

        setTimeout(() => {
            this._previewVorlageId = null;
            this.loadPreview(this.selectedVorlage.Id);
        });
    }

    Close() {
        if (this.selectedRT?.length > 0) {
            this.SerienbriefSettings.Mode = this.mode;
            this.SerienbriefSettings.ModelIds = this.selectedRT.map(w => w.Id);

            this.ref.close(this.SerienbriefSettings);
        } else
            this.Abbrechen();
    }

    Abbrechen() {
        this.ref.close(null);
    }
}
