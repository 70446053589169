import { ListType } from '../shared';
import TransformDate from '../shared/transformdate';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class DocuMatrixTemplateSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
    @TransformDate()
	public GueltigAb: Date;

    @Expose()
    @TransformDate()
	public GueltigBis: Date;

    @Expose()
	public Bezeichnung: string;

    @Expose()
	public TemplateName: string;
}
