import { Expose, Type } from 'class-transformer';

import { BaseModelState } from '../shared/basemodel.model';

export class NatpersonExtension extends BaseModelState {  
    @Expose()
    public get Id(): string { return this.getModelId('Id'); }
    public set Id(value: string) { this.setModelId('Id', value); }
    
    @Expose()
    public get ExtensionType(): string { return this.getProperty('ExtensionType'); }
    public set ExtensionType(value: string) { this.setProperty('ExtensionType', value); }
    
}
