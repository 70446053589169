import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, isDevMode, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ResourceLabel, AppLevel } from "@avi-x/avi-dto/system/resourcelabel.model";
import { AviApiService } from "../../../services/api.service";
import { AviCommonService } from "../../../services/common.service";
import { AviDialogComponent } from "../../dialog/dialog.component";
import { AviResourceFormComponent, ResourceFormMode } from "../resource-form/resource-form.component";
import { Table } from "primeng/table";
import { MenuItem, SelectItem } from "primeng/api";
import { TranslateService } from "@ngx-translate/core";
import { ReferenceDto } from "@avi-x/avi-dto/shared/referencedto.model";
import { NgxPermissionsService } from "ngx-permissions";
import { ObjectUtils } from "../../utils/object-utils";

@Component({
    selector: 'avi-core-resource-searcher',
    templateUrl: './resource-searcher.component.html',
    styleUrls: ['./resource-searcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviResourceSearcherComponent implements OnInit {
    formModel: ResourceLabel = null;
    results: ResourceLabel[];
    loading: boolean = false;
    BestFit: boolean = false;    
    SelectedRow: ResourceLabel;
    appLevelFilterItems: SelectItem[];
    appLevelFilter;
    public MenuItems: MenuItem[];

    public TotalRecords: number = 0;

    @ViewChild('table')
    table: Table;

    @ViewChild('resourceFormDialog', { static: false })
    resourceFormDialog: AviDialogComponent;

    @ViewChild('resourceForm', { static: false })
    public resourceForm: AviResourceFormComponent;

    @Input('api-prefix')
    public ApiPrefix: string = '';

    public getTableStyle: any = () => {
        return {
            'table-layout': this.BestFit ? 'auto' : 'fixed',
            'width': '100%'
        }
    }

    UsePaginator: boolean = true;
    MaxRowsPerPage: number = 25;

    sprachen: ReferenceDto[]
    private _selectedSprache: ReferenceDto;

    public get selectedSprache(): ReferenceDto {
        return this._selectedSprache;
    }

    public set selectedSprache(value: ReferenceDto) {
        this._selectedSprache = value;
        this.onSpracheChange(value);
        this.onSpracheTouch(value);
        this.cdr.markForCheck();
    }

    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', action: () => { this.closeForm(); } }];

    private canEdit = false;

    constructor(
        private commonService: AviCommonService,
        private apiService: AviApiService,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private permissionService: NgxPermissionsService,
        private cdr: ChangeDetectorRef
    ) {
        this.appLevelFilterItems = Object.keys(AppLevel)
            .filter(key => !isNaN(+key))
            .map(key => ({ label: AppLevel[key], value: key}));

        this.permissionService.hasPermission(['Resourcen schreiben'])
            .then(r => {
                this.canEdit = r
                this.initContextMenu();
            });
    }

    async ngOnInit() {
        if ('apiprefix' in this.activatedRoute.snapshot.data)
            this.ApiPrefix = this.activatedRoute.snapshot.data['apiprefix'];
        
        await this.initLists();
        this.cdr.markForCheck();
    }

    private initContextMenu() {
        var devMode = isDevMode();
        this.MenuItems = [];

        if (devMode)
            this.MenuItems.push({ label: this.translateService.instant('CORE.COMMON.BASESEARCHER_ACTION_NEW'), icon: 'pi pi-plus', command: () =>  this.createResource() });

        if (this.canEdit)
            this.MenuItems.push({ label: this.translateService.instant('CORE.COMMON.BASESEARCHER_ACTION_EDIT'), icon: 'pi pi-pencil', styleClass: 'menuitem-context', command: () => this.editResource(this.SelectedRow) });
        
        if (devMode)
            this.MenuItems.push({ label: this.translateService.instant('CORE.COMMON.BASESEARCHER_ACTION_DELETE'), icon: 'pi pi-trash', styleClass: 'menuitem-context', command: () => this.deleteResource() });
    }

    private async initLists() {
        await this.getSprachList();
    }

    async getSprachList() {
        await this.apiService.get(`${this.ApiPrefix ?? ''}/model/representations/Sprache`).then(r => {
            this.sprachen = r.sort(this.commonService.dynamicSort('SprachNr'))
        });
    }

    onSpracheChange: any = () => { this.DoSearch(); }
    onSpracheTouch: any = () => {}

    public DoSearch() {
        this.Reset();

        let sprachId = this.selectedSprache?.Id;
        if (sprachId) {
            this.loading = true;
            this.commonService.showGlobalLoader();

            this.apiService.getModelList(ResourceLabel, `${this.ApiPrefix ?? ''}/resource/sprache/${sprachId}`)
                .then((r) => {
                    this.results = r;
                    this.TotalRecords = r ? this.results.length : 0;

                    // RitchTextEditor hat Probleme mit undefined bzw. würde dies als Value im Editor darstellen!                    
                    this.results.forEach(w => ObjectUtils.UndefinedToNull(w));
                })
                .catch((err) => {
                    this.commonService.notificateError(err);
                })
                .finally(() => {
                    this.loading = false;
                    this.cdr.markForCheck();
                    this.commonService.hideGlobalLoader();
                });
        }
    }

    public Reset() {
        this.results = [];
        this.SelectedRow = null;
        this.TotalRecords = 0;
        this.cdr.markForCheck();
    }

    public getSettingsKey: () => string = (): string => `avix::ResourceSearcher`;

    public temDataLength: number = 0;

    onPage(event: any) {
        this.temDataLength = this.results.slice(event.first, event.first + this.MaxRowsPerPage).length;
    }

    onDoubleClick(data) {
        this.editResource(data);
    }

    public createResource() {
        this.resourceForm.ResourceFormMode = ResourceFormMode.CREATE;

        var resource = ObjectUtils.ObjectToBaseModel(ResourceLabel, new ResourceLabel());
        resource.AppLevel = AppLevel.WebClient;
        this.formModel = resource;
        
        this.resourceFormDialog.open();
    }

    public editResource(data) {
        if (data && this.canEdit) {
            this.resourceForm.ResourceFormMode = ResourceFormMode.UPDATE;
            
            this.formModel = this.copyResourceLabel(data);
            this.resourceFormDialog.open();
        }
    }

    public deleteResource() {
        if (this.SelectedRow) {
            var key = this.SelectedRow.Key;
            var appLevel = this.SelectedRow.AppLevel;

            this.commonService.confirmDelete(this.translateService.instant('CORE.COMMON.CONFIRM_DELETE'),
                () => {
                    this.apiService.delete(`${this.ApiPrefix ?? ''}/resource/${key}/AppLevel/${appLevel}`)
                        .then(r => {
                            this.translateService.get('CORE.COMMON.GELOESCHT')
                                .toPromise()
                                .then(w => this.commonService.notificateSuccess(w));
                            
                            this.results = this.results.filter(x => x.Key !== key);
                            this.TotalRecords = this.results.length;
                            this.cdr.markForCheck();
                        })
                        .catch((err) => this.commonService.notificateError(err));
                }
            );
        } else {
            this.translateService.get('CORE.COMMON.CHOOSE_RECORD')
                .toPromise()
                .then(w => this.commonService.notificateError(w));
        }
    }

    onSavedSuccessful(event) {
        if (this.resourceForm.ResourceFormMode == ResourceFormMode.CREATE) {
            if (this.formModel) {
                this.results = [...this.results, this.formModel];
                this.TotalRecords = this.results.length;
            }
        }
        else if (this.resourceForm.ResourceFormMode == ResourceFormMode.UPDATE) {
            var toUpdate = this.results.find(x => x.Key === this.formModel.Key);
            this.copyIntoResourceLabel(this.formModel, toUpdate);
            this.updateOriginalValues(toUpdate);
        }

        this.translateService.reloadLang('de');

        this.cdr.markForCheck();
        this.closeForm();
    }

    private closeForm() {
        this.formModel = null;
        this.resourceFormDialog.close();
    }

    getAppLevelRepresentation(resourceLabel: ResourceLabel): string
    {
        if (resourceLabel)
            return AppLevel[resourceLabel.AppLevel];
        return null;
    }

    public onInputTextFilter(value: any, field: any, filterMatchMode: any) {
        this.table.filter(value, field, filterMatchMode);
    }

    public onEnumFilter(value: any, field: any) {
        let filterMatchMode = 'equals';
        let _value: any;

        if (Array.isArray(value)) {
            if (value.length > 1)
                filterMatchMode = 'in';
            _value = value.map(ef => ef.value);
        }
        else if (value)
            _value = value['value'];
        else
            _value = null;

        this.table.filter(_value, field, filterMatchMode);
    }

    public clearColumnFilter(field: any, filterMatchMode: any) {
        this.table.filter(null, field, filterMatchMode);
        this.appLevelFilter = null;
    }

    private copyResourceLabel(source: ResourceLabel): ResourceLabel
    {
        var destination = new ResourceLabel();
        if (!source)
            return destination;

        this.copyIntoResourceLabel(source, destination);
        ObjectUtils.UndefinedToNull(destination);
        return destination;
    }

    private copyIntoResourceLabel(source: ResourceLabel, destination: ResourceLabel) {
        if (!source || !destination)
            return;

        for(var prop of source.getPropertyNames()) {
            destination.setProperty(prop, source.getProperty(prop));
        }
    }

    private updateOriginalValues(toUpdate: ResourceLabel) {
        if (toUpdate) {
            for (var prop of toUpdate.getPropertyNames().filter(x => x.startsWith('Original'))) {
                var propName = prop.substring(8);                
                toUpdate.setProperty(prop, toUpdate.getProperty(propName));
            }
            ObjectUtils.UndefinedToNull(toUpdate);
        }
    }

    public ShowCreateButton(): boolean {
        var result = isDevMode()
            && this._selectedSprache
            && this._selectedSprache.Id.toLowerCase() === 'ED3902C9-322E-480D-B327-72DF33EF5266'.toLowerCase(); // Deutsch
        return result;
    }

    _onShowContextMenu(e) {
        this.MenuItems.filter(i => i.styleClass === 'menuitem-context').forEach(w => w.visible = e.data != null);
    }

    public onOpenEditForm() {
        if (this.resourceForm)
            this.resourceForm.RefreshEditors();
    }
}