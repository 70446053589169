<div *ngIf="Model && isReadOnlyMode()" class="separator-nomargin"></div>

<div
    *ngIf="Model"
    class="unstimmigkeit ui-fluid ui-g-nopad"
    [ngClass]="{ 'unstimmigkeit-edit': !isReadOnlyMode(), 'unstimmigkeit-erledigt': this.UnstimmigkeitErledigt }"
>
    <div [ngClass]="{ 'unstimmigkeit-wrapper': isReadOnlyMode() }">
        <button
            pButton
            *ngIf="isReadOnlyMode()"
            [icon]="UnstimmigkeitCollapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"
            class="unstimmigkeit-collapse-button p-button-secondary p-button-rounded p-button-text fs-large"
            (click)="toggleCollapsed()"
        ></button>

        <div class="unstimmigkeit-container">
            <div *ngIf="!isNewUnstimmigkeit()" class="unstimmigkeit-header-container">
                <span class="unstimmigkeit-header">{{ this.Model.ErstelltVon }} </span>
                <span class="unstimmigkeit-subheader truncate margin-right-auto">{{ getUnstimmigkeitHeader() }}</span>

                <ng-container *ngIf="isReadOnlyMode() && !Readonly && !UnstimmigkeitErledigt">
                    <button
                        pButton
                        icon="pi pi-pencil"
                        class="p-button-secondary p-button-rounded p-button-text p-button-icon-only"
                        aviThrottledClick
                        (throttledClick)="EditUnstimmigkeit()"
                    ></button>
                    <button
                        pButton
                        icon="pi pi-trash"
                        class="p-button-secondary p-button-rounded p-button-text"
                        aviThrottledClick
                        (throttledClick)="DeleteUnstimmigkeit()"
                    ></button>
                </ng-container>
            </div>

            <div
                *ngIf="!UnstimmigkeitCollapsed"
                [ngClass]="{ 'unstimmigkeit-content': true, 'ui-g-nopad': true, 'mt-2': true }"
            >
                <div *ngIf="isReadOnlyMode()" [innerText]="Model.Meldung"></div>

                <div [ngClass]="{ 'unstimmigkeit-textarea': isReadOnlyMode() }">
                    <textarea
                        *ngIf="!isReadOnlyMode()"
                        [rows]="4"
                        id="Meldung"
                        [readonly]="isReadOnlyMode()"
                        [ngClass]="{ 'nobackground-image': isReadOnlyMode(), 'w-100p': true }"
                        pInputTextarea
                        [(ngModel)]="Model.Meldung"
                        [autoResize]="true"
                        name="Meldung"
                        attr.name="Meldung"
                    >
                    </textarea>
                </div>
                <div *ngIf="isReadOnlyMode() && !Readonly">
                    <div class="bemerkungen">Bemerkungen</div>
                    <div class="pl-3 pt-2 pb-4p">
                        <vwbp-intern-unstimmigkeit-comment-list [context-id]="Model.Id">
                        </vwbp-intern-unstimmigkeit-comment-list>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isReadOnlyMode()" class="pl-0">
        <div class="pt-2">
            <button
                pButton
                icon="pi pi-save"
                type="submit"
                style="width: auto;"
                class="mr-2  p-button-primary"
                aviThrottledClick
                (throttledClick)="SaveUnstimmigkeit()"
                [label]="'CORE.COMMON.SPEICHERN_BUTTON' | translate"
                [title]="'CORE.COMMON.SPEICHERN_BUTTON' | translate"
            ></button>
            <button
                pButton
                type="button"
                style="width: auto;"
                class="mr-2 p-button-secondary p-button-outlined"
                aviThrottledClick
                (throttledClick)="CancelUnstimmigkeit()"
                id="rteCancel"
                [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
                [title]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
            ></button>
        </div>
    </div>
</div>

<div *ngIf="Model && isReadOnlyMode()" class="separator-nomargin"></div>
