import { Transform } from "class-transformer";
import * as moment from "moment";

export default function TransformDate() {
    const toPlain = Transform((value) => value?.value ? moment(value.value).format('YYYY-MM-DD') : null, { toPlainOnly: true });
    const toClass = Transform((value) => value?.value ? moment(value.value, 'YYYY-MM-DD').toDate() : null, { toClassOnly: true });

    return function (target: any, key: string) {
        toPlain(target, key);
        toClass(target, key);
    };
}
