<div class="ui-g">
    <div class="ui-g-12 ui-md-12 pl-0">
        <div *ngIf="NewCommentId">
            <div class="new-comment">{{ LabelNewCommentHeader }}</div>

            <avi-crm-comment-view
                [comments-collapsible]="false"
                [comment-id]="NewCommentId"
                [context-id]="ContextId"
                [context-type]="ContextType"
                [enable-intern]="EnableIntern"
                [rtf]="IsRTF"
                (onCancelled)="OnCreateCommentCancelled()"
                (onSaved)="OnCreateCommentSaved()"
            >
            </avi-crm-comment-view>
        </div>

        <div>
            <button
                *ngIf="!NewCommentId && !EditCommentId && CanEdit && EnableErfassen"
                pButton
                icon="pi pi-comment"
                type="button"
                style="width: auto;"
                class="mr-2p"
                (click)="CreateComment()"
                [label]="LabelNewCommentButton"
                [title]="LabelNewCommentButton"
            ></button>
        </div>
    </div>
</div>

<div *ngIf="CommentIds.length > 0">
    <div class="separator"></div>
</div>

<div *ngFor="let commentId of CommentIds">
    <avi-crm-comment-view
        [show-buttons]="!EditCommentId"
        [comments-collapsible]="CommentsCollapsible && !EditCommentId"
        [enable-erledigen]="EnableErledigen"
        [enable-intern]="EnableIntern"
        [show-statusubergang]="ShowStatusuebergang"
        [comment-id]="commentId"
        [context-type]="ContextType"
        (onDeleted)="OnDeleteComment(commentId)"
        (onEdit)="OnEditComment(commentId)"
        (onCancelled)="OnEditCommentCancelled(commentId)"
        (onSaved)="OnEditCommentSaved(commentId)"
    >
    </avi-crm-comment-view>

    <div class="separator"></div>
</div>
