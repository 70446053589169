<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

	<div class="p-1">
        <avi-core-view-panel 
            [toggleable]="true" 
            [header]="'CORE.COMMON.MODEL.CAFDOCPROPCONTAINER.CAFDOCPROPCONTAINER_1' | translate" 
            class="w-100p">

		    <avi-docpropcontainer-form [model-id]="ModelId" [contents-padding]="false" [card]="false" [readonly]="true" [enable-taskmenu]="true"></avi-docpropcontainer-form>
        </avi-core-view-panel>
        
        <avi-core-view-panel 
            [toggleable]="true" 
            [header]="'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERREFERENCE.CAFDOCPROPCONTAINERREFERENCE_N' | translate" 
            class="w-100p">
            <avi-docpropcontainerreference-searcher [context-id]="ModelId" [contents-padding]="false" [enable-panel]="false"></avi-docpropcontainerreference-searcher>
        </avi-core-view-panel>

        <avi-core-view-panel 
            [toggleable]="true" 
            [header]="'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERPROPERTYLINK.CAFDOCPROPCONTAINERPROPERTYLINK_N' | translate" 
            class="w-100p">
            <avi-docproperty-searcher [context-id]="ModelId" [mode]="DocPropertyMode.Standalone" [contents-padding]="false" [enable-panel]="false"></avi-docproperty-searcher>
        </avi-core-view-panel>

	</div>

</avi-core-page>

