import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { GenericRef, ListType } from '../shared';
import { ListDetail } from './listdetail.model';

export class DecisionTableParamDto extends IDTO
{
	@Expose()
	public Id: string;

    @Expose()
	public TextValue: string;

    @Expose()
    @Type(() => Date)
    public DateValue: Date;

    @Expose()
	public DecimalValue: number;

    @Expose()
	public IntValue: number;

    @Expose()
    public RefModel: string;

    @Expose()
    public Listtype: string;

    @Expose()
	public vonDecisionTableParamDef: string;

    @Expose()
	public vonDecisionTableParamDef_ID: string;

    @Expose()
    @Type(() => ListType)
	public ListDetailValue: ListType;

    @Expose()
	public vonObjekt_ID: string;

    @Expose()
	public RefValue_ID: string;

    @Expose()
	@Type(() => ListType)
	public ParamArt: ListType;

    @Expose()
	@Type(() => ListType)
	public AttributeType: ListType;
}