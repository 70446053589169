import { Expose, Type } from 'class-transformer';

import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { VwbPRechtstraegerVersionInfo } from './vwbprechtstraegerversioninfo.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { VwbPVersion } from './vwbpversion.model';

export class VwbPRechtstraeger extends BaseModel {
    @Expose()
    public get CurrentDetailId(): string { return this.getProperty('CurrentDetailId'); }
    public set CurrentDetailId(value: string) { this.setProperty('CurrentDetailId', value); }

    @Expose()
    public get CurrentVersionId(): string { return this.getProperty('CurrentVersionId'); }
    public set CurrentVersionId(value: string) { this.setProperty('CurrentVersionId', value); }

    @Expose()
    public get OwnerContainer_ID(): string { return this.getProperty('OwnerContainer_ID'); }
    public set OwnerContainer_ID(value: string) { this.setProperty('OwnerContainer_ID', value); }

    @Expose()
    public get MasterOwner_ID(): string { return this.getProperty('MasterOwner_ID'); }
    public set MasterOwner_ID(value: string) { this.setProperty('MasterOwner_ID', value); }

    @Expose()
    public get InterneId(): number { return this.getProperty('InterneId'); }
    public set InterneId(value: number) { this.setProperty('InterneId', value); }

    @Expose()
    public get VersionCount(): number { return this.getProperty('VersionCount'); }
    public set VersionCount(value: number) { this.setProperty('VersionCount', value); }

    @Expose()
    public get IsDraft(): boolean { return this.getProperty('IsDraft'); }
    public set IsDraft(value: boolean) { this.setProperty('IsDraft', value); }

    @Expose()
    @Type(() => Date)
    public get HrEintragungsdatum(): Date { return this.getProperty('HrEintragungsdatum'); }
    public set HrEintragungsdatum(value: Date) { this.setProperty('HrEintragungsdatum', value); }

    @Expose()
    @Type(() => Date)
    public get HrLoeschungsdatum(): Date { return this.getProperty('HrLoeschungsdatum'); }
    public set HrLoeschungsdatum(value: Date) { this.setProperty('HrLoeschungsdatum', value); }

    @Expose()
    @Type(() => Date)
    public get AbgegebenAm(): Date { return this.getProperty('AbgegebenAm'); }
    public set AbgegebenAm(value: Date) { this.setProperty('AbgegebenAm', value); }

    @Expose()
    @Type(() => ListType)
    public get Uebergabestatus(): ListType { return this.getProperty('Uebergabestatus'); }
    public set Uebergabestatus(value: ListType) { this.setProperty('Uebergabestatus', value); }

    @Expose()
    @Type(() => ListType)
    public get HrStatus(): ListType { return this.getProperty('HrStatus'); }
    public set HrStatus(value: ListType) { this.setProperty('HrStatus', value); }

    @Expose()
    @Type(() => ListType)
    public get ValidierungAusnahme(): ListType { return this.getProperty('ValidierungAusnahme'); }
    public set ValidierungAusnahme(value: ListType) { this.setProperty('ValidierungAusnahme', value); }

    @Expose()
    @Type(() => ListType)
    public get BemerkungTeilfonds(): ListType { return this.getProperty('BemerkungTeilfonds'); }
    public set BemerkungTeilfonds(value: ListType) {this.setProperty('BemerkungTeilfonds', value); }

    @Expose()
    @Type(() => ListType)
    public get SchwaerzungVorhanden(): ListType { return this.getProperty('SchwaerzungVorhanden'); }
    public set SchwaerzungVorhanden(value: ListType) { this.setProperty('SchwaerzungVorhanden', value); }

    @Expose()
    @Type(() => ListType)
    public get SchwaerzungAntragVorhanden(): ListType { return this.getProperty('SchwaerzungAntragVorhanden'); }
    public set SchwaerzungAntragVorhanden(value: ListType) { this.setProperty('SchwaerzungAntragVorhanden', value); }

    @Expose()
    @Type(() => ListType)
    public get SchwaerzungRechtskraftVorhanden(): ListType { return this.getProperty('SchwaerzungRechtskraftVorhanden'); }
    public set SchwaerzungRechtskraftVorhanden(value: ListType) { this.setProperty('SchwaerzungRechtskraftVorhanden', value); }

    @Expose()
    @Type(() => ListType)
    public get SchwaerzungFrist3MtVorhanden(): ListType { return this.getProperty('SchwaerzungFrist3MtVorhanden'); }
    public set SchwaerzungFrist3MtVorhanden(value: ListType) { this.setProperty('SchwaerzungFrist3MtVorhanden', value); }

    @Expose()
    @Type(() => ListType)
    public get EingetragenEWRRegister(): ListType { return this.getProperty('EingetragenEWRRegister'); }
    public set EingetragenEWRRegister(value: ListType) { this.setProperty('EingetragenEWRRegister', value); }

    @Expose()
    @Type(() => ListType)
    public get MahnwesenStatus(): ListType { return this.getProperty('MahnwesenStatus'); }
    public set MahnwesenStatus(value: ListType) { this.setProperty('MahnwesenStatus', value); }

    @Expose()
    @Type(() => ListType)
    public get MahnwesenAktiv(): ListType { return this.getProperty('MahnwesenAktiv'); }
    public set MahnwesenAktiv(value: ListType) { this.setProperty('MahnwesenAktiv', value); }

    @Expose()
    public alleVersionDetails: VwbPRechtstraegerVersionInfo[] = [];

    @Expose()
    @Type(() => VwbPVersion)
    public get SelectedVersion(): VwbPVersion { return this.getModel('SelectedVersion'); }
    public set SelectedVersion(value: VwbPVersion) { this.setModel('SelectedVersion', value); }
}
