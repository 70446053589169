<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

    <avi-core-view-panel [toggleable]="true" [header]="'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.CAFADRESSBLOCKDEF_1' | translate" class="w-100p">
        <avi-adressblockdef-form [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true" taskmenu-position="bottom"></avi-adressblockdef-form>
    </avi-core-view-panel>

    <avi-core-view-panel [toggleable]="true"
        [header]="'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.ALLEZEILEN' | translate"
        class="w-100p">
        <avi-adressblockzeiledef-searcher [context-id]="ModelId" [card]="false" [contents-padding]="false" [enable-panel]="false"></avi-adressblockzeiledef-searcher>
    </avi-core-view-panel>
    
</avi-core-page>

