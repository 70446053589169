import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, Input } from "@angular/core";
import { AviAbstractModelSearcherComponent } from "../../base-searcher/abstract-model-searcher.component";
import { AviAbstractModelFormComponent, IAviAbstractModelFormComponent } from "../../base-form/abstract-model-form.component";
import { IDTO } from "@avi-x/avi-dto/shared";
import { ISearcherWithDetail } from "./abstract-model-searcher-with-detail";
import { MenuItem } from "primeng/api";

@Component({
    selector: 'avi-core-searcherwithdetail',
    templateUrl: './searcher-with-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AviSearcherWithDetailComponent implements AfterViewInit {

    @ContentChild(AviAbstractModelSearcherComponent)
    searcher: AviAbstractModelSearcherComponent;

    @ContentChild(AviAbstractModelFormComponent , {static: true})
    form: IAviAbstractModelFormComponent;

    searcherWithDetailModel: ISearcherWithDetail;

    @Input('taskmenu-items')
    TaskMenuItems: MenuItem[];

    ngAfterViewInit(): void {
        if (!this.searcher)
            throw new Error("Please add the following provider to the searcher used in the searcher-with-detail component: 'providers: [{ provide: AviAbstractModelSearcherComponent, useExisting: YourComponent }]'");

        if (!this.form)
            throw new Error("Please add the following provider to the form used in the searcher-with-detail component: 'providers: [{ provide: AviAbstractModelFormComponent, useExisting: YourComponent }]'");

        this.searcher.searcher.SearcherRows = 20;
        this.searcher.searcher.SelectFirstResult = true;
        this.searcher.searcher.ContentsPadding = false;
        this.searcher.searcher.onSelect.subscribe(this.searcher_onSelected.bind(this));

        this.form.Form.ContentsPadding = false;
        this.form.Form.ViewsPadding = false;
        this.form.Card = false;
    }

    private searcher_onSelected(data: any) {
        let selection: IDTO = null;
        if (data instanceof IDTO)
            selection = data;
        else {
            if (Array.isArray(data)) {
                let array = data as IDTO[];
                if (array.length > 0)
                    selection = array[0];
            }
            else if (data)
                selection = data as IDTO;
        }

        this.searcherWithDetailModel.SelectedRecordId = selection["Id"];
    }
}