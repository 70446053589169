import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { EncryptedType } from '../shared/encryptedtype.model';

export class AviDocArchivDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get Url(): string { return this.getProperty('Url'); }
    public set Url(value: string) { this.setProperty('Url', value); }
    
    @Expose()
    public get Username(): string { return this.getProperty('Username'); }
    public set Username(value: string) { this.setProperty('Username', value); }
    
    @Expose()
    public get Usergroup(): string { return this.getProperty('Usergroup'); }
    public set Usergroup(value: string) { this.setProperty('Usergroup', value); }
    
    @Expose()
    public get TimeoutInMs(): number { return this.getProperty('TimeoutInMs'); }
    public set TimeoutInMs(value: number) { this.setProperty('TimeoutInMs', value); }
    
    @Expose()
    public get IP(): string { return this.getProperty('IP'); }
    public set IP(value: string) { this.setProperty('IP', value); }
    
    @Expose()
    public get Port(): number { return this.getProperty('Port'); }
    public set Port(value: number) { this.setProperty('Port', value); }
    
    @Expose()
    public get Instanz(): string { return this.getProperty('Instanz'); }
    public set Instanz(value: string) { this.setProperty('Instanz', value); }
    
    @Expose()
    public get MaxResults(): number { return this.getProperty('MaxResults'); }
    public set MaxResults(value: number) { this.setProperty('MaxResults', value); }
    
    @Expose()
    public get ServiceVersion(): string { return this.getProperty('ServiceVersion'); }
    public set ServiceVersion(value: string) { this.setProperty('ServiceVersion', value); }
    
    @Expose()
    public get FeldTransaktionsId(): string { return this.getProperty('FeldTransaktionsId'); }
    public set FeldTransaktionsId(value: string) { this.setProperty('FeldTransaktionsId', value); }
    
    @Expose()
    public get MaxFileSize(): number { return this.getProperty('MaxFileSize'); }
    public set MaxFileSize(value: number) { this.setProperty('MaxFileSize', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DocArchivTyp(): ListType { return this.getProperty('DocArchivTyp'); }
    public set DocArchivTyp(value: ListType) { this.setProperty('DocArchivTyp', value); }
    
    @Expose()
    @Type(() => ListType)
    public get LoginUserRights(): ListType { return this.getProperty('LoginUserRights'); }
    public set LoginUserRights(value: ListType) { this.setProperty('LoginUserRights', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerbunddokumentPreview(): ListType { return this.getProperty('VerbunddokumentPreview'); }
    public set VerbunddokumentPreview(value: ListType) { this.setProperty('VerbunddokumentPreview', value); }
    
    @Expose()
    @Type(() => EncryptedType)
    public get Passwort(): EncryptedType { return this.getProperty('Passwort'); }
    public set Passwort(value: EncryptedType) { this.setProperty('Passwort', value); }
    
}
