import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabNav } from '@angular/material/tabs';
import { VwbPCommonService } from '@avi-x/vwbp-public';

@Component({
    selector: 'vwbp-admin-vorlagedef-index',
    templateUrl: './vorlagedef-index.component.html'
})
export class VwbPAdminVorlageDefIndexComponent implements OnInit {
    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(private vwbpCommonService: VwbPCommonService,
    ) { }

    navLinks = [
        { path: './vorlagedef', label: 'Vorlage Zuweisungen' },
    ];

    ngOnInit() {
    }
}
