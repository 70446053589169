import { ChangeDetectorRef, Component } from '@angular/core';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternSchwaerzenService } from '../../services/schwaerzen.service';
import { VwbPInternSchwaerzenBaseComponent } from './schwaerzen-base.component';

@Component({
    selector: 'vwbp-intern-schwaerzen-frist-abgelaufen',
    template: `
        <vwbp-intern-schwaerzen-antrag-searcher #searcher settingskey="settings.searcher.schwaerzen.fristabgelaufen" [last-version]="true" [get-filters]="GetFilters" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'Sitz', 'AntragEntscheidung', 'AntragEntscheidAm', 'AntragGueltigBis', 'AntragBegruendung', 'AntragFristablaufGrund', 'EingangsDatum' ]">
        </vwbp-intern-schwaerzen-antrag-searcher>
        `
})
export class VwbPInternSchwaerzenFristAbgelaufenComponent extends VwbPInternSchwaerzenBaseComponent {
    constructor(public commonService: AviCommonService, public schwaerzenService: VwbPInternSchwaerzenService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, schwaerzenService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters = (): string[] => {
        return [`AntragStatus eq ${VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELAUFEN}`];
    }
}
