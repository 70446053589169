import { Expose, Type } from 'class-transformer';

import { TechRole } from '../system/techrole.model';
import { ModelStateGroup } from '../system/modelstategroup.model';
import { ModelState } from '../system/modelstate.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class PartnerIdentifikationsArtDef extends BaseModel {  
    @Expose()
    public get OrtLabel1(): string { return this.getProperty('OrtLabel1'); }
    public set OrtLabel1(value: string) { this.setProperty('OrtLabel1', value); }
    
    @Expose()
    public get OrtLabel2(): string { return this.getProperty('OrtLabel2'); }
    public set OrtLabel2(value: string) { this.setProperty('OrtLabel2', value); }
    
    @Expose()
    public get LandLabel1(): string { return this.getProperty('LandLabel1'); }
    public set LandLabel1(value: string) { this.setProperty('LandLabel1', value); }
    
    @Expose()
    public get LandLabel2(): string { return this.getProperty('LandLabel2'); }
    public set LandLabel2(value: string) { this.setProperty('LandLabel2', value); }
    
    @Expose()
    public get GueltigAbLabel1(): string { return this.getProperty('GueltigAbLabel1'); }
    public set GueltigAbLabel1(value: string) { this.setProperty('GueltigAbLabel1', value); }
    
    @Expose()
    public get GueltigAbLabel2(): string { return this.getProperty('GueltigAbLabel2'); }
    public set GueltigAbLabel2(value: string) { this.setProperty('GueltigAbLabel2', value); }
    
    @Expose()
    public get GueltigBisLabel1(): string { return this.getProperty('GueltigBisLabel1'); }
    public set GueltigBisLabel1(value: string) { this.setProperty('GueltigBisLabel1', value); }
    
    @Expose()
    public get GueltigBisLabel2(): string { return this.getProperty('GueltigBisLabel2'); }
    public set GueltigBisLabel2(value: string) { this.setProperty('GueltigBisLabel2', value); }
    
    @Expose()
    public get IdentifikationsNrLabel1(): string { return this.getProperty('IdentifikationsNrLabel1'); }
    public set IdentifikationsNrLabel1(value: string) { this.setProperty('IdentifikationsNrLabel1', value); }
    
    @Expose()
    public get IdentifikationsNrLabel2(): string { return this.getProperty('IdentifikationsNrLabel2'); }
    public set IdentifikationsNrLabel2(value: string) { this.setProperty('IdentifikationsNrLabel2', value); }
    
    @Expose()
    public get StatusLabel1(): string { return this.getProperty('StatusLabel1'); }
    public set StatusLabel1(value: string) { this.setProperty('StatusLabel1', value); }
    
    @Expose()
    public get StatusLabel2(): string { return this.getProperty('StatusLabel2'); }
    public set StatusLabel2(value: string) { this.setProperty('StatusLabel2', value); }
    
    @Expose()
    public get CreateRole_ID(): string { return this.getModelId('CreateRole_ID'); }
    public set CreateRole_ID(value: string) { this.setModelId('CreateRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get rCreateRole(): TechRole { return this.getModel('rCreateRole'); }
    public set rCreateRole(value: TechRole) { this.setModel('rCreateRole', value); }
    
    @Expose()
    public get UpdateRole_ID(): string { return this.getModelId('UpdateRole_ID'); }
    public set UpdateRole_ID(value: string) { this.setModelId('UpdateRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get rUpdateRole(): TechRole { return this.getModel('rUpdateRole'); }
    public set rUpdateRole(value: TechRole) { this.setModel('rUpdateRole', value); }
    
    @Expose()
    public get DeleteRole_ID(): string { return this.getModelId('DeleteRole_ID'); }
    public set DeleteRole_ID(value: string) { this.setModelId('DeleteRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get rDeleteRole(): TechRole { return this.getModel('rDeleteRole'); }
    public set rDeleteRole(value: TechRole) { this.setModel('rDeleteRole', value); }
    
    @Expose()
    public get StateGroup_ID(): string { return this.getModelId('StateGroup_ID'); }
    public set StateGroup_ID(value: string) { this.setModelId('StateGroup_ID', value); }
 
    @Expose()
    @Type(() => ModelStateGroup)
    public get StateGroup(): ModelStateGroup { return this.getModel('StateGroup'); }
    public set StateGroup(value: ModelStateGroup) { this.setModel('StateGroup', value); }
    
    @Expose()
    public get DefaultStatus_ID(): string { return this.getModelId('DefaultStatus_ID'); }
    public set DefaultStatus_ID(value: string) { this.setModelId('DefaultStatus_ID', value); }
 
    @Expose()
    @Type(() => ModelState)
    public get DefaultStatus(): ModelState { return this.getModel('DefaultStatus'); }
    public set DefaultStatus(value: ModelState) { this.setModel('DefaultStatus', value); }
    
    @Expose()
    @Type(() => ListType)
    public get IdentifikationsArt(): ListType { return this.getProperty('IdentifikationsArt'); }
    public set IdentifikationsArt(value: ListType) { this.setProperty('IdentifikationsArt', value); }
    
    @Expose()
    @Type(() => ListType)
    public get BemerkungAnzeigen(): ListType { return this.getProperty('BemerkungAnzeigen'); }
    public set BemerkungAnzeigen(value: ListType) { this.setProperty('BemerkungAnzeigen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get BemerkungPflicht(): ListType { return this.getProperty('BemerkungPflicht'); }
    public set BemerkungPflicht(value: ListType) { this.setProperty('BemerkungPflicht', value); }
    
    @Expose()
    @Type(() => ListType)
    public get OrtAnzeigen(): ListType { return this.getProperty('OrtAnzeigen'); }
    public set OrtAnzeigen(value: ListType) { this.setProperty('OrtAnzeigen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get OrtPflicht(): ListType { return this.getProperty('OrtPflicht'); }
    public set OrtPflicht(value: ListType) { this.setProperty('OrtPflicht', value); }
    
    @Expose()
    @Type(() => ListType)
    public get LandAnzeigen(): ListType { return this.getProperty('LandAnzeigen'); }
    public set LandAnzeigen(value: ListType) { this.setProperty('LandAnzeigen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get LandPflicht(): ListType { return this.getProperty('LandPflicht'); }
    public set LandPflicht(value: ListType) { this.setProperty('LandPflicht', value); }
    
    @Expose()
    @Type(() => ListType)
    public get GueltigAbAnzeigen(): ListType { return this.getProperty('GueltigAbAnzeigen'); }
    public set GueltigAbAnzeigen(value: ListType) { this.setProperty('GueltigAbAnzeigen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get GueltigAbPflicht(): ListType { return this.getProperty('GueltigAbPflicht'); }
    public set GueltigAbPflicht(value: ListType) { this.setProperty('GueltigAbPflicht', value); }
    
    @Expose()
    @Type(() => ListType)
    public get GueltigBisAnzeigen(): ListType { return this.getProperty('GueltigBisAnzeigen'); }
    public set GueltigBisAnzeigen(value: ListType) { this.setProperty('GueltigBisAnzeigen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get GueltigBisPflicht(): ListType { return this.getProperty('GueltigBisPflicht'); }
    public set GueltigBisPflicht(value: ListType) { this.setProperty('GueltigBisPflicht', value); }
    
    @Expose()
    @Type(() => ListType)
    public get IdentifikationsNrAnzeigen(): ListType { return this.getProperty('IdentifikationsNrAnzeigen'); }
    public set IdentifikationsNrAnzeigen(value: ListType) { this.setProperty('IdentifikationsNrAnzeigen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get IdentifikationsNrPflicht(): ListType { return this.getProperty('IdentifikationsNrPflicht'); }
    public set IdentifikationsNrPflicht(value: ListType) { this.setProperty('IdentifikationsNrPflicht', value); }
    
    @Expose()
    @Type(() => ListType)
    public get StatePflicht(): ListType { return this.getProperty('StatePflicht'); }
    public set StatePflicht(value: ListType) { this.setProperty('StatePflicht', value); }
    
}
