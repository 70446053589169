import { AviListDetailConst } from '@avi-x/avi-core';
import { GenericRef, ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class JobParamSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
	public ValueInt: number;

    @Expose()
	public ValueDec: number;

    @Expose()
	public ValueText: string;

    @Expose()
    @Type(() => Date)
	public ValueDate: Date;

	@Expose()
	@Type(() => GenericRef)
	public ValueRef: GenericRef;

    @Expose()
	public Sequence: number;

	@Expose()
	public Bezeichnung: string;

	@Expose()
	public ParamType: ListType;

	@Expose()
	public ValueRefRepresentation: string;
}
