import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { Land } from '@avi-x/avi-dto/partner/land.model';
import { VwbPRechtstraeger } from './vwbprechtstraeger.model';

export class VwbPRechtstraegerDetail extends BaseModel {
    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get Name(): string { return this.getProperty('Name'); }
    public set Name(value: string) { this.setProperty('Name', value); }

    @Expose()
    public get RegisterNummer(): string { return this.getProperty('RegisterNummer'); }
    public set RegisterNummer(value: string) { this.setProperty('RegisterNummer', value); }

    @Expose()
    @TransformDate()
    public get RegisterDatum(): Date { return this.getProperty('RegisterDatum'); }
    public set RegisterDatum(value: Date) { this.setProperty('RegisterDatum', value); }

    @Expose()
    public get RegisterOrt(): string { return this.getProperty('RegisterOrt'); }
    public set RegisterOrt(value: string) { this.setProperty('RegisterOrt', value); }

    @Expose()
    @TransformDate()
    public get Gruendungsdatum(): Date { return this.getProperty('Gruendungsdatum'); }
    public set Gruendungsdatum(value: Date) { this.setProperty('Gruendungsdatum', value); }

    @Expose()
    public get Rechtsform(): string { return this.getProperty('Rechtsform'); }
    public set Rechtsform(value: string) { this.setProperty('Rechtsform', value); }

    @Expose()
    public get Sitz(): string { return this.getProperty('Sitz'); }
    public set Sitz(value: string) { this.setProperty('Sitz', value); }

    // @Expose()
    // public get AnwendbaresRecht(): string { return this.getProperty('AnwendbaresRecht'); }
    // public set AnwendbaresRecht(value: string) { this.setProperty('AnwendbaresRecht', value); }

    // @Expose()
    // public get RechtImAussenverhaeltnis(): string { return this.getProperty('RechtImAussenverhaeltnis'); }
    // public set RechtImAussenverhaeltnis(value: string) { this.setProperty('RechtImAussenverhaeltnis', value); }

    @Expose()
    public get RegisterLand_ID(): string { return this.getModelId('RegisterLand_ID'); }
    public set RegisterLand_ID(value: string) { this.setModelId('RegisterLand_ID', value); }

    @Expose()
    @Type(() => Land)
    public get RegisterLand(): Land { return this.getModel('RegisterLand'); }
    public set RegisterLand(value: Land) { this.setModel('RegisterLand', value); }

    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }

    @Expose()
    public get Rechtstraeger_ID(): string { return this.getModelId('Rechtstraeger_ID'); }
    public set Rechtstraeger_ID(value: string) { this.setModelId('Rechtstraeger_ID', value); }

    @Expose()
    @Type(() => VwbPRechtstraeger)
    public get Rechtstraeger(): VwbPRechtstraeger { return this.getModel('Rechtstraeger'); }
    public set Rechtstraeger(value: VwbPRechtstraeger) { this.setModel('Rechtstraeger', value); }

    @Expose()
    @Type(() => ListType)
    public get Quelle(): ListType { return this.getProperty('Quelle'); }
    public set Quelle(value: ListType) { this.setProperty('Quelle', value); }

    // Zustelladresse
    @Expose()
    public get ZustellAdresseCO(): string { return this.getProperty('ZustellAdresseCO'); }
    public set ZustellAdresseCO(value: string) { this.setProperty('ZustellAdresseCO', value); }

    @Expose()
    public get ZustellAdresseStrasse(): string { return this.getProperty('ZustellAdresseStrasse'); }
    public set ZustellAdresseStrasse(value: string) { this.setProperty('ZustellAdresseStrasse', value); }

    @Expose()
    public get ZustellAdressePostfach(): string { return this.getProperty('ZustellAdressePostfach'); }
    public set ZustellAdressePostfach(value: string) { this.setProperty('ZustellAdressePostfach', value); }

    @Expose()
    public get ZustellAdressePLZ(): string { return this.getProperty('ZustellAdressePLZ'); }
    public set ZustellAdressePLZ(value: string) { this.setProperty('ZustellAdressePLZ', value); }

    @Expose()
    public get ZustellAdresseOrt(): string { return this.getProperty('ZustellAdresseOrt'); }
    public set ZustellAdresseOrt(value: string) { this.setProperty('ZustellAdresseOrt', value); }

    // Transient
    @Expose()
    public get OwnerContainer_ID(): string { return this.getProperty('OwnerContainer_ID'); }
    public set OwnerContainer_ID(value: string) { this.setProperty('OwnerContainer_ID', value); }

    @Expose()
    public get IsTrust(): boolean { return this.getProperty('IsTrust'); }
    public set IsTrust(value: boolean) { this.setProperty('IsTrust', value); }
}
