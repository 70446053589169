import { Expose, Type } from 'class-transformer';

export class ActivityDates {
    @Expose()
    @Type(() => Date)
    public DueDate: Date;

    @Expose()
    @Type(() => Date)
    public RememberDate: Date;

    @Expose()
    @Type(() => Date)
    public EscalationDate: Date;
}
