import { Component, Input, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviFormField, AviCommonService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviApiService } from '@avi-x/avi-core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ScriptRulesetDef } from '@avi-x/avi-dto/system/scriptrulesetdef.model';
import { ScriptRulesetDefCreationParametersDto } from '@avi-x/avi-dto/system/scriptrulesetdefcreationparametersdto.model';
import { GenericRef } from '@avi-x/avi-dto/shared';

const nameof = (nameFunction: ((obj: ScriptRulesetDefCreationParametersDto) => any)) => typed_nameof<ScriptRulesetDefCreationParametersDto>(nameFunction);

@Component({
    selector: 'avi-scriptruleset-creationparameters-form',
    template: `
        <div class="scriptruleset-creationparameters-form">
        <avi-core-base-form #form
            [card]="false"
            [fields]="Fields"
            [(Model)]="Model"
            [loading]="Loading"
            [show-buttons]="ShowButtons"
            [auto-focus]="true"
            [contents-padding]="false"
            (onSave)="saveModel($event)"
            [action-buttons]="actionButtons">        
        
        </avi-core-base-form>
        </div>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRulesetDefCreationParametersFormComponent {  

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    public get FormTitle(): string {
        return 'Regelwerk testen';
    }
    
    @Input('action-buttons')
    actionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.cancel(); } }];

    @Input('show-buttons')
    public ShowButtons: boolean = true;
   
    public Loading: boolean = false;
    public Model: ScriptRulesetDefCreationParametersDto = null;
    
    public Fields: AviFormField[] = [];
    
    constructor(public commonService: AviCommonService,
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private formFieldService: AviFormFieldService,
        private apiService: AviApiService,
		private cdr: ChangeDetectorRef) {

        this.initFields();
    }
        
    ngOnInit() {
        if (this.config && this.config.data && this.config.data.rulesetDefId) {
            this.createModel(this.config.data.rulesetDefId);
        }
    }

    cancel() {
        if (this.ref) {
            this.ref.close(null);
        }
    }
    
    saveModel(model: ScriptRulesetDefCreationParametersDto) {
        if (this.ref) {
            this.ref.close(model);
        }
    }

    async createModel(rulesetDefId: string) {

            this.commonService.showGlobalLoader();
   
            this.Model = new ScriptRulesetDefCreationParametersDto();
            this.Model.Stichtag = new Date();
            this.Model.RulesetDefId = rulesetDefId;

            const rulesetDef = await this.apiService.getModel(ScriptRulesetDef, `scriptrulesetdef/${rulesetDefId}?init=Context`);

            this.commonService.hideGlobalLoader();
            this.Form?.clearFormMessages();

            const ctx1 = this.Fields.find(c => c.Name === nameof(x => x.ModelRef));
            ctx1.DropdownPlaceholder = `Kontext wählen...`;
            ctx1.DropdownValueField = (elem) => new GenericRef(elem.Id, rulesetDef.Context.Model_Name);
            ctx1.DropdownDatasource = `model/representations/${rulesetDef.Context.Model_Name}`;
   
            this.cdr.markForCheck();
            await this.updateDropdownSources();
       
            return this.Model;
    }

    async updateDropdownSources() {
        //await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model, true);
        await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);
        this.cdr.markForCheck();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.ModelRef), 'Kontext', true, 'Representation', 'Id', 2, null).setMDSizeFull());
    }    
}
