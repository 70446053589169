import { Component, OnInit, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { AviApiService, AviBaseSearcherComponent } from '@avi-x/avi-core';
import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { VwbPRechtstraeger, VwbPRechtstraegerDetail, VwbPVersion, VwbPFormularDaten } from '@avi-x/vwbp-public';
import { VwbPServerInfoDto } from '../../../dto/vwbpserverinfo.model';

@Component({
    selector: 'vwbp-admin-techinfo-view',
    templateUrl: './techinfo-view.component.html',
    styleUrls: ['./techinfo-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminTechInfoViewComponent implements OnInit {
    constructor(private cdr: ChangeDetectorRef, private apiService: AviApiService) {
    }

    ServerInfo: VwbPServerInfoDto;
    RechtstraegerId: string;

    rtListe: VwbPRechtstraeger[];
    rtDetListe: VwbPRechtstraegerDetail[];
    rtVerListe: VwbPVersion[];
    rtFormListe: VwbPFormularDaten[];

    @ViewChild('rtSearcher', { static: true })
    public rtSearcher: AviBaseSearcherComponent;

    @ViewChild('rtDetSearcher', { static: true })
    public rtDetSearcher: AviBaseSearcherComponent;

    @ViewChild('rtVerSearcher', { static: true })
    public rtVerSearcher: AviBaseSearcherComponent;

    @ViewChild('rtFormSearcher', { static: true })
    public rtFormSearcher: AviBaseSearcherComponent;

    techAttr: string[] = ['Id', 'version', 'Aktiv', 'ErstellDatum', 'ErstellUser', 'MutDatum', 'MutUser'];

    ngOnInit() {
        this.loadServerInfo();
    }

    async loadServerInfo() {
        this.ServerInfo = await this.apiService.getModel(VwbPServerInfoDto, 'v1/intern/vwbp/admin/serverinfo');
        this.cdr.markForCheck();
    }

    async searchButtonPressed() {
        if (this.RechtstraegerId) {
            const rechtstraeger = await this.apiService.getModel(VwbPRechtstraeger, `v1/intern/vwbp/admin/rt/${this.RechtstraegerId}`);
            if (rechtstraeger) {
                const details = await this.apiService.getModelList(VwbPRechtstraegerDetail, `v1/intern/vwbp/admin/rtdetails/${rechtstraeger.Id}`);
                const versionen = await this.apiService.getModelList(VwbPVersion, `v1/intern/vwbp/admin/rtversions/${rechtstraeger.Id}`);

                this.rtSearcher.clearColumns();

                this.rtListe = [rechtstraeger];
                this.addSearcherColumns(this.rtListe, this.rtSearcher, ['IsDraft', 'VersionCount']);

                this.rtDetListe = details;
                this.addSearcherColumns(this.rtDetListe, this.rtDetSearcher, ['OwnerContainer_ID', 'Rechtstraeger_ID']);

                this.rtVerListe = versionen;
                this.addSearcherColumns(this.rtVerListe, this.rtVerSearcher, ['CurrentRTOwnerContainer_ID', 'VersionMasterOwner_ID', 'Rechtstraeger_ID']);

                this.rtFormListe = this.rtVerListe.reduce((a, b) => a.concat(b.alleFormularDaten), []);
                this.addSearcherColumns(this.rtFormListe, this.rtFormSearcher, ['WBC_KONT', 'WBC_SMO', 'WBC_WB', 'WBT_BEG', 'WBT_KDB', 'WBT_KONT', 'WBT_ORG', 'WBT_PRO', 'WBT_SETTL', 'WBT_SETTL_VS', 'WBT_SMO']);

                this.cdr.markForCheck();
            }
        }
    }

    private addSearcherColumns(obj: BaseModel[], searcher: AviBaseSearcherComponent, hiddenProps: string[] = null) {
        searcher.clearColumns();

        const propNames = obj[0]?.getPropertyNames().sort(this.sortFunc);
        propNames?.forEach(w => {
            if (!w.endsWith('_ID') && propNames.some(x => x === w + '_ID'))
                return;

            if (hiddenProps && hiddenProps.findIndex(x => x === w) !== -1)
                return;

            const lt: ListType = obj.map(x => x[w]).filter(x => typeof x === 'object' && x instanceof ListType)[0];
            const dt = obj.map(x => x[w]).filter(x => typeof x === 'object' && x instanceof Date)[0];

            if (lt)
                searcher.addListTypeColumn(w, w, lt.ListName).setSortable(false);
            else if (dt)
                searcher.addDateColumn(w, w).setSortable(false);
            else
                searcher.addTextColumn(w, w, false).setSortable(false);
        });

        searcher.endInit();
    }

    sortFunc = (a: string, b: string): number => {
        const i1 = this.techAttr.indexOf(a);
        const i2 = this.techAttr.indexOf(b);

        if (i1 !== -1 && i2 === -1) return -1;
        if (i2 !== -1 && i1 === -1) return 1;
        if (i1 !== -1 && i2 !== -1) return i1 - i2;

        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }

    onSelectVersion() {
        this.rtFormSearcher.forceRefresh();
    }

    public SearchRTDelegate: any = async (searchValue: string) => new Promise((resolve) => resolve(this.rtListe));
    public SearchRTDetDelegate: any = async (searchValue: string) => new Promise((resolve) => resolve(this.rtDetListe));
    public SearchRTVerDelegate: any = async (searchValue: string) => new Promise((resolve) => resolve(this.rtVerListe));
    public SearchRTFormDelegate: any = async (searchValue: string) => {
        return new Promise((resolve) => {
            const selectedRows = this.rtVerSearcher.SelectedRows;
            const list = selectedRows.reduce((a, b) => a.concat(b.alleFormularDaten), []);

            return resolve(list);
        });
    }
}
