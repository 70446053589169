import { OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { AviCommonService, AviApiService, ISessionData, AviSessionControllerService, AviTabView } from '@avi-x/avi-core';
import { Subscription } from 'rxjs/Subscription';
import { TechRoleLinkInfo } from './TechRoleLinkInfo';
import { AviTechRoleLinkSearcherComponent } from './techrolelink-searcher.component';
import { AviAppUserGroupLogEntrySearcherComponent } from './appusergrouplogentry-searcher.component';
import { AviAppUserGroupLinkSearcherComponent } from './appusergrouplink-searcher.component';

@Component({
    selector: 'avi-appusergroup-index',
    templateUrl: './appusergroup-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAppUserGroupIndexComponent implements OnInit, OnDestroy, OnChanges {  
    @Input('session-data')
    SessionData: ISessionData;

    @ViewChild('tabView')
    tabView: AviTabView;

    @ViewChild(AviTechRoleLinkSearcherComponent, {static: false})
    private techRoleLinkSearcher: AviTechRoleLinkSearcherComponent;

    @ViewChild(AviAppUserGroupLinkSearcherComponent, {static: false})
    private appUserGroupLinkSearcher: AviAppUserGroupLinkSearcherComponent;

    @ViewChild(AviAppUserGroupLogEntrySearcherComponent, {static: false})
    private logEntrySearcher: AviAppUserGroupLogEntrySearcherComponent;

    ModelId: string = null;
    techRoleLinkInfo: TechRoleLinkInfo;

    sub: Subscription;

   constructor(public apiService: AviApiService,
        public commonService: AviCommonService,
        public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) {
    }
	
    ngOnInit(): void {
        this.sub = this.sessionController.sessionChanged$.subscribe(sessionId => {
            this.tabView.Active = sessionId === this.SessionData?.Id;
        });

        this.techRoleLinkInfo = this.SessionData.Data as TechRoleLinkInfo;
    }
		
    ngOnDestroy(): void {
        if (this.sub)
            this.sub.unsubscribe();
    }
	
    ngOnChanges(changes: SimpleChanges) {
		if (this.SessionData?.ContextId) {
			this.ModelId = this.SessionData.ContextId;
            // this.sessionController.setCurrentSessionTitle();
            if (this.SessionData.Modul)
                setTimeout(() => this.tabView.selectTab(this.SessionData.Modul));
        }
    }

    onAfterEdit() {
        if (this.techRoleLinkSearcher)
            this.techRoleLinkSearcher.searcher.forceRefresh();
        if (this.appUserGroupLinkSearcher)
            this.appUserGroupLinkSearcher.searcher.forceRefresh();
        if (this.logEntrySearcher)
            this.logEntrySearcher.searcher.forceRefresh();
    }
}
