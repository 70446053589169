import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviAuthService, AviCommonService, AviListDetailConst, AviSearcherButtonType, AviSearcherColumn, typed_nameof } from '@avi-x/avi-core';
import { ScriptRuleOutputSearchResultDto } from '@avi-x/avi-dto/system/scriptruleoutputsearchresultdto.model';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AviScriptRuleOutputCommentFormComponent } from './scriptruleoutput-comment-form.component';
import { AviScriptRuleOutputFormComponent } from './scriptruleoutput-form.component';
import { AviScriptRuleOutputPruefenFormComponent } from './scriptruleoutput-pruefen-form.component';
import { TranslateService } from '@ngx-translate/core';

const nameof = (nameFunction: ((obj: ScriptRuleOutputSearchResultDto) => any)) => typed_nameof<ScriptRuleOutputSearchResultDto>(nameFunction);

@Component({
    selector: 'avi-scriptruleoutput-searcher',
    templateUrl: './scriptruleoutput-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRuleOutputSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT';
    SettingsKey = 'settings.searcher.scriptruleoutput';
    BaseUrl = 'scriptruleoutput';
    FormType = AviScriptRuleOutputFormComponent;

    @Input('hide-when-noresults')
    public HideWhenNoResults: boolean = false;
	        
    _Modellist: ScriptRuleOutputSearchResultDto[];
    public get ModelList(): ScriptRuleOutputSearchResultDto[] { return this._Modellist; }

    @Input('modellist')
    public set ModelList(value: ScriptRuleOutputSearchResultDto[]) {
        this._Modellist = value;
        if (value)
            setTimeout(() => this.searcher.forceRefresh());
    }

    @Input('enable-tasks')
    public EnableTasks: boolean = true;

    @Output('onChange')
    public onChange: EventEmitter<any> = new EventEmitter<any>();

    dialogRef: DynamicDialogRef;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private authService: AviAuthService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);
    }

    public GetRowClass = (row: any): string => {
        let ret = ''; //'no-border-left no-border-right';
        if (row) {          
            if (row['Status'].Id === AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_OFFEN || row['Status'].Id === AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_GEPRUEFT1)
                ret += ' row-bold';

            // if (this.DetailModel && row['Id'] === this.DetailModel.Id)
            //     ret += ' ui-state-highlight';
        }

        return ret;
    }

    onSearcherInit(searcher) {
	    searcher.beginInit();
        searcher.addIntegerColumn(nameof(c => c.RuleId), 'CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.RULEID').setSortable(false);
        searcher.addTextColumn(nameof(c => c.Message1), 'CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.MESSAGE1').setSortable(false);
        searcher.addDecimalColumn(nameof(c => c.OutputWert), 'CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.OUTPUTWERT', 2, 2).setSortable(false);
        searcher.addListTypeColumn(nameof(c => c.Status), 'CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.STATUS', 'caf_scriptruleoutput_status').setSortable(false);
        searcher.addTextColumn(nameof(c => c.Geprueft1), 'CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.GEPRUEFT1').setSortable(false);
        searcher.addTextColumn(nameof(c => c.Geprueft2), 'CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.GEPRUEFT2').setSortable(false);
        this.AddColumnActions();
        searcher.endInit();
    }

    
    public AddColumnActions() {
        this.searcher.addColumn(AviSearcherColumn.CreateButton('btnActions', ' ', null, null, null, null, '120px',
                ['btnPruefen1', 'btnPruefen2', 'btnKommentieren'],
                [AviSearcherButtonType.BUTTON, AviSearcherButtonType.BUTTON, AviSearcherButtonType.BUTTON])
            .setButtonOnClickDelegate((row, col, buttonId) => {
                switch (buttonId) {
                    case 'btnPruefen1': this.setRuleOutputGeprueft(row.Id, this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.PRUEFUNG1')); break;
                    case 'btnPruefen2': this.setRuleOutputGeprueft(row.Id, this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.PRUEFUNG2')); break;
                    case 'btnKommentieren': this.doRuleOutputKommentieren(row.Id, this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.KOMMENTIEREN')); break;
                }
            })
            .setButtonClassesDelegate((row, col, buttonId) => {
                let hidden = false;
                let buttonColorGreen = false;
                switch (buttonId) {
                    case 'btnPruefen1':
                        hidden = row.Status.Id === AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_AUTO_ERLEDIGT;
                        buttonColorGreen = (row.Status.Id !== AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_OFFEN);
                        break;
                    case 'btnPruefen2':
                        hidden = row.Status.Id === AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_AUTO_ERLEDIGT;
                        buttonColorGreen = (row.Status.Id !== AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_GEPRUEFT1 && row.Status.Id !== AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_OFFEN);
                        break;
                    case 'btnKommentieren':
                        hidden = !this.EnableTasks;
                        break;
                }

                return {
                    'w-100p': true,
                    'p-button-primary': false,
                    'p-button-secondary': true,
                    'p-button-green-icon': buttonColorGreen,
                    'p-button-outlined': false,
                    'p-button-rounded': true,
                    'p-button-text': true,

                    'hidden': hidden
                };
            })
            .setButtonIconDelegate((row, col, buttonId) => {
                switch (buttonId) {
                    case 'btnPruefen1': return 'pi pi-check-circle';
                    case 'btnPruefen2': return 'pi pi-check-circle';
                    case 'btnKommentieren': return 'pi pi-pencil';
                }
            })
            .setTooltipDelegate((row, col, buttonId) => {
                switch (buttonId) {
                    case 'btnPruefen1': return `CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.PRUEFUNG1`;
                    case 'btnPruefen2': return `CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.PRUEFUNG2`;
                    case 'btnKommentieren': return `CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.KOMMENTIEREN`;
                }
            })
            .setDisabledDelegate((row, col, buttonId) => {
                switch (buttonId) {
                    case 'btnPruefen1': return row.Status.Id !== AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_OFFEN || !this.EnableTasks;
                    case 'btnPruefen2': return row.Status.Id !== AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_GEPRUEFT1 || !this.EnableTasks || this.currentUserSameAsPruefer1(row);
                }
                return false;
            })
            .setCellStyleDelegate(() => {
                return { 'text-align': 'right', 'disabled': true };
            })
            .setSortable(false)
        );
    }

    private currentUserSameAsPruefer1(row: ScriptRuleOutputSearchResultDto): boolean {
        return row.Geprueft1Von_ID === this.authService.CurrentUser.Uid;
    }


    getContextMenuItems = (data, menuItems: MenuItem[]): MenuItem[] => {
        const m = [];

       if (this.EnableTasks) {
            if (data.Status.Id === AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_OFFEN)
                m.push({ label: this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.PRUEFUNG1'), icon: 'pi pi-check',  command: () => this.setRuleOutputGeprueft(data.Id, this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.PRUEFUNG1')) });

            if (data.Status.Id === AviListDetailConst.CAF_SCRIPTRULEOUTPUT_STATUS_GEPRUEFT1 && !this.currentUserSameAsPruefer1(data))
                m.push({ label: this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.PRUEFUNG2'), icon: 'pi pi-check',  command: () => this.setRuleOutputGeprueft(data.Id, this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.PRUEFUNG2')) });
    
            m.push({ label: this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.KOMMENTIEREN'), icon: 'pi pi-pencil',  command: () => this.doRuleOutputKommentieren(data.Id, this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.KOMMENTIEREN')) });
        }

        return m;
    }

    onDoubleClickSearcher(data) {
        this.doRuleOutputKommentieren(data.Id, this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.KOMMENTIEREN'));
    }

    async doRuleOutputKommentieren(id: string, label: string) {
        this.dialogRef = this.dialogService.open(AviScriptRuleOutputCommentFormComponent, {
            header: label,
            closable: true,
            width: '80%',
            data: { 
                Id: id,
                formType: AviScriptRuleOutputCommentFormComponent
            }
        });
    }

    async setRuleOutputGeprueft(id: string, label: string) {
        this.dialogRef = this.dialogService.open(AviScriptRuleOutputPruefenFormComponent, {
            header: label,
            closable: true,
            width: '80%',
            data: { 
                Id: id,
                formType: AviScriptRuleOutputPruefenFormComponent
            }
        });
        this.dialogRef.onClose.subscribe(async (data: any) => {
            if (data) {
                await this.refresh();
                this.onChange.emit();
            }
        });      
    }

    public refresh() {
        this.searcher.forceRefresh();
    }

    public async editObject(selectedRows) {
        this.dialogRef = this.dialogService.open(AviScriptRuleOutputFormComponent, {
            header: this.translateService.instant('CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT.CAFSCRIPTRULEOUTPUT_1'),
            closable: true,
            width: '80%',
            data: { 
                Id: selectedRows.Id, 
                readonly: true,
                formType: AviScriptRuleOutputFormComponent
            }
        });        
    }
    
    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        if (this.ContextId) {
            const cfg =  { ...searchConfig };
            cfg.Query = searchValue;
            cfg.ContextId = this.ContextId;
            const res = await this.apiService.postModelList(ScriptRuleOutputSearchResultDto, `scriptruleoutput/search`, cfg);
            return res;
        } else if (this.ModelList) {
            let res = this.ModelList;
            res = this.searcher.ApplyColumnFilters(res);
            res = this.searcher.ApplyColumnSorting(res);
            return res;
        }

        return [];
    }
}
