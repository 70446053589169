import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviCommonService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviHistoryService } from '@avi-x/avi-core';
import { VwbPListDetailConst } from '../../../constants/constants';
import { VwbPRechtstraegerDetail } from '../../../dto/vwbprechtstraegerdetail.model';

const nameof = (nameFunction: ((obj: VwbPRechtstraegerDetail) => any)) => typed_nameof<VwbPRechtstraegerDetail>(nameFunction);

@Component({
    selector: 'vwbp-public-rechtstraeger-importieren-form',
    template: `
    <avi-core-base-form #form
        [readonly]="false"
        [card]="Card"
        [contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        (onSave)="Save()"
        [save-button-label]="SubmitLabel"
        save-button-icon="pi pi-plus"
        [validation-delegate]="ValidateData"
        [action-buttons]="actionButtons">

        <div header>
            <avi-textblock [identifier]="'vwbp-rechtstraeger-import-dialog-header'"></avi-textblock>
        </div>

        <div formFieldTemplate1>
            <div>
                <button
                    pButton
                    type="button"
                    [label]="'VWBP.COMMON.RT_SUCHEN' | translate"
                    icon="pi pi-search"
                    (click)="ImportHRRechtstraeger()"
                    class="w-100p-m"
                    [disabled]="!Model || Model.RegisterDatum || !Model.Name || Importing"
                    [hidden]="Selected"
                ></button>
            </div>
        </div>

        <div beforeButtons>
            <div class="pl-8p" *ngIf="DataVisible">
                <avi-textblock [identifier]="'vwbp-rechtstraeger-import-dialog-beforebuttons'"></avi-textblock>
            </div>
        </div>


    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPPublicRechtstraegerImportierenFormComponent implements OnInit, OnDestroy {
    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public FormTitle: string = 'Rechtsträger importieren';

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('mode')
    public Mode: 'select' | 'create' = 'select';

    @Input('card')
    public Card: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('submit-label')
    public SubmitLabel: string = 'Speichern';

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    @Output('onSelect')
    public onSelect: EventEmitter<VwbPRechtstraegerDetail> = new EventEmitter();

    public Model: VwbPRechtstraegerDetail = null;

    public Fields: AviFormField[] = [];

    Selected: boolean = false;
    Importing: boolean = false;

    DataVisible: boolean = false;


    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private historyService: AviHistoryService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm() {
        this.Model = null;
        this.Selected = false;
        this.ShowDaten(false);
        this.Model = await this.apiService.getModel(VwbPRechtstraegerDetail, `v1/vwbp/rechtstraeger/detail/create`);
        this.Model.Typ.Id = VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_LI_HRNR;

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RegisterNummer), false);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Name), false);

        this.Form.clearFormMessages();

        this.cdr.markForCheck();

        setTimeout(() => this.Form.focusField(nameof(c => c.RegisterNummer), false));
    }

    ngOnInit() {
        this.initFields();
        this.ShowDaten(false);
    }

    public Save() {
        if (this.Model) {
            if (this.Mode === 'create') {
                this.Form.clearFormMessages();

                const delegate: Promise<any> = this.apiService.post(`v1/vwbp/rechtstraeger/register`, this.Model);

                delegate.then(r => {
                    this.commonService.notificateSuccess('Gespeichert');
                    this.onSavedSuccessfulDelegate.emit(this.Model);

                    if (this.RedirectAfterSave)
                        this.router.navigate([this.RedirectAfterSave]);
                }).catch(err => {
                    console.log(err);
                    if (err instanceof AviApiErrorObject) {
                        this.Form.addFormMessage(err.ErrorMessage, err.Type);
                    } else {
                        this.Form.addFormMessage(JSON.stringify(err));
                    }
                });
            } else if (this.Mode === 'select')
                this.onSelect.emit(this.Model);
        }
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    ImportHRRechtstraeger() {
        this.Importing = true;
        this.Form.clearFormMessages();
        this.cdr.detectChanges();

        const hrRequest = { 'RegisterNummer': this.Model.RegisterNummer, 'Name': this.Model.Name, 'Mode': this.Mode };
        const delegate: Promise<any> = this.apiService.post(`v1/vwbp/rechtstraeger/hr`, hrRequest);

        delegate.then(r => {
            this.Model = r;

            this.Model.Typ.Id = VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_LI_HRNR;

            this.Selected = true;

            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RegisterNummer), true);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Name), true);

            this.ShowDaten(true);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }

            this.Selected = false;
            this.ShowDaten(false);
        });

        this.Importing = false;
    }

    ValidateData = (): boolean => {
        return this.Selected;
    }

    ShowDaten(visible: boolean) {
        this.formFieldService.setFieldVisible(this.Fields, 'lbl1', visible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Rechtsform), visible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Sitz), visible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ZustellAdresseCO), visible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ZustellAdresseStrasse), visible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ZustellAdressePostfach), visible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ZustellAdressePLZ), visible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ZustellAdresseOrt), visible);
        this.DataVisible = visible;

        this.cdr.detectChanges();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateInputMask(nameof(c => c.RegisterNummer), 'VWBP.COMMON.REGISTERNR', 'FL-9999.999.999-9', true).setMDSize(4));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'VWBP.PUBLIC.FORM.WB_NAME_FIRMA', true).setMDSize(4));
        this.Fields.push(this.formFieldService.CreateTemplate(1, '    ').setMDSize(4).setLabelVisible(true).setLabelClass('inline-block'));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Rechtsform), 'VWBP.COMMON.RECHTSFORM', false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Sitz), 'VWBP.COMMON.SITZ', false).setMDSizeHalf().setReadonly());

        // Zustelladresse
        this.Fields.push(this.formFieldService.CreateLabel('lbl1', 'VWBP.COMMON.REPRAESENTANZ_ZUSTELLADRESSE').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdresseCO), 'VWBP.COMMON.CO', false).setMDSizeFull().setReadonly());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdresseStrasse), 'VWBP.COMMON.STRASSE_NUMMER', false).setMDSizeFull().setReadonly());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdressePostfach), 'VWBP.COMMON.POSTFACH', false).setMDSizeFull().setReadonly());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdressePLZ), 'VWBP.COMMON.PLZ', false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdresseOrt), 'VWBP.COMMON.ORT', false).setMDSizeHalf().setReadonly());
    }
}
