import { Expose, Type } from "class-transformer";
import { IDTO } from "../shared";

export class DocuMatrixCreateDocumentResponse extends IDTO {

    @Expose()
    public DraftId: number;

    @Expose()
    @Type(() => Date)
    public CreatedAt: Date;

    @Expose()
    @Type(() => Date)
    public UpdatedAt: Date;

    @Expose()
    public Title: string;

    @Expose()
    public EditorUrl: string;

    @Expose()
    public Editable: boolean;

    @Expose()
    public Deletable: boolean;
    
    @Expose()
    public Contents: string;
  }
  