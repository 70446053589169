import { Expose, Type } from "class-transformer";
import { GenericRef, IDTO, ListType } from "../shared";
import TransformDate from "../shared/transformdate";

export class BusinesscaseSummaryDto extends IDTO {
    @Expose()
    public Id: string;

    @Expose()
    public Title: string;

    @Expose()
    public Number: number;

    @Expose()
    public NumberPrefix: string;

    @Expose()
    public Status_ID: string;

    @Expose()
    public StatusBez: string;

    @Expose()
    public NumberBez: string;
    
    @Expose()
    @Type(() => GenericRef)
    public Context1: GenericRef;
    
    @Expose()
    @Type(() => GenericRef)
    public Context2: GenericRef;

    @Expose()
    @Type(() => ListType)
    public EditFormOnCreate: ListType;

    @Expose()
    @Type(() => ListType)
    public CanCreateComments: ListType;

    @Expose()
    @Type(() => ListType)
    public CanCreateActivities: ListType;
    
    @Expose()
    @TransformDate()
    public Frist: Date;

    @Expose()
    public KulanzFrist: number;
}
