import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocPropContainerTemplateLink } from '@avi-x/avi-dto/documatrix/docpropcontainertemplatelink.model';
import { DocPropContainerSearchResponseDto } from '@avi-x/avi-dto/documatrix/docpropcontainersearchresponsedto.model';
import { DocuMatrixTemplate } from '@avi-x/avi-dto/documatrix/documatrixtemplate.model';

const nameof = (nameFunction: ((obj: DocPropContainerTemplateLink) => any)) => typed_nameof<DocPropContainerTemplateLink>(nameFunction);

@Component({
    selector: 'avi-docpropcontainertemplatelink-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocPropContainerTemplateLinkFormComponent extends AviAbstractModelFormComponent<DocPropContainerTemplateLink> {  
    ClassName = 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERTEMPLATELINK';
    BaseUrl = 'documatrixdef/template/container';
    ClassType = DocPropContainerTemplateLink;
	FormType = AviDocPropContainerTemplateLinkFormComponent;

    private DocuMatrixTemplate: DocuMatrixTemplate;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFDocuMatrixTemplate';
    }
	
    public async afterCreateModel(bm: DocPropContainerTemplateLink) {
        bm.vonTemplate_ID = this.ContextId;

        this.DocuMatrixTemplate = await this.apiService.getModel(DocuMatrixTemplate, `documatrixdef/template/${this.ContextId}`);
    }
    
    queryDocPropContainer = async (field: any) => {
        const ret = [];
        if (this.Model) {
            const cfg: any = { ModelId: this.ReadOnly ? this.Model.vonDocPropContainer_ID : null, ContainerTyp: this.DocuMatrixTemplate?.ContainerTyp?.Id };
            const p = await this.apiService.postPagedResult(DocPropContainerSearchResponseDto, `documatrixdef/container/search`, cfg);
            p.Results?.forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }    

    initFields() {
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonDocPropContainer_ID), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERTEMPLATELINK.VONDOCPROPCONTAINER', this.queryDocPropContainer, true).setAutoselectSingle().setMDSizeFull());
    }
}
