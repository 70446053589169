import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AviQueryDefExecutionGraphComponent } from './components/querydef-exec-graph/querydef-exec-graph.component';
import { AviCoreModule } from '@avi-x/avi-core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GridsterModule } from 'angular-gridster2';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        AviCoreModule,
        NgxChartsModule,
        GridsterModule,
        MatIconModule,
        FlexLayoutModule
    ],
    exports: [
        AviQueryDefExecutionGraphComponent,
        RouterModule
    ],
    declarations: [
        AviQueryDefExecutionGraphComponent,
    ],
})
export class AviQueryDefExecutionGraphModule { }
