import { ChangeDetectorRef, Component } from '@angular/core';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService } from '@avi-x/vwbp-public';
import { VwbPInternMahnwesenService } from '../../services/mahnwesen.service';
import { VwbPInternMahnwesenBaseComponent } from './mahnwesen-base.component';

@Component({
    selector: 'vwbp-intern-mahnwesen-abgeschlossen',
    template: `
        <vwbp-intern-mahnwesen-abgeschlossen-searcher #searcher settingskey="settings.searcher.mw.abgeschlossen" [checbox-selection]="true" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'MahnungCount', 'BussCount', 'LetzteMahnungAm', 'LetzteBusseAm', 'btnOpen']">
        </vwbp-intern-mahnwesen-abgeschlossen-searcher>
        `
})
export class VwbPInternMahnwesenAbgeschlossenComponent extends VwbPInternMahnwesenBaseComponent {
    constructor(public commonService: AviCommonService, public mahnwesenService: VwbPInternMahnwesenService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, mahnwesenService, vwbpCommonService, inputMaskService, cdr);
    }
}
