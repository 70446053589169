import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AviCoreModule } from '@avi-x/avi-core';

import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

import { FormsModule } from '@angular/forms';
import { AviDocPropContainerFormComponent } from './components/docpropcontainer-form.component';
import { AviDocPropContainerIndexComponent } from './components/docpropcontainer-index.component';
import { AviDocPropContainerSearcherComponent } from './components/docpropcontainer-searcher.component';
import { AviDocPropContainerReferenceFormComponent } from './components/docpropcontainerreference-form.component';
import { AviDocPropContainerReferenceSearcherComponent } from './components/docpropcontainerreference-searcher.component';
import { AviDocuMatrixTemplateFormComponent } from './components/documatrixtemplate-form.component';
import { AviDocuMatrixTemplateIndexComponent } from './components/documatrixtemplate-index.component';
import { AviDocuMatrixTemplateSearcherComponent } from './components/documatrixtemplate-searcher.component';
import { AviDocPropContainerTemplateLinkSearcherComponent } from './components/docpropcontainertemplatelink-searcher.component';
import { AviDocPropContainerTemplateLinkFormComponent } from './components/docpropcontainertemplatelink-form.component';
import { AviDocMatrixEditorComponent as AviDocuMatrixEditorComponent } from './components/documatrix-editor.component';
import { AviDokumentModule } from '../dokument/dokument.module';
import { AviDocuMatrixService } from './services/documatrix.service';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        AviCoreModule,
        AviDokumentModule,

        FlexLayoutModule,
        PanelModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ButtonModule,
        SplitButtonModule,
        InputTextModule,

        NgxPermissionsModule.forChild(),

        TranslateModule
    ],
    exports: [
        AviDocPropContainerFormComponent,
        AviDocPropContainerIndexComponent,
        AviDocPropContainerSearcherComponent,
        AviDocPropContainerReferenceFormComponent,
        AviDocPropContainerReferenceSearcherComponent,
        AviDocuMatrixTemplateFormComponent,
        AviDocuMatrixTemplateIndexComponent,
        AviDocuMatrixTemplateSearcherComponent,
        AviDocuMatrixEditorComponent
    ],
    declarations: [
        AviDocPropContainerFormComponent,
        AviDocPropContainerIndexComponent,
        AviDocPropContainerSearcherComponent,
        AviDocPropContainerReferenceFormComponent,
        AviDocPropContainerReferenceSearcherComponent,
        AviDocuMatrixTemplateFormComponent,
        AviDocuMatrixTemplateIndexComponent,
        AviDocuMatrixTemplateSearcherComponent,
        
        AviDocPropContainerTemplateLinkSearcherComponent,
        AviDocPropContainerTemplateLinkFormComponent,
        AviDocuMatrixEditorComponent
    ],
    providers: [ AviDocuMatrixService ]
})
export class AviDocuMatrixModule { }
