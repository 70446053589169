import { AppUserGroupSearchResponseDto } from "@avi-x/avi-dto/system/appusergroupsearchresponsedto.model";
import { TechRoleSearchResponseDto } from "@avi-x/avi-dto/system/techrolesearchresponsedto.model";

export class TechRoleLinkInfo {

    constructor(data?: TechRoleSearchResponseDto | AppUserGroupSearchResponseDto) {
        if (data instanceof TechRoleSearchResponseDto) {
            this.techRoleId = data.Id;
            this.techRoleName = data.RoleName;
        }

        if (data instanceof AppUserGroupSearchResponseDto) {
            this.appUserGroupId = data.Id;
        }
    }

    appUserGroupId: string;
    techRoleId: string;
    techRoleName: string;

    get isForAppUserGroup(): boolean {
        return this.appUserGroupId != null;
    }
}