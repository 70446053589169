
    <div class="ui-g pl-3 pr-3 pt-3">
        <div class="ui-g-12">
            <p-toolbar>

                <div class="ui-toolbar-group-left flex">
                    <button pButton
                        type="button"
                        label="Ändern"
                        icon="pi pi-pencil"
                        (click)="editActivity()"
                        class="w-100p-m p-button-secondary">
                    </button>

                    <p-divider layout="vertical" ></p-divider>

                    <button *ngFor="let task of Tasks; let i = index"
                        pButton
                        type="button"
                        [label]="task.Bezeichnung"
                        icon="fa fa-sign-out"
                        (click)="performTask(task)"
                        class="w-100p-m p-button-secondary"
                        [ngClass]="{ 'mr-1': i < Tasks.length - 1 }">
                    </button>

                    <p-divider *ngIf="Tasks.length > 0" layout="vertical" ></p-divider>

                    <button pButton
                        type="button"
                        label="Delete"
                        icon="pi pi-trash"
                        (click)="deleteActivity()"
                        class="red-btn w-100p-m p-button-secondary">
                    </button>

                    <p-divider layout="vertical" ></p-divider>

                    <button pButton
                        type="button"
                        label="Teilaufgabe"
                        icon="pi pi-plus"
                        (click)="createActivity()"
                        class="green-btn w-100p-m p-button-secondary">
                    </button>

                </div>
            </p-toolbar>
        </div>
    </div>

    <avi-core-view-panel header="Aktivität" [toggleable]="false">
        <avi-crm-activity-form #activityForm
            [card]="false"
            [readonly]="false"
            [show-buttons]="false"
            [inline-modus]="true"
            [auto-focus]="false"
            [label-alignment]="'left'"
            (onSavedSuccessful)="onSavedSuccessful($event)">
        </avi-crm-activity-form>
    </avi-core-view-panel>

    <avi-core-dialog #editActivityFormDialog
        identifier="editActivityFormDialog"
        [showCloseIcon]="false"
        [closeOnEscape]="false"
        (onOpenFinished)="onOpenEditForm()"
    >
        <avi-crm-activity-form #editActivityForm
            [status-wechsel]="EditStatusWechselDef"
            (onSavedSuccessful)="onSavedSuccessful($event)"
            [redirect-after-save]="null"
            [redirect-after-delete]="null"
            [action-buttons]="formActionButtons">
        </avi-crm-activity-form>
    </avi-core-dialog>

    <avi-core-view-panel *ngIf="SubTasks.length > 0" header="Teilaufgaben" [toggleable]="false">

            <div class="ui-g card-contents">
                <div class="ui-g-12">

                    <table class="subtask-list">
                        <tr>
                            <!-- <th width='20px'></th> -->
                            <th>Titel</th>
                            <th>Start</th>
                            <th>Fällig</th>
                            <th>Status</th>
                            <th>Priorität</th>
                            <th>Assigned</th>
                        </tr>
    
                        <tr *ngFor="let subtask of SubTasks; let i = index" (click)="openActivity(subtask.Id)" class="selectable">
                            <td class="subtask-title">{{ subtask.Titel }}</td>
                            <td>{{ subtask.StartDate | date: "dd.MM.y" : "de-CH" }}</td>
                            <td>{{ subtask.DueDate | date: "dd.MM.y" : "de-CH" }}</td>
                            <td><span [class]="'status-badge status-' + commonService.str2CSSName(subtask.StatusBez)">{{ subtask.StatusBez }}</span></td>
                            <td><div><i class="valign-center mr-4p pi" [ngClass]="activityService.GetPriorityIcon(subtask.Priority)"></i><span>{{ subtask.Priority.Bezeichnung }}</span></div></td>
                            <td>{{ subtask.AssignedToName }}</td>
                        </tr>
                    </table>
            </div>
        </div>
    </avi-core-view-panel>

    <avi-core-view-panel *ngIf="FormModelId" header="Kommentare">
        <div class="ui-g card-contents">
            <div class="ui-g-12">
                <avi-crm-comment-list *ngIf="FormModelId"
                    [context-id] = "FormModelId"
                    context-type = 'aviita.CAF.common.modul.activity.model.Activity'
                    [rtf]="IsRTF"
                >

                </avi-crm-comment-list>

            </div>
        </div>
    </avi-core-view-panel>
<!--
    <avi-core-view-panel *ngIf="ChangeLog.length > 0" header="Historie">

        <div class="ui-g card-contents">
            <div class="ui-g-12">

                <div *ngFor="let cl of ChangeLog; let i = index" >

                    <div class="history_separator"></div>

                    <div class="selectable pt-3 pb-3">
                        <div class="history_datum">{{ cl.ErstellDatum }}</div>

                        <div class="history_details">
                            <span class="history_user">{{ cl.ErstellUser }}</span>

                            <span *ngIf="cl.ChangeType == 'Insert'"> hat die Aktivität '{{ getTitel(cl.ChangedAttributes) }}' erfasst</span>

                            <span *ngIf="cl.ChangeType == 'Update'">
                                <span *ngIf="cl.ChangedAttributes.length === 1"> hat das Feld {{ cl.ChangedAttributes[0].Field }} auf '{{ cl.ChangedAttributes[0].NewValue }}' mutiert</span>
                                <span *ngIf="cl.ChangedAttributes.length > 1"> hat die folgende Felder mutiert:
                                    <ul>
                                    <li *ngFor="let attr of cl.ChangedAttributes" >
                                        {{ attr.Field }} to {{ attr.NewValue }}
                                    </li>
                                    </ul>
                                </span>
                            </span>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </avi-core-view-panel>
        -->
