import { Expose } from "class-transformer";
import { IDTO } from "../shared";
import TransformDate from "../shared/transformdate";

export class StatusWechselRequestDto extends IDTO
{
    @Expose()
    public Id: string;

    @Expose()
    public Bemerkung: string;

    @Expose()
    @TransformDate()
    public Frist: Date;

    @Expose()
    public KulanzFrist: number;
}
