import { Component, OnInit, ChangeDetectionStrategy, ViewChild, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AviApiService, AviBaseSearcherComponent, AviCommonService, AviDialogComponent, AviPartnerListConst, AviSearcherColumn } from '@avi-x/avi-core';
import { AviPartnerIdentifikationFormComponent } from '../partner-identifikation-form/partner-identifikation-form.component';

@Component({
    selector: 'avi-crm-partner-identifikation-liste',
    templateUrl: './partner-identifikation-liste.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviPartnerIdentifikationListeComponent implements OnInit, AfterViewInit {
    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    @ViewChild('formDialog', { static: false })
    formDialog: AviDialogComponent;

    @ViewChild('form', { static: false })
    public form: AviPartnerIdentifikationFormComponent;

    FormModelId: string = null;
    formActionButtons: any = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.closeForm(); } }];

    public Filters: string[] = null;

    @Input('partner-id')
    public partnerId: string = null;

    public DetailModelId: string = null;


    constructor(private activatedRoute: ActivatedRoute, public apiService: AviApiService, public commonService: AviCommonService) {
    }

    public onInit(data) {
        this.searcher.beginInit();
        this.searcher.addTextColumn('IdentifikationsNr', 'Identifikations-Nr.', true);
        this.searcher.addListTypeColumn('IdentifikationsArt', 'Identifikationsart', AviPartnerListConst.CAF_IDENTIFIKATIONSART, true, true, null);
        this.searcher.addTextColumn('SteuernummerDef.Bezeichnung1', 'Steuernummer-Typ', true);
        this.searcher.addDateColumn('GueltigAb', 'Gültig ab', null, true);
        this.searcher.addDateColumn('GueltigBis', 'Gültig bis', null, true);
        this.searcher.addTextColumn('vonLand.Bezeichnung1', 'Land', false).setVisible(false);
        this.searcher.addTextColumn('vonLand2.Bezeichnung1', 'Land2', false).setVisible(false);
        this.searcher.addTextColumn('vonLand3.Bezeichnung1', 'Land3', false).setVisible(false);
        this.searcher.addTextColumn('vonLand4.Bezeichnung1', 'Land4', false).setVisible(false);

        const col = AviSearcherColumn.CreateCustom(
            'Laender',
            'Länder',
            (row, col) => {
                return [row['vonLandBezeichnung1'], row['vonLand2Bezeichnung1'], row['vonLand3Bezeichnung1'], row['vonLand4Bezeichnung1']]
                    .filter(w => w != null && w !== '').join(', ');
            },
            false
        ).setSortable(false);

        col.OField = null;

        this.searcher.addColumn(col);

        this.searcher.endInit();

        // TODO: Detail / DetailTyp

        // if (jurperson)
        // {
        //     alleIdentifikationenListPanel.addColumn(new PathBuilder(typeof(CAFPartnerIdentifikation)).add(CAFPartnerIdentifikation.c_SponsoredGIINIdent).add(CAFPartnerIdentifikation.c_IdentifikationsNr), PartnerResConst.CAFPARTNERIDENTIFIKATION_SPONSOREDGIINIDENT.Label());
        //     alleIdentifikationenListPanel.addColumn(new PathBuilder(typeof(CAFPartnerIdentifikation)).add(CAFPartnerIdentifikation.c_SponsoredGIINIdent).add(CAFPartnerIdentifikation.c_vonPartner).add(CAFBasePartner.c_Name), PartnerResConst.CAFPARTNERIDENTIFIKATION_SPONSOREDGIINIDENT.Label());
        // }
    }

    onSelect(data) {
        if (data && data[0])
            this.DetailModelId = data[0].Id;
    }
    ngOnInit() {
    }

    public getFilters: any = (): string[] => {

        let res = [];
        if (this.partnerId != null)
            res = [...res, `vonPartner_ID eq ${this.partnerId}`];

        if (!this.searcher.showInaktive)
            res = [...res, 'GueltigAb le today', 'GueltigBis ge today'];

        return res;
    }

    ngAfterViewInit() {
        // this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
        //     setTimeout(() => { this.partnerId = res; this.searcher.endInit() }, 0);
        // });

    }

    // Form Dialog
    public createObject() {
        this.FormModelId = '0';
        this.formDialog.open();
    }

    public editObject() {
        if (this.searcher.SelectedRows.length > 0) {
            this.FormModelId = this.searcher.SelectedRows[0].Id;
            this.formDialog.open();
        }
    }

    public deleteObject() {
        this.apiService.deleteObject('partneridentifikation', this.searcher.SelectedRows[0]?.Id, () => this.searcher.forceRefresh());
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.searcher.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.formDialog.close();
    }

}
