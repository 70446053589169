import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { concatMap, delay, retryWhen } from 'rxjs/operators';

export const retryTimes = 3;
export const retryDelay = 1;

@Injectable()
export class HttpRetryInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            retryWhen((error) =>
                error.pipe(
                    concatMap((error, count) => {
                        if (count < retryTimes && (error.status === 0 || error.status === 503)) {
                            console.log(`HTTP Request failed, retrying in ${retryDelay} seconds`);
                            return of(error);
                        }

                        return throwError(error);
                    }),
                    delay(retryDelay*1000)
                )
            )
        );
    }
}
