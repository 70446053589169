import { VwbPActionConst, VwbPActionData, VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { Injectable } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';
import { Router, UrlSerializer } from '@angular/router';
import { VwbPBehoerdensucheRtRequestDto, VwbPBehoerdensucheWbRequestDto, VwbPBehoerdensucheKdbRequestDto, VwbPBehoerdensucheWbJpInlandRequestDto, VwbPBehoerdensucheWbJpAuslandRequestDto } from '../../dto/vwbpbehoerdensuche.models';


@Injectable({
    providedIn: 'root'
})
export class VwbPInternBehoerdenService {

    constructor(private apiService: AviApiService,
        private router: Router,
        private serializer: UrlSerializer,
        private vwbpCommonService: VwbPCommonService,
    ) { }

    StartBehoerdensucheRt(data: VwbPBehoerdensucheRtRequestDto): Promise<any> {
        return this.apiService.post('v1/intern/vwbp/rechtstraeger/behoerdensuche/rt', data);
    }

    StartBehoerdensucheWb(data: VwbPBehoerdensucheWbRequestDto): Promise<any> {
        return this.apiService.post('v1/intern/vwbp/rechtstraeger/behoerdensuche/wb', data);
    }

    StartBehoerdensucheWbJpInland(data: VwbPBehoerdensucheWbJpInlandRequestDto): Promise<any> {
        return this.apiService.post('v1/intern/vwbp/rechtstraeger/behoerdensuche/wb-jp-inland', data);
    }

    StartBehoerdensucheWbJpAusland(data: VwbPBehoerdensucheWbJpAuslandRequestDto): Promise<any> {
        return this.apiService.post('v1/intern/vwbp/rechtstraeger/behoerdensuche/wb-jp-ausland', data);
    }

    StartBehoerdensucheKdb(data: VwbPBehoerdensucheKdbRequestDto): Promise<any> {
        return this.apiService.post('v1/intern/vwbp/rechtstraeger/behoerdensuche/kdb', data);
    }



    StartBehoerdensucheRtIds(data: VwbPBehoerdensucheRtRequestDto): Promise<any> {
        return this.apiService.post(`v1/intern/vwbp/rechtstraeger/behoerdensuche/rt/ids`, data);
    }

    StartBehoerdensucheWbIds(data: VwbPBehoerdensucheWbRequestDto): Promise<any> {
        return this.apiService.post('v1/intern/vwbp/rechtstraeger/behoerdensuche/wb/ids', data);
    }

    StartBehoerdensucheWbJpInlandIds(data: VwbPBehoerdensucheWbJpInlandRequestDto): Promise<any> {
        return this.apiService.post('v1/intern/vwbp/rechtstraeger/behoerdensuche/wb-jp-inland/ids', data);
    }

    StartBehoerdensucheWbJpAuslandIds(data: VwbPBehoerdensucheWbJpAuslandRequestDto): Promise<any> {
        return this.apiService.post('v1/intern/vwbp/rechtstraeger/behoerdensuche/wb-jp-ausland/ids', data);
    }

    StartBehoerdensucheKdbIds(data: VwbPBehoerdensucheKdbRequestDto): Promise<any> {
        return this.apiService.post('v1/intern/vwbp/rechtstraeger/behoerdensuche/kdb/ids', data);
    }
}
