import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { ListType } from '../shared';
import { DecisionTableParamSearchResponseDto } from './decisiontableparamsearchresponsedto.model';

export class DecisionTableEntrySearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
	public Sequenz: number;

	@Expose()
	public DecisionTableParam1: string;

	@Expose()
	public DecisionTableParam2: string;

	@Expose()
	public DecisionTableParam3: string;

	@Expose()
	public DecisionTableParam4: string;

	@Expose()
	public DecisionTableParam5: string;

	@Expose()
	public DecisionTableParam6: string;

	@Expose()
	public DecisionTableParam7: string;

	@Expose()
	public DecisionTableParam8: string;

	@Expose()
	public DecisionTableParam9: string;

	@Expose()
	public DecisionTableParam10: string;

	@Expose()
	public DecisionTableParam11: string;

	@Expose()
	public DecisionTableParam12: string;

	@Expose()
	public DecisionTableParam13: string;

	@Expose()
	public DecisionTableParam14: string;

	@Expose()
	public DecisionTableParam15: string;

	@Expose()
	public DecisionTableParam16: string;

	@Expose()
	public DecisionTableParam17: string;

	@Expose()
	public DecisionTableParam18: string;

	@Expose()
	public DecisionTableParam19: string;

	@Expose()
	public DecisionTableParam20: string;

}
