import { DomHandler } from 'primeng/dom';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { Injectable, Inject, forwardRef, ViewContainerRef, EventEmitter, SecurityContext } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ConfirmationService, Confirmation } from 'primeng/api';

import * as moment from 'moment-timezone';

import { HotkeysService, Hotkey } from 'angular2-hotkeys';

import { AviCoreConfig } from '../avi-core.config';
import { AviListDetailConst, AviTypeUtil } from '../shared/constants/constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AviApiErrorObject } from '../dto/aviapierrorobject';
import { ClassType, RgbaColor } from '@avi-x/avi-dto/shared';
import { AviSessionControllerService } from './session-controller.service';
import { AviConfirmFormComponent } from '../components/controls/confirm-form/confirm-form.components';
import { first, tap } from 'rxjs/operators';
import { ObjectUtils } from '../components/utils/object-utils';
import { Subscription } from 'rxjs';
import { AviMetaBrowserComponent } from '../components/meta-browser/meta-browser.component';
import { InputTextarea } from 'primeng/inputtextarea';

export interface ConfirmationTB extends Confirmation {
    messageData?: { [klass: string]: any }
}

export interface IAppVersion {
    version: string;
    buildDate: string;
    buildTime: string;
}

export enum MessageType {
    Info,
    Warning,
    Exclamation,
    Error
}

@Injectable({ providedIn: 'root' })
export class AviCommonService {
    public static _localStoreKeyPrefix = 'avix::';

    public registeredHotkey: any = { global: [], private: [] };
    public displayGlobalSearchDialog = false;
    public GlobalSearchData: any[] = [];
    private filteredGlobalSearchResult: any[] = [];
    public filterGlobalSearchResultValue;
    public DebugEnabled = true;

    public GloballoaderChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    public GlobalLoaderVisible = false;

    public AppName: string;
    public AppVersion: IAppVersion;
    public InputStyle: string;

    private errorNotificationInterceptor: Function = null;

    public locale: any = {
        en: {
            firstDayOfWeek: 0,
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'mm/dd/yy',
            weekHeader: 'Wk'

        },
        de: {
            firstDay: 1,
            firstDayOfWeek: 1,
            dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
            dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
            monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
            monthNamesShort: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
            today: 'Heute',
            clear: 'Zurücksetzen',
            dateFormat: 'dd.mm.yy',
            weekHeader: 'W'
        }

    };

    public static localStorageGet<T>(key: string, defaultValue: T = null, encoded: boolean = true, justSession: boolean = false): T {
        let d: any = null;
        if (justSession) {
            d = sessionStorage.getItem(this._localStoreKeyPrefix + key);
        } else {
            d = localStorage.getItem(this._localStoreKeyPrefix + key);
            if (!d) d = sessionStorage.getItem(this._localStoreKeyPrefix + key);
        }
        if (d === 'undefined')
            return defaultValue;
        const ret = d ? JSON.parse(encoded ? window.atob(d) : d) as T : null;
        const retval = ret ? ret : defaultValue;
        return retval;
    }

    public static localStorageSet(key: string, value: any, encoded: boolean = true, justSession: boolean = false): void {
        if (!justSession) {
            localStorage.setItem(this._localStoreKeyPrefix + key, encoded ? window.btoa(JSON.stringify(value)) : JSON.stringify(value));
        } else {
            sessionStorage.setItem(this._localStoreKeyPrefix + key, encoded ? window.btoa(JSON.stringify(value)) : JSON.stringify(value));
        }
    }

    public static localStorageDelete(key: string) {
        localStorage.removeItem(this._localStoreKeyPrefix + key);
        sessionStorage.removeItem(this._localStoreKeyPrefix + key);
    }

    public stringifyWithNl(data: any) {
        return JSON.stringify(data);
        // return JSON.stringify(data).replace(/\\r\\n/g, '\\n').replace(/\\n/g, '\\r\\n'); // Das geht hier nicht so einfach, wenn ein String bereits escaped ist: z.B. das geht schief: const data = { "Model": "{\"Adresse\":\"aaa\\nbbb\"}", "Draft": false};
    }

    public get loginPath(): string {
        return this.config && this.config.loginPath ? this.config.loginPath : '/login';
    }

    public get IsProduction(): boolean {
        return this.config && this.config.environment && this.config.environment.production === true;
    }

    public GetEnvironment(): any {
        return this.config && this.config.environment ? this.config.environment : null;
    }

    public GetDateAsString(format: string = 'YYYY-MM-DD', date: Date = null) {
        if (!date)
            date = new Date();

        return moment(date).format(format);
    }

    public isEqualIgnoreCase(a: any, b: any) {
        return typeof a === 'string' && typeof b === 'string' ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0 : a === b;
    }

    public initBaseModel(): any {
        return {
            'Aktiv': { 'Id': '73DA8568-3CFF-4E63-9394-3AD7D60E9DA5' }
        };
    }

    constructor(@Inject('config') public config: AviCoreConfig,
        private confirmationService: ConfirmationService,
        public toastr: ToastrService,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private sanitizer: DomSanitizer,
        private sessionController: AviSessionControllerService,
        @Inject(forwardRef(() => HotkeysService)) public hotkeysService: HotkeysService) {
        if (!config?.environment?.production)
            console.debug('AviCommonService constructor -> config', config);

        this.sessionController.sessionChanged$.subscribe(sessionId => this.addHotkeysForSession(sessionId));
        this.sessionController.sessionClosed$.subscribe(sessionId => this.removeHotkeysForSession(sessionId));
    }


    public toSaveHtml(data): SafeHtml {
        return this.sanitizer.sanitize(SecurityContext.HTML, data);
    }

    public escapeHtml(text): string {
        if (!text)
            return '';
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            '\'': '&#039;'
        };

        return `${text}`.replace(/[&<>"']/g, function (m) {
            return map[m];
        });
    }

    public trimUrl(url: string) {
        if (url && url.endsWith('/'))
            url = url.substring(0, url.length - 1);
        return url;
    }


    public calcAge(date: Date) {
        return moment().diff(moment(date), 'years');
    }

    public formatNumber(number: number, currency: string = null, maximumFractionDigits: number = null, locale: string = 'de-CH') {
        if (currency)
            return new Intl.NumberFormat(locale, { style: 'currency', currency: currency, maximumFractionDigits: maximumFractionDigits }).format(number)
        else
            return new Intl.NumberFormat(locale, { maximumFractionDigits: maximumFractionDigits }).format(number);
    }

    public formatDate(date: string | Date, format: string = null): string {
        if (!date)
            date = new Date();
        if (format == null)
            format = 'L';
        const m = moment(date);

        return m.format(format);
    }

    public round(value, precision) {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    public roundTime(time: string, minutesToRound: number): string;
    public roundTime(time: Date, minutesToRound: number): Date;

    public roundTime(time: string | Date, minutesToRound: number): string | Date {
        if (time instanceof Date) {
            const coeff = 1000 * 60 * minutesToRound;
            return new Date(Math.round(time.getTime() / coeff) * coeff);
        } else {
            let hours: number = 0;
            let minutes: number = 0;
            const [hoursStr, minutesStr] = time.split(':');
            hours = parseInt(hoursStr, 10);
            minutes = parseInt(minutesStr, 10);

            // Convert hours and minutes to time in minutes
            const timeInMinutes = (hours * 60) + minutes;

            const rounded = Math.round(timeInMinutes / minutesToRound) * minutesToRound;
            const rHr = '' + Math.floor(rounded / 60);
            const rMin = '' + rounded % 60;

            return rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0');
        }
    }

    public getUTC00(dt?: Date): Date {
        const date = dt ? dt : new Date();
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
    }

    public getDate00(dt?: Date): Date {
        const date = dt ? dt : new Date();
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    }

    public convertDateToUTC(dt?: Date): Date {
        const date = dt ? dt : new Date();
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    }

    public correctForTimezone(dt: Date): Date {
        if (!dt)
            return dt;
        return new Date(dt.getTime() - dt.getTimezoneOffset() * 60000);
    }

    public getDateAddYears(dt: Date, years: number): Date {
        if (!dt)
            return dt;

        return new Date(dt.getFullYear() + years, dt.getMonth(), dt.getDay(), dt.getHours(), dt.getMinutes(), dt.getMilliseconds());
    }

    public parseDate(str: string, format: string = 'DD.MM.YYYY') {
        return moment(str, format).toDate();
    }

    public tryParseDate(str: string) {
        if (str) {
            let m = moment(str, 'DD.MM.YYYY', true);
            if (m.isValid())
                return m.toDate();

            m = moment(str, 'D.M.YYYY', true);
            if (m.isValid())
                return m.toDate();

            m = moment(str, 'DD.MM.YYYY hh:mm:ss', true);
            if (m.isValid())
                return m.toDate();

            m = moment(str, 'DD.MM.YYYY - hh:mm:ss', true);
            if (m.isValid())
                return m.toDate();
        }

        return null;
    }

    public decimalToTimeString(val: number): string {
        let decimalTime = val;
        const hours = Math.floor(decimalTime);
        decimalTime = decimalTime - hours;
        const minutes = Math.round(decimalTime * 60);
        return `${('00' + hours).slice(-2)}:${('00' + minutes).slice(-2)}`;
    }

    public removePrivateHotkeys() {
        this.registeredHotkey.private.forEach(hk => {
            this.hotkeysService.remove(hk.hotkey);
        });
        this.registeredHotkey.private = [];
    }

    public removeGlobalHotkeys() {
        this.registeredHotkey.global.forEach(hk => {
            this.hotkeysService.remove(hk.hotkey);
        });
        this.registeredHotkey.global = [];
    }

    public removeHotkey(key: string | string[], fromPrivate: boolean = true, fromGlobal: boolean = true, sessionId: string = null) {
        if (fromPrivate) {
            const sid = sessionId ?? this.sessionController.getCurrentSessionId();
            this.registeredHotkey.private.forEach((hk, index, obj) => {
                if (JSON.stringify(hk.key) === JSON.stringify(key)) {
                    this.hotkeysService.remove(hk.hotkey);
                    if (sid === hk.sessionId)
                        this.registeredHotkey.private.splice(index, 1);
                }
            });
        }

        if (fromGlobal) {
            this.registeredHotkey.global.forEach((hk, index, obj) => {
                if (JSON.stringify(hk.key) === JSON.stringify(key)) {
                    this.hotkeysService.remove(hk.hotkey);
                    this.registeredHotkey.global.splice(index, 1);
                }
            });
        }
    }

    public addHotkeysForSession(sessionId: string) {
        [...this.registeredHotkey.private.filter(w => w.sessionId === sessionId)].forEach(w => this.addHotkey(w.key, w.callback, w.description));
    }

    public removeHotkeysForSession(sessionId: string) {
        this.registeredHotkey.private.filter(w => w.sessionId === sessionId).forEach(w => this.removeHotkey(w.key, true, true, sessionId));
    }

    public addGlobalHotkey(key: string | string[], callback: Function, description: string = null, allowInInput: boolean = false): Hotkey | Hotkey[] {
        return this.addHotkey(key, callback, description, true, allowInInput);
    }

    // allowInInput ist defaultmässig false, ansonsten kann man oft nicht mehr mit den Arrowkeys innerhalb von einem Input navigieren (base-searcher registriert bspw. Arrowkeys)
    public addHotkey(key: string | string[], callback: Function, description: string = null, global: boolean = false, allowInInput: boolean = false): Hotkey | Hotkey[] {

        this.removeHotkey(key, true, true);

        const hk = this.hotkeysService.add(new Hotkey(key, (event: KeyboardEvent): boolean => {
            callback(event, key, description);
            return false; // Prevent bubbling
        }, allowInInput ? ['INPUT', 'SELECT', 'TEXTAREA'] : null, description ? `${global ? 'Global:' : 'Aktuelle Seite:'} ${description}` : null));

        if (global)
            this.registeredHotkey.global.push({ key: key, callback: callback, hotkey: hk, description: description });
        else {
            const sessionId = this.sessionController.getCurrentSessionId();
            const idx = this.registeredHotkey.private.findIndex(w => JSON.stringify(w.key) === JSON.stringify(key) && w.sessionId === sessionId);
            if (idx >= 0)
                this.registeredHotkey.private[idx] = { key: key, callback: callback, hotkey: hk, description: description, sessionId: sessionId };
            else
                this.registeredHotkey.private.push({ key: key, callback: callback, hotkey: hk, description: description, sessionId: sessionId });
        }

        return hk;
    }

    public displayGlobalSearch(show: boolean = true, clearText: boolean = true) {
        this.displayGlobalSearchDialog = show;

        if (show) {
            // Naja...
            setTimeout(function () {
                const sf: any = document.getElementById('acGlobalSearchInput');
                sf.select();
                sf.focus();
                if (clearText)
                    sf.value = '';
            }, 100);
        }
    }

    public addGlobalSearchData(title: string, command: string | Function, description: string = null, priority: number = 1000) {
        this.GlobalSearchData.push({ title: title, command: command, description: description, priority: priority });
        this.GlobalSearchData.sort((a, b) => {
            const aSize = a.priority;
            const bSize = b.priority;
            const aLow = a.title;
            const bLow = b.title;
            return (aSize < bSize) ? -1 : (aSize > bSize) ? 1 : ((aLow < bLow) ? -1 : (aLow > bLow) ? 1 : 0);
            // return (a.priority - b.priority || a.title - b.title);//  ? 1 : -1;
        });
    }

    public callGlobalSearchData(item, router) {
        this.displayGlobalSearchDialog = false;
        if (typeof item.command === 'string') {
            router.navigate([item.command]);
        } else {
            item.command();
        }
        this.filterGlobalSearchResultValue = '';
    }

    public getFilteredGlobalSearchResult(): any[] {
        return this.filteredGlobalSearchResult;
    }

    public filterGlobalSearchResult(event) {
        const query: string = event.query;

        // console.log("filteredGlobalSearchResult", query);
        if (!query) {
            this.filteredGlobalSearchResult = this.GlobalSearchData;
            this.filterGlobalSearchResultValue = '';
        } else {
            const splittedQuery = query.toLowerCase().trim().split(' ');
            this.filteredGlobalSearchResult = this.GlobalSearchData.filter((value, index, array) => {
                const title: string = value.title.toLowerCase();
                let ret = true;
                splittedQuery.forEach(searchElement => {
                    if (title.indexOf(searchElement) < 0)
                        ret = false;
                });
                return ret;
            });
        }
    }

    public getUrlOrigin(urlstring: string) {
         return new URL(urlstring).origin;
    }

    public get ContentHeight(): number {
        const topbar = document.getElementsByClassName('topbar');
        let topbarHeight = 0;
        if (topbar && topbar.length > 0)
            topbarHeight = topbar[0].clientHeight;

        const footer = document.getElementsByClassName('topbar');
        let footerHeight = 0;
        if (footer && footer.length > 0)
            footerHeight = footer[0].clientHeight;

        return window.innerHeight - topbarHeight - footerHeight;
    }

    public IsMobileView(minWidth: number = 1024): boolean {
        return window.innerWidth < minWidth;
    }

    public IsDesktopView(minWidth: number = 1024): boolean {
        return window.innerWidth >= minWidth;
    }

    public IsTabletView(minWidth: number = 642): boolean {
        return window.innerWidth < minWidth;
    }

    public localStorageGet<T>(key: string, defaultValue: T = null, encoded: boolean = true, justSession: boolean = false): T {
        return AviCommonService.localStorageGet<T>(key, defaultValue, encoded, justSession);
    }

    public localStorageSet(key: string, value: any, encoded: boolean = true, justSession: boolean = false): void {
        AviCommonService.localStorageSet(key, value, encoded, justSession);
    }

    public localStorageDelete(key: string) {
        AviCommonService.localStorageDelete(key);
    }

    public nl2br(value: string): string {
        return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br />' + '$2');
    }

    public text2html(s) {
        return s.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/'/g, '&#39;')
            .replace(/"/g, '&#34;')
            .replace(/\r\n/g, '\r')
            .replace(/\n/g, '\r')
            .replace(/\r/g, '<br>\r\n')
            .replace(/  /g, ' &nbsp;')
            ;
    }

    public dbg(data: any) {
        return JSON.stringify(data, null, 2);
    }

    public trimComma(value: string): string {
        return value ? value.trim().replace(/(^,)|(,$)/g, '').trim() : value;
    }

    private _log(type: string, message: string, ...data: any[]) {
        const prefix = 'AviXLog';
        if (type === 'debug')
            console.debug(prefix, message, ...data);
        else if (type === 'warn')
            console.warn(prefix, message, ...data);
        else if (type === 'error')
            console.error(prefix, message, ...data);
        else
            console.log(prefix, message, ...data);
    }

    public logDebug(message: string, ...data: any[]) {
        this._log('debug', message, ...data);
    }

    public logError(message: string, ...data: any[]) {
        this._log('error', message, ...data);
    }

    public logWarn(message: string, ...data: any[]) {
        this._log('warn', message, ...data);
    }

    public isGlobalLoaderVisible(): boolean {
        return this.GlobalLoaderVisible;
    }

    public showGlobalLoader(): void {
        this.GlobalLoaderVisible = true;
        this.GloballoaderChanged.emit(true);
    }
    public hideGlobalLoader() {
        this.GlobalLoaderVisible = false;
        this.GloballoaderChanged.emit(false);
    }

    /*
    private globalLoader: ElementRef = null;
    private globalLoaderHead: ElementRef = null;
    private globalRenderer: Renderer = null;
    public initGlobalLoader(loader: ElementRef, renderer: Renderer, loaderHead: ElementRef) {
        //console.log("initGlobalLoader", loader, renderer);
        this.globalLoader = loader;
        this.globalLoaderHead = loaderHead;
        this.globalRenderer = renderer;
    }

    public showGlobalLoader(text: string = null) {
        //console.log("Show loader", text, this.globalLoader);
        if (this.globalLoader && text) {
            this.globalLoader.nativeElement.innerHTML = text;
            this.globalRenderer.setElementStyle(this.globalLoader.nativeElement, 'visibility', 'visible');
        }
        if (this.globalLoaderHead) {
            this.globalRenderer.setElementStyle(this.globalLoaderHead.nativeElement, 'visibility', 'visible');
        }

        //this.renderer.setElementClass(this.globalLoader, "global-loader",false);
    }

    public hideGlobalLoader() {
        if (this.globalLoader)
            this.globalRenderer.setElementStyle(this.globalLoader.nativeElement, 'visibility', 'hidden');

        if (this.globalLoaderHead)
            this.globalRenderer.setElementStyle(this.globalLoaderHead.nativeElement, 'visibility', 'hidden');
    }
    */

    public notificateInit(vcr: ViewContainerRef) {
        // console.log("commonService.notificateInit", vcr, this.toastr);
        // this.toastr.setRootViewContainerRef(vcr);
    }

    public notificateSuccess(text: string, title: string = null, timeOut: number = 3) {
        const options = this._notificationConfig(false, timeOut);

        this.toastr.success(text, title, options);
    }

    public notificateWarning(text: string, title: string = null, timeOut: number = 3) {
        const options = this._notificationConfig(false, timeOut);
        this.toastr.warning(text, title, options);
    }


    public registerErrorNotificationInterceptor(i: Function) {
        this.errorNotificationInterceptor = i;
    }

    public registerDefaultErrorNotificationInterceptor() {
        this.registerErrorNotificationInterceptor((msg, title) => {
            setTimeout(() => {
                this.confirm({
                    key: 'notification',
                    icon: 'fa fa-exclamation-circle',
                    header: title || this.translateService.instant('CORE.COMMON.ERROR'),
                    message: msg,
                    rejectVisible: false,
                    acceptLabel: 'OK',
                    acceptButtonStyleClass: 'p-button-danger',
                    acceptIcon: 'pi pi-check',
                    accept: () => {}
                });
            }, 100);
        });
    }

    public notificateError(err: any, title: string = null, timeOut: number = 10) {
        console.error(err);
        if (!this.IsProduction)
            console.warn('commonService.notificateError', err);
        const errText = this.getErrorText(err, true);
        const options = this._notificationConfig(err.StatusCode === 500, timeOut);

        if (this.errorNotificationInterceptor != null)
            this.errorNotificationInterceptor(errText, title);
        else
            this.toastr.error(this.nl2br(errText), title, options);
    }

    public notificateErrorRes(err: string, errData: { [klass: string]: any } = null, title: string = null) {
        this.confirmRes({
            header: title || 'Fehler',
            message: err,
            messageData: errData,
            acceptLabel: 'OK',
            acceptButtonStyleClass: 'p-button-primary',
            rejectVisible: false
        });
    }

    public copyToClipboard(str: string) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    public getErrorText(err: any, asHtml: boolean = true) {
        let errText: string = null;
        if (typeof err === 'string')
            errText = err;
        else if (err.StatusCode === 0)
            errText = 'SERVER_NOT_REACHABLE';
        else if (err instanceof Error)
            errText = (<Error>err).message;
        else if (err instanceof AviApiErrorObject)
            errText = typeof err.ErrorMessage === 'string' ? err.ErrorMessage : err.ErrorMessage.Message;
        else if (err?.OriginalError?.error)
            errText = err.OriginalError.error?.message || err.OriginalError.error;
        else if (err.ErrorMessage)
            errText = err.ErrorMessage;
        else if (err.error && typeof err.error === 'string')
            errText = err.error;
        else if (err.error && err.error.Message && typeof err.error.Message === 'string')
            errText = err.error.Message;

        if (!(err instanceof AviApiErrorObject))
            errText = this.translateErrorMessages(errText);
        if (!errText)
            errText = 'Unbekannter Fehler';

        if (err.Details && typeof err.Details === 'string')
            errText += ` (${err.Details})`;

        // Am Schluss nochmal durch den lokalen Translator...
        errText = this.translateErrorMessages(errText);

        return asHtml ? this.nl2br(errText) : errText;
    }

    public getMomentFromTimeString(timestring: string) {
        return moment(timestring, 'HH:mm:ss');
    }

    public shortTimeSpanString(timestring: string | Date) {
        if (timestring instanceof Date)
            timestring = timestring.toTimeString().substr(0, 8);
        if (!this.isValidTime(timestring)) {
            // console.warn("Invalid Time String: ", timestring);
            return ``;
        }
        return this.getMomentFromTimeString(timestring).format('HH:mm');
    }

    public isValidTime(timestring: string) {
        return this.getMomentFromTimeString(timestring).isValid();
    }


    // public secondsToDuration(seconds: number, format: string = "HH:mm:ss"): string {
    //     var d = moment.duration(seconds * 1000);
    //     let span = moment.utc(d.asMilliseconds()).format(format);
    //     return `${span}`
    // }

    public secondsToDuration(seconds: number, includeSeconds: boolean = false) {
        const dur = moment.duration(seconds, 'seconds');
        const hours = Math.floor(dur.asHours());
        const mins = Math.floor(dur.asMinutes()) - hours * 60;
        const sec = Math.floor(dur.asSeconds()) - hours * 60 * 60 - mins * 60;

        let result = hours + ':' + ((mins > 9) ? mins : ('0' + mins));
        if (includeSeconds)
            result += ':' + ((sec > 9) ? sec : ('0' + sec));
        return result;
    }

    public getTimeSpan(timeFrom: string, timeTo: string, nullToString: boolean = true) {
        if (this.isValidTime(timeFrom) && this.isValidTime(timeTo)) {
            const diff = this.getMomentFromTimeString(timeTo).diff(this.getMomentFromTimeString(timeFrom), 'milliseconds');
            const d = moment.duration(diff);
            const span = moment.utc(d.asMilliseconds()).format('HH:mm:ss');
            return `${span}`;
        } else {
            return nullToString ? `${moment.utc(0).format('HH:mm:ss')}` : null;
        }
    }

    public getTimeSpanDate(timeFrom: Date, timeTo: Date, nullToString: boolean = true) {
        if (timeFrom && timeTo) {
            timeFrom.setMilliseconds(0);
            timeTo.setMilliseconds(0);
            const diff = moment(timeTo).diff(moment(timeFrom));
            const d = moment.duration(diff);
            const span = moment.utc(d.asSeconds() * 1000).format('HH:mm:ss');
            // console.log('getTimeSpanDate', timeFrom, timeTo, diff, d, span);

            return `${span}`;
        } else {
            return nullToString ? `${moment.utc(0).format('HH:mm:ss')}` : null;
        }
    }

    public addTimeSpan(datetime: string, span: string, returnFormat: string = 'L'): string {
        if (span.length === 5)
            span += ':00';

        const dur = moment.duration(span);
        const now = moment(datetime);
        if (!now.isValid())
            throw new Error(`Ungültige Zeit: ${datetime}`);
        if (!dur.isValid())
            throw new Error(`Ungültige Dauer: ${span}`);

        return now.add(dur).format(returnFormat);
    }

    public addTimeSpanDate(datetime: Date, span: string): Date {
        if (span.length === 5)
            span += ':00';

        const dur = moment.duration(span);
        const now = moment(datetime);
        if (!now.isValid())
            throw new Error(`Ungültige Zeit: ${datetime}`);
        if (!dur.isValid())
            throw new Error(`Ungültige Dauer: ${span}`);

        return now.add(dur).toDate();
    }

    public get GuidEmpty(): string {
        return '00000000-0000-0000-0000-000000000000';
    }

    public generateUUID(): string { // Public Domain/MIT
        let d = new Date().getTime(); // Timestamp
        let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;// Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16; // random number between 0 and 16
            if (d > 0) { // Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else { // Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    public translateErrorMessages(msg: string) {
        const msglc = `${msg}`.trim().toLowerCase();
        switch (msglc) {
            case 'invalid_username':
                return 'Benutzername ungültig';

            case 'invalid_password':
                return 'Aktuelles Passwort ungültig';

            case 'invalid_username_or_password':
            case 'Invalid username or password.':
                return 'Benutername oder Passwort ungültig';

            case 'invalid_token':
                return 'Token ungültig';

            case 'token_expired':
                return 'Der Token ist nicht mehr gültig';

            case 'pw_reset_disabled':
                return 'Diese Funktion ist auf dem Server deaktiviert';

            case 'password_not_strong_enough':
                return 'Das Passwort erfüllt die Komplexitätsvoraussetzungen nicht.'; // Die genauen Anforderungen sollen in der Applikation angezeigt werden, da sie via AppSettings übersteuerbar sind. Default: "Passwort muss mind. eine Zahl, mind. ein Sonderzeichen und mind. 6 alphanummerische Zeichen besitzen!"

            case 'passwords_doesnt_match':
                return 'Die Passwörter stimmen nicht überein';

            case 'password_not_set':
                return 'Kein Passwort gesetzt';

            case 'pwchange_current_password_invalid':
                return 'Aktuelles Passwort ungültig';

            case 'pwchange_new_password_invalid':
                return 'Neues Passwort ungültig';

            case 'missing_emailadress_on_user':
                return 'Email-Adresse auf diesem Benutzer nicht definiert.';

            case 'invalid totp':
            case 'invalid_totp':
                return 'Time-based One-time (TOTP) ungültig';

            case 'invalid_totp_already_used':
                return 'Time-based One-time (TOTP) bereits verwendet';

            case 'unauthorized':
            case 'access_denied':
                return 'Zugriff verweigert';

            case 'not_found':
                return 'Seite nicht gefunden';

            case 'server_not_reachable':
                return 'Server nicht erreichbar';

            case 'not_reachable':
                return 'API nicht erreichbar';

            case 'state.invalid':
                return 'Unvollständige oder ungültige Daten';

            case 'sso_not_enabled':
                return 'Windows-Authentifizierung ist auf dem Server nicht aktiviert';

            case 'user_temporary_locked':
                return 'Das Konto ist vorübergehend gesperrt';

            case 'file-extension-not-allowed':
                return 'Die verwendete Datei-Endung ist nicht erlaubt';
            case 'suspect-file':
                return 'Die Datei wurde als auffällige Datei eingestuft und wird daher abgelehnt';

            case 'unknown':
                return 'Unbekannter Fehler';
        }

        return msg;
    }

    private _notificationConfig(showCloseButton: boolean = true, timeOut: number = 3): any {
        const options = {
            showCloseButton: showCloseButton,
            dismiss: showCloseButton ? 'click' : 'auto',
            enableHtml: true,
            timeOut: timeOut * 1000
        };

        //   options.showMethod = 'slideDown';
        //   options.hideMethod = 'slideUp';
        //   options.closeMethod = 'slideUp';

        return options;
    }

    public initLightbox(name: string = null) {
        /* lightbox.option({
           'resizeDuration': 200,
           'wrapAround': true,
           'albumLabel' : "Bild %1 von %2",
           'alwaysShowNavOnTouchDevices' : true,
           });

          // lightbox.enable();
           // lightbox.build();

       //http://lokeshdhakar.com/projects/lightbox2/#getting-started
       console.log("enable and build lightbox", name, lightbox);*/
    }

    public getRouteParam(route: ActivatedRoute, paramName: string): string {
        let paramValue = null;
        route.params.forEach((params: Params) => {
            paramValue = params[paramName];
        });
        return paramValue;
    }
    public getRouteParams(route: ActivatedRoute): any[] {
        // const paramValue = null;
        const retparams = [];


        route.params.forEach((params: Params) => {
            console.log('retparams', params);
            // retparams = params;
        });
        return retparams;
    }

    public confirmDelete(message: string, acceptDelegate: Function, rejectDelegate: Function = null, header: string = 'Datensatz löschen', icon: string = ''/* 'fa pi pi-trash' */) {
        // this.confirm(message, acceptDelegate, rejectDelegate, header, icon);
        this.confirm({ message, accept: acceptDelegate, acceptButtonStyleClass: 'p-button-primary', reject: rejectDelegate, acceptLabel: 'Löschen', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', header, icon });
    }

    public async confirmDeleteWithAwait(message: string, header: string = 'Datensatz löschen',  icon: string = ''): Promise<boolean> {
        return new Promise((resolve) => {
            this.confirm({message, header, icon, 
                acceptButtonStyleClass: 'p-button-primary',
                acceptLabel: 'Löschen', rejectLabel: 'Abbrechen', 
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
                accept: () => {
                resolve(true);
              },
              reject: () => {
                resolve(false);
              }})
        });
    }

    public async showMessage(title: string, message: string, messageType: MessageType = MessageType.Info) {
        let icon = "pi pi-info-circle";
        if (messageType == MessageType.Warning)
            icon = "pi pi-exclamation-triangle";
        else if (messageType == MessageType.Exclamation)
            icon = "pi pi-exclamation-circle";

        if (title)
            title = this.translateInstant(title);
        if (message)
            message = this.translateInstant(message);

        return new Promise((resolve) => {
            this.confirm({message, header: title, icon, 
                acceptButtonStyleClass: 'p-button-primary',
                acceptLabel: 'Ok', 
                rejectVisible: false,
                accept: () => {
                resolve(true);
              },
              reject: () => {
                resolve(false);
              }})
        });
    }

    public confirm(confirmation: Confirmation) {
        // const confirmationConfig = {... confirm, acceptButtonStyleClass: 'ui-button'}
        // confirmation.acceptButtonStyleClass += ' ui-button';
        if (!confirmation.key)
            confirmation.key = 'main';
        if (!confirmation.rejectButtonStyleClass)
            confirmation.rejectButtonStyleClass = 'p-button-secondary p-button-outlined';
        if (!confirmation.acceptButtonStyleClass)
            confirmation.acceptButtonStyleClass = 'p-button-primary';

        if (confirmation.header)
            confirmation.header = this.translateInstant(confirmation.header);

        if (confirmation.message)
            confirmation.message = this.translateInstant(confirmation.message);

        this.confirmationService.confirm(confirmation);
    }

    public confirmRes(confirmation: ConfirmationTB) {
        if (!confirmation.key)
            confirmation.key = 'main';
        if (!confirmation.rejectButtonStyleClass)
            confirmation.rejectButtonStyleClass = 'p-button-secondary p-button-outlined';
        if (!confirmation.acceptButtonStyleClass)
            confirmation.acceptButtonStyleClass = 'p-button-primary';

        if (confirmation.header)
            confirmation.header = this.translateInstant(confirmation.header);

        const ref = this.dialogService.open(AviConfirmFormComponent, {
            header: confirmation.header,
            closable: true,
            width: 'auto',
            baseZIndex: 10000,
            data: {
                icon: confirmation.icon,
                message: confirmation.message,
                messageData: confirmation.messageData,
                acceptIcon: confirmation.acceptIcon,
                acceptLabel: confirmation.acceptLabel,
                acceptVisible: confirmation.acceptVisible ?? true,
                rejectIcon: confirmation.rejectIcon,
                rejectLabel: confirmation.rejectLabel,
                rejectVisible: confirmation.rejectVisible ?? true,
                acceptButtonStyleClass: confirmation.acceptButtonStyleClass,
                rejectButtonStyleClass: confirmation.rejectButtonStyleClass
            }
        });

        ref
            .onClose
            .pipe(tap((data) => data), first())
            .toPromise()
            .then(res => {
                ref.destroy();

                if (res) {
                    try {
                        if (confirmation.accept)
                            confirmation.accept();
                    } catch (err) {
                        this.notificateError(err);
                    }
                } else {
                    if (confirmation.reject)
                        confirmation.reject();
                }
            });
    }

    public generateEsrReferenz(number: string | number, length: number /* = 27 | 16 */, formatSpaces: boolean = true, cutLeadingZeroes: boolean = false): string {
        let numberInternal: string = null;
        if (typeof number === 'string') {
            numberInternal = number.replace(/\D/g, '');
        } else {
            numberInternal = number.toString();
        }
        let final = `${numberInternal}${this.getPuefzifferEsrReferenz(numberInternal)}`;

        while (final.length < length) final = '0' + final;

        if (formatSpaces) {
            if (length === 27) {
                final = final.substr(0, 2) + ' '
                    + final.substr(2, 5) + ' '
                    + final.substr(7, 5) + ' '
                    + final.substr(12, 5) + ' '
                    + final.substr(17, 5) + ' '
                    + final.substr(22, 5);
            } else {
                final = final.substr(0, 1) + ' '
                    + final.substr(1, 5) + ' '
                    + final.substr(6, 5) + ' '
                    + final.substr(11, 5);
            }
        }

        if (cutLeadingZeroes) {
            final = final.replace(/^0+/, '');
        }
        return final;
    }

    public cutStringIfLonger(value: string, length: number) {
        return value && value.length > length ? value.substr(0, length) : value;
    }

    public getPuefzifferEsrReferenz(number: string | number) {
        let numberInternal: string = null;
        if (typeof number === 'string') {
            numberInternal = number.replace(/\D/g, '');
        } else {
            numberInternal = number.toString();
        }
        const table = [0, 9, 4, 6, 8, 2, 7, 1, 3, 5];
        let uebertrag = 0;

        for (let i = 0; i < numberInternal.length; i++) {
            const c = parseInt(numberInternal.substring(i, i + 1), 10);
            const index = (uebertrag + c) % 10;

            uebertrag = table[index];
        }
        const pz = (10 - uebertrag) % 10;
        console.log('Prüfziffer von ' + number, pz);
        return pz;
    }

    public async sleep(ms) {
        // if (!environment.production)
        //     console.warn(`commonService.sleep(${ms})...`);
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    public GetStartMs() {
        return new Date().getTime();
    }
    public LogMs(millisecondsOnStart: number, prefix?: string, ...obj) {
        // const ms = new Date().getTime() - millisecondsOnStart;
        // if (!environment.production)
        //     console.log(`${prefix} geladen in ${ms} ms`.trim(), obj);
    }

    public dynamicSort(property) {
        let sortOrder = 1;
        if (property[0] === '-') {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            if (!a)
                return 1;
            if (!b)
                return 1;
            const valueA = ObjectUtils.getByPath(a, property);
            const valueB = ObjectUtils.getByPath(b, property);
            const result = (valueA < valueB) ? -1 : (valueA > valueB) ? 1 : 0;
            return result * sortOrder;
        };
    }

    public disableTabStopByClassName(className: string) {
        Array.from(document.getElementsByClassName(className)).forEach(e => {
            if (e instanceof HTMLElement)
                (<HTMLElement>e).tabIndex = -1;
        });
    }

    public addUnloadPreventer() {
        window.addEventListener('beforeunload', this.beforeUnloadFunc);
    }

    public removeUnloadPreventer() {
        window.removeEventListener('beforeunload', this.beforeUnloadFunc);
    }

    beforeUnloadFunc(e) {
        e.preventDefault();
        e.returnValue = '';
    }
    
    public isEmptyOrWhitespace(str: string) {
        return str == null || str.match(/^ *$/) != null;
    }
    
    public isHighLowOrEmptyDate(value: string | Date): boolean {
        if (!value) return true;
        const dateValue = moment(value);

        return (dateValue.isSame(AviTypeUtil.HighDate) || dateValue.isSame(AviTypeUtil.SqlLowDate));
    }

    public translateInstant(key: string | Array<string>, interpolateParams?: Object) {
        if (!key)
            return null;
        return this.translateService.instant(key, interpolateParams);
    }

    public formatString(str: string, ...val: string[]) {
        for (let index = 0; index < val.length; index++) {
            str = str.replace(`{${index}}`, val[index]);
        }
        return str;
    }

    public isTransparentColor(col: RgbaColor): boolean {
        return col.R === 255 && col.G === 255 && col.B === 255 && col.A === 0;
    }

    public colorToString(col: RgbaColor): string {
        return `${col.R}, ${col.G}, ${col.B}`;
    }

    public str2CSSName(str: string): string {
        return str?.toLowerCase().replace(/[\s\t\/]/g, '');
    }

    public stripHtmlTags(str: string): string {
        return str?.replace(/<([^>]+)>/g, '');
    }

    public setMenuItemActive(event) {
        let node;
        if (event.target.classList.contains('p-submenu-header') == true)
            node = 'submenu';
        else if (event.target.tagName === 'SPAN')
            node = event.target.parentNode.parentNode;
        else
            node = event.target.parentNode;

        if (node != 'submenu') {
            let menuitem = document.getElementsByClassName('p-menuitem');
            for (let i = 0; i < menuitem.length; i++)
                menuitem[i].classList.remove('active');

            node.classList.add('active');
        }
    }

    public truncateString(text, max, fillEllipses: boolean = true) {
        if (fillEllipses)
            return text.substr(0, max - 1) + (text.length > max ? '...' : '');
        else
            return text.substr(0, max - 1);
    }


    public compareValues(value1: number, operator: string, value2: number): boolean {

        switch (operator) {
            case AviListDetailConst.CAF_VERGLEICHSOPERATOR_EQ:
                return value1 === value2;
            case AviListDetailConst.CAF_VERGLEICHSOPERATOR_NEQ:
                return value1 !== value2;
            case AviListDetailConst.CAF_VERGLEICHSOPERATOR_LT:
                return value1 < value2;
            case AviListDetailConst.CAF_VERGLEICHSOPERATOR_LTE:
                return value1 <= value2;
            case AviListDetailConst.CAF_VERGLEICHSOPERATOR_GT:
                return value1 > value2;
            case AviListDetailConst.CAF_VERGLEICHSOPERATOR_GTE:
                return value1 >= value2;
            case AviListDetailConst.CAF_QUERYOPERATOR_IS_NULL:
                return value1 == null;
            case AviListDetailConst.CAF_QUERYOPERATOR_IS_NOTNULL:
                return value1 != null;
        }

        return false;
    }

    public async openFormDialog<T>(
        cls: ClassType<T>,
        header: string,
        modelId: any,
        contextId: string,
        params: { [k: string]: any; },
        editMode: boolean,
        styleClass:string = null,
        width: string = '80%',
        showCloseButton: boolean = true) {

        const ref = this.dialogService.open(cls, {
            header: this.translateInstant(header),
            closable: showCloseButton,
            closeOnEscape: !editMode,
            width: width,
            baseZIndex: 10000,
            styleClass: styleClass ? styleClass : (this.InputStyle === 'filled' ? 'p-input-filled' : ''),
            data: {
                Id: modelId,
                ContextId: contextId,
                Params: params,
                readonly: !editMode,
                formType: cls
            }
        });

        return await this.waitForDialogClose(ref);
    }

    public async waitForDialogClose(ref: DynamicDialogRef) {
        const res = await ref
            .onClose
            .pipe(tap(w => w), first())
            .toPromise();

        ref.destroy();

        return res;
    }

    public groupBy<S, T>(list: T[], keyGetter: (T) => S): Map<S, T[]> {
        const map = new Map<S, T[]>();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }

    public subscribeSessionChanged(func: (sessionId) => void): Subscription {
        return this.sessionController.sessionChanged$.subscribe(sessionId => func(sessionId));
    }

    public async openMetaBrowser(modelMetaId: string, selectionMode: 'any' | 'reference' = 'any') {
        const ref = this.dialogService.open(AviMetaBrowserComponent, {
            header: 'Meta-Browser',
            closable: true,
            width: '80%',
            baseZIndex: 10000,
            styleClass: this.InputStyle === 'filled' ? 'p-input-filled' : '',
            data: {
                Id: modelMetaId,
                SelectionMode: selectionMode
            }
        });

        return await this.waitForDialogClose(ref);
    }

    public CorrectInputTextareaAutoresize() {
        // Workaround for https://github.com/primefaces/primeng/issues/9890
        InputTextarea.prototype.ngAfterViewInit = function () {
            if (this.autoResize) {
                window.setTimeout(() => this.resize(), 150);
            }
        };
    }

    public CorrectDynamicDialogFocus() {        
        DynamicDialogComponent.prototype.onKeydown = function(event: KeyboardEvent) {
            // tab
            if (event.which === 9) {
                event.preventDefault();
    
                let focusableElements = DomHandler.getFocusableElements(this.container as HTMLDivElement);
                if (focusableElements && focusableElements.length > 0) {
                    if (!focusableElements[0].ownerDocument.activeElement) {
                        focusableElements[0].focus();
                        focusableElements[0].select();
                    } else {
                        let focusedIndex = focusableElements.indexOf(focusableElements[0].ownerDocument.activeElement);
    
                        if (event.shiftKey) {
                            if (focusedIndex == -1 || focusedIndex === 0) {
                                focusableElements[focusableElements.length - 1].focus();
                                focusableElements[focusableElements.length - 1].select();
                            }
                            else {
                                focusableElements[focusedIndex - 1].focus();
                                focusableElements[focusedIndex - 1].select();
                            }
                        } else {
                            if (focusedIndex == -1 || focusedIndex === focusableElements.length - 1) {
                                focusableElements[0].focus();
                                focusableElements[0].select();
                            }
                            else {
                                focusableElements[focusedIndex + 1].focus();
                                focusableElements[focusedIndex + 1].select();
                            }
                        }
                    }
                }
            }
        }
    }
}
