import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService, ParentIdResolver } from '@avi-x/avi-core';
import { AviActivityIndexComponent } from './components/activity-index/activity-index.component';
import { AviActivitySearcherComponent } from './components/activity-searcher/activity-searcher.component';
import { AviActivityViewComponent } from './components/activity-view/activity-view.component';


const routes: Routes = [
    { path: 'activity', component: AviActivitySearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] },

    {
        path: 'activity/index/:id',
        canActivate: [ AviCanActivateWhenLoggedInService ],
        component: AviActivityIndexComponent,
        children: [
            { path: '', redirectTo: 'view', pathMatch: 'full' },
            {
                path: 'view',
                component: AviActivityViewComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true  }
            }
        ]
    }
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviActivityRoutingModule {
}
