import { VwbPInternUnstimmigkeitService } from './../../services/unstimmigkeit.service';
import { ViewChild, ChangeDetectorRef, Component } from '@angular/core';
import { VwbPInternRechtstraegerVersionenSearcherComponent } from '../../../rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-versionen-searcher.component';
import { VwbPCommonService } from '@avi-x/vwbp-public';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';

import { Calendar } from 'primeng/calendar';

@Component({
    selector: 'vwbp-intern-unstimmigkeit-base',
    template: ''
})
export class VwbPInternUnstimmigkeitBaseComponent {
    @ViewChild('searcher', { static: true })
    protected searcher: VwbPInternRechtstraegerVersionenSearcherComponent;

    constructor(public commonService: AviCommonService, public unstimmigkeitService: VwbPInternUnstimmigkeitService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
    }

    public onInputDate(event, calendar: Calendar): void {
        this.inputMaskService.handleDateInput(event, calendar);
    }

    getSelectedIndex() {
        return this.GetAktivValue() ? 0 : 1;
    }

    setPausiert(ev) {
        const aktiv = ev.index === 0;
        this.searcher.searcher.ResetSelection();
        this.SetAktivValue(aktiv);
    }

    public GetAktivValue() {
        return this.searcher.searcher.getSearcherParameter('aktiv')?.Value ?? true;
    }

    public SetAktivValue(text: any) {
        this.searcher.searcher.setSearcherParameter('aktiv', text);
        this.searcher.searcher.updateQueryParams(true, true);
    }

    currentUserIsAjuUserSchreiben() {
        return this.vwbpCommonService.currentUserIsAjuUserSchreiben();
    }

    public GetSelectionCount() {
        return this.searcher.GetSelectedRechtstraeger().length;
    }

    public GetSelectedRechtstraeger(): any[] {
        return this.searcher.GetSelectedRechtstraeger();
    }

    Refresh() {
        this.searcher.forceRefresh();
        this.cdr.markForCheck();
    }
}
