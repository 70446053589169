import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class CustomPropDefSearchResultDto extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
        
    @Expose()
    public get Sequenz(): number { return this.getProperty('Sequenz'); }
    public set Sequenz(value: number) { this.setProperty('Sequenz', value); }
    
    @Expose()
    public get PropertyName(): string { return this.getProperty('PropertyName'); }
    public set PropertyName(value: string) { this.setProperty('PropertyName', value); }
    
    @Expose()
    public get Columns(): number { return this.getProperty('Columns'); }
    public set Columns(value: number) { this.setProperty('Columns', value); }

    @Expose()
    public get RadioGroupIndex(): number { return this.getProperty('RadioGroupIndex'); }
    public set RadioGroupIndex(value: number) { this.setProperty('RadioGroupIndex', value); }
    
    @Expose()
    public get IsRadioGroup(): boolean { return this.getProperty('IsRadioGroup'); }
    public set IsRadioGroup(value: boolean) { this.setProperty('IsRadioGroup', value); }
    
    @Expose()
    public get DefaultAttributPath(): string { return this.getProperty('DefaultAttributPath'); }
    public set DefaultAttributPath(value: string) { this.setProperty('DefaultAttributPath', value); }
   
    @Expose()
    @Type(() => ListType)
    public get PropertyType(): ListType { return this.getProperty('PropertyType'); }
    public set PropertyType(value: ListType) { this.setProperty('PropertyType', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Mandatory(): ListType { return this.getProperty('Mandatory'); }
    public set Mandatory(value: ListType) { this.setProperty('Mandatory', value); }
    
    @Expose()
    @Type(() => ListType)
    public get IsReadOnlyInGui(): ListType { return this.getProperty('IsReadOnlyInGui'); }
    public set IsReadOnlyInGui(value: ListType) { this.setProperty('IsReadOnlyInGui', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Visibility(): ListType { return this.getProperty('Visibility'); }
    public set Visibility(value: ListType) { this.setProperty('Visibility', value); }
}
