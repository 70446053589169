import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviHistoryService, AviPartnerListDetailConst, AviListDetailConst } from '@avi-x/avi-core';
import { JurpersonHist } from '@avi-x/avi-dto/partner/jurperson-hist.model';
import { ZustandInfoDto } from '@avi-x/avi-dto/shared/zustandinfo.model';
import { AenderungsGrundAttrLink } from '@avi-x/avi-dto/workflow/aenderungsgrundattrlink.model';

import * as moment from 'moment-timezone';

const nameof = (nameFunction: ((obj: JurpersonHist) => any)) => typed_nameof<JurpersonHist>(nameFunction);

@Component({
    selector: 'avi-crm-jurperson-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [label-alignment]="ReadOnly ? 'left' : 'top'"
        [label-width]="'160px'"
        [(Model)]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [contents-padding]="ContentsPadding"
        [action-buttons]="actionButtons">

        <div formFieldTemplate1>
            <avi-core-history-control [history-states]="HistoryStates" (stichtagChange)="OnStichtagChange($event)">
            </avi-core-history-control>
        </div>

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJurpersonFormComponent implements OnInit, OnDestroy {

    private _className = 'Jurperson';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('hidden-fields')
    public HiddenFields: string[] = null;

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = '/crm/jurperson';

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = '/crm/jurperson';

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: JurpersonHist = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string {
        return this._ModelId;
    }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    private stichtag: Date = new Date();
    public HistoryStates: ZustandInfoDto[];

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private historyService: AviHistoryService,
        private apiService: AviApiService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {

        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));

        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    async onAttrChange(data: AviChangedAttr) {
    }

    public async initNewModel(data?: any) {

        /*        this.Model = await this.apiService.getModel(JustpersonHist, 'jurperson/Create');
                this.isEditMode = false;

                if (data) {
                    for (const key in data) {
                        ObjectUtils.setByPath(this.Model, key, data[key]);
                        this.onAttrChange({ field: key, value: data[key] });
                    }
                }*/
    }

    ngOnInit() {
/*        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.ModelId = params['id'];
            }
        });*/

        this.initFields();
    }


    deleteModel(model: any) {
        /*        this.apiService.delete(`/jurperson/${model.id}`).then(r => {

                    if (this.RedirectAfterDelete)
                        this.router.navigate([this.RedirectAfterDelete]);
                });*/
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: JurpersonHist) {
        /*

                this.Form.clearFormMessages();

                let delegate: Promise<any> = null;
                if (this.isEditMode) {
                    delegate = this.apiService.put(`/jurperson/${model.Id}`, model);
                } else {
                    delegate = this.apiService.post('/jurperson', model);
                }
                delegate.then(r => {
                    this.commonService.notificateSuccess('Gespeichert');
                    this.onSavedSuccessfulDelegate.emit(model);

                    if (this.RedirectAfterSave)
                        this.router.navigate([this.RedirectAfterSave]);
                }).catch(err => {
                    if (err instanceof AviApiErrorObject) {
                        this.Form.addFormMessage(err.ErrorMessage, err.Type);
                    } else {
                        this.Form.addFormMessage(JSON.stringify(err));
                    }
                });*/
    }

    async loadModel(id: string) {

        this.commonService.showGlobalLoader();

        this.historyService.clearHistoryHighlighting(this.Fields);
        this.stichtag = new Date();
        const strStichtag = moment(this.stichtag).format('YYYY-MM-DD');
        this.Model = await this.apiService.getModel(JurpersonHist, `jurperson/hist/${strStichtag}/${id}`);

        await this.updateDropdownSources();

        this.commonService.hideGlobalLoader();
        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        this.HistoryStates = await this.apiService.getModelList(ZustandInfoDto, `jurperson/hist/states/${id}`);

        // änderungsgrunddef
        let changeDefId = this.Model.ChangeDefRechtsform_ID;
        if (this.Model.VerwendungChangeDef && this.Model.VerwendungChangeDef.Id === AviPartnerListDetailConst.CAF_VERWENDUNG_RAHMENPARTNER_RAHMENPARTNER)
            changeDefId = this.Model.ChangeDefRahmenPartner_ID;

        const attribs = changeDefId ? await this.apiService.getModelList(AenderungsGrundAttrLink, `aenderungsgrunddef/attribs/${changeDefId}`) : null;
        if (attribs)
            attribs.filter(w => w.Sichtbar.Id === AviListDetailConst.NEIN).forEach(w => this.formFieldService.setFieldVisible(this.Fields, w.Attribute, false));

        if ((this.Model.Gruendungsdatum != null && this.Model.Gruendungsdatum > new Date(2016, 1, 1)) || this.Model.Ausgestaltung.Id !== AviPartnerListDetailConst.CAF_JURPERSON_AUSGESTALTUNG_STIFTUNGSAEHNLICH)
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SPV12), false);

        return this.Model;
    }

    async updateDropdownSources() {
        if (this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'Gültig ab', false).setNullDateLow().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'Gültig bis', false).setNullDateHigh().setMDSizeHalf());

        if (this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateTemplate(1, 'Zustand ab/bis').setMDSizeFull());
        else {
            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.ZustandAb), 'Zustand ab', false).setNullDateLow().setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.ZustandBis), 'Zustand bis', false).setNullDateHigh().setMDSizeHalf());
        }

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.PartnerNr), 'PartnerNr', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Sprache_ID), 'Sprache', `model/representations/Sprache${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'Sprache wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Name), 'Name', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Name2), 'Name2', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Name3), 'Name3', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Name4), 'Name4', false).setMDSizeHalf());

        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Suchbegriff), 'Suchbegriff', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Rechtsform_ID), 'Rechtsform', `model/representations/CAFRechtsform${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'Rechtsform wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Ausgestaltung), 'Ausgestaltung', 'caf_jurperson_ausgestaltung', true, false, this.ReadOnly).setMDSizeHalf());

        // TODO: Status, HrNr, LEI
        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.HRNr), 'HRNr', false).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.HRDatum), 'HRDatum', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Gruendungsdatum), 'Gründungsdatum', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Loeschdatum), 'Löschdatum', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Kapital), 'Kapital', 2, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.KapitalWaehrung_ID), 'Kapitalwährung', `model/representations/CAFWaehrung${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'KapitalWaehrung wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));

        // TODO: SteuerNr, MwStNr
        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.SteuerNr), 'SteuerNr', false).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.MwStNr), 'MwStNr', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.VerwaltungOrt), 'Verwaltung-Ort', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.VerwaltungLand_ID), 'Verwaltung-Land', `model/representations/CAFLand${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'VerwaltungLand wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));

        // TODO: RegisterOrt

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Registerart), 'Registerart', 'caf_jurperson_registerart', true, false, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.RegisterDatum), 'Registerdatum', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Ermessen), 'Ermessen', 'caf_ermessen', true, false, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.SPV12), 'SPV12', 'caf_spv12', true, false, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Diskretionaer), 'Diskretionär', 'caf_diskretionaer', true, false, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.BeguenstigungsRegel), 'Begünstigungsregel', 'caf_beguenstigungsregel', true, false, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Boersenkotiert), 'Boersenkotiert', 'janein', true, false, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Klassifizierung), 'Klassifizierung', 'caf_jurperson_klassifizierung', true, false, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonSteuerStatus_ID), 'Steuerstatus', `model/representations/CAFSteuerStatusDef${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'vonSteuerStatus wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.KlassifizierungSteG), 'Klassifizierung (SteG)', 'caf_jurperson_klassifizierung', true, false, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonSteuerStatusSteG_ID), 'Steuerstatus (SteG)', `model/representations/CAFSteuerStatusDef${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'vonSteuerStatusSteG wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.GoverningLaw_ID), 'Governing law', `model/representations/CAFLand${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'GoverningLaw wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AuthShareCapital), 'Authorised types of shares', 'caf_authsharecapital', true, false, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.IssuedShare), 'issued types of shares', 'caf_issuedshare', true, false, this.ReadOnly).setMDSizeHalf());

        // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.PVSPersBesteuerung), 'PVSPersBesteuerung', null, 'caf_pvs_besteuerung', true, true, false, this.ReadOnly).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.PflichtCode), 'PflichtCode', null, 'caf_mandat_pflichtcode', true, true, false, this.ReadOnly).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Bemerkung), 'Bemerkung', false).setMDSizeHalf());

        if (this.HiddenFields && this.HiddenFields.length > 0)
            this.Fields = this.Fields.filter(w => !this.HiddenFields.includes(w.Name));
    }

    async OnStichtagChange(stichtag: Date) {
        this.stichtag = stichtag;
        const strStichtag = moment(this.stichtag).format('YYYY-MM-DD');

        const newModel = await this.apiService.getModel(JurpersonHist, `jurperson/hist/${strStichtag}/${this.Model.Id}`);
        this.historyService.handleHistoryHighlighting(this.Fields, this.Model, newModel);
        this.Model = newModel;

        this.cdr.markForCheck();
    }
}
