import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class AppUserSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
	public AppUserTyp: string;

    @Expose()
    @Type(() => Date)
	public GueltigAb: Date;

    @Expose()
    @Type(() => Date)
	public GueltigBis: Date;

    @Expose()
	public System: number;

    @Expose()
	public Username: string;

    @Expose()
	public Passwort: string;

    @Expose()
	public Kurzz: string;

    @Expose()
	public Name: string;

    @Expose()
	public Vorname: string;

    @Expose()
	public Email: string;

    @Expose()
	public ExterneReferenz: string;

	@Expose()
	@Type(() => ListType)
	public AdminUser: ListType;

	@Expose()
	@Type(() => ListType)
	public SupportUser: ListType;

	@Expose()
	@Type(() => ListType)
	public StrongPassword: ListType;

    @Expose()
	public PasswdExpirationMonths: number;

    @Expose()
    @Type(() => Date)
	public NextPwdExpiration: Date;

	@Expose()
	@Type(() => ListType)
	public Authentizierung: ListType;

    @Expose()
	public Domain: string;

    @Expose()
	public AcitivityReminderDisabled: boolean;

	@Expose()
	@Type(() => ListType)
	public DsgvoAktiv: ListType;

	@Expose()
	@Type(() => ListType)
	public DsgvoBeauftragter: ListType;
}
