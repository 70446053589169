import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviAuthService, AviBaseFormComponent, ObjectUtils, AviFormFieldService, typed_nameof, AviSearcherColumn, AviBaseSearcherComponent, AviTypeUtil } from '@avi-x/avi-core';
import { VwbPFormularDaten, VwbPListDetailConst, VwbPSchwaerzenAntragDaten } from '@avi-x/vwbp-public';

import { cloneDeep } from 'lodash-es';

const nameof = (nameFunction: ((obj: VwbPSchwaerzenAntragDaten) => any)) => typed_nameof<VwbPSchwaerzenAntragDaten>(nameFunction);

export enum AntragEditMode {
    // eslint-disable-next-line no-unused-vars
    CREATE, EDIT, SHOW, CHECK
}

@Component({
    selector: 'vwbp-intern-schwaerzen-antrag-form',
    templateUrl: './schwaerzen-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternSchwaerzenAntragFormComponent implements OnInit, OnDestroy {
    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('formularListe', { static: false })
    public formularListe: AviBaseSearcherComponent;

    public FormTitle: string;

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('action-buttons-readonly')
    actionButtonsReadonly: any[] = [];

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('formular-daten')
    public FormularDaten: VwbPFormularDaten[];

    public Loading: boolean = false;
    public Model: VwbPSchwaerzenAntragDaten = null;

    public Fields: AviFormField[] = [];

    public Mode: AntragEditMode;

    SelectionMode: string = 'multiple';
    MetaKeySelection: boolean = true;

    private _ModelId: string = null;
    // public get ModelId(): string {
    //     return this._ModelId;
    // }

    // @Input('model-id')
    // public set ModelId(value: string) {
    //     this.InitForm(value);
    // }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    @Input('api')
    public Api: string = 'v1/intern/vwbp';

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public resetForm() {
        this.Model = null;
        this._ModelId = null;
        this.Mode = AntragEditMode.SHOW;
    }

    public async InitForm(mode: AntragEditMode, value: string, detailId: string = null): Promise<boolean> {
        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.Mode = AntragEditMode.SHOW;
            }
            return;
        }

        this.Mode = mode;
        this._ModelId = value;

        if (this.Mode === AntragEditMode.SHOW)
            this.ReadOnly = true;
        else
            this.ReadOnly = false;

        this.MetaKeySelection = this.Mode === AntragEditMode.CREATE;
        this.SelectionMode = this.Mode === AntragEditMode.CREATE ? null: 'single';

        if (!this.ReadOnly)
            this.Form?.clearFormMessages();

        if (this.Mode !== AntragEditMode.CREATE && this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);
        } else if (this.Mode === AntragEditMode.CREATE) {
            await this.initNewModel(detailId);
        }

        if (this.Mode === AntragEditMode.CHECK)
            this.FormTitle = 'Version prüfen';
        else if (this.Mode === AntragEditMode.CREATE)
            this.FormTitle = 'Antrag erfassen';
        else if (this.Mode === AntragEditMode.EDIT)
            this.FormTitle = 'Antrag bearbeiten';
        else
            this.FormTitle = 'Antrag';

        this.setFromEditMode();

        this.cdr.detectChanges();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    showFormulare() {
        return this.Mode === AntragEditMode.CREATE || this.Mode === AntragEditMode.CHECK;
    }

    async onAttrChange(data: AviChangedAttr) {
    }

    private async initNewModel(rtId: string, data?: any) {
        this.Model = await this.apiService.getModel(VwbPSchwaerzenAntragDaten, `${this.Api}/schwaerzen/antrag/create`);

        this.Model.vonRechtstraeger_ID = rtId;
        this.Model.GueltigAb = null;
        this.Model.GueltigBis = AviTypeUtil.HighDate.toDate();

        if (data) {
            // eslint-disable-next-line guard-for-in
            for (const key in data) {
                ObjectUtils.setByPath(this.Model, key, data[key]);
                this.onAttrChange({ field: key, value: data[key], form: this.Form, model: this.Model });
            }
        }

        this.updateDropdownSources();
        //this.cdr.markForCheck();
    }

    ngOnInit() {
        this.initFields();
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    async saveModel(model: VwbPSchwaerzenAntragDaten) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;

        if (this.Mode === AntragEditMode.CREATE) {
            for (const form of this.formularListe.SelectedRows) {
                const obj: VwbPSchwaerzenAntragDaten = cloneDeep(model);

                if (form.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON) {
                    obj.Titel = form.Titel;
                    obj.Vorname = form.Vorname;
                    obj.Name = form.Name;
                    obj.Geburtsdatum = form.Geburtsdatum;
                    obj.Land = form.Land;
                    obj.Nationalitaet1 = form.Nationalitaet1;
                    obj.Nationalitaet2 = form.Nationalitaet2;
                    obj.Nationalitaet3 = form.Nationalitaet3;
                    obj.Nationalitaet4 = form.Nationalitaet4;
                } else {
                    obj.Gruendungsdatum = form.Gruendungsdatum;
                    obj.Rechtsform = form.Rechtsform;
                    obj.RegisterNummer = form.RegisterNummer;
                    obj.RegisterDatum = form.RegisterDatum;
                    obj.RegisterOrt = form.RegisterOrt;
                    obj.RegisterLand = form.RegisterLand;
                    obj.RechtstraegerName = form.RechtstraegerName;
                    obj.RechtstraegerSitz = form.RechtstraegerSitz;
                    obj.RTZustellAdresseCO = form.RTZustellAdresseCO;
                    obj.RTZustellAdresseStrasse = form.RTZustellAdresseStrasse;
                    obj.RTZustellAdressePLZ = form.RTZustellAdressePLZ;
                    obj.RTZustellAdresseOrt = form.RTZustellAdresseOrt;
                    obj.RTZustellAdresseLand = form.RTZustellAdresseLand;
                }

                obj.WBPartnerTyp = form.WBPartnerTyp;
                obj.FormularId = form.Id;

                try {
                    await this.apiService.post(`${this.Api}/schwaerzen/antrag`, obj);

                    this.commonService.notificateSuccess('Gespeichert');
                    this.onSavedSuccessfulDelegate.emit(model);
                } catch (err) {
                    if (err instanceof AviApiErrorObject) {
                        this.Form.addFormMessage(err.ErrorMessage, err.Type);
                    } else {
                        this.Form.addFormMessage(JSON.stringify(err));
                    }
                }
            }
        } else if (this.Mode === AntragEditMode.CHECK) {
            try {
                if (this.formularListe.SelectedRows && !Array.isArray(this.formularListe.SelectedRows))
                    this.Model.FormularId = this.formularListe.SelectedRows['Id'];
                else
                    this.Model.FormularId = null;

                await this.apiService.post(`v1/intern/vwbp/schwaerzen/antrag/geprueft`, this.Model);

                this.commonService.notificateSuccess('Gespeichert');
                this.onSavedSuccessfulDelegate.emit(model);
            } catch (err) {
                if (err instanceof AviApiErrorObject) {
                    this.Form.addFormMessage(err.ErrorMessage, err.Type);
                } else {
                    this.Form.addFormMessage(JSON.stringify(err));
                }
            }
        } else if (this.Mode === AntragEditMode.EDIT) {
            delegate = this.apiService.post(`${this.Api}/schwaerzen/antrag`, model);
            delegate.then(r => {
                this.commonService.notificateSuccess('Gespeichert');
                this.onSavedSuccessfulDelegate.emit(model);
            }).catch(err => {
                if (err instanceof AviApiErrorObject) {
                    this.Form.addFormMessage(err.ErrorMessage, err.Type);
                } else {
                    this.Form.addFormMessage(JSON.stringify(err));
                }
            });
        }
    }

    async loadModel(id: string) {
        try {
            this.commonService.showGlobalLoader();

            this.Model = await this.apiService.FindModel(VwbPSchwaerzenAntragDaten, `${this.Api}/schwaerzen/antrag/${id}`, null, null, null);

            this.updateDropdownSources();
        } finally {
            this.commonService.hideGlobalLoader();
        }

        this._ModelId = this.Model.Id;

        return this.Model;
    }

    async updateDropdownSources() {
        if (!this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    ValidateAntrag = (): boolean => {
        if (this.Mode === AntragEditMode.CREATE) {
            return this.formularListe && this.formularListe.SelectedRows?.length > 0;
        } else if (this.Mode === AntragEditMode.CHECK) {
//            console.log(this.formularListe?.SelectedRows);
            return this.formularListe && this.formularListe.SelectedRows instanceof VwbPFormularDaten;
        }

        return true;
    }

    public SearchFormularDelegate: any = async (searchvalue: string, searchConfig: any = null, searcher: AviBaseSearcherComponent = null) => this.doSearch(searcher);

    doSearch(searcher: AviBaseSearcherComponent): Promise<any> {
        return new Promise((resolve) => {
            return resolve(searcher.ApplyColumnSorting(this.FormularDaten.filter(w => w.WBPartnerTyp.Id !== VwbPListDetailConst.VWBP_WBPARTNERTYP_KDB && !w.WBT_SETTL_VS)));
        });
    }

    onFormularListInit(formularListe) {
        formularListe.beginInit();

        const colName = AviSearcherColumn.CreateCustom(
            'WB',
            'VWBP.INTERN.SCHWAERZEN.WB',
            (row, col) => {
                if (row['WBPartnerTyp'].Id !== VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON) {
                    const name = 'Firma: ' + this.commonService.nl2br(this.commonService.escapeHtml(row['RechtstraegerName']));
                    return name +
                        (row['RegisterNummer'] ? '<br />Registernummer: ' + this.commonService.formatDate(row['RegisterNummer'], 'L') : '') +
                        (row['Gruendungsdatum'] ? '<br />Gründungsdatum: ' + this.commonService.formatDate(row['Gruendungsdatum'], 'L') : '') +
                        (row['Rechtsform'] ? '<br />Rechtsform: ' + row['Rechtsform'] : '') +
                        (row['RechtstraegerSitz'] ? '<br />Sitz: ' + row['RechtstraegerSitz'] : '');
                } else {
                    const name = 'Name, Vorname: ' + this.commonService.nl2br(this.commonService.escapeHtml(row['Vorname'] + ', ' + row['Name']));
                    return name + '<br />Geburtsdatum: ' + this.commonService.formatDate(row['Geburtsdatum'], 'L') + '<br />Wohnsitz: ' + row['LandISOCode'] + '<br />Staatsangehörigkeit(en): ' + row['NationalitaetISOCodes'];
                }
            },
            false);
        colName.SortColumn = 'Name';
        formularListe.addColumn(colName);

        formularListe.addListTypeColumn('FormularTyp', 'VWBP.PUBLIC.FORM.FORMULARTYP', 'vwbp_formulartyp', true, true, null, 'v1/vwbp');

        const colRolle = AviSearcherColumn.CreateCustom(
            'Rollen2',
            'VWBP.PUBLIC.FORM.ROLLEN',
            (row, col) => {
                return row['Rollen'].replace(/(?:\r\n|\r|\n)/g, '<br>');
            },
            false
        );
        colRolle.SortColumn = 'Rollen';
        formularListe.addColumn(colRolle);

        formularListe.endInit();
    }

    isNew(): boolean {
        return this.Mode === AntragEditMode.CREATE;
    }

    setFromEditMode() {
/*        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Titel), !this.isNew());
        this.formFieldService.setFieldVisible(this.Fields, 'titel_filler2', !this.isNew());
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Vorname), !this.isNew());
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Name), !this.isNew());
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Geburtsdatum), !this.isNew());
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Land), !this.isNew());
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet1), !this.isNew());
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet2), !this.isNew() && this.Model.Nationalitaet2 != null);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet3), !this.isNew() && this.Model.Nationalitaet3 != null);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet4), !this.isNew() && this.Model.Nationalitaet4 != null);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Status), !this.isNew() && this.Mode !== AntragEditMode.EDIT);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.GueltigBis), !this.isNew() && this.Mode !== AntragEditMode.EDIT);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Entscheidung), this.Model.Status.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ANTRAG && this.Mode !== AntragEditMode.CHECK);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EntscheidAm), this.Model.Status.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ANTRAG && this.Mode !== AntragEditMode.CHECK);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RechtskraftAm), this.Model.RechtskraftAm != null && this.Mode !== AntragEditMode.CHECK);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Rechtsmittelfrist), this.Model.Rechtsmittelfrist != null && this.Mode !== AntragEditMode.CHECK);
        this.formFieldService.setFieldVisible(this.Fields, 'rk_filler', this.Mode !== AntragEditMode.CHECK && ((this.Model.RechtskraftAm != null && this.Model.Rechtsmittelfrist == null) || (this.Model.Rechtsmittelfrist != null && this.Model.RechtskraftAm == null)));

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Verlaufskommentar), this.Mode === AntragEditMode.CHECK);

        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Vorname), !this.isNew());
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Name), !this.isNew());
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Geburtsdatum), !this.isNew());
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Land), !this.isNew());
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Nationalitaet1), !this.isNew());
*/

        const vis = !this.isNew();
        const npVisible = vis && this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON;
        const jpAusland = vis && this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_JURPERSON_AUSLAENDISCH;
        const jpInland = vis && this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_JURPERSON_LI_HRN;

        // WB = Natürliche Person
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Vorname), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Titel), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, 'titel_filler', npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Name), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Geburtsdatum), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Land), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet1), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet2), npVisible && this.Model.Nationalitaet2 != null);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet3), npVisible && this.Model.Nationalitaet3 != null);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet4), npVisible && this.Model.Nationalitaet4 != null);

        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Vorname), npVisible);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Name), npVisible);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Geburtsdatum), npVisible);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Land), npVisible);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Nationalitaet1), npVisible);

        // WB = Inländischer RT
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.InlandRechtstraegerName), jpInland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.InlandRegisterNummer), jpInland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.InlandRechtsform), jpInland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.InlandRechtstraegerSitz), jpInland);

        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.InlandRechtstraegerName), jpInland);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.InlandRegisterNummer), jpInland);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.InlandRechtsform), jpInland);

        // WE = Ausländischer RT
        this.formFieldService.setFieldVisible(this.Fields, 'lblAuslandEintrag', jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, 'lblAuslandAdresse', jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRechtstraegerName), jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRechtsform), jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRechtstraegerSitz), jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandGruendungsdatum), jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRegisterLand), jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRegisterOrt), jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRegisterNummer), jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRegisterDatum), jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdresseLand), jpAusland);

        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.AuslandRechtstraegerName), jpAusland);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.AuslandRechtsform), jpAusland);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.AuslandGruendungsdatum), jpAusland);

        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.RTZustellAdresseStrasse), jpAusland);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.RTZustellAdressePLZ), jpAusland);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.RTZustellAdresseOrt), jpAusland);
        // this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.RTZustellAdresseLand), jpAusland);

        // Adresse
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdresseCO), jpInland || jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdresseStrasse), jpInland || jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdressePLZ), jpInland || jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdresseOrt), jpInland || jpAusland);

        //
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Status), !this.isNew() && this.Mode !== AntragEditMode.EDIT);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.GueltigBis), !this.isNew() && this.Mode !== AntragEditMode.EDIT);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Entscheidung), this.Model.Status.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ANTRAG && this.Mode !== AntragEditMode.CHECK);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EntscheidAm), this.Model.Status.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ANTRAG && this.Mode !== AntragEditMode.CHECK);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RechtskraftAm), this.Model.RechtskraftAm != null && this.Mode !== AntragEditMode.CHECK);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Rechtsmittelfrist), this.Model.Rechtsmittelfrist != null && this.Mode !== AntragEditMode.CHECK);
        this.formFieldService.setFieldVisible(this.Fields, 'rk_filler', this.Mode !== AntragEditMode.CHECK && ((this.Model.RechtskraftAm != null && this.Model.Rechtsmittelfrist == null) || (this.Model.Rechtsmittelfrist != null && this.Model.RechtskraftAm == null)));

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Verlaufskommentar), this.Mode === AntragEditMode.CHECK);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FristablaufGrund), this.Model.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELAUFEN && this.Mode !== AntragEditMode.CHECK);
        this.formFieldService.setFieldVisible(this.Fields, 'end_filler', this.Model.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELAUFEN && this.Mode !== AntragEditMode.CHECK);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.GueltigAb), this.Mode !== AntragEditMode.CREATE && this.Mode !== AntragEditMode.EDIT);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.GueltigBis), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Begruendung), this.Mode !== AntragEditMode.CREATE && this.Mode !== AntragEditMode.EDIT);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Titel), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Vorname), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Name), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Geburtsdatum), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Land), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Nationalitaet1), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Nationalitaet2), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Nationalitaet3), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Nationalitaet4), true);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.InlandRechtstraegerName), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.InlandRechtstraegerSitz), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.InlandRegisterNummer), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.InlandRechtsform), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdresseCO), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdresseStrasse), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdressePLZ), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdresseOrt), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdresseLand), true);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AuslandRechtstraegerName), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AuslandRechtsform), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AuslandRechtstraegerSitz), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AuslandGruendungsdatum), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AuslandRegisterLand), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AuslandRegisterOrt), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AuslandRegisterNummer), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AuslandRegisterDatum), true);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RechtskraftAm), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Rechtsmittelfrist), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.EntscheidAm), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Entscheidung), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.FristablaufGrund), true);

        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.GueltigAb), !this.Fields.find(w => w.Name === nameof(c => c.GueltigAb)).Readonly);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.GueltigBis), !this.Fields.find(w => w.Name === nameof(c => c.GueltigBis)).Readonly);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Begruendung), !this.Fields.find(w => w.Name === nameof(c => c.Begruendung)).Readonly);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Status), !this.isNew() && this.Mode !== AntragEditMode.EDIT && !this.Fields.find(w => w.Name === nameof(c => c.Status)).Readonly);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Entscheidung), !this.Fields.find(w => w.Name === nameof(c => c.Entscheidung)).Readonly);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.FristablaufGrund), this.Model.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELAUFEN && this.Mode !== AntragEditMode.CHECK && !this.Fields.find(w => w.Name === nameof(c => c.FristablaufGrund)).Readonly);
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'Eingangsdatum', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'Antrag gültig bis', false).setNullDateHigh().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Begruendung), 'Begründung', 'vwbp_schwaerzen_antrag_begruendung', true, false, null, 'v1/vwbp').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Status), 'Status', 'vwbp_schwaerzen_antrag_status', true, false, true, 'v1/vwbp').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Entscheidung), 'Entscheidung', 'vwbp_schwaerzen_antrag_entscheidung', true, false, true, 'v1/vwbp').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.EntscheidAm), 'Entscheid am', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Rechtsmittelfrist), 'Rechtsmittelfrist', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.RechtskraftAm), 'Rechtskraft', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateLabel('rk_filler', ' ').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.FristablaufGrund), 'Grund Fristablauf', 'vwbp_schwaerzen_antrag_fristablauf', true, false, null, 'v1/vwbp').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateLabel('end_filler', ' ').setMDSizeHalf());

        // WB = Natürliche Person
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Titel), 'CORE.COMMON.TITEL', false).setMDSizeHalf().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateLabel('titel_filler', ' ').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Vorname), 'CORE.COMMON.VORNAME', false).setMDSizeHalf().setMaxLength(256));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'CORE.COMMON.NAME', false).setMDSizeHalf().setMaxLength(256));

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Geburtsdatum), 'CORE.COMMON.GEBURTSDATUM', false).setMDSizeHalf().setMinMaxDate(this.commonService.getDateAddYears(new Date(), -110), new Date()));
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Land), 'CORE.COMMON.WOHNSITZSTAAT', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Nationalitaet1), 'CORE.COMMON.STAATSANGEHOERIGKEIT', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Nationalitaet2), 'CORE.COMMON.STAATSANGEHOERIGKEIT2', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Nationalitaet3), 'CORE.COMMON.STAATSANGEHOERIGKEIT3', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Nationalitaet4), 'CORE.COMMON.STAATSANGEHOERIGKEIT4', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());

        // WB = Juristische Person

        // inländisch
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.InlandRechtstraegerName), 'VWBP.PUBLIC.FORM.WB_NAME_FIRMA', false).setMDSizeHalf().setMaxLength(512));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.InlandRegisterNummer), 'VWBP.COMMON.REGISTERNR', false).setMDSizeHalf().setMaxLength(128));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.InlandRechtsform), 'VWBP.COMMON.RECHTSFORM', false).setMDSizeHalf().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.InlandRechtstraegerSitz), 'VWBP.COMMON.SITZ_ERRICHTUNGSORT', false).setMDSizeHalf().setMaxLength(128));

        // ausländisch
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRechtstraegerName), 'VWBP.PUBLIC.FORM.WB_NAME_FIRMA', false).setMDSizeHalf().setMaxLength(512));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRechtsform), 'VWBP.COMMON.RECHTSFORM', false).setMDSizeHalf().setMaxLength(128));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRechtstraegerSitz), 'VWBP.COMMON.SITZ_ERRICHTUNGSORT', false).setMDSizeHalf().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.AuslandGruendungsdatum), 'VWBP.COMMON.GRUENDUNGSDATUM_AUSLAND', false).setMDSizeHalf().setMinMaxDate(new Date(1800, 1, 1), new Date()));

        this.Fields.push(this.formFieldService.CreateLabel('lblAuslandEintrag', 'VWBP.COMMON.REGISTEREINTRAG_AUSLAND', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.AuslandRegisterLand), 'VWBP.COMMON.REGISTERLAND', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRegisterOrt), 'VWBP.COMMON.REGISTERORT', false).setMDSizeHalf().setMaxLength(128));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRegisterNummer), 'VWBP.COMMON.REGISTERNR', false).setMDSizeHalf().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.AuslandRegisterDatum), 'VWBP.COMMON.REGISTERDATUM', false).setMDSizeHalf().setMinMaxDate(new Date(1800, 1, 1), new Date()));

        // adresse
        this.Fields.push(this.formFieldService.CreateLabel('lblAuslandAdresse', 'VWBP.COMMON.ADRESSE', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RTZustellAdresseCO), 'VWBP.COMMON.CO', false).setMDSizeFull().setReadonly().setMaxLength(256));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RTZustellAdresseStrasse), 'VWBP.COMMON.STRASSE_NUMMER', false).setMDSizeFull().setReadonly().setMaxLength(512));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RTZustellAdressePLZ), 'VWBP.COMMON.PLZ', false).setMDSizeHalf().setReadonly().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RTZustellAdresseOrt), 'VWBP.COMMON.ORT', false).setMDSizeHalf().setReadonly().setMaxLength(128));

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.RTZustellAdresseLand), 'VWBP.COMMON.LAND', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());

        //
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Verlaufskommentar), 'Kommentar', false).setMDSizeFull());
    }
}
