declare var document: any;
import { Injectable } from '@angular/core';
import { AviApiService } from './api.service';
import { AviCommonService } from './common.service';
import * as signalR from '@microsoft/signalr';
import customProtocolCheck from 'custom-protocol-check';
import { IDocument } from '../shared/interfaces';
import { AviCoreDocDialogComponent } from '../components/documents/avi-core-doc-dialog/avi-core-doc-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AviDokumentAblageListDetailConst } from '../shared/constants/constants';


export interface ClipboardInfoDto {
    fileDate?: Date;
    fileExtension?: string;
    fileName?: string;
    filePath?: string;
    fileTitle?: string;
}


@Injectable({ providedIn: 'root' })
export class AviDocumentService {

    private appServerUrlAndPort: string = 'http://localhost:8086/api';
    private baseUrl: string = 'http://localhost';
    private basePort: number = 5000;
    private connection: signalR.HubConnection;

    public Connected: boolean = false;

    public DocumentServiceEnabled = false;

    public CheckedOutDocuments: any[] = null;
    public LockedDocuments: any[] = new Array();

    // falls benötigt in app.component bzw. app.main.component pro Kundenprojekt setzen
    public ApiPrefix: string = '/avidoc';

    private checkedOutDocumentsChanged = new Subject<any>();
    checkedOutDocumentsChanged$ = this.checkedOutDocumentsChanged.asObservable();

    constructor(public http: HttpClient, public apiService: AviApiService, public commonService: AviCommonService, public dialog: MatDialog) {

        this.initService();
    }


    async initService() {
        this.http.get('assets/config.txt').toPromise<any>().then(jsonConfig => {
            // console.log('AviDocumentService - jsonConfig', jsonConfig);
            this.appServerUrlAndPort = jsonConfig.baseURL;
            if (jsonConfig.docServiceEnabled === true) {
                this.DocumentServiceEnabled = true;
                if (!isNaN(jsonConfig.docServiceLocalPort))
                    this.basePort = <number>jsonConfig.docServiceLocalPort;

                this.initSignalR();
                setInterval(() => {
                    if (!this.connection)
                        this.initSignalR();
                }, 5000);
            }
        });
    }


    public LoadCheckedOutDocuments() {
        this.GetCheckedOutDocuments().then(r => {            
            const oldVal = this.CheckedOutDocuments;            
            this.CheckedOutDocuments = r;
            this.Connected = true;

            if ((oldVal == null && this.CheckedOutDocuments != null) || 
                (oldVal != null && this.CheckedOutDocuments == null) || 
                (oldVal && this.CheckedOutDocuments && 
                ((oldVal.length !== this.CheckedOutDocuments.length) ||
                (!oldVal.every(w => this.CheckedOutDocuments.some(x => x.id == w.id))))))
                this.checkedOutDocumentsChanged.next({ old: oldVal, new: this.CheckedOutDocuments});

            // console.log('CheckedOutDocuments', this.CheckedOutDocuments);
        });
    }

    private get BaseUrlAndPort(): string {
        return `${this.baseUrl}:${this.basePort}`;
    }

    private initSignalR() {
        console.debug('AviDocumentService.initSignalR...');

        if (!this.DocumentServiceEnabled) {
            console.debug('Document Service ist deaktiviert.');
            return;
        }

        if (this.connection) {
            console.debug('Connection schon da');
            return;
        }
        this.connection = new signalR.HubConnectionBuilder()
            // .configureLogging(signalR.LogLevel.Trace) // Für Debug-Modus
            // .configureLogging(this.commonService.IsProduction ? signalR.LogLevel.Critical : signalR.LogLevel.Trace)
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(this.commonService.trimUrl(this.BaseUrlAndPort) + '/signalr/mainhub')
            .withAutomaticReconnect([0, 2000, 5000, 10000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 60000, 60000, 60000, 60000, 60000, 60000, 60000])
            .build();
        this.connection.start().then(() => {
            console.debug('Connected to AviDocumentService SignalR MainHub');
            this.LoadCheckedOutDocuments();

            this.connection.onreconnected(connectionId => {
                console.debug('AviDocumentService SignalR onreconnected', connectionId);
                this.LoadCheckedOutDocuments();
            });
            this.connection.onreconnecting(e => {
                if (e) {
                    console.warn('AviDocumentService SignalR onreconnecting', e);
                    this.Connected = false;
                    this.CheckedOutDocuments = null;
                    this.checkedOutDocumentsChanged.next(null);
                } else {
                    console.debug('AviDocumentService SignalR onreconnecting', e);
                }

            });
            this.connection.onclose(e => {
                console.debug('AviDocumentService SignalR on onclose', e);
                this.Connected = false;
                this.connection = null;
                this.CheckedOutDocuments = null;
                this.checkedOutDocumentsChanged.next(null);
            });

            this.connection.on('OnOpen', (data: any[]) => {
                console.debug('AviDocumentService SignalR on OnOpen', data);
                this.LoadCheckedOutDocuments();
            });
            this.connection.on('OnSave', (data: any[]) => {
                console.debug('AviDocumentService SignalR on OnSave', data);
                this.LoadCheckedOutDocuments();
            });
            this.connection.on('OnCheckOut', (data: any[]) => {
                console.debug('AviDocumentService SignalR on OnCheckOut', data);
                this.LoadCheckedOutDocuments();
            });
            this.connection.on('OnCheckin', (data: any[]) => {
                console.debug('AviDocumentService SignalR on OnCheckin', data);
                this.LoadCheckedOutDocuments();
            });
            this.connection.on('OnDeleteLocal', (data: any[]) => {
                console.debug('AviDocumentService SignalR on OnDeleteLocal', data);
                this.LoadCheckedOutDocuments();
            });
            this.connection.on('OnCheckinAllOpened', (data: any[]) => {
                console.debug('AviDocumentService SignalR on OnCheckinAllOpened', data);
                this.LoadCheckedOutDocuments();
            });
            this.connection.on('OnShutDown', (data: any[]) => {
                console.debug('AviDocumentService SignalR on OnShutDown', data);
                const oldVal = this.CheckedOutDocuments;
                this.CheckedOutDocuments = null;
                this.checkedOutDocumentsChanged.next({ old: oldVal, new: this.CheckedOutDocuments});
            });


        }).catch((err) => {
            console.warn(err.toString());
            this.connection = null;
            this.Connected = false;
        });


    }


    public IsLocalServiceAvailable(): Promise<boolean> {
        console.log('IsLocalServiceAvailable');
        return new Promise<boolean>((resolve, reject) => {
            if (!this.IsOnline()) {
                resolve(false);
                return;
            }

            const url = `${this.BaseUrlAndPort}/api/info/status`;
            this.http.get(url).toPromise<any>().then(data => {
                resolve(data.status === 'online');
            }).catch(err => {
                resolve(false);
                console.log("error url", url, err);
                this.Connected = false;
                // reject(err);
            });
        });
    }


    public IsOnline() {
        return this.DocumentServiceEnabled && this.Connected;
    }

    public InitDoc(sorter: number = null): IDocument {
        const d: IDocument = {
            Id: null,
            Title: null,
            Path: null,
            Description: null
        };
        return d;
    }

    public ShowCheckinDialog(data: IDocument): Promise<IDocument> {

        return new Promise<IDocument>((resolve, reject) => {
            if (data === null) {
                data = this.InitDoc();
            }

            const act = async (result) => {
                try {
                    return await this.CheckInDocument(result);
                } catch (err) {
                    this.commonService.notificateError(this._buildError(err));
                    throw err;
                }
            };

            const dialogRef = this.dialog.open(AviCoreDocDialogComponent, {
                width: '60vw',
                disableClose: true,
                data: { Data: data, Action: act }
            });


            dialogRef.afterClosed().subscribe((result: IDocument) => {
                console.log('ShowCheckinDialog result', result);
                resolve(result || null);
            });
        });

    }

    public ShowCreateNewFileDialog(): Promise<IDocument> {
        return new Promise<IDocument>((resolve, reject) => {
            const data: IDocument = {
                Id: null
            };

            const act = async (result) => {
                try {
                    return await this.SaveNewDocument(result);
                } catch (err) {
                    this.commonService.notificateError(this._buildError(err));
                    throw err;
                }
            };

            const dialogRef = this.dialog.open(AviCoreDocDialogComponent, {
                width: '60vw',
                disableClose: true,
                data: { Data: data, Action: act }
            });

            dialogRef.afterClosed().subscribe((result: IDocument) => {
                console.log('ShowCreateNewFileDialog result', result);
                resolve(result || null);
            });
        });

    }

    private s_OfficeTypes: string[] = 
    [
        '.docx', '.dotx', '.docm', '.dotm', '.doc', '.dot', 
        '.xlsx', '.xltx', '.xlsm', '.xltm', '.xlam', '.xlsb', '.xls', '.xlt', '.xla', 
        '.pptx', '.potx', '.ppsx', '.ppt', '.pps', 
        '.msg'
    ];

    public IsOfficeType(extension: string): boolean {
        return extension && this.s_OfficeTypes.some(w => w === extension.toLowerCase());
    } 

    public IsDocumentCheckedOut(archivDevId: string, docId: string): boolean {
        if (!this.IsOnline() || this.CheckedOutDocuments == null)
            return false;
        return this.CheckedOutDocuments.some(i => i.fileDocId === docId && i.fileArchivDefId === archivDevId);
    }

    public GetDocumentCheckedOut(archivDevId: string, docId: string): any {
        if (!this.IsOnline()  || this.CheckedOutDocuments == null)
            return null;
        return this.CheckedOutDocuments.find(i => i.fileDocId === docId && i.fileArchivDefId === archivDevId);
    }

    public async IsDocumentLocked(archivDevId: string, docId: string): Promise<boolean> {
        const status = await this.GetDocumentLockstatus(archivDevId, docId);
        return status && status.lockstatus?.Id === AviDokumentAblageListDetailConst.AVIDOC_LOCKSTATUS_LOCKED;
    }

    public GetDocumentLockstatus(archivDevId: string, docId: string): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {
            const url = `${this.appServerUrlAndPort}${this.ApiPrefix ?? ''}/GetDocumentLockstatus/${archivDevId}/${docId}`;
            this.http.get(url, { headers: this.getAppserverRequestHeaders() }).toPromise<any>().then(data => {
                resolve(data);
            }).catch(err => {
                reject(false);
                console.log("error url", url, err);
                this.Connected = false;
                // reject(err);
            });
        });
    }


    public async IsDocumentDeleted(archivDevId: string, docId: string): Promise<boolean> {
        const status = await this.GetDocumentLoeschstatus(archivDevId, docId);
        return status && (status.lockstatus?.Id === AviDokumentAblageListDetailConst.AVIDOC_LOESCHSTATUS_GELOESCHT || status.lockstatus?.Id === AviDokumentAblageListDetailConst.AVIDOC_LOESCHSTATUS_STORNIERT);
    }

    public GetDocumentLoeschstatus(archivDevId: string, docId: string): Promise<any> {
        return new Promise<boolean>((resolve, reject) => {
            const url = `${this.appServerUrlAndPort}${this.ApiPrefix ?? ''}/GetDocumentLoeschstatus/${archivDevId}/${docId}`;
            this.http.get(url, { headers: this.getAppserverRequestHeaders() }).toPromise<any>().then(data => {
                resolve(data);
            }).catch(err => {
                reject(false);
                console.log("error url", url, err);
                this.Connected = false;
                // reject(err);
            });
        });
    }

    public CheckoutDocument(archivDevId: string, docId: string, docKategorieId: string, docArtId: string, fileName: string, fileExt: string, title: string, description: string, keywordDocArtName: string, keywordGruppeName: string, keywordUntergruppeName: string): Promise<any> {
        const req = {
            archivDevId,
            docId,
            docKategorieId,
            docArtId,
            keywordDocArtName,
            keywordGruppeName,
            keywordUntergruppeName,
            fileName,
            fileExt,
            title,
            description,
            appServerBaseUrl: this.appServerUrlAndPort,
            appServerDocApi: this.ApiPrefix,
            token: this.apiService.getAccessToken()
        };
        console.log("CheckoutDocument request", req);

        return new Promise<any>((resolve, reject) => {
            const url = `${this.BaseUrlAndPort}/api/document/checkout`;
            console.log('URL: ', url);
            this.http.post(url, this.commonService.stringifyWithNl(req), { headers: this.getHeaders() }).toPromise<any>().then(data => {
                console.log('Status = ', data.status);
                resolve(data.status === 'online');
            }).catch(err => {
                reject(this._buildError(err));
            });
        });
    }

    // public CheckinOpenedDocument(doc: IDocument): Promise<any> {
    //     const req = {
    //         document: doc,
    //         token: this.apiService.getAccessToken()
    //     };
    //     console.log("CheckinOpenedDocument request", req);

    //     return new Promise<any>((resolve, reject) => {
    //         const url = `${this.BaseUrlAndPort}/api/document/checkin-opened`;
    //         console.log('URL: ', url);
    //         this.http.post(url, this.commonService.stringifyWithNl(req), { headers: this.getHeaders() }).toPromise<any>().then(data => {
    //             console.log('Status = ', data.status);
    //             resolve(data.status === 'online');
    //         }).catch(err => {
    //             reject(this._buildError(err));
    //         });
    //     });

    // }

    public LockDocument(archivDevId: string, docId: string): Promise<any>
    {
        return this.setLockDocument(true, archivDevId, docId);
    }

    public UnlockDocument(archivDevId: string, docId: string): Promise<any>
    {
        return this.setLockDocument(false, archivDevId, docId);
    }

    private setLockDocument(lock: boolean, archivDevId: string, docId: string): Promise<any> {
        const req = {
            token: this.apiService.getAccessToken()
        };
        // console.log("Lock/Unlock request", req);

        let lockFunctionName;
        if(lock)
            lockFunctionName = "LockDocument";
        else
            lockFunctionName = "UnlockDocument";

        return this.postAviDocCommand(`${this.appServerUrlAndPort}${this.ApiPrefix ?? ''}/${lockFunctionName}/${archivDevId}/${docId}`, req);
    }

    public softUndeleteDocument(archivDevId: string, docId: string): Promise<any> {
        const req = {
            token: this.apiService.getAccessToken()
        };

        return this.postAviDocCommand(`${this.appServerUrlAndPort}${this.ApiPrefix ?? ''}/softundeletedocument/${archivDevId}/${docId}`, req);
    }

    public softDeleteDocument(archivDevId: string, docId: string): Promise<any> {
        const req = {
            token: this.apiService.getAccessToken()
        };

        return this.postAviDocCommand(`${this.appServerUrlAndPort}${this.ApiPrefix ?? ''}/softdeletedocument/${archivDevId}/${docId}`, req);
    }

    public softStornoDocument(archivDevId: string, docId: string): Promise<any> {
        const req = {
            token: this.apiService.getAccessToken()
        };

        return this.postAviDocCommand(`${this.appServerUrlAndPort}${this.ApiPrefix ?? ''}/softstornodocument/${archivDevId}/${docId}`, req);
    }

    private postAviDocCommand(url: string, req: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, this.commonService.stringifyWithNl(req), { headers: this.getAppserverRequestHeaders() }).toPromise<any>().then(data => {
                // console.log('Status = ', data.status);
                resolve(true);
            }).catch(err => {
                reject(this._buildError(err));
            });
        });        
    }

    public BuildAviDocCheckoutUrl(archivDevId: string, docId: string, fileName: string, fileExt: string): string {
        return 'avidoc://checkout';
        // const token = this.apiService.getAccessToken();
        // return `avidoc://checkout/${archivDevId}/${docId}/${fileName}${fileExt}/${token}`;
    }

    public BuildAviDocStartAppUrl(): string {
        return 'avidoc://startapp';
        // const token = this.apiService.getAccessToken();
        // return `avidoc://checkout/${archivDevId}/${docId}/${fileName}${fileExt}/${token}`;
    }

    public BuildAviDocStartFileUrl(archivDevId: string, docId: string, fileName: string, fileExt: string): string {
        return 'avidoc://start';
        // console.log('Connected to AviDocumentService SignalR MainHub');
        // const token = this.apiService.getAccessToken();
        // return `avidoc://start/${archivDevId}/${docId}/${fileName}${fileExt}/${token}`;
    }



    public CheckInDocument(doc: IDocument): Promise<any> {
        const req = {
            document: doc,
            appServerBaseUrl: this.appServerUrlAndPort,
            appServerDocApi: this.ApiPrefix,
            token: this.apiService.getAccessToken()
        };
        // console.log("CheckoutDocument request", req);

        return new Promise<any>((resolve, reject) => {
            const url = `${this.BaseUrlAndPort}/api/document/checkin`;
            this.http.post(url, this.commonService.stringifyWithNl(req), { headers: this.getHeaders() }).toPromise<any>().then(data => {
                resolve(data.status === 'ok');
            }).catch(err => {
                // console.log("error url", url, err);
                reject(this._buildError(err));
            });
        });

    }
  

    public SaveNewDocument(doc: IDocument): Promise<any> {

        return new Promise<any>((resolve, reject) => {
            const url = `${this.BaseUrlAndPort}/api/document/new-file`;

            const formData: FormData = new FormData();
            formData.append('uploadFile', doc.File || null);
            formData.append('appServerBaseUrl', this.appServerUrlAndPort);
            formData.append('appServerDocApi', this.ApiPrefix);
            formData.append('token', this.apiService.getAccessToken());
            formData.append('title', doc.Title || null);
            formData.append('path', doc.Path || null);
            formData.append('description', doc.Description || null);
            const headers = new HttpHeaders().set('X-avi-h1', 'h1h');
            headers.set('Accept', 'application/json');
            this.http.post(url, formData, { headers: headers }).toPromise<any>().then(data => {
                resolve(data.status === 'ok');
            }).catch(err => {
                // console.log("error url", url, err);
                reject(this._buildError(err));
            });
        });

    }

    public OpenDocumentAsProcess(id: string, title: string): Promise<any> {
        const req = {
            id,
            title,
            appServerBaseUrl: this.appServerUrlAndPort,
            appServerDocApi: this.ApiPrefix,
            token: this.apiService.getAccessToken()
        };
        
        console.log('Open req: ', req);
        return new Promise<any>((resolve, reject) => {
            const url = `${this.BaseUrlAndPort}/api/document/open-local`;
            this.http.post(url, this.commonService.stringifyWithNl(req), { headers: this.getHeaders() }).toPromise<any>().then(data => {
                resolve(data.status === 'ok');
            }).catch(err => {
                // console.log("error url", url, err);
                reject(this._buildError(err));
            });
        });
    }


    public DiscardCheckoutDocument(id: string, title: string): Promise<any> {
        const req = {
            id,
            title,
            appServerBaseUrl: this.appServerUrlAndPort,
            appServerDocApi: this.ApiPrefix,
            token: this.apiService.getAccessToken()
        };
        // console.log("CheckoutDocument request", req);

        return new Promise<any>((resolve, reject) => {
            const url = `${this.BaseUrlAndPort}/api/document/discard-checkout`;
            this.http.post(url, this.commonService.stringifyWithNl(req), { headers: this.getHeaders() }).toPromise<any>().then(data => {
                resolve(data.status === 'ok');
            }).catch(err => {
                // console.log("error url", url, err);
                reject(this._buildError(err));
            });
        });

    }



    public OpenDocumentReadonly(archivDevId: string, docId: string, fileName: string, fileExt: string): Promise<any> {
        const req = {
            archivDevId,
            docId,
            fileName,
            fileExt,
            token: this.apiService.getAccessToken()
        };

        return new Promise<any>((resolve, reject) => {
            const url = `${this.BaseUrlAndPort}/api/document/open`;
            this.http.post(url, this.commonService.stringifyWithNl(req), { headers: this.getHeaders() }).toPromise<any>().then(data => {
                resolve(data.status === 'online');
            }).catch(err => {
                // console.log("error url", url, err);
                reject(this._buildError(err));
            });
        });

    }

    public async TryStartAviDocHandler(url: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            console.log('TryStartAviDocHandler start');
            document.location = url || 'avidoc://start';
            let isOnline = false;
            for (let i = 1; i < 5; i++) {
                if (await this.IsLocalServiceAvailable()) {
                    isOnline = true;
                    break;
                }
                await this.commonService.sleep(2000);
            }
            
            // if (isOnline) {
            //     if (!this.connection)
            //         this.initSignalR();
            // }

            console.log('TryStartAviDocHandler', isOnline);
            resolve(isOnline); // TODO: Kann man da was prüfen?  IsLocalServiceAvailable mit Interval und 5 Versuchen oder so?
        });
    }

    public async IsAviDocHandlerInstalled(): Promise<boolean> {


        return new Promise<boolean>((resolve, reject) => {
            customProtocolCheck('avidoc://protocolcheck',
                () => { console.log('customProtocolCheck false'); resolve(false); },
                () => { console.log('customProtocolCheck true'); resolve(true); }
            );
        });



        // let support, iframe = document.createElement('iframe');

        // iframe.style.display = 'none';
        // iframe.setAttribute('src', 'avidoc://start');

        // document.body.appendChild(iframe);

        // try {

        //     console.log('iframe', iframe);

        //     support = !!iframe.contentDocument;
        // } catch (e) {
        //     console.log('e', e);
        //     support = false;
        // }

        // document.body.removeChild(iframe);

        // console.log('IsAviDocHandlerInstalled res', support);

        // return support;

    }

    private getHeaders() {
        // var headers = new HttpHeaders().set('X-avi-h1', 'h1h');
        // headers.set('Content-Type', 'application/json');
        const headers = new HttpHeaders({
            'X-avi-h1': 'h1h',
            'Content-Type': 'application/json'
        })

        // console.log(headers);
        return headers;
    }

    private getAppserverRequestHeaders() {
        // var headers = new HttpHeaders().set('X-avi-h1', 'h1h');
        // headers.set('Content-Type', 'application/json');
        const headers = new HttpHeaders({
            'X-avi-h1': 'h1h',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.apiService.getAccessToken()}`
          })
        console.log(headers);
        return headers;
    }


    public CheckinAllOpenedFiles() {
        const req = {
            appServerBaseUrl: this.appServerUrlAndPort,
            appServerDocApi: this.ApiPrefix,
            token: this.apiService.getAccessToken()
        };
        
        return new Promise<any[]>((resolve, reject) => {
            const url = `${this.BaseUrlAndPort}/api/document/checkin-all-opened`;
            this.http.post(url, this.commonService.stringifyWithNl(req), { headers: this.getHeaders() }).toPromise<any>().then(data => {
                resolve(data);
            }).catch(err => {
                // console.log("error url", url, err);
                reject(this._buildError(err));
            });
        });
    }

    public GetCheckedOutDocuments() {
        const req = { 
            appServerBaseUrl: this.appServerUrlAndPort,
            appServerDocApi: this.ApiPrefix,
            token: this.apiService.getAccessToken()
        };

        return new Promise<any>((resolve, reject) => {
            const url = `${this.BaseUrlAndPort}/api/document/checked-out`;
            this.http.post(url, this.commonService.stringifyWithNl(req), { headers: this.getHeaders() }).toPromise<any>().then(data => {
                resolve(data);
            }).catch(err => {
                reject(this._buildError(err));
            });
        });
    }


    public GetClipboardInfo() {
        return new Promise<any>((resolve, reject) => {
            //const url = `${this.BaseUrlAndPort}/api/document/clipboard-file-info?ts=${new Date().getTime()}`;
            const url = `${this.BaseUrlAndPort}/api/document/clipboard-file-info`;

            var _headers = this.getHeaders();
            //_headers.append('Cache-Control', 'no-cache');
            // _headers
            //     .set('Cache-Control', 'no-cache')
            //     .set('Pragma', 'no-cache')
            //     .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

            this.http.get(url, { headers: _headers, observe: 'response', responseType: 'blob' as 'json' }).toPromise<any>().then(data => {
                
                // requires WithExposedHeaders("Content-Disposition") in AddCors im Appserver
                let lastModified: Date = null;
                const hdrLastModified = data.headers.get('last-modified');
                if (hdrLastModified)
                    lastModified = new Date(data.headers.get('last-modified'));
                
                const contentDisposition = data.headers.get('content-disposition');
                let filename = null;
                if (contentDisposition) {
                    const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = fileNameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                      filename = matches[1].replace(/['"]/g, '');
                    }
                }
               
                resolve({ blob: data.body, filename: filename, lastModified: lastModified });
            }).catch(err => {
                // console.log("error url", url, err);
                reject(this._buildError(err));
            });
        });
    }


    private _buildError(err: any) {
        let errDetail = null;
        try {
            errDetail = err.json();
            if (errDetail)
                errDetail = err.error.error;
        } catch { }
        if (errDetail)
            return errDetail;
        else
            return err;
    }

}
