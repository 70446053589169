import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService, ParentIdResolver, AviTechRoleConst } from '@avi-x/avi-core';

import { AviNatPersonSummaryComponent } from './components/natperson-summary/natperson-summary.component';
import { AviPartnerKommunikationListeComponent } from './components/partner-kommunikation-liste/partner-kommunikation-liste.component';
import { AviAdresseListeComponent } from './components/adresse-liste/adresse-liste.component';


import { AviPartnerSearcherComponent } from './components/partner-searcher/partner-searcher.component';

import { AviJurPersonSearcherComponent } from './components/jurperson-searcher/jurperson-searcher.component';
import { AviJurpersonFormComponent } from './components/jurperson-form/jurperson-form.component';
import { AviJurPersonViewComponent } from './components/jurperson-view/jurperson-view.component';
import { AviJurPersonIndexComponent } from './components/jurperson-index/jurperson-index.component';

import { AviNatPersonSearcherComponent } from './components/natperson-searcher/natperson-searcher.component';
import { AviNatpersonFormComponent } from './components/natperson-form/natperson-form.component';
import { AviNatPersonIndexComponent } from './components/natperson-index/natperson-index.component';
import { AviNatPersonViewComponent } from './components/natperson-view/natperson-view.component';

import { AviPartnerRolleListeComponent } from './components/partnerrolle-liste/partnerrolle-liste.component';

import { AviActivitySearcherComponent } from '../activity/components/activity-searcher/activity-searcher.component';
import { AviDokumentDashboardComponent } from '../dokument/components/dokument-dashboard/dokument-dashboard.component';
import { AviJurPersonSummaryComponent } from './components/jurperson-summary/jurperson-summary.component';
import { AviPartnerIdentifikationListeComponent } from './components/partner-identifikation-liste/partner-identifikation-liste.component';
import { AviBankVerbindungListeComponent } from '../bank/components/bankverbindung-liste/bankverbindung-liste.component';


const routes: Routes = [
    // Searcher
    { path: 'partner', component: AviPartnerSearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] },

    // Natürliche Person
    { path: 'natperson/form/:id', component: AviNatpersonFormComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    { path: 'natperson', component: AviNatPersonSearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    {
        path: 'natperson/index/:id',
        canActivate: [],
        component: AviNatPersonIndexComponent,
        children: [
            { path: '', redirectTo: 'summary', pathMatch: 'full' },
            {
                path: 'summary',
                component: AviNatPersonSummaryComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'view',
                component: AviNatPersonViewComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'adresse-liste',
                component: AviAdresseListeComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'kommunikation-liste',
                component: AviPartnerKommunikationListeComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'identifikation-liste',
                component: AviPartnerIdentifikationListeComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'partnerrolle-liste',
                component: AviPartnerRolleListeComponent,
                resolve: { id: ParentIdResolver },
                canActivate: [AviCanActivateWhenLoggedInService],
                data: { 'submodul': true, 'role': AviTechRoleConst.BeziehungenUebersicht_TAB }
            },
            {
                path: 'activity',
                component: AviActivitySearcherComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true, 'contextType': 'aviita.CAF.common.modul.partner.model.CAFNatperson' }
            },
            {
                path: 'dokument-dashboard',
                component: AviDokumentDashboardComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true, 'contextType': 'aviita.CAF.common.modul.partner.model.CAFNatperson' }
            }
        ]
    },

    // Juristische Person
    { path: 'jurperson/form/:id', component: AviJurpersonFormComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    { path: 'jurperson', component: AviJurPersonSearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    {
        path: 'jurperson/index/:id',
        canActivate: [],
        component: AviJurPersonIndexComponent,
        children: [
            { path: '', redirectTo: 'summary', pathMatch: 'full' },
            {
                path: 'summary',
                component: AviJurPersonSummaryComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'view',
                component: AviJurPersonViewComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'adresse-liste',
                component: AviAdresseListeComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'kommunikation-liste',
                component: AviPartnerKommunikationListeComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'identifikation-liste',
                component: AviPartnerIdentifikationListeComponent,
                resolve: { id: ParentIdResolver }
            },
            {
                path: 'bankverbindung-liste',
                component: AviBankVerbindungListeComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            },
            {
                path: 'partnerrolle-liste',
                component: AviPartnerRolleListeComponent,
                resolve: { id: ParentIdResolver },
                canActivate: [AviCanActivateWhenLoggedInService],
                data: { 'submodul': true, 'role': AviTechRoleConst.BeziehungenUebersicht_TAB }
            },
            {
                path: 'activity',
                component: AviActivitySearcherComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true, 'contextType': 'aviita.CAF.common.modul.partner.model.CAFJurperson' }
            },
            {
                path: 'dokument-dashboard',
                component: AviDokumentDashboardComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true, 'contextType': 'aviita.CAF.common.modul.partner.model.CAFJurperson' }
            }
        ]
    }
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviPartnerRoutingModule {
}
