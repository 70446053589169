import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AviBaseFormComponent, AviBaseSearcherComponent, AviCommonService, AviDialogComponent, AviFormField, AviFormFieldService, typed_nameof, AviApiService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPRechtstraeger, VwbPVersion } from '@avi-x/vwbp-public';
import { VwbPBehoerdensucheRtRequestDto } from '../../../dto/vwbpbehoerdensuche.models';
import { VwbPInternRechtstraegerViewComponent } from '../../../rechtstraeger/components/rechtstraeger-view/rechtstraeger-view.component';
import { VwbPInternBehoerdenService } from '../../services/behoerden.service';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';
import { RTVerMode } from '../../../rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';

const nameof = (nameFunction: ((obj: VwbPBehoerdensucheRtRequestDto) => any)) => typed_nameof<VwbPBehoerdensucheRtRequestDto>(nameFunction);

@Component({
    selector: 'vwbp-intern-behoerden-suche-rechtstraeger',
    templateUrl: './behoerden-suche-rechtstraeger.component.html',
    styleUrls: ['./behoerden-suche-rechtstraeger.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternBehoerdenSucheRechtstraegerComponent implements OnInit, OnDestroy {
    
    toolbarButtons : any[] = [
        { title: 'Excel Export', class: 'p-button-secondary', icon: 'pi pi-download', action: () => this.OnExportClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0, visible: () => this.CheckboxSelection },
    ];
    
    public Loading: boolean = false;
    public Model: VwbPBehoerdensucheRtRequestDto = null;
    public CheckboxSelection: boolean = false;

    public Fields: AviFormField[] = [];

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @ViewChild('rtDialog', { static: false })
    rtDialog: AviDialogComponent;

    @ViewChild('rtView', { static: false })
    rtView: VwbPInternRechtstraegerViewComponent;

    constructor(
        private commonService: AviCommonService,
        private behoerdenService: VwbPInternBehoerdenService,
        private vwbpCommonService: VwbPCommonService,
        private formFieldService: AviFormFieldService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private apiService: AviApiService,
        private rechtstraegerService: VwbPInternRechtstraegerService
    ) {
    }

    ngOnInit() {
        const isAJUUser = this.vwbpCommonService.currentUserIsAjuUser();
        this.CheckboxSelection = isAJUUser;

        this.initFields();
        this.Model = {
            Name: '',
            CbAbgegebeneRechtstraeger: true,
            CbGeloeschteRechtstraeger: true,
            CbNichtAbgeschlosseneRechtstraeger: isAJUUser
        };
    }

    initFields() {
        const isAJUUser = this.vwbpCommonService.currentUserIsAjuUser();
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'VWBP.INTERN.BEHOERDENABFRAGE.RT_REGISTERNR', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbAbgegebeneRechtstraeger), null, 'VWBP.INTERN.BEHOERDENABFRAGE.AUCH_ABGEGEBENE_RTS_SUCHEN', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbGeloeschteRechtstraeger), null, 'VWBP.INTERN.BEHOERDENABFRAGE.AUCH_GELOESCHTE_RTS_SUCHEN', false).setMDSizeFull());

        if (isAJUUser)
            this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbNichtAbgeschlosseneRechtstraeger), null, 'VWBP.INTERN.BEHOERDENABFRAGE.AUCH_NICHT_ABGESCHLOSSENE_RTS_SUCHEN', false).setMDSizeFull());

        if (!isAJUUser)
            this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Begruendung), 'VWBP.INTERN.BEHOERDENABFRAGE.BEGRUENDUNG_DER_SUCHE', true).setMDSizeFull());
    }

    startSearch(model: VwbPBehoerdensucheRtRequestDto) {
        return this.searcher.doSearch();
    }


    onSearcherInit(data) {
        const isAJUUser = this.vwbpCommonService.currentUserIsAjuUser();

        this.searcher.beginInit();
        this.searcher.addTextColumn('RechtstraegerName', 'VWBP.COMMON.RT').setFilterable(false).setVisible(true)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_RT_TOOLTIP');

        this.searcher.addTextColumn('RechtstraegerSitz', 'VWBP.COMMON.SITZ').setFilterable(false).setVisible(true)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_SITZ_TOOLTIP');

        this.searcher.addTextColumn('RechtstraegerRegisternummer', 'VWBP.COMMON.REGISTERNR').setFilterable(false).setVisible(true)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_REGISTERNR_TOOLTIP')
            .setWidth('150px');

        this.searcher.addTextColumn('RechtstraegerRechtsform', 'VWBP.COMMON.RECHTSFORM').setFilterable(false).setVisible(true)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_RECHTSFORM_TOOLTIP');

        if (isAJUUser)
            this.searcher.addListTypeColumn('Erfassungsstatus', 'VWBP.COMMON.ERFASSUNGSSTATUS', 'vwbp_erfassungsstatus', true, false, null, 'v1/vwbp')
                .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_ERFASSUNGSSTATUS_TOOLTIP')
                .setWidth('200px');

        this.searcher.addDateColumn('Erfassungsdatum', 'VWBP.COMMON.ERFASSUNGSDATUM').setFilterable(false).setSortable(false)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_ERFASSUNGSDATUM_TOOLTIP')
            .setWidth('190px');

        this.searcher.addDateColumn('HrLoeschungsdatum', 'VWBP.COMMON.LOESCHUNGSDATUM').setFilterable(false).setSortable(false)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_LOESCHUNGSDATUM_TOOLTIP')
            .setWidth('190px');

        this.searcher.addListTypeColumn('Uebergabestatus', 'VWBP.COMMON.UEBERGABESTATUS', 'vwbp_rt_uebergabestatus', true, false, null, 'v1/vwbp')
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_UEBERGABESTATUS_TOOLTIP')
            .setWidth('200px');

        this.searcher.addTextColumn('VersionLaufnummer', 'VWBP.COMMON.VERSION').setFilterable(false).setVisible(true)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_VERSION_TOOLTIP')
            .setWidth('90px');

        this.searcher.endInit();
    }

    public GetRowClass = (row: any): string => {
        let ret = 'no-border-left no-border-right';
        if (row) {
            if (row['HrLoeschungsdatum'])
                ret += ' rt-geloescht';
        }
        return ret;
    }

    ngOnDestroy() {
        this.commonService.hideGlobalLoader();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        return new Promise<any>(async (resolveFn, rejectFn) => {
            try {
                this.commonService.showGlobalLoader();
                const results = await this.behoerdenService.StartBehoerdensucheRt({ ...this.Model, ...searchConfig });
                resolveFn(results);
                this.commonService.hideGlobalLoader();
            } catch (err) {
                this.commonService.hideGlobalLoader();
                rejectFn(new Error(this.commonService.getErrorText(err, true)));
            }
        });
    }

    public SelectAllDelegate: any = async (searchValue: string, searchConfig: any = null) => {
        return await this.behoerdenService.StartBehoerdensucheRtIds({ ...this.Model, ...searchConfig });
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    async onDoubleClickSearcher(data) {
        // this.router.navigate(['/intern/vwbp/behoerden/rt', data.Id]);

        try {
            this.commonService.showGlobalLoader();
            const version = await this.apiService.getModel(VwbPVersion, `v1/intern/vwbp/rechtstraeger/version/${data.Id}`);
            const rechtstraeger = await this.apiService.FindModel(VwbPRechtstraeger, `v1/intern/vwbp/rechtstraeger/${version.Rechtstraeger_ID}`, null, null, null);
            rechtstraeger.SelectedVersion = version;
            await this.rtView.initView(rechtstraeger, true, true);
            this.rtView.refresh();
        } finally {
            this.commonService.hideGlobalLoader();
        }

        this.rtDialog.open();
    }

    CloseRTView() {
        this.rtDialog.close();
        this.rtView.initView(null);
        this.cdr.detectChanges();
    }

    async OnExportClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.Id);
        this.rechtstraegerService.OpenBehoerdenExcelExportJobDialog(rechtstraegerIds, RTVerMode.BEHOERDEN_VERSION_IN);
    }

    public GetSelectedRechtstraeger(): any[] {
        return this.searcher.SelectedRows;
    }
}
