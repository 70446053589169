import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { ListType } from '../shared';

export class StatusDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;

    @Expose()
	public KurzBez: string;

    @Expose()
	public Bezeichnung: string;

    @Expose()
	public Sorter: number;

    @Expose()
	public IsStartState: boolean;

    @Expose()
	public IsDefaultFinishState: boolean;

    @Expose()
	public IsFinishState: boolean;

    @Expose()
	public Bearbeitbar: boolean;

	@Expose()
	@Type(() => ListType)
	public Sichtbarkeit: ListType;

	@Expose()
	@Type(() => ListType)
	public Verantwortlichkeit: ListType;
}
