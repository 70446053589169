import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviEmailDefFormComponent } from './emaildef-form.component';
import { EmailDef } from '@avi-x/avi-dto/workflow/emaildef.model';
import { EmailDefSearchResponseDto } from '@avi-x/avi-dto/workflow/emaildefsearchresponsedto.model';

const nameof = (nameFunction: ((obj: EmailDef) => any)) => typed_nameof<EmailDef>(nameFunction);

@Component({
    selector: 'avi-emaildef-searcher',
    templateUrl: './emaildef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviEmailDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFEMAILDEF';
    SettingsKey = 'settings.searcher.emaildef';
    BaseUrl = 'workflow/emaildef';
	FormType = AviEmailDefFormComponent;

    CanEdit: boolean = false;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFEmailDef schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            // this.sessionController.openSession('emaildef-index', this.SelectedRows.Id);
            this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addListTypeColumn(nameof(c => c.Typ), 'CORE.COMMON.MODEL.CAFEMAILDEF.TYP', 'caf_emaildef_typ', true, true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFEMAILDEF.BEZEICHNUNG', true);
        searcher.addTextColumn(nameof(c => c.EmailSubject1), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILSUBJECT1', true);        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(EmailDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
