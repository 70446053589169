import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AviCommonService, AviApiService } from '@avi-x/avi-core';
import { MatTabNav } from '@angular/material/tabs';

@Component({
    selector: 'vwbp-public-rechtstraeger-index',
    templateUrl: './rechtstraeger-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPPublicRechtstraegerIndexComponent implements OnInit {

    navLinks = [
        { path: './rtlist', label: 'Rechtsträger' }
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
    ) {
    }

    ngOnInit() {
    }

}
