import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { BasePartner } from '../partner/basepartner.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class Adresse extends BaseModel {
    @Expose()
    public get AdresseDiscriminator(): string { return this.getProperty('AdresseDiscriminator'); }
    public set AdresseDiscriminator(value: string) { this.setProperty('AdresseDiscriminator', value); }

    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }

    @Expose()
    public get verwendeAdresseUeberst(): boolean { return this.getProperty('verwendeAdresseUeberst'); }
    public set verwendeAdresseUeberst(value: boolean) { this.setProperty('verwendeAdresseUeberst', value); }

    @Expose()
    public get Encrypted(): boolean { return this.getProperty('Encrypted'); }
    public set Encrypted(value: boolean) { this.setProperty('Encrypted', value); }

    @Expose()
    public get ExterneReferenz(): string { return this.getProperty('ExterneReferenz'); }
    public set ExterneReferenz(value: string) { this.setProperty('ExterneReferenz', value); }

    @Expose()
    public get vonPartner_ID(): string { return this.getModelId('vonPartner_ID'); }
    public set vonPartner_ID(value: string) { this.setModelId('vonPartner_ID', value); }

    @Expose()
    @Type(() => BasePartner)
    public get vonPartner(): BasePartner { return this.getModel('vonPartner'); }
    public set vonPartner(value: BasePartner) { this.setModel('vonPartner', value); }

    @Expose()
    public get verwendeAdresse_ID(): string { return this.getModelId('verwendeAdresse_ID'); }
    public set verwendeAdresse_ID(value: string) { this.setModelId('verwendeAdresse_ID', value); }

    @Expose()
    @Type(() => ListType)
    public get AdressTyp(): ListType { return this.getProperty('AdressTyp'); }
    public set AdressTyp(value: ListType) { this.setProperty('AdressTyp', value); }

    @Expose()
    @Type(() => ListType)
    public get AdressSync(): ListType { return this.getProperty('AdressSync'); }
    public set AdressSync(value: ListType) { this.setProperty('AdressSync', value); }

    @Expose()
    @Type(() => ListType)
    public get Klassifizierung(): ListType { return this.getProperty('Klassifizierung'); }
    public set Klassifizierung(value: ListType) { this.setProperty('Klassifizierung', value); }

    @Expose()
    @Type(() => ListType)
    public get DomizilArt(): ListType { return this.getProperty('DomizilArt'); }
    public set DomizilArt(value: ListType) { this.setProperty('DomizilArt', value); }

}
