import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { RahmenPartner } from '../partnerdef/rahmenpartner.model';
import { BasePartnerExtension } from '../partner/basepartnerextension.model';
import { NatpersonExtension } from '../partner/natpersonextension.model';
import { Land } from '../partner/land.model';
import { ModelState } from '../system/modelstate.model';
import { BaseModelState } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { Sprache } from '../system/sprache.model';

export class NatpersonHist extends BaseModelState {

    // Zusatzdaten
    @Expose()
    public get ChangeDefRahmenPartner_ID(): string { return this.getProperty('ChangeDefRahmenPartner_ID'); }
    public set ChangeDefRahmenPartner_ID(value: string) { this.setProperty('ChangeDefRahmenPartner_ID', value); }

    // BasePartner
    @Expose()
    public get PartnerType(): string { return this.getProperty('PartnerType'); }
    public set PartnerType(value: string) { this.setProperty('PartnerType', value); }

    @Expose()
    @TransformDate()
    public get ZustandAb(): Date { return this.getProperty('ZustandAb'); }
    public set ZustandAb(value: Date) { this.setProperty('ZustandAb', value); }

    @Expose()
    @TransformDate()
    public get ZustandBis(): Date { return this.getProperty('ZustandBis'); }
    public set ZustandBis(value: Date) { this.setProperty('ZustandBis', value); }

    @Expose()
    public get ExterneReferenz(): string { return this.getProperty('ExterneReferenz'); }
    public set ExterneReferenz(value: string) { this.setProperty('ExterneReferenz', value); }

    @Expose()
    public get ExterneId(): string { return this.getProperty('ExterneId'); }
    public set ExterneId(value: string) { this.setProperty('ExterneId', value); }

    @Expose()
    public get PartnerNr(): string { return this.getProperty('PartnerNr'); }
    public set PartnerNr(value: string) { this.setProperty('PartnerNr', value); }

    @Expose()
    public get Encrypted(): boolean { return this.getProperty('Encrypted'); }
    public set Encrypted(value: boolean) { this.setProperty('Encrypted', value); }

    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get DomizilAdresseGUID(): string { return this.getProperty('DomizilAdresseGUID'); }
    public set DomizilAdresseGUID(value: string) { this.setProperty('DomizilAdresseGUID', value); }

    @Expose()
    public get Name(): string { return this.getProperty('Name'); }
    public set Name(value: string) { this.setProperty('Name', value); }

    @Expose()
    public get Suchbegriff(): string { return this.getProperty('Suchbegriff'); }
    public set Suchbegriff(value: string) { this.setProperty('Suchbegriff', value); }

    @Expose()
    public get ZusatzName(): string { return this.getProperty('ZusatzName'); }
    public set ZusatzName(value: string) { this.setProperty('ZusatzName', value); }

    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }

    @Expose()
    public get CustomPropXML(): string { return this.getProperty('CustomPropXML'); }
    public set CustomPropXML(value: string) { this.setProperty('CustomPropXML', value); }

    @Expose()
    public get vonRahmenPartner_ID(): string { return this.getModelId('vonRahmenPartner_ID'); }
    public set vonRahmenPartner_ID(value: string) { this.setModelId('vonRahmenPartner_ID', value); }

    @Expose()
    @Type(() => RahmenPartner)
    public get vonRahmenPartner(): RahmenPartner { return this.getModel('vonRahmenPartner'); }
    public set vonRahmenPartner(value: RahmenPartner) { this.setModel('vonRahmenPartner', value); }

    @Expose()
    public get Sprache_ID(): string { return this.getModelId('Sprache_ID'); }
    public set Sprache_ID(value: string) { this.setModelId('Sprache_ID', value); }

    @Expose()
    @Type(() => Sprache)
    public get Sprache(): Sprache { return this.getModel('Sprache'); }
    public set Sprache(value: Sprache) { this.setModel('Sprache', value); }

    @Expose()
    public get BasePartnerExtension_ID(): string { return this.getModelId('BasePartnerExtension_ID'); }
    public set BasePartnerExtension_ID(value: string) { this.setModelId('BasePartnerExtension_ID', value); }

    @Expose()
    @Type(() => BasePartnerExtension)
    public get BasePartnerExtension(): BasePartnerExtension { return this.getModel('BasePartnerExtension'); }
    public set BasePartnerExtension(value: BasePartnerExtension) { this.setModel('BasePartnerExtension', value); }

    @Expose()
    @Type(() => ListType)
    public get PartnerArt(): ListType { return this.getProperty('PartnerArt'); }
    public set PartnerArt(value: ListType) { this.setProperty('PartnerArt', value); }

    @Expose()
    @Type(() => ListType)
    public get PVSPersBesteuerung(): ListType { return this.getProperty('PVSPersBesteuerung'); }
    public set PVSPersBesteuerung(value: ListType) { this.setProperty('PVSPersBesteuerung', value); }

    @Expose()
    @Type(() => ListType)
    public get PflichtCode(): ListType { return this.getProperty('PflichtCode'); }
    public set PflichtCode(value: ListType) { this.setProperty('PflichtCode', value); }

    // Natperson
    @Expose()
    public get Vorname(): string { return this.getProperty('Vorname'); }
    public set Vorname(value: string) { this.setProperty('Vorname', value); }

    @Expose()
    public get Vorname2(): string { return this.getProperty('Vorname2'); }
    public set Vorname2(value: string) { this.setProperty('Vorname2', value); }

    @Expose()
    public get Ledigname(): string { return this.getProperty('Ledigname'); }
    public set Ledigname(value: string) { this.setProperty('Ledigname', value); }

    @Expose()
    @TransformDate()
    public get Geburtsdatum(): Date { return this.getProperty('Geburtsdatum'); }
    public set Geburtsdatum(value: Date) { this.setProperty('Geburtsdatum', value); }

    @Expose()
    public get GeburtsdatumUnbekannt(): boolean { return this.getProperty('GeburtsdatumUnbekannt'); }
    public set GeburtsdatumUnbekannt(value: boolean) { this.setProperty('GeburtsdatumUnbekannt', value); }

    @Expose()
    @TransformDate()
    public get Todesdatum(): Date { return this.getProperty('Todesdatum'); }
    public set Todesdatum(value: Date) { this.setProperty('Todesdatum', value); }

    @Expose()
    public get PreTitle(): string { return this.getProperty('PreTitle'); }
    public set PreTitle(value: string) { this.setProperty('PreTitle', value); }

    @Expose()
    public get PostTitle(): string { return this.getProperty('PostTitle'); }
    public set PostTitle(value: string) { this.setProperty('PostTitle', value); }

    @Expose()
    public get Anrede(): string { return this.getProperty('Anrede'); }
    public set Anrede(value: string) { this.setProperty('Anrede', value); }

    @Expose()
    public get Briefanrede(): string { return this.getProperty('Briefanrede'); }
    public set Briefanrede(value: string) { this.setProperty('Briefanrede', value); }

    @Expose()
    public get SozialVersNr(): string { return this.getProperty('SozialVersNr'); }
    public set SozialVersNr(value: string) { this.setProperty('SozialVersNr', value); }

    @Expose()
    public get Beruf(): string { return this.getProperty('Beruf'); }
    public set Beruf(value: string) { this.setProperty('Beruf', value); }

    @Expose()
    public get BuergerOrt(): string { return this.getProperty('BuergerOrt'); }
    public set BuergerOrt(value: string) { this.setProperty('BuergerOrt', value); }

    @Expose()
    public get GeburtsOrt(): string { return this.getProperty('GeburtsOrt'); }
    public set GeburtsOrt(value: string) { this.setProperty('GeburtsOrt', value); }

    @Expose()
    public get Extension_ID(): string { return this.getModelId('Extension_ID'); }
    public set Extension_ID(value: string) { this.setModelId('Extension_ID', value); }

    @Expose()
    @Type(() => NatpersonExtension)
    public get Extension(): NatpersonExtension { return this.getModel('Extension'); }
    public set Extension(value: NatpersonExtension) { this.setModel('Extension', value); }

    @Expose()
    public get Nation_ID(): string { return this.getModelId('Nation_ID'); }
    public set Nation_ID(value: string) { this.setModelId('Nation_ID', value); }

    @Expose()
    @Type(() => Land)
    public get Nation(): Land { return this.getModel('Nation'); }
    public set Nation(value: Land) { this.setModel('Nation', value); }

    @Expose()
    public get GeburtsLand_ID(): string { return this.getModelId('GeburtsLand_ID'); }
    public set GeburtsLand_ID(value: string) { this.setModelId('GeburtsLand_ID', value); }

    @Expose()
    @Type(() => Land)
    public get GeburtsLand(): Land { return this.getModel('GeburtsLand'); }
    public set GeburtsLand(value: Land) { this.setModel('GeburtsLand', value); }

    @Expose()
    public get NatpersonStatus_ID(): string { return this.getModelId('NatpersonStatus_ID'); }
    public set NatpersonStatus_ID(value: string) { this.setModelId('NatpersonStatus_ID', value); }

    @Expose()
    @Type(() => ModelState)
    public get NatpersonStatus(): ModelState { return this.getModel('NatpersonStatus'); }
    public set NatpersonStatus(value: ModelState) { this.setModel('NatpersonStatus', value); }

    @Expose()
    @Type(() => ListType)
    public get Titel(): ListType { return this.getProperty('Titel'); }
    public set Titel(value: ListType) { this.setProperty('Titel', value); }

    @Expose()
    @Type(() => ListType)
    public get Geschlecht(): ListType { return this.getProperty('Geschlecht'); }
    public set Geschlecht(value: ListType) { this.setProperty('Geschlecht', value); }

    @Expose()
    @Type(() => ListType)
    public get Zivilstand(): ListType { return this.getProperty('Zivilstand'); }
    public set Zivilstand(value: ListType) { this.setProperty('Zivilstand', value); }

}
