import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { AviMultiSelectionComponent } from "./multiselection.component";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { AviCommonService } from "../../../services/common.service";
import { IDTO } from "@avi-x/avi-dto/shared";

export interface IMultiSelectionModel {
    filter(data: any, selectedRows: any[]): boolean;

    createNewRecord(data: any, newId: string): any;

    get leftSearcherIdField(): string;
    get rightSearcherIdField(): string;
}

@Component({
    template: ''
})
export abstract class AviAbstractModelMultiSelectionComponent<LT extends IDTO, RT extends IDTO> implements OnInit, IMultiSelectionModel {

    @ViewChild(AviMultiSelectionComponent, {static: true})
    multiSelectionComponent: AviMultiSelectionComponent;

    ModelId: string;

    protected constructor(protected config: DynamicDialogConfig, private commonService: AviCommonService, 
        @Inject(String) public leftSearcherIdField: string, 
        @Inject(String) public rightSearcherIdField: string, 
        @Inject(String) private rightSearcherFilterField: string) { 
    }

    ngOnInit() {
        this.multiSelectionComponent.multiSelectionModel = this;
    }

    ngAfterViewInit(): void {
        if (this.config && this.config.data) {
            let contextId = this.config.data.Id;
            if (contextId == "0")
                this.ModelId = this.commonService.GuidEmpty;
            else
                this.ModelId = contextId;
        }
    }

    get addedRows(): RT[] {
        return this.multiSelectionComponent.addedItems;
    }

    get removedRows(): RT[] {
        return this.multiSelectionComponent.removedItems;
    }

    get addedIds(): string[] {
        let ids = new Array<string>();
        this.addedRows.forEach(link => ids.push(link[this.rightSearcherFilterField]))

        return ids;
    }

    get removedIds(): string[] {
        let ids = new Array<string>();
        this.removedRows.forEach(link => ids.push(link[this.rightSearcherFilterField]))

        return ids;
    }

    filter(dataRow: LT, selectedRows: RT[]): boolean {
        if (!selectedRows)
            return true;

        for (let i = 0; i < selectedRows.length; i++) {
            if (selectedRows[i][this.rightSearcherFilterField] == dataRow[this.leftSearcherIdField])
            return false;
        }

        return true;
    }

    abstract createNewRecord(data: LT, newId: string): any;
}