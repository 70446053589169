export class UserInfo {
    public Username:string;
    public Name: string;
    public Vorname: string;
    public IsAdmin: boolean;
    public KurzZeichen: string;
    public LoginPartnerID: string;
    public LoginuserID: string;
    public Authentizierung: string;
    public UserLanguageID: number;
    public MitarbeiterID: string;
    public Roles: string[] = [];
    public UserSettings: UserSetting[] = [];
    public CustomInfo?:any;
}

export interface UserSetting {
    Key: string;
    Value: string;
}