import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class AdressBlockZeileDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
	public Sequenz: number;

    @Expose()
	public Zeile: string;

    @Expose()
	public BeibehaltenWennLeer: boolean;

    @Expose()
	public SprachRelevant: boolean;
}
