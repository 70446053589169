import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class JobSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
	public JobID: number;

    @Expose()
    @Type(() => Date)
	public StartedAt: Date;

    @Expose()
    @Type(() => Date)
	public FinishedAt: Date;

    @Expose()
	public FilePath: string;

	@Expose()
	@Type(() => ListType)
	public Status: ListType;

    @Expose()
	public TotalItems: number;

    @Expose()
	public TotalSucceeded: number;

    @Expose()
	public TotalErrors: number;

    @Expose()
	public TotalWorkups: number;

	@Expose()
	public KurzBez1: string;

	@Expose()
	public Bezeichnung1: string;
}
