import { Expose, Type } from 'class-transformer';

import { TechRole } from '../system/techrole.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { StatusDef } from './statusdef.model';

export class StatusWechselDef extends BaseModel {
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }

    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }

    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }

    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }

    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }

    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }

    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }

    @Expose()
    public get UebergangGenerierenOutput(): string { return this.getProperty('UebergangGenerierenOutput'); }
    public set UebergangGenerierenOutput(value: string) { this.setProperty('UebergangGenerierenOutput', value); }

    @Expose()
    public get EingangStatus_ID(): string { return this.getModelId('EingangStatus_ID'); }
    public set EingangStatus_ID(value: string) { this.setModelId('EingangStatus_ID', value); }

    @Expose()
    @Type(() => StatusDef)
    public get EingangStatus(): StatusDef { return this.getModel('EingangStatus'); }
    public set EingangStatus(value: StatusDef) { this.setModel('EingangStatus', value); }

    @Expose()
    public get AusgangStatus_ID(): string { return this.getModelId('AusgangStatus_ID'); }
    public set AusgangStatus_ID(value: string) { this.setModelId('AusgangStatus_ID', value); }

    @Expose()
    @Type(() => StatusDef)
    public get AusgangStatus(): StatusDef { return this.getModel('AusgangStatus'); }
    public set AusgangStatus(value: StatusDef) { this.setModel('AusgangStatus', value); }

    @Expose()
    public get DefaultUserVonStatusWechsel_ID(): string { return this.getModelId('DefaultUserVonStatusWechsel_ID'); }
    public set DefaultUserVonStatusWechsel_ID(value: string) { this.setModelId('DefaultUserVonStatusWechsel_ID', value); }

    @Expose()
    public get DisallowUserVonStatusWechsel_ID(): string { return this.getModelId('DisallowUserVonStatusWechsel_ID'); }
    public set DisallowUserVonStatusWechsel_ID(value: string) { this.setModelId('DisallowUserVonStatusWechsel_ID', value); }

    @Expose()
    public get TechRole_ID(): string { return this.getModelId('TechRole_ID'); }
    public set TechRole_ID(value: string) { this.setModelId('TechRole_ID', value); }

    @Expose()
    @Type(() => TechRole)
    public get TechRole(): TechRole { return this.getModel('TechRole'); }
    public set TechRole(value: TechRole) { this.setModel('TechRole', value); }

    @Expose()
    public get FixerMitarbeiter_ID(): string { return this.getModelId('FixerMitarbeiter_ID'); }
    public set FixerMitarbeiter_ID(value: string) { this.setModelId('FixerMitarbeiter_ID', value); }

    @Expose()
    public get vonStatusWorkflowDef_ID(): string { return this.getModelId('vonStatusWorkflowDef_ID'); }
    public set vonStatusWorkflowDef_ID(value: string) { this.setModelId('vonStatusWorkflowDef_ID', value); }

    @Expose()
    public get vonSqlJobDefCheck_ID(): string { return this.getModelId('vonSqlJobDefCheck_ID'); }
    public set vonSqlJobDefCheck_ID(value: string) { this.setModelId('vonSqlJobDefCheck_ID', value); }

    @Expose()
    public get UebergangValidieren_ID(): string { return this.getModelId('UebergangValidieren_ID'); }
    public set UebergangValidieren_ID(value: string) { this.setModelId('UebergangValidieren_ID', value); }

    @Expose()
    public get UebergangGenerieren_ID(): string { return this.getModelId('UebergangGenerieren_ID'); }
    public set UebergangGenerieren_ID(value: string) { this.setModelId('UebergangGenerieren_ID', value); }

    @Expose()
    public get Task_ID(): string { return this.getModelId('Task_ID'); }
    public set Task_ID(value: string) { this.setModelId('Task_ID', value); }

    @Expose()
    @Type(() => ListType)
    public get BemerkungEinblenden(): ListType { return this.getProperty('BemerkungEinblenden'); }
    public set BemerkungEinblenden(value: ListType) { this.setProperty('BemerkungEinblenden', value); }

    @Expose()
    @Type(() => ListType)
    public get BemerkungZwingend(): ListType { return this.getProperty('BemerkungZwingend'); }
    public set BemerkungZwingend(value: ListType) { this.setProperty('BemerkungZwingend', value); }

    @Expose()
    @Type(() => ListType)
    public get KommentarEinblenden(): ListType { return this.getProperty('KommentarEinblenden'); }
    public set KommentarEinblenden(value: ListType) { this.setProperty('KommentarEinblenden', value); }

    @Expose()
    @Type(() => ListType)
    public get AllowChangeAssignedTo(): ListType { return this.getProperty('AllowChangeAssignedTo'); }
    public set AllowChangeAssignedTo(value: ListType) { this.setProperty('AllowChangeAssignedTo', value); }

    @Expose()
    @Type(() => ListType)
    public get AssignedTo(): ListType { return this.getProperty('AssignedTo'); }
    public set AssignedTo(value: ListType) { this.setProperty('AssignedTo', value); }

    @Expose()
    @Type(() => ListType)
    public get AssignedToContext1(): ListType { return this.getProperty('AssignedToContext1'); }
    public set AssignedToContext1(value: ListType) { this.setProperty('AssignedToContext1', value); }

    @Expose()
    @Type(() => ListType)
    public get AutoAusfuehren(): ListType { return this.getProperty('AutoAusfuehren'); }
    public set AutoAusfuehren(value: ListType) { this.setProperty('AutoAusfuehren', value); }

    @Expose()
    @Type(() => ListType)
    public get AutoAusfuehrenNachFrist(): ListType { return this.getProperty('AutoAusfuehrenNachFrist'); }
    public set AutoAusfuehrenNachFrist(value: ListType) { this.setProperty('AutoAusfuehrenNachFrist', value); }

    @Expose()
    @Type(() => ListType)
    public get Vollstaendigkeitspruefung(): ListType { return this.getProperty('Vollstaendigkeitspruefung'); }
    public set Vollstaendigkeitspruefung(value: ListType) { this.setProperty('Vollstaendigkeitspruefung', value); }

    @Expose()
    @Type(() => ListType)
    public get FristSetzen(): ListType { return this.getProperty('FristSetzen'); }
    public set FristSetzen(value: ListType) { this.setProperty('FristSetzen', value); }

    @Expose()
    @Type(() => ListType)
    public get FristBearbeitbar(): ListType { return this.getProperty('FristBearbeitbar'); }
    public set FristBearbeitbar(value: ListType) { this.setProperty('FristBearbeitbar', value); }

    @Expose()
    public get FristTage(): number { return this.getProperty('FristTage'); }
    public set FristTage(value: number) { this.setProperty('FristTage', value); }

    @Expose()
    public get KulanzFristTage(): number { return this.getProperty('KulanzFristTage'); }
    public set KulanzFristTage(value: number) { this.setProperty('KulanzFristTage', value); }

    @Expose()
    public get Icon(): string { return this.getProperty('Icon'); }
    public set Icon(value: string) { this.setProperty('Icon', value); }

    @Expose()
    public get StyleClass(): string { return this.getProperty('StyleClass'); }
    public set StyleClass(value: string) { this.setProperty('StyleClass', value); }

    @Expose()
    public get Sorter(): number { return this.getProperty('Sorter'); }
    public set Sorter(value:number) { this.setProperty('Sorter', value); }
}
