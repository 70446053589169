import { Component, ViewChild, OnInit, AfterViewInit, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AviCommonService, AviBaseSearcherComponent, AviApiService, AviPartnerListDetailConst, AviCommunicationService, AviSearcherColumn } from '@avi-x/avi-core';

@Component({
    selector: 'avi-crm-bankzeichnungsrecht-liste',
    templateUrl: './bankzeichnungsrecht-liste.component.html'
})
export class AviBankZeichnungsrechtListeComponent implements OnInit {
    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    private _bvId: string = null;
    @Input('bankverbindung-id')
    public set bvId(id: string) {
        this._bvId = id;
        this.searcher.doSearch();
    }
    public get bvId(): string {
        return this._bvId;
    }

    public DetailModelId: string = null;

    constructor(
        public apiService: AviApiService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public commonService: AviCommonService,
        private communicationService: AviCommunicationService
    ) {
    }

    public getRowGroupHeader(row: any, searcher: any): string {
        return `<strong>${row['ZRDefBez']}</strong>`;
    }

    public onInit(data) {
        this.searcher.beginInit();
        this.searcher.addTextColumn('ZRDefBez', 'Zeichnungsrecht').setVisible(false);
        this.searcher.addDateColumn('ZRGueltigAb', 'Gültig ab').setSortable(false);
        this.searcher.addDateColumn('ZRGueltigBis', 'Gültig bis').setSortable(false);
        this.searcher.addDecimalColumn('BetragVon', 'Betrag von', 2, 2).setSortable(false);
        this.searcher.addDecimalColumn('BetragBis', 'Betrag bis', 2, 2).setSortable(false);
        this.searcher.addTextColumn('Kommentar', 'Kommentar').setSortable(false);
        this.searcher.addIntegerColumn('Sorter', 'Rang').setSortable(false);

        this.searcher.addColumn(
            AviSearcherColumn.CreateCustom(
                'ZB',
                'Zeichnungsberechtigte(n)',
                (row, col) => {
                    return row['ZB'].replace(/(?:\r\n|\r|\n)/g, '<br>');
                },
                false,
                '45px'
            ).setSortable(false));

        this.searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string) => { return this.doSearch(); };

    doSearch(): Promise<any> {
        let url = `zeichnungsrecht/GetBankZeichnungsrechte/${this.bvId}`;
        if (this.searcher.showInaktive)
            url = `${url}?filterAktiv=false`;

        return this.apiService.get(url);
    }

    onSelect(data) {
        if (data && data[0])
            this.DetailModelId = data[0].Id;
    }

    ngOnInit() {
    }

    public getFilters: any = (): string[] => {
        let res = [];
        if (this.bvId != null)
            res = [...res, `vonBankVerbindung_ID eq ${this.bvId}`];

        if (!this.searcher.showInaktive)
            res = [...res, 'GueltigAb le today', 'GueltigBis ge today'];

        return res;
    }
}
