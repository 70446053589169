<div *ngIf="Model" class="p-2 grid">
    <div class="col-12 lg:col-6 p-2">
        <div class="col-12 lg:col-12 p-0 pb-3">
            <avi-core-view-panel [no-pad]="true" [header]="">
                <div class="p-3 grid">
                    <div class="p-0 mb-1 col-12 md:col-12 summary-header">{{ Model.Name }}</div>
                    <div class="ml-2 grid col-12 md:col-12">
                        <div class="p-0 col-12 md:col-4 summary-label">Strasse und Hausnummer</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Strasse }} {{ Model.HausNr }}</div>
                        <div class="p-0 col-12 md:col-4 summary-label">PLZ Ort</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.PLZ }} {{ Model.Ort }}</div>
                        <div class="p-0 col-12 md:col-4 summary-label">Land</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Land }}</div>
                    </div>

                    <div class="p-0 mb-1 col-12 md:col-12 summary-header mt-2">Details</div>
                    <div class="ml-2 grid col-12 md:col-12">
                        <div class="p-0 col-12 md:col-4 summary-label">Partnernummer</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.PartnerNr }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Rechtsform</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Rechtsform }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Ausgestaltung</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Ausgestaltung.Bezeichnung }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Gründungsdatum</div>
                        <div class="p-0 mb-1 col-12 md:col-8">
                            {{ Model.Gruendungsdatum | dateFormat: 'dd.MM.yyyy' }}
                        </div>

                        <div *ngIf="Model.Loeschdatum" class="p-0 col-12 md:col-4 summary-label">Löschdatum</div>
                        <div *ngIf="Model.Loeschdatum" class="p-0 mb-1 col-12 md:col-8">
                            {{ Model.Loeschdatum | dateFormat: 'dd.MM.yyyy' }}
                        </div>

                        <div *ngIf="Model.Kapital && Model.KapitalWaehrung" class="p-0 col-12 md:col-4 summary-label">
                            Kapital
                        </div>
                    <div *ngIf="Model.Kapital && Model.KapitalWaehrung" class="p-0 mb-1 col-12 md:col-8">
                            {{ Model.KapitalWaehrung }} {{ Model.Kapital | number: '1.2-2' }}
                        </div>

                        <div *ngIf="Model.VerwaltungLand" class="p-0 col-12 md:col-4 summary-label">
                            Land der Verwaltung
                        </div>
                        <div *ngIf="Model.VerwaltungLand" class="p-0 mb-1 col-12 md:col-8">
                            {{ Model.VerwaltungLand }}
                        </div>

                        <div class="p-0 col-12 md:col-4 summary-label">Diskretionaer</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Diskretionaer.Bezeichnung }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Klassifizierung</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Klassifizierung.Bezeichnung }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Steuerstatus</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Steuerstatus }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Sprache</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Sprache }}</div>
                    </div>

                    <div
                        *ngIf="Model.HRNr || Model.HROrt || Model.LEI || Model.MWStNr"
                        class="p-0 mb-1 col-12 md:col-12 summary-header mt-2"
                    >
                        Identifikationsnummern
                    </div>
                    <div
                        *ngIf="Model.HRNr || Model.HROrt || Model.LEI || Model.MWStNr"
                        class="ml-2 grid col-12 md:col-12"
                    >
                        <div *ngIf="Model.HRNr" class="p-0 col-12 md:col-4 summary-label">Handelsregisternummer</div>
                        <div *ngIf="Model.HRNr" class="p-0 mb-1 col-12 md:col-8">
                            <span>{{ Model.HRNr }}</span
                            ><span *ngIf="Model.HRDatum && Model.HRDatum !== '1753-01-01T00:00:00'">
                                ({{ Model.HRDatum | dateFormat: 'dd.MM.yyyy' }})</span
                            >
                        </div>

                        <div *ngIf="Model.HROrt" class="p-0 col-12 md:col-4 summary-label">Registerort</div>
                        <div *ngIf="Model.HROrt" class="p-0 mb-1 col-12 md:col-8">{{ Model.HROrt }}</div>

                        <div *ngIf="Model.LEI" class="p-0 col-12 md:col-4 summary-label">LEI</div>
                        <div *ngIf="Model.LEI" class="p-0 mb-1 col-12 md:col-8">
                            <span>{{ Model.LEI }}</span
                            ><span *ngIf="Model.LEIDatum && Model.LEIDatum !== '1753-01-01T00:00:00'">
                                ({{ Model.LEIDatum | dateFormat: 'dd.MM.yyyy' }})</span
                            >
                        </div>

                        <div *ngIf="Model.MWStNr" class="p-0 col-12 md:col-4 summary-label">MwStNr.</div>
                        <div *ngIf="Model.MWStNr" class="p-0 mb-1 col-12 md:col-8">
                            <span>{{ Model.MWStNr }}</span
                            ><span *ngIf="Model.MWStNrDatum && Model.MWStNrDatum !== '1753-01-01T00:00:00'">
                                ({{ Model.MWStNrDatum | dateFormat: 'dd.MM.yyyy' }})</span
                            >
                        </div>
                    </div>
                </div>
            </avi-core-view-panel>
        </div>

        <div class="col-12 lg:col-12 p-0">
            <avi-core-view-panel [no-pad]="true" [header]="">
                <div class="p-3 grid">
                    <div class="p-0 mb-1 col-12 md:col-12 summary-header">Steuerliche Informationen</div>
                    <div class="ml-2 grid col-12 md:col-12">
                        <div class="p-0 col-12 md:col-4 summary-label">Steuerliche Ansässigkeit</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ getSteuerDomizile() }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Selbstauskunft</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ getSelbstauskuenfte() }}</div>
                    </div>

                    <div
                        *ngIf="Model.Steuernummern && Model.Steuernummern.length > 0"
                        class="p-0 mb-1 col-12 md:col-12 summary-header mt-2"
                    >
                        Steuernummern
                    </div>
                    <div
                        *ngIf="Model.Steuernummern && Model.Steuernummern.length > 0"
                        class="ml-2 grid col-12 md:col-12"
                    >
                        <ng-container *ngFor="let komm of Model.Steuernummern">
                            <div class="p-0 col-12 md:col-4 summary-label">
                                {{ komm.Land }} ({{ komm.SteuernummerDef }})
                            </div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ komm.Steuernummer }}</div>
                        </ng-container>
                    </div>
                </div>
            </avi-core-view-panel>
        </div>
    </div>

    <div class="col-12 lg:col-6 p-2">
        <div *ngIf="Model.Kommunikationen && Model.Kommunikationen.length > 0" class="col-12 lg:col-12 p-0 pb-3">
            <avi-core-view-panel [no-pad]="true" [header]="">
                <div class="p-3 grid">
                    <div class="p-0 mb-1 col-12 md:col-12 summary-header">Kommunikation</div>
                    <div class="ml-2 grid col-12 md:col-12">
                        <ng-container *ngFor="let komm of Model.Kommunikationen">
                            <div class="p-0 col-12 md:col-4 summary-label">
                                {{ komm.KommArt.Bezeichnung }} {{ komm.KommTyp.Bezeichnung }}
                            </div>
                            <div class="p-0 mb-0 col-12 md:col-8">
                                <a *ngIf="isHomepage(komm)" (click)="openHomepage(komm.Eingabe)" class="p-0 mr-4p"
                                    ><i class="material-icons" style="color: #666;">home</i></a
                                >
                                <a *ngIf="isTelefonnummer(komm)" (click)="click2Call(komm.Eingabe)" class="p-0 mr-4p"
                                    ><i class="material-icons searcher-icon" style="color: #666;">call</i></a
                                >
                                <a *ngIf="isEmail(komm)" (click)="click2Email(komm.Eingabe)" class="p-0 mr-4p"
                                    ><i class="material-icons searcher-icon" style="color: #666;">email</i></a
                                >
                                {{ komm.Eingabe }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </avi-core-view-panel>
        </div>

        <div *ngIf="WCKInfo || ActivityInfo" class="col-12 lg:col-12 p-0">
            <avi-core-view-panel [no-pad]="true" [header]="">
                <div class="p-3 grid">
                    <div *ngIf="WCKInfo" class="p-0 mb-1 col-12 md:col-12 summary-header">Worldcheck</div>
                    <div *ngIf="WCKInfo" class="ml-2 grid col-12 md:col-12">
                        <div class="p-0 mb-1 col-12 md:col-12" [ngClass]="{ 'color-red': WCKInfo.OpenWCKTreffer > 0 }">
                            Es gibt {{ WCKInfo.OpenWCKTreffer }}
                            {{ WCKInfo.OpenWCKTreffer == 1 ? 'offener Treffer' : 'offene Treffer' }}
                        </div>
                        <div
                            class="p-0 mb-1 col-12 md:col-12"
                            [ngClass]="{ 'color-red': WCKInfo.ClosedWCKTrefferPersonIdentisch > 0 }"
                        >
                            Es gibt {{ WCKInfo.ClosedWCKTrefferPersonIdentisch }}
                            {{
                                WCKInfo.ClosedWCKTrefferPersonIdentisch == 1
                                    ? 'erledigter Treffer mit Person identisch'
                                    : 'erledigte Treffer mit Person identisch'
                            }}
                        </div>
                        <div
                            class="p-0 mb-1 col-12 md:col-12"
                            [ngClass]="{ 'color-red': WCKInfo.ClosedWCKTrefferPersonNichtIdentisch > 0 }"
                        >
                            Es gibt {{ WCKInfo.ClosedWCKTrefferPersonNichtIdentisch }}
                            {{
                                WCKInfo.ClosedWCKTrefferPersonIdentisch == 1
                                    ? 'erledigter Treffer mit Person nicht identisch'
                                    : 'erledigte Treffer mit Person nicht identisch'
                            }}
                        </div>
                        <div *ngIf="WCKInfo.LetztePruefung" class="p-0 mb-1 col-12 md:col-12">
                            Letzte WCK Prüfung am {{ WCKInfo.LetztePruefung | dateFormat: 'dd.MM.yyyy' }}
                        </div>
                    </div>

                    <div *ngIf="ActivityInfo" class="p-0 mb-1 col-12 md:col-12 summary-header mt-2">Aktivitäten</div>
                    <div *ngIf="ActivityInfo" class="ml-2 grid col-12 md:col-12">
                        <a
                            class="p-0 mb-1 col-12 md:col-12"
                            [ngClass]="{ 'color-red': ActivityInfo.OpenActivities > 0 }"
                            [routerLink]="['/crm/jurperson/index/', Model.Id, 'activity']"
                            title="Aktivitäten"
                        >
                            Es gibt {{ ActivityInfo.OpenActivities }}
                            {{ ActivityInfo.OpenActivities == 1 ? 'offene Aktivität' : 'offene Aktivitäten' }}
                        </a>
                    </div>
                </div>
            </avi-core-view-panel>
        </div>
    </div>
</div>
