<div *ngIf="Antrag">

    <div class="grid justify-content-end m-0">
        <!-- Rückgängig machen Tasks -->
        <button
            *ngIf="enableTaskRechtstraegerInformierenRueckgaengig()"
            pButton
            type="button"
            icon="pi pi-undo"
            [label]="'VWBP.INTERN.SCHWAERZEN.RECHSTRAEGER_INFORMIEREN_RUECKGAENGIG_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskRechtstraegerInformierenRueckgaengig()"
            class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
        ></button>
        <button
            *ngIf="enableTaskAntragRechtskraftSetzenRueckgaengig()"
            pButton
            type="button"
            icon="pi pi-undo"
            [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSKRAFT_RUECKGAENGIG_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskAntragRechtskraftSetzenRueckgaengig()"
            class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
        ></button>
        <!-- Tasks -->
        <button
            *ngIf="enableTaskKontrollieren()"
            pButton
            type="button"
            icon="pi pi-thumbs-up"
            [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_KONTROLLIEREN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskAntragKontrollieren()"
            class="w-100p-m m ml-2 p-button-primary"
        ></button>
        <button
            *ngIf="enableTaskWBNichtMehrVorhanden()"
            pButton
            type="button"
            icon="pi pi-times"
            [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_WB_NICHT_MEHR_VORHANDEN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskAntragWBNichtMehrVorhanden()"
            class="w-100p-m m ml-2 p-button-primary"
        ></button>
        <button
            *ngIf="enableTaskRechtskraftSetzen()"
            pButton
            type="button"
            icon="pi pi-star"
            [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSKRAFT_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskRechtskraftSetzen()"
            class="w-100p-m m ml-2 p-button-primary"
        ></button>
        <button
            *ngIf="enableTaskRechtsmittelfristSetzen()"
            pButton
            type="button"
            icon="pi pi-clock"
            [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSMITTELFRIST_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskRechtsmittelfristSetzen()"
            class="w-100p-m m ml-2 p-button-primary"
        ></button>
        <button
            *ngIf="enableTaskGueltigkeitSetzen()"
            pButton
            type="button"
            icon="pi pi-clock"
            [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_GUELTIGKEIT_SETZEN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskGueltigkeitSetzen()"
            class="w-100p-m m ml-2 p-button-primary"
        ></button>
        <button
            *ngIf="enableTaskAntragBewilligen()"
            pButton
            type="button"
            icon="pi pi-thumbs-up"
            [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskAntragBewilligen()"
            class="w-100p-m m ml-2 p-button-primary"
        ></button>
        <button
            *ngIf="enableTaskAntragAblehnen()"
            pButton
            type="button"
            icon="pi pi-times"
            [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_ABLEHNEN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskAntragAblehnen()"
            class="w-100p-m m ml-2 p-button-danger"
        ></button>
    </div>
</div>

<avi-core-dialog #taskDialog identifier="taskDialog" [showCloseIcon]="false" [closeOnEscape]="false">
    <vwbp-intern-schwaerzen-antrag-bewilligen-task
        *ngIf="Modus === SchwaerzenTaskMode.ANTRAG_BEWILLIGEN"
        [selected-rechtstraeger]="selectedRT"
        [neu-setzen]="getNeuEntscheidung()"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-schwaerzen-antrag-bewilligen-task>
    <vwbp-intern-schwaerzen-antrag-ablehnen-task
        *ngIf="Modus === SchwaerzenTaskMode.ANTRAG_ABLEHNEN"
        [selected-rechtstraeger]="selectedRT"
        [neu-setzen]="getNeuEntscheidung()"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-schwaerzen-antrag-ablehnen-task>
    <schwaerzen-antrag-wbnichtmehrvorhanden-task
        *ngIf="Modus === SchwaerzenTaskMode.ANTRAG_WB_NICHT_MEHR_VORHANDEN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></schwaerzen-antrag-wbnichtmehrvorhanden-task>
    <vwbp-intern-schwaerzen-informieren-task
        *ngIf="Modus === SchwaerzenTaskMode.RECHTSTRAEGER_INFORMIEREN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-schwaerzen-informieren-task>
    <vwbp-intern-schwaerzen-antrag-rechtsmittelfrist-anpassen-task
        *ngIf="Modus === SchwaerzenTaskMode.ANTRAG_RECHTSMITTELFRIST_SETZEN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-schwaerzen-antrag-rechtsmittelfrist-anpassen-task>
    <vwbp-intern-schwaerzen-antrag-rechtskraft-setzen-task
        *ngIf="Modus === SchwaerzenTaskMode.ANTRAG_RECHTSKRAFT_SETZEN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-schwaerzen-antrag-rechtskraft-setzen-task>
    <vwbp-intern-schwaerzen-antrag-gueltigkeit-setzen-task
        *ngIf="Modus === SchwaerzenTaskMode.ANTRAG_GUELTIGKEIT_SETZEN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        [taskId]="getGueltigSetzenTaskId()"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-schwaerzen-antrag-gueltigkeit-setzen-task>

    <!-- Rückgängig machen Tasks -->
    <vwbp-intern-schwaerzen-antrag-rueckgaengig-task
        *ngIf="Modus === SchwaerzenTaskMode.RUECKGAENGIG"
        [modus]="RueckgaengigMode"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-schwaerzen-antrag-rueckgaengig-task>

</avi-core-dialog>

<avi-core-dialog
    #AntragFormDialog
    identifier="AntragFormDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
>
    <vwbp-intern-schwaerzen-antrag-form
        #AntragForm
        [contents-padding]="false"
        (onSavedSuccessful)="onAntragSavedSuccessful($event)"
        [action-buttons]="formActionButtons"
        [action-buttons-readonly]="formActionButtonsReadonly"
        [formular-daten]="FormularDaten"
    >
    </vwbp-intern-schwaerzen-antrag-form>
</avi-core-dialog>
