import { Injectable } from '@angular/core';
import { AviListDetailConst } from '../shared/constants/constants';

@Injectable()
export class AviRoundingService {
    private readonly RundungsTeiler: Map<string, number> = new Map([
        [AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_0, 1],
        [AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_1, 10],
        [AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_5, 20],
        [AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_10, 10],
        [AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_20, 5],
        [AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_50, 2],
        [AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_100, 1],
    ]);

    constructor() {}

    public RoundAwayFromZero(startValue: number, digits: number): number {
        var decimalValue = 0;
        digits = digits || 0;
        startValue *= /*parseFloat(*/ Math.pow(10, digits + 1);
        decimalValue = /*parseInt(*/ Math.floor(startValue) - Math.floor(startValue / 10) * 10;
        startValue = Math.floor(startValue / 10);
        if (decimalValue >= 5) {
            startValue += 1;
        }
        startValue /= /*parseFloat(*/ Math.pow(10, digits);
        return startValue;
    }

    public RoundValue(value: number, DecStellen: number, rundungsRegel: string, rundungsgenauigkeit: string): number {
        if (value == null) return null;
        
        if (rundungsgenauigkeit == AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_15 ||
            rundungsgenauigkeit == AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_30 ||
            rundungsgenauigkeit == AviListDetailConst.CAF_RUNDUNGSGENAUIGKEIT_60) {
            throw new Error('Rundungsgenauigkeit 15, 30 und 60 ist nur bei Aufwand (Zeit) anwendbar!');
        }

        const teiler = this.RundungsTeiler.get(rundungsgenauigkeit);
        value = this.RoundAwayFromZero(value, DecStellen);
        value = value * teiler;
        if (rundungsRegel == AviListDetailConst.CAF_RUNDUNGSREGEL_KAUFM_RUNDEN) {
            value = this.RoundAwayFromZero(value, 0);
        } else if (rundungsRegel == AviListDetailConst.CAF_RUNDUNGSREGEL_AUFRUNDEN) {
            if (value >= 0) 
                value = Math.ceil(value);
            else
                value = Math.floor(value);
        } else if (rundungsRegel == AviListDetailConst.CAF_RUNDUNGSREGEL_ABRUNDEN) {
            if (value >= 0)
                value = Math.floor(value);
            else
                value = Math.ceil(value);
        }
        value = value / teiler;
        return value;
    }
}
