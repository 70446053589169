import { AviQueryDefExecutionSearcherModule } from './../querydef-exec-searcher/querydef-exec-searcher.module';
import { AviQueryDefExecutionGraphModule } from './../querydef-exec-graph/querydef-exec-graph.module';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AviInfoDeskComponent } from './components/infodesk-exec/infodesk-exec.component';
import { AviCoreModule } from '@avi-x/avi-core';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GridsterModule } from 'angular-gridster2';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        NgxChartsModule,
        GridsterModule,
        MatIconModule,

        AviCoreModule,
        AviQueryDefExecutionGraphModule,
        AviQueryDefExecutionSearcherModule
    ],
    exports: [
        AviInfoDeskComponent,
        RouterModule
    ],
    declarations: [
        AviInfoDeskComponent
    ],
})
export class AviInfoDeskExecutionModule { }
