import { AviApiService } from '@avi-x/avi-core';
import { get } from 'lodash-es';
import { MenuItem } from 'primeng/api';

export enum AviSearcherColumnType {
    // eslint-disable-next-line no-unused-vars
    TEXT, DATE, LISTTYPE, INTEGER, DECIMAL, PERCENT, TELEFONNR, EMAIL, CUSTOM, BUTTON, SPLITBUTTON, BOOLEAN
}

export enum AviSearcherButtonType {
    // eslint-disable-next-line no-unused-vars
    BUTTON, BADGE
}

export class AviSearcherColumn {
    public get key(): string { return this.Field; }
    public Field: string;
    public OField: string;
    public Label: string;
    public LabelTrans: string;
    public SortColumn: string;
    public Type: AviSearcherColumnType = AviSearcherColumnType.TEXT;

    //z.B für Tasks
    public IsSystem: boolean = false;

    public DateFormat: string;
    public Listname: string;

    public Description?: string;

    public Width: string | number = null;

    public Filterable = false;
    public MultiSelectionFilter = true;
    public Sortable = true;
    public Visible = true;

    public MinValue: number = null;
    public MaxValue: number = null;
    public MinNachkommastellen: number = 2;
    public MaxNachkommastellen: number = 2;

    public TriStateBoolean: boolean = false;

    public apiService: AviApiService;

    public DropdownDatasource: any = null;
    public DropdownDatasourceInternal: any = [];

    public DropdownPlaceholder = '...';
    public DropdownFilterable = false;

    public DropdownDisplayField: any = null;
    public DropdownValueField: any = null;
    public DropdownHiddenValues: string[] = null;

    public ShowOnMobile: boolean = true;

    public FormatDelegate: Function = null; // (value: any, row: any, col: any) => { return value; };
    public FormatTitleDelegate: Function = null;
    public CellStyleDelegate: Function = null; // (value: any, row: any, col: any) => { return value; };

    public ButtonOnClickDelegate: Function = null;
    public ButtonTextDelegate: Function = null;
    public ButtonClassesDelegate: Function = null;
    public ButtonIconDelegate: Function = null;
    public ButtonValueDelegate: Function = null;
    public ButtonIds: string[] = null;
    public ButtonTypes: AviSearcherButtonType[] = null;

    public SplitButtonMenuItems: any = [];
    public SplitButtonOnClickDelegate: Function = null;

    public HeaderColumnClass: string = null;
    public ColumnClass: string = null;

    private _FilterType: AviSearcherColumnType = null;
    public get FilterType(): AviSearcherColumnType {
        return this._FilterType ?? this.Type;
    }

    public set FilterType(filterType: AviSearcherColumnType) {
        this._FilterType = filterType;
    }

    public TooltipDelegate: Function = null;
    public TooltipStyleClass: string = null;
    public DisabledDelegate: Function = null;

    public static CreateInteger(field: string, label: string = null, minValue: number = null, maxValue: number = null, filterable: boolean = false, apiService: AviApiService = null, dataSource: any = null, displayField: any = null, valueField: any = null, width: string = null) {
        const col = AviSearcherColumn.CreateColumn(AviSearcherColumnType.INTEGER, field, label, filterable, apiService, dataSource, displayField, valueField, width);
        col.MinValue = minValue;
        col.MaxValue = maxValue;
        return col;
    }

    public static CreateDecimal(field: string, label: string = null, minNachkommastellen: number = 2, maxNachkommastellen: number = 2, minValue: number = null, maxValue: number = null, filterable: boolean = false, apiService: AviApiService = null, dataSource: any = null, displayField: any = null, valueField: any = null, width: string = null) {
        const col = AviSearcherColumn.CreateColumn(AviSearcherColumnType.DECIMAL, field, label, filterable, apiService, dataSource, displayField, valueField, width);
        col.MinNachkommastellen = minNachkommastellen || 2;
        col.MaxNachkommastellen = maxNachkommastellen || 2;
        col.MinValue = minValue;
        col.MaxValue = maxValue;
        return col;
    }

    public static CreatePercentage(field: string, label: string = null, minNachkommastellen: number = 2, maxNachkommastellen: number = 2, minValue: number = null, maxValue: number = null, filterable: boolean = false, apiService: AviApiService = null, dataSource: any = null, displayField: any = null, valueField: any = null, width: string = null) {
        const col = AviSearcherColumn.CreateColumn(AviSearcherColumnType.PERCENT, field, label, filterable, apiService, dataSource, displayField, valueField, width);
        col.MinNachkommastellen = minNachkommastellen || 2;
        col.MaxNachkommastellen = maxNachkommastellen || 2;
        col.MinValue = minValue;
        col.MaxValue = maxValue;
        return col;
    }

    public static CreateText(field: string, label: string = null, filterable: boolean = false, apiService: AviApiService = null, dataSource: any = null, displayField: any = null, valueField: any = null, width: string = null) {
        return AviSearcherColumn.CreateColumn(AviSearcherColumnType.TEXT, field, label, filterable, apiService, dataSource, displayField, valueField, width);
    }

    public static CreateBoolean(field: string, label: string = null, tristate: boolean = false, filterable: boolean = false, apiService: AviApiService = null, dataSource: any = null, displayField: any = null, valueField: any = null, width: string = null) {
        const col = AviSearcherColumn.CreateColumn(AviSearcherColumnType.BOOLEAN, field, label, filterable, apiService, dataSource, displayField, valueField, width);
        col.TriStateBoolean = tristate;
        return col;
    }

    private static CreateColumn(type: AviSearcherColumnType, field: string, label: string = null, filterable: boolean = false, apiService: AviApiService = null, dataSource: any = null, displayField: any = null, valueField: any = null, width: string = null) {
        const col = new AviSearcherColumn(field, label, type, filterable, width);
        col.apiService = apiService;
        if (dataSource) {
            col.DropdownPlaceholder = 'Alle';
            col.DropdownDatasource = dataSource;
            col.DropdownDisplayField = displayField;
            col.DropdownValueField = valueField;
            col.BuildDropdownDatasource();
        }
        return col;
    }

    public static CreateDate(field: string, label: string = null, dateFormat: string = 'mediumDate', filterable: boolean = false, width: string = null) {
        const col = new AviSearcherColumn(field, label, AviSearcherColumnType.DATE, filterable, width);
        col.DateFormat = dateFormat;
        return col;
    }

    public static CreateListType(field: string, label: string = null, listName: string, filterOnId: boolean = true, apiService: AviApiService = null, filterable: boolean = false, width: string = null, apiPrefix: string = null, hiddenListItems: string[] = null) {
        const col = new AviSearcherColumn(field, label, AviSearcherColumnType.LISTTYPE, filterable, width);
        col.Listname = listName;
        col.Field = `${col.Field}`;
        if (filterable && listName) {
            col.apiService = apiService;
            col.DropdownPlaceholder = 'Alle';
            // col.DropdownDatasource = `  listdetaillink?filter=listRef.name eq ${listName}&select=ListDetailRef.Id,ListDetailRef.Bezeichnung1`;
            // col.DropdownDisplayField = 'ListDetailRefBezeichnung1';
            // col.DropdownValueField = filterOnId ? 'ListDetailRefId' : col.DropdownDisplayField;

            col.DropdownDatasource = `model/representations/list/${listName}`;
            if (apiPrefix)
                col.DropdownDatasource = apiPrefix + (apiPrefix.endsWith('/') ? '' : '/') + col.DropdownDatasource;

            col.DropdownDisplayField = 'Representation';
            col.DropdownValueField = filterOnId ? 'Id' : col.DropdownDisplayField;
            col.DropdownHiddenValues = hiddenListItems;
            col.BuildDropdownDatasource();
        }

        return col;
    }

    public static CreateCustom(field: string, label: string = null, formatDelegate: Function = null, filterable: boolean = false, width: string = null) {
        const col = new AviSearcherColumn(field, label, AviSearcherColumnType.CUSTOM, filterable, width);
        if (formatDelegate)
            col.FormatDelegate = formatDelegate;
        return col;
    }

    public static CreateButton(name: string, label: string = null, onClickDelegate: Function = null, textDelegate: Function = null, buttonClassesDelegate: Function = null, buttonIconDelegate: Function = null, width: string = null, buttonIds: string[] = ['btn0'], buttonTypes: AviSearcherButtonType[] = null, buttonValueDelegate: Function = null) {
        const col = new AviSearcherColumn(name, label, AviSearcherColumnType.BUTTON, false, width);
        col.ButtonOnClickDelegate = onClickDelegate;
        col.ButtonTextDelegate = textDelegate;
        col.ButtonClassesDelegate = buttonClassesDelegate;
        col.ButtonIconDelegate = buttonIconDelegate;
        col.ButtonIds = buttonIds;
        col.ButtonTypes = buttonTypes;
        col.ButtonValueDelegate = buttonValueDelegate;
        col.IsSystem = true;
        return col;
    }

    public static CreateSplitButton(name: string, label: string, width: string = null, textDelegate: Function = null, buttonClassesDelegate: Function = null, buttonIconDelegate: Function = null, onClickDelegate: Function = null) {
        const col = new AviSearcherColumn(name, label, AviSearcherColumnType.SPLITBUTTON, false, width);
        col.ButtonOnClickDelegate = onClickDelegate;
        col.ButtonClassesDelegate = buttonClassesDelegate;
        col.ButtonIconDelegate = buttonIconDelegate;
        col.ButtonTextDelegate = textDelegate;
        col.SplitButtonMenuItems = [];
        return col;
    }

    
    private getSelectProjection(select: string)
    {
        let result = select;

        const si = select.indexOf("[");
        const se = select.indexOf("]");
        if (si > 0 && se > 0)
            result = select.substring(0, si) + select.substring(se + 1);

        return result.replace(/\./g, '');
    }

    public constructor(field: string, label: string = null, type: AviSearcherColumnType = AviSearcherColumnType.TEXT, filterable: boolean = false, width: string = null) {
        this.OField = field;
        this.Field = this.getSelectProjection(field);
        this.Label = label ? label : `{${this.OField}}`;
        this.LabelTrans = this.Label;
        this.Type = type;
        this.Filterable = filterable;
        this.Width = width;
    }

    public stripHtmlTags(str: string): string {
        return String(str)?.replace(/<([^>]+)>/g, '');
    }

    public FormatCustomTitle(row: any) {
        if (this.FormatTitleDelegate)
            return this.FormatTitleDelegate(row, this);
        if (this.FormatDelegate)
            return this.stripHtmlTags(this.FormatDelegate(row, this));
        else
            return {};
    }

    public FormatCustom(row: any) {
        if (this.FormatDelegate)
            return this.FormatDelegate(row, this);
        else
            return {};
    }

    public OnClickButton(row: any, id) {
        if (this.ButtonOnClickDelegate)
            return this.ButtonOnClickDelegate(row, this, id);
    }

    public OnClickSplitButton(row: any, items, menu: any) {
        if (this.SplitButtonOnClickDelegate)
            return this.SplitButtonOnClickDelegate(row, this, items, menu);
    }

    public getButtonType(index) {
        const res = this.ButtonTypes ? this.ButtonTypes[index] : null;
        if (res)
            return res;

        return AviSearcherButtonType.BUTTON;
    }

    public getButtonValue(row: any, id) {
        if (this.ButtonValueDelegate)
            return this.ButtonValueDelegate(row, this, id);
        return null;
    }

    public getButtonText(row: any, id) {
        if (this.ButtonTextDelegate)
            return this.ButtonTextDelegate(row, this, id);
        return null;
    }

    public getButtonClasses(row: any, id) {
        if (this.ButtonClassesDelegate)
            return this.ButtonClassesDelegate(row, this, id);
        return null;
    }

    public getButtonIcon(row: any, id) {
        if (this.ButtonIconDelegate)
            return this.ButtonIconDelegate(row, this, id);
        return null;
    }

    public getTooltip(row: any, id) {
        if (this.TooltipDelegate)
            return this.TooltipDelegate(row, this, id);
        return null;
    }

    public getTooltipStyleClass(row: any) {
        return this.TooltipStyleClass || null;
    }

    public getDisabled(row: any, id) {
        if (this.DisabledDelegate)
            return this.DisabledDelegate(row, this, id);
        return null;
    }

    public getCellStyle(row: any) {
        if (this.CellStyleDelegate)
            return this.CellStyleDelegate(row, this);
        else
            return {};
    }

    public setMinValue(value: number): AviSearcherColumn {
        this.MinValue = value;
        return this;
    }

    public setFilterable(value: boolean): AviSearcherColumn {
        this.Filterable = value;
        return this;
    }

    public setFormatDelegate(value: Function): AviSearcherColumn {
        this.FormatDelegate = value;
        return this;
    }

    public setFormatTitleDelegate(value: Function): AviSearcherColumn {
        this.FormatTitleDelegate = value;
        return this;
    }

    public setSortable(value: boolean): AviSearcherColumn {
        this.Sortable = value;
        return this;
    }

    public setHeaderColumnClass(value: string): AviSearcherColumn {
        this.HeaderColumnClass = value;
        return this;
    }

    public setColumnClass(value: string): AviSearcherColumn {
        this.ColumnClass = value;
        return this;
    }

    public setWidth(value: string | number): AviSearcherColumn {
        this.Width = value;
        return this;
    }

    public setVisible(value: boolean): AviSearcherColumn {
        this.Visible = value;
        return this;
    }

    public setSplitButtonMenuItems(items:MenuItem[]): AviSearcherColumn {
        this.SplitButtonMenuItems = items;
        return this;
    }

    public setSplitButtonOnClickDelegate(value: Function): AviSearcherColumn {
        this.SplitButtonOnClickDelegate = value;
        return this;
    }

    public setButtonOnClickDelegate(value: Function): AviSearcherColumn {
        this.ButtonOnClickDelegate = value;
        return this;
    }

    public setButtonValueDelegate(value: Function): AviSearcherColumn {
        this.ButtonValueDelegate = value;
        return this;
    }

    public setButtonTextDelegate(value: Function): AviSearcherColumn {
        this.ButtonTextDelegate = value;
        return this;
    }

    public setButtonClassesDelegate(value: Function): AviSearcherColumn {
        this.ButtonClassesDelegate = value;
        return this;
    }

    public setButtonIconDelegate(value: Function): AviSearcherColumn {
        this.ButtonIconDelegate = value;
        return this;
    }

    public setTooltipDelegate(value: Function): AviSearcherColumn {
        this.TooltipDelegate = value;
        return this;
    }

    public setTooltipStyleClass(classname: string): AviSearcherColumn {
        this.TooltipStyleClass = classname;
        return this;
    }

    public setDisabledDelegate(value: Function): AviSearcherColumn {
        this.DisabledDelegate = value;
        return this;
    }

    public setCellStyleDelegate(value: Function): AviSearcherColumn {
        this.CellStyleDelegate = value;
        return this;
    }

    public setDescription(value: string): AviSearcherColumn {
        this.Description = value;
        return this;
    }

    public hide(): AviSearcherColumn {
        this.Visible = false;
        return this;
    }

    private getDisplayField(element, displayField) {
        if (typeof displayField === 'string') {
            return get(element, displayField);
        } else if (typeof displayField === 'function') {
            return displayField(element);
        } else {
            return `Unknown Typ: ${typeof displayField}`;
        }
    }

    private getValueField(element, valueField) {
        if (typeof valueField === 'string') {
            return get(element, valueField);
        } else if (typeof valueField === 'function') {
            return valueField(element);
        } else {
            return `Unknown Typ: ${typeof valueField}`;
        }
    }

    public async BuildDropdownDatasource() {
        let ret = [];

        if (typeof this.DropdownDatasource === 'string') {
            ret = await this.BuildDropdownDatasourceFromApi(this.DropdownDatasource);
        } else if (typeof this.DropdownDatasource === 'function') {
            ret = await this.DropdownDatasource(this);
        } else if (this.DropdownDatasource instanceof Array) {
            this.DropdownDatasource.forEach(elem => {
                ret.push({ label: this.DropdownDisplayField ? this.getDisplayField(elem, this.DropdownDisplayField) : elem, value: this.DropdownValueField ? this.getValueField(elem, this.DropdownValueField) : elem });
            });
        }


        // ret.unshift({ label: this.DropdownPlaceholder, value: null });

        this.DropdownDatasourceInternal = ret;
    }

    private async BuildDropdownDatasourceFromApi(path: string): Promise<any[]> {
        const p = await this.apiService.get(path);
        const ret = [];
        p.forEach(elem => {
            const label = this.DropdownDisplayField ? this.getDisplayField(elem, this.DropdownDisplayField) : elem;
            const value = this.DropdownValueField ? this.getValueField(elem, this.DropdownValueField) : elem;
            if (!this.DropdownHiddenValues || !this.DropdownHiddenValues.find(w => w == value))
                ret.push({ label: label, value: value });
        });

        return ret;
    }
}
