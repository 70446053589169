<avi-core-sysmessage-monitor *ngIf="authService.IsLoggedIn" api-prefix="v1/vwbp"></avi-core-sysmessage-monitor>

<div
    class="layout-wrapper"
    [ngClass]="{
        'form-field-background': formFieldBackground,
        'p-input-filled': true, 'p-ripple-disabled': true,
        'layout-menu-static': false,
        'layout-menu-overlay': isOverlay(),
        'layout-menu-horizontal': isHorizontal(),
        'layout-menu-slim': false,
        'layout-menu-active': true,
        'layout-menu-mobile-active': true,
        'layout-topbar-mobile-active': true,
        'layout-rightmenu-active': true,
        'layout-rtl': false}"
    (click)="onLayoutClick()"
>
    <!-- <div
        #layoutContainer
        class="layout-container"
        [ngClass]="{
            'menu-layout-static': !isOverlay(),
            'menu-layout-overlay': isOverlay(),
            'layout-menu-overlay-active': overlayMenuActive,
            'menu-layout-horizontal': isHorizontal(),
            'menu-layout-slim': isSlim(),
            'layout-menu-static-inactive': staticMenuDesktopInactive,
            'layout-menu-static-active': staticMenuMobileActive
        }"
    >
        <avi-vwbp-topbar></avi-vwbp-topbar>

        <div class="layout-menu" [ngClass]="{ 'layout-menu-dark': darkMenu }" (click)="onMenuClick($event)">
            <p-scrollPanel #scrollPanel [style]="{ height: '100%' }">
                <avi-vwbp-inline-profile *ngIf="profileMode == 'inline' && !isHorizontal()"></avi-vwbp-inline-profile>
                <avi-vwbp-menu [reset]="resetMenu"></avi-vwbp-menu>
            </p-scrollPanel>
        </div>

        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>

        <avi-vwbp-rightpanel></avi-vwbp-rightpanel>
        <div class="layout-mask"></div>
    </div> -->

    <mat-sidenav-container class="sidenav-container">
        <!-- <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
            [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
            [opened]="!(isHandset$ | async)">
            <mat-toolbar>Menu</mat-toolbar>
            <mat-nav-list>
                <a mat-list-item routerLink="home">Home</a>
                <a mat-list-item routerLink="about">About</a>
            </mat-nav-list>
        </mat-sidenav> -->

        <mat-sidenav-content>
                <div *ngIf="authService.IsLoggedIn && vwbpCommonService.currentUserIsAjuUser()" class="current-user-info-box-small">
                    Version: <strong>v{{ appVersion.version }}</strong> | Build-Zeit:
                    <strong>{{ appVersion.buildDate }} {{ appVersion.buildTime }}</strong> | Angemeldet als:
                    <strong>{{ authService.CurrentUserName }}</strong>
                </div>

                <mat-toolbar color="primary" class="position-fixed">

                <mat-toolbar-row>
                    <!-- <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()"
                        *ngIf="!canNavigateBack() && isHandset$ | async">
                        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                    </button> -->
                    <a class="logoimage" (click)="goToHome()">
                        <img class="logoimage" src="../assets/logo/logo.svg" />
                    </a>
                    <div class="titel">{{ 'HOME.TITLE' | translate }}</div>

                    <!-- <span class="header-title">FineArts</span> -->
                    <span class="example-fill-remaining-space"></span>
                    <span class="example-spacer"></span>

                    <!-- User Menu -->
                    <div *ngIf="authService.IsLoggedIn">
                        <button
                            mat-icon-button
                            *ngIf="vwbpCommonService.currentUserIsAjuUser()"
                            pTooltip="Postfach"
                            (click)="OpenPostfach()"
                        >
                        <i class="pi pi-cloud-download mr-2"></i>
                        </button>
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <!-- <mat-icon>more_vert</mat-icon> -->
                            <i class="pi pi-ellipsis-v fs-large2"></i>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="goToKeycloakProfile()">
                                <i class="pi pi-lock mr-2"></i>
                                <span>Zugangsdaten</span>
                            </button>

                            <button
                                mat-menu-item
                                *ngIf="vwbpCommonService.currentUserIsEnduser()"
                                routerLink="/vwbp/profile"
                            >
                                <i class="pi pi-user mr-2"></i>
                                <span>Kontaktinformationen</span>
                            </button>

                            <button
                                mat-menu-item
                                *ngIf="vwbpCommonService.currentUserIsEnduser()"
                                routerLink="/vwbp/index"
                            >
                                <i class="pi pi-home mr-2"></i>
                                <span>VwB Home</span>
                            </button>

                            <button
                                mat-menu-item
                                *ngIf="vwbpCommonService.currentUserIsAjuUser()"
                                routerLink="/intern/vwbp/index"
                            >
                            <i class="pi pi-briefcase mr-2"></i>
                                <span>AJU Bereich</span>
                            </button>

                            <button
                                mat-menu-item
                                *ngIf="
                                    vwbpCommonService.currentUserIsBehoerdenUser() ||
                                    vwbpCommonService.currentUserIsAjuUser()
                                "
                                routerLink="/intern/vwbp/behoerden"
                            >
                                <i class="pi pi-users mr-2"></i>
                                <span>Behördenabfrage</span>
                            </button>

                            <button
                                mat-menu-item
                                *ngIf="vwbpCommonService.currentUserIsAdminUser()"
                                routerLink="/admin/vwbp"
                            >
                                <i class="pi pi-lock mr-2"></i>
                                <span>Admin Bereich</span>
                            </button>

                            <button *ngIf="authService.IsLoggedIn" mat-menu-item (click)="Logout()">
                                <i class="pi pi-sign-out mr-2"></i>
                                <span>Logout</span>
                            </button>
                        </mat-menu>
                    </div>
                </mat-toolbar-row>
            </mat-toolbar>
            <div class="content vwbp-router-outlet">
                <router-outlet></router-outlet>
                <avi-vwbp-footer *ngIf="vwbpCommonService.currentUserIsEnduser()"></avi-vwbp-footer>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
<!--
    <p-overlayPanel #op [showCloseIcon]="true" [dismissable]="false" [style]="{ width: '60%' }">
        <ng-template pTemplate>
            <vwbp-intern-postfach-searcher></vwbp-intern-postfach-searcher>
        </ng-template>
    </p-overlayPanel> -->

    <!-- <avi-core-full-dialog
        [(visible)]="testDialogVisible"
        [closable]="true"
        (onClose)="onDialogClose($event)"
        background-color="lime"
    >
        <h1>Login, Lockscreen oder sowas</h1>
    </avi-core-full-dialog> -->

    <div
        [ngClass]="{ 'global-searcher-bg': true, 'global-searcher-hidden': !commonService.displayGlobalSearchDialog }"
    ></div>
    <div
        [ngClass]="{
            'global-searcher': true,
            'shadow-box': true,
            'ui-shadow-5': true,
            'global-searcher-hidden': !commonService.displayGlobalSearchDialog
        }"
    >
        <h3>Globale Suche</h3>
        <a class="global-searcher-close-btn" (click)="commonService.displayGlobalSearch(false)">
            <i class="pi pi-times-circle"></i>
        </a>
        <div class="ui-g ui-fluid form-group">
            <div class="ui-g-12" style="margin-bottom: 10px;">
                <p-autoComplete
                    #ac
                    inputId="acGlobalSearchInput"
                    id="acGlobalSearch"
                    [suggestions]="commonService.getFilteredGlobalSearchResult()"
                    (completeMethod)="commonService.filterGlobalSearchResult($event)"
                    (onSelect)="commonService.callGlobalSearchData($event, router)"
                    (onDropdownClick)="commonService.filterGlobalSearchResult($event)"
                    (onClear)="commonService.filterGlobalSearchResult('')"
                    [autoHighlight]="true"
                    placeholder="Suchbegriff eingeben..."
                    [minLength]="1"
                    [dropdown]="true"
                    field="title"
                    dataKey="title"
                >
                    <ng-template let-item pTemplate="item">
                        <div class="ui-g ui-g-nopad">
                            <div class="ui-g-6">
                                <strong>{{ item.title }}</strong>
                            </div>
                            <div class="ui-g-6">
                                <small *ngIf="item.description">
                                    <i> &nbsp;&nbsp;{{ item.description }}</i>
                                </small>
                            </div>
                        </div>
                    </ng-template>
                </p-autoComplete>
            </div>
        </div>
    </div>
    <!-- <p-dialog header="Globale Suche" [(visible)]="commonService.displayGlobalSearchDialog" [modal]="true" width="600" [responsive]="true">
		<div class="ui-g ui-fluid form-group">
			<div class="ui-g-12" style="margin-bottom:10px">
				<p-autoComplete id="acSimple" [suggestions]="commonService.getFilteredGlobalSearchResult()" (completeMethod)="commonService.filterGlobalSearchResult($event)"
				(onSelect)="commonService.callGlobalSearchData($event,router)"
				(onClear)="commonService.filterGlobalSearchResult('')"
					field="name" placeholder="Suche..." [minLength]="1" [dropdown]="true" field="title" dataKey="title"></p-autoComplete>
			</div>
		</div>
	</p-dialog>-->
    <p-dialog
        header="Verfügbare Shortcuts / Hotkeys"
        [(visible)]="displayHotkeyDialog"
        [modal]="false"
        width="600"
        [responsive]="true"
    >
        <div *ngIf="displayHotkeyDialog && commonService.registeredHotkey.global.length > 0">
            <strong>Globale Hotkeys</strong>
            <ul>
                <li *ngFor="let h of commonService.registeredHotkey.global">
                    <strong>{{ h.key }}</strong> - {{ h.description }}
                </li>
            </ul>
        </div>
        <div *ngIf="displayHotkeyDialog && commonService.registeredHotkey.private.length > 0">
            <strong>Seitenspezifische Hotkeys</strong>
            <ul>
                <li *ngFor="let h of commonService.registeredHotkey.private">
                    <strong>{{ h.key }}</strong> - {{ h.description }}
                </li>
            </ul>
        </div>
    </p-dialog>
</div>
<hotkeys-cheatsheet title="Hotkeys"></hotkeys-cheatsheet>

<div *ngIf="globalLoaderVisible" style="position: fixed; z-index: 90; margin-left: 50%; bottom: 10px;">
    <avi-core-loader></avi-core-loader>
</div>

<p-confirmDialog
    key="main"
    acceptLabel="OK"
    rejectLabel="Abbrechen"
    [closable]="false"
    [closeOnEscape]="true"
    icon="fa fa-question-circle"
    [baseZIndex]="99999"
    width="425"
>
</p-confirmDialog>

<p-confirmDialog
    key="notification"
    acceptLabel="OK"
    rejectLabel="Abbrechen"
    [closable]="false"
    [closeOnEscape]="true"
    icon="fa fa-question-circle"
    [baseZIndex]="99999"
    width="425"
>
</p-confirmDialog>

<!--
<div *ngIf="authService.IsLoggedIn && vwbpCommonService.currentUserIsAjuUser()" class="current-user-info-box-small">
    Version: <strong>v{{ appVersion.version }}</strong> | Build-Zeit:
    <strong>{{ appVersion.buildDate }} {{ appVersion.buildTime }}</strong> | Angemeldet als:
    <strong>{{ authService.CurrentUserName }}</strong>
</div> -->
