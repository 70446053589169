<!-- <div class="ui-g p-3 mb-2">
    <div class="w-100p">
        <p-checkbox
            name="chkdeleted"
            [binary]="true"
            [(ngModel)]="IncludeDeleted"
            [label]="'VWBP.COMMON.AUCH_GELOESCHTE_RTS_SUCHEN' | translate"
        ></p-checkbox>
    </div>
</div>
 -->

<vwbp-intern-rechtstraeger-versionen-searcher #searcher
    settingskey="settings.searcher.ueb.normal"
    [last-version]="true"
    [use-router-params]="true"
    [get-filters]="GetFilters"
    [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'Erfassungsstatus', 'btnOpen']">
</vwbp-intern-rechtstraeger-versionen-searcher>

<div *ngIf="vwbpCommonService.currentUserIsAjuUserSchreiben()"
    fxLayout="row">
    <div class="pl-3 pr-3"
        fxLayout="column"
        fxLayoutAlign="start left"
        fxFlex="30"
        fxLayoutGap="1em">

        <span class="p-float-label">
            <textarea [rows]="8" id="comment" class="w-100p"
                pInputTextarea
                [(ngModel)]="Bemerkung"
                [autoResize]="true"
                name="inform"></textarea>
            <label for="comment">{{ 'VWBP.COMMON.COMMENT' | translate }}</label>
        </span>

        <button pButton
            type="button"
            label="{{ 'VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_ZU_AJU_HOLEN_BUTTON' | translate: ButtonParams }}"
            [disabled]="IsNothingSelected() || !Bemerkung"
            icon="pi pi-thumbs-up"
            (click)="StartRechtstraegerZuAjuHolen()"
            class="p-button-primary mb-2"></button>
        <button pButton
            type="button"
            label="{{ 'VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_LOESCHEN_BUTTON' | translate: ButtonParams }}"
            [disabled]="IsNothingSelected()"
            icon="pi pi-trash"
            (click)="StartRechtstraegerLoeschen()"
            class="mb-2 p-button-danger"></button>
    </div>
</div>
