import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { ListType } from '../shared';

export class SqlJobDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;

    @Expose()
	public Beschreibung: string;

    @Expose()
	public Bemerkung: string;

	@Expose()
	@Type(() => ListType)
	public AnzeigenInSuche: ListType;

	@Expose()
	@Type(() => ListType)
	public AnzeigenInController: ListType;
}
