import { AviVwbPInternModule } from '@avi-x/vwbp-intern';
import { AviVwbPPublicModule } from '@avi-x/vwbp-public';
import { AviVwbPAdminModule } from '@avi-x/vwbp-admin';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import lcoaleDECH from '@angular/common/locales/de-CH';
registerLocaleData(lcoaleDECH);

import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';

import { FormsModule } from '@angular/forms';
// import { HttpModule } from '@angular/core/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AppRoutes } from './app.routes';
import 'rxjs/add/operator/toPromise';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRippleModule } from '@angular/material/core';

import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { HotkeyModule } from 'angular2-hotkeys';

import { NgxIndexedDBModule } from 'ngx-indexed-db';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';

import { ToastrModule } from 'ngx-toastr';
import { GridsterModule } from 'angular-gridster2';

import { AppComponent } from './app.component';
import { AviVwbPMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AviVwbPRightPanelComponent } from './app.rightpanel.component';
import { AviVwbPTopbarComponent } from './app.topbar.component';
import { AviVwbPFooterComponent } from './app.footer.component';
import { AviVwbPInlineProfileComponent } from './app.profile.component';


import { HomeComponent } from './components/home/home.component';

import { MatPaginatorIntlDe } from './md-intl';

import { AviCoreModule, AviDbConfig, TranslateParserFactory, HttpLoaderFactory, GlobalErrorHandler, AppServerTranslateLoader, AppServerLoaderFactory } from '@avi-x/avi-core';
import { AviQueryDefSearcherModule, AviQueryDefExecutionSearcherModule, AviQueryDefExecutionGraphModule, AviInfoDeskExecutionModule } from '@avi-x/avi-report';
import { AviPartnerModule, AviLandModule, AviUserContactInfoModule } from '@avi-x/avi-crm';
import { environment } from '../environments/environment';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ActivatedRoute } from '@angular/router';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        // HttpModule,
        BrowserAnimationsModule,

        MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatMomentDateModule, MatFormFieldModule, MatInputModule,
        MatCardModule, MatChipsModule,
        MatGridListModule, MatRippleModule, MatSnackBarModule,
        MatSidenavModule, MatToolbarModule, MatListModule, MatMenuModule,

        // PrimeNg
        DragDropModule,
        AutoCompleteModule,
        ConfirmDialogModule,
        TableModule,
        DialogModule,
        PanelModule,
        PanelMenuModule,
        ScrollPanelModule,
        TooltipModule,
        TabMenuModule,
        TabViewModule,
        OverlayPanelModule,

        GridsterModule,
        MatIconModule,

        ToastrModule.forRoot(),

        AviQueryDefExecutionGraphModule,
        AviQueryDefExecutionSearcherModule,
        AviQueryDefSearcherModule,
        AviInfoDeskExecutionModule,

        // AviAbsenzModule,
        // AviAnwesenheitModule,

        AviCoreModule.forRoot({
            loginPath: '/vwbp/login',
            signalRBasicHub: '/api/signalr/basichub',
            environment: environment,
            oAuthConfig: environment.oauth2,
            saveLoginUserInSessionStorage: environment.saveLoginUserInSessionStorage === true,
            publicUrl: '/v1/vwbp/public',
            privateUrl: '/v1/intern/vwbp'
        }),
        
        AviPartnerModule,
        AviLandModule,
        // AviMandatModule,
        // AviMandatTrxModule,
        // AviLeistungModule,
        AviUserContactInfoModule,

        AviVwbPPublicModule,
        AviVwbPInternModule,
        AviVwbPAdminModule,

        NgxPermissionsModule.forRoot(),

        HotkeyModule.forRoot({ cheatSheetDescription: 'Hilfe anzeigen / ausblenden' }),
        NgxIndexedDBModule.forRoot(AviDbConfig.AviitaIndexDbConfig),

        TranslateModule.forRoot({
            defaultLanguage: 'de',
            loader: {
                provide: TranslateLoader,
                useClass: AppServerTranslateLoader,
                useFactory: AppServerLoaderFactory,
                deps: [HttpClient, ActivatedRoute]
            },
            parser: {
                provide: TranslateParser,
                useFactory: TranslateParserFactory
            }
        })

        // OAuthConfigModule.forRoot(environment.keycloak),

    ],
    declarations: [
        AppComponent,
        AviVwbPMenuComponent,
        AviVwbPFooterComponent,
        AviVwbPRightPanelComponent,
        AppSubMenuComponent,
        AviVwbPTopbarComponent,
        AviVwbPInlineProfileComponent,

        HomeComponent
    ],
    // entryComponents: [AbsenzUebersichtDayItemComponent],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        // {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
        { provide: LOCALE_ID, useValue: 'de-CH' },
        { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
        // { provide: DateAdapter, useClass: CustomDateAdapter},
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: ErrorHandler, useClass: GlobalErrorHandler},
        ConfirmationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
