import { Expose, Type } from 'class-transformer';

import { Provinz } from '../partner/provinz.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class Land extends BaseModel {  
    @Expose()
    public get ISOCode(): string { return this.getProperty('ISOCode'); }
    public set ISOCode(value: string) { this.setProperty('ISOCode', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get Amtlich(): string { return this.getProperty('Amtlich'); }
    public set Amtlich(value: string) { this.setProperty('Amtlich', value); }
    
    @Expose()
    public get KfzCode(): string { return this.getProperty('KfzCode'); }
    public set KfzCode(value: string) { this.setProperty('KfzCode', value); }
    
    @Expose()
    public get ExterneReferenz(): string { return this.getProperty('ExterneReferenz'); }
    public set ExterneReferenz(value: string) { this.setProperty('ExterneReferenz', value); }
    
    @Expose()
    public get Alias1(): string { return this.getProperty('Alias1'); }
    public set Alias1(value: string) { this.setProperty('Alias1', value); }
    
    @Expose()
    public get Alias2(): string { return this.getProperty('Alias2'); }
    public set Alias2(value: string) { this.setProperty('Alias2', value); }
    
    @Expose()
    public get PlatzhalterPLZ(): string { return this.getProperty('PlatzhalterPLZ'); }
    public set PlatzhalterPLZ(value: string) { this.setProperty('PlatzhalterPLZ', value); }
    
    @Expose()
    public get PlatzhalterOrt(): string { return this.getProperty('PlatzhalterOrt'); }
    public set PlatzhalterOrt(value: string) { this.setProperty('PlatzhalterOrt', value); }
    
    @Expose()
    public get PlatzhalterProvinz_ID(): string { return this.getModelId('PlatzhalterProvinz_ID'); }
    public set PlatzhalterProvinz_ID(value: string) { this.setModelId('PlatzhalterProvinz_ID', value); }
 
    @Expose()
    @Type(() => Provinz)
    public get PlatzhalterProvinz(): Provinz { return this.getModel('PlatzhalterProvinz'); }
    public set PlatzhalterProvinz(value: Provinz) { this.setModel('PlatzhalterProvinz', value); }
    
    @Expose()
    @Type(() => ListType)
    public get PLZOrtOrder(): ListType { return this.getProperty('PLZOrtOrder'); }
    public set PLZOrtOrder(value: ListType) { this.setProperty('PLZOrtOrder', value); }
    
    @Expose()
    @Type(() => ListType)
    public get StrasseHausnrOrder(): ListType { return this.getProperty('StrasseHausnrOrder'); }
    public set StrasseHausnrOrder(value: ListType) { this.setProperty('StrasseHausnrOrder', value); }
    
    @Expose()
    @Type(() => ListType)
    public get BeiAdresseDrucken(): ListType { return this.getProperty('BeiAdresseDrucken'); }
    public set BeiAdresseDrucken(value: ListType) { this.setProperty('BeiAdresseDrucken', value); }
    
}
