import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { VwbPVersion } from '@avi-x/vwbp-public';
import { Expose, Type } from 'class-transformer';

export class VwbPUnstimmigkeit extends BaseModel {
    @Expose()
    public get ErstelltVon(): string { return this.getProperty('ErstelltVon'); }
    public set ErstelltVon(value: string) { this.setProperty('ErstelltVon', value); }

    @Expose()
    public get Meldung(): string { return this.getProperty('Meldung'); }
    public set Meldung(value: string) { this.setProperty('Meldung', value); }

    @Expose()
    @Type(() => Date)
    public get ErledigtAm(): Date { return this.getProperty('ErledigtAm'); }
    public set ErledigtAm(value: Date) { this.setProperty('ErledigtAm', value); }

    @Expose()
    @Type(() => Date)
    public get InformiertAm(): Date { return this.getProperty('InformiertAm'); }
    public set InformiertAm(value: Date) { this.setProperty('InformiertAm', value); }

    @Expose()
    public get InformiertDurch(): string { return this.getProperty('InformiertDurch'); }
    public set InformiertDurch(value: string) { this.setProperty('InformiertDurch', value); }

    @Expose()
    public get ErledigtDurch(): string { return this.getProperty('ErledigtDurch'); }
    public set ErledigtDurch(value: string) { this.setProperty('ErledigtDurch', value); }

    @Expose()
    public get vonVersion_ID(): string { return this.getModelId('vonVersion_ID'); }
    public set vonVersion_ID(value: string) { this.setModelId('vonVersion_ID', value); }

    @Expose()
    @Type(() => VwbPVersion)
    public get vonVersion(): VwbPVersion { return this.getModel('vonVersion'); }
    public set vonVersion(value: VwbPVersion) { this.setModel('vonVersion', value); }

    @Expose()
    @Type(() => ListType)
    public get Unstimmigkeitsstatus(): ListType { return this.getProperty('Unstimmigkeitsstatus'); }
    public set Unstimmigkeitsstatus(value: ListType) { this.setProperty('Unstimmigkeitsstatus', value); }

    @Expose()
    @Type(() => ListType)
    public get LastUnstimmigkeitsstatus(): ListType { return this.getProperty('LastUnstimmigkeitsstatus'); }
    public set LastUnstimmigkeitsstatus(value: ListType) { this.setProperty('LastUnstimmigkeitsstatus', value); }
}
