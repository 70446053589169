import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, AviListDetailConst, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { StatusWorkflowDefSearchResponseDto } from '@avi-x/avi-dto/workflow/statusworkflowdefsearchresponsedto.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviStatusWorkflowDefFormComponent } from './statusworkflowdef-form.component';

const nameof = (nameFunction: ((obj: StatusWorkflowDefSearchResponseDto) => any)) => typed_nameof<StatusWorkflowDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-statusworkflowdef-searcher',
    templateUrl: './statusworkflowdef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviStatusWorkflowDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF';
    SettingsKey = 'settings.searcher.statusworkflowdef';
    BaseUrl = 'workflow/statusworkflowdef';
    FormType = AviStatusWorkflowDefFormComponent;

    CanEdit: boolean = false;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFStatusWorkflowDef schreiben').then(w => this.CanEdit = w);
    }

    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.sessionController.openSession('statusworkflowdef-index', this.SelectedRows.Id);
            //this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();

        searcher.addDateColumn(nameof(c => c.GueltigAb), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.GUELTIGAB', null, true);
        searcher.addDateColumn(nameof(c => c.GueltigBis), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.GUELTIGBIS', null, true);
        
        searcher.addTextColumn(nameof(c => c.KurzBez), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.KURZBEZ1', true);        
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.BEZEICHNUNG1', true);

        searcher.addListTypeColumn(nameof(c => c.StartNachErfassen), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.STARTNACHERFASSEN', 'janein', true, true);
        searcher.addListTypeColumn(nameof(c => c.StartNachEditieren), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.STARTNACHEDITIEREN', 'janein', true, true);
        
        searcher.addTextColumn(nameof(c => c.StartBedingungOutput), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.STARTBEDINGUNGOUTPUT', true);

        searcher.addTextColumn(nameof(c => c.ContextModelName), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.VONMODEL', true);

        searcher.addIntegerColumn(nameof(c => c.System), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.SYSTEM', true);

		searcher.endInit();
    }
    
    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
        cfg.ContextId = this.ContextId;
        cfg.Typ = AviListDetailConst.CAF_STATUSWORKFLOW_TYP_BEWILLIGUNGSPROZESS;
        const res = await this.apiService.postPagedResult(StatusWorkflowDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }

}
