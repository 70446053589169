import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AviApiService, AviBaseFormComponent, AviBaseSearcherComponent, AviCommonService, AviDialogComponent, AviFormField, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPRechtstraeger, VwbPVersion } from '@avi-x/vwbp-public';
import { VwbPBehoerdensucheWbRequestDto } from '../../../dto/vwbpbehoerdensuche.models';
import { VwbPInternRechtstraegerViewComponent } from '../../../rechtstraeger/components/rechtstraeger-view/rechtstraeger-view.component';
import { VwbPInternBehoerdenService } from '../../services/behoerden.service';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';
import { RTVerMode } from '../../../rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';

const nameof = (nameFunction: ((obj: VwbPBehoerdensucheWbRequestDto) => any)) => typed_nameof<VwbPBehoerdensucheWbRequestDto>(nameFunction);

@Component({
    selector: 'vwbp-intern-behoerden-suche-wb-np',
    templateUrl: './behoerden-suche-wb-np.component.html',
    styleUrls: ['./behoerden-suche-wb-np.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternBehoerdenSucheWbNpComponent implements OnInit, OnDestroy {
    
    toolbarButtons : any[] = [
        { title: 'Excel Export', class: 'p-button-secondary', icon: 'pi pi-download', action: () => this.OnExportClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0, visible: () => this.CheckboxSelection },
    ];
    
    public Loading: boolean = false;
    public Model: VwbPBehoerdensucheWbRequestDto = null;
    public CheckboxSelection: boolean = false;

    public Fields: AviFormField[] = [];

    public IsAJUUser : boolean;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @ViewChild('rtDialog', { static: false })
    rtDialog: AviDialogComponent;

    @ViewChild('rtView', { static: false })
    rtView: VwbPInternRechtstraegerViewComponent;

    constructor(
        private commonService: AviCommonService,
        private behoerdenService: VwbPInternBehoerdenService,
        private vwbpCommonService: VwbPCommonService,
        private formFieldService: AviFormFieldService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private apiService: AviApiService,
        private rechtstraegerService: VwbPInternRechtstraegerService
    ) { }

    async ngOnInit() {
        this.IsAJUUser = this.vwbpCommonService.currentUserIsAjuUser();
        this.CheckboxSelection = this.IsAJUUser;

        this.initFields();
        this.Model = {
            Name: '',
            CbAbgegebeneRechtstraeger: true,
            CbGeloeschteRechtstraeger: true,
            CbNichtAbgeschlosseneRechtstraeger: this.IsAJUUser,
            CbNurAktuelleVersion: false
        };
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
    }

    initFields() {
        // this.Fields.push(this.formFieldService.CreateLabel('lblTitle', '', false, 'form-title').setMDSizeFull());
        // this.Fields.push(this.formFieldService.CreateLabel('lbl1', '').setMDSizeFull());
        // this.formFieldService.setFieldLabel(this.Fields, 'lblTitle', 'Formular zur Feststellung des letztlich wirtschaftlichen Eigentümers von Rechtsträgern nach Art. 2 Abs. 1 Bst. a VwEG');
        // this.formFieldService.setFieldLabel(this.Fields, 'lbl1', 'Als letztlich wirtschaftlicher Eigentümer nach Art. 2 Abs. 1 Bst. a VwEG wurde festgestellt:');
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'VWBP.INTERN.BEHOERDENABFRAGE.WB', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GeburtsdatumVon), 'VWBP.INTERN.BEHOERDENABFRAGE.GEBURTSDATUM_VON', false).setDateRangeButtonsVisibility(false).setMDSizeOneQuarter());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GeburtsdatumBis), 'VWBP.INTERN.BEHOERDENABFRAGE.GEBURTSDATUM_BIS', false).setDateRangeButtonsVisibility(false).setMDSizeOneQuarter());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Wohnsitzstaat), 'VWBP.INTERN.BEHOERDENABFRAGE.WOHNSITZSTAAT', `v1/vwbp/model/representations/land`, false, true, ' - Alle - ', (m) => (`${m.Representation}`), 'Id', true, false).setMDSizeOneQuarter());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Nationalitaet), 'VWBP.INTERN.BEHOERDENABFRAGE.NATIONALITAET', `v1/vwbp/model/representations/land`, false, true, ' - Alle - ', (m) => (`${m.Representation}`), 'Id', true, false).setMDSizeOneQuarter());

        if (!this.IsAJUUser)
            this.Fields.push(this.formFieldService.CreateLabel('lblHinweis', 'VWBP.INTERN.BEHOERDENABFRAGE.HINWEIS_SUCHKRITERIEN', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbAbgegebeneRechtstraeger), null, 'VWBP.INTERN.BEHOERDENABFRAGE.AUCH_ABGEGEBENE_RTS_SUCHEN', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbGeloeschteRechtstraeger), null, 'VWBP.INTERN.BEHOERDENABFRAGE.AUCH_GELOESCHTE_RTS_SUCHEN', false).setMDSizeFull());

        if (this.IsAJUUser)
            this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbNichtAbgeschlosseneRechtstraeger), null, 'VWBP.INTERN.BEHOERDENABFRAGE.AUCH_NICHT_ABGESCHLOSSENE_RTS_SUCHEN', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbNurAktuelleVersion), null, 'VWBP.INTERN.BEHOERDENABFRAGE.NUR_AKTUELLE_VERSION_SUCHEN', false).setMDSizeFull());

        if (!this.IsAJUUser)
            this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Begruendung), 'VWBP.INTERN.BEHOERDENABFRAGE.BEGRUENDUNG_DER_SUCHE', true).setMDSizeFull());
    }

    startSearch(model: VwbPBehoerdensucheWbRequestDto) {
        return this.searcher.doSearch();
    }


    onSearcherInit(data) {
        const isAJUUser = this.vwbpCommonService.currentUserIsAjuUser();

        this.searcher.beginInit();

        if (isAJUUser)
            this.searcher.addListTypeColumn('Erfassungsstatus', 'VWBP.COMMON.ERFASSUNGSSTATUS', 'vwbp_erfassungsstatus', null, null, null, 'v1/vwbp').setFilterable(false).setSortable(false).setFormatDelegate((record) => this.commonService.escapeHtml(record.Erfassungsstatus.Bezeichnung))
                .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_ERFASSUNGSSTATUS_TOOLTIP')
                .setWidth('200px');

        this.searcher.addDateColumn('Erfassungsdatum', 'VWBP.COMMON.ERFASSUNGSDATUM').setFilterable(false).setSortable(false)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_ERFASSUNGSDATUM_TOOLTIP')
            .setWidth('150px');

        this.searcher.addDateColumn('HrLoeschungsdatum', 'VWBP.COMMON.LOESCHUNGSDATUM').setFilterable(false).setSortable(false)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_LOESCHUNGSDATUM_TOOLTIP')
            .setWidth('150px');

        this.searcher.addListTypeColumn('Uebergabestatus', 'VWBP.COMMON.UEBERGABESTATUS', 'vwbp_rt_uebergabestatus', null, null, null, 'v1/vwbp').setFilterable(false).setSortable(false).setFormatDelegate((record) => this.commonService.escapeHtml(record.Uebergabestatus.Bezeichnung))
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_UEBERGABESTATUS_TOOLTIP')
            .setWidth('150px');

        this.searcher.addTextColumn('FormDataDecryptedObj.Vorname', 'CORE.COMMON.VORNAME').setFilterable(false).setSortable(false).setFormatDelegate((record) => this.commonService.escapeHtml(record.FormDataDecryptedObj.Vorname));
        this.searcher.addTextColumn('FormDataDecryptedObj.Name', 'CORE.COMMON.NAME').setFilterable(false).setSortable(false).setFormatDelegate((record) => this.commonService.escapeHtml(record.FormDataDecryptedObj.Name));
        this.searcher.addTextColumn('FormDataDecryptedObj.Geburtsdatum', 'CORE.COMMON.GEBURTSDATUM').setFilterable(false).setSortable(false)
            .setFormatDelegate((record) => record.FormDataDecryptedObj.Geburtsdatum ? this.commonService.formatDate(record.FormDataDecryptedObj.Geburtsdatum, 'l') : null)
            .setWidth('150px');

        this.searcher.addTextColumn('WohnsitzStaat', 'VWBP.INTERN.BEHOERDENABFRAGE.WOHNSITZSTAAT').setFilterable(false).setSortable(false);
        this.searcher.addTextColumn('Nationalitaet1', 'VWBP.INTERN.BEHOERDENABFRAGE.NATIONALITAET_1').setFilterable(false).setSortable(false);
        this.searcher.addTextColumn('Nationalitaet2', 'VWBP.INTERN.BEHOERDENABFRAGE.NATIONALITAET_2').setFilterable(false).setSortable(false);
        this.searcher.addTextColumn('Nationalitaet3', 'VWBP.INTERN.BEHOERDENABFRAGE.NATIONALITAET_3').setFilterable(false).setSortable(false);
        this.searcher.addTextColumn('Nationalitaet4', 'VWBP.INTERN.BEHOERDENABFRAGE.NATIONALITAET_4').setFilterable(false).setSortable(false);

        this.searcher.endInit();
    }

    public GetRowClass = (row: any): string => {
        let ret = 'no-border-left no-border-right';
        if (row) {
            if (row['HrLoeschungsdatum'])
                ret += ' rt-geloescht';
        }
        return ret;
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        return new Promise<any>(async (resolveFn, rejectFn) => {
            try {
                this.commonService.showGlobalLoader();
                const results = await this.behoerdenService.StartBehoerdensucheWb({ ...this.Model, ...searchConfig });
                console.log('results', results);
                resolveFn(results);
                this.commonService.hideGlobalLoader();
            } catch (err) {
                this.commonService.hideGlobalLoader();
                rejectFn(new Error(this.commonService.getErrorText(err, true)));
            }
        });
    }

    public SelectAllDelegate: any = async (searchValue: string, searchConfig: any = null) => {
        return await await this.behoerdenService.StartBehoerdensucheWbIds({ ...this.Model, ...searchConfig });
    }

    public getRowGroupHeader(row: any, searcher: any): string {
        //return `<strong>${this.commonService.escapeHtml(row.RechtstraegerName)}, ${this.commonService.escapeHtml(row.RechtstraegerSitz)} (${this.commonService.escapeHtml(row.RechtstraegerRegisternummer)}) - ${this.commonService.escapeHtml(row.RechtstraegerRechtsform)} - Version ${this.commonService.escapeHtml(row.VersionLaufnummer)}</strong>`;
        
        let result = `<strong>${this.commonService.escapeHtml(row.RechtstraegerName)}`;

        if (row.RechtstraegerSitz)
            result += `, ${this.commonService.escapeHtml(row.RechtstraegerSitz)}`;

        if (row.RechtstraegerRegisternummer)
            result += ` (${this.commonService.escapeHtml(row.RechtstraegerRegisternummer)})`;

        if (row.RechtstraegerRechtsform)
            result += ` - ${this.commonService.escapeHtml(row.RechtstraegerRechtsform)}`;

        if (row.VersionLaufnummer)
            result += ` - Version ${this.commonService.escapeHtml(row.VersionLaufnummer)}`;

        result += '</strong>';
        return result;
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    ngOnDestroy() {
        this.commonService.hideGlobalLoader();
    }

    async onDoubleClickSearcher(data) {
        // this.router.navigate(['/intern/vwbp/behoerden/rt', data.Id]);

        try {
            this.commonService.showGlobalLoader();
            const version = await this.apiService.getModel(VwbPVersion, `v1/intern/vwbp/rechtstraeger/version/${data.Id}`);
            const rechtstraeger = await this.apiService.FindModel(VwbPRechtstraeger, `v1/intern/vwbp/rechtstraeger/${version.Rechtstraeger_ID}`, null, null, null);
            rechtstraeger.SelectedVersion = version;
            await this.rtView.initView(rechtstraeger, true, true);
            this.rtView.refresh();
        } finally {
            this.commonService.hideGlobalLoader();
        }

        this.rtDialog.open();
    }

    CloseRTView() {
        this.rtDialog.close();
        this.rtView.initView(null);
        this.cdr.detectChanges();
    }
    
    async OnExportClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.Id).filter((value, index, self) => self.indexOf(value) === index);
        this.rechtstraegerService.OpenBehoerdenExcelExportJobDialog(rechtstraegerIds, RTVerMode.BEHOERDEN_VERSION_IN);
    }

    public GetSelectedRechtstraeger(): any[] {
        return this.searcher.SelectedRows;
    }
}
