import { Component, ViewChild, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';

@Component({
    selector: 'vwbp-public-xmlupload-form',
    templateUrl: './xmlupload-form.component.html',
    styleUrls: ['./xmlupload-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPPublicXMLUploadFormComponent {
    @ViewChild('inputXML', { static: false })
    inputXML: FileUpload;

    @Output('onClose')
    public onClose: EventEmitter<boolean> = new EventEmitter();

    Uploading: boolean = false;

    constructor(private cdr: ChangeDetectorRef) {
    }

    onSelect() {
    }

    async onUploadXML(event) {
        this.Uploading = true;
        this.cdr.markForCheck();

        if (event.files?.length > 0)
            this.onClose.emit(event.files[0]);
    }

    onFileDropped($event) {
        this.setFile($event);
    }

    setFile(files: Array<any>) {
        if (files && files.length > 0)
            this.inputXML.files.push(files[0]);
    }

    public Init() {
        this.inputXML.clear();
        this.Uploading = false;
    }

    doClose() {
        this.onClose.emit(null);
    }
}
