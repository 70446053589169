import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vwbp-admin-start',
  templateUrl: './vwbp-admin-start.component.html',
  styleUrls: ['./vwbp-admin-start.component.scss']
})
export class VwbPAdminStartComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
