import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviAppUserFormComponent } from './appuser-form.component';
import { AppUserSearchResponseDto } from '@avi-x/avi-dto/system/appusersearchresponsedto.model';

const nameof = (nameFunction: ((obj: AppUserSearchResponseDto) => any)) => typed_nameof<AppUserSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-appuser-searcher',
    templateUrl: './appuser-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: AviAbstractModelSearcherComponent, useExisting: AviAppUserSearcherComponent }]
})
export class AviAppUserSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.APPUSER';
    SettingsKey = 'settings.searcher.appuser';
    BaseUrl = 'appuser';
	FormType = AviAppUserFormComponent;

    CanEdit: boolean = false;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('AppUser schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
/*         if (this.SelectedRows) {
            // this.sessionController.openSession('appuser-index', this.SelectedRows.Id);
            this.editObject(this.SelectedRows);
        } */
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        
        searcher.addTextColumn(nameof(c => c.Kurzz), 'CORE.COMMON.MODEL.APPUSER.KURZZ', true);
        searcher.addTextColumn(nameof(c => c.Username), 'CORE.COMMON.MODEL.APPUSER.USERNAME', true);
   
        searcher.addTextColumn(nameof(c => c.Vorname), 'CORE.COMMON.MODEL.APPUSER.VORNAME', true);
        searcher.addTextColumn(nameof(c => c.Name), 'CORE.COMMON.MODEL.APPUSER.NAME', true);
  
        searcher.addTextColumn(nameof(c => c.Domain), 'CORE.COMMON.MODEL.APPUSER.DOMAIN', true);
        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(AppUserSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
