<avi-core-page [card]="false" [collapsible]="false" [expanded]="true" [contents-padding]="true" [no-box-shadow]="true">
    <!-- <div class="searcher-scrollarea"> -->
        <avi-core-base-searcher
            #searcher
            [use-router-params]="false"
            [visible-columns]="VisibleColumns"
            [keep-selection]="true"
            [selectall-delegate]="SelectAllDelegate"
            [card]="false"
            [disable-inactive-filter]="true"
            [contents-padding]="false"
            [search-delegate]="SearchDelegate"
            [rows]="15"
            [toolbar]="false"
            [auto-search]="true"
            [show-autofilter]="true"
            [select-first-result]="false"
            [checkbox-selection]="true"
            [checkbox-selectionmode]="'multiple'"
            [metakeyselection]="false"
            searcher-title=""
            (onInit)="onSearcherInit($event)"
            (onSelect)="onSelectionChanged($event)"
            [show-totalrecords]="true"
            [show-totalselected]="true"
            [total-label]="Mode === RTVerMode.RECHTSTRAEGER ? ('VWBP.COMMON.RT_INANSICHT' | translate) : ('VWBP.COMMON.VERSIONEN_INANSICHT' | translate)"
            [total-selected-label]="Mode === RTVerMode.RECHTSTRAEGER ? ('VWBP.COMMON.RT_SELECTIERT' | translate) : ('VWBP.COMMON.VERSIONEN_SELECTIERT' | translate)"

        >
        </avi-core-base-searcher>
    <!-- </div> -->
</avi-core-page>
