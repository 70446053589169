import {Directive,ElementRef,HostListener,Input,Output, EventEmitter,Optional, AfterViewInit, OnInit, OnDestroy, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import {NgModel, NgControl} from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[aviInputTextarea]',
    host: {
        'class': 'p-inputtextarea p-inputtext p-component p-element',
        '[class.p-filled]': 'filled',
        '[class.p-inputtextarea-resizable]': 'autoResize'
    }
})
export class AviInputTextarea implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
    /**
     * When present, textarea size changes as being typed.
     * @group Props
     */
    @Input() autoResize: boolean | undefined;
    /**
     * Callback to invoke on textarea resize.
     * @param {(Event | {})} event - Custom resize event.
     * @group Emits
     */
    @Output() onResize: EventEmitter<Event | {}> = new EventEmitter<Event | {}>();

    filled: boolean | undefined;

    cachedScrollHeight: number | undefined;

    ngModelSubscription: Subscription | undefined;

    ngControlSubscription: Subscription | undefined;

    constructor(public el: ElementRef, @Optional() public ngModel: NgModel, @Optional() public control: NgControl, private cd: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.ngModel) {
            this.ngModelSubscription = (this.ngModel as any).valueChanges.subscribe(() => {
                this.updateState();
            });
        }

        if (this.control) {
            this.ngControlSubscription = (this.control as any).valueChanges.subscribe(() => {
                this.updateState();
            });
        }
    }

    ngAfterViewChecked() {
        this.updateState();
    }

    ngAfterViewInit() {
        if (this.autoResize) this.resize();

        this.updateFilledState();
        this.cd.detectChanges();
    }

    @HostListener('input', ['$event'])
    onInput(e: Event) {
        this.updateState();
    }

    updateFilledState() {
        this.filled = this.el.nativeElement.value && this.el.nativeElement.value.length;
    }

    resize(event?: Event) {
        let verticalScrollPosition: number = null;

        let scrollContainer = this.findAncestor(this.el.nativeElement as HTMLElement, "p-dialog-content");  
        if (!scrollContainer)
            scrollContainer = this.findAncestor(this.el.nativeElement as HTMLElement, "e-dlg-content");  
        if (!scrollContainer)
            scrollContainer = this.findAncestor(this.el.nativeElement as HTMLElement, "mat-tab-body-content");  
        if (scrollContainer)
            verticalScrollPosition= scrollContainer.scrollTop;

        this.el.nativeElement.style.height = 'auto';
        this.el.nativeElement.style.height = this.el.nativeElement.scrollHeight + 'px';

        if (parseFloat(this.el.nativeElement.style.height) >= parseFloat(this.el.nativeElement.style.maxHeight)) {
            this.el.nativeElement.style.overflowY = "scroll";
            this.el.nativeElement.style.height = this.el.nativeElement.style.maxHeight;
        }
        else {
            this.el.nativeElement.style.overflow = "hidden";
        }

        if (scrollContainer) {
            //Workaround: Die vertikale Scrollposition wird restored, um ein Ändern der Scrollposition zu verhindern.
            scrollContainer.scrollTop = verticalScrollPosition;
        }

        this.onResize.emit(event||{});
    }

    private findAncestor (el: HTMLElement, cls: string) {
        while ((el = el.parentElement) && !el.classList.contains(cls));
        return el;
    }

    updateState() {
        this.updateFilledState();

        if (this.autoResize) {
            this.resize();
        }
    }

    ngOnDestroy() {
        if (this.ngModelSubscription) {
            this.ngModelSubscription.unsubscribe();
        }

        if (this.ngControlSubscription) {
            this.ngControlSubscription.unsubscribe();
        }
    }
}