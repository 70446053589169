import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AppUserGroupLinkSearchResponseDto } from "@avi-x/avi-dto/system/appusergrouplinksearchresponsedto.model";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { AviAbstractModelMultiSelectionComponent } from 'libs/avi-core/src/lib/components/controls/multiselection/abstract-model-multiselection';
import { AppUserSearchResponseDto } from "@avi-x/avi-dto/system/appusersearchresponsedto.model";
import { AviCommonService, typed_nameof } from "@avi-x/avi-core";
import { TechRoleLinkInfo } from "./TechRoleLinkInfo";

const nameofL = (nameFunction: ((obj: AppUserSearchResponseDto) => any)) => typed_nameof<AppUserSearchResponseDto>(nameFunction);
const nameofR = (nameFunction: ((obj: AppUserGroupLinkSearchResponseDto) => any)) => typed_nameof<AppUserGroupLinkSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-appusergrouplink-multiselection',
    templateUrl: './appusergrouplink-multiselection.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAppUserGroupLinkMultiSelectionComponent extends AviAbstractModelMultiSelectionComponent<AppUserSearchResponseDto, AppUserGroupLinkSearchResponseDto> {
    techRoleLinkInfo: TechRoleLinkInfo;
    
    constructor(config: DynamicDialogConfig, commonService: AviCommonService) { 
        super(config, commonService, nameofL(c => c.Id), nameofR(c => c.Id), nameofR(c => c.AppUserId));
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.techRoleLinkInfo = new TechRoleLinkInfo();
        this.techRoleLinkInfo.appUserGroupId = this.ModelId;
    }

    createNewRecord(user: AppUserSearchResponseDto, newId: string): any {
        let newRecord = new AppUserGroupLinkSearchResponseDto();
        newRecord.Id = newId;
        newRecord.AppUserId = user.Id;
        newRecord.Kurzz = user.Kurzz;
        newRecord.Username = user.Username;
        newRecord.Vorname = user.Vorname;
        newRecord.Name = user.Name;
        newRecord.Domain = user.Domain;

        return newRecord;
    }
}
