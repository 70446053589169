import { AfterViewInit, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from "@angular/core";
import { AviAbstractModelSearcherComponent } from "../../base-searcher/abstract-model-searcher.component";
import { IMultiSelectionModel } from "./abstract-model-multiselection";
import { ISearcherDragDropHandler } from "../../base-searcher/base-searcher.component";

@Component({
    selector: 'avi-core-multiselection',
    templateUrl: './multiselection.component.html',
    styleUrls: ['./multiselection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AviMultiSelectionComponent implements AfterViewInit, ISearcherDragDropHandler {

    @ContentChildren(AviAbstractModelSearcherComponent)
    private searchers: QueryList<AviAbstractModelSearcherComponent>;

    @Input('height')
    height = "500px";

    leftSearcher: AviAbstractModelSearcherComponent;
    rightSearcher: AviAbstractModelSearcherComponent;

    multiSelectionModel: IMultiSelectionModel;

    addedItems: any[] = [];
    removedItems: any[] = [];

    private draggedItems: any[];
    private draggingDirectionLeftToRight = true;

    private newRecordCounter = 0;

    ngAfterViewInit(): void {
         if (this.searchers.length != 2)
            throw new Error("Please add the following provider to both searchers used in the multiselection component: 'providers: [{ provide: AviAbstractModelSearcherComponent, useExisting: YourComponent }]'");

        this.leftSearcher = this.searchers.get(0);
        this.leftSearcher.searcher.AutoSearch = false;
        this.leftSearcher.searcher.ScrollHeight = this.height;
        this.leftSearcher.searcher.SelectionMode = "multiple";
        this.leftSearcher.searcher.AddCRUDMenuItems = false;
        this.leftSearcher.searcher.ShowCreateButton = false;
        this.leftSearcher.searcher.ShowGenericExport = false;
        this.leftSearcher.searcher.ShowColumnSelection = false;
        this.leftSearcher.searcher.dataSource.filterDelegate = this.filterDelegate.bind(this);
        this.leftSearcher.searcher.dragDropHandler = this;
        this.leftSearcher.searcher.draggableScope = "addScope";
        this.leftSearcher.searcher.droppableScope = "removeScope";
        this.leftSearcher.searcher.draggableDisabled = false;
        this.leftSearcher.searcher.enableDroppable();

        this.rightSearcher = this.searchers.get(1);
        this.rightSearcher.searcher.ScrollHeight = this.height;
        this.rightSearcher.searcher.SelectionMode = "multiple";
        this.rightSearcher.searcher.AddCRUDMenuItems = false;
        this.rightSearcher.searcher.ShowCreateButton = false;
        this.rightSearcher.searcher.ShowGenericExport = false;
        this.rightSearcher.searcher.ShowColumnSelection = false;
        this.rightSearcher.searcher.ShowSearchInput = false;
        this.rightSearcher.searcher.ShowRefreshButton = false;
        this.rightSearcher.searcher.LazyLoading = false;
        this.rightSearcher.searcher.onDataLoaded.subscribe(this.rightSearcher_onDataLoaded.bind(this));
        this.rightSearcher.searcher.dragDropHandler = this;
        this.rightSearcher.searcher.droppableScope = "addScope";
        this.rightSearcher.searcher.draggableScope = "removeScope";
        this.rightSearcher.searcher.draggableDisabled = false;
        this.rightSearcher.searcher.enableDroppable();
    }

    private getIndexByIdField(data: any[], field: string, item: any): number {
        for (let i = 0; i < data.length; i++) {
            if (data[i][field] == item[field])
                return i;
        }

        return -1;
    }

    private addNewItems(items: any[]) {
        let data = this.rightSearcher.searcher.dataSource;

        items.forEach((e) => {
            // Wenn die Id nicht unterschiedlich ist zwischen den neuen Records, hat der Searcher Probleme mit der Selektion.
            // Es werden dann immer alle neuen Records gemeinsam selektiert.
            let newRecord = this.multiSelectionModel.createNewRecord(e, this.newRecordCounter +"");
            this.newRecordCounter++;

            this.addedItems.push(newRecord);
            data.addRow(newRecord);
        });

        this.rightSearcher.searcher.SelectedRows = [];
        this.leftSearcher.searcher.SelectedRows = [];
        this.rightSearcher.searcher.updateDataSource(true);
        this.leftSearcher.searcher.updateDataSource();
    }

    private removeItems(items: any[]) {
        let data = this.rightSearcher.searcher.dataSource;

        items.forEach((rowData) => {
            let index = data.data.indexOf(rowData);

            let record = data.data.splice(index, 1)[0];

            let addedIndex = this.addedItems.indexOf(record);
            if (addedIndex >= 0)
                this.addedItems.splice(addedIndex, 1);
            else
                this.removedItems.push(record);
        });

        this.rightSearcher.searcher.SelectedRows = [];
        this.leftSearcher.searcher.SelectedRows = [];
        this.rightSearcher.searcher.updateDataSource(true);
        this.leftSearcher.searcher.updateDataSource();
    }

    private filterDelegate(rowData: any): boolean {
        if (this.multiSelectionModel)
            return this.multiSelectionModel.filter(rowData, this.rightSearcher.searcher.Data);

        return true;
    }

    buttonAddClicked() {
        let selectionToAdd = this.leftSearcher.searcher.SelectedRows;

        this.addNewItems(selectionToAdd);
    }
        
    buttonRemoveClicked() {
        let selectionToRemove = this.rightSearcher.searcher.SelectedRows;
        
        this.removeItems(selectionToRemove);
    }

    private rightSearcher_onDataLoaded() {
        let data = this.rightSearcher.searcher.dataSource.data;

        let itemsToRemove: any[] = [];
        this.removedItems.forEach(item => {
            let index = this.getIndexByIdField(data, this.multiSelectionModel.rightSearcherIdField, item);
            if (index < 0)
                itemsToRemove.push(item);
            else 
                data.splice(index, 1);
        });

        this.addedItems.forEach(item => data.push(item));

        this.leftSearcher.searcher.forceRefresh();
    }

    dragStart(sender: any, dataRows: any[]) {
        this.draggedItems = dataRows;
        this.draggingDirectionLeftToRight = sender == this.leftSearcher.searcher;
    }

    dragEnd(sender: any) {
        this.draggedItems = null;
    }

    drop(sender: any) {
        if (!this.draggedItems)
            return;

        if (this.draggingDirectionLeftToRight) 
            this.addNewItems(this.draggedItems);
        else
            this.removeItems(this.draggedItems);
    }
}