import { Expose, Type } from 'class-transformer';

import { ListType } from '../shared/listtype.model';
import { GenericRef, IDTO } from '../shared';

export class ScriptRuleOutputSearchResultDto extends IDTO {  
    @Expose()
    public Id: string;
    
    @Expose()
    @Type(() => ListType)
    public Aktiv: ListType;

    @Expose()
    @Type(() => Date)
    public ErstellDatum: Date;

    @Expose()
    public ErstellUser: string;

    @Expose()
    @Type(() => Date)
    public MutDatum: Date;

    @Expose()
    public MutUser: string;

    @Expose()
    public Message1: string;

    @Expose()
    public Kommentar: string;

    @Expose()
    public Vermerk: string;

    @Expose()
    public RuleId: number;
    
    @Expose()
    public OutputWert: number;

    @Expose()
    @Type(() => ListType)
    public RuleViolated: ListType;
    
    @Expose()
    @Type(() => ListType)
    public Status: ListType;
    
    @Expose()
    @Type(() => GenericRef)
    public ModelRef: GenericRef;

    @Expose()
    @Type(() => Date)
    public Geprueft1Am: Date;
    
    @Expose()
    @Type(() => Date)
    public Geprueft2Am: Date;

    @Expose()
    public Geprueft1: string;

    @Expose()
    public Geprueft2: string;

    @Expose()
    public Geprueft1VonUsername: string;

    @Expose()
    public Geprueft2VonUsername: string;

    @Expose()
    public Geprueft1Von_ID: string;

    @Expose()
    public Geprueft2Von_ID: string;
}
