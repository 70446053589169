<avi-core-page
    icon="person"
    [contents-template]="subheader"
    [contents-padding]="false"
>
    <ng-template #subheader>
        <div
            *ngIf="Phonenumbers?.length > 0 || EmailAdresses?.length > 0"
            class="subheader"
            fxLayoutGap="10px"
            fxLayout="row"
            fxLayout.sm="column"
            fxLayout.xs="column"
        >
            <div *ngIf="Phonenumbers?.length > 0" fxFlex="30">
                <button
                    [disabled]="Phonenumbers.length < 2"
                    class="test"
                    mat-button
                    [matMenuTriggerFor]="menu"
                >
                    {{ PhoneLabel
                    }}<i
                        *ngIf="Phonenumbers.length > 1"
                        class="pl-4 material-icons"
                        style="color: #666;"
                        >arrow_drop_down</i
                    >
                </button>
                <mat-menu #menu="matMenu">
                    <button
                        class="test2"
                        *ngFor="let number of Phonenumbers"
                        (click)="selectPhonenumber(number)"
                        mat-menu-item
                    >
                        {{ number.Eingabe }} ({{ number.KommTyp.Bezeichnung }})
                    </button>
                </mat-menu>
                <div *ngIf="SelectedPhonenumber" class="pl-3">
                    <a class="lnk" (click)="click2Call(SelectedPhonenumber)"
                        >{{ SelectedPhonenumber.Eingabe }} ({{
                            SelectedPhonenumber.KommTyp.Bezeichnung
                        }})</a
                    >
                </div>
            </div>

            <div *ngIf="EmailAdresses?.length > 0" fxFlex="30">
                <button
                    [disabled]="EmailAdresses.length < 2"
                    class="test"
                    mat-button
                    [matMenuTriggerFor]="menu"
                >
                    {{ EmailLabel
                    }}<i
                        *ngIf="EmailAdresses.length > 1"
                        class="pl-4 material-icons"
                        style="color: #666;"
                        >arrow_drop_down</i
                    >
                </button>
                <mat-menu #menu="matMenu">
                    <button
                        class="test2"
                        *ngFor="let number of EmailAdresses"
                        (click)="selectEmailAdress(number)"
                        mat-menu-item
                    >
                        {{ number.Eingabe }} ({{ number.KommTyp.Bezeichnung }})
                    </button>
                </mat-menu>
                <div *ngIf="SelectedEmailAdress" class="pl-3">
                    <a class="lnk" (click)="click2Email(SelectedEmailAdress)"
                        >{{ SelectedEmailAdress.Eingabe }} ({{
                            SelectedEmailAdress.KommTyp.Bezeichnung
                        }})</a
                    >
                </div>
            </div>
        </div>
    </ng-template>
     
    <avi-tabView *ngIf="PartnerId" width="10rem" [tabview-class]="Phonenumbers?.length > 0 || EmailAdresses?.length > 0 ? 'tabstyle-hdr-session' : 'tabstyle-session'" orientation="vertical" [session-modul]="true">
        <avi-tabPanel title="Übersicht" id="jurperson-summary">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-crm-jurperson-summary [partner-id]="PartnerId"></avi-crm-jurperson-summary>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="Partner" id="jurperson-view">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-crm-jurperson-view [partner-id]="PartnerId"></avi-crm-jurperson-view>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
        
        <avi-tabPanel title="Adresse" id="adresse-liste">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-crm-adresse-liste [partner-id]="PartnerId"></avi-crm-adresse-liste>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
        
        <avi-tabPanel title="Kommunikationen" id="kommunikation-liste">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-crm-partner-kommunikation-liste [partner-id]="PartnerId"></avi-crm-partner-kommunikation-liste>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="Identifikationen" id="identifikation-liste">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-crm-partner-identifikation-liste [partner-id]="PartnerId"></avi-crm-partner-identifikation-liste>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="Partnerrolle" id="partnerrolle-liste">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-crm-partnerrolle-liste [partner-id]="PartnerId" [card]="false"></avi-crm-partnerrolle-liste>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="Aktivitäten" id="activity-searcher">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-activity-searcher [context-id]="PartnerId" context-type="aviita.CAF.common.modul.partner.model.CAFJurperson" [card]="false"></avi-activity-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
        
        <avi-tabPanel title="Dokumente" id="dokumente">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-crm-dokumente [context-id]="PartnerId" context-type="aviita.CAF.common.modul.partner.model.CAFJurperson" [card]="false" [use-router-params]="false"></avi-crm-dokumente>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

    </avi-tabView>
</avi-core-page>
