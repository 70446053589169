import { Expose } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';

export class ScriptDef extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get Script(): string { return this.getProperty('Script'); }
    public set Script(value: string) { this.setProperty('Script', value); }
    
    @Expose()
    public get Context_ID(): string { return this.getModelId('Context_ID'); }
    public set Context_ID(value: string) { this.setModelId('Context_ID', value); }
     
    @Expose()
    public get vonStatusWorkflowDef_ID(): string { return this.getModelId('vonStatusWorkflowDef_ID'); }
    public set vonStatusWorkflowDef_ID(value: string) { this.setModelId('vonStatusWorkflowDef_ID', value); } 
}
