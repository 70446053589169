import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputmaskService, AviCommonService, AviApiService } from '@avi-x/avi-core';

import { DialogService } from 'primeng/dynamicdialog';
import { Calendar } from 'primeng/calendar';
import { VwbPInternRechtstraegerService } from '../../services/rechtstraeger.service';
import { RTVerMode } from '../rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';

type ActionFunction = (selectedRT: any[]) => Promise<any>;


@Component({
    selector: 'vwbp-intern-task-base',
    template: ''
})
export class VwbPInternTaskBaseComponent {
    public Bemerkung: string;
    public DatumInvalid: boolean = false;
    public DocuGenerieren: boolean = true;
    public HasDoku: boolean = false;
    public TaskId: string;


    _SelectedRechtstraeger: any[] = [];

    @Input('selected-rechtstraeger')
    public set SelectedRechtstraeger(value: any[]) {
        this._SelectedRechtstraeger = value;
        setTimeout(() => this.OnSelectionChanged.emit(value));
    }

    public get SelectedRechtstraeger() {
        return this._SelectedRechtstraeger;
    }

    @Input('dialog')
    public Dialog: boolean = false;

    @Input('datum')
    public Datum: Date;

    @Input('datumBis')
    public DatumBis: Date;

    @Input('frist')
    public Frist: Date;

    @Output('onCancel')
    public OnCancel: EventEmitter<any> = new EventEmitter<any>();

    @Output('onSave')
    public OnSave: EventEmitter<any> = new EventEmitter<any>();


    public OnSelectionChanged: EventEmitter<any> = new EventEmitter<any>();

    // @Input('task-id')
    // public set setTaskId(value: string) {
    //     this.TaskId = value;
    //     this.InitDoku(this.TaskId);
    // }

    // public get getTaskId(): string {
    //     return this.TaskId;
    // }

    constructor(public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public inputMaskService: InputmaskService, public dialogService: DialogService) {
    }

    public onInputDate(event, calendar: Calendar): void {
        this.inputMaskService.handleDateInput(event, calendar);
    }

    Cancel() {
        this.OnCancel.emit(null);
    }

    Save() {
        this.Bemerkung = null;
        this.Datum = null;
        this.OnSave.emit(null);
    }

    async InitDoku(taskId: string) {
        this.HasDoku = await this.apiService.get(`v1/intern/vwbp/vorlagedef/hastask/${taskId}`);
    }

    GetResourceName(resourceName: string) {
        return this.SelectedRechtstraeger.length === 1 ? resourceName : resourceName + '_N';
    }

    async PerformAction(header: string, message: string, acceptlabel: string, action: ActionFunction) {
        if (this.Dialog) {
            await action(this.SelectedRechtstraeger);
            this.Save();
        } else {
            const rtIds = await this.rechtstraegerService.OpenRTAdresseDialog(
                this.commonService.translateInstant(header),
                this.commonService.translateInstant(message),
                this.commonService.translateInstant(acceptlabel),
                this.SelectedRechtstraeger.map(w => w.RechtstraegerId),
                RTVerMode.RECHTSTRAEGER);

            if (rtIds && rtIds.length > 0) {
                const ids = this.SelectedRechtstraeger.filter(w => rtIds.some(x => x === w.RechtstraegerId));
                await action(ids);
                this.Save();
            }
            //     this.commonService.confirm({
            //         header: header,
            //         message: message, acceptLabel: acceptlabel, rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: async () => {
            //             await action(this.SelectedRechtstraeger);
            //             this.Save();
            //     });
            // }
        }
    }
}
