import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviBaseFormComponent, ObjectUtils, AviFormFieldService, typed_nameof, AviHistoryService } from '@avi-x/avi-core';
import { ZustandInfoDto } from '@avi-x/avi-dto/shared/zustandinfo.model';
import { VwbPListDetailConst } from '../../../constants/constants';
import { VwbPRechtstraegerDetail } from '../../../dto/vwbprechtstraegerdetail.model';

import { TranslateService } from '@ngx-translate/core';

const nameof = (nameFunction: ((obj: VwbPRechtstraegerDetail) => any)) => typed_nameof<VwbPRechtstraegerDetail>(nameFunction);

@Component({
    selector: 'vwbp-public-rechtstraegerdetail-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons">

        <div formFieldTemplate1>
            <avi-core-history-control [history-states]="HistoryStates" (stichtagChange)="OnStichtagChange($event)">
            </avi-core-history-control>
        </div>

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPPublicRechtstraegerDetailFormComponent implements OnInit, OnDestroy {
    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this.translateService.instant('VWBP.PUBLIC.RT_AUSLAND_BEARBEITEN');
        } else
            return this.translateService.instant('VWBP.PUBLIC.RT_AUSLAND_ERFASSEN');
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: VwbPRechtstraegerDetail = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string {
        return this._ModelId;
    }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    @Input('api')
    public Api: string = 'v1/vwbp';

    private stichtag: Date = new Date();
    public HistoryStates: ZustandInfoDto[];

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private historyService: AviHistoryService,
        private formFieldService: AviFormFieldService,
        private translateService: TranslateService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {
        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        this.Form?.clearFormMessages();

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;
        } else {
            await this.initNewModel();
        }

        this.cdr.markForCheck();

        setTimeout(() => this.Form.focusField(nameof(c => c.Name), false));

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.ZustellAdressePLZ) && data.value) {
            const p = await this.apiService.get(`v1/vwbp/rechtstraeger/provinz/0208AAB3-550A-463F-BC29-17CACE610885`);
            const provinz = p.find(w => w.Identifikation === data.value);
            if (provinz && this.Model.ZustellAdresseOrt !== provinz.Bezeichnung) {
                this.Model.ZustellAdresseOrt = provinz.Bezeichnung;
                this.cdr.markForCheck();
            }
        } else if (data.field === nameof(c => c.ZustellAdresseOrt) && data.value) {
            const p = await this.apiService.get(`v1/vwbp/rechtstraeger/provinz/0208AAB3-550A-463F-BC29-17CACE610885`);
            const provinz = p.find(w => w.Bezeichnung === data.value);
            if (provinz && this.Model.ZustellAdressePLZ !== provinz.Identifikation) {
                this.Model.ZustellAdressePLZ = provinz.Identifikation;
                this.cdr.markForCheck();
            }
        }
    }

    private setFromTyp() {
        const isLI: boolean = this.Model.Typ.Id === VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_LI_HRNR;

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Name), !this.ReadOnly);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Rechtsform), !this.ReadOnly);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RegisterNummer), !this.ReadOnly || !isLI);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Sitz), isLI && !this.ReadOnly);
    }

    public async initNewModel(data?: any) {
        this.Model = null;
        this.cdr.markForCheck();
        this.Model = await this.apiService.getModel(VwbPRechtstraegerDetail, `${this.Api}/rechtstraeger/detail/create`);
        this.Model.Typ.Id = VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_AUSLAENDISCH;
        this.Fields = [];
        this.initFields();
        this.isEditMode = false;

        this.setFromTyp();

        if (data) {
            // eslint-disable-next-line guard-for-in
            for (const key in data) {
                ObjectUtils.setByPath(this.Model, key, data[key]);
                this.onAttrChange({ field: key, value: data[key], form: this.Form, model: this.Model });
            }
        }

        await this.updateDropdownSources();
    }

    ngOnInit() {
        // this.initFields();
    }


    deleteModel(model: any) {
        this.apiService.delete(`${this.Api}/rechtstraeger/detail/${model.id}`).then(r => {
            if (this.RedirectAfterDelete)
                this.router.navigate([this.RedirectAfterDelete]);
        });
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: VwbPRechtstraegerDetail) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            delegate = this.apiService.put(`${this.Api}/rechtstraeger/detail/${model.Id}`, model);
        } else {
            this.Model.Rechtstraeger_ID = this.commonService.GuidEmpty;
            delegate = this.apiService.post(`${this.Api}/rechtstraeger/register`, model);
        }
        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.RedirectAfterSave)
                this.router.navigate([this.RedirectAfterSave]);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {
        try {
            this.commonService.showGlobalLoader();
            this.Model = await this.apiService.FindModel(VwbPRechtstraegerDetail, `${this.Api}/rechtstraeger/detail/${id}`, null, null, null);
            this.Fields = [];
            this.initFields();

            if (this.ReadOnly) {
                this.HistoryStates = await this.apiService.getModelList(ZustandInfoDto, `${this.Api}/rechtstraeger/states/${this.Model.Rechtstraeger_ID}`);
                this.formFieldService.setFieldVisible(this.Fields, 'formFieldTemplate1', this.HistoryStates.length > 1);
            }

            this.setFromTyp();

            await this.updateDropdownSources();
        } finally {
            this.commonService.hideGlobalLoader();
        }

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        return this.Model;
    }

    async OnStichtagChange(stichtag: Date) {
        this.stichtag = stichtag;

        const id = this.historyService.getCurrentHistory(this.HistoryStates, this.stichtag).Id;
        const newModel = await this.apiService.FindModel(VwbPRechtstraegerDetail, `${this.Api}/rechtstraeger/detail/${id}`, null, null, null);
        this.historyService.handleHistoryHighlighting(this.Fields, this.Model, newModel);
        this.Model = newModel;
        await this.updateDropdownSources();
    }

    async updateDropdownSources() {
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        if (this.Model) {
            const isLI: boolean = this.Model.Typ.Id === VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_LI_HRNR;

            if (isLI) {
                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'VWBP.COMMON.RT', true).setMDSizeHalf().setMaxLength(512));
                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RegisterNummer), 'VWBP.COMMON.REGISTERNR', true).setMDSizeHalf().setMaxLength(128));

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Rechtsform), 'VWBP.COMMON.RECHTSFORM', true).setMDSizeHalf().setMaxLength(128));
                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Sitz), 'VWBP.COMMON.SITZ', false).setMDSizeFull().setMaxLength(128));

                // Zustelladresse
                this.Fields.push(this.formFieldService.CreateLabel('lbl1', 'VWBP.COMMON.REPRAESENTANZ_ZUSTELLADRESSE', false).setMDSizeFull());

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdresseCO), 'VWBP.COMMON.CO', false).setMDSizeFull().setMaxLength(256));

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdresseStrasse), 'VWBP.COMMON.STRASSE_NUMMER', true).setMDSizeFull().setMaxLength(512));

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdressePostfach), 'VWBP.COMMON.POSTFACH', false).setMDSizeFull().setReadonly());

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdressePLZ), 'VWBP.COMMON.PLZ', false).setMDSizeHalf().setMaxLength(128));
                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdresseOrt), 'VWBP.COMMON.ORT', true).setMDSizeHalf().setMaxLength(128));
            } else {
                if (this.ReadOnly)
                    this.Fields.push(this.formFieldService.CreateTemplate(1, 'Zustand ab/bis').setMDSizeFull());

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'VWBP.COMMON.RT_AUSLAND', true).setMDSizeHalf().setMaxLength(512));
                this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Gruendungsdatum), 'VWBP.COMMON.GRUENDUNGSDATUM_AUSLAND', true).setMDSizeHalf().setMinMaxDate(new Date(1800, 1, 1), new Date()));

                this.Fields.push(this.formFieldService.CreateLabel('lbl1', 'VWBP.COMMON.REGISTEREINTRAG_AUSLAND', false).setMDSizeFull());

                this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.RegisterLand_ID), 'VWBP.COMMON.REGISTERLAND', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RegisterOrt), 'VWBP.COMMON.REGISTERORT', false).setMDSizeHalf().setMaxLength(128));

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RegisterNummer), 'VWBP.COMMON.REGISTERNR', false).setMDSizeHalf().setMaxLength(128));
                this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.RegisterDatum), 'VWBP.COMMON.DATUM_DES_EINTRAGES', false).setMDSizeHalf().setMinMaxDate(new Date(1800, 1, 1), new Date()));

                // Inländische Zustelladresse
                this.Fields.push(this.formFieldService.CreateLabel('lbl2', 'VWBP.COMMON.INLAENDISCHE_ZUSTELLADRESSE', false).setMDSizeFull());

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdresseCO), 'VWBP.COMMON.ZUSTELLUNGSBEVOLLMAECHTIGTER', true).setMDSizeFull().setMaxLength(256));

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdresseStrasse), 'VWBP.COMMON.STRASSE_NUMMER', true).setMDSizeFull().setMaxLength(512));

                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdressePostfach), 'VWBP.COMMON.POSTFACH', false).setMDSizeFull().setReadonly());

                //                this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ZustellAdressePLZ), 'VWBP.COMMON.PLZ', true).setMDSizeHalf().setMaxLength(128));
                this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.ZustellAdressePLZ), 'VWBP.COMMON.PLZ',
                    async (field) => {
                        const ret = [];
                        if (!this.Model) {
                            return ret;
                        }
                        const p = await this.apiService.get(`v1/vwbp/rechtstraeger/provinz/0208AAB3-550A-463F-BC29-17CACE610885`);
                        p.forEach(elem => {
                            ret.push({ label: elem.Identifikation, value: elem.Identifikation });
                        });

                        return ret.sort((a, b) => a.label > b.label ? 1 : -1);
                    }, true, false, null, null, null, true, null, 'startsWith').setMDSizeHalf());

                this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.ZustellAdresseOrt), 'VWBP.COMMON.ORT',
                    async (field) => {
                        const ret = [];
                        if (!this.Model) {
                            return ret;
                        }
                        const p = await this.apiService.get(`v1/vwbp/rechtstraeger/provinz/0208AAB3-550A-463F-BC29-17CACE610885`);
                        p.forEach(elem => {
                            ret.push({ label: elem.Bezeichnung, value: elem.Bezeichnung });
                        });

                        return ret.sort((a, b) => a.label > b.label ? 1 : -1);
                    }, true, false, null, null, null, true, null, 'startsWith').setMDSizeHalf());
            }
        }
    }
}
