import { AviCommonService } from './../../../services/common.service';
import { PersonalContactData } from '@avi-x/avi-dto/partner/personalcontactdata.model';
import { Component, Input, Output, EventEmitter, ViewChild, forwardRef, ViewEncapsulation, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { RichTextEditorComponent, ToolbarService, LinkService, ImageService, HtmlEditorService, QuickToolbarService, NodeSelection, CommandName, ILinkCommandsArgs, IImageCommandsArgs, ITableCommandsArgs, ExecuteCommandOption } from '@syncfusion/ej2-angular-richtexteditor';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import Tribute from 'tributejs';
import { AviApiService } from '../../../services/api.service';
import { Dialog } from '@syncfusion/ej2-popups';
import { ButtonModel } from '@syncfusion/ej2-angular-buttons';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';

@Component({
    selector: 'avi-core-richtexteditor',
    templateUrl: './richtexteditor.component.html',
    styleUrls: ['./richtexteditor.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AviRichTextEditorComponent), multi: true },
        ToolbarService,
        LinkService,
        ImageService,
        HtmlEditorService,
        QuickToolbarService,
    ],
})
export class AviRichTextEditorComponent implements ControlValueAccessor {
    // get accessor
    get Model(): any {
        return this._Model;
    }

    // set accessor including call the onchange callback
    @Input('Model')
    set Model(v: any) {
        if (v !== this._Model) {
            this._Model = v;
            this.onChangeCallback(v);
            this.ModelChanged.emit(this._Model);
        }
    }

    constructor(public apiService: AviApiService, public commonService: AviCommonService, private emojiSearch: EmojiSearch, public cdr: ChangeDetectorRef) {
        this.Id = this.commonService.generateUUID();
    }

    public richTextEditToolbarItems: any = {
        enable: true,
        items: [
            'Undo',
            'Redo',
            '|',
            'Bold',
            'Italic',
            'Underline',
            'StrikeThrough',
            '|',
            'FontName',
            'FontSize',
            'FontColor',
            'BackgroundColor',
            '|',
            'SubScript',
            'SuperScript',
            '|',
            'LowerCase',
            'UpperCase',
            '|',
            'Formats',
            'Alignments',
            '|',
            'OrderedList',
            'UnorderedList',
            '|',
            'Indent',
            'Outdent',
            '|',
            'CreateLink',
            'CreateTable',
            'Image',
            '|',
            {
                tooltipText: 'Insert Symbol',
                undo: true,
                click: this.onClickSymbol.bind(this),
                template:
                    '<button type="button" class="e-tbar-btn e-btn" tabindex="-1" id="tbar_symbol"  style="width:100%">' +
                    '<div class="e-tbar-btn-text" style="font-weight: 500;"> Ω</div></button>',
            },
            {
                tooltipText: 'Insert Emoticons',
                undo: true,
                click: this.onClickSmiley.bind(this),
                template:
                    '<button type="button" class="e-tbar-btn e-btn" tabindex="-1" id="tbar_smiley"  style="width:100%">' +
                    '<div class="e-tbar-btn-text" style="font-weight: 500;">&#128578;</div></button>',
            },
            '|',
            'ClearFormat',
            'Print',
            '|',
            'FullScreen',
            'SourceCode'
        ],
    };

    public insertImageSettings = {
        display: 'inline',
        saveFormat: 'Base64',
    };

    @Input('id')
    Id: string = null;

    @Input('disabled')
    Disabled: boolean;

    _EnableTribute: boolean = true;
    get EnableTribute(): boolean {
        return this._EnableTribute;
    }

    @Input('enable-tribute')
    set EnableTribute(value: boolean) {
        this._EnableTribute = value;
        setTimeout(() => this.initTribute());
    }

    _ReadOnly: boolean = false;
    get ReadOnly(): boolean {
        return this._ReadOnly;
    }

    @Input('readonly')
    set ReadOnly(value: boolean) {
        this._ReadOnly = value;
        setTimeout(() => this.initTribute());
    }

    @Input('focus')
    Focus: boolean;

    @Input('required')
    Required: boolean;

    @Input('name')
    Name: string;

    _toolbarModus: 'normal' | 'onfocus' = 'normal';

    @Input('toolbar-modus')
    set ToolbarModus(value: 'normal' | 'onfocus') {
        this._toolbarModus = value;
        if (value == 'normal')
            setTimeout(() => (this.Editor.toolbarSettings.enable = true));
        else
            setTimeout(() => (this.Editor.toolbarSettings.enable = false));
    }

    @Output()
    ModelChanged: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onBlur: EventEmitter<any> = new EventEmitter();

    private _Model: any = null;

    @ViewChild('Editor')
    public Editor: RichTextEditorComponent;

    @ViewChild('SymbolDialog')
    private SymbolDialog: Dialog;

    @ViewChild('SmileyDialog')
    private SmileyDialog: Dialog;

    public SymbolDialogButtons: { [key: string]: ButtonModel }[] = [
        // { buttonModel: { content: 'Insert', isPrimary: true }, click: this.onInsertSymbol.bind(this) },
        { buttonModel: { content: 'Abbrechen' }, click: this.SymbolDialogOverlay.bind(this) },
    ];
    public SymbolHeader = 'Special Characters';
    public target: HTMLElement;

    public SmileyDialogButtons: { [key: string]: ButtonModel }[] = [
        { buttonModel: { content: 'Abbrechen' }, click: this.SmileyDialogOverlay.bind(this) },
    ];
    public SmileyHeader = 'Emoticons';

    tribute: any = null;

    private selection: NodeSelection = new NodeSelection();
    private ranges: Range;

    // Placeholders for the callbacks which are later providesd
    // by the Control Value Accessor
    private onTouchedCallback: () => void = () => {};
    private onChangeCallback: (_: any) => void = () => {};

    onCreate(e) {
        // document.onkeydown = function (e) {
        //     e.preventDefault();
        // }
    }

    public refreshUI() {
        if (this.Editor && !this._ReadOnly) this.Editor.refreshUI();
    }

    onFocusInt(e) {
        if (this._toolbarModus == 'onfocus') { 
            this.Editor.toolbarSettings.enable = true;
            setTimeout(() => this.refreshUI());
        }
    }

    // Set touched on blur
    onBlurInt(e) {
        if (this._toolbarModus == 'onfocus') this.Editor.toolbarSettings.enable = false;

        this.onTouchedCallback();
        this.onBlur.emit(e);
    }

    // ControlValueAccessor implementation
    writeValue(value: any) {
        if (value !== this._Model) {
            this._Model = value;
            this.onChangeCallback(value);
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    initTribute() {
        if ((this.ReadOnly || !this._EnableTribute) && this.tribute) {
            if (this.Editor)
                this.tribute.detach(this.Editor.inputElement);
            this.tribute = null;
        } else if (!this.ReadOnly && this._EnableTribute) {
            this.tribute = new Tribute({
                collection: [
                    {
                        trigger: '@',
                        values: (text, cb) => {
                            this.apiService
                                .getModelList(PersonalContactData, `personal/GetContactData?search=${text}`)
                                .then((users) => {
                                    const vals = users.slice(0, 15).map((w) => {
                                        return { key: w.VornameName, email: w.Email };
                                    });
                                    cb(vals);
                                });
                        },
                        menuShowMinLength: 1,
                        // spaceSelectsMatch: false,    // requires newer version
                        selectTemplate: function (item) {
                            return `<span contenteditable="false"><a href="mailto:${item.original.email}">@${item.original.key}</a></span>`;
                        },
                    },
                    {
                        trigger: ':',
                        values: (text, cb) => {
                            const results = this.emojiSearch.search(text);
                            if (!results) cb([]);
                            else
                                cb(
                                    results.slice(0, 15).map((o) => {
                                        return { value: o.native, key: o.shortName, name: o.name, colons: o.colons };
                                    })
                                );
                        },
                        menuItemTemplate: function (item) {
                            return item.original.value + ' - ' + item.original.name;
                        },
                        menuShowMinLength: 1,
                        // spaceSelectsMatch: false,    // requires newer version
                        selectTemplate: function (item) {
                            return `${item.original.value}`;
                        },
                    },
                ],
            });

            setTimeout(() => this.tribute.attach(this.Editor.inputElement));
        }
    }

    public UpdateSelectionHighlighted(): boolean {
        return this.executeCommand('backColor', 'rgb(255, 255, 0)');
    }

    public UpdateSelectionBold(): boolean {
        return this.executeCommand('bold');
    }

    public UpdateSelectionItalic(): boolean {
        return this.executeCommand('italic');
    }

    public UpdateSelectionUnderlined(): boolean {
        return this.executeCommand('underline');
    }

    public UpdateSelectionLowerCase(): boolean {
        return this.executeCommand('lowercase');
    }

    public UpdateSelectionUpperCase(): boolean {
        return this.executeCommand('uppercase');
    }

    public UpdateSelectionTitleCase(): boolean {
        return this.executeCommand('titlecase');
    }

    public UpdateSelectionSentenceCase(): boolean {
        return this.executeCommand('sentencecase');
    }

    public UpdateSelectionRemoveFormat(): boolean {
        return this.executeCommand('removeFormat');
    }

    nextUpper: boolean = true;

    private UpdateSelection(func: (str: string) => string): boolean {
        if (this.Editor.getSelection().length > 0) {

            // if (!this.isEditorActive()) return false;

            this.nextUpper = true;
            const range = this.selection.getRange(document);
            const saveSelection = this.selection.save(range, document);
            const exactNodes = this.selection.getNodeCollection(range);

            if (exactNodes.length == 1) {
                let singleContent = exactNodes[0].textContent.substring(range.startOffset - 1, range.endOffset - 1);
                exactNodes[0].textContent = exactNodes[0].textContent.replace(singleContent, func(singleContent));
            } else {
                for (let i = 0; i < exactNodes.length; i++) {
                    if (exactNodes[i].nodeName === '#text') {
                        let content: string = exactNodes[i].textContent;
                        if (range.startContainer == exactNodes[i]) {
                            content = exactNodes[i].textContent.substring(range.startOffset - 1);
                        } else if (range.endContainer == exactNodes[i]) {
                            content = exactNodes[i].textContent.substring(0, range.endOffset);
                        }
                        exactNodes[i].textContent = exactNodes[i].textContent.replace(content, func(content));
                    }
                }
            }
            saveSelection.restore();
            this.Editor.formatter.saveData();
            return true;
        }

        return false;
    }

    private toTitleCase = (str: string): string => {
        return str.toLowerCase().replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
    }

    private toSentenceCase = (str: string): string => {
        const res = (' ' + str).slice(1).toLowerCase();

        let result = '';
        for (let letter of res) {
          const isChar = /[a-zA-ZäöüÄÖÜß]/.test(letter);

          const isEgalForUpper = /[\s0-9',]/.test(letter);

          if (isChar && this.nextUpper) {
            result += letter.toUpperCase();
            this.nextUpper = false;
          } else result += letter;

          if (!isChar && !isEgalForUpper) this.nextUpper = true;
        }

        return result;
    }

    public Print() {
        this.Editor.print();
    }

    private isEditorActive(): boolean {
        // this.Editor.focusIn();

        if (!this.Id) return false;

        const range = this.Editor.getRange();
        if (range.startContainer === range.endContainer && range.startOffset === range.endOffset) return false;

        let found = false;
        let p = <HTMLElement>range.commonAncestorContainer;
        while (p) {
            if (p.id === this.Id) {
                found = true;
                break;
            }
            p = p.parentElement;
        }

        return found;
    }

    public executeCommand(
        commandName: CommandName | 'titlecase' | 'sentencecase',
        value?: string | HTMLElement | ILinkCommandsArgs | IImageCommandsArgs | ITableCommandsArgs,
        option?: ExecuteCommandOption
    ): boolean {
        // console.log('executeCommand', commandName);
        if (!this.isEditorActive()) return false;

        if (this.Editor.formatter.getUndoRedoStack().length === 0) {
            this.Editor.formatter.saveData();
        }

        if (commandName === 'titlecase')
            this.UpdateSelection(this.toTitleCase);
        else if (commandName === 'sentencecase')
            this.UpdateSelection(this.toSentenceCase);
        else
            this.Editor.executeCommand(commandName, value, option);

        this.Editor.updateValue();
        this.Editor.formatter.saveData();
        this.writeValue(this.Editor.getHtml());
        (this.Editor as any).formatter.enableUndo(this.Editor);

        return true;
    }

    private updateEditorText(text: string) {
        if (this.Editor.formatter.getUndoRedoStack().length === 0) {
            this.Editor.formatter.saveData();
        }
        this.selection.setRange(document, this.ranges);
        this.Editor.executeCommand('insertHTML', text);
        this.Editor.updateValue();
        this.Editor.formatter.saveData();
        this.writeValue(this.Editor.getHtml());
        (this.Editor as any).formatter.enableUndo(this.Editor);
    }

    // Symbols
    public onClickSymbol() {
        this.Editor.focusIn();
        this.ranges = this.selection.getRange(document);
        this.SymbolDialog.show();
    }

    public SelectSymbol(e: Event) {
        const target: HTMLElement = e.target as HTMLElement;
        if (target.classList.contains('char_block')) {
            this.updateEditorText(target.textContent);
            this.SymbolDialogOverlay();
        }
    }

    public SymbolDialogOverlay(): void {
        this.SymbolDialog.hide();
    }

    // Smiley
    public onClickSmiley() {
        this.Editor.focusIn();
        this.ranges = this.selection.getRange(document);
        this.SmileyDialog.show();
    }

    public SelectSmiley(e: any) {
        if (e.emoji && e.emoji.unified) {
            this.updateEditorText(`&#x${e.emoji.unified}`);
            this.SmileyDialogOverlay();
        }
    }

    public SmileyDialogOverlay(): void {
        this.SmileyDialog.hide();
    }
}
