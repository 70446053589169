import { SysMessage } from '@avi-x/avi-dto/system/sysmessage.model';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviBaseFormComponent } from '../../base-form/base-form.component';
import { AviChangedAttr, AviFormField } from '../../base-form/form-field';
import { AviCommonService } from '../../../services/common.service';
import { AviApiService } from '../../../services/api.service';
import { AviAuthService } from '../../../services/auth.service';
import { AviFormFieldService } from '../../../services/form-field.service';
import { ObjectUtils } from '../../utils/object-utils';
import { typed_nameof } from '../../utils/nameof';
import { AviApiErrorObject } from '../../../dto/aviapierrorobject';

const nameof = (nameFunction: ((obj: SysMessage) => any)) => typed_nameof<SysMessage>(nameFunction);

@Component({
    selector: 'avi-core-sysmessage-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviSysMessageFormComponent implements OnInit, OnDestroy {
    private _className = 'Systemmitteilung';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: SysMessage = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string { return this._ModelId; }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Input('api-prefix')
    public ApiPrefix: string = '';

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {
        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                 this.Model = null;
                 this._ModelId = value;
                 this.isEditMode = false;
             }
             return true;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));
        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    async onAttrChange(data: AviChangedAttr) {
    }

    public async initNewModel(data?: any) {
        const emptyGuid = this.commonService.GuidEmpty;
        this.Model = await this.apiService.getModel(SysMessage, `${this.ApiPrefix ?? ''}/sysmessage/${emptyGuid}`);
        this.isEditMode = false;

        this.updateDropdownSources();

        if (data) {
            // eslint-disable-next-line guard-for-in
            for (const key in data) {
                ObjectUtils.setByPath(this.Model, key, data[key]);
                this.onAttrChange({ field: key, value: data[key], model: this.Model, form: this.Form });
            }
        }
    }

    ngOnInit() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.ModelId = params['id'];
            }
        });

        this.initFields();
    }


    deleteModel(model: any) {
        this.apiService.delete(`${this.ApiPrefix ?? ''}/sysmessage/${model.id}`).then(r => {
            if (this.RedirectAfterDelete)
                this.router.navigate([this.RedirectAfterDelete]);
        });
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: SysMessage) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            delegate = this.apiService.put(`${this.ApiPrefix ?? ''}/sysmessage/${model.Id}`, model);
        } else {
            delegate = this.apiService.post(`${this.ApiPrefix ?? ''}/sysmessage`, model);
        }
        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.RedirectAfterSave)
                this.router.navigate([this.RedirectAfterSave]);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {
        this.commonService.showGlobalLoader();

        this.Model = await this.apiService.FindModel(SysMessage, `${this.ApiPrefix ?? ''}/sysmessage/${id}`, null, null, null);

        this.updateDropdownSources();

        this.commonService.hideGlobalLoader();
        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        return this.Model;
    }

    async updateDropdownSources() {
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'CORE.COMMON.MODEL.CAFSYSMESSAGE.GUELTIGAB', true).setDateTime().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'CORE.COMMON.MODEL.CAFSYSMESSAGE.GUELTIGBIS', true).setDateTime().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonSysMessageDef_ID), 'CORE.COMMON.MODEL.CAFSYSMESSAGE.VONSYSMESSAGEDEF', `${this.ApiPrefix ?? ''}/model/representations/CAFSysMessageDef${this.ReadOnly ? '?id=$id' : ''}`, true, false, 'vonSysMessageDef wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Text), 'CORE.COMMON.MODEL.CAFSYSMESSAGE.TEXT', true).setMDSizeFull());
    }
}
