import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { BasePartner } from '../partner/basepartner.model';
import { Adresse } from '../partner/adresse.model';
import { TelefonVorwahl } from '../partner/telefonvorwahl.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class PartnerKommunikation extends BaseModel {
    @Expose()
    public get PartnerKommunikationDiscriminator(): string { return this.getProperty('PartnerKommunikationDiscriminator'); }
    public set PartnerKommunikationDiscriminator(value: string) { this.setProperty('PartnerKommunikationDiscriminator', value); }

    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }

    @Expose()
    public get Eingabe(): string { return this.getProperty('Eingabe'); }
    public set Eingabe(value: string) { this.setProperty('Eingabe', value); }

    @Expose()
    public get EingabeZusatz(): string { return this.getProperty('EingabeZusatz'); }
    public set EingabeZusatz(value: string) { this.setProperty('EingabeZusatz', value); }

    @Expose()
    public get ZurSuche(): string { return this.getProperty('ZurSuche'); }
    public set ZurSuche(value: string) { this.setProperty('ZurSuche', value); }

    @Expose()
    public get KontaktaufnahmeNichtErwuenscht(): boolean { return this.getProperty('KontaktaufnahmeNichtErwuenscht'); }
    public set KontaktaufnahmeNichtErwuenscht(value: boolean) { this.setProperty('KontaktaufnahmeNichtErwuenscht', value); }

    @Expose()
    public get Encrypted(): boolean { return this.getProperty('Encrypted'); }
    public set Encrypted(value: boolean) { this.setProperty('Encrypted', value); }

    @Expose()
    public get vonPartner_ID(): string { return this.getModelId('vonPartner_ID'); }
    public set vonPartner_ID(value: string) { this.setModelId('vonPartner_ID', value); }

    @Expose()
    @Type(() => BasePartner)
    public get vonPartner(): BasePartner { return this.getModel('vonPartner'); }
    public set vonPartner(value: BasePartner) { this.setModel('vonPartner', value); }

    @Expose()
    public get vonAdresse_ID(): string { return this.getModelId('vonAdresse_ID'); }
    public set vonAdresse_ID(value: string) { this.setModelId('vonAdresse_ID', value); }

    @Expose()
    @Type(() => Adresse)
    public get vonAdresse(): Adresse { return this.getModel('vonAdresse'); }
    public set vonAdresse(value: Adresse) { this.setModel('vonAdresse', value); }

    @Expose()
    public get verwendePartnerKomm_ID(): string { return this.getModelId('verwendePartnerKomm_ID'); }
    public set verwendePartnerKomm_ID(value: string) { this.setModelId('verwendePartnerKomm_ID', value); }

    @Expose()
    @Type(() => PartnerKommunikation)
    public get verwendePartnerKomm(): PartnerKommunikation { return this.getModel('verwendePartnerKomm'); }
    public set verwendePartnerKomm(value: PartnerKommunikation) { this.setModel('verwendePartnerKomm', value); }

    @Expose()
    public get TelefonVorwahl_ID(): string { return this.getModelId('TelefonVorwahl_ID'); }
    public set TelefonVorwahl_ID(value: string) { this.setModelId('TelefonVorwahl_ID', value); }

    @Expose()
    @Type(() => TelefonVorwahl)
    public get TelefonVorwahl(): TelefonVorwahl { return this.getModel('TelefonVorwahl'); }
    public set TelefonVorwahl(value: TelefonVorwahl) { this.setModel('TelefonVorwahl', value); }

    @Expose()
    @Type(() => ListType)
    public get KommTyp(): ListType { return this.getProperty('KommTyp'); }
    public set KommTyp(value: ListType) { this.setProperty('KommTyp', value); }

    @Expose()
    @Type(() => ListType)
    public get KommArt(): ListType { return this.getProperty('KommArt'); }
    public set KommArt(value: ListType) { this.setProperty('KommArt', value); }

    @Expose()
    @Type(() => ListType)
    public get KommSync(): ListType { return this.getProperty('KommSync'); }
    public set KommSync(value: ListType) { this.setProperty('KommSync', value); }

}
