import { Expose, Type } from 'class-transformer';

import { ModelMeta } from '../system/modelmeta.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import TransformDate from '../shared/transformdate';

export class ScriptRulesetDef extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Context_ID(): string { return this.getModelId('Context_ID'); }
    public set Context_ID(value: string) { this.setModelId('Context_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get Context(): ModelMeta { return this.getModel('Context'); }
    public set Context(value: ModelMeta) { this.setModel('Context', value); }
    
    @Expose()
    @Type(() => ListType)
    public get SaveRuleOutput(): ListType { return this.getProperty('SaveRuleOutput'); }
    public set SaveRuleOutput(value: ListType) { this.setProperty('SaveRuleOutput', value); }
    
    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }
    
    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }
}
