import { BaseModelState } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { Expose, Type } from 'class-transformer';
import { RTVerMode } from '../rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';

export class VwbPSerienbriefSettingsDto extends BaseModelState {
    @Expose()
    public get VorlageDefId(): string { return this.getProperty('VorlageDefId'); }
    public set VorlageDefId(value: string) { this.setProperty('VorlageDefId', value); }

    @Expose()
    public get DocArtId(): string { return this.getProperty('DocArtId'); }
    public set DocArtId(value: string) { this.setProperty('DocArtId', value); }

    @Expose()
    @Type(() => ListType)
    public get Format(): ListType { return this.getProperty('Format'); }
    public set Format(value: ListType) { this.setProperty('Format', value); }

    @Expose()
    @Type(() => ListType)
    public get DigitalSignieren(): ListType { return this.getProperty('DigitalSignieren'); }
    public set DigitalSignieren(value: ListType) { this.setProperty('DigitalSignieren', value); }

    @Expose()
    public Mode: RTVerMode;

    @Expose()
    public ModelIds: string[];
}
