import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { ListType } from '../shared';

export class ActivityTypeSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;

    @Expose()
	public KurzBez: string;

    @Expose()
	public Bezeichnung: string;

    @Expose()
	public Remember_Months: number;

    @Expose()
	public Remember_Days: number;

    @Expose()
	public Remember_Hours: number;

    @Expose()
	public Remember_Minutes: number;

    @Expose()
	public DueIn_Months: number;

    @Expose()
	public DueIn_Days: number;

    @Expose()
	public DueIn_Hours: number;

    @Expose()
	public DueIn_Minutes: number;

    @Expose()
	public Escalate_Months: number;

    @Expose()
	public Escalate_Days: number;

    @Expose()
	public Escalate_Hours: number;

    @Expose()
	public Escalate_Minutes: number;

	@Expose()
	@Type(() => ListType)
	public EscalationToType: ListType;

	@Expose()
	@Type(() => ListType)
	public DescriptionPflicht: ListType;

	@Expose()
	@Type(() => ListType)
	public DatePrecision: ListType;

	@Expose()
	@Type(() => ListType)
	public ReminderBeforeStart: ListType;

	@Expose()
	@Type(() => ListType)
	public PeriodPossible: ListType;

	@Expose()
	@Type(() => ListType)
	public PeriodExtendType: ListType;

	@Expose()
	@Type(() => ListType)
	public ActivityPeriodeSichtbar: ListType;

	@Expose()
	@Type(() => ListType)
	public ActivityBeschreibungKommentar: ListType;

	@Expose()
	@Type(() => ListType)
	public IsRTF: ListType;

	@Expose()
	@Type(() => ListType)
	public ContinueComment: ListType;

	@Expose()
	@Type(() => ListType)
	public ReminderEnabled: ListType;

    @Expose()
	public Context1Zwingend: boolean;

    @Expose()
	public Context2Zwingend: boolean;

    @Expose()
	public Context3Zwingend: boolean;

    @Expose()
	public FolgeActZeigeWizard: boolean;

	@Expose()
	@Type(() => ListType)
	public CloseActivityAfterTask: ListType;

	@Expose()
	@Type(() => ListType)
	public RightToDelete: ListType;

	@Expose()
	@Type(() => ListType)
	public RightToChangeDates: ListType;

	@Expose()
	@Type(() => ListType)
	public RightToFinish: ListType;

	@Expose()
	public Context1ModelName: string;

	@Expose()
	public Context2ModelName: string;

	@Expose()
	public Context3ModelName: string;
}
