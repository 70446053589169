import { Component, OnInit, Input, Output, EventEmitter, forwardRef, TemplateRef, ViewChild, QueryList, ViewChildren, AfterViewChecked, ChangeDetectorRef, OnDestroy, SecurityContext, ChangeDetectionStrategy, AfterViewInit, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgForm, AbstractControl } from '@angular/forms';
import * as moment from 'moment-timezone';

import { AviCommonService } from '../../services/common.service';
import { AviFormFieldType, AviFormField, AviChangedAttr, AviFieldError, AviFieldErrorDisplayModus } from './form-field';
import { MenuItem, Message } from 'primeng/api';
import { InputmaskService } from '../../services/inputmask.service';
import { ObjectUtils } from '../utils/object-utils';
import { AviRichTextEditorComponent } from '../controls/richtexteditor/richtexteditor.component';
import { AviBaseFormDesignerComponent } from '../base-form-designer/base-form-designer.component';
import { Subject } from 'rxjs'; // --> https://stackoverflow.com/questions/63263569/o-subject-is-not-a-constructor-angular-10
import { throttleTime, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DomSanitizer } from '@angular/platform-browser';
import { AviDropdownComponent } from '../controls/dropdown/dropdown.component';
import { forEach, keys } from 'lodash-es';
import { GenericRef } from '@avi-x/avi-dto/shared';
import { AviSessionControllerService } from '../../services/session-controller.service';

@Component({
    selector: 'avi-core-base-form',
    templateUrl: './base-form.component.html',
    styleUrls: ['./base-form.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AviBaseFormComponent), multi: true },
    ],
})
export class AviBaseFormComponent implements OnInit, OnDestroy, ControlValueAccessor, AfterViewChecked, AfterViewInit {
    // The internal data model
    private _Model: any = null;

    submit: Subject<NgForm> = new Subject<NgForm>();


    @ViewChild('form', { static: false })
    public form: NgForm;

    @ViewChild('hinweisPanel', { static: false })
    public hinweisPanel: OverlayPanel;

    @ViewChildren('richtextEditors') richtextEditors: QueryList<AviRichTextEditorComponent>;

    @ViewChildren('dropdownFields') dropdownFields: QueryList<AviDropdownComponent>;

    @ViewChildren('listFields') listFields: QueryList<AviDropdownComponent>;

    public get FormTitle(): string {
        if (this.Loading)
            return 'Lade....';

        return this._formTitle;
    }

    @Output()
    ModelChange: EventEmitter<any> = new EventEmitter<any>();

    // get accessor
    @Input('Model')
    get Model(): any {
        return this._Model;
    }

    // set accessor including call the onchange callback
    set Model(v: any) {
        if (v !== this._Model) {
            this._Model = v;
            this.onChangeCallback(v);
            this.ModelChange.emit(this._Model);

            // this.disableTabStopForCalendarButtons();
        }
    }

    @Input()
    headerTemplate: TemplateRef<any>;

    @Input('field-error-position')
    public FieldErrorPosition: 'top' | 'inline' | 'both' | 'none' = 'inline';

    @Input('hidden-form')
    public HiddenForm = false;

    @Input('loading')
    public Loading = false;

    @Input('saving')
    public Saving = false;

    @Input('form-title')
    public _formTitle: string = null;

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('page-style-class')
    public PageStyleClass: string = null;

    @Input('direct-binding')
    public DirectBinding: boolean = false;

    @Input('show-buttons')
    public ShowButtons: boolean = true;

    @Input('card-content-class')
    public CardContentClass: boolean = true;

    @Input('steps-clickable')
    public StepsClickable: boolean = false;

    @Input('show-step-navigation-top')
    public ShowStepNavigationTop: boolean = false;

    @Input('show-step-navigation-bottom')
    public ShowStepNavigationBottom: boolean = true;


    @Input('prev-step-enabled-delegate')
    public PrevStepEnabledDelegate: (formComponent: AviBaseFormComponent, currentStepIndex: number, model: any) => boolean = null;

    @Input('next-step-enabled-delegate')
    public NextStepEnabledDelegate: (formComponent: AviBaseFormComponent, currentStepIndex: number, model: any) => boolean = (formComponent, currentStepIndex, model) => this.isStepValid(currentStepIndex);

    @Input('prev-step-visible-delegate')
    public PrevStepVisibleDelegate: (formComponent: AviBaseFormComponent, currentStepIndex: number, model: any) => boolean = null;

    @Input('next-step-visible-delegate')
    public NextStepVisibleDelegate: (formComponent: AviBaseFormComponent, currentStepIndex: number, model: any) => boolean = null;

    @Input('taskmenu-items')
    public TaskMenuItems: MenuItem[] = [];

    @Input('taskmenu-position')
    public TaskMenuPosition: 'top' | 'bottom' = 'top';

    @Output() onStepChange = new EventEmitter<number>();

    @Input('prev-button-label')
    PrevButtonLabel: string = 'Vorherige Seite';

    @Input('next-button-label')
    NextButtonLabel: string = 'Nächste Seite';

    @Input('prev-button-icon')
    PrevButtonIcon: string = 'pi pi-arrow-circle-left'

    @Input('next-button-icon')
    NextButtonIcon: string = 'pi pi-arrow-circle-right'


    @Input('action-buttons')
    public actionButtons: any[] = [];

    @Input('action-buttons-readonly')
    public actionButtonsReadonly: any[] = [];

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('views-padding')
    public ViewsPadding: boolean = true;

    @Input('label-alignment')
    public LabelAlignment: 'left' | 'top' = 'top';

    @Input('label-width')
    public LabelWidth: any = '120px';

    @Input('validation-delegate')
    public CustomValidationDelegate: () => boolean = null;

    @Input('save-button-icon')
    SaveButtonIcon: string = 'pi pi-save';

    @Input('save-button-label')
    SaveButtonLabel: string = 'Speichern';

    @Input('auto-btn-datepair')
    public AutoBtnDatepair: boolean = false;

    @Input('auto-focus')
    public AutoFocus = true;

    @Input('inline-modus')
    public InlineModus = false;

    @Input('buttons-alignment')
    public ButtonsAlignment: 'left' | 'right' = 'left';

    @Input('buttons-invert')
    public ButtonsInvert: boolean = false;

    @Input('buttons-padding')
    public ButtonsPadding: number = 3;

    @Input('enable-designer')
    public EnableDesigner: boolean = false;

    @Output() onSave = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();

    @Output() onAttrChange = new EventEmitter<AviChangedAttr>();
    @Output() onAttrBlur = new EventEmitter<AviChangedAttr>();

    onFormAttrChanged: Subject<AviChangedAttr> = new Subject<AviChangedAttr>();
    onFormAttrBlur: Subject<AviChangedAttr> = new Subject<AviChangedAttr>();

    @ViewChild('designer', { static: false })
    public designer: AviBaseFormDesignerComponent;

    private TaskMenuHeight: number = 0;
    @Output() onTaskMenuHeightChanged = new EventEmitter<number>();

    /*
    @Input('form-title-edit')
    private _formTitleEdit = "Eintrag bearbeiten";

    @Input('form-title-new')
    private _formTitleNew = "Eintrag erstellen";
    */

    private sub: any;

    public FormFieldType = AviFormFieldType;

    public FormMessages: Message[] = [];

    private _Fields: AviFormField[] = [];

    @Input('fields')
    public get Fields(): AviFormField[] {

        if ((this._Fields || []).find(f => f.MetaData === null))
            this.buildFieldsMetadata('fields-getter');

        return this._Fields;
    }


    public buildFieldsMetadata(trigger: string) {
        let stepIndex = -1;
        (this._Fields || []).forEach((f, i) => {
            if (f.Type == AviFormFieldType.STEP)
                stepIndex++;
            f.MetaData = {
                Index: i,
                NextGroupOrStepIndex: this.getNextGroupOrStepIndex(i + 1),
                NextGroupIndex: this.getNextGroup(i + 1),
                NextStepIndex: this.getNextStep(i + 1),
                StepIndex: f.Type == AviFormFieldType.STEP ? stepIndex : null
            };

        })
    }

    public set Fields(fields: AviFormField[]) {
        this._Fields = this.ReadOnly ? fields.filter(w => w.Type !== AviFormFieldType.STEP) : fields;
        this.buildFieldsMetadata('fields-setter');

        // this.disableTabStopForCalendarButtons();
    }

    getFieldErrors(field: AviFormField, severity: string): AviFieldError[] {
        return field.Errors.filter(w => (severity == null || w.Severity === severity) && (w.DisplayModus === AviFieldErrorDisplayModus.DIRECT || !this.pristine(field.Name, this.form)))
    }

    getNumFieldErrors(field: AviFormField, severity: string): number {
        return field.Errors.filter(w => (severity == null || w.Severity === severity)).length;
    }

    getAllFieldErrors(severity: string): AviFieldError[] {
        let errors: AviFieldError[] = [];
        this._Fields.filter(w => w.Errors.length > 0).forEach(field => errors = errors.concat(this.getFieldErrors(field, severity)));
        errors = [...new Map(errors.map(item => [item.Message, item])).values()]; // unique by Message
        return errors;
    }

    getFieldErrorString(field: AviFormField): string {
        const errors = this.getFieldErrors(field, null);
        return errors.map(w => w.Message).join('\n');
    }

    getActionButtonClass(ab: any) {
        const ret = `mr-2 ${ab.class ?? ''}`;
        return ret;
    }

    constructor(public commonService: AviCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef, protected sanitizer: DomSanitizer, private sessionController: AviSessionControllerService) {
    }

    public RefreshEditors() {
        if (this.richtextEditors)
            this.richtextEditors.forEach(w => {
                w.refreshUI();
            });
    }

    public onInputTime(event, calendar: any): void {
        this.inputMaskService.handleTimeInput(event, calendar);
    }

    public onInputDate(event, calendar: any): void {
        this.inputMaskService.handleDateInput(event, calendar);
    }

    onKeyDownEvent(field: AviFormField, event: any) {
        if (event instanceof KeyboardEvent) {
            if (field.KeyDownDelegate != null)
                field.KeyDownDelegate(event);
        }
    }

    // Placeholders for the callbacks which are later providesd
    // by the Control Value Accessor
    private onTouchedCallback: () => void = () => { };
    private onChangeCallback: (_: any) => void = () => { };

    public focusField(fieldName: string, selectText: boolean = true, timeout: number = 200) {
        if (this.ReadOnly) return;
        this.dropdownFields.filter(w => w.name === fieldName).forEach(e => e.applyFocus());
        this.listFields.filter(w => w.name === fieldName).forEach(e => e.applyFocus());

        document.getElementsByName(fieldName).forEach(e => {
            e.focus();
            if (selectText && (e instanceof HTMLInputElement || e instanceof HTMLTextAreaElement)) {
                setTimeout(() => {
                    if (e instanceof HTMLInputElement)
                        (<HTMLInputElement>e).select();
                    else if (e instanceof HTMLTextAreaElement)
                        (<HTMLTextAreaElement>e).select();
                }, timeout);
            }
        });
    }

    public focusFirstEnabledField(selectText: boolean = true, timeout: number = 200) {
        if (this.ReadOnly) return;
        const field = this._Fields.find(w => w.Visible && !w.Disabled && !w.Readonly && w.Type !== AviFormFieldType.STEP && w.Type !== AviFormFieldType.GROUP);
        if (field)
            this.focusField(field.Name);
    }

    ngAfterViewChecked() {
        this.disableTabStopForCalendarButtons();

        const taskMenuHeight = this.contentEl.nativeElement.querySelector('.taskMenuItems')?.offsetHeight;
        if (this.TaskMenuHeight != taskMenuHeight) {
            this.TaskMenuHeight = taskMenuHeight;
            this.onTaskMenuHeightChanged.emit(this.TaskMenuHeight);
        }
    }

    @ViewChild('mainContent') contentEl: ElementRef;
  
    ngAfterViewInit() {
        const taskMenuHeight = this.contentEl.nativeElement.querySelector('.taskMenuItems')?.offsetHeight;
        if (this.TaskMenuHeight != taskMenuHeight) {
            this.TaskMenuHeight = taskMenuHeight;
            this.onTaskMenuHeightChanged.emit(this.TaskMenuHeight);
        }
    }

    disableTabStopForCalendarButtons() {
        setTimeout(() => {
            this.commonService.disableTabStopByClassName('p-datepicker-trigger');
            this.commonService.disableTabStopByClassName('p-autocomplete-dropdown');
            this.commonService.disableTabStopByClassName('p-panel-titlebar-icon');
        }, 200);
    }

    public GetField(path: string): any {
        const field = this.getField(path);
        if (field && field.Type === AviFormFieldType.TEXT && field.UnderlyingType === AviFormFieldType.LISTTYPE)
            path = path + '.Bezeichnung';
        let value = this.DirectBinding ? this.getField(path).Value : ObjectUtils.getByPath(this.Model, path);

        if (value instanceof GenericRef) {
            value = value.ModelID;
        }

        return value;
    }

    public GetResolvedField(path: string): any {
        let value = this.GetField(path);

        const field = this.getField(path);
        if (field.Type == AviFormFieldType.REFERENCE || (field.Type == AviFormFieldType.DROPDOWN && field.ReadonlyDropdownAsReference && (field.Readonly || this.ReadOnly))) {
            if (field.DropdownDatasourceInternal) {
                const valFound = field.DropdownDatasourceInternal.find(i => i.value === value);
                if (valFound)
                    value = valFound.label;
            }
        }
            
        return value;
    }

    public onCheckboxChange(field: AviFormField) {
        const paramValue = this.GetField(field.Name);
        if (paramValue != null) {
            if (field.BoolRadioGroupID != null) {
                const otherParams = this._Fields.filter(w => w.BoolRadioGroupID === field.BoolRadioGroupID && w.Name !== field.Name);
                if (paramValue === true)
                    otherParams.forEach(element => this.SetField(element.Name, false));
                else if (field.Required)
                    this.SetField(field.Name, true);
            } else if (field.Value !== paramValue)
                this.SetField(field.Name, paramValue);
        }
    }

    public SetField(path: string, value: any, triggerFormAttrChange: boolean = false) {
        // if (value instanceof Date) {
        // let locTime = moment(value).clone().tz("Europe/Berlin");
        // console.warn("locTime", locTime);
        // value = locTime.format();
        // }
        if (this.DirectBinding) {
            this._Fields.find(w => w.Name === path).Value = value;

            if (triggerFormAttrChange)
                this.formAttrChange(path, value);
        } else {
            ObjectUtils.setByPath(this.Model, path, value);

            if (triggerFormAttrChange)
                this.formAttrChange(path, value);
        }

        if (this.CustomValidationDelegate != null)
            this.CustomValidationDelegate();
    }

    public SetAutocomplete(field: AviFormField, value: any) {
        this.SetField(field.AutocompleteField, value, false);
        this.SetField(field.Name, value?.value, true);
    }

    public SetAutocompleteModel(field: AviFormField, value: any) {
        let newValue = {};
        if (typeof value === 'string') {
            ObjectUtils.setByPath(newValue, field.DropdownDisplayField, value);
        } else {
            newValue = value;
        }
        this.SetField(field.Name, newValue, true);
        this.SetField(field.AutocompleteField, newValue, false);
    }

    public ClearAutocomplete(field: AviFormField) {
        this.SetAutocomplete(field, null);
    }

    public setDropdownByPath(field: AviFormField, value: any) {
        // const valueId = value && (value.id || value.Id) ? (value.id || value.Id) : null;
        // this.setByPath(field.Name + "_ID", valueId);

        this.SetField(field.Name, value, false);

        // // Wenn Projekt_ID gesetzt wird: Setze Model.Projekt auf NULL, damit POST richtig verarbeitet werden kann
        // if (field.Name.endsWith('_ID'))
        //     this.setByPath(field.Name.substr(0, field.Name.length - 3), null);


        // this.setByPath(field.Name, null);
        this.formAttrChange(field.Name, value, field.DropdownDatasourceInternal || null);
    }

    getAutocompleteDisplayValue(field: AviFormField, event: any) {
        const value = event?.value;
        if (typeof value === 'string')
            return value;
        else if (!value)
            return value;
        else if (value && field.DropdownDisplayField)
            return ObjectUtils.getByPath(value, field.DropdownDisplayField);
        else
            return value
    }


    async autoComplete(field: AviFormField, event: any) {
        if ((!event.query && field.AutocompleteMinChars > 0) || event.query.length < field.AutocompleteMinChars || field.AutocompleteDelegate == null) {
            field.DropdownDatasourceInternal = [];
        } else {
            field.DropdownDatasourceInternal = await field.AutocompleteDelegate(event.query);
        }

        this.cdr.markForCheck();
    }


    onDropdownShow(dd, evt) {
        // dd.editableInputViewChild

        setTimeout(function () {
            if (dd?.filterViewChild !== undefined) {
                dd.filterViewChild.nativeElement.focus();
            }
        }, 400);
    }

    onDropdownFocus(dd, evt) {
        // console.warn("onDropdownFocus", dd, evt);
    }

    pristine(name: string, form: NgForm): boolean {
        if (this.ReadOnly) return true;

        if (!(form.controls && form.controls[name]))
            return false;

        if (form.controls[name].disabled)
            return true;

        const field = this._Fields.find(w => w.Name === name);
        if (field && field.Type === AviFormFieldType.CHECKBOX && field.FieldGroupID != null) {
            return this._Fields.filter(w => w.Type === AviFormFieldType.CHECKBOX && w.FieldGroupID === field.FieldGroupID).map(w => w.Name).every(w => {
                return form.controls[w].pristine;
            });
        } else
            return form.controls[name].pristine;
    }

    validOrPristine(name: string, form: NgForm): boolean {
        if (this.ReadOnly) return true;

        if (!(form.controls && form.controls[name]))
            return false;

        if (form.controls[name].disabled)
            return true;

        return form.controls[name].valid || form.controls[name].pristine;
    }

    public markForCheck(){
        this.cdr.markForCheck();
        this.cdr.detectChanges();
    }

    public markAsDirty(name: string) {
        if (this.ReadOnly) return;

        if (!(this.form && this.form.controls && this.form.controls[name]))
            return;

        this.form.controls[name].markAsDirty();
        this.cdr.markForCheck();
    }

    markAsPristine() {
        if (this.ReadOnly) return;
        Object.keys(this.form.controls).forEach(control => this.form.controls[control].markAsPristine());
    }

    setErrors(name: string, error: string) {
        if (!(this.form.controls && this.form.controls[name])) {
            console.warn(`setErrors(${name}) --> Control nicht gefunden`);
            return;
        }

        this.form.controls[name].setErrors({ name: error });
    }

    getErrors(name: string): any {
        if (!this.form) return null;

        if (!(this.form.controls && this.form.controls[name]))
            return null;

        return this.form.controls[name].errors;
    }

    // clearAllErrors(){ //TODO(MSC): Error-Handling abgleichen (alles in AviFormField oder alles in AbstractControl???
    //     this.form.controls.forEach(element => {
    //     });
    // }

    clearErrors(name: string) {
        if (!(this.form && this.form.controls && this.form.controls[name]))
            return;

        this.form.controls[name].setErrors({ name: null });
        this.form.controls[name].updateValueAndValidity();
    }

    formAttrBlur(fieldName: string) {
        setTimeout(() => {
            if (this.ReadOnly) return;

            if (!this.form || !this.form.form)
                return;

            if (!(this.form.controls && this.form.controls[fieldName]))
                return;

            if (this.form.controls[fieldName].disabled)
                return;

            const field = this.getField(fieldName);
            if (field?.Readonly)
                return;

            const dirtyAndValid = this.form.controls[fieldName].dirty && this.form.form.valid && !this.Saving && (this.CustomValidationDelegate === null || this.CustomValidationDelegate());

            if (dirtyAndValid) {
                // console.log("formAttrBlur", fieldName, this.form.controls[fieldName].value);
                this.onFormAttrBlur.next({ field: fieldName, value: this.form.controls[fieldName].value, model: this.Model, form: this });
            }

        }, 500);
    }

    formAttrChange(field: string, value: any, datasource?: any[]) {
        // console.log("formAttrChange", field, value);
        this.onFormAttrChanged.next({ field: field, value: value, datasource: datasource, model: this.Model, form: this });
    }

    public getFieldStyle(field: AviFormField) {
        let style = {};
        if (field.CustomStyle)
            style = field.CustomStyle();

        return style;
    }

    public getFieldCustomClass(field: AviFormField) {
        let cl = {
            'form-readonly': this.ReadOnly,
            'form-field-error': this.getNumFieldErrors(field, 'error') > 0,
            'form-field-info': this.getNumFieldErrors(field, 'info') > 0,
            'form-field-warn': this.getNumFieldErrors(field, 'warn') > 0,
        };
        if (field.CustomClass) {
            cl = { ...cl, ...field.CustomClass() };
        }

        return cl;
    }

    toDate(str: string) {
        if (!str)
            return null;

        const dt = moment(str, 'YYYY-MM-DD').toDate();
        // console.log("toDate", { dt: dt, str: str });

        // return str;

        return dt;
    }

    toString(dt: Date) {
        if (!dt)
            return null;

        const str = moment(dt).format('YYYY-MM-DD');
        // console.log('toString', { dt: dt, str: str });
        // return dt;
        return str;
    }

    onStepActiveIndexChange(newIndex) {
        if (newIndex > this.StepsActiveIndex) {
            if (Array.from({length: newIndex-this.StepsActiveIndex}, (_, i) => i + this.StepsActiveIndex).every(idx => this._IsNextStepEnabled(idx)))
                this.StepsActiveIndex = newIndex;
        }
        else if (newIndex < this.StepsActiveIndex)
            this.StepsActiveIndex = newIndex;

        this.onStepChange.emit(newIndex);
    }

    getNextGroupOrStepIndex(startIndex: number) {
        return this._Fields.findIndex((w, index) => index >= startIndex && (w.Type === AviFormFieldType.GROUP || w.Type === AviFormFieldType.STEP));
    }

    getNextGroup(startIndex: number) {
        return this._Fields.findIndex((w, index) => index >= startIndex && (w.Type === AviFormFieldType.GROUP));
    }

    getNextStep(startIndex: number) {
        return this._Fields.findIndex((w, index) => index >= startIndex && (w.Type === AviFormFieldType.STEP));
    }


    getGroupsForStep(step: AviFormField): AviFormField[] {

        let nextStepIndex = this.Fields.findIndex((w, index) => index > step.MetaData.Index && w.Type === AviFormFieldType.STEP);
        if (nextStepIndex < 0)
            nextStepIndex = Number.MAX_VALUE;

        const nextItem = this.Fields.find((w, index) => index > step.MetaData.Index);



        if (!nextItem || nextItem.Type !== AviFormFieldType.GROUP) {
            const fakeGroup = new AviFormField(null, null, AviFormFieldType.GROUP);
            this._Fields.splice(step.MetaData.Index + 1, 0, fakeGroup);
            // console.log("adding fakegroup on ", step.MetaData.Index+1, {...this._Fields});
            this.buildFieldsMetadata('group-adder');
        }

        return this._Fields.filter((w, index) => index > step.MetaData.Index && index < nextStepIndex && w.Type === AviFormFieldType.GROUP && !w.HiddenToCustomization);

    }

    getSteps(justCurrentStep: boolean): AviFormField[] {
        let steps = this.Fields.filter(w => w.Type === AviFormFieldType.STEP && w.Visible);
        if (steps.length == 0) {
            const fakeStep = new AviFormField(null, null, AviFormFieldType.STEP);
            fakeStep.MetaData = {
                Index: -1,
                NextGroupOrStepIndex: this.getNextGroupOrStepIndex(1),
                NextGroupIndex: this.getNextGroup(1),
                NextStepIndex: this.getNextStep(1),
                StepIndex: 0
            };

            this._Fields.splice(0, 0, fakeStep);
            // console.log("adding fakestep on -1", { ...this._Fields });
            this.buildFieldsMetadata('step-adder');
            steps = this.Fields.filter(w => w.Type === AviFormFieldType.STEP);
        }

        if (justCurrentStep) {
            return steps.filter(s => s.MetaData.StepIndex === this.StepsActiveIndex);
        } else {
            return steps;
        }

    }

    get HasRealSteps(): boolean {
        const isNullOrEmpty = (value) => !(typeof value === "string" && value.length > 0);
        return this._Fields.filter(s => !isNullOrEmpty(s.Label) && s.Type === AviFormFieldType.STEP).length > 0;
    }

    get IsLastStep(): boolean {
        return this.StepsActiveIndex >= this.getSteps(false).length - 1
    }

    StepsActiveIndex: number = 0;
    getStepsModel(): MenuItem[] {
        const isNullOrEmpty = (value) => !(typeof value === "string" && value.length > 0);
        let ret = this.getSteps(false).filter(s => !isNullOrEmpty(s.Label)).map(m => { return { label: this.commonService.translateInstant(m.Label)} });
        return ret;
    }


    getVisibleFieldsByIndexrange(startIndex: number, endIndex: number): AviFormField[] {

        if (endIndex < 0)
            endIndex = Number.MAX_VALUE;

        const ret = this._Fields.filter((f, index) => index >= startIndex && index <= endIndex && (f.Type !== AviFormFieldType.HIDDEN && f.Type !== AviFormFieldType.STEP && f.Type !== AviFormFieldType.GROUP) && f.Visible && !f.HiddenToCustomization);
        // console.log("getVisibleFieldsByIndexrange", startIndex, endIndex, ret);
        return ret;
    }

    getVisibleFields(): AviFormField[] {
        return this._Fields.filter(f => f.Type !== AviFormFieldType.HIDDEN && f.Visible && !f.HiddenToCustomization && f.Type !== AviFormFieldType.GROUP && f.Type !== AviFormFieldType.STEP);
    }

    getInvisibleFields(): AviFormField[] {
        return this._Fields.filter(f => (f.Type === AviFormFieldType.HIDDEN || !f.Visible || f.HiddenToCustomization) && f.Type !== AviFormFieldType.GROUP && f.Type !== AviFormFieldType.STEP);
    }

    public clearFormMessages() {
        this.FormMessages = [];
    }

    public addFormMessage(title: string, errorType: string = null, type: string = 'error', id: any = null, splitMsgOnNewline: boolean = true, detail: string = null) {
        if (!id || this.FormMessages.findIndex(w => w.id === id) === -1) {
            if (splitMsgOnNewline) {
                const msglist = title.split(/(?:\r\n|\r|\n)/g);
                msglist.forEach(w => this.FormMessages.push({ severity: type, summary: w, detail: detail, id: id }));
            } else
                this.FormMessages.push({ severity: type, summary: title, detail: detail, id: id });

            this.cdr.markForCheck();
        }
    }

    public removeFormMessage(id: any) {
        if (id) {
            const idx = this.FormMessages.findIndex(w => w.id === id);
            if (idx != -1) {
                this.FormMessages.splice(idx, 1);
                this.cdr.markForCheck();
            }
        }
    }

    get IsPrevStepEnabled(): boolean {
        if (this.PrevStepEnabledDelegate != null)
            return this.PrevStepEnabledDelegate(this, this.StepsActiveIndex, this.Model);
        return true;
    }

    private _IsNextStepEnabled(stepIndex: number): boolean {
        if (this.NextStepEnabledDelegate != null)
            return this.NextStepEnabledDelegate(this, stepIndex, this.Model);
        return true;
    }

    get IsNextStepEnabled(): boolean {
        return this._IsNextStepEnabled(this.StepsActiveIndex);
    }

    get IsPrevStepVisible() {
        if (this.PrevStepVisibleDelegate != null)
            return this.PrevStepVisibleDelegate(this, this.StepsActiveIndex, this.Model);
        return this.StepsActiveIndex > 0;
    }

    get IsNextStepVisible() {
        if (this.NextStepVisibleDelegate != null)
            return this.NextStepVisibleDelegate(this, this.StepsActiveIndex, this.Model);
        return !this.IsLastStep;
    }

    goToPrevStep() {
        if (this.StepsActiveIndex > 0) {
            this.StepsActiveIndex--;
            this.cdr.markForCheck();

            this.onStepChange.emit(this.StepsActiveIndex);
        }
    }

    goToNextStep() {
        if (this.StepsActiveIndex < this.getSteps(false).length - 1) {
            this.StepsActiveIndex++;
            this.cdr.markForCheck();

            this.onStepChange.emit(this.StepsActiveIndex);
        }
    }

    deleteModel() {
        this.commonService.confirmDelete(`Möchten Sie den Eintrag wirklich löschen?`,
            // this.commonService.confirmDelete(`Datensatz '${this.Model.id}' wirklich löschen?`,
            () => this.onDelete.emit(this.Model)
        );
    }

    public getAllVisibleFormControls(): { [key: string]: AbstractControl; } {
        const visibleFields: AviFormField[] = [];
        this.getSteps(false).forEach(stepField => {
            this.getGroupsForStep(stepField).forEach(group => {
                if (group.Visible) {
                    const f = this.getVisibleFieldsByIndexrange(group.MetaData.Index + 1, group.MetaData.NextGroupOrStepIndex - 1);
                    visibleFields.push(...f);
                }
            });
        });

        const visibleFieldsNames = visibleFields.map(f => f.Name);
        const visibleControls: { [key: string]: AbstractControl; } = {};
        if (!this.form)
            return visibleControls;

        let controlsKeys = keys(this.form.controls);
        visibleFieldsNames.forEach(name => {
            if (controlsKeys.includes(name)) {
                visibleControls[name] = this.form.controls[name];
            }
        })
        return visibleControls;
    }

    public getVisibleFormControlsByStep(stepIndex: number): { [key: string]: AbstractControl; } {

        const visibleFields: AviFormField[] = [];
        const stepField = this._Fields.find(f => f.Type === this.FormFieldType.STEP && f.MetaData?.StepIndex === stepIndex);
        this.getGroupsForStep(stepField).forEach(group => {
            if (group.Visible) {
                const f = this.getVisibleFieldsByIndexrange(group.MetaData.Index + 1, group.MetaData.NextGroupOrStepIndex - 1);
                visibleFields.push(...f);
            }
        });

        const visibleFieldsNames = visibleFields.map(f => f.Name);
        const visibleControls: { [key: string]: AbstractControl; } = {};
        if (!this.form)
            return visibleControls;

        let controlsKeys = keys(this.form.controls);
        visibleFieldsNames.forEach(name => {
            if (controlsKeys.includes(name)) {
                visibleControls[name] = this.form.controls[name];
            }
        })
        return visibleControls;
    }

    public isStepValid(stepIndex: number): boolean {
        const visibleControls = this.getVisibleFormControlsByStep(stepIndex);

        const invalidControls = [];
        const invalidControlNames = [];

        forEach(visibleControls, (value, key) => {
            if (value.invalid) {
                invalidControls.push(value);
                invalidControlNames.push(key);
            }
        });

        //  if (invalidControlNames.length > 0)
        //      console.log("[BaseForm] invalidControlNames", invalidControlNames, invalidControls);
        return invalidControls.length == 0;
    }

    saveModel(form: NgForm) {
        this.submit.next(form);
    }

    _saveModelInternal(form: NgForm) {
        if (this.CustomValidationDelegate != null)
            this.CustomValidationDelegate();

        if (!form.valid) {
            form.control.markAsDirty({ onlySelf: false });
            // console.log('FORM NOT VALID', this.form);
            return false;
        }

        // console.log('onSave', this.Model);
        this.onSave.emit(this.Model);
        return true;
    }

    public isFormValid(): boolean {
        return this.form && this.form.valid;
    }

    public isFormPristine(): boolean {
        return this.form && this.form.pristine;
    }

    public canSaveForm(): boolean {
        return this.form && !(!this.form.valid || this.Saving || (this.CustomValidationDelegate != null && !this.CustomValidationDelegate()));
    }

    ngOnInit() {
        this.clearFormMessages();

        this.submit
            .pipe(debounceTime(200), throttleTime(700))
            .subscribe(form => this._saveModelInternal(form));

        this.onFormAttrChanged
            .pipe(
                debounceTime(200),
                distinctUntilChanged()
            )
            .subscribe(model => this.onAttrChange.emit(model));

        this.onFormAttrBlur
            .pipe(
                debounceTime(50),
                distinctUntilChanged()
            )
            .subscribe(model => this.onAttrBlur.emit(model));
    }

    ngOnDestroy() {
        this.onFormAttrChanged.unsubscribe();
        this.onFormAttrBlur.unsubscribe();
        this.submit.unsubscribe();
    }

    // Set touched on blur
    onBlur() {
        this.onTouchedCallback();
    }

    // From ControlValueAccessor interface
    writeValue(value: any) {
        if (value !== this._Model) {
            this._Model = value;
        }
    }

    // From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    // From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    public getField(paramName: string): AviFormField {
        return this._Fields.find(w => w.Name === paramName);
    }

    public getFields(pattern: RegExp): AviFormField[] {
        return this._Fields.filter(w => pattern.test(w.Name));
    }

    public getFormControls(pattern: RegExp): { [key: string]: AbstractControl } {
        let controlsKeys = keys(this.form.controls);
        let ret = {};
        controlsKeys.forEach(k => {
            if (pattern.test(k)) {
                ret[k] = this.form.controls[k];
            }
        });
        return ret;
    }

    public getFormControl(name: string): AbstractControl {
        return this.form.controls[name];
    }


    private tryUpdateDateField(param: AviFormField, dir: number) {
        const value = this.GetField(param.Name);
        if (value) {
            const type = param.DateRangeButtonType;
            const inc = param.DateRangeButtonInc * dir;
            this.SetField(param.Name, moment(value).add(inc, type).toDate(), true);
        }
    }

    onDatumDecrease(paramName: string) {
        const param = this.getField(paramName);
        if (param) {
            this.tryUpdateDateField(param, -1);

            const otherParam = this._getNeighbourParameter(param);
            if (otherParam)
                this.tryUpdateDateField(otherParam, -1);
        }
    }

    onDatumIncrease(paramName: string) {
        const param = this.getField(paramName);
        if (param) {
            this.tryUpdateDateField(param, 1);

            const otherParam = this._getNeighbourParameter(param);
            if (otherParam)
                this.tryUpdateDateField(otherParam, 1);
        }
    }

    onFollowLink(paramName: string) {
        const param = this.getField(paramName);
        if (param) {
            this.sessionController.openSession(param.LinkUrl, this.GetField(paramName));
        }
    }

    private _getNeighbourParameter(param: AviFormField): AviFormField {
        const paramName = param.Name;
        const paramNameUC = paramName.toUpperCase();

        let paramBase = null;
        if (paramNameUC.endsWith('BIS') || paramNameUC.endsWith('VON'))
            paramBase = paramNameUC.substring(0, paramNameUC.length - 3);
        else if (paramName.toUpperCase().endsWith('TO'))
            paramBase = paramNameUC.substring(0, paramNameUC.length - 2);
        else if (paramName.toUpperCase().endsWith('FROM'))
            paramBase = paramNameUC.substring(0, paramNameUC.length - 4);

        if (paramBase)
            return this._Fields.find(w => w.Type === AviFormFieldType.DATE && w.Name.toUpperCase().startsWith(paramBase) && w.Name !== paramName);

        return null;
    }

    Customize() {
        this.designer.openDialog();
    }

    resetForm() {
        this.form?.resetForm();
    }

    Hinweis: string = null;
    HinweisTextblock: string = null;
    PopupHinweis(event, hinweis: string, hinweisTextblock: string) {
        if (hinweis) {
            this.Hinweis = this.sanitizer.sanitize(SecurityContext.HTML, this.commonService.translateInstant(hinweis));
            this.HinweisTextblock = null;
        }
        if (hinweisTextblock) {
            this.HinweisTextblock = hinweisTextblock;
            this.Hinweis = null;
        }

        if (hinweis || hinweisTextblock) {
            this.hinweisPanel.toggle(event);
        }
    }
}

