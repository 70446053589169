import { Expose, Type } from "class-transformer";
import { GenericRef, ListType } from "../shared";
import TransformDate from "../shared/transformdate";

export class StatusWechselRequestInfo
{
    @Expose()
    public Id: string;

    @Expose()
    @Type(() => Date)
    public ErstellDatum: Date;

    @Expose()
    public StatusWechselDefId: string;

    @Expose()
    public vonStatusWorkflowId: string;

    @Expose()
    public UebergangGenerierenId: string;

    @Expose()
    public UebergangValidierenId: string;

    @Expose()
    public vonSqlJobDefCheckId: string;

    @Expose()
    public TaskId: string;

    @Expose()
    public Bezeichnung: string;

    @Expose()
    public Icon: string;

    @Expose()
    public StyleClass: string;

    @Expose()
    public Sorter?: number;

    @Expose()
    public TechRoleName: string;

    @Expose()
    @Type(() => GenericRef)
    public Kontext: GenericRef;

    @Expose()
    @Type(() => Date)
    public AusgefuehrtAm: Date;

    @Expose()
    @Type(() => Date)
    public ErledigtAm: Date;

    @Expose()
    public AusgefuehrtDurch: string;

    @Expose()
    public ErledigtDurch: string;

    @Expose()
    @Type(() => ListType)
    public RequestStatus: ListType;

    @Expose()
    @Type(() => ListType)
    public BemerkungEinblenden: ListType;

    @Expose()
    @Type(() => ListType)
    public BemerkungZwingend: ListType;

    @Expose()
    @Type(() => ListType)
    public KommentarEinblenden: ListType;

    @Expose()
    @Type(() => ListType)
    public Verantwortlichkeit: ListType;

    @Expose()
    public DefaultUserVonStatusWechsel_ID: string;

    @Expose()
    public DisallowUserVonStatusWechsel_ID: string;

    @Expose()
    public DefaultUser_ID: string;

    @Expose()
    public DisallowUser_ID: string;

    @Expose()
    public FristSetzen: ListType;

    @Expose()
    public FristBearbeitbar: ListType;

    @Expose()
    public FristTage: number;

    @Expose()
    public KulanzFristTage: number;

    @Expose()
    @TransformDate()
    public Frist: Date;

    @Expose()
    public KulanzFrist: number;

}
