import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviDecisionTableParamDefFormComponent } from './avi-decisiontableparamdef-form.component';
import { DecisionTableParamDefSearchResponseDto } from '@avi-x/avi-dto/system/decisiontableparamdefsearchresponsedto.model';

const nameof = (nameFunction: ((obj: DecisionTableParamDefSearchResponseDto) => any)) => typed_nameof<DecisionTableParamDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-decisiontableparamdef-searcher',
    templateUrl: './avi-decisiontableparamdef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDecisionTableParamDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF';
    SettingsKey = 'settings.searcher.decisiontableparamdef';
    BaseUrl = 'decisiontabledef/param'; //BaseUrl = 'decisiontableparamdef';
	FormType = AviDecisionTableParamDefFormComponent;

    CanEdit: boolean = false;

    @ViewChild('defparamform')
    defform: AviDecisionTableParamDefFormComponent;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFDecisionTableDef schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            // this.sessionController.openSession('decisiontableparamdef-index', this.SelectedRows.Id);
            this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.KurzBez), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.KURZBEZ1', true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.BEZEICHNUNG1', true);
        
        searcher.addIntegerColumn(nameof(c => c.Sequenz), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.SEQUENZ', true);
        
        searcher.addTextColumn(nameof(c => c.PropertyName), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.PROPERTYNAME', true);
        searcher.addListTypeColumn(nameof(c => c.ParamArt), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.PARAMART', 'caf_decisiontableparamdef_art', true, true);
        
        searcher.addListTypeColumn(nameof(c => c.AttributeType), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.ATTRIBUTETYPE', 'attribute_type', true, true);
        searcher.addListTypeColumn(nameof(c => c.VergleichsOperator), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.VERGLEICHSOPERATOR', 'caf_vergleichsoperator', true, true); 
        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        this.DetailModelId = null;
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(DecisionTableParamDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }

    public async createObject() {
        await this.commonService.openFormDialog(this.FormType, this.getFormHeader(false, false), '0', this.ContextId, this.getParams(), true);
        this.onSavedSuccessful();
    }
}
