import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { AviApiService, AviCommonService } from "@avi-x/avi-core";

@Component({
    selector: 'avi-scriptrulesetdef-view',   
    templateUrl: './scriptrulesetdef-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRulesetDefViewComponent {  
    @Input('model-id')
    public ModelId: string;

    @Output('onTestRulesetDef')
    public onTestRulesetDef: EventEmitter<string> = new EventEmitter();
        
    constructor(public apiService: AviApiService, public commonService: AviCommonService, public cdr: ChangeDetectorRef) {
    }

    TestRulesetDef() {
        this.onTestRulesetDef.emit(this.ModelId);
    }
}
