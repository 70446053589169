import { Expose } from "class-transformer";
import { AppUserGroup } from "./appusergroup.model";
import { AppUserGroupLogEntryRequestBase } from "./appusergrouplogentryrequestbase.model";

export class AppUserGroupEditDto extends AppUserGroupLogEntryRequestBase  {

    @Expose()
    AppUserGroup: AppUserGroup;

    @Expose()
    AddedTechRolesIds: string[] = [];

    @Expose()
    RemovedTechRolesIds: string[] = [];

    @Expose()
    AddedAppUserIds: string[];

    @Expose()
    RemovedAppUserIds: string[];
}