<div class="m-2">
    <div *ngIf="Adresse">
        <div class="valign-center"><strong>Adresse:</strong><a *ngIf="MapsUrl != null" (click)="MapsVisible = !MapsVisible" class="ml-1"><i class="material-icons" style="color:#666;">map</i></a></div>
        <div>{{Adresse.Strasse}} {{Adresse.HausNr}}</div>
        <div>{{Adresse.PLZ}} {{Adresse.Ort}}</div>
        <div>{{Adresse.Land.Bezeichnung1}}</div>

        <div *ngIf="MapsVisible" class="ui-g-12">
            <iframe  width="100%" height="350" frameborder="0" style="border:0;width:100%;" [src]="MapsUrl" allowfullscreen></iframe>
        </div>
    </div>
</div>
