import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { JobLogging } from '@avi-x/avi-dto/system/joblogging.model';
import { typed_nameof } from '../utils/nameof';
import { AviAbstractModelFormComponent } from '../base-form/abstract-model-form.component';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviFormFieldService } from '../../services/form-field.service';
import { AviSessionControllerService } from '../../services/session-controller.service';
import { MenuItem } from 'primeng/api';
import { AviJobService } from '@avi-x/avi-core';

const nameof = (nameFunction: ((obj: JobLogging) => any)) => typed_nameof<JobLogging>(nameFunction);

@Component({
    selector: 'avi-joblogging-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
		[views-padding]="ViewsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: AviAbstractModelFormComponent, useExisting: AviJobLoggingFormComponent }]
})
export class AviJobLoggingFormComponent extends AviAbstractModelFormComponent<JobLogging> {  
    ClassName = 'CORE.COMMON.MODEL.JOBLOGGING';
    BaseUrl = 'job/logging';
    ClassType = JobLogging;
	FormType = AviJobLoggingFormComponent;

    statusEditMode = false;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef,
        public jobservice: AviJobService,) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'Job';
    }
	
    ngOnInit(): void {
        this.BaseUrlPrefix = this.jobservice.ApiPrefix;
        super.ngOnInit();
    }

    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        if (!this.statusEditMode)
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.LogType), 'CORE.COMMON.MODEL.JOBLOGGING.LOGTYPE', 'logging_type', true, true, this.ReadOnly).setMDSizeHalf());

        let erledigtField = this.formFieldService.CreateListType(nameof(c => c.Erledigt), 'CORE.COMMON.MODEL.JOBLOGGING.ERLEDIGT', 'janein', true, true, this.ReadOnly);
        if (this.statusEditMode)
            erledigtField.setMDSizeFull();
        else 
            erledigtField.setMDSizeHalf();
        this.Fields.push(erledigtField);

        if (!this.statusEditMode)
            this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.LogMessage), 'CORE.COMMON.MODEL.JOBLOGGING.LOGMESSAGE', false).setMDSizeFull());
    }

    async getTaskMenuItems(): Promise<MenuItem[]> {
        return null;
    }

    initParams(params: { [k: string]: any; }) {
        if (params.statusEditMode)
            this.statusEditMode = params.statusEditMode as boolean;

        this.cdr.markForCheck();
    }
}
