import * as moment from 'moment-timezone';
import { DBConfig } from 'ngx-indexed-db';

export class AviListDetailConst {
    public static readonly JA = 'bd4d1844-71f9-49af-b2a3-9c07ac58c88e';
    public static readonly NEIN = 'a540c213-2b6c-4797-864c-fc50f1d6d716';
    public static readonly UNBEKANNT = '9b65a228-d28d-4b52-a426-9941bf730d02';
    public static readonly STRICH = '5c8b05c9-4d26-4789-a9da-01bc4551493e';
    public static readonly IGNORIEREN = '1fda3a3a-58a9-406d-94b3-165bb7b03000';

    public static readonly AKTIV = '880f0bf5-1047-4a27-8249-a1e33cd1cead';
    public static readonly INAKTIV = '73da8568-3cff-4e63-9394-3ad7d60e9da5';
    public static readonly DELETED = '0ec6cca6-5c42-47ec-8d92-ac5ca29793b1';

    public static readonly ATTRTYPE_INTEGER = 'c3cab614-f3f5-48ed-839a-904c98263ca9';
    public static readonly ATTRTYPE_DECIMAL = '536e8f41-da75-45c5-9a3a-2f06f488bae5';
    public static readonly ATTRTYPE_TEXT = '1697eb37-c732-4b1f-819b-dcba2d86c0c4';
    public static readonly ATTRTYPE_LISTTYPE = 'b0322597-e610-4e5c-bb0b-c30f4302ae4f';
    public static readonly ATTRTYPE_DATE = '6ade097c-cb95-4d75-a162-f0f132ec99e7';
    public static readonly ATTRTYPE_REFERENCE = 'e9803a10-05cd-41b0-8b56-706d62d2e2c8';
    public static readonly ATTRTYPE_COLOR = '8c05ea0c-b8aa-42a6-a134-964160550369';
    public static readonly ATTRTYPE_GUID = '9bfb9052-7ec9-4799-8f68-373185a5ba49';
    public static readonly ATTRTYPE_BOOL = 'db257955-0ae7-4178-897f-02993da7ae4a';
    public static readonly ATTRTYPE_TIMESPAN = '0e365738-5022-40da-a8e8-78fbadb1d6cd';
    public static readonly ATTRTYPE_MULTI_AUSWAHL = 'b8944cc3-dbf4-4570-b3d2-f0d5de9e6a87';
    public static readonly ATTRTYPE_DOCUMENT_MULTI_AUSWAHL = 'aa5de780-535d-48b7-832b-7fa21c42e563';
    public static readonly ATTRTYPE_MULTI_REFERENCE = '602b973e-7902-44f4-8e2f-f3056de00bba';

    public static readonly PROPERTY_TYPE_GROUP = 'a2b5fe57-e5a7-4657-b026-ed25e19459df';
    public static readonly PROPERTY_TYPE_TABLE = '153943e8-f8e9-4907-b37b-d67db41e67f8';
    public static readonly PROPERTY_TYPE_EMPTY = 'ad66a667-6896-4f95-874f-9799cd4f40de';
    public static readonly PROPERTY_TYPE_LABEL = '580de3b8-10ea-4b2a-869f-a23424692378';

    public static readonly CAF_DATE_CHOICE_DEFINED = '153c1634-40b9-419e-ae1d-8d2762585a1b';
    public static readonly CAF_DATE_CHOICE_TOMORROW = '45b0439e-87a3-4207-b631-bf693c12c51a';
    public static readonly CAF_DATE_CHOICE_TODAY = 'f7f44bb5-ef73-4365-a28f-60e0f6ff72d4';
    public static readonly CAF_DATE_CHOICE_YESTERDAY = '52a0f053-b399-4a79-98f5-08ab8c4a0446';
    public static readonly CAF_DATE_CHOICE_WEEKBEGIN = '24c55731-f2c4-47b2-897c-188788e18991';
    public static readonly CAF_DATE_CHOICE_WEEKEND = '605eea44-443b-419d-ade7-292d33995fe5';
    public static readonly CAF_DATE_CHOICE_MONTHBEGIN = 'f6b62828-5fed-457d-8e04-dedf654be8a4';
    public static readonly CAF_DATE_CHOICE_MONTHEND = '1893d3f9-346b-41bd-b57c-58e29530c62f';
    public static readonly CAF_DATE_CHOICE_YEARBEGIN = 'ea37fcea-ef78-4879-81d8-d2bbcdfa5434';
    public static readonly CAF_DATE_CHOICE_YEAREND = 'e9b43ecc-ab27-467c-bb99-d3e89fc175b3';

    public static readonly CAF_INFODESKDETAIL_DARSTELLUNG_LIST = '1939830e-4419-470b-b333-bc6389fb2b9b';
    public static readonly CAF_INFODESKDETAIL_DARSTELLUNG_CHART = '79458f3c-85d5-4567-8a3f-29f98eb2e96d';

    public static readonly CAF_CHARTTYPE_BAR = '216353d5-edf2-4a0b-9b41-7b456e0fed4d';
    public static readonly CAF_CHARTTYPE_BAR_STACKED = '8d51085f-3fb4-48f1-b19e-ab9cf879fd84';
    public static readonly CAF_CHARTTYPE_BAR_FULL_STACKED = '16239d5a-a62a-40d5-8ec8-82426b9c497f';
    public static readonly CAF_CHARTTYPE_PIE = '682b864c-2946-4f3c-a7fe-e14ea6cd020d';
    public static readonly CAF_CHARTTYPE_DOUGHNUT = 'd779c317-a352-47b6-9c16-a1d3e6d95534';
    public static readonly CAF_CHARTTYPE_POINT = 'ec0278f6-8994-4181-9380-008f63f6cc8f';
    public static readonly CAF_CHARTTYPE_LINE = '6dac9daf-a160-495e-a5da-79bed80d3bc3';
    public static readonly CAF_CHARTTYPE_SPLINE = 'd716d6ed-cf4e-4ff9-94da-c2395ab6974a';
    public static readonly CAF_CHARTTYPE_STEP_LINE = 'e48b98a4-a0d0-4962-bc77-acc6cd4ff5e5';
    public static readonly CAF_CHARTTYPE_AREA = 'dd705435-40fb-47f9-a891-786dbefb68d2';
    public static readonly CAF_CHARTTYPE_SPLINE_AREA = 'd52f5c2a-3b83-4bd5-8ade-50e8a58a5c08';
    public static readonly CAF_CHARTTYPE_STEP_AREA = 'f72045c8-c270-48b3-8afa-f8e89bc2fad5';
    public static readonly CAF_CHARTTYPE_LINE_STACKED = '4ff904d8-1b69-4b1a-b022-a003fc6b7c01';
    public static readonly CAF_CHARTTYPE_LINE_FULL_STACKED = '3ac98218-c495-4b0e-9aa3-e8f4f53140f2';

    public static readonly CAF_CHART_LEGEND_HALIGNMENT_RIGHT = 'c4094f5f-4c88-4852-84e8-15784c43b2a7';
    public static readonly CAF_CHART_LEGEND_HALIGNMENT_RIGHTOUTSIDE = '5ac040a2-34a7-473a-93ee-bf957a383373';
    public static readonly CAF_CHART_LEGEND_HALIGNMENT_LEFT = '407c1d39-808a-462d-b281-d5f7f7da1a51';
    public static readonly CAF_CHART_LEGEND_HALIGNMENT_LEFTOUTSIDE = '0cdc7ea9-c11c-48c9-8790-91e423b1217c';
    public static readonly CAF_CHART_LEGEND_HALIGNMENT_CENTER = 'cc8aaefe-7bec-4017-824d-45fcdd0499d8';

    public static readonly CAF_CHART_LEGEND_VALIGNMENT_TOP = '5308e55d-4ef6-4a3f-a38e-203edd04bb03';
    public static readonly CAF_CHART_LEGEND_VALIGNMENT_TOPOUTSIDE = '436dd7ba-f8c3-4217-8b60-8cd49ea71e60';
    public static readonly CAF_CHART_LEGEND_VALIGNMENT_BOTTOM = '812bb143-1197-4d7a-b8a6-77c16365e0a6';
    public static readonly CAF_CHART_LEGEND_VALIGNMENT_BOTTOMOUTSIDE = '8b823be2-79a2-4683-b470-1ed139591616';
    public static readonly CAF_CHART_LEGEND_VALIGNMENT_CENTER = '44f52c8f-e9d5-4bd4-b03e-ca3c5c481f76';

    public static readonly CAF_CHART_LEGEND_DIRECTION_LEFTTORIGHT = 'defb3085-96cf-4946-9285-4b00caced8c3';
    public static readonly CAF_CHART_LEGEND_DIRECTION_TOPTOBOTTOM = '09d75e93-c954-454b-9908-b276f01d1f1c';

    public static readonly CAF_COLORMAPPING_RANGE_ROW = 'ffeb382a-2eda-40d6-8961-89605bf76e60';
    public static readonly CAF_COLORMAPPING_RANGE_CELL = '50710219-3b9a-4fa2-a76d-70aca666a3ef';

    public static readonly ACTIVITY_ESKALATION_TO_TYP_KEINE = '7f1eaa17-902f-409d-89c8-18ff93dcf2ad';
    public static readonly ACTIVITY_ESKALATION_TO_TYP_OWNER = '6f2dbc4b-9885-40ef-a579-a9ad17a66aba';
    public static readonly ACTIVITY_ESKALATION_TO_TYP_ASSIGNEDTO = 'a6e786d8-f9e3-43df-b420-381d00edd0be';
    public static readonly ACTIVITY_ESKALATION_TO_TYP_FIX_PERSON = '7ed0a21f-f632-441b-8ddc-7e0e75b20443';

    public static readonly ACTIVITY_DATEPRECISION_DAY = '653961e7-f54d-4a11-b0a3-47c53ac0aa1c';
    public static readonly ACTIVITY_DATEPRECISION_DAY_HOUR_MINUTE = '7377cdc3-737c-4fbb-82c3-fd51bc4f6f8e';

    public static readonly ACTIVITY_BESCHREIBUNG_KOMMENTAR_BEIDE = 'e8eb6690-8789-4b8f-abde-c841a1435b77';
    public static readonly ACTIVITY_BESCHREIBUNG_KOMMENTAR_NUR_BESCHREIBUNG = '820d95ae-14ea-4643-8388-e370b725e143';
    public static readonly ACTIVITY_BESCHREIBUNG_KOMMENTAR_NUR_KOMMENTAR = 'd3c58385-dd3b-4d05-9e21-cebd7d2b2d17';

    public static readonly AP_HOCH = '5cea3dd1-876d-4630-8bb2-161854a25ede';
    public static readonly AP_NORMAL = '0b63ef93-5f5b-4463-bd5e-c140bb7a5375';
    public static readonly AP_TIEF = '746ed1f7-3bbc-4a9a-9a74-d2d62b08ca92';

    public static readonly APT_NEIN = '39e94b34-963f-4f61-a682-965dc373b19b';
    public static readonly APT_TAG = '67db2d10-c21c-40a1-b4ea-4a6b803844f4';
    public static readonly APT_WOCHE = '018e6197-8804-48e2-85ab-94228a79f235';
    public static readonly APT_MONAT = '92a1a28b-f9c1-42bb-b9bb-52ef1937254c';
    public static readonly APT_JAHR = 'fc6ce848-7881-41fe-a504-6a6c0aef5eaa';
    
    public static readonly APD_TAG_DEFINED = '3de0ac2e-cf04-47ec-a28a-772c39d56bcc';
    public static readonly APD_MONATSENDE = '3479aba6-6a6a-4b9b-83d7-0d4a31bcb96f';
    
    public static readonly APT_STARTDATE = 'b1ad8dd9-377d-49ab-9540-e2086413b1ab';
    public static readonly APT_DUEDATE = '94247f3d-4106-4497-b011-1a8e2e95920b';

    public static readonly LOGGING_TYPE_INFO = 'f654287d-3d2a-496a-8987-1920d3cffab7';
    public static readonly LOGGING_TYPE_WARNING = 'a2622f39-83d7-40fc-bbd2-3413285f8a0c';
    public static readonly LOGGING_TYPE_ERROR = 'c746a3a4-f0a0-4277-bbb1-10da3af557e5';

    public static readonly JOB_STATUS_BOOKED = '1df3e31e-5e31-4d52-802f-7d6ebfb8ce21';
    public static readonly JOB_STATUS_RUNNING = '3c5a0fe3-10d3-4c8e-b9dd-4aedf3062f0d';
    public static readonly JOB_STATUS_CANCELED = '153abad9-034f-47ce-9ae3-10c6416b5bc2';
    public static readonly JOB_STATUS_FAILED = '6e8a8493-d396-42af-93db-149076edcc97';
    public static readonly JOB_STATUS_SUCCEEDED = 'fe0cbe63-8e3e-4001-b40d-9b1f776c4986';

    public static readonly JOBITEM_STATUS_OFFEN = '2686171b-51a9-4a46-b97c-e8ba31e34d5e';
    public static readonly JOBITEM_STATUS_FAILED = '3476bcf8-8968-46ce-b247-83758468187f';
    public static readonly JOBITEM_STATUS_DONE = '5f1e411b-6e2f-4173-9fd6-c3e226db44bd';

    public static readonly CAF_AUTHENTICATION_PASSWORD = '6ad1df91-40e9-4752-b58d-b7dd1231dade';
    public static readonly CAF_AUTHENTICATION_TOTP = '69ca33a5-b169-4cc9-891e-a72ddd8cb568';
    
    public static readonly CAF_QUERYOPERATOR_IS_NULL = '1dfac742-c703-406e-a6c6-49b3070f1a1e';
    public static readonly CAF_QUERYOPERATOR_IS_NOTNULL = 'bb902180-c123-456d-936f-3eb4797e1234';
    public static readonly CAF_QUERYOPERATOR_LIKE = '25c69de1-ba69-4a62-ad76-21baeec6ab51';
    public static readonly CAF_VERGLEICHSOPERATOR_EQ = 'a8f730c8-4548-45d1-a26a-7a70f48547ed';
    public static readonly CAF_VERGLEICHSOPERATOR_NEQ = '84698453-a529-4d3f-b0d6-2bc97048afca';
    public static readonly CAF_VERGLEICHSOPERATOR_LT = '0109e396-7d63-40ae-a177-fa827d67deb8';
    public static readonly CAF_VERGLEICHSOPERATOR_LTE = '33f92a24-c7c0-43ff-ae16-073a2c1d80af';
    public static readonly CAF_VERGLEICHSOPERATOR_GT = '6f4f3a88-6048-4210-a7ba-d028363f9bde';
    public static readonly CAF_VERGLEICHSOPERATOR_GTE = 'fc567a8a-c4d4-4e71-ac75-7ba4435da5dd';

    public static readonly CAF_RUNDUNGSREGEL_KEINE = 'ed4b964c-0c5a-4e52-8931-6f3a7b7d5279';
	public static readonly CAF_RUNDUNGSREGEL_AUFRUNDEN = '979ce6b2-3eec-41bc-b886-68a93d35f947';
	public static readonly CAF_RUNDUNGSREGEL_ABRUNDEN = '26b1fe73-8ea8-45b7-bfc1-21180ae8e123';
	public static readonly CAF_RUNDUNGSREGEL_KAUFM_RUNDEN = 'c522d102-7124-4966-b399-f4356670c619';

	public static readonly CAF_RUNDUNGSGENAUIGKEIT_0 = '66ce37ae-9fd3-4a23-9593-dc2a5d467c0f';
	public static readonly CAF_RUNDUNGSGENAUIGKEIT_1 = '6933d6c7-4883-4a77-974e-c73fdafd8f78';
	public static readonly CAF_RUNDUNGSGENAUIGKEIT_5 = 'd496eb85-f035-48f9-ad4d-59c22e2c2774';
	public static readonly CAF_RUNDUNGSGENAUIGKEIT_10 = 'e65a9596-5c7d-4881-ae82-e8e47ccedff5';
	public static readonly CAF_RUNDUNGSGENAUIGKEIT_15 = 'be7280fa-8eb6-4ca6-8d02-55de09acf87e';
	public static readonly CAF_RUNDUNGSGENAUIGKEIT_20 = '2e173c3a-9329-4735-8bea-82fe0c93f650';
	public static readonly CAF_RUNDUNGSGENAUIGKEIT_30 = '0d135873-6bd4-463a-b61a-7c016f39df34"';
	public static readonly CAF_RUNDUNGSGENAUIGKEIT_50 = '25a1dcd6-6d72-4ba5-8980-2b3d49d3ee89';
	public static readonly CAF_RUNDUNGSGENAUIGKEIT_60 = 'f11c2386-2f3a-4f01-8c99-8f7a7cc47e4f';
	public static readonly CAF_RUNDUNGSGENAUIGKEIT_100 = '0df26f33-ee87-4aed-8e82-410be007b337';

    public static readonly CAF_SICHTBARKEIT_SICHTBAR = 'bbedece0-be9b-454f-9d49-995b8b28e826';
    public static readonly CAF_SICHTBARKEIT_AUSBLENDEN = '40e27b83-3de1-440d-a7c0-8a213e27467e';
    public static readonly CAF_SICHTBARKEIT_AUSBLENDEN_WENN_LEER = '42499632-7ebe-438c-9b31-5f2600106877';
    public static readonly CAF_SICHTBARKEIT_AUSBLENDEN_GUI_SICHTBAR_DRUCKEN = 'e4f1cb1b-0dde-4246-8922-f4bccbbeea27';
    public static readonly CAF_SICHTBARKEIT_SICHTBAR_GUI_AUSBLENDEN_DRUCKEN = '8b64b04c-b2e2-4df1-ac29-86aba20a1c4a';

    public static readonly CAF_SCRIPTRULEOUTPUT_STATUS_OFFEN = '9d92c7f8-be09-4a47-97ff-f1e31845768d';
    public static readonly CAF_SCRIPTRULEOUTPUT_STATUS_GEPRUEFT1 = '16e4ec2b-a888-4e9c-ac60-315f0e23cbba';
    public static readonly CAF_SCRIPTRULEOUTPUT_STATUS_GEPRUEFT2 = '942a5ab5-daa0-475f-b32c-b1950e495f0d';
    public static readonly CAF_SCRIPTRULEOUTPUT_STATUS_AUTO_ERLEDIGT = '16f8a1a9-c53e-4d1d-a19f-c95d930b54ab';
    
    public static readonly CAF_RULEDEF_ASSIGNMENT_STRICH = '455d75dc-e271-4c4f-9c29-7abecdf7768f';
    public static readonly CAF_RULEDEF_ASSIGNMENT_SB = '11921930-bad9-42bc-bf2a-d40069b8f0d7';
    public static readonly CAF_RULEDEF_ASSIGNMENT_REV = 'e48e0502-edc2-46ca-959d-870b1ce1373d';

    public static readonly CAF_RULESETDEF_SAVEOUTPUT_ON_VIOLATION = '9f9330c4-786e-4222-b117-2184aa2cfb00';
    public static readonly CAF_RULESETDEF_SAVEOUTPUT_ALWAYS = '078e13eb-0524-416b-9002-3870cc98bd49';

    public static readonly CAF_ERROR_SEVERITY_ERROR = '581acf28-191e-4eaf-9970-12d1f9dff9d4';
    public static readonly CAF_ERROR_SEVERITY_WARN = 'd76b0698-94c5-4700-9e40-0c16a2f4223b';
    public static readonly CAF_ERROR_SEVERITY_INFO = 'a86cb94e-075f-4a46-923e-f27d044e45f0';
    
    public static readonly CAF_STATUSWORKFLOW_TYP_AKTIVITAETSSTATUS = '12b9d48f-7274-43bf-9e59-085f9927adc6';
    public static readonly CAF_STATUSWORKFLOW_TYP_BEWILLIGUNGSPROZESS = 'e6850979-a889-4a9a-bcf5-4e0b599fb884';

    public static readonly CAF_STATUSWECHSELREQUEST_STATUS_OFFEN = 'bfa58025-d2a4-4ccb-958d-d04461481926';
    public static readonly CAF_STATUSWECHSELREQUEST_STATUS_AUSGEFUEHRT = '466c73be-7c1f-4287-9ea0-5e149d984b65';
    public static readonly CAF_STATUSWECHSELREQUEST_STATUS_ERLEDIGT = '111bacfa-70db-49ac-b9d1-7a87474c83f7';
    public static readonly CAF_STATUSWECHSELREQUEST_STATUS_ABGEBROCHEN = '07acb12d-9ed8-42f9-b53e-dbbcff62d818';

    public static readonly CAF_STATUSWORKFLOW_STATUS_INARBEIT = 'f0e0b0f5-87e6-458d-9946-5ad8c11494dc';
    public static readonly CAF_STATUSWORKFLOW_STATUS_ERLEDIGT = 'decb8aa8-a3f4-4df9-86ef-846a374f608a';
    public static readonly CAF_STATUSWORKFLOW_STATUS_ABGEBROCHEN = '50e48e2a-8b20-4133-b700-0f004e96f4cd';
    
    public static readonly CAF_STATUSDEF_SICHTBARKEIT_BEIDE = '5cb5c9b4-bd5a-4b72-a831-634fea6814c2';
    public static readonly CAF_STATUSDEF_SICHTBARKEIT_INTERN = 'bd6a1de7-8a7d-4abb-844d-8cb3ddb88059';
    public static readonly CAF_STATUSDEF_SICHTBARKEIT_EXTERN = 'f71cf9da-1b16-40e3-b71d-4638915b26b6';

    public static readonly CAF_STATUSDEF_VERANTWORTLICHKEIT_ANTRAGSTELLER = 'dcca4c08-fad9-4eb1-8f7c-fa68935db324';
    public static readonly CAF_STATUSDEF_VERANTWORTLICHKEIT_SACHBEARBEITER = 'a8baf138-0340-4c6d-b902-962f4382ef97';

    public static readonly CAF_BUSINESSCASE_PRIORITY_TIEF = '252d64c4-a1c8-44f1-b7cf-34d8a282902f';
    public static readonly CAF_BUSINESSCASE_PRIORITY_NORMAL = 'c8f27a26-a085-4cc5-97ee-b32e0decca6c';
    public static readonly CAF_BUSINESSCASE_PRIORITY_HOCH = 'ae834e80-6a43-4ebb-b29a-bdb685919852';

    public static readonly CAF_BUSINESSCASEDATA_TYP_DOC_VERKNUEPFEN = '3f0fadbb-91af-4039-8ac8-66874154b606';
    public static readonly CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN = 'b12e31e9-31b8-44d9-8480-e16b69bb8f0a';
    public static readonly CAF_BUSINESSCASEDATA_TYP_DOC_FINALISIEREN = 'a97f7e3c-54af-4831-acd1-8a6f86712ed8';
    public static readonly CAF_BUSINESSCASEDATA_TYP_FORM_ERSTELLEN = 'f24703f6-a493-4813-a884-88da56056646';
    public static readonly CAF_BUSINESSCASEDATA_TYP_DATEN_KORRIGIEREN = '81394981-be89-4e61-aa3f-3fa3a151f824';
    public static readonly CAF_BUSINESSCASEDATA_TYP_DATEN_VEROEFFENTLICHEN = 'f2eb2e3a-5336-4142-a060-12a59e8b61ad';
    public static readonly CAF_BUSINESSCASEDATA_TYP_AKTIVITAET_ERSTELLEN = 'b4e50049-c714-4765-9148-c62454e543f5';
    public static readonly CAF_BUSINESSCASEDATA_TYP_CUSTOM_DATA = 'c3b30d3f-a2a7-45d4-b454-4145079672f1';

    public static readonly CAF_BUSINESSCASEDATA_DOCFORMAT_PDF = 'aaec5186-f3cf-4594-a549-ebf1488ea9a1';
    public static readonly CAF_BUSINESSCASEDATA_DOCFORMAT_WORD = '680500e6-ea8e-4f60-8b9a-125705e59bb8';

    public static readonly CAF_BUSINESSCASEDATA_CONTEXT_BUSINESSCASE = 'ae9cca5a-02a8-4138-8a77-c02e505ea8e2';
    public static readonly CAF_BUSINESSCASEDATA_CONTEXT_CONTEXT1 = 'c0e3ef47-a6c1-4a90-a55c-1282126e3d9c';
    public static readonly CAF_BUSINESSCASEDATA_CONTEXT_CONTEXT2 = 'a2238b43-970a-479b-9f36-679eef996bdf';

    public static readonly CAF_BUSINESSCASE_CONTEXTRIGHTS_NICHT_PRUEFEN = 'e5bb5134-93fb-4181-b765-78d7ad6da412';
    public static readonly CAF_BUSINESSCASE_CONTEXTRIGHTS_INTERN = '74e26430-33da-4a7d-8d25-d0424df0d5db';
    public static readonly CAF_BUSINESSCASE_CONTEXTRIGHTS_EXTERN = '911ef416-71c5-4d89-a02c-36b1a0b1cc02';
    public static readonly CAF_BUSINESSCASE_CONTEXTRIGHTS_BEIDE = 'cb97587e-cd0f-45fb-a60a-17145180352b';

    public static readonly CAF_EMAILDEF_TYP_TEXT = 'ddb5a920-bb79-4633-9e84-c59ab8edbf78';
    public static readonly CAF_EMAILDEF_TYP_HTML = '3375f833-2e5b-400c-b533-5576514c787e';
    public static readonly CAF_EMAILDEF_TYP_HTML_TEMPLATE = '9ab9fc15-3019-4911-bd59-d8567d770445';

    public static readonly CAF_CUSTOMPROPERTY_READONLY_NUR_BEARBEITBAR_ERFASSEN = '60b4218f-e749-41bb-b2fe-b873d6ba22d5';

    public static readonly JOBDEF_JOBTYPE_FILEEXPORT = "47173c3c-481b-4f43-b7b5-7870c470d0ce";
    public static readonly JOBDEF_JOBTYPE_FILEIMPORT = "fb692639-48cc-4a1f-ad5f-9eaec34531b8";
    public static readonly JOBDEF_JOBTYPE_PROCESSOR = "84ba6a18-be4b-4cee-81b2-bc770c0bdf7f";
    public static readonly JOBDEF_JOBTYPE_XMLDOCUMENT = "62328f33-4ff8-4b7c-b71e-501bc7dc3658";
}

export class AviPartnerListConst {
    public static readonly CAF_ADRESSTYP = 'caf_adresstyp';
    public static readonly CAF_GESCHLECHT = 'caf_geschlecht';
    public static readonly CAF_ZIVILSTAND = 'caf_zivilstand';
    public static readonly CAF_ROLLEDEF_VERWENDUNG = 'caf_rollendef_verwendung';
    public static readonly CAF_ROLLENDEF_MULTIPLICITY = 'caf_rollendef_multiplicity';
    public static readonly CAF_ROLLENDEF_ROLLENTYP = 'caf_rollendef_rollentyp';
    public static readonly CAF_ROLLENDEF_LABELTYP = 'caf_rollendef_labeltyp';
    public static readonly CAF_PARTNERKOMM_TYP = 'caf_partnerkomm_typ'; // standard, arbeit, zusatz
    public static readonly CAF_PARTNERKOMM_ART = 'caf_partnerkomm_art'; // email, handy, etc..
    public static readonly CAF_PARTNER_ART = 'caf_partner_art';
    public static readonly CAF_PARTNER_ART_OHNE_BANK = 'caf_partner_art_ohne_bank';
    public static readonly CAF_SALDORELEVANT = 'caf_saldorelevant';
    public static readonly CAF_ZEICHNUNGSRECHT = 'caf_zeichnungsrecht';
    public static readonly CAF_PROFIL_MULITPLICITY = 'caf_profil_mulitplicity';
    public static readonly CAF_DOMIZILART = 'caf_domizilart';
    public static readonly CAF_RISKTYPE = 'caf_risktype';
    public static readonly CAF_ROLLEN_BEZIEHUNGEN_SORTIERUNG = 'caf_rollen_beziehungen_sortierung';
    public static readonly CAF_WAEHRUNGSKURSART = 'caf_waehrungskursart';
    public static readonly CAF_POTENTIAL_ERFOLGSSTATUS = 'caf_potential_erfolgsstatus';
    public static readonly CAF_POTENTIAL_BEARBEITUNGSSTATUS = 'caf_potential_bearbeitungsstatus';
    public static readonly CAF_POTENTIAL_POTENTIALTYP = 'caf_potential_potentialtyp';
    public static readonly CAF_MITARBEITERSTUFE = 'caf_mitarbeiterstufe';
    public static readonly CAF_IDENTIFIKATIONSART = 'caf_identifikationsart';
    public static readonly CAF_IDENTIFIKATION_ISEXTERNAL = 'caf_identifikation_isexternal';
    public static readonly CAF_LANDPLZORTORDER = 'caf_landplzortorder';
    public static readonly CAF_ANREDETITELVERZEICHNISTYP = 'caf_anredetitelverzeichnistyp';
    public static readonly CAF_ANSTELLUNGSVERHAELTNIS = 'caf_anstellungsverhaeltnis';
    public static readonly CAF_KONFESSION = 'caf_konfession';
    public static readonly CAF_ROLLEDEF_ENCRYPTED = 'caf_rollendef_encrypted';
    public static readonly CAF_PARTNERKOMM_SYNC = 'caf_partnerkomm_sync';
    public static readonly CAF_ADRESS_SYNC = 'caf_adress_sync';
    public static readonly CAF_LOHNBASIS = 'caf_lohnbasis';
    public static readonly CRM_CC_IDENTIFIKATIONWEISE = 'compliance_identifikationsweise';
    public static readonly CRM_CC_ID_NACHWEIS = 'compliance_id_nachweis';
    public static readonly CRM_CC_BERUFSTAND = 'compliance_berufstand';
    public static readonly CRM_CC_VERMOEGEN_GESCHAETZT = 'compliance_vermoegen_geschaetzt';
    public static readonly CRM_CC_TH_GEWAEHLT = 'compliance_th_gewaehlt';

    public static readonly CAF_IDENTIFIKATIONSDOKUMENT = 'caf_identifikationsdokument';
    public static readonly CAF_FORMDESNACHWEISES = 'caf_formdesnachweises';
    public static readonly CAF_EIGENMITTEL = 'caf_eigenmittel';
    public static readonly CAF_TREUHAENDERGEWAEHLTDURCH = 'caf_treuhaendergewaehltdurch';
    public static readonly CAF_KONTAKTCODE = 'caf_kontaktcode';

    public static readonly CAFADRESSCODE_ART = 'cafadresscode_art';

    public static readonly CAF_ANTEILTAG = 'caf_anteiltag';
    public static readonly CAF_TAGESTEIL = 'caf_tagesteil';
    public static readonly CAF_MONAT = 'caf_monat';
    public static readonly CAF_TAGTYPE = 'caf_tagtype';
    public static readonly CAF_VERWENDUNG_ABSENZ = 'caf_verwendung_absenz';
    public static readonly CAF_ABSENZ_STATUS = 'caf_absenz_status';
    public static readonly CAF_GUTHABEN_TYP = 'caf_guthaben_typ';
    public static readonly CAF_COLMAP_TYP = 'caf_colmap_typ';
    public static readonly CAF_ERSTELL_QUELLE = 'caf_erstell_quelle';

    public static readonly CAF_PVS_BESTEUERUNG = 'caf_pvs_besteuerung';
    public static readonly CAF_MANDAT_PFLICHTCODE = 'caf_mandat_pflichtcode';
    public static readonly CAF_FATCASTATUS = 'caf_fatcastatus';

    public static readonly CAF_VPORGFUNKTION = 'caf_vporgfunktion';

    public static readonly CAF_PARTNERROLLEGRAPH_VERWENDUNG = 'caf_partnerrollegraph_verwendung';
    public static readonly CAF_PARTNERROLLEGRAPH_ROLLEDARSTELLUNG = 'caf_partnerrollegraph_rolledarstellung';

    public static readonly CAF_JURPERSON_REGISTERART = 'caf_jurperson_registerart';
    public static readonly CAF_PVS_STATUS = 'caf_pvs_status';
    public static readonly CAF_JURPERSON_KLASSIFIZIERUNG = 'caf_jurperson_klassifizierung';
    public static readonly CAF_DISKRETIONAER = 'caf_diskretionaer';
    public static readonly CAF_BEGUENSTIGUNGSREGEL = 'caf_beguenstigungsregel';
    public static readonly CAF_BANKVERBINDUNG_KONTAKT_TYP = 'cafbankverbindung_kontakt_typ';
    public static readonly CAF_ERMESSEN = 'caf_ermessen';
    public static readonly CAF_SPV12 = 'caf_spv12';

    public static readonly CAF_PRUEFUNGANZAHLBERECHTIGTE = 'caf_pruefunganzahlberechtigte';

    public static readonly CAF_SALDO_QUELLE = 'caf_saldo_quelle';

    public static readonly CAF_AUTHSHARECAPITAL = 'caf_authsharecapital';
    public static readonly CAF_ISSUEDSHARE = 'caf_issuedshare';
    public static readonly CAF_ASSET_QUELLE = 'caf_asset_quelle';

    public static readonly CAF_ADRESSKLASSIFIZIERUNG = 'caf_adressklassifizierung';
    public static readonly CAF_STEUERNUMMER_VERWENDUNG = 'caf_steuernummer_verwendung';

    public static readonly CAF_JURPERSON_AUSGESTALTUNG = 'caf_jurperson_ausgestaltung';
    public static readonly CAF_STEUERBEFREIT = 'caf_steuerbefreit';

    public static readonly CAF_BANK_VERWENDUNG = 'caf_bank_verwendung';
    public static readonly CAF_ROLLENDEF_ANTEILTYP = 'caf_rollendef_anteiltyp';

    public static readonly CAF_TREEVIEW_SORTMODE = 'caf_treeview_sortmode';
    public static readonly CAF_ROLLENPARTNER = 'caf_rollenpartner';
    public static readonly CAF_ROLLENPARTNER_BEIDE = 'caf_rollenpartner_beide';

    public static readonly CAF_ROLLENDEF_AUSGESTALTUNG = 'caf_rollendef_ausgestaltung';
    public static readonly CAF_ROLLEDEF_JURPERSON_ENTSPRICHT = 'caf_rolledef_jurperson_entspricht';

    public static readonly CAF_EBANKING_AUSWAHL = 'caf_ebanking_auswahl';

    public static readonly CAF_OPERSTELLUNG_STATUS = 'caf_operstellung_status';
    public static readonly CAF_OPSTATUS = 'caf_opstatus';
    public static readonly CAF_OPDRUCKSTATUS = 'caf_opdruckstatus';
    public static readonly CAF_MAHNSTUFE = 'caf_mahnstufe';
}

export class AviPartnerListDetailConst {
    public static readonly CAF_PARTNERKOMM_ART_TELEFON = '39274547-f6de-48e6-abfb-a10e1fefbda8';
    public static readonly CAF_PARTNERKOMM_ART_FAX = '65113028-e11c-46d6-aa83-08f6ffe287b4';
    public static readonly CAF_PARTNERKOMM_ART_MOBILE = 'b3d79f79-6469-44f8-a836-6514887184c0';
    public static readonly CAF_PARTNERKOMM_ART_EMAIL = '5110e24b-f2c3-42b2-84f7-e0bbf892a6dd';
    public static readonly CAF_PARTNERKOMM_ART_VOIP = '0eabd5ef-1edc-40bf-8977-3a997e0b099e';
    public static readonly CAF_PARTNERKOMM_ART_CHAT = '74eeed4a-875c-4009-87cd-30e092828bdd';
    public static readonly CAF_PARTNERKOMM_ART_HOMEPAGE = 'b4a6d2c7-84ab-413a-8256-c5ac65aaf919';
    public static readonly CAF_PARTNERKOMM_ART_PAGER = 'f3750573-3000-4380-b641-0c258300dbbb';
    public static readonly CAF_PARTNERKOMM_ART_PAGER_NUMMERISCH = '9d24296a-7f8d-488b-830f-45d9980164e9';

    public static readonly CAF_PARTNERKOMM_TYP_PRIVAT = '2a345af4-b127-4eeb-a2f6-d0a32e80fb57';
    public static readonly CAF_PARTNERKOMM_TYP_BERUF = 'cf71a74c-95d2-40ab-a4f4-1dfc79724315';
    public static readonly CAF_PARTNERKOMM_TYP_ZENTRALE = 'f1eafe35-587b-47ce-a0b7-87ff52480706';
    public static readonly CAF_PARTNERKOMM_TYP_OFFICE = '03dc5d56-2bab-447b-badd-8f3e1bef6a0c';
    public static readonly CAF_PARTNERKOMM_TYP_OFFICE_INTERN = '2b82d2b5-9e0a-468a-83ed-d8a2f45357cd';

    public static readonly CAF_PARTNER_ART_NATPERSON = '518e7edb-647c-471c-863e-4b0c34530009';
    public static readonly CAF_PARTNER_ART_JURPERSON = '337b4f8c-f4fa-4bf2-9e38-ecb6d11aad3f';
    public static readonly CAF_PARTNER_ART_BANK = '1c0901e1-0a82-408e-b40a-f64806a1ef89';
    public static readonly CAF_PARTNER_ART_KONTAKTPERSON = '128bc67f-f4fe-4266-a099-3e3b9493bdc1';

    public static readonly CAF_VERWENDUNG_RAHMENPARTNER_RAHMENPARTNER = '8c0bea3b-6af5-4688-9779-b81df6d762dd';
    public static readonly CAF_VERWENDUNG_RAHMENPARTNER_RECHTSFORM = 'fd80116e-44f4-44d3-a512-1a3b58dabdd8';

    public static readonly CAF_JURPERSON_AUSGESTALTUNG_STIFTUNGSAEHNLICH = '7956d588-5341-4a77-8914-cb022e269f92';
    public static readonly CAF_JURPERSON_AUSGESTALTUNG_KOERPERSCHAFTSAEHNLICH = '60174cfb-056d-42c8-8174-ba374753f82d';
    public static readonly CAF_JURPERSON_AUSGESTALTUNG_NICHTRELEVANT = '313a9fd5-08f3-4a0b-92dd-c045221c0b38';

    public static readonly CAF_IDENTIFIKATIONSART_PASS = 'dd5c2113-5547-42d8-801b-3b38a32838a2';
    public static readonly CAF_IDENTIFIKATIONSART_ID = 'e18922a5-931d-47e7-82d3-70ef99989d67';
    public static readonly CAF_IDENTIFIKATIONSART_FUEHRERSCHEIN = '12673801-4424-4442-adbe-989b1aff71d3';
    public static readonly CAF_IDENTIFIKATIONSART_NATIONALITAET = 'ef4f39df-02c6-4bb4-8f8d-a4be7e96005e';
    public static readonly CAF_IDENTIFIKATIONSART_WOHNSITZBST = '9182f355-8d73-4ab9-9106-23742551d1ae';
    public static readonly CAF_IDENTIFIKATIONSART_GEBURTSURKUNDE = '3556cd18-37be-4a41-83e9-5fe803ca66dd';
    public static readonly CAF_IDENTIFIKATIONSART_AUFENTHALT = 'dd395de2-c465-4c2b-a9a9-6894a110030a';
    public static readonly CAF_IDENTIFIKATIONSART_REGISTER = 'f7388395-e903-4142-8ecb-7877cccb1b2d';
    public static readonly CAF_IDENTIFIKATIONSART_LEI = '97cab36a-80a3-4ec4-84d9-509e461e97b9';
    public static readonly CAF_IDENTIFIKATIONSART_MWSTNR = '42483b80-a2fc-499d-ab48-e4fb031324b8';
    public static readonly CAF_IDENTIFIKATIONSART_STEUERNUMMER = 'a379c36f-bdb5-4196-ad3f-1f429701c477';
    public static readonly CAF_IDENTIFIKATIONSART_STEUERNUMMER_SPONSOR = '9f8fa1d5-60ba-411c-a782-f7570a25ffbc';
    public static readonly CAF_IDENTIFIKATIONSART_SELBSTAUSKUNFT = 'f8baf1ad-4a28-4cdf-b7e3-bfefccdefdfe';
    public static readonly CAF_IDENTIFIKATIONSART_MELDESTELLENNUMMER = 'fb3b8bc3-a010-477c-bb8d-7d1827fd6b27';
    public static readonly CAF_IDENTIFIKATIONSART_VQFMITGLIEDNR = 'e38cca30-f457-43f5-8d64-841af51d1bee';
    public static readonly CAF_IDENTIFIKATIONSART_ANDERE = '7d09c3e4-0454-4444-a8a8-d6ca823d3ffb';
    public static readonly CAF_IDENTIFIKATIONSART_FATCA = '38b5d1fa-ce76-471b-a2a2-68ac59428c05';
    
    public static readonly CAF_GESCHLECHT_MAENNLICH = '8249db12-89c2-4f27-8467-b87cf1be480c';
    public static readonly CAF_GESCHLECHT_WEIBLICH = '57300b52-c9f8-4610-bd3c-9693736cdaeb';

    public static readonly CAF_ZIVILSTAND_UNBEKANNT = 'f6a6435f-e336-4acc-8353-96496f16fe67';
    public static readonly CAF_ZIVILSTAND_LEDIG = 'fd571b64-b15c-4b9e-a402-9e0840d2bcf7';
    public static readonly CAF_ZIVILSTAND_VERHEIRATET = 'e2c563a4-11d0-4138-8039-8480f9877f18';
    public static readonly CAF_ZIVILSTAND_GESCHIEDEN = '95d4bb0d-b659-4625-ae39-fd5b62d7586a';
    public static readonly CAF_ZIVILSTAND_VERWITWET = 'a6927d27-ab24-4e83-a170-9a5ef452fdca';
    public static readonly CAF_ZIVILSTAND_GETRENNT = '418ba668-6bc7-444c-bdfb-9850ca76e5e4';

    public static readonly CAF_TAGTYPE_ARBEITSTAG = '018C0009-BBE0-415A-827A-DD11F20C2433';
    public static readonly CAF_TAGTYPE_FEIERTAG = '5EB709AF-A754-409E-BCFE-66982859E819';
    public static readonly CAF_TAGTYPE_WOCHENENDE = '3AC81926-3258-4586-9FFA-98B1960D1AE7';
    public static readonly CAF_TAGTYPE_FREI = '1C9DCB26-3168-4620-9BE9-C85D3C6F03D8';

    public static readonly CAF_OPERSTELLUNG_STATUS_ERSTELLT = '64FBB0D4-A408-4ACF-96FD-534499B0D086';
}

export class AviPartnerParamConst {
    public static readonly DEFAULT_FINANZEN_WAEHRUNG = 'defaults.finanzen.waehrung';
    public static readonly DEFAULT_FINANZEN_KONTOART = 'defaults.finanzen.kontoart';
    public static readonly DEFAULT_FINANZEN_BANKVERBINDUNGART = 'defaults.finanzen.bankverbindungart';

    public static readonly PARTNER_NEW_AUTONR = 'partner.new.autonr';
    public static readonly PARTNER_NEW_AUTONR_UEBERSTEUERBAR = 'partner.new.autonr.uebersteuerbar';
    public static readonly ADRESSE_PLZORT_OFFEN = 'adresse.plzort.offen';
    public static readonly ADRESSE_ZEIGE_VERWENDEADRESSE = 'adresse.zeige.verwendeadresse';
    public static readonly ADRESSE_STEUERDOMIZIL_DEFAULT = 'adresse.steuerdomizil.default';

    public static readonly ROLLEN_ZEIGE_KOMMUNIKATION = 'rollen.zeige.kommunikation';
    public static readonly ROLLEN_VONPARTNER_AENDERBAR = 'rollen.vonpartner.aenderbar';
    public static readonly ROLLEN_MODEL_AENDERBAR = 'rollen.model.aenderbar';

    public static readonly ROLLEN_ZEIGE_BEZIEHUNGSHIERARCHIE = 'rollen.zeige.beziehungshierarchie';
    public static readonly ROLLEN_BEZIEHUNGEN_SORTIERUNG = 'rollen_beziehungen.sortierung';
    public static readonly ROLLEN_BEZIEHUNGEN_AUFGEKLAPPT = 'rollen_beziehungen.baum_aufgeklappt';

    public static readonly PARTNER_NAME_UPPERCASE = 'partner.name.uppercase';

    public static readonly PARTNER_KOMM_SYNC_DECISIONTABLE = 'partner.komm.sync.entscheidungstabelle';
    public static readonly PARTNER_ADRESSE_SYNC_DECISIONTABLE = 'partner.adresse.sync.entscheidungstabelle';

    public static readonly PARTNER_KOMM_TELEFONVORWAHL = 'partner.komm.telefonvorwahl';
    public static readonly PARTNER_KOMM_PHONEVALIDATION_E164 = 'partner.komm.telefon.validation.e164';

    public static readonly PARTNER_KONTO_IBANANDKONTONR = 'partner.konto.ibanandkontonr';
    public static readonly PARTNER_CHECK_TODESDATUM_NACH_GEBURTSDATUM = 'partner.check.todesdatum_nach_geburtsdatum';

    public static readonly BEZIEHUNGSGRAPH_SHOW_PARENT = 'beziehungsgraph.zeige.uebergeordnete.elemente';

    public static readonly FONE_ADRESSE_PROVINZ_AUSBLENDEN = 'fone.adresse.provinz_ausblenden';
    public static readonly FONE_ADRESSE_HAUSNR_AUSBLENDEN = 'fone.adresse.hausnr_ausblenden';

    public static readonly PARTNER_KOMM_ART_ADDITIONAL_TELEPHONE_TYPES = 'partner.kommart.additional_telephone.types';
}

export class AviPartnerCountryConst {
    public static readonly COUNTRY_UKNOWN = '682f640e-24cf-461b-b101-0bd3d0cb4355';
    public static readonly COUNTRY_STAATENLOS = '150cc435-5d7f-49e4-96b1-c018fd8e714b';
    public static readonly COUNTRY_KEINSTEUERDOMIZIL = '89fa7974-d6d2-4a79-bd0c-685b50ea22aa';

    public static readonly COUNTRY_CH = '4fb98941-ea61-4a73-b866-0ba6db6cc973';
    public static readonly COUNTRY_LI = '0208aab3-550a-463f-bc29-17cace610885';
    public static readonly COUNTRY_AT = '5692881f-0d06-48f3-83e1-a0741fb163dd';
}

export class AviDokumentAblageListDetailConst {
    public static readonly DOCKEYWORDTYPE_FACHSCHLUESSEL = 'fd821da0-c5cf-43af-8ef4-c1367528811c';

    // Wenn man hier neue ArchivType hinzufügt soll man DokumentArchivServiceImpl erweitern
    public static readonly DOCARCHIV_TYP_D3_ACCESS_SERVICE = 'f4a6838e-84e4-4718-a82e-b9ab9c5bd67a';
    public static readonly DOCARCHIV_TYP_D3_ACCESS_SERVICE_LIGHT = 'a1c10df6-f1a0-4f95-8574-a8f0645527f2';
    public static readonly DOCARCHIV_TYP_D3_WEB_SERVICE = '6b0b1948-c332-4739-aceb-75522659e212';
    public static readonly DOCARCHIV_TYP_DOCUWARE_WEB_SERVICE = '20ab6a70-5bfd-4052-a499-49b1361dfc40';
    public static readonly DOCARCHIV_TYP_DOCUWARE_PLATFORM = '1eec70b4-3923-4527-b152-1942e905973d';
    public static readonly DOCARCHIV_TYP_MULTI_SUPPORT = '805cc094-47e4-49d6-ad10-a3c33f573454';
    public static readonly DOCARCHIV_TYP_AVIDOC_ARCHIV_LOCAL = 'ecdcda83-4526-429e-a5c1-fb239390eaf8';
    public static readonly DOCARCHIV_TYP_AVIDOC_ARCHIV_WEB_SERVICE = '9595f061-d94a-4815-bff0-c60fe5e5a36d';

    public static readonly CAF_DOSSIERAUSLEIHSTATUS_ANGEFORDERT = '244e0017-fb0f-4968-a442-decc950a15b4';
    public static readonly CAF_DOSSIERAUSLEIHSTATUS_AUSGEGEBEN = '492edc50-eed7-423b-9286-7fc2908becec';
    public static readonly CAF_DOSSIERAUSLEIHSTATUS_RUECKNAHME = 'bed48cfd-50e1-4b98-bedf-82a9a52e2f8d';
    public static readonly CAF_DOSSIERAUSLEIHSTATUS_ANNULLIERT = '1fedaff3-95ca-40c9-b3e5-7feff469bb10';
    public static readonly CAF_DOSSIERAUSLEIHSTATUS_LEER = '08D1C2CD-4C54-485B-9F6D-2BFA5EC592E3';

    public static readonly CAF_CONTENTTYPE_EXTERN = '7afec97b-b642-4313-8b1a-fe9e9b0630b5';
    public static readonly CAF_CONTENTTYPE_TEXT = 'ce390aaf-7343-4ce9-91f0-6a2f538007c0';
    public static readonly CAF_CONTENTTYPE_BILD = 'd5c5e72f-b958-4933-8dac-792c8d3f44fd';
    public static readonly CAF_CONTENTTYPE_REFERENZ = 'f8a0b35e-4a09-455a-94fe-3b35ef5476ec';
    public static readonly CAF_CONTENTTYPE_URL = 'b949c58d-c1ee-4505-bdfd-fed782a651b3';

    public static readonly CAF_VERNICHTUNGSTOPART_UNBEFRISTET = 'ea4dbdd1-0614-484c-bada-e8507b6a3e58';
    public static readonly CAF_VERNICHTUNGSTOPART_BEFRISTETBIS = '21510274-684d-48ee-92ba-5962158207f2';

    public static readonly CAF_XMLDOCSTATUS_OFFEN = '3bbb9fd8-ccb5-46fb-8719-d3001f0e28de';
    public static readonly CAF_XMLDOCSTATUS_EXPORTIERT = 'c367b984-9c51-47cb-a379-2b41caf7b475';
    public static readonly CAF_XMLDOCSTATUS_VERSCHICKT = 'e228cb23-af1a-45ef-addc-d9922e253876';

    public static readonly CAF_DOCTRIGGERSTATUS_OFFEN = '699ca348-fddb-4716-95ec-d3e1944f9094';
    public static readonly CAF_DOCTRIGGERSTATUS_VERARBEITET = '8bb1cdaa-d973-4898-9dad-196be2b63d60';

    public static readonly CAF_DOCTEMPLATESTATUS_ENTWURF = 'ae64c3c3-589a-425a-aa26-bb249e8eab80';
    public static readonly CAF_DOCTEMPLATESTATUS_INARBEIT = 'f67e0d14-7068-455b-b7f3-1581447f137e';
    public static readonly CAF_DOCTEMPLATESTATUS_FREIGEGEBEN = '95d557e2-1eae-4ddf-b23e-46cbfc5c8aa5';
    public static readonly CAF_DOCTEMPLATESTATUS_ARCHIVIERT = 'c194bb0a-269f-43f5-9ef0-03e97a3f5483';

    public static readonly CAF_DOCPROP_TYP_ATTR = '6f42aaed-3cde-4ee0-99e1-53bc05797ba9';
    public static readonly CAF_DOCPROP_TYP_SQLQUERY = '6b4770f1-dbf8-4b8e-b8f2-076dca112941';

    public static readonly CAF_ADRBLOCK_PARTNER_AUSWAHL_ALLE = '7ba19fd5-ce38-4d81-bf14-6c9db9e13d67';
    public static readonly CAF_ADRBLOCK_PARTNER_AUSWAHL_ABFRAGE = '68d35faa-de5b-47e5-8ed4-decd53974073';
    public static readonly CAF_ADRBLOCK_PARTNER_AUSWAHL_KEINE = 'e792def9-300f-41df-8971-c2424a21f6d6';

    public static readonly CAF_CAF_PRINT_COLOR_MODE_DEFAULT = 'c1fdebef-469e-4e3e-90f9-460740441789';
    public static readonly CAF_CAF_PRINT_COLOR_MODE_COLOR = '7267683d-323c-4b31-945f-71eaca65d6ea';
    public static readonly CAF_CAF_PRINT_COLOR_MODE_BLACKWHITE = '4cebbc3f-4c6a-4cda-84c2-44aa3163544a';

    public static readonly CAF_WORD_FIELD_TYPE_ANCOMATAG = '8ba4233b-7935-473a-a9e4-1a3094727110';
    public static readonly CAF_WORD_FIELD_TYPE_MERGEFIELD = 'f9e0d6cc-8230-42a9-821d-85b2937ff754';

    public static readonly DOKUMENTABLAGE_VERSION_CAFDOCUMENT = '99fd22fd-6f06-4668-9b49-8339ce33668f';
    public static readonly DOKUMENTABLAGE_VERSION_AVIDOC = 'cc858a85-ff03-4051-b7c2-f3c23a670f9a';
    public static readonly DOKUMENTABLAGE_VERSION_BEIDE = '36b817e5-ae1f-4eed-bd5a-4dacc3bbd371';

    public static readonly AVIDOC_LOESCHSTATUS_OFFEN = 'c7b7e328-3ba9-4a14-b827-1b1f19d859b2';
    public static readonly AVIDOC_LOESCHSTATUS_GELOESCHT = '91ec748f-ad84-4d3d-b7e8-a25f39957423';
    public static readonly AVIDOC_LOESCHSTATUS_STORNIERT = '95a54d48-47b1-480b-9992-8991bcb818e9';

    public static readonly AVIDOC_LOCKSTATUS_UNLOCKED = 'fc87b883-f70f-4be2-9a35-8cd22206df12';
    public static readonly AVIDOC_LOCKSTATUS_LOCKED = '369ac315-d39a-4dbe-9d6e-374877984bf7';

    public static readonly AVIDOC_DOKUMENTSTATUS_BEARBEITUNG = 'cf0218ad-726a-438d-bb67-658b6df582e8';
    public static readonly AVIDOC_DOKUMENTSTATUS_PRUEFUNG = 'a397c2f3-9b58-4659-808f-afcbd39daab7';
    public static readonly AVIDOC_DOKUMENTSTATUS_FREIGABE = 'b84edda2-3981-43df-ad96-590203ff9e0b';
    public static readonly AVIDOC_DOKUMENTSTATUS_ARCHIV = 'd8cc2797-477e-4ae8-8382-693e30f0af54';

    public static readonly CAF_ABLAUFDATUM_BERECHNUNG_ERSTELLDATUM = '9093036d-7a97-475f-b8b0-f08dba8e6c44';
    public static readonly CAF_ABLAUFDATUM_BERECHNUNG_DOKUMENTPERIODE = 'd927e4e4-3425-49f4-91d0-5c33423d2536';
    public static readonly CAF_ABLAUFDATUM_BERECHNUNG_MANDATSENDE = '89ca58ca-5b8d-4046-a659-c0943b37f7c9';
    public static readonly CAF_ABLAUFDATUM_BERECHNUNG_UNBESCHRAENKT = 'b9ac2538-1191-4d2f-b1f6-de382a933168';

    public static readonly CAF_ABLAUFDATUM_BERECHNUNG_TAG_STICHTAG = '4bfe3269-0d5d-48bf-85b6-320117b2fb1d';
    public static readonly CAF_ABLAUFDATUM_BERECHNUNG_TAG_ENDEJAHR = 'b76c21d7-5d8f-41f4-8ef4-1648a036cfa5';
    public static readonly CAF_ABLAUFDATUM_BERECHNUNG_TAG_ANFANG_FOLGEJAHR = '4bb8b5cd-46b4-47a2-8a32-66966f75540e';

    public static readonly MANDAT_AUFLOESUNGS_ATTRIBUT_ALTEREFERENZ = 'ed60c35a-15bf-497a-9424-8909596c58da';
    public static readonly MANDAT_AUFLOESUNGS_ATTRIBUT_MANDATSNR = 'fcd45a2a-13c7-4cf3-9e53-d1497ceba338';

    public static readonly CAF_DOCPROPCONTAINER_TYP_HIERARCHICAL = 'f80f02ef-31d6-4c4d-9971-f9386d407fa9';
    public static readonly CAF_DOCPROPCONTAINER_TYP_TEMPLATE = 'a2cabb0e-6bcc-4340-9e0f-cc494ca13a9c';

    public static readonly CAF_DOCUMATRIX_TEMPLATE_TYP_DOCTYPE = 'ae1ef631-2c2d-4b62-913c-cda523e98882';
    public static readonly CAF_DOCUMATRIX_TEMPLATE_TYP_DMDOC = 'eb8084c4-2589-471c-bd90-e07e1fceb4fb';
}

export class AviJobConst {
    
    public static readonly CAMT_IMPORT_JOB ="65978337-5952-44f4-b44a-3866d06dc22a";

    public static readonly CAMT_ESR_PROCESSING_JOB = "3c0192e9-3fa7-40f7-b1a8-9ca63618f5fc";
    public static readonly CAMT_ESR_PROCESSING_JOB_VONMESSAGE = "31c3c0bd-95b9-4989-be0a-ee52de03525e";

    public static readonly ZAHLUNGHEADERERSTELLEN_JOB = "e21da810-aed1-49df-a554-01ff041dc754";
    public static readonly ZAHLUNGHEADERERSTELLEN_JOB_ZMDEF = "3c05e84d-8213-434f-94f1-7e24005f77a8";
    public static readonly ZAHLUNGHEADERERSTELLEN_JOB_GBEREICH = "cf9b8a48-51fe-47c5-9ab6-470fca8687b5";
    public static readonly ZAHLUNGHEADERERSTELLEN_JOB_ZAHLUNGENBIS = "77d261fb-7ab9-4139-9884-18485cbb7299";
    public static readonly ZAHLUNGHEADERERSTELLEN_JOB_VALUTA = "dcf7e874-1e02-4210-8f8f-5afe19bd1ab5";

    public static readonly ZAHLUNGHEADEREXPORT_JOB = "88a57605-c7e8-435b-b3cd-cf99ee627a2d";
    public static readonly ZAHLUNGHEADEREXPORT_JOB_HEADER = "3f1f75c7-5e1a-4147-a092-9d70131b8a4a";
}

export class AviComplianceListDetailConst {
    public static readonly CAF_SPV_RISIKOSTUFE_GERING = 'd19a50c3-e952-46e9-a9bd-715a27c68429';
    public static readonly CAF_SPV_RISIKOSTUFE_NORMAL = 'cdc753e1-7ef9-4404-b101-be138a29819d';
    public static readonly CAF_SPV_RISIKOSTUFE_ERHOEHT = 'd76b93bf-ed4a-428b-9645-af0a948a131a';
    public static readonly CAF_SPV_RISIKOSTUFE_HOCH = 'cecff8e8-f8ed-46a3-ba17-ad23fa788606';
    public static readonly CAF_SPV_RISIKOSTUFE_CH_NORMAL = 'a79d9d50-27a3-45d4-a428-224b7aaa43d6';
    public static readonly CAF_SPV_RISIKOSTUFE_CH_ERHOEHT = 'af292a11-78b0-4e06-a56e-47be8558da5a';
}

export class AviTypeUtil {
    public static readonly SqlLowDate = moment('1753-01-01T00:00:00');
    public static readonly HighDate = moment('9999-12-31T00:00:00');
}

export class SysEventConst
{
    public static readonly BUSINESSCASE_CREATED = '4c2b7f77-e0b4-4281-99ba-a41f3aaa0872';
    public static readonly BUSINESSCASE_UPDATED = 'bbc010d8-484d-4c33-b95e-2865b469ee46';
    public static readonly BUSINESSCASE_DELETED = '09e7a1af-b014-4b88-88ec-5f61827d48b3';
    public static readonly BUSINESSCASE_ASSIGNEE_CHANGED = 'b8aa2f68-6448-40c7-a591-59ef3e6ec90b';
    public static readonly BUSINESSCASE_APPLICANT_CHANGED = '62ff09e2-2b11-4117-81bd-898a3e24110b';
    public static readonly BUSINESSCASE_STATUS_CHANGED = 'efb5bff9-9e70-415d-a5d5-252cafe824ee';
    public static readonly COMMENT_CREATED = 'ceb4215c-bfde-4157-a159-0cb6286407ad';
    public static readonly COMMENT_UPDATED = '62472d29-b1aa-433f-89d8-fb70142d7ea6';
    public static readonly COMMENT_DELETED = '7f6151db-45c4-4591-bec6-7cb9b4b71164';
    public static readonly BUSINESSCASE_ACTIVITY_CREATED = '2e23f06e-e110-4092-803c-ec66a5846b68';
    public static readonly BUSINESSCASE_ACTIVITY_FINISHED = '2dd18278-bc53-41f1-9305-81aebe4e2981';
    public static readonly BUSINESSCASE_FORMULAR_CORRECTION_UPDATED = '4466347b-0f9f-4803-94a7-e53d136bcdf0';
    public static readonly BUSINESSCASE_FORMULAR_CORRECTION_CREATED = 'dfa7c7c7-6006-4a71-b877-c3ba01437e86';
    public static readonly BUSINESSCASE_FORMULAR_CREATED = 'af2c51af-ebf1-4a7d-a0f6-442f6ecfb022';
    public static readonly BUSINESSCASE_FORMULAR_UPDATED = '8627aae4-602d-47b1-9451-c76ddf167d30';
    public static readonly BUSINESSCASE_DOCUMENTLINK_CREATED = '154fa992-a709-47c0-a70e-d733c1c89f8b';
    public static readonly BUSINESSCASE_DOCUMENT_UPLOADED = 'ab073c98-30b0-41f4-995d-5b611eb00173';
}

export class AviRoleConst {
    public static readonly JOB_STARTEN = 'Job starten';
    public static readonly Job_SCHREIBEN = 'Job schreiben';
    public static readonly Job_EDITIEREN = 'Job editieren';
    public static readonly JobLogging_SCHREIBEN = 'JobLogging schreiben';
    public static readonly JobLogging_STATUSAENDERN = 'JobLogging Status aendern';

    public static readonly CAMTMessage_LESEN = 'CAFCAMTMessage lesen';
    public static readonly CAMTMessage_SCHREIBEN = 'CAFCAMTMessage schreiben';
    public static readonly CAMTMessageDetail_LESEN = 'CAFCAMTMessageDetail lesen';
    public static readonly CAMTMessageDetail_SCHREIBEN = 'CAFCAMTMessageDetail schreiben';

    public static readonly CAMTMessage_LOESCHEN = 'CAFCamtMessage löschen';
    public static readonly CAMTMessage_ERFASSEN = 'CAFCamtMessage erfassen';
    public static readonly CAMTMessage_IN_ZWISCHENABLAGE_KOPIEREN = 'CAFCamtMessage in Zwischenablage kopieren';

    public static readonly CAMTMessageDetail_ERFASSEN = 'CAFCamtMessageDetail erfassen';
    public static readonly CAMTMessageDetail_EDITIEREN = 'CAFCamtMessageDetail editieren';
    public static readonly CAMTMessageDetail_LOESCHEN = 'CAFCamtMessageDetail löschen';
    public static readonly CAMTMessageDetail_GENERIEREN = 'CAFCamtMessageDetail generieren';

    public static readonly ZahlungHeader_SCHREIBEN = 'CAFZahlungHeader schreiben';
    public static readonly ZahlungHeader_LESEN = 'CAFZahlungHeader lesen';
    public static readonly ZahlungRecord_SCHREIBEN = 'CAFZahlungRecord schreiben';
    public static readonly ZahlungRecord_LESEN = 'CAFZahlungRecord lesen';

    public static readonly ZahlungHeader_ERFASSEN = 'CAFZahlungHeader erfassen';
    public static readonly ZahlungRecord_ERFASSEN = 'CAFZahlungRecord erfassen';
    public static readonly ZahlungHeader_ANZEIGEN = 'CAFZahlungHeader anzeigen';
    public static readonly ZahlungRecord_ANZEIGEN = 'CAFZahlungRecord anzeigen';
}

export class AviDbConfig {
    public static AviitaIndexDbConfig: DBConfig = {
        name: 'AviitaIndexedDb',
        version: 1,
        objectStoresMeta: [{
            store: 'favs',
            storeConfig: { keyPath: 'Id', autoIncrement: true },
            storeSchema: [
                { name: 'Title', keypath: 'Title', options: { unique: false } },
                { name: 'Url', keypath: 'Url', options: { unique: false } },
                { name: 'Sorter', keypath: 'Sorter', options: { unique: false } }
            ]
        }]
    };
}
