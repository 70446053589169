<avi-core-page title=""
    [card]="false"
    [no-box-shadow]="true"
    [collapsible]="false"
    [expanded]="true"
    [contents-padding]="true">
    <button *ngIf="!IsEmbedded"
        pButton
        type="button"
        icon="pi pi-chevron-left"
        aria-label="Go back"
        class="p-button-secondary p-button-flat back-button"
        (click)="NavigateBack()"></button>

    <h1 *ngIf="!IsEmbedded">{{'VWBP.INTERN.UEBERGABE_MANAGEMENT.UEBERGABE_ANFRAGE' | translate}}</h1>

    <div *ngIf="Anfrage; else loading"
        class="pos-rel">

        <div class="action-buttons">

            <button pButton
                *ngIf="IstStatusWarteAufDokumente()"
                label="{{'VWBP.INTERN.UEBERGABE_MANAGEMENT.DOKUMENTE_ERHALTEN' | translate}}"
                class="p-button-primary ml-2"
                pTooltip="Setzt die Anfrage in den Status 'In Prüfung'"
                (click)="SetDokumenteErhalten()"></button>

            <button pButton
                *ngIf="IstStatusBereitFuerVisum()"
                label="{{'VWBP.INTERN.UEBERGABE_MANAGEMENT.ANFRAGE_BEWILLIGEN' | translate}}"
                class="p-button-success ml-2"
                (click)="AnfrageBewilligen()"></button>

            <button pButton
                *ngIf="IstStatusBereitFuerVisum()"
                label="{{'VWBP.INTERN.UEBERGABE_MANAGEMENT.ANFRAGE_ABLEHNEN' | translate}}"
                class="p-button-warning ml-2"
                (click)="AnfrageAblehnen()"></button>

        </div>

        <p-panel [toggleable]="IsEmbedded"
            expandIcon="pi pi-chevron-down"
            collapseIcon="pi pi-chevron-up"
            toggler="header">
            <p-header class="rtpanel">
                <span class="p-panel-title"> {{'VWBP.INTERN.UEBERGABE_MANAGEMENT.UEBERGABE_ANFRAGE' | translate}} für {{ Anfrage.RechtstraegerName }}</span>
                <div class="grid m-0 pb-2 pt-2 panel-header">
                    <ng-container *ngIf="!IsEmbedded">
                        <div class="p-0 col-6 lg:col-3 summary-label">
                            {{'VWBP.COMMON.RT' | translate}}<br />
                            {{'VWBP.COMMON.REGISTERNR' | translate}}
                        </div>
                        <div class="p-0 mb-4 col-6 lg:col-3">
                            {{ Anfrage.RechtstraegerName }}<br />
                            {{ Anfrage.RechtstraegerRegisternummer }}
                        </div>
                    </ng-container>

                    <ng-container>
                        <div class="p-0 col-6 lg:col-3 summary-label">
                            {{'VWBP.INTERN.UEBERGABE_MANAGEMENT.ANFRAGENDER_BENUTZER' | translate}}<br />
                            {{'VWBP.INTERN.UEBERGABE_MANAGEMENT.AKTUELLER_BENUTZER' | translate}}
                        </div>
                        <div class="p-0 mb-4 col-6 lg:col-3">
                            {{ Anfrage.RequestOwnerMaster }}<br />
                            {{ Anfrage.OldOwnerMaster }}
                        </div>
                    </ng-container>

                    <ng-container>
                        <div class="p-0 col-6 lg:col-3 summary-label">
                            {{'VWBP.COMMON.ANFRAGESTATUS' | translate}}
                        </div>
                        <div class="p-0 mb-4 col-6 lg:col-3">
                            {{ Anfrage.Anfragestatus?.Bezeichnung }}
                        </div>
                    </ng-container>

                </div>
            </p-header>

            <vwbp-intern-rechtstraeger-kontaktinfo-panel *ngIf="Anfrage.RequestOwnerMasterId"
                [widget-title]="'VWBP.INTERN.UEBERGABE_MANAGEMENT.ANFRAGENDER_BENUTZER'"
                [owner-id]="Anfrage.RequestOwnerMasterId"
                [force-collapsed]="false"></vwbp-intern-rechtstraeger-kontaktinfo-panel>
<!--
            <vwbp-intern-rechtstraeger-kontaktinfo-panel *ngIf="Anfrage.OldOwnerMasterId"
                [widget-title]="'VWBP.INTERN.UEBERGABE_MANAGEMENT.AKTUELLER_BENUTZER'"
                [owner-id]="Anfrage.OldOwnerMasterId"
                [force-collapsed]="true"></vwbp-intern-rechtstraeger-kontaktinfo-panel> -->


            <div class="grid lg:col-12">
                <div class="col-12 lg:col-12">

                    <h2 class="default"> {{'VWBP.INTERN.UEBERGABE_MANAGEMENT.ERHALTENE_VISA' | translate}} </h2>

                    <div *ngIf="VisaAll.length > 0; else noVisa">

                        <p-table [value]="VisaAll">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="no-border"
                                        pSortableColumn="ErstellDatum">{{'CORE.COMMON.DATUM' | translate}}</th>
                                    <th class="no-border"
                                        pSortableColumn="VisumTyp.Bezeichnung">{{'CORE.COMMON.TYP' | translate}}</th>
                                    <th class="no-border"
                                        pSortableColumn="VisumGeber.Username">{{'CORE.COMMON.USER' | translate}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body"
                                let-visum>
                                <tr>
                                    <td>{{visum.ErstellDatum | date:'medium'}}</td>
                                    <td><span [ngStyle]="{color: isVisumTypBewilligt(visum.VisumTyp.Id) ? 'inherit':'red'}">{{visum.VisumTyp.Bezeichnung}}</span></td>
                                    <td>{{visum.VisumGeber.Username}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <ng-template #noVisa>
                        {{'VWBP.INTERN.UEBERGABE_MANAGEMENT.NOCH_KEINE_VISA_ERHALTEN' | translate}}
                    </ng-template>

                </div>
            </div>
        </p-panel>


        <avi-core-dbg [m]="Anfrage"
            t="Anfrage"></avi-core-dbg>
        <avi-core-dbg [m]="VisaBewilligt"
            t="VisaBewilligt"></avi-core-dbg>
        <avi-core-dbg [m]="VisaAbgelehnt"
            t="VisaAbgelehnt"></avi-core-dbg>

    </div>
    <ng-template #loading>
        <div class="loader-div-with-text">
            {{'CORE.COMMON.LOADING' | translate}}...
        </div>
    </ng-template>
</avi-core-page>
