import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AviApiService, AviBaseFormComponent, AviBaseSearcherComponent, AviCommonService, AviListDetailConst, AviSearcherColumn } from '@avi-x/avi-core';


@Component({
    selector: 'vwbp-admin-xmlimportjob-details',
    templateUrl: './xmlimport-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminXMLImportJobDetailsComponent implements OnInit {

    public Loading: boolean = false;

    FormModelId: string = null;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('logSearcher', { static: true })
    private logSearcher: AviBaseSearcherComponent;

    public Card = true;
    jobId: string = null;

    private SelectedRows: any[] = [];
    public DetailModelId: string = null;

    constructor(
        private commonService: AviCommonService,
        private apiService: AviApiService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
    }

    initView(jobId: string) {
        this.jobId = jobId;
        this.logSearcher.forceRefresh();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get(`v1/intern/vwbp/job/log/${this.jobId}`);
        res = res.filter(w => w.LogType.Id !== AviListDetailConst.LOGGING_TYPE_INFO);
        return res;
    }

    onLogInit(data) {
        this.logSearcher.beginInit();

        this.logSearcher.addColumn(
            AviSearcherColumn.CreateCustom(
                '',
                ' ',
                (row, col) => {
                    if (row['LogType'].Id === AviListDetailConst.LOGGING_TYPE_ERROR)
                        return '<i class="pi pi-exclamation-circle color-red"></i>';
                    else if (row['LogType'].Id === AviListDetailConst.LOGGING_TYPE_WARNING)
                        return '<i class="pi pi-exclamation-triangle color-orange"></i>';
                    else if (row['LogType'].Id === AviListDetailConst.LOGGING_TYPE_INFO)
                        return '<i class="pi pi-info-circle color-green"></i>';

                    return '';
                },
                false,
                '35px'
            ).setSortable(false)
        );

        this.logSearcher.addTextColumn('LogMessage', 'Beschreibung').setSortable(false);
        this.logSearcher.endInit();
    }
}
