
export class VwbPListDetailConst {
    public static VWBP_RECHTSTRAEGER_TYP_LI_HRNR = 'e6b86ec1-5177-4b44-848e-461486a81a45';
    public static VWBP_RECHTSTRAEGER_TYP_AUSLAENDISCH = '58a3d827-f4c6-4a1b-87d9-01bd8d77518a';

    public static VWBP_ERFASSUNGSSTATUS_INBEARBEITUNG = '3c145c6a-aed2-4282-b504-6092a67e5122';
    public static VWBP_ERFASSUNGSSTATUS_ERFASSUNG_ABGESCHLOSSEN = '865933cb-f48b-4ef3-9e89-5bfbcdb53381';
    public static VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN = '310b699b-9bcc-4f64-9a4f-329fa0e0ec6f';
    public static VWBP_ERFASSUNGSSTATUS_MUTIEREN = 'b9181715-2034-4068-9527-0df687bc57a2';
    public static VWEG_ERFASSUNGSSTATUS_VERANTWORTLICHKEIT_ABGEGEGEBEN = 'c5ff056c-dd56-46b3-81ee-d06077e2c6b6'; // wird nur für Versionen vom Typ VwEG verwendet! (Migration)
    public static VWEG_ERFASSUNGSSTATUS_RECHTSTRAEGER_STATUS_GELOESCHT = '5ed64d17-46de-41cf-be02-d6c5a2777971'; // wird nur für Versionen vom Typ VwEG verwendet! (Migration)

    public static VWBP_FORMULAR_QUELLE_MANUELL = '4dd6cfa7-2914-4719-8307-1477a2c2b584';
    public static VWBP_FORMULAR_QUELLE_XMLIMPORT = 'c21f9ed2-7c8a-4307-9ce5-d5a56838d109';
    public static VWBP_FORMULAR_QUELLE_MIGRATION = '8e49454a-ad05-4f6b-8cca-33ecd46180a6';

    public static VWBP_FORMULARTYP_C = 'd1311621-b491-4572-ab97-0f4f23eaf19a';
    public static VWBP_FORMULARTYP_T = 'b81afb80-2700-45b3-841e-bfa271c7b9ef';
    public static VWBP_FORMULARTYP_VWEG_C = '9ef03831-48cd-4ff5-bb70-2b9929bb1faf';
    public static VWBP_FORMULARTYP_VWEG_T = '379b6152-c82c-463c-9c47-824faee8e331';

    public static VWBP_VERSION_TYP_VWBP = '919c0822-6815-4aa5-8762-c0df058bd0c0';
    public static VWBP_VERSION_TYP_VWEG = '96a1c615-a102-462f-9f81-f485a829842a';

    public static VWBP_WBPARTNERTYP_NATPERSON = 'd3802a32-0776-497b-9767-1ca754f69080';
    public static VWBP_WBPARTNERTYP_JURPERSON_LI_HRN = 'a6c75af7-e008-4e04-9a2c-b0a30c4353b2';
    public static VWBP_WBPARTNERTYP_JURPERSON_AUSLAENDISCH = '9bc80298-8021-47f4-9e91-617a3a242741';
    public static VWBP_WBPARTNERTYP_KDB = 'fd3261f4-a7a2-4176-833c-66f226122bd4';

    public static VWBP_UNSTIMMIGKEITSSTATUS_NICHT_RELEVANT = '66cb7e23-dad9-4a54-b37c-d4c9813fd44d';
    public static VWBP_UNSTIMMIGKEITSSTATUS_MELDUNG_OFFEN = '604fc212-cc17-451e-ba14-b7c63e0227b1';
    public static VWBP_UNSTIMMIGKEITSSTATUS_INFORMIERT = '23499f30-acf9-4967-b88b-c012e1bdefa8';
    public static VWBP_UNSTIMMIGKEITSSTATUS_KORRIGIERT = 'dc03a624-160f-4321-8e48-6c0b218fd87f';
    public static VWBP_UNSTIMMIGKEITSSTATUS_ABGESCHLOSSEN = '04894e07-b6fc-4f96-ad4a-da4fd48d3845';
    public static VWBP_UNSTIMMIGKEITSSTATUS_MAHNLAUF = '679fd1e7-1fde-40bc-aa17-7bf6e0d28084';
    public static VWBP_UNSTIMMIGKEITSSTATUS_AMTLICHES_LIQUIDATIONSVERFAHREN = 'f6a8759d-0ed0-4885-a9fb-ed9495da7f6c';

    public static VWBP_MAHNWESEN_AKTIV = 'f88008d9-a1f2-4d45-bcaa-e6ef0338185d';
    public static VWBP_MAHNWESEN_PAUSIERT = 'f2493b1a-ada1-43a9-8503-8098b326a211';

    public static VWBP_MAHNUNG_STATUS_OFFEN = 'e5f4834c-9d66-4744-84ca-c37d580c07c0';
    public static VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN = '6e6c9c0c-bf54-489e-a40f-7f4e0d49bc92';
    public static VWBP_MAHNUNG_STATUS_ERLEDIGT = 'c8ea26c9-72da-41ec-a437-cff945414fc4';

    public static VWBP_BUSS_STATUS_OFFEN = '0a2bd4dd-8ba1-44f2-bc08-558a92a6ba85';
    public static VWBP_BUSS_STATUS_RECHTSMITTELFRIST_ABGELAUFEN = '4b95037f-1ba8-4a84-bcbd-e261cd19e3c9';
    public static VWBP_BUSS_STATUS_BUSSFRIST_ABGELAUFEN = '20c7df40-d875-4641-b0b9-be036b7d6c6a';
    public static VWBP_BUSS_STATUS_EINZUGSAUFTRAG = 'a5c97646-39a7-4f1d-9d18-ef1956f0bf99';
    public static VWBP_BUSS_STATUS_ERLEDIGT = '512ddded-ca68-4e2e-9331-9fe6d1a6ae45';

    public static VWBP_RT_UEBERGABESTATUS_NORMAL = 'f2449aa5-bb63-46e1-b2ba-326fc6fbe78e';
    public static VWBP_RT_UEBERGABESTATUS_ABGEGEBEN = '81e3f011-0d15-4c69-bcf3-0eb38a63ad3b';
    public static VWBP_RT_UEBERGABESTATUS_RT_INAKTIV = '959e44c6-f3d6-4545-9071-c482d8282d2e';
    public static VWBP_RT_UEBERGABESTATUS_ANGEFRAGT = 'bc19d1c7-f420-4584-8212-a84e177eacf2';

    public static VWBP_RT_ANFRAGESTATUS_WARTE_AUF_DOKUMENTE = '959cd8a1-1241-4158-8d03-b96a8e8bdd00';
    public static VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG = '712b2ef1-0e90-46e9-becc-941258461ac3';
    public static VWBP_RT_ANFRAGESTATUS_ABGELEHNT = '2acbe86b-16a2-4508-8afb-c056d50b0ad3';
    public static VWBP_RT_ANFRAGESTATUS_BEWILLIGT = 'ad5ffd75-5960-454c-9f12-c10fa036b67d';

    public static VWBP_MAHNWESENSTATUS_OFFEN = '0c68822a-f246-4e8c-bcdb-f04371b744bb';
    public static VWBP_MAHNWESENSTATUS_MAHNLAUF = 'ad30b085-c155-4a37-93b7-e39107fdae97';
    public static VWBP_MAHNWESENSTATUS_LIQUIDATIONSVERFAHREN = 'bd6ee4e8-5355-4d31-89ed-1fa374abddf0';
    public static VWBP_MAHNWESENSTATUS_NICHT_RELEVANT = '32b7a9d7-6f42-4bca-92ec-ed66e9b6604b';
    public static VWBP_MAHNWESENSTATUS_ABGESCHLOSSEN = '676d6ef2-52c4-4e38-b294-2b4b11fef5d8';

    // Events
    public static VWBP_EVENT_UNBEKANNT = 'bb788fc4-af71-4b88-bc15-af2c271b188a';
    public static VWBP_EVENT_STATUSAENDERUNG = 'a0245fa6-1b18-4387-8bff-e9af829093f3';
    public static VWBP_EVENT_UEBERGABESTATUSAENDERUNG = '0d593f10-4007-4f7e-bbe4-63ab06612b57';
    public static VWBP_EVENT_UEBERGABEANFRAGESTATUSAENDERUNG = '4050fce5-d19e-49d6-9fa1-6797b0b536dd';
    public static VWBP_EVENT_UNSTIMMIGKEIT = '9fc46ebc-c3ac-45af-a1ce-33ca22d9b8db';
    public static VWBP_EVENT_BEMERKUNG = '88e1e8b4-0c6c-4875-bada-2ff96ba1249e';
    public static VWBP_EVENT_RUECKGAENGIG = 'e95aff56-5bf0-4bf1-bd3c-df0a874d212c';
    public static VWBP_EVENT_MAHNWESEN = 'e5ac0c19-0642-43d5-b7f8-1f8d314dde9d';
    public static VWBP_EVENT_SCHWAERZEN = '7c1d2c48-d431-4fef-ba12-ceed3676d565';
    public static VWBP_EVENT_HRIMPORT = '1a34c271-c85e-4721-be0c-a9ae247e6688';
    public static VWBP_EVENT_HRIMPORT_JOB_STATUS = 'efd91af9-6081-42fc-a30e-4625b6a8c39b';

    // Event Details
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_MAHNFRIST_ANGEPASST = '751ef0de-e7c8-423d-8490-952598b362c3';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_RECHTSMITTELFRIST_ANGEPASST = 'c3972a46-7669-4b8f-b087-fb1a5dd56d0d';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_BUSSFRIST_ANGEPASST = '2d80b68a-853a-4e57-8ebf-3a430eab1709';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_GEMAHNT = 'ac84051e-aab0-4852-9185-6d817e370ebb';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_GEBUESST = '0d9faaf5-269e-4010-af5e-31752e0126ec';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_EINZUGSAUFTRAG = 'd06cbedd-81a8-46bb-b1c3-3c509cfcf0f1';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_AKTIVIERT = '3cc38d28-2c94-4ed2-946e-32ed333ecb04';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_PAUSIERT = '7b29344f-90b0-4af1-b1c1-4b3e478205d4';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_AKTIVIERT_MAHNFRIST_ANGEPASST = '93a326cd-88ab-4553-8cfc-080543c152c9';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_AKTIVIERT_RECHTSMITTELFRIST_ANGEPASST = '55fb3a52-95db-4005-b3b3-444af7fcf859';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_AKTIVIERT_BUSSFRIST_ANGEPASST = 'd60cc773-0073-472d-b998-7f07476ef06c';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_GELOESCHT = 'f5780eea-a44e-4af2-a5c0-acaa667acab4';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_INFORMIEREN_RUECKGAENGIG = '69e5a068-ff8d-430e-889a-f0e328d03db2';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_ABSCHLIESSEN_RUECKGAENGIG = 'be42aba4-f82e-468c-8aed-d3fffe1534bf';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_MAHNEN_RUECKGAENGIG = 'e7017706-701c-4861-a0d0-56cfdb924c73';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_BUESSEN_RUECKGAENGIG = 'c1d6fe40-cae4-4588-a17c-81e85be39179';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_EINZUGSAUFTRAG_RUECKGAENGIG = 'db367506-e142-4469-af75-98a48e6e7edf';
    public static VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_LOESCHVERFAHREN_RUECKGAENGIG = '1c8af8f1-d438-44b0-8cea-88b0f616a6a9';
    public static VWBP_EVENT_DETAIL_VEREINFACHTE_VALIDIERUNG_AKTIVIERT = 'ac8c8e3e-4093-401c-9c85-be6adc1667f9';
    public static VWBP_EVENT_DETAIL_VEREINFACHTE_VALIDIERUNG_DEAKTIVIERT = '65a1f5e2-e16e-46dd-ad2e-b2b2a8cdb407';
    public static VWBP_EVENT_DETAIL_BEMERKUNG_TEILFONDS_AKTIVIERT = '2ad3c1c2-6fdf-412f-a250-1d9c6d0ea357';
    public static VWBP_EVENT_DETAIL_BEMERKUNG_TEILFONDS_DEAKTIVIERT = '39c4570c-dc41-4a2c-b524-35db387e5454';
    public static VWBP_EVENT_DETAIL_EINGETRAGEN_EWR_REGISTER_AKTIVIERT = '71af00bf-71a9-46fb-9afe-ad5ded15e9e1';
    public static VWBP_EVENT_DETAIL_EINGETRAGEN_EWR_REGISTER_DEAKTIVIERT = '4cd86e61-26ea-4289-9a3d-a262c824f6c7';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_MAHNEN_RUECKGAENGIG = '88a8dc48-b2d9-45ec-887c-aeb6c9c1c75e';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_BUESSEN_RUECKGAENGIG = '89a87588-e77e-42e8-a3a0-f083477c6b80';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_EINZUGSAUFTRAG_RUECKGAENGIG = 'e6ea6128-708f-47fa-887e-1daa42c61665';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_LOESCHVERFAHREN_RUECKGAENGIG = '4fe0b0b7-17fe-4efe-b831-5419f87c0f66';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_NICHT_RELEVANT_RUECKGAENGIG = '226a1acd-770b-45e9-84c6-5e23541eef8a';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_AKTIVIERT_MAHNFRIST_ANGEPASST = 'a3d3c6b1-4c52-40a4-ad3b-4177a7f84a8e';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_MAHNFRIST_ANGEPASST = '7681fe0d-6088-4ea4-a129-1a7c71246520';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_AKTIVIERT_RECHTSMITTELFRIST_ANGEPASST = '894f8d13-9926-4dbd-85af-111a3ea36686';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_RECHTSMITTELFRIST_ANGEPASST = '487c1d7d-e519-4d42-8e7c-f0c9d1c0d358';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_AKTIVIERT_BUSSFRIST_ANGEPASST = '4e328b82-e97e-47c2-b31b-a74d665c8264';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_BUSSFRIST_ANGEPASST = '01876501-6013-4ce2-8fa0-07a65c32885c';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_GEMAHNT = 'b2a712f6-1fcd-4a21-9ac5-b1566a05e784';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_GEBUESST = 'c13a5b13-8909-4698-92a3-7678c3cb5e2c';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_EINZUGSAUFTRAG = '10315a38-da3f-42ed-ad2e-9edff648fecc';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_AKTIVIERT = 'f21f249f-0481-421a-8df3-3296943e03fb';
    public static VWBP_EVENT_DETAIL_MAHNWESEN_PAUSIERT = 'be4a33a9-edcc-4b36-b8e3-929334fe8bb8';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_VORHANDEN_AKTIVIERT = '9a6ec93f-3268-4e79-87f7-552eb289668f';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_VORHANDEN_DEAKTIVIERT = '0e7fd48b-6be7-40cc-9c60-f306e8b9ab54';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_VERSION_KONTROLLIERT = '0b266da9-73b8-40c6-a185-64c57b5e07d5';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_NEUE_VERSION = 'eb797751-d0e6-4e79-8543-9c87f060621f';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_RECHTSMITTELFRIST_ANGEPASST = '36d7119d-2b26-4008-b75a-7070620b3d4b';
    public static VWBP_EVENT_DETAIL_SCHWAERZEN_ANTRAG_STATUS_BEWILLIGT_RUECKGAENGIG = '30cf7ff4-730d-4f0c-89e2-c95e0fe2bdf4';
    public static VWBP_EVENT_DETAIL_SCHWAERZEN_ANTRAG_STATUS_ABGELEHNT_RUECKGAENGIG = '3e7ed21d-63a9-4301-87da-d5ad7fcd289c';
    public static VWBP_EVENT_DETAIL_SCHWAERZEN_ANTRAG_RECHTSTRAEGER_INFORMIEREN_RUECKGAENGIG = '5d056c90-625d-4ca8-b076-b563c7a54778';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_AKTIVIERT_RECHTSMITTELFRIST_ANGEPASST = '1d3b743b-f5af-41c5-830d-2ae6df63046e';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_AKTIVIERT = 'e5062e25-7a26-41d5-af58-a454dba49f38';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_PAUSIERT = '654986c3-0d49-450e-9557-8f749d04ef93';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_ANTRAG_ERFASST = '945c4267-eb0d-4fa7-a287-3a30fa440969';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_ANTRAG_BEARBEITET = 'ae5778f6-2d86-4c08-8061-e4b9514a040c';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_ANTRAG_GELOESCHT = '54db7697-9bfb-4434-8721-f25663f4d8ba';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_ANTRAG_GEPRUEFT = 'adaa18f2-9a4c-4bd0-87e5-c14c4b756300';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_ANTRAG_ABGESCHLOSSEN_WB_NICHT_MEHR_VORHANDEN = '11989fdc-e1a6-412d-9455-6e31173db983';
    public static VWBP_EVENT_DETAIL_SCHWAERZUNG_ANTRAG_ABGESCHLOSSEN_RT_GELOESCHT = 'ae8bc381-09c5-4c8f-ab78-dac3e8939a26';
    public static VWBP_EVENT_DETAIL_HRIMPORT_REAKTIVIERT = 'bd8293c5-020d-4d5d-b3bc-5528c3320940';

    public static VWBP_RT_HRSTATUS_NICHT_IM_REGISTER = '185384ce-08ed-4f75-b480-0afc21088dff';
    public static VWBP_RT_HRSTATUS_VON_BENUTZER_IMPORTIERT = 'eb733524-ba29-4812-a791-86e681f72673';
    public static VWBP_RT_HRSTATUS_NICHT_VON_BENUTZER_IMPORTIERT = '02d02ae2-66df-4f4a-a399-61e97c120d8c';

    public static VWBP_VISUM_TYP_UEBERGABE_ANFRAGE_BEWILLIGUNG = '1a4c3b43-f23e-4c92-8edf-4d5affbbfe36';
    public static VWBP_VISUM_TYP_UEBERGABE_ANFRAGE_ABLEHNUNG = 'b8c03aec-3784-48ad-ad4a-c4a9212b18ea';

    public static VWBP_LOG_TYP_BEHOERDENSUCHE = '945f7093-ef9c-443b-b815-a7635d3695a0';

    // Vorlagen Konfiguration
    public static VWBP_VORLAGE_TYP_RECHTSTRAEGER_VIEW = '0f5078da-85d8-461b-91ea-07e5cf5df65a';
    public static VWBP_VORLAGE_TYP_TASK = '107bfe33-6b81-43c9-ac72-aba0f925aaf1';
    public static VWBP_VORLAGE_TYP_SERIENBRIEF = '1bb4c1b2-3a38-4d11-9926-2e5071a7194d';

    public static VWBP_VORLAGE_BERECHTIGUNG_ENDUSER = '32f3ddce-156c-415d-927e-853f53cd9d43';
    public static VWBP_VORLAGE_BERECHTIGUNG_AJU = '262eb7d5-a993-46d6-8c70-b87ad7da78eb';
    public static VWBP_VORLAGE_BERECHTIGUNG_BEHOERDEN = '0e93ea2c-9649-48b1-b06c-3150fe5b69f2';

    public static VWBP_VORLAGE_VIEWVERSION_ALLE_VERSIONEN = '9256bfdd-ec36-400d-a878-7e4a91f1d5e5';
    public static VWBP_VORLAGE_VIEWVERSION_LETZTE_VERSION = 'cac5ec17-9462-44bd-a9bc-a8fbbc6c4e51';
    public static VWBP_VORLAGE_VIEWVERSION_ABGESCHLOSSENE_VERSIONEN = 'f3c06036-7302-4a4b-8b1e-3772ddb3ee4f';
    public static VWBP_VORLAGE_VIEWVERSION_LETZTE_ABGESCHLOSSENE_VERSION = '17a4d04b-128d-4f8b-ac9b-e91326866ecc';

    public static VWBP_VORLAGE_FORMAT_WORD = '928b68cd-823d-47cc-b2d8-46caabc194e6';
    public static VWBP_VORLAGE_FORMAT_PDF = 'aabccae6-662a-47f1-b5fd-1fee197e0a77';

    public static VWBP_SCHWAERZEN_ANTRAG_STATUS_ANTRAG = '5135dfc4-2f9d-43bd-87f6-02d33c705688';
    public static VWBP_SCHWAERZEN_ANTRAG_STATUS_BEWILLIGT = 'ccd858db-c118-4cfd-a924-56fd168120d0';
    public static VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELEHNT = '6c52c1e7-6415-4b7c-b667-e6650403cd77';
    public static VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELAUFEN = '3b6b7d36-28ab-4265-8615-7c6e0083b645';
    public static VWBP_SCHWAERZEN_ANTRAG_STATUS_BEARBEITET = '9fe70ca5-2abb-4ff4-b04d-2d4a7d767e7f';
    public static VWBP_SCHWAERZEN_ANTRAG_STATUS_RECHTMITTELFRIST_ABGELAUFEN = '9e148ac6-6745-4efb-97fc-3b3dd5a3d96f';
    public static VWBP_SCHWAERZEN_ANTRAG_STATUS_FRIST_3MT = '26dde760-582b-4631-9b9c-07ead40e9577';
    public static VWBP_SCHWAERZEN_ANTRAG_STATUS_RT_INFORMIERT = 'f3533204-8365-4561-8cf5-697a0180fe0e';

    public static VWBP_SCHWAERZEN_ANTRAG_BEGRUENDUNG_ERPRESSUNG = '212189e8-2b1d-4139-b675-2093290d66df';
    public static VWBP_SCHWAERZEN_ANTRAG_BEGRUENDUNG_MINDERJAEHRIGKEIT = 'b1c9221a-7046-430b-a667-c58abb75acf8';
    public static VWBP_SCHWAERZEN_ANTRAG_BEGRUENDUNG_GESCHAEFTSUNFAEHIGKEIT = '246d9c8c-f2e5-4573-8cd5-5e4733e003aa';

    public static VWBP_SCHWAERZEN_ANTRAG_ENTSCHEIDUNG_OFFEN = '1850f159-4dde-4d99-9dbe-ba37a3a51cc7';
    public static VWBP_SCHWAERZEN_ANTRAG_ENTSCHEIDUNG_BEWILLIGT = 'a3f87db0-bad9-4654-88f2-c8469b6270bf';
    public static VWBP_SCHWAERZEN_ANTRAG_ENTSCHEIDUNG_ABGEWIESEN = 'd8307f72-008f-496e-b3e4-f01692e2d158';

    public static VWBP_VORLAGE_TASK_UNSTIMMIGKEITEN_MAHNEN = '42a1c14d-eafc-4be8-954c-5fc6702a2f57';
    public static VWBP_VORLAGE_TASK_UNSTIMMIGKEITEN_BUESSEN = '24d4f7d9-0c11-4590-8f6a-080089e7b03e';
    public static VWBP_VORLAGE_TASK_UNSTIMMIGKEITEN_EINZUGSAUFTRAG = '706d0da3-2fac-4212-a6fa-62211c21cea5';
    public static VWBP_VORLAGE_TASK_UNSTIMMIGKEITEN_ERNEUT_BUESSEN = 'f7185c19-7ed3-4e20-a876-cf11ca33b74d';
    public static VWBP_VORLAGE_TASK_UNSTIMMIGKEITEN_RECHTSTRAEGER_INFORMIEREN = '3101c960-6efb-4fd6-b9ae-e29e4852b104';
    public static VWBP_VORLAGE_TASK_UNSTIMMIGKEITEN_AMTLICHES_LIQUIDATIONSVERFAHREN = '71102e3e-961f-4e6a-be64-6bb3f82041f7';
    public static VWBP_VORLAGE_TASK_MAHNWESEN_MAHNEN = '1a030f59-3503-4cfa-a538-80f6c4a91387';
    public static VWBP_VORLAGE_TASK_MAHNWESEN_BUESSEN = '03c946de-3ad8-4b71-8f9c-aaba5f5f2ac4';
    public static VWBP_VORLAGE_TASK_MAHNWESEN_EINZUGSAUFTRAG = 'f0462243-ab67-42b1-8fad-6beb38833fca';
    public static VWBP_VORLAGE_TASK_MAHNWESEN_ERNEUT_BUESSEN = 'df03fa8a-aabb-4326-80f1-d07b341a3d75';
    public static VWBP_VORLAGE_TASK_MAHNWESEN_AMTLICHES_LIQUIDATIONSVERFAHREN = '4805cff0-c49f-4273-bacb-ce78e6c99140';
    public static VWBP_VORLAGE_TASK_MAHNWESEN_NICHT_RELEVANT = 'ea743ec2-7771-4c6c-b42a-cd11f1f423d7';
    public static VWBP_VORLAGE_TASK_SCHWAERZEN_RECHTSTRAEGER_INFORMIEREN = 'd22a9cd2-7b84-4ad6-bb83-beb0fd1cfbea';
    public static VWBP_VORLAGE_TASK_SCHWAERZEN_RECHTSKRAFT = '49015af0-4e86-4f43-8a92-9f4105659f3e';
    public static VWBP_VORLAGE_TASK_SCHWAERZEN_BEARBEITET_BEWILIGT = 'a8e5f07c-1f28-4c99-83a5-fc0e34dfa810';
    public static VWBP_VORLAGE_TASK_SCHWAERZEN_BEARBEITET_ABGELEHNT = 'a062ee7a-b226-49ae-8c01-a40d4e0304f4';
    public static VWBP_VORLAGE_TASK_SCHWAERZEN_WB_NICHT_MEHR_VORHANDEN = '35a2e346-177d-4b09-bbde-f931652af072';
    public static VWBP_VORLAGE_TASK_SCHWAERZEN_GUELTIGKEIT_BEWILLIGT = '9F952F4F-1AF7-4EC6-8D66-0737AAA976E0';
    public static VWBP_VORLAGE_TASK_SCHWAERZEN_GUELTIGKEIT_FRISTABLAUF_3M = '4157A284-93EB-4EB4-96EA-350774AD44BB';
    public static VWBP_VORLAGE_TASK_SCHWAERZEN_GUELTIGKEIT_RT_INFORMIERT = '8EA29DF8-B616-4344-A7B0-583B0FAA3996';

    public static VWBP_SCHWAERZEN_ANTRAG_FRISTABLAUF_NONE = '78e887e0-ec5c-4dbe-a171-ac7e57c64c5d';
    public static VWBP_SCHWAERZEN_ANTRAG_FRISTABLAUF_ORDENTLICH = 'b29254ca-b010-4af1-a2ca-6b5eecf63551';
    public static VWBP_SCHWAERZEN_ANTRAG_FRISTABLAUF_WEGFALL_VORAUSSETZUNG = '5e8f1702-cdee-424d-9210-ce3aa30906a5';
    public static VWBP_SCHWAERZEN_ANTRAG_FRISTABLAUF_WB_NICHT_MEHR_VORHANDEN = '1b3ad12c-b1ad-4eda-a9b4-93f8b2717c1b';
    public static VWBP_SCHWAERZEN_ANTRAG_FRISTABLAUF_RT_GELOESCHT = '29acb9c2-cfde-470d-b412-857404ba9584';

    public static VWBP_FORMULAR_SCHWAERZEN_STATUS_KEIN = 'c929fee6-0b80-4520-8f4a-6457c225ab67';
    public static VWBP_FORMULAR_SCHWAERZEN_STATUS_ANTRAG = 'bfecf94b-7f24-4f6c-b92b-3e6296d4b852';
    public static VWBP_FORMULAR_SCHWAERZEN_STATUS_BEWILLIGT = '195f8a2e-8169-4289-a64b-442a3eab0da7';
    public static VWBP_FORMULAR_SCHWAERZEN_STATUS_FRIST_3MT = 'dcf38bb0-8251-4216-a851-12c9eb73fb53';
}

export class VwbPActionConst {
    public static VWBP_ACTION_RECHTSTRAEGER_STATUS_SETZEN = 'e8ec3265-05c8-41d2-a7d3-0c95d7c9f588';
    public static VWBP_ACTION_RECHTSTRAEGER_STATUS_SETZEN_RUECKGAENGIG = '9ad8e257-7bcf-49f5-bd9b-accd892da056';
    public static VWBP_ACTION_RECHTSTRAEGER_INBEARBEITUNG = 'fde4887b-b8da-4382-b2d9-f4364c898e41';
    public static VWBP_ACTION_RECHTSTRAEGER_ERFASSUNG_ABGESCHLOSSEN = '142f39ca-75fc-40f4-bfc6-0a76ae88d6c9';
    public static VWBP_ACTION_RECHTSTRAEGER_INBEARBEITUNG_RUECKGAENGIG = '362b9f79-4518-426e-a6ec-d39bfc652849';
    public static VWBP_ACTION_RECHTSTRAEGER_LOESCHEN = 'c5c14102-d4f4-44de-bdbe-52cf1a9976d9';
    public static VWBP_ACTION_RECHTSTRAEGER_SET_VEREINFACHTE_VALIDIERUNG = '7cd25a6d-086a-4db8-af7c-bad49040b8f1';
    public static VWBP_ACTION_RECHTSTRAEGER_SET_BEMERKUNG_TEILFONDS = 'D50D3282-5F1C-4F93-9341-4306D9750E38';
    public static VWBP_ACTION_RECHTSTRAEGER_SET_EINGETRAGEN_EWR_REGISTER = 'b2cc9222-dc48-4a28-a4d2-44b5f19a7a10';

    public static VWBP_ACTION_SCHWAERZEN_SET_SCHWAERZUNG_VORHANDEN = 'a4d8c0a4-29f1-4b6b-b231-83eb620795f4';

    public static VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_AKTIV = 'efc5c0b0-0699-4ca3-830a-58a41c425a34';
    public static VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_AKTIV_MIT_MAHNFRIST = 'b8490c4f-21a4-432a-803d-d0a00d1eca9e';
    public static VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_AKTIV_MIT_RECHTSMITTELFRIST = 'e8410a44-5ebb-4772-a803-27408d60e770';
    public static VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_AKTIV_MIT_BUSSFRIST = '0c655a48-6d44-4127-ba59-cd43ceab9240';
    public static VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_PAUSIERT = 'd4e408b7-ee7d-44bf-a249-af4c49c82811';
    public static VWBP_ACTION_UNSTIMMIGKEITSSTATUS_SETZEN = 'a477c794-50ad-4dd7-88e9-4fba91ff1c67';
    public static VWBP_ACTION_UNSTIMMIGKEIT_MAHNFRIST_ANPASSEN = '028f5d2c-434c-4a04-aa8f-cb0bf8e7fbd5';
    public static VWBP_ACTION_UNSTIMMIGKEIT_MAHNEN = '1d49249f-98ed-4227-bf38-3b63f03e6aa6';
    public static VWBP_ACTION_UNSTIMMIGKEIT_RECHTSMITTELFRIST_ANPASSEN = '10dbdc08-d38c-4102-a85c-ac62920deb23';
    public static VWBP_ACTION_UNSTIMMIGKEIT_BUESSEN = 'd9da1dbe-6a7c-401e-9355-1ded70fe3313';
    public static VWBP_ACTION_UNSTIMMIGKEIT_ERNEUT_BUESSEN = '7691f5c7-ef27-41af-bc1d-ab6d08487cd1';
    public static VWBP_ACTION_UNSTIMMIGKEIT_EINZUGSAUFTRAG = '229b6b38-c23e-4588-8428-a06e4d4ae087';
    public static VWBP_ACTION_UNSTIMMIGKEIT_BUSSFRIST_ANPASSEN = '4cf27b02-6d4c-4869-bdb6-a4e4da46be33';
    public static VWBP_ACTION_UNSTIMMIGKEIT_AMTLICHE_AUFLOESUNG = '845e678c-8621-4006-b80b-f8190e80a69b';
    public static VWBP_ACTION_UNSTIMMIGKEIT_LOESCHEN = 'f988d30b-07b5-40ea-ac19-e2bed92249e5';
    public static VWBP_ACTION_UNSTIMMIGKEIT_ZURUECK_AUF_INFORMIERT = 'c313d56d-bea6-4d13-87a1-537841293756';

    public static VWBP_ACTION_UNSTIMMIGKEIT_STATUS_SETZEN_RUECKGAENGIG = 'ec8e79f5-2461-4f66-be5b-f64abb31b7e9';
    public static VWBP_ACTION_UNSTIMMIGKEIT_MAHNEN_RUECKGAENGIG = '6454194e-e3f3-4bf1-8788-5463ceddc6cd';
    public static VWBP_ACTION_UNSTIMMIGKEIT_BUESSEN_RUECKGAENGIG = 'e8854b29-71da-4be5-9af5-22bda08e6eab';
    public static VWBP_ACTION_UNSTIMMIGKEIT_EINZUGSAUFTRAG_RUECKGAENGIG = 'c4df72c1-c0b7-4aaa-8b1f-13da02ba9a42';
    public static VWBP_ACTION_UNSTIMMIGKEIT_LOESCHVERFAHREN_RUECKGAENGIG = '885e2558-c518-47bf-bd2d-fb6823b2b708';

    public static VWBP_ACTION_UEBERGABE_RT_LOESCHEN = '99d2569a-ccc5-4f0a-ba83-0e224ff6481f';
    public static VWBP_ACTION_UEBERGABE_RT_ABGEBEN = '2b675002-4a95-48b5-92fc-bd53099353d9';
    public static VWBP_ACTION_UEBERGABE_RT_ANFORDERN = 'fc69d384-5980-495e-b733-31a4dd65c0f3';
    public static VWBP_ACTION_UEBERGABE_RT_INAKTIVIEREN = '3173b027-7ce7-452e-918e-c5865a982566';
    public static VWBP_ACTION_UEBERGABE_RT_REAKTIVIEREN = 'c06acd77-f25b-450c-a94f-397ce7fa81d8';

    public static VWBP_ACTION_UEBERGABEANFRAGE_STATUS_SETZEN = '0acc45cf-8c5f-4b54-be0b-5db3225332ac';

    public static VWBP_ACTION_MAHNWESEN_AKTIV = '4998329b-c402-4aad-8f4c-34f38759bc65';
    public static VWBP_ACTION_MAHNWESEN_AKTIV_MIT_MAHNFRIST = '57972c9b-82a5-4ecc-9763-83b838f63dda';
    public static VWBP_ACTION_MAHNWESEN_AKTIV_MIT_RECHTSMITTELFRIST = '5ee8ca88-3ad2-4b61-9f55-36e0860cc1b6';
    public static VWBP_ACTION_MAHNWESEN_AKTIV_MIT_BUSSFRIST = '4a91eed2-1d34-4eb5-8a7b-af2329593a7d';
    public static VWBP_ACTION_MAHNWESEN_PAUSIERT = '172bb3e6-21e0-4339-8cc0-2214528203bf';
    public static VWBP_ACTION_MAHNWESEN_MAHNFRIST_ANPASSEN = 'ced7a0ff-6caf-4853-9955-19b8303e072b';
    public static VWBP_ACTION_MAHNWESEN_RECHTSMITTELFRIST_ANPASSEN = 'b9001ce6-fdfe-4227-a600-fec627859fa0';
    public static VWBP_ACTION_MAHNWESEN_BUSSFRIST_ANPASSEN = '3299c609-484a-4ecd-8bc8-12e238512826';
    public static VWBP_ACTION_MAHNWESEN_MAHNEN = '1ab64c45-4a35-46e4-9e6e-291638abfea9';
    public static VWBP_ACTION_MAHNWESEN_BUESSEN = 'a94e7923-6206-406f-b7af-25a137700f8c';
    public static VWBP_ACTION_MAHNWESEN_ERNEUT_BUESSEN = '946bf007-ea89-4577-89bd-b23c474b628d';
    public static VWBP_ACTION_MAHNWESEN_EINZUGSAUFTRAG = 'bddfb4ea-dd01-48e2-9b82-ba07db889529';
    public static VWBP_ACTION_MAHNWESEN_STATUS_SETZEN = 'd77a9414-c2e3-4772-a86d-a5fb6ee5dc18';
    public static VWBP_ACTION_MAHNWESEN_MAHNEN_RUECKGAENGIG = '794b090c-6257-47e7-a70c-9cea340f636f';
    public static VWBP_ACTION_MAHNWESEN_BUESSEN_RUECKGAENGIG = 'f3a7fae5-1053-48e8-a4a2-643d6f411cfc';
    public static VWBP_ACTION_MAHNWESEN_EINZUGSAUFTRAG_RUECKGAENGIG = '85727e3f-fe21-4898-9699-438c38427827';
    public static VWBP_ACTION_MAHNWESEN_LOESCHVERFAHREN_RUECKGAENGIG = '6b3ababa-d53f-48a9-808d-cb1f7ae41845';
    public static VWBP_ACTION_MAHNWESEN_NICHT_RELEVANT_RUECKGAENGIG = 'e67ca997-d3f1-4796-a325-f024d54972d3';

    public static VWBP_ACTION_SCHWAERZEN_RECHTSKRAFT_SETZEN = '90a55675-994c-403b-8bd4-f1935612b642';
    public static VWBP_ACTION_SCHWAERZEN_RECHTSKRAFT_SETZEN_RUECKGAENGIG = 'ed77df04-d40b-48d2-99c7-b9b2828917dd';
    public static VWBP_ACTION_SCHWAERZEN_RECHTSTRAEGER_INFORMIEREN_RUECKGAENGIG = 'adaca2eb-65b3-4c98-92fa-141db1c4a2f2';
    public static VWBP_ACTION_SCHWAERZEN_VERSION_KONTROLLIEREN = '3b26b16e-2dd5-4f7e-9f77-f054369878b8';
    public static VWBP_ACTION_SCHWAERZEN_RECHTSTRAEGER_INFORMIEREN = '3a517729-9345-4c51-a276-7631c41963c3';
    public static VWBP_ACTION_SCHWAERZEN_RECHTSMITTELFRIST_ANPASSEN = '70f1ff37-2918-468f-8c5c-e090b45c555d';
    public static VWBP_ACTION_SCHWAERZEN_BEARBEITET_SETZEN = '3886cac5-2244-4be0-8211-6c42a72e2292';
    public static VWBP_ACTION_SCHWAERZEN_ENSCHEIDUNG_ANPASSEN = 'a3af9430-17b9-4fa8-ab88-626fb19206ae';
    public static VWBP_ACTION_SCHWAERZEN_GUELTIGKEIT_ANPASSEN = '94a32146-f70e-434d-8c01-073a9f291323';
    public static VWBP_ACTION_SCHWAERZEN_WB_NICHT_MEHR_VORHANDEN = 'ce02b324-ce52-47a6-92f5-dfe52382bfd1';

    public static VWBP_ACTION_SCHWAERZEN_AKTIV = '9feff03b-2929-4fda-bb90-9621fb75ebdb';
    public static VWBP_ACTION_SCHWAERZEN_AKTIV_MIT_RECHTSMITTELFRIST = '3dcd19f4-9888-4853-a739-9d28f7f4a7ab';
    public static VWBP_ACTION_SCHWAERZEN_PAUSIERT = '377ced79-eb3f-4a61-bd89-1bbd0db5a145';
}

export class VwbPRoleConst {
    public static VWBP_ROLE_VWBP_BASIS = 'VwbP Basis';
    public static VWBP_ROLE_VWBP_AJU_LESEN = 'VwbP AJU lesen';
    public static VWBP_ROLE_VWBP_AJU_SCHREIBEN = 'VwbP AJU schreiben';
    public static VWBP_ROLE_VWBP_BEHOERDEN = 'VwbP Behoerden';
    public static VWBP_ROLE_VWBP_ADMIN = 'VwbP Admin';
    public static VWBP_AJU_PLUS = 'VwbP AJU plus';

    public static readonly RESOURCEN_SCHREIBEN = 'Resourcen schreiben';
}
