import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { ListType } from '../shared';
import TransformDate from '../shared/transformdate';

export class StatusWorkflowDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;

    @Expose()
    @TransformDate()
	public GueltigAb: Date;

    @Expose()
    @TransformDate()
	public GueltigBis: Date;

    @Expose()
	public System: number;

	@Expose()
	@Type(() => ListType)
	public Typ: ListType;

    @Expose()
	public KurzBez: string;

    @Expose()
	public Bezeichnung: string;

	@Expose()
	@Type(() => ListType)
	public StartNachErfassen: ListType;

	@Expose()
	@Type(() => ListType)
	public StartNachEditieren: ListType;

    @Expose()
	public StartBedingungOutput: string;

	@Expose()
	public ContextModelName: string;
}