import { AviListDetailConst, AviApiService, AviCommonService } from '@avi-x/avi-core';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { VwbPCommonService, VwbPListDetailConst, VwbPRechtstraeger, VwbPRechtstraegerDetail, VwbPVersion } from '@avi-x/vwbp-public';
import { VwbPMahnung } from '../../../dto/vwbpmahnung.model';
import { VwbPBusse } from '../../../dto/vwbpbusse.model';
import { VwbPUnstimmigkeit } from '../../../dto/vwbpunstimmigkeit.model';
import { MessageType } from 'libs/avi-core/src/lib/services/common.service';


@Component({
    selector: 'vwbp-intern-rechtstraeger-miniview',
    templateUrl: './rechtstraeger-miniview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternRechtstraegerMiniViewComponent implements OnInit, OnChanges {
    VwbPListDetailConst = VwbPListDetailConst;

    @Input('InterneId')
    InterneId: number = null;

    @Input('SelectedDetail')
    SelectedDetail: VwbPRechtstraegerDetail = null;

    @Input('SelectedVersion')
    SelectedVersion: VwbPVersion = null;

    _SelectedRT: VwbPRechtstraeger = null;
    public get SelectedRT(): VwbPRechtstraeger {
        return this._SelectedRT;
    }

    @Input('SelectedRT')
    public set SelectedRT(value: VwbPRechtstraeger) {
        this._SelectedRT = value;
    }

    @Output()
    public onToggleValidierungAusnahme: EventEmitter<string> = new EventEmitter();

    @Output()
    public onToggleBemerkungTeilfonds: EventEmitter<string> = new EventEmitter();

    @Output()
    public onToggleEingetragenEWRRegister: EventEmitter<string> = new EventEmitter();

    IsBehoerdenUser: boolean = false;
    IsAJUSchreibenUser: boolean = false;
    IsAJUUser: boolean = false;

    LastMahnung: VwbPMahnung = null;
    LastBusse: VwbPBusse = null;

    Unstimmigkeit: VwbPUnstimmigkeit = null;
    LastUnstimmigkeitMahnung: VwbPMahnung = null;
    LastUnstimmigkeitBusse: VwbPBusse = null;

    constructor(public commonService: AviCommonService, public vwbpCommonService: VwbPCommonService, public apiService: AviApiService, public cdr: ChangeDetectorRef) {
    }

    ngOnChanges() {
        this.setRechtstraeger();
    }

    async setRechtstraeger() {
        if (this.SelectedDetail != null && this.SelectedVersion != null && this._SelectedRT != null) {
            if (this.IsMahnprozess() && this.SelectedRT && this.SelectedRT.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_MAHNLAUF &&
                this.vwbpCommonService.currentUserIsAjuUser() && !this.vwbpCommonService.currentUserIsOnlyBehoerdenUser()) {
                this.LastMahnung = await this.apiService.getModel(VwbPMahnung, `v1/intern/vwbp/mahnwesen/letzte_mahnung/${this.SelectedRT.Id}`);
                this.LastBusse = await this.apiService.getModel(VwbPBusse, `v1/intern/vwbp/mahnwesen/letzte_busse/${this.SelectedRT.Id}`);
            } else {
                this.LastMahnung = null;
                this.LastBusse = null;
            }

            if (this.SelectedVersion && this.vwbpCommonService.currentUserIsAjuUser() && !this.vwbpCommonService.currentUserIsOnlyBehoerdenUser()) {
                this.Unstimmigkeit = await this.apiService.getModel(VwbPUnstimmigkeit, `v1/intern/vwbp/unstimmigkeit/version/${this.SelectedVersion.Id}`);
                if (this.Unstimmigkeit && this.Unstimmigkeit.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_MAHNLAUF) {
                    this.LastUnstimmigkeitMahnung = await this.apiService.getModel(VwbPMahnung, `v1/intern/vwbp/unstimmigkeit/letzte_mahnung/${this.SelectedVersion.Id}`);
                    this.LastUnstimmigkeitBusse = await this.apiService.getModel(VwbPBusse, `v1/intern/vwbp/unstimmigkeit/letzte_busse/${this.SelectedVersion.Id}`);
                } else {
                    this.LastUnstimmigkeitMahnung = null;
                    this.LastUnstimmigkeitBusse = null;
                }
            } else {
                this.Unstimmigkeit = null;
                this.LastUnstimmigkeitMahnung = null;
                this.LastUnstimmigkeitBusse = null;
            }
        }
    }

    // async setVersion() {
    //     if (this.SelectedVersion && this.vwbpCommonService.currentUserIsAjuUser() && !this.vwbpCommonService.currentUserIsOnlyBehoerdenUser()) {
    //         this.Unstimmigkeit = await this.apiService.getModel(VwbPUnstimmigkeit, `v1/intern/vwbp/unstimmigkeit/version/${this.SelectedVersion.Id}`);
    //         if (this.Unstimmigkeit && this.Unstimmigkeit.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_MAHNLAUF) {
    //             this.LastUnstimmigkeitMahnung = await this.apiService.getModel(VwbPMahnung, `v1/intern/vwbp/unstimmigkeit/letzte_mahnung/${this.SelectedVersion.Id}`);
    //             this.LastUnstimmigkeitBusse = await this.apiService.getModel(VwbPBusse, `v1/intern/vwbp/unstimmigkeit/letzte_busse/${this.SelectedVersion.Id}`);
    //         } else {
    //             this.LastUnstimmigkeitMahnung = null;
    //             this.LastUnstimmigkeitBusse = null;
    //         }

    //     } else {
    //         this.Unstimmigkeit = null;
    //         this.LastUnstimmigkeitMahnung = null;
    //         this.LastUnstimmigkeitBusse = null;
    //     }

    //     this.cdr.markForCheck();
    // }

    isInBearbeitung() {
        return this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_INBEARBEITUNG || this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_MUTIEREN;
    }

    getUnstimmigkeitStatus() {
        if (this.Unstimmigkeit && this.LastUnstimmigkeitBusse && this.Unstimmigkeit.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_MAHNLAUF)
            return this.LastUnstimmigkeitBusse.Status.Bezeichnung;
        else if (this.Unstimmigkeit && this.LastUnstimmigkeitMahnung && this.Unstimmigkeit.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_MAHNLAUF)
            return this.LastUnstimmigkeitMahnung.Status.Bezeichnung;

        return this.Unstimmigkeit.Unstimmigkeitsstatus.Bezeichnung;
    }

    IsUnstimmigkeitProzessAbgeschlossen() {
        return this.Unstimmigkeit && this.Unstimmigkeit.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_ABGESCHLOSSEN;
    }

    getMahnwesenStatus() {
        if (this.LastBusse && this.SelectedRT.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_MAHNLAUF)
            return this.LastBusse.Status.Bezeichnung;
        else if (this.LastMahnung && this.SelectedRT.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_MAHNLAUF)
            return this.LastMahnung.Status.Bezeichnung;

        return this.SelectedRT.MahnwesenStatus.Bezeichnung;
    }

    IsMahnprozess() {
        return this.SelectedRT.MahnwesenStatus.Id !== VwbPListDetailConst.VWBP_MAHNWESENSTATUS_OFFEN;
    }

    IsMahnprozessAbgeschlossen() {
        return this.SelectedRT.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_NICHT_RELEVANT || this.SelectedRT.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_ABGESCHLOSSEN;
    }

    IsValidierungAusnahme() {
        return this.SelectedRT.ValidierungAusnahme?.Id === AviListDetailConst.JA;
    }

    onValidierungAusnahmeChange(event) {
        event.preventDefault();
        event.stopPropagation();

        // if (this.IsAJUSchreibenUser) {
        //     // Falls BemerkungTeilfonds eingeschaltet, darf ValidierungAusnahme nicht aktiviert werden
        //     if (this.SelectedRT.BemerkungTeilfonds.Id === AviListDetailConst.JA)
        //         this.commonService.showMessage('CORE.COMMON.ERROR', 'VWBP.INTERN.VEREINFACHTE_VALIDIERUNG.BEMERKUNG_TEILFONDS_AKTIV', MessageType.Warning);
        //     else if (this.SelectedRT.ValidierungAusnahme.Id === AviListDetailConst.JA)
        //         this.onToggleValidierungAusnahme.emit(AviListDetailConst.NEIN);
        //     else
        //         this.onToggleValidierungAusnahme.emit(AviListDetailConst.JA);
        // }

        if (this.IsAJUSchreibenUser) {
            if (this.SelectedRT.ValidierungAusnahme.Id === AviListDetailConst.JA)
                this.onToggleValidierungAusnahme.emit(AviListDetailConst.NEIN);
            else
                this.onToggleValidierungAusnahme.emit(AviListDetailConst.JA);
        }
    }

    IsBemerkungTeilfonds() {
        return this.SelectedRT.BemerkungTeilfonds?.Id == AviListDetailConst.JA;
    }

    onBemerkungTeilfondsChange(event) {
        event.preventDefault();
        event.stopPropagation();

        // if (this.IsAJUSchreibenUser) {
        //     // Falls ValidierungAusnahme eingeschaltet, darf BemerkungTeilfonds nicht aktiviert werden
        //     if (this.SelectedRT.ValidierungAusnahme.Id === AviListDetailConst.JA)
        //         this.commonService.showMessage('CORE.COMMON.ERROR', 'VWBP.INTERN.BEMERKUNG_TEILFONDS.VEREINFACHTE_VALIDIERUNG_AKTIV', MessageType.Warning);
        //     else if (this.SelectedRT.BemerkungTeilfonds.Id === AviListDetailConst.JA)
        //         this.onToggleBemerkungTeilfonds.emit(AviListDetailConst.NEIN);
        //     else
        //         this.onToggleBemerkungTeilfonds.emit(AviListDetailConst.JA);
        // }

        if (this.IsAJUSchreibenUser) {
            if (this.SelectedRT.BemerkungTeilfonds.Id === AviListDetailConst.JA)
                this.onToggleBemerkungTeilfonds.emit(AviListDetailConst.NEIN);
            else
                this.onToggleBemerkungTeilfonds.emit(AviListDetailConst.JA);
        }
    }

    IsSchwaerzungVorhanden() {
        return this.SelectedRT.SchwaerzungVorhanden?.Id === AviListDetailConst.JA;
    }

    IsTrust(): boolean {
        return Boolean(this.SelectedDetail?.IsTrust);
    }

    IsEingetragenEWRRegister() {
        return this.SelectedRT.EingetragenEWRRegister?.Id === AviListDetailConst.JA;
    }

    onEingetragenEWRRegisterChange(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.IsAJUSchreibenUser) {
            if (this.SelectedRT.EingetragenEWRRegister.Id === AviListDetailConst.JA)
                this.onToggleEingetragenEWRRegister.emit(AviListDetailConst.NEIN);
            else
                this.onToggleEingetragenEWRRegister.emit(AviListDetailConst.JA);
        }
    }

    ngOnInit() {
        this.IsBehoerdenUser = this.vwbpCommonService.currentUserIsOnlyBehoerdenUser();
        this.IsAJUSchreibenUser = this.vwbpCommonService.currentUserIsAjuUserSchreiben();
        this.IsAJUUser = this.vwbpCommonService.currentUserIsAjuUser();
    }

    GetErfassungsstatus() {
        if (!this.vwbpCommonService.currentUserIsAjuUser() && this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN)
            return 'Erfassung abgeschlossen';

        return this.SelectedVersion?.Erfassungsstatus.Bezeichnung;
    }
}
