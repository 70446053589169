import { Component, ChangeDetectorRef } from '@angular/core';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternUnstimmigkeitService } from '../../services/unstimmigkeit.service';
import { VwbPInternUnstimmigkeitBaseComponent } from './unstimmigkeit-base.component';

@Component({
    selector: 'vwbp-intern-unstimmigkeit-informiert',
    template: `
        <vwbp-intern-rechtstraeger-versionen-searcher #searcher settingskey="settings.searcher.um.informiert" [use-router-params]="true" [get-filters]="GetFilters" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'Version', 'InformiertAm', 'btnOpen']">
        </vwbp-intern-rechtstraeger-versionen-searcher>

        <div *ngIf="currentUserIsAjuUserSchreiben()" fxLayout="row">
            <div class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-unstimmigkeit-mahnen-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-unstimmigkeit-mahnen-task>
            </div>
            <!-- <div class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-unstimmigkeit-abschliessen-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-unstimmigkeit-abschliessen-task>
            </div> -->
        </div>
        `
})
export class VwbPInternUnstimmigkeitInformiertComponent extends VwbPInternUnstimmigkeitBaseComponent {
    constructor(public commonService: AviCommonService, public unstimmigkeitService: VwbPInternUnstimmigkeitService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, unstimmigkeitService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters = (): string[] => {
        return [`Unstimmigkeitsstatus eq ${VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_INFORMIERT}`];
    }
}
