import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService } from '@avi-x/avi-core';
import { AviUserContactInfoSearcherComponent } from './components/user-contactinfo-searcher.ts/user-contactinfo-searcher.component';

const routes: Routes = [
    { path: 'usercontactinfos', component: AviUserContactInfoSearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] }
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviUserContactInfoRoutingModule {
}
