import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AviCommonService, AviApiService } from '@avi-x/avi-core';
import { MatTabNav } from '@angular/material/tabs';

@Component({
    selector: 'vwbp-intern-mahnwesen-index',
    templateUrl: './mahnwesen-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPInternMahnwesenIndexComponent implements OnInit {

    navLinks = [

        { path: './nichterfasst', label: 'Nicht erfasst' },
        { path: './inbearbeitung', label: 'In Bearbeitung' },
        { path: './gemahnt', label: 'Gemahnt' },
        { path: './mahnfrist-abgelaufen', label: 'Mahnfrist abgelaufen' },
        { path: './gebuesst', label: 'Gebüsst' },
        { path: './rechtsmittelfrist-abgelaufen', label: 'Rechtsmittelfrist abgelaufen' },
        { path: './bussfrist-lauft', label: 'Bussfrist läuft' },
        { path: './bussfrist-abgelaufen', label: 'Bussfrist abgelaufen' },
        { path: './amtliches-loeschverfahren', label: 'Amtliches Liquidationsverfahren' },
        { path: './nicht-relevant', label: 'Nicht relevant' },
        { path: './abgeschlossen', label: 'Abgeschlossen' }
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
    ) {
    }

    ngOnInit() {
    }
}
