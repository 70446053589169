import { NgxPermissionsModule } from 'ngx-permissions';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SplitterModule } from 'primeng/splitter';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AviCoreModule } from '@avi-x/avi-core';

import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TreeModule } from 'primeng/tree';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonModule } from 'primeng/togglebutton';

// import { BrowserModule } from '@angular/platform-browser';

import { HighlightModule } from 'ngx-highlightjs';
import { FormsModule } from '@angular/forms';
import { AviCustomPropCollectionDefSearcherComponent } from './components/custompropcollectiondef-searcher.component';
import { AviCustomPropCollectionDefViewComponent } from './components/custompropcollectiondef-view.component';
import { AviCustomPropCollectionDefFormComponent } from './components/custompropcollectiondef-form.component';
import { AviCustomPropCollectionFormComponent } from './components/custompropcollection-form.component';
import { AviCustomPropDefSearcherComponent } from './components/custompropdef-searcher.component';
import { AviCustomPropDefFormComponent } from './components/custompropdef-form.component';


@NgModule({
    imports: [
        // BrowserModule,
        FormsModule,
        CommonModule,
        AviCoreModule,

        TreeModule,
        FlexLayoutModule,
        HighlightModule,
        PanelModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ButtonModule,
        SplitButtonModule,
        FileUploadModule,
        InputTextModule,
        MenuModule,
        SplitterModule,
        BreadcrumbModule,

        NgxPermissionsModule.forChild(),

        TranslateModule
    ],
    exports: [
        AviCustomPropCollectionDefSearcherComponent,
        AviCustomPropCollectionDefViewComponent,
        AviCustomPropCollectionDefFormComponent,
        AviCustomPropDefSearcherComponent,
        AviCustomPropDefFormComponent,
        AviCustomPropCollectionFormComponent,
    ],
    declarations: [
        AviCustomPropCollectionDefSearcherComponent,
        AviCustomPropCollectionDefViewComponent,
        AviCustomPropCollectionDefFormComponent,
        AviCustomPropDefSearcherComponent,
        AviCustomPropDefFormComponent,
        AviCustomPropCollectionFormComponent
    ]
})
export class AviFormularModule { }
