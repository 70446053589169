import { Expose, Type } from 'class-transformer';

import { AenderungsGrundDef } from '../workflow/aenderungsgrunddef.model';
import { ListDetail } from '../system/listdetail.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared/genericref.model';

export class AenderungsGrundAttrLink extends BaseModel {  
    @Expose()
    public get Attribute(): string { return this.getProperty('Attribute'); }
    public set Attribute(value: string) { this.setProperty('Attribute', value); }
    
    @Expose()
    public get ValueBool(): boolean { return this.getProperty('ValueBool'); }
    public set ValueBool(value: boolean) { this.setProperty('ValueBool', value); }
    
    @Expose()
    public get ValueInt(): number { return this.getProperty('ValueInt'); }
    public set ValueInt(value: number) { this.setProperty('ValueInt', value); }
    
    @Expose()
    public get ValueDec(): number { return this.getProperty('ValueDec'); }
    public set ValueDec(value: number) { this.setProperty('ValueDec', value); }
    
    @Expose()
    public get ValueText(): string { return this.getProperty('ValueText'); }
    public set ValueText(value: string) { this.setProperty('ValueText', value); }
    
    @Expose()
    @Type(() => Date)
    public get ValueDate(): Date { return this.getProperty('ValueDate'); }
    public set ValueDate(value: Date) { this.setProperty('ValueDate', value); }
    
    @Expose()
    public get vonAenderungDef_ID(): string { return this.getModelId('vonAenderungDef_ID'); }
    public set vonAenderungDef_ID(value: string) { this.setModelId('vonAenderungDef_ID', value); }
 
    @Expose()
    @Type(() => AenderungsGrundDef)
    public get vonAenderungDef(): AenderungsGrundDef { return this.getModel('vonAenderungDef'); }
    public set vonAenderungDef(value: AenderungsGrundDef) { this.setModel('vonAenderungDef', value); }
    
    @Expose()
    public get ValueListDetail_ID(): string { return this.getModelId('ValueListDetail_ID'); }
    public set ValueListDetail_ID(value: string) { this.setModelId('ValueListDetail_ID', value); }
 
    @Expose()
    @Type(() => ListDetail)
    public get ValueListDetail(): ListDetail { return this.getModel('ValueListDetail'); }
    public set ValueListDetail(value: ListDetail) { this.setModel('ValueListDetail', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AllowChange(): ListType { return this.getProperty('AllowChange'); }
    public set AllowChange(value: ListType) { this.setProperty('AllowChange', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Sichtbar(): ListType { return this.getProperty('Sichtbar'); }
    public set Sichtbar(value: ListType) { this.setProperty('Sichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Obligatory(): ListType { return this.getProperty('Obligatory'); }
    public set Obligatory(value: ListType) { this.setProperty('Obligatory', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ChangeValue(): ListType { return this.getProperty('ChangeValue'); }
    public set ChangeValue(value: ListType) { this.setProperty('ChangeValue', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get ValueRef(): GenericRef { return this.getProperty('ValueRef'); }
    public set ValueRef(value: GenericRef) { this.setProperty('ValueRef', value); }
    
}
