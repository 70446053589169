import { NgxPermissionsService } from 'ngx-permissions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs/operators';
import { AviAuthConfig, AviCoreOAuthConfigs } from '../avi-core.config';
import { AviApiService, AviCustomHeader } from '../services/api.service';
import { AviCurrentUser } from '../services/auth.service';
import { AviCommonService } from '../services/common.service';

export function init_app(authConfigService: OAuthConfigService) {
    return () => authConfigService.initAuth();
}

@Injectable({ providedIn: 'root' })
export class OAuthConfigService {
    private _decodedAccessToken: any;
    private _decodedIDToken: any;
    get decodedAccessToken() { return this._decodedAccessToken; }
    get decodedIDToken() { return this._decodedIDToken; }

    private BaseUrl: string = null;
    private ApiPrefix: string = '';

    constructor(
        private readonly oauthService: OAuthService,
        private readonly authConfigs: AviCoreOAuthConfigs,
        private readonly http: HttpClient,
        private permissionService: NgxPermissionsService
    ) {
    }

    async ensureApiPrefix(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.ApiPrefix) {
                resolve(this.ApiPrefix);
            } else {
                this.http.get('assets/config.txt')
                    .toPromise<any>().then(jsonConfig => {
                        this.ApiPrefix = jsonConfig.baseUrl_prefix;
                        resolve(this.ApiPrefix);
                    });
            }
        });
    }

    async ensureBaseUrl(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.BaseUrl) {
                resolve(this.BaseUrl);
            } else {
                this.http.get('assets/config.txt')
                    .toPromise<any>().then(jsonConfig => {
                        this.BaseUrl = jsonConfig.baseURL;
                        resolve(this.BaseUrl);
                    });
            }
        });
    }

    private buildApiUrl(url: string): string {
        if (url.startsWith('/')) url = url.substr(1);
        if (!url.startsWith(this.BaseUrl) && !url.startsWith('http'))
            url = this.BaseUrl + (this.BaseUrl.endsWith('/') ? '' : '/') + url;
        return url;
    }

    private async get(url: string, cu: AviCurrentUser): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);
        return this.http.get(intUrl, { headers: this.getHeaders(cu), withCredentials: false })
            .toPromise()
            .then(response => { return AviApiService.handleJsonResponse(response); })
            .catch();
    }

    private getHeaders(cu: AviCurrentUser, contentType: string = 'application/json'): HttpHeaders {
        let rHeaders = new HttpHeaders().set('X-avi-h1', 'h1h');
        rHeaders = rHeaders.set('Content-Type', contentType);
        rHeaders = rHeaders.set('Accept', 'application/json');
        rHeaders = rHeaders.set('Authorization', 'Bearer ' + cu.AccessToken);
        return rHeaders;
    }

    async initAuth(): Promise<void> {

        return new Promise((resolveFn, rejectFn) => {

            if (!this.authConfigs) {
                // console.debug('OAuthConfigService - keine authConfigs - Abbruch');
                resolveFn();
                return;
            }

            // setup oauthService
            const lastConfig = this.GetLastUsedAuthConfig();
            this.oauthService.configure(lastConfig);
            this.oauthService.setStorage(lastConfig.storage === 'sessionStorage' ? sessionStorage : localStorage);
            this.oauthService.tokenValidationHandler = new NullValidationHandler();

            // subscribe to token events
            this.oauthService.events
                .pipe(filter((e: any) => {
                    return e.type === 'token_received';
                }))
                .subscribe(() => this.handleNewToken());

            this.oauthService.loadDiscoveryDocumentAndTryLogin({
                onTokenReceived: (info) => {
                    console.warn('OAuthConfigService.onTokenReceived', info); 
                }
            }).then(async isLoggedIn => {
                if (isLoggedIn) {
                    this.oauthService.setupAutomaticSilentRefresh();
                    try {
                        const claims = <any>this.oauthService.getIdentityClaims();
                        if (claims) {
                            const cu = new AviCurrentUser();
                            cu.AccessToken = this.oauthService.getAccessToken();
                            cu.Username = claims.preferred_username; // res.username;
                            cu.FirstName = claims.given_name;
                            cu.LastName = claims.family_name;
                            cu.ExpiresAtUtc = new Date(claims.exp * 1000).toISOString(); // res.expires_at_utc;
                            cu.Roles = claims.roles;

                            await this.ensureApiPrefix();
                            const url = `${this.ApiPrefix ?? ''}/user/userinfo`;
                            const userInfos = await this.get(url, cu);                            
                            cu.Uid = userInfos.LoginuserID;
                            cu.LanguageId = userInfos.UserLanguageID;
                            cu.CustomInfo = userInfos.CustomInfo;
                            cu.UserSettings = userInfos.UserSettings;
                            this.permissionService.addPermission(userInfos.Roles);

                            AviCommonService.localStorageSet('currentUser', cu, true, lastConfig.storage === 'sessionStorage');
                        } else {
                            console.warn('OAuthConfigService - keine Claims gefunden. Logout...');
                            this.logout();
                        }

                        resolveFn();
                    } catch (ex) {
                        console.warn('OAuthConfigService - Exception', ex);
                        this.logout();
                        resolveFn();
                    }
                } else {
                    // this.oauthService.initImplicitFlow();
                    this.logout();
                    // console.debug('OAuthConfigService - Nicht angemeldet');
                    rejectFn();
                }
            }).catch(err => {
                console.warn('OAuthConfigService - err:', err);
                this.logout();
                // rejectFn(err); // wird vom Global Exceptionhandler nicht gefangen und dann bleibt die GUI immer bei "Applikation wird geladen ..." hängen
                resolveFn();
            });
        });
    }


    private logout() {
        localStorage.removeItem(AviCommonService._localStoreKeyPrefix + 'currentUser');
        sessionStorage.removeItem(AviCommonService._localStoreKeyPrefix + 'currentUser');
    }

    public GetLastUsedAuthConfig(): AviAuthConfig {
        const lastType = localStorage.getItem('avi.oauth2.lastconfig');
        if (Object.keys(this.authConfigs).indexOf(lastType) >= 0)
            return this.authConfigs[lastType];
        else
            return this.authConfigs[Object.keys(this.authConfigs)[0]];
    }

    public SaveLastUsedAuthConfig(configName: string): void {
            localStorage.setItem('avi.oauth2.lastconfig', configName);
    }

    private handleNewToken() {
        this._decodedAccessToken = this.oauthService.getAccessToken();
        this._decodedIDToken = this.oauthService.getIdToken();
    }
}
