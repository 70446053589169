import { ChangeDetectorRef, Component } from '@angular/core';
import { AviApiService, AviCommonService, AviJobService, AviListDetailConst, InputmaskService } from '@avi-x/avi-core';
import { VwbPInternMahnwesenService } from '../../services/mahnwesen.service';
import { VwbPInternTaskBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-tasks/task-base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';

@Component({
    selector: 'vwbp-intern-mahnwesen-einzugsauftrag-task',
    template: `
      <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
            {{ 'VWBP.INTERN.MAHNWESEN.EINZUGSAUFTRAG_AN_LK_BUTTON' | translate}}
        </div>
        <div class="grid form-group">
            <div class="col-12">
                <span class="p-float-label mb-4p">
                    <p-calendar #calendar2 id="cal2" appendTo="body" [baseZIndex]="10000" (onInput)="onInputDate($event, calendar2)" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [firstDayOfWeek]="1" [(ngModel)]="Datum" [selectOtherMonths]="true" dateFormat="dd.mm.yy" [readonlyInput]="false" [showOnFocus]="false" [showIcon]="true"></p-calendar>
                    <span [hidden]="!DatumInvalid" class="ui-message ui-messages-error ui-corner-all nopad-force">Die Bussfrist darf nicht in der Vergangenheit liegen</span>
                    <label for="cal2">{{ 'VWBP.INTERN.MAHNWESEN.BUSSFRIST' | translate }}*</label>
                </span>

                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ 'VWBP.INTERN.MAHNWESEN.EINZUGSAUFTRAG_AN_LK_KOMMENTAR' | translate }}*</label>
                </span>
            </div>
            <div *ngIf="Dialog && HasDoku" class="col-12 lg:col-12">
                <p-checkbox [(ngModel)]="DocuGenerieren" label="{{ 'VWBP.INTERN.DOKUMENTE.GENERIEREN' | translate }}" name="doc" [binary]="true"></p-checkbox>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-credit-card" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-warning"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternMahnwesenEinzugsauftragTaskComponent extends VwbPInternTaskBaseComponent {
    constructor(public postfachService: VwbPInternPostfachService, public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public mahnwesenService: VwbPInternMahnwesenService, public inputMaskService: InputmaskService, public dialogService: DialogService, public cdr: ChangeDetectorRef, public jobService: AviJobService) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);
    }

    ngOnInit() {
        this.InitDoku(VwbPListDetailConst.VWBP_VORLAGE_TASK_MAHNWESEN_EINZUGSAUFTRAG);
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + this.commonService.translateInstant('VWBP.INTERN.MAHNWESEN.EINZUGSAUFTRAG_AN_LK_BUTTON');
    }

    CanExecute(): boolean {
        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung && !!this.Datum;
    }

    async Execute() {
        this.PerformAction('VWBP.INTERN.MAHNWESEN.EINZUGSAUFTRAG_AN_LK_BUTTON', this.GetResourceName('VWBP.INTERN.MAHNWESEN.EINZUGSAUFTRAG_AN_LK_BESTAETIGEN'), 'VWBP.INTERN.MAHNWESEN.EINZUGSAUFTRAG_AN_LK_BUTTON',
            async (selectedRT) => {
                const jobId: string = await this.mahnwesenService.Einzugsauftrag(selectedRT.map(w => w.BusseId), this.Datum, this.DocuGenerieren, this.Bemerkung);
                if (jobId) {
                    const jobStatus = await this.jobService.OpenJobDialog(jobId, 'VWBP.INTERN.MAHNWESEN.EINZUGSAUFTRAG_AN_LK_BUTTON', 'v1/intern/vwbp/job', false);
                    if (jobStatus !== AviListDetailConst.JOB_STATUS_SUCCEEDED)
                        return;
                }
                if (this.HasDoku && this.DocuGenerieren)
                    this.postfachService.OpenPostfach();
            }
        );
    }
}
