<!-- <avi-core-page  [title]="SearcherTitle" [card]="Card" [collapsible]="Collapsible" [expanded]="Expanded" [fill-height]="FillHeight"> -->

<div class="p-3"
    style="height:100%;"
    fxLayout="column"
    fxLayoutAlign="space-between stretch">
    <div fxFlex="none">
        <h3 class="m-0 mb-20"
            *ngIf="ShowTitle && Model && Model.Bezeichnung1">{{Model.Bezeichnung1}}</h3>

        <avi-core-base-form #parameters
            [direct-binding]="true"
            [card]="false"
            [contents-padding]="false"
            [show-buttons]="false"
            [direct-binding]="true"
            [auto-btn-datepair]="true"
            [fields]="SearcherParameters"
            (onAttrChange)="onParameterValueChange($event)"
         >
        </avi-core-base-form>
    </div>


    <!-- <div [ngClass]="{'ui-g': true, 'fluid wrapper': true, 'fill-height': FillHeight }">
        <div [ngClass]="{'ui-g-12': true, 'ui-lg-12': true, 'fill-height': FillHeight }">
            <div [ngClass]="{'ui-g-12': true, 'ui-md-12': true, 'fill-height': FillHeight }" > -->

    <div fxFlex
        fxFill>

        <div *ngIf="Data"
            [ngSwitch]="Type"
            [ngStyle]="{ 'min-height': graphMinHeight}"
            style="height:98%">

            <ngx-charts-bar-vertical-2d *ngSwitchCase="ChartType.BAR"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="ShowLegend"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [barPadding]="0"
                [groupPadding]="4"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-bar-vertical-2d>

            <ngx-charts-bar-horizontal-2d *ngSwitchCase="ChartType.HORIZONTALBAR"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="ShowLegend"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [barPadding]="0"
                [groupPadding]="4"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-bar-horizontal-2d>

            <ngx-charts-bar-vertical-stacked *ngSwitchCase="ChartType.BARSTACKED"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="ShowLegend"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [barPadding]="8"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-bar-vertical-stacked>

            <ngx-charts-bar-horizontal-stacked *ngSwitchCase="ChartType.HORIZONTALBARSTACKED"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="ShowLegend"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [barPadding]="8"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-bar-horizontal-stacked>

            <ngx-charts-bar-vertical-normalized *ngSwitchCase="ChartType.BARSTACKED_NORM"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="ShowLegend"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [barPadding]="8"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-bar-vertical-normalized>

            <ngx-charts-bar-horizontal-normalized *ngSwitchCase="ChartType.HORIZONTALBARSTACKED_NORM"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="ShowLegend"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [barPadding]="8"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-bar-horizontal-normalized>

            <ngx-charts-line-chart *ngSwitchCase="ChartType.LINE"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="ShowLegend"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-line-chart>

            <ngx-charts-area-chart *ngSwitchCase="ChartType.AREA"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [gradient]="false"
                [xAxis]="true"
                [yAxis]="true"
                [legend]="ShowLegend"
                [showXAxisLabel]="false"
                [showYAxisLabel]="false"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-area-chart>

            <ngx-charts-pie-chart *ngSwitchCase="ChartType.PIE"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [legend]="ShowLegend"
                [explodeSlices]="false"
                [labels]="true"
                [doughnut]="false"
                [gradient]="false"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-pie-chart>

            <ngx-charts-pie-chart *ngSwitchCase="ChartType.DOUGHNUT"
                [results]="Data"
                [legendTitle]="'Legende'"
                [scheme]="ColorScheme"
                [legend]="ShowLegend"
                [explodeSlices]="false"
                [labels]="true"
                [doughnut]="true"
                [gradient]="false"
                [animations]="false">

                <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateOutlet; context:{$implicit: model}"></ng-container>
                </ng-template>

            </ngx-charts-pie-chart>

        </div>

        <div class="pt-15 text-xsmall"
            *ngIf="ShowTable">
            <avi-core-base-searcher #searcher
                [search-delegate]=searchDelegateTable
                [search-fields]="['bezeichnung1']"
                [rows]="10"
                [toolbar]="false"
                [auto-search]="false"
                [best-fit]="true"
                [show-autofilter]="false"
                [show-totalrecords]="false"
                [get-table-style]="getTableStyle"
                [card]="false"
                [searcher-title]=null></avi-core-base-searcher>
        </div>
    </div>
</div>


<ng-template #tooltipTemplateOutlet let-model>
    <div class="tooltip-content">
        <div class="tooltip-label">
          <span *ngIf="model.series">{{ model.series }} • </span>
          <span *ngIf="model.name">{{ model.name }}</span>
        </div>
        <div class="tooltip-val">{{ model.tooltip }}</div>
      </div>
</ng-template>

<!--
            </div>
        </div>
    </div> -->

<!-- </avi-core-page> -->
