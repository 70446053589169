import { SteuernummerDef } from '@avi-x/avi-dto/partner/steuernummerdef.model';
import { PartnerIdentifikationsArtDef } from '@avi-x/avi-dto/partner/partneridentifikationsartdef.model';
import { AviPartnerListDetailConst, AviListDetailConst, AviPartnerCountryConst } from '@avi-x/avi-core';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviCommonService, AviAuthService, AviBaseFormComponent, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { PartnerIdentifikation } from '@avi-x/avi-dto/partner/partneridentifikation.model';
import { ListDetail } from '@avi-x/avi-dto/system/listdetail.model';

const nameof = (nameFunction: ((obj: PartnerIdentifikation) => any)) => typed_nameof<PartnerIdentifikation>(nameFunction);

@Component({
    selector: 'avi-crm-partner-identifikation-form',
    template: `

    <avi-core-page *ngIf="currentStep"
        [title]="FormTitle"
        [collapsible]="false"
        [card]="true"
        [contents-padding]="false"
    >
        <p-steps [model]="steps" [activeIndex]="currentStep-1" [readonly]="true">
        </p-steps>
    </avi-core-page>

    <!-- Erfassen -->
    <div *ngIf="currentStep == 1">
        <div class="card-contents">
            <div class="pt-3p">
                <div *ngFor="let art of Identifikationsarte" class="p-field-checkbox pl-12p">
                    <p-radioButton [inputId]="art.Id" name="art" [value]="art" [(ngModel)]="selectedIdentifikationsart"></p-radioButton>
                    <label [for]="art.Id">{{art.Bezeichnung1}}</label>
                </div>

                <div class="ui-g-12 ui-md-12 pt-3p">
                    <p-button [disabled]="!selectedIdentifikationsart" class="p-button-primary mr-2" label="Next" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right"></p-button>
                    <avi-core-actionbuttons [action-buttons]="actionButtons"></avi-core-actionbuttons>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="currentStep == 2">
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
    </div>

    <!-- Readonly / Mutieren -->
    <div *ngIf="ReadOnly || isEditMode" >
        <ng-container *ngTemplateOutlet="formTemplate"></ng-container>
    </div>

    <ng-template #formTemplate>
        <avi-core-base-form #form
                [readonly]="ReadOnly"
                [card]="Card && !currentStep"
                [fields]="Fields"
                [form-title]="FormTitle"
                [label-alignment]="ReadOnly ? 'left' : 'top'"
                [label-width]="'160px'"
                [(Model)]="Model"
                [loading]="Loading"
                (onDelete)="deleteModel($event)"
                (onSave)="saveModel($event)"
                (onAttrChange)="handleAttrChange($event)"
                [action-buttons]="actionButtons">
        </avi-core-base-form>
    </ng-template>

 `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviPartnerIdentifikationFormComponent implements OnInit, OnDestroy {
    selectedIdentifikationsart: ListDetail = null;
    Identifikationsarte: ListDetail[] = null;

    currentStep: number = null;

    steps = [
        {
            label: 'Identifikationsart',
            command: (event: any) => { this.currentStep = 1; }
        },
        {
            label: 'Identifikation',
            command: (event: any) => { this.currentStep = 2; }
        }
    ];

    private _className = 'PartnerIdentifikation';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = '/crm/partneridentifikation';

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = '/crm/partneridentifikation';

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: PartnerIdentifikation = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string { return this._ModelId; }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Input('partner-id')
    partnerId: string = null;

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    IdentifikationsArtDef: PartnerIdentifikationsArtDef = null;

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {
        this.currentStep = null;

        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));
        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    public async initNewModel(data?: any) {
        this.Model = await this.apiService.getModel(PartnerIdentifikation, 'partneridentifikation/create');
        this.Model.vonPartner_ID = this.partnerId;
        this.isEditMode = false;

        if (this.Model.vonPartner_ID)
            this.Identifikationsarte = await this.apiService.get(`partneridentifikation/GetIdentifikationsArte/${this.Model.vonPartner_ID}`);

        this.currentStep = 1;
    }

    nextPage() {
        this.Model.IdentifikationsArt.Id = this.selectedIdentifikationsart.Id;
        this.Model.IdentifikationsArt.Bezeichnung = this.selectedIdentifikationsart.Bezeichnung1;

        this.init().then(w => {
            this.currentStep = this.currentStep + 1;
            this.cdr.markForCheck();
        });
    }

    ngOnInit() {
        // this.sub = this.activatedRoute.params.subscribe(params => {
        //     if (params && params['id']) {
        //         this.ModelId = params['id'];
        //     }
        // });

        this.initFields();
    }

    deleteModel(model: any) {
        this.apiService.delete(`/partneridentifikation/${model.id}`).then(r => {
            if (this.RedirectAfterDelete)
                this.router.navigate([this.RedirectAfterDelete]);
        });
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: PartnerIdentifikation) {
        this.Form.clearFormMessages();

        if (!model.Folgenummer) model.Folgenummer = 0;

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            delegate = this.apiService.put(`/partneridentifikation/${model.Id}`, model);
        } else {
            delegate = this.apiService.post('/partneridentifikation', model);
        }
        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.RedirectAfterSave)
                this.router.navigate([this.RedirectAfterSave]);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {
        this.commonService.showGlobalLoader();

        this.Model = await this.apiService.FindModel(PartnerIdentifikation, `partneridentifikation/${id}`, null, null, null);
        await this.init();

        this.isEditMode = true;

        return this.Model;
    }

    private async init() {
        if (this.Model.SteuernummerDef_ID)
            this.Model.SteuernummerDef = await this.apiService.FindModel(SteuernummerDef, `steuernummerdef/${this.Model.SteuernummerDef_ID}`, null, null, null);

        if (this.IdentifikationsArtDef == null || this.IdentifikationsArtDef.IdentifikationsArt.Id !== this.Model.IdentifikationsArt.Id)
            this.IdentifikationsArtDef = await this.apiService.getModel(PartnerIdentifikationsArtDef, `partneridentifikationsartdef/getforidentifikationsart/${this.Model.IdentifikationsArt.Id}`);

        this.setFromIdentifikationsArtInternal();

        this.updateDropdownSources();

        this.commonService.hideGlobalLoader();
        this.Form?.clearFormMessages();

        this._ModelId = this.Model.Id;

        if (!this.ReadOnly && this.Model.vonLand_ID && this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER)
            await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.SteuernummerDef_ID)));

        if (!this.ReadOnly && this.Model.vonPartner_ID && this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_FATCA)
            await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.DetailType_ID)));

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Folgenummer), this.ReadOnly);
        if (!this.ReadOnly)
            this.formFieldService.setFieldSize(this.Fields, nameof(c => c.IdentifikationsArt), 12);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.IdentifikationsArt), !this.ReadOnly);

        setTimeout(() => this.Form.focusFirstEnabledField());
    }

    async updateDropdownSources() {
        if (this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.IdentifikationsArt), 'Identifikationsart', 'caf_identifikationsart', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Folgenummer), 'Folgenummer', 0, false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonLand_ID), 'Land', false, 'Representation', 'Id', 0, `model/representations/land`).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonLand2_ID), 'Land 2', false, 'Representation', 'Id', 0, `model/representations/land`).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonLand3_ID), 'Land 3', false, 'Representation', 'Id', 0, `model/representations/land`).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonLand4_ID), 'Land 4', false, 'Representation', 'Id', 0, `model/representations/land`).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.SteuernummerDef_ID), 'Steuernummer-Typ',
            async (field) => {
                const ret = [];
                if (!this.Model || !this.Model.vonLand_ID)
                    return ret;

                if (this.ReadOnly) {
                    if (this.Model.SteuernummerDef_ID) {
                        const elem = await this.apiService.get(`model/representations/CAFSteuernummerDef?id=${this.Model.SteuernummerDef_ID}`);
                        if (elem && elem.length > 0)
                            ret.push({ label: elem[0].Representation, value: elem[0].Id });
                    }
                    return ret;
                }

                const p = await this.apiService.get(`partnerIdentifikation/GetSteuernummerDefs/${this.Model.vonLand_ID}`);
                p.forEach(elem => {
                    ret.push({ label: elem.Representation, value: elem.Id });
                });

                return ret.sort((a, b) => a.label > b.label ? 1 : -1);
            }
            , false, false, 'Steuernummer-Typ wählen...', null, null, true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.SponsoredGIINIdent_ID), 'SponsoredGIINIdent', `model/representations/CAFPartnerIdentifikation${this.ReadOnly ? '?id=$id' : ''}`, false, false, 'SponsoredGIINIdent wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'Gültig ab', false).setNullDateLow().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'Gültig bis', false).setNullDateHigh().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DetailType_ID), 'Formular Typ',
            async (field) => {
                const ret = [];
                if (!this.Model || !this.Model.vonPartner_ID || this.Model.IdentifikationsArt.Id !== AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_FATCA)
                    return ret;

                const p = await this.apiService.get(`partnerIdentifikation/GetDetailTypes/${this.Model.vonPartner_ID}`);
                p.forEach(elem => {
                    ret.push({ label: elem.Representation, value: elem.Id });
                });

                return ret.sort((a, b) => a.label > b.label ? 1 : -1);
            }, false, false, 'Typ wählen...', null, null, true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.IdentifikationsNr), 'Identifikations-Nr.', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Status_ID), 'Status', `model/representations/CAFModelState${this.ReadOnly ? '?id=$id' : ''}`, false, false, 'Status wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Ort), 'Ort', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Bemerkung), 'Bemerkung', false).setMDSizeFull());

        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.DocId), 'DocId', false).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.DocArchiv), 'DocArchiv', false).setMDSizeHalf());
    }


    private setFromIdentifikationsArtInternal() {
        const isUndefinedStnr = this.isUndefinedSteuernummer();
        const bemerkungVisible = this.getBemerkungVisible();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Bemerkung), bemerkungVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Bemerkung), isUndefinedStnr);

        const steuernummerDefVisible = this.getSteuernummerDefVisible();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SteuernummerDef_ID), steuernummerDefVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.SteuernummerDef_ID), steuernummerDefVisible);

        if (this.Model.IdentifikationsArt.Id !== AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER && this.Model.IdentifikationsArt.Id !== AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER_SPONSOR)
            this.Model.SteuernummerDef_ID = null;

        const identifikationsNrVisible = this.getIdentifikationsNrVisible();
        const identifikationsNrPflicht = this.getIdentifikationsNrPflicht();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.IdentifikationsNr), identifikationsNrVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.IdentifikationsNr), identifikationsNrPflicht);

        const ortVisible = this.getOrtVisible();
        const ortPflicht = this.getOrtPflicht();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Ort), ortVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Ort), ortPflicht);

        const landVisible = this.getLandVisible();
        const landPflicht = this.getLandPflicht();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.vonLand_ID), landVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.vonLand_ID), landPflicht);

        const gueltigAbVisible = this.getGueltigAbVisible();
        const gueltigAbPflicht = this.getGueltigAbPflicht();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.GueltigAb), gueltigAbVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.GueltigAb), gueltigAbPflicht);
        this.formFieldService.setFieldNoHighLowDate(this.Fields, nameof(c => c.GueltigAb), gueltigAbPflicht);

        const gueltigBisVisible = this.getGueltigBisVisible();
        const gueltigBisPflicht = this.getGueltigBisPflicht();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.GueltigBis), gueltigBisVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.GueltigBis), gueltigBisPflicht);
        this.formFieldService.setFieldNoHighLowDate(this.Fields, nameof(c => c.GueltigBis), gueltigBisPflicht);

        if (this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER) {
            if (this.Model.SponsoredGIINIdent_ID == null) {
                this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.IdentifikationsNr), !isUndefinedStnr);
                this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.IdentifikationsNr), false);
                this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.SponsoredGIINIdent_ID), !(!this.Model.IdentifikationsNr));
            } else {
                this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.IdentifikationsNr), false);
                this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.IdentifikationsNr), true);
            }
        }

        const showExtraCountries = this.getShowExtraCountries();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.vonLand2_ID), (showExtraCountries && !this.ReadOnly) || this.Model.vonLand2_ID != null);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.vonLand3_ID), (showExtraCountries && !this.ReadOnly) || this.Model.vonLand3_ID != null);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.vonLand4_ID), (showExtraCountries && !this.ReadOnly) || this.Model.vonLand4_ID != null);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SponsoredGIINIdent_ID), this.getShowSponsoredIdent());

        if (this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_NATIONALITAET) {
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.vonLand2_ID), this.Model.vonLand_ID === AviPartnerCountryConst.COUNTRY_STAATENLOS);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.vonLand3_ID), this.Model.vonLand_ID === AviPartnerCountryConst.COUNTRY_STAATENLOS);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.vonLand4_ID), this.Model.vonLand_ID === AviPartnerCountryConst.COUNTRY_STAATENLOS);
        } else if (this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_SELBSTAUSKUNFT) {
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.vonLand2_ID), this.Model.vonLand_ID === AviPartnerCountryConst.COUNTRY_KEINSTEUERDOMIZIL);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.vonLand3_ID), this.Model.vonLand_ID === AviPartnerCountryConst.COUNTRY_KEINSTEUERDOMIZIL);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.vonLand4_ID), this.Model.vonLand_ID === AviPartnerCountryConst.COUNTRY_KEINSTEUERDOMIZIL);
        }

        const stateVisible = this.getStateVisible();
        const statePflicht = this.getStatePflicht();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Status_ID), stateVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Status_ID), statePflicht);

        if (!this.isEditMode && stateVisible && this.IdentifikationsArtDef.DefaultStatus_ID != null) {
            this.Model.Status_ID = this.IdentifikationsArtDef.DefaultStatus_ID;
        }

        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.vonLand_ID), this.getLandLabel());
        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.vonLand2_ID), this.getLandLabel() + ' 2');
        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.vonLand3_ID), this.getLandLabel() + ' 3');
        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.vonLand4_ID), this.getLandLabel() + ' 4');

        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.Ort), this.getOrtLabel());
        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.GueltigAb), this.getGueltigAbLabel());
        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.GueltigBis), this.getGueltigBisLabel());
        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.IdentifikationsNr), this.getIdentifikationsNrLabel());
        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.Status_ID), this.getStatusLabel());

        const isFatca = this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_FATCA;
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DetailType_ID), isFatca);
        this.formFieldService.setFieldLabel(this.Fields, nameof(c => c.DetailType_ID), isFatca ? 'Formular Typ' : 'Detail Typ');
    }

    async handleAttrChange(data: any) {
        if (data.field === nameof(c => c.vonLand_ID)) {
            if (this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER)
                await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.SteuernummerDef_ID)));
        }

        if (data.field === nameof(c => c.vonPartner_ID)) {
            if (this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_FATCA)
                await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.DetailType_ID)));
        }
    }

    public isUndefinedSteuernummer(): boolean {
        return this.Model &&
            (this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER && this.Model.SteuernummerDef_ID != null && this.Model.SteuernummerDef.IstUnbekannt.Id === AviListDetailConst.JA);
    }

    public getBemerkungVisible(): boolean {
        if (this.Model && this.isUndefinedSteuernummer())
            return true;

        return !(this.IdentifikationsArtDef?.BemerkungAnzeigen.Id === AviListDetailConst.NEIN);
    }

    public getBemerkungZwingend(): boolean {
        if (this.isUndefinedSteuernummer())
            return true;

        return !(this.IdentifikationsArtDef?.BemerkungPflicht.Id === AviListDetailConst.NEIN);
    }

    public getSteuernummerDefVisible(): boolean {
        return this.Model &&
            (this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER ||
                this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER_SPONSOR);
    }


    public getShowSponsoredIdent(): boolean {
        return this.Model &&
            (this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER
                && this.Model.SteuernummerDef_ID != null && this.Model.SteuernummerDef.SponsorPossible.Id === AviListDetailConst.JA);
    }

    public getStateVisible(): boolean {
        return (this.IdentifikationsArtDef?.StateGroup_ID != null);
    }

    public getStatePflicht(): boolean {
        return this.IdentifikationsArtDef?.StatePflicht.Id === AviListDetailConst.JA;
    }

    public getOrtVisible(): boolean {
        return !(this.IdentifikationsArtDef?.OrtAnzeigen.Id === AviListDetailConst.NEIN);
    }

    public getOrtPflicht(): boolean {
        return this.IdentifikationsArtDef?.OrtPflicht.Id === AviListDetailConst.JA;
    }

    public getLandVisible(): boolean {
        return !(this.IdentifikationsArtDef?.LandAnzeigen.Id === AviListDetailConst.NEIN);
    }

    public getLandPflicht(): boolean {
        return this.IdentifikationsArtDef?.LandPflicht.Id === AviListDetailConst.JA;
    }

    public getGueltigAbVisible(): boolean {
        return !(this.IdentifikationsArtDef?.GueltigAbAnzeigen.Id === AviListDetailConst.NEIN);
    }

    public getGueltigAbPflicht(): boolean {
        return this.IdentifikationsArtDef?.GueltigAbPflicht.Id === AviListDetailConst.JA;
    }

    public getGueltigBisVisible(): boolean {
        return !(this.IdentifikationsArtDef?.GueltigBisAnzeigen.Id === AviListDetailConst.NEIN);
    }

    public getGueltigBisPflicht(): boolean {
        return this.IdentifikationsArtDef?.GueltigBisPflicht.Id === AviListDetailConst.JA;
    }

    public getIdentifikationsNrVisible(): boolean {
        return !(this.IdentifikationsArtDef?.IdentifikationsNrAnzeigen.Id === AviListDetailConst.NEIN);
    }

    public getIdentifikationsNrPflicht(): boolean {
        return this.IdentifikationsArtDef?.IdentifikationsNrPflicht.Id === AviListDetailConst.JA;
    }

    public getOrtLabel(): string {
        return this.IdentifikationsArtDef?.OrtLabel1 ?? 'Ort';
    }

    public getLandLabel(): string {
        return this.IdentifikationsArtDef?.LandLabel1 ?? 'Land';
    }

    public getGueltigAbLabel(): string {
        return this.IdentifikationsArtDef?.GueltigAbLabel1 ?? 'Gültig ab';
    }

    public getGueltigBisLabel(): string {
        return this.IdentifikationsArtDef?.GueltigBisLabel1 ?? 'Gültig bis';
    }

    public getIdentifikationsNrLabel(): string {
        return this.IdentifikationsArtDef?.IdentifikationsNrLabel1 ?? 'Identifikationsnummer';
    }

    public getStatusLabel(): string {
        return this.IdentifikationsArtDef?.StatusLabel1 ?? 'Status';
    }

    public getShowExtraCountries(): boolean {
        return this.Model &&
            this.Model.IdentifikationsArt.Id === AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_SELBSTAUSKUNFT &&
            (this.Model.vonLand2_ID != null || this.Model.vonLand3_ID != null || this.Model.vonLand4_ID != null);
    }

    public getShowIdentifikationsNr(): boolean {
        return this.Model == null || ((this.Model.IdentifikationsArt.Id !== AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_STEUERNUMMER ||
            this.Model.SteuernummerDef_ID == null || this.Model.SteuernummerDef.IstUnbekannt.Id === AviListDetailConst.NEIN)
            && (this.Model.IdentifikationsArt.Id !== AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_NATIONALITAET)
            && (this.Model.IdentifikationsArt.Id !== AviPartnerListDetailConst.CAF_IDENTIFIKATIONSART_SELBSTAUSKUNFT));
    }
}
