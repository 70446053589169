import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DecisionTable } from '@avi-x/avi-dto/system/decisiontable.model';

const nameof = (nameFunction: ((obj: DecisionTable) => any)) => typed_nameof<DecisionTable>(nameFunction);

@Component({
    selector: 'avi-decisiontable-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDecisionTableFormComponent extends AviAbstractModelFormComponent<DecisionTable> {  
    ClassName = 'CORE.COMMON.MODEL.CAFDECISIONTABLE';
    BaseUrl = 'decisiontable';
    ClassType = DecisionTable;
	FormType = AviDecisionTableFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFDecisionTable';
    }

    public async afterInitForm() {
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.vonDecisionTableDef_ID), this.isEditMode);
    }
	
    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sequenz), 'CORE.COMMON.MODEL.CAFDECISIONTABLE.SEQUENZ', 0, true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonDecisionTableDef_ID), 'CORE.COMMON.MODEL.CAFDECISIONTABLE.VONDECISIONTABLEDEF', `model/representations/CAFDecisionTableDef${this.ReadOnly ? '?id=$id' : ''}`, true, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.CAFDECISIONTABLE.KURZBEZ1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.CAFDECISIONTABLE.BEZEICHNUNG1', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.KURZBEZ2', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.BEZEICHNUNG2', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.KURZBEZ3', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.BEZEICHNUNG3', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.KURZBEZ4', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.BEZEICHNUNG4', true).setMDSizeHalf());
        
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez5), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.KURZBEZ5', true).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung5), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.BEZEICHNUNG5', true).setMDSizeHalf());
        
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez6), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.KURZBEZ6', true).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung6), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.BEZEICHNUNG6', true).setMDSizeHalf());
        
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez7), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.KURZBEZ7', true).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung7), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.BEZEICHNUNG7', true).setMDSizeHalf());

        console.log("initFields on DecTabel")
        
    }

    override async  onAttrChange(data: AviChangedAttr) {
        if(data.field === nameof(c => c.KurzBez1))
        {
            var value: string = data.value;
            this.Model.KurzBez2 = value;
            this.Model.KurzBez3 = value;
            this.Model.KurzBez4 = value;
            this.Model.KurzBez5 = value;
            this.Model.KurzBez6 = value;
            this.Model.KurzBez7 = value;
        }

        if(data.field === nameof(c => c.Bezeichnung1))
        {
            var value: string = data.value;
            this.Model.Bezeichnung2 = value;
            this.Model.Bezeichnung3 = value;
            this.Model.Bezeichnung4 = value;
            this.Model.Bezeichnung5 = value;
            this.Model.Bezeichnung6 = value;
            this.Model.Bezeichnung7 = value;
        }
        
        super.onAttrChange(data);
    }
}
