import { Component } from '@angular/core';

@Component({
    selector: 'vwbp-intern-kontrollwesen-bemerkungen',
    template: `
         <vwbp-intern-rechtstraeger-comments-searcher [use-router-params]="true" [include-keywords]="true" settingskey="settings.searcher.kw.bemerkungen" [show-deleted-filter]="true" [show-comment-detailview]="true" [get-filters]="GetFilters" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'Erfassungsstatus', 'Version', 'Freigabedatum', 'Keywords', 'HrLoeschungsdatum', 'ValidierungAusnahme', 'BemerkungTeilfonds', 'btnOpen']">
         </vwbp-intern-rechtstraeger-comments-searcher>
        `
})
export class VwbPInternKontrollwesenBemerkungenComponent {
    constructor() { }

    GetFilters(): string[] {
        return ['BemerkungCount gt 0'];
    }
}
