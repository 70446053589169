<avi-core-base-form #form
	[fields]="Fields"
	form-title="Land bearbeiten / hinzufügen"
	[(ngModel)]="Model"
	[loading]="Loading"
	(onDelete)="deleteModel($event)"
	(onSave)="saveModel($event)"
	[action-buttons]="actionButtons"
></avi-core-base-form>

<avi-core-dbg [m]="Model" t="Model aus LandFormComponent"></avi-core-dbg>
