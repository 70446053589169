import { Injectable } from '@angular/core';
import { AviCommonService } from './common.service';
import { AviChangePasswordFormComponent } from '../components/password/changepassword-form.component';
import { AviApiService } from './api.service';

@Injectable()
export class AviPasswordService {
    constructor(private commonService: AviCommonService, private apiService: AviApiService) { }
    
    public async OpenChangePasswordDialog(apiPath: string, oldPasswordVisible: boolean = true, oldPasswordRequired: boolean = true, newPasswordRequired: boolean = true) {
        const res = await this.commonService.openFormDialog(AviChangePasswordFormComponent, 'Passwort ändern', '0', null, { oldPasswordVisible: oldPasswordVisible, oldPasswordRequired: oldPasswordRequired, newPasswordRequired: newPasswordRequired }, false);
        if (res) {
            await this.apiService.post(apiPath, res);
        }
    }

}