import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

import { AviApiService } from '@avi-x/avi-core';

export interface AviAbsenzInfoRecordsDto {
    Absenzen: AviAbsenzInfoDto[];
    KalenderDetails: AviAbsenzInfoDto[];
}

export class AviAbsenzInfoDto {
    public Id: string;
    public Text: string;
    public EventStart: string;
    public EventEnd: string;

    public IsAllDay: boolean;
    public MitarbeiterId: string;

    public Typ: string;
    public TypId?: string;

    public Minutes: number;
    public MinutesToWork: number;

    public Status: string;
    public StatusId?: string;
}

export class AviEventStyle {
    public Typ: string;
    public BackgroundColor: string;
    public TextColor?: string;

    public constructor(bg: string, text?: string) {
      this.BackgroundColor = bg;
      this.TextColor = text;
    }
}

@Injectable()
export class AviPartnerService {
    public TypColor: AviEventStyle[] = [
        { Typ: 'FT', BackgroundColor: '#61b500', TextColor: '#FFF' },
        { Typ: 'FER', BackgroundColor: '#0692bd', TextColor: '#FFF' },
        { Typ: 'FER_PEND', BackgroundColor: '#9506bd', TextColor: '#FFF' },
        { Typ: 'FER_DENIED', BackgroundColor: '#FF0000', TextColor: '#FFF' },
        { Typ: 'KOMP', BackgroundColor: '#9dca00', TextColor: '#FFF' },
        { Typ: 'KRANK', BackgroundColor: '#FF8C00', TextColor: '#FFF' },
        { Typ: 'kAT', BackgroundColor: '#81862f', TextColor: '#FFF' },
        { Typ: 'AbwStift', BackgroundColor: '#81862f', TextColor: '#FFF' },

        { Typ: '_DEFAULT', BackgroundColor: '#000', TextColor: '#FFF' },
      ];

    constructor(public apiService: AviApiService) { }

    public async Find(q: string = null): Promise<any[]> {
        let url = 'basepartner';
        if (q) {
            url += '?filter=name contains ' + q;
        }
        return this.apiService.get(url, [this.apiService.buildHeader('q', q)]);
    }

    public async GetOne(id: string): Promise<any> {
        return this.apiService.get(`basepartner/${id}`);
    }

    public async Save(data: any): Promise<any> {
        return this.apiService.putPost('basepartner', data);
    }

    public async Delete(id: string): Promise<any> {
        return this.apiService.delete(`basepartner/${id}`);
    }

    public async GetAktiveMitarbeiterInfos(): Promise<any[]> {
        return this.apiService.get('mitarbeiterdetail?filter=(zustandAb le today) and (zustandBis ge today)&init=vonMitarbeiter.partnerRolle.vonPartner,kalender&select=id,vonMitarbeiter_ID,vonMitarbeiter.partnerRolle.vonPartner.Name,vonMitarbeiter.partnerRolle.vonPartner.ZusatzName,Kuerzel,beschaeftigungsgrad,kalender');
    }

    public async GetLatestAnwesenheitMandate(mitarbeiterId: string, daysBack: number = 365): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const m = moment().subtract(daysBack, 'days');
                const filter = `(mitarbeiter_ID eq ${mitarbeiterId}) and (datum ge ${m.format('YYYY-MM-DD')}) and (vonMandat.GueltigBis ge today) and (vonMandat.GueltigAb le today) and vonMandat.aktiv eq aktiv()`;
                const select = 'id, vonMandat_ID, vonProjekt_ID, vonProjekt.Titel, vonMandat.MandatsNr, vonMandat.Bezeichnung1, vonMandat.vonGBereich.KurzBez1, vonMandat.vonMandatArt.KurzBez1, vonLstKatalogPreis_ID, vonLstKatalogPreis.vonLeistungsArt.KurzBez1';
                const data = await this.apiService.get(`anwesenheit?filter=${filter}&select=${select}&sort=-datum`);

                const ret = [];

                data.forEach(element => {
                    if (!element.vonMandat_ID && !element.vonMandat_ID)
                        return;

                    const _key = `${element.vonMandat_ID || ''}${element.vonLstKatalogPreis_ID || ''}${element.vonProjekt_ID || ''}`;

                    const existingItem = ret.find(i => i._key === _key );
                    if (existingItem)
                        existingItem.cnt ++;
                    else
                        ret.push({
                            _key: _key,
                            vonMandat_ID: element.vonMandat_ID,
                            vonMandat: element.vonMandatvonGBereichKurzBez1 + '-' + element.vonMandatMandatsNr + '-' + element.vonMandatvonMandatArtKurzBez1 + ' ' + element.vonMandatBezeichnung1,
                            vonLstKatalogPreis_ID: element.vonLstKatalogPreis_ID,
                            vonLeistungsArt: element.vonLstKatalogPreisvonLeistungsArtKurzBez1,
                            vonProjekt_ID: element.vonProjekt_ID,
                            vonProjektTitel: element.vonProjektTitel,
                            cnt: 1
                        });
                });

                resolve(ret.sort((a, b) => a.cnt < b.cnt ? 1 : -1));
            } catch (err) {
                reject(err);
            }
        });
    }

    public async GetLatestLeistungMandate(mitarbeiterId: string, daysBack: number = 365): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const m = moment().subtract(daysBack, 'days');
                const filter = `(mitarbeiter_ID eq ${mitarbeiterId}) and (valuta ge ${m.format('YYYY-MM-DD')}) and (vonMandat.GueltigBis ge today) and (vonMandat.GueltigAb le today) and vonMandat.aktiv eq aktiv()`;
                const select = 'id, vonMandat_ID, vonProjekt_ID, vonProjekt.Titel, vonMandat.MandatsNr, vonMandat.Bezeichnung1, vonMandat.vonGBereich.KurzBez1, vonMandat.vonMandatArt.KurzBez1, vonLstKatPreis_ID, vonLstKatPreis.vonLeistungsArt.KurzBez1';
                const data = await this.apiService.get(`leistung?filter=${filter}&select=${select}&sort=-valuta`);

                const ret = [];

                data.forEach(element => {
                    if (!element.vonMandat_ID && !element.vonMandat_ID)
                        return;

                    const _key = `${element.vonMandat_ID || ''}${element.vonLstKatPreis_ID || ''}${element.vonProjekt_ID || ''}`;

                    const existingItem = ret.find(i => i._key === _key );
                    if (existingItem)
                        existingItem.cnt ++;
                    else
                        ret.push({
                            _key: _key,
                            vonMandat_ID: element.vonMandat_ID,
                            vonMandat: element.vonMandatvonGBereichKurzBez1 + '-' + element.vonMandatMandatsNr + '-' + element.vonMandatvonMandatArtKurzBez1 + ' ' + element.vonMandatBezeichnung1,
                            vonLstKatalogPreis_ID: element.vonLstKatPreis_ID,
                            vonLeistungsArt: element.vonLstKatPreisvonLeistungsArtKurzBez1,
                            vonProjekt_ID: element.vonProjekt_ID,
                            vonProjektTitel: element.vonProjektTitel,
                            cnt: 1
                        });
                });

                resolve(ret.sort((a, b) => a.cnt < b.cnt ? 1 : -1));
            } catch (err) {
                reject(err);
            }
        });
    }

    // public async getAbsenzenForMitarbeiter(mitarbeiterId, year) {
    //     if (!mitarbeiterId)
    //         throw new Error("Keine Mitarbeiter-ID");

    //     let qs = `absenz?filter=Mitarbeiter_ID eq ${mitarbeiterId} and datum ge ${year}-01-01 and datum le ${year}-12-31&init=absenzArt`;
    //     let absenzen = await this.apiService.get(qs);
    //     return absenzen;
    // }

    // public async getAbsenzenForAll(year) {
    //     let qs = `absenz?filter=datum ge ${year}-01-01 and datum le ${year}-12-31&init=absenzArt`;
    //     let absenzen = await this.apiService.get(qs);
    //     return absenzen;
    // }


    public async getAbsenzen(year: number, mitarbeiterId?: string): Promise<AviAbsenzInfoRecordsDto> {
        let url = `personal/absenzen/${year}`;
        if (mitarbeiterId)
            url += `/${mitarbeiterId}`;
        return await this.apiService.get(url);
    }

    public async deleteAbsenz(id: string, ganzeSerie: boolean) {
        const url = `personal/absenzen/form/custom/${id}/${ganzeSerie ? 'true' : 'false'}`;
        return await this.apiService.delete(url);
    }


    // async getFeiertageForMitarbeiter(mitarbeiterId, year) {
    //     return new Promise<any[]>(async (resolve, reject) => {
    //         try {
    //             if (!mitarbeiterId)
    //                 throw new Error("Keine Mitarbeiter-ID");

    //             let maDetails = await this.apiService.get(`mitarbeiterdetail?filter=vonMitarbeiter_ID eq ${mitarbeiterId} and zustandBeginn le today and zustandEnde ge today`);

    //             let kalDefId = maDetails[0].kalender_ID;
    //             let qs = `CAFCalendarDefDetail?filter=vonCalendarDef_ID eq ${kalDefId} and datum ge ${year}-01-01 and datum le ${year}-12-31 and (TagType eq 5EB709AF-A754-409E-BCFE-66982859E819 or TagType eq 1c9dcb26-3168-4620-9be9-c85d3c6f03d8)`;
    //             let feiertage = await this.apiService.get(qs);

    //             resolve(feiertage);
    //         } catch (err) {
    //             reject(err);
    //         }
    //     });


    // }
}
