<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

    <avi-tabView #tabView *ngIf="ModelId" width="14rem" tabview-class="tabstyle-session" orientation="vertical" [session-modul]="true">

        <avi-tabPanel title="CORE.COMMON.MODEL.JOBDEF.JOBDEF_1" id="jobdef-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-jobdef-form [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true" [enable-delete]="false"></avi-jobdef-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
<!-- 	
        <avi-tabPanel title="CORE.COMMON.MODEL.JOBDEF.ALLEPARAMETER" id="alleparameter-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-jobparamdef-searcher [context-id]="ModelId" [enable-panel]="true"></avi-jobparamdef-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel> -->
        
        <avi-tabPanel title="CORE.COMMON.MODEL.JOBDEF.ALLEJOBS" id="allejobs-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-job-searcher [context-id]="ModelId" [enable-panel]="true"></avi-job-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
    </avi-tabView>

</avi-core-page>

