import { AviPartnerKommunikationFormComponent } from './../partner-kommunikation-form/partner-kommunikation-form.component';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AviApiService, AviBaseSearcherComponent, AviCommonService, AviDialogComponent, AviPartnerListConst } from '@avi-x/avi-core';

@Component({
    selector: 'avi-crm-partner-kommunikation-liste',
    templateUrl: './partner-kommunikation-liste.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviPartnerKommunikationListeComponent implements OnInit, AfterViewInit {
    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    @ViewChild('formDialog', { static: false })
    formDialog: AviDialogComponent;

    @ViewChild('form', { static: false })
    public form: AviPartnerKommunikationFormComponent;

    FormModelId: string = null;
    formActionButtons: any = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.closeForm(); } }];

    public Filters: string[] = null;

    @Input('partner-id')
    public partnerId: string = null;

    public DetailModelId: string = null;

    constructor(private activatedRoute: ActivatedRoute, public apiService: AviApiService, public commonService: AviCommonService) {
    }

    public onInit(data) {
        this.searcher.beginInit();
        // this.searcher.addTextColumn('Id', 'Id').setVisible(false);
        this.searcher.addDateColumn('GueltigAb', 'Gültig ab', null, true);
        this.searcher.addDateColumn('GueltigBis', 'Gültig bis', null, true);

        this.searcher.addListTypeColumn('KommTyp', 'Typ', AviPartnerListConst.CAF_PARTNERKOMM_TYP, true, true, null);

        this.searcher.addListTypeColumn('KommArt', 'Kommunikationsart', AviPartnerListConst.CAF_PARTNERKOMM_ART, true, true, null);

        this.searcher.addTextColumn('Eingabe', 'Telefon/Mail/Chat', true);
        this.searcher.endInit();
    }

    onSelect(data) {
        if (data && data[0])
            this.DetailModelId = data[0].Id;
    }
    ngOnInit() {
    }

    public getFilters: any = (): string[] => {
        let res = [];
        if (this.partnerId != null)
            res = [...res, `vonPartner_ID eq ${this.partnerId}`];

        if (!this.searcher.showInaktive)
            res = [...res, 'GueltigAb le today', 'GueltigBis ge today'];

        return res;
    }

    ngAfterViewInit() {
        // this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
        //     setTimeout(() => {this.partnerId = res; this.searcher.endInit() }, 0);
        // });
    }


    // Form Dialog
    public createObject() {
        this.FormModelId = '0';
        this.formDialog.open();
    }

    public editObject() {
        if (this.searcher.SelectedRows.length > 0) {
            this.FormModelId = this.searcher.SelectedRows[0].Id;
            this.formDialog.open();
        }
    }

    public deleteObject() {
        this.apiService.deleteObject('partnerkommunikation', this.searcher.SelectedRows[0]?.Id, () => this.searcher.forceRefresh());
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.searcher.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.formDialog.close();
    }
}
