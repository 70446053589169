import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'avi-documatrix-editor',
    templateUrl: './documatrix-editor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocMatrixEditorComponent implements OnInit {

    DocuMatrixUrl: string;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    }

    ngOnInit(): void {
        if (this.config && this.config.data) {
            this.DocuMatrixUrl = this.config.data.DocuMatrixUrl;
        }
    }
}
