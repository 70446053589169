import { Calendar } from 'primeng/calendar';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviApiService, AviCommonService, AviJobService, AviListDetailConst, InputmaskService } from '@avi-x/avi-core';
import { VwbPInternSchwaerzenService } from '../../services/schwaerzen.service';
import { VwbPInternTaskBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-tasks/task-base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';

@Component({
    selector: 'vwbp-intern-schwaerzen-antrag-bewilligen-task',
    template: `
      <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
            {{ (this.NeuSetzen ? 'VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BUTTON' : 'VWBP.INTERN.SCHWAERZEN.ANTRAG_BEARBEITET_BUTTON') | translate }}
        </div>
        <div class="grid form-group">
            <div class="col-12">
                <span class="p-float-label mb-4p">
                    <p-calendar #calendar2 id="cal2" appendTo="body" [baseZIndex]="10000" [firstDayOfWeek]="1" (onInput)="onInputEntscheidungAm($event, calendar2)" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="Datum" [selectOtherMonths]="true" dateFormat="dd.mm.yy" [readonlyInput]="false" [showOnFocus]="false" [showIcon]="true" ></p-calendar>
                    <label *ngIf="!NeuSetzen" for="cal2">{{ 'VWBP.INTERN.SCHWAERZEN.BEWILLIGT_ENTSCHEIDUNG_VOM' | translate }}*</label>
                    <label *ngIf="NeuSetzen" for="cal2">{{ 'VWBP.INTERN.SCHWAERZEN.DATUM_ENTSCHEIDUNG_RECHTSMITTELINSTANZ' | translate }}*</label>
                </span>

                <span class="p-float-label mb-4p">
                    <p-calendar #calendar3 id="cal3" appendTo="body" [baseZIndex]="10000" [firstDayOfWeek]="1" (onInput)="onInputDate($event, calendar3)" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="Frist" [selectOtherMonths]="true" dateFormat="dd.mm.yy" [readonlyInput]="false" [showOnFocus]="false" [showIcon]="true"></p-calendar>
                    <label for="cal3">{{ 'VWBP.INTERN.SCHWAERZEN.RECHTSMITTELFRIST' | translate }}*</label>
                </span>
<!--
                <span class="p-float-label mb-4p">
                    <p-calendar #calendar4 id="cal4" appendTo="body" [baseZIndex]="10000" [firstDayOfWeek]="1" (onInput)="onInputDate($event, calendar4)" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="DatumBis" [selectOtherMonths]="true" dateFormat="dd.mm.yy" [readonlyInput]="false" [showOnFocus]="false" [showIcon]="true"></p-calendar>
                    <label for="cal4">{{ 'VWBP.INTERN.SCHWAERZEN.GUELTIGBIS' | translate }}*</label>
                </span>
                <span [hidden]="KannGueltigBisErmitteln" class="ui-message ui-messages-error ui-corner-all nopad-force">Das Datum bis kann nicht automatisch ermittelt werden, da Anträge mit unterschiedlichen Begründungen gewählt wurden</span> -->

                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ 'VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_KOMMENTAR' | translate }}*</label>
                </span>
            </div>
            <div *ngIf="Dialog && HasDoku" class="col-12 lg:col-12">
                <p-checkbox [(ngModel)]="DocuGenerieren" label="{{ 'VWBP.INTERN.DOKUMENTE.GENERIEREN' | translate }}" name="doc" [binary]="true"></p-checkbox>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-thumbs-up" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-primary"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternSchwaerzenAntragBewilligenTaskComponent extends VwbPInternTaskBaseComponent {
    // KannGueltigBisErmitteln: boolean = true;

    @Input('neu-setzen')
    public NeuSetzen: boolean = false;

    constructor(public postfachService: VwbPInternPostfachService, public jobService: AviJobService, public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public schwaerzenService: VwbPInternSchwaerzenService, public inputMaskService: InputmaskService, public dialogService: DialogService, public cdr: ChangeDetectorRef) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);
    }

    ngOnInit() {
        this.InitDoku(VwbPListDetailConst.VWBP_VORLAGE_TASK_SCHWAERZEN_BEARBEITET_BEWILIGT);
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + (this.NeuSetzen ? this.commonService.translateInstant('VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BUTTON') : this.commonService.translateInstant('VWBP.INTERN.SCHWAERZEN.ANTRAG_BEARBEITET_BUTTON'));
    }

    public onInputEntscheidungAm(event, calendar: Calendar): void {
        this.inputMaskService.handleDateInput(event, calendar);

        // if (this.SelectedRechtstraeger.length > 0) {
        //     const begruendung = this.SelectedRechtstraeger[0].AntragBegruendung.Id;
        //     const gueltigBis = this.SelectedRechtstraeger[0].AntragGueltigBis;
        //     if (begruendung === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_BEGRUENDUNG_MINDERJAEHRIGKEIT) {
        //         this.KannGueltigBisErmitteln = this.SelectedRechtstraeger.every(w => w.AntragBegruendung.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_BEGRUENDUNG_MINDERJAEHRIGKEIT && w.AntragGueltigBis === gueltigBis);

        //         if (this.KannGueltigBisErmitteln && !this.DatumBis)
        //             this.DatumBis = new Date(gueltigBis);
        //     } else {
        //         this.KannGueltigBisErmitteln = this.SelectedRechtstraeger.every(w => w.AntragBegruendung.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_BEGRUENDUNG_MINDERJAEHRIGKEIT);

        //         if (this.KannGueltigBisErmitteln && !!this.Datum) {
        //             let fillDate = !this.DatumBis;
        //             if (this.DatumBis) {
        //                 const dateValue = moment(this.DatumBis);
        //                 fillDate = dateValue.isSame(AviTypeUtil.HighDate);
        //             }

        //             if (fillDate)
        //                 this.DatumBis = moment(this.Datum).add(5, 'year').toDate();
        //         }
        //     }
        // } else {
        //     this.KannGueltigBisErmitteln = true;
        //     this.Datum = null;
        //     this.Frist = null;
        //     this.DatumBis = null;
        // }
    }

    CanExecute(): boolean {
        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung && !!this.Datum && !!this.Frist;
    }

    async Execute() {
        if (this.NeuSetzen) {
            this.PerformAction('VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BUTTON', this.GetResourceName('VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BESTAETIGEN'), 'VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BUTTON',
                async (selectedRT) => this.schwaerzenService.SetAntragEntscheidung(selectedRT.map(w => w.AntragId), VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_ENTSCHEIDUNG_BEWILLIGT, this.Datum, this.Frist, this.Bemerkung)
            );
        } else {
            this.PerformAction('VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BUTTON', this.GetResourceName('VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BESTAETIGEN'), 'VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BUTTON',
                async (selectedRT) => {
                    const jobId: string = await this.schwaerzenService.SetAntragBearbeitet(selectedRT.map(w => w.AntragId), VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_ENTSCHEIDUNG_BEWILLIGT, this.Datum, this.Frist, this.DocuGenerieren, this.Bemerkung);
                    if (jobId) {
                        const jobStatus = await this.jobService.OpenJobDialog(jobId, 'VWBP.INTERN.SCHWAERZEN.ANTRAG_BEWILLIGEN_BUTTON', 'v1/intern/vwbp/job', false);
                        if (jobStatus !== AviListDetailConst.JOB_STATUS_SUCCEEDED)
                            return;
                    }
                    if (this.HasDoku && this.DocuGenerieren)
                        this.postfachService.OpenPostfach();
                }
            );
        }
    }
}
