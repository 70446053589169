import { FlexLayoutModule } from '@angular/flex-layout';
import { AviCoreModule } from '@avi-x/avi-core';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';
import { FormsModule } from '@angular/forms';
import { AviBankVerbindungListeComponent } from '../bank/components/bankverbindung-liste/bankverbindung-liste.component';
import { AviKontoListeComponent } from '../bank/components/konto-liste/konto-liste.component';
import { AviBankZeichnungsrechtListeComponent } from './components/bankzeichnungsrecht-liste/bankzeichnungsrecht-liste.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        FlexLayoutModule,

        AviCoreModule,

        PanelModule,
        AccordionModule
    ],
    exports: [
        AviBankVerbindungListeComponent,
        AviBankZeichnungsrechtListeComponent,
        AviKontoListeComponent
    ],
    declarations: [
        AviBankVerbindungListeComponent,
        AviBankZeichnungsrechtListeComponent,
        AviKontoListeComponent
    ]
})
export class AviBankModule { }
