import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { Adresse } from '../partner/adresse.model';
import { Land } from '../partner/land.model';
import { Provinz } from '../partner/provinz.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class AdresseDetail extends BaseModel {
    @Expose()
    public get AdresseDetDiscriminator(): string { return this.getProperty('AdresseDetDiscriminator'); }
    public set AdresseDetDiscriminator(value: string) { this.setProperty('AdresseDetDiscriminator', value); }

    @Expose()
    @TransformDate()
    public get ZustandAb(): Date { return this.getProperty('ZustandAb'); }
    public set ZustandAb(value: Date) { this.setProperty('ZustandAb', value); }

    @Expose()
    @TransformDate()
    public get ZustandBis(): Date { return this.getProperty('ZustandBis'); }
    public set ZustandBis(value: Date) { this.setProperty('ZustandBis', value); }

    @Expose()
    @TransformDate()
    public get ZustandBeginn(): Date { return this.getProperty('ZustandBeginn'); }
    public set ZustandBeginn(value: Date) { this.setProperty('ZustandBeginn', value); }

    @Expose()
    @TransformDate()
    public get ZustandEnde(): Date { return this.getProperty('ZustandEnde'); }
    public set ZustandEnde(value: Date) { this.setProperty('ZustandEnde', value); }

    @Expose()
    public get Strasse(): string { return this.getProperty('Strasse'); }
    public set Strasse(value: string) { this.setProperty('Strasse', value); }

    @Expose()
    public get HausNr(): string { return this.getProperty('HausNr'); }
    public set HausNr(value: string) { this.setProperty('HausNr', value); }

    @Expose()
    public get Adresszusatz1(): string { return this.getProperty('Adresszusatz1'); }
    public set Adresszusatz1(value: string) { this.setProperty('Adresszusatz1', value); }

    @Expose()
    public get Adresszusatz2(): string { return this.getProperty('Adresszusatz2'); }
    public set Adresszusatz2(value: string) { this.setProperty('Adresszusatz2', value); }

    @Expose()
    public get Adresszusatz3(): string { return this.getProperty('Adresszusatz3'); }
    public set Adresszusatz3(value: string) { this.setProperty('Adresszusatz3', value); }

    @Expose()
    public get Postfach(): string { return this.getProperty('Postfach'); }
    public set Postfach(value: string) { this.setProperty('Postfach', value); }

    @Expose()
    public get PLZ(): string { return this.getProperty('PLZ'); }
    public set PLZ(value: string) { this.setProperty('PLZ', value); }

    @Expose()
    public get PLZZusatz(): string { return this.getProperty('PLZZusatz'); }
    public set PLZZusatz(value: string) { this.setProperty('PLZZusatz', value); }

    @Expose()
    public get Ort(): string { return this.getProperty('Ort'); }
    public set Ort(value: string) { this.setProperty('Ort', value); }

    @Expose()
    public get Encrypted(): boolean { return this.getProperty('Encrypted'); }
    public set Encrypted(value: boolean) { this.setProperty('Encrypted', value); }

    @Expose()
    public get Landwort(): string { return this.getProperty('Landwort'); }
    public set Landwort(value: string) { this.setProperty('Landwort', value); }

    @Expose()
    public get vonAdresse_ID(): string { return this.getModelId('vonAdresse_ID'); }
    public set vonAdresse_ID(value: string) { this.setModelId('vonAdresse_ID', value); }

    @Expose()
    @Type(() => Adresse)
    public get vonAdresse(): Adresse { return this.getModel('vonAdresse'); }
    public set vonAdresse(value: Adresse) { this.setModel('vonAdresse', value); }

    @Expose()
    public get Land_ID(): string { return this.getModelId('Land_ID'); }
    public set Land_ID(value: string) { this.setModelId('Land_ID', value); }

    @Expose()
    @Type(() => Land)
    public get Land(): Land { return this.getModel('Land'); }
    public set Land(value: Land) { this.setModel('Land', value); }

    @Expose()
    public get Provinz_ID(): string { return this.getModelId('Provinz_ID'); }
    public set Provinz_ID(value: string) { this.setModelId('Provinz_ID', value); }

    @Expose()
    @Type(() => Provinz)
    public get Provinz(): Provinz { return this.getModel('Provinz'); }
    public set Provinz(value: Provinz) { this.setModel('Provinz', value); }

    @Expose()
    @Type(() => ListType)
    public get SteuerDomizil(): ListType { return this.getProperty('SteuerDomizil'); }
    public set SteuerDomizil(value: ListType) { this.setProperty('SteuerDomizil', value); }

}
