import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService } from '@avi-x/avi-core';
import { AviQueryDefExecutionGraphComponent } from './components/querydef-exec-graph/querydef-exec-graph.component';

const routes: Routes = [
    { path: 'querydef/graph/:id', component: AviQueryDefExecutionGraphComponent, canActivate: [AviCanActivateWhenLoggedInService] },
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviQueryDefExecutionGraphRoutingModule {
}
