import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { BasePartner } from '../partner/basepartner.model';
import { ListDetail } from '../system/listdetail.model';
import { SteuernummerDef } from '../partner/steuernummerdef.model';
import { Land } from '../partner/land.model';
import { ModelState } from '../system/modelstate.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class PartnerIdentifikation extends BaseModel {
    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get Folgenummer(): number { return this.getProperty('Folgenummer'); }
    public set Folgenummer(value: number) { this.setProperty('Folgenummer', value); }

    @Expose()
    public get DocId(): string { return this.getProperty('DocId'); }
    public set DocId(value: string) { this.setProperty('DocId', value); }

    @Expose()
    public get DocArchiv(): string { return this.getProperty('DocArchiv'); }
    public set DocArchiv(value: string) { this.setProperty('DocArchiv', value); }

    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }

    @Expose()
    public get IdentifikationsNr(): string { return this.getProperty('IdentifikationsNr'); }
    public set IdentifikationsNr(value: string) { this.setProperty('IdentifikationsNr', value); }

    @Expose()
    public get Ort(): string { return this.getProperty('Ort'); }
    public set Ort(value: string) { this.setProperty('Ort', value); }

    @Expose()
    public get Encrypted(): boolean { return this.getProperty('Encrypted'); }
    public set Encrypted(value: boolean) { this.setProperty('Encrypted', value); }

    @Expose()
    public get ExterneReferenz(): string { return this.getProperty('ExterneReferenz'); }
    public set ExterneReferenz(value: string) { this.setProperty('ExterneReferenz', value); }

    @Expose()
    public get vonPartner_ID(): string { return this.getModelId('vonPartner_ID'); }
    public set vonPartner_ID(value: string) { this.setModelId('vonPartner_ID', value); }

    @Expose()
    @Type(() => BasePartner)
    public get vonPartner(): BasePartner { return this.getModel('vonPartner'); }
    public set vonPartner(value: BasePartner) { this.setModel('vonPartner', value); }

    @Expose()
    public get DetailType_ID(): string { return this.getModelId('DetailType_ID'); }
    public set DetailType_ID(value: string) { this.setModelId('DetailType_ID', value); }

    @Expose()
    @Type(() => ListDetail)
    public get DetailType(): ListDetail { return this.getModel('DetailType'); }
    public set DetailType(value: ListDetail) { this.setModel('DetailType', value); }

    @Expose()
    public get SponsoredGIINIdent_ID(): string { return this.getModelId('SponsoredGIINIdent_ID'); }
    public set SponsoredGIINIdent_ID(value: string) { this.setModelId('SponsoredGIINIdent_ID', value); }

    @Expose()
    public get SteuernummerDef_ID(): string { return this.getModelId('SteuernummerDef_ID'); }
    public set SteuernummerDef_ID(value: string) { this.setModelId('SteuernummerDef_ID', value); }

    @Expose()
    @Type(() => SteuernummerDef)
    public get SteuernummerDef(): SteuernummerDef { return this.getModel('SteuernummerDef'); }
    public set SteuernummerDef(value: SteuernummerDef) { this.setModel('SteuernummerDef', value); }

    @Expose()
    public get vonLand_ID(): string { return this.getModelId('vonLand_ID'); }
    public set vonLand_ID(value: string) { this.setModelId('vonLand_ID', value); }

    @Expose()
    @Type(() => Land)
    public get vonLand(): Land { return this.getModel('vonLand'); }
    public set vonLand(value: Land) { this.setModel('vonLand', value); }

    @Expose()
    public get vonLand2_ID(): string { return this.getModelId('vonLand2_ID'); }
    public set vonLand2_ID(value: string) { this.setModelId('vonLand2_ID', value); }

    @Expose()
    @Type(() => Land)
    public get vonLand2(): Land { return this.getModel('vonLand2'); }
    public set vonLand2(value: Land) { this.setModel('vonLand2', value); }

    @Expose()
    public get vonLand3_ID(): string { return this.getModelId('vonLand3_ID'); }
    public set vonLand3_ID(value: string) { this.setModelId('vonLand3_ID', value); }

    @Expose()
    @Type(() => Land)
    public get vonLand3(): Land { return this.getModel('vonLand3'); }
    public set vonLand3(value: Land) { this.setModel('vonLand3', value); }

    @Expose()
    public get vonLand4_ID(): string { return this.getModelId('vonLand4_ID'); }
    public set vonLand4_ID(value: string) { this.setModelId('vonLand4_ID', value); }

    @Expose()
    @Type(() => Land)
    public get vonLand4(): Land { return this.getModel('vonLand4'); }
    public set vonLand4(value: Land) { this.setModel('vonLand4', value); }

    @Expose()
    public get Status_ID(): string { return this.getModelId('Status_ID'); }
    public set Status_ID(value: string) { this.setModelId('Status_ID', value); }

    @Expose()
    @Type(() => ModelState)
    public get Status(): ModelState { return this.getModel('Status'); }
    public set Status(value: ModelState) { this.setModel('Status', value); }

    @Expose()
    @Type(() => ListType)
    public get IdentifikationsArt(): ListType { return this.getProperty('IdentifikationsArt'); }
    public set IdentifikationsArt(value: ListType) { this.setProperty('IdentifikationsArt', value); }

    @Expose()
    @Type(() => ListType)
    public get IsExternal(): ListType { return this.getProperty('IsExternal'); }
    public set IsExternal(value: ListType) { this.setProperty('IsExternal', value); }

}
