import { Component, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AviCommonService } from '@avi-x/avi-core';
import { VwbPListDetailConst, VwbPCommonService } from '@avi-x/vwbp-public';
import { VwbPInternRechtstraegerVersionenSearcherComponent } from '../../../rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-versionen-searcher.component';

export enum RechtstraegerTaskMode {
    // eslint-disable-next-line no-unused-vars
    FREIGEBEN, RUECKGAENGIG
}

@Component({
    selector: 'vwbp-intern-kontrollwesen-pendenzen',
    template: `
         <vwbp-intern-rechtstraeger-versionen-searcher #searcher [use-router-params]="true" settingskey="settings.searcher.kw.pendenzen" [get-filters]="GetFilters" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'Version', 'Freigabedatum', 'ValidierungAusnahme', 'BemerkungTeilfonds', 'btnOpen']">
         </vwbp-intern-rechtstraeger-versionen-searcher>

         <div *ngIf="currentUserIsAjuUserSchreiben()" fxLayout="row">
            <div class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="start left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-rechtstraeger-freigeben-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-rechtstraeger-freigeben-task>
            </div>
        </div>
        `
})
export class VwbPInternKontrollwesenPendenzenComponent {
    @ViewChild('searcher', { static: true })
    private searcher: VwbPInternRechtstraegerVersionenSearcherComponent;

    FreigebenBemerkung: string;

    constructor(public commonService: AviCommonService, private vwbpCommonService: VwbPCommonService, public cdr: ChangeDetectorRef) { }

    GetFilters(): string[] {
        return [`Erfassungsstatus eq ${VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_ERFASSUNG_ABGESCHLOSSEN}`];
    }

    currentUserIsAjuUserSchreiben() {
        return this.vwbpCommonService.currentUserIsAjuUserSchreiben();
    }

    GetSelectionCount() {
        return this.GetSelectedRechtstraeger().length;
    }

    public GetSelectedRechtstraeger(): any[] {
        return this.searcher.GetSelectedRechtstraeger();
    }

    Refresh() {
        this.searcher.forceRefresh();
        this.cdr.markForCheck();
    }
}
