import { Component, EventEmitter, Input, Output, ViewChild, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AviApiService, AviBaseSearcherComponent, AviCommonService, AviSearcherColumn, AviSearcherColumnType } from '@avi-x/avi-core';
import { CommentKeywordDto } from '@avi-x/avi-dto/system/comment-keyword-dto.model';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { AviSearcherButtonType } from '@avi-x/avi-core';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'vwbp-intern-rechtstraeger-base',
    template: ''
})
export class VwbPInternRechtstraegerBaseComponent implements OnInit, OnDestroy {
    FormModelId: string = null;
    SelectedRows: any[] = [];
    DetailModel: any = null;

    showDeletedValueChanged: Subject<boolean> = new Subject<boolean>();

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @Input('titel')
    public Titel: string = 'Rechtsträger';

    @Input('card')
    public Card: boolean = false;

    @Input('visible-columns')
    public VisibleColumns: string[] = null;

    @Input('checbox-selection')
    public CheckboxSelection: boolean = true;

    @Input('checbox-selectionmode')
    public CheckboxSelectionMode: string = 'null';

    @Input('show-comment-detailview')
    public ShowCommentDetailView: boolean = false;

    @Input('open-mode')
    public OpenMode: 'normal' | 'unstimmigkeit' | 'mahnwesen' | 'schwaerzen' = 'normal';

    @Input('last-version')
    public LastVersion: boolean = false;

    @Input('regfrist-abgelaufen')
    public RegFristAbgelaufen: boolean = false;

    @Input('include-keywords')
    public IncludeKeywords: boolean = false;

    @Input('show-deleted-filter')
    public ShowDeletedFilter: boolean = false;

    @Input('settingskey')
    public SettingsKey: string = null;

    @Input('show-aktiv')
    public ShowAktiv: boolean = false;

    @Output()
    public onInit: EventEmitter<any> = new EventEmitter();

    @Input('get-filters')
    public GetFilters: () => string[] = (): string[] => [];

    constructor(
        public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        public vwbpCommonService: VwbPCommonService,
        public cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.showDeletedValueChanged
            .pipe(
                debounceTime(250),
                distinctUntilChanged())
            .subscribe(model => {
                setTimeout(() => {
                    this.searcher.updateQueryParams(true, true);
                });
            });
    }

    public GetIncludeDeletedValue() {
        return this.searcher.getSearcherParameter('includeDeleted')?.Value;
    }

    public SetIncludeDeletedValue(text: any) {
        this.searcher.setSearcherParameter('includeDeleted', text);
        this.showDeletedValueChanged.next(text);
    }

    ngOnDestroy() {
        this.showDeletedValueChanged.unsubscribe();
    }

    public getColumn(field: string): AviSearcherColumn {
        return this.searcher.getColumn(field);
    }

    public GetSelectedRechtstraeger(): any[] {
        return this.searcher.SelectedRows;
    }

    public showInaktivVersion(record: any): boolean {
        const erfassungsstatus = record.Erfassungsstatus?.Id;
        const uebergabestatus = record.Uebergabestatus?.Id;
        return uebergabestatus !== VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_NORMAL ||
            (erfassungsstatus !== VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_INBEARBEITUNG && erfassungsstatus !== VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_MUTIEREN);
    }

    public canOpenVersion(record: any): boolean {
        return true;
    }

    public GetRowClass = (row: any): string => {
        let ret = 'no-border-left no-border-right';
        if (row) {
            if (row['Erfassungsstatus']?.Id && !this.showInaktivVersion(row))
                ret += ' rt-inaktiv';

            if (row['HrLoeschungsdatum'])
                ret += ' rt-geloescht';

            if (this.DetailModel && row['Id'] === this.DetailModel.Id)
                ret += ' ui-state-highlight';
        }
        return ret;
    }

    public onDoubleClick(data) {
        if (this.canOpenVersion(data))
            this.openRechtstraeger(data.Id);
    }

    openRechtstraeger(id: string) {
        this.router.navigate(['/intern/vwbp/index/rt', id], { queryParams: { openMode: this.OpenMode } });
    }

    public forceRefresh() {
        this.DetailModel = null;
        this.SelectedRows = [];
        this.searcher.forceRefresh();
    }

    async onSelect(data: any[]) {
        this.SelectedRows = data;
    }

    onClick(data: any[]) {
        if (data)
            this.DetailModel = data;
    }

    currentUserIsAjuUserSchreiben() {
        return this.vwbpCommonService.currentUserIsAjuUserSchreiben();
    }

    public AddColumBemerkungen() {
        this.searcher.addColumn(AviSearcherColumn.CreateCustom(
            'badgeBemerkungen',
            ' ',
            (row, col) => {
                if (row['BemerkungCount'])
                    return `<span class="comment-badge">${row['BemerkungCount']}</span>`;
                else
                    return `<span class="comment-badge-empty">${row['BemerkungCount']}</span>`;
            },
            false,
            '45px')
            .setSortable(false)
        );
    }

    public AddColumnAuge() {
        this.searcher.addColumn(
            AviSearcherColumn.CreateButton('btnOpen', ' ', null, null, null, null, '80px', ['b1', 'b2'], [AviSearcherButtonType.BADGE, AviSearcherButtonType.BUTTON] )
                .setTooltipDelegate((row, col, buttonId) => {
                    if (buttonId === 'b1')
                        return row['BemerkungCount'] + (row['BemerkungCount'] === 1 ? ' offene Bemerkung' : ' offene Bemerkungen');
                    else if (buttonId === 'b2')
                        return 'Rechtsträger öffnen';
                })
                .setButtonOnClickDelegate((row, col, buttonId) => {
                    if (buttonId === 'b2')
                        this.openRechtstraeger(row['Id']);
                })
                .setButtonValueDelegate((row, col, buttonId) => {
                    if (buttonId === 'b1')
                        return row['BemerkungCount'];

                    return null;
                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    if (buttonId === 'b1') {
                        return row['BemerkungCount'] ? 'comment' : 'comment-empty';
                    } else
                        return {
                            'w-100p': true,
                            'p-button-primary': false,
                            'p-button-secondary': true,
                            'p-button-rounded': true,
                            'p-button-text': true,
                        };
                    })
                .setButtonIconDelegate((row, col, buttonId) => {
                    return 'pi pi-eye';
                })
                .setSortable(false)
        );
    }

    public async AddColumnKeywords() {
        const kwdefs = await this.apiService.getModelList(CommentKeywordDto, 'v1/intern/vwbp/comment/keyworddefs');
        const columnKW = this.searcher.addTextColumn('Keywords', 'VWBP.COMMON.KEYWORDS', true).setSortable(false);
        columnKW.FilterType = AviSearcherColumnType.LISTTYPE;
        columnKW.DropdownDisplayField = 'Keyword';
        columnKW.DropdownValueField = 'Keyword';
        columnKW.DropdownDatasourceInternal = kwdefs.map(w => {
            return { label: w.Keyword, value: w.Keyword };
        });

        columnKW.MultiSelectionFilter = false;
    }

    public buildSearchConfigAsObject(): any {
        const ret: any = {};

        const filterlist = [];

        if (this.GetFilters) {
            const filters = this.GetFilters();
            if (filters)
                filters.forEach(f => filterlist.push(`(${f})`));
        }

        if (this.searcher.ColumnFilters)
            this.searcher.ColumnFilters.forEach(f => {
                if (f.column.Field !== 'Keywords') {
                    const colFilter = this.searcher._getSearchFilterForColumn(f.column, f.value);
                    if (colFilter)
                        filterlist.push(colFilter);
                } else {
                    ret.keywordFilter = f.value;
                }
            });

        if (filterlist.length > 0)
            ret.filter = `${(filterlist.join(' AND '))}`;

        if (this.searcher.InitFields && this.searcher.InitFields.length > 0)
            ret.init = `${this.searcher.InitFields.join(',')}`;

        if (this.searcher.LazyLoading && this.searcher.SearcherRows > 0) {
            ret.page = `${Math.floor(this.searcher.PageRowStartIndex / this.searcher.SearcherRows)}`;
            ret.per_page = `${this.searcher.SearcherRows}`;
        }

        if (this.searcher.SortInfo && this.searcher.SortInfo.length > 0) {
            ret.sort = this.searcher.SortInfo.map(w => `${Number(w.order) === -1 ? '-' : ''}${w.column.OField}`).join(',');
        }

        ret.lastversion = this.LastVersion;
        ret.regFristAbgelaufen = this.RegFristAbgelaufen;
        ret.includeKeywords = this.IncludeKeywords;
        ret.includeDeleted = this.GetIncludeDeletedValue();

        return ret;
    }

    public async doSearch(searchValue: string, api: string, searchConfig: any = null): Promise<any> {
        this.searcher.SelectedRows = [];

        const settings = this.buildSearchConfigAsObject();
        return await this.apiService.post(api, settings);
    }
}
