import { Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AviAuthService } from '../../services/auth.service';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'avi-core-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class AviLoginComponent implements OnInit {
    redir: string = null;

    @Input('title')
    public Title: string = `Login`;

    @Input('full-screen')
    public FullScreen = true;

    loginForm: FormGroup;
    loginFormSSO: FormGroup;
    initPwResetForm: FormGroup;
    doPwResetForm: FormGroup;

    public ShowSsoLoginForm = false;
    public ShowTotpInput = false;
    public ShowTotpInputSSO = false;

    displayedForm: string = 'login';

    busy: boolean = false;

    constructor(
        public authService: AviAuthService,
        public commonService: AviCommonService,
        public apiService: AviApiService,
        private route: ActivatedRoute,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2
    ) {
        this.Title = ((this.commonService.AppName || '') + ' Login').trim();

        this.loginForm = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
            totp: new FormControl(''),
        });
        this.loginFormSSO = new FormGroup({
            totp: new FormControl(''),
        });


        const handleParam = (params) => {
            const form = params.f || 'login';
            if (form === 'ipr') {
                this.initPwReset(params.username);
            } else if (form === 'dpr') {
                this.initDoPwReset(params.username, params.token);
            }
        };

        this.route.params.subscribe(params => handleParam(params));
        this.route.queryParams.subscribe(params => handleParam(params));
    }


    public setUser(username: string, password?: string) {
        if (password !== undefined) {
            this.loginForm.patchValue({ username: username, password: password });
        } else {
            this.loginForm.patchValue({ username: username });
        }
    }

    ngOnInit() {
        this.authService.IsSsoEnabled().then(r => this.ShowSsoLoginForm = r);
        const getPathFromUrl = (url) => {
            return url ? url.split(/[?#]/)[0] : url;
        };
        this.route.params.subscribe(params => {
            this.redir = getPathFromUrl(params['source']);
            const lastUsername = this.commonService.localStorageGet(
                'loginLastUser',
                '',
                false
            );
            this.loginForm.setValue({ username: lastUsername, password: '', totp: '' });
        });
    }

    public submitLoginForm() {
        this.onSubmit();
    }

    onSubmitSSO() {
        const redirAction =
            this.redir != null
                ? data => this.router.navigate([this.redir])
                : data => {
                    this.router.navigate(['/']);
                };

        this.setBusyState(true);
        this.authService
            .LoginGetTokenWithSSO(this.loginFormSSO.value)
            .then(r => {
                this.cdr.detectChanges();
                this.commonService.localStorageSet(
                    'loginLastUser',
                    r.Username,
                    false
                );

                this.loginForm.reset();
                this.cdr.detectChanges();

                this.authService.registerDefaultTimedLogout(false);

                this.setBusyState(false);
                redirAction(r);
            })
            .catch(err => {
                if (err.message && err.message === 'totp_required') {
                    this.ShowTotpInputSSO = true;
                    // this.commonService.notificateWarning('Bitte TOTP eingeben');
                    this.cdr.detectChanges();
                    this.renderer.selectRootElement('#totpInputSSO').focus();
                } else {
                    this.commonService.notificateError(err);
                }
                this.setBusyState(false);
            });
    }

    onSubmit() {
        const redirAction =
            this.redir != null
                ? data => this.router.navigate([this.redir])
                : data => {
                    this.router.navigate(['/']);
                };

        this.setBusyState(true);
        this.authService
            .Login(this.loginForm.value, null)
            .then(r => {
                this.cdr.detectChanges();
                this.commonService.localStorageSet(
                    'loginLastUser',
                    this.loginForm.value.username,
                    false
                );

                this.loginForm.reset();
                this.cdr.detectChanges();

                this.authService.registerDefaultTimedLogout(true);

                this.setBusyState(false);
                redirAction(r);
            })
            .catch(err => {
                if (err.message && err.message === 'totp_required') {
                    this.ShowTotpInput = true;
                    // this.commonService.notificateWarning('Bitte TOTP eingeben');
                    this.cdr.detectChanges();
                    this.renderer.selectRootElement('#totpInput').focus();
                } else {
                    this.commonService.notificateError(err);
                }
                this.setBusyState(false);
            });
    }

    initPwReset(username: string) {
        this.initPwResetForm = new FormGroup({
            username: new FormControl(username || this.loginForm.value.username, Validators.required)
        });

        this.displayedForm = 'initPwReset';
    }
    onSubmitInitPwReset() {
        this.setBusyState(true);
        this.authService.InitPwReset(this.initPwResetForm.value.username)
            .then(r => {
                this.commonService.notificateSuccess('Email versendet. Bitte prüfen Sie ihre Inbox.');
                this.initDoPwReset();
                this.setBusyState(false);
            })
            .catch(err => {
                this.commonService.notificateError(err);
                this.setBusyState(false);
            });
    }

    initDoPwReset(username: string = null, token: string = null) {
        const usernameFinal = username || (this.initPwResetForm && this.initPwResetForm.value ? this.initPwResetForm.value.username : null);
        this.doPwResetForm = new FormGroup({
            username: new FormControl(usernameFinal, Validators.required),
            token: new FormControl(token || '', Validators.required),
            password1: new FormControl('', Validators.required),
            password2: new FormControl('', Validators.required)
        });
        this.displayedForm = 'doPwReset';
    }

    onSubmitDoPwReset() {
        console.log(this.doPwResetForm.value);
        this.setBusyState(true);
        this.authService.DoPwReset(this.doPwResetForm.value)
            .then(r => {
                this.commonService.notificateSuccess('Passwort erfolgreich geändert.');
                this.displayedForm = 'login';
                this.setBusyState(false);
                this.setUser(this.doPwResetForm.value.username, this.doPwResetForm.value.password1);
                this.submitLoginForm();
            })
            .catch(err => {
                this.commonService.notificateError(err);
                this.setBusyState(false);
            });
    }

    public setBusyState(busy: boolean) {
        this.busy = busy;
        if (busy)
            this.commonService.showGlobalLoader();
        else
            this.commonService.hideGlobalLoader();
    }
}
