import { ChangeDetectorRef, Component } from '@angular/core';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternUnstimmigkeitService } from '../../services/unstimmigkeit.service';
import { VwbPInternUnstimmigkeitBaseComponent } from './unstimmigkeit-base.component';

@Component({
    selector: 'vwbp-intern-unstimmigkeit-abgeschlossen',
    template: `
        <vwbp-intern-rechtstraeger-versionen-searcher settingskey="settings.searcher.um.abgeschlossen" [use-router-params]="true" [get-filters]="GetFilters" open-mode='unstimmigkeit' [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'Version', 'btnOpen']">
        </vwbp-intern-rechtstraeger-versionen-searcher>
        `
})
export class VwbPInternUnstimmigkeitAbgeschlossenComponent extends VwbPInternUnstimmigkeitBaseComponent {
    constructor(public commonService: AviCommonService, public unstimmigkeitService: VwbPInternUnstimmigkeitService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, unstimmigkeitService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters(): string[] {
        return [`Unstimmigkeitsstatus eq ${VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_ABGESCHLOSSEN}`];
    }
}
