import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { ListType } from '../shared';

export class StatusWechselDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;

    @Expose()
	public Bezeichnung: string;

	@Expose()
	@Type(() => ListType)
	public BemerkungEinblenden: ListType;

	@Expose()
	@Type(() => ListType)
	public BemerkungZwingend: ListType;

	@Expose()
	@Type(() => ListType)
	public KommentarEinblenden: ListType;

	@Expose()
	@Type(() => ListType)
	public AllowChangeAssignedTo: ListType;

	@Expose()
	@Type(() => ListType)
	public AssignedTo: ListType;

	@Expose()
	@Type(() => ListType)
	public AssignedToContext1: ListType;

	@Expose()
	@Type(() => ListType)
	public AutoAusfuehren: ListType;

    @Expose()
	public UebergangGenerierenOutput: string;
	
    @Expose()
	public Eingangsstatus: string;

	@Expose()
	public Ausgangsstatus: string;
}