import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviListDetailConst, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DecisionTableParamDef } from '@avi-x/avi-dto/system/decisiontableparamdef.model';
import { AviUserContactInfoSearcherComponent } from '../../user/components/user-contactinfo-searcher.ts/user-contactinfo-searcher.component';
import { ListType } from '@avi-x/avi-dto/shared';

const nameof = (nameFunction: ((obj: DecisionTableParamDef) => any)) => typed_nameof<DecisionTableParamDef>(nameFunction);

@Component({
    selector: 'avi-decisiontableparamdef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDecisionTableParamDefFormComponent extends AviAbstractModelFormComponent<DecisionTableParamDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF';
    BaseUrl = 'decisiontabledef/param';
    ClassType = DecisionTableParamDef;
	FormType = AviDecisionTableParamDefFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFDecisionTableDef';
    }
	
    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.KURZBEZ1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.BEZEICHNUNG1', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.KURZBEZ2', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.BEZEICHNUNG2', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.KURZBEZ3', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.BEZEICHNUNG3', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.KURZBEZ4', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.BEZEICHNUNG4', true).setMDSizeHalf());
        
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez5), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.KURZBEZ5', true).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung5), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.BEZEICHNUNG5', true).setMDSizeHalf());
        
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez6), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.KURZBEZ6', true).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung6), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.BEZEICHNUNG6', true).setMDSizeHalf());
        
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez7), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.KURZBEZ7', true).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung7), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.BEZEICHNUNG7', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonDecisionTableDef_ID), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.VONDECISIONTABLEDEF', `model/representations/CAFDecisionTableDef${this.ReadOnly ? '?id=$id' : ''}`, true, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sequenz), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.SEQUENZ', 0, true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.PropertyName), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.PROPERTYNAME', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ParamArt), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.PARAMART', 'caf_decisiontableparamdef_art', true, true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AttributeType), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.ATTRIBUTETYPE', 'attribute_type', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.VergleichsOperator), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.VERGLEICHSOPERATOR', 'caf_vergleichsoperator', true, true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.List_ID), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.LIST', `model/representations/List${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Model_ID), 'CORE.COMMON.MODEL.CAFDECISIONTABLEPARAMDEF.MODEL', `model/representations/ModelMeta${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        
    }

    override async  onAttrChange(data: AviChangedAttr) {
        if(data.field === nameof(c => c.AttributeType))
        {
            var valueL: ListType = data.value;
            this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.List_ID), valueL.Id != AviListDetailConst.ATTRTYPE_LISTTYPE);
            this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.Model_ID), valueL.Id != AviListDetailConst.ATTRTYPE_REFERENCE);
        }

        if(data.field === nameof(c => c.KurzBez1))
        {
            var value: string = data.value;
            this.Model.KurzBez2 = value;
            this.Model.KurzBez3 = value;
            this.Model.KurzBez4 = value;
            this.Model.KurzBez5 = value;
            this.Model.KurzBez6 = value;
            this.Model.KurzBez7 = value;
        }

        if(data.field === nameof(c => c.Bezeichnung1))
        {
            var value: string = data.value;
            this.Model.Bezeichnung2 = value;
            this.Model.Bezeichnung3 = value;
            this.Model.Bezeichnung4 = value;
            this.Model.Bezeichnung5 = value;
            this.Model.Bezeichnung6 = value;
            this.Model.Bezeichnung7 = value;
        }
        
        super.onAttrChange(data);
    }

    override async afterInitForm() {
        this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.List_ID), this.Model.AttributeType?.Id != AviListDetailConst.ATTRTYPE_LISTTYPE ?? true);
        this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.Model_ID), this.Model.AttributeType?.Id != AviListDetailConst.ATTRTYPE_REFERENCE ?? true);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.vonDecisionTableDef_ID), this.isEditMode);
    
        super.afterInitForm();
    }    

    public async createModel(): Promise<DecisionTableParamDef> {
        const res = await this.apiService.getModel(this.ClassType, `${this.getBaseUrl()}/create/${this.ContextId}`);
        await this.afterCreateModel(res);
        return res;
    }
}
