import { Expose } from 'class-transformer';

export class GenericRef {
    private _ModelID: string;
    private _ModelType: string;

    constructor(modelId: string = null, modelType: string = null) {
        this._ModelID = modelId;
        this._ModelType = modelType;
    }

    @Expose()
    public get ModelID(): string { return this._ModelID; }
    public set ModelID(value: string) { this._ModelID = value; }

    @Expose()
    public get ModelType(): string { return this._ModelType; }
    public set ModelType(value: string) { this._ModelType = value; }
}
