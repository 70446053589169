import { Expose, Type } from 'class-transformer';

import { BaseModelState } from '@avi-x/avi-dto/shared/basemodel.model';

export class VwbPRechtstraegerVersionInfo extends BaseModelState {

    @Expose()
    public get Laufnummer(): number { return this.getProperty('Laufnummer'); }
    public set Laufnummer(value: number) { this.setProperty('Laufnummer', value); }

    @Expose()
    @Type(() => Date)
    public get Versionsdatum(): Date { return this.getProperty('Versionsdatum'); }
    public set Versionsdatum(value: Date) { this.setProperty('Versionsdatum', value); }

    @Expose()
    @Type(() => Date)
    public get Freigabedatum(): Date { return this.getProperty('Freigabedatum'); }
    public set Freigabedatum(value: Date) { this.setProperty('Freigabedatum', value); }

    @Expose()
    public get VersionTyp(): string { return this.getProperty('VersionTyp'); }
    public set VersionTyp(value: string) { this.setProperty('VersionTyp', value); }
}
