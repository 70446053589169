import { Component, Input, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviApiService, AviFormField, AviCommonService, AviAuthService, AviBaseFormComponent, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { Location } from '@angular/common';

import { VwbPUserProfile, VwbPKontaktInfoData } from '@avi-x/vwbp-public';

const nameof = (nameFunction: ((obj: VwbPKontaktInfoData) => any)) => typed_nameof<VwbPKontaktInfoData>(nameFunction);

@Component({
    selector: 'vwbp-intern-rechtstraeger-kontaktinfo-panel',
    templateUrl: './rechtstraeger-kontaktinfo-panel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternRechtstraegerKontaktInfoComponent implements OnInit, OnDestroy {

    sub: any;

    @ViewChild('form', { static: true })
    Form: AviBaseFormComponent = null;

    _OwnerId: string = null;
    public get VersionId(): string {
        return this._OwnerId;
    }

    @Input('widget-title')
    public WidgetTitle: string;

    @Input('owner-id')
    public set VersionId(value: string) {
        this._OwnerId = value;
        if (this._OwnerId)
            this.loadModel(this._OwnerId);
    }

    @Input('version-number')
    VersionNumber;

    @Input('toggleable')
    Toggleable: boolean = true;

    @Input('force-collapsed')
    public ForceCollapsed: boolean = false;

    public Loading: boolean = false;
    public Model: VwbPKontaktInfoData = null;
    public Fields: AviFormField[] = [];

    public Api: string = 'v1/vwbp';

    constructor(private commonService: AviCommonService,
        private authService: AviAuthService,
        private location: Location,
        private apiService: AviApiService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    ngOnInit() {
        this.initFields();
    }

    async loadModel(id) {
        const model: VwbPUserProfile = await this.apiService.getModel(VwbPUserProfile, `${this.Api}/user/profile/${id}`);

        const newModel = new VwbPKontaktInfoData();
        if (model) {
            newModel.Name = model.Name;
            newModel.Adresse = `${model.Strasse}\n${model.PLZ} ${model.Ort}`;
            newModel.KontaktName = model.KontaktVorname ? `${model.KontaktVorname}, ${model.KontaktName}` : model.KontaktName;
            newModel.KontaktEmail = model.KontaktEmail;
            newModel.KontaktTelefon = model.KontaktTelefon;
            newModel.KontoName = model.KontoName;
            newModel.KontoEmail = model.KontoEmail;
            newModel.KeycloakId = model.KeycloakId;
        }
        this.Model = newModel;

        // this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.KeycloakId), newModel.KeycloakId != null);

        await this.updateDropdownSources();
    }

    async updateDropdownSources() {
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KontaktName), 'VWBP.KONTAKTINFO.KONTAKTNAMEVORNAME', true).setMDSizeOneThird());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KontaktTelefon), 'VWBP.KONTAKTINFO.KONTAKTTELEFON', true).setMDSizeOneThird());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KontaktEmail), 'VWBP.KONTAKTINFO.KONTAKTEMAIL', true).setMDSizeOneThird());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'VWBP.KONTAKTINFO.NAME', true).setMDSizeOneThird());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Adresse), 'VWBP.KONTAKTINFO.ADRESSE', true).setMDSizeOneThird());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KontoEmail), 'VWBP.KONTAKTINFO.KONTOEMAIL', true).setMDSizeOneThird());

        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KeycloakId), 'VWBP.KONTAKTINFO.KEYCLOAKID', true).setMDSizeOneThird());
    }
}
