
export interface IFav {
    Id: number;
    Title: string;
    Url: string;
    Sorter?: number;
}


export interface IDocument {
    Id: number;
    Title?: string;
    Path?: string;
    File?: File;
    Description?: string;
}

export interface IDocDialogConfig {
    Data: IDocument;
    Action: Function;
}
