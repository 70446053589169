import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService } from '@avi-x/avi-core';
import { AviQueryDefSearcherComponent } from './components/querydef-searcher.component';

const routes: Routes = [
    { path: 'querydef', component: AviQueryDefSearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] }
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviQueryDefSearcherRoutingModule {
}
