import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnChanges } from '@angular/core';
import { AviApiService, AviCommunicationService, AviPartnerListDetailConst, AviSessionControllerService, ISessionData } from '@avi-x/avi-core';
import { PartnerKommunikation } from '@avi-x/avi-dto/partner/partnerkommunikation.model';

@Component({
    selector: 'avi-crm-natperson-index',
    templateUrl: './natperson-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AviNatPersonIndexComponent implements OnChanges {    
    @Input('session-data')
    SessionData: ISessionData;
    
    PartnerId: any = null;
    PageTitle: string = null;

    Kommunikationen: PartnerKommunikation[];
    Phonenumbers: PartnerKommunikation[];
    SelectedPhonenumber: PartnerKommunikation;
    EmailAdresses: PartnerKommunikation[];
    SelectedEmailAdress: PartnerKommunikation;

    // DomizilAdresse: AdresseDetail;

    PhoneLabel: string;
    EmailLabel: string;

    constructor(
        private apiService: AviApiService,
        public sessionController: AviSessionControllerService,
        private cdr: ChangeDetectorRef,
        private communicationService: AviCommunicationService
    ) {       
    }

    async ngOnChanges() {
        if (this.SessionData?.ContextId) {
            await this.loadModel(this.SessionData.ContextId);
            this.sessionController.setCurrentSessionTitle(this.PageTitle);
        }
    }

    async loadModel(id: string) {
        this.PartnerId = id;
        if (id) {
            this.Kommunikationen = await this.apiService.getModelList(PartnerKommunikation, `partnerkommunikation?filter=(vonPartner_ID eq ${this.PartnerId}) and (gueltigAb le today) and (gueltigBis ge today)&select=Id,Eingabe,KommArt,KommTyp`);

            this.Phonenumbers = this.Kommunikationen.filter(w => w.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_TELEFON || w.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_MOBILE);
            this.SelectedPhonenumber = this.Phonenumbers[0];
            this.PhoneLabel = 'Telefon' + (this.Phonenumbers.length > 1 ? ` (${this.Phonenumbers.length})` : '');

            this.EmailAdresses = this.Kommunikationen.filter(w => w.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_EMAIL);
            this.SelectedEmailAdress = this.EmailAdresses[0];
            this.EmailLabel = 'Email' + (this.EmailAdresses.length > 1 ? ` (${this.EmailAdresses.length})` : '');

            // this.DomizilAdresse = (await this.apiService.getModel(AdresseDetail, `adressedetail?filter=(vonAdresse_ID eq ${this.Model.DomizilAdresseGUID}) and (zustandBeginn le today) and (zustandEnde ge today)&init=land,provinz`))[0];

            this.PageTitle = await this.apiService.get(`model/representation/${this.PartnerId}/cafnatperson`);

            this.cdr.markForCheck();
        }
    }

    public click2Call(data: PartnerKommunikation) {
        this.communicationService.StartCall(data.Eingabe);
    }

    public click2Email(data: PartnerKommunikation) {
        this.communicationService.StartEmail(data.Eingabe);
    }

    public selectPhonenumber(number: PartnerKommunikation) {
        this.SelectedPhonenumber = number;
    }

    public selectEmailAdress(address: PartnerKommunikation) {
        this.SelectedEmailAdress = address;
    }
}
