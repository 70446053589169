import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviListDetailConst, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { StatusWorkflowDef } from '@avi-x/avi-dto/workflow/statusworkflowdef.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: StatusWorkflowDef) => any)) => typed_nameof<StatusWorkflowDef>(nameFunction);

@Component({
    selector: 'avi-statusworkflowdef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviStatusWorkflowDefFormComponent extends AviAbstractModelFormComponent<StatusWorkflowDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF';
    BaseUrl = 'workflow/statusworkflowdef';
    ClassType = StatusWorkflowDef;
    FormType = AviStatusWorkflowDefFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFStatusWorkflowDef';        
    }  
    
    public async createModel(): Promise<StatusWorkflowDef> {
        const res = await this.apiService.getModel(this.ClassType, `${this.getBaseUrl()}/create/${AviListDetailConst.CAF_STATUSWORKFLOW_TYP_BEWILLIGUNGSPROZESS}`);
        await this.afterCreateModel(res);
        return res;
    }

    public async afterInitForm() {
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.vonModel_ID), this.Model.Typ.Id === AviListDetailConst.CAF_STATUSWORKFLOW_TYP_BEWILLIGUNGSPROZESS);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.vonModel_ID), this.Model.Typ.Id === AviListDetailConst.CAF_STATUSWORKFLOW_TYP_BEWILLIGUNGSPROZESS);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.StartBedingungOutput), !!this.Model.StartBedingung_ID);
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1)) {
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung3 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung4 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung5 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung6 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung7 = this.Model.Bezeichnung1;
        }

        if (data.field === nameof(c => c.KurzBez1)) {
            this.Model.KurzBez2 = this.Model.KurzBez1;
            this.Model.KurzBez3 = this.Model.KurzBez1;
            this.Model.KurzBez4 = this.Model.KurzBez1;
            this.Model.KurzBez5 = this.Model.KurzBez1;
            this.Model.KurzBez6 = this.Model.KurzBez1;
            this.Model.KurzBez7 = this.Model.KurzBez1;
        }

        if (data.field === nameof(c => c.StartBedingung_ID)) 
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.StartBedingungOutput), !!this.Model.StartBedingung_ID);
    }
    
    initFields() {
        this.Fields.push(this.formFieldService.CreateGroup('grp1', 'Workflow Definition'));

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.GUELTIGAB', true).setMDSizeHalf().setNullDateLow());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.GUELTIGBIS', true).setMDSizeHalf().setNullDateHigh());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.KURZBEZ1', true).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.BEZEICHNUNG1', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.KURZBEZ2', true).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.BEZEICHNUNG2', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.KURZBEZ3', true).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.BEZEICHNUNG3', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.KURZBEZ4', true).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.BEZEICHNUNG4', true).setMDSizeHalf());

        // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Typ), 'Typ', 'caf_statusworkflow_typ', true, true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonModel_ID), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.VONMODEL', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.EmailDef_ID), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.EMAILDEF', `model/representations/CAFEmailDef${this.ReadOnly ? '?id=$id' : ''}`, false, false, 'EmailDef wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.System), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.SYSTEM', 0, true).setMDSizeHalf().setReadonly(true));

        this.Fields.push(this.formFieldService.CreateGroup('grp2', 'Startbedingung'));

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.StartNachErfassen), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.STARTNACHERFASSEN', 'janein', true, true, this.ReadOnly).setMDSizeHalf());       
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.StartNachEditieren), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.STARTNACHEDITIEREN', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.StartBedingung_ID), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.STARTBEDINGUNG', `model/representations/CAFScriptDef${this.ReadOnly ? '?id=$id' : ''}`, false, false, 'StartBedingung wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.StartBedingungOutput), 'CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.STARTBEDINGUNGOUTPUT', false).setMDSizeHalf());

        //this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.StartTask_ID), 'StartTask', `model/representations/CAFTask${this.ReadOnly ? '?id=$id' : ''}`, true, false, 'StartTask wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());        
    }
    
    async getTaskMenuItems(): Promise<MenuItem[]> {
        var res = await super.getTaskMenuItems();

        if (this.ModelId && this.EnableTasks) {
            if (this.CRUDCanEdit)
                res.push({ label: 'Workflow Definition kopieren', icon: 'pi pi-clone', command: () => this.CopyStatusworkflowDef() });
        }
        
        return res;
    }

    async CopyStatusworkflowDef() {
        this.commonService.confirm({
            header: 'Workflow Definition',
            message: `Möchten Sie die Workflow Definition kopieren?`, 
            acceptButtonStyleClass: 'p-button-primary', 
            rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
            accept: async () => {
                const modelId = await this.apiService.get(`${this.getBaseUrl()}/copy/${this.ModelId}`);
                this.sessionController.openSession('statusworkflowdef-index', modelId);
            }
        }); 
    }
}
