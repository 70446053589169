<p-breadcrumb *ngIf="Page !== 'searcher'" [model]="getBreadcrumbs()" [home]="bcHome"></p-breadcrumb>

<div *ngIf="Page == 'searcher'">
    <avi-core-view-panel [toggleable]="false" [header]="Title | translate" class="w-100p" [enable-panel]="EnablePanel" panel-class="mt-2">
        <avi-core-base-searcher
            #searcher
            [settingskey]="SettingsKey"
            [search-delegate]="SearchDelegate"
            [field-names-gueltigab]="['GueltigAb']"
            [field-names-gueltigbis]="['GueltigBis']"
            (onInit)="onSearcherInit($event)"
            (onError)="onSearcherError($event)"
            [use-router-params]="false"
            (onDoubleClick)="onDoubleClickSearcher($event)"
            [rows]="50"
            [auto-search]="true"
            [show-autofilter]="true"
            [toolbar]="true"
            selectionMode="single"
            [toolbar-show-searchinput]="false"
            [toolbar-show-advanced]="false"
            [toolbar-show-columnselection]="false"
            [toolbar-show-inactivebutton]="true"
            [toolbar-show-generic-export]="true"
            [lazy-loading]="true"
            (onSelect)="onSelectionChanged($event)"
            [card]="!EnablePanel"
            [contents-padding]="EnablePanel"
            [searcher-title]="Title"
            [show-totalrecords]="false"
            [select-first-result]="false"
            [add-crud-menuitems]="true"
            [toolbar-show-createbutton]="true"
            crud-base-right="CAFScriptRulesetDef"
            (onCreateObject)="createObject()"
            (onEditObject)="editObject($event)"
            (onDeleteObject)="deleteObject($event)"
        >
        </avi-core-base-searcher>    
    </avi-core-view-panel>
</div>

<div *ngIf="Page == 'rulesetdef'">
    <avi-scriptrulesetdef-view
        [model-id]="DetailModelId"
        (onTestRulesetDef)="onTestRulesetDef($event)"
    >
    </avi-scriptrulesetdef-view>
</div>

<div *ngIf="Page == 'test'">
    <avi-scriptruleoutput-list
        [results]="RuleOutput"
    >
    </avi-scriptruleoutput-list>
</div>