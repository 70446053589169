<avi-core-view-panel header="Adressen">
    <avi-core-base-searcher
        #searcher
        [use-router-params]="false"
        search-delegate="adressedetail"
        settingskey="settings.searcher.adresse"
        [get-filters]="getFilters"
        [search-fields]="['Strasse', 'PLZ', 'Land.Bezeichnung1']"
        [field-names-gueltigab]="['vonAdresse.GueltigAb']"
        [field-names-gueltigbis]="['vonAdresse.GueltigBis']"
        [auto-search]="true"
        [show-autofilter]="false"
        [toolbar]="true"
        [toolbar-show-searchinput]="true"
        [card]="false"
        (onInit)="onInit($event)"
        (onSelect)="onSelect($event)"
        searcher-title="Adresse"
        [row-detailview-template]="rowdetailview"
    >
    </avi-core-base-searcher>
</avi-core-view-panel>

<ng-template #rowdetailview let-record>
    <avi-crm-adresse-liste-detailview
        [adresseId]="record.vonAdresse_ID"
    ></avi-crm-adresse-liste-detailview>
</ng-template>

<avi-core-view-panel *ngIf="DetailModelId" header="Details">
    <avi-crm-adresse-form
        #form
        [card]="false"
        [readonly]="true"
        [model-id]="DetailModelId"
    >
    </avi-crm-adresse-form>
</avi-core-view-panel>
