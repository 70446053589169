import { Expose, Type } from 'class-transformer';

import { TechRole } from '../system/techrole.model';
import { AppUser } from '../system/appuser.model';
import { BaseModel } from '../shared/basemodel.model';

export class ActivityQueue extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get ReadRole_ID(): string { return this.getModelId('ReadRole_ID'); }
    public set ReadRole_ID(value: string) { this.setModelId('ReadRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get rReadRole(): TechRole { return this.getModel('rReadRole'); }
    public set rReadRole(value: TechRole) { this.setModel('rReadRole', value); }
    
    @Expose()
    public get DefaultOwner_ID(): string { return this.getModelId('DefaultOwner_ID'); }
    public set DefaultOwner_ID(value: string) { this.setModelId('DefaultOwner_ID', value); }
 
    @Expose()
    @Type(() => AppUser)
    public get DefaultOwner(): AppUser { return this.getModel('DefaultOwner'); }
    public set DefaultOwner(value: AppUser) { this.setModel('DefaultOwner', value); }
    
}
