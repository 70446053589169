import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService, AviCanDeactivateService } from '@avi-x/avi-core';
import { AviInfoDeskComponent } from './components/infodesk-exec/infodesk-exec.component';

const routes: Routes = [
    { path: 'infodesk/exec/:infodeskid', component: AviInfoDeskComponent, canDeactivate: [AviCanDeactivateService], canActivate: [AviCanActivateWhenLoggedInService] },
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviInfoDeskExecutionRoutingModule {
}
