import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class AppUserGroupLogEntrySearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
	public Hintergrund: string;

    @Expose()
	public GroupsAdded: string;

    @Expose()
	public GroupsRemoved: string;

    @Expose()
	public RolesAdded: string;

    @Expose()
	public RolesRemoved: string;

	@Expose()
    @Type(() => Date)
    public ErstellDatum: Date;

    @Expose()
    public ErstellUser: string;
}
