import { Expose, Type } from 'class-transformer';

import { Land } from '../partner/land.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class SteuernummerDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get Sorter(): number { return this.getProperty('Sorter'); }
    public set Sorter(value: number) { this.setProperty('Sorter', value); }
    
    @Expose()
    public get Mask1(): string { return this.getProperty('Mask1'); }
    public set Mask1(value: string) { this.setProperty('Mask1', value); }
    
    @Expose()
    public get Mask2(): string { return this.getProperty('Mask2'); }
    public set Mask2(value: string) { this.setProperty('Mask2', value); }
    
    @Expose()
    public get Mask3(): string { return this.getProperty('Mask3'); }
    public set Mask3(value: string) { this.setProperty('Mask3', value); }
    
    @Expose()
    public get vonLand_ID(): string { return this.getModelId('vonLand_ID'); }
    public set vonLand_ID(value: string) { this.setModelId('vonLand_ID', value); }
 
    @Expose()
    @Type(() => Land)
    public get vonLand(): Land { return this.getModel('vonLand'); }
    public set vonLand(value: Land) { this.setModel('vonLand', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Verwendung(): ListType { return this.getProperty('Verwendung'); }
    public set Verwendung(value: ListType) { this.setProperty('Verwendung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get IstUnbekannt(): ListType { return this.getProperty('IstUnbekannt'); }
    public set IstUnbekannt(value: ListType) { this.setProperty('IstUnbekannt', value); }
    
    @Expose()
    @Type(() => ListType)
    public get SponsorPossible(): ListType { return this.getProperty('SponsorPossible'); }
    public set SponsorPossible(value: ListType) { this.setProperty('SponsorPossible', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AIARelevant(): ListType { return this.getProperty('AIARelevant'); }
    public set AIARelevant(value: ListType) { this.setProperty('AIARelevant', value); }
    
    @Expose()
    @Type(() => ListType)
    public get FatcaRelevant(): ListType { return this.getProperty('FatcaRelevant'); }
    public set FatcaRelevant(value: ListType) { this.setProperty('FatcaRelevant', value); }
    
}
