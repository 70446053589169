import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AviApiService, AviBaseFormComponent, AviBaseSearcherComponent, AviCommonService, AviDialogComponent, AviListDetailConst } from '@avi-x/avi-core';
import { VwbPAdminXMLImportJobDetailsComponent } from '../xmlimport-details/xmlimport-details.component';


@Component({
    selector: 'vwbp-admin-xmlimportjob-searcher',
    templateUrl: './xmlimport-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminXMLImportJobSearcherComponent implements OnInit {

    public Loading: boolean = false;

    FormModelId: string = null;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('jobSearcher', { static: true })
    private jobSearcher: AviBaseSearcherComponent;

    @ViewChild('jobDialog', { static: false })
    jobDialog: AviDialogComponent;

    @ViewChild('jobView', { static: false })
    jobView: VwbPAdminXMLImportJobDetailsComponent;

    public Card = true;

    private SelectedRows: any[] = [];
    public JobId: string = null;

    constructor(
        private commonService: AviCommonService,
        private apiService: AviApiService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
    }

    public JobSearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get(`v1/intern/vwbp/job/xmlimport`);

        res = this.jobSearcher.ApplyColumnFilters(res);
        res = this.jobSearcher.ApplyColumnSorting(res);

        return res;
    }

    onJobSearcherInit(data) {
        this.jobSearcher.beginInit();

        this.jobSearcher.addDateColumn('ErstellDatum', 'Datum', 'short', true, '120px').setSortable(false);
        this.jobSearcher.addTextColumn('ErstellUser', 'Sachbearbeiter', true).setSortable(false);
        this.jobSearcher.addTextColumn('Status', 'Status', true).setSortable(false);

        this.jobSearcher.endInit();
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    async CopyToClipboard() {
        const res = await this.apiService.get(`v1/intern/vwbp/job/log/${this.JobId}`);
        this.copyText(res.map(w => w.LogMessage).join('\n'));
    }

    copyText(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    async onDoubleClickSearcher(data) {
        if (data.StatusId === AviListDetailConst.JOB_STATUS_FAILED) {
            try {
                this.JobId = data.Id;
                this.commonService.showGlobalLoader();
                this.jobView.initView(this.JobId);
            } finally {
                this.commonService.hideGlobalLoader();
            }

            this.jobDialog.open();
        }
    }

    CloseRTView() {
        this.jobDialog.close();
        //        this.jobView.initView(null);
        this.cdr.detectChanges();
    }
}
