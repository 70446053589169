import { Component, OnInit, ViewChild, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar } from 'primeng/calendar';

@Component({
    selector: 'avi-core-calendar',
    templateUrl: './calendar.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviCalendarComponent),
            multi: true,
        },
    ],
})
export class AviCalendarComponent implements ControlValueAccessor, OnInit {
    // public calendar1: FormControl;

    @ViewChild(Calendar, { static: true }) public _calendar: Calendar;

    @Input() calId: string;
    @Input() baseZIndex: number = 0;
    @Input() required: boolean;
    @Input() style: any;
    @Input() disabled: boolean;
    @Input() placeholder: string;
    @Input() tabindex: number;
    @Input() dateFormat: string = 'dd.mm.yy';
    @Input() dataType: 'date' | 'string' = 'date';
    @Input() dateView : 'date' | 'month' | 'year' = 'date';
    @Input() selectionMode: string = 'single';
    @Input() defaultDate: Date;
    @Input() showIcon: boolean = true;
    @Input() readonlyInput: boolean = false;
    @Input() showOnFocus: boolean = true;
    @Input() showTime: boolean = false;
    @Input() firstDayOfWeek: number = 1;
    @Input() name: string;
    @Input() minDate: Date;
    @Input() maxDate: Date;
    @Input() readonly: boolean;
    @Input() timeOnly: boolean;
    @Input() inputStyle: any;
    @Input() selectOtherMonths: boolean;

    @Input() monthNavigator: boolean;
    @Input() showButtonBar: boolean;

    @Output() onInput: EventEmitter<any> = new EventEmitter();
    @Output() onFocus: EventEmitter<any> = new EventEmitter();
    @Output() onSelect: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();
    @Output() onClickOutside: EventEmitter<any> = new EventEmitter();

    @Input() nullDate: Date = null; // für lowdate/highdate

    private _value: string;
    public get value(): string {
        return this._value;
    }
    public set value(value: string) {
        if (value !== this._value ) {
            this._value = value;
            if (this.onModelChange)
                this.onModelChange(value);
        }
    }

    constructor() {}

    focus() {
        if (this._calendar?.inputfieldViewChild?.nativeElement)
            this._calendar.inputfieldViewChild.nativeElement.focus();
    }

    ngOnInit() {
        // this.calendar1 = new FormControl();

        if (this._calendar) {
            const origParseValueFromString = this._calendar.parseValueFromString;
            this._calendar.parseValueFromString = (text: string): Date | Date[] => {
                if (!text || text.trim().length === 0) return this.nullDate;
                else {
                    if (!text || text.trim().length === 0) return null;

                    if (this._calendar.isRangeSelection()) {
                        const tokens = text.split(this._calendar.rangeSeparator);
                        const value = [];
                        for (let i = 0; i < tokens.length; i++)
                            value[i] = this._calendar.parseDateTime(tokens[i].trim());

                        return value;
                    } else return origParseValueFromString.call(this._calendar, text);
                }
            };

            const origFormatDateTime = this._calendar.formatDateTime;
            this._calendar.formatDateTime = (date) => {
                if (this.nullDate && date && date.getTime() === this.nullDate.getTime()) return '';
                else return origFormatDateTime.call(this._calendar, date);
            };
        }
    }

    writeValue(obj: any): void {
        if (obj !== this._value) {
            this._value = obj;
        }
    }

    onModelChange: (val) => {};
    onModelTouch: () => {};
    registerOnChange(fn: any): void {
        this.onModelChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onModelTouch = fn;
    }
}
