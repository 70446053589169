import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviAuthService, AviCommonService, OAuthConfigService } from '@avi-x/avi-core';
import { OAuthService } from 'angular-oauth2-oidc';
import { VwbPCommonService } from '../../../common/vwbp-common.service';

@Component({
    selector: 'vwbp-public-user-login',
    templateUrl: './user-login.component.html',
    styleUrls: ['./user-login.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPPublicUserLoginComponent implements OnInit {
    public config: any = null;
    public initError = null;

    constructor(
        private apiService: AviApiService,
        private commonService: AviCommonService,
        private authService: AviAuthService,
        private oauthService: OAuthService,
        public oauthConfigService: OAuthConfigService,
        private vwbpCommonService: VwbPCommonService,
        private router: Router,
    ) { }

    async ngOnInit() {
        if (this.oauthService.hasValidAccessToken()) {
            if (this.vwbpCommonService.currentUserIsAjuUser())
                this.router.navigate(['/intern/vwbp/index']);
            else if (this.vwbpCommonService.currentUserIsBehoerdenUser())
                this.router.navigate(['/intern/vwbp/behoerden']);
            else if (this.vwbpCommonService.currentUserIsAdminUser())
                this.router.navigate(['/admin/vwbp']);
            else
                this.router.navigate(['/vwbp/index']);
            return;
        } else {
            console.log('noch kein hasValidAccessToken');
        }

        try {
            this.initError = '';
            this.config = await this.apiService.get('v1/vwbp/public/config', null, false, false);
            console.log(this.config);
            if (this.config && this.config.is_internal !== true)
                this.initPublicLogin();
        } catch (err) {
            console.error('API ERR: ', err);
            if (err instanceof AviApiErrorObject && err.ErrorMessage === 'SERVER_NOT_REACHABLE') {
                this.initError = 'VWBP.PUBLIC.API_NICHT_ERREICHBAR';
            } else {
                throw err;
            }
        }
    }


    public testAuthData() {
        if (!this.oauthService.hasValidAccessToken()) {
            console.log('Nicht angemeldet... ');
            return;
        }


        const claims = this.oauthService.getIdentityClaims();
        // const idToken = this.oauthService.getIdToken();
        // console.log("idToken", idToken);

        this.oauthService.loadUserProfile().then(r => {
            console.log('loadUserProfile: ', r);
        });

        // this.oauthService.getIdToken
    }

    public initPublicLogin() {
        // this.oauthService.initImplicitFlow();
        this.oauthConfigService.SaveLastUsedAuthConfig('public');
        this.initLogin();
    }

    public initInternalLogin() {
        this.oauthConfigService.SaveLastUsedAuthConfig('internal');
        this.initLogin();
    }

    private initLogin() {
        this.oauthService.configure(this.oauthConfigService.GetLastUsedAuthConfig());
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(isLoggedIn => {
            // console.log("loadDiscoveryDocumentAndTryLogin done", isLoggedIn, intConfig);
            this.oauthService.initLoginFlow();
        });
    }
}
