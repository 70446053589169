import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { JobDef } from '@avi-x/avi-dto/system/jobdef.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AviAbstractModelFormComponent } from '../base-form/abstract-model-form.component';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviFormFieldService } from '../../services/form-field.service';
import { AviSessionControllerService } from '../../services/session-controller.service';
import { typed_nameof } from '../utils/nameof';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AviRoleConst } from '../../shared/constants/constants';
import { AviJobService } from '../../services/job.service';

const nameof = (nameFunction: ((obj: JobDef) => any)) => typed_nameof<JobDef>(nameFunction);

@Component({
    selector: 'avi-jobdef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJobDefFormComponent extends AviAbstractModelFormComponent<JobDef> {
    ClassName = 'CORE.COMMON.MODEL.JOBDEF';
    BaseUrl = 'job/def';
    ClassType = JobDef;
	FormType = AviJobDefFormComponent;

    CanStartJob:boolean = false;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public translateService: TranslateService,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        private jobService: AviJobService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'JobDef';
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.BaseUrlPrefix = this.jobService.ApiPrefix;
        this.permissionsService.hasPermission([AviRoleConst.JOB_STARTEN]).then(w => this.CanStartJob = w);
    }

    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.JOBDEF.KURZBEZ1', true).setReadonly().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.JOBDEF.BEZEICHNUNG1', true).setReadonly().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'CORE.COMMON.MODEL.JOBDEF.KURZBEZ2', true).setReadonly().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.JOBDEF.BEZEICHNUNG2', true).setReadonly().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'CORE.COMMON.MODEL.JOBDEF.KURZBEZ3', true).setReadonly().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.JOBDEF.BEZEICHNUNG3', true).setReadonly().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'CORE.COMMON.MODEL.JOBDEF.KURZBEZ4', true).setReadonly().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.JOBDEF.BEZEICHNUNG4', true).setReadonly().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez5), 'CORE.COMMON.MODEL.JOBDEF.KURZBEZ5', true).setReadonly().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung5), 'CORE.COMMON.MODEL.JOBDEF.BEZEICHNUNG5', true).setReadonly().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez6), 'CORE.COMMON.MODEL.JOBDEF.KURZBEZ6', true).setReadonly().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung6), 'CORE.COMMON.MODEL.JOBDEF.BEZEICHNUNG6', true).setReadonly().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez7), 'CORE.COMMON.MODEL.JOBDEF.KURZBEZ7', true).setReadonly().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung7), 'CORE.COMMON.MODEL.JOBDEF.BEZEICHNUNG7', true).setReadonly().setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.JobType), 'CORE.COMMON.MODEL.JOBDEF.JOBTYPE', 'jobdef_jobtype', true, true, this.ReadOnly, this.BaseUrlPrefix).setReadonly().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.JobClass), 'CORE.COMMON.MODEL.JOBDEF.JOBCLASS', true).setReadonly().setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.EventLogLevel), 'CORE.COMMON.MODEL.JOBDEF.EVENTLOGLEVEL', 'caf_eventlog_level', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.EventLogPrio), 'CORE.COMMON.MODEL.JOBDEF.EVENTLOGPRIO', 'caf_eventlog_prioritaet', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonTechRole_ID), 'CORE.COMMON.MODEL.JOBDEF.VONTECHROLE', `model/representations/TechRole${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.UebersichtsReport_ID), 'CORE.COMMON.MODEL.JOBDEF.UEBERSICHTSREPORT', `model/representations/ReportDef${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.JobSequenzName), 'CORE.COMMON.MODEL.JOBDEF.JOBSEQUENZNAME', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateEmpty('empty1').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Beschreibung), 'CORE.COMMON.MODEL.JOBDEF.BESCHREIBUNG', false).setReadonly().setMDSizeFull());
    }

    async getTaskMenuItems(): Promise<MenuItem[]> {
        var res = await super.getTaskMenuItems();

        if (this.ModelId && this.EnableTasks) {
            if (this.CanStartJob)
                res.push({ label: 'Job starten', icon: 'pi pi-play', command: () => this.doJobStart() });
        }

        return res;
    }

    async doJobStart() {
        await this.jobService.startJob(this.Model.Id, this.translateService.instant('CORE.COMMON.JOB_AUSFUEHREN_TITLE', { name: this.Model.Bezeichnung1 }), null);
    }
}
