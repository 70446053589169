import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AviApiService, AviBaseFormComponent, AviBaseSearcherComponent, AviCommonService, AviDialogComponent } from '@avi-x/avi-core';
import { VwbPAdminKeywordDefFormComponent } from '../keyworddef-form/keyworddef-form.component';


@Component({
    selector: 'vwbp-admin-keyworddef-searcher',
    templateUrl: './keyworddef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminKeywordDefSearcherComponent implements OnInit {

    public Loading: boolean = false;

    FormModelId: string = null;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @ViewChild('keywordDefFormDialog', { static: false })
    keywordDefFormDialog: AviDialogComponent;

    @ViewChild('keywordDefForm', { static: false })
    public keywordDefForm: VwbPAdminKeywordDefFormComponent;

    public Card = true;

    private SelectedRows: any[] = [];
    public DetailModelId: string = null;

    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];

    constructor(
        private commonService: AviCommonService,
        private apiService: AviApiService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {

    }

    async onSelectionChanged(data: any[]) {
        this.SelectedRows = data;

        if (this.SelectedRows.length > 0) {
            this.DetailModelId = this.SelectedRows[0].Id;
        }
    }

    onSearcherInit() {
        this.searcher.beginInit();
        this.searcher.addTextColumn('Keyword', 'Keyword');
        this.searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get('v1/intern/vwbp/comment/keyworddefs');

        res = this.searcher.ApplyColumnFilters(res);
        res = this.searcher.ApplyColumnSorting(res);
        return res;
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    onDoubleClickSearcher(data) {
        this.editKeywordDef();
    }


    public createKeywordDef() {
        this.FormModelId = '0';
        this.keywordDefFormDialog.open();
    }

    public editKeywordDef() {
        if (this.SelectedRows.length > 0 && this.SelectedRows[0]) {
            this.FormModelId = this.SelectedRows[0].Id;
            this.keywordDefFormDialog.open();
        }
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.searcher.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.keywordDefFormDialog.close();
    }

    public deleteKeywordDef() {

        if (this.SelectedRows.length > 0 && this.SelectedRows[0]) {
            const id = this.SelectedRows[0].Id;

            this.commonService.confirmDelete(`Möchten Sie die Keyword-Definition wirklich löschen?`,
                () => {
                    this.apiService.delete(`v1/intern/vwbp/comment/keyworddef/${id}`).then(r => {
                        this.commonService.notificateSuccess('Gelöscht');
                        this.searcher.forceRefresh();
                    });
                }
            );
        }
    }
}
