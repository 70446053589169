import { ChangeDetectorRef, Component } from '@angular/core';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternUnstimmigkeitService } from '../../services/unstimmigkeit.service';
import { VwbPInternUnstimmigkeitBaseComponent } from './unstimmigkeit-base.component';

@Component({
    selector: 'vwbp-intern-unstimmigkeit-bussfrist-abgelaufen',
    template: `
        <mat-tab-group class="mat-tab-tertiary" mat-align-tabs="center" animationDuration="0ms" (selectedTabChange)="setPausiert($event)" [selectedIndex]="getSelectedIndex()">
          <mat-tab label="Aktiv" ></mat-tab>
          <mat-tab label="Pausiert"></mat-tab>
        </mat-tab-group>

        <vwbp-intern-unstimmigkeit-busse-searcher #searcher settingskey="settings.searcher.um.bussfrist-abgelaufen" [show-aktiv]="true" [get-filters]="GetFilters" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'Version', 'Bussfrist', 'BussCount', 'btnOpen']">
        </vwbp-intern-unstimmigkeit-busse-searcher>

        <div *ngIf="currentUserIsAjuUserSchreiben()" fxLayout="row">
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="start left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-unstimmigkeit-erneut-bussen-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-unstimmigkeit-erneut-bussen-task>
            </div>
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-unstimmigkeit-amtliche-aufloesung-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-unstimmigkeit-amtliche-aufloesung-task>
            </div>
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-unstimmigkeit-pausieren-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-unstimmigkeit-pausieren-task>
            </div>
            <div *ngIf="!GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-unstimmigkeit-aktivieren-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-unstimmigkeit-aktivieren-task>
            </div>
        </div>
        `
})
export class VwbPInternUnstimmigkeitBussfristAbgelaufenComponent extends VwbPInternUnstimmigkeitBaseComponent {
    constructor(public commonService: AviCommonService, public unstimmigkeitService: VwbPInternUnstimmigkeitService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, unstimmigkeitService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters = (): string[] => {
        return [
            `BusseStatus eq ${VwbPListDetailConst.VWBP_BUSS_STATUS_BUSSFRIST_ABGELAUFEN}`,
            `UnstimmigkeitMahnwesenAktiv eq ${this.GetAktivValue() ? VwbPListDetailConst.VWBP_MAHNWESEN_AKTIV : VwbPListDetailConst.VWBP_MAHNWESEN_PAUSIERT}`
        ];
    }
}
