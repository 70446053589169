import { AfterContentChecked, Component, ContentChild, ElementRef, TemplateRef } from '@angular/core';

@Component({
    selector: 'avi-core-lazy-loader',
    template: `
        <ng-container></ng-container>
        <ng-container [ngTemplateOutlet]="contentTemplate" *ngIf="loadContent"></ng-container>
    `
})
export class AviLazyLoaderComponent implements AfterContentChecked {
    constructor(private elRef: ElementRef) {}

    @ContentChild('content', { static: true })
    contentTemplate: TemplateRef<ElementRef>;

    loadContent: boolean;
    ngAfterContentChecked() {
        if (this.elRef.nativeElement.offsetParent) {
            this.loadContent = true;
        }
    }
}
