import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { JobParamSearchResponseDto } from '@avi-x/avi-dto/system/jobparamsearchresponsedto.model'
import { AviJobParamFormComponent } from './jobparam-form.component';
import { typed_nameof } from '../utils/nameof';
import { AviAbstractModelSearcherComponent } from '../base-searcher/abstract-model-searcher.component';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviSessionControllerService } from '../../services/session-controller.service';
import { AviBaseSearcherComponent } from '../base-searcher/base-searcher.component';
import { AviSearcherColumn } from '../base-searcher/searcher-column';
import { AviListDetailConst } from '../../shared/constants/constants';
import { AviJobService } from '../../services/job.service';

const nameof = (nameFunction: ((obj: JobParamSearchResponseDto) => any)) => typed_nameof<JobParamSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-jobparam-searcher',
    templateUrl: './jobparam-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJobParamSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.JOBPARAM';
    SettingsKey = 'settings.searcher.jobparam';
    BaseUrl = 'job/param';
    FormType = AviJobParamFormComponent;
    ApiPrefix = '';

    CanEdit: boolean = false;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef,
        public jobService: AviJobService
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('JobParam schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        // if (this.SelectedRows) {
        //     this.sessionController.openSession('jobparam-index', this.SelectedRows.Id);
        //     this.editObject(this.SelectedRows);
        // }
    }

    ngOnInit(): void {
        this.ApiPrefix = this.jobService.ApiPrefix;
        super.ngOnInit();
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();

        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.JOBPARAM.BEZEICHNUNG', true);

        this.searcher.addColumn(
            AviSearcherColumn.CreateCustom(
                '',
                'CORE.COMMON.MODEL.JOBPARAM.VALUE',
                (row, col) => {
                    let paramType = row[nameof(c => c.ParamType)];
                    if (paramType.Id == AviListDetailConst.ATTRTYPE_INTEGER)
                        return row[nameof(c => c.ValueInt)]+"";
                    if (paramType.Id == AviListDetailConst.ATTRTYPE_DECIMAL)
                        return this.commonService.formatNumber(row[nameof(c => c.ValueDec)], null, 2);
                    if (paramType.Id == AviListDetailConst.ATTRTYPE_DATE)
                        return this.commonService.formatDate(row[nameof(c => c.ValueDate)]);
                    if (paramType.Id == AviListDetailConst.ATTRTYPE_REFERENCE)
                        return row[nameof(c => c.ValueRefRepresentation)];
                    else 
                        return row[nameof(c => c.ValueText)];
                    },
                true
            ));
        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(JobParamSearchResponseDto, `${this.getApiPrefix()}${this.BaseUrl}/search`, cfg);
        return res;
    }

    private getApiPrefix() {
        var url = '';
        if (this.ApiPrefix) {
            url = this.ApiPrefix;
            if (!url.endsWith('/'))
                url = url + '/'
        }
        return url;
    }
}
