import { Expose, Type } from 'class-transformer';

import { DocuMatrixTemplate } from '../documatrix/documatrixtemplate.model';
import { DocPropContainer } from '../documatrix/docpropcontainer.model';
import { BaseModel } from '../shared/basemodel.model';

export class DocPropContainerTemplateLink extends BaseModel {  
    @Expose()
    public get vonTemplate_ID(): string { return this.getModelId('vonTemplate_ID'); }
    public set vonTemplate_ID(value: string) { this.setModelId('vonTemplate_ID', value); }
 
    @Expose()
    @Type(() => DocuMatrixTemplate)
    public get vonTemplate(): DocuMatrixTemplate { return this.getModel('vonTemplate'); }
    public set vonTemplate(value: DocuMatrixTemplate) { this.setModel('vonTemplate', value); }
    
    @Expose()
    public get vonDocPropContainer_ID(): string { return this.getModelId('vonDocPropContainer_ID'); }
    public set vonDocPropContainer_ID(value: string) { this.setModelId('vonDocPropContainer_ID', value); }
 
    @Expose()
    @Type(() => DocPropContainer)
    public get vonDocPropContainer(): DocPropContainer { return this.getModel('vonDocPropContainer'); }
    public set vonDocPropContainer(value: DocPropContainer) { this.setModel('vonDocPropContainer', value); }
    
}
