<avi-core-page *ngIf="currentStep"
    [collapsible]="false"
    [card]="false"
    [contents-padding]="false"
>
    <p-steps [model]="steps" [activeIndex]="currentStep-1" [readonly]="true">
    </p-steps>

    <div *ngIf="currentStep == 1">
            <div class="grid pt-3">
                <div class="col-12 md:col-6">
                    <avi-core-base-form #form
                        [readonly]="false"
                        [card]="false"
                        [fields]="Fields"
                        form-title="Serienbrief Einstellungen"
                        [Model]="SerienbriefSettings"
                        (onAttrChange)="onSerienbriefChange($event)"
                        [show-buttons]="false">

                    </avi-core-base-form>
                </div>

                <div class="col-12 md:col-6">

                    <div class="vh-70" [ngSwitch]="Previewer">
                        <avi-core-pdf-viewer *ngSwitchCase="PreviewType.PDF" height="100%" [src]="Preview"></avi-core-pdf-viewer>

                        <code *ngSwitchCase="PreviewType.CODE" class="preview-area" [highlight]="Preview"></code>

                        <code *ngSwitchCase="PreviewType.TEXT" class="preview-area">
                            <div class="text-preview-area">{{ Preview }}</div>
                        </code>

                        <div *ngSwitchCase="PreviewType.IMAGE" class="preview-area">
                            <img class="image-preview-area" [src]="Preview" />
                        </div>

                        <div *ngSwitchCase="PreviewType.UNKNOWN" class="preview-area" style="background: #fff; color: #aaa;">
                            Bitte Dokument auswählen
                        </div>
                    </div>
                </div>
            </div>
    </div>

    <div *ngIf="currentStep == 2">
        <div class="pt-3">
            <vwbp-intern-rechtstraeger-adresse-searcher
                *ngIf="modelIds"
                [Mode]="mode"
                [ModelIds]="modelIds"
                (onSelect)="onRTSelectionChanged($event)"
            ></vwbp-intern-rechtstraeger-adresse-searcher>
        </div>
    </div>

    <div class="ui-g-12 ui-md-12 pt-3 pull-right">
        <button pButton [disabled]="currentStep == 1" type="button" class="p-button-secondary mr-2" label="Zurück" (click)="prevPage()" icon="pi pi-angle-left"></button>
        <button pButton [disabled]="currentStep == 2 || selectedVorlage == null" type="button" class="p-button-primary mr-2" label="Weiter" (click)="nextPage()" icon="pi pi-angle-right" iconPos="right"></button>
        <button pButton [disabled]="currentStep == 1 || selectedVorlage == null || SerienbriefSettings.Format == null ||  SerienbriefSettings.DigitalSignieren == null || selectedRT?.length == 0" type="button" class="p-button-success mr-4p" label="Serienbrief erstellen" (click)="Close()" icon="pi pi-save"></button>

        <button pButton type="button" class="p-button-secondary" icon="pi pi-times" (click)="Abbrechen()" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" [title]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"></button>
    </div>

</avi-core-page>
