import { ChangeDetectorRef, Component } from '@angular/core';
import { AviApiService, AviCommonService, AviJobService, AviListDetailConst, AviTypeUtil, InputmaskService } from '@avi-x/avi-core';
import { VwbPInternSchwaerzenService } from '../../services/schwaerzen.service';
import { VwbPInternTaskBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-tasks/task-base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';

import * as moment from 'moment-timezone';

@Component({
    selector: 'vwbp-intern-schwaerzen-antrag-rechtskraft-setzen-task',
    template: `
      <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
            {{ 'VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSKRAFT_BUTTON' | translate}}
        </div>
        <div class="grid form-group">
            <div class="col-12">
                <span class="p-float-label mb-4p">
                    <p-calendar #calendar2 id="cal2" appendTo="body" [baseZIndex]="10000" [firstDayOfWeek]="1" (onInput)="onInputDate($event, calendar2)" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [ngModel]="Datum" (ngModelChange) ="onInputEntscheidungAm($event);" [selectOtherMonths]="true" dateFormat="dd.mm.yy" [readonlyInput]="false" [showOnFocus]="false" [showIcon]="true"></p-calendar>
                    <label for="cal2">{{ 'VWBP.INTERN.SCHWAERZEN.RECHTSKRAFT_SETZEN' | translate }}*</label>
                </span>
                <span [hidden]="!RechtskraftZukunft" class="ui-message ui-messages-error ui-corner-all nopad-force">Das Rechtskraftdatum darf nicht in der Zukunft gesetzt werden</span>

                <span class="p-float-label mb-4p">
                    <p-calendar #calendar4 id="cal4" appendTo="body" [disabled]="!GueltigBisEnabled" [readonlyInput]="!GueltigBisEnabled" [showIcon]="GueltigBisEnabled"  [tabindex]="!GueltigBisEnabled ? -1 : 0" [baseZIndex]="10000" [firstDayOfWeek]="1" (onInput)="onInputDate($event, calendar4)" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="DatumBis" [selectOtherMonths]="true" dateFormat="dd.mm.yy" [readonlyInput]="false" [showOnFocus]="false" [showIcon]="true"></p-calendar>
                    <label for="cal4">{{ 'VWBP.INTERN.SCHWAERZEN.GUELTIGBIS' | translate }}{{ GueltigBisEnabled ? '*' : '' }}</label>
                </span>
                <span [hidden]="KannGueltigBisErmitteln" class="ui-message ui-messages-error ui-corner-all nopad-force">Antrag gültig bis kann nicht automatisch ermittelt werden, da Anträge mit unterschiedlichen Begründungen gewählt wurden</span>
                <span [hidden]="!GueltigBisVorRechtskraft" class="ui-message ui-messages-error ui-corner-all nopad-force">Antrag gültig bis kann nicht vor das Rechtskraftdatum liegen</span>

                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ 'VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSKRAFT_KOMMENTAR' | translate }}*</label>
                </span>
            </div>
            <div *ngIf="Dialog && HasDoku" class="col-12 lg:col-12">
                <p-checkbox [(ngModel)]="DocuGenerieren" label="{{ 'VWBP.INTERN.DOKUMENTE.GENERIEREN' | translate }}" name="doc" [binary]="true"></p-checkbox>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-star-fill" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-primary"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternSchwaerzenAntragRechtskraftSetzenTaskComponent extends VwbPInternTaskBaseComponent {
    KannGueltigBisErmitteln: boolean = true;
    GueltigBisVorRechtskraft: boolean = false;
    GueltigBisEnabled: boolean = true;
    RechtskraftZukunft = false;

    constructor(public postfachService: VwbPInternPostfachService, public jobService: AviJobService, public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public schwaerzenService: VwbPInternSchwaerzenService, public inputMaskService: InputmaskService, public dialogService: DialogService, public cdr: ChangeDetectorRef) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);
    }

    ngOnInit() {
        this.InitDoku(VwbPListDetailConst.VWBP_VORLAGE_TASK_SCHWAERZEN_RECHTSKRAFT);

        this.OnSelectionChanged.subscribe(value => {
            this.tryCalcGueltigBis();

            if (!this.KannGueltigBisErmitteln)
                this.DatumBis = null;
        });
    }

    public onInputEntscheidungAm(event): void {
        this.Datum = event;
        this.tryCalcGueltigBis();
    }

    private tryCalcGueltigBis() {
        if (this.SelectedRechtstraeger.length > 0) {
            const begruendung = this.SelectedRechtstraeger[0].AntragBegruendung.Id;
            const gueltigBis = this.SelectedRechtstraeger[0].AntragGueltigBis;

            const hasBewilligt = this.SelectedRechtstraeger.some(w => w.AntragEntscheidung.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_ENTSCHEIDUNG_BEWILLIGT);
            const allesAbgelehnt = this.SelectedRechtstraeger.every(w => w.AntragEntscheidung.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_ENTSCHEIDUNG_ABGEWIESEN);

            const bewilligteRT = this.SelectedRechtstraeger.filter(w => w.AntragEntscheidung.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_ENTSCHEIDUNG_BEWILLIGT);

            if (hasBewilligt) {
                if (begruendung === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_BEGRUENDUNG_MINDERJAEHRIGKEIT) {
                    this.KannGueltigBisErmitteln = bewilligteRT.every(w => w.AntragBegruendung.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_BEGRUENDUNG_MINDERJAEHRIGKEIT && w.AntragGueltigBis === gueltigBis);

                    if (this.KannGueltigBisErmitteln && !this.DatumBis)
                        this.DatumBis = new Date(gueltigBis);
                } else {
                    this.KannGueltigBisErmitteln = bewilligteRT.every(w => w.AntragBegruendung.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_BEGRUENDUNG_MINDERJAEHRIGKEIT);

                    if (this.KannGueltigBisErmitteln && !!this.Datum) {
                        this.DatumBis = moment(this.Datum).add(5, 'year').toDate();
                    }
                }
            } else if (allesAbgelehnt) {
                this.KannGueltigBisErmitteln = true;
                this.DatumBis = null;
            }

            this.GueltigBisEnabled = !allesAbgelehnt;
        } else {
            this.KannGueltigBisErmitteln = true;
            this.Frist = null;
            this.DatumBis = null;
            this.GueltigBisEnabled = true;
        }
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + this.commonService.translateInstant('VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSKRAFT_BUTTON');
    }

    CanExecute(): boolean {
        if (this.SelectedRechtstraeger.length > 0 && !!this.Datum)
            this.RechtskraftZukunft = this.Datum > this.commonService.getDate00();
        else
            this.RechtskraftZukunft = false;

        this.GueltigBisVorRechtskraft = this.SelectedRechtstraeger.length > 0 && !!this.Datum && !!this.DatumBis && this.DatumBis < this.Datum;

        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung && !!this.Datum && (!!this.DatumBis || !this.GueltigBisEnabled) && !this.RechtskraftZukunft && !this.GueltigBisVorRechtskraft;
    }

    async Execute() {
        this.PerformAction('VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSKRAFT_BUTTON', this.GetResourceName('VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSKRAFT_BESTAETIGEN'), 'VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSKRAFT_BUTTON',
            async (selectedRT) => {
                const jobId: string = await this.schwaerzenService.SetAntragRechtskraft(selectedRT.map(w => w.AntragId), this.Datum, this.DatumBis, this.DocuGenerieren, this.Bemerkung);
                if (jobId) {
                    const jobStatus = await this.jobService.OpenJobDialog(jobId, 'VWBP.INTERN.SCHWAERZEN.ANTRAG_RECHTSKRAFT_BUTTON', 'v1/intern/vwbp/job', false);
                    if (jobStatus !== AviListDetailConst.JOB_STATUS_SUCCEEDED)
                        return;
                }
                if (this.HasDoku && this.DocuGenerieren)
                    this.postfachService.OpenPostfach();
            }
        );
    }
}
