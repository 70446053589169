<div class="card-contents">
    <p-panel
        header="{{ (WidgetTitle || 'VWBP.KONTAKTINFO.KONTAKTINFORMATIONEN') | translate }}"
        [toggleable]="Toggleable"
        [collapsed]="ForceCollapsed"
        expandIcon="pi pi-chevron-down"
        collapseIcon="pi pi-chevron-up"
        toggler="header"
    >
        <avi-core-base-form
            #form
            [readonly]="true"
            [card]="false"
            [contents-padding]="false"
            [fields]="Fields"
            [Model]="Model"
            [loading]="Loading"
        >
        </avi-core-base-form>
        <div class="grid justify-content-end text-xsmall mr-0" *ngIf="Model && Model.KeycloakId">
            <div  pTooltip="Keycloak Id">
                {{ Model.KeycloakId }}
            </div>
        </div>
    </p-panel>
</div>
