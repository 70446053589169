import { Component, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';
import { FileUpload } from 'primeng/fileupload';

@Component({
    selector: 'vwbp-admin-daten-import',
    templateUrl: './daten-import.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminDatenImportComponent {
    uploadedFiles: any[] = [];

    @ViewChild('inputUser', { static: false })
    inputUser: FileUpload;

    @ViewChild('inputCRB', { static: false })
    inputCRB: FileUpload;

    @ViewChild('inputRT', { static: false })
    inputRT: FileUpload;

    @ViewChild('inputFormular', { static: false })
    inputFormular: FileUpload;

    constructor(private apiService: AviApiService) {
    }

    async onUploadUser(event) {
        for (const file of event.files)
            await this.apiService.postFile('v1/intern/vwbp/datenimport/user', file);

        this.inputUser.clear();
    }

    async onUploadCRB(event) {
        for (const file of event.files)
            await this.apiService.postFile('v1/intern/vwbp/datenimport/crb', file);

        this.inputCRB.clear();
    }

    async onUploadRT(event) {
        for (const file of event.files)
            await this.apiService.postFile('v1/intern/vwbp/datenimport/rechtstraeger', file);

        this.inputRT.clear();
    }

    async onUploadFormular(event) {
        for (const file of event.files)
            await this.apiService.postFile('v1/intern/vwbp/datenimport/formular', file);

        this.inputFormular.clear();
    }
}

