import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class ActivitySearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
	public ActivityID: number;

    @Expose()
	public Titel: string;

    @Expose()
	public Description: string;

    @Expose()
	public StatusBez: string;

    @Expose()
	public AssignedToName: string;

    @Expose()
	public OwnerName: string;

    @Expose()
	public IsRTF: boolean;

    @Expose()
	public Comment: string;

    @Expose()
	public ActRepresentation: string;

    @Expose()
    @Type(() => Date)
	public StartDate: Date;

    @Expose()
    @Type(() => Date)
	public DueDate: Date;

    @Expose()
    @Type(() => Date)
	public FinishDate: Date;

    @Expose()
    @Type(() => Date)
	public RememberDate: Date;

    @Expose()
    @Type(() => Date)
	public EscalationDate: Date;

    @Expose()
    @Type(() => Date)
	public AcknowledgeDate: Date;

    @Expose()
	public Dauer: number;

	@Expose()
	@Type(() => ListType)
	public ActivityRead: ListType;

	@Expose()
	@Type(() => ListType)
	public ActivityState: ListType;

	@Expose()
	@Type(() => ListType)
	public Priority: ListType;

    @Expose()
	public ExterneReferenz: string;

	@Expose()
	@Type(() => ListType)
	public PeriodTyp: ListType;

    @Expose()
	public Period: number;

    @Expose()
    @Type(() => Date)
	public ExtendUntil: Date;

    @Expose()
	public TextUebernehmen: boolean;

    @Expose()
	public PeriodMonthDay: number;

	@Expose()
	@Type(() => ListType)
	public PeriodDayTyp: ListType;

    @Expose()
	public ActContext1Representation: string;

    @Expose()
	public ActContext2Representation: string;

    @Expose()
	public ActContext3Representation: string;

    @Expose()
    @Type(() => Date)
	public ActivityPeriodeVon: Date;

    @Expose()
    @Type(() => Date)
	public ActivityPeriodeBis: Date;
}
