import { Expose, Type } from 'class-transformer';

import { ModelMeta } from '../system/modelmeta.model';
import { CustomPropCollectionDef } from '../system/custompropcollectiondef.model';
import { AdressBlockDef } from '../partner/adressblockdef.model';
import { SearcherFilter } from '../system/searcherfilter.model';
import { AuswahlDef } from '../partner/auswahldef.model';
import { TechRole } from '../system/techrole.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class PartnerRolleDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get InvKurzBez1(): string { return this.getProperty('InvKurzBez1'); }
    public set InvKurzBez1(value: string) { this.setProperty('InvKurzBez1', value); }
    
    @Expose()
    public get InvBezeichnung1(): string { return this.getProperty('InvBezeichnung1'); }
    public set InvBezeichnung1(value: string) { this.setProperty('InvBezeichnung1', value); }
    
    @Expose()
    public get InvKurzBez2(): string { return this.getProperty('InvKurzBez2'); }
    public set InvKurzBez2(value: string) { this.setProperty('InvKurzBez2', value); }
    
    @Expose()
    public get InvBezeichnung2(): string { return this.getProperty('InvBezeichnung2'); }
    public set InvBezeichnung2(value: string) { this.setProperty('InvBezeichnung2', value); }
    
    @Expose()
    public get InvKurzBez3(): string { return this.getProperty('InvKurzBez3'); }
    public set InvKurzBez3(value: string) { this.setProperty('InvKurzBez3', value); }
    
    @Expose()
    public get InvBezeichnung3(): string { return this.getProperty('InvBezeichnung3'); }
    public set InvBezeichnung3(value: string) { this.setProperty('InvBezeichnung3', value); }
    
    @Expose()
    public get InvKurzBez4(): string { return this.getProperty('InvKurzBez4'); }
    public set InvKurzBez4(value: string) { this.setProperty('InvKurzBez4', value); }
    
    @Expose()
    public get InvBezeichnung4(): string { return this.getProperty('InvBezeichnung4'); }
    public set InvBezeichnung4(value: string) { this.setProperty('InvBezeichnung4', value); }
    
    @Expose()
    public get InvKurzBez5(): string { return this.getProperty('InvKurzBez5'); }
    public set InvKurzBez5(value: string) { this.setProperty('InvKurzBez5', value); }
    
    @Expose()
    public get InvBezeichnung5(): string { return this.getProperty('InvBezeichnung5'); }
    public set InvBezeichnung5(value: string) { this.setProperty('InvBezeichnung5', value); }
    
    @Expose()
    public get InvKurzBez6(): string { return this.getProperty('InvKurzBez6'); }
    public set InvKurzBez6(value: string) { this.setProperty('InvKurzBez6', value); }
    
    @Expose()
    public get InvBezeichnung6(): string { return this.getProperty('InvBezeichnung6'); }
    public set InvBezeichnung6(value: string) { this.setProperty('InvBezeichnung6', value); }
    
    @Expose()
    public get InvKurzBez7(): string { return this.getProperty('InvKurzBez7'); }
    public set InvKurzBez7(value: string) { this.setProperty('InvKurzBez7', value); }
    
    @Expose()
    public get InvBezeichnung7(): string { return this.getProperty('InvBezeichnung7'); }
    public set InvBezeichnung7(value: string) { this.setProperty('InvBezeichnung7', value); }
    
    @Expose()
    public get BemerkungLabel1(): string { return this.getProperty('BemerkungLabel1'); }
    public set BemerkungLabel1(value: string) { this.setProperty('BemerkungLabel1', value); }
    
    @Expose()
    public get BemerkungLabel2(): string { return this.getProperty('BemerkungLabel2'); }
    public set BemerkungLabel2(value: string) { this.setProperty('BemerkungLabel2', value); }
    
    @Expose()
    public get Sorter(): number { return this.getProperty('Sorter'); }
    public set Sorter(value: number) { this.setProperty('Sorter', value); }
    
    @Expose()
    public get PartnerFilterAnzeigen(): boolean { return this.getProperty('PartnerFilterAnzeigen'); }
    public set PartnerFilterAnzeigen(value: boolean) { this.setProperty('PartnerFilterAnzeigen', value); }
    
    @Expose()
    public get AnteilNachkommaStellen(): number { return this.getProperty('AnteilNachkommaStellen'); }
    public set AnteilNachkommaStellen(value: number) { this.setProperty('AnteilNachkommaStellen', value); }
    
    @Expose()
    public get LandLabel1(): string { return this.getProperty('LandLabel1'); }
    public set LandLabel1(value: string) { this.setProperty('LandLabel1', value); }
    
    @Expose()
    public get LandLabel2(): string { return this.getProperty('LandLabel2'); }
    public set LandLabel2(value: string) { this.setProperty('LandLabel2', value); }
    
    @Expose()
    public get LandLabel3(): string { return this.getProperty('LandLabel3'); }
    public set LandLabel3(value: string) { this.setProperty('LandLabel3', value); }
    
    @Expose()
    public get LandLabel4(): string { return this.getProperty('LandLabel4'); }
    public set LandLabel4(value: string) { this.setProperty('LandLabel4', value); }
    
    @Expose()
    public get Model_ID(): string { return this.getModelId('Model_ID'); }
    public set Model_ID(value: string) { this.setModelId('Model_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get Model(): ModelMeta { return this.getModel('Model'); }
    public set Model(value: ModelMeta) { this.setModel('Model', value); }
    
    @Expose()
    public get CustomProperties_ID(): string { return this.getModelId('CustomProperties_ID'); }
    public set CustomProperties_ID(value: string) { this.setModelId('CustomProperties_ID', value); }
 
    @Expose()
    @Type(() => CustomPropCollectionDef)
    public get CustomProperties(): CustomPropCollectionDef { return this.getModel('CustomProperties'); }
    public set CustomProperties(value: CustomPropCollectionDef) { this.setModel('CustomProperties', value); }
    
    @Expose()
    public get vonAdressBlockDef_ID(): string { return this.getModelId('vonAdressBlockDef_ID'); }
    public set vonAdressBlockDef_ID(value: string) { this.setModelId('vonAdressBlockDef_ID', value); }
 
    @Expose()
    @Type(() => AdressBlockDef)
    public get vonAdressBlockDef(): AdressBlockDef { return this.getModel('vonAdressBlockDef'); }
    public set vonAdressBlockDef(value: AdressBlockDef) { this.setModel('vonAdressBlockDef', value); }
    
    @Expose()
    public get vonPartnerFilter_ID(): string { return this.getModelId('vonPartnerFilter_ID'); }
    public set vonPartnerFilter_ID(value: string) { this.setModelId('vonPartnerFilter_ID', value); }
 
    @Expose()
    @Type(() => SearcherFilter)
    public get vonPartnerFilter(): SearcherFilter { return this.getModel('vonPartnerFilter'); }
    public set vonPartnerFilter(value: SearcherFilter) { this.setModel('vonPartnerFilter', value); }
    
    @Expose()
    public get AuswahlDef_ID(): string { return this.getModelId('AuswahlDef_ID'); }
    public set AuswahlDef_ID(value: string) { this.setModelId('AuswahlDef_ID', value); }
 
    @Expose()
    @Type(() => AuswahlDef)
    public get AuswahlDef(): AuswahlDef { return this.getModel('AuswahlDef'); }
    public set AuswahlDef(value: AuswahlDef) { this.setModel('AuswahlDef', value); }
    
    @Expose()
    public get CreateRole_ID(): string { return this.getModelId('CreateRole_ID'); }
    public set CreateRole_ID(value: string) { this.setModelId('CreateRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get CreateRole(): TechRole { return this.getModel('CreateRole'); }
    public set CreateRole(value: TechRole) { this.setModel('CreateRole', value); }
    
    @Expose()
    public get UpdateRole_ID(): string { return this.getModelId('UpdateRole_ID'); }
    public set UpdateRole_ID(value: string) { this.setModelId('UpdateRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get UpdateRole(): TechRole { return this.getModel('UpdateRole'); }
    public set UpdateRole(value: TechRole) { this.setModel('UpdateRole', value); }
    
    @Expose()
    public get DeleteRole_ID(): string { return this.getModelId('DeleteRole_ID'); }
    public set DeleteRole_ID(value: string) { this.setModelId('DeleteRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get DeleteRole(): TechRole { return this.getModel('DeleteRole'); }
    public set DeleteRole(value: TechRole) { this.setModel('DeleteRole', value); }
    
    @Expose()
    @Type(() => ListType)
    public get RollenLabelTyp(): ListType { return this.getProperty('RollenLabelTyp'); }
    public set RollenLabelTyp(value: ListType) { this.setProperty('RollenLabelTyp', value); }
    
    @Expose()
    @Type(() => ListType)
    public get RollenTyp(): ListType { return this.getProperty('RollenTyp'); }
    public set RollenTyp(value: ListType) { this.setProperty('RollenTyp', value); }
    
    @Expose()
    @Type(() => ListType)
    public get BeziehungZuZwingend(): ListType { return this.getProperty('BeziehungZuZwingend'); }
    public set BeziehungZuZwingend(value: ListType) { this.setProperty('BeziehungZuZwingend', value); }
    
    @Expose()
    @Type(() => ListType)
    public get GueltigAbBisSichtbar(): ListType { return this.getProperty('GueltigAbBisSichtbar'); }
    public set GueltigAbBisSichtbar(value: ListType) { this.setProperty('GueltigAbBisSichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerwendungIn(): ListType { return this.getProperty('VerwendungIn'); }
    public set VerwendungIn(value: ListType) { this.setProperty('VerwendungIn', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerwendungJP(): ListType { return this.getProperty('VerwendungJP'); }
    public set VerwendungJP(value: ListType) { this.setProperty('VerwendungJP', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerwendungInEncrypted(): ListType { return this.getProperty('VerwendungInEncrypted'); }
    public set VerwendungInEncrypted(value: ListType) { this.setProperty('VerwendungInEncrypted', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Referenziert(): ListType { return this.getProperty('Referenziert'); }
    public set Referenziert(value: ListType) { this.setProperty('Referenziert', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ReferenziertJP(): ListType { return this.getProperty('ReferenziertJP'); }
    public set ReferenziertJP(value: ListType) { this.setProperty('ReferenziertJP', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ReferenziertEncrypted(): ListType { return this.getProperty('ReferenziertEncrypted'); }
    public set ReferenziertEncrypted(value: ListType) { this.setProperty('ReferenziertEncrypted', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Multiplicity(): ListType { return this.getProperty('Multiplicity'); }
    public set Multiplicity(value: ListType) { this.setProperty('Multiplicity', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AutomLoeschen(): ListType { return this.getProperty('AutomLoeschen'); }
    public set AutomLoeschen(value: ListType) { this.setProperty('AutomLoeschen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get KannRolleDefAendern(): ListType { return this.getProperty('KannRolleDefAendern'); }
    public set KannRolleDefAendern(value: ListType) { this.setProperty('KannRolleDefAendern', value); }
    
    @Expose()
    @Type(() => ListType)
    public get KannErsetzen(): ListType { return this.getProperty('KannErsetzen'); }
    public set KannErsetzen(value: ListType) { this.setProperty('KannErsetzen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get EmailWaiverSichtbar(): ListType { return this.getProperty('EmailWaiverSichtbar'); }
    public set EmailWaiverSichtbar(value: ListType) { this.setProperty('EmailWaiverSichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get EroeffnungGeschaeftsbeziehungSichtbar(): ListType { return this.getProperty('EroeffnungGeschaeftsbeziehungSichtbar'); }
    public set EroeffnungGeschaeftsbeziehungSichtbar(value: ListType) { this.setProperty('EroeffnungGeschaeftsbeziehungSichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ModelRefSichtbar(): ListType { return this.getProperty('ModelRefSichtbar'); }
    public set ModelRefSichtbar(value: ListType) { this.setProperty('ModelRefSichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ModelRefZwingend(): ListType { return this.getProperty('ModelRefZwingend'); }
    public set ModelRefZwingend(value: ListType) { this.setProperty('ModelRefZwingend', value); }
    
    @Expose()
    @Type(() => ListType)
    public get RangSichtbar(): ListType { return this.getProperty('RangSichtbar'); }
    public set RangSichtbar(value: ListType) { this.setProperty('RangSichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get SpracheSichtbar(): ListType { return this.getProperty('SpracheSichtbar'); }
    public set SpracheSichtbar(value: ListType) { this.setProperty('SpracheSichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get GueltigAbPflicht(): ListType { return this.getProperty('GueltigAbPflicht'); }
    public set GueltigAbPflicht(value: ListType) { this.setProperty('GueltigAbPflicht', value); }
    
    @Expose()
    @Type(() => ListType)
    public get GueltigBevorAIAEinblenden(): ListType { return this.getProperty('GueltigBevorAIAEinblenden'); }
    public set GueltigBevorAIAEinblenden(value: ListType) { this.setProperty('GueltigBevorAIAEinblenden', value); }
    
    @Expose()
    @Type(() => ListType)
    public get BemerkungSichtbar(): ListType { return this.getProperty('BemerkungSichtbar'); }
    public set BemerkungSichtbar(value: ListType) { this.setProperty('BemerkungSichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get BeschreibungSichtbar(): ListType { return this.getProperty('BeschreibungSichtbar'); }
    public set BeschreibungSichtbar(value: ListType) { this.setProperty('BeschreibungSichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get BemerkungZwingend(): ListType { return this.getProperty('BemerkungZwingend'); }
    public set BemerkungZwingend(value: ListType) { this.setProperty('BemerkungZwingend', value); }
    
    @Expose()
    @Type(() => ListType)
    public get KannOZRHaben(): ListType { return this.getProperty('KannOZRHaben'); }
    public set KannOZRHaben(value: ListType) { this.setProperty('KannOZRHaben', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AnteilEinblenden(): ListType { return this.getProperty('AnteilEinblenden'); }
    public set AnteilEinblenden(value: ListType) { this.setProperty('AnteilEinblenden', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AnteilPflicht(): ListType { return this.getProperty('AnteilPflicht'); }
    public set AnteilPflicht(value: ListType) { this.setProperty('AnteilPflicht', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AnteilTyp(): ListType { return this.getProperty('AnteilTyp'); }
    public set AnteilTyp(value: ListType) { this.setProperty('AnteilTyp', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VonAusgestaltung(): ListType { return this.getProperty('VonAusgestaltung'); }
    public set VonAusgestaltung(value: ListType) { this.setProperty('VonAusgestaltung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ZuAusgestaltung(): ListType { return this.getProperty('ZuAusgestaltung'); }
    public set ZuAusgestaltung(value: ListType) { this.setProperty('ZuAusgestaltung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get GueltigAbDefaultWert(): ListType { return this.getProperty('GueltigAbDefaultWert'); }
    public set GueltigAbDefaultWert(value: ListType) { this.setProperty('GueltigAbDefaultWert', value); }
    
    @Expose()
    @Type(() => ListType)
    public get LandSichtbar(): ListType { return this.getProperty('LandSichtbar'); }
    public set LandSichtbar(value: ListType) { this.setProperty('LandSichtbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get LandZwingend(): ListType { return this.getProperty('LandZwingend'); }
    public set LandZwingend(value: ListType) { this.setProperty('LandZwingend', value); }
    
}
