import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AviQueryDefExecutionSearcherComponent } from './components/querydef-exec-searcher.component';
import { AviCoreModule } from '@avi-x/avi-core';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GridsterModule } from 'angular-gridster2';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
    imports: [
        CommonModule,
        AviCoreModule,
        NgxChartsModule,
        GridsterModule,
        MatIconModule
    ],
    exports: [
        AviQueryDefExecutionSearcherComponent,
        RouterModule
    ],
    declarations: [
        AviQueryDefExecutionSearcherComponent,
    ],
})
export class AviQueryDefExecutionSearcherModule { }
