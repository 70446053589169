import { AppLevel, ResourceLabel } from '@avi-x/avi-dto/system/resourcelabel.model';
import { AviBaseFormComponent } from '../../base-form/base-form.component';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, isDevMode, MissingTranslationStrategy, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { typed_nameof } from '../../utils/nameof';
import { AviChangedAttr, AviFormField } from '../../base-form/form-field';
import { AviFormFieldService } from '../../../services/form-field.service';
import { AviApiService } from '../../../services/api.service';
import { AviApiErrorObject } from '../../../dto/aviapierrorobject';
import { AviCommonService } from '../../../services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute } from '@angular/router';

const nameof = (nameFunction: ((obj: ResourceLabel) => any)) => typed_nameof<ResourceLabel>(nameFunction);

export enum ResourceFormMode {
    // eslint-disable-next-line no-unused-vars
    CREATE, UPDATE
}

@Component({
    selector: 'avi-core-resource-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons"
        [validation-delegate]="ValidateData"
        >

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviResourceFormComponent implements OnInit, AfterViewInit {
    private _resourceFormMode: ResourceFormMode = ResourceFormMode.UPDATE;

    public get ResourceFormMode(): ResourceFormMode {
        return this._resourceFormMode;
    }

    public set ResourceFormMode(mode: ResourceFormMode) {
        this._resourceFormMode = mode;

        var isUpdateMode: boolean = this.ResourceFormMode === ResourceFormMode.UPDATE;
        this.FieldAppLevel.setReadonly(isUpdateMode);
        this.FieldKey.setReadonly(isUpdateMode);

        //this.cdr.markForCheck();
    }

    private FieldHTML: AviFormField;
    private FieldAppLevel: AviFormField;
    private FieldKey: AviFormField;
    private FieldValueCoreHTML: AviFormField;
    private FieldValueAppHTML: AviFormField;
    private FieldValueCustomHTML: AviFormField;
    private FieldValueCore: AviFormField;
    private FieldValueApp: AviFormField;
    private FieldValueCustom: AviFormField;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    public get FormTitle(): string {
        if (this.ResourceFormMode === ResourceFormMode.CREATE)
            return this.translateService.instant('CORE.RESOURCE.CREATE');
        else if (this.ResourceFormMode === ResourceFormMode.UPDATE)
            return this.translateService.instant('CORE.RESOURCE.EDIT');
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;

    private _Model: ResourceLabel = null;
    public get Model(): ResourceLabel { return this._Model; }

    @Input('model')
    public set Model(value: ResourceLabel) {
        this.InitForm(value);
    }

    private _Sprache: ReferenceDto = null;

    @Input('sprache')
    public set Sprache(value: ReferenceDto) {
        this._Sprache = value;
    }

    public Fields: AviFormField[] = [];

    @Input('api-prefix')
    public ApiPrefix: string = '';

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    abbrechenButton: any = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { if (this.ref) this.ref.close(null); } }];

    AlwaysHtml: boolean = false;

    constructor(
        private commonService: AviCommonService,
        private apiService: AviApiService,
        private formFieldService: AviFormFieldService,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private cdr: ChangeDetectorRef
    ) { }

    public async InitForm(value: ResourceLabel): Promise<boolean> {
        this._Model = value;
        this.cdr.markForCheck();

        if (this.Model) {
            this.Model.IsHtml = this.AlwaysHtml || this.isHtml(this._Model.ValueCore) || this.isHtml(this._Model.ValueApp);
            this.initHtml();
        }

        return true;
    }

    initHtml() {
        var devMode = isDevMode();

        let showCore = true;
        let showApp = true;

        if (!devMode) { 
            showCore = this.Model.ValueCore != null && this.Model.ValueCore != '';
            showApp = this.Model.ValueApp != null && this.Model.ValueApp != '';
        }

        this.FieldValueCoreHTML.Visible = this.Model.IsHtml && showCore;
        this.FieldValueAppHTML.Visible = this.Model.IsHtml && showApp;
        this.FieldValueCustomHTML.Visible = this.Model.IsHtml && !devMode;
        this.FieldValueCore.Visible = !this.Model.IsHtml && showCore;
        this.FieldValueApp.Visible = !this.Model.IsHtml && showApp;
        this.FieldValueCustom.Visible = !this.Model.IsHtml && !devMode;

        this.FieldHTML.Visible = !this.AlwaysHtml && devMode;

        this.cdr.markForCheck();
    }

    ValidateData = (): boolean => {
        let valid = true;
        this.clearErrors();

        if (this.ResourceFormMode === ResourceFormMode.UPDATE) {
            // keine Änderungen -> kein Error anzeigen, aber Speichern-Button soll readOnly sein
            if (!this.hasChanges(this.Model)) {
                valid = false;
            }
        }

        // Value darf nicht leer sein
        if (this.Model && !this.Model.ValueCore && !this.Model.ValueApp) {
            this.translateService.get('CORE.RESOURCE.VALUE_NOT_NULL')
                .toPromise()
                .then(w => this.FieldValueCustomHTML.addError(w));
            valid = false;
        }

        return valid;
    }

    clearErrors() {
        this.Fields.forEach(field => {
            field.clearErrors();
        });
    }

    async onAttrChange(data: AviChangedAttr) {
        if (this.Model)
        {
            if (data.field === nameof(c => c.ValueCore) && data.value == '')
                this._Model.ValueCore = null;
            else if (data.field === nameof(c => c.ValueApp) && data.value == '')
                this._Model.ValueApp = null;
            else if (data.field === nameof(c => c.ValueCustom) && data.value == '')
                this._Model.ValueCustom = null;
            else if (data.field === nameof(c => c.IsHtml))
                this.initHtml();
        }
    }

    ngOnInit() {
        this.initFields();

        if (this.config && this.config.data) {
            if (this.config.data) {
                this.AlwaysHtml = this.config.data.alwaysHtml;
                this.Sprache = this.config.data.sprache;
                this.ResourceFormMode = this.config.data.mode;
                this.Model = this.config.data.model;
                this.Card = false;
                this.ContentsPadding = false;
                this.actionButtons = [...this.actionButtons, ...this.abbrechenButton];

                this.FieldAppLevel.setReadonly(!!this.Model.AppLevel);
                this.FieldKey.setReadonly(this.ResourceFormMode === ResourceFormMode.UPDATE || (this.ResourceFormMode === ResourceFormMode.CREATE && !!this.Model.Key));
            }
        }
    }

    ngAfterViewInit(): void {
            this.Form.focusField(nameof(c => c.Key), true);
    }

    saveModel(model: ResourceLabel) {
        this.Form.clearFormMessages();

        if (this.ResourceFormMode === ResourceFormMode.CREATE)
            this.createResourceLabel(model);
        else if (this.ResourceFormMode === ResourceFormMode.UPDATE)
            this.updateResourceLabel(model);
    }

    private createResourceLabel(model: ResourceLabel) {
        this.apiService.post(`${this.ApiPrefix ?? ''}/resource`, model)
            .then(r => this.handleResponse(model))
            .catch(this.handelError.bind(this));
    }

    private updateResourceLabel(model: ResourceLabel) {
        if (this.hasChanges(model)) {
            this.apiService.put(`${this.ApiPrefix ?? ''}/resource/sprache/${this._Sprache.Id}`, model)
                .then(r => this.handleResponse(model))
                .catch(this.handelError.bind(this));
        }
        else
            this.onSavedSuccessfulDelegate.emit(model);
    }

    private handleResponse(model: ResourceLabel) {
        this.translateService.get('CORE.COMMON.GESPEICHERT')
            .toPromise()
            .then(w => {
                this.commonService.notificateSuccess(w);
                if (this.ref)
                    this.ref.close(model);    
            });

        this.onSavedSuccessfulDelegate.emit(model);
    }

    private handelError(error: any) {
        if (error instanceof AviApiErrorObject)
            this.Form.addFormMessage(error.ErrorMessage, error.Type);
        else
            this.Form.addFormMessage(JSON.stringify(error));
    }

    initFields() {
        var devMode = isDevMode();
        //var devMode = false;
        var isUpdateMode = this.ResourceFormMode === ResourceFormMode.UPDATE;

        this.FieldHTML = this.formFieldService.CreateCheckbox(nameof(c => c.IsHtml), null, 'HTML').setMDSizeFull().setVisible(devMode).setLabelVisible(false);

        this.FieldAppLevel = this.formFieldService.CreateEnum(nameof(c => c.AppLevel), 'AppLevel', AppLevel, true).setMDSizeFull().setReadonly(isUpdateMode);
        this.FieldKey = this.formFieldService.CreateText(nameof(c => c.Key), 'Key', true).setMDSizeFull().setReadonly(isUpdateMode);

        this.FieldValueCoreHTML = this.formFieldService.CreateRichText(nameof(c => c.ValueCore), 'Value Core', false).setHinweis('Wird im Core-Projekt gespeichert (ClientResources.Core.xy.json)').setMDSizeFull().setReadonly(!devMode);
        this.FieldValueCoreHTML.EnableTribute = false;
        this.FieldValueCore = this.formFieldService.CreateText(nameof(c => c.ValueCore), 'Value Core', false).setMDSizeFull().setHinweis('Wird im Core-Projekt gespeichert (ClientResources.Core.xy.json)').setReadonly(!devMode);

        this.FieldValueAppHTML = this.formFieldService.CreateRichText(nameof(c => c.ValueApp), 'Value App', false).setMDSizeFull().setHinweis('Wird im Kundenprojekt gespeichert (ClientResources.App.xy.json)').setReadonly(!devMode);
        this.FieldValueAppHTML.EnableTribute = false;
        this.FieldValueApp = this.formFieldService.CreateText(nameof(c => c.ValueApp), 'Value App', false).setMDSizeFull().setHinweis('Wird im Kundenprojekt gespeichert (ClientResources.App.xy.json)').setReadonly(!devMode);

        //this.FieldValueCustom = this.formFieldService.CreateTextarea(nameof(c => c.ValueCustom), 'Value Custom', false).setMDSizeFull().setReadonly();
        this.FieldValueCustomHTML = this.formFieldService.CreateRichText(nameof(c => c.ValueCustom), 'Value Custom', false).setHinweis('Wird im Kundenprojekt gespeichert (ClientResources.Custom.xy.json)').setMDSizeFull();
        this.FieldValueCustomHTML.EnableTribute = false;
        this.FieldValueCustom = this.formFieldService.CreateText(nameof(c => c.ValueCustom), 'Value Custom', false).setHinweis('Wird im Kundenprojekt gespeichert (ClientResources.Custom.xy.json)').setMDSizeFull();

        this.Fields.push(this.FieldAppLevel);
        this.Fields.push(this.FieldKey);
        this.Fields.push(this.FieldHTML);
        this.Fields.push(this.FieldValueCoreHTML);
        this.Fields.push(this.FieldValueCore);
        this.Fields.push(this.FieldValueAppHTML);
        this.Fields.push(this.FieldValueApp);
        this.Fields.push(this.FieldValueCustomHTML);
        this.Fields.push(this.FieldValueCustom);
    }

    hasChanges(model: ResourceLabel): boolean {
        if (model) {
            return this.valueChanged(model.OriginalValueCustom, model.ValueCustom)
                || this.valueChanged(model.OriginalValueApp, model.ValueApp)
                || this.valueChanged(model.OriginalValueCore, model.ValueCore);
        }
        return false;
    }

    private valueChanged(originalValue: string, currentValue: string): boolean {
        var _currentValue = currentValue;
        if (!this.isHtml(originalValue))
            _currentValue = this.removeOuterHtmlTags(_currentValue);

        return originalValue !== _currentValue;
    }

    public RefreshEditors() {
        this.Form.RefreshEditors();
    }

    private htmlRegex = new RegExp('<([^>]+)>');
    private htmlStartRegex = new RegExp('^<[^>]*>');
    private htmlEndRegex = new RegExp('<\/[^>]*>$');

    private isHtml(input: string): boolean {
        return this.htmlRegex.test(input);
    }

    private removeOuterHtmlTags(input: string): string {
        if (!input)
            return null;

        // <p>Test</p>                      =>  Test
        // <p><strong>Test</strong></p>     =>  <strong>Test</strong>
        return input.replace(this.htmlStartRegex, '').replace(this.htmlEndRegex, '');
    }
}
