import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviJobLoggingFormComponent } from './joblogging-form.component';

import { JobLoggingSearchResponseDto } from '@avi-x/avi-dto/system/jobloggingsearchresponsedto.model';
import { AviAbstractModelSearcherComponent } from '../base-searcher/abstract-model-searcher.component';
import { typed_nameof } from '../utils/nameof';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviSessionControllerService } from '../../services/session-controller.service';
import { AviBaseSearcherComponent } from '../base-searcher/base-searcher.component';
import { ActivatedRoute } from '@angular/router';

const nameof = (nameFunction: ((obj: JobLoggingSearchResponseDto) => any)) => typed_nameof<JobLoggingSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-joblogging-searcher',
    templateUrl: './joblogging-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: AviAbstractModelSearcherComponent, useExisting: AviJobLoggingSearcherComponent }]
})
export class AviJobLoggingSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.JOBLOGGING';
    SettingsKey = 'settings.searcher.joblogging';
    BaseUrl = 'job/logging';
	FormType = AviJobLoggingFormComponent;

    @Output()
    onSelectedJobLoggingChanged = new EventEmitter<string>();

    @Input('no-padding')
    noPadding = false;
    
    CanEdit: boolean = false;
    ApiPrefix: string = null;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef,
        public activatedRoute: ActivatedRoute
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('JobLogging schreiben').then(w => this.CanEdit = w);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if ('BaseUrl' in this.activatedRoute.snapshot.data)
            this.BaseUrl = this.activatedRoute.snapshot.data['BaseUrl'];

        if ('ApiPrefix' in this.activatedRoute.snapshot.data)
            this.ApiPrefix = this.activatedRoute.snapshot.data['ApiPrefix'];

        // if ('UseRouting' in this.activatedRoute.snapshot.data)
        //     this.jobService.UseRouting = this.activatedRoute.snapshot.data['UseRouting'];

        // if ('RouterPrefix' in this.activatedRoute.snapshot.data)
        //     this.jobService.RouterPrefix = this.activatedRoute.snapshot.data['RouterPrefix'];
    }

    async onSelectionChanged(data: any): Promise<void> {
        await super.onSelectionChanged(data);
        
        let selection: JobLoggingSearchResponseDto = null;
        if (data instanceof JobLoggingSearchResponseDto)
            selection = data;
        else {
            if (Array.isArray(data)) {
                let array = data as JobLoggingSearchResponseDto[];
                if (array.length > 0)
                    selection = array[0];
            }
            else if (data)
                selection = data as JobLoggingSearchResponseDto;
        }

        this.onSelectedJobLoggingChanged.emit(selection.Id);
    }
	
    onDoubleClickSearcher(data) {
        // if (this.SelectedRows) {
        //     // this.sessionController.openSession('joblogging-index', this.SelectedRows.Id);
        //     this.editObject(this.SelectedRows);
        // }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addIntegerColumn(nameof(c => c.LogNr), 'CORE.COMMON.MODEL.JOBLOGGING.LOGNR', true);
        searcher.addDateColumn(nameof(c => c.ErstellDatum), 'CORE.COMMON.MODEL.JOBLOGGING.ERSTELLDATUM', "short", true);

        searcher.addListTypeColumn(nameof(c => c.Erledigt), 'CORE.COMMON.MODEL.JOBLOGGING.ERLEDIGT', 'janein', true, true, null, this.ApiPrefix);
        searcher.addListTypeColumn(nameof(c => c.LogType), 'CORE.COMMON.MODEL.JOBLOGGING.LOGTYPE', 'logging_type', true, true, null, this.ApiPrefix);
        
        searcher.addTextColumn(nameof(c => c.LogMessage), 'CORE.COMMON.MODEL.JOBLOGGING.LOGMESSAGE', true);

		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(JobLoggingSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
