<div
    *ngIf="Model"
    class="comment ui-fluid ui-g-nopad"
    [ngClass]="{ 'comment-erledigt': this.CommentErledigt, 'comment-edit': !isReadOnly() }"
>
    <div [ngClass]="{ 'comment-wrapper': isReadOnly() }">
        <button
            *ngIf="isReadOnly() && CommentsCollapsible"
            pButton
            [icon]="CommentCollapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"
            class="comment-collapse-button p-button-secondary p-button-rounded p-button-text"
            (click)="toggleCollapsed()"
        ></button>

        <div class="comment-container" [ngClass]="{'comments-collapsible': CommentsCollapsible}">
            <div *ngIf="!isNewComment()" class="comment-header-container">
                <span class="comment-header">{{ this.Model.ErstelltVon }} </span>
                <span class="comment-subheader truncate margin-right-auto pl-1">{{ getCommentHeader() }}</span>

                <div class="comment-tags">
                    <ng-container *ngIf="isReadOnly()">
                        <div *ngIf="Model.Internal" class="status-badge status-intern">
                            <div>Intern</div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isReadOnly()">
                        <div class="tag-item tag-item-value" *ngFor="let option of SelectedKeywords">
                            <div>{{ option.name }}</div>
                        </div>
                    </ng-container>
                </div>

                <ng-container *ngIf="isReadOnly()">
                    <ng-container *ngIf="!CommentErledigt">
                        <button
                            *ngIf="CanEdit && IsOwner"
                            pButton
                            icon="pi pi-pencil"
                            class="p-button-secondary p-button-flat"
                            (click)="EditComment()"
                        ></button>
                        <button
                            *ngIf="CanEdit && IsOwner"
                            pButton
                            icon="pi pi-trash"
                            class="p-button-secondary p-button-flat"
                            (click)="DeleteComment()"
                        ></button>
                    </ng-container>
                    <p-checkbox
                        *ngIf="EnableErledigen"
                        [disabled]="!CanEdit"
                        class="pl-2"
                        [ngModel]="CommentErledigt"
                        (ngModelChange)="SetCommentErledigt($event)"
                        label="Erledigt"
                        [binary]="true"
                    ></p-checkbox>
                </ng-container>
            </div>

            <div
                *ngIf="!isReadOnly() || !CommentCollapsed"
                [ngClass]="{ 'comment-content': true, 'ui-g-nopad': true }"
            >
                <span [ngSwitch]="Model.IsRTF">
                    <span *ngSwitchCase="true">
                        <avi-core-richtexteditor
                            #rtEditor
                            id="CommentRTE"
                            [readonly]="isReadOnly()"
                            name="Comment"
                            [(ngModel)]="Model.Comment"
                        >
                        </avi-core-richtexteditor>
                    </span>
                    <span *ngSwitchDefault>
                        <div *ngIf="isReadOnly()" [innerText]="Model.Comment"></div>

                        <textarea
                            *ngIf="!isReadOnly()"
                            [rows]="4"
                            id="Comment"
                            [readonly]="isReadOnly()"
                            [ngClass]="{ 'nobackground-image': isReadOnly(), 'w-100p': true }"
                            pInputTextarea
                            class="inputtextarea-with-min-height"
                            [(ngModel)]="Model.Comment"
                            [autoResize]="true"
                            name="Comment"
                            attr.name="Comment"
                        >
                        </textarea>
                    </span>
                </span>
            </div>
        </div>
    </div>

    <div *ngIf="!isReadOnly()" class="pl-0">
        <div *ngIf="Keywords && Keywords.length > 0" class="comment-keyword-wrapper">
            <label class="pr-2">Keywords:</label>
            <p-multiSelect
                appendTo="body"
                scrollHeight="400px"
                [options]="Keywords"
                [(ngModel)]="SelectedKeywords"
                defaultLabel="Selektiere Keywords"
                optionLabel="name"
            >
                <ng-template let-value pTemplate="selectedItems">
                    <div class="tag-item tag-item-value" *ngFor="let option of SelectedKeywords">
                        <div>{{ option.name }}</div>
                    </div>
                    <div *ngIf="!SelectedKeywords || SelectedKeywords.length === 0" class="tag-placeholder">
                        Selektiere Keywords
                    </div>
                </ng-template>
            </p-multiSelect>
        </div>

        <div class="comment-keyword-wrapper" *ngIf="EnableIntern">
            <p-checkbox                
                class="pr-32p"
                [(ngModel)]="Model.Internal"
                label="Intern"
                [binary]="true"
            ></p-checkbox>           
       </div>
        <div class="pt-2">
            <button
                pButton
                icon="pi pi-save"
                type="submit"
                style="width: auto;"
                class="mr-2"
                (click)="SaveComment()"
                label="Speichern"
                title="Speichern"
            ></button>
            <button
                pButton
                type="button"
                icon="pi pi-times"
                style="width: auto;"
                class="mr-2 p-button-secondary"
                (click)="CancelComment()"
                id="rteCancel"
                [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
                [title]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
            ></button>
        </div>
    </div>
</div>
