import { Injectable } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';
import { VwbPActionConst } from '../../constants/constants';
import { VwbPRechtstraegerAnfordernDto } from '../../dto/vwbp.uebergabe.models';
import { VwbPActionData } from '../../dto/vwbpactiondata.model';

@Injectable({
    providedIn: 'root'
})
export class VwbPUebergabeService {
    constructor(private apiService: AviApiService) { }


    StartRechtstraegerAbgeben(rechtstraegerId: string): Promise<any> {

        const data: VwbPActionData = {
            ModelIdList: [rechtstraegerId],
            Action: VwbPActionConst.VWBP_ACTION_UEBERGABE_RT_ABGEBEN,
        };
        return this.apiService.postModel(VwbPActionData, 'v1/vwbp/uebergabe/action', data);

    }

    StartRechtstraegerAnfordern(data: VwbPRechtstraegerAnfordernDto) {
        return this.apiService.postModel(VwbPActionData, 'v1/vwbp/uebergabe/anfordern', data);
    }


    async GetAbgegebeneRechtstraeger() {
        return this.apiService.get('/v1/vwbp/uebergabe/abgegebene-rechtstraeger');
    }

}
