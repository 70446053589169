import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
    selector: 'avi-core-view-panel',
    templateUrl: './view-panel.component.html',
    styleUrls: ['./view-panel.component.scss'],
})
export class AviViewPanelComponent {
    @Input('header')
    Header: string = null;

    @Input('no-label')
    NoLabel: boolean = false;

    @Input('toggleable')
    Toggleable: boolean = true;

    @Input('toggler')
    toggler: string = 'header';

    @Input('collapsed')
    Collapsed: boolean = false;

    @Input('fill-height')
    public FillHeight: boolean = false;

    @Input('no-pad')
    public NoPadding: boolean = false;

    @Input('enable-panel')
    public PanelEnabled: boolean = true;

    @Input('show-fullscreen-icon')
    public ShowFullscreenIcon: boolean = false;

    @Input('panel-class')
    public PanelClass: string = '';

    @Output('collapsedChange')
    collapsedChange: EventEmitter<any> = new EventEmitter();

    @Input('card-class')
    public cardClass:string = '';

    PanelFullscreen: boolean = false;

    @Output()
    FullscreenChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    toggleFullscreen(e) {
        this.PanelFullscreen = !this.PanelFullscreen;
        this.FullscreenChanged.emit(this.PanelFullscreen);
        e.stopPropagation();
    }

    public getClass(): string {
        let res = '';

        if (this.NoPadding && (this.FillHeight || this.PanelFullscreen))
            res = 'fill-height';
        else if (!this.NoPadding && (this.FillHeight || this.PanelFullscreen))
            res = 'fill-height card-contents';
        else if (!this.NoPadding && !(this.FillHeight || this.PanelFullscreen))
            res = 'card-contents';

        return (this.PanelEnabled && this.PanelClass ? res + ' ' + this.PanelClass : res);
    }

    public getStyleClass(): string {
        if (this.FillHeight || this.PanelFullscreen)
            return `${this.cardClass} view-panel fill-height`;
        else
            return `${this.cardClass} view-panel`;
    }

    OnCollapsedChange(evt) {
        this.collapsedChange.emit(evt);
    }
}
