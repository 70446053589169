import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, typed_nameof, AviListDetailConst, AviChangedAttr, AviSessionControllerService } from '@avi-x/avi-core';
import { ActivityType } from '@avi-x/avi-dto/activity/activitytype.model';
import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';
import { StatusWorkflowDefSearchResponseDto } from '@avi-x/avi-dto/workflow/statusworkflowdefsearchresponsedto.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: ActivityType) => any)) => typed_nameof<ActivityType>(nameFunction);

@Component({
    selector: 'avi-activitytype-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [label-alignment]="ReadOnly ? 'left' : 'top'"
        [label-width]="'160px'"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [steps-clickable]="true"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviActivityTypeFormComponent extends AviAbstractModelFormComponent<ActivityType> {  
    ClassName = 'CORE.COMMON.MODEL.ACTIVITYTYPE';
    BaseUrl = 'activity/activitytype';
    ClassType = ActivityType;
    FormType = AviActivityTypeFormComponent;

    @Input('mode')
    public Mode: 'normal' | 'activity' | 'periode' | 'defaults' | 'contexts' | 'dates' | 'rights' = 'normal';
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'ActivityType';
    }	    

    queryStatusWorkflowDef = async (field: any, modelId: string, query: string) => {
        const ret = [];
        if (this.Model) {
            const cfg: any = { Query: query, ModelId: modelId, Typ: AviListDetailConst.CAF_STATUSWORKFLOW_TYP_AKTIVITAETSSTATUS };
            const p = await this.apiService.postPagedResult(StatusWorkflowDefSearchResponseDto, `workflow/statusworkflowdef/search`, cfg);
            p.Results?.forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    public async afterInitForm() {
        this.initEscalation();
        this.initDescription();
        this.initReminder();
        this.initPeriodSichtbar();
        
        await this.setFromPeriodType();
        await this.setPeriodDayTyp();

        await this.setContextModelMeta(1, false);
        await this.setContextModelMeta(2, false);
        await this.setContextModelMeta(3, false);
    }

    initEscalation() {
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EscalationTo_ID), this.Model.EscalationToType.Id === AviListDetailConst.ACTIVITY_ESKALATION_TO_TYP_FIX_PERSON);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.EscalationTo_ID), this.Model.EscalationToType.Id === AviListDetailConst.ACTIVITY_ESKALATION_TO_TYP_FIX_PERSON);
    }

    initDescription() {
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DescriptionPflicht), this.Model.ActivityBeschreibungKommentar.Id === AviListDetailConst.ACTIVITY_BESCHREIBUNG_KOMMENTAR_NUR_KOMMENTAR);
    }

    initReminder() {
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Remember_Months), this.Model.ReminderEnabled.Id === AviListDetailConst.NEIN);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Remember_Days), this.Model.ReminderEnabled.Id === AviListDetailConst.NEIN);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Remember_Hours), this.Model.ReminderEnabled.Id === AviListDetailConst.NEIN);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Remember_Minutes), this.Model.ReminderEnabled.Id === AviListDetailConst.NEIN);
    }

    initPeriodSichtbar() {
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.ActivityPeriodeLabel), this.Model.ActivityPeriodeSichtbar.Id === AviListDetailConst.JA);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.ActivityPeriodeLabel), this.Model.ActivityPeriodeSichtbar.Id === AviListDetailConst.NEIN);
    }
    
    async setFromPeriodType()
    {
        await this.initPeriodAttribs();
        if (this.Model.DefaultPeriodTyp == null || this.Model.DefaultPeriodTyp.Id === AviListDetailConst.APT_NEIN)
            this.Model.DefaultPeriod = null;

        this.setPeriodDay();

        const pdvis = (this.Model.PeriodPossible.Id === AviListDetailConst.JA && this.Model.DefaultPeriodTyp.Id === AviListDetailConst.APT_MONAT);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DefaultPeriodDayTyp), pdvis);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DefaultPeriodMonthDay), pdvis);
    }

    setPeriodDay() {
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.DefaultPeriodMonthDay), this.Model.DefaultPeriodTyp?.Id === AviListDetailConst.APT_MONAT && this.Model.DefaultPeriodDayTyp.Id === AviListDetailConst.APD_TAG_DEFINED);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DefaultPeriodMonthDay), this.Model.DefaultPeriodTyp?.Id === AviListDetailConst.APD_MONATSENDE || this.Model.PeriodPossible.Id !== AviListDetailConst.JA);
    }

    async setPeriodDayTyp()
    {
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.DefaultPeriodMonthDay), this.Model.DefaultPeriodTyp?.Id === AviListDetailConst.APT_MONAT && this.Model.DefaultPeriodDayTyp.Id === AviListDetailConst.APD_TAG_DEFINED);

        if (this.Model.DefaultPeriodTyp?.Id === AviListDetailConst.APT_MONAT)
        {
            if (this.Model.DefaultPeriodDayTyp.Id == AviListDetailConst.APD_MONATSENDE)
            {
                this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DefaultPeriodMonthDay), true);
                this.Model.DefaultPeriodMonthDay = 31;
            }
            else
            {
                this.Model.DefaultPeriodDayTyp = await this.getListType('activity_periodday', AviListDetailConst.APD_TAG_DEFINED);
                this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DefaultPeriodMonthDay), false);
            }
        }
    }

    async initPeriodAttribs()
    {
        const periodPossible = this.Model.PeriodPossible.Id === AviListDetailConst.JA;

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DefaultPeriodTyp), !periodPossible);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DefaultPeriod), !periodPossible);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DefaultPeriodDayTyp), !periodPossible);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DefaultPeriodMonthDay), !periodPossible);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.PeriodActivityDescriptionSource), !periodPossible);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.PeriodActivityShowNewWizardOnFinish), !periodPossible);

        if (periodPossible)
        {
            this.formFieldService.addFieldHiddenItems(this.Fields, nameof(c => c.DefaultPeriodTyp), [AviListDetailConst.APT_NEIN], 'Id');
//            m_ActivityType.addDisallowedListDetail(ActivityType.c_DefaultPeriodTyp, SysListDetailConst.APT_NEIN);
        }
        else
        {
            this.formFieldService.clearFieldHiddenItems(this.Fields, nameof(c => c.DefaultPeriodTyp));
//            m_ActivityType.addDisallowedListDetail(ActivityType.c_DefaultPeriodTyp, null);
            if (this.Model.DefaultPeriodTyp?.Id != AviListDetailConst.APT_NEIN)
                this.Model.DefaultPeriodTyp = await this.getListType('activity_periodtyp', AviListDetailConst.APT_NEIN);
        }
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1)) {
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung3 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung4 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung5 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung6 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung7 = this.Model.Bezeichnung1;
        }

        else if (data.field === nameof(c => c.KurzBez1)) {
            this.Model.KurzBez2 = this.Model.KurzBez1;
            this.Model.KurzBez3 = this.Model.KurzBez1;
            this.Model.KurzBez4 = this.Model.KurzBez1;
            this.Model.KurzBez5 = this.Model.KurzBez1;
            this.Model.KurzBez6 = this.Model.KurzBez1;
            this.Model.KurzBez7 = this.Model.KurzBez1;
        }

        else if (data.field === nameof(c => c.ReminderEnabled)) {
            this.initReminder();

            if (this.Model.ReminderEnabled.Id === AviListDetailConst.NEIN) {
                this.Model.Remember_Months = 0;
                this.Model.Remember_Days = 0;
                this.Model.Remember_Hours = 0;
                this.Model.Remember_Minutes = 0;
            }
        }

        else if (data.field === nameof(c => c.EscalationToType)) {
            this.initEscalation();

            if (this.Model.EscalationToType.Id !== AviListDetailConst.ACTIVITY_ESKALATION_TO_TYP_FIX_PERSON)
                this.Model.EscalationTo_ID = null;                
        }
        
        else if (data.field === nameof(c => c.ActivityBeschreibungKommentar)) {
            this.initDescription();

            if (this.Model.DescriptionPflicht.Id === AviListDetailConst.JA && this.Model.ActivityBeschreibungKommentar.Id === AviListDetailConst.ACTIVITY_BESCHREIBUNG_KOMMENTAR_NUR_KOMMENTAR)
                this.Model.DescriptionPflicht = await this.getListType('janein', AviListDetailConst.NEIN);
        }

        else if (data.field === nameof(c => c.ActivityPeriodeSichtbar)) {
            this.initPeriodSichtbar();
            if (this.Model.ActivityPeriodeSichtbar.Id === AviListDetailConst.NEIN)
                this.Model.ActivityPeriodeLabel = null;
        }

        else if (data.field === nameof(c => c.DescriptionPflicht)) {
            if (this.Model.DescriptionPflicht.Id === AviListDetailConst.JA && this.Model.ActivityBeschreibungKommentar.Id === AviListDetailConst.ACTIVITY_BESCHREIBUNG_KOMMENTAR_NUR_KOMMENTAR)
                this.Model.ActivityBeschreibungKommentar = await this.getListType('activity_beschreibung_kommentar', AviListDetailConst.ACTIVITY_BESCHREIBUNG_KOMMENTAR_BEIDE);
        }

        else if (data.field === nameof(c => c.DefaultPeriodTyp) || data.field === nameof(c => c.PeriodPossible))
            await this.setFromPeriodType();

        else if (data.field === nameof(c => c.DefaultPeriodDayTyp))
            await this.setPeriodDayTyp();

        else if (data.field === nameof(c => c.vonContext1ModelMeta_ID))
            await this.setContextModelMeta(1, !this.ReadOnly);
        else if (data.field === nameof(c => c.vonContext2ModelMeta_ID))
            await this.setContextModelMeta(2, !this.ReadOnly);
        else if (data.field === nameof(c => c.vonContext3ModelMeta_ID))
            await this.setContextModelMeta(3, !this.ReadOnly);
    }

    private async setContextModelMeta(idx: number, setBezeichnung: boolean) {
        if (this.Model.getProperty<string>(`vonContext${idx}ModelMeta_ID`) == null) {
            for (let i=1; i<=7; ++i) {
                const fieldName = `Context${idx}Bezeichnung${i}`;
                if (setBezeichnung)
                    this.Model.setProperty<string>(fieldName, null);
                this.formFieldService.setFieldReadonly(this.Fields, fieldName, true);
                this.formFieldService.setFieldRequired(this.Fields, fieldName, false);
            }
            this.Model.setProperty(`Context${idx}Zwingend`, false);
        }
        else
        {
            const rlist = await this.apiService.getModelList(ReferenceDto, `model/representations/ModelMeta?id=${this.Model.getProperty<string>(`vonContext${idx}ModelMeta_ID`)}`);
            if (rlist?.length === 1) {
                let repr = rlist[0].Representation;
                if (repr.startsWith('CAF'))
                    repr = repr.substring(3);

                for (let i=1; i<=7; ++i) {
                    const fieldName = `Context${idx}Bezeichnung${i}`;
                    if (setBezeichnung)
                        this.Model.setProperty<string>(fieldName, repr);
                    this.formFieldService.setFieldReadonly(this.Fields, fieldName, false);
                    this.formFieldService.setFieldRequired(this.Fields, fieldName, true);
                }
            }
        }        
    }

    getParams(): { [k: string]: any; } {
        return { 'Mode': this.Mode };
    }
    
    initParams(params: { [k: string]: any; }) {
        if (params && params.Mode)
            this.Mode = params.Mode;
    }

    initFields() {

        // Aktivität
        if (this.Mode === 'normal' || this.Mode === 'activity') {
            if (this.Mode === 'normal')
                this.Fields.push(this.formFieldService.CreateStep('CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_ACTIVITYTYPE'));
    
            if (this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateGroup('grp1', 'CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_ACTIVITYTYPE'));
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.ACTIVITYTYPE.KURZBEZ1', true).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.ACTIVITYTYPE.BEZEICHNUNG1', true).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'CORE.COMMON.MODEL.ACTIVITYTYPE.KURZBEZ2', true).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.ACTIVITYTYPE.BEZEICHNUNG2', true).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'CORE.COMMON.MODEL.ACTIVITYTYPE.KURZBEZ3', true).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.ACTIVITYTYPE.BEZEICHNUNG3', true).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'CORE.COMMON.MODEL.ACTIVITYTYPE.KURZBEZ4', true).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.ACTIVITYTYPE.BEZEICHNUNG4', true).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez5), 'CORE.COMMON.MODEL.ACTIVITYTYPE.KURZBEZ5', true).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung5), 'CORE.COMMON.MODEL.ACTIVITYTYPE.BEZEICHNUNG5', true).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez6), 'CORE.COMMON.MODEL.ACTIVITYTYPE.KURZBEZ6', true).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung6), 'CORE.COMMON.MODEL.ACTIVITYTYPE.BEZEICHNUNG6', true).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez7), 'CORE.COMMON.MODEL.ACTIVITYTYPE.KURZBEZ7', true).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung7), 'CORE.COMMON.MODEL.ACTIVITYTYPE.BEZEICHNUNG7', true).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DefaultPriority), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DEFAULTPRIORITY', 'activity_priority', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.StatusWorkflowDef_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.STATUSWORKFLOWDEF', this.queryStatusWorkflowDef, true).setAutoselectSingle().setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ActivityPeriodeSichtbar), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYPERIODESICHTBAR', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ActivityPeriodeLabel), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYPERIODELABEL', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ReminderEnabled), 'CORE.COMMON.MODEL.ACTIVITYTYPE.REMINDERENABLED', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ContinueComment), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTINUECOMMENT', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
    
            // this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonSqlJobDefCheck_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.VONSQLJOBDEFCHECK', `model/representations/CAFSqlJobDef${this.ReadOnly ? '?id=$id' : ''}`, false, false, 'vonSqlJobDefCheck wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
            // this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.ValidatorService_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.VALIDATORSERVICE', `model/representations/CAFService${this.ReadOnly ? '?id=$id' : ''}`, false, false, 'ValidatorService wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
    
            // this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.CreateTask_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CREATETASK', `model/representations/CAFTask${this.ReadOnly ? '?id=$id' : ''}`, false, false, 'CreateTask wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());        
            // this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Task_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.TASK', `model/representations/CAFTask${this.ReadOnly ? '?id=$id' : ''}`, false, false, 'Task wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
            
            // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.CreateTaskContext), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CREATETASKCONTEXT', 'activity_taskcontext', true, true, this.ReadOnly).setMDSizeHalf());
            // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.TaskContext), 'CORE.COMMON.MODEL.ACTIVITYTYPE.TASKCONTEXT', 'activity_taskcontext', true, true, this.ReadOnly).setMDSizeHalf());
    
            // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.CloseActivityAfterTask), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CLOSEACTIVITYAFTERTASK', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.ActivityQueue_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.RDEFAULTQUEUE', `model/representations/ActivityQueue${this.ReadOnly ? '?id=$id' : ''}`, true, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('gfill1'));
    
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.FolgeAktivitaet_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.FOLGEAKTIVITAET', `model/representations/ActivityType${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.FolgeActZeigeWizard), 'CORE.COMMON.MODEL.ACTIVITYTYPE.FOLGEACTZEIGEWIZARD', '', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.CustomProperties_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CUSTOMPROPERTIES', `model/representations/CAFCustomPropCollectionDef${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.ReportDef_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.REPORTDEF', `model/representations/ReportDef${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.PeriodExtendType), 'CORE.COMMON.MODEL.ACTIVITYTYPE.PERIODEXTENDTYPE', 'activity_periodextendtyp', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.IsRTF), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ISRTF', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        }

        // Periode
        if (this.Mode === 'normal' || this.Mode === 'periode') {
            if (this.Mode === 'normal')
                this.Fields.push(this.formFieldService.CreateStep('CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_PERIODE'));

            if (this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateGroup('grp2', 'CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_PERIODE'));
    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.PeriodPossible), 'CORE.COMMON.MODEL.ACTIVITYTYPE.PERIODPOSSIBLE', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('gfill2'));
    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DefaultPeriodTyp), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DEFAULTPERIODTYP', 'activity_periodtyp', true, true, this.ReadOnly).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DefaultPeriod), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DEFAULTPERIOD', 0, false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DefaultPeriodDayTyp), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DEFAULTPERIODDAYTYP', 'activity_periodday', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DefaultPeriodMonthDay), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DEFAULTPERIODMONTHDAY', 0, false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.PeriodActivityDescriptionSource), 'CORE.COMMON.MODEL.ACTIVITYTYPE.PERIODACTIVITYDESCRIPTIONSOURCE', 'activity_perioddescriptionsource', true, true, this.ReadOnly).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.PeriodActivityShowNewWizardOnFinish), 'CORE.COMMON.MODEL.ACTIVITYTYPE.PERIODACTIVITYSHOWNEWWIZARDONFINISH', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        }

        // Defaults
        if (this.Mode === 'normal' || this.Mode === 'defaults') {
            if (this.Mode === 'normal')
                this.Fields.push(this.formFieldService.CreateStep('CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_DEFAULTS'));
    
            if (this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateGroup('grp3', 'CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_DEFAULTS'));
    
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DefaultOwner_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DEFAULTOWNER', `model/representations/AppUser${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DefaultAssignedTo_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DEFAULTASSIGNEDTO', `model/representations/AppUser${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.DefaultTitel), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DEFAULTTITEL', false).setMDSizeFull());
            this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.DefaultDescription), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DEFAULTDESCRIPTION', false).setMDSizeFull());
            
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DescriptionPflicht), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DESCRIPTIONPFLICHT', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ActivityBeschreibungKommentar), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYBESCHREIBUNGKOMMENTAR', 'activity_beschreibung_kommentar', true, true, this.ReadOnly).setMDSizeHalf());
        }

        // Kontexte
        if (this.Mode === 'normal' || this.Mode === 'contexts') {
            if (this.Mode === 'normal')
               this.Fields.push(this.formFieldService.CreateStep('CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_KONTEXTE'));

            if (this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateGroup('grp4', 'CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_KONTEXTE'));
    
            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonContext1ModelMeta_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.VONCONTEXT1MODELMETA', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.Context1Zwingend), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT1ZWINGEND', '', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung1), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT1BEZEICHNUNG1', false).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung2), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT1BEZEICHNUNG2', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung3), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT1BEZEICHNUNG3', false).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung4), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT1BEZEICHNUNG4', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung5), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT1BEZEICHNUNG5', false).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung6), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT1BEZEICHNUNG6', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung7), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT1BEZEICHNUNG7', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('ctxfill1'));
            
            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonContext2ModelMeta_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.VONCONTEXT2MODELMETA', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.Context2Zwingend), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT2ZWINGEND', '', false).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung1), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT2BEZEICHNUNG1', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung2), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT2BEZEICHNUNG2', false).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung3), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT2BEZEICHNUNG3', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung4), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT2BEZEICHNUNG4', false).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung5), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT2BEZEICHNUNG5', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung6), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT2BEZEICHNUNG6', false).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung7), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT2BEZEICHNUNG7', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('ctxfill2'));
    
            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonContext3ModelMeta_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.VONCONTEXT3MODELMETA', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.Context3Zwingend), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT3ZWINGEND', '', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context3Bezeichnung1), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT3BEZEICHNUNG1', false).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context3Bezeichnung2), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT3BEZEICHNUNG2', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context3Bezeichnung3), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT3BEZEICHNUNG3', false).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context3Bezeichnung4), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT3BEZEICHNUNG4', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context3Bezeichnung5), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT3BEZEICHNUNG5', false).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context3Bezeichnung6), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT3BEZEICHNUNG6', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context3Bezeichnung7), 'CORE.COMMON.MODEL.ACTIVITYTYPE.CONTEXT3BEZEICHNUNG7', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('ctxfill3'));
        }

        // Fälligkeiten
        if (this.Mode === 'normal' || this.Mode === 'dates') {
            if (this.Mode === 'normal')
                this.Fields.push(this.formFieldService.CreateStep('CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_DATES'));
            
            if (this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateGroup('grp5', 'CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_DATES'));
    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DatePrecision), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DATEPRECISION', 'activity_dateprecision', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ReminderBeforeStart), 'CORE.COMMON.MODEL.ACTIVITYTYPE.REMINDERBEFORESTART', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Remember_Months), 'CORE.COMMON.MODEL.ACTIVITYTYPE.REMEMBER_MONTHS', 0, false).setMDSize(3));        
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Remember_Days), 'CORE.COMMON.MODEL.ACTIVITYTYPE.REMEMBER_DAYS', 0, false).setMDSize(3));    
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Remember_Hours), 'CORE.COMMON.MODEL.ACTIVITYTYPE.REMEMBER_HOURS', 0, false).setMDSize(3));        
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Remember_Minutes), 'CORE.COMMON.MODEL.ACTIVITYTYPE.REMEMBER_MINUTES', 0, false).setMDSize(3));
    
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DueIn_Months), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DUEIN_MONTHS', 0, false).setMDSize(3));        
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DueIn_Days), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DUEIN_DAYS', 0, false).setMDSize(3));    
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DueIn_Hours), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DUEIN_HOURS', 0, false).setMDSize(3));        
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DueIn_Minutes), 'CORE.COMMON.MODEL.ACTIVITYTYPE.DUEIN_MINUTES', 0, false).setMDSize(3));
    
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Escalate_Months), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ESCALATE_MONTHS', 0, false).setMDSize(3));        
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Escalate_Days), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ESCALATE_DAYS', 0, false).setMDSize(3));    
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Escalate_Hours), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ESCALATE_HOURS', 0, false).setMDSize(3));
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Escalate_Minutes), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ESCALATE_MINUTES', 0, false).setMDSize(3));
    
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.EscalationActivityType_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ESCALATIONACTIVITYTYPE', `model/representations/ActivityType${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.EscalationToType), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ESCALATIONTOTYPE', 'activity_eskalation_to_typ', true, true, this.ReadOnly).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.EscalationTo_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.ESCALATIONTO', `model/representations/AppUser${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('fkfill1'));
        }
                
        // Berechtigungen
        if (this.Mode === 'normal' || this.Mode === 'rights') {
            if (this.Mode === 'normal')
                this.Fields.push(this.formFieldService.CreateStep('CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_RIGHTS'));

            if (this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateGroup('grp6', 'CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_RIGHTS'));
    
            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.CreateRole_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.RCREATEROLE', false, 'Representation', 'Id', 2, `model/representations/TechRole`).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.UpdateRole_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.RUPDATEROLE', false, 'Representation', 'Id', 2, `model/representations/TechRole`).setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.DeleteRole_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.RDELETEROLE', false, 'Representation', 'Id', 2, `model/representations/TechRole`).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.DateRole_ID), 'CORE.COMMON.MODEL.ACTIVITYTYPE.RDATEROLE', false, 'Representation', 'Id', 2, `model/representations/TechRole`).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.RightToDelete), 'CORE.COMMON.MODEL.ACTIVITYTYPE.RIGHTTODELETE', 'activity_rechten', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.RightToChangeDates), 'CORE.COMMON.MODEL.ACTIVITYTYPE.RIGHTTOCHANGEDATES', 'activity_rechten', true, true, this.ReadOnly).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.RightToFinish), 'CORE.COMMON.MODEL.ACTIVITYTYPE.RIGHTTOFINISH', 'activity_rechten', true, true, this.ReadOnly).setMDSizeHalf());
        }
    }
}
