import { StatusWechselDef } from '@avi-x/avi-dto/workflow/statuswechseldef.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AviCommonService, AviApiService, AviDialogComponent, AviListDetailConst, AviSessionControllerService } from '@avi-x/avi-core';
import { AviActivityFormComponent } from '../activity-form/activity-form.component';
import { AviActivityService } from '../../services/activity.service';
import { map } from 'rxjs/operators';
import { ActivitySearchResponseDto } from '@avi-x/avi-dto/activity/activitysearchresponsedto.model';

interface IActivityTask {
    Bezeichnung: string;
    StatusWechselDef: StatusWechselDef;
}

@Component({
    selector: 'avi-crm-activity-view',
    templateUrl: './activity-view.component.html',
    styleUrls: ['./activity-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviActivityViewComponent implements OnInit {
    FormModelId: string = null;

    EditFormModelId: string = null;
    EditStatusWechselDef: StatusWechselDef = null;
    formActionButtons: any = [];

    @ViewChild('activityForm', { static: false })
    public activityForm: AviActivityFormComponent;

    @ViewChild('editActivityFormDialog', { static: false })
    editActivityFormDialog: AviDialogComponent;

    @ViewChild('editActivityForm', { static: false })
    public editActivityForm: AviActivityFormComponent;

    public SubTasks: ActivitySearchResponseDto[] = [];
    // public ChangeLog: any[] = [];

    public Tasks: IActivityTask[] = [];
    IsRTF: boolean = true;
    
    @Input('activity-id')
    public set ActivityId(value: string) {
        this.init(value);
    }
    public get ActivityId() {
        return this.FormModelId;
    }

    constructor(public apiService: AviApiService, public router: Router, public activityService: AviActivityService, public sessionController: AviSessionControllerService, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
            this.init(res);
        });

        this.formActionButtons = [];
        this.formActionButtons.push({  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.closeForm(); } });
    }

    getTitel(attrList: any[]) {
        return attrList.filter(w => w.Field === 'Titel')[0].NewValue;
    }

    async init(res) {
        if (res) {
            this.FormModelId = res;
            const swDefList = await this.activityService.GetStatusWorkflowDefStatusWechsel(this.FormModelId);
            this.IsRTF = await this.activityService.IsRTF(this.FormModelId);
            const actRes = await this.apiService.postPagedResult(ActivitySearchResponseDto,  'activity/search', { ParentActivityId: this.FormModelId });
            this.SubTasks = actRes.Results; //await this.apiService.get(`activity?filter=ParentActivity_ID eq ${this.FormModelId}&init=StatusDef&sort=StartDate,Erstelldatum&select=Id,Titel,StartDate,DueDate,StatusDef,Priority,rAssignedTo.Username,rActivityType.DatePrecision`);

            await this.activityForm.InitForm(this.FormModelId);

            // this.ChangeLog = await this.apiService.get(`changelog/GetModelChangeList/${this.FormModelId}`);
            // this.ChangeLog = this.ChangeLog.reverse();
            // this.ChangeLog.forEach(w => {
            //     w.ErstellDatum = new Date(w.ErstellDatum).toLocaleString('de-CH');
            // });

            this.Tasks = [];
            swDefList.forEach(w => this.addTask(w));

            this.cdr.markForCheck();
        }
    }

    openActivity(id: string) {
        this.sessionController.openSession('activity-index', id);
    }

    addTask(statusWechselDef: StatusWechselDef) {
        this.Tasks.push({ Bezeichnung: statusWechselDef.Bezeichnung1, StatusWechselDef: statusWechselDef });
    }

    public performTask(task: IActivityTask) {
        this.EditStatusWechselDef = task.StatusWechselDef;
        this.editActivity();
    }

    public async createActivity() {
        await this.editActivityForm.InitForm('0', this.FormModelId);
        this.editActivityFormDialog.open();
    }

    public async editActivity() {
        const showForm = await this.editActivityForm.InitForm(this.FormModelId);
        if (showForm)
            this.editActivityFormDialog.open();
        else {
            this.editActivityForm.Save();
        }
    }

    public onOpenEditForm() {
        this.editActivityForm.RefreshEditors();
    }

    async refresh() {
        await this.activityForm.Refresh();
        await this.init(this.FormModelId);
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.refresh();
    }

    private closeForm() {
        this.editActivityForm.ClearForm();
        this.EditStatusWechselDef = null;
        this.editActivityFormDialog.close();
    }

    deleteActivity() {
        console.log('Go away!');
    }
}
