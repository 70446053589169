import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';
import { AviDokumentService } from '../../../dokument/services/dokument.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

// eslint-disable-next-line no-unused-vars
export enum AviPreviewType {
    // eslint-disable-next-line no-unused-vars
    UNKNOWN, PDF, CODE, TEXT, IMAGE
}

@Component({
    selector: 'avi-crm-dokument-preview',
    styles: [
        `::ng-deep avi-crm-dokument-preview {
            display: grid;
            height: 100%;
            width: 100%;
        }
            
        ::ng-deep .dokument-preview-dialog .p-dialog-content {
            padding: 0;
        }

        ::ng-deep .dokument-preview-dialog.p-dialog {
            max-height: 100%;
        }

        .pointer-events-none { pointer-events: none ; }
        .pointer-events-all { pointer-events: all ; }        
        `
    ],
    template: `
        <div class="flex-column flex-1" [ngSwitch]="Previewer">
            <div *ngIf="Loading" style="position: absolute; margin-left: 50%; top: 50%">
                <p-progressSpinner></p-progressSpinner>
            </div>

            <ng-container *ngIf="!Loading">
                <avi-core-pdf-viewer *ngSwitchCase="PreviewType.PDF" height="100%" [class]="PdfViewerClass" [src]="Preview"></avi-core-pdf-viewer>
    
                <code *ngSwitchCase="PreviewType.CODE" class="preview-area" [highlight]="Preview"></code>
        
                <code *ngSwitchCase="PreviewType.TEXT" class="preview-area">
                    <div class="text-preview-area">{{ Preview }}</div>
                </code>
        
                <div *ngSwitchCase="PreviewType.IMAGE" class="preview-area">
                    <img class="image-preview-area" [src]="Preview" />
                </div>
        
                <div *ngSwitchCase="PreviewType.UNKNOWN" class="preview-area" style="background: #fff; color: #aaa;">
                    Bitte Dokument auswählen
                </div>
            </ng-container>
        </div>    
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDokumentPreviewComponent implements OnInit {
    @Input('pdfviewer-class')
    PdfViewerClass: string;

    @Input('api')
    public Api: string = '/avidoc';

    private _previewArchivDefId: string;
    private _previewDocId: string;
    private _lastPreviewTime: Date;

    public Preview: any;
    public PreviewType = AviPreviewType;
    public Previewer: AviPreviewType = AviPreviewType.UNKNOWN;

    Loading: boolean = false;

    constructor(
        private sanitizer: DomSanitizer,
        private apiService: AviApiService,
        public dokumentService: AviDokumentService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private cdr: ChangeDetectorRef) {
    }
    
    ngOnInit() {
        if (this.config && this.config.data) {
            
            if (this.config.data.Api)
                this.Api = this.config.data.Api;
            
            if (this.config.data.DocId && this.config.data.ArchivDefId)
                this.load(this.config.data.ArchivDefId, this.config.data.DocId);
            else if (this.config.data.Blob)
                this.loadBlob(this.config.data.Blob);
        }
    }
    
    public clear() {
        this.Preview = null;
        this.Previewer = AviPreviewType.UNKNOWN;
        this._previewDocId = null;
        this._previewArchivDefId = null;
        this.Loading = false;
    }

    public async loadBlob(blob: Blob) {
        if (blob != null) {
            this.Previewer = AviPreviewType.PDF;
            this.Loading = false;
            this.cdr.detectChanges();
            setTimeout(() => this.setPreview(URL.createObjectURL(blob)));
        } else {
            this.Previewer = AviPreviewType.UNKNOWN;
            this.Loading = false;
            this.cdr.detectChanges();
        }
    }

    public async load(archivDefId: string, docId: string, forceReload: boolean = false) {
        if (archivDefId && docId && (this._previewArchivDefId !== archivDefId || this._previewDocId !== docId || forceReload)) {
            this.Loading = true;
            this._previewArchivDefId = archivDefId;
            this._previewDocId = docId;

            const currentTime = new Date();
            const blob = await this.apiService.getBlob(`${this.Api}/GetDocumentPreview/${archivDefId}/${docId}`);
            if (blob && (this._lastPreviewTime == null || currentTime > this._lastPreviewTime)) {
                this._lastPreviewTime = new Date();
                this.Preview = null;

                if (blob.type === 'text/xml' || blob.type === 'text/html' || blob.type === 'text/plain') {
                    this.Previewer = blob.type === 'text/plain' ? AviPreviewType.TEXT : AviPreviewType.CODE;
                    this.Loading = false;
                    this.cdr.detectChanges();
                    const reader = new FileReader();

                    reader.addEventListener('loadend', (e) => {
                        const text = reader.result;
                        setTimeout(() => this.setPreview(text));
                    });

                    reader.readAsText(blob);
                } else if (blob.type === 'application/pdf') {
                    this.Previewer = AviPreviewType.PDF;
                    this.Loading = false;
                    this.cdr.detectChanges();
                    setTimeout(() => this.setPreview(URL.createObjectURL(blob)));
                } else if (blob.type.startsWith('image/')) {
                    this.Previewer = AviPreviewType.IMAGE;
                    this.Loading = false;
                    this.cdr.detectChanges();
                    setTimeout(() => this.setPreview(this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))));
                } else {
                    this.Previewer = AviPreviewType.UNKNOWN;
                    this.Loading = false;
                    this.cdr.detectChanges();
                }
            }
            this.Loading = false;
        }
    }

    private setPreview(preview: any) {
        this.Preview = preview;
        this.cdr.markForCheck();
    }
}
