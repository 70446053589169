import { VwbPInternRechtstraegerBaseComponent } from './../../../rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-searcher-base.component';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

import { AviCommonService, AviApiService, AviJobService, AviFormField, AviFormFieldType } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';
import { RTVerMode } from '../../../rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';

@Component({
    selector: 'vwbp-intern-mahnwesen-busse-searcher',
    templateUrl: './mahnwesen-busse-searcher.component.html'
})
export class VwbPInternMahnwesenBusseSearcherComponent extends VwbPInternRechtstraegerBaseComponent {
    toolbarButtons : any[] = [
        { title: 'Excel Export', class: 'p-button-secondary', icon: 'pi pi-download', action: () => this.OnExportClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0, visible: () => this.CheckboxSelection },
        { title: 'Serienbrief', class: 'p-button-secondary', icon: 'pi pi-envelope', action: () => this.OnSerienbriefClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0, visible: () => this.CheckboxSelection },
    ];

    constructor(
        public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        public vwbpCommonService: VwbPCommonService,
        public cdr: ChangeDetectorRef,
        private postfachService: VwbPInternPostfachService,
        private jobService: AviJobService,
        private rechtstraegerService: VwbPInternRechtstraegerService
    ) {
        super(apiService, router, commonService, vwbpCommonService, cdr);
        this.OpenMode = 'mahnwesen';
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any = null) => this.doSearch(searchValue, '/v1/intern/vwbp/mahnwesen/bussen', searchConfig);

    public SelectAllDelegate: any = async (searchValue: string, searchConfig: any = null) => {
        const settings = this.buildSearchConfigAsObject();
        return await this.apiService.post('v1/intern/vwbp/mahnwesen/bussen/ids', settings);
    }

    async OnExportClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.RechtstraegerId);
        this.rechtstraegerService.OpenExcelExportJobDialog(rechtstraegerIds);
    }

    async OnSerienbriefClick() {
        const versionIds = this.GetSelectedRechtstraeger().map(w => w.RechtstraegerId);
        this.rechtstraegerService.OpenSerienbriefJobDialog(versionIds, RTVerMode.RECHTSTRAEGER);
    }

    public async onSearcherInit(data) {
        if (this.ShowAktiv) {
            const field = new AviFormField('aktiv', 'aktiv', AviFormFieldType.CHECKBOX, false, false);
            field.Value = true;
            this.searcher.SearcherParameters.push(field);
        }

        this.searcher.beginInit();

        this.searcher.addTextColumn('Name', 'Name', true);
        this.searcher.addTextColumn('Rechtsform', 'Rechtsform', true);
        this.searcher.addTextColumn('RegisterNummer', 'Registernummer', true);
        this.searcher.addDateColumn('Eingangsdatum', 'Eingangsdatum', null, true);
        this.searcher.addDateColumn('RegisterDatum', 'Registerdatum', null, true);
        this.searcher.addListTypeColumn('Erfassungsstatus', 'Erfassungsstatus', 'vwbp_erfassungsstatus', true, true, null, 'v1/vwbp',
            [VwbPListDetailConst.VWEG_ERFASSUNGSSTATUS_VERANTWORTLICHKEIT_ABGEGEGEBEN, VwbPListDetailConst.VWEG_ERFASSUNGSSTATUS_RECHTSTRAEGER_STATUS_GELOESCHT]);
        this.searcher.addIntegerColumn('Version', 'Version', true);
        // this.searcher.addDateColumn('Versionsdatum', 'VWBP.COMMON.VERSIONSDATUM', null, true);
        this.searcher.addDateColumn('Freigabedatum', 'VWBP.COMMON.FREIGABEDATUM', null, true);
        this.searcher.addDateColumn('Bussfrist', 'Bussfrist', null, true);
        this.searcher.addDateColumn('Rechtsmittelfrist', 'Rechtsmittelfrist', null, true);
        this.searcher.addIntegerColumn('MahnungCount', 'Mahnungen', true);
        this.searcher.addIntegerColumn('BussCount', 'Bussen', true);
        this.searcher.addListTypeColumn('Typ', 'Typ', 'vwbp_rechtstraeger_typ', true, true, null, 'v1/vwbp');

        this.searcher.addIntegerColumn('BemerkungCount', 'Offene Bemerkungen', true).setVisible(false);
        this.searcher.addTextColumn('BusseId', 'BusseId', true).setVisible(false);
        this.searcher.addListTypeColumn('BusseStatus', 'BusseStatus', 'vwbp_buss_status', true, false, null, 'v1/vwbp').setVisible(false);
        this.searcher.addTextColumn('RechtstraegerId', 'RechtstraegerId', true).setVisible(false);
        this.searcher.addListTypeColumn('MahnwesenStatus', 'MahnwesenStatusId', 'vwbp_mahnwesenstatus', true, false, null, 'v1/vwbp').setVisible(false);
        this.searcher.addListTypeColumn('MahnwesenAktiv', 'MahnwesenAktivId', 'vwbp_mahnwesen_aktiv', true, false, null, 'v1/vwbp').setVisible(false);

        // this.AddColumBemerkungen();
        this.AddColumnAuge();

        this.searcher.endInit();

        this.onInit.emit(null);
    }
}
