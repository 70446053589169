import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatTabNav } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'vwbp-admin-job-index',
    templateUrl: './vwbp-admin-job-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPAdminJobIndexComponent {

    navLinks = [
        { path: './job', label: 'CORE.COMMON.MODEL.JOB.JOB_1' },
        { path: './job-logging', label: 'CORE.COMMON.MODEL.JOB.ALLELOGGING' },
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public translateService: TranslateService
    ) {
    }
}
