import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AviBaseFormComponent, AviCommonService, AviFormField, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { VwbPCommonService, } from '@avi-x/vwbp-public';
import { VwbPAdminService } from '../../../common/vwbp-admin.service';

import * as moment from 'moment';


export class VwbPBehoerdensucheLogExportRequestDto {
    public DatumStart?: Date;
    public DatumEnde?: Date;
}

const nameof = (nameFunction: ((obj: VwbPBehoerdensucheLogExportRequestDto) => any)) => typed_nameof<VwbPBehoerdensucheLogExportRequestDto>(nameFunction);

@Component({
    selector: 'vwbp-admin-behoerden-suche',
    templateUrl: './behoerden-suche.component.html',
    styleUrls: ['./behoerden-suche.component.scss']
})
export class VwbPAdminBehoerdenSucheComponent implements OnInit {


    public Loading: boolean = false;
    public Model: VwbPBehoerdensucheLogExportRequestDto = null;

    public Fields: AviFormField[] = [];


    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;


    constructor(
        private commonService: AviCommonService,
        private vwbpAdminService: VwbPAdminService,
        // private behoerdenService: VwbPInternBehoerdenService,
        private vwbpCommonService: VwbPCommonService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {

        this.initFields();
        this.Model = {
            DatumStart: moment().startOf('month').startOf('day').toDate(),
            DatumEnde: moment().subtract(0, 'days').startOf('day').toDate(),
        };

    }

    initFields() {

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.DatumStart), 'Datum von', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.DatumEnde), 'Datum bis', true).setMDSizeHalf());

    }

    startExport($event) {
        const dtStart = moment($event.DatumStart).startOf('day').toDate();
        const dtEnd = moment($event.DatumEnde).endOf('day').toDate();
        this.vwbpAdminService.DownloadBehoerdensucheLogs(dtStart, dtEnd);
    }

}
