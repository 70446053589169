<avi-core-page *ngIf="Model" [title]="PageTitle" [contents-padding]="false" [no-box-shadow]="true" [show-fullscreen-icon]="true" [show-refresh-button]="true" (onRefresh)="refreshBusinesscase(BusinessViewRefreshMode.All)" [navigate-back]="businesscaseService.UseRouting">

    <div class="grid grid-nogutter">

        <div class="col-{{ Intern ? 7 : 12 }} pr-0">
            <!-- Businesscase -->
            <avi-businesscase-form 
                [card]="false" 
                [inline-modus]="true" 
                [contents-padding]="false" 
                [readonly]="false"
                [model-id]="Model.Id" 
                [enable-taskmenu]="true" 
                (onChanged)="refreshBusinesscase($event)"
                (onTaskMenuHeightChanged)="onTaskMenuHeightChanged($event)"
            >
            </avi-businesscase-form>

            <!-- Dokumente -->
            <avi-businesscase-dokumente-view 
                [data-verknuepfen]="DokVerknuepfenData" 
                [data-erstellen]="DokErstellenData" 
                [businesscase]="Model"
                (onChanged)="refreshBusinesscase(BusinessViewRefreshMode.Dokumente)">
            </avi-businesscase-dokumente-view>

            <!-- Formulare -->
            <avi-businesscase-formulare-view 
                [data]="FormData" 
                [businesscase]="Model"
                (onChanged)="refreshBusinesscase(BusinessViewRefreshMode.Formulare)">
            </avi-businesscase-formulare-view>
            
            <!-- Kommentare -->
            <avi-core-view-panel *ngIf="!Intern && ShowComments" header="Kommentare" [no-pad]="true" panel-class="p-2">
                <div class="ui-g businesscase-card-contents">
                    <div class="ui-g-12">
                        <avi-crm-comment-list 
                            [context-id]="Model.Id"
                            context-type="aviita.CAF.common.modul.businesscase.model.CAFBusinesscase"
                            [rtf]="true"
                            [comments-collapsible]="false"
                            [enable-erledigen]="false"
                            [show-statusubergang]="Intern"
                            [enable-intern]="Intern"
                            [enable-erfassen]="CanCreateComments"
                            (onChanged)="refreshBusinesscase(BusinessViewRefreshMode.Verlauf)"
                        >
                        </avi-crm-comment-list>
                    </div>
                </div>
            </avi-core-view-panel>

        </div>

        <div *ngIf="Intern" class="col-5 pl-0">

            <div [ngStyle]="{ 'height': TaskMenuHeight }"></div>

            <!-- Activities -->
            <avi-businesscase-activities-view 
                *ngIf="Intern" 
                [model-id]="Model.Id"
                [enable-erfassen]="CanCreateActivities"
                (onChanged)="refreshBusinesscase(BusinessViewRefreshMode.Verlauf)">
            </avi-businesscase-activities-view>

            <!-- Kommentare -->
            <avi-core-view-panel *ngIf="ShowComments" header="Kommentare" [no-pad]="true" panel-class="p-2">
                <div class="ui-g businesscase-card-contents">
                    <div class="ui-g-12">
                        <avi-crm-comment-list 
                            [context-id]="Model.Id"
                            context-type="aviita.CAF.common.modul.businesscase.model.CAFBusinesscase"
                            [rtf]="true"
                            [comments-collapsible]="false"
                            [enable-erledigen]="false"
                            [show-statusubergang]="Intern"
                            [enable-intern]="Intern"
                            [enable-erfassen]="CanCreateComments"
                            (onChanged)="refreshBusinesscase(BusinessViewRefreshMode.Verlauf)"
                        >
                        </avi-crm-comment-list>
                    </div>
                </div>
            </avi-core-view-panel>

            <!-- Historie -->
            <div *ngIf="Data" class="p-2">
                <avi-verlauf-list
                    #verlauf
                    *ngIf="Intern"
                    [readonly]="true"
                    [kontext-id]="Model.Id"
                    kontext-url="cafbusinesscase"
                >
                </avi-verlauf-list>
            </div>
        </div>
    </div>

</avi-core-page>