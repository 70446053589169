import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { MatTabNav } from "@angular/material/tabs";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'vwbp-intern-behoerden-suche-wb-jp-index',
    templateUrl: './behoerden-suche-wb-jp-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPInternBehoerdenSucheWbJpIndexComponent implements OnInit {
    
    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public translateService: TranslateService,
    ) {
    }

    private _navLinks = null;
    get NavLinks(): any[] {
        if (this._navLinks === null) {
            this._navLinks = [
                { path: './inland', label: this.translateService.instant('VWBP.INTERN.BEHOERDENABFRAGE.TITLE_WB_JP_IN') },
                { path: './ausland', label: this.translateService.instant('VWBP.INTERN.BEHOERDENABFRAGE.TITLE_WB_JP_AUS') }
            ];
        }
        return this._navLinks;
    }

    ngOnInit() {
    }
}