import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviCommonService, AviAuthService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviCurrentUser } from '@avi-x/avi-core';
import { Location } from '@angular/common';

import { VwbPUserProfile } from '../../../dto/vwbpuserprofile.model';
import { VwbPCommonService } from '../../../common/vwbp-common.service';
import { VwbPPublicRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';

const nameof = (nameFunction: ((obj: VwbPUserProfile) => any)) => typed_nameof<VwbPUserProfile>(nameFunction);

@Component({
    selector: 'vwbp-public-user-profile-form',
    template: `

    <avi-core-page
    [no-box-shadow]="true"
        [collapsible]="false"
        [card]="Card"
        [contents-padding]="ContentsPadding"
    >
        <button
            *ngIf="!initialProfile"
            pButton
            type="button"
            icon="pi pi-chevron-left"
            aria-label="Go back"
            class="p-button-secondary p-button-text p-button-rounded mt-8p back-button"
            (click)="NavigateBack()"
        ></button>

        <div class="mat-expansion-panel-header-title pl-8p pt-3">
            {{ FormTitle }}
        </div>

        <avi-core-base-form #form
            [readonly]="ReadOnly"
            [card]="false"
            [contents-padding]="false"
            [fields]="Fields"
            [(Model)]="Model"
            [loading]="Loading"
            (onSave)="saveModel($event)"
            [action-buttons]="actionButtons">

        </avi-core-base-form>

    </avi-core-page>`
})
export class VwbPPublicUserProfileComponent implements OnInit, OnDestroy, AfterViewInit {
    sub: any;

    @ViewChild('form', { static: true })
    Form: AviBaseFormComponent = null;

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('card')
    public Card: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: VwbPUserProfile = null;
    public Fields: AviFormField[] = [];

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    public Api: string = 'v1/vwbp';

    initialProfile: boolean = false;

    constructor(private commonService: AviCommonService,
        private authService: AviAuthService,
        private location: Location,
        private apiService: AviApiService,
        private formFieldService: AviFormFieldService,
        private vwbpCommonService: VwbPCommonService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private rts: VwbPPublicRechtstraegerService
    ) {

    }

    public get CurrentUser(): AviCurrentUser {
        return this.authService.CurrentUser;
    }

    public get FormTitle(): string {
        return this.Model?.KontoName;
    }

    ngAfterViewInit() {
        this.loadModel();
    }

    ngOnInit() {
        if (this.vwbpCommonService.currentUserIsEnduser())
            this.apiService.get('v1/vwbp/user/profile/exists').then(w => this.initialProfile = !w);

        this.initFields();
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    NavigateBack() {
        this.location.back();
    }

    saveModel(model: VwbPUserProfile) {
        this.Form.clearFormMessages();

        const delegate: Promise<any> = this.apiService.post(`${this.Api}/user/profile`, model);

        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.initialProfile)
                this.router.navigate(['/vwbp/index']);
            else
                this.NavigateBack();
        }).catch(err => {
            console.log(err);
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel() {
        this.Model = await this.apiService.getModel(VwbPUserProfile, `${this.Api}/user/profile`);
        this.updateDropdownSources();

        return this.Model;
    }


    async updateDropdownSources() {
        if (this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        const lbl1 = this.formFieldService.CreateLabel('lbl1', 'VWBP.KONTAKTINFO.BENUTZERDATEN', true);
        lbl1.FieldClass = 'mt-16p';
        this.Fields.push(lbl1.setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'VWBP.KONTAKTINFO.NAME', false).setMDSizeFull().setMaxLength(256));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Strasse), 'VWBP.COMMON.STRASSE_NUMMER', true).setMDSizeFull().setMaxLength(512));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.PLZ), 'VWBP.COMMON.PLZ', true).setMDSizeHalf().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Ort), 'VWBP.COMMON.ORT', true).setMDSizeHalf().setMaxLength(128));

        const lbl2 = this.formFieldService.CreateLabel('lbl1', 'VWBP.KONTAKTINFO.KONTAKTPERSON', true);
        lbl2.FieldClass = 'mt-16p';
        this.Fields.push(lbl2.setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KontaktVorname), 'VWBP.KONTAKTINFO.KONTAKTVORNAME', true).setMDSizeHalf().setMaxLength(256));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KontaktName), 'VWBP.KONTAKTINFO.KONTAKTNAME', true).setMDSizeHalf().setMaxLength(256));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KontaktTelefon), 'VWBP.KONTAKTINFO.KONTAKTTELEFON', true).setMDSizeFull().setMaxLength(128).setPattern('^\\+?(\\d[\\d\\-. ]+)?(\\([\\d\\-. ]+\\))?[\\d\\-. ]+\\d$'));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KontaktEmail), 'VWBP.KONTAKTINFO.KONTAKTEMAIL', true).setMDSizeFull().setMaxLength(128).setPattern('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$'));
    }
}
