import { ScriptRuleOutput } from '@avi-x/avi-dto/system/scriptruleoutput.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, ObjectUtils, typed_nameof } from '@avi-x/avi-core';
import { ScriptRulesetDefSearchResultDto } from '@avi-x/avi-dto/system/scriptrulesetdefsearchresultdto.model';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AviScriptRulesetDefCreationParametersFormComponent } from './scriptrulesetdef-creationparameters-form.component';
import { AviScriptRulesetDefFormComponent } from './scriptrulesetdef-form.component';

const nameof = (nameFunction: ((obj: ScriptRulesetDefSearchResultDto) => any)) => typed_nameof<ScriptRulesetDefSearchResultDto>(nameFunction);

@Component({
    selector: 'avi-scriptrulesetdef-searcher',
    templateUrl: './scriptrulesetdef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRulesetDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFSCRIPTRULESETDEF';
    SettingsKey = 'settings.searcher.scriptrulesetdef';
    BaseUrl = 'scriptrulesetdef';
    FormType = AviScriptRulesetDefFormComponent;

    Page: 'searcher' | 'rulesetdef' | 'test' = 'searcher';

    bcHome: MenuItem = { label: 'Regelwerk suchen', command: () => this.Page = 'searcher' };
    bcFormdef: MenuItem = { label: 'Regelwerk', command: () => this.Page = 'rulesetdef' };
    bcTest: MenuItem = { label: 'Test Regelwerk' };

    dialogRef: DynamicDialogRef;

    RuleOutput: ScriptRuleOutput[] = [];

    getBreadcrumbs() {
        if (this.Page === 'searcher') return [];
        if (this.Page === 'rulesetdef') return [ this.bcFormdef ];
        if (this.Page === 'test') return [ this.bcFormdef, this.bcTest ];
    }

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
        public dialogService: DialogService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);
    }

    onSearcherInit(searcher) {
	    searcher.beginInit();
        searcher.addDateColumn(nameof(c => c.GueltigAb), 'Gültig ab');
        searcher.addDateColumn(nameof(c => c.GueltigBis), 'Gültig bis');
        searcher.addTextColumn(nameof(c => c.Bezeichnung1), 'Bezeichnung');
        searcher.addTextColumn(nameof(c => c.ContextModelName), 'Kontext');
//        searcher.addListTypeColumn(nameof(c => c.SaveRuleOutput), 'Regel speichern', 'caf_rulesetdef_saveoutput', true);        
        searcher.endInit();
    }
    
    onDoubleClickSearcher(data) {
        this.Page = 'rulesetdef';
        this.cdr.markForCheck();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
        const res = await this.apiService.postModelList(ScriptRulesetDefSearchResultDto, `scriptrulesetdef/search`, cfg);
        return res;
    }
    
    async onTestRulesetDef(id: string) {        
        this.dialogRef = this.dialogService.open(AviScriptRulesetDefCreationParametersFormComponent, {
            header: 'Regelwerk testen',
            closable: false,
            width: '80%',
            data: { 
                rulesetDefId: id,
                formType: AviScriptRulesetDefCreationParametersFormComponent
            }
        });
        this.dialogRef.onClose.subscribe(async (data: any) => {
            if (data) {
                this.RuleOutput = await this.apiService.postModelList(ScriptRuleOutput, 'scriptrulesetdef/execute/dryrun', ObjectUtils.BaseModelToObject(data));                
                this.Page = 'test';
                this.cdr.markForCheck();
            }
        });
    }
}
