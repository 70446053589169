import { AviAppServerInfoDto } from '@avi-x/avi-dto/system/appserverinfo.model';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { AviAuthService } from '../../services/auth.service';
import { AviCommonService, IAppVersion } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { ISessionData } from '../../services/session-controller.service';

@Component({
    selector: 'avi-core-about',
    templateUrl: './about.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAboutComponent implements OnInit {
    @Input('session-data')
    SessionData: ISessionData;

    @Input('show-thankyou')
    ShowThankyou: boolean = false;

    appVersion: IAppVersion;
    appName: string;

    serverInfo: AviAppServerInfoDto;


    constructor(public commonService: AviCommonService, public apiService: AviApiService, public authService: AviAuthService, public cdr: ChangeDetectorRef) {
        this.appVersion = this.commonService.AppVersion;
        this.appName = this.commonService.AppName;
    }

    ngOnInit() {
        this.getServerInfo();
    }

    async getServerInfo() {
        this.serverInfo = await this.apiService.getModel(AviAppServerInfoDto, 'system/serverinfo');
        this.cdr.markForCheck();
    }
}
