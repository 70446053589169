import { Component, ViewChild, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AviCommonService, AviBaseSearcherComponent, AviApiService, AviPartnerListDetailConst, AviCommunicationService } from '@avi-x/avi-core';
import { map } from 'rxjs/operators';

@Component({
    selector: 'avi-crm-bankverbindung-liste',
    templateUrl: './bankverbindung-liste.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBankVerbindungListeComponent implements OnInit, AfterViewInit {
    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    @Input('partner-id')
    public partnerId: string = null;
    
    public DetailModelId: string = null;

    constructor(
        public apiService: AviApiService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public commonService: AviCommonService,
        private cdr: ChangeDetectorRef,
        private communicationService: AviCommunicationService
    ) {
    }

    public onInit(data) {
        this.searcher.beginInit();
        this.searcher.addTextColumn('vonBank_ID', 'vonBank_ID').setVisible(false);

        this.searcher.addDateColumn('GueltigAb', 'Gültig ab');
        this.searcher.addDateColumn('GueltigBis', 'Gültig bis');
        this.searcher.addTextColumn('StammNr_ZRNR', 'StammNr/ZRNr');
        this.searcher.addTextColumn('Bezeichnung', 'Bezeichnung');
        this.searcher.addTextColumn('vonBankVerbindungArt.Bezeichnung1', 'Bankverbindungart');
        this.searcher.addTextColumn('vonBank', 'Bank').setSortable(false).OField = null;
        this.searcher.addTextColumn('Bemerkung', 'Bemerkung');
        this.searcher.endInit()
    }

    public SearchDelegate: any = async (searchValue: string) => { return this.doSearch(); };

    async doSearch(): Promise<any> {
        const res = await this.searcher.Find(this.searcher.buildSearchConfig('bankverbindung'));
        if (res)
            await Promise.all(res.map(async x => x.vonBank = await this.apiService.get(`model/representation/${x.vonBank_ID}/cafbank`)));

        return res;
    }

    onSelect(data) {
        if (data && data[0])
            this.DetailModelId = data[0].Id;
    }

    ngOnInit() {
    }

    public getFilters: any = (): string[] => {
        let res = [];
        if (this.partnerId != null)
            res = [...res, `vonPartner_ID eq ${this.partnerId}`];

        if (!this.searcher.showInaktive)
            res = [...res, 'GueltigAb le today', 'GueltigBis ge today'];

        return res;
    }

    ngAfterViewInit() {
        // this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
        //     this.partnerId = res;
        //     setTimeout(() => this.searcher.endInit(), 0);
        // });

    }

}
