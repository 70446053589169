<div class="p-12">
    <avi-core-page
        title="{{ 'VWBP.INTERN.BEHOERDENABFRAGE.SUBTITLE_WB_JP' | translate }}"
        [contents-padding]="true"
        [no-box-shadow]="true"
    >
            <div [ngClass]="{ 'mat-tab-header-autohide': NavLinks.length <= 1 }" class="mat-tab-secondary">
                <!-- <nav *ngIf="NavLinks.length > 1" mat-tab-nav-bar mat-align-tabs="center" #tabNavbar> -->
                <nav *ngIf="NavLinks.length > 1" mat-tab-nav-bar #tabNavbar>
                    <a
                        mat-tab-link
                        *ngFor="let link of NavLinks"
                        [routerLink]="link.path"
                        routerLinkActive
                        #rla="routerLinkActive"
                        [active]="rla.isActive"
                        >{{ link.label | translate }}</a
                    >
                </nav>
                <div>
                    <router-outlet></router-outlet>
                </div>
            </div>
    </avi-core-page>
</div>