<avi-core-page title="Behördensuche"
    [no-box-shadow]="true">

    <p-panel header="Daten-Export"
        [toggleable]="true">
        <avi-core-base-form *ngIf="Model"
            #form
            [card]="false"
            [fields]="Fields"
            [contents-padding]="false"
            [Model]="Model"
            [save-button-label]="'Daten exportieren'"
            [save-button-icon]="'pi pi-download'"
            [loading]="Loading"
            (onSave)="startExport($event)">
        </avi-core-base-form>
    </p-panel>
</avi-core-page>
