import { IDTO } from './../shared/idto.model';
import { Expose } from "class-transformer";

export class SysParameterSearchResultDto extends IDTO {
    @Expose()
    public _TotalRowCount: number;

    @Expose()
    public Id: string;

    @Expose()
    public ParamName: string;

    @Expose()
    public KurzBez: string;

    @Expose()
    public Bezeichnung: string;
}
