import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AviCommonService, AviApiService, AviBaseSearcherComponent, FilterUtils } from '@avi-x/avi-core';
import { PartnerRolleTreeElement } from '@avi-x/avi-dto/partner/partnerrolletreeelement';
import { map } from 'rxjs/operators';

class PartnerRolleDefChip {
    constructor(public pdef: string, public selected: boolean) {
    }
}

@Component({
    selector: 'avi-crm-partnerrolle-liste',
    templateUrl: './partnerrolle-liste.component.html',
    styleUrls: ['./partnerrolle-liste.component.scss']
})
export class AviPartnerRolleListeComponent implements OnInit {

    public PartnerRolleDefs: PartnerRolleDefChip[] = null;

    @Input('partner-id')
    private partnerId: string;

    @Input('card')
    public Card: boolean = false;

    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    public SearchDelegate: any = async (searchValue: string) => { return this.doSearch(searchValue); };

    constructor(private cdr: ChangeDetectorRef, public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService) {
    }

    public onInit(data) {
        this.searcher.beginInit();

        this.searcher.addTextColumn('RolleAktivId', 'Aktiv', false, '85px').setSortable(false).hide();
        this.searcher.addTextColumn('PartnerNr', 'PartnerNr', true);
        this.searcher.addTextColumn('PartnerRepresentation', 'Partner', true);
        this.searcher.addTextColumn('RolleDefKurzBez', 'Kurzbez', true);
        this.searcher.addTextColumn('RolleKontext', 'ist', true);
        this.searcher.addTextColumn('Beschreibung', 'Beschreibung', true);
        this.searcher.addTextColumn('Bemerkung', 'Bemerkung', true);
        this.searcher.addDateColumn('Geburtsdatum', 'Geburtsdatum', 'mediumDate', true);
        this.searcher.addDateColumn('Todesdatum', 'Todesdatum', 'mediumDate', true);
        this.searcher.addTextColumn('NatpersonStatus', 'Status', true);
        this.searcher.addTextColumn('BegAnteil', 'Anteil in %', true);
        this.searcher.addTextColumn('Laender', 'Länder', true);
        this.searcher.addTextColumn('Nationalitaeten', 'Nationalitäten', true);

        this.searcher.endInit();
    }

    ngOnInit() {
        // this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
        //     this.partnerId = res;
        // });

        // if ('submodul' in this.activatedRoute.snapshot.data) {
        //     this.Card = !this.activatedRoute.snapshot.data['submodul'];
        // }
    }

    public onSelect(data) {
    }

    public onDoubleClick(data) {
        // this.router.navigate(['/mandat/projekt', data.id ]);
        // this.router.navigate(['/compliance/trxcheckdesk', { mid: data.id }])
    }

    togglePartnerRolleDef(item: PartnerRolleDefChip) {
        item.selected = !item.selected;
        this.cdr.detectChanges();
        this.searcher.doSearch();
    }

    private MergeDefs(newDefs: PartnerRolleDefChip[]) {
        let copy = true;

        if (this.PartnerRolleDefs) {
            const array1 = this.PartnerRolleDefs.map(w => w.pdef);
            const array2 = newDefs.map(w => w.pdef);

            copy = !(array1.length === array2.length && array1.every((value, index) => value === array2[index]));
        }

        if (copy)
            this.PartnerRolleDefs = newDefs;
    }

    public async doSearch(searchValue: string) {
        this.searcher.SelectedRows = [];

        let res = await this.apiService.getModelList(PartnerRolleTreeElement, `/partnerrolle/BuildPartnerRolleList/${this.partnerId}?filter_inactive=${!this.searcher.showInaktive}`);

        res = this.searcher.ApplyColumnFilters(res);
        res = this.searcher.ApplyColumnSorting(res);

        const newDefs = Array.from(new Set(res.map(w => w.RolleDefKurzBez))).sort((a, b) => a.localeCompare(b)).map(w => new PartnerRolleDefChip(w, false));
        this.MergeDefs(newDefs);

        if (this.PartnerRolleDefs.some(w => w.selected))
            res = res.filter(w => this.PartnerRolleDefs.some(pdef => pdef.selected && pdef.pdef === w.RolleDefKurzBez));

        return res;
    }
}
