import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class JobLoggingSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

	@Expose()
	@Type(() => ListType)
	public LogType: ListType;

    @Expose()
	public LogMessage: string;

	@Expose()
	@Type(() => ListType)
	public Erledigt: ListType;

    @Expose()
	public LogNr: number;

	@Expose()
	@Type(() => Date)
	public ErstellDatum: Date;
}
