<div *ngIf="Model">


    <div class="p-2 summary-header">{{ Model.Titel }}</div>
    <div class="p-2 ml-2 grid m-0">

        <ng-container *ngFor="let keyword of getKeywords()">
            <ng-container *ngIf="hasKeywordValue(keyword)">
                <div class="col-12 md:col-4 summary-label">{{ keyword.DocKeywordDef.Bezeichnung1 }}</div>
                <div class="col-12 md:col-8">
                    <ng-container [ngSwitch]="keyword.DocKeywordDef.Keywordtype.Id">
                        <div *ngSwitchCase="AviListDetailConst.ATTRTYPE_TEXT"
                            [innerHTML]="commonService.nl2br(getKeywordValue(keyword))"></div>
                        <ng-container *ngSwitchCase="AviListDetailConst.ATTRTYPE_INTEGER">
                            {{ getKeywordValue(keyword) }}
                        </ng-container>
                        <ng-container *ngSwitchCase="AviListDetailConst.ATTRTYPE_DECIMAL">
                            {{ getKeywordValue(keyword) | number: '1.2-2' }}
                        </ng-container>
                        <ng-container *ngSwitchCase="AviListDetailConst.ATTRTYPE_DATE">
                            {{ getKeywordValue(keyword) | dateFormat: 'dd.MM.yyyy' }}
                        </ng-container>
                        <ng-container *ngSwitchCase="AviListDetailConst.ATTRTYPE_LISTTYPE">
                            {{ keyword.ValueList?.Bezeichnung1 }}
                        </ng-container>
                    </ng-container>

                </div>
            </ng-container>
        </ng-container>

        <div class="col-12 md:col-4 summary-label">Status</div>
        <div class="col-12 md:col-8">{{ Model.Status.Bezeichnung }}</div>

    </div>

</div>
