<avi-core-page *ngIf="!HideWhenNoResults || TotalRecords > 0 || (ColumnFilters && ColumnFilters.length > 0)"
    [title]="SearcherTitle"
    [icon]="SearcherIcon"
    [card]="Card"
    [collapsible]="Collapsible"
    [expanded]="Expanded"
    [fill-height]="FillHeight"
    [contents-padding]="ContentsPadding"
    [style]="{ overflow: 'auto' }"
    [navigate-back]="ShowNavigateBack"
    >
    <ng-container *ngTemplateOutlet="pretoolbar_template"></ng-container>

    <div class="searcher-toolbar-toolbar"
        [style.display]="ShowToolbar ? 'inherit' : 'none'">
        <div *ngIf="ShowRefreshButton || ShowSearchInput || ShowInactiveButton || ShowColumnSelection || ShowGenericExport || toolbarButtons?.length > 0"
            fxLayout="row"
            fxLayoutGap="0.75em"
            fxLayoutAlign="space-around center"
            class="pb-2 pt-2">

            <div fxFlex="initial" *ngIf="ShowCreateButton && CRUDCanCreate">
                <button
                    pButton
                    icon="pi pi-plus"
                    class="p-button-secondary"
                    pTooltip="Neu"
                    tooltipPosition="left"
                    (click)="createObject()"
                ></button>
            </div>

            <ng-container *ngTemplateOutlet="toolbarcontrols_template"></ng-container>

            <div fxFlex>
                <form (ngSubmit)="searchButtonPressed()"
                    #form="ngForm"
                    autocomplete="off"
                    name="lastpass-disable-search"
                    [style.display]="ShowSearchInput ? 'inherit' : 'none'">
                    <!-- <label class="label-nonmd">Suchbegriff</label> -->
                    <div class="p-inputgroup form-field">
                        <span class="p-input-icon-left w-100p">
                            <i class="pi pi-search"></i>

                            <input pInputText
                                #searcherInput
                                style="width: 100%;"
                                [(ngModel)]="SearchValue"
                                (ngModelChange)="_searchValueChanged($event)"
                                name="SearchValue"
                                placeholder="Suchbegriff"
                                type="text"
                                autocomplete="off" />
                        </span>
                        <div *ngIf="SearchValue"
                            (click)="clearSearchValue()"
                            class="p-inputgroup-addon color-red"><i class="pi pi-times"></i>
                        </div>
                    </div>
                </form>
            </div>

            <div fxFlex="initial"
                [style.display]="ShowSearchInput ? 'inherit' : 'none'">
                <p-splitButton #btn label="Suchen"
                    icon="pi pi-search"
                    (onClick)="searchButtonPressed()"
                    (onDropdownClick)="fillSplitButtonItems(btn)">
                </p-splitButton>
            </div>

            <div fxFlex="initial"
                [style.display]="!ShowRefreshButton || ShowSearchInput ? 'none' : 'inherit'">
                <button
                    pButton
                    icon="pi pi-refresh"
                    class="p-button-secondary p-button-flat searcher-refresh-button toolbar-button-icon-only"
                    pTooltip="Aktualisieren"
                    tooltipPosition="left"
                    (click)="deselectAllAndForceRefresh()"
                ></button>
            </div>

            <div fxFlex="initial"
                [style.display]="ShowColumnSelection ? 'inherit' : 'none'">
                <p-multiSelect appendTo="body"
                    scrollHeight="400px"
                    styleClass="multi-select-button p-component p-button"
                    [options]="SelectableColumns"
                    [(ngModel)]="selectedColumns"
                    optionLabel="LabelTrans"
                    [showToggleAll]="false"
                    [maxSelectedLabels]="0"
                    selectedItemsLabel="Spalten"
                    defaultLabel="Spalten"></p-multiSelect>
            </div>

            <div fxFlex="initial"
                [style.display]="ShowGenericExport ? 'inherit' : 'none'">
                <p-button type="button"
                    aviThrottledOnClick
                    (throttledClick)="exportCSV()"
                    icon="pi pi-download"
                    styleClass="p-button-secondary"
                    label="Export"></p-button>
            </div>

            <div *ngFor="let ab of toolbarButtons"
                fxFlex="initial">
                <p-button *ngIf="ab.type !== 'file'"
                    type="button"
                    aviThrottledOnClick
                    (throttledClick)="ab.action()"
                    [icon]="ab.icon"
                    [styleClass]="ab.class"
                    [disabled]="ab.disabled ? ab.disabled(): false"
                    [label]="ab.titledelegate ? ab.titledelegate() : (ab.title ? (ab.title | translate) : '')"
                    [hidden]="ab.visible ? !ab.visible() : false"
                    [pTooltip]="ab.tooltip ? ab.tooltip() : ''">
                </p-button>
                <avi-file-upload-button *ngIf="ab.type === 'file'"
                    [icon]="ab.icon"
                    [styleClass]="ab.class"
                    [accept]="ab.accept"
                    [disabled]="ab.disabled ? ab.disabled(): false"
                    [label]="ab.titledelegate ? ab.titledelegate() : (ab.title ? (ab.title | translate) : '')"
                    [hidden]="ab.visible ? !ab.visible() : false"
                    (onUpload)="ab.action($event)"
                    [pTooltip]="ab.tooltip ? ab.tooltip() : ''">
                </avi-file-upload-button>
            </div>

            <div fxFlex="initial"
                [style.display]="ShowAdvanced ? 'inherit' : 'none'">
                <button pButton
                    type="button"
                    (click)="toggleShowAdvanced()"
                    class="p-button-secondary"
                    [icon]="AdvancedMode ? 'pi pi-circle-on' : 'pi pi-circle-off'"
                    label="Erweitert"></button>
            </div>

            <div fxFlex="initial"
                [style.display]="ShowInactiveButton ? 'inherit' : 'none'">
                <p-checkbox name="groupname"
                    [binary]="true"
                    [(ngModel)]="showInaktive"
                    (ngModelChange)="_showInactiveValueChanged($event)"
                    [label]="getInaktivLabel()"></p-checkbox>
            </div>
        </div>

        <div [ngClass]="{ 'searcher-params': ShowAdvanced && !AdvancedMode }">
            <avi-core-base-form #parameterForm
                [hidden-form]="ShowAdvanced && !AdvancedMode"
                [direct-binding]="true"
                [auto-btn-datepair]="true"
                [card]="false"
                [contents-padding]="false"
                [show-buttons]="false"
                [direct-binding]="true"
                [fields]="SearcherParameters"
                (onAttrChange)="ParameterValueChanged($event)">
            </avi-core-base-form>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="posttoolbar_template"></ng-container>

    <div *ngIf="visible" [pDroppable]="droppableScope" [pDroppableDisabled]="droppableDisabled" (onDrop)="drop()">
        <p-table #dt
            [loading]="loading"
            [columns]="selectedColumns"
            [value]="Data"
            [selectionMode]="CheckboxSelection ? CheckboxSelectionMode : SelectionMode"
            [metaKeySelection]="MetaKeySelection"
            (onRowSelect)="_onRowSelect($event)"
            (onRowUnselect)="_onRowUnselect($event)"
            [rows]="SearcherRows"
            [paginator]="false"
            [alwaysShowPaginator]="false"
            [totalRecords]="TotalRecords"
            [lazy]="LazyLoading"
            (onLazyLoad)="_loadData($event)"
            [contextMenu]="(AddCRUDMenuItems || (MenuItems && MenuItems.length > 0) || getContextMenuItems) ? cm : null"
            [(selection)]="SelectedRows"
            [resizableColumns]="ResizableColumns"
            [reorderableColumns]="ReorderableColumns"
            [columnResizeMode]="'fit'"
            sortMode="multiple"
            [multiSortMeta]="MultiSortMeta"
            [autoLayout]="!NoWordWrap"
            [responsive]="Responsive"
            [filters]="FilterData"
            [filterDelay]="500"
            [tableStyle]="getTableStyle"
            [tableStyleClass]="getTableStyleClasses()"
            [scrollable]="Scrollable"
            [scrollHeight]="ScrollHeight"
            [responsiveLayout]="responsiveLayout"
            [breakpoint]="breakpoint"
            stateStorage="local"
            [stateKey]="getSettingsKey()"
            (onStateRestore)="_onStateRestore($event)"
            (onStateSave)="_onStateSave($event)"
            contextMenuSelectionMode="joint"
            [(contextMenuSelection)]="SelectedRows"
            [expandedRowKeys]="ExpandedRowKeys"
            (onContextMenuSelect)="_onShowContextMenu($event)"
            [dataKey]="DataKey"
            >
            <!-- <div class="p-0 m-0 table-border"> -->
            <ng-template *ngIf="Scrollable"
                pTemplate="colgroup"
                let-columns>
                <colgroup>
                    <col *ngFor="let col of columns"
                        style="width: 250px;" />
                </colgroup>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr *ngIf="columns.length > 0"
                    [ngClass]="getHeaderRowClass()" avi-core-dragdrop [aviDragDropDisabled]="!ReorderableColumns">
                    <th *ngIf="ShowExpansionButtons"
                        style="width: 3.5rem;">
                        <!--EXPANSIONCOL-->
                    </th>
                    <th *ngIf="CheckboxSelection"
                        [ngStyle]="getCheckboxSelectionStyle()"
                        pResizableColumn
                        [ngClass]="getHeaderColumnClass(null)">
                        <ng-container *ngIf="CheckboxSelectionMode !== 'single'">
                            <p-tableHeaderCheckbox *ngIf="!LazyLoading || !KeepSelection"></p-tableHeaderCheckbox>
                            <div *ngIf="LazyLoading && KeepSelection"
                                class="p-checkbox p-component"
                                (click)="onHeaderCheckboxClick($event)">
                                <div class="p-hidden-accessible">
                                    <input #cb
                                        type="checkbox"
                                        [checked]="headerCheckboxChecked"
                                        [disabled]="isHeaderCheckboxDisabled()">
                                </div>
                                <div [ngClass]="{'p-checkbox-box':true,
                                    'p-highlight': headerCheckboxChecked, 'p-disabled': isHeaderCheckboxDisabled()}"
                                    role="checkbox"
                                    [attr.aria-checked]="headerCheckboxChecked">
                                    <span class="p-checkbox-icon"
                                        [ngClass]="{'pi pi-check': headerCheckboxChecked}"></span>
                                </div>
                            </div>
                        </ng-container>
                    </th>

                    <th *ngFor="let c of columns"
                        [pSortableColumn]="c.Field" [pSortableColumnDisabled]="!c.Sortable"
                        pResizableColumn
                        pReorderableColumn
                        [ngStyle]="getColumnStyle(c)"
                        [ngClass]="getHeaderColumnClass(c)"
                        pTooltip="{{ c.Description | translate }}"
                        title="{{ (c.Label || c.Field) | translate }}">
                        <avi-textblock-span [identifier]="c.Label || c.Field" [disable-html]="true"></avi-textblock-span>
                        <!-- {{ c.Label || (c.Field | translate) }} -->
                        <avi-core-sorticon *ngIf="c.Sortable" [field]="c.Field"></avi-core-sorticon>
                    </th>
                </tr>
                <tr *ngIf="ShowAutofilter"
                    [ngClass]="getHeaderRowClass()"
                    class="searcher-filter-row">
                    <td *ngIf="ShowExpansionButtons">
                        <!--EXPANSIONCOL-->
                    </td>
                    <td *ngIf="CheckboxSelection"
                        class="ui-fluid"
                        [ngClass]="getHeaderColumnClass(null)"></td>
                    <td *ngFor="let c of columns"
                        [ngSwitch]="c.Field"
                        class="ui-fluid"
                        [ngClass]="getHeaderColumnClass(c)"
                        class="searcher-autofilter-color">
                        <div *ngIf="c.Filterable && c.FilterType === SearcherColumnType.DATE"
                            class="p-inputgroup">
                            <avi-core-calendar dateFormat="dd.mm.yy"
                                [(ngModel)]="DateFilters[c.Field]"
                                [monthNavigator]="true"
                                selectionMode="range"
                                placeholder="dd.mm.yyyy-dd.mm.yyyy"
                                [firstDayOfWeek]="1"
                                appendTo="body"
                                [showButtonBar]="true"
                                styleClass="searcher-filter"
                                class="searcher-filter"
                                (onSelect)="onUpdateDateFilter(DateFilters[c.Field], c.Field, c.filterMatchMode)"
                                (onBlur)="onInputDateFilter($event.target.value, c.Field, c.filterMatchMode)"
                                (onClearClick)="onUpdateDateFilter(DateFilters[c.Field], c.Field, c.filterMatchMode)"
                                dataType="string">
                            </avi-core-calendar>
                            <div *ngIf="ShowAutofilterClear && dt.filters[c.Field] && dt.filters[c.Field]['value']"
                                (click)="clearColumnFilter(c.Field, c.filterMatchMode)"
                                class="p-inputgroup-addon color-red"><i class="pi pi-times"></i></div>
                        </div>

                        <div *ngIf="
                            c.Filterable && !c.MultiSelectionFilter && c.FilterType === SearcherColumnType.LISTTYPE"
                            class="p-inputgroup">
                            <p-dropdown [(ngModel)]="ListTypeFilters[c.Field]"
                                [options]="c.DropdownDatasourceInternal"
                                placeholder="Filter"
                                [showClear]="true"
                                optionLabel="label"
                                appendTo="body"
                                styleClass="searcher-filter"
                                class="searcher-filter"
                                (onChange)="onUpdateListTypeFilter(ListTypeFilters[c.Field], c.Field, c.filterMatchMode)">
                            </p-dropdown>
                            <div *ngIf="ShowAutofilterClear && dt.filters[c.Field] && dt.filters[c.Field]['value']"
                                (click)="clearColumnFilter(c.Field, c.filterMatchMode)"
                                class="p-inputgroup-addon color-red"><i class="pi pi-times"></i></div>
                        </div>

                        <div *ngIf="
                            c.Filterable && c.MultiSelectionFilter && c.FilterType === SearcherColumnType.LISTTYPE"
                            class="p-inputgroup">
                            <p-multiSelect scrollHeight="400px"
                                [(ngModel)]="ListTypeFilters[c.Field]"
                                [options]="c.DropdownDatasourceInternal"
                                defaultLabel="Filter"
                                optionLabel="label"
                                appendTo="body"
                                styleClass="searcher-filter"
                                class="searcher-filter"
                                [panelStyle]="{ 'z-index': 99999 }"
                                [autoZIndex]="false"
                                (onChange)="onUpdateListTypeFilter(ListTypeFilters[c.Field], c.Field, c.filterMatchMode)">
                                <ng-template let-value
                                    pTemplate="selectedItems">
                                    <div class="multiselect-item multiselect-item-value"
                                        *ngFor="let option of ListTypeFilters[c.Field]">
                                        {{ option.label }}
                                    </div>
                                    <div *ngIf="!ListTypeFilters[c.Field] || ListTypeFilters[c.Field].length === 0"
                                        class="multiselect-placeholder">
                                        Filter
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                            <div *ngIf="ShowAutofilterClear && dt.filters[c.Field] && dt.filters[c.Field]['value']"
                                (click)="clearColumnFilter(c.Field, c.filterMatchMode)"
                                class="p-inputgroup-addon color-red"><i class="pi pi-times"></i></div>
                        </div>

                        <div *ngIf="
                            c.Filterable && c.FilterType === SearcherColumnType.BOOLEAN"
                            class="p-inputgroup checkbox-filter-container">
                            <p-triStateCheckbox 
                                [(ngModel)]="BooleanFilters[c.Field]"
                                (onChange)="onUpdateBooleanFilter(BooleanFilters[c.Field], c.Field, c.filterMatchMode)">
  
                            </p-triStateCheckbox>
                        </div>

                        <div *ngIf="
                            c.Filterable &&
                            c.FilterType !== SearcherColumnType.DATE &&
                            c.FilterType !== SearcherColumnType.LISTTYPE &&
                            c.FilterType !== SearcherColumnType.BOOLEAN"
                            class="p-inputgroup">
                            <input pInputText
                                placeholder="Filter"
                                type="text"
                                class="searcher-filter"
                                (input)="onInputTextFilter($event.target['value'], c.Field, c.filterMatchMode)"
                                [value]="dt.filters[c.Field] ? dt.filters[c.Field]['value'] : null" />
                            <div *ngIf="ShowAutofilterClear && dt.filters[c.Field] && dt.filters[c.Field]['value']"
                                (click)="clearColumnFilter(c.Field, c.filterMatchMode)"
                                class="p-inputgroup-addon color-red"><i class="pi pi-times"></i></div>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr *ngIf="_initialSearchDone"
                    [ngClass]="getGroupHeaderRowClass()">
                    <td [attr.colspan]="getNumVisibleColumns()"
                        class="table-emptymessage"
                        [pContextMenuRow]="null"
                        [ngClass]="getGroupHeaderColumnClass()">
                        <span class="table-emptymessage-text">{{ _emptyMessage }}</span>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="body"
                let-record
                let-columns="columns"
                let-rowIndex="rowIndex"
                let-expanded="expanded">
                <tr class="ui-widget-header"
                    *ngIf="RowGroupHeaderDelegate && rowGroupMetadata[record[GroupField]].index === rowIndex"
                    [ngClass]="getGroupHeaderRowClass()"
                    (dblclick)="_onDoubleClickRowHeader(record)"
                    (click)="_onRowClickRowHeader(record)"
                    >

                    <td [attr.colspan]="getNumVisibleColumns()"
                        [ngClass]="getGroupHeaderColumnClass()">

                        <div class="avi-base-searcher-group-content">
                            <span *ngIf="ShowGroupExpansionButtons">
                                <button type="button"
                                    pButton
                                    pRipple
                                    (click)="toggleGroupVisible(record[GroupField])"
                                    class="p-button-text p-button-rounded p-button-plain"
                                    [icon]="getGroupVisible(record[GroupField]) ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                            </span>

                            <span [innerHTML]="RowGroupHeaderDelegate(record, dt)"></span>
                        </div>
                    </td>
                </tr>

                <tr *ngIf="getGroupVisible(record[GroupField])"
                    [pSelectableRow]="record"
                    [pSelectableRowIndex]="rowIndex"
                    (dblclick)="_onDoubleClick(record)"
                    (click)="_onRowClick(record)"
                    [ngClass]="getRowClassInternal(record, rowIndex)"
                    [pDraggable]="draggableScope" [pDraggableDisabled]="draggableDisabled" dragEffect="move"
                    (onDragStart)="dragStart(record)" (onDragEnd)="dragEnd()"
                    >
                    <td *ngIf="ShowExpansionButtons">
                        <button type="button"
                            pButton
                            pRipple
                            [pRowToggler]="record"
                            class="p-button-text p-button-rounded p-button-plain"
                            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                    </td>
                    <td *ngIf="CheckboxSelection"
                        [ngClass]="getColumnClass(null)"
                        (dblclick)="_onDoubleClickPrevent($event)">
                        <p-tableCheckbox [disabled]="CheckboxSelectionMode === 'single' || CheckboxSelectionMode === 'multiple'" [pContextMenuRow]="record" [value]="record"></p-tableCheckbox>
                    </td>
                    <td *ngFor="let c of columns"
                        [ngClass]="getColumnClass(c)"
                        [ngStyle]="c.getCellStyle(record)"
                        [pContextMenuRow]="getContextMenuRow(c, record)"
                        >
                        <ng-container *ngIf="c.FormatDelegate === null; else customFormat">
                            <ng-container [ngSwitch]="c.Type">
                                <ng-container *ngSwitchCase="SearcherColumnType.DATE">
                                    <div pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                        [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                        class="avi-base-searcher-column-content"
                                        title="{{ record[c.Field] || '' | dateFormat: c.DateFormat }}">
                                        {{ record[c.Field] || '' | dateFormat: c.DateFormat }}
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="SearcherColumnType.INTEGER">
                                    <div pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                        [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                        class="avi-base-searcher-column-content"
                                        [title]="record[c.Field] === 0 ? 0 : record[c.Field]  || ''">
                                        {{ record[c.Field] === 0 ? 0 : record[c.Field] || '' }}
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="SearcherColumnType.DECIMAL">
                                    <div pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                        [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                        class="avi-base-searcher-column-content"
                                        title="{{ record[c.Field] === 0 ? 0 : record[c.Field]  || c.MinValue | number: '1.' + c.MinNachkommastellen + '-' + c.MaxNachkommastellen }}">
                                        {{
                                            record[c.Field] === 0 ? 0 : record[c.Field] || c.MinValue | number: '1.' + c.MinNachkommastellen + '-' + c.MaxNachkommastellen
                                        }}
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="SearcherColumnType.PERCENT">
                                    <div pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                        [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                        class="avi-base-searcher-column-content"
                                        title="{{ (record[c.Field] === 0 ? 0 : record[c.Field]  || '') / 100 | percent: '1.' + c.MinNachkommastellen + '-' + c.MaxNachkommastellen }}">
                                        {{ (record[c.Field] === 0 ? 0 : record[c.Field]  || '') / 100 | percent: '1.' + c.MinNachkommastellen + '-' + c.MaxNachkommastellen }}
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="SearcherColumnType.TELEFONNR">
                                    <div class="valign-center"
                                        pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                        [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                        class="avi-base-searcher-column-content"
                                        [title]="record[c.Field] || ''">
                                        <a *ngIf="record[c.Field]"
                                            (click)="click2Call(record[c.Field])"
                                            class="mr-4p">
                                            <i class="material-icons searcher-icon"
                                                style="color: #666;">call</i>
                                        </a>
                                        <span>{{ record[c.Field] || '' }}</span>
                                    </div>
                                </ng-container>
                                <span *ngSwitchCase="SearcherColumnType.EMAIL"
                                    pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                    [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                    class="avi-base-searcher-column-content"
                                    [title]="record[c.Field] || ''">
                                    <div class="valign-center">
                                        <a *ngIf="record[c.Field]"
                                            (click)="click2Email(record[c.Field])"
                                            class="mr-4p">
                                            <i class="material-icons searcher-icon"
                                                style="color: #666;">email</i>
                                        </a>
                                        <span>{{ record[c.Field] || '' }}</span>
                                    </div>
                                </span>
                                <ng-container *ngSwitchCase="SearcherColumnType.BOOLEAN">
                                    <div pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                        [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                        class="avi-base-searcher-column-content checkbox-field-container"
                                        [title]="record[c.Field] || ''">
                                        <p-checkbox *ngIf="!c.TriStateBoolean" class="checkbox-field" [binary]="true" [readonly]="true" [(ngModel)]="record[c.Field]"></p-checkbox>
                                        <p-triStateCheckbox *ngIf="c.TriStateBoolean" class="checkbox-field" [readonly]="true" [(ngModel)]="record[c.Field]"></p-triStateCheckbox>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="SearcherColumnType.BUTTON">
                                    <div class="avi-base-searcher-column-content avi-base-searcher-button-group">
                                        <ng-container *ngFor="let id of c.ButtonIds; let i = index">
                                            <p-badge *ngIf="c.getButtonType(i) === SearcherButtonType.BADGE"
                                                [styleClass]="c.getButtonClasses(record, id)"
                                                pTooltip="{{ c.getTooltip(record, id) | translate }}" tooltipPosition="left"
                                                [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                                [value]="c.getButtonValue(record, id)"></p-badge>
                                            <button *ngIf="c.getButtonType(i) === SearcherButtonType.BUTTON"
                                                aviThrottledClick
                                                (throttledClick)="c.OnClickButton(record, id)"
                                                pButton
                                                [ngClass]="c.getButtonClasses(record, id)"
                                                [icon]="c.getButtonIcon(record, id)"
                                                pTooltip="{{ c.getTooltip(record, id) | translate }}" tooltipPosition="left"
                                                [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                                [disabled]="c.getDisabled(record, id)"
                                                [label]="translateInstant(c.getButtonText(record, id))"
                                                badge="3"
                                                badgeClass="p-badge-danger"></button>
                                        </ng-container>
                                    </div>
                                </ng-container>

                                <ng-container *ngSwitchCase="SearcherColumnType.SPLITBUTTON">
                                    <div class="avi-base-searcher-column-content">
                                        <ng-container>
                                            <p-menu #menu
                                                [popup]="true"
                                                [model]="c.SplitButtonMenuItems"
                                                appendTo="body"
                                            ></p-menu>
                                            <button
                                                pButton
                                                pRipple
                                                type="button"
                                                [ngClass]="c.getButtonClasses(record)"
                                                [icon]="c.getButtonIcon(record)"
                                                [label]="translateInstant(c.getButtonText(record))"
                                                (click)="c.OnClickSplitButton(record, c.SplitButtonMenuItems); !c.getDisabled(record) && menu.toggle($event)"
                                                [disabled]="c.getDisabled(record)"
                                            ></button>
                                        </ng-container>
                                    </div>
                                </ng-container>

                                <ng-container *ngSwitchCase="SearcherColumnType.LISTTYPE">
                                    <div pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                        [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                        class="avi-base-searcher-column-content"
                                        title="{{ c.FormatDelegate ? c.FormatCustomTitle(record) : record[c.Field]?.Bezeichnung }}">
                                        {{ c.FormatDelegate ? c.FormatCustom(record) : record[c.Field]?.Bezeichnung }}
                                    </div>
                                </ng-container>

                                <ng-container *ngSwitchDefault>
                                    <div pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                        [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                        class="avi-base-searcher-column-content"
                                        [title]="record[c.Field] || ''">
                                        {{ record[c.Field] }}
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <ng-template #customFormat>
                            <div pTooltip="{{ c.getTooltip(record, null) | translate }}"
                                [tooltipStyleClass]="c.getTooltipStyleClass(record)"
                                class="avi-base-searcher-column-content"
                                [title]="c.FormatCustomTitle(record) || ''"
                                [innerHTML]="c.FormatCustom(record)"></div>
                        </ng-template>
                    </td>
                </tr>

                <tr *ngIf="RowGroupFooterDelegate && rowGroupMetadata[record[GroupField]].size === rowIndex + 1 && getGroupVisible(record[GroupField])"
                    [ngClass]="getGroupFooterRowClass()">
                    <td *ngIf="ShowExpansionButtons">
                        <!--EXPANSIONCOL-->
                    </td>
                    <td *ngFor="let c of columns"
                        [ngClass]="getGroupFooterColumnClass()">
                        <span [innerHTML]="RowGroupFooterDelegate(record, dt, c)"></span>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion"
                *ngIf="rowDetailViewTemplate"
                let-record
                let-columns="columns">
                <tr [ngClass]="getGridLinesRowClass()">
                    <td [attr.colspan]="getNumVisibleColumns()"
                        [ngClass]="getGridLinesColumnClass()"
                        class="nopad-force">
                        <ng-container *ngTemplateOutlet="rowDetailViewTemplate; context: { $implicit: record }"></ng-container>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="footer"
                let-columns>
                <tr *ngIf="RowFooterDelegate">
                    <td *ngIf="ShowExpansionButtons">
                        <!--EXPANSIONCOL-->
                    </td>
                    <td *ngFor="let c of columns"
                        [ngClass]="getFooterColumnClass()">
                        <span [innerHTML]="RowFooterDelegate(dt, c)"></span>
                    </td>
                </tr>
            </ng-template>

            <!-- </div> -->
        </p-table>

        <!-- Paginator separate due to scroll bar in best-fit mode -->

        <p-paginator *ngIf="LazyLoading" [rows]="SearcherRows"
            [first]="dt.first"
            [totalRecords]="dt.totalRecords"
            [pageLinkSize]="dt.pageLinks"
            styleClass="ui-paginator-bottom"
            (onPageChange)="_onPage($event)"
            [rowsPerPageOptions]="dt.rowsPerPageOptions"
            [alwaysShow]="false">
        </p-paginator>

        <div class="text-right m-1 searcher-records">
            <small>
                <span *ngIf="TotalRecords != null && ShowTotalRecords">
                    {{ TotalLabel }}: <span class="searcher-num-records">{{ TotalRecords | number }}</span><br />
                </span>
                <span *ngIf="TotalRecordsUnfiltered != null && ShowTotalRecordsUnfiltered">
                    {{ TotalUnfilteredLabel }}: <span class="searcher-num-records">{{ TotalRecordsUnfiltered | number }}</span><br />
                </span>
                <span *ngIf="SelectedRows?.length && ShowTotalSelected">
                    {{ TotalSelectedLabel }}: <span class="searcher-num-records">{{ SelectedRows.length | number }}</span><br />
                </span>
            </small>
        </div>
    </div>

</avi-core-page>
<p-contextMenu #cm [model]="MenuItems" appendTo="body"></p-contextMenu>

<!-- SortField {{SortField}} -->
<avi-core-dbg [m]="SelectedRows"
    t="BaseSearcherComponent.SelectedRows"></avi-core-dbg>

<!-- <dbg [m]="Data" t="BaseSearcherComponent.Data"></dbg> -->
<!--<dbg [m]="MenuItems" t="BaseSearcherComponent.MenuItems"></dbg>-->
