<avi-core-dialog
    #sysMessageFormDialog
    identifier="sysMessageFormDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
>
    <avi-core-sysmessage-form
        #sysMessageForm
        [model-id]="FormModelId"
        [api-prefix]="ApiPrefix"
        (onSavedSuccessful)="onSavedSuccessful($event)"
        [redirect-after-save]="null"
        [redirect-after-delete]="null"
        [action-buttons]="formActionButtons"
    >
    </avi-core-sysmessage-form>
</avi-core-dialog>

<div class="mt-2">
    <avi-core-view-panel [toggleable]="false" [header]="'Systemmitteilungen'" class="w-100p">

    <avi-core-base-searcher
        #searcher
        [search-delegate]="SearchDelegate"
        settingskey="settings.searcher.sysmessage"
        (onInit)="onSearcherInit()"
        (onError)="onSearcherError($event)"
        [use-router-params]="false"
        (onDoubleClick)="onDoubleClickSearcher($event)"
        [rows]="50"
        [auto-search]="true"
        [show-autofilter]="true"
        [toolbar]="false"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [lazy-loading]="true"
        (onSelect)="onSelectionChanged($event)"
        [card]="false"
        searcher-title="Formulare"
        [show-totalrecords]="true"
        [pretoolbar-template]="toolbar"
        [select-first-result]="false"
    >
        <ng-template #toolbar>
            <div class="ui-g">
                <div class="w-100p pt-3 pb-3">
                    <p-toolbar>
                        <div class="ui-toolbar-group-left">
                            <button
                                pButton
                                type="button"
                                label="Neu"
                                icon="pi pi-plus"
                                (click)="createSysMessage()"
                                class="p-button-success w-100p-m mr-2 mb-1"
                            ></button>

                            <button
                                pButton
                                type="button"
                                label="Ändern"
                                icon="pi pi-pencil"
                                (click)="editSysMessage()"
                                class="p-button-primary w-100p-m mr-2 mb-1"
                            ></button>

                            <button
                                pButton
                                type="button"
                                label="Löschen"
                                icon="pi pi-trash"
                                (click)="deleteSysMessage()"
                                class="p-button-danger w-100p-m mr-2 mb-1"
                            ></button>
                        </div>
                    </p-toolbar>
                </div>
            </div>
        </ng-template>
    </avi-core-base-searcher>
</avi-core-view-panel>
</div>
