import { AviAuthService } from './../../../services/auth.service';
import { SysMessageDto } from '@avi-x/avi-dto/system/sysmessagedto.model';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ObjectUtils } from '../../utils/object-utils';
import { AviSignalRService } from '../../../services/signalr.service';
import { Message } from 'primeng/api';
import { AviApiService } from '../../../services/api.service';

@Component({
    selector: 'avi-core-sysmessage-monitor',
    template: '<p-messages *ngIf="authService.IsLoggedIn" class="sysmessages" [(value)]="msgs" [enableService]="false"[escape]="false"></p-messages>'
})
export class AviSysMessageMonitorComponent implements OnInit, OnDestroy {
    @Input('api-prefix')
    public ApiPrefix: string = '';

    msgs: Message[] = [];

    constructor(public signalRService: AviSignalRService, public authService: AviAuthService, private apiService: AviApiService, private cdr: ChangeDetectorRef) {
    }

    private handleSysMessage = (data) => {
        if (data && this.authService.IsLoggedIn) {
            const res: SysMessageDto = ObjectUtils.ObjectToBaseModel(SysMessageDto, JSON.parse(data));

            const idx = this.msgs.findIndex(w => w.id === res.Id);
            if (idx === -1) {
                if (!res.Finished) {
                    const newmsg = this.createMessage(res);
                    this.msgs = this.msgs ? [...this.msgs, newmsg] : [newmsg];
                    this.cdr.markForCheck();
                }
            } else if (res.Finished) {
                this.msgs.splice(idx, 1);
                this.msgs = [...this.msgs];
                this.cdr.markForCheck();
            }
        }
    }

    private createMessage(msg: SysMessageDto): Message {
        return {
            id: msg.Id,
            key: msg.Id,
            severity: 'warn',
            summary: '',
            detail: msg.Text
        };
    }

    ngOnInit() {
        this.InitSysMessages();
    }

    async ngOnDestroy() {
        const connection = await this.signalRService.GetConnection();

        if (connection)
            connection.off('SysMessage', this.handleSysMessage);
    }

    private async InitSysMessages() {
        const connection = await this.signalRService.GetConnection();

        if (connection)
            connection.on('SysMessage', this.handleSysMessage);

        if (this.authService.IsLoggedIn) {
            const messages = await this.apiService.getModelList(SysMessageDto, `${this.ApiPrefix ?? ''}/sysmessage/login`);
            if (messages.length > 0) {
                messages.forEach(w => {
                    const msg = this.createMessage(w);
                    this.msgs.push(msg);
                });

                this.msgs = [...this.msgs];
                this.cdr.markForCheck();
            }
        }
    }
}
