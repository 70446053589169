import { OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { AviCommonService, AviApiService, ISessionData, AviSessionControllerService, AviTabView } from '@avi-x/avi-core';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'avi-docpropertydef-index',
    templateUrl: './docpropertydef-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocPropertyDefIndexComponent implements OnChanges {  
    @Input('session-data')
    SessionData: ISessionData;

    ModelId: string = null;

    sub: Subscription;

   constructor(public apiService: AviApiService,
        public commonService: AviCommonService,
        public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) {
    }	

    
    ngOnChanges(changes: SimpleChanges) {
		if (this.SessionData?.ContextId) {
			this.ModelId = this.SessionData.ContextId;
        }
    }
}
