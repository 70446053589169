import { EmailConfigTest } from '@avi-x/avi-dto/system/emailconfigtest.model';
import { Component, Input, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviApiErrorObject, AviApiService, AviFormField, AviCommonService, AviAuthService, AviBaseFormComponent, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: EmailConfigTest) => any)) => typed_nameof<EmailConfigTest>(nameFunction);

@Component({
    selector: 'avi-crm-emailconfig-test-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="false"
        [fields]="Fields"
        [form-title]="FormTitle"
        [contents-padding]="false"
        [Model]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        [action-buttons]="actionButtons">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviEmailConfigTestFormComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
   
    @Input('form-title')
    public FormTitle: string = 'Email-Konfiguration testen';

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('api-prefix')
    public ApiPrefix: string = '';

    public Loading: boolean = false;
    public Model: EmailConfigTest = null;
    public Fields: AviFormField[] = [];

    abbrechenButton: any = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.tryCloseDialog(); } }];

    constructor(private commonService: AviCommonService,
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    initParams(params: { [k: string]: any; }) {
        if (params?.ApiPrefix)
            this.ApiPrefix = params.ApiPrefix;
    }

    ngOnInit() {
        if (this.config && this.config.data) {
            this.Model = new EmailConfigTest();
            this.initFields();
            this.initParams(this.config.data.Params);
            this.actionButtons = [ ...this.abbrechenButton, ...this.actionButtons ];
        }
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    tryCloseDialog() {
        if (this.ref) 
            this.ref.close(null);
    }

    saveModel(model: EmailConfigTest) {

        this.commonService.showGlobalLoader();
        this.Form.clearFormMessages();

        const delegate: Promise<any> = this.apiService.post(`${this.ApiPrefix ?? ''}/emaildef/config/test`, this.Model);

        delegate.then(r => {
            if (this.ref)
                this.ref.close(null);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        }).finally(() => {
            this.commonService.hideGlobalLoader();
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy() {
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.TestRecipient), 'TestRecipient', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.TestSubject), 'TestSubject', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.TestMessage), 'TestMessage', true).setMDSizeFull());
    }
}
