import { Injectable } from "@angular/core";
import { AviApiService, AviCommonService } from "@avi-x/avi-core";
import { DocuMatrixCreateDocumentRequest } from "@avi-x/avi-dto/documatrix/documatrixcreatedocumentrequest.model";
import { DocuMatrixCreateDocumentResponse } from "@avi-x/avi-dto/documatrix/documatrixcreatedocumentresponse.model";
import { GenericRef } from "@avi-x/avi-dto/shared";
import { DialogService } from "primeng/dynamicdialog";
import { AviDocMatrixEditorComponent } from "../components/documatrix-editor.component";
import { AviDokumentService } from "../../dokument/services/dokument.service";

@Injectable()
export class AviDocuMatrixService {
    constructor(public apiService: AviApiService, public commonService: AviCommonService, public dialogService: DialogService, public dokumentService: AviDokumentService) {

    }

    async createDraft(templateId: string, context: GenericRef): Promise<DocuMatrixCreateDocumentResponse> {
        const draftRequest: DocuMatrixCreateDocumentRequest = new DocuMatrixCreateDocumentRequest();
        draftRequest.TemplateId = templateId;
        draftRequest.Kontext = context;
        return await this.apiService.postModel(DocuMatrixCreateDocumentResponse, 'documatrix/draft/create', draftRequest);
    }

    async editDraft(editorUrl: string) {

        const ref = this.dialogService.open(AviDocMatrixEditorComponent, {
            header: 'Dokument bearbeiten',
            closable: true,
            width: '98%',
            baseZIndex: 10000,
            styleClass: this.commonService.InputStyle === 'filled' ? 'p-input-filled max-height-dialog' : 'max-height-dialog',
            data: {
                DocuMatrixUrl: editorUrl
            }
        });

        const res = await this.commonService.waitForDialogClose(ref);
        return res != null;
    }

    async showDraft(draftId: number) {
        let blob: Blob;
        try {
            this.commonService.showGlobalLoader();
            blob = await this.apiService.getBlob(`documatrix/draft/${draftId}/preview`);
        } finally {
            this.commonService.hideGlobalLoader();
        }
        await this.dokumentService.openBlobPreviewDialog(blob);
    }

    async deleteDraft(draftId: number) {
        await this.apiService.delete(`documatrix/draft/${draftId}`);
    }
    
}
