
export class AviTechRoleConst {
    public static CAFBaseMandat_TAB = 'CAFBaseMandat_TAB';
    public static CAFLeistung_TAB = 'CAFLeistung_TAB';
    public static CAFAuszahlung_TAB = 'CAFAuszahlung_TAB';
    public static BeziehungenUebersicht_TAB = 'BeziehungenUebersicht_TAB';
    public static AviDocument_TAB = 'AviDocument_TAB';
    public static CAFProjekt_TAB = 'CAFProjekt_TAB';
    public static Activity_TAB = 'Activity_TAB';
}
