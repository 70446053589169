import { AviDocumentContainerVM } from '@avi-x/avi-dto/avidoc/avidocumentcontainer-vm.model';
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiService, AviFormField, AviCommonService, AviAuthService, AviFormFieldService, AviListDetailConst } from '@avi-x/avi-core';
import { AviDocumentKeywordContainer } from '@avi-x/avi-dto/avidoc/avidocumentkeywordcontainer.model';

export enum DokumentFormMode {
    // eslint-disable-next-line no-unused-vars
    CREATE, UPDATE, UPDATESTATUS
}

@Component({
    selector: 'avi-crm-dokument-details-view',
    templateUrl: './dokument-details-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDokumentDetailsViewComponent {
    public get FormTitle(): string {
            return 'Dokument';
    }

    public AviListDetailConst = AviListDetailConst;

    public Loading: boolean = false;
    public Model: AviDocumentContainerVM = null;

    public Fields: AviFormField[] = [];

    private contextId: string;
    private contextType: string;

    @Input('context-id')
    public set ContextId(value: string) {
        this.contextId = value;
    }

    @Input('context-type')
    public set ContextType(value: string) {
        this.contextType = value;
    }

    @Input('card')
    public Card: boolean = false;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('document')
    public set Document(document: any) {
        this.InitForm(document.FileMeta);
    }

    @Input('api')
    public Api: string = '/avidoc';

    @Input('api-prefix')
    public ApiPrefix: string = '';

    constructor(public commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {
    }

    public async InitForm(value: any): Promise<boolean> {
        await this.loadModel(value);

        this.cdr.markForCheck();

        return true;
    }


    hasKeywordValue(keyword: AviDocumentKeywordContainer): any {
        const val = this.Model.getKeywordValue(keyword);
        if (keyword.DocKeywordDef.Keywordtype.Id === AviListDetailConst.ATTRTYPE_TEXT && val) 
            return true;
        if (keyword.DocKeywordDef.Keywordtype.Id === AviListDetailConst.ATTRTYPE_INTEGER && val != null) 
            return true;
        if (keyword.DocKeywordDef.Keywordtype.Id === AviListDetailConst.ATTRTYPE_DECIMAL && val != null) 
            return true;
        if (keyword.DocKeywordDef.Keywordtype.Id === AviListDetailConst.ATTRTYPE_DATE && val != null) 
            return true;
        if (keyword.DocKeywordDef.Keywordtype.Id === AviListDetailConst.ATTRTYPE_LISTTYPE && !this.commonService.isEmptyOrWhitespace(keyword.ValueList?.Bezeichnung1)) 
            return true;

        return false;
    }

    getKeywordValue(keyword: AviDocumentKeywordContainer): any {
        return this.Model.getKeywordValue(keyword);
    }

    getKeywords(): AviDocumentKeywordContainer[] {
        return this.Model.alleKeywords.filter(w =>
            w.DocKeywordDef.Keywordtype.Id !== AviListDetailConst.ATTRTYPE_GUID &&
            w.DocKeywordDef.Keywordtype.Id !== AviListDetailConst.ATTRTYPE_REFERENCE &&
            !(w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocumentContainer' && (w.DocKeywordDef.Fachschluessel_AttrName === 'Titel' || w.DocKeywordDef.Fachschluessel_AttrName === 'Status')));
    }

    private async loadModel(cont: any) {
        this.Model = await this.apiService.postModel(AviDocumentContainerVM, `${this.Api}/CreateDocumentContainer`, cont);
        this.Model.alleKeywords = await this.apiService.post(`${this.Api}/GetDocumentKeywords?model_id=${this.contextId}&model_type=${this.contextType}&filter_editable=false`, cont);
    }
}
