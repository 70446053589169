import { VwbPInternSchwaerzenPanelComponent } from './../../../schwaerzen/components/schwaerzen-panel/schwaerzen-panel.component';
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiService, AviCommonService, AviDialogComponent, AviDocumentService, AviListDetailConst, } from '@avi-x/avi-core';
import { Location } from '@angular/common';
import { VwbPCommonService, VwbPListDetailConst, VwbPPublicFormularListeComponent, VwbPPublicRechtstraegerDetailFormComponent, VwbPRechtstraeger, VwbPRechtstraegerDetail, VwbPRechtstraegerVersionInfo, VwbPVersion, VwbPVorlageDefInfoDto } from '@avi-x/vwbp-public';
import { VwbPInternVerlaufSearcherComponent } from '../../../verlauf/components/verlauf-searcher/verlauf-searcher.component';
import { RechtstraegerRueckgaengigMode } from '../rechtstraeger-tasks/rechtstraeger-rueckgaengig-task.component';
import { MenuItem, Message } from 'primeng/api';
import { map } from 'rxjs/operators';

export enum RechtstraegerTaskMode {
    // eslint-disable-next-line no-unused-vars
    FREIGEBEN, RUECKGAENGIG, VEREINFACHTE_VALIDIERUNG, SCHWAERZUNG_VORHANDEN, BEMERKUNG_TEILFONDS, EINGETRAGEN_EWR_REGISTER
}

@Component({
    selector: 'vwbp-intern-rechtstraeger-view',
    styleUrls: ['./rechtstraeger-view.component.scss'],
    templateUrl: './rechtstraeger-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternRechtstraegerViewComponent implements OnInit, OnDestroy {
    @ViewChild('taskDialog', { static: false })
    taskDialog: AviDialogComponent;

    @ViewChild('formularListe', { static: false })
    private formularListe: VwbPPublicFormularListeComponent;

    @ViewChild('showRechtstraegerForm', { static: false })
    showRechtstraegerForm: VwbPPublicRechtstraegerDetailFormComponent;

    @ViewChild('verlauf', { static: false })
    verlauf: VwbPInternVerlaufSearcherComponent;

    @ViewChild('schwaerzenPanel', { static: false })
    schwaerzenPanel: VwbPInternSchwaerzenPanelComponent;

    @Input('standalone')
    Standalone: boolean = true;

    SetValidierungAusnahme: string = null;
    SetBemerkungTeilfonds: string = null;
    SetSchwaerzungVorhanden: string = null;
    SetEingetragenEWRRegister: string = null;

    Model: VwbPRechtstraeger = null;
    SelectedVersion: VwbPVersion = null;
    SelectedDetail: VwbPRechtstraegerDetail = null;
    Versions: any[] = null;
    id: any = null;
    RTName: string = null;

    selectedRT: any[] = [];
    RueckgaengigMode: RechtstraegerRueckgaengigMode;
    public RechtstraegerTaskMode = RechtstraegerTaskMode;
    Modus: RechtstraegerTaskMode;

    ExportItems: MenuItem[] = [];
    VersionMasterOwnerID: string = null;

    ForceCommentsCollapsed: boolean = false;
    ForceKontaktInfoCollapsed: boolean = false;
    ForceFormulareCollapsed: boolean = false;
    ForceSchwaerzenCollapsed: boolean = false;

    HasUnstimmigkeit: boolean = false;
    SchwaerzungVorhanden: boolean = false;
    SchwaerzungAntragVorhanden: boolean = false;
    SchwaerzungRechtskraftVorhanden: boolean = false;
    SchwaerzungFrist3MtVorhanden: boolean = false;
    EingetragenEWRRegister: boolean = false;

    IsAbgegeben: boolean = false;

    UebergabeAnfrageId: string = null;

    msgs: Message[] = [];

    subParam: any;
    subParent: any;

    constructor(private activatedRoute: ActivatedRoute, private apiService: AviApiService, private commonService: AviCommonService, private router: Router, private location: Location,
        public vwbpCommonService: VwbPCommonService, private fileSaverService: FileSaverService, private translateService: TranslateService, private cdr: ChangeDetectorRef, private docService: AviDocumentService) {
    }

    NavigateBack() {
        this.location.back();
    }

    ngOnInit() {
        this.subParam = this.activatedRoute
            .queryParams
            .subscribe(params => {
                if (params['openMode'] === 'unstimmigkeit') {
                    this.ForceCommentsCollapsed = true;
                    this.ForceKontaktInfoCollapsed = true;
                    this.ForceFormulareCollapsed = true;
                    this.ForceSchwaerzenCollapsed = true;
                } else if (params['openMode'] === 'uebergabe-anfrage') {
                    this.UebergabeAnfrageId = params['anfrageId'];
                } else if (params['openMode'] === 'schwaerzen') {
                    this.ForceCommentsCollapsed = true;
                    this.ForceKontaktInfoCollapsed = true;
                    this.ForceFormulareCollapsed = false;
                    this.ForceSchwaerzenCollapsed = false;
                }
            });

        this.subParent = this.activatedRoute.parent.data.pipe(map(w => w.id)).subscribe(async res => {
            if (res) {
                await this.initView(res);

                setTimeout(() => this.refresh());
            }
        });
    }

    private initMessages() {
        const msglist = [];

        if (this.SchwaerzungAntragVorhanden) {
            msglist.push({
                severity: 'black',
                detail: this.translateService.instant('VWBP.PUBLIC.SCHWAERZUNG_ANTRAG_VORHANDEN'),
                icon: 'pi-exclamation-circle'
            });
        }

        if (this.SchwaerzungRechtskraftVorhanden) {
            msglist.push({
                severity: 'black',
                detail: this.translateService.instant('VWBP.PUBLIC.SCHWAERZUNG_RECHTSKRAFT_VORHANDEN'),
                icon: 'pi-exclamation-circle'
            });
        }

        if (this.SchwaerzungFrist3MtVorhanden) {
            msglist.push({
                severity: 'black',
                detail: this.translateService.instant('VWBP.PUBLIC.SCHWAERZUNG_FRIST_3MT_VORHANDEN'),
                icon: 'pi-exclamation-circle'
            });
        }

        if (this.HasUnstimmigkeit) {
            msglist.push({
                severity: 'warn-red',
                detail: this.translateService.instant('VWBP.PUBLIC.UNSTIMMIGKEIT_VORHANDEN'),
                icon: 'pi-exclamation-circle'
            });
        }

        if (this.EingetragenEWRRegister) {
            msglist.push({
                severity: 'warn-yellow',
                detail: this.translateService.instant('VWBP.COMMON.EINGETRAGEN_EWR_REGISTER'),
                icon: 'pi-exclamation-circle'
            });
        }

        this.msgs = msglist;
    }

    public async initView(res: VwbPRechtstraeger, collapseComments: boolean = null, collapseKontaktInfo: boolean = null) {
        this.msgs = [];

        if (res === null) {
            this.SelectedVersion = null;
            this.Model = null;
            this.SelectedDetail = null;
            this.Versions = [];
            this.RTName = null;
            this.HasUnstimmigkeit = false;
            this.IsAbgegeben = false;
            this.cdr.detectChanges();
        } else {
            if (collapseComments != null)
                this.ForceCommentsCollapsed = collapseComments;

            if (collapseKontaktInfo != null)
                this.ForceKontaktInfoCollapsed = collapseKontaktInfo;

            await this.loadRechtstraeger(res.Id);
            this.SelectedVersion = res.SelectedVersion;

            this.IsAbgegeben = this.Model.Uebergabestatus.Id !== VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_NORMAL;

            if (this.SelectedVersion) {
                this.SelectedVersion.alleFormularDaten.forEach(w => {
                    w['FormularTypBezeichnung'] = w.FormularTyp.Bezeichnung;
                    w['WBPartnerTypBezeichnung'] = w.WBPartnerTyp.Bezeichnung;
                    w['WBPartnerTypId'] = w.WBPartnerTyp.Id;
                });

                this.VersionMasterOwnerID = this.SelectedVersion.VersionMasterOwner_ID;

                this.HasUnstimmigkeit = await this.apiService.get(`v1/vwbp/rechtstraeger/hasunstimmigkeit/${this.SelectedVersion.Id}`);
            } else {
                this.VersionMasterOwnerID = null;
                this.HasUnstimmigkeit = false;
            }

            this.SelectedDetail = await this.apiService.FindModel(VwbPRechtstraegerDetail, `v1/intern/vwbp/rechtstraeger/detail/${this.Model.CurrentDetailId}`, null, null, null);
            this.RTName = this.SelectedDetail.Name;

            this.Versions = [];
            this.Model.alleVersionDetails.forEach(w => this.Versions.push({ 'label': this.getVersionLabel(w), 'value': w.Id }));

            this.msgs = [...this.msgs];

            await this.getExportItems();
        }

        this.SchwaerzungVorhanden = this.Model?.SchwaerzungVorhanden?.Id === AviListDetailConst.JA;
        this.SchwaerzungAntragVorhanden = this.Model?.SchwaerzungAntragVorhanden?.Id === AviListDetailConst.JA;
        this.SchwaerzungRechtskraftVorhanden = this.Model?.SchwaerzungRechtskraftVorhanden?.Id === AviListDetailConst.JA;
        this.SchwaerzungFrist3MtVorhanden = this.Model?.SchwaerzungFrist3MtVorhanden?.Id === AviListDetailConst.JA;

        this.EingetragenEWRRegister = this.Model?.EingetragenEWRRegister?.Id === AviListDetailConst.JA;

        this.initMessages();
    }

    public refresh() {
        this.cdr.detectChanges();
        this.formularListe?.forceRefresh();
    }

    isVwEGVersion() {
        return this.SelectedVersion?.Typ?.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWEG;
    }

    private getVersionLabel(versionInfo: VwbPRechtstraegerVersionInfo): string {
        let res = `Version ${versionInfo.Laufnummer} - ${this.commonService.formatDate(versionInfo.Versionsdatum, 'L LT')}`;
        if (versionInfo.Freigabedatum)
            res = res + ` (${this.commonService.formatDate(versionInfo.Freigabedatum, 'L')})`;

        if (versionInfo.VersionTyp === VwbPListDetailConst.VWBP_VERSION_TYP_VWEG)
            res = res + ' [VwEG]';

        return res;
    }

    ngOnDestroy() {
        this.subParam.unsubscribe();
        this.subParent.unsubscribe();
    }

    private async loadRechtstraeger(id: string) {
        this.Model = await this.apiService.FindModel(VwbPRechtstraeger, `v1/intern/vwbp/rechtstraeger/${id}`, null, null, null);

        this.SchwaerzungVorhanden = this.Model?.SchwaerzungVorhanden.Id === AviListDetailConst.JA;
        this.SchwaerzungAntragVorhanden = this.Model?.SchwaerzungAntragVorhanden.Id === AviListDetailConst.JA;
        this.SchwaerzungRechtskraftVorhanden = this.Model?.SchwaerzungRechtskraftVorhanden.Id === AviListDetailConst.JA;
        this.SchwaerzungFrist3MtVorhanden = this.Model?.SchwaerzungFrist3MtVorhanden.Id === AviListDetailConst.JA;

        this.EingetragenEWRRegister = this.Model?.EingetragenEWRRegister.Id === AviListDetailConst.JA;
    }

    private async loadVersion(id: string) {
        this.SelectedVersion = await this.apiService.getModel(VwbPVersion, `v1/intern/vwbp/rechtstraeger/version/${id}`);

        this.SelectedVersion.alleFormularDaten.forEach(w => {
            w['FormularTypBezeichnung'] = w.FormularTyp.Bezeichnung;
            w['WBPartnerTypBezeichnung'] = w.WBPartnerTyp.Bezeichnung;
            w['WBPartnerTypId'] = w.WBPartnerTyp.Id;
        });
    }

    async SetSelectedVersion(data: string) {
        if (this.Standalone) {
            this.router.navigate(['intern/vwbp/index/rt', data], { skipLocationChange: true });
        } else {
            await this.loadVersion(data);

            this.Model.SelectedVersion = this.SelectedVersion;
            await this.initView(this.Model);

            setTimeout(() => this.refresh());
        }
    }

    isCurrentVersion() {
        return this.Model && this.SelectedVersion && this.Model.CurrentVersionId === this.SelectedVersion.Id;
    }

    OnCommentChanged() {
        this.verlauf?.forceRefresh();
    }

    async OnUnstimmigkeitenChanged() {
        this.verlauf?.forceRefresh();
        await this.loadRechtstraeger(this.Model.Id);
        this.HasUnstimmigkeit = await this.apiService.get(`v1/vwbp/rechtstraeger/hasunstimmigkeit/${this.SelectedVersion.Id}`);
        this.initMessages();
        this.cdr.markForCheck();
    }

    async OnSchwaerzenChanged() {
        await this.loadRechtstraeger(this.Model.Id);
        this.verlauf?.forceRefresh();
        this.schwaerzenPanel?.forceRefresh();
        this.refreshFormulare();
        this.initMessages();
    }

    async OnMahnwesenChanged() {
        await this.loadRechtstraeger(this.Model.Id);
        this.verlauf?.forceRefresh();
        this.cdr.markForCheck();
    }

    doTask(selectedRT: any[], modus: RechtstraegerTaskMode) {
        this.selectedRT = selectedRT;
        this.Modus = modus;
        this.cdr.detectChanges();
        this.taskDialog.open();
    }

    canEditFormSchwaerzen() {
        return (this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_ERFASSUNG_ABGESCHLOSSEN || this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN)
            && this.currentUserIsAjuUserSchreiben() && this.SelectedVersion?.Typ.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWBP
            && !this.Model.HrLoeschungsdatum;
    }

    enableTaskFreigeben() {
        return this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_ERFASSUNG_ABGESCHLOSSEN && this.currentUserIsAjuUserSchreiben() && this.SelectedVersion?.Typ.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWBP;
    }

    doTaskFreigeben() {
        this.doTask([{ Id: this.SelectedVersion.Id }], RechtstraegerTaskMode.FREIGEBEN);
    }

    enableTaskFreigebenRueckgaengig() {
        return this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN && this.currentUserIsAjuUserSchreiben() && this.SelectedVersion?.Typ.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWBP;
    }

    doTaskFreigebenRueckgaengig() {
        this.RueckgaengigMode = RechtstraegerRueckgaengigMode.FREIGABE;
        this.doTask([{ Id: this.SelectedVersion.Id }], RechtstraegerTaskMode.RUECKGAENGIG);
    }

    enableTaskValidierungAusnahme() {
        return this.SelectedVersion && this.currentUserIsAjuUserSchreiben();
    }

    doTaskValidierungAusnahme(val: string) {
        this.SetValidierungAusnahme = val;
        this.doTask([{ Id: this.Model.Id }], RechtstraegerTaskMode.VEREINFACHTE_VALIDIERUNG);
    }

    doTaskBemerkungTeilfonds(val: string) {
        this.SetBemerkungTeilfonds = val;
        this.doTask([{ Id: this.Model.Id }], RechtstraegerTaskMode.BEMERKUNG_TEILFONDS);
    }

    // doTaskSchwaerzungVorhanden(val: string) {
    //     this.SetSchwaerzungVorhanden = val;
    //     this.doTask([{ Id: this.Model.Id }], RechtstraegerTaskMode.SCHWAERZUNG_VORHANDEN);
    // }

    doTaskEingetragenEWRRegister(val: string) {
        this.SetEingetragenEWRRegister = val;
        this.doTask([{ Id: this.Model.Id }], RechtstraegerTaskMode.EINGETRAGEN_EWR_REGISTER);
    }

    currentUserIsAjuUserSchreiben() {
        return this.vwbpCommonService.currentUserIsAjuUserSchreiben();
    }

    currentUserIsAjuUser() {
        return this.vwbpCommonService.currentUserIsAjuUser();
    }

    currentUserIsOnlyBehoerdenUser() {
        return this.vwbpCommonService.currentUserIsOnlyBehoerdenUser();
    }

    async OnSave() {
        this.CloseDialog();
        if (this.Modus === RechtstraegerTaskMode.VEREINFACHTE_VALIDIERUNG ||
            this.Modus === RechtstraegerTaskMode.SCHWAERZUNG_VORHANDEN ||
            this.Modus === RechtstraegerTaskMode.BEMERKUNG_TEILFONDS ||
            this.Modus === RechtstraegerTaskMode.EINGETRAGEN_EWR_REGISTER) {
            await this.loadRechtstraeger(this.Model.Id);
            this.initMessages();
        } else
            await this.loadVersion(this.SelectedVersion.Id);
        this.cdr.detectChanges();
    }

    CloseDialog() {
        this.selectedRT = [];
        this.taskDialog.close();
        this.cdr.detectChanges();
    }

    public async getExportItems() {
        if (this.Model == null)
            this.ExportItems = [];
        else {
            const items: MenuItem[] = [];

            const rtVersion = this.SelectedVersion ? this.SelectedVersion.Id : this.commonService.GuidEmpty;
            const templates = await this.apiService.getModelList(VwbPVorlageDefInfoDto, `v1/vwbp/document/vorlagedefs/rt/${this.Model.Id}/version/${rtVersion}`);

            templates.forEach(w => {
                items.push({ label: w.Bezeichnung, command: () => this.GenerateDocument(w) });
            });

            this.ExportItems = items;
        }
    }

    public async GenerateDocument(vorlageDef: VwbPVorlageDefInfoDto) {
        try {

            this.commonService.showGlobalLoader();

            var openWithDocHandler = this.currentUserIsAjuUserSchreiben()
                && vorlageDef.KannAbgelegtWerden
                && vorlageDef.OpenWithDocHandler.Id === AviListDetailConst.JA
                && await this.initAviDocHandler();

            const rtVersion = this.SelectedVersion ? this.SelectedVersion.Id : this.commonService.GuidEmpty;
            const antrag = this.schwaerzenPanel?.getSelectedAntrag();
            const antragId = antrag ? antrag.Id : this.commonService.GuidEmpty;

            if (openWithDocHandler) {
                // Dokument aus Vorlage generieren und via aviDocHandler auschecken
                try {
                    const fileMeta = await this.apiService.get(`v1/intern/vwbp/document/generateandsave/${vorlageDef.Id}/rt/${this.Model.Id}/version/${rtVersion}/antrag/${antragId}`);
                    if (fileMeta)
                        await this.docService.CheckoutDocument(fileMeta.vonArchivDef_ID, fileMeta.ArchivDocumentID, fileMeta.DocKategorie_ID, fileMeta.DocArt_ID, fileMeta.Name, fileMeta.Type, fileMeta.Titel, fileMeta.Description, fileMeta.KeywordDocArtName, fileMeta.KeywordGruppeName, fileMeta.KeywordUntergruppeName);
                } catch (err) {
                    // falls es mit dem aviDocHandler Probleme gibt, soll das Dokument als Fallback wie bisher über den Browser heruntergeladen werden.
                    console.error('Error beim auschecken eines Dokumentes via aviDocHandler: ', err);
                    await this.GenerateDocumentWithoutDocHandler(vorlageDef, rtVersion, antragId);
                }
            }
            else {
                // Dokument aus Vorlage generieren und via Browser herunterladen
                await this.GenerateDocumentWithoutDocHandler(vorlageDef, rtVersion, antragId);
            }
        } catch (err) {
            this.cdr.markForCheck();
            this.commonService.notificateError(err);
        } finally {
            this.commonService.hideGlobalLoader();
        }
    }

    private async GenerateDocumentWithoutDocHandler(vorlageDef: VwbPVorlageDefInfoDto, rtVersion: string, antragId: string) {
        const blob = await this.apiService.getBlob(`v1/vwbp/document/generate/${vorlageDef.Id}/rt/${this.Model.Id}/version/${rtVersion}/antrag/${antragId}`);
        if (blob) {
            let fname = vorlageDef.DefaultFilename ?? vorlageDef.Bezeichnung;
            if (vorlageDef.Format.Id === VwbPListDetailConst.VWBP_VORLAGE_FORMAT_WORD)
                fname = fname + '.docx';
            else
                fname = fname + '.pdf';
            this.fileSaverService.save(blob, fname);
        }
    }

    public async refreshFormulare() {
        await this.loadVersion(this.SelectedVersion.Id);
        this.cdr.detectChanges();
        this.formularListe.forceRefresh();
    }

    GetPdfIconToolTip(): string {
        if (this.currentUserIsOnlyBehoerdenUser())
            return this.translateService.instant('VWBP.COMMON.RT_AUSZUG_ERSTELLEN_BUTTON');
        return null;
    }

    async initAviDocHandler(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                let isOnline = await this.docService.IsLocalServiceAvailable();
                if (!isOnline) {
                    // if (await this.docService.IsAviDocHandlerInstalled())
                    //     throw new Error('Der lokale Service ist nicht installiert.');

                    const url = this.docService.BuildAviDocStartAppUrl();
                    isOnline = await this.docService.TryStartAviDocHandler(url);
                }
                resolve(isOnline);
            } catch (err) {
                console.error('aviDocHandler kann nicht gestartet werden. Dokument wird über Browser heruntergeladen!');
                console.error(err);
                resolve(false);
            } finally {
                // setInterval(this.checkAviDocHandlerStatus, 10000);
            }
        });
    }
}
