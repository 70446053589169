import { Expose, Type } from 'class-transformer';

import { SysMessageDef } from '../system/sysmessagedef.model';
import { BaseModel } from '../shared/basemodel.model';

export class SysMessage extends BaseModel {  
    @Expose()
    @Type(() => Date)
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }
    
    @Expose()
    @Type(() => Date)
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }
    
    @Expose()
    public get Text(): string { return this.getProperty('Text'); }
    public set Text(value: string) { this.setProperty('Text', value); }
    
    @Expose()
    public get vonSysMessageDef_ID(): string { return this.getModelId('vonSysMessageDef_ID'); }
    public set vonSysMessageDef_ID(value: string) { this.setModelId('vonSysMessageDef_ID', value); }
 
    @Expose()
    @Type(() => SysMessageDef)
    public get vonSysMessageDef(): SysMessageDef { return this.getModel('vonSysMessageDef'); }
    public set vonSysMessageDef(value: SysMessageDef) { this.setModel('vonSysMessageDef', value); }
    
}
