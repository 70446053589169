


import { MatPaginatorIntl } from '@angular/material/paginator';
import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorIntlDe extends MatPaginatorIntl {
    itemsPerPageLabel: string = 'Einträge pro Seite: ';
    nextPageLabel: string = null; // 'Nächste Seite';
    previousPageLabel: string = null; // 'Vorherige Seite';


    getRangeLabel = this.getRangeLabelDe;

    getRangeLabelDe(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return `0 von ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} von ${length}`;
    }
}



/** Adapts the native JS Date for use with cdk-based components that work with dates. */
export class CustomDateAdapter extends NativeDateAdapter {

    parse(value: any): Date | null {

        if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
            const str = value.split('.');

            let year = Number(str[2]);
            const month = Number(str[1]) - 1;
            const date = Number(str[0]);

            if (!year) year = new Date().getFullYear();
            if (year < 1000)
                year += 2000;
            console.log('ymd:', year, month, date);

            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }

    // retirar quando for feito o merge da data por mmalerba
    format(date: Date, displayFormat: Object): string {
        date = new Date(Date.UTC(
            date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(),
            date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
        displayFormat = Object.assign({}, displayFormat, { timeZone: 'utc' });


        const dtf = new Intl.DateTimeFormat(this.locale, displayFormat);
        return dtf.format(date).replace(/[\u200e\u200f]/g, '');
    }

}
