import { Component, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AviCommonService, AviApiService, AviBaseSearcherComponent, AviSearcherColumn } from '@avi-x/avi-core';
import { VwbPCommonService } from '@avi-x/vwbp-public';


@Component({
    selector: 'vwbp-intern-rechtstraeger-dokumente-searcher',
    templateUrl: './rechtstraeger-dokumente-searcher.component.html'
})
export class VwbPInternRechtstraegerDokumenteSearcherComponent {
    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @Input('RechtstraegerId')
    public RechtstraegerId: string;

    constructor(
        public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        public cdr: ChangeDetectorRef,
        private vwbpCommonService: VwbPCommonService
    ) {
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get(`v1/intern/vwbp/document/GetDocumentsForRechtstraeger/${this.RechtstraegerId}`);

        this.searcher.SelectedRows = res;

        res = this.searcher.ApplyColumnFilters(res);
        res = this.searcher.ApplyColumnSorting(res);

        return res;
    }

    public async onSearcherInit(data) {
        this.searcher.beginInit();

        this.searcher.addTextColumn('Titel', 'Titel', true);
        this.searcher.addDateColumn('Datum', 'Datum');
        this.searcher.addTextColumn('KeywordGruppeName', 'Ordner', true);
        this.searcher.addColumn(
            AviSearcherColumn.CreateCustom(
                'Type',
                'Type',
                (row, col) => {
                    const type = row['Type'] ? row['Type'].toLowerCase() : '';
                    if (type === '.pdf')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-pdf-o"></i>${row['Type']}</div>`;
                    else if (type === '.docx' || type === '.dotx' || type === '.docm' || type === '.dotm' || type === '.doc' || type === '.dot')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-word-o"></i>${row['Type']}</div>`;
                    else if (type === '.xlsx' || type === '.xls')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-excel-o"></i>${row['Type']}</div>`;
                    else if (type === '.pptx' || type === '.ppt')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-powerpoint-o"></i>${row['Type']}</div>`;
                    else if (type === '.xml')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-code-o"></i>${row['Type']}</div>`;
                    else if (type === '.png' || type === '.jpg' || type === '.jpeg' || type === '.tif' || type === '.gif' || type === '.bmp')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-image-o"></i>${row['Type']}</div>`;
                    else if (type === '.msg' || type === '.eml')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-envelope-o"></i>${row['Type']}</div>`;
                    else
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-o"></i>${row['Type']}</div>`;
                },
                false,
                '85px'
            ).setSortable(false)
        );
        this.searcher.addTextColumn('Size', 'Size', true);

        this.searcher.endInit();
    }
}
