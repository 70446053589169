import { Expose, Type } from 'class-transformer';

export class ListType {
    private _Id: string;
    private _ListName: string;
    private _KurzBez: string;
    private _Bezeichnung: string;

    constructor(id: string, listName?: string, bezeichnung?: string, kurzbez?:string) {
        this._Id = id;
        this._ListName = listName;
        this._Bezeichnung = bezeichnung;
        this._KurzBez = kurzbez;
    }

    @Expose()
    public get Id(): string { return this._Id; }
    public set Id(value: string) { this._Id = value; }

    @Expose()
    public get ListName(): string { return this._ListName; }
    public set ListName(value: string) { this._ListName = value; }

    @Expose()
    public get KurzBez(): string { return this._KurzBez; }
    public set KurzBez(value: string) { this._KurzBez = value; }

    @Expose()
    public get Bezeichnung(): string { return this._Bezeichnung; }
    public set Bezeichnung(value: string) { this._Bezeichnung = value; }
}
