import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { ListType } from '../shared';
import TransformDate from '../shared/transformdate';

export class BusinesscaseDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
	
	@Expose()
    @TransformDate()
    public GueltigAb: Date;
    
    @Expose()
    @TransformDate()
    public GueltigBis: Date;
    
    @Expose()
	public KurzBez: string;

    @Expose()
	public Bezeichnung: string;

    @Expose()
	public DefaultTitle: string;

    @Expose()
	public StartNumber: number;

    @Expose()
	public NumberPrefix: string;

	@Expose()
	@Type(() => ListType)
	public DefaultPriority: ListType;

	@Expose()
	@Type(() => ListType)
	public ShowPriority: ListType;

	@Expose()
	@Type(() => ListType)
	public ShowDueDate: ListType;

    @Expose()
	public DueIn_Months: number;

    @Expose()
	public DueIn_Days: number;

    @Expose()
	public DueIn_Hours: number;

    @Expose()
	public DueIn_Minutes: number;

	@Expose()
	@Type(() => ListType)
	public Context1Mandatory: ListType;

	@Expose()
	@Type(() => ListType)
	public Context2Mandatory: ListType;

	@Expose()
	@Type(() => ListType)
	public CreateVisibility: ListType;

	@Expose()
	CreateRoleName: string;

	@Expose()
	Context1ModelName: string;
	
	@Expose()
	Context2ModelName: string;

	@Expose()
	WorkflowDefName: string;
}
