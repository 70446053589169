import { AviDocKeywordDef } from '@avi-x/avi-dto/avidoc_def/avidockeyworddef.model';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { AviApiService, AviCommonService, AviBaseSearcherComponent, AviListDetailConst, AviFormFieldService, AviSearcherColumn, AviDokumentAblageListDetailConst, AviDocumentService, IDocument, AviDialogComponent } from '@avi-x/avi-core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { TreeNode, MenuItem } from 'primeng/api';
import { SqlQueryParameterVM } from '@avi-x/avi-dto/reporting/sqlqueryparameter-vm.model';
import { findIndex, map } from 'rxjs/operators';

import { Subscription } from 'rxjs/Subscription';

import { FileSaverService } from 'ngx-filesaver';
import { DokumentFormMode } from '../dokument-erfassen-form/dokument-erfassen-form.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AviDokumentVersionInfoComponent } from '../dokument-versioninfo/dokument-versioninfo.component';
import { AviDokumentSignierenFormComponent } from '../dokument-signieren-form/dokument-signieren-form.component';
import { AviDokumentService } from '../../services/dokument.service';
import { AviDokumentPreviewComponent } from '../dokument-preview/dokument-preview.component';
import { TranslateService } from '@ngx-translate/core';
import { async } from '@angular/core/testing';

enum DocCommand {
    DOKUMENT_AENDERN,
    DOKUMENT_ERSETZEN,
    STATUS_AENDERN,
    METADATEN_AENDERN,
    SPERREN,
    ENTSPERREN,
    LOESCHEN,
    STORNIEREN,
    WIEDERHERSTELLEN,
    VERNICHTEN,
    VERSIONEN_ANZEIGEN,
    DRUCKEN,
    EMAIL_SENDEN,
    ZWISCHENABLAGE,
    DOWNLOAD,
    SAVE_PDF
}

@Component({
    selector: 'avi-crm-dokumente',
    templateUrl: './dokument-dashboard.component.html',
    styleUrls: ['./dokument-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDokumentDashboardComponent implements OnInit, OnDestroy {
    public Mode: 'normal' | 'selection' = 'normal';
    
    createItems: MenuItem[];
    editItems: MenuItem[];
    exportItems: MenuItem[];

    SearchValue = '';
    FullTextSearch: boolean = false;
    public isFlatTree: boolean = false;

    public blockedButtons: boolean  = false;
    public intervalId: any = 0;  
    // public documentServiceEnabled = false;

    contextId: string;
    contextType: string;

    ctxSub: Subscription;
    querySub: Subscription;
    
    splitterIFrameHack;

    public folderTree: TreeNode[];

    ShowInternal: boolean = false;
    Internal: boolean = false;
    InternalReadonly: boolean = false;

    private _selectedNode: TreeNode;
    public _selectedDocument: any;
    private searchResults: any;
    private searchFolder: TreeNode = {
        'label': 'Suchergebnisse',
        'expandedIcon': 'pi pi-search',
        'collapsedIcon': 'pi pi-search',
        'data': 'searchFolder',
        'styleClass': 'hidden'
    };

    public get SelectedDocument(): any {
        return this._selectedDocument;
    }

    public get SelectedNode(): TreeNode {
        return this._selectedNode;
    }

    public set SelectedNode(value: TreeNode) {
        this._selectedNode = value;

        this.clearPreview();
        this._selectedDocument = null;
        this.updateMenuItems();

        if (this.searcher) {
            if (value === this.searchFolder) {
                this.searcher.forceRefresh();
            } else {
                this.searcher.setSearcherParameter('paramFolder', this.SelectedNode.data.Id, false);
                this.searcher.setSearcherParameter('paramKategorie', this.SelectedNode.data.DocKategorie_ID, false);
                this.searcher.forceRefresh();
            }
        }
    }

    @ViewChild('preview', { static: false })
    preview: AviDokumentPreviewComponent;
    
    @Input('card')
    public Card: boolean = false;

    @ViewChild('searcher', { static: false })
    private searcher: AviBaseSearcherComponent;

    @Input('context-id')
    public set ContextId(value: string) {
        this.contextId = value;
        this.Reload();
    }

    @Input('context-type')
    public set ContextType(value: string) {
        this.contextType = value;
        this.Reload();
    }
 
    _localService: boolean = false;    // TODOVR: Abhängig von config.txt
    @Input('local-service')
    public set LocalService(value: boolean) {
        this._localService = value;
        this.initMenuItems();
    }

    _hasEsignatur: boolean = true;
    @Input('has-esignatur')
    public set HasEsignatur(value: boolean) {
        this._hasEsignatur = value;
        this.initMenuItems();
    }

    _menuItemsCreate: MenuItem[] = null;
    @Input('menuitems-create')
    public set MenuItemsCreate(items: MenuItem[]) {
        this._menuItemsCreate = items;
        this.initMenuItems();
    }

    _menuItemsEdit: MenuItem[] = null;
    @Input('menuitems-edit')
    public set MenuItemsEdit(items: MenuItem[]) {
        this._menuItemsEdit = items;
        this.initMenuItems();
    }

    _menuItemsExport: MenuItem[] = null;
    @Input('menuitems-export')
    public set MenuItemsExport(items: MenuItem[]) {
        this._menuItemsExport = items;
        this.initMenuItems();
    }

    @Input('api')
    public Api: string = '/avidoc';

    @Input('api-prefix')
    public ApiPrefix: string = '';

    @Input('hide-keywords')
    public HideKeywords: string[] = null;

    @Input('settingskey')
    public SettingsKey: string = 'avix::settings.searcher.dokumente.splitter';

    @Input('use-router-params')
    public UseRouterParams: boolean = false;
    
    @Input('show-preview')
    public ShowPreview: boolean = true;

    @Output('onDocumentsChanged')
    public OnDocumentsChanged: EventEmitter<any> = new EventEmitter();

    public SearchDelegate: any = async (searchValue: string) => this.loadDocuments();

    docSub: Subscription;

    constructor(public docService: AviDocumentService, public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute,
        public commonService: AviCommonService, public formFieldService: AviFormFieldService, public dokumentService: AviDokumentService,
        private fileSaverService: FileSaverService,
        public dialogService: DialogService,
        public ref: DynamicDialogRef,
        public translateService: TranslateService,
        public config: DynamicDialogConfig,
        public cdr: ChangeDetectorRef, public documentService: AviDokumentService) {
    }

    async handleCheckedOutDocumentsChanged(docs) {
        // update lock status and buttons without reloading...
        if (docs) {
            const docIdList = [...new Set([...docs.old?.map(w => w.fileDocId) || [], ...docs.new?.map(w => w.fileDocId) || [] ])];
            for (var doc of docIdList) {
                const row = this.searcher?.Data?.find(w => w.ArchivDocumentID === doc);
    
                if (row) {
                    const status = await this.docService.GetDocumentLockstatus(row.DocArchivDef_ID, row.ArchivDocumentID);
                    row.Keywords_Lockstatus = status.lockstatus;
                    row.Keywords_Lockdatum = status.lockdatum;
                }
            }

            if (this._selectedDocument && docIdList.some(w => w === this._selectedDocument.ArchivDocumentID))
                this.loadPreview(this._selectedDocument.DocArchivDef_ID, this._selectedDocument.ArchivDocumentID, true);
        }

        this.searcher.updateView();
        //this.cdr.markForCheck();
        this.cdr.detectChanges();
        console.log('handleCheckedOutDocumentsChanged');
    }

    public onInit(searcher) {
        searcher.beginInit();

        const paramFolder = this.formFieldService.CreateFieldFromSearcherParameter(
            new SqlQueryParameterVM('paramFolder', AviListDetailConst.ATTRTYPE_TEXT, 'Folder', AviListDetailConst.JA, AviListDetailConst.NEIN, null, false, null), 6);
        searcher.SearcherParameters.push(paramFolder);

        const paramKategorie = this.formFieldService.CreateFieldFromSearcherParameter(
            new SqlQueryParameterVM('paramKategorie', AviListDetailConst.ATTRTYPE_TEXT, 'Kategorie', AviListDetailConst.JA, AviListDetailConst.NEIN, null, false, null), 6);
        searcher.SearcherParameters.push(paramKategorie);
        
        this.searcher.endInit();
    }

    clearSearchValue() {
        this.SearchValue = null;
        this.searchButtonPressed();
    }    

    private _handleParamMapChanged(params: ParamMap) {
        const paramList = params.getAll('param');

        paramList.forEach(w => {
            const valPair = w.split('=');
            const paramName = valPair[0];
            const paramValue = valPair[1];
            if (this.folderTree && paramName === 'paramFolder' && this.SelectedNode !== this.searchFolder && this.SelectedNode?.data?.Id !== paramValue) {
                this._selectedNode = this.folderTree.find(x => x.data.Id === paramValue);
            }
        });
    }

    ngOnDestroy() {
        this.ctxSub?.unsubscribe();
        this.querySub?.unsubscribe();
        this.docSub?.unsubscribe();
        clearInterval(this.intervalId);
    }

    ngOnInit() {
        if (this.config && this.config.data) {
            this.contextId = this.config.data.ContextId;
            this.contextType = this.config.data.ContextType;
            this.Card = false;
            this.ShowPreview = false;
            this.Mode = 'selection';
            this.ShowInternal = this.config.data.showInternal;
            this.Internal = this.config.data.internal;
            this.InternalReadonly = this.config.data.internalReadonly;
        } else {
            const ctype = this.activatedRoute.snapshot.data['contextType'];
            if (ctype) {
                this.contextType = ctype;
    
                if (this.contextType) {
                    this.ctxSub = this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
                        this.contextId = res;
                    });
                }
    
                if ('submodul' in this.activatedRoute.snapshot.data)
                    this.Card = !this.activatedRoute.snapshot.data['submodul'];
            }
    
            this.querySub = this.activatedRoute.queryParamMap.subscribe(params => this._handleParamMapChanged(params));
        }
        
        this.docSub = this.docService.checkedOutDocumentsChanged$.subscribe(docs => this.handleCheckedOutDocumentsChanged(docs));
        
        this.loadDocumentFolderTree();
        this.initMenuItems();
    }

    ngAfterContentInit() {
        // if (this._localService) {
        //     var conIntId = setInterval(() => {
        //         console.log('docService.Connected ', this.docService.Connected);
        //         this.documentServiceEnabled = this.docService.DocumentServiceEnabled;
        //         if (this.docService.IsOnline()){
        //             this.blockedBtnAviDocHandler = true;
        //             console.log('blockedBtnAviDocHandler ', this.blockedBtnAviDocHandler);
        //             clearInterval(conIntId);
        //             this.initCheckedOutDocuments();
        //         }
        //         this.cdr.markForCheck();
        //     }, 500);            
        // }
    }

    // private initCheckedOutDocuments() {
    //     this.intervalId = setInterval(() => {
    //         if(this.docService.IsOnline()){                
    //             this.docService.LoadCheckedOutDocuments();
    //             this.blockedBtnAviDocHandler = true;
    //         }
    //         else{
    //             this.blockedBtnAviDocHandler = false;
    //             this.cdr.markForCheck();
    //         }
    //     }, 10000);
    // }
    

    public initMenuItems() {
        this.createItems = [];
        this.createItems.push({ label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_ABLEGEN'), icon: 'pi pi-plus', command: () => this.createDocumentNoService() });

        if (this._localService === true) {
            this.createItems.push({ label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_AUS_ZWISCHENABLAGE'), icon: 'pi pi-plus', command: () => this.createDocumentFromClipboard() });
            // this.createItems.push({ label: 'Neu aus Vorlage', icon: 'pi pi-plus', command: () => this.createDocument() });
        }
        
        if (this._hasEsignatur)
            this.createItems.push({ label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.DIGITAL_SIGNIEREN'), icon: 'pi pi-shield', command: () => this.digitalSignierenNoService() });


        var ersetzenIcon = 'pi pi-pencil';

        this.editItems = [];
        if (this._localService === true) {
            ersetzenIcon = 'pi pi-sync'
            this.editItems.push({ id: DocCommand.DOKUMENT_AENDERN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_AENDERN'), icon: 'pi pi-pencil', command: () => this.checkOutOrOpenDocument(this._selectedDocument) });
        }

        this.editItems.push({ id: DocCommand.DOKUMENT_ERSETZEN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_ERSETZEN'), icon: ersetzenIcon, command: () => this.updateDocumentNoService(this._selectedDocument) });
        this.editItems.push({ id: DocCommand.STATUS_AENDERN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.STATUS_AENDERN'), icon: 'pi pi-flag', command: () => this.updateDocumentStatusNoService(this._selectedDocument) });
        this.editItems.push({ id: DocCommand.METADATEN_AENDERN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.METADATEN_AENDERN'), icon: 'pi pi-cog', command: () => this.updateDocumentKeywordsNoService(this._selectedDocument) });
        this.editItems.push({ separator: true });
        this.editItems.push({ id: DocCommand.SPERREN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.SPERREN'), icon: 'pi pi-lock', command: () => this.lockDocument(this._selectedDocument) });
        this.editItems.push({ id: DocCommand.ENTSPERREN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.ENTSPERREN'), icon: 'pi pi-lock-open', command: () => this.unlockDocument(this._selectedDocument) });
        this.editItems.push({ id: DocCommand.LOESCHEN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.LOESCHEN'), icon: 'pi pi-trash', command: () => this.softDeleteDocument(this._selectedDocument) });
        this.editItems.push({ id: DocCommand.STORNIEREN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.STORNIEREN'), icon: 'pi pi-times', command: () => this.softStornoDocument(this._selectedDocument) });
        this.editItems.push({ id: DocCommand.WIEDERHERSTELLEN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.WIEDERHERSTELLEN'), icon: 'pi pi-replay', command: () => this.softUndeleteDocument(this._selectedDocument) });
        this.editItems.push({ id: DocCommand.VERNICHTEN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.VERNICHTEN'), icon: 'pi pi-trash', command: () => this.deleteDocument(this._selectedDocument) });

        this.exportItems = [];
        this.exportItems.push({ id: DocCommand.VERSIONEN_ANZEIGEN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.VERSIONEN_ANZEIGEN'), icon: 'pi pi-list', command: () => this.showVersionen(this._selectedDocument) });
        this.exportItems.push({ id: DocCommand.DRUCKEN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.DRUCKEN'), icon: 'pi pi-print', command: () => this.printDocument(this._selectedDocument) });

        // if (this._localService === true) {
        //     this.exportItems.push({ id: DocCommand.EMAIL_SENDEN.toString(), label: this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.PER_EMAIL_SENDEN'), icon: 'pi pi-send', command: () => this.exportDocument(this._selectedDocument) });
        //     this.exportItems.push({ id: DocCommand.ZWISCHENABLAGE.toString(), label:this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.IN_ZWISCHENABLAGE_KOPIEREN'), icon: 'pi pi-copy', command: () => this.exportDocument(this._selectedDocument) });
        // }

        this.exportItems.push({ id: DocCommand.DOWNLOAD.toString(), label:this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.HERUNTERLADEN'), icon: 'pi pi-download', command: () => this.downloadDocument(this._selectedDocument) });
        this.exportItems.push({ id: DocCommand.SAVE_PDF.toString(), label:this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.ALS_PDF_SPEICHERN'), icon: 'pi pi-file-pdf', command: () => this.exportDocumentAsPDF(this._selectedDocument) });

        if (this._menuItemsCreate)
            this._menuItemsCreate.forEach(w => this.createItems.push(w));

        if (this._menuItemsEdit)
            this._menuItemsEdit.forEach(w => this.editItems.push(w));

        if (this._menuItemsExport)
            this._menuItemsExport.forEach(w => this.exportItems.push(w));

        this.updateMenuItems();
    }

    onFileDropped($event) {
        this.createDocumentDragDrop($event);
    }

    createDocumentDragDrop(files: Array<any>) {
        if (files && files.length > 0)
            this.createDocumentNoService(files[0]);
    }

    Reload() {
        if (this.contextId != null && this.contextType != null) {
            this.loadDocumentFolderTree();
        }
    }

    async createDocumentFromClipboard() {
        this.lockButtons();
        try {
            if (!await this.initAviDocHandler()) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.LOKALE_SERVICE_NOT_RUNNNING');
                return;
            }   

            const infos = await this.docService.GetClipboardInfo();

            if (!infos.blob || !infos.filename) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.KEINE_DATEI_ZWISCHENABLAGE');
                return;
            }

            const file = new File([infos.blob], infos.filename, { lastModified: infos.lastModified });

            await this.createDocumentNoService(file);   
        } catch (ex) {
            //this.commonService.notificateError(ex);
            this.handleAviDocHandlerError(ex);
        }
        this.unlockButtons();
    }


    lockButtons() {
        this.blockedButtons = true;
        this.cdr.markForCheck();
        this.searcher.cdr.markForCheck();
    }

    unlockButtons() {
        this.blockedButtons = false;
        this.cdr.markForCheck();
        this.searcher.cdr.markForCheck();
    }

    async initAviDocHandler(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                let isOnline = await this.docService.IsLocalServiceAvailable();
                if (!isOnline) {
                    // if (await this.docService.IsAviDocHandlerInstalled())
                    //     throw new Error('Der lokale Service ist nicht installiert.');

                    const url = this.docService.BuildAviDocStartAppUrl();
                    isOnline = await this.docService.TryStartAviDocHandler(url);
                }
                resolve(isOnline);
            } catch (err) {
                reject(err);
            } finally {
                // setInterval(this.checkAviDocHandlerStatus, 10000);
            }
        });
    }

    async checkAviDocHandlerStatus() {
        console.log("Check aviDocHandler Status");
            if (!await this.docService.IsLocalServiceAvailable()) {
                const url = this.docService.BuildAviDocStartAppUrl();
                await this.docService.TryStartAviDocHandler(url);
                console.log('AVIDOCHANDLER IS NOT ONLINE');
            }
            else {
                console.log('AVIDOCHANDLER IS ONLINE');
            }
    }

    async foo(selectedDocument) {
        this.commonService.notificateError('Not implemented yet!');
    }

    async checkoutDocument(selectedDocument) {
        this.lockButtons();
        try {
            if (!await this.initAviDocHandler()) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.LOKALE_SERVICE_NOT_RUNNNING');
                return;
            }

            if (!selectedDocument) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
                return;
            }
            else if (await this.docService.IsDocumentLocked(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID)){
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_GESPERRT');
                if(selectedDocument.Keywords_Lockstatus === 'Unlocked')
                {
                    this.loadDocuments();
                }
                return;
            }
            else if (await this.docService.IsDocumentDeleted(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID))
            {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_DELETED');
                return;
            }
            
            await this.docService.CheckoutDocument(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID, selectedDocument.FileMeta.DocKategorie_ID, selectedDocument.FileMeta.DocArt_ID, selectedDocument.Name, selectedDocument.Type, selectedDocument.Titel, selectedDocument.Description, selectedDocument.FileMeta.KeywordDocArtName, selectedDocument.FileMeta.KeywordGruppeName, selectedDocument.FileMeta.KeywordUntergruppeName);
    
            // this.commonService.addUnloadPreventer();
        } catch (ex) {
            console.log('Ex: ', ex);
            if (ex?.error?.code === 100) {
                const act = () => alert('Kommt noch...');
                this.commonService.confirm({ message: 'Die Datei ist bereits ausgecheckt. Möchten Sie diese überschreiben?', accept: act });
            } else {
                //this.commonService.notificateError(ex);
                this.handleAviDocHandlerError(ex);
            }
        }
        this.unlockButtons();
    }

    async isDocumentLocked(selectedDocument: any) : Promise<boolean> {
        if (await this.docService.IsDocumentLocked(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID)){
            this.unlockButtons();
            if(selectedDocument.Keywords_Lockstatus === 'Unlocked')
            {
                this.loadDocuments();   // Aktualisieren
            }
            return true;
        }
        else {
            if(selectedDocument.Keywords_Lockstatus === 'Locked')
            {
                this.loadDocuments();
            }
        }
        return false;
    }

    async unlockDocument(selectedDocument) {
        this.lockButtons();
        try {
            if (!selectedDocument) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
                return;
            }
            
            this.commonService.confirmRes({
                header: 'CORE.COMMON.AVIDOC.UNLOCK_HEADER',
                message: this.commonService.translateInstant('CORE.COMMON.AVIDOC.UNLOCK_MSG', { docName: selectedDocument.Name }),
                acceptLabel: 'CORE.COMMON.AVIDOC.UNLOCK_BUTTON',
                rejectLabel: 'CORE.COMMON.ABBRECHEN_BUTTON',
                acceptButtonStyleClass: 'p-button-primary', 
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
                accept: async () => {
                    await this.docService.UnlockDocument(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID);
                    this.refreshSearcher();
                    this.unlockButtons();            
                },
                reject: () => this.unlockButtons()
            });            
        } catch (ex) {
            console.log('Ex: ', ex.error.code);
            this.commonService.notificateError(ex);                
            this.commonService.notificateError(ex.error.code);
            this.unlockButtons();
        }
    }
    
    async lockDocument(selectedDocument) {
        this.lockButtons();
        try {
            if (!selectedDocument) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
                return;
            }
            
            this.commonService.confirmRes({
                header: 'CORE.COMMON.AVIDOC.LOCK_HEADER',
                message: this.commonService.translateInstant('CORE.COMMON.AVIDOC.LOCK_MSG', { docName: selectedDocument.Name }),
                acceptLabel: 'CORE.COMMON.AVIDOC.LOCK_BUTTON',
                rejectLabel: 'CORE.COMMON.ABBRECHEN_BUTTON',
                acceptButtonStyleClass: 'p-button-primary', 
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
                accept: async () => {
                    await this.docService.LockDocument(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID);
                    this.refreshSearcher();
                    this.unlockButtons();
                },
                reject: () => this.unlockButtons()
            });            
        } catch (ex) {
            console.log('Ex: ', ex.error.code);
            this.commonService.notificateError(ex);                
            this.commonService.notificateError(ex.error.code);
            this.unlockButtons();
        }

    }

    async softDeleteDocument(selectedDocument) {
        this.lockButtons();
        try {
            if (!selectedDocument) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
                return;
            }
            
            this.commonService.confirmRes({
                header: 'CORE.COMMON.AVIDOC.SOFTDELETE_HEADER',
                message: this.commonService.translateInstant('CORE.COMMON.AVIDOC.SOFTDELETE_MSG', { docName: selectedDocument.Name }),
                acceptLabel: 'CORE.COMMON.AVIDOC.SOFTDELETE_BUTTON',
                rejectLabel: 'CORE.COMMON.ABBRECHEN_BUTTON',
                acceptButtonStyleClass: 'p-button-primary', 
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
                accept: async () => {
                    await this.docService.softDeleteDocument(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID);
                    this.refreshSearcher();
                    this.unlockButtons();            
                },
                reject: () => this.unlockButtons()
            });            
        } catch (ex) {
            console.log('Ex: ', ex.error.code);
            this.commonService.notificateError(ex);                
            this.commonService.notificateError(ex.error.code);
            this.unlockButtons();
        }
    }

    async softUndeleteDocument(selectedDocument) {
        this.lockButtons();
        try {
            if (!selectedDocument) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
                return;
            }
            
            this.commonService.confirmRes({
                header: 'CORE.COMMON.AVIDOC.SOFTUNDELETE_HEADER',
                message: this.commonService.translateInstant('CORE.COMMON.AVIDOC.SOFTUNDELETE_MSG', { docName: selectedDocument.Name }),
                acceptLabel: 'CORE.COMMON.AVIDOC.SOFTUNDELETE_BUTTON',
                rejectLabel: 'CORE.COMMON.ABBRECHEN_BUTTON',
                acceptButtonStyleClass: 'p-button-primary', 
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
                accept: async () => {
                    await this.docService.softUndeleteDocument(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID);
                    this.refreshSearcher();
                    this.unlockButtons();            
                },
                reject: () => this.unlockButtons()
            });            
        } catch (ex) {
            console.log('Ex: ', ex.error.code);
            this.commonService.notificateError(ex);                
            this.commonService.notificateError(ex.error.code);
            this.unlockButtons();
        }
    }

    async softStornoDocument(selectedDocument) {
        this.lockButtons();
        try {
            if (!selectedDocument) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
                return;
            }
            
            this.commonService.confirmRes({
                header: 'CORE.COMMON.AVIDOC.SOFTSTORNO_HEADER',
                message: this.commonService.translateInstant('CORE.COMMON.AVIDOC.SOFTSTORNO_MSG', { docName: selectedDocument.Name }),
                acceptLabel: 'CORE.COMMON.AVIDOC.SOFTSTORNO_BUTTON',
                rejectLabel: 'CORE.COMMON.ABBRECHEN_BUTTON',
                acceptButtonStyleClass: 'p-button-primary', 
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
                accept: async () => {
                    await this.docService.softStornoDocument(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID);
                    this.refreshSearcher();
                    this.unlockButtons();            
                },
                reject: () => this.unlockButtons()
            });            
        } catch (ex) {
            console.log('Ex: ', ex.error.code);
            this.commonService.notificateError(ex);                
            this.commonService.notificateError(ex.error.code);
            this.unlockButtons();
        }
    }

    async openDocumentAsProcess(localId: string, selectedDocument: any) {
        this.lockButtons();
        try {
            if (!await this.initAviDocHandler()) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.LOKALE_SERVICE_NOT_RUNNNING');
                return;
            }

            await this.docService.OpenDocumentAsProcess(localId, selectedDocument.Name);
        } catch (ex) {
            this.commonService.notificateError(ex);
        }
        this.unlockButtons();
    }

    async discardCheckoutDocument(localId: string, selectedDocument: any) {
        this.lockButtons();
        try {
            if (!await this.initAviDocHandler()) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.LOKALE_SERVICE_NOT_RUNNNING');
                return;
            }
            await this.docService.DiscardCheckoutDocument(localId, selectedDocument.Name);
        } catch (ex) {
            this.handleAviDocHandlerError(ex);
        }
        this.unlockButtons();
    }

    async checkInDocument(localId: string, selectedDocument: any) {
        return new Promise<IDocument>(async (resolve, reject) => {
            this.lockButtons();
            try {
                if (!await this.initAviDocHandler()) {
                    this.unlockButtons();
                    this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.LOKALE_SERVICE_NOT_RUNNNING');
                    return;
                }
                const doc = this.docService.CheckedOutDocuments.find(i => i.fileDocId === localId);
                const document = this.docService.InitDoc();
                document.Id = doc.fileDocId;
                // document.Title = doc.fileName;
                document.Path = doc.path;
                document.Title = doc.title;
                document.Description = doc.description;
                const docInfo = await this.docService.CheckInDocument(document);
                this.commonService.notificateSuccess(this.translateService.instant('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_EINGECHECKT'));
                resolve(docInfo);

                // const docInfo = await this.docService.CheckInDocument(localId);
            } catch (ex) {
                // this.commonService.notificateError(ex);
                this.commonService.notificateErrorRes(this.commonService.translateInstant('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_NICHT_EINGECHECKT', { errorMsg: ex }));
                //reject(ex);
            }
            this.unlockButtons();
        });
    }

    async exportDocument(selectedDocument: any) {
        this.lockButtons();
        try {
            if (!selectedDocument) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
                return;
            }

            if (!await this.initAviDocHandler()) {
                this.unlockButtons();
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.LOKALE_SERVICE_NOT_RUNNNING');
                return;
            }

            await this.docService.OpenDocumentReadonly(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID, selectedDocument.Name, selectedDocument.Type);
        } catch (ex) {
            this.commonService.notificateError(ex);
        }
        this.unlockButtons();
    }

    async downloadDocument(selectedDocument: any) {
        if (!selectedDocument) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
            return;
        }

        this.commonService.showGlobalLoader();
        const blob = await this.apiService.getBlob(`${this.Api}/Download/${selectedDocument.DocArchivDef_ID}/${selectedDocument.ArchivDocumentID}`);
        if (blob) {
            this.fileSaverService.save(blob, selectedDocument.Name/* + selectedDocument.Type*/);
        }
        this.commonService.hideGlobalLoader();
    }

    async printDocument(selectedDocument: any) {
        if (!selectedDocument) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
            return;
        }

        this.commonService.showGlobalLoader();
        const blob = await this.apiService.getBlob(`${this.Api}/DownloadAlsPDF/${selectedDocument.DocArchivDef_ID}/${selectedDocument.ArchivDocumentID}`);
        if (blob) {
            const blobUrl = URL.createObjectURL(blob);

/*            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            iframe.contentWindow.print();*/

            let popup: Window;
            if (navigator.userAgent.match(/Edge\/\d+/g))
                popup = window.open(blobUrl, 'print', 'width=' + screen.width + ',height=' + screen.height);
            else {
                popup = window.open(blobUrl, 'print', 'fullscreen');
                if (popup.outerWidth < screen.availWidth || popup.outerHeight < screen.availHeight) {
                    popup.moveTo(0, 0);
                    popup.resizeTo(screen.availWidth, screen.availHeight);
                }
            }

            popup.print();
        }
        this.commonService.hideGlobalLoader();
    }

    async _doDeleteDocument(selectedDocument: any) {
        if (selectedDocument) {
            this.lockButtons();
            await this.apiService.delete(`${this.Api}/DeleteDocument/${selectedDocument.DocArchivDef_ID}/${selectedDocument.ArchivDocumentID}`);
            this.refreshSearcher();
            this.unlockButtons();
            this.OnDocumentsChanged.emit(null);
        }
    }

    deleteDocument(selectedDocument: any) {
        if (!selectedDocument) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
            return;
        }

        if (selectedDocument) {
            this.commonService.confirmRes({
                header: 'CORE.COMMON.AVIDOC.VERNICHTEN_HEADER',
                message: this.commonService.translateInstant('CORE.COMMON.AVIDOC.VERNICHTEN_MSG', { docName: selectedDocument.Name }),
                acceptLabel: 'CORE.COMMON.AVIDOC.VERNICHTEN_BUTTON',
                rejectLabel: 'CORE.COMMON.ABBRECHEN_BUTTON',
                acceptButtonStyleClass: 'p-button-primary', 
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
                accept: () => this._doDeleteDocument(selectedDocument),
                reject: () => this.unlockButtons()
            });
        }
    }

    async exportDocumentAsPDF(selectedDocument: any) {
        if (!selectedDocument) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
            return;
        }

        if (selectedDocument) {
            this.commonService.showGlobalLoader();
            const blob = await this.apiService.getBlob(`${this.Api}/DownloadAlsPDF/${selectedDocument.DocArchivDef_ID}/${selectedDocument.ArchivDocumentID}`);
            if (blob) {
                this.fileSaverService.save(blob, selectedDocument.Name + '.pdf');
            }
            this.commonService.hideGlobalLoader();
        }
    }

    expandAll() {
        this.folderTree.forEach(node => this.expandRecursive(node, true));
    }

    collapseAll() {
        this.folderTree.forEach(node => this.expandRecursive(node, false));
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children)
            node.children.forEach(childNode => this.expandRecursive(childNode, isExpand));
    }

    public refreshSearcher() {
        if (this.searcher)
            this.searcher.forceRefresh();
    }

    public async loadDocuments(): Promise<any> {
        if (this.searcher == null) return;
        
        const folderId = this.searcher.getSearcherParameter('paramFolder').Value;
        const kategorieId = this.searcher.getSearcherParameter('paramKategorie').Value;

        let documentList = null;
        let keywordDefs = null;
        
        this.clearPreview();

        if (this._selectedNode === this.searchFolder && this.searchFolder && this.searchResults) {
            documentList = this.searchResults;

            const docKategorieList = [...new Set(this.getFolders().map(w => w.data.DocKategorie_ID))];
            keywordDefs = [];
            for (const kategorie of docKategorieList) {
                const kwdefs = await this.apiService.getModelList(AviDocKeywordDef, `${this.Api}/GetKeywordDefsForKategorie/${kategorie}`);
                keywordDefs = keywordDefs.concat(kwdefs);
            }
        } else {
            if (folderId == null || kategorieId == null || folderId === this.commonService.GuidEmpty || kategorieId === this.commonService.GuidEmpty)
                return null;

            if (!(this.contextId && this.contextType))
                return null;

            documentList = await this.apiService.get(`${this.Api}/GetDocumentsForFolder/${folderId}/${kategorieId}?model_id=${this.contextId}&model_type=${this.contextType}`);
            keywordDefs = await this.apiService.getModelList(AviDocKeywordDef, `${this.Api}/GetKeywordDefsForKategorie/${kategorieId}`);
        }

        let result = documentList.map(w => {
            const res = {
                'ArchivDocumentID': w.ArchivDocumentID,
                'Aktiv': w.Aktiv,
                'StatusId': w.Status.Id,
                'Name': w.Name,
                'Created': w.Created,
                'CreatedUser': w.CreatedUser,
                'Modified': w.Modified,
                'ModifiedUser': w.ModifiedUser,
                'Titel': w.Titel,
                'Description': w.Description,
                'Type': w.Type,
                'Size': w.Size,
                'DocKategorieBezeichnung1': w.DocKategorieString,
                'DocArchivDef_ID': w.vonArchivDef_ID,
                'Visibility': w.Visibility,
                'FileMeta': w
            };

            if (keywordDefs != null) {
                Object.keys(w.Keywords).forEach(x => {
                    const kwdef = keywordDefs.find(kwd => kwd.FeldName === x);
                    // if (kwdef.Keywordtype.Id === AviListDetailConst.ATTRTYPE_LISTTYPE)
                    //     res['Keywords_' + x] = w.Keywords[x] ? w.Keywords[x]['Bezeichnung1'] : null;
                    if (kwdef.Keywordtype.Id !== AviListDetailConst.ATTRTYPE_REFERENCE && kwdef.Keywordtype.Id !== AviListDetailConst.ATTRTYPE_GUID)
                        res['Keywords_' + x] = w.Keywords[x];

                    if (kwdef.IstFachschluessel.Id === AviListDetailConst.JA && kwdef.Fachschluessel_AttrName === 'Lockstatus')
                        res['_lockStatus'] = res['Keywords_' + x]?.Id;

                    if (kwdef.IstFachschluessel.Id === AviListDetailConst.JA && kwdef.Fachschluessel_AttrName === 'Loeschstatus')
                        res['_loeschStatus'] = res['Keywords_' + x]?.Id;
                });
            }

            return res;
        });

        this.searcher.clearColumns();
        this.searcher.addTextColumn('Aktiv', 'Aktiv', false, '85px').setSortable(false).hide();

        this.searcher.addColumn(
            AviSearcherColumn.CreateCustom(
                'StatusId',
                'Status',
                (row, col) => {
                    if (row['StatusId'] === AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_BEARBEITUNG)
                        return '<div class="valign-center"><i class="pi pi-pencil"></i></div>';
                    else if (row['StatusId'] === AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_PRUEFUNG)
                        return '<div class="valign-center"><i class="pi pi-search-plus"></i></div>';
                    else if (row['StatusId'] === AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_FREIGABE)
                        return '<div class="valign-center"><i class="pi pi-check"></i></div>';
                    else // if (row['StatusId'] === AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_ARCHIV)
                        return '<div class="valign-center"><i class="pi pi-briefcase"></i></div>';
                },
                false,
                '45px'
            ).setSortable(false)
        );

        // this.searcher.addTextColumn('Name', 'Name', true);

        if (this.HideKeywords == null || !this.HideKeywords.some(x => x === 'Titel'))
            this.searcher.addTextColumn('Titel', 'Titel', true);

        if (this.HideKeywords == null || !this.HideKeywords.some(x => x === 'Description'))
            this.searcher.addTextColumn('Description', 'Beschreibung', true);

        this.searcher.addColumn(
            AviSearcherColumn.CreateCustom(
                'Type',
                'Type',
                (row, col) => {
                    const icon = this.dokumentService.getDocumentIcon(row['Type']);
                    return `<div class="valign-center"><i class="font-size-medium mr-4p fa ${icon}"></i>${row['Type']}</div>`;
                },
                false,
                '85px'
            ).setSortable(false)
        );
        this.searcher.addTextColumn('Size', 'Size', true);

        this.searcher.addListTypeColumn('Visibility', 'Sichtbarkeit', null, null, false).setFormatDelegate(record => record['Visibility']?.Bezeichnung);

        if (this.HideKeywords == null || !this.HideKeywords.some(x => x === 'Kategorie'))
            this.searcher.addTextColumn('DocKategorie.Bezeichnung1', 'Kategorie', false);

        // Schlüsselwörter die auf der Kategorie parametriert sind werden neu immer hinzugefügt, da ansonsten die Spaltenbreiten usw. nicht mehr stimmen
        if (keywordDefs != null) {
            keywordDefs.sort((a, b) => a.FeldName.localeCompare(b.FeldName)).forEach(kwdef => {
                if (kwdef.Fachschluessel_ModelMeta_ModelName === 'AviDocumentContainer' && (
                    kwdef.Fachschluessel_AttrName === 'Titel' ||
                    kwdef.Fachschluessel_AttrName === 'Visibility' ||
                    kwdef.Fachschluessel_AttrName === 'Abstract'))
                    return;

                if (this.HideKeywords !== null && this.HideKeywords.some(x => x === kwdef.FeldName))
                    return;

                const fieldName = 'Keywords_' + kwdef.FeldName;
                if (this.searcher.Columns.findIndex(x => x.OField === fieldName) >= 0)
                    return;

                if (kwdef.Keywordtype.Id !== AviListDetailConst.ATTRTYPE_REFERENCE && kwdef.Keywordtype.Id !== AviListDetailConst.ATTRTYPE_GUID && (this.HideKeywords == null || !this.HideKeywords.some(x => x === kwdef.FeldName))) {
                    if (kwdef.Keywordtype.Id === AviListDetailConst.ATTRTYPE_DATE)
                        this.searcher.addDateColumn(fieldName, kwdef.FeldName, 'mediumDate', true);
                    else if (kwdef.Keywordtype.Id === AviListDetailConst.ATTRTYPE_LISTTYPE)
                        this.searcher.addListTypeColumn(fieldName, kwdef.FeldName, null, null, false).setFormatDelegate(record => record[fieldName]?.Bezeichnung1);
                    else
                        this.searcher.addTextColumn(fieldName, kwdef.FeldName, true);
                }
            });
        }

        // const col = AviSearcherColumn.CreateCustom(
        //     'blaat',
        //     'blaat',
        //     (row, col) => {
        //         return this.docService.IsDocumentCheckedOut(row.DocArchivDef_ID, row.ArchivDocumentID) ? 'checked out': 'normal';
        //     },
        //     false
        // ).setSortable(false);

        // col.OField = null;
        // this.searcher.addColumn(col);

        this.searcher.addDateColumn('Created', 'Erstelldatum', 'mediumDate', true);
        this.searcher.addTextColumn('CreatedUser', 'Erstellt von', true);
        this.searcher.addDateColumn('Modified', 'Mutdatum', 'mediumDate', true);
        this.searcher.addTextColumn('ModifiedUser', 'Mutiert von', true);

        if (this.docService.DocumentServiceEnabled) {
            this.searcher.addColumn(
                AviSearcherColumn.CreateButton('btn1', ' ', null, null, null, null, '120px', ['checkin', 'edit', 'cancel'], null, null).setSortable(false)
                    .setButtonOnClickDelegate(async (row, col, buttonId) => {
                        if (buttonId === 'checkin')
                            await this.checkIn(row);
                        else if (buttonId === 'edit')
                            await this.checkOutOrOpenDocument(row);
                        else 
                            await this.cancelCheckout(row);
                    })
                    .setButtonClassesDelegate((row, col, buttonId) => {
                        const isOfficeType = this.docService.IsOfficeType(row.Type);
                        const isCheckedOut = this.docService.IsDocumentCheckedOut(row.DocArchivDef_ID, row.ArchivDocumentID);
                        const isOnline = this.docService.DocumentServiceEnabled;
                        const ret = {
                            'w-100p': true,
                            'p-button-primary': isCheckedOut || buttonId === 'cancel',
                            'p-button-secondary': !isCheckedOut && buttonId !== 'cancel',
                            'hidden': !isOnline || (buttonId === 'cancel' && !isCheckedOut) || (buttonId === 'checkin' && (isOfficeType || !isCheckedOut )),
                            'p-button-flat': true,
                            'p-button-text': true   
                        };
                        return ret;
                    })
                    .setDisabledDelegate((row, buttonId) => {
                        return this.blockedButtons;
                    })  
                    .setButtonIconDelegate((row, col, buttonId) => {
                        if (buttonId === 'checkin')
                            return 'pi pi-save';
                        else if (buttonId === 'edit') {
                            const isCheckedOut = this.docService.IsDocumentCheckedOut(row.DocArchivDef_ID, row.ArchivDocumentID);
                            if (isCheckedOut)
                                return 'pi pi-file';
                            else
                                return 'pi pi-pencil';
                        }
                        else // if (buttonId === 'cancel')
                            return 'pi pi-undo';
                    })
                    .setTooltipDelegate((row, col, buttonId) => {
                        var label = '';                        
                        if (buttonId === 'checkin') {
                            label = 'CORE.COMMON.MODEL.AVIDOC.DOKUMENT_SPEICHERN';
                        } else if (buttonId === 'edit') {
                            const isCheckedOut = this.docService.IsDocumentCheckedOut(row.DocArchivDef_ID, row.ArchivDocumentID);
                            label = isCheckedOut ? 'CORE.COMMON.MODEL.AVIDOC.CHECKEDOUT_DOKUMENT_OEFFNEN' : 'CORE.COMMON.MODEL.AVIDOC.DOKUMENT_AENDERN';    
                        } else // if (buttonId === 'cancel')
                            label = 'CORE.COMMON.MODEL.AVIDOC.AENDERUNGEN_VERWERFEN';
                        return this.translateService.instant(label);
                    })
            );
        }

        result = this.searcher.ApplyColumnFilters(result);
        this.searcher.initColumns();
        this.searcher.restoreSortOrder();
        this.searcher.restoreSettings();
        this.searcher.recalcColumnFit();
        result = this.searcher.ApplyColumnSorting(result);

        // console.log('Result: ', result);
        return result;
    }

    private refreshSearcherAndPreview(selectedDocument: any, docInfo: IDocument) {
        this.loadPreview(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID, true);
        if (docInfo)
            this.refreshSearcher();
    }

    async checkIn(selectedDocument) {
        const checkedOutDoc = this.docService.GetDocumentCheckedOut(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID);                        
        if (checkedOutDoc) {
            const docInfo = await this.checkInDocument(checkedOutDoc.fileDocId, selectedDocument); 
            this.refreshSearcherAndPreview(selectedDocument, docInfo);        
        }
    }

    // checkout or open
    async checkOutOrOpenDocument(row) {
        const checkedOutDoc = this.docService.GetDocumentCheckedOut(row.DocArchivDef_ID, row.ArchivDocumentID);                        
        if (checkedOutDoc) {
            await this.openDocumentAsProcess(checkedOutDoc.id, row);
        } else if (await this.isDocumentLocked(row)) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_GESPERRT');
        } else if (await this.docService.IsDocumentDeleted(row.DocArchivDef_ID, row.ArchivDocumentID))
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_DELETED');
        else {
            this.commonService.confirmRes({
                header: 'CORE.COMMON.AVIDOC.BEARBEITEN_HEADER',
                message: this.commonService.translateInstant('CORE.COMMON.AVIDOC.BEARBEITEN_MSG', { docName: row.Name }),
                acceptLabel: 'CORE.COMMON.AVIDOC.BEARBEITEN_BUTTON',
                rejectLabel: 'CORE.COMMON.ABBRECHEN_BUTTON',
                acceptButtonStyleClass: 'p-button-primary', 
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
                accept: () => this.checkoutDocument(row)
            });
        }
    }

    cancelCheckout(row) {
        const checkedOutDoc = this.docService.GetDocumentCheckedOut(row.DocArchivDef_ID, row.ArchivDocumentID);
        if (checkedOutDoc) {
            this.commonService.confirmRes({
                header: 'CORE.COMMON.AVIDOC.AENDERUNGEN_VERWERFEN_HEADER',
                message: this.commonService.translateInstant('CORE.COMMON.AVIDOC.AENDERUNGEN_VERWERFEN_MSG', { docName: row.Name }),
                acceptLabel: 'CORE.COMMON.AVIDOC.AENDERUNGEN_VERWERFEN_BUTTON',
                rejectLabel: 'CORE.COMMON.ABBRECHEN_BUTTON',
                acceptButtonStyleClass: 'p-button-primary', 
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
                accept: () => this.discardCheckoutDocument(checkedOutDoc.id, row)
            });
        }
    }

    getSettingsKey: any = () => {
        const baseKey = 'settings.searcher.dokumente';
        const folderId = this.searcher?.getSearcherParameter('paramFolder')?.Value;
        if (folderId)
            return `avix::${baseKey}.${folderId}`;
        else
            return `avix::${baseKey}`;
    }

    private transformNode(node): TreeNode {
        return {
            'label': node.Bezeichnung,
            'children': node.Children.map(w => this.transformNode(w)),
            'data': node,
            'expandedIcon': 'pi pi-folder-open',
            'collapsedIcon': 'pi pi-folder'
        };
    }

    public async loadDocumentFolderTree() {
        if (this.contextId && this.contextType) {
            this.commonService.showGlobalLoader();

            const test = await this.apiService.get(`${this.Api}/GetFolderTree?model_id=${this.contextId}&model_type=${this.contextType}`);

            this.folderTree = test.Children.map(w => this.transformNode(w));

            // Search Folder
            // this.searchFolder = {
            //     'label': 'Suchergebnisse',
            //     'expandedIcon': 'pi pi-search',
            //     'collapsedIcon': 'pi pi-search',
            //     'data': 'searchFolder',
            //     'styleClass': 'hidden'
            // };
            this.folderTree = this.folderTree.concat(this.searchFolder);

            this.expandAll();

            this.isFlatTree = !this.folderTree.some(w => w.children?.length > 0);

            // Wenn nur 1 Verzeichnis konfiguriert ist selektieren wir diese automatisch (2 wegen searchFolder)
            if (this.folderTree.length == 2)
                this.SelectedNode = this.folderTree[0];

            this.commonService.hideGlobalLoader();

            this.cdr.markForCheck();
        }
    }

    public async onDoubleClickDocument(doc: any) {
        if (doc) {
            await this.documentService.openPreviewDialog(doc.ArchivDocumentID, doc.DocArchivDef_ID, this.Api);
        }
    }

    getContextMenuItems = (data, menuItems: MenuItem[]): MenuItem[] => {
        this._selectedDocument = data;
        this.updateMenuItems();
        return [...this.editItems, { separator: true }, ...this.exportItems];
    }

    private updateMenuItems() {
        for (const item of this.editItems) {
            const isLocked = this._selectedDocument != null && this._selectedDocument.FileMeta.Capabilities.CanLock === true && this._selectedDocument._lockStatus === AviDokumentAblageListDetailConst.AVIDOC_LOCKSTATUS_LOCKED;
            if (item.id === DocCommand.DOKUMENT_AENDERN.toString()) item.disabled = this._selectedDocument == null && !isLocked;
            if (item.id === DocCommand.DOKUMENT_ERSETZEN.toString()) item.disabled = this._selectedDocument == null && !isLocked;
            if (item.id === DocCommand.STATUS_AENDERN.toString()) item.disabled = this._selectedDocument == null && !isLocked;
            if (item.id === DocCommand.METADATEN_AENDERN.toString()) item.disabled = this._selectedDocument == null && !isLocked;
            if (item.id === DocCommand.SPERREN.toString()) item.disabled = this._selectedDocument == null || this._selectedDocument.FileMeta.Capabilities.CanLock !== true || this._selectedDocument._lockStatus === AviDokumentAblageListDetailConst.AVIDOC_LOCKSTATUS_LOCKED;
            if (item.id === DocCommand.ENTSPERREN.toString()) item.disabled = this._selectedDocument == null || this._selectedDocument.FileMeta.Capabilities.CanLock !== true || this._selectedDocument._lockStatus !== AviDokumentAblageListDetailConst.AVIDOC_LOCKSTATUS_LOCKED;
            if (item.id === DocCommand.LOESCHEN.toString()) item.disabled = (this._selectedDocument == null || this._selectedDocument.FileMeta.Capabilities.CanSoftDelete !== true || this._selectedDocument._loeschStatus === AviDokumentAblageListDetailConst.AVIDOC_LOESCHSTATUS_GELOESCHT || this._selectedDocument._loeschStatus === AviDokumentAblageListDetailConst.AVIDOC_LOESCHSTATUS_STORNIERT)  && !isLocked;
            if (item.id === DocCommand.STORNIEREN.toString()) item.disabled = (this._selectedDocument == null || this._selectedDocument.FileMeta.Capabilities.CanSoftStorno !== true || this._selectedDocument._loeschStatus === AviDokumentAblageListDetailConst.AVIDOC_LOESCHSTATUS_GELOESCHT || this._selectedDocument._loeschStatus === AviDokumentAblageListDetailConst.AVIDOC_LOESCHSTATUS_STORNIERT)  && !isLocked;
            if (item.id === DocCommand.WIEDERHERSTELLEN.toString()) item.disabled = (this._selectedDocument == null || this._selectedDocument.FileMeta.Capabilities.CanSoftDelete !== true || (this._selectedDocument._loeschStatus !== AviDokumentAblageListDetailConst.AVIDOC_LOESCHSTATUS_GELOESCHT && this._selectedDocument._loeschStatus !== AviDokumentAblageListDetailConst.AVIDOC_LOESCHSTATUS_STORNIERT))  && !isLocked;

            if (item.id === DocCommand.SPERREN.toString()) item.visible = this._selectedDocument != null && this._selectedDocument.FileMeta.Capabilities.CanLock === true;
            if (item.id === DocCommand.ENTSPERREN.toString()) item.visible = this._selectedDocument != null && this._selectedDocument.FileMeta.Capabilities.CanLock === true;
            if (item.id === DocCommand.LOESCHEN.toString()) item.visible = this._selectedDocument != null && this._selectedDocument.FileMeta.Capabilities.CanSoftDelete === true;
            if (item.id === DocCommand.STORNIEREN.toString()) item.visible = this._selectedDocument != null && this._selectedDocument.FileMeta.Capabilities.CanSoftStorno === true;
            if (item.id === DocCommand.VERNICHTEN.toString()) item.visible = this._selectedDocument != null && this._selectedDocument.FileMeta.Capabilities.CanDelete === true;
            if (item.id === DocCommand.WIEDERHERSTELLEN.toString()) item.visible = this._selectedDocument != null && (this._selectedDocument.FileMeta.Capabilities.CanSoftDelete === true || this._selectedDocument.FileMeta.Capabilities.CanSoftStorno === true);
        }

        for (const item of this.exportItems) {
            if (item.id === DocCommand.VERSIONEN_ANZEIGEN.toString()) item.disabled = this._selectedDocument == null;
            if (item.id === DocCommand.DRUCKEN.toString()) item.disabled = this._selectedDocument == null;
            if (item.id === DocCommand.EMAIL_SENDEN.toString()) item.disabled = this._selectedDocument == null;
            if (item.id === DocCommand.ZWISCHENABLAGE.toString()) item.disabled = this._selectedDocument == null;
            if (item.id === DocCommand.DOWNLOAD.toString()) item.disabled = this._selectedDocument == null;
            if (item.id === DocCommand.SAVE_PDF.toString()) item.disabled = this._selectedDocument == null;        
        }
    }

    public onSelectDocument(doc: any) {
        this._selectedDocument = doc;
        this.updateMenuItems();
        if (this.ShowPreview && this.preview)
            this.loadPreview(doc.DocArchivDef_ID, doc.ArchivDocumentID);
    }

    private async loadPreview(archivDefId: string, docId: string, forceReload: boolean = false) {
        if (this.ShowPreview && this.preview)
            this.preview.load(archivDefId, docId, forceReload);
    }

    private clearPreview() {
        if (this.ShowPreview && this.preview)
            this.preview.clear();
    }

    getFolders(): TreeNode[] {
        const res: TreeNode[] = [];
        if (this.folderTree) {
            this.folderTree.forEach(node => {
                this.getFoldersRecursive(node, res);
                if (node !== this.searchFolder)
                    res.push(node);
            });
        }
        return res;
    }

    private getFoldersRecursive(node: TreeNode, res: TreeNode[]) {
        if (node.children) {
            node.children.forEach(childNode => {
                this.getFoldersRecursive(childNode, res);
                res.push(childNode);
            });
        }
    }

    // search
    public async SearchFiles(suchbegriff: string, fullTextSearch: boolean, approvedOnly: boolean = false) {
        const emptyGuid = this.commonService.GuidEmpty;

        const folders = this.getFolders();

        let res = [];
        for (const folder of folders) {
            if (folder.data.Id !== emptyGuid && folder.data.DocKategorie_ID !== emptyGuid && folder.data.SuchRelevant.Id === AviListDetailConst.JA) {
                let documents = await this.apiService.get(`${this.Api}/GetDocumentsForFolder/${folder.data.Id}/${folder.data.DocKategorie_ID}?model_id=${this.contextId}&model_type=${this.contextType}&suchbegriff=${suchbegriff}&fulltextsearch=${fullTextSearch}`);

                if (approvedOnly)
                    documents = documents.filter(doc => doc.Status.EqualsID(AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_FREIGABE)).ToList();

                res = res.concat(documents);
            }
        }

        res = res.filter((thing, i, arr) => arr.findIndex(t => t.ArchivDocumentID === thing.ArchivDocumentID) === i);

        return res;
    }

    public async searchButtonPressed() {
        if (this.SearchValue) {
            this.searchResults = await this.SearchFiles(this.SearchValue, this.FullTextSearch);
            this.SelectedNode = this.searchFolder;
            this.searchFolder.styleClass = 'searchnode';
            this.searchFolder.label = `Suchergebnisse (${this.SearchValue})`;
        } else {
            this.searchResults = null;
            this.searchFolder.styleClass = 'hidden';
            this.SelectedNode = this.folderTree[0];
        }
    }

    getIcon() {
        if(this.docService.IsOnline())
            return 'pi pi-circle-on';        
        return 'pi pi-circle-off';
    }

    getAviDocBtnClass() {
        return {
            'p-button-success': this.docService.IsOnline(),
            'p-button-primary p-button-outlined': !this.docService.IsOnline(),
            'p-button-raised': false,
            'w-100p-m mr-2 mb-1': true,
        };
    }

    public async connectAviDocHandlerButtonPressed() {
        if (this.docService.IsOnline()) return;

        const isOnline = await this.initAviDocHandler();
        if (isOnline) {
            this.commonService.notificateSuccess('AviDocHandler ist online');
            await this.docService.LoadCheckedOutDocuments();                
        }
        else {
            clearInterval(this.intervalId);
        }
        this.cdr.markForCheck();
    }

    public async checkinAllOpenedFiles() {
        const isOnline = await this.initAviDocHandler();
        if (isOnline) {
            try {
                this.lockButtons();
                this.commonService.notificateSuccess('Checkin gestartet');
                await this.docService.CheckinAllOpenedFiles();
            } finally {
                this.unlockButtons();
            }
        }
        else{
            this.commonService.notificateError('AviDocHandler konnte nicht gestartet werden');
            clearInterval(this.intervalId);
        }
        this.cdr.markForCheck();
    }
    

    public async checkAviUntil(condition) {
        return await new Promise(resolve => {
          const interval = setInterval(() => {
            if (condition) {
              resolve('foo');
              clearInterval(interval);
            };
          }, 10000);
        });
      }

    public _searchValueChanged(text: string) {
    }

    showVersionen(selectedDocument: any) {
        if (!selectedDocument) {
                this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
                return;
        }

        this.dialogService.open(AviDokumentVersionInfoComponent, {
            data: {
                selectedDocument: selectedDocument,
                baseUrl: this.Api,
                formType: AviDokumentVersionInfoComponent
            },
            header: 'Dokument Historie',
            width: '75%',
            closable: false,
            closeOnEscape: false,
            contentStyle: { 'max-height': '500px', 'overflow': 'auto' },
            baseZIndex: 10000
        });
    }

    // Editing ohne local service
    public async createDocumentNoService(file: File = null) {
        this.lockButtons();
        const res = await this.dokumentService.openDokumentEditingDialog(DokumentFormMode.CREATE, '0', this.contextId, this.contextType, file, 
            this.SelectedNode?.data?.DocErfassenDocArtVorschlag_ID, this.SelectedNode?.data?.DocErfassenDocArtGruppeVorschlag, this.SelectedNode?.data?.DocErfassenDocArtUntergruppeVorschlag, this._hasEsignatur, this.Api, this.ApiPrefix);

        this.unlockButtons();
        //if (res) {
            this._selectedDocument = null;
            this.updateMenuItems();
            this.searcher.forceRefresh();
            this.OnDocumentsChanged.emit(null);    
        //}
    }
    
    public async digitalSignierenNoService() {
        this.lockButtons();
        
        const params = {
            Api: this.Api,
        };

        // const res = await this.commonService.openFormDialog(AviDokumentSignierenFormComponent, 'Dokument signieren', '0', null, null, true);
        const res = await this.commonService.openFormDialog(AviDokumentSignierenFormComponent, 'Dokument signieren', '0', null, params, true);
        this.unlockButtons();
    }

    public async updateDocumentNoService(selectedDocument: any) {
        if (!selectedDocument) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
            return;
        }

        if (await this.docService.IsDocumentLocked(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID)) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_GESPERRT');
            return;
        }

        if (await this.docService.IsDocumentDeleted(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID))
        {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_DELETED');
            return;
        }

        this.lockButtons();
        const res = await this.dokumentService.openDokumentEditingDialog(DokumentFormMode.UPDATE, selectedDocument.FileMeta, this.contextId, this.contextType, null, null, null, null, this._hasEsignatur, this.Api, this.ApiPrefix);
        this.unlockButtons();
        if (res) {
            this._selectedDocument = null;
            this.updateMenuItems();
            this.searcher.forceRefresh();
            this.OnDocumentsChanged.emit(null);    
        }
    }

    public async updateDocumentStatusNoService(selectedDocument: any) {
        if (!selectedDocument) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
            return;
        }

        if (await this.docService.IsDocumentLocked(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID)) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_GESPERRT');
            return;
        }        
        
        if (await this.docService.IsDocumentDeleted(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID))
        {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_DELETED');
            return;
        }

        this.lockButtons();
        const res = await this.dokumentService.openDokumentEditingDialog(DokumentFormMode.UPDATESTATUS, selectedDocument.FileMeta, this.contextId, this.contextType, null, null, null, null, this._hasEsignatur, this.Api, this.ApiPrefix);
        this.unlockButtons();
        if (res) {
            this._selectedDocument = null;
            this.updateMenuItems();
            this.searcher.forceRefresh();
            this.OnDocumentsChanged.emit(null);    
        }
    }

    public async updateDocumentKeywordsNoService(selectedDocument: any) {
        if (!selectedDocument) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_WAEHLEN');
            return;
        }

        if (await this.docService.IsDocumentLocked(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID)) {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_GESPERRT');
            return;
        }

        if (await this.docService.IsDocumentDeleted(selectedDocument.DocArchivDef_ID, selectedDocument.ArchivDocumentID))
        {
            this.commonService.notificateErrorRes('CORE.COMMON.MODEL.AVIDOC.DOKUMENT_DELETED');
            return;
        }

        this.lockButtons();
        const res = await this.dokumentService.openDokumentEditingDialog(DokumentFormMode.UPDATEKEYWORDS, selectedDocument.FileMeta, this.contextId, this.contextType, null, null, null, null, this._hasEsignatur, this.Api, this.ApiPrefix);
        this.unlockButtons();
        if (res) {
            this._selectedDocument = null;
            this.updateMenuItems();
            this.searcher.forceRefresh();
            this.OnDocumentsChanged.emit(null);    
        }
    }

    enablePointerEvents() {
        this.splitterIFrameHack = 'pointer-events-all';
    }

    disablePointerEvents() {
        this.splitterIFrameHack = 'pointer-events-none';
    }

    doSchliessen() {
        if (this.ref)
            this.ref.close(null);
    }

    doSelektieren() {
        if (this.ref && this._selectedDocument)
            this.ref.close({ Document: this._selectedDocument, Internal: this.Internal });
    }

    private handleAviDocHandlerError(ex: any)
    {
        console.error('Ex: ', ex);

        if (ex?.error?.error)
            this.commonService.notificateError(ex.error.error);
        else
            this.commonService.notificateError(ex);
    }
}
