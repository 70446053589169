<avi-core-page title="Rechtsträger-Zugriffe" [no-box-shadow]="true">
    <avi-textblock [identifier]="'vwbp-rechtstraeger-owner-header'"
    (onTextblockClicked)="onTextblockClicked($event)"
        class="mb-2"></avi-textblock>
    <div *ngIf="!loading; else loadingTemplate">

        <div *ngIf="ownerData; else noOwnerData">

            <p-table [value]="ownerData.Members">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Benutzer</th>
                        <th *ngIf="isCurrentUserOwner()">Aktionen</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body"
                    let-member>
                    <tr>
                        <td>{{member.User}} <strong *ngIf="isOwner(member.UserId)"> (Inhaber)</strong></td>
                        <td *ngIf="isCurrentUserOwner()"
                            class="text-right">
                            <button *ngIf="!isOwner(member.UserId)"
                                pButton
                                class="ml-2"
                                label="Entfernen"
                                icon="pi pi-trash"
                                (click)="removeUser(member.UserId)"
                                iconPos="left"></button>

                            <button *ngIf="!isOwner(member.UserId)"
                                pButton
                                class="ml-2"
                                label="Master"
                                icon="pi pi-exclamation-triangle"
                                (click)="setMaster(member.UserId)"
                                iconPos="left"></button>

                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <div class="ui-g ui-fluid form-group mt-20">
                <div class="ui-g-9">
                    <span class="ui-float-label">
                        <input id="float-input"
                            type="text"
                            [required]="true"
                            class="w-100p"
                            [(ngModel)]="userToAdd"
                            pInputText>
                        <label for="float-input">Email-Adresse</label>
                    </span>
                </div>
                <div class="ui-g-3">
                    <button pButton
                        label="Hinzufügen"
                        class="p-button-primary w-100p"
                        [disabled]="!userToAdd"
                        icon="pi pi-plus"
                        (click)="addUser($event)"
                        iconPos="left"></button>
                </div>
            </div>

        </div>

        <ng-template #noOwnerData>
            Keine Daten vorhanden. Vermutlich keine Rechtsträger vorhanden.
        </ng-template>

        <avi-core-dbg [m]="ownerData"></avi-core-dbg>
    </div>
    <ng-template #loadingTemplate>
        Lade...
    </ng-template>
</avi-core-page>
