import { Expose, Type } from 'class-transformer';

import { AppUser } from '../system/appuser.model';
import { TechRole } from '../system/techrole.model';
import { ActivityQueue } from './activityqueue.model';
import { ModelStateGroup } from '../system/modelstategroup.model';
import { ModelState } from '../system/modelstate.model';
import { MappingDef } from '../system/mappingdef.model';
import { StatusWorkflowDef } from '../workflow/statusworkflowdef.model';
import { CustomPropCollectionDef } from '../system/custompropcollectiondef.model';
import { ModelMeta } from '../system/modelmeta.model';
import { Service } from '../system/service.model';
import { SqlJobDef } from '../system/sqljobdef.model';
import { Task } from '../system/task.model';
import { ReportDef } from '../system/reportdef.model';
import { ActivityTypeGruppe } from '../activity/activitytypegruppe.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class ActivityType extends BaseModel {
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }

    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }

    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }

    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }

    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }

    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }

    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }

    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }

    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }

    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }

    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }

    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }

    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }

    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }

    @Expose()
    public get Remember_Months(): number { return this.getProperty('Remember_Months'); }
    public set Remember_Months(value: number) { this.setProperty('Remember_Months', value); }

    @Expose()
    public get Remember_Days(): number { return this.getProperty('Remember_Days'); }
    public set Remember_Days(value: number) { this.setProperty('Remember_Days', value); }

    @Expose()
    public get Remember_Hours(): number { return this.getProperty('Remember_Hours'); }
    public set Remember_Hours(value: number) { this.setProperty('Remember_Hours', value); }

    @Expose()
    public get Remember_Minutes(): number { return this.getProperty('Remember_Minutes'); }
    public set Remember_Minutes(value: number) { this.setProperty('Remember_Minutes', value); }

    @Expose()
    public get DueIn_Months(): number { return this.getProperty('DueIn_Months'); }
    public set DueIn_Months(value: number) { this.setProperty('DueIn_Months', value); }

    @Expose()
    public get DueIn_Days(): number { return this.getProperty('DueIn_Days'); }
    public set DueIn_Days(value: number) { this.setProperty('DueIn_Days', value); }

    @Expose()
    public get DueIn_Hours(): number { return this.getProperty('DueIn_Hours'); }
    public set DueIn_Hours(value: number) { this.setProperty('DueIn_Hours', value); }

    @Expose()
    public get DueIn_Minutes(): number { return this.getProperty('DueIn_Minutes'); }
    public set DueIn_Minutes(value: number) { this.setProperty('DueIn_Minutes', value); }

    @Expose()
    public get Escalate_Months(): number { return this.getProperty('Escalate_Months'); }
    public set Escalate_Months(value: number) { this.setProperty('Escalate_Months', value); }

    @Expose()
    public get Escalate_Days(): number { return this.getProperty('Escalate_Days'); }
    public set Escalate_Days(value: number) { this.setProperty('Escalate_Days', value); }

    @Expose()
    public get Escalate_Hours(): number { return this.getProperty('Escalate_Hours'); }
    public set Escalate_Hours(value: number) { this.setProperty('Escalate_Hours', value); }

    @Expose()
    public get Escalate_Minutes(): number { return this.getProperty('Escalate_Minutes'); }
    public set Escalate_Minutes(value: number) { this.setProperty('Escalate_Minutes', value); }

    @Expose()
    public get DefaultPeriod(): number { return this.getProperty('DefaultPeriod'); }
    public set DefaultPeriod(value: number) { this.setProperty('DefaultPeriod', value); }

    @Expose()
    public get DefaultPeriodMonthDay(): number { return this.getProperty('DefaultPeriodMonthDay'); }
    public set DefaultPeriodMonthDay(value: number) { this.setProperty('DefaultPeriodMonthDay', value); }

    @Expose()
    public get ActivityPeriodeLabel(): string { return this.getProperty('ActivityPeriodeLabel'); }
    public set ActivityPeriodeLabel(value: string) { this.setProperty('ActivityPeriodeLabel', value); }

    @Expose()
    public get DefaultTitel(): string { return this.getProperty('DefaultTitel'); }
    public set DefaultTitel(value: string) { this.setProperty('DefaultTitel', value); }

    @Expose()
    public get DefaultDescription(): string { return this.getProperty('DefaultDescription'); }
    public set DefaultDescription(value: string) { this.setProperty('DefaultDescription', value); }

    @Expose()
    public get Context1Zwingend(): boolean { return this.getProperty('Context1Zwingend'); }
    public set Context1Zwingend(value: boolean) { this.setProperty('Context1Zwingend', value); }

    @Expose()
    public get Context1Bezeichnung1(): string { return this.getProperty('Context1Bezeichnung1'); }
    public set Context1Bezeichnung1(value: string) { this.setProperty('Context1Bezeichnung1', value); }

    @Expose()
    public get Context1Bezeichnung2(): string { return this.getProperty('Context1Bezeichnung2'); }
    public set Context1Bezeichnung2(value: string) { this.setProperty('Context1Bezeichnung2', value); }

    @Expose()
    public get Context1Bezeichnung3(): string { return this.getProperty('Context1Bezeichnung3'); }
    public set Context1Bezeichnung3(value: string) { this.setProperty('Context1Bezeichnung3', value); }

    @Expose()
    public get Context1Bezeichnung4(): string { return this.getProperty('Context1Bezeichnung4'); }
    public set Context1Bezeichnung4(value: string) { this.setProperty('Context1Bezeichnung4', value); }

    @Expose()
    public get Context1Bezeichnung5(): string { return this.getProperty('Context1Bezeichnung5'); }
    public set Context1Bezeichnung5(value: string) { this.setProperty('Context1Bezeichnung5', value); }

    @Expose()
    public get Context1Bezeichnung6(): string { return this.getProperty('Context1Bezeichnung6'); }
    public set Context1Bezeichnung6(value: string) { this.setProperty('Context1Bezeichnung6', value); }

    @Expose()
    public get Context1Bezeichnung7(): string { return this.getProperty('Context1Bezeichnung7'); }
    public set Context1Bezeichnung7(value: string) { this.setProperty('Context1Bezeichnung7', value); }

    @Expose()
    public get Context2Zwingend(): boolean { return this.getProperty('Context2Zwingend'); }
    public set Context2Zwingend(value: boolean) { this.setProperty('Context2Zwingend', value); }

    @Expose()
    public get Context2Bezeichnung1(): string { return this.getProperty('Context2Bezeichnung1'); }
    public set Context2Bezeichnung1(value: string) { this.setProperty('Context2Bezeichnung1', value); }

    @Expose()
    public get Context2Bezeichnung2(): string { return this.getProperty('Context2Bezeichnung2'); }
    public set Context2Bezeichnung2(value: string) { this.setProperty('Context2Bezeichnung2', value); }

    @Expose()
    public get Context2Bezeichnung3(): string { return this.getProperty('Context2Bezeichnung3'); }
    public set Context2Bezeichnung3(value: string) { this.setProperty('Context2Bezeichnung3', value); }

    @Expose()
    public get Context2Bezeichnung4(): string { return this.getProperty('Context2Bezeichnung4'); }
    public set Context2Bezeichnung4(value: string) { this.setProperty('Context2Bezeichnung4', value); }

    @Expose()
    public get Context2Bezeichnung5(): string { return this.getProperty('Context2Bezeichnung5'); }
    public set Context2Bezeichnung5(value: string) { this.setProperty('Context2Bezeichnung5', value); }

    @Expose()
    public get Context2Bezeichnung6(): string { return this.getProperty('Context2Bezeichnung6'); }
    public set Context2Bezeichnung6(value: string) { this.setProperty('Context2Bezeichnung6', value); }

    @Expose()
    public get Context2Bezeichnung7(): string { return this.getProperty('Context2Bezeichnung7'); }
    public set Context2Bezeichnung7(value: string) { this.setProperty('Context2Bezeichnung7', value); }

    @Expose()
    public get Context3Zwingend(): boolean { return this.getProperty('Context3Zwingend'); }
    public set Context3Zwingend(value: boolean) { this.setProperty('Context3Zwingend', value); }

    @Expose()
    public get Context3Bezeichnung1(): string { return this.getProperty('Context3Bezeichnung1'); }
    public set Context3Bezeichnung1(value: string) { this.setProperty('Context3Bezeichnung1', value); }

    @Expose()
    public get Context3Bezeichnung2(): string { return this.getProperty('Context3Bezeichnung2'); }
    public set Context3Bezeichnung2(value: string) { this.setProperty('Context3Bezeichnung2', value); }

    @Expose()
    public get Context3Bezeichnung3(): string { return this.getProperty('Context3Bezeichnung3'); }
    public set Context3Bezeichnung3(value: string) { this.setProperty('Context3Bezeichnung3', value); }

    @Expose()
    public get Context3Bezeichnung4(): string { return this.getProperty('Context3Bezeichnung4'); }
    public set Context3Bezeichnung4(value: string) { this.setProperty('Context3Bezeichnung4', value); }

    @Expose()
    public get Context3Bezeichnung5(): string { return this.getProperty('Context3Bezeichnung5'); }
    public set Context3Bezeichnung5(value: string) { this.setProperty('Context3Bezeichnung5', value); }

    @Expose()
    public get Context3Bezeichnung6(): string { return this.getProperty('Context3Bezeichnung6'); }
    public set Context3Bezeichnung6(value: string) { this.setProperty('Context3Bezeichnung6', value); }

    @Expose()
    public get Context3Bezeichnung7(): string { return this.getProperty('Context3Bezeichnung7'); }
    public set Context3Bezeichnung7(value: string) { this.setProperty('Context3Bezeichnung7', value); }

    @Expose()
    public get Context4Zwingend(): boolean { return this.getProperty('Context4Zwingend'); }
    public set Context4Zwingend(value: boolean) { this.setProperty('Context4Zwingend', value); }

    @Expose()
    public get Context4Bezeichnung1(): string { return this.getProperty('Context4Bezeichnung1'); }
    public set Context4Bezeichnung1(value: string) { this.setProperty('Context4Bezeichnung1', value); }

    @Expose()
    public get Context4Bezeichnung2(): string { return this.getProperty('Context4Bezeichnung2'); }
    public set Context4Bezeichnung2(value: string) { this.setProperty('Context4Bezeichnung2', value); }

    @Expose()
    public get Context4Bezeichnung3(): string { return this.getProperty('Context4Bezeichnung3'); }
    public set Context4Bezeichnung3(value: string) { this.setProperty('Context4Bezeichnung3', value); }

    @Expose()
    public get Context4Bezeichnung4(): string { return this.getProperty('Context4Bezeichnung4'); }
    public set Context4Bezeichnung4(value: string) { this.setProperty('Context4Bezeichnung4', value); }

    @Expose()
    public get Context4Bezeichnung5(): string { return this.getProperty('Context4Bezeichnung5'); }
    public set Context4Bezeichnung5(value: string) { this.setProperty('Context4Bezeichnung5', value); }

    @Expose()
    public get Context4Bezeichnung6(): string { return this.getProperty('Context4Bezeichnung6'); }
    public set Context4Bezeichnung6(value: string) { this.setProperty('Context4Bezeichnung6', value); }

    @Expose()
    public get Context4Bezeichnung7(): string { return this.getProperty('Context4Bezeichnung7'); }
    public set Context4Bezeichnung7(value: string) { this.setProperty('Context4Bezeichnung7', value); }

    @Expose()
    public get FolgeActZeigeWizard(): boolean { return this.getProperty('FolgeActZeigeWizard'); }
    public set FolgeActZeigeWizard(value: boolean) { this.setProperty('FolgeActZeigeWizard', value); }

    @Expose()
    public get DefaultOwner_ID(): string { return this.getModelId('DefaultOwner_ID'); }
    public set DefaultOwner_ID(value: string) { this.setModelId('DefaultOwner_ID', value); }

    @Expose()
    @Type(() => AppUser)
    public get DefaultOwner(): AppUser { return this.getModel('DefaultOwner'); }
    public set DefaultOwner(value: AppUser) { this.setModel('DefaultOwner', value); }

    @Expose()
    public get DefaultAssignedTo_ID(): string { return this.getModelId('DefaultAssignedTo_ID'); }
    public set DefaultAssignedTo_ID(value: string) { this.setModelId('DefaultAssignedTo_ID', value); }

    @Expose()
    @Type(() => AppUser)
    public get DefaultAssignedTo(): AppUser { return this.getModel('DefaultAssignedTo'); }
    public set DefaultAssignedTo(value: AppUser) { this.setModel('DefaultAssignedTo', value); }

    @Expose()
    public get EscalationActivityType_ID(): string { return this.getModelId('EscalationActivityType_ID'); }
    public set EscalationActivityType_ID(value: string) { this.setModelId('EscalationActivityType_ID', value); }

    @Expose()
    @Type(() => ActivityType)
    public get EscalationActivityType(): ActivityType { return this.getModel('EscalationActivityType'); }
    public set EscalationActivityType(value: ActivityType) { this.setModel('EscalationActivityType', value); }

    @Expose()
    public get EscalationTo_ID(): string { return this.getModelId('EscalationTo_ID'); }
    public set EscalationTo_ID(value: string) { this.setModelId('EscalationTo_ID', value); }

    @Expose()
    @Type(() => AppUser)
    public get EscalationTo(): AppUser { return this.getModel('EscalationTo'); }
    public set EscalationTo(value: AppUser) { this.setModel('EscalationTo', value); }

    @Expose()
    public get CreateRole_ID(): string { return this.getModelId('CreateRole_ID'); }
    public set CreateRole_ID(value: string) { this.setModelId('CreateRole_ID', value); }

    @Expose()
    @Type(() => TechRole)
    public get rCreateRole(): TechRole { return this.getModel('rCreateRole'); }
    public set rCreateRole(value: TechRole) { this.setModel('rCreateRole', value); }

    @Expose()
    public get UpdateRole_ID(): string { return this.getModelId('UpdateRole_ID'); }
    public set UpdateRole_ID(value: string) { this.setModelId('UpdateRole_ID', value); }

    @Expose()
    @Type(() => TechRole)
    public get rUpdateRole(): TechRole { return this.getModel('rUpdateRole'); }
    public set rUpdateRole(value: TechRole) { this.setModel('rUpdateRole', value); }

    @Expose()
    public get DeleteRole_ID(): string { return this.getModelId('DeleteRole_ID'); }
    public set DeleteRole_ID(value: string) { this.setModelId('DeleteRole_ID', value); }

    @Expose()
    @Type(() => TechRole)
    public get rDeleteRole(): TechRole { return this.getModel('rDeleteRole'); }
    public set rDeleteRole(value: TechRole) { this.setModel('rDeleteRole', value); }

    @Expose()
    public get DateRole_ID(): string { return this.getModelId('DateRole_ID'); }
    public set DateRole_ID(value: string) { this.setModelId('DateRole_ID', value); }

    @Expose()
    @Type(() => TechRole)
    public get rDateRole(): TechRole { return this.getModel('rDateRole'); }
    public set rDateRole(value: TechRole) { this.setModel('rDateRole', value); }

    @Expose()
    public get ActivityQueue_ID(): string { return this.getModelId('ActivityQueue_ID'); }
    public set ActivityQueue_ID(value: string) { this.setModelId('ActivityQueue_ID', value); }

    @Expose()
    @Type(() => ActivityQueue)
    public get rDefaultQueue(): ActivityQueue { return this.getModel('rDefaultQueue'); }
    public set rDefaultQueue(value: ActivityQueue) { this.setModel('rDefaultQueue', value); }

    @Expose()
    public get ModelStateGroup_ID(): string { return this.getModelId('ModelStateGroup_ID'); }
    public set ModelStateGroup_ID(value: string) { this.setModelId('ModelStateGroup_ID', value); }

    @Expose()
    @Type(() => ModelStateGroup)
    public get ModelStateGroup(): ModelStateGroup { return this.getModel('ModelStateGroup'); }
    public set ModelStateGroup(value: ModelStateGroup) { this.setModel('ModelStateGroup', value); }

    @Expose()
    public get DefaultModelState_ID(): string { return this.getModelId('DefaultModelState_ID'); }
    public set DefaultModelState_ID(value: string) { this.setModelId('DefaultModelState_ID', value); }

    @Expose()
    @Type(() => ModelState)
    public get DefaultModelState(): ModelState { return this.getModel('DefaultModelState'); }
    public set DefaultModelState(value: ModelState) { this.setModel('DefaultModelState', value); }

    @Expose()
    public get ErlModelState_ID(): string { return this.getModelId('ErlModelState_ID'); }
    public set ErlModelState_ID(value: string) { this.setModelId('ErlModelState_ID', value); }

    @Expose()
    @Type(() => ModelState)
    public get ErlModelState(): ModelState { return this.getModel('ErlModelState'); }
    public set ErlModelState(value: ModelState) { this.setModel('ErlModelState', value); }

    @Expose()
    public get StateMappingDef_ID(): string { return this.getModelId('StateMappingDef_ID'); }
    public set StateMappingDef_ID(value: string) { this.setModelId('StateMappingDef_ID', value); }

    @Expose()
    @Type(() => MappingDef)
    public get StateMappingDef(): MappingDef { return this.getModel('StateMappingDef'); }
    public set StateMappingDef(value: MappingDef) { this.setModel('StateMappingDef', value); }

    @Expose()
    public get StatusWorkflowDef_ID(): string { return this.getModelId('StatusWorkflowDef_ID'); }
    public set StatusWorkflowDef_ID(value: string) { this.setModelId('StatusWorkflowDef_ID', value); }

    @Expose()
    @Type(() => StatusWorkflowDef)
    public get StatusWorkflowDef(): StatusWorkflowDef { return this.getModel('StatusWorkflowDef'); }
    public set StatusWorkflowDef(value: StatusWorkflowDef) { this.setModel('StatusWorkflowDef', value); }

    @Expose()
    public get CustomProperties_ID(): string { return this.getModelId('CustomProperties_ID'); }
    public set CustomProperties_ID(value: string) { this.setModelId('CustomProperties_ID', value); }

    @Expose()
    @Type(() => CustomPropCollectionDef)
    public get CustomProperties(): CustomPropCollectionDef { return this.getModel('CustomProperties'); }
    public set CustomProperties(value: CustomPropCollectionDef) { this.setModel('CustomProperties', value); }

    @Expose()
    public get vonContext1ModelMeta_ID(): string { return this.getModelId('vonContext1ModelMeta_ID'); }
    public set vonContext1ModelMeta_ID(value: string) { this.setModelId('vonContext1ModelMeta_ID', value); }

    @Expose()
    @Type(() => ModelMeta)
    public get vonContext1ModelMeta(): ModelMeta { return this.getModel('vonContext1ModelMeta'); }
    public set vonContext1ModelMeta(value: ModelMeta) { this.setModel('vonContext1ModelMeta', value); }

    @Expose()
    public get vonContext2ModelMeta_ID(): string { return this.getModelId('vonContext2ModelMeta_ID'); }
    public set vonContext2ModelMeta_ID(value: string) { this.setModelId('vonContext2ModelMeta_ID', value); }

    @Expose()
    @Type(() => ModelMeta)
    public get vonContext2ModelMeta(): ModelMeta { return this.getModel('vonContext2ModelMeta'); }
    public set vonContext2ModelMeta(value: ModelMeta) { this.setModel('vonContext2ModelMeta', value); }

    @Expose()
    public get vonContext3ModelMeta_ID(): string { return this.getModelId('vonContext3ModelMeta_ID'); }
    public set vonContext3ModelMeta_ID(value: string) { this.setModelId('vonContext3ModelMeta_ID', value); }

    @Expose()
    @Type(() => ModelMeta)
    public get vonContext3ModelMeta(): ModelMeta { return this.getModel('vonContext3ModelMeta'); }
    public set vonContext3ModelMeta(value: ModelMeta) { this.setModel('vonContext3ModelMeta', value); }

    @Expose()
    public get vonContext4ModelMeta_ID(): string { return this.getModelId('vonContext4ModelMeta_ID'); }
    public set vonContext4ModelMeta_ID(value: string) { this.setModelId('vonContext4ModelMeta_ID', value); }

    @Expose()
    @Type(() => ModelMeta)
    public get vonContext4ModelMeta(): ModelMeta { return this.getModel('vonContext4ModelMeta'); }
    public set vonContext4ModelMeta(value: ModelMeta) { this.setModel('vonContext4ModelMeta', value); }

    @Expose()
    public get ValidatorService_ID(): string { return this.getModelId('ValidatorService_ID'); }
    public set ValidatorService_ID(value: string) { this.setModelId('ValidatorService_ID', value); }

    @Expose()
    @Type(() => Service)
    public get ValidatorService(): Service { return this.getModel('ValidatorService'); }
    public set ValidatorService(value: Service) { this.setModel('ValidatorService', value); }

    @Expose()
    public get vonSqlJobDefCheck_ID(): string { return this.getModelId('vonSqlJobDefCheck_ID'); }
    public set vonSqlJobDefCheck_ID(value: string) { this.setModelId('vonSqlJobDefCheck_ID', value); }

    @Expose()
    @Type(() => SqlJobDef)
    public get vonSqlJobDefCheck(): SqlJobDef { return this.getModel('vonSqlJobDefCheck'); }
    public set vonSqlJobDefCheck(value: SqlJobDef) { this.setModel('vonSqlJobDefCheck', value); }

    @Expose()
    public get CreateTask_ID(): string { return this.getModelId('CreateTask_ID'); }
    public set CreateTask_ID(value: string) { this.setModelId('CreateTask_ID', value); }

    @Expose()
    @Type(() => Task)
    public get CreateTask(): Task { return this.getModel('CreateTask'); }
    public set CreateTask(value: Task) { this.setModel('CreateTask', value); }

    @Expose()
    public get Task_ID(): string { return this.getModelId('Task_ID'); }
    public set Task_ID(value: string) { this.setModelId('Task_ID', value); }

    @Expose()
    @Type(() => Task)
    public get Task(): Task { return this.getModel('Task'); }
    public set Task(value: Task) { this.setModel('Task', value); }

    @Expose()
    public get FolgeAktivitaet_ID(): string { return this.getModelId('FolgeAktivitaet_ID'); }
    public set FolgeAktivitaet_ID(value: string) { this.setModelId('FolgeAktivitaet_ID', value); }

    @Expose()
    @Type(() => ActivityType)
    public get FolgeAktivitaet(): ActivityType { return this.getModel('FolgeAktivitaet'); }
    public set FolgeAktivitaet(value: ActivityType) { this.setModel('FolgeAktivitaet', value); }

    @Expose()
    public get ReportDef_ID(): string { return this.getModelId('ReportDef_ID'); }
    public set ReportDef_ID(value: string) { this.setModelId('ReportDef_ID', value); }

    @Expose()
    @Type(() => ReportDef)
    public get ReportDef(): ReportDef { return this.getModel('ReportDef'); }
    public set ReportDef(value: ReportDef) { this.setModel('ReportDef', value); }

    @Expose()
    public get vonGruppe_ID(): string { return this.getModelId('vonGruppe_ID'); }
    public set vonGruppe_ID(value: string) { this.setModelId('vonGruppe_ID', value); }

    @Expose()
    @Type(() => ActivityTypeGruppe)
    public get vonGruppe(): ActivityTypeGruppe { return this.getModel('vonGruppe'); }
    public set vonGruppe(value: ActivityTypeGruppe) { this.setModel('vonGruppe', value); }

    @Expose()
    @Type(() => ListType)
    public get EscalationToType(): ListType { return this.getProperty('EscalationToType'); }
    public set EscalationToType(value: ListType) { this.setProperty('EscalationToType', value); }

    @Expose()
    @Type(() => ListType)
    public get DescriptionPflicht(): ListType { return this.getProperty('DescriptionPflicht'); }
    public set DescriptionPflicht(value: ListType) { this.setProperty('DescriptionPflicht', value); }

    @Expose()
    @Type(() => ListType)
    public get DatePrecision(): ListType { return this.getProperty('DatePrecision'); }
    public set DatePrecision(value: ListType) { this.setProperty('DatePrecision', value); }

    @Expose()
    @Type(() => ListType)
    public get ReminderBeforeStart(): ListType { return this.getProperty('ReminderBeforeStart'); }
    public set ReminderBeforeStart(value: ListType) { this.setProperty('ReminderBeforeStart', value); }

    @Expose()
    @Type(() => ListType)
    public get PeriodPossible(): ListType { return this.getProperty('PeriodPossible'); }
    public set PeriodPossible(value: ListType) { this.setProperty('PeriodPossible', value); }

    @Expose()
    @Type(() => ListType)
    public get PeriodExtendType(): ListType { return this.getProperty('PeriodExtendType'); }
    public set PeriodExtendType(value: ListType) { this.setProperty('PeriodExtendType', value); }

    @Expose()
    @Type(() => ListType)
    public get PeriodActivityDescriptionSource(): ListType { return this.getProperty('PeriodActivityDescriptionSource'); }
    public set PeriodActivityDescriptionSource(value: ListType) { this.setProperty('PeriodActivityDescriptionSource', value); }

    @Expose()
    @Type(() => ListType)
    public get PeriodActivityShowNewWizardOnFinish(): ListType { return this.getProperty('PeriodActivityShowNewWizardOnFinish'); }
    public set PeriodActivityShowNewWizardOnFinish(value: ListType) { this.setProperty('PeriodActivityShowNewWizardOnFinish', value); }

    @Expose()
    @Type(() => ListType)
    public get DefaultPeriodTyp(): ListType { return this.getProperty('DefaultPeriodTyp'); }
    public set DefaultPeriodTyp(value: ListType) { this.setProperty('DefaultPeriodTyp', value); }

    @Expose()
    @Type(() => ListType)
    public get DefaultPeriodDayTyp(): ListType { return this.getProperty('DefaultPeriodDayTyp'); }
    public set DefaultPeriodDayTyp(value: ListType) { this.setProperty('DefaultPeriodDayTyp', value); }

    @Expose()
    @Type(() => ListType)
    public get ActivityPeriodeSichtbar(): ListType { return this.getProperty('ActivityPeriodeSichtbar'); }
    public set ActivityPeriodeSichtbar(value: ListType) { this.setProperty('ActivityPeriodeSichtbar', value); }

    @Expose()
    @Type(() => ListType)
    public get ActivityBeschreibungKommentar(): ListType { return this.getProperty('ActivityBeschreibungKommentar'); }
    public set ActivityBeschreibungKommentar(value: ListType) { this.setProperty('ActivityBeschreibungKommentar', value); }

    @Expose()
    @Type(() => ListType)
    public get IsRTF(): ListType { return this.getProperty('IsRTF'); }
    public set IsRTF(value: ListType) { this.setProperty('IsRTF', value); }

    @Expose()
    @Type(() => ListType)
    public get ContinueComment(): ListType { return this.getProperty('ContinueComment'); }
    public set ContinueComment(value: ListType) { this.setProperty('ContinueComment', value); }

    @Expose()
    @Type(() => ListType)
    public get DefaultPriority(): ListType { return this.getProperty('DefaultPriority'); }
    public set DefaultPriority(value: ListType) { this.setProperty('DefaultPriority', value); }

    @Expose()
    @Type(() => ListType)
    public get ReminderEnabled(): ListType { return this.getProperty('ReminderEnabled'); }
    public set ReminderEnabled(value: ListType) { this.setProperty('ReminderEnabled', value); }

    @Expose()
    @Type(() => ListType)
    public get CreateTaskContext(): ListType { return this.getProperty('CreateTaskContext'); }
    public set CreateTaskContext(value: ListType) { this.setProperty('CreateTaskContext', value); }

    @Expose()
    @Type(() => ListType)
    public get TaskContext(): ListType { return this.getProperty('TaskContext'); }
    public set TaskContext(value: ListType) { this.setProperty('TaskContext', value); }

    @Expose()
    @Type(() => ListType)
    public get CloseActivityAfterTask(): ListType { return this.getProperty('CloseActivityAfterTask'); }
    public set CloseActivityAfterTask(value: ListType) { this.setProperty('CloseActivityAfterTask', value); }

    @Expose()
    @Type(() => ListType)
    public get RightToDelete(): ListType { return this.getProperty('RightToDelete'); }
    public set RightToDelete(value: ListType) { this.setProperty('RightToDelete', value); }

    @Expose()
    @Type(() => ListType)
    public get RightToChangeDates(): ListType { return this.getProperty('RightToChangeDates'); }
    public set RightToChangeDates(value: ListType) { this.setProperty('RightToChangeDates', value); }

    @Expose()
    @Type(() => ListType)
    public get RightToFinish(): ListType { return this.getProperty('RightToFinish'); }
    public set RightToFinish(value: ListType) { this.setProperty('RightToFinish', value); }

}
