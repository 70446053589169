import { APPVERSION } from './../environments/version';
import { VwbPNavigationService } from './services/navigation.service';
import { Component, AfterViewInit, ElementRef, Renderer2, ViewChild, ViewContainerRef, ChangeDetectorRef, OnInit, OnDestroy, NgZone } from '@angular/core';
import { HostListener } from '@angular/core';

import { ScrollPanel } from 'primeng/scrollpanel';
import { filter } from 'rxjs/operators';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { L10n, loadCldr } from '@syncfusion/ej2-base';
import { AviAuthService, AviCommonService, AviDocumentService, AviJobService, OAuthConfigService } from '@avi-x/avi-core';
import { Location } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import { OAuthService } from 'angular-oauth2-oidc';
import { VwbPCommonService, VwbPRoleConst } from '@avi-x/vwbp-public';

import { TranslateService } from '@ngx-translate/core';
import { VwbPInternPostfachService } from '@avi-x/vwbp-intern';
import { PrimeNGConfig } from 'primeng/api';
import { InputTextarea } from 'primeng/inputtextarea';
import { NgxPermissionsService } from 'ngx-permissions';

declare const require: any;
loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/de-CH/ca-gregorian.json'),
    require('cldr-data/main/de-CH/numbers.json'),
    require('cldr-data/main/de-CH/timeZoneNames.json')
);

// loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
L10n.load({
    'de-CH': {
        'datepicker': {
            placeholder: 'Wählen Sie ein Datum',
            today: 'heute'
        },
        'schedule': {
            day: 'Tag',
            week: 'Woche',
            workWeek: 'Arbeitswoche',
            month: 'Monat',
            agenda: 'Agenda',
            weekAgenda: 'Wochen-Agenda',
            workWeekAgenda: 'Arbeitswochen-Agenda',
            monthAgenda: 'Monats-Agenda',
            today: 'Heute',
            noEvents: 'Keine Einträge',
            emptyContainer: 'There are no events scheduled on this day.',
            allDay: 'Ganztägig',
            start: 'Start',
            end: 'End',
            more: 'mehr',
            close: 'Close',
            cancel: 'Cancel',
            noTitle: '(No Title)',
            delete: 'Delete',
            deleteEvent: 'Delete Event',
            deleteMultipleEvent: 'Delete Multiple Events',
            selectedItems: 'Items selected',
            deleteSeries: 'Delete Series',
            edit: 'Edit',
            editSeries: 'Edit Series',
            editEvent: 'Edit Event',
            createEvent: 'Create',
            subject: 'Subject',
            addTitle: 'Add title',
            moreDetails: 'More Details',
            save: 'Save',
            editContent: 'Do you want to edit only this event or entire series?',
            deleteRecurrenceContent: 'Do you want to delete only this event or entire series?',
            deleteContent: 'Are you sure you want to delete this event?',
            deleteMultipleContent: 'Are you sure you want to delete the selected events?',
            newEvent: 'New Event',
            title: 'Title',
            location: 'Location',
            description: 'Description',
            timezone: 'Timezone',
            startTimezone: 'Start Timezone',
            endTimezone: 'End Timezone',
            repeat: 'Repeat',
            saveButton: 'Save',
            cancelButton: 'Cancel',
            deleteButton: 'Delete',
            recurrence: 'Recurrence',
            wrongPattern: 'The recurrence pattern is not valid.',
            seriesChangeAlert: 'The changes made to specific instances of this series will be cancelled ' +
                'and those events will match the series again.',
            createError: 'The duration of the event must be shorter than how frequently it occurs. ' +
                'Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
            recurrenceDateValidation: 'Some months have fewer than the selected date. For these months, ' +
                'the occurrence will fall on the last date of the month.',
            sameDayAlert: 'Two occurrences of the same event cannot occur on the same day.',
            editRecurrence: 'Edit Recurrence',
            repeats: 'Repeats',
            alert: 'Alert',
            startEndError: 'The selected end date occurs before the start date.',
            invalidDateError: 'The entered date value is invalid.',
            ok: 'Ok',
            occurrence: 'Occurrence',
            series: 'Series',
            previous: 'Zurück',
            next: 'Weiter',
            timelineDay: 'Timeline Tag',
            timelineWeek: 'Timeline Woche',
            timelineWorkWeek: 'Timeline Arbeitswoche',
            timelineMonth: 'Timeline Monat'

        },
    }
});

// import { L10n , setCulture} from '@syncfusion/ej2-base';

// setCulture('de-CH');

// L10n.load({
//    'de-CH': {
//         'chart': {
//             ZoomIn: 'تكبير',
//             ZoomOut: 'تصغير',
//             Zoom: 'زوم',
//             Pan: 'مقلاة',
//             Reset: 'إعادة تعيين',
//             ResetZoom: ' زومإعادة تعيين'
//         },
//     }
// });


export enum MenuOrientation {
    // eslint-disable-next-line no-unused-vars
    STATIC, OVERLAY, SLIM, HORIZONTAL
}

// declare var jQuery: any;

@Component({
    selector: 'avi-vwbp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    appVersion = APPVERSION;
    layoutCompact = false;
    formFieldBackground = true;
    layoutMode: MenuOrientation = MenuOrientation.STATIC;
    darkMenu = true;
    inputStyle: string = 'filled';
    ripple: boolean = false;
    isRTL: boolean = false;
    profileMode = 'top';
    rotateMenuButton: boolean;
    topbarMenuActive: boolean;
    overlayMenuActive: boolean;
    staticMenuDesktopInactive: boolean;
    staticMenuMobileActive: boolean;
    rightPanelActive: boolean;
    rightPanelClick: boolean;
    layoutContainer: HTMLDivElement;
    layoutMenuScroller: HTMLDivElement;
    menuClick: boolean;
    topbarItemClick: boolean;
    activeTopbarItem: any;
    resetMenu: boolean;
    menuHoverActive: boolean;

    @ViewChild('layoutContainer', { static: false }) layourContainerViewChild: ElementRef;

    @ViewChild('scrollPanel', { static: false }) layoutMenuScrollerViewChild: ScrollPanel;

    rippleInitListener: any;

    rippleMouseDownListener: any;

    public testDialogVisible = false;
    public displayHotkeyDialog = false;
    public globalLoaderVisible = false;

    constructor(public renderer2: Renderer2,
        public zone: NgZone,
        private primengConfig: PrimeNGConfig,
        public authService: AviAuthService,
        private oauthService: OAuthService,
        private oauthConfigService: OAuthConfigService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        vcr: ViewContainerRef,
        public commonService: AviCommonService,
        public docService: AviDocumentService,
        public jobService: AviJobService,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private elementRef: ElementRef,
        private navigationService: VwbPNavigationService,
        public vwbpCommonService: VwbPCommonService,
        private location: Location,
        public translateService: TranslateService,
        public postfachService: VwbPInternPostfachService,
        private permissionService: NgxPermissionsService) {

        this.commonService.AppName = environment.appname;
        this.commonService.AppVersion = APPVERSION;
        this.commonService.InputStyle = this.inputStyle;
        
        this.commonService.notificateInit(vcr);

        this.docService.ApiPrefix = '/v1/intern/vwbp/document';
        this.jobService.ApiPrefix = '/v1/intern/vwbp'

        // console.warn("ESR", this.commonService.generateEsrReferenz("2"));
            this.primengConfig.setTranslation(this.commonService.locale.de);
        translateService.addLangs(['de', 'en']);
        translateService.setDefaultLang('de');
        const browserLang = translateService.getBrowserLang();
        translateService.use(browserLang.match(/de|en/) ? browserLang : 'de');

        this.commonService.CorrectInputTextareaAutoresize();
        this.commonService.CorrectDynamicDialogFocus();
        
        NgxPermissionsService.prototype.hasPermission = function(permission: string | string[]): Promise<boolean> { 
            return Promise.resolve(true);
        }

        this.authService.registerInlineEditingRoleDelegate(() => this.permissionService.hasPermission([ VwbPRoleConst.RESOURCEN_SCHREIBEN ]) && this.vwbpCommonService.currentUserIsAdminUser());

        this.authService.registerDefaultTimedLogout(true);
        this.authService.registerDefaultInactivityLogout('vwbp', 15, true);

        this.commonService.GloballoaderChanged.subscribe(d => {
            this.globalLoaderVisible = d;
            this.cdr.detectChanges();
        });

        this.commonService.registerDefaultErrorNotificationInterceptor();

        router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.commonService.removePrivateHotkeys();
            }
        });

        this.authService.UserChanged.subscribe(user => {
            this.addHotkeys();
        });

        this.addHotkeys();

        this.authService.subscribeOAuthTokenReceived();
    }


    public OpenPostfach() {
        this.postfachService.OpenPostfach();
    }

    private addHotkeys() {
        this.commonService.removeGlobalHotkeys();
        this.commonService.removePrivateHotkeys();

        if (!this.authService.IsLoggedIn)
            return;

        this.commonService.addGlobalHotkey('alt+h', (_event, _key, _description) => this.displayHotkeyDialog = true, 'Hilfe & Hotkeys anzeigen', true);
        this.commonService.addGlobalHotkey('g d', () => this.router.navigate(['/']), 'goto::Dashboard');
        this.commonService.addGlobalHotkey('g p', () => this.router.navigate(['/vwbp/profile']), 'goto::Profil');

        if (this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_AJU_SCHREIBEN)
            || this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_AJU_LESEN)
            || this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_BEHOERDEN)
        ) {
            this.commonService.addGlobalHotkey('g k', () => this.router.navigate(['/intern/vwbp/index/kontrollwesen']), 'goto::Kontrollwesen');
            this.commonService.addGlobalHotkey('g u', () => this.router.navigate(['/intern/vwbp/index/uebergabe']), 'goto::Übergabe Management');
            this.commonService.addGlobalHotkey('g m', () => this.router.navigate(['/intern/vwbp/index/unstimmigkeit']), 'goto::Unstimmigkeiten');
            this.commonService.addGlobalHotkey('g b', () => this.router.navigate(['/intern/vwbp/behoerden']), 'goto::Behördenabfrage');
        }


        this.commonService.addGlobalHotkey('alt+q', () => this.Logout(), 'Logout durchführen', true);

        if (!this.commonService.IsProduction) {
            this.commonService.addGlobalHotkey('d o', () => {
                this.enableDebug(!this.debugEnabled());
                this.cdr.markForCheck();
            }, 'Debug an/aus');

            this.commonService.addGlobalHotkey('c b', () => {
                this.commonService.copyToClipboard(`Bearer ${this.oauthService.getAccessToken()}`);
                this.commonService.notificateSuccess('Bearer Token ist jetzt im Clipboard');
            }, 'dev: Copy Bearer to Clipboard');
        }

        /*
        this.commonService.addGlobalHotkey('alt+g', (event, key, description) => { this.commonService.displayGlobalSearch(); }, 'Globale Suche', true);

        this.commonService.addGlobalSearchData('Partner suchen', '/crm/partner');
        this.commonService.addGlobalSearchData('Leistungen suchen', '/faktura/leistung', 'Leistungen suchen');
        this.commonService.addGlobalSearchData('Anwesenheiten - Dashboard', '/arbeitszeit/anwesenheit-dashboard', 'Anwesenheiten - Dashboard');
        this.commonService.addGlobalSearchData('Leistungen - Dashboard', '/faktura/leistung-dashboard', 'Leistungen - Dashboard');
        this.commonService.addGlobalSearchData('Absenzübersicht Woche/Monat', '/arbeitszeit/absenzuebersicht/monat', 'Absenzübersicht Woche/Monat');
        this.commonService.addGlobalSearchData('Absenzübersicht Jahr', '/arbeitszeit/absenzuebersicht/jahr', 'Absenzübersicht Jahr');
        this.commonService.addGlobalSearchData('Transaktionsprüfung', '/mandat/trx/trxcheckdesk', 'TRX Check Desk');
        this.commonService.addGlobalSearchData('Mandat suchen', '/mandat/mandat', 'Mandate suchen');
        this.commonService.addGlobalSearchData('Transaktionen suchen', '/mandat/trx', 'Transaktionen suchen');
        this.commonService.addGlobalSearchData('Dashboard', '/', 'Gehe zum Dashboard', 100);
        this.commonService.addGlobalSearchData('Super Def', '/crm/partner');
        this.commonService.addGlobalSearchData('Mach was ohne Router', () => { alert('Was denn?'); });
        this.commonService.addGlobalSearchData('Noch bessere Def', '/crm/partner');
        this.commonService.addGlobalSearchData('Andere Definition', '/crm/partner');
        this.commonService.addGlobalSearchData('Infodesk', '/reporting/infodesk/exec/b80ddbd3-d6cf-473c-8ca9-a8117bb1c141'); // 556c4501-c25d-4b26-bb51-795d13385e82");
        */
    }

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        // console.log('Back or fwd button pressed',event);
    }

    ngOnInit() {
        this.zone.runOutsideAngular(() => this.bindRipple());

        // Prevent drag & drop
        window.addEventListener('dragover', e => {
            e.preventDefault();
            e.dataTransfer.effectAllowed = 'none';
            e.dataTransfer.dropEffect = 'none';
        }, false);
        window.addEventListener('drop', e => {
            e.preventDefault();
            e.dataTransfer.effectAllowed = 'none';
            e.dataTransfer.dropEffect = 'none';
        }, false);
    }

    public NavigateBack() {
        this.location.back();
    }

    public canNavigateBack(): boolean {
        return this.navigationService.canNavigateBack();
    }

    public NavigateHome() {
        this.router.navigate(['/']);
    }

    bindRipple() {
        // this.rippleInitListener = this.init.bind(this);
        // document.addEventListener('DOMContentLoaded', this.rippleInitListener);
    }

    init() {
        // this.rippleMouseDownListener = this.rippleMouseDown.bind(this);
        // document.addEventListener('mousedown', this.rippleMouseDownListener, false);
    }

    rippleMouseDown(e) {
        for (let target = e.target; target && target !== this; target = target['parentNode']) {
            if (!this.isVisible(target)) {
                continue;
            }

            // Element.matches() -> https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
            if (this.selectorMatches(target, '.ripplelink, .p-button')) {
                const element = target;
                this.rippleEffect(element, e);
                break;
            }
        }
    }

    selectorMatches(el, selector) {
        const p = Element.prototype;
        const f = p['matches'] || p['webkitMatchesSelector'] || p['mozMatchesSelector'] || p['msMatchesSelector'] || function(s) {
            return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
        };
        return f.call(el, selector);
    }

    isVisible(el) {
        return !!(el.offsetWidth || el.offsetHeight);
    }

    rippleEffect(element, e) {
        if (element.querySelector('.ink') === null) {
            const inkEl = document.createElement('span');
            this.addClass(inkEl, 'ink');

            if (this.hasClass(element, 'ripplelink') && element.querySelector('span')) {
                element.querySelector('span').insertAdjacentHTML('afterend', '<span class=\'ink\'></span>');
            } else {
                element.appendChild(inkEl);
            }
        }

        const ink = element.querySelector('.ink');
        this.removeClass(ink, 'ripple-animate');

        if (!ink.offsetHeight && !ink.offsetWidth) {
            const d = Math.max(element.offsetWidth, element.offsetHeight);
            ink.style.height = d + 'px';
            ink.style.width = d + 'px';
        }

        const x = e.pageX - this.getOffset(element).left - (ink.offsetWidth / 2);
        const y = e.pageY - this.getOffset(element).top - (ink.offsetHeight / 2);

        ink.style.top = y + 'px';
        ink.style.left = x + 'px';
        ink.style.pointerEvents = 'none';
        this.addClass(ink, 'ripple-animate');
    }
    hasClass(element, className) {
        if (element.classList) {
            return element.classList.contains(className);
        } else {
            return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
        }
    }

    addClass(element, className) {
        if (element.classList) {
            element.classList.add(className);
        } else {
            element.className += ' ' + className;
        }
    }

    removeClass(element, className) {
        if (element.classList) {
            element.classList.remove(className);
        } else {
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    getOffset(el) {
        const rect = el.getBoundingClientRect();

        return {
            top: rect.top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
            left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0),
        };
    }

    unbindRipple() {
        if (this.rippleInitListener) {
            document.removeEventListener('DOMContentLoaded', this.rippleInitListener);
        }
        if (this.rippleMouseDownListener) {
            document.removeEventListener('mousedown', this.rippleMouseDownListener);
        }
    }

    ngAfterViewInit() {
        if (this.layourContainerViewChild) {
            this.layoutContainer = <HTMLDivElement> this.layourContainerViewChild.nativeElement;
            setTimeout(() => this.layoutMenuScrollerViewChild.moveBar());
        }

        if (!this.commonService.IsDesktopView())
            this.layoutCompact = false;
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.resetMenu = true;
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;

        if (this.layoutMode === MenuOrientation.OVERLAY) {
            this.overlayMenuActive = !this.overlayMenuActive;
        } else {
            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }
        }

        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
        this.resetMenu = false;
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;
        event.preventDefault();
    }

    onRightPanelClick() {
        this.rightPanelClick = true;
    }

    hideOverlayMenu() {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    isHorizontal() {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    }

    isSlim() {
        return this.layoutMode === MenuOrientation.SLIM;
    }

    changeToStaticMenu() {
        this.layoutMode = MenuOrientation.STATIC;
        this.commonService.localStorageSet('avix::settings.menu.layoutmode', this.layoutMode);
    }

    changeToOverlayMenu() {
        this.layoutMode = MenuOrientation.OVERLAY;
        this.commonService.localStorageSet('avix::settings.menu.layoutmode', this.layoutMode);
    }

    changeToHorizontalMenu() {
        this.layoutMode = MenuOrientation.HORIZONTAL;
        this.commonService.localStorageSet('avix::settings.menu.layoutmode', this.layoutMode);
    }

    changeToSlimMenu() {
        this.layoutMode = MenuOrientation.SLIM;
        this.commonService.localStorageSet('avix::settings.menu.layoutmode', this.layoutMode);
    }

    ngOnDestroy() {
        this.unbindRipple();
    }


    public get IsLoggedIn() {
        return this.oauthService.hasValidAccessToken();
    }

    public Logout(): void {
        this.oauthService.logOut();
        // this.router.navigate(['/vwbp/login']);
    }
    debugEnabled(): boolean {
        return localStorage.getItem('avix::debug::enabled') === 'YES';
    }
    enableDebug(enabled: boolean) {
        localStorage.setItem('avix::debug::enabled', enabled ? 'YES' : 'NO');
        this.cdr.markForCheck();
    }

    public get loginDialogVisible(): boolean {
        return false; // !this.authService.IsLoggedIn;
    }

    public onDialogClose($event) {
        console.log('dialog zu', $event);
    }

    private _setTheme(theme: string) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('theme-css');
        const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.getElementById('layout-css');

        themeLink.href = 'assets/theme/theme-' + theme + '.css';
        layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';
    }

    public changeTheme(theme: string) {
        this._setTheme(theme);
        this.commonService.localStorageSet('avix::settings.theme', theme);
    }


    public goToKeycloakProfile() {
        location.href = `${this.oauthConfigService.GetLastUsedAuthConfig().issuer}/account`;
    }

    public goToHome() {
        if (this.vwbpCommonService.currentUserIsAjuUser())
            this.router.navigate(['/intern/vwbp/index']);
        else if (this.vwbpCommonService.currentUserIsBehoerdenUser())
            this.router.navigate(['/intern/vwbp/behoerden']);
        else
            this.router.navigate(['/vwbp/index']);
    }
}
