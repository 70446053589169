import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviAuthService, AviBaseFormComponent, ObjectUtils, AviFormFieldService, typed_nameof, AviHistoryService } from '@avi-x/avi-core';
import { AdresseHist } from '@avi-x/avi-dto/partner/adresse-hist.model';

import * as moment from 'moment-timezone';
import { ZustandInfoDto } from '@avi-x/avi-dto/shared/zustandinfo.model';

const nameof = (nameFunction: ((obj: AdresseHist) => any)) => typed_nameof<AdresseHist>(nameFunction);

@Component({
    selector: 'avi-crm-adresse-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [label-alignment]="ReadOnly ? 'left' : 'top'"
        [label-width]="'160px'"
        [(Model)]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons">

        <div formFieldTemplate1>
            <avi-core-history-control [history-states]="HistoryStates" (stichtagChange)="OnStichtagChange($event)">
            </avi-core-history-control>
        </div>

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAdresseFormComponent implements OnInit, OnDestroy {

    private _className = 'Adresse';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('hidden-fields')
    public HiddenFields: string[] = null;

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = '/crm/adresse';

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = '/crm/adresse';

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: AdresseHist = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string { return this._ModelId; }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    private _PartnerId: string = null;
    public get PartnerId(): string { return this._PartnerId; }

    @Input('partner-id')
    public set PartnerId(value: string) {
        this.InitForm(null, value);
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    private stichtag: Date = new Date();
    public HistoryStates: ZustandInfoDto[];

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private historyService: AviHistoryService,
        private apiService: AviApiService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string, partner: string = null): Promise<boolean> {

        if (partner == null) {
            if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
                if (value == null) {
                    this.Model = null;
                    this._ModelId = value;
                    this.isEditMode = false;
                }
                return;
            }
        }

        this._ModelId = value;
        this._PartnerId = partner;

        this.isEditMode = false;

        if ((this._ModelId && this._ModelId !== '0') || (this._PartnerId && this._PartnerId !== '0')) {
            await this.loadModel(this._ModelId, this._PartnerId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));

        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    async onAttrChange(data: AviChangedAttr) {
    }

    public async initNewModel(data?: any) {
        /*
                this.Model = await this.apiService.getModel(AdresseHist, 'adresse/Create');
                this.isEditMode = false;

                if (data) {
                    for (const key in data) {
                        ObjectUtils.setByPath(this.Model, key, data[key]);
                        this.onAttrChange({ field: key, value: data[key] });
                    }
                }*/
    }

    ngOnInit() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.ModelId = params['id'];
            }
        });

        this.initFields();
    }


    deleteModel(model: any) {
        /*        this.apiService.delete(`/adresse/${model.id}`).then(r => {

                    if (this.RedirectAfterDelete)
                        this.router.navigate([this.RedirectAfterDelete]);
                });*/
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: AdresseHist) {

        /*
                this.Form.clearFormMessages();

                let delegate: Promise<any> = null;
                if (this.isEditMode) {
                    delegate = this.apiService.put(`/adresse/${model.Id}`, model);
                } else {
                    delegate = this.apiService.post('/adresse', model);
                }
                delegate.then(r => {
                    this.commonService.notificateSuccess('Gespeichert');
                    this.onSavedSuccessfulDelegate.emit(model);

                    if (this.RedirectAfterSave)
                        this.router.navigate([this.RedirectAfterSave]);
                }).catch(err => {
                    if (err instanceof AviApiErrorObject) {
                        this.Form.addFormMessage(err.ErrorMessage, err.Type);
                    } else {
                        this.Form.addFormMessage(JSON.stringify(err));
                    }
                });*/
    }

    async loadModel(id: string, partnerid: string) {

        this.commonService.showGlobalLoader();

        this.historyService.clearHistoryHighlighting(this.Fields);
        this.stichtag = new Date();
        const strStichtag = moment(this.stichtag).format('YYYY-MM-DD');

        if (id)
            this.Model = await this.apiService.getModel(AdresseHist, `adresse/hist/${strStichtag}/${id}`);
        else
            this.Model = await this.apiService.getModel(AdresseHist, `adresse/hist/partner/${strStichtag}/${partnerid}`);

        await this.updateDropdownSources();

        this.commonService.hideGlobalLoader();
        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;
        this._PartnerId = this.Model.vonPartner_ID;

        this.HistoryStates = await this.apiService.getModelList(ZustandInfoDto, `adresse/hist/states/${this._ModelId}`);

        return this.Model;
    }

    async updateDropdownSources() {
        if (this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'Gültig ab', false).setNullDateLow().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'Gültig bis', false).setNullDateHigh().setMDSizeHalf());

        if (this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateTemplate(1, 'Zustand ab/bis').setMDSizeFull());
        else {
            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.ZustandAb), 'Zustand ab', false).setNullDateLow().setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.ZustandBis), 'Zustand bis', false).setNullDateHigh().setMDSizeHalf());
        }

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Strasse), 'Strasse', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.HausNr), 'HausNr', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Klassifizierung), 'Klassifizierung', 'caf_adressklassifizierung', true, false, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Adresszusatz1), 'Adresszusatz1', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Adresszusatz2), 'Adresszusatz2', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Adresszusatz3), 'Adresszusatz3', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Postfach), 'Postfach', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.PLZ), 'PLZ', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Ort), 'Ort', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Land_ID), 'Land', `model/representations/CAFLand${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'Land wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Provinz_ID), 'Provinz', `model/representations/CAFProvinz${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'Provinz wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.SteuerDomizil), 'Steuerdomizil', 'janein', true, false, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Landwort), 'Landwort', false).setMDSizeHalf());

        if (this.HiddenFields && this.HiddenFields.length > 0)
            this.Fields = this.Fields.filter(w => !this.HiddenFields.includes(w.Name));
    }

    async OnStichtagChange(stichtag: Date) {
        this.stichtag = stichtag;
        const strStichtag = moment(this.stichtag).format('YYYY-MM-DD');

        const newModel = await this.apiService.getModel(AdresseHist, `adresse/hist/${strStichtag}/${this.Model.Id}`);
        this.historyService.handleHistoryHighlighting(this.Fields, this.Model, newModel);
        this.Model = newModel;

        this.cdr.markForCheck();
    }
}
