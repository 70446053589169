import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviBaseSearcherComponent, AviCommonService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { BusinesscaseDataDefSearchResponseDto } from '@avi-x/avi-dto/businesscase/businesscasedatadefsearchresponsedto.model';
import { AviBusinesscaseDataDefFormComponent } from './businesscasedatadef-form.component';

const nameof = (nameFunction: ((obj: BusinesscaseDataDefSearchResponseDto) => any)) => typed_nameof<BusinesscaseDataDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-businesscasedatadef-searcher',
    templateUrl: './businesscasedatadef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseDataDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF';
    SettingsKey = 'settings.searcher.businesscasedatadef';
    BaseUrl = 'businesscase/businesscasedatadef';
    FormType = AviBusinesscaseDataDefFormComponent;

    CanEdit: boolean = false;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFBusinesscaseDef schreiben').then(w => this.CanEdit = w);
    }

    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            // this.sessionController.openSession('businesscasedatadef-index', this.SelectedRows.Id);
            this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.BEZEICHNUNG', true);
        searcher.addTextColumn(nameof(c => c.Description), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DESCRIPTION', true);
        
        searcher.addListTypeColumn(nameof(c => c.DataType), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DATATYPE', 'caf_businesscasedata_typ', true, true);
        searcher.addListTypeColumn(nameof(c => c.Mandatory), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.MANDATORY', 'janein', true, true);
                
        searcher.addTextColumn(nameof(c => c.StatusDefBez), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.STATUSDEF', true);
        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        if (this.ContextId) {
            const cfg =  { ...searchConfig };
            cfg.Query = searchValue;
            cfg.ContextId = this.ContextId;
            const res = await this.apiService.postPagedResult(BusinesscaseDataDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
            return res;
        }

        return null;
    }
}
