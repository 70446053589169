import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { ListType } from '../shared';

export class BusinesscaseDataDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;

    @Expose()
	public Bezeichnung: string;

    @Expose()
	public Description: string;

	@Expose()
	@Type(() => ListType)
	public DataType: ListType;

	@Expose()
	@Type(() => ListType)
	public Mandatory: ListType;

	@Expose()
	@Type(() => ListType)
	public EditDocument: ListType;

	@Expose()
	@Type(() => ListType)
	public DocFormat: ListType;

	@Expose()
	@Type(() => ListType)
	public DocSign: ListType;

	@Expose()
	@Type(() => ListType)
	public MustBeFinished: ListType;

    @Expose()
	public CustomFormId: string;

	@Expose()
	public Internal: boolean;

	@Expose()
	public StatusDef_ID: string;

	@Expose()
	public StatusDefBez: string;
}
