import { Expose, Type } from 'class-transformer';

import { AviDocArt } from '../avidoc_def/avidocart.model';
import { AviDocKategorie } from '../avidoc_def/avidockategorie.model';
import { AviDocArchivDef } from '../avidoc_def/avidocarchivdef.model';
import { AviDocTemplate } from '../avidoc_def/avidoctemplate.model';
import { AviDocumentKeywordContainer } from './avidocumentkeywordcontainer.model';
import { Sprache } from '../system/sprache.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared/genericref.model';

export class AviDocumentContainer extends BaseModel {
    @Expose()
    public get Dateipfad(): string { return this.getProperty('Dateipfad'); }
    public set Dateipfad(value: string) { this.setProperty('Dateipfad', value); }

    @Expose()
    public get Dateiversion(): number { return this.getProperty('Dateiversion'); }
    public set Dateiversion(value: number) { this.setProperty('Dateiversion', value); }

    @Expose()
    public get Titel(): string { return this.getProperty('Titel'); }
    public set Titel(value: string) { this.setProperty('Titel', value); }

    @Expose()
    public get Abstract(): string { return this.getProperty('Abstract'); }
    public set Abstract(value: string) { this.setProperty('Abstract', value); }

    @Expose()
    @Type(() => Date)
    public get Dateidatum(): Date { return this.getProperty('Dateidatum'); }
    public set Dateidatum(value: Date) { this.setProperty('Dateidatum', value); }

    @Expose()
    public get Dateiname(): string { return this.getProperty('Dateiname'); }
    public set Dateiname(value: string) { this.setProperty('Dateiname', value); }

    @Expose()
    public get Dateiendung(): string { return this.getProperty('Dateiendung'); }
    public set Dateiendung(value: string) { this.setProperty('Dateiendung', value); }

    @Expose()
    @Type(() => Date)
    public get Ablaufdatum(): Date { return this.getProperty('Ablaufdatum'); }
    public set Ablaufdatum(value: Date) { this.setProperty('Ablaufdatum', value); }

    @Expose()
    public get DocID(): string { return this.getProperty('DocID'); }
    public set DocID(value: string) { this.setProperty('DocID', value); }

    @Expose()
    public get BarcodeDocId(): string { return this.getProperty('BarcodeDocId'); }
    public set BarcodeDocId(value: string) { this.setProperty('BarcodeDocId', value); }

    @Expose()
    @Type(() => Date)
    public get Loeschdatum(): Date { return this.getProperty('Loeschdatum'); }
    public set Loeschdatum(value: Date) { this.setProperty('Loeschdatum', value); }

    @Expose()
    @Type(() => Date)
    public get Lockdatum(): Date { return this.getProperty('Lockdatum'); }
    public set Lockdatum(value: Date) { this.setProperty('Lockdatum', value); }

    @Expose()
    @Type(() => Date)
    public get ArchviertAm(): Date { return this.getProperty('ArchviertAm'); }
    public set ArchviertAm(value: Date) { this.setProperty('ArchviertAm', value); }

    @Expose()
    public get ArbeitsListe(): boolean { return this.getProperty('ArbeitsListe'); }
    public set ArbeitsListe(value: boolean) { this.setProperty('ArbeitsListe', value); }

    @Expose()
    public get AlteReferenz(): string { return this.getProperty('AlteReferenz'); }
    public set AlteReferenz(value: string) { this.setProperty('AlteReferenz', value); }

    @Expose()
    public get DocArt_ID(): string { return this.getModelId('DocArt_ID'); }
    public set DocArt_ID(value: string) { this.setModelId('DocArt_ID', value); }

    @Expose()
    @Type(() => AviDocArt)
    public get DocArt(): AviDocArt { return this.getModel('DocArt'); }
    public set DocArt(value: AviDocArt) { this.setModel('DocArt', value); }

    @Expose()
    public get ErstellungDocKategorie_ID(): string { return this.getModelId('ErstellungDocKategorie_ID'); }
    public set ErstellungDocKategorie_ID(value: string) { this.setModelId('ErstellungDocKategorie_ID', value); }

    @Expose()
    @Type(() => AviDocKategorie)
    public get ErstellungDocKategorie(): AviDocKategorie { return this.getModel('ErstellungDocKategorie'); }
    public set ErstellungDocKategorie(value: AviDocKategorie) { this.setModel('ErstellungDocKategorie', value); }

    @Expose()
    public get DocKategorie_ID(): string { return this.getModelId('DocKategorie_ID'); }
    public set DocKategorie_ID(value: string) { this.setModelId('DocKategorie_ID', value); }

    @Expose()
    @Type(() => AviDocKategorie)
    public get DocKategorie(): AviDocKategorie { return this.getModel('DocKategorie'); }
    public set DocKategorie(value: AviDocKategorie) { this.setModel('DocKategorie', value); }

    @Expose()
    public get Archiv_ID(): string { return this.getModelId('Archiv_ID'); }
    public set Archiv_ID(value: string) { this.setModelId('Archiv_ID', value); }

    @Expose()
    @Type(() => AviDocArchivDef)
    public get Archiv(): AviDocArchivDef { return this.getModel('Archiv'); }
    public set Archiv(value: AviDocArchivDef) { this.setModel('Archiv', value); }

    @Expose()
    public get DocTemplate_ID(): string { return this.getModelId('DocTemplate_ID'); }
    public set DocTemplate_ID(value: string) { this.setModelId('DocTemplate_ID', value); }

    @Expose()
    @Type(() => AviDocTemplate)
    public get DocTemplate(): AviDocTemplate { return this.getModel('DocTemplate'); }
    public set DocTemplate(value: AviDocTemplate) { this.setModel('DocTemplate', value); }

    @Expose()
    public get Sprache_ID(): string { return this.getModelId('Sprache_ID'); }
    public set Sprache_ID(value: string) { this.setModelId('Sprache_ID', value); }

    @Expose()
    @Type(() => Sprache)
    public get Sprache(): Sprache { return this.getModel('Sprache'); }
    public set Sprache(value: Sprache) { this.setModel('Sprache', value); }

    @Expose()
    @Type(() => ListType)
    public get Status(): ListType { return this.getProperty('Status'); }
    public set Status(value: ListType) { this.setProperty('Status', value); }

    @Expose()
    @Type(() => ListType)
    public get Visibility(): ListType { return this.getProperty('Visibility'); }
    public set Visibility(value: ListType) { this.setProperty('Visibility', value); }

    @Expose()
    @Type(() => ListType)
    public get Loeschstatus(): ListType { return this.getProperty('Loeschstatus'); }
    public set Loeschstatus(value: ListType) { this.setProperty('Loeschstatus', value); }

    @Expose()
    @Type(() => ListType)
    public get Lockstatus(): ListType { return this.getProperty('Lockstatus'); }
    public set Lockstatus(value: ListType) { this.setProperty('Lockstatus', value); }

    @Expose()
    @Type(() => ListType)
    public get Original(): ListType { return this.getProperty('Original'); }
    public set Original(value: ListType) { this.setProperty('Original', value); }

    @Expose()
    @Type(() => GenericRef)
    public get DocumentBaseModel(): GenericRef { return this.getProperty('DocumentBaseModel'); }
    public set DocumentBaseModel(value: GenericRef) { this.setProperty('DocumentBaseModel', value); }

    @Expose()
    @Type(() => GenericRef)
    public get SecondCtx(): GenericRef { return this.getProperty('SecondCtx'); }
    public set SecondCtx(value: GenericRef) { this.setProperty('SecondCtx', value); }

    @Expose()
    @Type(() => ListType)
    public get DigitalSignieren(): ListType { return this.getProperty('DigitalSignieren'); }
    public set DigitalSignieren(value: ListType) { this.setProperty('DigitalSignieren', value); }

    //
    @Expose()
    public get DocArtGruppe(): string { return this.getModelId('DocArtGruppe'); }
    public set DocArtGruppe(value: string) { this.setModelId('DocArtGruppe', value); }

    @Expose()
    public get DocArtUntergruppe(): string { return this.getModelId('DocArtUntergruppe'); }
    public set DocArtUntergruppe(value: string) { this.setModelId('DocArtUntergruppe', value); }

    @Expose()
    @Type(() => AviDocumentKeywordContainer)
    public alleKeywords: AviDocumentKeywordContainer[] = [];

}
