<mat-expansion-panel
    [ngClass]="getClass()"
    [ngStyle]="getStyle()"
    [expanded]="Expanded || Title == null || Collapsible == false"
    [disabled]="Collapsible == false"
    (afterExpand)="Expanded=true"
    (closed)="Expanded=false"
>
    <mat-expansion-panel-header
        *ngIf="Title != null && Card"
        class="card-header avi-card-header"
        collapsedHeight="*"
        expandedHeight="*"
    >
        <mat-panel-title class="valign-center">
            <button
                *ngIf="ShowNavigateBack"
                pButton
                type="button"
                icon="pi pi-chevron-left"
                aria-label="Go back"
                class="p-button-text back-button p-button-rounded"
                aviThrottledClick
                (throttledClick)="NavigateBack()"
            ></button>

            <i *ngIf="Icon != null" class="material-icons pr-2 awf-page-icon">{{Icon}}</i>
            {{Title | translate}}

        </mat-panel-title>

        <!-- <mat-panel-description>Description</mat-panel-description> -->
        <ng-container *ngTemplateOutlet="buttons_template"></ng-container>

        <button
            pButton
            *ngIf="ShowRefreshButton"
            icon="pi pi-refresh"
            class="p-button-secondary p-button-flat page-fullscreen-icon toolbar-button-icon-only"
            pTooltip="Aktualisieren"
            tooltipPosition="left"
            (click)="onRefresh($event)"
            >
        </button>

        <button
           pButton
           *ngIf="ShowFullscreenIcon"
           [icon]="PageFullscreen ? 'pi pi-window-minimize' : 'pi pi-window-maximize'"
           [pTooltip]="PageFullscreen ? 'Normalmodus': 'Vollbildmodus'"
           tooltipPosition="left"
           class="p-button-secondary p-button-flat page-fullscreen-icon toolbar-button-icon-only"
           (click)="toggleFullscreen($event)"
           >
        </button>
        <div *ngIf="Collapsible && ShowFullscreenIcon" class="mr-2"></div>

    </mat-expansion-panel-header>

    <ng-container *ngTemplateOutlet="contents"></ng-container>
    <div [ngClass]="{ 'card-contents': CardContentClass, 'nopad-force': !ContentsPadding, 'full-height': FillHeight}">
        <ng-content></ng-content>
    </div>
</mat-expansion-panel>
