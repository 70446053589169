import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { AviApiService, AviCommonService, AviCommunicationService, AviPartnerListDetailConst } from '@avi-x/avi-core';

import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Component({
    selector: 'avi-crm-jurperson-summary',
    templateUrl: './jurperson-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AviJurPersonSummaryComponent implements OnInit {

    Model: any = null;
    WCKInfo: any = null;
    ActivityInfo: any = null;

    private _PartnerId: string = null;
    public get PartnerId(): string { return this._PartnerId; }

    @Input('partner-id')
    public set PartnerId(value: string) {
        this.loadModel(value);
    }
    
    sub: any;

    PageTitle: string = null;

    public moment: any = moment;

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private ref: ChangeDetectorRef,
        private communicationService: AviCommunicationService
    ) {
    }

    ngOnInit() {
        // this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
        //     this.id = res;
        //     this.loadModel(this.id);
        // });
    }

    async loadModel(id: string) {
        this.PartnerId = id;
        if (id) {
            this.Model = await this.apiService.get(`jurperson/summary/${id}`);
            this.ref.markForCheck();

            this.ActivityInfo = await this.apiService.get(`activity/GetOpenActivities/${id}`);
            this.WCKInfo = await this.apiService.get(`worldcheck/summary/${id}`);
            this.ref.markForCheck();
        }
    }

    getSelbstauskuenfte() {
        return this.Model.Selbstauskuenfte.length > 0 ? this.Model.Selbstauskuenfte.map(w => w.Laender).join(', ') : 'Nicht vorhanden';
    }

    getSteuerDomizile() {
        return this.Model.SteuerDomizile.length > 0 ? this.Model.SteuerDomizile.map(w => w.Land).join(', ') : 'Nicht vorhanden';
    }

    isEmail(komm): boolean {
        return komm.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_EMAIL;
    }

    isTelefonnummer(komm): boolean {
        return komm.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_TELEFON ||
            komm.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_FAX ||
            komm.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_MOBILE ||
            komm.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_VOIP;
    }

    isHomepage(komm): boolean {
        return komm.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_HOMEPAGE;
    }

    public click2Call(data: string) {
        this.communicationService.StartCall(data);
    }

    public click2Email(data: string) {
        this.communicationService.StartEmail(data);
    }

    public openHomepage(data: string) {
        this.communicationService.OpenHomepage(data);
    }
}
