import { Component, Input, ViewChild } from '@angular/core';

import { AviCommonService, AviBaseSearcherComponent, AviApiService, AviSearcherColumnType, ISessionData } from '@avi-x/avi-core';

@Component({
    selector: 'avi-crm-user-contactinfo-searcher',
    template: `<avi-core-base-searcher #searcher
        settingskey="settings.searcher.contactdata"
        [use-router-params]="false"
        [search-delegate] = "getContactData"
        [search-fields]   = "['name']"
        [rows]            = "20"
        [auto-search]     = "true"
        [show-autofilter] = "false"
        [collapsible]="false"
        (onInit)          = "onInit($event)"
        [lazy-loading]="false"
        [auto-search-on-type]="true"
    
        searcher-title    = "Personal suchen"
    >

    </avi-core-base-searcher>`
})
export class AviUserContactInfoSearcherComponent {
    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    @Input('session-data')
    SessionData: ISessionData;

    constructor(public apiService: AviApiService, public commonService: AviCommonService) {
    }

    public onInit(data) {
        this.searcher.beginInit();

        this.searcher.addTextColumn('Name', 'Name', false);
        this.searcher.addTextColumn('Vorname', 'Vorname', false);
        this.searcher.addTextColumn('TelIntern', 'Intern', false).Type = AviSearcherColumnType.TELEFONNR;
        this.searcher.addTextColumn('Email', 'Email', false).Type = AviSearcherColumnType.EMAIL;

        this.searcher.endInit();
    }

    public getContactData: any = async (searchvalue: string) => {
        if (searchvalue)
            return await this.apiService.get(`personal/GetContactData?search=${searchvalue}`);
        else
            return await this.apiService.get(`personal/GetContactData`);
    }
}
