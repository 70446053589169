import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { VwbPVersion } from '@avi-x/vwbp-public';
import { Expose, Type } from 'class-transformer';


export class VwbPMahnung extends BaseModel {
    @Expose()
    @Type(() => Date)
    public get ErledigtAm(): Date { return this.getProperty('ErledigtAm'); }
    public set ErledigtAm(value: Date) { this.setProperty('ErledigtAm', value); }

    @Expose()
    public get ErledigtDurch(): string { return this.getProperty('ErledigtDurch'); }
    public set ErledigtDurch(value: string) { this.setProperty('ErledigtDurch', value); }

    @Expose()
    @Type(() => Date)
    public get Mahnfrist(): Date { return this.getProperty('Mahnfrist'); }
    public set Mahnfrist(value: Date) { this.setProperty('Mahnfrist', value); }

    @Expose()
    public get vonVersion_ID(): string { return this.getModelId('vonVersion_ID'); }
    public set vonVersion_ID(value: string) { this.setModelId('vonVersion_ID', value); }

    @Expose()
    @Type(() => VwbPVersion)
    public get vonVersion(): VwbPVersion { return this.getModel('vonVersion'); }
    public set vonVersion(value: VwbPVersion) { this.setModel('vonVersion', value); }

    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }

    @Expose()
    @Type(() => ListType)
    public get Status(): ListType { return this.getProperty('Status'); }
    public set Status(value: ListType) { this.setProperty('Status', value); }

}
