import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AviCommonService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternRechtstraegerVersionenSearcherComponent } from '../../../rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-versionen-searcher.component';
import { VwbPInternUebergabeService } from '../../service/uebergabe.service';

@Component({
    selector: 'vwbp-intern-uebergabe-ausgenommene',
    template: `
         <vwbp-intern-rechtstraeger-versionen-searcher #searcher
             settingskey="settings.searcher.ueb.rt_inaktiv"
             [last-version]="true"
             [use-router-params]="true"
             [get-filters]="GetFilters"
             [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'AbgegebenAm', 'btnOpen']">
         </vwbp-intern-rechtstraeger-versionen-searcher>



<div *ngIf="vwbpCommonService.currentUserIsAjuUserSchreiben()" fxLayout="row">
    <div class="pl-3 pr-3"
        fxLayout="column"
        fxLayoutAlign="start left"
        fxFlex="30"
        fxLayoutGap="1em">

        <span class="p-float-label">
            <textarea
                [rows]="8" id="comment" class="w-100p"
                pInputTextarea
                [(ngModel)]="Bemerkung"
                [autoResize]="true"
                name="inform"
            ></textarea>
            <label for="comment">{{ 'VWBP.COMMON.COMMENT' | translate }}*</label>
        </span>

        <button pButton
            type="button"
            label="{{'VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_REAKTIVIEREN_BUTTON' | translate:ButtonParams}}"
            [disabled]="!CanStartRechtstraegerReaktivieren()"
            icon="pi pi-exclamation-triangle"
            (click)="StartRechtstraegerReaktivieren()"
            class="p-button-primary mb-8p"></button>

    </div>
</div>

         `
})
export class VwbPInternUebergabeAusgenommeneComponent {
    @ViewChild('searcher', { static: true })
    private searcher: VwbPInternRechtstraegerVersionenSearcherComponent;

    Bemerkung: string;

    constructor(
        private commonService: AviCommonService,
        private cdr: ChangeDetectorRef,
        private uebergabeService: VwbPInternUebergabeService,
        public vwbpCommonService: VwbPCommonService
    ) { }

    GetFilters(): string[] {
        return [`Uebergabestatus eq ${VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_RT_INAKTIV}`];
    }

    GetSelectionCount() {
        return this.searcher.GetSelectedRechtstraeger().length;
    }

    IsNothingSelected(): boolean {
        return this.GetSelectionCount() === 0;
    }

    get ButtonParams() {
        return { cnt: this.GetSelectionCount() };
    }
    CanStartRechtstraegerReaktivieren() {
        return !this.IsNothingSelected() && this.Bemerkung;
    }

    async StartRechtstraegerReaktivieren() {
        const selectedRT = this.searcher.GetSelectedRechtstraeger();
        this.commonService.confirm({
            header: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_REAKTIVIEREN'),
            message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_REAKTIVIEREN_Q', { cnt: selectedRT.length }), acceptLabel: 'Ja', acceptButtonStyleClass: 'p-button-primary', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: () => {
                this.uebergabeService.StartRechtstraegerReaktivieren(selectedRT.map(w => w.RechtstraegerId), this.Bemerkung).then(r => {
                    this.Bemerkung = '';
                    this.searcher.forceRefresh();
                    this.cdr.markForCheck();
                    this.commonService.notificateSuccess(this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_REAKTIVIERT'));
                }).catch(err => this.commonService.notificateError(err));
            }
        });
    }
}
