import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class AuswahlDef extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get LabelKeinAuswahl1(): string { return this.getProperty('LabelKeinAuswahl1'); }
    public set LabelKeinAuswahl1(value: string) { this.setProperty('LabelKeinAuswahl1', value); }
    
    @Expose()
    public get LabelKeinAuswahl2(): string { return this.getProperty('LabelKeinAuswahl2'); }
    public set LabelKeinAuswahl2(value: string) { this.setProperty('LabelKeinAuswahl2', value); }
    
    @Expose()
    public get LabelKeinAuswahl3(): string { return this.getProperty('LabelKeinAuswahl3'); }
    public set LabelKeinAuswahl3(value: string) { this.setProperty('LabelKeinAuswahl3', value); }
    
    @Expose()
    public get LabelKeinAuswahl4(): string { return this.getProperty('LabelKeinAuswahl4'); }
    public set LabelKeinAuswahl4(value: string) { this.setProperty('LabelKeinAuswahl4', value); }
    
    @Expose()
    public get LabelKeinAuswahl5(): string { return this.getProperty('LabelKeinAuswahl5'); }
    public set LabelKeinAuswahl5(value: string) { this.setProperty('LabelKeinAuswahl5', value); }
    
    @Expose()
    public get LabelKeinAuswahl6(): string { return this.getProperty('LabelKeinAuswahl6'); }
    public set LabelKeinAuswahl6(value: string) { this.setProperty('LabelKeinAuswahl6', value); }
    
    @Expose()
    public get LabelKeinAuswahl7(): string { return this.getProperty('LabelKeinAuswahl7'); }
    public set LabelKeinAuswahl7(value: string) { this.setProperty('LabelKeinAuswahl7', value); }
    
    @Expose()
    public get LabelAuswahl1(): string { return this.getProperty('LabelAuswahl1'); }
    public set LabelAuswahl1(value: string) { this.setProperty('LabelAuswahl1', value); }
    
    @Expose()
    public get LabelAuswahl2(): string { return this.getProperty('LabelAuswahl2'); }
    public set LabelAuswahl2(value: string) { this.setProperty('LabelAuswahl2', value); }
    
    @Expose()
    public get LabelAuswahl3(): string { return this.getProperty('LabelAuswahl3'); }
    public set LabelAuswahl3(value: string) { this.setProperty('LabelAuswahl3', value); }
    
    @Expose()
    public get LabelAuswahl4(): string { return this.getProperty('LabelAuswahl4'); }
    public set LabelAuswahl4(value: string) { this.setProperty('LabelAuswahl4', value); }
    
    @Expose()
    public get LabelAuswahl5(): string { return this.getProperty('LabelAuswahl5'); }
    public set LabelAuswahl5(value: string) { this.setProperty('LabelAuswahl5', value); }
    
    @Expose()
    public get LabelAuswahl6(): string { return this.getProperty('LabelAuswahl6'); }
    public set LabelAuswahl6(value: string) { this.setProperty('LabelAuswahl6', value); }
    
    @Expose()
    public get LabelAuswahl7(): string { return this.getProperty('LabelAuswahl7'); }
    public set LabelAuswahl7(value: string) { this.setProperty('LabelAuswahl7', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AuswahlPflicht(): ListType { return this.getProperty('AuswahlPflicht'); }
    public set AuswahlPflicht(value: ListType) { this.setProperty('AuswahlPflicht', value); }
    
}
