<div class="card-contents" >    <!-- *ngIf="VersionId && (NumAntraege > 0 || !Readonly) && KannAntraegeHaben" -->
    <p-panel
        header=""
        [toggleable]="true"
        [collapsed]="NumAntraege === 0 || ForceCollapsed"
        expandIcon="pi pi-chevron-down"
        collapseIcon="pi pi-chevron-up"
        toggler="header"
    >
        <p-header>
            <div class="p-panel-title">
                Einschränkung der Offenlegung
                <p-badge
                    *ngIf="NumAntraege > 0"
                    styleClass="schwaerzen valign-center"
                    [value]="NumAntraege?.toString()"
                    [pTooltip]="NumAntraege + (NumAntraege === 1 ? ' Antrag' : ' Anträge')"
                    >
                </p-badge>
            </div>
        </p-header>

        <div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <div class="pb-3">
                        <avi-core-base-searcher
                            #antragListe
                            [contents-padding]="false"
                            [use-router-params]="false"
                            [hide-when-noresults]="true"
                            [search-delegate]="SearchDelegate"
                            [rows]="0"
                            [auto-search]="true"
                            [show-autofilter]="false"
                            [toolbar]="false"
                            [toolbar-show-searchinput]="false"
                            [toolbar-show-advanced]="false"
                            [lazy-loading]="true"
                            [card]="false"
                            (onInit)="onAntragListInit($event)"
                            searcher-title="Formulare"
                            [show-totalrecords]="true"
                            [select-first-result]="false"
                        >
                        </avi-core-base-searcher>
                    </div>

                </div>
            </div>

        </div>

        <div class="ui-g" *ngIf="!Readonly && CanEdit">
            <div class="grid m-0">
                <div class="col grid m-0">
                    <button
                        pButton
                        type="button"
                        icon="pi pi-star"
                        [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_ERFASSEN_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="createAntrag()"
                        class="w-100p-m mr-2 p-button-primary"
                    ></button>
                </div>

                <vwbp-intern-schwaerzen-taskbar
                    [readonly]="Readonly"
                    [version]="Version"
                    [antrag]="getSelectedAntrag()"
                    (onChanged)="HandleChanged()"
                >
                </vwbp-intern-schwaerzen-taskbar>
            </div>

        </div>

    </p-panel>
</div>

<!-- EDIT ANTRAG DIALOG -->
<avi-core-dialog
    #AntragFormDialog
    identifier="AntragFormDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
>
    <vwbp-intern-schwaerzen-antrag-form
        #AntragForm
        [contents-padding]="false"
        (onSavedSuccessful)="onAntragSavedSuccessful($event)"
        [action-buttons]="formActionButtons"
        [action-buttons-readonly]="formActionButtonsReadonly"
        [formular-daten]="FormularDaten"
    >
    </vwbp-intern-schwaerzen-antrag-form>
</avi-core-dialog>
