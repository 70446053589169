import { Expose, Type } from 'class-transformer';

import { SysMessageDef } from '../system/sysmessagedef.model';
import { BaseModelState } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class SysMessageDto extends BaseModelState {
    @Expose()
    @Type(() => Date)
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @Type(() => Date)
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get Text(): string { return this.getProperty('Text'); }
    public set Text(value: string) { this.setProperty('Text', value); }

    @Expose()
    @Type(() => ListType)
    public get AnzeigenBeiLogin(): ListType { return this.getProperty('AnzeigenBeiLogin'); }
    public set AnzeigenBeiLogin(value: ListType) { this.setProperty('AnzeigenBeiLogin', value); }

    @Expose()
    @Type(() => ListType)
    public get Anzeigen1hVorBeginn(): ListType { return this.getProperty('Anzeigen1hVorBeginn'); }
    public set Anzeigen1hVorBeginn(value: ListType) { this.setProperty('Anzeigen1hVorBeginn', value); }

    @Expose()
    @Type(() => ListType)
    public get Anzeigen15minVorBeginn(): ListType { return this.getProperty('Anzeigen15minVorBeginn'); }
    public set Anzeigen15minVorBeginn(value: ListType) { this.setProperty('Anzeigen15minVorBeginn', value); }

    @Expose()
    @Type(() => ListType)
    public get Anzeigen5minVorBeginn(): ListType { return this.getProperty('Anzeigen5minVorBeginn'); }
    public set Anzeigen5minVorBeginn(value: ListType) { this.setProperty('Anzeigen5minVorBeginn', value); }

    @Expose()
    @Type(() => ListType)
    public get Anzeigen2minVorBeginn(): ListType { return this.getProperty('Anzeigen2minVorBeginn'); }
    public set Anzeigen2minVorBeginn(value: ListType) { this.setProperty('Anzeigen2minVorBeginn', value); }

    @Expose()
    @Type(() => ListType)
    public get Anzeigen30sVorBeginn(): ListType { return this.getProperty('Anzeigen30sVorBeginn'); }
    public set Anzeigen30sVorBeginn(value: ListType) { this.setProperty('Anzeigen30sVorBeginn', value); }

    @Expose()
    public get Finished(): boolean { return this.getProperty('Finished'); }
    public set Finished(value: boolean) { this.setProperty('Finished', value); }
}
