import { Expose, Type } from 'class-transformer';

export class VwbPActionData {
    @Expose()
    public ModelIdList: string[] = [];

    @Expose()
    public Status?: string;

    @Expose()
    @Type(() => Date)
    public Datum?: Date;

    @Expose()
    @Type(() => Date)
    public DatumBis?: Date;

    @Expose()
    @Type(() => Date)
    public Frist?: Date;

    @Expose()
    public Action: string;

    @Expose()
    public Bemerkung?: string;

    @Expose()
    public Context?: string;

    @Expose()
    public DokuGenerieren?: boolean;
}
