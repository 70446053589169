<avi-core-page *ngIf="!app.IsLoggedIn" title="Mein Dashboard" icon="dashboard" [collapsible]="false">
    <!-- <core-login></core-login> -->
    <a [routerLink]="['/vwbp/login']">Zum Login...</a>
</avi-core-page>
<avi-core-page *ngIf="app.IsLoggedIn" title="Mein Dashboard" icon="dashboard" [collapsible]="false">
    <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-lg-5">
            <p-tabView>
                
                <p-tabPanel header="Faktura">
                    <ul>
                        <li><a [routerLink]="['/faktura/leistung']">Leistungen suchen</a></li>
                    </ul>
                </p-tabPanel>
                <!-- <p-tabPanel header="Reports">
						<ul>
							<li><a [routerLink]="['/crm/partner']">Meine Leistungen</a></li>
							<li><a [routerLink]="['/crm/partner']">Adresslisten</a></li>
						</ul>
					</p-tabPanel> -->
                <!-- <p-tabPanel header="Stammdaten Reports">
						<ul>
							<li><a [routerLink]="['/crm/partner']">Kreditübersicht</a></li>
							<li><a [routerLink]="['/crm/partner']">Honorarbestand</a></li>
						</ul>
					</p-tabPanel> -->
            </p-tabView>
        </div>
        <!--
			<div class="ui-g-12 ui-lg-7">
				<h2>Statistiken</h2>
				<p-tabView>
					<p-tabPanel header="Verrechenbare Leistungen">
							<app-querydef-exec-graph *ngIf="showGraphs()" #graph id="dc9cd5bd-0572-437a-ad78-72abc864390b"></app-querydef-exec-graph>
					</p-tabPanel>
					<p-tabPanel header="Chart">
						<p-chart type="line" [data]="chartData" responsive="true"></p-chart>
					</p-tabPanel>
					<p-tabPanel header="Bar">
						<p-chart type="bar" [data]="chartData" responsive="true"></p-chart>
					</p-tabPanel>
					<p-tabPanel header="Radar">
						<p-chart type="radar" [data]="chartData" responsive="true"></p-chart>
					</p-tabPanel>
				</p-tabView>
			</div> -->
    </div>
</avi-core-page>
