import { DividerModule } from 'primeng/divider';
import { StepsModule } from 'primeng/steps';
import { FileSaverModule } from 'ngx-filesaver';
import { MenuModule } from 'primeng/menu';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HighlightModule } from 'ngx-highlightjs';

import { TranslateModule } from '@ngx-translate/core';

// avi-x und vwbp-public
import { AviCoreModule, ParentIdResolver } from '@avi-x/avi-core';
import { AviVwbPPublicModule } from '@avi-x/vwbp-public';
import { AviCommentModule, AviDokumentModule } from '@avi-x/avi-crm';

// PrimeNG
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';
import { MessagesModule } from 'primeng/messages';
import { BadgeModule } from 'primeng/badge';


// VwbP Common
import { VwbPInternalAuthGuardService } from './auth/services/vwbp-internal-auth-guard.service';

// Rechtsträger
import { VwbPInternRechtstraegerViewComponent } from './rechtstraeger/components/rechtstraeger-view/rechtstraeger-view.component';
import { VwbPInternRechtstraegerIndexComponent } from './rechtstraeger/components/rechtstraeger-index/rechtstraeger-index.component';
import { VwbPInternRechtstraegerCommentListComponent } from './rechtstraeger/components/rechtstraeger-comment-list/rechtstraeger-comment-list.component';
import { VwbPInternRechtstraegerCommentViewComponent } from './rechtstraeger/components/rechtstraeger-comment-view/rechtstraeger-comment-view.component';
import { VwbPInternRechtstraegerVersionResolver } from './rechtstraeger/services/rechtstraegerversion-resolver';
import { VwbPInternRechtstraegerBaseComponent } from './rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-searcher-base.component';
import { VwbPInternRechtstraegerVersionenSearcherComponent } from './rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-versionen-searcher.component';
import { VwbPInternRechtstraegerVersionenCommentsSearcherComponent } from './rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-comments-searcher.component';
import { VwbPInternRechtstraegerSearcherComponent } from './rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-searcher.component';
import { VwbPInternRechtstraegerKontaktInfoComponent } from './rechtstraeger/components/rechtstraeger-kontaktinfo/rechtstraeger-kontaktinfo-panel.component';
import { VwbPInternRechtstraegerViewIndexComponent } from './rechtstraeger/components/rechtstraeger-view/rechtstraeger-view-index.component';
import { VwbPInternRechtstraegerAdresseSearcherComponent } from './rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';
import { VwbPInternRechtstraegerAdresseDialogComponent } from './rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-dialog.component';
import { VwbPInternRechtstraegerMiniViewComponent } from './rechtstraeger/components/rechtstraeger-miniview/rechtstraeger-miniview.component';
import { VwbPInternBehoerdenVersionSearcherComponent } from './behoerden/components/behoerden-version-searcher/behoerden-version-searcher.component';
import { VwbPInternBehoerdenVersionDialogComponent } from './behoerden/components/behoerden-version-searcher/behoerden-version-dialog.component';

// Rechtsträger Dokumente
import { VwbPInternRechtstraegerDokumenteComponent } from './rechtstraeger/components/rechtstraeger-dokumente/rechtstraeger-dokumente.component';
import { VwbPInternRechtstraegerDokumenteSearcherComponent } from './rechtstraeger/components/rechtstraeger-dokumente/rechtstraeger-dokumente-searcher.component';
import { VwbPInternRechtstraegerGesamtAktDialogComponent } from './rechtstraeger/components/rechtstraeger-dokumente/rechtstraeger-gesamtakt-dialog.component';
import { VwbPInternRechtstraegerSerienbriefDialogComponent } from './rechtstraeger/components/rechtstraeger-dokumente/rechtstraeger-serienbrief-dialog.component';

// Rechtsträger Tasks
import { VwbPInternRechtstraegerRueckgaengigTaskComponent } from './rechtstraeger/components/rechtstraeger-tasks/rechtstraeger-rueckgaengig-task.component';
import { VwbPInternRechtstraegerFreigebenTaskComponent } from './rechtstraeger/components/rechtstraeger-tasks/rechtstraeger-freigeben-task.component';
import { VwbPInternRechtstraegerVereinfachteValidierungTaskComponent } from './rechtstraeger/components/rechtstraeger-tasks/rechtstraeger-vereinfachte-validierung-task.component';
import { VwbPInternRechtstraegerEingetragenEWRRegisterTaskComponent } from './rechtstraeger/components/rechtstraeger-tasks/rechtstraeger-eingetragen-ewr-register-task.component';
import { VwbPInternRechtstraegerSchwaerzungVorhandenTaskComponent } from './rechtstraeger/components/rechtstraeger-tasks/rechtstraeger-schwaerzung-vorhanden-task.component';
import { VwbPInternRechtstraegerBemerkungTeilfondsTaskComponent } from './rechtstraeger/components/rechtstraeger-tasks/rechtstraeger-bemerkung-fonds-task.component';

// Verlauf
import { VwbPInternVerlaufSearcherComponent } from './verlauf/components/verlauf-searcher/verlauf-searcher.component';
import { VwbPInternVerlaufFormComponent } from './verlauf/components/verlauf-form/verlauf-form.component';

// Unstimmigkeit
import { VwbPInternUnstimmigkeitIndexComponent } from './unstimmigkeit/components/unstimmigkeit-index/unstimmigkeit-index.component';
import { VwbPInternUnstimmigkeitViewComponent } from './unstimmigkeit/components/unstimmigkeit-view/unstimmigkeit-view.component';
import { VwbPInternUnstimmigkeitListComponent } from './unstimmigkeit/components/unstimmigkeit-list/unstimmigkeit-list.component';
import { VwbPInternUnstimmigkeitCommentListComponent } from './unstimmigkeit/components/unstimmigkeit-comment-list/unstimmigkeit-comment-list.component';
import { VwbPInternUnstimmigkeitCommentViewComponent } from './unstimmigkeit/components/unstimmigkeit-comment-view/unstimmigkeit-comment-view.component';
import { VwbPInternUnstimmigkeitMeldungOffenComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-meldung-offen.component';
import { VwbPInternUnstimmigkeitKorrigiertComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-korrigiert.component';
import { VwbPInternUnstimmigkeitInformiertComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-informiert.component';
import { VwbPInternUnstimmigkeitAbgeschlossenComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-abgeschlossen.component';
import { VwbPInternUnstimmigkeitMahnfristAbgelaufenComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-mahnfirst-abgelaufen.component';
import { VwbPInternUnstimmigkeitGemahntComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-gemahnt.component';
import { VwbPInternUnstimmigkeitGebuesstComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-gebuesst.component';
import { VwbPInternUnstimmigkeitBussfristLauftComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-bussfirst-lauft.component';
import { VwbPInternUnstimmigkeitAmtlichesLoeschverfahrenComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-amtliches-loeschverfahren.component';
import { VwbPInternUnstimmigkeitRechtsmittelfristAbgelaufenComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-rechtsmittelfrist-abgelaufen.component';
import { VwbPInternUnstimmigkeitBussfristAbgelaufenComponent } from './unstimmigkeit/components/unstimmigkeit-management/unstimmigkeit-bussfirst-abgelaufen.component';
import { VwbPInternUnstimmigkeitBusseSearcherComponent } from './unstimmigkeit/components/unstimmigkeit-busse-searcher/unstimmigkeit-busse-searcher.component';
import { VwbPInternUnstimmigkeitMahnungSearcherComponent } from './unstimmigkeit/components/unstimmigkeit-mahnung-searcher/unstimmigkeit-mahnung-searcher.component';
import { VwbPInternUnstimmigkeitLoeschenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-loeschen-task.component';
import { VwbPInternUnstimmigkeitRueckgaengigTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-rueckgaengig-task.component';

// Unstimmigkeit Tasks
import { VwbPInternUnstimmigkeitPausierenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-pausieren-task.component';
import { VwbPInternUnstimmigkeitAktivierenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-aktivieren-task.component';
import { VwbPInternUnstimmigkeitBussenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-bussen-task.component';
import { VwbPInternUnstimmigkeitAmtlicheAufloesungTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-amtliche-aufloesung-task.component';
import { VwbPInternUnstimmigkeitInformierenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-informieren-task.component';
import { VwbPInternUnstimmigkeitMahnenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-mahnen-task.component';
import { VwbPInternUnstimmigkeitAbschliessenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-abschliessen-task.component';
import { VwbPInternUnstimmigkeitEinzugsauftragTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-einzugsauftrag-task.component';
import { VwbPInternUnstimmigkeitAktivierenBussfristSetzenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-aktivieren-bussfrist-setzen-task.component';
import { VwbPInternUnstimmigkeitAktivierenRechtsmittelfristSetzenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-aktivieren-rechtsmittelfrist-setzen-task.component';
import { VwbPInternUnstimmigkeitAktivierenMahnfristSetzenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-aktivieren-mahnfrist-setzen-task.component';
import { VwbPInternUnstimmigkeitBussfristSetzenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-bussfirst-setzen-task.component';
import { VwbPInternUnstimmigkeitRechtsmittelfristSetzenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-rechtsmittelfirst-setzen-task.component';
import { VwbPInternUnstimmigkeitMahnfristSetzenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-mahnfirst-setzen-task.component';
import { VwbPInternUnstimmigkeitZurueckAufInformiertTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-zurueck-auf-informiert-task.component';
import { VwbPInternUnstimmigkeitErneutBussenTaskComponent } from './unstimmigkeit/components/unstimmigkeit-tasks/unstimmigkeit-erneut-bussen-task.component';

// Kontrollwesen
import { VwbPInternKontrollwesenIndexComponent } from './kontrollwesen/components/kontrollwesen-index/kontrollwesen-index.component';
import { VwbPInternKontrollwesenPendenzenComponent } from './kontrollwesen/components/kontrollwesen/kontrollwesen-pendenzen.component';
import { VwbPInternKontrollwesenBemerkungenComponent } from './kontrollwesen/components/kontrollwesen/kontrollwesen-bemerkungen.component';
import { VwbPInternKontrollwesenAlleRechtstraegerComponent } from './kontrollwesen/components/kontrollwesen/kontrollwesen-allerechtstraeger.component';

// Mahnwesen
import { VwbPInternMahnwesenIndexComponent } from './mahnwesen/components/mahnwesen-index/mahnwesen-index.component';
import { VwbPInternMahnwesenTaskbarComponent } from './mahnwesen/components/mahnwesen-taskbar/mahnwesen-taskbar.component';
import { VwbPInternMahnwesenNichtRelevantComponent } from './mahnwesen/components/mahnwesen/mahnwesen-nichtrelevant.component';
import { VwbPInternMahnwesenGebuesstComponent } from './mahnwesen/components/mahnwesen/mahnwesen-gebuesst.component';
import { VwbPInternMahnwesenMahnfirstAbgelaufenComponent } from './mahnwesen/components/mahnwesen/mahnwesen-mahnfrist-abgelaufen.component';
import { VwbPInternMahnwesenGemahntComponent } from './mahnwesen/components/mahnwesen/mahnwesen-gemahnt.component';
import { VwbPInternMahnwesenInBearbeitungComponent } from './mahnwesen/components/mahnwesen/mahnwesen-inbearbeitung.component';
import { VwbPInternMahnwesenNichtErfasstComponent } from './mahnwesen/components/mahnwesen/mahnwesen-nichterfasst.component';
import { VwbPInternMahnwesenRechtsmittelfristAbgelaufenComponent } from './mahnwesen/components/mahnwesen/mahnwesen-rechtsmittelfrist-abgelaufen.component';
import { VwbPInternMahnwesenBussfristLauftComponent } from './mahnwesen/components/mahnwesen/mahnwesen-bussfrist-lauft.component';
import { VwbPInternMahnwesenBussfristAbgelaufenComponent } from './mahnwesen/components/mahnwesen/mahnwesen-bussfrist-abgelaufen.component';
import { VwbPInternMahnwesenAmtlichesLoeschverfahrenComponent } from './mahnwesen/components/mahnwesen/mahnwesen-amtliches-loeschverfahren.component';
import { VwbPInternMahnwesenBusseSearcherComponent } from './mahnwesen/components/mahnwesen-busse-searcher/mahnwesen-busse-searcher.component';
import { VwbPInternMahnwesenMahnungSearcherComponent } from './mahnwesen/components/mahnwesen-mahnung-searcher/mahnwesen-mahnung-searcher.component';
import { VwbPInternMahnwesenAbgeschlossenSearcherComponent } from './mahnwesen/components/mahnwesen-abgeschlossen-searcher/mahnwesen-abgeschlossen-searcher.component';
import { VwbPInternMahnwesenAbgeschlossenComponent } from './mahnwesen/components/mahnwesen/mahnwesen-abgeschlossen.component';

// Mahnwesen Tasks
import { VwbPInternMahnwesenPausierenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-pausieren-task.component';
import { VwbPInternMahnwesenAktivierenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-aktivieren-task.component';
import { VwbPInternMahnwesenBussenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-bussen-task.component';
import { VwbPInternMahnwesenAmtlicheAufloesungTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-amtliche-aufloesung-task.component';
import { VwbPInternMahnwesenNichtRelevantTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-nicht-relevant-task.component';
import { VwbPInternMahnwesenMahnenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-mahnen-task.component';
import { VwbPInternMahnwesenEinzugsauftragTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-einzugsauftrag-task.component';
import { VwbPInternMahnwesenAktivierenBussfristSetzenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-aktivieren-bussfrist-setzen-task.component';
import { VwbPInternMahnwesenAktivierenRechtsmittelfristSetzenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-aktivieren-rechtsmittelfrist-setzen-task.component';
import { VwbPInternMahnwesenAktivierenMahnfristSetzenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-aktivieren-mahnfrist-setzen-task.component';
import { VwbPInternMahnwesenBussfristSetzenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-bussfirst-setzen-task.component';
import { VwbPInternMahnwesenRechtsmittelfristSetzenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-rechtsmittelfirst-setzen-task.component';
import { VwbPInternMahnwesenMahnfristSetzenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-mahnfirst-setzen-task.component';
import { VwbPInternMahnwesenRueckgaengigTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-rueckgaengig-task.component';
import { VwbPInternMahnwesenErneutBussenTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-erneut-bussen-task.component';
import { VwbPInternMahnwesenAktivierenNichtRelevantTaskComponent } from './mahnwesen/components/mahnwesen-tasks/mahnwesen-aktivieren-nicht-relevant-task.component';

// Übergabe
import { VwbPInternUebergabeIndexComponent } from './uebergabe/components/uebergabe-index/uebergabe-index.component';
import { VwbPInternUebergabeAbgegebenComponent } from './uebergabe/components/uebergabe/uebergabe-abgegeben.component';
import { VwbPInternUebergabeMitVerantwortlicherComponent } from './uebergabe/components/uebergabe/uebergabe-mit-verantwortlicher.component';
import { VwbPInternUebergabeAusgenommeneComponent } from './uebergabe/components/uebergabe/uebergabe-ausgenommene.component';
import { VwbPInternUebergabeAngefragtComponent } from './uebergabe/components/uebergabe/uebergabe-angefragt.component';
import { VwbPInternUebergabeAnfrageViewComponent } from './uebergabe/components/uebergabe/uebergabe-anfrage-view/uebergabe-anfrage-view.component';

// Behörden-Suche
import { VwbPInternBehoerdenSucheIndexComponent } from './behoerden/components/behoerden-suche-index/behoerden-suche-index.component';
import { VwbPInternBehoerdenSucheRechtstraegerComponent } from './behoerden/components/behoerden-suche-rechtstraeger/behoerden-suche-rechtstraeger.component';
import { VwbPInternBehoerdenSucheWbNpComponent } from './behoerden/components/behoerden-suche-wb-np/behoerden-suche-wb-np.component';
import { VwbPInternBehoerdenSucheWbJpIndexComponent } from './behoerden/components/behoerden-suche-wb-jp-index/behoerden-suche-wb-jp-index.component';
import { VwbPInternBehoerdenSucheWbJpInlandComponent } from './behoerden/components/behoerden-suche-wb-jp-inland/behoerden-suche-wb-jp-inland.component';
import { VwbPInternBehoerdenSucheWbJpAuslandComponent } from './behoerden/components/behoerden-suche-wb-jp-ausland/behoerden-suche-wb-jp-ausland.component';
import { VwbPInternBehoerdenSucheKdbComponent } from './behoerden/components/behoerden-suche-kdb/behoerden-suche-kdb.component';

// Postfach
import { VwbPInternPostfachSearcherComponent } from './postfach/components/postfach-searcher/postfach-searcher.components';

// Schwärzen
import { VwbPInternSchwaerzenIndexComponent } from './schwaerzen/components/schwaerzen-index/schwaerzen-index.component';
import { VwbPInternSchwaerzenAntragComponent } from './schwaerzen/components/schwaerzen/schwaerzen-antrag.component';
import { VwbPInternSchwaerzenBewilligtComponent } from './schwaerzen/components/schwaerzen/schwaerzen-bewilligt.component';
import { VwbPInternSchwaerzenFristLauftAbComponent } from './schwaerzen/components/schwaerzen/schwaerzen-frist-lauft-ab.component';
import { VwbPInternSchwaerzenRechtstraegerInformiertComponent } from './schwaerzen/components/schwaerzen/schwaerzen-rechtstraeger-informiert.component';
import { VwbPInternSchwaerzenFristAbgelaufenComponent } from './schwaerzen/components/schwaerzen/schwaerzen-frist-abgelaufen.component';
import { VwbPInternSchwaerzenAbgelehntComponent } from './schwaerzen/components/schwaerzen/schwaerzen-abgelehnt.component';
import { VwbPInternSchwaerzenNeueVersionComponent } from './schwaerzen/components/schwaerzen/schwaerzen-neue-version.component';

// Schwärzen Tasks
import { VwbPInternSchwaerzenAntragBewilligenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-antrag-bewilligen-task.component';
import { VwbPInternSchwaerzenAntragAblehnenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-antrag-ablehnen-task.component';
import { VwbPInternSchwaerzenInformierenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-informieren.component';
import { VwbPInternSchwaerzenAntragKontrollierenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-antrag-kontrollieren.component';
import { VwbPInternSchwaerzenPanelComponent } from './schwaerzen/components/schwaerzen-panel/schwaerzen-panel.component';
import { VwbPInternSchwaerzenAntragFormComponent } from './schwaerzen/components/schwaerzen-form/schwaerzen-form.component';
import { VwbPInternSchwaerzenAntragSearcherComponent } from './schwaerzen/components/schwaerzen-antrag-searcher/schwaerzen-antrag-searcher.component';
import { VwbPInternSchwaerzenTaskbarComponent } from './schwaerzen/components/schwaerzen-taskbar/schwaerzen-taskbar.component';
import { VwbPInternSchwaerzenRueckgaengigTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-antrag-rueckgaengig-task.component';
import { VwbPInternSchwaerzenBearbeitetComponent } from './schwaerzen/components/schwaerzen/schwaerzen-bearbeitet';
import { VwbPInternSchwaerzenRechtsmittelfristAbgelaufenComponent } from './schwaerzen/components/schwaerzen/schwaerzen-rechtsmittelfrist-abgelaufen';
import { VwbPInternSchwaerzenAntragRechtsmittelfristAnpassenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-antrag-rechtsmittelfrist-anpassen-task.component';
import { VwbPInternSchwaerzenAntragGueltigkeitSetzenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-antrag-gueltigkeit-setzen-task.component';
import { VwbPInternSchwaerzenAntragRechtskraftSetzenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-antrag-rechtskraft-setzen-task.component';
import { VwbPInternSchwaerzenPausierenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-pausieren-task.component';
import { VwbPInternSchwaerzenAktivierenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-aktivieren-task.component';
import { VwbPInternSchwaerzenAktivierenRechtsmittelfristSetzenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-aktivieren-rechtsmittelfrist-setzen-task.component';
import { VwbPInternSchwaerzenWBNichtMehrVorhandenTaskComponent } from './schwaerzen/components/schwaerzen-tasks/schwaerzen-antrag-wbnichtmehrvorhanden-task.component';

const routes: Routes = [

    { path: '', redirectTo: 'intern/vwbp/index', pathMatch: 'full' },

    // {
    //     path: 'intern/vwbp/postfach',
    //     canActivate: [VwbPInternalAuthGuardService],
    //     component: VwbPInternPostfachSearcherComponent,
    // },

    {
        path: 'intern/vwbp/index',
        canActivate: [VwbPInternalAuthGuardService],
        component: VwbPInternRechtstraegerIndexComponent,
        children: [
            { path: '', redirectTo: 'kontrollwesen', pathMatch: 'full' },

            {
                path: 'rt/:id',
                component: VwbPInternRechtstraegerViewIndexComponent,
                resolve: { id: VwbPInternRechtstraegerVersionResolver },
                children: [
                    { path: '', redirectTo: 'rechtstraeger', pathMatch: 'full' },

                    {
                        path: 'rechtstraeger',
                        component: VwbPInternRechtstraegerViewComponent,
                        data: { 'submodul': true }
                    },
                    {
                        path: 'dokumente',
                        component: VwbPInternRechtstraegerDokumenteComponent,
                        data: { 'submodul': true }
                    }
                ]
            },

            {
                path: 'kontrollwesen',
                component: VwbPInternKontrollwesenIndexComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true },
                children: [
                    { path: '', redirectTo: 'allerechtstraeger', pathMatch: 'full' },
                    {
                        path: 'allerechtstraeger',
                        component: VwbPInternKontrollwesenAlleRechtstraegerComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'pendenzen',
                        component: VwbPInternKontrollwesenPendenzenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'bemerkungen',
                        component: VwbPInternKontrollwesenBemerkungenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                ]
            },
            {
                path: 'uebergabe',
                component: VwbPInternUebergabeIndexComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true },
                children: [
                    { path: '', redirectTo: 'mit-verantwortlicher', pathMatch: 'full' },
                    {
                        path: 'mit-verantwortlicher',
                        component: VwbPInternUebergabeMitVerantwortlicherComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'abgegeben',
                        component: VwbPInternUebergabeAbgegebenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'angefragt',
                        component: VwbPInternUebergabeAngefragtComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'angefragt/:id',
                        component: VwbPInternUebergabeAnfrageViewComponent,
                    },
                    {
                        path: 'ausgenommene',
                        component: VwbPInternUebergabeAusgenommeneComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    }
                ]
            },
            {
                path: 'unstimmigkeit',
                component: VwbPInternUnstimmigkeitIndexComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true },
                children: [
                    { path: '', redirectTo: 'meldung', pathMatch: 'full' },
                    {
                        path: 'meldung',
                        component: VwbPInternUnstimmigkeitMeldungOffenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'informiert',
                        component: VwbPInternUnstimmigkeitInformiertComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'korrigiert',
                        component: VwbPInternUnstimmigkeitKorrigiertComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'gemahnt',
                        component: VwbPInternUnstimmigkeitGemahntComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'mahnfrist-abgelaufen',
                        component: VwbPInternUnstimmigkeitMahnfristAbgelaufenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'gebuesst',
                        component: VwbPInternUnstimmigkeitGebuesstComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'rechtsmittelfrist-abgelaufen',
                        component: VwbPInternUnstimmigkeitRechtsmittelfristAbgelaufenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'bussfrist-lauft',
                        component: VwbPInternUnstimmigkeitBussfristLauftComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'bussfrist-abgelaufen',
                        component: VwbPInternUnstimmigkeitBussfristAbgelaufenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'amtliches-loeschverfahren',
                        component: VwbPInternUnstimmigkeitAmtlichesLoeschverfahrenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'abgeschlossen',
                        component: VwbPInternUnstimmigkeitAbgeschlossenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    }
                ]
            },
            {
                path: 'mahnwesen',
                component: VwbPInternMahnwesenIndexComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true },
                children: [
                    { path: '', redirectTo: 'nichterfasst', pathMatch: 'full' },
                    {
                        path: 'nichterfasst',
                        component: VwbPInternMahnwesenNichtErfasstComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'inbearbeitung',
                        component: VwbPInternMahnwesenInBearbeitungComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'gemahnt',
                        component: VwbPInternMahnwesenGemahntComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'mahnfrist-abgelaufen',
                        component: VwbPInternMahnwesenMahnfirstAbgelaufenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'gebuesst',
                        component: VwbPInternMahnwesenGebuesstComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'rechtsmittelfrist-abgelaufen',
                        component: VwbPInternMahnwesenRechtsmittelfristAbgelaufenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'bussfrist-lauft',
                        component: VwbPInternMahnwesenBussfristLauftComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'bussfrist-abgelaufen',
                        component: VwbPInternMahnwesenBussfristAbgelaufenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'amtliches-loeschverfahren',
                        component: VwbPInternMahnwesenAmtlichesLoeschverfahrenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'nicht-relevant',
                        component: VwbPInternMahnwesenNichtRelevantComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'abgeschlossen',
                        component: VwbPInternMahnwesenAbgeschlossenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    }
                ]
            },
            {
                path: 'schwaerzen',
                component: VwbPInternSchwaerzenIndexComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true },
                children: [
                    { path: '', redirectTo: 'antrag', pathMatch: 'full' },
                    {
                        path: 'antrag',
                        component: VwbPInternSchwaerzenAntragComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'bearbeitet',
                        component: VwbPInternSchwaerzenBearbeitetComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'rechtsmittelfrist',
                        component: VwbPInternSchwaerzenRechtsmittelfristAbgelaufenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'bewilligt',
                        component: VwbPInternSchwaerzenBewilligtComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'frist-lauft-ab',
                        component: VwbPInternSchwaerzenFristLauftAbComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'rechtstraeger-informiert',
                        component: VwbPInternSchwaerzenRechtstraegerInformiertComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'neue-version',
                        component: VwbPInternSchwaerzenNeueVersionComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'abgelehnt',
                        component: VwbPInternSchwaerzenAbgelehntComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'frist-abgelaufen',
                        component: VwbPInternSchwaerzenFristAbgelaufenComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    }
                ]
            }
        ]
    },
    {
        path: 'intern/vwbp/behoerden',
        canActivate: [VwbPInternalAuthGuardService],
        component: VwbPInternBehoerdenSucheIndexComponent,
        children: [
            { path: '', redirectTo: 'rechtstraeger', pathMatch: 'full' },
            {
                path: 'rechtstraeger',
                component: VwbPInternBehoerdenSucheRechtstraegerComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            },
            {
                path: 'wb-np',
                component: VwbPInternBehoerdenSucheWbNpComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            },
            {
                path: 'wb-jp',
                component: VwbPInternBehoerdenSucheWbJpIndexComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true },
                children: [
                    { path: '', redirectTo: 'inland', pathMatch: 'full' },
                    {
                        path: 'inland',
                        component: VwbPInternBehoerdenSucheWbJpInlandComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    },
                    {
                        path: 'ausland',
                        component: VwbPInternBehoerdenSucheWbJpAuslandComponent,
                        resolve: { id: ParentIdResolver },
                        data: { 'submodul': true }
                    }
                ]
            },
            {
                path: 'kdb',
                component: VwbPInternBehoerdenSucheKdbComponent,
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        FlexLayoutModule,
        AviCoreModule,
        AviCommentModule,
        AviDokumentModule,

        ButtonModule,
        ToggleButtonModule,
        ToolbarModule,
        MessageModule,
        MessagesModule,
        CheckboxModule,
        AutoCompleteModule,
        PanelModule,
        ConfirmDialogModule,
        DropdownModule,
        InputTextareaModule,
        MultiSelectModule,
        CalendarModule,
        TooltipModule,
        TableModule,
        MenuModule,
        BadgeModule,
        StepsModule,
        DividerModule,

        MatTabsModule,

        AviVwbPPublicModule,

        FileSaverModule,
        HighlightModule,

        RouterModule.forChild(routes),
        TranslateModule
    ],
    exports: [
        VwbPInternPostfachSearcherComponent
    ],
    declarations: [
        // Rechtsträger View
        VwbPInternRechtstraegerViewComponent,
        VwbPInternRechtstraegerMiniViewComponent,
        VwbPInternRechtstraegerIndexComponent,
        VwbPInternRechtstraegerCommentListComponent,
        VwbPInternRechtstraegerCommentViewComponent,
        VwbPInternRechtstraegerBaseComponent,
        VwbPInternRechtstraegerVersionenSearcherComponent,
        VwbPInternRechtstraegerSearcherComponent,
        VwbPInternRechtstraegerVersionenCommentsSearcherComponent,
        VwbPInternRechtstraegerViewIndexComponent,
        VwbPInternRechtstraegerKontaktInfoComponent,
        VwbPInternRechtstraegerAdresseSearcherComponent,
        VwbPInternRechtstraegerAdresseDialogComponent,
        VwbPInternBehoerdenVersionSearcherComponent,
        VwbPInternBehoerdenVersionDialogComponent,

        // Rechtsträger Dokumente
        VwbPInternRechtstraegerDokumenteComponent,
        VwbPInternRechtstraegerDokumenteSearcherComponent,
        VwbPInternRechtstraegerGesamtAktDialogComponent,
        VwbPInternRechtstraegerSerienbriefDialogComponent,

        // Verlauf
        VwbPInternVerlaufSearcherComponent,
        VwbPInternVerlaufFormComponent,

        // Kontrollwesen
        VwbPInternKontrollwesenIndexComponent,
        VwbPInternKontrollwesenPendenzenComponent,
        VwbPInternKontrollwesenBemerkungenComponent,
        VwbPInternKontrollwesenAlleRechtstraegerComponent,

        // Unstimmigkeitsmanagement
        VwbPInternUnstimmigkeitListComponent,
        VwbPInternUnstimmigkeitViewComponent,
        VwbPInternUnstimmigkeitIndexComponent,
        VwbPInternUnstimmigkeitCommentListComponent,
        VwbPInternUnstimmigkeitCommentViewComponent,
        VwbPInternUnstimmigkeitMeldungOffenComponent,
        VwbPInternUnstimmigkeitKorrigiertComponent,
        VwbPInternUnstimmigkeitInformiertComponent,
        VwbPInternUnstimmigkeitGemahntComponent,
        VwbPInternUnstimmigkeitMahnfristAbgelaufenComponent,
        VwbPInternUnstimmigkeitGebuesstComponent,
        VwbPInternUnstimmigkeitAbgeschlossenComponent,
        VwbPInternUnstimmigkeitBussfristAbgelaufenComponent,
        VwbPInternUnstimmigkeitRechtsmittelfristAbgelaufenComponent,
        VwbPInternUnstimmigkeitAmtlichesLoeschverfahrenComponent,
        VwbPInternUnstimmigkeitBussfristLauftComponent,
        VwbPInternUnstimmigkeitMahnungSearcherComponent,
        VwbPInternUnstimmigkeitBusseSearcherComponent,

        // Mahnwesen
        VwbPInternMahnwesenIndexComponent,
        VwbPInternMahnwesenTaskbarComponent,
        VwbPInternMahnwesenNichtErfasstComponent,
        VwbPInternMahnwesenInBearbeitungComponent,
        VwbPInternMahnwesenGemahntComponent,
        VwbPInternMahnwesenMahnfirstAbgelaufenComponent,
        VwbPInternMahnwesenGebuesstComponent,
        VwbPInternMahnwesenRechtsmittelfristAbgelaufenComponent,
        VwbPInternMahnwesenBussfristLauftComponent,
        VwbPInternMahnwesenBussfristAbgelaufenComponent,
        VwbPInternMahnwesenAmtlichesLoeschverfahrenComponent,
        VwbPInternMahnwesenNichtRelevantComponent,
        VwbPInternMahnwesenMahnungSearcherComponent,
        VwbPInternMahnwesenBusseSearcherComponent,
        VwbPInternMahnwesenAbgeschlossenSearcherComponent,
        VwbPInternMahnwesenAbgeschlossenComponent,

        // Mahnwesen Tasks
        VwbPInternMahnwesenPausierenTaskComponent,
        VwbPInternMahnwesenAktivierenTaskComponent,
        VwbPInternMahnwesenBussenTaskComponent,
        VwbPInternMahnwesenErneutBussenTaskComponent,
        VwbPInternMahnwesenAmtlicheAufloesungTaskComponent,
        VwbPInternMahnwesenNichtRelevantTaskComponent,
        VwbPInternMahnwesenMahnenTaskComponent,
        VwbPInternMahnwesenEinzugsauftragTaskComponent,
        VwbPInternMahnwesenAktivierenBussfristSetzenTaskComponent,
        VwbPInternMahnwesenAktivierenRechtsmittelfristSetzenTaskComponent,
        VwbPInternMahnwesenAktivierenMahnfristSetzenTaskComponent,
        VwbPInternMahnwesenBussfristSetzenTaskComponent,
        VwbPInternMahnwesenRechtsmittelfristSetzenTaskComponent,
        VwbPInternMahnwesenMahnfristSetzenTaskComponent,
        VwbPInternMahnwesenRueckgaengigTaskComponent,
        VwbPInternMahnwesenAktivierenNichtRelevantTaskComponent,

        // Übergabe Prozess
        VwbPInternUebergabeIndexComponent,
        VwbPInternUebergabeMitVerantwortlicherComponent,
        VwbPInternUebergabeAbgegebenComponent,
        VwbPInternUebergabeAngefragtComponent,
        VwbPInternUebergabeAnfrageViewComponent,
        VwbPInternUebergabeAusgenommeneComponent,

        // Rechtsträger Tasks
        VwbPInternRechtstraegerFreigebenTaskComponent,
        VwbPInternRechtstraegerRueckgaengigTaskComponent,
        VwbPInternRechtstraegerVereinfachteValidierungTaskComponent,
        VwbPInternRechtstraegerSchwaerzungVorhandenTaskComponent,
        VwbPInternRechtstraegerEingetragenEWRRegisterTaskComponent,
        VwbPInternRechtstraegerBemerkungTeilfondsTaskComponent,

        // Unstimmigkeiten Tasks
        VwbPInternUnstimmigkeitPausierenTaskComponent,
        VwbPInternUnstimmigkeitAktivierenTaskComponent,
        VwbPInternUnstimmigkeitBussenTaskComponent,
        VwbPInternUnstimmigkeitErneutBussenTaskComponent,
        VwbPInternUnstimmigkeitAmtlicheAufloesungTaskComponent,
        VwbPInternUnstimmigkeitInformierenTaskComponent,
        VwbPInternUnstimmigkeitMahnenTaskComponent,
        VwbPInternUnstimmigkeitAbschliessenTaskComponent,
        VwbPInternUnstimmigkeitEinzugsauftragTaskComponent,
        VwbPInternUnstimmigkeitMahnfristSetzenTaskComponent,
        VwbPInternUnstimmigkeitRechtsmittelfristSetzenTaskComponent,
        VwbPInternUnstimmigkeitBussfristSetzenTaskComponent,
        VwbPInternUnstimmigkeitAktivierenMahnfristSetzenTaskComponent,
        VwbPInternUnstimmigkeitAktivierenRechtsmittelfristSetzenTaskComponent,
        VwbPInternUnstimmigkeitAktivierenBussfristSetzenTaskComponent,
        VwbPInternUnstimmigkeitLoeschenTaskComponent,
        VwbPInternUnstimmigkeitRueckgaengigTaskComponent,
        VwbPInternUnstimmigkeitZurueckAufInformiertTaskComponent,

        // Behörden-Suche
        VwbPInternBehoerdenSucheIndexComponent,
        VwbPInternBehoerdenSucheRechtstraegerComponent,
        VwbPInternBehoerdenSucheWbNpComponent,
        VwbPInternBehoerdenSucheWbJpIndexComponent,
        VwbPInternBehoerdenSucheWbJpInlandComponent,
        VwbPInternBehoerdenSucheWbJpAuslandComponent,
        VwbPInternBehoerdenSucheKdbComponent,

        // Postfach
        VwbPInternPostfachSearcherComponent,

        // Schwärzen
        VwbPInternSchwaerzenIndexComponent,
        VwbPInternSchwaerzenAntragComponent,
        VwbPInternSchwaerzenBearbeitetComponent,
        VwbPInternSchwaerzenRechtsmittelfristAbgelaufenComponent,
        VwbPInternSchwaerzenBewilligtComponent,
        VwbPInternSchwaerzenFristLauftAbComponent,
        VwbPInternSchwaerzenRechtstraegerInformiertComponent,
        VwbPInternSchwaerzenFristAbgelaufenComponent,
        VwbPInternSchwaerzenAbgelehntComponent,
        VwbPInternSchwaerzenNeueVersionComponent,
        VwbPInternSchwaerzenPanelComponent,
        VwbPInternSchwaerzenAntragFormComponent,
        VwbPInternSchwaerzenAntragSearcherComponent,

        // Schwärzen Tasks
        VwbPInternSchwaerzenTaskbarComponent,
        VwbPInternSchwaerzenAntragBewilligenTaskComponent,
        VwbPInternSchwaerzenAntragAblehnenTaskComponent,
        VwbPInternSchwaerzenInformierenTaskComponent,
        VwbPInternSchwaerzenAntragKontrollierenTaskComponent,
        VwbPInternSchwaerzenRueckgaengigTaskComponent,
        VwbPInternSchwaerzenAntragRechtsmittelfristAnpassenTaskComponent,
        VwbPInternSchwaerzenAntragGueltigkeitSetzenTaskComponent,
        VwbPInternSchwaerzenAntragRechtskraftSetzenTaskComponent,
        VwbPInternSchwaerzenPausierenTaskComponent,
        VwbPInternSchwaerzenAktivierenTaskComponent,
        VwbPInternSchwaerzenAktivierenRechtsmittelfristSetzenTaskComponent,
        VwbPInternSchwaerzenWBNichtMehrVorhandenTaskComponent
    ]
})
export class AviVwbPInternModule { }
