import { AviApiService } from '@avi-x/avi-core';

export enum InfoDeskDetailType {
    QUERYDEF, GRAPH
}
export class InfoDeskDetail {

    public id: string;
    public posx: number;
    public posy: number;
    public width: number;
    public height: number;
    public embeddedTable: boolean = false;

    public Type: InfoDeskDetailType = InfoDeskDetailType.QUERYDEF;

    private apiService: AviApiService;

    public constructor(id: string, posx: number, posy: number, width: number, height: number, type: InfoDeskDetailType, embeddedTable: boolean) {
        this.id = id;
        this.posx = posx;
        this.posy = posy;
        this.width = width;
        this.height = height;
        this.Type = type;
        this.embeddedTable = embeddedTable;
    }
}
