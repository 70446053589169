<avi-core-view-panel [toggleable]="false" [header]="Title | translate" class="w-100p" [enable-panel]="EnablePanel" [panel-class]="noPadding ? '' : 'mt-2'" [no-pad]="noPadding">
	<avi-core-base-searcher
		#searcher
		[settingskey]="SettingsKey"
		[search-delegate]="SearchDelegate"
		[default-sort-data]="[{ field: 'LogNr', order: 1 }]"
		[search-fields]="['LogMessage']"
		(onInit)="onSearcherInit($event)"
		(onError)="onSearcherError($event)"
		[use-router-params]="false"
		(onDoubleClick)="onDoubleClickSearcher($event)"
		[rows]="20"
		[auto-search]="true"
		[show-autofilter]="true"
		[toolbar]="true"
		selectionMode="single"
		[toolbar-show-searchinput]="true"
		[toolbar-show-advanced]="false"
		[toolbar-show-columnselection]="true"
		[toolbar-show-inactivebutton]="true"
		[toolbar-show-generic-export]="true"
		[disable-inactive-filter]="false"
		[lazy-loading]="true"
		(onSelect)="onSelectionChanged($event)"
		[card]="!EnablePanel"
		[contents-padding]="EnablePanel"
		[searcher-title]="Title"
		[show-totalrecords]="false"
		[select-first-result]="true"
		[add-crud-menuitems]="false"
		[toolbar-show-createbutton]="false"
		crud-base-right="Job"
		(onCreateObject)="createObject()"
		(onEditObject)="editObject($event)"
		(onDeleteObject)="deleteObject($event)"
	>
	</avi-core-base-searcher>
</avi-core-view-panel>