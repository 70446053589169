import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AviFormField, AviCommonService, AviBaseFormComponent, ObjectUtils, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { ZustandInfoDto } from '@avi-x/avi-dto/shared/zustandinfo.model';
import { VwbPRechtstraegerAnfordernDto } from '../../../dto/vwbp.uebergabe.models';
import { VwbPUebergabeService } from '../../../uebergabe/service/uebergabe.service';

const nameof = (nameFunction: ((obj: VwbPRechtstraegerAnfordernDto) => any)) => typed_nameof<VwbPRechtstraegerAnfordernDto>(nameFunction);


@Component({
    selector: 'vwbp-public-rechtstraeger-anfordern',
    templateUrl: './rechtstraeger-anfordern.component.html',
    styleUrls: ['./rechtstraeger-anfordern.component.scss']
})
export class VwbPPublicRechtstraegerAnfordernComponent implements OnInit, OnDestroy {

    sub: any;

    showSuccessMessage = false;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: VwbPRechtstraegerAnfordernDto = null;

    public Fields: AviFormField[] = [];


    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();


    public HistoryStates: ZustandInfoDto[];

    constructor(private commonService: AviCommonService,
        private formFieldService: AviFormFieldService,
        private uebergabeService: VwbPUebergabeService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(): Promise<boolean> {
        this.Model = null;
        this.cdr.detectChanges();
        this.Form?.clearFormMessages();
        await this.initNewModel();
        this.cdr.markForCheck();

        setTimeout(() => this.Form.focusField(nameof(c => c.RegisterNummer), false));

        return true;
    }

    public async initNewModel(data?: any) {
        this.showSuccessMessage = false;
        this.Model = new VwbPRechtstraegerAnfordernDto();
        if (data) {
            for (const key in data) {
                ObjectUtils.setByPath(this.Model, key, data[key]);
            }
        }
    }

    ngOnInit() {
        this.initFields();
        this.InitForm();
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: VwbPRechtstraegerAnfordernDto) {

        this.Form.Saving = true;
        this.Form.clearFormMessages();
        this.cdr.detectChanges();

        this.uebergabeService.StartRechtstraegerAnfordern(model).then(() => {
            this.showSuccessMessage = true;
            this.cdr.markForCheck();

        }).catch(err => {
            const errText = this.commonService.getErrorText(err);
            // this.commonService.notificateError(errText);
            this.Form.addFormMessage(errText);
        });

        this.Form.Saving = false;
    }


    onBtnClosePress() {
        this.onSavedSuccessfulDelegate.emit(this.Model);

        setTimeout(() => {
            this.showSuccessMessage = false;
            this.cdr.markForCheck();
        }, 600);
    }


    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateInputMask(nameof(c => c.RegisterNummer), 'VWBP.COMMON.REGISTERNR', 'FL-9999.999.999-9', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'VWBP.PUBLIC.FORM.WB_NAME_FIRMA', true).setMDSizeHalf());
    }
}

