import { Expose, Type } from 'class-transformer';

import { CustomPropCollection } from '../system/custompropcollection.model';
import { CustomPropDef } from '../system/custompropdef.model';
import { ListDetail } from '../system/listdetail.model';
import { BaseModel } from '../shared/basemodel.model';
import { EncryptedType } from '../shared/encryptedtype.model';
import { GenericRef } from '../shared/genericref.model';
import { ListType } from '../shared';

export class CustomProperty extends BaseModel {  
    @Expose()
    public get Sequenz(): number { return this.getProperty('Sequenz'); }
    public set Sequenz(value: number) { this.setProperty('Sequenz', value); }
    
    @Expose()
    public get TextValue(): string { return this.getProperty('TextValue'); }
    public set TextValue(value: string) { this.setProperty('TextValue', value); }
    
    @Expose()
    public get BoolValue(): boolean { return this.getProperty('BoolValue'); }
    public set BoolValue(value: boolean) { this.setProperty('BoolValue', value); }
    
    @Expose()
    @Type(() => Date)
    public get DateValue(): Date { return this.getProperty('DateValue'); }
    public set DateValue(value: Date) { this.setProperty('DateValue', value); }
    
    @Expose()
    public get DecimalValue(): number { return this.getProperty('DecimalValue'); }
    public set DecimalValue(value: number) { this.setProperty('DecimalValue', value); }
    
    @Expose()
    public get IntValue(): number { return this.getProperty('IntValue'); }
    public set IntValue(value: number) { this.setProperty('IntValue', value); }
    
    @Expose()
    public get AuswahlItems(): string { return this.getProperty('AuswahlItems'); }
    public set AuswahlItems(value: string) { this.setProperty('AuswahlItems', value); }
    
    @Expose()
    public get vonCustomCollection_ID(): string { return this.getModelId('vonCustomCollection_ID'); }
    public set vonCustomCollection_ID(value: string) { this.setModelId('vonCustomCollection_ID', value); }
 
    // @Expose()
    // @Type(() => CustomPropCollection)
    // public get vonCustomCollection(): CustomPropCollection { return this.getModel('vonCustomCollection'); }
    // public set vonCustomCollection(value: CustomPropCollection) { this.setModel('vonCustomCollection', value); }
    
    @Expose()
    public get vonCAFCustomPropDef_ID(): string { return this.getModelId('vonCAFCustomPropDef_ID'); }
    public set vonCAFCustomPropDef_ID(value: string) { this.setModelId('vonCAFCustomPropDef_ID', value); }
 
    @Expose()
    @Type(() => CustomPropDef)
    public get vonCAFCustomPropDef(): CustomPropDef { return this.getModel('vonCAFCustomPropDef'); }
    public set vonCAFCustomPropDef(value: CustomPropDef) { this.setModel('vonCAFCustomPropDef', value); }
    
    @Expose()
    public get ListDetailValue_ID(): string { return this.getModelId('ListDetailValue_ID'); }
    public set ListDetailValue_ID(value: string) { this.setModelId('ListDetailValue_ID', value); }
 
    // @Expose()
    // @Type(() => ListDetail)
    // public get ListDetailValue(): ListDetail { return this.getModel('ListDetailValue'); }
    // public set ListDetailValue(value: ListDetail) { this.setModel('ListDetailValue', value); }
    
    @Expose()
    @Type(() => EncryptedType)
    public get TextValueEncrypted(): EncryptedType { return this.getProperty('TextValueEncrypted'); }
    public set TextValueEncrypted(value: EncryptedType) { this.setProperty('TextValueEncrypted', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get RefValue(): GenericRef { return this.getProperty('RefValue'); }
    public set RefValue(value: GenericRef) { this.setProperty('RefValue', value); } 
    
    // transient       
    @Expose()
    public get ListName(): string { return this.getProperty('ListName'); }
    public set ListName(value: string) { this.setProperty('ListName', value); }

    @Expose()
    public get ModelName(): string { return this.getProperty('ModelName'); }
    public set ModelName(value: string) { this.setProperty('ModelName', value); }
        
    @Expose()
    @Type(() => ListType)
    public get InternalListType(): ListType { return this.getProperty('InternalListType'); }
    public set InternalListType(value: ListType) { this.setProperty('InternalListType', value); }    
}
