import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviListDetailConst, AviPartnerListConst, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DecisionTableEntryDto } from '@avi-x/avi-dto/system/decisiontableentrydto.model';

const nameof = (nameFunction: ((obj: DecisionTableEntryDto) => any)) => typed_nameof<DecisionTableEntryDto>(nameFunction);

@Component({
    selector: 'avi-decisiontableentry-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDecisionTableEntryFormComponent extends AviAbstractModelFormComponent<DecisionTableEntryDto> {  
    ClassName = 'CORE.COMMON.MODEL.CAFDECISIONTABLEENTRY';
    BaseUrl = 'decisiontable/entry';
    ClassType = DecisionTableEntryDto;
	FormType = AviDecisionTableEntryFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFDecisionTable';
    }
	
    initFields() {
        this.Fields = [];
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false), false));

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sequenz), 'CORE.COMMON.MODEL.CAFDECISIONTABLEENTRY.SEQUENZ', 0, true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonDecisionTable_ID), 'CORE.COMMON.MODEL.CAFDECISIONTABLEENTRY.VONDECISIONTABLE', `model/representations/CAFDecisionTable${this.ReadOnly ? '?id=$id' : ''}`, true, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setReadonly().setMDSizeHalf());

        if(this.Model)
        {
            this.Fields.push(this.formFieldService.CreateGroup('Input', 'Input', false));
            for (const param of this.Model.InputDecisionTableParam){
                const index = this.Model.InputDecisionTableParam.findIndex(m => m.vonDecisionTableParamDef_ID === param.vonDecisionTableParamDef_ID)
                if(param.AttributeType.Id == AviListDetailConst.ATTRTYPE_TEXT)
                    this.Fields.push(this.formFieldService.CreateText(`InputDecisionTableParam[${index}].TextValue`, param.vonDecisionTableParamDef, false).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_DATE)
                    this.Fields.push(this.formFieldService.CreateDate(`InputDecisionTableParam[${index}].DateValue`, param.vonDecisionTableParamDef, false).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_DECIMAL)
                    this.Fields.push(this.formFieldService.CreateNumber(`InputDecisionTableParam[${index}].DecimalValue`, param.vonDecisionTableParamDef, 4, false).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_INTEGER)
                    this.Fields.push(this.formFieldService.CreateNumber(`InputDecisionTableParam[${index}].IntValue`, param.vonDecisionTableParamDef, 0, false).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_LISTTYPE)
                    this.Fields.push(this.formFieldService.CreateListType(`InputDecisionTableParam[${index}].ListDetailValue`, param.vonDecisionTableParamDef, param.Listtype, true, false, this.ReadOnly).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_REFERENCE)
                {
                    this.Fields.push(this.formFieldService.CreateDropdown(`InputDecisionTableParam[${index}].RefValue_ID`, param.vonDecisionTableParamDef, `model/representations/${param.RefModel}${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull());
                }
                    


            }
    
            this.Fields.push(this.formFieldService.CreateGroup('Output', 'Output', false));
            for (const param of this.Model.OutputDecisionTableParam){
                const index = this.Model.OutputDecisionTableParam.findIndex(m => m.vonDecisionTableParamDef_ID === param.vonDecisionTableParamDef_ID)
                if(param.AttributeType.Id == AviListDetailConst.ATTRTYPE_TEXT)
                    this.Fields.push(this.formFieldService.CreateText(`OutputDecisionTableParam[${index}].TextValue`, param.vonDecisionTableParamDef, false).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_DATE)
                    this.Fields.push(this.formFieldService.CreateDate(`OutputDecisionTableParam[${index}].DateValue`, param.vonDecisionTableParamDef, false).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_DECIMAL)
                    this.Fields.push(this.formFieldService.CreateNumber(`OutputDecisionTableParam[${index}].DecimalValue`, param.vonDecisionTableParamDef, 4, false).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_INTEGER)
                    this.Fields.push(this.formFieldService.CreateNumber(`OutputDecisionTableParam[${index}].IntValue`, param.vonDecisionTableParamDef, 0, false).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_LISTTYPE)
                    this.Fields.push(this.formFieldService.CreateListType(`OutputDecisionTableParam[${index}].ListDetailValue`, param.vonDecisionTableParamDef, param.Listtype, true, false, this.ReadOnly).setMDSizeHalf());
                else if (param.AttributeType.Id == AviListDetailConst.ATTRTYPE_REFERENCE)
                {
                    this.Fields.push(this.formFieldService.CreateDropdown(`OutputDecisionTableParam[${index}].RefValue_ID`, param.vonDecisionTableParamDef, `model/representations/${param.RefModel}${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull());
                }
            }
        }
    }

    public async afterInitForm(){
        this.initFields();
        this.cdr.markForCheck();
    }

    public async createModel(): Promise<DecisionTableEntryDto> {
        const res = await this.apiService.getModel(this.ClassType, `${this.getBaseUrl()}/create/${this.ContextId}`);
        await this.afterCreateModel(res);
        return res;
    }

    saveModel(model: DecisionTableEntryDto) {      
        console.log(model) 
        this.saveDto(model.Id, model);
    }
}
// for (const satz of this.Abrechnung.Faktoren.filter(w => w.vonFaktorDef_ID === faktorDef.Id)) {
//     const index = this.Abrechnung.Faktoren.findIndex(w => w.Id === satz.Id);
//     newRows = newRows.concat(this.AddRow(count === 0 ? this.getFaktorDefBezeichnung(faktorDef) : null, faktorDef.Ziffer, faktorDef.ZifferEinblenden.Id === AviListDetailConst.JA && count === 0,
//         { name: `Faktoren[${index}].Aufwand`, label: null, valueMin: faktorDef.ValueMin, valueMax: faktorDef.ValueMax, precisionMin: faktorDef.PrecisionMin, precisionMax: faktorDef.PrecisionMax },
//         { name: `Faktoren[${index}].Betrag`, label: '+', readonly: true, valueMin: faktorDef.ValueMin, valueMax: faktorDef.ValueMax, precisionMin: faktorDef.PrecisionMin, precisionMax: faktorDef.PrecisionMax },
//         faktorDef.SteuersatzTyp.Id === EMWListDetailConst.EMW_FORMULAR_STEUERSATZTYP_BEZUGSTEUER ? null : { name: `Faktoren[${index}].Satz`, label: null, readonly: true },
//         faktorDef.Typ.Id));


//     // add hinweis
//     if (!this.ReadOnly && faktorDef.Hinweis) {
//         const labelField = this.Fields.find(w => w.Name === `${faktorDef.Ziffer}_L1`);
//         labelField.Hinweis = faktorDef.Hinweis;
//         labelField.HinweisClass = 'abrechnung-hinweis';
//     }

//     if (count == 0)
//         await this._addSubformularEditButton(faktorDef, newRows);

//     ++count;
// }






















// import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
// import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
// import { NgxPermissionsService } from 'ngx-permissions';
// import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
// import { DecisionTableEntryDto } from '@avi-x/avi-dto/system/decisiontableentrydto.model';
// import { DecisionTableParamDto } from '@avi-x/avi-dto/system/decisiontableparamdto.model';

// // import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
// // import { AviCommonService } from '@avi-x/avi-core';
// // import { EMWAbrechnung, EMWPartnerHeaderDto, EMWSTAbrechnungCommonService, EMWSTGrunddatenCommonService } from '@avi-x/emwst-common';
// // import { TranslateService } from '@ngx-translate/core';

// const nameof = (nameFunction: ((obj: DecisionTableEntryDto) => any)) => typed_nameof<DecisionTableEntryDto>(nameFunction);

// // @Component({
// //     selector: 'emwst-abrechnung-konsolidiert-summary-form',
// //     styleUrls: ['./abrechnung-konsolidiert-summary-form.component.scss'],
// //     templateUrl: './abrechnung-konsolidiert-summary-form.component.html',
// //     changeDetection: ChangeDetectionStrategy.OnPush
// // })
// // export class EMWSTAbrechnungKonsolidiertSummaryFormComponent implements OnChanges {

// @Component({
//     selector: 'avi-decisiontableentry-form',
//     template: `
//     <avi-core-base-form #form
//         [readonly]="ReadOnly"
//         [card]="Card"
// 		[contents-padding]="ContentsPadding"
//         [fields]="Fields"
//         [form-title]="FormTitle"
//         [(Model)]="Model"
//         [loading]="Loading"
//         (onSave)="saveModel($event)"
//         (onAttrChange)="onAttrChange($event)"
// 		[taskmenu-items]="TaskMenuItems"
//         [action-buttons]="actionButtons"
// 		[action-buttons-readonly]="actionButtonsReadonly">

//     </avi-core-base-form>`,
//     // templateUrl: './avi-decisiontableentry-form.component.html',
//     changeDetection: ChangeDetectionStrategy.OnPush
// })
// export class AviDecisionTableEntryFormComponent implements OnInit {  
//     ClassName = 'CORE.COMMON.MODEL.CAFDECISIONTABLEENTRY';
//     BaseUrl = 'decisiontable/entry';
//     ClassType = DecisionTableEntryDto;
// 	FormType = AviDecisionTableEntryFormComponent;
//     public get Title(){
//         return "Entscheidungstablleneintrag " + this._IsNewEntity ? "erfassen" : "bearbeiten";
//     }

//     @Input('entryId')
//     public set EntryId(value: string){
//         this.apiService.get(`${this.BaseUrl}/${value}`)
//             .then(x => {
//                 this.DecisionTableEntry = x;
//                 this._IsNewEntity = false;
//                 this.cdr.markForCheck();
//             });
//     }

//     @Input('tableId')
//     public set TableId(value: string){
//         this.apiService.get(`${this.BaseUrl}/create/${value}`)
//             .then(x => {
//                 this.DecisionTableEntry = x;
//                 this._IsNewEntity = true;
//                 this.cdr.markForCheck();
//             });
//     }

//     _IsNewEntity: boolean = false;
//     _DecisionTableEntry: DecisionTableEntryDto = null;
//     public get DecisionTableEntry(): DecisionTableEntryDto { 
//         return this._DecisionTableEntry; 
//     }

    
//     public set DecisionTableEntry(value: DecisionTableEntryDto) {
//         this._DecisionTableEntry = value;
//     }


// //     constructor(
// //         public commonService: AviCommonService,
// //         public abrechnungService: EMWSTAbrechnungCommonService,
// //         public translateService: TranslateService,
// //         private grunddatenService: EMWSTGrunddatenCommonService,
// //         public cdr: ChangeDetectorRef
// //     ) { 
// //     }

//     constructor(public commonService: AviCommonService,
//         public apiService: AviApiService,
// 		public ref: DynamicDialogRef,
//         public config: DynamicDialogConfig,
//         public formFieldService: AviFormFieldService,
// 		public permissionsService: NgxPermissionsService,
//         public sessionController: AviSessionControllerService,
// 		public cdr: ChangeDetectorRef) {

//         // super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

// 		//this.CRUDBaseRight = 'CAFDecisionTableDef';
//     }

//     ngOnInit(): void {
        
//     }
	
//     // initFields() {
// 	// 	if (this.ReadOnly)
// 	// 		this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

//     //     this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sequenz), 'CORE.COMMON.MODEL.CAFDECISIONTABLEENTRY.SEQUENZ', 0, true).setMDSizeHalf());

//     //     console.log("initFields on DecTabel")
        
//     // }
// }









//     Partner: EMWPartnerHeaderDto = null;

//     _Abrechnung: EMWAbrechnung = null;
//     public get Abrechnung(): EMWAbrechnung { 
//         return this._Abrechnung; 
//     }

//     @Input('abrechnung')
//     public set Abrechnung(value: EMWAbrechnung) {
//         this._Abrechnung = value;
//         // this.InitForm(value);
//     }
    
//     @Output('onChange')
//     public onChange: EventEmitter<any> = new EventEmitter<any>();

//     constructor(
//         public commonService: AviCommonService,
//         public abrechnungService: EMWSTAbrechnungCommonService,
//         public translateService: TranslateService,
//         private grunddatenService: EMWSTGrunddatenCommonService,
//         public cdr: ChangeDetectorRef
//     ) { 
//     }
    
//     ngOnChanges(changes: SimpleChanges) {
//         if (this.Abrechnung)
//             this.InitForm(this.Abrechnung);
//     }
    
//     async InitForm(abrechnung: EMWAbrechnung) {
//         if (abrechnung) {
//             this.Partner = await this.grunddatenService.loadPartnerHeaderDto(abrechnung.vonPerson_ID, new Date());
//         }

//         this.cdr.markForCheck();
//     }
// }



