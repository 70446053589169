import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'avi-crm-jurperson-view',
    templateUrl: './jurperson-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AviJurPersonViewComponent implements OnInit {

    @Input('partner-id')
    FormModelId: string = null;

    constructor(private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        // this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
        //     this.FormModelId = res;
        // });
    }
}
