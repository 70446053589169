import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'vwbp-admin-joblogging',
    template: `
        <avi-joblogging-searcherwithdetail
            [context-id]="ModelId"
            [enable-erledigen]="false">
        </avi-joblogging-searcherwithdetail>`
})
export class VwbPAdminJobLoggingComponent implements OnInit, OnDestroy {
    
    sub: Subscription;

    ModelId: string = null;

    constructor(public activatedRoute: ActivatedRoute
    ) {

    }

    ngOnInit() {
        this.sub = this.activatedRoute.data.subscribe(params => {
            if (params && params['id']) {
                this.setModel(params['id']);
            }
        });
        
    }

    ngOnDestroy(): void {
        if (this.sub)
            this.sub.unsubscribe();
    }

    setModel(modelId: string) {
        this.ModelId = modelId || '0';
    }
}