import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';

export class Provinz extends BaseModel {  
    @Expose()
    public get Bezeichnung(): string { return this.getProperty('Bezeichnung'); }
    public set Bezeichnung(value: string) { this.setProperty('Bezeichnung', value); }
    
    @Expose()
    public get KurzBez(): string { return this.getProperty('KurzBez'); }
    public set KurzBez(value: string) { this.setProperty('KurzBez', value); }
    
    @Expose()
    public get Identifikation(): string { return this.getProperty('Identifikation'); }
    public set Identifikation(value: string) { this.setProperty('Identifikation', value); }
    
    @Expose()
    public get vonLand_ID(): string { return this.getModelId('vonLand_ID'); }
    public set vonLand_ID(value: string) { this.setModelId('vonLand_ID', value); }
 
}
