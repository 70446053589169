<vwbp-intern-rechtstraeger-versionen-searcher
    #searcher
    settingskey="settings.searcher.ueb.freigegeben_uebernahme"
    [last-version]="true"
    [use-router-params]="true"
    [get-filters]="GetFilters"
    [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'AbgegebenAm', 'btnOpen']"
>
</vwbp-intern-rechtstraeger-versionen-searcher>

<div *ngIf="vwbpCommonService.currentUserIsAjuUserSchreiben()" fxLayout="row">
    <div class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="start left" fxFlex="30" fxLayoutGap="1em">
        <!-- <label>{{ 'CORE.COMMON.USER' | translate }}</label>
        <p-autoComplete
            styleClass="w-100p"
            [minLength]="3"
            [autoHighlight]="true"
            [dropdown]="true"
            inputStyleClass="w-100p"
            [(ngModel)]="SelectedUser"
            field="DisplayName"
            [suggestions]="UserSuggestions"
            (completeMethod)="searchUser($event)"
            [forceSelection]="true"
        ></p-autoComplete> -->

        <span class="p-float-label">
            <textarea
                [rows]="8" id="comment" class="w-100p"
                pInputTextarea
                [(ngModel)]="Bemerkung"
                [autoResize]="true"
                name="inform"
            ></textarea>
            <label for="comment">{{ 'VWBP.COMMON.COMMENT' | translate }}*</label>
        </span>

        <!-- <button
            pButton
            type="button"
            label="{{ 'VWBP.INTERN.UEBERGABE_MANAGEMENT.ANFRAGE_STARTEN_BUTTON' | translate: ButtonParams }}"
            [disabled]="!CanStartRechtstraegerAnfragen()"
            icon="pi pi-user-plus"
            (click)="StartRechtstraegerAnfragen()"
            class="mb-2"
        ></button> -->

        <button
            pButton
            type="button"
            label="{{ 'VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_INAKTIVIEREN_BUTTON' | translate: ButtonParams }}"
            [disabled]="!CanStartRechtstraegerInaktivieren()"
            icon="pi pi-exclamation-triangle"
            (click)="StartRechtstraegerInaktivieren()"
            class="mb-2 p-button-secondary p-button-outlined"
        ></button>
    </div>
</div>
