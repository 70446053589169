<div *ngIf="!showSuccessMessage; else successMessage ">

    <avi-core-base-form #form
        [readonly]="false"
        [card]="Card"
        [contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        [loading]="Loading"
        [save-button-icon]="null"
        [save-button-label]="'VWBP.PUBLIC.RT_LOESCHEN_BUTTON'"
        (onSave)="saveModel($event)"
        [action-buttons]="actionButtons">

        <div header>
            <avi-textblock [identifier]="'vwbp-rechtstraeger-loeschen-header'"
                class="mb-2"></avi-textblock>
        </div>

        <div beforeButtons>
            <div class="pl-8">
                {{'VWBP.COMMON.RT' | translate}}: <strong>{{Model?.Name}}</strong>
            </div>
        </div>

    </avi-core-base-form>
</div>

<ng-template #successMessage>
    <avi-core-page [card]="Card"
        [no-box-shadow]="true"
        [title]="FormTitle"
        [contents-padding]="ContentsPadding">
        <div class="success-message-box p-12">
            <avi-textblock [identifier]="'vwbp-rechtstraeger-loeschen-erfolgreich'"
                class="mb-2"></avi-textblock>
        </div>

        <div class="mt-2">
            <button pButton
                (click)="onBtnClosePress()"
                label="Schliessen"></button>
        </div>

    </avi-core-page>
</ng-template>
