import { Expose, Type } from 'class-transformer';

import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { AppUser } from '@avi-x/avi-dto/system/appuser.model';

export class VwbPUserProfile extends BaseModel {

    @Expose()
    public get vonAppUser_ID(): string { return this.getModelId('vonAppUser_ID'); }
    public set vonAppUser_ID(value: string) { this.setModelId('vonAppUser_ID', value); }

    @Expose()
    @Type(() => AppUser)
    public get vonAppUser(): AppUser { return this.getModel('vonAppUser'); }
    public set vonAppUser(value: AppUser) { this.setModel('vonAppUser', value); }

    @Expose()
    public get Name(): string { return this.getProperty('Name'); }
    public set Name(value: string) { this.setProperty('Name', value); }

    @Expose()
    public get Strasse(): string { return this.getProperty('Strasse'); }
    public set Strasse(value: string) { this.setProperty('Strasse', value); }

    @Expose()
    public get PLZ(): string { return this.getProperty('PLZ'); }
    public set PLZ(value: string) { this.setProperty('PLZ', value); }

    @Expose()
    public get Ort(): string { return this.getProperty('Ort'); }
    public set Ort(value: string) { this.setProperty('Ort', value); }

    @Expose()
    public get KontaktName(): string { return this.getProperty('KontaktName'); }
    public set KontaktName(value: string) { this.setProperty('KontaktName', value); }

    @Expose()
    public get KontaktVorname(): string { return this.getProperty('KontaktVorname'); }
    public set KontaktVorname(value: string) { this.setProperty('KontaktVorname', value); }

    @Expose()
    public get KontaktTelefon(): string { return this.getProperty('KontaktTelefon'); }
    public set KontaktTelefon(value: string) { this.setProperty('KontaktTelefon', value); }

    @Expose()
    public get KontaktEmail(): string { return this.getProperty('KontaktEmail'); }
    public set KontaktEmail(value: string) { this.setProperty('KontaktEmail', value); }

    // Transients
    @Expose()
    public get KontoName(): string { return this.getProperty('KontoName'); }
    public set KontoName(value: string) { this.setProperty('KontoName', value); }

    @Expose()
    public get KontoEmail(): string { return this.getProperty('KontoEmail'); }
    public set KontoEmail(value: string) { this.setProperty('KontoEmail', value); }

    @Expose()
    public get KeycloakId(): string { return this.getProperty('KeycloakId'); }
    public set KeycloakId(value: string) { this.setProperty('KeycloakId', value); }
}
