import { AviHistoryService } from './../../../services/history.service';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ZustandInfoDto } from '@avi-x/avi-dto/shared/zustandinfo.model';
import { AviCommonService } from '../../../services/common.service';
import { AviTypeUtil } from '../../../shared/constants/constants';

@Component({
    selector: 'avi-core-history-control',
    templateUrl: './historycontrol.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviHistoryControlComponent {

    LowDate = AviTypeUtil.SqlLowDate.toDate();
    HighDate = AviTypeUtil.HighDate.toDate();

    public DateFormat = 'dd.mm.yy';

    public CurrentHistory: ZustandInfoDto;

    private _HistoryStates: ZustandInfoDto[];
    public get HistoryStates(): ZustandInfoDto[] { return this._HistoryStates; }

    @Input('history-states')
    public set HistoryStates(value: ZustandInfoDto[]) {
        this.Stichtag = new Date();
        this._HistoryStates = value;
        this.updateCurrentHistory();
    }

    public Stichtag: Date = new Date();

    @Output('stichtagChange')
    public StichtagChange: EventEmitter<Date> = new EventEmitter();

    constructor(public commonService: AviCommonService, public historyService: AviHistoryService) {
    }

    private updateCurrentHistory() {
        this.CurrentHistory = this.historyService.getCurrentHistory(this._HistoryStates, this.Stichtag);
    }

    async setStichtag(stichtag: Date) {
        this.Stichtag = stichtag;
        this.StichtagChange.emit(this.Stichtag);
        this.updateCurrentHistory();
    }

    async SelectPrevHist() {

        const prev = this.historyService.GetPreviousHistoryState(this._HistoryStates, this.CurrentHistory);
        if (prev)
            this.setStichtag(prev.ZustandAb);
    }

    async SelectNextHist() {
        const next = this.historyService.GetNextHistoryState(this._HistoryStates, this.CurrentHistory);
        if (next)
            this.setStichtag(next.ZustandAb);
    }

    async SelectLastHist() {
        const next = this.historyService.GetLastHistoryState(this._HistoryStates);
        if (next)
            this.setStichtag(next.ZustandAb);
    }
}
