import { Component, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';

export enum AviCommentMode {
    Readonly, Create, Edit
}

@Component({
    selector: 'avi-crm-comment-list',
    templateUrl: './comment-list.component.html',
    styleUrls: ['./comment-list.component.scss'],
    providers: [DecimalPipe, PercentPipe]
})
export class AviCommentListComponent {
    CommentIds: any[] = [];

    NewCommentId: string = null;
    EditCommentId: string = null;

    _ContextId: string = null;
    public get ContextId(): string { return this._ContextId; }

    @Input('context-id')
    public set ContextId(value: string) {
        this._ContextId = value;
        this.LoadComments();
    }

    CanEdit: boolean = true;
    _ContextType: string = null;
    public get ContextType(): string { return this._ContextType; }

    @Input('context-type')
    public set ContextType(value: string) {
        this._ContextType = value;

        if (this._ContextType)
            this.permissionsService.hasPermission(`CAFComment ${this._ContextType.split('.').pop()} schreiben`).then(w => this.CanEdit = w);

        this.LoadComments();
    }

    @Input('comments-collapsible')
    public CommentsCollapsible: boolean = true;

    @Input('enable-erledigen')
    public EnableErledigen: boolean = true;

    @Input('enable-erfassen')
    public EnableErfassen: boolean = true;

    @Input('enable-intern')
    public EnableIntern: boolean = true;

    @Input('label-newcomment-header')
    public LabelNewCommentHeader = 'Neue Bemerkung';

    @Input('label-newcomment-button')
    public LabelNewCommentButton = 'Bemerkung erfassen';

    @Input('show-statusubergang')
    public ShowStatusuebergang: boolean = true;

    @Input('rtf')
    IsRTF: boolean = true;

    @Output()
    public onChanged: EventEmitter<any> = new EventEmitter();

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, public permissionsService: NgxPermissionsService, private cdr: ChangeDetectorRef) {
    }

    public forceRefresh() {
        this.LoadComments();
    }

    async LoadComments() {
        if (this.ContextId && this.ContextType) {
            this.CommentIds = await this.apiService.get(`comment/idlist/${this.ContextId}/${this.ContextType}`);
            this.cdr.markForCheck();
        }
    }

    OnDeleteComment(id: string) {
        const index = this.CommentIds.indexOf(id, 0);
        if (index > -1) {
            this.CommentIds.splice(index, 1);
            this.onChanged.emit();
        }
    }

    OnEditComment(id: string) {
        this.EditCommentId = id;
    }

    OnEditCommentCancelled(id: string) {
        this.EditCommentId = null;
    }

    OnEditCommentSaved(id: string) {
        this.EditCommentId = null;
        this.onChanged.emit();
    }

    OnCreateCommentCancelled() {
        this.NewCommentId = null;
    }

    OnCreateCommentSaved() {
        this.NewCommentId = null;
        this.LoadComments();
        this.onChanged.emit();
    }

    CreateComment() {
        this.NewCommentId = this.commonService.GuidEmpty;
    }
}
