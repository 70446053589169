import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviJobDefFormComponent } from './jobdef-form.component';
import { JobDefSearchResponseDto } from '@avi-x/avi-dto/system/jobdefsearchresponsedto.model';
import { typed_nameof } from '../utils/nameof';
import { AviAbstractModelSearcherComponent } from '../base-searcher/abstract-model-searcher.component';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviSessionControllerService } from '../../services/session-controller.service';
import { AviBaseSearcherComponent } from '../base-searcher/base-searcher.component';
import { TranslateService } from '@ngx-translate/core';
import { AviMenuItem, AviMenuService } from '../../services/menu.service'
import { MenuItem } from 'primeng/api';
import { AviRoleConst } from '../../shared/constants/constants';
import { AviJobService } from '../../services/job.service';
import { ActivatedRoute } from '@angular/router';

const nameof = (nameFunction: ((obj: JobDefSearchResponseDto) => any)) => typed_nameof<JobDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-jobdef-searcher',
    templateUrl: './jobdef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJobDefSearcherComponent extends AviAbstractModelSearcherComponent {
    ClassName = 'CORE.COMMON.MODEL.JOBDEF';
    SettingsKey = 'settings.searcher.jobdef';
    BaseUrl = 'job/def';
    RouterPrefix = '';
	FormType = AviJobDefFormComponent;

    CanEdit: boolean = false;
    ContextMenuItems: AviMenuItem[] = [];
    ApiPrefix: string = null;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public menuService: AviMenuService,
        private translateService: TranslateService,
        private jobService: AviJobService,
        public cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute
    ) {
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission(['JobDef schreiben', 'JobDef editieren']).then(w => this.CanEdit = w);
    }

    async ngOnInit(): Promise<void> {

        await super.ngOnInit();
        await this.menuService.addIfAndPermission(this.ContextMenuItems, 'Job starten', 'pi pi-play', () => this.doJobStart(), [AviRoleConst.JOB_STARTEN]);
        this.menuService.addSeparator(this.ContextMenuItems);

        if ('BaseUrl' in this.activatedRoute.snapshot.data)
            this.BaseUrl = this.activatedRoute.snapshot.data['BaseUrl'];

        if ('ApiPrefix' in this.activatedRoute.snapshot.data)
            this.ApiPrefix = this.activatedRoute.snapshot.data['ApiPrefix'];

        if ('UseRouting' in this.activatedRoute.snapshot.data)
            this.jobService.UseRouting = this.activatedRoute.snapshot.data['UseRouting'];

        if ('RouterPrefix' in this.activatedRoute.snapshot.data)
            this.jobService.RouterPrefix = this.activatedRoute.snapshot.data['RouterPrefix'];
    }

    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            //this.sessionController.openSession('jobdef-index', this.SelectedRows.Id);
            // this.editObject(this.SelectedRows);
            this.jobService.openJobDef(this.SelectedRows.Id);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.KurzBez), 'CORE.COMMON.MODEL.JOBDEF.KURZBEZ1', true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.JOBDEF.BEZEICHNUNG1', true);
        searcher.addListTypeColumn(nameof(c => c.JobType), 'CORE.COMMON.MODEL.JOBDEF.JOBTYPE', 'jobdef_jobtype', true, true, null, this.ApiPrefix);
        searcher.addTextColumn(nameof(c => c.Beschreibung), 'CORE.COMMON.MODEL.JOBDEF.BESCHREIBUNG', true);
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(JobDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }

    async doJobStart() {
        if (this.SelectedRows)
            await this.jobService.startJob(this.SelectedRows.Id, this.translateService.instant('CORE.COMMON.JOB_AUSFUEHREN_TITLE', { name: this.SelectedRows.Bezeichnung }), null);
    }

    getContextMenuItems = (data, menuItems: MenuItem[]): MenuItem[] => {
        const m = [...this.ContextMenuItems, ...menuItems.filter(i => (i.styleClass || '').split(' ').includes('crud-menu-item'))];
        return m;
    }
}
