// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
    appname: 'VwbP Aviita',
    saveLoginUserInSessionStorage: true,
    oauth2: {
        public: {
            issuer: 'https://vwbp.aviita.li/auth/realms/vwbp',
            redirectUri: window.location.origin + '/#/vwbp/login',
            clientId: 'vwbp-public',
            scope: 'openid profile email',
            responseType: 'code',
            requireHttps: false,
            showDebugInformation: true,
            disableAtHashCheck: true,
            storage: 'sessionStorage'
        },
        internal: {
            issuer: 'https://vwbp.aviita.li/auth/realms/vwbp-internal',
            redirectUri: window.location.origin + '/#/vwbp/login',
            clientId: 'vwbp-internal',
            scope: 'openid profile email',
            responseType: 'code',
            requireHttps: false,
            showDebugInformation: true,
            disableAtHashCheck: true,
            storage: 'sessionStorage'
        }

    }
};
