import { AviApiService, AviBaseSearcherComponent, AviSearcherColumn, AviCommonService } from '@avi-x/avi-core';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, Input, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FileSaverService } from 'ngx-filesaver';

@Component({
    template: `
        <avi-core-page title="" [card]="false" [collapsible]="false" [expanded]="true" [contents-padding]="true">

            <avi-core-base-searcher #searcher

                    [use-router-params]="false"
                    [card]="false"
                    [default-sort-data]="[ { field: 'Dateiversion', order: -1 } ]"
                    [contents-padding]="true"
                    [search-delegate]="SearchDelegate"
                    [rows]="20"
                    [toolbar]="false"
                    [auto-search]="true"
                    [show-autofilter]="true"
                    [select-first-result]="false"
                    [metakeyselection]="false"
                    [selectionMode]="null"
                    searcher-title=""
                    (onInit)="onSearcherInit($event)"
            >
            </avi-core-base-searcher>

            <div class="grid m-0 justify-content-end">
                    <button
                        pButton
                        type="button"
                        icon="pi pi-check-circle"
                        label="Schliessen"
                        (click)="Close()"
                        class="w-100p-m m ml-2 p-button-secondary"
                    ></button>
            </div>

        </avi-core-page>`,
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class AviDokumentVersionInfoComponent implements OnInit {


    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    baseUrl: string;
    selectedDocument: any;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, public cdr: ChangeDetectorRef, public apiService: AviApiService, public commonService: AviCommonService, private fileSaverService: FileSaverService) { }

    async ngOnInit() {
        this.selectedDocument = this.config.data.selectedDocument;
        this.baseUrl = this.config.data.baseUrl ?? '/avidoc';
    }

    onSearcherInit(data) {
        this.searcher.beginInit();
        this.searcher.addIntegerColumn('Dateiversion', 'Dateiversion').setSortable(false);
        this.searcher.addTextColumn('Name', 'Name').setSortable(false);
        this.searcher.addTextColumn('Dateiendung', 'Dateiendung').setSortable(false);
        this.searcher.addListTypeColumn('Status', 'Status', 'avidoc_dokumentstatus').setSortable(false);
        this.searcher.addDateColumn('Created', 'Datum').setSortable(false);
        this.searcher.addTextColumn('CreatedUser', 'Ersteller').setSortable(false);

        this.searcher.addColumn(
            AviSearcherColumn.CreateButton('btnAct', ' ', null, null, null, null, '40px', ['download'])
                .setButtonOnClickDelegate((row, col, buttonId) => {
                    this.downloadDocument(row);
                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-flat': true,
                    };
                })
                .setButtonIconDelegate((row, col, buttonId) => {
                    return 'pi-dark pi pi-download';
                })
                .setCellStyleDelegate(() => { return { 'text-align': 'right' }; })
                .setSortable(false)
        );

        this.searcher.endInit();
    }

    async downloadDocument(row) {
        if (row) {
            this.commonService.showGlobalLoader();
            const blob = await this.apiService.getBlob(`${this.baseUrl}/Download/${row.vonDocument.vonArchivDef_ID}/${row.ArchivDocumentID}?contents_link=${encodeURIComponent(row.ContentsLink)}`);
            if (blob) {
                this.fileSaverService.save(blob, row.Name + row.Type);
            }
            this.commonService.hideGlobalLoader();
        }
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        var res = await this.apiService.post(`${this.baseUrl}/GetDocumentVersionInfo`, this.selectedDocument.FileMeta);
        return res;
    }

    Close() {
        this.ref.close(false);
    }
}
