import { Expose, Type } from "class-transformer";
import { IDTO, ListType } from "../shared";


export class BusinesscaseDataTypeDto extends IDTO {
    @Expose()
    @Type(() => ListType)
    public DataType: ListType;

    @Expose()
    @Type(() => BusinesscaseStatusDto)
    public Status: BusinesscaseStatusDto[];
}

export class BusinesscaseStatusDto extends IDTO {
    @Expose()
    public Id: string;

    @Expose()
    public ErstellDatum: Date;

    @Expose()
    public Bezeichnung: string;

    @Expose()
    public Readonly: boolean;

    @Expose()
    @Type(() => BusinesscaseDataDto)
    public Data: BusinesscaseDataDto[];
}

export class BusinesscaseDataDto extends IDTO {
    @Expose()
    public Id: string;

    @Expose()
    public Internal: boolean;

    @Expose()
    public AlwaysInternal: boolean;

    @Expose()
    public ShowBemerkung: boolean;

    @Expose()
    @Type(() => ListType)
    public Context: ListType;

    @Expose()
    public Bezeichnung: string;

    @Expose()
    public DataDefId: string;
    
    @Expose()
    public FormDef_ID: string;

    @Expose()
    public CustomFormId: string;

    @Expose()
    public DocArt_ID: string;

    @Expose()
    public CanCorrect: boolean;

    @Expose()
    @Type(() => BusinesscaseDataDetailDto)
    public Details: BusinesscaseDataDetailDto[];
}


export class BusinesscaseDataDetailDto extends IDTO {
    @Expose()
    public Id: string;

    @Expose()
    public Titel: string;

    @Expose()
    public DocId: string;

    @Expose()
    public DocArchiv: string;

    @Expose()
    public Activity_ID: string;

    @Expose()
    public Internal: boolean;

    @Expose()
    public Versionsnummer: number;

    @Expose()
    public Draft: boolean;
    
    @Expose()
    public CreatedByName: string;

    @Expose()
    @Type(() => Date)
    public CreatedDate: Date;

    @Expose()
    public Bemerkung: string;
}
