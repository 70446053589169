import { VwbPInternRechtstraegerBaseComponent } from './../../../rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-searcher-base.component';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

import { AviCommonService, AviApiService, AviJobService, AviFormField, AviFormFieldType } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';
import { RTVerMode } from '../../../rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';

@Component({
    selector: 'vwbp-intern-schwaerzen-antrag-searcher',
    templateUrl: './schwaerzen-antrag-searcher.component.html'
})
export class VwbPInternSchwaerzenAntragSearcherComponent extends VwbPInternRechtstraegerBaseComponent {
    toolbarButtons : any[] = [
        { title: 'Excel Export', class: 'p-button-secondary', icon: 'pi pi-download', action: () => this.OnExportClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0, visible: () => this.CheckboxSelection, },
        { title: 'Serienbrief', class: 'p-button-secondary', icon: 'pi pi-envelope', action: () => this.OnSerienbriefClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0, visible: () => this.CheckboxSelection }
    ];

    constructor(
        public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        public vwbpCommonService: VwbPCommonService,
        public cdr: ChangeDetectorRef,
        private postfachService: VwbPInternPostfachService,
        private rechtstraegerService: VwbPInternRechtstraegerService,
        private jobService: AviJobService
    ) {
        super(apiService, router, commonService, vwbpCommonService, cdr);

        this.OpenMode = 'schwaerzen';
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any = null) => this.doSearch(searchValue, '/v1/intern/vwbp/schwaerzen/antraege', searchConfig);

    public onDoubleClick(data) {
        if (this.canOpenVersion(data))
            this.openRechtstraeger(data.RechtstraegerId);
    }

    async OnExportClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.RechtstraegerId);
        this.rechtstraegerService.OpenExcelExportJobDialog(rechtstraegerIds);
    }

    async OnSerienbriefClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.AntragId);
        this.rechtstraegerService.OpenSerienbriefJobDialog(rechtstraegerIds, RTVerMode.ANTRAG);
    }

    public async onSearcherInit(data) {
        if (this.ShowAktiv) {
            const field = new AviFormField('aktiv', 'aktiv', AviFormFieldType.CHECKBOX, false, false);
            field.Value = true;
            this.searcher.SearcherParameters.push(field);
        }

        this.searcher.beginInit();

        this.searcher.addTextColumn('Name', 'Rechtsträger', true);
        this.searcher.addTextColumn('Rechtsform', 'Rechtsform', true);
        this.searcher.addTextColumn('RegisterNummer', 'Registernummer', true);
        this.searcher.addTextColumn('Sitz', 'Sitz', true);
        this.searcher.addDateColumn('EingangsDatum', 'Eingangsdatum', null, true);
        this.searcher.addDateColumn('RegisterDatum', 'Registerdatum', null, true);
        this.searcher.addListTypeColumn('Erfassungsstatus', 'Erfassungsstatus', 'vwbp_erfassungsstatus', true, true, null, 'v1/vwbp',
            [VwbPListDetailConst.VWEG_ERFASSUNGSSTATUS_VERANTWORTLICHKEIT_ABGEGEGEBEN, VwbPListDetailConst.VWEG_ERFASSUNGSSTATUS_RECHTSTRAEGER_STATUS_GELOESCHT]);
        this.searcher.addIntegerColumn('Version', 'Version', true);
        // this.searcher.addDateColumn('Versionsdatum', 'VWBP.COMMON.VERSIONSDATUM', null, true);
        this.searcher.addDateColumn('Freigabedatum', 'VWBP.COMMON.FREIGABEDATUM', null, true);
        this.searcher.addListTypeColumn('Typ', 'Typ', 'vwbp_rechtstraeger_typ', true, true, null, 'v1/vwbp');

        this.searcher.addIntegerColumn('BemerkungCount', 'Offene Bemerkungen', true).setVisible(false);
        this.searcher.addTextColumn('RechtstraegerId', 'RechtstraegerId', true).setVisible(false);

        this.searcher.addDateColumn('AntragGueltigAb', 'VWBP.INTERN.SCHWAERZEN.GUELTIGAB', null, true);
        this.searcher.addDateColumn('AntragGueltigBis', 'VWBP.INTERN.SCHWAERZEN.GUELTIGBIS', null, true);
        this.searcher.addDateColumn('AntragEntscheidAm', 'VWBP.INTERN.SCHWAERZEN.ENTSCHEIDUNG_AM', null, true);
        this.searcher.addDateColumn('AntragRechtsmittelfrist', 'VWBP.INTERN.SCHWAERZEN.RECHTSMITTELFRIST', null, true);
        this.searcher.addDateColumn('AntragRechtskraftAm', 'Rechtskraft', null, true);
        this.searcher.addDateColumn('AntragInformiertAm', 'Rechtsträger informiert', null, true);
        this.searcher.addListTypeColumn('AntragEntscheidung', 'Entscheidung', 'vwbp_schwaerzen_antrag_entscheidung', true, true, null, 'v1/vwbp', null);
        this.searcher.addListTypeColumn('AntragStatus', 'Status', 'vwbp_schwaerzen_antrag_status', true, true, null, 'v1/vwbp', null);
        this.searcher.addListTypeColumn('AntragBegruendung', 'Begründung', 'vwbp_schwaerzen_antrag_begruendung', true, true, null, 'v1/vwbp', null);
        this.searcher.addListTypeColumn('AntragAktiv', 'AntragAktiv', 'vwbp_mahnwesen_aktiv', true, false, null, 'v1/vwbp').setVisible(false);
        this.searcher.addListTypeColumn('AntragFristablaufGrund', 'Grund Fristablauf', 'vwbp_schwaerzen_antrag_fristablauf', true, true, null, 'v1/vwbp', [VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_FRISTABLAUF_NONE]);

        // this.AddColumBemerkungen();
        this.AddColumnAuge();

        this.searcher.endInit();

        this.onInit.emit(this.searcher);
    }

    public buildSearchConfigAsObject(): any {
        const ret: any = {};

        const filterlist = [];

        if (this.GetFilters) {
            const filters = this.GetFilters();
            if (filters)
                filters.forEach(f => filterlist.push(`(${f})`));
        }

        if (this.searcher.ColumnFilters)
            this.searcher.ColumnFilters.forEach(f => {
                if (f.column.Field !== 'Keywords') {
                    const colFilter = this.searcher._getSearchFilterForColumn(f.column, f.value);
                    if (colFilter)
                        filterlist.push(colFilter);
                } else {
                    ret.keywordFilter = f.value;
                }
            });

        if (filterlist.length > 0)
            ret.filter = `${(filterlist.join(' AND '))}`;

        if (this.searcher.InitFields && this.searcher.InitFields.length > 0)
            ret.init = `${this.searcher.InitFields.join(',')}`;

        if (this.searcher.LazyLoading && this.searcher.SearcherRows > 0) {
            ret.page = `${Math.floor(this.searcher.PageRowStartIndex / this.searcher.SearcherRows)}`;
            ret.per_page = `${this.searcher.SearcherRows}`;
        }

        if (this.searcher.SortInfo && this.searcher.SortInfo.length > 0) {
            ret.sort = this.searcher.SortInfo.map(w => `${Number(w.order) === -1 ? '-' : ''}${w.column.OField}`).join(',');
        }

        return ret;
    }

    public async doSearch(searchValue: string, api: string, searchConfig: any = null): Promise<any> {
        this.searcher.SelectedRows = [];

        const settings = this.buildSearchConfigAsObject();
        return this.apiService.post(api, settings);
    }
}
