
    <avi-core-view-panel header="Juristische Person">
        <avi-crm-jurperson-form #form
            [card]="false"
            [readonly]="true"
            [model-id]="FormModelId">
        </avi-crm-jurperson-form>
    </avi-core-view-panel>

    <avi-core-view-panel header="Adresse">
        <avi-crm-adresse-form #form
            [hidden-fields]="['GueltigAb', 'GueltigBis']"
            [card]="false"
            [readonly]="true"
            [partner-id]="FormModelId">
        </avi-crm-adresse-form>
    </avi-core-view-panel>
