import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Input, ViewChild } from '@angular/core';
import { AviApiService, AviCommonService, AviListDetailConst, AviSessionControllerService, AviTypeUtil } from '@avi-x/avi-core';
import { AviActivityService, AviBusinesscaseService } from '@avi-x/avi-crm';
import { BusinesscaseDataTypeDto } from '@avi-x/avi-dto/businesscase/businesscasedatatypedto.model';

import { AviVerlaufListComponent } from '../../verlauf/components/verlauf-list.component';
import { BusinesscaseSummaryDto } from '@avi-x/avi-dto/businesscase/businesscasesummarydto.model';

export enum BusinessViewRefreshMode {
    All = 0,
    Businesscase = 1,
    Dokumente = 2,
    Formulare = 3,
    Verlauf = 4
}

@Component({
    selector: 'avi-businesscase-view',
    templateUrl: './businesscase-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseViewComponent implements OnInit, OnDestroy {
    AviTypeUtil = AviTypeUtil;
    BusinessViewRefreshMode = BusinessViewRefreshMode;

    Model: BusinesscaseSummaryDto = null;

    _ModelID: any = null;
    @Input('model-id')
    public set ModelID(value: any)
    {
        this._ModelID = value;

        if (this._ModelID)
            this.initView();
    }
    public get ModelID(): any {
        return this._ModelID;
    }

    _active: boolean = false;
    @Input('active')
    public set Active(value: boolean) {
        this._active = value;
        // if (value)
        //     setTimeout(() => this.refresh());
    }

    @ViewChild('verlauf', { static: false })
    public VerlaufList: AviVerlaufListComponent;

    FormModelId: string = null;
    PageTitle: string;

    Loaded = false;
    TaskMenuHeight: string = "0";

    Data: BusinesscaseDataTypeDto[] = [];
    DokVerknuepfenData: BusinesscaseDataTypeDto;
    DokErstellenData: BusinesscaseDataTypeDto;
    FormData: BusinesscaseDataTypeDto;
    Intern: boolean;
    CanCreateComments: boolean;
    CanCreateActivities: boolean;
    ShowComments: boolean;

    public SettingsKey: string = 'avix::settings.businesscase.splitter';

    constructor(private apiService: AviApiService, public commonService: AviCommonService,
        public activityService: AviActivityService,
        public sessionController: AviSessionControllerService,
        public businesscaseService: AviBusinesscaseService,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    private async initView() {
        await this.refreshBusinesscase();
    }

    onTaskMenuHeightChanged(height: number) {
        this.TaskMenuHeight = `${height}px`;
        this.cdr.markForCheck();
    }

    public async refreshBusinesscase(refreshMode: BusinessViewRefreshMode = BusinessViewRefreshMode.All) {
        if (this._ModelID) {

            this.Loaded = false;

            if (refreshMode === BusinessViewRefreshMode.All || refreshMode === BusinessViewRefreshMode.Businesscase) {
                this.Model = null;
                this.Model = await this.businesscaseService.loadBusinesscaseSummary(this._ModelID);
                this.Intern = this.businesscaseService.isIntern();
                this.CanCreateComments = this.Model.CanCreateComments.Id === AviListDetailConst.JA;
                this.CanCreateActivities = this.Model.CanCreateActivities.Id === AviListDetailConst.JA;
                this.PageTitle = this.Model.NumberBez + ' ' + this.Model.Title;
                this.sessionController.setCurrentSessionTitle(this.PageTitle);
            }

            if (refreshMode === BusinessViewRefreshMode.All || refreshMode === BusinessViewRefreshMode.Dokumente || refreshMode === BusinessViewRefreshMode.Formulare)
                this.Data = await this.apiService.getModelList(BusinesscaseDataTypeDto, `businesscase/${this.Model.Id}/data`);

            if (refreshMode === BusinessViewRefreshMode.All || refreshMode === BusinessViewRefreshMode.Dokumente) {
                this.DokVerknuepfenData = this.Data.filter(w => w.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_VERKNUEPFEN)[0];
                this.DokErstellenData = this.Data.filter(w => w.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN)[0];
            }

            if (refreshMode === BusinessViewRefreshMode.All || refreshMode === BusinessViewRefreshMode.Formulare)
                this.FormData = this.Data.filter(w => w.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_FORM_ERSTELLEN)[0];

            if (this.VerlaufList)
                await this.VerlaufList.forceRefresh();
            
            this.ShowComments = this.CanCreateComments || (await this.apiService.get(`comment/idlist/${this.Model.Id}/aviita.CAF.common.modul.businesscase.model.CAFBusinesscase`)).length > 0;

            this.Loaded = true;

            this.cdr.markForCheck();
            
            // if (this.Model.CAEvent == null && this.Model.CAEvent !== '')
            //     this.sessionController.setCurrentSessionTitle(`GF: ${this.Model.InstrumentBez}`);
            // else
            //     this.sessionController.setCurrentSessionTitle(`GF: ${this.Model.CAEvent} ${this.Model.InstrumentBez}`);

        }
    }

    public async refresh() {
        await this.initView();
    }

    ngOnDestroy() {
    }    
}
