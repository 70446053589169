import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviApiService, AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternTaskBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-tasks/task-base.component';
import { VwbPInternRechtstraegerService } from '../../services/rechtstraeger.service';

export enum RechtstraegerRueckgaengigMode {
    // eslint-disable-next-line no-unused-vars
    FREIGABE
}

@Component({
    selector: 'vwbp-intern-rechtstraeger-rueckgaengig-task',
    template: `
     <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
            {{ 'VWBP.INTERN.FREIGABE.FREIGEBEN_RUECKGAENGIG_BUTTON' | translate }}
        </div>
        <div class="grid form-group">
            <div class="col-12">
                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ 'VWBP.INTERN.FREIGABE.FREIGEBEN_RUECKGAENGIG_KOMMENTAR' | translate }}*</label>
                </span>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-undo" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-primary"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternRechtstraegerRueckgaengigTaskComponent extends VwbPInternTaskBaseComponent {
    @Input('modus')
    public Modus: RechtstraegerRueckgaengigMode;

    constructor(public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public inputMaskService: InputmaskService, public dialogService: DialogService, public cdr: ChangeDetectorRef) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + this.commonService.translateInstant('VWBP.INTERN.FREIGABE.FREIGEBEN_RUECKGAENGIG_BUTTON');
    }

    CanExecute(): boolean {
        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung;
    }

    async Execute() {
        this.PerformAction('VWBP.INTERN.FREIGABE.RUECKGAENGIG_MACHEN', this.GetMessage(), 'VWBP.INTERN.FREIGABE.RUECKGAENGIG_MACHEN',
            async (selectedRT) => this.RueckgaengigMachen(selectedRT)
        );
    }

    async RueckgaengigMachen(selectedRT: any[]) {
        const versionIds: string[] = selectedRT.map(w => w.Id);

        if (this.Modus === RechtstraegerRueckgaengigMode.FREIGABE)
            return await this.rechtstraegerService.FreigabeRueckgaengig(versionIds, this.Bemerkung);
    }

    GetMessage() {
        if (this.Modus === RechtstraegerRueckgaengigMode.FREIGABE)
            return this.commonService.translateInstant(this.GetResourceName('VWBP.INTERN.FREIGABE.RUECKGAENGIG_BESTAETIGEN'));
    }
}
