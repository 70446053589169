import { AviListDetailConst } from '@avi-x/avi-core';
import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class AppUserGroupLinkSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

	@Expose()
	public AppUserId: string;

	@Expose()
	public Kurzz: string;

	@Expose()
	public Username: string;

	@Expose()
	public Vorname: string;

	@Expose()
	public Name: string;

	@Expose()
	public Domain: string;

	@Expose()
	public AppUserGroupId : string;

	@Expose()
    @Type(() => Date)
	public AppUserGueltigAb: Date;

    @Expose()
    @Type(() => Date)
	public AppUserGueltigBis: Date;

	@Expose()
	@Type(() => ListType)
	public AppUserAktiv: ListType;

	// Der Searcher prüft auch auf Felder "Aktiv"+"Id". Deswegen funktioniert das.
	public get AktivId(): string {
		let combinedActive = (this.Aktiv == null || this.Aktiv.Id == AviListDetailConst.AKTIV) && (this.AppUserAktiv == null || this.AppUserAktiv.Id == AviListDetailConst.AKTIV);

		return combinedActive ? AviListDetailConst.AKTIV : AviListDetailConst.INAKTIV;
	}
}
