import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared';

export class DocuMatrixTemplate extends BaseModel {  
    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }
    
    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }
    
    @Expose()
    public get Bezeichnung(): string { return this.getProperty('Bezeichnung'); }
    public set Bezeichnung(value: string) { this.setProperty('Bezeichnung', value); }
    
    @Expose()
    public get TemplateName(): string { return this.getProperty('TemplateName'); }
    public set TemplateName(value: string) { this.setProperty('TemplateName', value); }

    @Expose()
    public get Mime(): string { return this.getProperty('Mime'); }
    public set Mime(value: string) { this.setProperty('Mime', value); }

    @Expose()
    public get Bulkroot(): string { return this.getProperty('Bulkroot'); }
    public set Bulkroot(value: string) { this.setProperty('Bulkroot', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ContainerTyp(): ListType { return this.getProperty('ContainerTyp'); }
    public set ContainerTyp(value: ListType) { this.setProperty('ContainerTyp', value); }    

    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }    
}
