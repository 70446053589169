<avi-core-dialog
    #formDialog
    identifier="formDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
>
    <avi-crm-land-form
        #form
        [model-id]="FormModelId"
        (onSavedSuccessful)="onSavedSuccessful($event)"
        [action-buttons]="formActionButtons"
    >
    </avi-crm-land-form>
</avi-core-dialog>

<div class="mt-2">
    <avi-core-view-panel [toggleable]="false" [header]="'Länder'" class="w-100p">

    <avi-core-base-searcher #searcher
        settingskey="settings.searcher.land"
        [use-router-params]="false"
        search-delegate="land"
        [card]="false"
        [auto-search]="true"
        (onInit)="onInit($event)"
        (onDoubleClick)="onDoubleClick($event)"
        sort-field="bezeichnung1"
        [search-fields]="['bezeichnung1']"
        searcher-title="Länder">
    </avi-core-base-searcher>

</avi-core-view-panel>
</div>