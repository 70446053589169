import { FlexLayoutModule } from '@angular/flex-layout';
import { AviCoreModule } from '@avi-x/avi-core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PanelModule } from 'primeng/panel';
import { DividerModule } from 'primeng/divider';
import { ToolbarModule } from 'primeng/toolbar';
import { FormsModule } from '@angular/forms';

import { AviAppUserFormComponent } from './components/appuser-form.component';
import { AviAppUserSearcherComponent } from './components/appuser-searcher.component';
import { AviAppUserGroupFormComponent } from './components/appusergroup-form.component';
import { AviAppUserGroupIndexComponent } from './components/appusergroup-index.component';
import { AviAppUserGroupSearcherComponent } from './components/appusergroup-searcher.component';
import { AviAppUserGroupLinkFormComponent } from './components/appusergrouplink-form.component';
import { AviAppUserGroupLinkSearcherComponent } from './components/appusergrouplink-searcher.component';
import { AviAppUserGroupLinkMultiSelectionComponent } from './components/appusergrouplink-multiselection.component';
import { AviAppUserGroupLogEntryFormComponent } from './components/appusergrouplogentry-form.component';
import { AviAppUserGroupLogEntryIndexComponent } from './components/appusergrouplogentry-index.component';
import { AviAppUserGroupLogEntrySearcherComponent } from './components/appusergrouplogentry-searcher.component';
import { AviTechRoleFormComponent } from './components/techrole-form.component';
import { AviTechRoleIndexComponent } from './components/techrole-index.component';
import { AviTechRoleSearcherComponent } from './components/techrole-searcher.component';
import { AviTechRoleLinkFormComponent } from './components/techrolelink-form.component';
import { AviTechRoleLinkSearcherComponent } from './components/techrolelink-searcher.component';
import { AviTechRoleLinkMultiSelectionComponent } from './components/techrolelink-multiselection.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        FlexLayoutModule,

        AviCoreModule,

        PanelModule,
        DividerModule,
        ToolbarModule,
        TranslateModule
    ],
    exports: [
        AviAppUserFormComponent, AviAppUserSearcherComponent,
        AviAppUserGroupFormComponent, AviAppUserGroupIndexComponent, AviAppUserGroupSearcherComponent,
        AviAppUserGroupLinkFormComponent, AviAppUserGroupLinkSearcherComponent, AviAppUserGroupLinkMultiSelectionComponent,
        AviAppUserGroupLogEntryFormComponent, AviAppUserGroupLogEntryIndexComponent, AviAppUserGroupLogEntrySearcherComponent,
        AviTechRoleFormComponent, AviTechRoleIndexComponent, AviTechRoleSearcherComponent,
        AviTechRoleLinkFormComponent, AviTechRoleLinkSearcherComponent, AviTechRoleLinkMultiSelectionComponent
    ],
    declarations: [
        AviAppUserFormComponent, AviAppUserSearcherComponent,
        AviAppUserGroupFormComponent, AviAppUserGroupIndexComponent, AviAppUserGroupSearcherComponent,
        AviAppUserGroupLinkFormComponent, AviAppUserGroupLinkSearcherComponent, AviAppUserGroupLinkMultiSelectionComponent,
        AviAppUserGroupLogEntryFormComponent, AviAppUserGroupLogEntryIndexComponent, AviAppUserGroupLogEntrySearcherComponent,
        AviTechRoleFormComponent, AviTechRoleIndexComponent, AviTechRoleSearcherComponent,
        AviTechRoleLinkFormComponent, AviTechRoleLinkSearcherComponent, AviTechRoleLinkMultiSelectionComponent
    ]
})
export class AviSystemModule { }
