<avi-core-view-panel [toggleable]="false" [header]="Title | translate" class="w-100p" [enable-panel]="EnablePanel" panel-class="mt-2" [no-pad]="!EnablePanel">
    <avi-core-base-searcher
        #searcher
        [settingskey]="SettingsKey"
        [search-delegate]="SearchDelegate"
        (onInit)="onSearcherInit($event)"
        (onError)="onSearcherError($event)"
        [use-router-params]="false"
        [toolbar-show-refresh]="false"
        (onDoubleClick)="onDoubleClickSearcher($event)"
        [toolbar]="true"
        selectionMode="single"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [toolbar-show-columnselection]="false"
        [toolbar-show-inactivebutton]="false"
        [toolbar-show-generic-export]="false"
        [disable-inactive-filter]="true"
        [lazy-loading]="true"
        (onSelect)="onSelectionChanged($event)"
		[card]="Card"
		[contents-padding]="ContentsPadding"
        [searcher-title]="Title"
        [show-totalrecords]="false"
        [select-first-result]="false"
        [add-crud-menuitems]="true"
        [toolbar-show-createbutton]="false"
        crud-base-right="ListDetail"
        [crud-enable-create]="false"
        [crud-enable-delete]="false"
        (onEditObject)="editObject($event)"
    >
    </avi-core-base-searcher>
</avi-core-view-panel>
