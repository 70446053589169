import { ChangeDetectorRef, Component } from '@angular/core';
import { AviApiService, AviCommonService, AviJobService, AviListDetailConst, InputmaskService } from '@avi-x/avi-core';
import { VwbPInternUnstimmigkeitService } from '../../services/unstimmigkeit.service';
import { VwbPInternTaskBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-tasks/task-base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';

@Component({
    selector: 'vwbp-intern-unstimmigkeit-mahnen-task',
    template: `
     <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
            {{ 'VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_MAHNEN_BUTTON' | translate}}
        </div>

        <div class="grid form-group">
            <div class="col-12">
                <span class="p-float-label mb-4p">
                    <p-calendar #calendar2 id="cal2" appendTo="body" [baseZIndex]="10000" (onInput)="onInputDate($event, calendar2)" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [firstDayOfWeek]="1" [(ngModel)]="Datum" [selectOtherMonths]="true" dateFormat="dd.mm.yy" [readonlyInput]="false" [showOnFocus]="false" [showIcon]="true"></p-calendar>
                    <label for="cal2">{{ 'VWBP.INTERN.UNSTIMMIGKEITEN.MAHNFRIST' | translate }}*</label>
                </span>

                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ 'VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_MAHNEN_KOMMENTAR' | translate }}*</label>
                </span>
            </div>
            <div *ngIf="Dialog && HasDoku" class="col-12 lg:col-12">
                <p-checkbox [(ngModel)]="DocuGenerieren" label="{{ 'VWBP.INTERN.DOKUMENTE.GENERIEREN' | translate }}" name="doc" [binary]="true"></p-checkbox>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-exclamation-triangle" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-primary"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternUnstimmigkeitMahnenTaskComponent extends VwbPInternTaskBaseComponent {
    constructor(public postfachService: VwbPInternPostfachService, public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public unstimmigkeitService: VwbPInternUnstimmigkeitService, public inputMaskService: InputmaskService, public dialogService: DialogService, public cdr: ChangeDetectorRef, public jobService: AviJobService) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);
    }

    ngOnInit() {
        this.InitDoku(VwbPListDetailConst.VWBP_VORLAGE_TASK_UNSTIMMIGKEITEN_MAHNEN);
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + this.commonService.translateInstant('VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_MAHNEN_BUTTON');
    }

    CanExecute(): boolean {
        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung && !!this.Datum;
    }

    async Execute() {
        this.PerformAction('VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_MAHNEN_BUTTON', this.GetResourceName('VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_MAHNEN_BESTAETIGEN'), 'VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_MAHNEN_BUTTON',
            async (selectedRT) => {
                const jobId: string = await this.unstimmigkeitService.Mahnen(selectedRT.map(w => w.Id), this.Datum, this.DocuGenerieren, this.Bemerkung);
                if (jobId) {
                    const jobStatus = await this.jobService.OpenJobDialog(jobId, 'VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_MAHNEN_BUTTON', 'v1/intern/vwbp/job', false);
                    if (jobStatus !== AviListDetailConst.JOB_STATUS_SUCCEEDED)
                        return;
                }
                if (this.HasDoku && this.DocuGenerieren)
                    this.postfachService.OpenPostfach();
            }
        );
    }
}
