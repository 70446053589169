<div *ngIf="Model" class="p-2 grid">
    <div class="col-12 lg:col-6 p-2">
        <div class="col-12 lg:col-12 p-0 pb-3">
            <avi-core-view-panel [no-pad]="true" [header]="">
                <div class="p-3 grid">
                    <div class="p-0 mb-1 col-12 md:col-12 summary-header">{{ Model.Vorname }} {{ Model.Name }}</div>
                    <div *ngIf="Model.PEP" class="pep-marker">PEP</div>

                    <div class="ml-2 grid col-12 md:col-12">
                        <div class="p-0 col-12 md:col-4 summary-label">Strasse und Hausnummer</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Strasse }} {{ Model.HausNr }}</div>
                        <div class="p-0 col-12 md:col-4 summary-label">PLZ Ort</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.PLZ }} {{ Model.Ort }}</div>
                        <div class="p-0 col-12 md:col-4 summary-label">Land</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Land }}</div>
                    </div>

                    <div class="p-0 mb-1 col-12 md:col-12 summary-header mt-2">Details</div>
                    <div class="ml-2 grid col-12 md:col-12">
                        <div class="p-0 col-12 md:col-4 summary-label">Partnernummer</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.PartnerNr }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Geburtsdatum</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Geburtsdatum | dateFormat: 'dd.MM.yyyy' }}</div>

                        <div *ngIf="Model.Todesdatum" class="p-0 col-12 md:col-4 summary-label">Todesdatum</div>
                        <div *ngIf="Model.Todesdatum" class="p-0 mb-1 col-12 md:col-8">
                            {{ Model.Todesdatum | dateFormat: 'dd.MM.yyyy' }}
                        </div>

                        <div class="p-0 col-12 md:col-4 summary-label">Geschlecht</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Geschlecht.Bezeichnung }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Sprache</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ Model.Sprache }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">
                            {{ this.Model.Nationalitaeten.length > 1 ? 'Nationalitäten' : 'Nationalität' }}
                        </div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ getNationalitaeten() }}</div>
                    </div>
                </div>
            </avi-core-view-panel>
        </div>

        <div class="col-12 lg:col-12 p-0">
            <avi-core-view-panel [no-pad]="true" [header]="">
            <!-- <avi-core-page [contents-padding]="false"> -->
                <div class="p-3 grid">
                    <div class="p-0 mb-1 col-12 md:col-12 summary-header">Steuerliche Informationen</div>
                    <div class="ml-2 grid col-12 md:col-12">
                        <div class="p-0 col-12 md:col-4 summary-label">Steuerliche Ansässigkeit</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ getSteuerDomizile() }}</div>

                        <div class="p-0 col-12 md:col-4 summary-label">Selbstauskunft</div>
                        <div class="p-0 mb-1 col-12 md:col-8">{{ getSelbstauskuenfte() }}</div>
                    </div>

                    <div
                        *ngIf="Model.Steuernummern && Model.Steuernummern.length > 0"
                        class="p-0 mb-1 col-12 md:col-12 summary-header mt-2"
                    >
                        Steuernummern
                    </div>
                    <div
                        *ngIf="Model.Steuernummern && Model.Steuernummern.length > 0"
                        class="ml-2 grid col-12 md:col-12"
                    >
                        <ng-container *ngFor="let komm of Model.Steuernummern">
                            <div class="p-0 col-12 md:col-4 summary-label">
                                {{ komm.Land }} ({{ komm.SteuernummerDef }})
                            </div>
                            <div class="p-0 mb-1 col-12 md:col-8">{{ komm.Steuernummer }}</div>
                        </ng-container>
                    </div>
                </div>
            <!-- </avi-core-page> -->
            </avi-core-view-panel>
        </div>
    </div>

    <div class="col-12 lg:col-6 p-2">
        <div *ngIf="Model.Kommunikationen && Model.Kommunikationen.length > 0" class="col-12 ui-lg-12 p-0 pb-3">
            <avi-core-view-panel [no-pad]="true" [header]="">
                <!-- <avi-core-page [contents-padding]="false"> -->
                <div class="p-3 grid">
                    <div class="p-0 mb-1 col-12 md:col-12 summary-header">Kommunikation</div>
                    <div class="ml-2 grid col-12 md:col-12">
                        <ng-container *ngFor="let komm of Model.Kommunikationen">
                            <div class="p-0 col-12 md:col-4 summary-label">
                                {{ komm.KommArt.Bezeichnung }} {{ komm.KommTyp.Bezeichnung }}
                            </div>
                            <div class="p-0 mb-0 col-12 md:col-8">
                                <a *ngIf="isHomepage(komm)" (click)="openHomepage(komm.Eingabe)" class="p-0 mr-4p"
                                    ><i class="material-icons" style="color: #666;">home</i></a
                                >
                                <a *ngIf="isTelefonnummer(komm)" (click)="click2Call(komm.Eingabe)" class="p-0 mr-4p"
                                    ><i class="material-icons searcher-icon" style="color: #666;">call</i></a
                                >
                                <a *ngIf="isEmail(komm)" (click)="click2Email(komm.Eingabe)" class="p-0 mr-4p"
                                    ><i class="material-icons searcher-icon" style="color: #666;">email</i></a
                                >
                                {{ komm.Eingabe }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            <!-- </avi-core-page> -->
            </avi-core-view-panel>
        </div>

        <div *ngIf="WCKInfo || ActivityInfo" class="col-12 lg:col-12 p-0">
           <!-- <avi-core-page [contents-padding]="false"> -->
            <avi-core-view-panel [no-pad]="true" [header]="">
                <div class="p-3 grid">
                    <div *ngIf="WCKInfo" class="p-0 mb-1 col-12 md:col-12 summary-header">Worldcheck</div>
                    <div *ngIf="WCKInfo" class="ml-2 grid col-12 md:col-12">
                        <div class="p-0 mb-1 col-12 md:col-12" [ngClass]="{ 'color-red': WCKInfo.OpenWCKTreffer > 0 }">
                            Es gibt {{ WCKInfo.OpenWCKTreffer }}
                            {{ WCKInfo.OpenWCKTreffer == 1 ? 'offener Treffer' : 'offene Treffer' }}
                        </div>
                        <div
                            class="p-0 mb-1 col-12 md:col-12"
                            [ngClass]="{ 'color-red': WCKInfo.ClosedWCKTrefferPersonIdentisch > 0 }"
                        >
                            Es gibt {{ WCKInfo.ClosedWCKTrefferPersonIdentisch }}
                            {{
                                WCKInfo.ClosedWCKTrefferPersonIdentisch == 1
                                    ? 'erledigter Treffer mit Person identisch'
                                    : 'erledigte Treffer mit Person identisch'
                            }}
                        </div>
                        <div
                            class="p-0 mb-1 col-12 md:col-12"
                            [ngClass]="{ 'color-red': WCKInfo.ClosedWCKTrefferPersonNichtIdentisch > 0 }"
                        >
                            Es gibt {{ WCKInfo.ClosedWCKTrefferPersonNichtIdentisch }}
                            {{
                                WCKInfo.ClosedWCKTrefferPersonIdentisch == 1
                                    ? 'erledigter Treffer mit Person nicht identisch'
                                    : 'erledigte Treffer mit Person nicht identisch'
                            }}
                        </div>
                        <div *ngIf="WCKInfo.LetztePruefung" class="p-0 mb-1 col-12 md:col-12">
                            Letzte WCK Prüfung am {{ WCKInfo.LetztePruefung | dateFormat: 'dd.MM.yyyy' }}
                        </div>
                    </div>

                    <div *ngIf="ActivityInfo" class="p-0 mb-1 col-12 md:col-12 summary-header mt-2">Aktivitäten</div>
                    <div *ngIf="ActivityInfo" class="ml-2 grid col-12 md:col-12">
                        <a
                            class="p-0 mb-1 col-12 md:col-12"
                            [ngClass]="{ 'color-red': ActivityInfo.OpenActivities > 0 }"
                            [routerLink]="['/crm/natperson/index/', Model.Id, 'activity']"
                            title="Aktivitäten"
                        >
                            Es gibt {{ ActivityInfo.OpenActivities }}
                            {{ ActivityInfo.OpenActivities == 1 ? 'offene Aktivität' : 'offene Aktivitäten' }}
                        </a>
                    </div>
                </div>
            <!-- </avi-core-page> -->
            </avi-core-view-panel>
        </div>
    </div>
</div>
