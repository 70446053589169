import { Expose } from 'class-transformer';
import { IDTO } from './idto.model';

export class PagedResult<T> extends IDTO {
    @Expose()
    public CurrentPage: number;

    @Expose()
    public PageSize: number;

    @Expose()
    public RecordCount: number;

    @Expose()
    public PageCount: number;

    @Expose()
    public Results: T[];
    
    @Expose()
    public RecordCountUnfiltered?: number;
}