<avi-core-dialog
    #formDialog
    identifier="formDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
>
    <avi-crm-partner-identifikation-form
        #form
        [model-id]="FormModelId"
        [partner-id]="partnerId"
        (onSavedSuccessful)="onSavedSuccessful($event)"
        [redirect-after-save]="null"
        [redirect-after-delete]="null"
        [action-buttons]="formActionButtons"
    >
    </avi-crm-partner-identifikation-form>
</avi-core-dialog>

<avi-core-view-panel header="Identifikationen">
    <avi-core-base-searcher
        #searcher
        [use-router-params]="false"
        [select-first-result]="false"
        search-delegate="partnerIdentifikation"
        settingskey="settings.searcher.partneridentifikation"
        [get-filters]="getFilters"
        [search-fields]="['IdentifikationsNr']"
        [field-names-gueltigab]="['GueltigAb']"
        [field-names-gueltigbis]="['GueltigBis']"
        [auto-search]="true"
        [show-autofilter]="true"
        [toolbar]="true"
        [toolbar-show-searchinput]="true"
        [card]="false"
        (onInit)="onInit($event)"
        (onSelect)="onSelect($event)"
        [pretoolbar-template]="toolbar"
        searcher-title="Identifikation"
    >
        <ng-template #toolbar>
            <div class="ui-g">
                <div class="w-100p pt-2 pb-4">
                    <p-toolbar>
                        <div class="ui-toolbar-group-left flex">
                            <button
                                pButton
                                type="button"
                                label="Neu"
                                icon="pi pi-plus"
                                (click)="createObject()"
                                class="w-100p-m mb-4-m p-button-secondary"
                            ></button>

                            <p-divider layout="vertical" ></p-divider>

                            <button
                                pButton
                                type="button"
                                label="Ändern"
                                icon="pi pi-pencil"
                                (click)="editObject()"
                                class="w-100p-m mb-4-m p-button-secondary"
                            ></button>

                            <p-divider layout="vertical" ></p-divider>

                            <button
                                pButton
                                type="button"
                                label="Delete"
                                icon="pi pi-trash"
                                (click)="deleteObject()"
                                class="red-btn w-100p-m mb-4-m p-button-secondary"
                            ></button>
                        </div>
                    </p-toolbar>
                </div>
                <p-divider class="toolbar-divider"></p-divider>
            </div>
        </ng-template>
    </avi-core-base-searcher>
</avi-core-view-panel>

<avi-core-view-panel *ngIf="DetailModelId" header="Details">
    <avi-crm-partner-identifikation-form
        #form
        [card]="false"
        [readonly]="true"
        [model-id]="DetailModelId"
    >
    </avi-crm-partner-identifikation-form>
</avi-core-view-panel>
