import { Expose, Type } from 'class-transformer';

import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { VwbPFormularDaten } from './vwbpformulardaten.model';

export class VwbPVersion extends BaseModel {
    @Expose()
    public get Laufnummer(): number { return this.getProperty('Laufnummer'); }
    public set Laufnummer(value: number) { this.setProperty('Laufnummer', value); }

    @Expose()
    public get VwEGSortOrder(): number { return this.getProperty('VwEGSortOrder'); }
    public set VwEGSortOrder(value: number) { this.setProperty('VwEGSortOrder', value); }

    @Expose()
    @Type(() => Date)
    public get Versionsdatum(): Date { return this.getProperty('Versionsdatum'); }
    public set Versionsdatum(value: Date) { this.setProperty('Versionsdatum', value); }

    @Expose()
    @Type(() => Date)
    public get Eingangsdatum(): Date { return this.getProperty('Eingangsdatum'); }
    public set Eingangsdatum(value: Date) { this.setProperty('Eingangsdatum', value); }

    @Expose()
    @Type(() => Date)
    public get Freigabedatum(): Date { return this.getProperty('Freigabedatum'); }
    public set Freigabedatum(value: Date) { this.setProperty('Freigabedatum', value); }

    @Expose()
    @Type(() => Date)
    public get ajuAbschlussdatum(): Date { return this.getProperty('ajuAbschlussdatum'); }
    public set ajuAbschlussdatum(value: Date) { this.setProperty('ajuAbschlussdatum', value); }

    @Expose()
    public get VersionOwnerContainer_ID(): string { return this.getModelId('VersionOwnerContainer_ID'); }
    public set VersionOwnerContainer_ID(value: string) { this.setModelId('VersionOwnerContainer_ID', value); }

    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }

    @Expose()
    @Type(() => ListType)
    public get Erfassungsstatus(): ListType { return this.getProperty('Erfassungsstatus'); }
    public set Erfassungsstatus(value: ListType) { this.setProperty('Erfassungsstatus', value); }

    @Expose()
    @Type(() => ListType)
    public get UnstimmigkeitMahnwesenAktiv(): ListType { return this.getProperty('UnstimmigkeitMahnwesenAktiv'); }
    public set UnstimmigkeitMahnwesenAktiv(value: ListType) { this.setProperty('UnstimmigkeitMahnwesenAktiv', value); }

    @Expose()
    public get Rechtstraeger_ID(): string { return this.getModelId('Rechtstraeger_ID'); }
    public set Rechtstraeger_ID(value: string) { this.setModelId('Rechtstraeger_ID', value); }

    // @Expose()
    // @Type(() => VwbPRechtstraeger)
    // public get Rechtstraeger(): VwbPRechtstraeger { return this.getModel('Rechtstraeger'); }
    // public set Rechtstraeger(value: VwbPRechtstraeger) { this.setModel('Rechtstraeger', value); }

    @Expose()
    @Type(() => ListType)
    public get Quelle(): ListType { return this.getProperty('Quelle'); }
    public set Quelle(value: ListType) { this.setProperty('Quelle', value); }

    @Expose()
    @Type(() => VwbPFormularDaten)
    public alleFormularDaten: VwbPFormularDaten[] = [];

    // Transient

    @Expose()
    public get CurrentRTOwnerContainer_ID(): string { return this.getProperty('CurrentRTOwnerContainer_ID'); }
    public set CurrentRTOwnerContainer_ID(value: string) { this.setProperty('CurrentRTOwnerContainer_ID', value); }

    @Expose()
    public get VersionMasterOwner_ID(): string { return this.getProperty('VersionMasterOwner_ID'); }
    public set VersionMasterOwner_ID(value: string) { this.setProperty('VersionMasterOwner_ID', value); }
}
