import { VwbPUnstimmigkeit } from './../../../dto/vwbpunstimmigkeit.model';
import { Component, Input, ChangeDetectorRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService, AviDialogComponent } from '@avi-x/avi-core';
import { VwbPListDetailConst, VwbPRechtstraegerDetail, VwbPVersion } from '@avi-x/vwbp-public';
import { VwbPMahnung } from '../../../dto/vwbpmahnung.model';
import { VwbPBusse } from '../../../dto/vwbpbusse.model';
import { UnstimmigkeitRueckgaengigMode } from '../unstimmigkeit-tasks/unstimmigkeit-rueckgaengig-task.component';
import { VwbPInternUnstimmigkeitViewComponent } from '../unstimmigkeit-view/unstimmigkeit-view.component';

export enum AviCommentMode {
    // eslint-disable-next-line no-unused-vars
    Readonly, Create, Edit
}

export enum UnstimmigkeitTaskMode {
    // eslint-disable-next-line no-unused-vars
    INFORMIEREN, MAHNEN, MAHNFRIST, BUESSEN, LOESCHVERFAHREN, RECHTSMITTELFRIST, EINZUGSAUFTRAG, BUSSFRIST, ABSCHLIESSEN, LOESCHEN, RUECKGAENGIG, ZURUECK_AUF_INFORMIERT
}

@Component({
    selector: 'vwbp-intern-unstimmigkeit-list',
    templateUrl: './unstimmigkeit-list.component.html',
    styleUrls: ['./unstimmigkeit-list.component.scss'],
    providers: [DecimalPipe, PercentPipe]
})
export class VwbPInternUnstimmigkeitListComponent {
    @ViewChild('taskDialog', { static: false })
    taskDialog: AviDialogComponent;

    @ViewChild('unstimmigkeitView', { static: false })
    unstimmigkeitView: VwbPInternUnstimmigkeitViewComponent;

    Unstimmigkeit: VwbPUnstimmigkeit = null;

    NewUnstimmigkeitId: string = null;
    EditUnstimmigkeitId: string = null;

    @Input('rechtstraeger-detail')
    public RechtstraegerDetail: VwbPRechtstraegerDetail;

    VersionId: string = null;
    _Version: VwbPVersion = null;
    public get Version(): VwbPVersion {
        return this._Version;
    }

    @Input('version')
    public set Version(value: VwbPVersion) {
        this._Version = value;
        this.VersionId = this.Version?.Id;
        this.LoadUnstimmigkeiten();
    }

    @Input('readonly')
    public Readonly: boolean = false;

    @Output('onChanged')
    public OnChanged: EventEmitter<any> = new EventEmitter();

    NumUnstimmigkeiten: number = 0;
    NumOpenUnstimmigkeiten: number = 0;
    NumClosedUnstimmigkeiten: number = 0;
    selectedRT: any[] = [];

    LastMahnung: VwbPMahnung = null;
    LastBusse: VwbPBusse = null;
    Mahnfrist: Date = null;
    Rechtsmittelfrist: Date = null;
    Bussfrist: Date = null;
    ErneutBuessen: boolean = false;
    RueckgaengigMode: UnstimmigkeitRueckgaengigMode;
    IstGemahnt: boolean = false;
    IstGebuesst: boolean = false;

    public UnstimmigkeitTaskMode = UnstimmigkeitTaskMode;
    Modus: UnstimmigkeitTaskMode;
    KannUnstimmigkeitenHaben: boolean = false;

    VwbPListDetailConst = VwbPListDetailConst;

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, private cdr: ChangeDetectorRef) {
    }

    async LoadUnstimmigkeiten() {
        if (this.VersionId) {
            this.Unstimmigkeit = await this.apiService.getModel(VwbPUnstimmigkeit, `v1/intern/vwbp/unstimmigkeit/version/${this.VersionId}`);
            this.NumUnstimmigkeiten = this.Unstimmigkeit ? 1 : 0;
            this.NumOpenUnstimmigkeiten = this.Unstimmigkeit && this.Unstimmigkeit.Unstimmigkeitsstatus.Id !== VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_ABGESCHLOSSEN ? 1 : 0;
            this.NumClosedUnstimmigkeiten = this.Unstimmigkeit && this.Unstimmigkeit.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_ABGESCHLOSSEN ? 1 : 0;
            if (this.Unstimmigkeit) {
                this.LastMahnung = await this.apiService.getModel(VwbPMahnung, `v1/intern/vwbp/unstimmigkeit/letzte_mahnung/${this.VersionId}`);
                this.LastBusse = await this.apiService.getModel(VwbPBusse, `v1/intern/vwbp/unstimmigkeit/letzte_busse/${this.VersionId}`);

                this.IstGemahnt = this.LastMahnung != null;
                this.IstGebuesst = this.LastBusse != null;

                this.Mahnfrist = this.LastMahnung?.Mahnfrist;
                this.Rechtsmittelfrist = this.LastBusse?.Rechtsmittelfrist;
                this.Bussfrist = this.LastBusse?.Bussfrist;
            } else {
                this.Mahnfrist = null;
                this.Rechtsmittelfrist = null;
                this.Bussfrist = null;
                this.LastMahnung = null;
                this.LastBusse = null;
            }
        }

        this.KannUnstimmigkeitenHaben = this.Version && this.Version?.Typ.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWBP &&
            (this.Version.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_ERFASSUNG_ABGESCHLOSSEN || this.Version.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN);
        this.cdr.markForCheck();
    }

    CanAdd() {
        return !this.Readonly && (this.NumUnstimmigkeiten === 0 || this.NewUnstimmigkeitId) && this.Version?.Typ.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWBP;
    }

    OnDeleteUnstimmigkeit() {
        this.doTaskLoeschen();
    }

    OnEditUnstimmigkeit() {
        this.EditUnstimmigkeitId = this.Unstimmigkeit?.Id;
    }

    OnEditUnstimmigkeitCancelled() {
        this.EditUnstimmigkeitId = null;
    }

    OnEditUnstimmigkeitSaved() {
        this.EditUnstimmigkeitId = null;
        this.LoadUnstimmigkeiten();
        this.OnChanged.emit(null);
    }

    OnCreateUnstimmigkeitCancelled() {
        this.NewUnstimmigkeitId = null;
    }

    OnCreateUnstimmigkeitSaved() {
        this.NewUnstimmigkeitId = null;
        this.LoadUnstimmigkeiten();
        this.OnChanged.emit(null);
    }

    CreateUnstimmigkeit() {
        this.NewUnstimmigkeitId = this.commonService.GuidEmpty;
    }

    doTask(selectedRT: any[], modus: UnstimmigkeitTaskMode) {
        this.selectedRT = selectedRT;
        this.Modus = modus;
        this.cdr.detectChanges();
        this.taskDialog.open();
    }

    isMahnwesenAktivAndHasRecht() {
        return !this.Readonly && this.Version?.UnstimmigkeitMahnwesenAktiv.Id === VwbPListDetailConst.VWBP_MAHNWESEN_AKTIV && this.Version?.Typ.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWBP;
    }

    enableTaskAbschliessen() {
        return this.isMahnwesenAktivAndHasRecht() && this.Unstimmigkeit?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_KORRIGIERT;
    }

    doTaskAbschliessen() {
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.ABSCHLIESSEN);
    }

    enableTaskInformieren() {
        return this.isMahnwesenAktivAndHasRecht() && (this.Unstimmigkeit?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_MELDUNG_OFFEN ||
            this.Unstimmigkeit?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_AMTLICHES_LIQUIDATIONSVERFAHREN);
    }

    doTaskInformieren() {
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.INFORMIEREN);
    }

    enableTaskMahnen() {
        return this.isMahnwesenAktivAndHasRecht() && this.Unstimmigkeit?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_INFORMIERT;
    }

    doTaskMahnen() {
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.MAHNEN);
    }

    enableTaskMahnfristSetzen() {
        return this.isMahnwesenAktivAndHasRecht() &&
            (this.LastMahnung?.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_OFFEN || this.LastMahnung?.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN);
    }

    doTaskMahnfristSetzen() {
        this.doTask([{ 'MahnungId': this.LastMahnung?.Id }], UnstimmigkeitTaskMode.MAHNFRIST);
    }

    enableTaskBuessen() {
        return this.isMahnwesenAktivAndHasRecht() &&
            (this.LastMahnung?.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN || this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_BUSSFRIST_ABGELAUFEN);
    }

    doTaskBuessen() {
        this.ErneutBuessen = !this.LastMahnung || this.LastMahnung?.Status.Id !== VwbPListDetailConst.VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN;

        this.doTask(!this.ErneutBuessen ? [{ 'MahnungId': this.LastMahnung.Id }] : [{ 'BusseId': this.LastBusse.Id }], UnstimmigkeitTaskMode.BUESSEN);
    }

    enableTaskLoeschverfahren() {
        return this.isMahnwesenAktivAndHasRecht() &&
            (this.LastMahnung?.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN || this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_BUSSFRIST_ABGELAUFEN);
    }

    doTaskLoeschverfahren() {
        this.doTask([{ Id: this.VersionId, Typ: this.RechtstraegerDetail.Typ }], UnstimmigkeitTaskMode.LOESCHVERFAHREN);
    }

    enableTaskRechtsmittelfristSetzen() {
        return this.isMahnwesenAktivAndHasRecht() &&
            (this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_OFFEN || this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_RECHTSMITTELFRIST_ABGELAUFEN);
    }

    doTaskRechtsmittelfristSetzen() {
        this.doTask([{ 'BusseId': this.LastBusse?.Id }], UnstimmigkeitTaskMode.RECHTSMITTELFRIST);
    }

    enableTaskEinzugsauftrag() {
        return this.isMahnwesenAktivAndHasRecht() && this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_RECHTSMITTELFRIST_ABGELAUFEN;
    }

    doTaskEinzugsauftrag() {
        this.doTask([{ 'BusseId': this.LastBusse?.Id }], UnstimmigkeitTaskMode.EINZUGSAUFTRAG);
    }

    enableTaskBussfristSetzen() {
        return this.isMahnwesenAktivAndHasRecht() && this.LastBusse?.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_EINZUGSAUFTRAG;
    }

    doTaskBussfristSetzen() {
        this.doTask([{ 'BusseId': this.LastBusse?.Id }], UnstimmigkeitTaskMode.BUSSFRIST);
    }

    doTaskLoeschen() {
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.LOESCHEN);
        this.OnChanged.emit(null);
    }

    enableTaskZurueckAufInformiert() {
        return this.isMahnwesenAktivAndHasRecht() && this.Unstimmigkeit?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_MAHNLAUF &&
            ((this.LastMahnung && this.LastMahnung.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_MAHNFRIST_ABGELAUFEN) ||
                (this.LastBusse && (this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_RECHTSMITTELFRIST_ABGELAUFEN || this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_BUSSFRIST_ABGELAUFEN))
            );
    }

    doTaskZurueckAufInformiert() {
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.ZURUECK_AUF_INFORMIERT);
    }

    // Rückgängig machen Tasks

    enableTaskAbschliessenRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && this.Unstimmigkeit?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_ABGESCHLOSSEN;
    }

    doTaskAbschliessenRueckgaengig() {
        this.RueckgaengigMode = UnstimmigkeitRueckgaengigMode.ABSCHLIESSEN;
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.RUECKGAENGIG);
    }

    enableTaskInformierenRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && this.Unstimmigkeit?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_INFORMIERT && !this.IstGemahnt && !this.IstGebuesst;
    }

    doTaskInformierenRueckgaengig() {
        this.RueckgaengigMode = UnstimmigkeitRueckgaengigMode.INFORMIEREN;
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.RUECKGAENGIG);
    }

    enableTaskMahnenRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && this.Unstimmigkeit?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_MAHNLAUF && this.LastMahnung && this.LastMahnung.Status.Id !== VwbPListDetailConst.VWBP_MAHNUNG_STATUS_ERLEDIGT;
    }

    doTaskMahnenRueckgaengig() {
        this.RueckgaengigMode = UnstimmigkeitRueckgaengigMode.MAHNEN;
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.RUECKGAENGIG);
    }


    enableTaskBuessenRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && this.LastBusse && (this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_OFFEN || this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_RECHTSMITTELFRIST_ABGELAUFEN);
    }

    doTaskBuessenRueckgaengig() {
        this.RueckgaengigMode = UnstimmigkeitRueckgaengigMode.BUESSEN;
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.RUECKGAENGIG);
    }

    enableTaskLoeschverfahrenRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && (this.Unstimmigkeit?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_AMTLICHES_LIQUIDATIONSVERFAHREN &&
            ((this.LastMahnung && this.LastMahnung.Status.Id === VwbPListDetailConst.VWBP_MAHNUNG_STATUS_ERLEDIGT) ||
                (this.LastBusse && this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_ERLEDIGT)));
    }

    doTaskLoeschverfahrenRueckgaengig() {
        this.RueckgaengigMode = UnstimmigkeitRueckgaengigMode.LOESCHVERFAHREN;
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.RUECKGAENGIG);
    }

    enableTaskEinzugsauftragRueckgaengig() {
        return this.isMahnwesenAktivAndHasRecht() && this.LastBusse && (this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_EINZUGSAUFTRAG || this.LastBusse.Status.Id === VwbPListDetailConst.VWBP_BUSS_STATUS_BUSSFRIST_ABGELAUFEN);
    }

    doTaskEinzugsauftragRueckgaengig() {
        this.RueckgaengigMode = UnstimmigkeitRueckgaengigMode.EINZUGSAUFTRAG;
        this.doTask([{ Id: this.VersionId }], UnstimmigkeitTaskMode.RUECKGAENGIG);
    }

    OnSave() {
        this.CloseDialog();
        this.LoadUnstimmigkeiten();
        this.unstimmigkeitView.refresh();
        this.OnChanged.emit(null);
    }

    CloseDialog() {
        this.selectedRT = [];
        this.taskDialog.close();
    }
}
