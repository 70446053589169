<avi-core-page
    [title]="'VWBP.PUBLIC.RT_SEARCHER_TITLE'"
    [no-box-shadow]="true"
    [card]="true"
    [collapsible]="false"
    [expanded]="true"
    [contents-padding]="true"
>
    <div style="text-align: right;">
        <button
            pButton
            type="button"
            [label]="'VWBP.PUBLIC.RT_ANFORDERN_BUTTON' | translate"
            class="p-button-secondary p-button-outlined ml-2"
            (click)="createRechtstraegerAnfordern()"
        ></button>

        <button
            pButton
            type="button"
            [label]="
                showAngeforderteRechtstraeger
                    ? ('VWBP.PUBLIC.ANGEFORDERTE_RT_AUSBLENDEN' | translate)
                    : ('VWBP.PUBLIC.ANGEFORDERTE_RT_ANZEIGEN' | translate)"
            class="p-button-secondary p-button-outlined ml-2"
            (click)="ShowHideAngeforderteRechtstraeger()"
        ></button>

        <button
            pButton
            type="button"
            [label]="
                showAbgegebeneRechtstraeger
                    ? ('VWBP.PUBLIC.ABGEGEBENE_RT_AUSBLENDEN' | translate)
                    : ('VWBP.PUBLIC.ABGEGEBENE_RT_ANZEIGEN' | translate)
            "
            class="p-button-secondary p-button-outlined ml-2"
            (click)="ShowHideAbgebebeneRechtstraeger()"
        ></button>
    </div>

    <avi-textblock [identifier]="'VWBP.PUBLIC.HINWEIS_RT_ERFASSEN'" classes="hint-text-block pb-4 pt-3"></avi-textblock>

    <avi-core-dialog
        #rechtstraegerFormDialog
        identifier="rechtstraegerFormDialog"
        [showCloseIcon]="false"
        [closeOnEscape]="false"
    >
        <vwbp-public-rechtstraegerdetail-form
            *ngIf="CreateMode === 'RTAusland'"
            #rechtstraegerForm
            (onSavedSuccessful)="onNewRTSavedSuccessful($event)"
            [redirect-after-save]="null"
            [action-buttons]="formActionButtons"
        >
        </vwbp-public-rechtstraegerdetail-form>

        <vwbp-public-rechtstraeger-importieren-form
            *ngIf="CreateMode === 'RTInland'"
            #rechtstraegerLIForm
            (onSavedSuccessful)="onNewRTSavedSuccessful($event)"
            [redirect-after-save]="null"
            mode="create"
            submit-label="Rechtsträger hinzufügen"
            [action-buttons]="formActionButtons"
        >
        </vwbp-public-rechtstraeger-importieren-form>
    </avi-core-dialog>

    <avi-core-dialog
        #rechtstraegerAnfordernDialog
        identifier="rechtstraegerAnfordernDialog"
        [showCloseIcon]="false"
        [closeOnEscape]="false"
    >
        <vwbp-public-rechtstraeger-anfordern
            #rechtstraegerAnfordernForm
            (onSavedSuccessful)="onSavedSuccessful($event)"
            [redirect-after-save]="null"
            [redirect-after-delete]="null"
            [action-buttons]="formActionButtons"
        >
        </vwbp-public-rechtstraeger-anfordern>
    </avi-core-dialog>

    <avi-core-dialog #xmlUploadDialog identifier="xmlUploadDialog" [showCloseIcon]="false" [closeOnEscape]="false">
        <vwbp-public-xmlupload-form #xmlUploadForm (onClose)="closeXMLUploadForm($event)"> </vwbp-public-xmlupload-form>
    </avi-core-dialog>

    <p-panel class="mb-20">
        <avi-core-base-searcher
            #searcher
            [hide-when-noresults]="true"
            [use-router-params]="false"
            [card]="false"
            [contents-padding]="false"
            [search-delegate]="SearchDelegate"
            [rows]="20"
            [toolbar]="false"
            [auto-search]="true"
            [show-autofilter]="true"
            [toolbar-show-inaktive]="true"
            (onInit)="onInit($event)"
            (onDoubleClick)="onDoubleClick($event)"
            (onStartNew)="onStartNew()"
            (onSelect)="onSelect($event)"
            [select-first-result]="false"
            [get-row-class]="GetRowClass"
            searcher-title=""
        >
        </avi-core-base-searcher>

        <div *ngIf="keinRechtstraegerErfasst()" class="pb-3">
            <p-message severity="error" [text]="'VWBP.PUBLIC.RT_KEINE_ERFASST' | translate" class="w-100p"></p-message>
        </div>
    </p-panel>

    <div class="pt-4" [innerHTML]="'VWBP.PUBLIC.RT_ERFASSEN_KATEGORIE' | translate">
    </div>

    <div class="grid p-fluid pt-4">
        <div class="col-12 md:col-9">
            <!-- <div class="ui-toolbar-group-left"> -->
            <button
                pButton
                type="button"
                [label]="'VWBP.PUBLIC.RT_ERFASSEN_LI_HR' | translate"
                icon="pi pi-search"
                (click)="ImportHRRechtstraeger()"
                class="w-100p-m mr-2 mb-2 p-button-primary"
            ></button>
        </div>
        <div class="col-12 md:col-3">
            <button
                pButton
                type="button"
                [label]="'VWBP.PUBLIC.RT_XML_IMPORT' | translate"
                icon="pi pi-download"
                (click)="importXML()"
                class="w-100p-m mr-2 mb-2 p-button-secondary p-button-outlined"
            ></button>
        </div>
    </div>

    <div class="col-12 pt-4">
        <div class="red-border">
            <label
                (click)="onClickManuelleErfassung($event)"
                [innerHTML]="'VWBP.PUBLIC.RT_ERFASSEN_AUSLAENDISCH' | translate"
            >
            </label>
        </div>
    </div>

    <div class="col-12 pt-4" [hidden]="!showAbgegebeneRechtstraeger && !showAngeforderteRechtstraeger">
        <span [hidden]="!showAbgegebeneRechtstraeger">
            <div class="mb-4" [hidden]="keinAbgegebeneRT()">
                <p-panel>
                    <h3>Abgegebene Rechtstr&auml;ger</h3>
                    <avi-core-base-searcher
                        #abgegebeneRtSearcher
                        (onInit)="initAbgegebeneRechtstraegerSearcher()"
                        [card]="false"
                        [use-router-params]="false"
                        [contents-padding]="false"
                        [toolbar]="false"
                        [auto-search]="false"
                        [hide-when-noresults]="false"
                        [show-autofilter]="false"
                        [search-delegate]="AbgegebeneRtSearchDelegate"
                    ></avi-core-base-searcher>
                </p-panel>
            </div>

            <div [hidden]="keinGeloeschteRT()">
                <p-panel>
                    <h3>Gelöschte Rechtstr&auml;ger</h3>
                    <avi-core-base-searcher
                        #geloeschteRtSearcher
                        (onInit)="initGeloeschteRechtstraegerSearcher()"
                        [card]="false"
                        [use-router-params]="false"
                        [contents-padding]="false"
                        [toolbar]="false"
                        [auto-search]="false"
                        [hide-when-noresults]="false"
                        [show-autofilter]="false"
                        [search-delegate]="GeloeschteRtSearchDelegate"
                    ></avi-core-base-searcher>
                </p-panel>
            </div>

            <div class="mb-4" [hidden]="keinEwrRT()">
                <p-panel>
                    <h3>Rechtstr&auml;ger eingetragen in einem anderen EWR-Register</h3>
                    <avi-core-base-searcher
                        #ewrRegisterRtSearcher
                        (onInit)="initEwrRechtstraegerSearcher()"
                        [card]="false"
                        [use-router-params]="false"
                        [contents-padding]="false"
                        [toolbar]="false"
                        [auto-search]="false"
                        [hide-when-noresults]="false"
                        [show-autofilter]="false"
                        [search-delegate]="EwrRtSearchDelegate"
                    ></avi-core-base-searcher>
                </p-panel>
            </div>

            <div *ngIf="keinGeloeschteRT() && keinAbgegebeneRT() && keinEwrRT()" class="pb-3">
                <p-message
                    severity="info"
                    [text]="'VWBP.PUBLIC.RT_KEINE_ABGEGEBENE_GELOESCHTE' | translate"
                    class="w-100p"
                ></p-message>
            </div>
        </span>

        <span [hidden]="!showAngeforderteRechtstraeger">
            <div [hidden]="keinAngeforderteRT()">
                <p-panel>
                    <h3>Angeforderte Rechtstr&auml;ger</h3>
                    <avi-core-base-searcher
                        #angeforderteRtSearcher
                        (onInit)="initAngeforderteRechtstraegerSearcher()"
                        [card]="false"
                        [use-router-params]="false"
                        [contents-padding]="false"
                        [toolbar]="false"
                        [auto-search]="false"
                        [hide-when-noresults]="false"
                        [show-autofilter]="false"
                        [search-delegate]="AngeforderteRtSearchDelegate"
                    ></avi-core-base-searcher>
                </p-panel>
            </div>

            <div *ngIf="keinAngeforderteRT()" class="pb-3">
                <p-message
                    severity="info"
                    [text]="'VWBP.PUBLIC.RT_KEINE_ANGEFORDERTE' | translate"
                    class="w-100p"
                ></p-message>
            </div>
        </span>

    </div>
</avi-core-page>
