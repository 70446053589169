import { Expose, Type } from 'class-transformer';

import { QueryDef } from '../reporting/querydef.model';
import { BarcodeDocDef } from '../reporting/barcodedocdef.model';
import { AviDocArt } from '../avidoc_def/avidocart.model';
import { AviDocKategorie } from '../avidoc_def/avidockategorie.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class AviDocTemplate extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get DefaultTitel(): string { return this.getProperty('DefaultTitel'); }
    public set DefaultTitel(value: string) { this.setProperty('DefaultTitel', value); }
    
    @Expose()
    public get DefaultAbstract(): string { return this.getProperty('DefaultAbstract'); }
    public set DefaultAbstract(value: string) { this.setProperty('DefaultAbstract', value); }
    
    @Expose()
    public get DefaultDateiname(): string { return this.getProperty('DefaultDateiname'); }
    public set DefaultDateiname(value: string) { this.setProperty('DefaultDateiname', value); }
    
    @Expose()
    public get MailMergePossible(): boolean { return this.getProperty('MailMergePossible'); }
    public set MailMergePossible(value: boolean) { this.setProperty('MailMergePossible', value); }
    
    @Expose()
    public get Beschreibung(): string { return this.getProperty('Beschreibung'); }
    public set Beschreibung(value: string) { this.setProperty('Beschreibung', value); }
    
    @Expose()
    public get AlteReferenz(): string { return this.getProperty('AlteReferenz'); }
    public set AlteReferenz(value: string) { this.setProperty('AlteReferenz', value); }
    
    @Expose()
    public get AbfrageDef_ID(): string { return this.getModelId('AbfrageDef_ID'); }
    public set AbfrageDef_ID(value: string) { this.setModelId('AbfrageDef_ID', value); }
 
    @Expose()
    @Type(() => QueryDef)
    public get AbfrageDef(): QueryDef { return this.getModel('AbfrageDef'); }
    public set AbfrageDef(value: QueryDef) { this.setModel('AbfrageDef', value); }
    
    @Expose()
    public get BarcodeDocDef_ID(): string { return this.getModelId('BarcodeDocDef_ID'); }
    public set BarcodeDocDef_ID(value: string) { this.setModelId('BarcodeDocDef_ID', value); }
 
    @Expose()
    @Type(() => BarcodeDocDef)
    public get BarcodeDocDef(): BarcodeDocDef { return this.getModel('BarcodeDocDef'); }
    public set BarcodeDocDef(value: BarcodeDocDef) { this.setModel('BarcodeDocDef', value); }
    
    @Expose()
    public get TemplateDocArt_ID(): string { return this.getModelId('TemplateDocArt_ID'); }
    public set TemplateDocArt_ID(value: string) { this.setModelId('TemplateDocArt_ID', value); }
 
    @Expose()
    @Type(() => AviDocArt)
    public get TemplateDocArt(): AviDocArt { return this.getModel('TemplateDocArt'); }
    public set TemplateDocArt(value: AviDocArt) { this.setModel('TemplateDocArt', value); }
    
    @Expose()
    public get ErstellungDocArt_ID(): string { return this.getModelId('ErstellungDocArt_ID'); }
    public set ErstellungDocArt_ID(value: string) { this.setModelId('ErstellungDocArt_ID', value); }
 
    @Expose()
    @Type(() => AviDocArt)
    public get ErstellungDocArt(): AviDocArt { return this.getModel('ErstellungDocArt'); }
    public set ErstellungDocArt(value: AviDocArt) { this.setModel('ErstellungDocArt', value); }
    
    @Expose()
    public get ErstellungDocKategorie_ID(): string { return this.getModelId('ErstellungDocKategorie_ID'); }
    public set ErstellungDocKategorie_ID(value: string) { this.setModelId('ErstellungDocKategorie_ID', value); }
 
    @Expose()
    @Type(() => AviDocKategorie)
    public get ErstellungDocKategorie(): AviDocKategorie { return this.getModel('ErstellungDocKategorie'); }
    public set ErstellungDocKategorie(value: AviDocKategorie) { this.setModel('ErstellungDocKategorie', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DeleteEmptyFields(): ListType { return this.getProperty('DeleteEmptyFields'); }
    public set DeleteEmptyFields(value: ListType) { this.setProperty('DeleteEmptyFields', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Status(): ListType { return this.getProperty('Status'); }
    public set Status(value: ListType) { this.setProperty('Status', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AdrBlockPartnerAuswahl(): ListType { return this.getProperty('AdrBlockPartnerAuswahl'); }
    public set AdrBlockPartnerAuswahl(value: ListType) { this.setProperty('AdrBlockPartnerAuswahl', value); }
    
}
