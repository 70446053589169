import { Component, Input, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService, AviBaseSearcherComponent, AviSearcherColumn, AviDialogComponent, AviAuthService } from '@avi-x/avi-core';
import { VwbPVerlauf } from '../../../dto/vwbpverlauf.model';
import { VwbPInternVerlaufFormComponent } from '../verlauf-form/verlauf-form.component';
import { VwbPListDetailConst, VwbPRoleConst } from '@avi-x/vwbp-public';

@Component({
    selector: 'vwbp-intern-verlauf-searcher',
    templateUrl: './verlauf-searcher.component.html',
    styleUrls: ['./verlauf-searcher.component.scss'],
    providers: [DecimalPipe, PercentPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternVerlaufSearcherComponent {
    @ViewChild('verlaufDialog', { static: false })
    verlaufDialog: AviDialogComponent;

    @ViewChild('verlaufForm', { static: false })
    verlaufForm: VwbPInternVerlaufFormComponent;

    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    _readonly: boolean = false;
    public get Readonly() {
        return this._readonly;
    }

    @Input('readonly')
    public set Readonly(value: boolean) {
        this._readonly = value;
    }

    _RechtstraegerId: string = null;
    public get RechtstraegerId(): string {
        return this._RechtstraegerId;
    }

    @Input('rechtstraeger-id')
    public set RechtstraegerId(value: string) {
        this._RechtstraegerId = value;
    }

    FormModelId: string = null;
    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => this.closeForm() }];

    constructor(public apiService: AviApiService, public router: Router, public commonService: AviCommonService, public authService: AviAuthService, private cdr: ChangeDetectorRef) {
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => this.doSearch(searchConfig);

    async doSearch(searchConfig) {
        let res = await this.apiService.getModelList(VwbPVerlauf, `v1/intern/vwbp/verlauf/rechtstraeger/${this.RechtstraegerId}`);

        res = this.searcher.ApplyColumnFilters(res);
        res = this.searcher.ApplyColumnSorting(res);

        return res;
    }

    OnCollapsedChanged(data) {
        if (!data && !this.searcher._initialSearchDone) {
            this.forceRefresh();
        }
    }

    onSearcherInit(data) {
        this.searcher.beginInit();
        this.searcher.addTextColumn('ErstelltVon', 'Sachbearbeiter', true).setSortable(false);
        this.searcher.addDateColumn('Datum', 'Datum', 'short', true, '120px').setSortable(false);

        this.searcher.addListTypeColumn('Event', 'Event', 'vwbp_event', false, true, null, 'v1/vwbp').setSortable(false);

        this.searcher.addTextColumn('Beschreibung', 'Beschreibung', true).setSortable(false);
        this.searcher.addIntegerColumn('Laufnummer', 'Version', false).setWidth('90px');

        this.searcher.addColumn(
            AviSearcherColumn.CreateCustom(
                'Bemerkung',
                'Verlaufskommentar',
                (row, col) => {
                    if (row['Bemerkung'])
                        return `<div>${this.commonService.nl2br(this.commonService.escapeHtml(row['Bemerkung']))}</div>`;

                    return this.commonService.nl2br(this.commonService.escapeHtml(row['Bemerkung']));
                },
                true
            )
                .setSortable(false)
                .setFormatTitleDelegate((row) => this.commonService.escapeHtml(row['Bemerkung']))
        );


        this.searcher.addColumn(
            AviSearcherColumn.CreateButton('btnAct', 'Aktionen', null, null, null, null, '90px', ['edit', 'delete'])
                .setButtonOnClickDelegate((row, col, buttonId) => {
                    if (buttonId === 'edit')
                        this.editVerlaufseintrag(row['Id']);
                    else
                        this.deleteVerlaufseintrag(row['Id']);
                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-flat': true,
                        'p-button-text': true,
                        'hidden': buttonId === 'delete' && (row['Event'].Id === VwbPListDetailConst.VWBP_EVENT_STATUSAENDERUNG || !this.authService.currentUserHasRole(VwbPRoleConst.VWBP_AJU_PLUS))
                    };
                })
                .setButtonIconDelegate((row, col, buttonId) => {
                    if (buttonId === 'edit')
                        return 'pi pi-pencil';
                    else
                        return 'pi pi-trash';
                })
                .setCellStyleDelegate(() => {
                    return { 'text-align': 'left' };
                })
                .setSortable(false)
        );

        this.searcher.getColumn('btnAct').Visible = !this._readonly;

        this.searcher.endInit();
    }

    getVerlaufskommentar(kommentar: string) {
        return kommentar?.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    deleteVerlaufseintrag(id) {
        if (this.authService.currentUserHasRole(VwbPRoleConst.VWBP_AJU_PLUS)) {
            this.commonService.confirmDelete(`Möchten Sie den Verlaufseintrag wirklich löschen?`,
                () => {
                    this.apiService.delete(`v1/intern/vwbp/verlauf/${id}`).then(r => {
                        this.commonService.notificateSuccess('Gelöscht');
                        this.forceRefresh();
                    });
                }
            );
        }
    }

    forceRefresh() {
        this.searcher.forceRefresh();
    }

    editVerlaufseintrag(id) {
        this.FormModelId = id;
        this.verlaufDialog.open();
    }

    createVerlaufseintrag(id) {
        this.FormModelId = '0';
        this.verlaufDialog.open();
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.verlaufDialog.close();
    }
}
