import { Injectable } from '@angular/core';
import { RouterOutlet, Router, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AviAnimationService {

    private currentAnimation: any = null;

    private previousUrl: string;
    private currentUrl: string;

    constructor(private router: Router) {
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;

                let transitionName = 'section';

                const isSame = this.previousUrl === this.currentUrl;
                const isBackward = this.previousUrl && this.previousUrl.startsWith(this.currentUrl);
                const isForward = this.currentUrl && this.currentUrl.startsWith(this.previousUrl);

                if (isSame) {
                    transitionName = 'none';
                } else if (isBackward && isForward) {
                    transitionName = 'initial';
                } else if (isBackward) {
                    transitionName = 'backward';
                } else if (isForward) {
                    transitionName = 'forward';
                }

                this.currentAnimation = transitionName;
            }
        });
    }

    getCurrentAnimation(routerOutlet: RouterOutlet) {
        return this.currentAnimation;
    }
}
