<div class="card-contents">
    <p-panel
        header="Verlauf"
        [toggleable]="true"
        [collapsed]="true"
        expandIcon="pi pi-chevron-down"
        collapseIcon="pi pi-chevron-up"
        toggler="header"
        (collapsedChange)="OnCollapsedChanged($event)"
    >
        <div *ngIf="!Readonly" class="ui-g">
            <div class="ui-g-12 ui-md-12 pl-0 pt-0 pb-0">
                <div>
                    <button
                        pButton
                        icon="pi pi-list"
                        type="button"
                        style="width: auto;"
                        class="mr-2"
                        (click)="createVerlaufseintrag($event)"
                        label="Verlaufeintrag erfassen"
                        title="Verlaufeintrag"
                    ></button>
                </div>
            </div>
        </div>

        <div class="mt-2 pb-3">
            <avi-core-base-searcher
                #searcher
                [contents-padding]="false"
                [use-router-params]="false"
                [search-delegate]="SearchDelegate"
                [rows]="0"
                [auto-search]="false"
                [show-autofilter]="true"
                [toolbar]="false"
                [toolbar-show-searchinput]="false"
                [toolbar-show-advanced]="false"
                [lazy-loading]="true"
                [card]="false"
                (onInit)="onSearcherInit($event)"
                searcher-title="Verlauf"
                [show-totalrecords]="false"
                [select-first-result]="false"
                [row-detailview-template]="rowdetailview"
            >
            </avi-core-base-searcher>
        </div>
    </p-panel>
</div>

<ng-template #rowdetailview let-record>
    <ng-container>
        <div class="pt-2">
            <vwbp-intern-verlauf-form
                class="summary"
                [card]="false"
                [model-id]="record['Id']"
                [readonly]="true"
            ></vwbp-intern-verlauf-form>
        </div>
    </ng-container>
</ng-template>

<avi-core-dialog #verlaufDialog identifier="verlaufDialog" [showCloseIcon]="false" [closeOnEscape]="false">
    <vwbp-intern-verlauf-form
        #verlaufForm
        [model-id]="FormModelId"
        [rechtstraeger-id]="RechtstraegerId"
        (onSavedSuccessful)="onSavedSuccessful($event)"
        [action-buttons]="formActionButtons"
    >
    </vwbp-intern-verlauf-form>
</avi-core-dialog>
