import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ParentIdResolver implements Resolve<string> {
    constructor() {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<string> {
         const idProperty = route.data['resolveId'] || 'id';
         const id: string = route.parent.params[idProperty];
         return Promise.resolve(id);

    }
}
