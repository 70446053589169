<avi-core-page
    [title]="'CORE.COMMON.MODEL.AVIDOC.SIGNIEREN_TITLE' | translate"
    [card]="true"
    [collapsible]="false"
    [expanded]="true"
    [contents-padding]="true"
    [no-box-shadow]="true"
>

    <p-fileUpload #inputFile
        mode="advanced"
        name="file"
        (onSelect)="onSelect($event)"
        (onRemove)="onRemove($event)"
        (onClear)="onClear($event)"
        [multiple]="false"
        [showUploadButton]="false"
        [chooseLabel]="'CORE.COMMON.MODEL.AVIDOC.DATEI_WAEHLEN' | translate"
        [cancelLabel]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
        [showCancelButton]="false"
        [customUpload]="true"
        accept=".pdf"
        [maxFileSize]="10000000"
    >
    </p-fileUpload>

    <div class="grid justify-content-end mt-4">
        <button
            pButton
            type="button"
            [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
            (click)="doClose()"
            class="w-100p-m m mr-4p p-button-secondary"
        ></button>
        <button
            pButton
            type="button"
            [label]="'CORE.COMMON.MODEL.AVIDOC.SIGNIEREN_BUTTON' | translate"
            [disabled]="File == null"
            (click)="doSignieren()"
            class="w-100p-m m mr-4p"
        ></button>
    </div>

</avi-core-page>
