import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AviCanActivateWhenLoggedInService } from '@avi-x/avi-core';

export const routes: Routes = [
    // {
    //     path: '',
    //     canActivate: [AviCanActivateWhenLoggedInService],
    //     children: [

    //         { path: '', component: AviVwbPRechtstraegerSearcherComponent, pathMatch: 'full' },
    //     ]
    // }
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules });
