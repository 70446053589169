<div *ngIf="!showSuccessMessage; else successMessage ">

    <avi-core-base-form #form
        [readonly]="false"
        [card]="Card"
        [contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="'VWBP.PUBLIC.RT_ANFORDERN_TITLE'"
        [Model]="Model"
        [loading]="Loading"
        [save-button-icon]="null"
        [save-button-label]="'VWBP.PUBLIC.RT_ANFORDERN_BUTTON'"
        (onSave)="saveModel($event)"
        [action-buttons]="actionButtons">

        <div header>
            <avi-textblock [identifier]="'vwbp-rechtstraeger-anfordern-header'" class="mb-2"></avi-textblock>
        </div>

    </avi-core-base-form>
</div>

<ng-template #successMessage>
    <avi-core-page [card]="Card" [no-box-shadow]="true"
        [title]="'VWBP.PUBLIC.RT_ANFORDERN_TITLE'"
        [contents-padding]="ContentsPadding">
        <div class="success-message-box p-12">
            <avi-textblock [identifier]="'vwbp-rechtstraeger-anfordern-erfolgreich'" [params]="{ rt: this.Model.Name}" class="mb-2"></avi-textblock>
        </div>

        <div class="mt-2">
            <button pButton
                (click)="onBtnClosePress()"
                label="Schliessen"></button>
        </div>

    </avi-core-page>
</ng-template>
