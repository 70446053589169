import { GenericRef } from './../shared/genericref.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { Expose, Type } from 'class-transformer';
import TransformDate from '../shared/transformdate';

export enum AttrType
{
    Text,
    Bool,
    DateTime,
    Date,
    Guid,
    Decimal,
    Int,
    List,
    Ref
}

export class AttrValueDto {
    @Expose()
    AttrType: AttrType;

    @Expose()
    TextValue: string;

    @Expose()
    BoolValue: boolean;

    @Expose()
    @Type(() => Date)
    DateTimeValue: Date;

    @Expose()
    @TransformDate()
    DateValue: Date;

    @Expose()
    DecimalValue: number;

    @Expose()
    IntValue: number;

    @Expose()
    GuidValue: string;

    @Expose()
    @Type(() => ListType)
    ListValue: ListType;

    @Expose()
    @Type(() => GenericRef)
    RefValue: GenericRef;

    @Expose()
    RefValueBez: string;
}

export class AttrChangeDto {

    @Expose()
    Name: string;

    @Expose()
    @Type(() => AttrValueDto)
    ValueOld: AttrValueDto;

    @Expose()
    @Type(() => AttrValueDto)
    ValueNew: AttrValueDto;
}

export class VerlaufDto {
    @Expose()
    public Id: string;

    @Expose()
    public ErstellUser: string;

    @Expose()
    public Username: string;

    @Expose()
    @Type(() => Date)
    public Datum: Date;

    @Expose()
    public Event_ID: string;

    @Expose()
    public EventBez: string;

    @Expose()
    public Bemerkung: string;

    @Expose()
    @Type(() => GenericRef)
    public Kontext: GenericRef;

    @Expose()
    @Type(() => GenericRef)
    public Kontext2: GenericRef;

    @Expose()
    @Type(() => AttrChangeDto)
    public Changes: AttrChangeDto[];
}
