import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'avi-core-page',
    styleUrls: ['./page.directive.scss'],
    templateUrl: './page.directive.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviPageComponent {
    @Input('navigate-back')
    public ShowNavigateBack: boolean = false;

    Title: string = null;

    @Input() get title(): string {
      return this.Title;
    }
    set title(value: string) {
        this.Title = value;
        this.elementRef.nativeElement.title = ''; // prevent tooltip
    }

    @Input('icon')
    public Icon: string = null;

    @Input('card')
    public Card: boolean = true;

    @Input('card-content-class')
    public CardContentClass: boolean = true;

    @Input('no-box-shadow')
    public NoBoxShadow: boolean = false;

    @Input('fill-height')
    public FillHeight: boolean = false;

    @Input('style')
    public Style: any = null;

    @Input('class')
    public Class: any = null;

    @Input('collapsible')
    public Collapsible: boolean = false;

    @Input('expanded')
    public Expanded: boolean = true;

    @Input('contents-template')
    public contents: TemplateRef<any>;

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('show-fullscreen-icon')
    public ShowFullscreenIcon: boolean = false;

    @Input('show-refresh-button')
    public ShowRefreshButton: boolean = false;

    @Input('buttons-template')
    // eslint-disable-next-line camelcase
    public buttons_template: TemplateRef<any>;

    @Output('onRefresh')
    public OnRefresh: EventEmitter<any> = new EventEmitter();

    PageFullscreen: boolean = false;

    constructor(private location: Location, private elementRef: ElementRef) {
    }

    public getStyle(): any {
        if (this.Style != null && this.Expanded)
            return this.Style;

        return null;
    }

    public getClass(): any {
        const ret = {
            'full-height': this.FillHeight,
            'overflow-visible': this.Expanded,
            'mat-elevation-z0': !this.Card || this.NoBoxShadow,
            'no-box-shadow': !this.Card || this.NoBoxShadow,
            'inherit-background': !this.Card,
            'card-container': this.Card && this.ContentsPadding,
            'avi-page': true,
            'fullscreen': this.PageFullscreen
        };
        return { ...ret, ...this.Class };
    }

    NavigateBack() {
        this.location.back();
    }

    toggleFullscreen(e) {
        this.PageFullscreen = !this.PageFullscreen;
        // this.FullscreenChanged.emit(this.PanelFullscreen);
        e.stopPropagation();
    }

    onRefresh(e) {
        this.OnRefresh.emit(null);
        e.stopPropagation();
    }
}
