import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppUserGroupLink } from '@avi-x/avi-dto/system/appusergrouplink.model';
import { AppUserGroupLinkAddDto } from '@avi-x/avi-dto/system/appusergrouplinkadddto.model';
import { AviAppUserGroupLogEntryFormComponent } from './appusergrouplogentry-form.component';

const nameof = (nameFunction: ((obj: AppUserGroupLink) => any)) => typed_nameof<AppUserGroupLink>(nameFunction);

@Component({
    selector: 'avi-appusergrouplink-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [validation-delegate]="ValidateData"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

        <div groupTemplate1>
            <avi-appusergrouplogentry-form [card]="false" [contents-padding]="false" [readonly]="false" [model-id]="'0'" [enable-taskmenu]="false" ></avi-appusergrouplogentry-form>
        </div>

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAppUserGroupLinkFormComponent extends AviAbstractModelFormComponent<AppUserGroupLink> {  
    ClassName = 'CORE.COMMON.MODEL.APPUSERGROUPLINK';
    BaseUrl = 'appusergroup/link';
    ClassType = AppUserGroupLink;
	FormType = AviAppUserGroupLinkFormComponent;

    @ViewChild(AviAppUserGroupLogEntryFormComponent)
    logEntryComponent: AviAppUserGroupLogEntryFormComponent;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'AppUserGroup';
    }


    public async afterCreateModel(bm: AppUserGroupLink) {
        bm.AppUserGroupRef_ID = this.ContextId;
    }
	
    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateStep("CORE.COMMON.MODEL.APPUSERGROUPLINK.STEP_USER"));
        //this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.AppUserRef_ID), 'CORE.COMMON.MODEL.APPUSERGROUPLINK.APPUSERREF', `model/representations/AppUser${this.ReadOnly ? '?id=$id' : ''}`, true, false, null, (m) => (`${m.Representation}`), "Id", true, this.ReadOnly).setMDSizeFull());
        if (this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AppUserRef_ID), 'CORE.COMMON.MODEL.APPUSERGROUPLINK.APPUSERREF', true));
        else
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.AppUserRef_ID), 'CORE.COMMON.MODEL.APPUSERGROUPLINK.APPUSERREF', `appuser/filteredbyusergroup/${this.ContextId}`, true, false, null, (m) => (`${m.Representation}`), "Id", true, this.ReadOnly).setMDSizeFull());
        
        this.Fields.push(this.formFieldService.CreateStep("CORE.COMMON.MODEL.APPUSERGROUPLINK.STEP_LOG"));
        this.Fields.push(this.formFieldService.CreateGroupTemplate(1));
    }

    ValidateData = () => {
        return this.logEntryComponent && this.logEntryComponent.Form.isFormValid();
    }

    saveModel(model: AppUserGroupLink) {
        let saveDto = new AppUserGroupLinkAddDto();
        saveDto.AppUserGroupLink = this.Model;
        saveDto.LogEntry = this.logEntryComponent.Model;
        saveDto.LogEntry.vonAppUserGroup_ID = this.Model.AppUserGroupRef_ID;
       
        this.saveDto(model.Id, saveDto);
    }
}
