import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviDocPropertyFormComponent, DocPropertyMode } from './docproperty-form.component';
import { DocPropertySearchResponseDto } from '@avi-x/avi-dto/document_def/docpropertysearchresponsedto.model';

const nameof = (nameFunction: ((obj: DocPropertySearchResponseDto) => any)) => typed_nameof<DocPropertySearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-docproperty-searcher',
    templateUrl: './docproperty-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocPropertySearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFDOCPROPERTY';
    SettingsKey = 'settings.searcher.docproperty';
    BaseUrl = 'documentdef/docproperty';
	FormType = AviDocPropertyFormComponent;

    @Input('mode') Mode: DocPropertyMode = DocPropertyMode.Normal;

    CanEdit: boolean = false;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFDocProperty schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();

        searcher.addListTypeColumn(nameof(c => c.DocPropType), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.DOCPROPTYPE', 'caf_docprop_typ', true, true);
        searcher.addTextColumn(nameof(c => c.PathToObject), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.PATHTOOBJECT', true);
        
        searcher.addTextColumn(nameof(c => c.AttributName), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.ATTRIBUTNAME', true);

        searcher.addTextColumn(nameof(c => c.ModelMetaName), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.MODELMETANAME', true);

        if (this.Mode === DocPropertyMode.Standalone)
            searcher.addTextColumn(nameof(c => c.PropertyName), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.PROPERTYNAME', true);
                       
		searcher.endInit();
    }
    
    getParams(): { [k: string]: any; } {
        return { 'Mode': this.Mode };
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(DocPropertySearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
