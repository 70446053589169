import { Injectable } from '@angular/core';
import { ZustandInfoDto } from '@avi-x/avi-dto/shared/zustandinfo.model';
import { ObjectUtils } from '../components/utils/object-utils';
import { AviFormField } from '../components/base-form/form-field';

@Injectable()
export class AviHistoryService {

    constructor() {
    }

    public clearHistoryHighlighting(fields: AviFormField[]) {
        fields.forEach(w => w.CustomClass = null);
    }

    public handleHistoryHighlighting(fields: AviFormField[], model1: any, model2: any) {

        fields.forEach(w => {
            w.CustomClass = null;
            if (!ObjectUtils.equalsByValue(ObjectUtils.getByPath(model1, w.Name), ObjectUtils.getByPath(model2, w.Name)))
                w.CustomClass = () => { return { 'history-highlight': true }; };
            else
                w.CustomClass = null;
        });
    }

    public getCurrentHistory(historyStates: ZustandInfoDto[], stichtag: Date): ZustandInfoDto {
        if (historyStates) {
            const res = historyStates.find(w => w.ZustandAb <= stichtag && w.ZustandBis >= stichtag);

            return res ?? historyStates[historyStates.length - 1];
        }

        return null;
    }

    public GetPreviousHistoryState(historyStates: ZustandInfoDto[], currentHistory: ZustandInfoDto): ZustandInfoDto {

        return historyStates.slice().reverse().find(w => w.ZustandAb < currentHistory.ZustandAb);
    }

    public GetNextHistoryState(historyStates: ZustandInfoDto[], currentHistory: ZustandInfoDto): ZustandInfoDto {
        return historyStates.find(w => w.ZustandAb > currentHistory.ZustandAb);
    }

    public GetLastHistoryState(historyStates: ZustandInfoDto[]): ZustandInfoDto {
        return historyStates[historyStates.length - 1];
    }
}
