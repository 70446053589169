import { Expose, Type } from 'class-transformer';
import { AviDocKeywordDef } from '../avidoc_def/avidockeyworddef.model';

import { BaseModel } from '../shared/basemodel.model';
import { GenericRef } from '../shared/genericref.model';
import { ListDetail } from '../system/listdetail.model';

export class AviDocumentKeywordContainer extends BaseModel {
    @Expose()
    public get ValueInt(): number { return this.getProperty('ValueInt'); }
    public set ValueInt(value: number) { this.setProperty('ValueInt', value); }

    @Expose()
    public get ValueDec(): number { return this.getProperty('ValueDec'); }
    public set ValueDec(value: number) { this.setProperty('ValueDec', value); }

    @Expose()
    public get ValueText(): string { return this.getProperty('ValueText'); }
    public set ValueText(value: string) { this.setProperty('ValueText', value); }

    @Expose()
    @Type(() => Date)
    public get ValueDate(): Date { return this.getProperty('ValueDate'); }
    public set ValueDate(value: Date) { this.setProperty('ValueDate', value); }

    @Expose()
    public get ValueGuid(): string { return this.getProperty('ValueGuid'); }
    public set ValueGuid(value: string) { this.setProperty('ValueGuid', value); }

    @Expose()
    public get ValueList_ID(): string { return this.getModelId('ValueList_ID'); }
    public set ValueList_ID(value: string) { this.setModelId('ValueList_ID', value); }

    @Expose()
    @Type(() => ListDetail)
    public get ValueList(): ListDetail { return this.getModel('ValueList'); }
    public set ValueList(value: ListDetail) { this.setModel('ValueList', value); }

    @Expose()
    @Type(() => AviDocKeywordDef)
    public get DocKeywordDef(): AviDocKeywordDef { return this.getModel('DocKeywordDef'); }
    public set DocKeywordDef(value: AviDocKeywordDef) { this.setModel('DocKeywordDef', value); }

    @Expose()
    @Type(() => GenericRef)
    public get ValueRef(): GenericRef { return this.getProperty('ValueRef'); }
    public set ValueRef(value: GenericRef) { this.setProperty('ValueRef', value); }
}
