import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
    selector: 'avi-confirm-form',
    template: `
        <div>
            <div class="confirm-form-icon-container">
                <i [ngClass]="'confirm-form-icon'" [class]="icon" *ngIf="icon"></i>
                <avi-textblock-span [identifier]="message" [params]="messageData"></avi-textblock-span>
            </div>

            <div class="p-dialog-footer p-0">
            <button
                type="button"
                pRipple
                pButton
                [icon]="rejectIcon"
                [label]="rejectButtonLabel | translate"
                (click)="reject()"
                [ngClass]="'p-confirm-dialog-reject'"
                [class]="rejectButtonStyleClass"
                *ngIf="rejectVisible"
                [attr.aria-label]="rejectAriaLabel"
            ></button>
            <button
                type="button"
                pRipple
                pButton
                [icon]="acceptIcon"
                [label]="acceptButtonLabel | translate"
                (click)="accept()"
                [ngClass]="'p-confirm-dialog-accept'"
                [class]="acceptButtonStyleClass"
                *ngIf="acceptVisible"
                [attr.aria-label]="acceptAriaLabel"
            ></button>
            </div>
        </div>
    `,
    styles: [
        `.confirm-form-icon {
            font-size: 2rem;
        }
        
        .confirm-form-icon-container {
            display: flex;
            align-items: center;
            column-gap: 0.75rem;
            margin-bottom: 1.5rem;
        }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviConfirmFormComponent implements OnInit {  
    @Input() icon: string;

    @Input() message: string;

    @Input() messageData: { [klass: string]: any };

    @Input() acceptIcon: string = 'pi pi-check';

    @Input() acceptLabel: string;

    @Input() acceptAriaLabel: string;

    @Input() acceptVisible: boolean = true;

    @Input() rejectIcon: string = 'pi pi-times';

    @Input() rejectLabel: string;

    @Input() rejectAriaLabel: string;

    @Input() rejectVisible: boolean = true;

    @Input() acceptButtonStyleClass: string;

    @Input() rejectButtonStyleClass: string;


    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public translateService: TranslateService,
		public cdr: ChangeDetectorRef) {
    }
    
    get acceptButtonLabel(): string {
        return this.acceptLabel || 'CORE.COMMON.BESTAETIGEN_BUTTON';
    }

    get rejectButtonLabel(): string {
        return this.rejectLabel || 'CORE.COMMON.ABBRECHEN_BUTTON';
    }

    ngOnInit() {
        if (this.config && this.config.data) {
            if (this.config.data)
            {
                this.icon = this.config.data.icon || 'fa fa-question-circle';
                this.message = this.config.data.message;
                this.messageData = this.config.data.messageData;
                this.acceptIcon = this.config.data.acceptIcon;
                this.acceptLabel = this.config.data.acceptLabel;
                this.acceptAriaLabel = this.config.data.acceptAriaLabel;
                this.acceptVisible = this.config.data.acceptVisible;
                this.rejectIcon = this.config.data.rejectIcon;
                this.rejectLabel = this.config.data.rejectLabel;
                this.rejectAriaLabel = this.config.data.rejectAriaLabel;
                this.rejectVisible = this.config.data.rejectVisible;
                this.acceptButtonStyleClass = this.config.data.acceptButtonStyleClass;
                this.rejectButtonStyleClass = this.config.data.rejectButtonStyleClass;
            }
        }
    }

    accept() {
        if (this.ref)
            this.ref.close(true);
    }

    reject() {
        if (this.ref)
            this.ref.close(false);
    }
}
