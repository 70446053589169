import { TreeModule } from 'primeng/tree';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MatTabsModule } from '@angular/material/tabs';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { BadgeModule } from 'primeng/badge';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatChipsModule } from '@angular/material/chips';
import { AviSortByPipe } from './shared/pipes/sortByPipe';
import { NgModule, Optional, SkipSelf, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';

// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { TranslateModule } from '@ngx-translate/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { ListboxModule } from 'primeng/listbox';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { EditorModule } from 'primeng/editor';
import { TooltipModule } from 'primeng/tooltip';
import { SpinnerModule } from 'primeng/spinner';
import { FileUploadModule } from 'primeng/fileupload';
import { StepsModule } from 'primeng/steps';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PaginatorModule } from 'primeng/paginator';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DragDropModule } from 'primeng/dragdrop';

import { AviCoreConfig, AviCoreOAuthConfigs } from './avi-core.config';

import { AviLoginComponent } from './components/login/login.component';
import { AviAnimationService } from './services/animation.service';
import { AviRouterHistoryService } from './services/router-history.service';
import { AviCommunicationService } from './services/communication.service';

import { AviDebugComponent } from './directives/debug.directive';
import { AviThrottledClickComponent } from './directives/throttled-click.directive';
import { AviFullDialogDirectiveComponent } from './directives/fulldialog.directive';
import { AviFocusCalDirective, AviFocusDirective, AviFocusDropdownDirective } from './directives/focus.directive';
import { AviLoaderComponent } from './directives/loader.directive';
import { AviPageComponent } from './components/page/page.directive';
import { AviDialogComponent } from './components/dialog/dialog.component';
import { AviPdfViewerComponent } from './shared/pdf-viewer/pdf-viewer.component';
import { AviBaseFormComponent } from './components/base-form/base-form.component';
import { AviProfileComponent } from './components/profile/profile.component';
import { AviBaseSearcherComponent, AviSortIconComponent } from './components/base-searcher/base-searcher.component';

import { AviDateFormatPipe } from './shared/pipes/dateFormatPipe';
import { AviSafeHtmlPipe } from './shared/pipes/safeHtmlPipe';
import { CommonModule } from '@angular/common';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { InputmaskService } from './services/inputmask.service';
import { MatDialogModule } from '@angular/material/dialog';
import { AviFavFormDialogComponent } from './components/favoriten/avi-fav-form.component';
import { AviFormFieldService } from './services/form-field.service';
import { AviDocumentService } from './services/document.service';
import { AviCoreDocsOpenComponent } from './components/documents/avi-core-docs-open/avi-core-docs-open.component';
import { AviCoreDocDialogComponent } from './components/documents/avi-core-doc-dialog/avi-core-doc-dialog.component';
import { AviDropdownComponent } from './components/controls/dropdown/dropdown.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TableService, QuickToolbarService, RichTextEditorAllModule, RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
//import { DialogModule } from 'primeng/dialog';

import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { AviRichTextEditorComponent } from './components/controls/richtexteditor/richtexteditor.component';

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AviViewPanelComponent } from './components/view-panel/view-panel.component';
import { GridsterModule } from 'angular-gridster2';
import { AviBaseFormDesignerComponent } from './components/base-form-designer/base-form-designer.component';
import { AviHistoryControlComponent } from './components/controls/historycontrol/historycontrol.component';
import { AviHistoryService } from './services/history.service';
// import { WinAuthInterceptor } from './interceptors/winauth-interceptor';
import { OAuthModule } from 'angular-oauth2-oidc';
import { init_app, OAuthConfigService } from './auth/oauthconfig.service';
import { AviJobDialogComponent } from './components/job-dialog/job-dialog.component';
import { AviJobService } from './services/job.service';
import { AviDragDropDirective } from './directives/dragdrop.directive';
import { AviActionButtonsComponent } from './components/action-buttons/action-buttons.component';
import { AviAboutComponent } from './components/about/about.component';
import { AviCalendarComponent } from './components/controls/calendar/calendar.component';

import { AviPhoneNumberValidator } from './shared/validators/phonenumber.validator';
import { AviNoHighLowDateValidator } from './shared/validators/nohighlowdate.validator';
import { AviSerializationOverviewComponent } from './components/serialization/serialization-overview/serialization-overview.component';
import { AviSerializationImportComponent } from './components/serialization/serialization-import/serialization-import.component';
import { AviSerializationExportComponent } from './components/serialization/serialization-export/serialization-export.component';
import { AviSysMessageFormComponent } from './components/sysmessage/sysmessage-form/sysmessage-form.component';
import { AviSysMessageSearcherComponent } from './components/sysmessage/sysmessage-searcher/sysmessage-searcher.component';
import { AviSysMessageMonitorComponent } from './components/sysmessage/sysmessage-monitor/sysmessage-monitor.component';
import { AviThrottledOnClickComponent } from './directives/throttled-onclick.directive';
import { MenuModule } from 'primeng/menu';
import { InputNumberModule } from 'primeng/inputnumber';
import { HttpRetryInterceptor } from './interceptors/http-retry-interceptor';
import { AviListDetailFormComponent } from './components/list/components/listdetail-form.component';
import { AviListDetailSearcherComponent } from './components/list/components/listdetail-searcher.component';
import { AviSessionControllerComponent } from './components/session-controller/session-controller.component';
import { AviSessionControllerService } from './services/session-controller.service';
import { AviTabPanel } from './components/controls/tabview/tabpanel.component';
import { AviTabView } from './components/controls/tabview/tabview.component';
import { AviLineBreakPipe } from './shared/pipes/lineBreakPipe';
import { AviLazyLoaderComponent } from './components/lazy-loader/lazy-loader.components';
import { AviBookmarkFormComponent } from './components/bookmark/bookmark-form.component';
import { AviBookmarkService } from './services/bookmark.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AviFormatAsHtmlPipe } from './shared/pipes/text2html.pipe';
import { AviTextDiffComponent } from './components/controls/text-diff/text-diff.component';
import { AviTextDiffContainerDirective } from './components/controls/text-diff/text-diff-container.directive';
import { AviFileUploadComponent } from './components/controls/file-upload-button/file-upload-button.component';
import { AviRoundingService } from './services/rounding.service';
import { AviTextblockComponent, AviTextblockSpanComponent } from './components/textblock/textblock.component';
import { AviTextblockService } from './components/textblock/textblock.service';
import { AviAdminTextblockComponent } from './components/textblock/admin-textblock.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AviResourceFormComponent } from './components/resource/resource-form/resource-form.component';
import { AviResourceSearcherComponent } from './components/resource/resource-searcher/resource-searcher.component';
import { AppCodeComponent } from './components/app-code/app-code.component';
import { AviSysParameterFormComponent } from './components/sysparameter/components/sysparameter-form.component';
import { AviSysParameterSearcherComponent } from './components/sysparameter/components/sysparameter-searcher.component';
import { AviListAdminComponent } from './components/list/components/list-admin.component';
import { AviConfirmFormComponent } from './components/controls/confirm-form/confirm-form.components';
import { AviCalendarMaskDirective } from './directives/calendar-mask.directive';
import { DividerModule } from 'primeng/divider';
import { AviMetaBrowserComponent } from './components/meta-browser/meta-browser.component';
import { AviSafeUrlPipe } from './shared/pipes/safeUrlPipe';
import { AviMultiSelectionComponent } from './components/controls/multiselection/multiselection.component';
import { AviJobDefSearcherComponent } from './components/joblogging/jobdef-searcher.component';
import { AviJobDefFormComponent } from './components/joblogging/jobdef-form.component';
import { AviJobSearcherComponent } from './components/joblogging/job-searcher.component';
import { AviJobFormComponent } from './components/joblogging/job-form.component';
import { AviJobIndexComponent } from './components/joblogging/job-index.component';
import { AviJobLoggingSearcherWithDetailComponent } from './components/joblogging/joblogging-searcherwithdetail.component';
import { AviJobLoggingSearcherComponent } from './components/joblogging/joblogging-searcher.component';
import { AviJobLoggingFormComponent } from './components/joblogging/joblogging-form.component';
import { AviJobParamSearcherComponent } from './components/joblogging/jobparam-searcher.component';
import { AviJobParamFormComponent } from './components/joblogging/jobparam-form.component';
import { AviSearcherWithDetailComponent } from './components/controls/searcher-with-detail/searcher-with-detail.component';
import { AviInputTextarea } from './components/base-form/aviInputTextarea';
import { AviChangePasswordFormComponent } from './components/password/changepassword-form.component';
import { AviPasswordService } from './services/password.service';
import { AviJobStartFormComponent } from './components/joblogging/job-start-form.component';
import { AviJobDefIndexComponent } from './components/joblogging/jobdef-index.component';

// export const options: Partial<IConfig> | (() => Partial<IConfig>);

export class OAuthModuleConfig {
    resourceServer: OAuthResourceServerConfig = { sendAccessToken: false };
}

export class OAuthResourceServerConfig {
    /**
     * Urls for which calls should be intercepted.
     * If there is an ResourceServerErrorHandler registered, it is used for them.
     * If sendAccessToken is set to true, the access_token is send to them too.
     */
    allowedUrls?: Array<string>;
    sendAccessToken = true;
    customUrlValidation?: (url: string) => boolean;
}

@NgModule({
    imports: [
        // BrowserModule,
        CommonModule,
        // BrowserAnimationsModule,
        ToastrModule.forRoot(),
        FormsModule,
        ScrollingModule,
        ReactiveFormsModule,
        HttpClientModule,

        GridsterModule,

        MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatInputModule,
        MatCardModule, MatExpansionModule, MatDialogModule, MatChipsModule,
        MatListModule, MatIconModule, MatTabsModule,

        StepsModule,
        FileUploadModule,
        MessageModule,
        ButtonModule,
        CalendarModule,
        CheckboxModule,
        TriStateCheckboxModule,
        ContextMenuModule,
        ProgressBarModule,
        DropdownModule,
        ListboxModule,
        RadioButtonModule,
        InputMaskModule,
        MessagesModule,
        TooltipModule,
        PanelModule,
        SpinnerModule,
        SplitButtonModule,
        MenuModule,
        ToolbarModule,
        TableModule,
        AutoCompleteModule,
        SharedModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        InputNumberModule,
        PaginatorModule,
        MultiSelectModule,
        EditorModule,
        DynamicDialogModule,
        RichTextEditorAllModule,
        DialogModule,
        PickerModule,
        BadgeModule,
        ToggleButtonModule,
        MenuModule,
        OverlayPanelModule,
        ProgressSpinnerModule,
        DividerModule,
        TreeModule,
        DragDropModule,
        RichTextEditorModule,

        FlexLayoutModule,

        // AviCoreRoutingModule,

        NgxPermissionsModule.forChild(),
        OAuthModule.forRoot(),

        NgxSmartModalModule.forRoot(),
        TranslateModule

    ],
    entryComponents: [AviFavFormDialogComponent, AviCoreDocDialogComponent],
    declarations: [
        AviLoginComponent, AviAboutComponent, AviDebugComponent, AviThrottledClickComponent, AviThrottledOnClickComponent, AviFocusDirective, AviFocusCalDirective, AviFocusDropdownDirective, AviCalendarMaskDirective, AviPdfViewerComponent, AppCodeComponent,
        AviBaseFormComponent, AviActionButtonsComponent, AviBaseFormDesignerComponent, AviFullDialogDirectiveComponent, AviProfileComponent, AviPageComponent, AviViewPanelComponent,
        AviDialogComponent, AviLoaderComponent, AviBaseSearcherComponent, AviSortIconComponent,
        AviDateFormatPipe, AviSafeHtmlPipe, AviSafeUrlPipe, AviSortByPipe, AviLineBreakPipe, AviFormatAsHtmlPipe,
        AviFavFormDialogComponent, AviRichTextEditorComponent,
        AviCoreDocDialogComponent, AviCoreDocsOpenComponent,
        AviDropdownComponent, AviCalendarComponent, AviHistoryControlComponent, AviFileUploadComponent, AviConfirmFormComponent,
        AviJobDialogComponent, AviDragDropDirective,
        AviPhoneNumberValidator, AviNoHighLowDateValidator,
        AviSysMessageFormComponent, AviSysMessageSearcherComponent, AviSysMessageMonitorComponent,
        AviSerializationOverviewComponent, AviSerializationImportComponent, AviSerializationExportComponent,
        AviListDetailFormComponent, AviListDetailSearcherComponent, AviListAdminComponent,
        AviSysParameterFormComponent, AviSysParameterSearcherComponent, AviBookmarkFormComponent,
        AviTabView, AviTabPanel,
        AviSessionControllerComponent, AviLazyLoaderComponent,
        AviTextDiffComponent, AviTextDiffContainerDirective,
        AviTextblockComponent, AviTextblockSpanComponent, AviAdminTextblockComponent,
        AviResourceFormComponent, AviResourceSearcherComponent,
        AviMetaBrowserComponent,
        AviMultiSelectionComponent, AviSearcherWithDetailComponent,
        AviJobSearcherComponent, AviJobFormComponent, AviJobIndexComponent,
        AviJobLoggingSearcherComponent, AviJobLoggingFormComponent, AviJobLoggingSearcherWithDetailComponent,
        AviJobDefSearcherComponent, AviJobDefFormComponent, AviJobStartFormComponent, AviJobDefIndexComponent,

        AviJobParamSearcherComponent, AviJobParamFormComponent,
        AviInputTextarea, AviChangePasswordFormComponent
    ],
    providers: [AviRoundingService, AviTextblockService,
        // AviApiService, AviAuthService, AviCommonService, AviDocumentService
        ConfirmationService,
        AviAnimationService, AviRouterHistoryService, AviCommunicationService, AviFormFieldService, InputmaskService, HttpClientModule,
        ToolbarService, LinkService, ImageService, HtmlEditorService, TableService, QuickToolbarService, AviHistoryService,
        AviJobService, DialogService, DynamicDialogRef, DynamicDialogConfig,
        AviSessionControllerService, AviBookmarkService,AviPasswordService,
        AviDateFormatPipe,        
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRetryInterceptor,
            multi: true
        },
    ],
    exports: [
        AviLoginComponent, AviAboutComponent, AviDebugComponent, AviThrottledClickComponent, AviThrottledOnClickComponent, AviFocusDirective, AviFocusCalDirective, AviFocusDropdownDirective, AviCalendarMaskDirective, AviPdfViewerComponent, AppCodeComponent,
        AviBaseFormComponent, AviActionButtonsComponent, AviBaseFormDesignerComponent, AviFullDialogDirectiveComponent, AviProfileComponent, AviPageComponent, AviViewPanelComponent,
        AviDialogComponent, AviLoaderComponent, AviBaseSearcherComponent, AviSortIconComponent,
        AviDateFormatPipe, AviSafeHtmlPipe, AviSafeUrlPipe, AviSortByPipe, AviLineBreakPipe, AviFormatAsHtmlPipe,
        AviRichTextEditorComponent, AviCoreDocsOpenComponent,
        AviDropdownComponent, AviCalendarComponent, AviHistoryControlComponent, AviFileUploadComponent, AviConfirmFormComponent,
        AviJobDialogComponent, AviDragDropDirective,
        AviPhoneNumberValidator, AviNoHighLowDateValidator,
        AviSysMessageFormComponent, AviSysMessageSearcherComponent, AviSysMessageMonitorComponent,
        AviSerializationOverviewComponent, AviSerializationImportComponent, AviSerializationExportComponent,
        AviListDetailFormComponent, AviListDetailSearcherComponent, AviListAdminComponent,
        AviSysParameterFormComponent, AviSysParameterSearcherComponent, AviBookmarkFormComponent,
        AviTabView, AviTabPanel,
        AviSessionControllerComponent, AviLazyLoaderComponent,
        AviTextDiffComponent, AviTextblockComponent, AviTextblockSpanComponent, AviAdminTextblockComponent,
        AviResourceFormComponent, AviResourceSearcherComponent,
        AviMetaBrowserComponent,
        AviMultiSelectionComponent, AviSearcherWithDetailComponent,
        AviJobSearcherComponent, AviJobFormComponent, AviJobIndexComponent,
        AviJobLoggingSearcherComponent, AviJobLoggingFormComponent, AviJobLoggingSearcherWithDetailComponent,
        AviJobDefSearcherComponent, AviJobDefFormComponent, AviJobStartFormComponent, AviJobDefIndexComponent,

        AviJobParamSearcherComponent, AviJobParamFormComponent,
        AviInputTextarea, AviChangePasswordFormComponent
    ]
})
export class AviCoreModule {
    static forRoot(config: AviCoreConfig): ModuleWithProviders<AviCoreModule> {
        return {
            ngModule: AviCoreModule,
            providers: [
                { provide: 'config', useValue: config },
                OAuthConfigService,
                { provide: AviCoreOAuthConfigs, useValue: config.oAuthConfig },
                OAuthModuleConfig,
                {
                    provide: APP_INITIALIZER,
                    useFactory: init_app,
                    deps: [OAuthConfigService],
                    multi: true
                }
            ]
        };
    }

    /*
    static forRoot(apiConfig: ApiServiceConfig): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: [
                ApiService, AuthService, CommonService, ConfirmationService,
                { provide: ApiServiceConfig, useValue: apiConfig },
            ]
        };
    }
    */

    constructor(@Optional() @SkipSelf() parentModule: AviCoreModule) {
        if (parentModule) {
            // console.log('ParentModul', parentModule);
            // throw new Error('AviCoreModule is already loaded. Import it in the AppModule only');
        }
    }
}


