<avi-core-base-searcher #searcher
    [use-router-params]="false"
    [search-delegate] = "'basepartner'"
    [search-fields]   = "['Name','ZusatzName']"
    [rows]            = "20"
    [auto-search]     = "true"
    [collapsible]     = "false"
    (onInit)          = "onInit($event)"
    (onDoubleClick)   = "onDoubleClick($event)"
    (onStartNew)      = "onStartNew()"
    (onSelect)        = "onSelect($event)"
    searcher-icon     = "people"
    searcher-title    = "Partner suchen"
    [row-detailview-template] = "rowdetailview">
</avi-core-base-searcher>

<ng-template #rowdetailview let-record>

    <avi-crm-partner-searcher-detailview [partnerId]="record.Id"></avi-crm-partner-searcher-detailview>

</ng-template>
