<avi-core-dialog #dialog
    identifier="dialog"
    [showCloseIcon]="true"
    [closeOnEscape]="true"
>

    <avi-core-page *ngIf="dashboard && dashboard.length > 0" title="Form Designer" [collapsible]="false" [card]="true" [contents-padding]="false">

        <div class="ui-g-12 ui-md-12">

            <div class="pull-right">
                <p-multiSelect styleClass="multi-select-button ui-widget"
                    [options]="dashboard"
                    [ngModel]="VisibleItems"
                    (ngModelChange)="setVisibleItems($event)"
                    optionLabel="label"
                    [showToggleAll]="false"
                    [maxSelectedLabels]=0
                    selectedItemsLabel="Attribute"
                    defaultLabel="Attribute"></p-multiSelect>
            </div>
        </div>

        <div class="ui-g form-designer-container" [ngStyle]="{'height': height}">

            <gridster class="form-designer-gridster" [options]="options" style="min-height: 200px">
                <gridster-item class="gridster-item-content" [item]="item" *ngFor="let item of getVisibleItems()">

                    <button pButton *ngIf="!item.required" type="button" class="pull-right p-button-secondary p-button-flat" icon="pi pi-trash" (click)="HideItem(item)"></button>

                        <div class="ui-g ui-fluid p-2 ui-g-nopad">
                            <label [ngClass]="{'label-nonmd':true}">{{item.label}}</label>
                            <input pInputText
                                        [readonly]="false"
                                        type="text">

                        </div>
                </gridster-item>
            </gridster>

        </div>

        <div class="ui-g-12 ui-md-12">
            <button
            icon="pi pi-save"
            pButton
            type="button"
            style="width:auto"
            class="mr-2"
            label="Speichern"
            title="Speichern"
            (click)="doSave()">
            </button>

            <button
            pButton
            type="button"
            style="width:auto"
            class="mr-2 p-button-secondary"
            [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
            [title]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
            (click)="doCancel()">
            </button>

        </div>

    </avi-core-page>

</avi-core-dialog>
