import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabNav } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AviApiService, AviCommonService } from '@avi-x/avi-core';

@Component({
    selector: 'vwbp-admin-index',
    templateUrl: './vwbp-admin-index.component.html',
    styleUrls: ['./vwbp-admin-index.component.scss']
})
export class VwbPAdminIndexComponent implements OnInit {
    navLinks = [
        // { path: './start', label: 'Index' },
        { path: './behoerdensuche', label: 'Behördensuche' },
        { path: './xmlimport', label: 'XML Importjobs' },
        { path: './resources', label: 'Resourcen' },
        { path: './document', label: 'Vorlagen' },
        { path: './keyworddefs', label: 'Keywords' },
        { path: './rechtsformdefs', label: 'Rechtsform Definitionen' },
        { path: './emaildef', label: 'Email Definitionen' },
        // { path: './import', label: 'Datenimport' },
        { path: './sysmessages', label: 'Systemmitteilungen' },
        { path: './jobdef', label: 'Job Definitionen' },
        { path: './techinfo', label: 'Tech Info' }
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
    ) {
    }


    ngOnInit() {
    }
}
