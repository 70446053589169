import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviAdressBlockZeileDefFormComponent } from './adressblockzeiledef-form.component';
import { AdressBlockZeileDefSearchResponseDto } from '@avi-x/avi-dto/partner/adressblockzeiledefsearchresponsedto.model';

const nameof = (nameFunction: ((obj: AdressBlockZeileDefSearchResponseDto) => any)) => typed_nameof<AdressBlockZeileDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-adressblockzeiledef-searcher',
    templateUrl: './adressblockzeiledef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAdressBlockZeileDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFADRESSBLOCKZEILEDEF';
    SettingsKey = 'settings.searcher.adressblockzeiledef';
    BaseUrl = 'adressblockdef/zeiledef';
	FormType = AviAdressBlockZeileDefFormComponent;

    CanEdit: boolean = false;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFAdressBlockDef schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addIntegerColumn(nameof(c => c.Sequenz), 'CORE.COMMON.MODEL.CAFADRESSBLOCKZEILEDEF.SEQUENZ', true);
        
        searcher.addTextColumn(nameof(c => c.Zeile), 'CORE.COMMON.MODEL.CAFADRESSBLOCKZEILEDEF.ZEILE', true);
        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(AdressBlockZeileDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
