import { AviQueryDefExecutionSearcherComponent } from '@avi-x/avi-report';
import { Component, Input, ViewChild } from '@angular/core';

import { AviPartnerService } from '../../services/partner.service';
import { AviCommonService, AviApiService, AviSessionControllerService, ISessionData } from '@avi-x/avi-core';

@Component({
    selector: 'avi-crm-natperson-searcher',
    templateUrl: './natperson-searcher.component.html'
})
export class AviNatPersonSearcherComponent {
    @ViewChild('searcher', { static: false })
    private searcher: AviQueryDefExecutionSearcherComponent;

    @Input('session-data')
    SessionData: ISessionData;
    
    constructor(
        public partnerService: AviPartnerService,
        public apiService: AviApiService,
        public commonService: AviCommonService,
        public sessionController: AviSessionControllerService
    ) {
    }

    public onSelect(data) {
    }

    public _onDoubleClick(data) {
        this.sessionController.openSession('natperson-index', data.PartnerId);
    }

    public onModelLoaded = (data) => {
        this.searcher.getColumns().forEach(w => {
            w.ShowOnMobile = w.Field === 'Name' || w.Field === 'Vorname' || w.Field === 'Ort';
        });
    }

    // Form Dialog
    public createObject() {
        alert('Da passiert noch nix');
        // this.FormModelId = '0';
        // this.formDialog.open();
    }

    public editObject() {
        alert('Da passiert noch nix');
        // if (this.searcher.SelectedRows.length > 0) {
        //     this.FormModelId = this.searcher.SelectedRows[0].Id;
        //     this.formDialog.open();
        // }
    }

    public deleteObject() {
        alert('Da passiert noch nix');

        // this.apiService.deleteObject('crm/natperson', this.searcher.SelectedRows[0]?.Id, () => this.searcher.forceRefresh());
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.searcher.forceRefresh();
    }

    private closeForm() {
        // this.FormModelId = null;
        // this.formDialog.close();
    }

}
