import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared/genericref.model';
import TransformDate from '../shared/transformdate';

export class Businesscase extends BaseModel {  
    @Expose()
    public get Number(): number { return this.getProperty('Number'); }
    public set Number(value: number) { this.setProperty('Number', value); }
    
    @Expose()
    public get Title(): string { return this.getProperty('Title'); }
    public set Title(value: string) { this.setProperty('Title', value); }
    
    @Expose()
    public get Description(): string { return this.getProperty('Description'); }
    public set Description(value: string) { this.setProperty('Description', value); }

    @Expose()
    @Type(() => Date)
    public get DueDate(): Date { return this.getProperty('DueDate'); }
    public set DueDate(value: Date) { this.setProperty('DueDate', value); }
    
    @Expose()
    public get CustomProperties(): string { return this.getProperty('CustomProperties'); }
    public set CustomProperties(value: string) { this.setProperty('CustomProperties', value); }
    
    @Expose()
    public get vonBusinesscaseDef_ID(): string { return this.getModelId('vonBusinesscaseDef_ID'); }
    public set vonBusinesscaseDef_ID(value: string) { this.setModelId('vonBusinesscaseDef_ID', value); }
 
    @Expose()
    public get vonWorkflow_ID(): string { return this.getModelId('vonWorkflow_ID'); }
    public set vonWorkflow_ID(value: string) { this.setModelId('vonWorkflow_ID', value); }
 
    @Expose()
    public set CreatedBy_ID(value: string) { this.setModelId('CreatedBy_ID', value); }
    public get CreatedBy_ID(): string { return this.getModelId('CreatedBy_ID'); }

    @Expose()
    public get Applicant_ID(): string { return this.getModelId('Applicant_ID'); }
    public set Applicant_ID(value: string) { this.setModelId('Applicant_ID', value); }
 
    @Expose()
    public get AssignedTo_ID(): string { return this.getModelId('AssignedTo_ID'); }
    public set AssignedTo_ID(value: string) { this.setModelId('AssignedTo_ID', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Priority(): ListType { return this.getProperty('Priority'); }
    public set Priority(value: ListType) { this.setProperty('Priority', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Verantwortlichkeit(): ListType { return this.getProperty('Verantwortlichkeit'); }
    public set Verantwortlichkeit(value: ListType) { this.setProperty('Verantwortlichkeit', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get Context1(): GenericRef { return this.getProperty('Context1'); }
    public set Context1(value: GenericRef) { this.setProperty('Context1', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get Context2(): GenericRef { return this.getProperty('Context2'); }
    public set Context2(value: GenericRef) { this.setProperty('Context2', value); }
    
    // Transients    
    @Expose()
    public get NumberBez(): string { return this.getProperty('NumberBez'); }
    public set NumberBez(value: string) { this.setProperty('NumberBez', value); }

    @Expose()
    public get StatusBez(): string { return this.getProperty('StatusBez'); }
    public set StatusBez(value: string) { this.setProperty('StatusBez', value); }

    @Expose()
    public get Status_ID(): string { return this.getProperty('Status_ID'); }
    public set Status_ID(value: string) { this.setProperty('Status_ID', value); }    

    @Expose()
    public get Bearbeitbar(): boolean { return this.getProperty('Bearbeitbar'); }
    public set Bearbeitbar(value: boolean) { this.setProperty('Bearbeitbar', value); }

    @Expose()
    public get Erledigt(): boolean { return this.getProperty('Erledigt'); }
    public set Erledigt(value: boolean) { this.setProperty('Erledigt', value); }
    
    @Expose()
    public get CustomPropCollectionDef_ID(): string { return this.getProperty('CustomPropCollectionDef_ID'); }
    public set CustomPropCollectionDef_ID(value: string) { this.setProperty('CustomPropCollectionDef_ID', value); }    

    @Expose()
    @TransformDate()
    public get Frist(): Date { return this.getProperty('Frist'); }
    public set Frist(value: Date) { this.setProperty('Frist', value); }

    @Expose()
    public get KulanzFrist(): number { return this.getProperty('KulanzFrist'); }
    public set KulanzFrist(value: number) { this.setProperty('KulanzFrist', value); }

    @Expose()
    public get ParentBusinesscase_ID(): string { return this.getModelId('ParentBusinesscase_ID'); }
    public set ParentBusinesscase_ID(value: string) { this.setModelId('ParentBusinesscase_ID', value); }
}
