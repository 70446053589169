import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class JobDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
	public KurzBez: string;

    @Expose()
	public Bezeichnung: string;

    @Expose()
	public JobClass: string;

    @Expose()
	public JobSequenzName: string;

	@Expose()
	@Type(() => ListType)
	public JobType: ListType;

    @Expose()
	public Beschreibung: string;

    @Expose()
	public SupportUser: boolean;

    @Expose()
	public CanResume: boolean;

    @Expose()
	public ApiJobClass: string;

    @Expose()
	public IsExternalDB: boolean;

    @Expose()
	public ExternalInterval: number;
}
