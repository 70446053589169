import { Transform } from "class-transformer";

export default function TransformNullableString() {
    const toPlain = Transform((value) => value?.value ? value.value : null, { toPlainOnly: true });
    const toClass = Transform((value) => value?.value ? value.value : null, { toClassOnly: true });

    return function (target: any, key: string) {
        toPlain(target, key);
        toClass(target, key);
    };
}
