import { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AviApiService } from './../../../services/api.service';
import { AviCommonService } from './../../../services/common.service';
import { AviAbstractModelSearcherComponent } from '../../base-searcher/abstract-model-searcher.component';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviListDetailFormComponent } from './listdetail-form.component';

@Component({
    selector: 'avi-listdetail-searcher',
    templateUrl: './listdetail-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviListDetailSearcherComponent extends AviAbstractModelSearcherComponent implements OnChanges {  
    ClassName = 'ListDetail';
    SettingsKey = 'settings.searcher.listdetail';
    BaseUrl = 'listdetail';
    FormType = AviListDetailFormComponent;

    CanEdit: boolean = false;
    
    @Input('listname')
    public ListName: string;
    
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('ListDetail schreiben').then(w => this.CanEdit = w);
    }
    
    getParams(): { [k: string]: any; } {
        return { 'ListName': this.ListName };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ListName.currentValue !== changes.ListName.previousValue && this.searcher)
            this.searcher.forceRefresh();
    }

    onSearcherInit(searcher) {
        this.searcher.beginInit();
        this.searcher.addIntegerColumn('Sorter', 'Sorter');
        this.searcher.addTextColumn('KurzBez1', 'KurzBez1');
        this.searcher.addTextColumn('Bezeichnung1', 'Bezeichnung1');
        this.searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        if (this.ListName) {
            let res = await this.apiService.get(`listdetail/container/${this.ListName}`);
    
            res = this.searcher.ApplyColumnFilters(res);
            res = this.searcher.ApplyColumnSorting(res);
            return res;
        }

        return [];
    }

    public deleteObject(selectedRows) {

        if (selectedRows && this.BaseUrl) {
            const id = selectedRows.Id;

            this.commonService.confirmDelete(`Möchten Sie das Listdetail wirklich löschen?`,
                () => {
                    this.apiService.delete(`${this.BaseUrl}/${id}`).then(r => {
                        this.commonService.notificateSuccess('Gelöscht');
                        this.searcher.forceRefresh();
                    });
                }
            );
        }
    }
}
