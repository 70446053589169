import { ListType } from "../shared";

export class BusinesscaseFormularChangesDto {
    public Field: string;
    public OldValue: string;
    public NewValue: string;
}

export class BusinesscaseDataDetailFormularChangesResultDto {
    public Bemerkung: string = null;
    public FormChanges: BusinesscaseFormularChangesDto[] = [];
}

export class BusinesscaseDataDetailKorrigierenDto extends BusinesscaseDataDetailFormularChangesResultDto {
    public Internal: boolean = false;
    public AlwaysInternal: boolean = false;
    public IsCorrection: boolean = false;
    public FormData: string;
    public BusinessDataId: string
    public NumVersions: number = 0;
    public SelectedVersion: number = 1;
}
