import { AviTypeUtil } from '../../shared/constants/constants';
import { AviBaseFormComponent } from './base-form.component';

export enum AviFormFieldType {
    // eslint-disable-next-line no-unused-vars
    TEXT, RICHTEXT, INPUTMASK, DATE, TEXTAREA, CHECKBOX, DROPDOWN, AUTOCOMPLETE, AUTOCOMPLETE_TYPELESS, LISTTYPE, TIME, TOGGLEBUTTON, TEXT_QUILL, BUTTON,
    // eslint-disable-next-line no-unused-vars
    HIDDEN, TEMPLATE, NUMBER, PERCENTAGE, CURRENCY, LABEL, GROUP, STEP, EMPTY, RADIO, TEXTBLOCK, ENUM, LINK, REFERENCE
}

export class AviChangedAttr {
    public field: string;
    public value: any;
    public datasource?: any[];
    public model: any;
    public form: AviBaseFormComponent;
}

export enum AviFieldErrorDisplayModus {
    DIRECT,
    TOUCHED
}

export class AviFieldError {
    Message: string;
    Severity: string = 'error';
    DisplayModus: AviFieldErrorDisplayModus = AviFieldErrorDisplayModus.DIRECT;
}

export class AviFormFieldMetaData {
    public Index: number = null;
    public NextStepIndex: number = null;
    public NextGroupIndex: number = null;
    public NextGroupOrStepIndex: number = null;
    public StepIndex: number = null;
}

export class AviFormField {

    public MetaData: AviFormFieldMetaData = null;

    public Name: string;
    public Label: string;
    public LabelLiteral: boolean = false;

    public Type: AviFormFieldType = AviFormFieldType.TEXT;
    public TextType: string = 'text';
    public TextAutocompleteType: string = null;
    public UnderlyingType: AviFormFieldType = null;
    public SourceType: string = null;

    public LabelBold: boolean = false;
    public LabelVisible: boolean = true;
    public LabelClass: string = null;
    public LabelWidth: string = null;
    public FieldClass: string = null;
    public LabelTextAlignment: 'left' | 'right' = 'left';
    public Hinweis: string = null;
    public HinweisTextblock: string = null;
    public HinweisClass: string = null;

    public GroupToggleable: boolean = true;
    public GroupExpanded: boolean = true;
    public GroupTemplate: boolean = false;
    public GroupClass: string = null;
    public GroupCardClass: string = null;

    public Required: boolean = false;
    public Disabled: boolean = false;
    public Visible: boolean = true;
    public HiddenToCustomization: boolean = false;
    public Readonly: boolean = false;
    public MDSize: number = 6;
    public BoolRadioGroupID: number = null;
    public FieldGroupID: number = null;
    public Mask: string;
    public Placeholder: string = '';
    public Prefix: string = null;
    public Suffix: string = null;
    public PrefixClass: string = null;
    public SuffixClass: string = null;
    public EnableTribute: boolean = true;

    public Currency: string = 'CHF';
    public InputAddonText: string = null;
    // public InputAddonButtonLabel: string = null;

    public Errors: AviFieldError[] = [];

    public PrecisionMin: number = 0;
    public PrecisionMax: number = 0;

    public CheckboxText: string = null;
    public MaxLength: number = 524288;
    public IconOn: string = null;
    public IconOff: string = null;
    public IconPos: string = 'left';

    public TrueValue: any = true;
    public FalseValue: any = false;

    public Options: any[] = [];

    // public DateFormat = "dd.mm.yy";// "yy-mm-dd"; // dd.mm.yy;
    public DateFormat = 'dd.mm.yy';
    public DataType: 'date' | 'string' = 'date';
    public DateView: 'date' | 'month' | 'year' = 'date';
    public DateDefaultValue: Date = new Date();
    public DateTime: boolean = false;
    public DateMinValue: Date = null;
    public DateMaxValue: Date = null;
    public MinValue: number = null;
    public MaxValue: number = null;
    public UseSeparators: boolean = true;

    public DropdownDatasource: any = [];
    public DropdownDatasourceMethod: 'GET' | 'POST' = 'GET';
    public DropdownDatasourceInternal: any[] = [];
    public DropdownDataKey:string = null; // z.B. 'Id' --> Wird bis p-dropdown (dataKey) durchgereicht --> A property to uniquely identify a value in options.
    public DropdownDatasourceRequestDataDelegate: (field: AviFormField) => any = null;

    public DropdownPlaceholder = null;
    public DropdownFilterable = false;
    public DropdownMatchMode = 'contains';
    public DropdownEnableVirtualScroll = false;
    public ReadonlyDropdownAsReference = false;

    public DropdownDisplayField: any = null;
    public DropdownValueField: any = null;
    public ValueTransformer: (item) => any = null;
    public DateRangeButtons: boolean = false;
    public DateRangeButtonType: 'days' | 'weeks' | 'months' | 'years' = 'days';
    public DateRangeButtonInc: number = 7;
    public DropdownAddChooseItem: boolean = true;
    public HiddenDropdownItems: any[] = null;
    public HiddenDropdownField: string = null;
    public AutoselectSingle: boolean = false;

    public ButtonEvent: (field, event?) => void = null;
    public ButtonIcon: string = null;
    public ButtonLabel: string = null;
    public ButtonDisabled: boolean = false;
    public ButtonClass: string;

    public LinkText: string = null;
    public LinkUrl: string = null;
    public LinkTarget: string = '_blank';
    public LinkIcon: string = null; // ''

    public Pattern: string = null;

    public Value: any;

    public TextblockText: string;
    public TextblockIdentifier: string;
    public TextblockParams?: { [klass: string]: any };

    public KeyDownDelegate: (event: KeyboardEvent) => void = null;

    public CustomStyle: () => any = null;
    public CustomClass: () => any = null;
    public CustomTemplateDelegate: (item) => any = null;
    public CustomTemplateHeaderDelegate: () => any = null;
    public CustomTemplateFooterDelegate: () => any = null;
    public CustomTemplateSelectedItemDelegate: (item) => any = null;
    public AutocompleteDelegate: (query: string) => Promise<any> = null;
    public AutocompleteMinChars: number = 3;
    public AutocompleteField: string = null;
    public AutocompleteForceSelection: boolean = true;

    public IsPhoneNumber: boolean = false;
    public NoHighLowDate: boolean = false;
    public NullDate: Date = null;

    public constructor(name: string, label: string = null, type: AviFormFieldType = AviFormFieldType.TEXT, required: boolean = false, visible: boolean = true) {
        this.Name = name;
        this.Label = label ?? name;
        this.Type = type;
        this.Required = required;
        this.Visible = visible;
    }

    public addError(message: string, severity: string = 'error', displayModus: AviFieldErrorDisplayModus = AviFieldErrorDisplayModus.DIRECT): AviFormField {
        if (this.Errors.findIndex(e => e.Message === message) === -1)
            this.Errors.push({ Message: message, Severity: severity, DisplayModus: displayModus });
        return this;
    }

    public removeError(message: string): AviFormField {
        const foundIndex = this.Errors.findIndex(e => e.Message === message);
        if (foundIndex > -1)
            this.Errors.splice(foundIndex, 1);

        return this;
    }

    public clearErrors(): AviFormField {
        this.Errors.splice(0, this.Errors.length);
        return this;
    }

    public setReadonly(readonly: boolean = true): AviFormField {
        this.Readonly = readonly;
        return this;
    }

    public setVisible(visible: boolean = true): AviFormField {
        this.Visible = visible;
        return this;
    }

    public setDropdownDataKey(field: string): AviFormField {
        this.DropdownDataKey = field;
        return this;
    }

    public setReadonlyDropdownAsReference(target: string) {
        this.ReadonlyDropdownAsReference = true;
        this.LinkUrl = target;
        return this;
    }

    public setHiddenToCustomization(hidden: boolean = true): AviFormField {
        this.HiddenToCustomization = hidden;
        return this;
    }

    public setDisabled(disabled: boolean = true): AviFormField {
        this.Disabled = disabled;
        return this;
    }

    public setRequired(required: boolean): AviFormField {
        this.Required = required;
        return this;
    }

    public setTrueValue(value: any): AviFormField {
        this.TrueValue = value;
        return this;
    }

    public setFalseValue(value: any): AviFormField {
        this.FalseValue = value;
        return this;
    }

    public setDateTime(datetime: boolean = true): AviFormField {
        this.DateTime = datetime;
        return this;
    }

    public setMaxLength(maxlen: number): AviFormField {
        this.MaxLength = maxlen;
        return this;
    }


    public setMinDate(minDate:Date): AviFormField{
        this.DateMinValue = minDate;
        return this;
    }

    public setMaxDate(maxDate:Date): AviFormField{
        this.DateMaxValue = maxDate;
        return this;
    }

    public setMinMaxDate(minDate: Date, maxDate: Date): AviFormField {
        this.DateMinValue = minDate;
        this.DateMaxValue = maxDate;
        return this;
    }

    public setMinMaxValue(min: number, max: number): AviFormField {
        this.MinValue = min;
        this.MaxValue = max;
        return this;
    }

    public setKeyDownEventDelegate(delegate: (event) => void): AviFormField {
        this.KeyDownDelegate = delegate;
        return this;
    }


    public setHinweis(hinweis: string, hinweisClass: string = null): AviFormField {
        this.Hinweis = hinweis;
        this.HinweisClass
        return this;
    }

    public setHinweisTextblock(hinweisTextblock, hinweisClass: string = null): AviFormField {
        this.HinweisTextblock = hinweisTextblock;
        this.HinweisClass = hinweisClass;
        return this;
    }

    public setInputAddonText(text: string): AviFormField {
        this.InputAddonText = text;
        return this;
    }

    public setButtonEvent(delegate: (field, event?) => void): AviFormField {
        this.ButtonEvent = delegate;
        return this;
    }

    public setButtonLabel(label: string): AviFormField {
        this.ButtonLabel = label;
        return this;
    }



    // public setInputAddonButton(label: string): AviFormField {
    //     this.InputAddonButtonLabel = label;
    //     return this;
    // }

    public setBoolRadioButtonId(id: number): AviFormField {
        this.BoolRadioGroupID = id;
        return this;
    }

    public setCustomStyleDelegate(delegate: () => any): AviFormField {
        this.CustomStyle = delegate;
        return this;
    }

    public setCustomClassDelegate(delegate: () => any): AviFormField {
        this.CustomClass = delegate;
        return this;
    }

    public setPattern(pattern: string): AviFormField {
        this.Pattern = pattern;
        return this;
    }

    public setIsEmail(isEmail: boolean = true): AviFormField {
        this.Pattern = isEmail ? '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$' : null;
        return this;
    }

    public setMDSizeFull(): AviFormField {
        this.MDSize = 12;
        return this;
    }
    public setMDSizeHalf(): AviFormField {
        this.MDSize = 6;
        return this;
    }
    public setMDSizeOneQuarter(): AviFormField {
        this.MDSize = 3;
        return this;
    }

    public setMDSizeThreeQuarter(): AviFormField {
        this.MDSize = 9;
        return this;
    }

    public setMDSizeOneThird(): AviFormField {
        this.MDSize = 4;
        return this;
    }

    public setMDSizeTwoThird(): AviFormField {
        this.MDSize = 8;
        return this;
    }

    public setMDSize(size: number): AviFormField {
        this.MDSize = size;
        return this;
    }

    public setDateRangeButtonsVisibility(showButtons: boolean): AviFormField {
        this.DateRangeButtons = showButtons;
        return this;
    }

    public setType(type: AviFormFieldType): AviFormField {
        this.Type = type;
        return this;
    }

    public setTextTypePassword(): AviFormField {
        this.TextType = 'password';
        return this;
    }

    public setTextAutocompleteType(type: string): AviFormField {
        this.TextAutocompleteType = type; // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofilling-form-controls%3A-the-autocomplete-attribute
        return this;
    }

    public setAutocompleteForceSelection(value: boolean): AviFormField {
        this.AutocompleteForceSelection = value;
        return this;
    }

    public setLabel(label: string): AviFormField {
        this.Label = label;
        return this;
    }

    public setNoHighLowDate(noHighLowDate: boolean): AviFormField {
        this.NoHighLowDate = noHighLowDate;
        return this;
    }

    public setNullDateLow(): AviFormField {
        this.NullDate = AviTypeUtil.SqlLowDate.toDate();
        return this;
    }

    public setNullDateHigh(): AviFormField {
        this.NullDate = AviTypeUtil.HighDate.toDate();
        return this;
    }

    public setFieldClass(val: string): AviFormField {
        this.FieldClass = val;
        return this;
    }

    public setGroupClass(val: string): AviFormField {
        this.GroupClass = val;
        return this;
    }

    public setGroupCardClass(val: string): AviFormField {
        this.GroupCardClass = val;
        return this;
    }

    public setLabelClass(val: string): AviFormField {
        this.LabelClass = val;
        return this;
    }

    public setLabelLiteral(val: boolean = true): AviFormField {
        this.LabelLiteral = val;
        return this;
    }

    public setLabelVisible(val: boolean): AviFormField {
        this.LabelVisible = val;
        return this;
    }

    public setLabelWidth(val: string): AviFormField {
        this.LabelWidth = val;
        return this;
    }

    public setPlaceholder(placeholder: string): AviFormField {
        this.Placeholder = placeholder;
        return this;
    }

    public setAutoselectSingle(autoselect: boolean = true): AviFormField {
        this.AutoselectSingle = autoselect;
        return this;
    }

    public setLabelTextAlignment(labelTextAlignment: 'left' | 'right'): AviFormField {
        this.LabelTextAlignment = labelTextAlignment;
        return this;
    }

    public setPrefix(prefix: string): AviFormField {
        this.Prefix = prefix;
        return this;
    }

    public setSuffix(suffix: string): AviFormField {
        this.Suffix = suffix;
        return this;
    }

    public setPrecision(precisionMin: number, precisionMax: number): AviFormField {
        this.PrecisionMin = precisionMin;
        this.PrecisionMax = precisionMax;
        return this;
    }

    public setFieldGroupID(id: number) {
        this.FieldGroupID = id;
        return this;
    }

    public setButtonClass(buttonClass: string): AviFormField {
        this.ButtonClass = buttonClass;
        return this;
    }

    public setButtonIcon(buttonIcon: string): AviFormField {
        this.ButtonIcon = buttonIcon;
        return this;
    }

    public setUseSeparators(value: boolean): AviFormField {
        this.UseSeparators = value;
        return this;
    }

    public setDateFormat(format: string): AviFormField {
        this.DateFormat = format;
        return this;
    }

    public setDateDefaultValue(defaultValue: Date): AviFormField {
        this.DateDefaultValue = defaultValue;
        return this;
    }
}
