import { ChangeDetectorRef, Component } from '@angular/core';
import { AviApiService, AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPInternUnstimmigkeitService } from '../../services/unstimmigkeit.service';
import { VwbPInternTaskBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-tasks/task-base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';

@Component({
    selector: 'vwbp-intern-unstimmigkeit-pausieren-task',
    template: `
     <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
            {{ 'VWBP.INTERN.UNSTIMMIGKEITEN.PAUSIEREN_BUTTON' | translate}}
        </div>
        <div class="grid form-group">
            <div class="col-12">
                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ 'VWBP.INTERN.UNSTIMMIGKEITEN.PAUSIEREN_KOMMENTAR' | translate }}*</label>
                </span>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-pause" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-primary"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternUnstimmigkeitPausierenTaskComponent extends VwbPInternTaskBaseComponent {
    constructor(public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public unstimmigkeitService: VwbPInternUnstimmigkeitService, public inputMaskService: InputmaskService, public dialogService: DialogService, public cdr: ChangeDetectorRef) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + this.commonService.translateInstant('VWBP.INTERN.UNSTIMMIGKEITEN.PAUSIEREN_BUTTON');
    }

    CanExecute(): boolean {
        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung;
    }

    async Execute() {
        this.PerformAction('VWBP.INTERN.UNSTIMMIGKEITEN.PAUSIEREN_BUTTON', this.GetResourceName('VWBP.INTERN.UNSTIMMIGKEITEN.PAUSIEREN_BESTAETIGEN'), 'VWBP.INTERN.UNSTIMMIGKEITEN.PAUSIEREN_BUTTON',
            async (selectedRT) => this.unstimmigkeitService.SetMahnwesenPausiert(selectedRT.map(w => w.Id), this.Bemerkung)
        );
    }
}
