import { FileUploadModule } from 'primeng/fileupload';
import { VwbPPublicXMLUploadFormComponent } from './xmlupload-form/components/xmlupload-form/xmlupload-form.component';
import { FileSaverModule } from 'ngx-filesaver';
import { SplitButtonModule } from 'primeng/splitbutton';
import { VwbPPublicRechtstraegerImportierenFormComponent } from './rechtstraeger/components/rechtstraeger-importieren-form/rechtstraeger-importieren-form.component';
import { VwbPPublicFormularListeComponent } from './formular/components/formular-liste/formular-liste.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AviCoreModule, ParentIdResolver } from '@avi-x/avi-core';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { AviCommentModule } from '@avi-x/avi-crm';
import { VwbPPublicRechtstraegerIndexComponent } from './rechtstraeger/components/rechtstraeger-index/rechtstraeger-index.component';
import { VwbPPublicRechtstraegerViewComponent } from './rechtstraeger/components/rechtstraeger-view/rechtstraeger-view.component';
import { VwbPPublicRechtstraegerVersionResolver } from './rechtstraeger/services/rechtstraegerversion-resolver';
import { VwbPPublicRechtstraegerSearcherComponent } from './rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-searcher.component';
import { VwbPPublicRechtstraegerDetailFormComponent } from './rechtstraeger/components/rechtstraegerdetail-form/rechtstraegerdetail-form.component';
import { VwbPPublicRechtstraegerAnfordernComponent } from './rechtstraeger/components/rechtstraeger-anfordern/rechtstraeger-anfordern.component';
import { VwbPPublicRechtstraegerLoeschenFormComponent } from './rechtstraeger/components/rechtstraeger-loeschen-form/rechtstraeger-loeschen-form.component';
import { VwbPPublicRechtstraegerOwnerFormComponent } from './rechtstraeger/components/rechtstraeger-owner-form/rechtstraeger-owner-form.component';
import { VwbPPublicFormularFormComponent } from './formular/components/formular-form/formular-form.component';
import { VwbPPublicVwEGFormularFormComponent } from './formular/components/formular-form/vweg-formular-form.component';
import { VwbPPublicUserLoginComponent } from './user/components/user-login/user-login.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { VwbPPublicAuthGuardService } from './auth/services/vwbp-public-auth-guards.service';
import { InputTextModule } from 'primeng/inputtext';
import { VwbPPublicUserProfileComponent } from './user/components/user-profile/user-profile-form.component';
import { VwbPPublicRechtstraegerMiniViewComponent } from './rechtstraeger/components/rechtstraeger-miniview/rechtstraeger-miniview.component';
import { TranslateModule } from '@ngx-translate/core';
import { MessagesModule } from 'primeng/messages';


const routes: Routes = [

    { path: '', redirectTo: 'vwbp/login', pathMatch: 'full' },

    {
        path: 'vwbp/login',
        component: VwbPPublicUserLoginComponent,
    },

    {
        path: 'vwbp/profile',
        canActivate: [VwbPPublicAuthGuardService],
        component: VwbPPublicUserProfileComponent,
    },

    {
        path: 'vwbp/rt/:id',
        canActivate: [VwbPPublicAuthGuardService],
        component: VwbPPublicRechtstraegerViewComponent,
        resolve: { id: VwbPPublicRechtstraegerVersionResolver },
        data: { 'submodul': true }
    },
    {
        path: 'vwbp/rechtstraeger-anfordern',
        canActivate: [VwbPPublicAuthGuardService],
        component: VwbPPublicRechtstraegerAnfordernComponent,
        data: { 'submodul': true }
    },

    {
        path: 'vwbp/rechtstraeger-owner',
        canActivate: [VwbPPublicAuthGuardService],
        component: VwbPPublicRechtstraegerOwnerFormComponent,
        data: { 'submodul': true }
    },

    {
        path: 'vwbp/index',
        canActivate: [VwbPPublicAuthGuardService],
        component: VwbPPublicRechtstraegerIndexComponent,
        children: [
            { path: '', redirectTo: 'rtlist', pathMatch: 'full' },

            {
                path: 'rtlist',
                component: VwbPPublicRechtstraegerSearcherComponent,
                canActivate: [VwbPPublicAuthGuardService],
                resolve: { id: ParentIdResolver },
                data: { 'submodul': true }
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,

        AviCoreModule,
        AviCommentModule,

        ButtonModule,
        SplitButtonModule,
        ToggleButtonModule,
        TableModule,
        ToolbarModule,
        MessageModule,
        MessagesModule,
        CheckboxModule,
        PanelModule,
        ConfirmDialogModule,
        DropdownModule,
        InputTextareaModule,
        InputTextModule,
        MultiSelectModule,
        TooltipModule,
        MenuModule,
        FileUploadModule,

        MatTabsModule,

        FileSaverModule,

        RouterModule.forChild(routes),
        TranslateModule
    ],
    exports: [
        VwbPPublicRechtstraegerSearcherComponent,
        VwbPPublicRechtstraegerDetailFormComponent,
        VwbPPublicRechtstraegerViewComponent,
        VwbPPublicRechtstraegerMiniViewComponent,
        VwbPPublicRechtstraegerIndexComponent,
        VwbPPublicFormularFormComponent,
        VwbPPublicVwEGFormularFormComponent,
        VwbPPublicFormularListeComponent
    ],
    declarations: [
        VwbPPublicUserLoginComponent,
        VwbPPublicUserProfileComponent,
        VwbPPublicRechtstraegerSearcherComponent,
        VwbPPublicRechtstraegerDetailFormComponent,
        VwbPPublicRechtstraegerLoeschenFormComponent,
        VwbPPublicRechtstraegerImportierenFormComponent,
        VwbPPublicRechtstraegerViewComponent,
        VwbPPublicRechtstraegerMiniViewComponent,
        VwbPPublicRechtstraegerIndexComponent,
        VwbPPublicFormularFormComponent,
        VwbPPublicVwEGFormularFormComponent,
        VwbPPublicFormularListeComponent,
        VwbPPublicRechtstraegerAnfordernComponent,
        VwbPPublicRechtstraegerOwnerFormComponent,
        VwbPPublicXMLUploadFormComponent
    ]
})
export class AviVwbPPublicModule { }
