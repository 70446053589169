import { Expose, Type } from 'class-transformer';

import { Land } from '../partner/land.model';
import { BaseModel } from '../shared/basemodel.model';

export class TelefonVorwahl extends BaseModel {  
    @Expose()
    public get Vorwahl(): string { return this.getProperty('Vorwahl'); }
    public set Vorwahl(value: string) { this.setProperty('Vorwahl', value); }
    
    @Expose()
    public get Land_ID(): string { return this.getModelId('Land_ID'); }
    public set Land_ID(value: string) { this.setModelId('Land_ID', value); }
 
    @Expose()
    @Type(() => Land)
    public get Land(): Land { return this.getModel('Land'); }
    public set Land(value: Land) { this.setModel('Land', value); }
    
}
