import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviAuthService, AviChangedAttr, AviCommonService, AviFormFieldService, AviListDetailConst, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Businesscase } from '@avi-x/avi-dto/businesscase/businesscase.model';
import { BusinesscaseDef } from '@avi-x/avi-dto/businesscase/businesscasedef.model';
import { AviBusinesscaseService } from '../services/businesscase.service';
import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';
import { GenericRef } from '@avi-x/avi-dto/shared';
import { MenuItem } from 'primeng/api';
import { StatusWechselRequestInfo } from '@avi-x/avi-dto/workflow/statuswechselrequestinfo.model';
import { AviCustomPropCollectionFormComponent } from '../../formular/components/custompropcollection-form.component';
import { BusinessViewRefreshMode } from './businesscase-view.component';
import { BusinesscaseContextSearchRequestDto } from '@avi-x/avi-dto/businesscase/businesscasecontextsearchrequestdto.model';
import { BusinesscaseFollowupProcessInfo } from '@avi-x/avi-dto/businesscase/businesscasefollowupprocessinfo.model';
import { Router } from '@angular/router';
import { orderBy } from 'lodash-es';
import { Location } from '@angular/common';

const nameof = (nameFunction: ((obj: Businesscase) => any)) => typed_nameof<Businesscase>(nameFunction);

@Component({
    selector: 'avi-businesscase-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [inline-modus]="InlineModus"
        [label-alignment]="'top'"
        [label-width]="'160px'"
        [(Model)]="Model"
        [loading]="Loading"
        [show-buttons]="!InlineModus"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        (onAttrBlur)="onAttrBlur($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly"
        [validation-delegate]="ValidateForm"
        (onTaskMenuHeightChanged)="_onTaskMenuHeightChanged($event)"
        >

        <div formFieldTemplate1>
            <div>
                <avi-custompropcollection-form
                    #customprops
                    [title]="null"
                    mode="json"
                    base-url-prefix="businesscase"
                    [json]="Model?.CustomProperties"
                    [collectiondef-id]="Model?.CustomPropCollectionDef_ID"
                    [model-id]="PropCollectionId"
                    [readonly]="ReadOnly || this.Model?.Bearbeitbar !== true"
                    [parent-editmode]="this.isEditMode"
                    [contents-padding]="false"
                    [show-buttons]="false"
                    [inline-modus]="InlineModus"
                    [card]="false"
                    [auto-focus]="false"
                    [label-alignment]="'top'"
                    [label-width]="'160px'"
                    (onCustomPropAttrBlur)="onAttrBlur($event)"
                    crud-base-right="CAFBusinesscase"
                    >
                </avi-custompropcollection-form>
            </div>
        </div>

    </avi-core-base-form>

    `,
    styles: [
        `::ng-deep .form-inline label.businesscase-description.label-nonmd.col-fixed {
            align-items: baseline;
            margin-top: 0.5rem !important;
        }

        ::ng-deep .childform {
            padding: 0 !important;
        }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseFormComponent extends AviAbstractModelFormComponent<Businesscase> {
    ClassName = 'CORE.COMMON.MODEL.CAFBUSINESSCASE';
    BaseUrl = 'businesscase';
    ClassType = Businesscase;
	FormType = AviBusinesscaseFormComponent;

    @ViewChild('customprops', { static: false}) customprops: AviCustomPropCollectionFormComponent;

    @Input('inline-modus')
    public InlineModus = false;

    @Output('onChanged')
    public onChanged = new EventEmitter<BusinessViewRefreshMode>();

    @Output('onTaskMenuHeightChanged')
    public onTaskMenuHeightChanged = new EventEmitter<number>();

    private BusinesscaseDef: BusinesscaseDef;
    private Tasks: StatusWechselRequestInfo[];
    private FollowupProzesses: BusinesscaseFollowupProcessInfo[];

    public contextId: string;
    public contextType: string;

    PropCollectionId: string;
    ParentId: string;
    BusinesscaseDefId: string;
    Context1: string;
    Context2: string;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public router: Router,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        private businesscaseService: AviBusinesscaseService,
        private authService: AviAuthService,
        private location: Location,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFBusinesscase';
    }

    public async createModel(): Promise<Businesscase> {
        let res: Businesscase = null;

        if (this.ParentId && this.BusinesscaseDefId)
            res = await this.apiService.getModel(Businesscase, `${this.getBaseUrl()}/create/${this.ParentId}/${this.BusinesscaseDefId}`);
        else {
            res = await this.apiService.getModel(Businesscase, `${this.getBaseUrl()}/create`);
            if (this.BusinesscaseDefId)
                res.vonBusinesscaseDef_ID = this.BusinesscaseDefId;
        }

        await this.afterCreateModel(res);
        return res;
    }

    async saveModel(model: Businesscase) {
        this.fillCustomProperties().then(w => super.saveModel(this.Model));
    }

    private async fillCustomProperties() {
        if (this.Model.CustomPropCollectionDef_ID && this.customprops?.Model)
             this.Model.CustomProperties = await this.apiService.post(`${this.getBaseUrl()}/custompropcollection/serialize`, this.customprops.Model);
    }

    initParams(params: { [k: string]: any; }) {
        if (params) {
            if (params.ParentId)
                this.ParentId = params.ParentId;
            if (params.BusinesscaseDefId)
                this.BusinesscaseDefId = params.BusinesscaseDefId;
            if (params.Context1)
                this.Context1 = params.Context1;
            if (params.Context2)
                this.Context2 = params.Context2;
        }
    }

    public async afterInitForm() {
        await this.setFromDef();

        if (!this.isEditMode && this.BusinesscaseDefId)
            this.initFromDef();

        const bcdefRO = this.isEditMode || this.Model.ParentBusinesscase_ID != null;

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.vonBusinesscaseDef_ID), bcdefRO || this.BusinesscaseDefId != null);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Context1), bcdefRO || this.Context1 != null);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Context2), bcdefRO || this.Context2 != null);

        if (this.ReadOnly || this.InlineModus) {
            this.Tasks = await this.apiService.getModelList(StatusWechselRequestInfo, `${this.getBaseUrl()}/${this.Model.Id}/tasks`);

            if (this.Model.Erledigt === true)
                this.FollowupProzesses = await this.apiService.getModelList(BusinesscaseFollowupProcessInfo, `${this.getBaseUrl()}/${this.Model.Id}/followup_processes`);

            /*if (this.businesscaseService.isIntern())*/ {
                const fieldTitle = this.Fields.find(w => w.Name === nameof(c => c.Title));
                fieldTitle.Prefix = this.Model.NumberBez;
                fieldTitle.PrefixClass = 'addon-text-only';
            }
        }

        if (this.InlineModus)
            this.SetInlineModus();

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.CreatedBy_ID), this.Model.Applicant_ID !== this.Model.CreatedBy_ID);
    }

    public SetInlineModus() {
        this.Fields.forEach((w) => this.formFieldService.setFieldDisabled(this.Fields, w.Name, true));

        if (this.Model.Bearbeitbar === true) {
            this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.Description), false);
            this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.Priority), false);
            this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.Title), false);
        }// else
//            this.customprops.SetInlineModus();

        // // Assignee kann geändert werden wenn intern und noch nicht erledigt
        // if (this.businesscaseService.isIntern() && this.Model.Erledigt != true && this.Model.Verantwortlichkeit.Id === AviListDetailConst.CAF_STATUSDEF_VERANTWORTLICHKEIT_SACHBEARBEITER)
        //     this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.AssignedTo_ID), false);

        // // Applicant kann geändert werden wenn extern und noch nicht erledigt
        // if (!this.businesscaseService.isIntern() && this.Model.Erledigt != true && this.Model.Verantwortlichkeit.Id === AviListDetailConst.CAF_STATUSDEF_VERANTWORTLICHKEIT_ANTRAGSTELLER)
        //     this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.Applicant_ID), false);

    }

    async onAttrBlur(data: AviChangedAttr) {
        if (this.InlineModus) {

            this._ModelId = this.Model.Id;
            await this.fillCustomProperties();

            try {
                // if (data.field == nameof(c => c.AssignedTo_ID))
                //     await this.apiService.get(`${this.getBaseUrl()}/${this.Model.Id}/assignee/${this.Model.AssignedTo_ID}`);
                // else if (data.field == nameof(c => c.Applicant_ID))
                //     await this.apiService.get(`${this.getBaseUrl()}/${this.Model.Id}/applicant/${this.Model.Applicant_ID}`);
                // else
                    await this.apiService.put(`${this.getBaseUrl()}/${this.Model.Id}`, this.Model);
            } catch (error) {
                this.commonService.notificateError(error);
            }

            await this.refresh();
            // await this.afterInitForm();
            // this.FillTaskMenuItems();

            this.Form.markAsPristine();

            // this.onSavedSuccessfulDelegate.emit(this.Model);
            this.onChanged.emit(BusinessViewRefreshMode.Verlauf);
        }
    }

    private initFromDef() {
        if (this.BusinesscaseDef) {
            this.Model.Priority = this.BusinesscaseDef.DefaultPriority;
            this.Model.Title = this.BusinesscaseDef.DefaultTitle;
        }
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.vonBusinesscaseDef_ID)) {
            await this.setFromDef();
            this.initFromDef();

            this.cdr.markForCheck();
        }

        if (data.field === nameof(c => c.Applicant_ID) || data.field === nameof(c => c.CreatedBy_ID))
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.CreatedBy_ID), this.Model.Applicant_ID !== this.Model.CreatedBy_ID);
    }

    private async setFromDef() {
        if (this.Model.vonBusinesscaseDef_ID) {
            this.BusinesscaseDef = await this.apiService.getModelById(BusinesscaseDef, `${this.getBaseUrl()}/businesscasedef`, this.Model.vonBusinesscaseDef_ID);

            // Antrag wird intern erfassst und hat noch kein zugewiesenen Mitarbeiter -> automatisch zuweisen
            if (!this.ReadOnly && !this.isEditMode && this.businesscaseService.isIntern() && this.Model.AssignedTo_ID == null) {
                this.Model.AssignedTo_ID = this.authService.CurrentUser?.Uid;
                // const startStatus = await this.apiService.postModel(StatusDef, `${this.getBaseUrl()}/start_status`, this.Model);
                // if (startStatus && startStatus.Verantwortlichkeit.Id === AviListDetailConst.CAF_STATUSDEF_VERANTWORTLICHKEIT_SACHBEARBEITER)
                //     this.Model.AssignedTo_ID = this.authService.CurrentUser?.Uid;
            }

            this.Model.CustomPropCollectionDef_ID = this.BusinesscaseDef.CustomPropCollectionDef_ID;

            // TODO: Wenn man einen bestehenden Geschäftsfall ändert und später eine Attributsammlung hinterlegt wird, kann man bestehende Geschäftsfälle hier ändern
            // Frage ist wie umgehen mit Validierungen (bspw. Pflichtfelder in der Attributsammlung die nicht befüllt sind)
            if (!this.ReadOnly && (!this.isEditMode /*|| this.Model.CustomProperties == null*/) && this.Model.CustomPropCollectionDef_ID && !this.Model.CustomProperties) {
                this.PropCollectionId = '0';
            }
            else
                 this.PropCollectionId = this.Model.CustomPropCollectionDef_ID;

            // if (this.PropCollectionId)
            //     this.cdr.markForCheck();

            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Priority), this.BusinesscaseDef.ShowPriority.Id === AviListDetailConst.JA);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DueDate), this.BusinesscaseDef.ShowDueDate.Id === AviListDetailConst.JA);

            const ctx1Visible: boolean = !!(this.BusinesscaseDef.Context1ModelMeta_ID  && (!this.ReadOnly || (this.Model.Context1 && this.Model.Context1.ModelID && this.Model.Context1.ModelType)));
            const ctx2Visible: boolean = !!(this.BusinesscaseDef.Context2ModelMeta_ID  && (!this.ReadOnly || (this.Model.Context2 && this.Model.Context2.ModelID && this.Model.Context2.ModelType)));

            if (ctx1Visible) {
                const field = this.Fields.find(w => w.Name === nameof(c => c.Context1));
                field.DropdownValueField = (elem) => new GenericRef(elem.Id, this.BusinesscaseDef.Context1ModelMeta.Model_Name);
                field.Label = this.BusinesscaseDef.Context1Bezeichnung1;
                field.LabelLiteral = true;

                if (!this.isEditMode && this.Context1)
                    this.Model.Context1 = new GenericRef(this.Context1, this.BusinesscaseDef.Context1ModelMeta.Model_Name);
            }

            if (ctx2Visible) {
                const field = this.Fields.find(w => w.Name === nameof(c => c.Context2));
                field.DropdownValueField = (elem) => new GenericRef(elem.Id, this.BusinesscaseDef.Context2ModelMeta.Model_Name);
                field.Label = this.BusinesscaseDef.Context2Bezeichnung1;
                field.LabelLiteral = true;

                if (!this.isEditMode && this.Context2)
                    this.Model.Context2 = new GenericRef(this.Context2, this.BusinesscaseDef.Context2ModelMeta.Model_Name);
            }

            const isCreation = !this.ReadOnly && !this.InlineModus && !this.isEditMode;

            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.vonBusinesscaseDef_ID), isCreation || this.BusinesscaseDef.ShowDef.Id === AviListDetailConst.JA)
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Title), true);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.CreatedBy_ID), !isCreation);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Applicant_ID), !isCreation);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AssignedTo_ID), this.businesscaseService.isIntern());
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Applicant_ID), this.businesscaseService.isIntern());

            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Context1), ctx1Visible);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Context2), ctx2Visible);
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Context1), ctx1Visible && this.BusinesscaseDef.Context1Mandatory.Id === AviListDetailConst.JA);
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Context2), ctx2Visible && this.BusinesscaseDef.Context2Mandatory.Id === AviListDetailConst.JA);

            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Description), this.BusinesscaseDef.ShowDescription.Id === AviListDetailConst.JA);
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Description), this.BusinesscaseDef.ShowDescription.Id === AviListDetailConst.JA && this.BusinesscaseDef.DescriptionMandatory.Id === AviListDetailConst.JA);

            await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model, false);

        } else {
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Description), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Priority), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DueDate), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Context1), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Context2), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Title), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.CreatedBy_ID), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Applicant_ID), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AssignedTo_ID), false);
        }
    }

    queryBusinesscaseDef = async (field: any) => {
        const ret = [];
        if (this.Model) {
            const p = await this.businesscaseService.getBusinesscaseDefList(this.ReadOnly || field.Readonly || field.Disabled ? this.Model.vonBusinesscaseDef_ID : null, null);
            p.forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    queryBusinesscaseContext = async (field: any, modelId: string, query: string, contextIndex: number) => {
        const ret = [];
        if (this.Model && this.BusinesscaseDef) {
            const cfg = new BusinesscaseContextSearchRequestDto()
            cfg.Query = query;
            cfg.BusinesscaseDefId = this.BusinesscaseDef.Id;
            cfg.ContextIndex = contextIndex;
            cfg.Context1 = this.Model.Context1;
            cfg.Context2 = this.Model.Context2;
            cfg.ModelId =  modelId;
            const p = await this.apiService.postModelList(ReferenceDto, `${this.getBaseUrl()}/context/search`, cfg);
            if (contextIndex === 1)
                p.forEach(elem => ret.push({ label: elem.Representation, value: new GenericRef(elem.Id, this.BusinesscaseDef.Context1ModelMeta.Model_Name), valueFull: elem }));
            else
                p.forEach(elem => ret.push({ label: elem.Representation, value: new GenericRef(elem.Id, this.BusinesscaseDef.Context2ModelMeta.Model_Name), valueFull: elem }));
        }
        return ret;
    }

    queryBusinesscaseContext1 = async (field: any, modelId: string, query: string) => this.queryBusinesscaseContext(field, modelId, query, 1);
    queryBusinesscaseContext2 = async (field: any, modelId: string, query: string) => this.queryBusinesscaseContext(field, modelId, query, 2);

    queryBusinesscaseAppUser = async (field: any) => {
        const ret = [];
        if (this.Model && this.BusinesscaseDef) {
            const cfg: any = { ModelId: this.ReadOnly || field.Readonly || field.Disabled ? this.Model[field.Name] : null, BusinesscaseDefId: this.BusinesscaseDef.Id, Context1Id: this.Model.Context1?.ModelID, Context2Id: this.Model.Context2?.ModelID };
            const p = await this.apiService.postModelList(ReferenceDto, `${this.getBaseUrl()}/appuser/search`, cfg);
            p.forEach(elem => ret.push({ label: elem.Representation, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    initFields() {
		if (this.ReadOnly || this.InlineModus)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonBusinesscaseDef_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.VONBUSINESSCASEDEF', this.queryBusinesscaseDef, true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Title), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.TITLE', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateRichText(nameof(c => c.Description), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.DESCRIPTION', false).setLabelClass('businesscase-description').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Context1), 'Kontext1', false, null, null, 0, this.queryBusinesscaseContext1).setAutoselectSingle().setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Context2), 'Kontext2', false, null, null, 0, this.queryBusinesscaseContext2).setAutoselectSingle().setMDSizeFull());

        if ((this.ReadOnly || this.InlineModus)) {
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.StatusBez), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.STATUSBEZ').setMDSizeFull());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Verantwortlichkeit), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.VERANTWORTLICHKEIT', 'caf_statusdef_verantwortlichkeit', true, true, this.ReadOnly).setMDSizeFull());
        }

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Priority), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.PRIORITY', 'caf_businesscase_priority', true, true, this.ReadOnly).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.DueDate), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.DUEDATE', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.CreatedBy_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.CREATEDBY', this.queryBusinesscaseAppUser, true).setReadonly(true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Applicant_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.APPLICANT', this.queryBusinesscaseAppUser, true).setMDSizeFull());

        if (this.businesscaseService.isIntern()) {
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.AssignedTo_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.ASSIGNEDTO', this.queryBusinesscaseAppUser, false).setMDSizeFull());
        }

        this.Fields.push(this.formFieldService.CreateTemplate(1, ' ').setLabelVisible(false).setFieldClass('childform').setMDSizeFull());
    }


    async getTaskMenuItems(): Promise<MenuItem[]> {
        var items: MenuItem[] = [];
        if (this.ModelId && this.EnableTasks && this.Tasks) {
            // if (this.CRUDCanEdit && this.Model.Bearbeitbar === true)
            //     items.push({ label: 'Bearbeiten', command: () => this.openEditDialog(), icon: 'pi pi-pencil' });
            if (this.CRUDCanDelete && this.Model.Bearbeitbar === true)
                items.push({ label: 'Löschen', command: () => this.openDeleteDialog(), icon: 'pi pi-trash' });

            // Assignee kann geändert werden wenn intern und noch nicht erledigt
            if (this.businesscaseService.isIntern() && this.Model.Erledigt != true && this.Model.Verantwortlichkeit.Id === AviListDetailConst.CAF_STATUSDEF_VERANTWORTLICHKEIT_SACHBEARBEITER)
                items.push({ label: 'Zuständiger MA ändern', command: async () => {
                    const res = await this.businesscaseService.openChangeAssigneeDialog(this.Model);
                    if (res)
                        this.onChanged.emit(BusinessViewRefreshMode.All);
                },
                icon: 'pi pi-user' });

            // Applicant kann geändert werden wenn extern und noch nicht erledigt
            if (!this.businesscaseService.isIntern() && this.Model.Erledigt != true && this.Model.Verantwortlichkeit.Id === AviListDetailConst.CAF_STATUSDEF_VERANTWORTLICHKEIT_ANTRAGSTELLER)
                items.push({ label: 'Antragsteller ändern', command: async () => {
                    const res = await this.businesscaseService.openChangeApplicantDialog(this.Model);
                    if (res)
                        this.onChanged.emit(BusinessViewRefreshMode.All);
                }, icon: 'pi pi-user' });

            if (items.length > 0 && this.Tasks.length > 0)
                items.push({ separator: true });

            this.Tasks = orderBy(this.Tasks, [w => w.Sorter], ['asc']);
            this.Tasks.forEach(w => items.push({ label: w.Bezeichnung, command: () => this.executeTask(w), icon:  w.Icon, styleClass:  w.StyleClass }));
        }

        if (this.ModelId && /*this.EnableTasks &&*/ this.FollowupProzesses)
            this.FollowupProzesses.forEach(w => items.push({ label: w.Bezeichnung, command: () => this.executeFollowupProcess(w) }));

        return items;
    }


    openDeleteDialog() {
        this.apiService.deleteObject(this.getBaseUrl(), this.ModelId, () => {
            if (this.CloseSessionOnDelete) {
                if (this.businesscaseService.UseRouting)
                    this.sessionController.closeCurrentSession();
                else 
                    this.location.back();
            }
        });
    }

    async executeFollowupProcess(inf: BusinesscaseFollowupProcessInfo) {
        const modelId = await this.businesscaseService.createBusinesscase(this.Model.Id, inf.FollowupProcessId);
        if (modelId)
            this.businesscaseService.openBusinesscase(modelId);
    }

    async executeTask(task: StatusWechselRequestInfo) {
        try {
            const res = await this.businesscaseService.openStatusWechselDialog(this.Model, task);
            if (res)
                this.onChanged.emit(BusinessViewRefreshMode.All);
        } catch (err) {
            this.commonService.notificateError(err);
        }
    }

    ValidateForm = (): boolean => {
        let result: boolean = true;

        if (this.Model.CustomPropCollectionDef_ID && this.customprops)
            result = this.customprops.canSaveForm();

        return result;
    };

    _onTaskMenuHeightChanged(height: number) {
        this.onTaskMenuHeightChanged.emit(height);
    }
}
