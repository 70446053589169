import { CustomPropCollectionDef } from '@avi-x/avi-dto/system/custompropcollectiondef.model';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgxPermissionsService } from 'ngx-permissions';

const nameof = (nameFunction: ((obj: CustomPropCollectionDef) => any)) => typed_nameof<CustomPropCollectionDef>(nameFunction);

@Component({
    selector: 'avi-custompropcollectiondef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviCustomPropCollectionDefFormComponent extends AviAbstractModelFormComponent<CustomPropCollectionDef> {
    ClassName = 'CORE.COMMON.MODEL.CAFCUSTOMPROPCOLLECTIONDEF';
    BaseUrl = 'custompropcollectiondef';
    ClassType = CustomPropCollectionDef;
    FormType = AviCustomPropCollectionDefFormComponent;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
        public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

        this.CRUDBaseRight = 'CAFCustomPropCollectionDef';
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'Bezeichnung', true).setMDSizeHalf());
    }
}
