import { Component, Input, ChangeDetectorRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService, AviDialogComponent, AviBaseSearcherComponent, AviSearcherColumn } from '@avi-x/avi-core';
import { VwbPFormularDaten, VwbPListDetailConst, VwbPRechtstraeger, VwbPSchwaerzenAntragDaten, VwbPVersion } from '@avi-x/vwbp-public';
import { AntragEditMode, VwbPInternSchwaerzenAntragFormComponent } from '../schwaerzen-form/schwaerzen-form.component';

@Component({
    selector: 'vwbp-intern-schwaerzen-panel',
    templateUrl: './schwaerzen-panel.component.html',
    providers: [DecimalPipe, PercentPipe]
})
export class VwbPInternSchwaerzenPanelComponent {
    @ViewChild('AntragFormDialog', { static: false })
    AntragFormDialog: AviDialogComponent;

    @ViewChild('AntragForm', { static: false })
    public AntragForm: VwbPInternSchwaerzenAntragFormComponent;

    @ViewChild('antragListe', { static: true })
    public antragListe: AviBaseSearcherComponent;

    @ViewChild('taskDialog', { static: false })
    taskDialog: AviDialogComponent;

    @Input('rechtstraeger')
    public Rechtstraeger: VwbPRechtstraeger;

    @Input('formular-daten')
    public FormularDaten: VwbPFormularDaten[];

    @Input('force-collapsed')
    public ForceCollapsed: boolean = false;

    VersionId: string = null;
    _Version: VwbPVersion = null;
    public get Version(): VwbPVersion {
        return this._Version;
    }

    @Input('version')
    public set Version(value: VwbPVersion) {
        this._Version = value;
        this.VersionId = this.Version?.Id;
        this.LoadAntraege();
    }

    @Input('can-edit')
    public CanEdit: boolean = true;

    @Input('readonly')
    public Readonly: boolean = false;

    @Output('onChanged')
    public OnChanged: EventEmitter<any> = new EventEmitter();

    NumAntraege: number = 0;
    NumBewilligteAntraege: number = 0;

    KannAntraegeHaben: boolean = true;

    formActionButtons: any[] = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];
    formActionButtonsReadonly: any[] = [{ title: 'CORE.COMMON.SCHLIESSEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, private cdr: ChangeDetectorRef) {
    }

    onAntragListInit(data) {
        this.antragListe.beginInit();

        const colName = AviSearcherColumn.CreateCustom(
            'WB',
            'VWBP.INTERN.SCHWAERZEN.WB',
            (row, col) => {
                if (row['WBPartnerTyp'].Id !== VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON) {
                    const name = 'Firma: ' + this.commonService.nl2br(this.commonService.escapeHtml(row['RechtstraegerName']));
                    return name +
                        (row['RegisterNummer'] ? '<br />Registernummer: ' + this.commonService.formatDate(row['RegisterNummer'], 'L') : '') +
                        (row['Gruendungsdatum'] ? '<br />Gründungsdatum: ' + this.commonService.formatDate(row['Gruendungsdatum'], 'L') : '') +
                        (row['Rechtsform'] ? '<br />Rechtsform: ' + row['Rechtsform'] : '') +
                        (row['RechtstraegerSitz'] ? '<br />Sitz: ' + row['RechtstraegerSitz'] : '');
                } else {
                    const name = 'Name, Vorname: ' + this.commonService.nl2br(this.commonService.escapeHtml(row['Vorname'] + ', ' + row['Name']));
                    return name + '<br />Geburtsdatum: ' + this.commonService.formatDate(row['Geburtsdatum'], 'L') + '<br />Wohnsitz: ' + row['LandISOCode'] + '<br />Staatsangehörigkeit(en): ' + row['NationalitaetISOCodes'];
                }
            },
            false);
        colName.SortColumn = 'Name';
        this.antragListe.addColumn(colName);

        this.antragListe.addDateColumn('GueltigAb', 'VWBP.INTERN.SCHWAERZEN.GUELTIGAB', null, true);
        this.antragListe.addDateColumn('GueltigBis', 'VWBP.INTERN.SCHWAERZEN.GUELTIGBIS', null, true);

        this.antragListe.addListTypeColumn('Status', 'Status', 'vwbp_schwaerzen_antrag_status', true, true, null, 'v1/vwbp')
            .setFormatDelegate(w => {
                let res = w.Status.Bezeichnung;

                if (w.AntragAktiv.Id !== VwbPListDetailConst.VWBP_MAHNWESEN_AKTIV)
                    res = res + ' / pausiert';

                if (w.AntragKontrollieren === true && w.Status.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELEHNT)
                    res = res + ' (zu prüfen)';

                return res;
            });

        this.antragListe.addListTypeColumn('Begruendung', 'Begruendung', 'vwbp_schwaerzen_antrag_begruendung', true, true, null, 'v1/vwbp');

        this.antragListe.addListTypeColumn('FristablaufGrund', 'Grund Fristablauf', 'vwbp_schwaerzen_antrag_fristablauf', true, true, null, 'v1/vwbp');

        this.antragListe.addColumn(
            AviSearcherColumn.CreateButton('btnAct', 'VWBP.COMMON.AKTIONEN', null, null, null, null, '156px', ['show', 'edit', 'delete'])
                .setTooltipDelegate((row, col, buttonId) => {
                    if (buttonId === 'show')
                        return 'Antrag anzeigen';
                    else if (buttonId === 'edit')
                        return 'Antrag ändern';
                    else if (buttonId === 'delete')
                        return 'Antrag löschen';
                })
                .setButtonOnClickDelegate((row, col, buttonId) => {
                    if (buttonId === 'show')
                        this.showAntrag(row['Id']);
                    else if (buttonId === 'edit')
                        this.editAntrag(row['Id']);
                    else
                        this.deleteAntrag(row['Id']);
                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-flat': true,
                        'p-button-text': true,
                        'p-button-rounded': true,
                        'hidden': (buttonId === 'edit' || buttonId === 'delete') && (!this.CanEdit || row['Status'].Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ANTRAG)
                    };
                })
                .setButtonIconDelegate((row, col, buttonId) => {
                    if (buttonId === 'show')
                        return 'pi pi-eye';
                    else if (buttonId === 'edit')
                        return 'pi pi-pencil';
                    else
                        return 'pi pi-trash';
                })
                .setHeaderColumnClass('text-right')
                .setSortable(false)
        );

        this.antragListe.endInit();
    }

    getSelectedAntrag() {
        return this.antragListe.SelectedRows[0];
    }

    public SearchDelegate: any = async (searchValue: string) => this.doSearch();

    async doSearch() {
        let res = await this.apiService.getModelList(VwbPSchwaerzenAntragDaten, `v1/intern/vwbp/schwaerzen/antrag/list/${this.Rechtstraeger.Id}`);

        this.NumAntraege = res.length;

        res = this.antragListe.ApplyColumnFilters(res);
        res = this.antragListe.ApplyColumnSorting(res);

        return res;
    }

    async LoadAntraege() {
        if (this.VersionId) {

        }

        this.cdr.markForCheck();
    }

    OnSave() {
        this.CloseDialog();
        this.LoadAntraege();
        this.OnChanged.emit(null);
    }

    CloseDialog() {
        this.taskDialog.close();
    }


    private closeForm() {
        this.AntragFormDialog.close();
        this.AntragForm?.resetForm();
    }

    public async showAntrag(formId) {
        await this.AntragForm.InitForm(AntragEditMode.SHOW, formId);
        this.AntragFormDialog.open();
    }

    public async editAntrag(formId) {
        await this.AntragForm.InitForm(AntragEditMode.EDIT, formId);
        this.AntragFormDialog.open();
    }

    public async deleteAntrag(formId) {
        this.commonService.confirmDelete(`Möchten Sie den Antrag wirklich löschen?`,
            () => {
                this.apiService.delete(`v1/intern/vwbp/schwaerzen/antrag/${formId}`).then(r => {
                    this.commonService.notificateSuccess('Gelöscht');
                    this.OnChanged.emit(null);
                });
            }
        );
    }

    public async createAntrag() {
        await this.AntragForm.InitForm(AntragEditMode.CREATE, '0', this.Rechtstraeger.Id);
        this.AntragFormDialog.open();
    }

    // public async checkAntrag() {
    //     await this.AntragForm.InitForm(AntragEditMode.CHECK, this.getSelectedAntrag().Id);
    //     this.AntragFormDialog.open();
    // }

    onAntragSavedSuccessful(event) {
        this.AntragFormDialog.close();
        this.AntragForm.resetForm();
        this.OnChanged.emit(null);
    }

    HandleChanged() {
        this.OnChanged.emit(null);
    }

    public async forceRefresh() {
        this.antragListe.forceRefresh();
    }
}
