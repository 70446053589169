import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AviCoreModule } from '@avi-x/avi-core';

import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

import { FormsModule } from '@angular/forms';
import { AviDecisionTableDefSearcherComponent } from './components/avi-decisiontabledef-searcher.component';
import { AviDecisionTableDefFormComponent } from './components/avi-decisiontabledef-form.component';
import { AviDecisionTableParamDefSearcherComponent } from './components/avi-decisiontableparamdef-searcher.component';
import { AviDecisionTableParamDefFormComponent } from './components/avi-decisiontableparamdef-form.component';
import { AviDecisionTableSearcherComponent } from './components/avi-decisiontable-searcher.component';
import { AviDecisionTableFormComponent } from './components/avi-decisiontable-form.component';
import { AviDecisionTableEntrySearcherComponent } from './components/avi-decisiontableentry-searcher.component';
import { AviDecisionTableEntryFormComponent } from './components/avi-decisiontableentry-form.component';
import { AviDecisionTableService } from './services/avi-decisiontable.service';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        AviCoreModule,

        FlexLayoutModule,
        PanelModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ButtonModule,
        SplitButtonModule,
        InputTextModule,

        NgxPermissionsModule.forChild(),

        TranslateModule
    ],
    exports: [
        AviDecisionTableDefSearcherComponent,
        AviDecisionTableDefFormComponent,
        AviDecisionTableParamDefSearcherComponent,
        AviDecisionTableParamDefFormComponent,
        AviDecisionTableSearcherComponent,
        AviDecisionTableFormComponent,
        AviDecisionTableEntrySearcherComponent,
        AviDecisionTableEntryFormComponent
    ],
    declarations: [
        AviDecisionTableDefSearcherComponent,
        AviDecisionTableDefFormComponent,
        AviDecisionTableParamDefSearcherComponent,
        AviDecisionTableParamDefFormComponent,
        AviDecisionTableSearcherComponent,
        AviDecisionTableFormComponent,
        AviDecisionTableEntrySearcherComponent,
        AviDecisionTableEntryFormComponent
    ],
    providers: [ AviDecisionTableService ]
})
export class AviDecisionTableModule { }
