import { Expose, Type } from 'class-transformer';

import { BaseModelState } from '../shared/basemodel.model';

export class PartnerRolleTreeElement extends BaseModelState {
    @Expose()
    public get PartnerNr(): string { return this.getProperty('PartnerNr'); }
    public set PartnerNr(value: string) { this.setProperty('PartnerNr', value); }

    @Expose()
    public get PartnerRepresentation(): string { return this.getProperty('PartnerRepresentation'); }
    public set PartnerRepresentation(value: string) { this.setProperty('PartnerRepresentation', value); }

    @Expose()
    public get RolleDefKurzBez(): string { return this.getProperty('RolleDefKurzBez'); }
    public set RolleDefKurzBez(value: string) { this.setProperty('RolleDefKurzBez', value); }

    @Expose()
    public get RolleKontext(): string { return this.getProperty('RolleKontext'); }
    public set RolleKontext(value: string) { this.setProperty('RolleKontext', value); }

    @Expose()
    public get Beschreibung(): string { return this.getProperty('Beschreibung'); }
    public set Beschreibung(value: string) { this.setProperty('Beschreibung', value); }

    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }

    @Expose()
    @Type(() => Date)
    public get Geburtsdatum(): Date { return this.getProperty('Geburtsdatum'); }
    public set Geburtsdatum(value: Date) { this.setProperty('Geburtsdatum', value); }

    @Expose()
    public get EmailWaiver(): string { return this.getProperty('EmailWaiver'); }
    public set EmailWaiver(value: string) { this.setProperty('EmailWaiver', value); }

    @Expose()
    public get EroeffGesBez(): string { return this.getProperty('EroeffGesBez'); }
    public set EroeffGesBez(value: string) { this.setProperty('EroeffGesBez', value); }

    @Expose()
    public get RolleAktivId(): string { return this.getProperty('RolleAktivId'); }
    public set RolleAktivId(value: string) { this.setProperty('RolleAktivId', value); }

    @Expose()
    public get RolleAktivBezeichnung(): string { return this.getProperty('RolleAktivBezeichnung'); }
    public set RolleAktivBezeichnung(value: string) { this.setProperty('RolleAktivBezeichnung', value); }

    @Expose()
    public get ModelRefRepresentation(): string { return this.getProperty('ModelRefRepresentation'); }
    public set ModelRefRepresentation(value: string) { this.setProperty('ModelRefRepresentation', value); }

    @Expose()
    @Type(() => Date)
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @Type(() => Date)
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    @Type(() => Date)
    public get Todesdatum(): Date { return this.getProperty('Todesdatum'); }
    public set Todesdatum(value: Date) { this.setProperty('Todesdatum', value); }

    @Expose()
    public get NatpersonStatus(): string { return this.getProperty('NatpersonStatus'); }
    public set NatpersonStatus(value: string) { this.setProperty('NatpersonStatus', value); }

    @Expose()
    public get BegAnteil(): string { return this.getProperty('BegAnteil'); }
    public set BegAnteil(value: string) { this.setProperty('BegAnteil', value); }

    @Expose()
    public get Laender(): string { return this.getProperty('Laender'); }
    public set Laender(value: string) { this.setProperty('Laender', value); }

    @Expose()
    public get Nationalitaeten(): string { return this.getProperty('Nationalitaeten'); }
    public set Nationalitaeten(value: string) { this.setProperty('Nationalitaeten', value); }

    @Expose()
    public get vpAktivId(): string { return this.getProperty('vpAktivId'); }
    public set vpAktivId(value: string) { this.setProperty('vpAktivId', value); }

    @Expose()
    public get zpAktivId(): string { return this.getProperty('zpAktivId'); }
    public set zpAktivId(value: string) { this.setProperty('zpAktivId', value); }

    @Expose()
    public get vpAktivBezeichnung(): string { return this.getProperty('vpAktivBezeichnung'); }
    public set vpAktivBezeichnung(value: string) { this.setProperty('vpAktivBezeichnung', value); }

    @Expose()
    public get zpAktivBezeichnung(): string { return this.getProperty('zpAktivBezeichnung'); }
    public set zpAktivBezeichnung(value: string) { this.setProperty('zpAktivBezeichnung', value); }
}
