<avi-core-page
    title="XML Import Jobs"
    [card]="Card"
    [collapsible]="false"
    [expanded]="true"
    [no-box-shadow]="true"
    [contents-padding]="true"
>
    <avi-core-base-searcher
        #jobSearcher
        [hide-when-noresults]="true"
        [use-router-params]="false"
        [search-delegate]="JobSearchDelegate"
        (onDoubleClick)="onDoubleClickSearcher($event)"
        [rows]="20"
        [auto-search]="true"
        [show-autofilter]="true"
        [toolbar]="false"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [lazy-loading]="true"
        [card]="false"
        (onInit)="onJobSearcherInit($event)"
        searcher-title="Jobs"
        [show-totalrecords]="false"
        [select-first-result]="false"
    >
    </avi-core-base-searcher>
</avi-core-page>

<avi-core-dialog #jobDialog identifier="jobDialog" [showCloseIcon]="false" [closeOnEscape]="false">
    <vwbp-admin-xmlimportjob-details #jobView></vwbp-admin-xmlimportjob-details>

    <div class="grid mb-4 mr-5 ml-5">
        <div class="col">
            <button
                pButton
                type="button"
                [label]="'CORE.COMMON.CLIPBOARD_BUTTON' | translate"
                (click)="CopyToClipboard()"
                class="w-100p-m m p-button-secondary p-button-outlined"
            ></button>
        </div>

        <div class="col grid justify-content-end m-0">
            <button
                pButton
                type="button"
                [label]="'CORE.COMMON.SCHLIESSEN_BUTTON' | translate"
                (click)="CloseRTView()"
                class="w-100p-m m p-button-secondary p-button-outlined"
            ></button>
        </div>
    </div>
</avi-core-dialog>
