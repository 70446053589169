import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTabNav } from '@angular/material/tabs';
import { VwbPCommonService } from '@avi-x/vwbp-public';
import { VwbPInternBehoerdenService } from '../../services/behoerden.service';

@Component({
    selector: 'vwbp-intern-behoerden-index-suche',
    templateUrl: './behoerden-suche-index.component.html',
    styleUrls: ['./behoerden-suche-index.component.scss']
})
export class VwbPInternBehoerdenSucheIndexComponent implements OnInit {

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(private vwbpCommonService: VwbPCommonService,
        private behoerdenService: VwbPInternBehoerdenService
    ) { }


    private _navLinks = null;
    get NavLinks(): any[] {
        if (this._navLinks === null) {
            this._navLinks = [
                { path: './rechtstraeger', label: 'VWBP.COMMON.RT' },
                { path: './wb-np', label: 'VWBP.INTERN.BEHOERDENABFRAGE.TITLE_WBS_NAT' },
                { path: './wb-jp', label: 'VWBP.INTERN.BEHOERDENABFRAGE.TITLE_WBS_JUR' },
                { path: './kdb', label: 'VWBP.COMMON.KDB' }
            ];


        }
        return this._navLinks;
    }

    ngOnInit() {
    }
}
