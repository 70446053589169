import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviTechRoleFormComponent } from './techrole-form.component';
import { TechRoleSearchResponseDto } from '@avi-x/avi-dto/system/techrolesearchresponsedto.model';
import { TechRole } from '@avi-x/avi-dto/system/techrole.model';
import { TranslateService } from '@ngx-translate/core';
import { TechRoleLinkInfo } from './TechRoleLinkInfo';

const nameof = (nameFunction: ((obj: TechRoleSearchResponseDto) => any)) => typed_nameof<TechRoleSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-techrole-searcher',
    templateUrl: './techrole-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: AviAbstractModelSearcherComponent, useExisting: AviTechRoleSearcherComponent }]
})
export class AviTechRoleSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.TECHROLE';
    SettingsKey = 'settings.searcher.techrole';
    BaseUrl = 'techrole';
	FormType = AviTechRoleFormComponent;

    CanEdit: boolean = false;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        private translateService: TranslateService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('TechRole schreiben').then(w => this.CanEdit = w);
    }

    public async editObject(selectedRows) {
        let techRole = selectedRows as TechRole;
        if (techRole.SysRole == 1) {
            this.commonService.confirm({ message: this.translateService.instant("CORE.COMMON.MODEL.TECHROLE.DISALLOWMODIFICATIONOFSYSTEMROLES"), header: this.translateService.instant("CORE.COMMON.MODEL.TECHROLE.TECHROLE_EDIT"), rejectVisible: false, icon: "fa fa-ban" });
            return;
        }

        await super.editObject(selectedRows);
    }

    public deleteObject(selectedRows) {
        let techRole = selectedRows as TechRole;
        if (techRole.SysRole == 1) {
            this.commonService.confirm({ message: this.translateService.instant("CORE.COMMON.MODEL.TECHROLE.DISALLOWDELETIONOFSYSTEMROLES"), header: this.translateService.instant("CORE.COMMON.MODEL.TECHROLE.TECHROLE_DELETE"), rejectVisible: false, icon: "fa fa-ban" });
            return;
        }

        super.deleteObject(selectedRows);
    }

    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.sessionController.openSession('techrole-index', this.SelectedRows.Id, null, null, false, new TechRoleLinkInfo(this.SelectedRows));
            //this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.RoleName), 'CORE.COMMON.MODEL.TECHROLE.ROLENAME', true);
        searcher.addIntegerColumn(nameof(c => c.SysRole), 'CORE.COMMON.MODEL.TECHROLE.SYSROLE', true);
        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(TechRoleSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
