import { Expose, Type } from 'class-transformer';
import { BaseModel } from '../shared/basemodel.model';

export class AviAppServerInfoDto extends BaseModel {
    @Expose()
    public get ServerVersion(): string { return this.getProperty('ServerVersion'); }
    public set ServerVersion(value: string) { this.setProperty('ServerVersion', value); }

    @Expose()
    public get ServerBuildDate(): string { return this.getProperty('ServerBuildDate'); }
    public set ServerBuildDate(value: string) { this.setProperty('ServerBuildDate', value); }

    @Expose()
    public get ServerBuildTime(): string { return this.getProperty('ServerBuildTime'); }
    public set ServerBuildTime(value: string) { this.setProperty('ServerBuildTime', value); }

    @Expose()
    public get ServerDotNetVersion(): string { return this.getProperty('ServerDotNetVersion'); }
    public set ServerDotNetVersion(value: string) { this.setProperty('ServerDotNetVersion', value); }

    @Expose()
    public get ServerOSVersion(): string { return this.getProperty('ServerOSVersion'); }
    public set ServerOSVersion(value: string) { this.setProperty('ServerOSVersion', value); }

    @Expose()
    public get DBServer(): string { return this.getProperty('DBServer'); }
    public set DBServer(value: string) { this.setProperty('DBServer', value); }

    @Expose()
    public get DBName(): string { return this.getProperty('DBName'); }
    public set DBName(value: string) { this.setProperty('DBName', value); }

    @Expose()
    public get DBTimeout(): number { return this.getProperty('DBTimeout'); }
    public set DBTimeout(value: number) { this.setProperty('DBTimeout', value); }
}
