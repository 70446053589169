<avi-core-page title="Aktivität" icon="work" [contents-padding]="false" [navigate-back]="false">

    <avi-crm-activity-view *ngIf="SessionData.ContextId" [activity-id]="SessionData.ContextId"></avi-crm-activity-view>

    <!-- <avi-tabView #tabView *ngIf="SessionData.ContextId" width="10rem" [tabview-class]="'tabstyle-session'" orientation="vertical" [session-modul]="true">
        <avi-tabPanel title="Übersicht" id="activity-summary">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-crm-activity-view [activity-id]="SessionData.ContextId"></avi-crm-activity-view>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
    </avi-tabView> -->
</avi-core-page>
