import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import TransformDate from '../shared/transformdate';

export class StatusWorkflowDef extends BaseModel {  
    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }
    
    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }
    
    @Expose()
    public get System(): number { return this.getProperty('System'); }
    public set System(value: number) { this.setProperty('System', value); }
    
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get StartBedingungOutput(): string { return this.getProperty('StartBedingungOutput'); }
    public set StartBedingungOutput(value: string) { this.setProperty('StartBedingungOutput', value); }
    
    @Expose()
    public get vonModel_ID(): string { return this.getModelId('vonModel_ID'); }
    public set vonModel_ID(value: string) { this.setModelId('vonModel_ID', value); }
 
    @Expose()
    public get StartBedingung_ID(): string { return this.getModelId('StartBedingung_ID'); }
    public set StartBedingung_ID(value: string) { this.setModelId('StartBedingung_ID', value); }
 
    @Expose()
    public get StartTask_ID(): string { return this.getModelId('StartTask_ID'); }
    public set StartTask_ID(value: string) { this.setModelId('StartTask_ID', value); }
 
    @Expose()
    public get EmailDef_ID(): string { return this.getModelId('EmailDef_ID'); }
    public set EmailDef_ID(value: string) { this.setModelId('EmailDef_ID', value); }
 
    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }
    
    @Expose()
    @Type(() => ListType)
    public get StartNachErfassen(): ListType { return this.getProperty('StartNachErfassen'); }
    public set StartNachErfassen(value: ListType) { this.setProperty('StartNachErfassen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get StartNachEditieren(): ListType { return this.getProperty('StartNachEditieren'); }
    public set StartNachEditieren(value: ListType) { this.setProperty('StartNachEditieren', value); }
    
}
