declare var PDFObject: any;
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
// import { Http, Headers, Request, RequestOptions, RequestMethod, ResponseContentType } from '@angular/http';

// import {PDFObject} from 'pdfobject/pdfobject.min';

@Component({
    selector: 'avi-core-pdf-viewer',
    template: `<div class="pdf-viewer-container" #pdfViewer></div>`,
    styleUrls: ['./pdf-viewer.component.scss']
})
export class AviPdfViewerComponent implements OnInit, OnChanges {

    @ViewChild('pdfViewer', { static: false }) pdfViewer: ElementRef;
    @Input('src') source: any = null;

    constructor(private http: HttpClient) { }

    ngOnInit() {
        this.init(this.source);
    }


    async downloadPdf(url: string, headers: any[] = null): Promise<any> {
        const reqHeaders = new HttpHeaders().set('X-avi-h1', 'h1h');
        if (headers != null) {
            headers.forEach((item) => {
                reqHeaders.set(item.key, item.value);
            });
        }
        // const requestOptions = new RequestOptions({
        //     method: RequestMethod.Get,
        //     headers: reqHeaders,
        //     url: url,
        //     responseType: ResponseContentType.Blob,
        // });
        // const request = new Request(requestOptions);
        // return await this.http.request(request).toPromise().then(response => { return new Blob([response.blob()], { type: 'application/pdf' }); });



        return this.http.get(url, { headers: reqHeaders, responseType: 'blob' as 'json' })
            .toPromise()
            .then(response => {
                return response;
            })
            ;

    }

    ngOnChanges(changes: SimpleChanges) {
        if ('source' in changes) {
            this.init(this.source);
        }
    }

    async init(src: any) {
        if (!src || !this.pdfViewer) return;

        const options = {
            pdfOpenParams: {
                navpanes: 0,
                toolbar: 0,
                statusbar: 0,
                view: 'FitH',
                pagemode: 'none',
                page: 1,
            },
            forcePDFJS: true,
            suppressConsole: true,
            PDFJS_URL: '/assets/lib/pdfjs/web/viewer.html'
        };

        let fileURL: string = null;
        if (typeof src === 'string') {
            fileURL = src;
        } else {
            if (src.data) {
                console.log('Loading src.data', src.data);
                const blob = this.__b64toBlob(src.data, 'application/pdf');
                fileURL = URL.createObjectURL(blob);
            } else if (src.url) {
                fileURL = URL.createObjectURL(await this.downloadPdf(src.url));
            }
        }

        PDFObject.embed(fileURL, this.pdfViewer.nativeElement, options/*, {pdfOpenParams: { page: 10 }}*/);
    }

    private __b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
