import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService } from '@avi-x/avi-core';
import { AviBusinesscaseIndexComponent } from './components/businesscase-index.component';

const routes: Routes = [
    {
        path: 'businesscase/index/:id',
        canActivate: [ AviCanActivateWhenLoggedInService ],
        component: AviBusinesscaseIndexComponent
    }
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviBusinesscaseRoutingModule {
}
