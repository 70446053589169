import { ChangeDetectorRef, Component, ContentChild, TemplateRef } from "@angular/core";
import { AviCommonService } from "../../services/common.service";
import { AviSessionControllerService, ISessionData } from "../../services/session-controller.service";

@Component({
    selector: "avi-core-session-controller",
    styles: [
        `::ng-deep avi-core-session-controller > .mat-tab-group > .mat-tab-header {
            position: sticky;
            background: white;
            z-index: 2;
            top: 0;
        }

        ::ng-deep .session-tooltip {
            max-width: fit-content;
        }
        `
    ],
    templateUrl: "./session-controller.component.html"
})
export class AviSessionControllerComponent {
    constructor(public sessionController: AviSessionControllerService, private commonService: AviCommonService, public cdr: ChangeDetectorRef) { }

    @ContentChild('session')
    public sessionTemplate: TemplateRef<any>;

    closeSession(idx: number) {
        if (this.sessionController.activeSessionIndex === idx) {
            if (idx < this.sessionController.getNumSessions() - 1)
                this.sessionController.activeSessionIndex = idx + 1;
            else
                this.sessionController.activeSessionIndex = idx - 1;

            this.cdr.detectChanges();    // smoother transition
        }

        this.sessionController.closeSession(idx);
    }

    getCaption(session: ISessionData) {
        return this.commonService.truncateString(session.Caption ?? 'Untitled', 30);
    }

    onAuxClick(event: MouseEvent, idx:number) {
        if (event.button === 1)
            this.closeSession(idx)
    }
}
