import { Expose, Type } from 'class-transformer';

import { ModelStateGroup } from '../system/modelstategroup.model';
import { ModelState } from '../system/modelstate.model';
import { CustomPropCollectionDef } from '../system/custompropcollectiondef.model';
import { Collection } from '../system/collection.model';
import { PartnerRolleDef } from '../partner/partnerrolledef.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class Rechtsform extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get StateGroup_ID(): string { return this.getModelId('StateGroup_ID'); }
    public set StateGroup_ID(value: string) { this.setModelId('StateGroup_ID', value); }
 
    @Expose()
    @Type(() => ModelStateGroup)
    public get StateGroup(): ModelStateGroup { return this.getModel('StateGroup'); }
    public set StateGroup(value: ModelStateGroup) { this.setModel('StateGroup', value); }
    
    @Expose()
    public get DefaultStatus_ID(): string { return this.getModelId('DefaultStatus_ID'); }
    public set DefaultStatus_ID(value: string) { this.setModelId('DefaultStatus_ID', value); }
 
    @Expose()
    @Type(() => ModelState)
    public get DefaultStatus(): ModelState { return this.getModel('DefaultStatus'); }
    public set DefaultStatus(value: ModelState) { this.setModel('DefaultStatus', value); }
    
    @Expose()
    public get RechtsformChangeDef_ID(): string { return this.getModelId('RechtsformChangeDef_ID'); }
    public set RechtsformChangeDef_ID(value: string) { this.setModelId('RechtsformChangeDef_ID', value); }
 
    @Expose()
    public get CustomProperties_ID(): string { return this.getModelId('CustomProperties_ID'); }
    public set CustomProperties_ID(value: string) { this.setModelId('CustomProperties_ID', value); }
 
    @Expose()
    @Type(() => CustomPropCollectionDef)
    public get CustomProperties(): CustomPropCollectionDef { return this.getModel('CustomProperties'); }
    public set CustomProperties(value: CustomPropCollectionDef) { this.setModel('CustomProperties', value); }
    
    @Expose()
    public get KlassifizierungCollection_ID(): string { return this.getModelId('KlassifizierungCollection_ID'); }
    public set KlassifizierungCollection_ID(value: string) { this.setModelId('KlassifizierungCollection_ID', value); }
 
    @Expose()
    @Type(() => Collection)
    public get KlassifizierungCollection(): Collection { return this.getModel('KlassifizierungCollection'); }
    public set KlassifizierungCollection(value: Collection) { this.setModel('KlassifizierungCollection', value); }
    
    @Expose()
    public get AktionaerPartnerRolleDef_ID(): string { return this.getModelId('AktionaerPartnerRolleDef_ID'); }
    public set AktionaerPartnerRolleDef_ID(value: string) { this.setModelId('AktionaerPartnerRolleDef_ID', value); }
 
    @Expose()
    @Type(() => PartnerRolleDef)
    public get AktionaerPartnerRolleDef(): PartnerRolleDef { return this.getModel('AktionaerPartnerRolleDef'); }
    public set AktionaerPartnerRolleDef(value: PartnerRolleDef) { this.setModel('AktionaerPartnerRolleDef', value); }
    
    @Expose()
    @Type(() => ListType)
    public get CustomPropertiesLocation(): ListType { return this.getProperty('CustomPropertiesLocation'); }
    public set CustomPropertiesLocation(value: ListType) { this.setProperty('CustomPropertiesLocation', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DefaultAusgestaltung(): ListType { return this.getProperty('DefaultAusgestaltung'); }
    public set DefaultAusgestaltung(value: ListType) { this.setProperty('DefaultAusgestaltung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AusgUnbekanntErlaubt(): ListType { return this.getProperty('AusgUnbekanntErlaubt'); }
    public set AusgUnbekanntErlaubt(value: ListType) { this.setProperty('AusgUnbekanntErlaubt', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DefaultPflichtigkeit(): ListType { return this.getProperty('DefaultPflichtigkeit'); }
    public set DefaultPflichtigkeit(value: ListType) { this.setProperty('DefaultPflichtigkeit', value); }
    
    @Expose()
    @Type(() => ListType)
    public get KannAktienRegisterHaben(): ListType { return this.getProperty('KannAktienRegisterHaben'); }
    public set KannAktienRegisterHaben(value: ListType) { this.setProperty('KannAktienRegisterHaben', value); }
    
}
