import { Expose, Type } from 'class-transformer';

import { TechRole } from '../system/techrole.model';
import { Rechtsform } from '../partner/rechtsform.model';
import { CustomPropCollectionDef } from '../system/custompropcollectiondef.model';
import { ActivityTemplate } from '../activity/activitytemplate.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class RahmenPartner extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    @Type(() => Date)
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }
    
    @Expose()
    @Type(() => Date)
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }
    
    @Expose()
    public get CreateRole_ID(): string { return this.getModelId('CreateRole_ID'); }
    public set CreateRole_ID(value: string) { this.setModelId('CreateRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get CreateRole(): TechRole { return this.getModel('CreateRole'); }
    public set CreateRole(value: TechRole) { this.setModel('CreateRole', value); }
    
    @Expose()
    public get UpdateRole_ID(): string { return this.getModelId('UpdateRole_ID'); }
    public set UpdateRole_ID(value: string) { this.setModelId('UpdateRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get UpdateRole(): TechRole { return this.getModel('UpdateRole'); }
    public set UpdateRole(value: TechRole) { this.setModel('UpdateRole', value); }
    
    @Expose()
    public get DeleteRole_ID(): string { return this.getModelId('DeleteRole_ID'); }
    public set DeleteRole_ID(value: string) { this.setModelId('DeleteRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get DeleteRole(): TechRole { return this.getModel('DeleteRole'); }
    public set DeleteRole(value: TechRole) { this.setModel('DeleteRole', value); }
    
    @Expose()
    public get Rechtsform_ID(): string { return this.getModelId('Rechtsform_ID'); }
    public set Rechtsform_ID(value: string) { this.setModelId('Rechtsform_ID', value); }
 
    @Expose()
    @Type(() => Rechtsform)
    public get Rechtsform(): Rechtsform { return this.getModel('Rechtsform'); }
    public set Rechtsform(value: Rechtsform) { this.setModel('Rechtsform', value); }
    
    @Expose()
    public get ChangeDef_ID(): string { return this.getModelId('ChangeDef_ID'); }
    public set ChangeDef_ID(value: string) { this.setModelId('ChangeDef_ID', value); }
 
    @Expose()
    public get CustomProperties_ID(): string { return this.getModelId('CustomProperties_ID'); }
    public set CustomProperties_ID(value: string) { this.setModelId('CustomProperties_ID', value); }
 
    @Expose()
    @Type(() => CustomPropCollectionDef)
    public get CustomProperties(): CustomPropCollectionDef { return this.getModel('CustomProperties'); }
    public set CustomProperties(value: CustomPropCollectionDef) { this.setModel('CustomProperties', value); }
    
    @Expose()
    public get vonActivityTemplate_ID(): string { return this.getModelId('vonActivityTemplate_ID'); }
    public set vonActivityTemplate_ID(value: string) { this.setModelId('vonActivityTemplate_ID', value); }
 
    @Expose()
    @Type(() => ActivityTemplate)
    public get vonActivityTemplate(): ActivityTemplate { return this.getModel('vonActivityTemplate'); }
    public set vonActivityTemplate(value: ActivityTemplate) { this.setModel('vonActivityTemplate', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Verwendung(): ListType { return this.getProperty('Verwendung'); }
    public set Verwendung(value: ListType) { this.setProperty('Verwendung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DublettePruefung(): ListType { return this.getProperty('DublettePruefung'); }
    public set DublettePruefung(value: ListType) { this.setProperty('DublettePruefung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Encrypt(): ListType { return this.getProperty('Encrypt'); }
    public set Encrypt(value: ListType) { this.setProperty('Encrypt', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerantwortlichkeitenManuell(): ListType { return this.getProperty('VerantwortlichkeitenManuell'); }
    public set VerantwortlichkeitenManuell(value: ListType) { this.setProperty('VerantwortlichkeitenManuell', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AutoWorldcheckPruefung(): ListType { return this.getProperty('AutoWorldcheckPruefung'); }
    public set AutoWorldcheckPruefung(value: ListType) { this.setProperty('AutoWorldcheckPruefung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerwendungChangeDef(): ListType { return this.getProperty('VerwendungChangeDef'); }
    public set VerwendungChangeDef(value: ListType) { this.setProperty('VerwendungChangeDef', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerwendungCustomProperties(): ListType { return this.getProperty('VerwendungCustomProperties'); }
    public set VerwendungCustomProperties(value: ListType) { this.setProperty('VerwendungCustomProperties', value); }
    
}
