

<div>

    <div *ngIf="!docService.IsOnline()">

        <p-message severity="warn" text="Service nicht online"></p-message>
    </div>

    <div *ngIf="docService.IsOnline() && docService.CheckedOutDocuments.length === 0 ; else docList">
        <p-message severity="info" text="Keine Dokumente in Bearbeitung"></p-message>
    </div>

    <ng-template #docList>



        <mat-list *ngIf="docService.IsOnline()">
            <mat-list-item *ngFor="let d of docService.CheckedOutDocuments; last as last">
                <mat-icon mat-list-icon>attachment</mat-icon>
                <div class="ui-g ui-fluid ui-g-nopad"
                    style="width:100%">
                    <div class="ui-g-8">
                        <h4 class="m-0 pt-2 mb-2"
                            mat-line>{{d.title}}</h4>
                        <div class="small-light"
                            mat-line>CheckOut: {{d.checkedOutAt | date:'medium'}}
                            | Datei: {{d.fileName}}{{d.fileExtension}}
                            <!--<i class="ml-16 small-light">{{d.path}}</i>-->
                        </div>
                    </div>
                    <div class="ui-g-4"
                        style="text-align: end;">

                        <button pButton
                            class="mt-2 mr-2"
                            icon="pi pi-upload"
                            pTooltip="Check In"
                            title="Check In"
                            (click)="CheckInDocument(d)"></button>

                        <button pButton
                            class="mt-2 mr-2"
                            pTooltip="Lokales Dokument öffnen"
                            title="Lokales Dokument öffnen"
                            icon="pi pi-pencil"
                            (click)="OpenDocumentAsProcess(d)"></button>

                        <button pButton
                            class="mt-2 mr-2"
                            icon="pi pi-undo"
                            pTooltip="Änderungen verwerfen"
                            title="Änderungen verwerfen"
                            (click)="DiscardCheckoutDocument(d)"></button>
                    </div>

                </div>
                <!-- <p mat-line class="demo-2"> {{d.id}} </p> -->
                <mat-divider [inset]="true"
                    *ngIf="!last"></mat-divider>
            </mat-list-item>
            <!-- <mat-divider></mat-divider>
    <h3 mat-subheader>Notes</h3>
    <mat-list-item *ngFor="let note of notes">
       <mat-icon mat-list-icon>note</mat-icon>
       <h4 mat-line>{{note.name}}</h4>
       <p mat-line class="demo-2"> {{note.updated}} </p>
    </mat-list-item> -->
        </mat-list>

    </ng-template>
</div>


<avi-core-dbg [m]="docService.CheckedOutDocuments"></avi-core-dbg>
