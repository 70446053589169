import { BaseModelState } from '@avi-x/avi-dto/shared/basemodel.model';
import { Expose, Type } from 'class-transformer';

import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared/genericref.model';

export class SysParameterDto extends BaseModelState {  
    @Expose()
    public get KurzBez(): string { return this.getProperty('KurzBez'); }
    public set KurzBez(value: string) { this.setProperty('KurzBez', value); }
    
    @Expose()
    public get Bezeichnung(): string { return this.getProperty('Bezeichnung'); }
    public set Bezeichnung(value: string) { this.setProperty('Bezeichnung', value); }
        
    @Expose()
    public get Beschreibung(): string { return this.getProperty('Beschreibung'); }
    public set Beschreibung(value: string) { this.setProperty('Beschreibung', value); }
    
    @Expose()
    public get ParamName(): string { return this.getProperty('ParamName'); }
    public set ParamName(value: string) { this.setProperty('ParamName', value); }
    
    @Expose()
    public get ParamLength(): number { return this.getProperty('ParamLength'); }
    public set ParamLength(value: number) { this.setProperty('ParamLength', value); }
    
    @Expose()
    public get ListTypeName(): string { return this.getProperty('ListTypeName'); }
    public set ListTypeName(value: string) { this.setProperty('ListTypeName', value); }
    
    @Expose()
    public get Mask(): string { return this.getProperty('Mask'); }
    public set Mask(value: string) { this.setProperty('Mask', value); }
    
    @Expose()
    public get RegEx(): string { return this.getProperty('RegEx'); }
    public set RegEx(value: string) { this.setProperty('RegEx', value); }
    
    @Expose()
    public get IsPassword(): boolean { return this.getProperty('IsPassword'); }
    public set IsPassword(value: boolean) { this.setProperty('IsPassword', value); }
    
    @Expose()
    public get IsCipherPassword(): boolean { return this.getProperty('IsCipherPassword'); }
    public set IsCipherPassword(value: boolean) { this.setProperty('IsCipherPassword', value); }
    
    @Expose()
    public get minValue(): number { return this.getProperty('minValue'); }
    public set minValue(value: number) { this.setProperty('minValue', value); }
    
    @Expose()
    public get maxValue(): number { return this.getProperty('maxValue'); }
    public set maxValue(value: number) { this.setProperty('maxValue', value); }
    
    @Expose()
    public get ValidateService(): string { return this.getProperty('ValidateService'); }
    public set ValidateService(value: string) { this.setProperty('ValidateService', value); }
    
    @Expose()
    public get DefaultIntParamValue(): number { return this.getProperty('DefaultIntParamValue'); }
    public set DefaultIntParamValue(value: number) { this.setProperty('DefaultIntParamValue', value); }
    
    @Expose()
    public get DefaultDecParamValue(): number { return this.getProperty('DefaultDecParamValue'); }
    public set DefaultDecParamValue(value: number) { this.setProperty('DefaultDecParamValue', value); }
    
    @Expose()
    public get DefaultTextParamValue(): string { return this.getProperty('DefaultTextParamValue'); }
    public set DefaultTextParamValue(value: string) { this.setProperty('DefaultTextParamValue', value); }
    
    @Expose()
    @Type(() => Date)
    public get DefaultDateParamValue(): Date { return this.getProperty('DefaultDateParamValue'); }
    public set DefaultDateParamValue(value: Date) { this.setProperty('DefaultDateParamValue', value); }
    
    @Expose()
    public get DefaultListDetailParamValue_ID(): string { return this.getModelId('DefaultListDetailParamValue_ID'); }
    public set DefaultListDetailParamValue_ID(value: string) { this.setModelId('DefaultListDetailParamValue_ID', value); }
 
    @Expose()
    @Type(() => GenericRef)
    public get DefaultRefParamValue(): GenericRef { return this.getProperty('DefaultRefParamValue'); }
    public set DefaultRefParamValue(value: GenericRef) { this.setProperty('DefaultRefParamValue', value); }
    
    @Expose()
    public get RefType(): string { return this.getProperty('RefType'); }
    public set RefType(value: string) { this.setProperty('RefType', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ParamType(): ListType { return this.getProperty('ParamType'); }
    public set ParamType(value: ListType) { this.setProperty('ParamType', value); }
    
    // Value
    @Expose()
    public get IntValue(): number { return this.getProperty('IntValue'); }
    public set IntValue(value: number) { this.setProperty('IntValue', value); }
    
    @Expose()
    public get DecValue(): number { return this.getProperty('DecValue'); }
    public set DecValue(value: number) { this.setProperty('DecValue', value); }
    
    @Expose()
    public get TextValue(): string { return this.getProperty('TextValue'); }
    public set TextValue(value: string) { this.setProperty('TextValue', value); }
    
    @Expose()
    @Type(() => Date)
    public get DateValue(): Date { return this.getProperty('DateValue'); }
    public set DateValue(value: Date) { this.setProperty('DateValue', value); }
    
    @Expose()
    public get ListDetailValue_ID(): string { return this.getModelId('ListDetailValue_ID'); }
    public set ListDetailValue_ID(value: string) { this.setModelId('ListDetailValue_ID', value); }
     
    @Expose()
    @Type(() => GenericRef)
    public get RefValue(): GenericRef { return this.getProperty('RefValue'); }
    public set RefValue(value: GenericRef) { this.setProperty('RefValue', value); }
}
