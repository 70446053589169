import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AviApiService, AviCommunicationService, AviPartnerListDetailConst } from '@avi-x/avi-core';
import { AviPartnerService } from '../../services/partner.service';

import { AdresseDetail } from '@avi-x/avi-dto/partner/adressedetail.model';

@Component({
    selector: 'avi-crm-adresse-liste-detailview',
    templateUrl: './adresse-liste-detailview.component.html'
})
export class AviAdresseListeDetailviewComponent implements OnInit {
    public Adresse: AdresseDetail;
    public MapsUrl: SafeResourceUrl;
    public MapsVisible: boolean = false;

    @Input('adresseId')
    public AdresseId: string;

    constructor(public partnerService: AviPartnerService,
                public apiService: AviApiService,
                private communicationService: AviCommunicationService) {
    }

    ngOnInit() {
        this.loadDetailView();
    }

    public async loadDetailView() {
        this.MapsVisible = false;
        this.Adresse = null;

        if (this.AdresseId != null)
            this.Adresse = (await this.apiService.getModelList(AdresseDetail,
                `adressedetail?init=vonAdresse,Land,land&filter=(zustandBeginn le today) and (zustandEnde ge today) and (vonAdresse.id eq ${this.AdresseId})`
            ))[0];

        this.MapsUrl = this.communicationService.GetGoogleMapsUrl({
            LandISO: this.Adresse.Land ? this.Adresse.Land.ISOCode : null,
            Strasse: this.Adresse.Strasse,
            Ort: this.Adresse.Ort,
            PLZ: this.Adresse.PLZ
        });
    }
}
