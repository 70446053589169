import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { Directive, Input, ChangeDetectorRef, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { AviTypeUtil } from '../constants/constants';

import * as moment from 'moment-timezone';

@Directive({
    selector: '[noHighLowDate][formControlName],[noHighLowDate][formControl],[noHighLowDate][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AviNoHighLowDateValidator),
            multi: true
        }
    ],
    host: { '[attr.noHighLowDate]': 'noHighLowDate ? noHighLowDate : null' }
})
export class AviNoHighLowDateValidator implements Validator, OnChanges {
    private _validator: ValidatorFn = null;

    private _onChange?: () => void;

    @Input()
    noHighLowDate!: boolean;

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('noHighLowDate' in changes) {
            this._createValidator();
            if (this._onChange) this._onChange();
        }
    }

    registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn;
    }

    private _createValidator(): void {
        this._validator = this.phoneNumberValidator();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return !this.noHighLowDate || this._validator == null ? null : this._validator(control);
    }

    phoneNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value)
                return null;

            const dateValue = moment(control.value);

            if (dateValue.isSame(AviTypeUtil.HighDate) || dateValue.isSame(AviTypeUtil.SqlLowDate))
                return ({ validateDate: { valid: false } });

            return null;
        };
    }

}
