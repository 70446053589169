import { Component, ChangeDetectorRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { AviCommonService, AviApiService, AviBaseSearcherComponent } from '@avi-x/avi-core';
import { VwbPCommonService } from '@avi-x/vwbp-public';

export enum RTVerMode {
    // eslint-disable-next-line no-unused-vars
    RECHTSTRAEGER = 0, VERSION = 1, ANTRAG = 2, BEHOERDEN_VERSION_IN = 3, BEHOERDEN_VERSION_AUS = 4, BEHOERDEN_VERSION_IN_AUS = 5
};

@Component({
    selector: 'vwbp-intern-rechtstraeger-adresse-searcher',
    templateUrl: './rechtstraeger-adresse-searcher.component.html',
    styleUrls: ['./rechtstraeger-adresse-searcher.component.scss']
})
export class VwbPInternRechtstraegerAdresseSearcherComponent {
    RTVerMode = RTVerMode;
    VisibleColumns: string[] = ['Name', 'Rechtsform', 'RegisterNummer', 'RegisterOrt', 'Sitz', 'ZustellAdresseCO', 'ZustellAdresseStrasse', 'ZustellAdressePostfach', 'ZustellAdressePLZ', 'ZustellAdresseOrt'];

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @Input('ModelIds')
    public ModelIds: string[] = [];

    _Mode: RTVerMode = RTVerMode.RECHTSTRAEGER;
    @Input('Mode')
    public set Mode(value: RTVerMode) {
        this._Mode = value;
        if (this._Mode === RTVerMode.ANTRAG)
            this.VisibleColumns = ['Name', 'Rechtsform', 'RegisterNummer', 'RegisterOrt', 'Sitz', 'ZustellAdresseCO', 'ZustellAdresseStrasse', 'ZustellAdressePostfach', 'ZustellAdressePLZ', 'ZustellAdresseOrt', 'AntragGueltigAb', 'AntragGueltigBis', 'AntragBegruendung'];
        else
            this.VisibleColumns = ['Name', 'Rechtsform', 'RegisterNummer', 'RegisterOrt', 'Sitz', 'ZustellAdresseCO', 'ZustellAdresseStrasse', 'ZustellAdressePostfach', 'ZustellAdressePLZ', 'ZustellAdresseOrt'];
    }

    public get Mode(): RTVerMode {
        return this._Mode;
    }


    @Output()
    public onSelect: EventEmitter<any[]> = new EventEmitter();

    constructor(
        public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        public cdr: ChangeDetectorRef,
        private vwbpCommonService: VwbPCommonService
    ) {
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        searchConfig.Mode = this.Mode;
        searchConfig.ModelIds = this.ModelIds;

        const res = await this.apiService.post(`v1/intern/vwbp/rechtstraeger/adressen`, searchConfig);

        return res;
    }

    public SelectAllDelegate: any = async (searchValue: string, searchConfig: any = null) => {
        searchConfig.Mode = this.Mode;
        searchConfig.ModelIds = this.ModelIds;

        return await this.apiService.post(`v1/intern/vwbp/rechtstraeger/adressen/ids`, searchConfig);
    }

    public async onSearcherInit(data) {
        this.searcher.beginInit();

        this.searcher.addTextColumn('Name', 'VWBP.COMMON.RT', true).setWidth('25%');
        this.searcher.addTextColumn('Rechtsform', 'VWBP.COMMON.RECHTSFORM', true);
        this.searcher.addTextColumn('RegisterNummer', 'VWBP.COMMON.REGISTERNR', true);
        this.searcher.addTextColumn('RegisterOrt', 'VWBP.COMMON.REGISTERORT', true);
        this.searcher.addTextColumn('Sitz', 'VWBP.COMMON.SITZ', true);

        this.searcher.addDateColumn('AntragGueltigAb', 'VWBP.INTERN.SCHWAERZEN.GUELTIGAB', null, true);
        this.searcher.addDateColumn('AntragGueltigBis', 'VWBP.INTERN.SCHWAERZEN.GUELTIGBIS', null, true);
        this.searcher.addListTypeColumn('AntragEntscheidung', 'Entscheidung', 'vwbp_schwaerzen_antrag_entscheidung', true, false, null, 'v1/vwbp', null).setSortable(false);
        this.searcher.addListTypeColumn('AntragBegruendung', 'Begründung', 'vwbp_schwaerzen_antrag_begruendung', true, false, null, 'v1/vwbp', null).setSortable(false);

        this.searcher.addTextColumn('ZustellAdresseCO', 'VWBP.COMMON.CO', false).setSortable(false);
        this.searcher.addTextColumn('ZustellAdresseStrasse', 'VWBP.COMMON.STRASSE_NUMMER', false).setSortable(false);
        this.searcher.addTextColumn('ZustellAdressePostfach', 'VWBP.COMMON.POSTFACH', false).setSortable(false);
        this.searcher.addTextColumn('ZustellAdressePLZ', 'VWBP.COMMON.PLZ', false).setSortable(false);
        this.searcher.addTextColumn('ZustellAdresseOrt', 'VWBP.COMMON.ORT', false).setSortable(false);

        this.searcher.KeepSelectedRows = this.ModelIds.map(w => { return { Id: w }; });
        this.searcher.endInit();

        this.onSelectionChanged(this.searcher.KeepSelectedRows);
    }

    onSelectionChanged(evt) {
        this.onSelect.emit(evt);
    }
}
