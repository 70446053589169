import { Expose, Type } from "class-transformer";
import { IDTO } from "../shared";

export class BusinesscaseDataDetailFormularDto extends IDTO {  
    @Expose()
    public vonBusinesscaseData_ID: string;

    @Expose()
    public FormData: string;

    @Expose()
    public Draft: boolean;

    @Expose()
    public Bemerkung: string;

    @Expose()
    public Titel: string;

    @Expose()
    public Internal: boolean;

    @Expose()
    public AlwaysInternal: boolean;

    @Expose()
    public Versioning: boolean;

    @Expose()
    public CreatedByName: string;

    @Expose()
    @Type(() => Date)
    public CreatedDate: Date;
}
