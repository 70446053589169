import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AviFormField, AviBaseFormComponent, AviBaseSearcherComponent, AviDialogComponent, AviCommonService, AviFormFieldService, AviApiService, typed_nameof } from "@avi-x/avi-core";
import { VwbPCommonService, VwbPVersion, VwbPRechtstraeger } from "@avi-x/vwbp-public";
import { VwbPBehoerdensucheWbJpAuslandRequestDto } from "../../../dto/vwbpbehoerdensuche.models";
import { VwbPInternRechtstraegerViewComponent } from "../../../rechtstraeger/components/rechtstraeger-view/rechtstraeger-view.component";
import { VwbPInternBehoerdenService } from "../../services/behoerden.service";
import { VwbPInternRechtstraegerService } from "../../../rechtstraeger/services/rechtstraeger.service";
import { RTVerMode } from "../../../rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component";

const nameof = (nameFunction: ((obj: VwbPBehoerdensucheWbJpAuslandRequestDto) => any)) => typed_nameof<VwbPBehoerdensucheWbJpAuslandRequestDto>(nameFunction);

@Component({
    selector: 'vwbp-intern-behoerden-suche-wb-jp-ausland',
    templateUrl: './behoerden-suche-wb-jp-ausland.component.html',
    styleUrls: ['./behoerden-suche-wb-jp-ausland.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternBehoerdenSucheWbJpAuslandComponent implements OnInit, OnDestroy {
    
    toolbarButtons : any[] = [
        { title: 'Excel Export', class: 'p-button-secondary', icon: 'pi pi-download', action: () => this.OnExportClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0, visible: () => this.CheckboxSelection },
    ];
    
    public Loading: boolean = false;
    public Model: VwbPBehoerdensucheWbJpAuslandRequestDto = null;
    public CheckboxSelection: boolean = false;

    public Fields: AviFormField[] = [];

    public IsAJUUser : boolean;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @ViewChild('rtDialog', { static: false })
    rtDialog: AviDialogComponent;

    @ViewChild('rtView', { static: false })
    rtView: VwbPInternRechtstraegerViewComponent;

    constructor(
        private commonService: AviCommonService,
        private behoerdenService: VwbPInternBehoerdenService,
        private vwbpCommonService: VwbPCommonService,
        private formFieldService: AviFormFieldService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private apiService: AviApiService,
        private rechtstraegerService: VwbPInternRechtstraegerService
    ) {
    }

    ngOnInit() {
        this.IsAJUUser = this.vwbpCommonService.currentUserIsAjuUser();
        this.CheckboxSelection = this.IsAJUUser;

        this.initFields();
        this.Model = {
            Name: '',
            Rechtsform: '',
            Sitz: '',
            RegisterOrt: '',
            RegisterNummer: '',
            CbAbgegebeneRechtstraeger: true,
            CbGeloeschteRechtstraeger: true,
            CbNichtAbgeschlosseneRechtstraeger: this.IsAJUUser,
            CbNurAktuelleVersion: false
        };
    }

    initFields() {
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'VWBP.INTERN.BEHOERDENABFRAGE.WB_NAME_FIRMA', true).setMDSizeHalf().setHinweis('VWBP.INTERN.BEHOERDENABFRAGE.INFO_BOX_PFLICHT'));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Rechtsform), 'VWBP.COMMON.RECHTSFORM', false).setMDSizeHalf().setHinweis('VWBP.INTERN.BEHOERDENABFRAGE.INFO_BOX_PFLICHT'));
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Sitz), 'VWBP.COMMON.SITZ_ERRICHTUNGSORT', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GruendungsDatum), 'VWBP.COMMON.GRUENDUNGSDATUM_AUSLAND', false).setMinMaxDate(new Date(1800, 1, 1), new Date()).setMDSizeHalf().setHinweis('VWBP.INTERN.BEHOERDENABFRAGE.INFO_BOX_PFLICHT'));

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.RegisterLand_ID), 'VWBP.COMMON.REGISTERLAND', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RegisterOrt), 'VWBP.COMMON.REGISTERORT', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RegisterNummer), 'VWBP.COMMON.REGISTERNR', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.RegisterDatum), 'VWBP.COMMON.REGISTERDATUM', false).setMinMaxDate(new Date(1800, 1, 1), new Date()).setMDSizeHalf());

        if (!this.IsAJUUser)
            this.Fields.push(this.formFieldService.CreateLabel('lblHinweis', 'VWBP.INTERN.BEHOERDENABFRAGE.HINWEIS_SUCHKRITERIEN', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbAbgegebeneRechtstraeger), null, 'VWBP.INTERN.BEHOERDENABFRAGE.AUCH_ABGEGEBENE_RTS_SUCHEN', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbGeloeschteRechtstraeger), null, 'VWBP.INTERN.BEHOERDENABFRAGE.AUCH_GELOESCHTE_RTS_SUCHEN', false).setMDSizeFull());

        if (this.IsAJUUser)
            this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbNichtAbgeschlosseneRechtstraeger), null, 'VWBP.INTERN.BEHOERDENABFRAGE.AUCH_NICHT_ABGESCHLOSSENE_RTS_SUCHEN', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.CbNurAktuelleVersion), null, 'VWBP.INTERN.BEHOERDENABFRAGE.NUR_AKTUELLE_VERSION_SUCHEN', false).setMDSizeFull());

        if (!this.IsAJUUser)
            this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Begruendung), 'VWBP.INTERN.BEHOERDENABFRAGE.BEGRUENDUNG_DER_SUCHE', true).setMDSizeFull());
    }

    startSearch(model: VwbPBehoerdensucheWbJpAuslandRequestDto) {
        return this.searcher.doSearch();
    }

    onSearcherInit(data) {
        this.searcher.beginInit();

        if (this.IsAJUUser)
            this.searcher.addListTypeColumn('Erfassungsstatus', 'VWBP.COMMON.ERFASSUNGSSTATUS', 'vwbp_erfassungsstatus', null, null, null, 'v1/vwbp').setFilterable(false).setSortable(false).setFormatDelegate((record) => this.commonService.escapeHtml(record.Erfassungsstatus.Bezeichnung))
                .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_ERFASSUNGSSTATUS_TOOLTIP')
                .setWidth('200px');

        this.searcher.addDateColumn('Erfassungsdatum', 'VWBP.COMMON.ERFASSUNGSDATUM').setFilterable(false).setSortable(false)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_ERFASSUNGSDATUM_TOOLTIP')
            .setWidth('150px');

        this.searcher.addDateColumn('HrLoeschungsdatum', 'VWBP.COMMON.LOESCHUNGSDATUM').setFilterable(false).setSortable(false)
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_LOESCHUNGSDATUM_TOOLTIP')
            .setWidth('150px');

        this.searcher.addListTypeColumn('Uebergabestatus', 'VWBP.COMMON.UEBERGABESTATUS', 'vwbp_rt_uebergabestatus', null, null, null, 'v1/vwbp').setFilterable(false).setSortable(false).setFormatDelegate((record) => this.commonService.escapeHtml(record.Uebergabestatus.Bezeichnung))
            .setDescription('VWBP.INTERN.BEHOERDENABFRAGE.COLUMN_UEBERGABESTATUS_TOOLTIP')
            .setWidth('150px');

        this.searcher.addTextColumn('FormDataRechtstraegerName', 'VWBP.PUBLIC.FORM.WB_NAME_FIRMA').setFilterable(false).setSortable(false).setFormatDelegate((record) => this.commonService.escapeHtml(record.FormDataRechtstraegerName));
        this.searcher.addTextColumn('Rechtsform', 'VWBP.COMMON.RECHTSFORM').setFilterable(false).setSortable(false);
        this.searcher.addTextColumn('RechtstraegerSitz', 'VWBP.COMMON.SITZ_ERRICHTUNGSORT').setFilterable(false).setSortable(false);
        this.searcher.addTextColumn('RegisterNummer', 'VWBP.COMMON.REGISTERNR').setFilterable(false).setSortable(false);

        this.searcher.addTextColumn('GruendungsDatum', 'VWBP.COMMON.GRUENDUNGSDATUM_AUSLAND').setFilterable(false).setSortable(false)
            .setFormatDelegate((record) => record.GruendungsDatum ? this.commonService.formatDate(record.GruendungsDatum, 'l') : null)
            .setWidth('150px');
        
        this.searcher.addTextColumn('RegisterLand', 'VWBP.COMMON.REGISTERLAND').setFilterable(false).setSortable(false);
        this.searcher.addTextColumn('RegisterOrt', 'VWBP.COMMON.REGISTERORT').setFilterable(false).setSortable(false);
        
        this.searcher.addTextColumn('RegisterDatum', 'VWBP.COMMON.REGISTERDATUM').setFilterable(false).setSortable(false)
            .setFormatDelegate((record) => record.RegisterDatum ? this.commonService.formatDate(record.RegisterDatum, 'l') : null)
            .setWidth('150px');

        this.searcher.endInit();
    }

    public GetRowClass = (row: any): string => {
        let ret = 'no-border-left no-border-right';
        if (row) {
            if (row['HrLoeschungsdatum'])
                ret += ' rt-geloescht';
        }
        return ret;
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        return new Promise<any>(async (resolveFn, rejectFn) => {
            try {
                this.commonService.showGlobalLoader();
                const results = await this.behoerdenService.StartBehoerdensucheWbJpAusland({ ...this.Model, ...searchConfig });
                console.log('results', results);
                resolveFn(results);
                this.commonService.hideGlobalLoader();
            } catch (err) {
                this.commonService.hideGlobalLoader();
                rejectFn(new Error(this.commonService.getErrorText(err, true)));
            }
        });
    }

    public SelectAllDelegate: any = async (searchValue: string, searchConfig: any = null) => {
        return await this.behoerdenService.StartBehoerdensucheWbJpAuslandIds({ ...this.Model, ...searchConfig });
    }

    public getRowGroupHeader(row: any, searcher: any): string {
        //return `<strong>${this.commonService.escapeHtml(row.RechtstraegerName)}, ${this.commonService.escapeHtml(row.RechtstraegerSitz)} (${this.commonService.escapeHtml(row.RechtstraegerRegisternummer)}) - ${this.commonService.escapeHtml(row.RechtstraegerRechtsform)} - Version ${this.commonService.escapeHtml(row.VersionLaufnummer)}</strong>`;
        
        let result = `<strong>${this.commonService.escapeHtml(row.RechtstraegerName)}`;

        if (row.RechtstraegerSitz)
            result += `, ${this.commonService.escapeHtml(row.RechtstraegerSitz)}`;

        if (row.RechtstraegerRegisternummer)
            result += ` (${this.commonService.escapeHtml(row.RechtstraegerRegisternummer)})`;

        if (row.RechtstraegerRechtsform)
            result += ` - ${this.commonService.escapeHtml(row.RechtstraegerRechtsform)}`;

        if (row.VersionLaufnummer)
            result += ` - Version ${this.commonService.escapeHtml(row.VersionLaufnummer)}`;

        result += '</strong>';
        return result;
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    ngOnDestroy() {
        this.commonService.hideGlobalLoader();
    }

    async onDoubleClickSearcher(data) {
        try {
            this.commonService.showGlobalLoader();
            const version = await this.apiService.getModel(VwbPVersion, `v1/intern/vwbp/rechtstraeger/version/${data.Id}`);
            const rechtstraeger = await this.apiService.FindModel(VwbPRechtstraeger, `v1/intern/vwbp/rechtstraeger/${version.Rechtstraeger_ID}`, null, null, null);
            rechtstraeger.SelectedVersion = version;
            await this.rtView.initView(rechtstraeger, true, true);
            this.rtView.refresh();
        } finally {
            this.commonService.hideGlobalLoader();
        }

        this.rtDialog.open();
    }

    CloseRTView() {
        this.rtDialog.close();
        this.rtView.initView(null);
        this.cdr.detectChanges();
    }

    async OnExportClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.Id).filter((value, index, self) => self.indexOf(value) === index);
        this.rechtstraegerService.OpenBehoerdenExcelExportJobDialog(rechtstraegerIds, RTVerMode.BEHOERDEN_VERSION_AUS);
    }

    public GetSelectedRechtstraeger(): any[] {
        return this.searcher.SelectedRows;
    }
}