import { InfoDeskDefDetail } from './infodeskdefdetail.model';
import { ListType } from '../shared/listtype.model';
import { Expose, Type } from 'class-transformer';

export class InfoDeskDefDetailVM extends InfoDeskDefDetail {
    @Expose()
    @Type(() => ListType)
    public get querydefDarstellung(): ListType { return this.getProperty('querydefDarstellung'); }
    public set querydefDarstellung(value: ListType) { this.setProperty('querydefDarstellung', value); }
}
