import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { TechRole } from '@avi-x/avi-dto/system/techrole.model';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: TechRole) => any)) => typed_nameof<TechRole>(nameFunction);

@Component({
    selector: 'avi-techrole-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviTechRoleFormComponent extends AviAbstractModelFormComponent<TechRole> {  
    ClassName = 'CORE.COMMON.MODEL.TECHROLE';
    BaseUrl = 'techrole';
    ClassType = TechRole;
	FormType = AviTechRoleFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        private translateService: TranslateService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'TechRole';
    }
	
    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RoleName), 'CORE.COMMON.MODEL.TECHROLE.ROLENAME', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.SysRole), 'CORE.COMMON.MODEL.TECHROLE.SYSROLE', 0, true).setMDSizeHalf().setReadonly());
    }

    async openEditDialog() {
        if (this.Model.SysRole == 1) {
            this.commonService.confirm({ message: this.translateService.instant("CORE.COMMON.MODEL.TECHROLE.DISALLOWMODIFICATIONOFSYSTEMROLES"), header: this.translateService.instant("CORE.COMMON.MODEL.TECHROLE.TECHROLE_EDIT"), rejectVisible: false, icon: "fa fa-ban" });
            return;
        }

        await super.openEditDialog();
    }

    openDeleteDialog() {
        if (this.Model.SysRole == 1) {
            this.commonService.confirm({ message: this.translateService.instant("CORE.COMMON.MODEL.TECHROLE.DISALLOWDELETIONOFSYSTEMROLES"), header: this.translateService.instant("CORE.COMMON.MODEL.TECHROLE.TECHROLE_DELETE"), rejectVisible: false, icon: "fa fa-ban" });
            return;
        }

        super.openDeleteDialog();
    }
}
