import { OnChanges, SimpleChanges } from '@angular/core';
import { Email } from '@avi-x/avi-dto/system/email.model';
import { AviDocumentReference } from '@avi-x/avi-dto/avidoc/avidocumentreference.model';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviDialogComponent } from '@avi-x/avi-core';
import { AviDokumentService } from '../../../dokument/services/dokument.service';
import { SplitButton } from 'primeng/splitbutton';

const nameof = (nameFunction: ((obj: Email) => any)) => typed_nameof<Email>(nameFunction);

@Component({
    selector: 'avi-crm-email-form',
    styleUrls: [ './email-form.components.scss'],
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [contents-padding]="ContentsPadding"
        [Model]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        save-button-label="Email versenden"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons">

        <div formFieldTemplate1>
            <div class="form-field flex flex-wrap flex-gap-2">
                <p-splitButton *ngFor="let t of Model?.Documents" 
                    #btn
                    [label]="t.Dateiname" 
                    (onDropdownClick)="fillAttachmentMenuItems(btn, t)"
                    (onClick)="btn.onDropdownButtonClick($event)"
                    class="attachment" 
                    styleClass="attachment" 
                    [icon]="'fa ' + documentService.getDocumentIcon(t.Dateiendung)"
                    [pTooltip]="t.Dateiname" 
                    tooltipPosition="top" 
                    [showDelay]="500" 
                    tooltipStyleClass="autofit-tooltip">
                </p-splitButton>
            </div>
        </div>

    </avi-core-base-form>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviEmailFormComponent implements OnInit, OnDestroy, OnChanges {
    private _className = 'Nachricht';

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
   
    @Input('form-title')
    public FormTitle: string = this._className;

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('api-prefix')
    public ApiPrefix: string = '';

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('model')
    public Model: Email = null;

    public Loading: boolean = false;
    public Fields: AviFormField[] = [];

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    @Output('onAttrChange')
    public onAttrChangeEvent: EventEmitter<AviChangedAttr> = new EventEmitter();

    constructor(private commonService: AviCommonService,
        private apiService: AviApiService,
        public documentService: AviDokumentService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    fillAttachmentMenuItems(btn: SplitButton, t: AviDocumentReference) {
        btn.model = this.getAttachmentMenuItems(t);
    }

    getAttachmentMenuItems(t: AviDocumentReference) {
        return [
            { label: 'Beilage öffnen', icon: 'pi pi-external-link', command: () => this.onOpenPreview(t)  },
            { separator: true },
            { label: 'Beilage entfernen', icon: 'pi pi-trash', command: () => this.removeAttachment(t) }
        ];
    }

    // public async InitForm(model: Email): Promise<boolean> {
    //     this.initModel(model);

    //     this.cdr.markForCheck();

    //     return true;
    // }

    public async ClearForm() {
        this.Model = null;
    }

    async onAttrChange(data: AviChangedAttr) {
        this.onAttrChangeEvent.emit(data);
    }

    ngOnInit() {
        this.initFields();
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: Email) {
        this.Form.clearFormMessages();

        const delegate: Promise<any> = this.apiService.post(`${this.ApiPrefix ?? ''}/email/send`, this.Model);

        delegate.then(r => {
            this.onSavedSuccessfulDelegate.emit(model);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    ngOnDestroy() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.Model) {
            console.log(this.Model.Documents.length);
            this.formFieldService.setFieldVisible(this.Fields, 'formFieldTemplate1', this.Model.Documents?.length > 0);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Message), !this.Model.IsBodyHtml);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.MessageRTF), this.Model.IsBodyHtml);
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Message), !this.Model.IsBodyHtml);
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.MessageRTF), this.Model.IsBodyHtml);
            this.cdr.markForCheck();
        }
    }

    public RefreshEditors() {
        this.Form.RefreshEditors();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Recipient), 'Empfänger', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.CCRecipient), 'CC', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Subject), 'Betreff', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(1, 'Beilagen').setLabelVisible(true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Message), 'Nachricht', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateRichText(nameof(c => c.MessageRTF), 'Nachricht', false).setMDSizeFull());
    }
    

    public onOpenPreview(doc: any) {
        if (doc) 
            this.loadPreview(doc.vonArchivDef_ID, doc.DocId);
    }

    public removeAttachment(doc: any) {
        if (doc) {
            const idx = this.Model.Documents.indexOf(doc);
            this.Model.Documents.splice(idx, 1);
            this.formFieldService.setFieldVisible(this.Fields, 'formFieldTemplate1', this.Model.Documents?.length > 0);
            this.cdr.markForCheck();
        }
    }

    private async loadPreview(archivDefId: string, docId: string) {
        if (archivDefId && docId)
            await this.documentService.openPreviewDialog(docId, archivDefId);        
    }
}
