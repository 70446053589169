import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocPropertyDef } from '@avi-x/avi-dto/document_def/docpropertydef.model';

const nameof = (nameFunction: ((obj: DocPropertyDef) => any)) => typed_nameof<DocPropertyDef>(nameFunction);

@Component({
    selector: 'avi-docpropertydef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [taskmenu-position]="TaskMenuPosition"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocPropertyDefFormComponent extends AviAbstractModelFormComponent<DocPropertyDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF';
    BaseUrl = 'documentdef/docpropertydef';
    ClassType = DocPropertyDef;
	FormType = AviDocPropertyDefFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFDocProperty';
    }

    
    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1)) {
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung3 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung4 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung5 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung6 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung7 = this.Model.Bezeichnung1;
        }

        else if (data.field === nameof(c => c.KurzBez1)) {
            this.Model.KurzBez2 = this.Model.KurzBez1;
            this.Model.KurzBez3 = this.Model.KurzBez1;
            this.Model.KurzBez4 = this.Model.KurzBez1;
            this.Model.KurzBez5 = this.Model.KurzBez1;
            this.Model.KurzBez6 = this.Model.KurzBez1;
            this.Model.KurzBez7 = this.Model.KurzBez1;
        }
    }
	
    initFields() {
		// if (this.ReadOnly)
		// 	this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.KURZBEZ1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.BEZEICHNUNG1', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.KURZBEZ2', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.BEZEICHNUNG2', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.KURZBEZ3', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.BEZEICHNUNG3', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.KURZBEZ4', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.BEZEICHNUNG4', true).setMDSizeHalf());
                
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.PropertyName), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.PROPERTYNAME', true).setMDSizeHalf());
    }
}
