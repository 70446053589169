import { Expose, Type } from "class-transformer";
import { GenericRef, IDTO, ListType } from "../shared";
import { AviListDetailConst } from "@avi-x/avi-core";

export class JobParameterDto extends IDTO {

    constructor(key?: string, attrType?: string, value?: any) {
        super();

        if (key) 
            this.Key = key;

        if (attrType) {
            this.AttrType = attrType;

            switch (attrType) {
                case AviListDetailConst.ATTRTYPE_INTEGER:
                case AviListDetailConst.ATTRTYPE_DECIMAL:
                    this.Number = value;
                    break;
                case AviListDetailConst.ATTRTYPE_TEXT:
                    this.Text = value;
                    break;
                case AviListDetailConst.ATTRTYPE_DATE:
                    this.Date = value;
                    break;
                case AviListDetailConst.ATTRTYPE_LISTTYPE:
                    this.ListType = value;
                    break;
                case AviListDetailConst.ATTRTYPE_REFERENCE:
                    this.GenericRef = value;
                    break;
                default:
                    throw new Error("not implemented");
            }
        }
    }

    @Expose()
    public Key: string;

    @Expose()
    public AttrType: string;

    @Expose()
    public Number: number;

    @Expose()
    public Text: string;

    @Expose()
    @Type(() => Date)
    public Date: Date;

    @Expose()
    @Type(() => ListType)
    public ListType: ListType;

    @Expose()
    @Type(() => GenericRef)
    public GenericRef: GenericRef;
}