<div class="mt-2">
    <avi-core-view-panel 
        [toggleable]="true" 
        header="Abrechnungsvorlage" 
        class="w-100p">

        <avi-custompropcollectiondef-form
            [readonly]="true"
            [model-id]="ModelId"
            [card]="false"
            [contents-padding]="false"
            [title]=""
        >
        </avi-custompropcollectiondef-form>

        <button *ngxPermissionsOnly="['CAFCustomPropCollectionDef schreiben']"  pButton type="button" label="Attributsammlung testen" icon="pi pi-eye" aviThrottledClick (throttledClick)="TestCustomPropCollectionDef()" class="mb-8p p-button-primary ml-4 mt-2"></button>
        <!-- <button pButton type="button" label="Vorlage kopieren" icon="pi pi-clone" aviThrottledClick (throttledClick)="CopyFormularDef()" class="mb-8p p-button-secondary ml-2 mt-2"></button> -->

    </avi-core-view-panel>

    <avi-core-view-panel 
        [toggleable]="true" 
        header="Properties" 
        class="w-100p">

        <avi-custompropdef-searcher
            [context-id]="ModelId"
        >
        </avi-custompropdef-searcher>
       
    </avi-core-view-panel>

</div>
