import { AviDateFormatPipe } from './../../shared/pipes/dateFormatPipe';
import { AviFormField, AviFormFieldType } from './../base-form/form-field';
import { AviSafeHtmlPipe } from './../../shared/pipes/safeHtmlPipe';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef, AfterViewInit, OnDestroy, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { MenuItem, FilterMetadata, SortMeta } from 'primeng/api';
import { Table } from 'primeng/table';
import { Droppable } from 'primeng/dragdrop';

import { Subject } from 'rxjs'; // --> https://stackoverflow.com/questions/63263569/o-subject-is-not-a-constructor-angular-10
import { Subscription } from 'rxjs/Subscription';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { DomSanitizer } from '@angular/platform-browser';
import { DecimalPipe, PercentPipe } from '@angular/common';
import { AviSearcherButtonType, AviSearcherColumn, AviSearcherColumnType } from '../base-searcher/searcher-column';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviListDetailConst } from '../../shared/constants/constants';
import { AviCommunicationService } from '../../services/communication.service';

import { IAviSearcherParameter } from '@avi-x/avi-dto/interface/avisearcher-parameter.interface';
import { FilterUtils } from '../utils/filter-utils';
import { ObjectUtils } from '../utils/object-utils';
import { AviFormFieldService } from '../../services/form-field.service';
import { AviBaseFormComponent } from '../base-form/base-form.component';
import { split } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
import { ListType } from '@avi-x/avi-dto/shared';

import { FileSaverService } from 'ngx-filesaver';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviAuthService } from '@avi-x/avi-core';
import { SplitButton } from 'primeng/splitbutton';
import { SearcherDataSource } from './SearcherDataSource';

export interface UrlParams {
    search: string;
    page: number;
    inactive: string;
    do: string;
    cflt: string[];
    csort: string[];
    param: string[];
    context: string;
}

export interface ISearcherDragDropHandler {
    dragStart(sender: any, dataRows: any[]);
    dragEnd(sender: any);
    drop(sender: any);
}

@Component({
    selector: 'avi-core-base-searcher',
    templateUrl: './base-searcher.component.html',
    styleUrls: ['./base-searcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DecimalPipe, PercentPipe, AviSafeHtmlPipe],
})
export class AviBaseSearcherComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    public get _emptyMessage(): string {
        return this._initialSearchDone ? this.EmptyMessage : 'Bitte Suchbegriff eingeben und Suche starten';
    }

    public get SelectableColumns() {
        return this._selectableColumns;
    }

    public get VisibleColumns() {
        return this.Columns.filter(
            (i) =>
                i.Visible &&
                (this._VisibleColumns == null ||
                    this._VisibleColumns.length === 0 ||
                    this._VisibleColumns.includes(i.Field)) &&
                (this._HiddenColumns == null ||
                    this._HiddenColumns.length === 0 ||
                    !this._HiddenColumns.includes(i.Field))
        );
    }

    public get VisibleResponsiveColumns() {
        if (window.innerWidth <= 767) return this.VisibleColumns.filter((i) => i.ShowOnMobile || i.IsSystem);
        else return this.VisibleColumns;
    }

    @ViewChild('parameterForm', { static: false })
    public parameterForm: AviBaseFormComponent;

    @ViewChild(Droppable)
    private droppableDirective: Droppable;

    public IsInitialized: boolean = false;

    public dataSource = new SearcherDataSource();
    public get Data(): any {
        return this.dataSource.filteredData;
    }
    public SelectedRows: any[] | any = []; // Bei selectionMode=single kein Array
    public KeepSelectedRows: any[] = [];
    public PageRowStartIndex = 0;
    public PageRowNumbers = 0;
    public SortInfo: { column: AviSearcherColumn; order: number }[] = null;
    public ColumnFilters: { column: AviSearcherColumn; value: string; matchmode: string }[] = null;
    public SearcherParameters: AviFormField[] = [];

    headerCheckboxChecked: boolean;
    selectionChangeSubscription: Subscription;
    valueChangeSubscription: Subscription;

    private urlParams: UrlParams = null;
    private Selection: string[] = [];

    public SearcherColumnType = AviSearcherColumnType;
    public SearcherButtonType = AviSearcherButtonType;
    public TotalRecords: number = 0;
    public TotalRecordsUnfiltered: number = null;
    public ExpandedRowKeys: {
        [s: string]: boolean;
    } = {};

    public FieldNameId: string = 'Id';
    public DataKey: string = 'Id';
    public ExecuteSearch: boolean = false;
    public AdditionalSearchData = undefined;

    private SuppressSearchEvents: boolean = true;
    public Context: string = null;
    public AdvancedMode: boolean = false;
    public SelectColumns: boolean = false;

    public DateFilters: any[] = [];
    public ListTypeFilters: any[] = [];
    public BooleanFilters: any[] = [];
    public CheckBoxSelectionWidth = null;

    visible: boolean = true;
    updateView(): void {
        this.visible = false;

        setTimeout(() => {
            this.visible = true;
            this.cdr.markForCheck();
        });
    }

    @Input('empty-message')
    public EmptyMessage: string = 'Keine Datensätze gefunden';

    @Input('navigate-back')
    public ShowNavigateBack: boolean = false;

    @Input('resizable-columns')
    public ResizableColumns: boolean = true;

    @Input('reorderable-columns')
    public ReorderableColumns: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('use-router-params')
    public UseRouterParams: boolean = true;

    @Input('use-router-params-selection')
    public UseRouterParamsSelection: boolean = true;

    // Wenn true wird die Selektion beibehalten, egal ob mal mit paging navigiert und/oder filtert
    // Aktuell nur getestet mit lazy-loading=true
    @Input('keep-selection')
    public KeepSelection: boolean = false;

    @Input('savestate-selection')
    public SaveStateSelection: boolean = false;

    @Input('savestate-filter')
    public SaveStateFilter: boolean = false;

    @Input('savestate-sorting')
    public SaveStateSorting: boolean = true;

    @Input('savestate-parameters')
    public SaveStateParameters: boolean = false;

    // Wenn true werden die Zeilen im Searcher nicht umgebrochen sondern mit Ellipsis gekürzt
    @Input('no-word-wrap')
    public NoWordWrap: boolean = true;

    @Input('collapsible')
    public Collapsible: boolean = true;

    @Input('expanded')
    public Expanded: boolean = true;

    @Input('show-expansion-buttons')
    public ShowExpansionButtons: boolean = false;

    @Input('show-group-expansion-buttons')
    public ShowGroupExpansionButtons: boolean = false;

    @Input('fill-height')
    public FillHeight: boolean = false;

    @Input('show-totalrecords')
    public ShowTotalRecords = true;

    @Input('total-label')
    public TotalLabel: string = 'Total';

    @Input('show-totalselected')
    public ShowTotalSelected = false;

    @Input('total-selected-label')
    public TotalSelectedLabel: string = 'Total selektiert';

    @Input('show-totalrecordsunfiltered')
    public ShowTotalRecordsUnfiltered = false;

    @Input('total-unfiltered-label')
    public TotalUnfilteredLabel: string = 'Total unfiltered';

    @Input('toolbar-show-inaktive')
    public showInaktive = false;

    @Input('disable-inactive-filter')
    public disableInactiveFilter = false;

    @Input('inaktiv-label')
    public InaktivLabel: string = null;

    @Input('toolbar')
    public ShowToolbar: boolean = true;

    @Input('toolbar-show-searchinput')
    public ShowSearchInput: boolean = true;

    @Input('toolbar-show-advanced')
    public ShowAdvanced: boolean = false;

    @Input('toolbar-show-inactivebutton')
    public ShowInactiveButton: boolean = true;

    @Input('toolbar-show-columnselection')
    public ShowColumnSelection: boolean = true;

    @Input('toolbar-show-generic-export')
    public ShowGenericExport: boolean = false;

    @Input('toolbar-show-createbutton')
    public ShowCreateButton: boolean = false;

    @Input('toolbar-show-refresh')
    public ShowRefreshButton: boolean = true;

    @Input() csvSeparator: string = ';';

    @Input() exportFilename: string = 'export';

    // @Input('toolbar-show-exportbutton')
    // public ShowExportButton: boolean = false;

    @Input('toolbar-buttons')
    toolbarButtons: any[] = [];

    @Input('searcher-columns')
    public Columns: AviSearcherColumn[] = [];

    public loading = false;

    @Input('show-global-loader')
    public DisplayGlobalLoader: boolean = false;

    @Input('show-autofilter')
    public ShowAutofilter = true;

    @Input('show-autofilter-clear')
    public ShowAutofilterClear = true;

    @Input('auto-search')
    public AutoSearch = true;

    @Input('auto-search-on-type')
    public AutoSearchOnType = false;

    @Input('lazy-loading')
    public LazyLoading = true;

    @Input('select-first-result')
    public SelectFirstResult = false;

    @Input('exportable-csv')
    public ExportableCSV = true;

    @Input('rows')
    public SearcherRows = 20;

    @Input('searcher-title')
    public SearcherTitle = 'Suche';

    @Input('searcher-icon')
    public SearcherIcon: string = null;

    @Input('menu-items')
    public MenuItems: MenuItem[];

    @Input('row-footer-delegate')
    public RowFooterDelegate: (searcher: any, column: any) => string = null;

    @Input('row-group-mode')
    public RowGroupMode: string = null; // rowGroupMode="rowspan" | rowGroupMode="subheader" groupField="brand"

    @Input('row-group-footer-delegate')
    public RowGroupFooterDelegate: (row: any, searcher: any, column: any) => string = null;

    @Input('row-group-header-delegate')
    public RowGroupHeaderDelegate: (row: any, searcher: any) => string = null;

    @Input('row-detailview-template')
    public rowDetailViewTemplate: TemplateRef<any>;

    @Input('group-field')
    public GroupField: string = null;

    @Input('responsive')
    public Responsive = true;

    @Input('search-fields')
    public SearchFields: string[] = null;

    @Input('init-fields')
    public InitFields: string[] = null;

    @Input('field-name-id')
    public set setFieldNameId(value: string) {
        this.FieldNameId = value;
        this.DataKey = this.getFieldName(this.FieldNameId);
    }

    @Input('field-name-aktiv')
    public FieldNameAktiv: string = 'aktiv';

    @Input('field-names-gueltigab')
    public FieldNamesGueltigAb: string[] = null;

    @Input('field-names-gueltigbis')
    public FieldNamesGueltigBis: string[] = null;

    @Input('pretoolbar-template')
    // eslint-disable-next-line camelcase
    public pretoolbar_template: TemplateRef<any>;

    @Input('posttoolbar-template')
    // eslint-disable-next-line camelcase
    public posttoolbar_template: TemplateRef<any>;

    @Input('toolbarcontrols-template')
    // eslint-disable-next-line camelcase
    public toolbarcontrols_template: TemplateRef<any>;

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('hide-when-noresults')
    public HideWhenNoResults: boolean = false;

    @Input('selectionMode')
    public SelectionMode: string = 'multiple';

    @Input('checkbox-selection')
    public CheckboxSelection: boolean = false;

    @Input('metakeyselection')
    public MetaKeySelection: boolean = true;

    @Input('checkbox-selectionmode')
    public CheckboxSelectionMode: string = 'null';

    @Input('add-crud-menuitems')
    public AddCRUDMenuItems: boolean = false;

    CRUDCanCreate = true;
    CRUDCanEdit = true;
    CRUDCanDelete = true;

    _CRUDBaseRight: string = null;
    @Input('crud-base-right')
    public set CRUDBaseRight(value: string) {
        this._CRUDBaseRight = value;

        if (this.CRUDBaseRight) {
            this.permissionsService
                .hasPermission([`${this.CRUDBaseRight} erfassen`, `${this.CRUDBaseRight} schreiben`])
                .then((w) => (this.CRUDCanCreate = w));
            this.permissionsService
                .hasPermission([`${this.CRUDBaseRight} editieren`, `${this.CRUDBaseRight} schreiben`])
                .then((w) => (this.CRUDCanEdit = w));
            this.permissionsService
                .hasPermission([`${this.CRUDBaseRight} löschen`, `${this.CRUDBaseRight} schreiben`])
                .then((w) => (this.CRUDCanDelete = w));
        } else {
            this.CRUDCanCreate = true;
            this.CRUDCanEdit = true;
            this.CRUDCanDelete = true;
        }
    }

    public get CRUDBaseRight() {
        return this._CRUDBaseRight;
    }

    @Input('crud-enable-create')
    public CRUDEnableCreate: boolean = true;

    @Input('crud-enable-edit')
    public CRUDEnableEdit: boolean = true;

    @Input('crud-enable-delete')
    public CRUDEnableDelete: boolean = true;

    @Input() responsiveLayout: string = 'stack';

    @Input() breakpoint: string = '960px';

    @Output()
    public onClick: EventEmitter<any> = new EventEmitter();

    @Output()
    public onDoubleClick: EventEmitter<any> = new EventEmitter();

    @Output()
    public onClickRowHeader: EventEmitter<any> = new EventEmitter();

    @Output()
    public onDoubleClickRowHeader: EventEmitter<any> = new EventEmitter();

    @Output()
    public onSelect: EventEmitter<any[]> = new EventEmitter();

    @Output()
    public onStartNew: EventEmitter<any> = new EventEmitter();

    @Output()
    public onError: EventEmitter<any> = new EventEmitter();

    @Output()
    public onCreateObject: EventEmitter<any> = new EventEmitter();

    @Output()
    public onEditObject: EventEmitter<any> = new EventEmitter();

    @Output()
    public onDeleteObject: EventEmitter<any> = new EventEmitter();

    @Output()
    public onDataLoaded: EventEmitter<any> = new EventEmitter();

    @Input('getContextMenuItems')
    public getContextMenuItems: (data, items: MenuItem[]) => MenuItem[] = null;

    @Input('best-fit')
    public BestFit = false;

    @Input('default-sort-data')
    public DefaultSortData: SortMeta[] = null;

    @Input('scrollable')
    public Scrollable: boolean = false;

    @Input('scrollheight')
    public ScrollHeight: string = null;

    @Input('visible-columns')
    private _VisibleColumns: string[] = null;

    @Input('hidden-columns')
    private _HiddenColumns: string[] = null;

    @Input('multisort-meta')
    public MultiSortMeta: SortMeta[] = null;

    public FilterData: { [s: string]: FilterMetadata | FilterMetadata[] } = {};
    public CurrentFilterData: { [s: string]: FilterMetadata | FilterMetadata[] } = {};

    @Output()
    public onInit: EventEmitter<AviBaseSearcherComponent> = new EventEmitter();

    // @Output()
    // public onExportClick: EventEmitter<any> = new EventEmitter();

    searchValueChanged: Subject<string> = new Subject<string>();
    showInactiveValueChanged: Subject<string> = new Subject<string>();
    parameterValueChanged: Subject<string> = new Subject<string>();
    querySub: Subscription;

    @Output('onParameterValueChange')
    public onParameterValueChange: EventEmitter<any> = new EventEmitter();

    @Output('onStateSave')
    public onStateSave: EventEmitter<any> = new EventEmitter();
    public onUpdateUserSetting: EventEmitter<any> = new EventEmitter();

    @Output('onStateRestore')
    public onStateRestore: EventEmitter<any> = new EventEmitter();

    @ViewChild('dt', { static: false })
    public dt: Table;

    @ViewChild('searcherInput', { static: false })
    searcherInput: ElementRef;

    public rowGroupMetadata: any;

    public splitButtonItems: MenuItem[] = [];

    public _initialSearchDone = false;
    public SearchValue = '';
    private doValue = '1';
    private _columnOrder: string[] = null;
    private _selectedColumns: AviSearcherColumn[] = [];
    private _selectableColumns: AviSearcherColumn[] = [];

    @Input('show-vertical-gridlines')
    public ShowVerticalGridlines: boolean = false;

    @Input('settingskey')
    public SettingsKey: string = null;

    @Input('search-delegate')
    public SearchDelegate: any = async (
        searchvalue: string,
        searchConfig: any = null,
        searcher: AviBaseSearcherComponent = null
    ) => []; // z.B. async (searchvalue:string) => {return await this.partnerService.Find(searchvalue)}

    @Input('selectall-delegate')
    public SelectAllDelegate: any = null;

    @Input('get-filters')
    public GetFilters: () => string[] = (): string[] => [];

    @Input('get-row-class')
    public getRowClass: (row: any) => string = (row: any): string => this.getBaseRowClass(row);

    getRowClassInternal: (row: any, rowIndex: any) => string = (row: any, rowIndex: any): string => {
        let res = this.getRowClass(row);
        if (this.isOdd(rowIndex)) res += ' odd';

        return res;
    };

    @Input('settingskey-delegate')
    public getSettingsKey: () => string = (): string => (this.SettingsKey ? `avix::${this.SettingsKey}` : null);

    @Input('get-table-style')
    public getTableStyle: any = () => {
        return {
            'table-layout': this.BestFit ? 'auto' : 'fixed',
            width: '100%',
        };
    };

    public getTableStyleClasses(): string {
        const s = ['avi-base-searcher']; // { 'avi-base-searcher': true, 'avi-base-searcher-no-word-wrap': NoWordWrap }
        if (this.NoWordWrap) s.push('avi-base-searcher-no-word-wrap');

        return s.join(' ');
    }

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    set selectedColumns(val: any[]) {
        this._selectedColumns = this.VisibleColumns.filter((col) => val.includes(col) || col.IsSystem);

        if (this.ReorderableColumns && this._columnOrder)
            this._selectedColumns = this._selectedColumns.sort((a, b) =>
                this._sortColumnsFunc(a, b, this._columnOrder)
            );

        setTimeout(() => this.dt.saveState());
    }

    dragDropHandler: ISearcherDragDropHandler
    draggableScope: string;
    droppableScope: string;
    droppableDisabled = true;
    draggableDisabled = true;

    public saveState() {
        if (this.dt) this.dt.saveState();
    }

    public restoreState() {
        if (this.dt) this.dt.restoreState();
    }

    public restoreSortOrder() {
        if (this.dt) {
            this.dt.restoreState();
            this.MultiSortMeta = this.dt._multiSortMeta;
        }
    }

    public ParameterValueChanged(data) {
        this.onParameterValueChange.emit(data);
        this._parameterValueChanged(data);

        setTimeout(() => this.dt.saveState());
    }

    public FocusSearchField() {
        if (this.searcherInput) this.searcherInput.nativeElement.focus();
    }

    public getGridLinesRowClass(): string {
        return this.ShowVerticalGridlines ? '' : 'no-border-left no-border-right'; // 'border-left border-right';
    }

    public getGridLinesColumnClass(): string {
        return this.ShowVerticalGridlines ? '' : 'no-border-left no-border-right';
    }

    public getHeaderRowClass(): string {
        return this.getGridLinesRowClass();
    }

    public getGroupHeaderRowClass(): string {
        return this.getGridLinesRowClass() + ' table-groupheader';
    }

    public getGroupFooterRowClass(): string {
        return this.getGridLinesRowClass() + ' table-groupfooter';
    }

    public getHeaderColumnClass(c: AviSearcherColumn): string {
        let classes = c && !c.ShowOnMobile ? 'ui-table-hide-on-sm' : '';
        if (c?.HeaderColumnClass) classes = classes + ' ' + c.HeaderColumnClass;
        return classes + ' ' + this.getGridLinesColumnClass();
    }

    public getGroupHeaderColumnClass(): string {
        return this.getGridLinesColumnClass();
    }

    public getGroupFooterColumnClass(): string {
        return this.getGridLinesColumnClass();
    }

    public getFooterColumnClass(): string {
        return this.getGridLinesColumnClass();
    }

    public getColumnClass(c: AviSearcherColumn): string {
        let classes = c && !c.ShowOnMobile ? 'ui-table-hide-on-sm ui-resizable-column' : 'ui-resizable-column';
        if (c?.ColumnClass) classes = classes + ' ' + c.ColumnClass;
        return classes + ' ' + this.getGridLinesColumnClass();
    }

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public commonService: AviCommonService,
        public apiService: AviApiService,
        private sanitizer: DomSanitizer,
        public decimalPipe: DecimalPipe,
        public percentPipe: PercentPipe,
        public datePipe: AviDateFormatPipe,
        public ref: ChangeDetectorRef,
        private communicationService: AviCommunicationService,
        private formFieldService: AviFormFieldService,
        private fileSaverService: FileSaverService,
        private translateService: TranslateService,
        private permissionsService: NgxPermissionsService,
        private authService: AviAuthService,
        public cdr: ChangeDetectorRef
    ) {
        this.InitColumns();
    }

    isOdd(rowIndex) {
        if (this.RowGroupHeaderDelegate) return (this.Data[rowIndex].__grpindex & 1) > 0;

        return (rowIndex & 1) == 0;
    }

    toggleGroupVisible(rowIndex) {
        this.rowGroupMetadata[rowIndex].visible = !(this.rowGroupMetadata[rowIndex].visible ?? true);
        this.ref.markForCheck();
    }

    getGroupVisible(rowIndex) {
        if (this.RowGroupHeaderDelegate) return this.rowGroupMetadata[rowIndex].visible ?? true;

        return true;
    }

    public resetPaging() {
        this.PageRowStartIndex = 0;
        this.dt.first = 0;
    }

    public selectNextPage() {
        const i = this.currentPage();

        const totalPages = Math.ceil(this.TotalRecords / this.SearcherRows);
        // console.log("selectNextPage", i, totalPages);
        if (i < totalPages) this.setCurrentPage(i + 1);
    }

    // Wenn ichs im Template mache kann ich keine Nur-Icon-Buttons darstellen...
    translateInstant(val) {
        if (!val) return null;
        return this.translateService.instant(val);
    }

    public selectPrevPage() {
        const i = this.currentPage();
        // console.log("selectPrevPage", i);
        if (i > 1) this.setCurrentPage(i - 1);
    }

    public currentPage(): number {
        return this.SearcherRows <= 0 ? 1 : Math.floor(this.PageRowStartIndex / this.PageRowNumbers) + 1;
    }

    setCurrentPage(n: number) {
        this.dt.first = (n - 1) * this.SearcherRows;
        this.PageRowStartIndex = this.dt.first;
        this.updateQueryParams();
        // this.startSearch(false);
    }

    public _onPage($event) {
        this.PageRowStartIndex = $event.first;
        this.PageRowNumbers = $event.rows;

        if (this.KeepSelection) this.KeepSelectedRows = this.SelectedRows;

        this.SelectedRows = [];
        this.dt.selectionKeys = {};
        this.dt.first = $event.first;

        this.updateQueryParams(true);
    }

    public selectPrev() {
        if (!(this.Data && this.Data.length > 0)) return;
        if (this.SelectedRows.length === 0) {
            this.SelectedRows = this.Data[this.Data.length - 1];
            this.onSelect.emit(this.SelectedRows);
            return;
        }

        const selIdx = this.Data.indexOf(this.SelectedRows[0]);
        if (selIdx > this.PageRowStartIndex) {
            this.SelectedRows = [this.Data[selIdx - 1]];
            this.onSelect.emit(this.SelectedRows);
        }
    }

    public selectNext() {
        if (!(this.Data && this.Data.length > 0)) return;
        if (this.SelectedRows.length === 0) {
            this.SelectedRows = this.Data[0];
            this.onSelect.emit(this.SelectedRows);
            return;
        }

        const selIdx = this.Data.indexOf(this.SelectedRows[0]);
        if (selIdx + 1 < this.PageRowStartIndex + this.PageRowNumbers && selIdx + 1 < this.Data.length) {
            this.SelectedRows = [this.Data[selIdx + 1]];
            this.onSelect.emit(this.SelectedRows);
        }
    }

    public getBaseRowClass(row: any): string {
        let ret = this.getGridLinesRowClass();
        if (row) {
            const aktivField = this.getFieldName(this.FieldNameAktiv);
            if (
                (row[aktivField] && row[aktivField].Id !== AviListDetailConst.AKTIV && row[aktivField] !== 1) ||
                (row[aktivField + 'Bezeichnung'] && row[aktivField + 'Bezeichnung'] !== 'aktiv') ||
                (row[aktivField + 'Id'] && row[aktivField + 'Id'] !== AviListDetailConst.AKTIV)
            )
                ret += ' row-inaktiv';

            const today = this.commonService.getDate00(new Date());

            if (this.FieldNamesGueltigAb) {
                this.FieldNamesGueltigAb.some((w) => {
                    if (row[w] && new Date(row[w]) > today) {
                        ret += ' row-ungueltig-future';
                        return true;
                    }
                    return false;
                });
            }
            if (this.FieldNamesGueltigBis) {
                this.FieldNamesGueltigBis.some((w) => {
                    if (row[w] && new Date(row[w]) < today) {
                        ret += ' row-ungueltig';
                        return true;
                    }
                    return false;
                });
            }
        }
        return ret;
    }

    private getFieldName(attr: string): string {
        return attr.replace(/\./g, '');
    }

    private getIdValue(obj: any): string {
        return obj[this.getFieldName(this.FieldNameId)];
    }

    private getFieldValue(obj: any, attr: string): any {
        return obj[this.getFieldName(attr)];
    }

    public InitColumns() {}

    clearSearchValue() {
        this.SearchValue = null;
        this.searchButtonPressed();
    }

    public clearSearcherParameters() {
        if (this.SearcherParameters != null) this.SearcherParameters = [];
    }

    public addSearcherParameters(flt: IAviSearcherParameter, width: number) {
        if (this.SearcherParameters != null && flt != null)
            this.SearcherParameters.push(this.formFieldService.CreateFieldFromSearcherParameter(flt, width));
    }

    public getSearcherParameter(paramName: string): AviFormField {
        return this.SearcherParameters.find((w) => w.Name === paramName);
    }

    public setSearcherParameter(paramName: string, paramValue: any, notifyUpdate: boolean = true) {
        if (this.parameterForm != null) {
            this.formFieldService.setFieldValue(
                this.parameterForm,
                this.SearcherParameters,
                paramName,
                paramValue,
                notifyUpdate
            );
        }
    }

    public clearColumns() {
        this.Columns = [];
    }

    public addColumn(column: AviSearcherColumn): AviSearcherColumn {
        this.Columns.push(column);
        if (column.LabelTrans)
            this.translateService
                .get(column.LabelTrans)
                .toPromise()
                .then((w) => (column.LabelTrans = w));
        return column;
    }

    public getColumn(field: string): AviSearcherColumn {
        return this.Columns.filter((w) => w.OField === field)[0];
    }

    public addTextColumn(
        field: string,
        label: string = null,
        filterable: boolean = false,
        width: string = null,
        dataSource: any = null,
        displayField: any = null,
        valueField: any = null
    ): AviSearcherColumn {
        const col = AviSearcherColumn.CreateText(
            field,
            label,
            filterable,
            this.apiService,
            dataSource,
            displayField,
            valueField,
            width
        );
        if (label)
            this.translateService
                .get(label)
                .toPromise()
                .then((w) => (col.LabelTrans = w));
        this.Columns.push(col);
        return col;
    }

    public addDateColumn(
        field: string,
        label: string = null,
        dateFormat: string = 'mediumDate',
        filterable: boolean = false,
        width: string = null
    ): AviSearcherColumn {
        if (dateFormat == null) dateFormat = 'mediumDate';
        const col = AviSearcherColumn.CreateDate(field, label, dateFormat, filterable, width);
        if (label)
            this.translateService
                .get(label)
                .toPromise()
                .then((w) => (col.LabelTrans = w));
        this.Columns.push(col);
        return col;
    }

    public addIntegerColumn(
        field: string,
        label: string = null,
        filterable: boolean = false,
        width: string = null,
        dataSource: any = null,
        displayField: any = null,
        valueField: any = null
    ): AviSearcherColumn {
        const col = AviSearcherColumn.CreateInteger(
            field,
            label,
            null,
            null,
            filterable,
            this.apiService,
            dataSource,
            displayField,
            valueField,
            width
        );
        if (label)
            this.translateService
                .get(label)
                .toPromise()
                .then((w) => (col.LabelTrans = w));
        this.Columns.push(col);
        return col;
    }

    public addDecimalColumn(
        field: string,
        label: string = null,
        minNachkommastellen: number = 2,
        maxNachkommastellen: number = 2,
        filterable: boolean = false,
        width: string = null,
        dataSource: any = null,
        displayField: any = null,
        valueField: any = null
    ): AviSearcherColumn {
        const col = AviSearcherColumn.CreateDecimal(
            field,
            label,
            minNachkommastellen,
            maxNachkommastellen,
            null,
            null,
            filterable,
            this.apiService,
            dataSource,
            displayField,
            valueField,
            width
        );
        if (label)
            this.translateService
                .get(label)
                .toPromise()
                .then((w) => (col.LabelTrans = w));
        this.Columns.push(col);
        return col;
    }

    public addBooleanColumn(
        field: string,
        label: string = null,
        tristate: boolean = false,
        filterable: boolean = false,
        width: string = null,
        dataSource: any = null,
        displayField: any = null,
        valueField: any = null
    ): AviSearcherColumn {
        const col = AviSearcherColumn.CreateBoolean(
            field,
            label,
            tristate,
            filterable,
            this.apiService,
            dataSource,
            displayField,
            valueField,
            width
        );
        if (label)
            this.translateService
                .get(label)
                .toPromise()
                .then((w) => (col.LabelTrans = w));
        this.Columns.push(col);
        return col;
    }

    public addListTypeColumn(
        field: string,
        label: string = null,
        listName: string,
        filterOnId: boolean = true,
        filterable: boolean = false,
        width: string = null,
        prefixApi: string = null,
        hiddenListItems: string[] = null
    ): AviSearcherColumn {
        const col = AviSearcherColumn.CreateListType(
            field,
            label,
            listName,
            filterOnId,
            this.apiService,
            filterable,
            width,
            prefixApi,
            hiddenListItems
        );
        if (label)
            this.translateService
                .get(label)
                .toPromise()
                .then((w) => (col.LabelTrans = w));
        this.Columns.push(col);
        return col;
    }

    public addIntegerBarColumn(
        field: string,
        label: string = null,
        minValue: number,
        maxValue: number,
        filterable: boolean = false,
        width: string = null,
        dataSource: any = null,
        displayField: any = null,
        valueField: any = null
    ): AviSearcherColumn {
        const col = AviSearcherColumn.CreateInteger(
            field,
            label,
            minValue,
            maxValue,
            filterable,
            this.apiService,
            dataSource,
            displayField,
            valueField,
            width
        );
        if (label)
            this.translateService
                .get(label)
                .toPromise()
                .then((w) => (col.LabelTrans = w));
        col.Type = AviSearcherColumnType.CUSTOM;
        col.CellStyleDelegate = this.getBarCellStyle;
        this.Columns.push(col);
        return col;
    }

    public addDecimalBarColumn(
        field: string,
        label: string = null,
        minNachkommastellen: number = 2,
        maxNachkommastellen: number = 2,
        minValue: number = 0,
        maxValue: number = 100,
        filterable: boolean = false,
        width: string = null,
        dataSource: any = null,
        displayField: any = null,
        valueField: any = null
    ): AviSearcherColumn {
        const col = AviSearcherColumn.CreateDecimal(
            field,
            label,
            minNachkommastellen,
            maxNachkommastellen,
            minValue,
            maxValue,
            filterable,
            this.apiService,
            dataSource,
            displayField,
            valueField,
            width
        );
        if (label)
            this.translateService
                .get(label)
                .toPromise()
                .then((w) => (col.LabelTrans = w));
        col.Type = AviSearcherColumnType.CUSTOM;
        col.CellStyleDelegate = this.getBarCellStyle;
        this.Columns.push(col);
        return col;
    }

    public setBarColumn(field: string, minValue: number, maxValue: number) {
        const col = this.getColumn(field);
        if (col) {
            col.CellStyleDelegate = this.getBarCellStyle;
            col.MinValue = minValue;
            col.MaxValue = maxValue;
        }
    }

    public GetMinRowValueDelegate: Function = (value: any, row: any, col: any) =>
        col.MinValue != null ? col.MinValue : 0;
    public GetMaxRowValueDelegate: Function = (value: any, row: any, col: any) =>
        col.MaxValue != null ? col.MaxValue : 100;

    public getBarCellStyle: Function = (row, col) => {
        const value = row[col.Field];
        if (value !== undefined) {
            const minValue = this.GetMinRowValueDelegate(value, row, col);
            const maxValue = this.GetMaxRowValueDelegate(value, row, col);

            const prozent = Math.min(100, (100 * value) / (maxValue - minValue));
            return {
                background: `linear-gradient(to right, var(--accent-dark-color) 0%,  var(--accent-light-color) ${prozent}% ${prozent}%, transparent ${prozent}%)`,
            };
        }

        return value;
    };

    public async Find(config: any): Promise<any> {
        // console.log('FIND', config);

        if (this.LazyLoading && this.SearcherRows > 0)
            return this.apiService.Find(
                config.path,
                config.q,
                config.filter,
                config.init,
                config.select,
                config.sort,
                config.page,
                config.per_page
            );
        else return this.apiService.Find(config.path, config.q, config.filter, config.init, config.select, config.sort);

        // return this.apiService.get(config.path, [this.apiService.buildHeader("q", this.SearchValue)]);
    }

    public getCheckboxSelectionStyle() {
        const style = {};
        if (this.CheckBoxSelectionWidth) {
            if (`${this.CheckBoxSelectionWidth}`.endsWith('px') || `${this.CheckBoxSelectionWidth}`.endsWith('%')) {
                style['width'] = `${this.CheckBoxSelectionWidth}`;
            } else {
                style['width'] = `${this.CheckBoxSelectionWidth}px`;
            }
        } else style['width'] = '3.0rem';

        return style;
    }

    public getColumnStyle(col: AviSearcherColumn) {
        const style = {};
        if (col.Width) {
            if (`${col.Width}`.endsWith('px') || `${col.Width}`.endsWith('%')) {
                style['width'] = `${col.Width}`;
            } else {
                style['width'] = `${col.Width}px`;
            }
        }
        return style;
    }

    private _getFilterOperator(filterColumn: AviSearcherColumn, filterValue: string): string {
        if (filterColumn.FilterType === AviSearcherColumnType.TEXT) return 'contains';
        else if (
            filterColumn.FilterType === AviSearcherColumnType.DATE ||
            filterColumn.Type === AviSearcherColumnType.INTEGER ||
            filterColumn.Type === AviSearcherColumnType.DECIMAL
        ) {
            let op = 'eq';
            const value = filterValue.trim();
            if (value.startsWith('>=')) op = 'ge';
            else if (value.startsWith('<=')) op = 'le';
            else if (value.startsWith('>')) op = 'gt';
            else if (value.startsWith('<')) op = 'lt';

            return op;
        } else if (filterColumn.FilterType === AviSearcherColumnType.LISTTYPE || filterColumn.FilterType === AviSearcherColumnType.BOOLEAN) {
            return 'eq';
        } else {
            return null;
        }
    }

    private _getFilterValue(filterColumn: AviSearcherColumn, filterValue: string): any {
        if (
            filterValue &&
            (filterColumn.FilterType === AviSearcherColumnType.DATE ||
                filterColumn.FilterType === AviSearcherColumnType.INTEGER ||
                filterColumn.FilterType === AviSearcherColumnType.DECIMAL)
        ) {
            let value = filterValue.trim();
            if (value.startsWith('>=') || value.startsWith('<=')) value = value.slice(2);
            else if (value.startsWith('>') || value.startsWith('<')) value = value.slice(1);

            if (filterColumn.FilterType === AviSearcherColumnType.DATE) {
                const parts = value.split('.');
                if (parts && parts.length === 3 && parseInt(parts[2], null) >= 1800) return value;
            } else {
                const num = Number(value);
                if (!isNaN(num)) return num.toString();
            }

            return null;
        }

        return filterValue;
    }

    public onUpdateDateFilter(value: any, field: any, filterMatchMode: any) {
        if (this.KeepSelection) this.KeepSelectedRows = this.SelectedRows;

        if (value !== null && value != undefined) {
            if (value[0] !== null && value[1] === null) {
                const dateString: string[] = value[0].replace(' ', '').split('.');
                if (dateString.length === 3) {
                    value[0] = dateString[0] + '.' + dateString[1] + '.' + dateString[2];
                    this.dt.filter(value[0], field, filterMatchMode);
                } else this.dt.filter(null, field, filterMatchMode);
            }

            if (value[0] !== null && value[1] !== null) {
                const dateString: string[] = value[1].replace(' ', '').split('.');
                if (dateString.length === 3) {
                    value[1] = dateString[0] + '.' + dateString[1] + '.' + dateString[2];
                    this.dt.filter(value, field, filterMatchMode);
                } else this.dt.filter(null, field, filterMatchMode);
            }
        } else this.dt.filter(null, field, filterMatchMode);
    }

    public onInputDateFilter(value: string, field: any, filterMatchMode: any) {
        if (value.indexOf('-') > 0) {
            const dateStrings: string[] = value.replace(' ', '').split('-');
            this.onUpdateDateFilter([dateStrings[0], dateStrings[1]], field, filterMatchMode);
        } else {
            this.onUpdateDateFilter([value, null], field, filterMatchMode);
        }
    }

    public onUpdateListTypeFilter(value: any, field: any, filterMatchMode: any) {
        if (this.KeepSelection) this.KeepSelectedRows = this.SelectedRows;

        if (Array.isArray(this.ListTypeFilters[field]))
            this.dt.filter(
                this.ListTypeFilters[field].map((ltf) => ltf.value),
                field,
                filterMatchMode
            );
        else {
            if (this.ListTypeFilters[field])
                this.dt.filter(this.ListTypeFilters[field]['value'], field, filterMatchMode);
            else this.dt.filter(null, field, filterMatchMode);
        }
    }

    public clearColumnFilter(field: any, filterMatchMode: any) {
        if (this.KeepSelection) this.KeepSelectedRows = this.SelectedRows;

        this.dt.filter(null, field, filterMatchMode);

        this.ListTypeFilters[field] = null;
        this.DateFilters[field] = null;
        this.BooleanFilters[field] = null;
    }

    public onInputTextFilter(value: any, field: any, filterMatchMode: any) {
        if (this.KeepSelection) this.KeepSelectedRows = this.SelectedRows;

        this.dt.filter(value, field, filterMatchMode);
    }

    public onUpdateBooleanFilter(value: any, field: any, filterMatchMode: any) {
        if (this.KeepSelection) this.KeepSelectedRows = this.SelectedRows;

        let filterString: string;
        if (value === true || value === false)
            filterString = value +"";
        else 
            filterString = null;
        
        this.dt.filter(filterString, field, filterMatchMode);
    }

    private _getSearchFilter(fieldname: string, filterValue: string): string {
        const filterColumn = this._getColumn(fieldname, true);
        if (!filterColumn) return null;

        return this._getSearchFilterForColumn(filterColumn, filterValue);
    }

    private _getSearchFilterForDateRangeColumn(
        dateRangeValues: string[],
        filterColumn: AviSearcherColumn,
        filterValue: string
    ): string {
        if (
            Array.isArray(dateRangeValues) &&
            dateRangeValues.length === 2 &&
            dateRangeValues[1] != null &&
            dateRangeValues[1] !== ''
        ) {
            const opRangeValueStart = this._getFilterOperator(filterColumn, dateRangeValues[0]);
            const valueRangeValueStart = this._getFilterValue(filterColumn, dateRangeValues[0]);

            const opRangeValueEnd = this._getFilterOperator(filterColumn, dateRangeValues[1]);
            const valueRangeValueEnd = this._getFilterValue(filterColumn, dateRangeValues[1]);

            if (opRangeValueStart && valueRangeValueStart && opRangeValueEnd && valueRangeValueEnd) {
                return `(${filterColumn.OField} ge ${valueRangeValueStart}) AND (${filterColumn.OField} le ${valueRangeValueEnd})`;
            }
        }
        return null;
    }

    private _getSearchFilterForListTypeRangeColumn(
        listTypeRangeValues: string[],
        filterColumn: AviSearcherColumn,
        filterValue: string
    ): string {
        if (Array.isArray(listTypeRangeValues)) {
            const filterFieldSplit = filterColumn.OField.split('.');
            let filterString = '(';
            listTypeRangeValues.forEach((rv, index) => {
                if (filterFieldSplit.length > 0 && filterFieldSplit[0] !== null) {
                    filterString = filterString + `(${filterFieldSplit[0]} eq ${rv})`;
                    if (index + 1 < listTypeRangeValues.length) filterString += ' OR ';
                }
            });

            if (filterString !== '' && filterString != null) return filterString + ')';
        }
        return null;
    }

    public _getSearchFilterForColumn(filterColumn: AviSearcherColumn, filterValue: string): string {
        if (filterColumn.FilterType === AviSearcherColumnType.DATE) {
            const dateRangeValues = split(filterValue, ',');
            const dateRangeFilter = this._getSearchFilterForDateRangeColumn(dateRangeValues, filterColumn, filterValue);

            if (dateRangeFilter != null) return dateRangeFilter;
            else filterValue = dateRangeValues[0];
        } else if (filterColumn.FilterType === AviSearcherColumnType.LISTTYPE) {
            const listTypeRangeValues = split(filterValue, ',');
            const listTypeRangeFilter = this._getSearchFilterForListTypeRangeColumn(
                listTypeRangeValues,
                filterColumn,
                filterValue
            );

            if (listTypeRangeFilter != null) return listTypeRangeFilter;
            else filterValue = listTypeRangeValues[0];
        }

        const op = this._getFilterOperator(filterColumn, filterValue);
        const value = this._getFilterValue(filterColumn, filterValue);

        if (op && (value || value === false)) {
            if (filterColumn.FilterType === AviSearcherColumnType.TEXT) {
                // escape string " -> \"
                const valueStr = JSON.stringify(value).slice(1, -1);
                return `(${filterColumn.OField} ${op} "${valueStr}")`;
            } else return `(${filterColumn.OField} ${op} ${value})`;
        }

        return null;
    }

    public getBaseFilterList() {
        const filterlist = [];
        if (this.SearchValue && this.SearchFields) {
            filterlist.push(
                '(' +
                    this.SearchFields.map((w) => this._getSearchFilter(w, this.SearchValue))
                        .filter((w) => w)
                        .join(' OR ') +
                    ')'
            );
        }

        if (this.GetFilters) {
            const filters = this.GetFilters();
            if (filters) filters.forEach((f) => filterlist.push(`(${f})`));
        }

        if (this.ColumnFilters)
            this.ColumnFilters.forEach((f) => {
                const colFilter = this._getSearchFilterForColumn(f.column, f.value);
                if (colFilter) filterlist.push(colFilter);
            });

        if (!this.showInaktive && !this.disableInactiveFilter) {
            filterlist.push(`(${this.FieldNameAktiv} eq aktiv())`);

            if (this.FieldNamesGueltigAb) this.FieldNamesGueltigAb.forEach((w) => filterlist.push(`(${w} le today)`));

            if (this.FieldNamesGueltigBis) this.FieldNamesGueltigBis.forEach((w) => filterlist.push(`(${w} ge today)`));
        }

        return filterlist;
    }

    public buildSearchConfigAsObject(): any {
        const filterlist = this.getBaseFilterList();

        const ret: any = {};

        if (filterlist.length > 0) ret.filter = `${filterlist.join(' AND ')}`;

        if (this.InitFields && this.InitFields.length > 0) ret.init = `${this.InitFields.join(',')}`;

        if (this.LazyLoading && this.SearcherRows > 0) {
            ret.page = `${Math.floor(this.PageRowStartIndex / this.SearcherRows)}`;
            ret.per_page = `${this.SearcherRows}`;
        }

        if (this.SortInfo && this.SortInfo.length > 0) {
            ret.sort = this.SortInfo.map((w) => `${Number(w.order) === -1 ? '-' : ''}${w.column?.OField}`).join(',');
        }

        const selectlist = [this.FieldNameId];
        if (this.showInaktive) selectlist.push(this.FieldNameAktiv);

        this.Columns.filter(
            (w) => w.Type !== AviSearcherColumnType.BUTTON && w.Type !== AviSearcherColumnType.SPLITBUTTON
        ).forEach((f) => {
            if (f.OField) selectlist.push(`${f.OField}`);
        });

        ret.select = `${selectlist.join(',')}`;
        ret.showInactive = this.showInaktive;

        ret.query = this.SearchValue;

        if(this.AdditionalSearchData)
            ret.AdditionalData = this.AdditionalSearchData;

        return ret;
    }

    public buildSearchConfig(path: string): any {
        const filterlist = this.getBaseFilterList();

        let flt = '';
        if (filterlist.length > 0) flt = `&filter=${encodeURIComponent(filterlist.join(' AND '))}`;

        let init = '';
        if (this.InitFields && this.InitFields.length > 0) init = `&init=${this.InitFields.join(',')}`;

        let page = '';
        let perPage = '';
        if (this.LazyLoading && this.SearcherRows > 0) {
            page = `&page=${Math.floor(this.PageRowStartIndex / this.SearcherRows)}`;
            perPage = `&per_page=${this.SearcherRows}`;
        }

        let sort = '';
        if (this.SortInfo && this.SortInfo.length > 0) {
            sort =
                '&sort=' + this.SortInfo.map((w) => `${Number(w.order) === -1 ? '-' : ''}${w.column.OField}`).join(',');
        }

        const selectlist = [this.FieldNameId];
        if (this.showInaktive) selectlist.push(this.FieldNameAktiv);

        this.Columns.forEach((f) => {
            if (f.OField && f.Type !== AviSearcherColumnType.BUTTON && f.Type !== AviSearcherColumnType.SPLITBUTTON)
                selectlist.push(`${f.OField}`);
        });

        const select = `&select=${encodeURIComponent(selectlist.join(','))}`;

        // console.log('Search config', filter);

        return {
            path: path,
            filter: flt,
            q: encodeURIComponent(this.SearchValue),
            init: init,
            sort: sort,
            page: page,
            per_page: perPage,
            select: select,
        };
    }

    public async doSearch(withLoadingStatus: boolean = true) {
        if (this.SuppressSearchEvents) return;
        this.resetPaging();
        try {
            await this.startSearch(withLoadingStatus);
            // this.markForCheck();
        } catch (err) {
            this.commonService.notificateError(err);
        }
    }

    // public markForCheck() {
    //     this.ref.markForCheck()
    // }

    private setLoading(loading: boolean) {
        Promise.resolve(null).then(() => {
            if (this.DisplayGlobalLoader) {
                if (loading) this.commonService.showGlobalLoader();
                else this.commonService.hideGlobalLoader();
            }

            this.loading = loading;
            this.ref.markForCheck();
        });
    }

    private async restoreSpecialColumnFilterData() {
        this.ColumnFilters.forEach(async (col) => {
            const filterColumn = col.column;
            const filterValue = col.value;
            if (filterColumn.Type === AviSearcherColumnType.LISTTYPE && !this.ListTypeFilters[col.column.Field]) {
                await col.column.BuildDropdownDatasource();
                const values = split(filterValue, ',');
                this.ListTypeFilters[col.column.Field] = col.column.DropdownDatasourceInternal.filter((w) =>
                    values.includes(w.value)
                );
            } else if (filterColumn.Type === AviSearcherColumnType.DATE && !this.DateFilters[col.column.Field]) {
                const values = split(filterValue, ',');
                this.DateFilters[col.column.Field] = values.map((w) => (w ? this.commonService.parseDate(w) : null));
            }
            else if (filterColumn.Type === AviSearcherColumnType.BOOLEAN && this.BooleanFilters[col.column.Field] === null) {

                let value: boolean = null;
                if (filterValue === "true")
                    value = true;
                else if (filterValue === "false")
                    value = false;
                this.BooleanFilters[col.column.Field] = value;
            }
        });
    }

    private async initSearchParameters() {
        this.restoreSettings();

        if (this.urlParams && this.UseRouterParams) {
            this.SearchValue = this.urlParams.search;
            this.Context = this.urlParams.context;
            let page: number = this.urlParams.page;
            if (!page || Number.isNaN(page)) page = 1;

            const firstVal = (page - 1) * this.SearcherRows;
            if (this.dt) {
                this.dt.first = firstVal;
            }
            this.PageRowStartIndex = firstVal;
            this.PageRowNumbers = this.SearcherRows;
            this.showInaktive = this.urlParams.inactive === '1' ? true : false;
            this.ExecuteSearch = this.ExecuteSearch || this.urlParams.do === '1' || this.urlParams.do === '2';

            const columnFilters = this.urlParams.cflt;
            this.FilterData = {};
            columnFilters.forEach((w) => {
                const valPair = w.split('=');
                this.FilterData[valPair[0]] = { value: valPair[1], matchMode: undefined };
            });

            this.CurrentFilterData = { ...this.FilterData };

            const columnSort = this.urlParams.csort;
            if (columnSort && columnSort.length > 0) {
                this.MultiSortMeta = [];
                columnSort.forEach((w) => {
                    const valPair = w.split('=');
                    this.MultiSortMeta.push({ field: valPair[0], order: Number(valPair[1]) });
                });
            } else this.MultiSortMeta = null;

            const parlist = this.urlParams.param;
            parlist.forEach((w) => {
                const valPair = w.split('=');
                const spar = this.SearcherParameters.filter((x) => x.Name === valPair[0])[0];
                if (spar) this.formFieldService.DeserializeValue(spar, valPair[1]);
            });
        }

        this.SortInfo = [];
        this.ColumnFilters = [];

        if (this.MultiSortMeta && this.MultiSortMeta.length > 0) {
            Object.keys(this.MultiSortMeta).forEach((w) => {
                this.SortInfo.push({
                    column: this._getColumn(this.MultiSortMeta[w].field, false),
                    order: this.MultiSortMeta[w].order,
                });
            });
        } else if (this.DefaultSortData) {
            Object.keys(this.DefaultSortData).forEach((w) => {
                this.SortInfo.push({
                    column: this._getColumn(this.DefaultSortData[w].field, false),
                    order: this.DefaultSortData[w].order,
                });
            });
        }

        Object.keys(this.FilterData).forEach((w) => {
            this.ColumnFilters.push({
                column: this._getColumn(w, false),
                value: (<FilterMetadata>this.FilterData[w]).value,
                matchmode: (<FilterMetadata>this.FilterData[w]).matchMode,
            });
        });

        await this.restoreSpecialColumnFilterData();
    }

    async startSearch(withLoadingStatus: boolean = true): Promise<any> {
        await this.initSearchParameters();

        // console.log("start search - withLoadingStatus:", withLoadingStatus);
        // this.markForCheck();
        this.setLoading(withLoadingStatus); // .... sonst gib's mal wieder ExpressionChangedAfterItHasBeenCheckedError

        // await this.commonService.sleep(2000);
        return new Promise((resolve, reject) => {
            try {
                this.SelectedRows = [];
                if (this.dt) this.dt.selectionKeys = {};

                if (this.SearchDelegate) {
                    if (typeof this.SearchDelegate === 'string') {
                        this.Find(this.buildSearchConfig(this.SearchDelegate as string))
                            .then((r) => {
                                resolve(this.handleSearchResult(r));
                            })
                            .catch((err) => {
                                this.setLoading(false);
                                this.onError.emit(err);
                                reject(err);
                            });
                    } else {
                        this.SearchDelegate(this.SearchValue, this.buildSearchConfigAsObject(), this)
                            .then((r) => {
                                resolve(this.handleSearchResult(r));
                            })
                            .catch((err) => {
                                this.setLoading(false);
                                this.onError.emit(err);
                                reject(err);
                            });
                    }
                } else {
                    this.setLoading(false);
                    resolve([]);
                }
            } catch (err) {
                this.setLoading(false);
                this.onError.emit(err);
                reject(err);
            }
        });
    }

    private updateDetailViewTemplate() {
        if (this.rowDetailViewTemplate) {
            if (this.SelectedRows.length > 0) {
                const id = this.getIdValue(this.SelectedRows[0]);

                if (this.ExpandedRowKeys[id]) {
                    this.ExpandedRowKeys[id] = false;
                } else {
                    this.ExpandedRowKeys = {};
                    this.ExpandedRowKeys[id] = true;
                }
            } else if (this.SelectedRows && !Array.isArray(this.SelectedRows)) {
                const id = this.getIdValue(this.SelectedRows);

                if (this.ExpandedRowKeys[id]) {
                    this.ExpandedRowKeys[id] = false;
                } else {
                    this.ExpandedRowKeys = {};
                    this.ExpandedRowKeys[id] = true;
                }
            } else this.ExpandedRowKeys = {};

            this.ref.markForCheck();
        }
    }

    getNumVisibleColumns() {
        let numcol = this.VisibleResponsiveColumns.length;
        if (this._selectedColumns.length < numcol) numcol = this._selectedColumns.length;
        numcol = numcol + (this.CheckboxSelection ? 1 : 0);
        numcol = numcol + (this.ShowExpansionButtons ? 1 : 0);
        return numcol;
    }

    private handleSearchResult(r): Promise<any> {
        return new Promise((resolve, reject) => {
            if (r && 'Results' in r) {
                this.dataSource.setData(r.Results);
                this.TotalRecords = r.RecordCount || r.TotalItemCount || 0;
                this.TotalRecordsUnfiltered = r.RecordCountUnfiltered;
            } else {
                this.dataSource.setData(r);
                this.TotalRecords = r ? this.Data.length : 0;
                this.TotalRecordsUnfiltered = null;
            }

            this.setLoading(false);
            this._initialSearchDone = true;
            resolve(this.Data);

            this.onDataLoaded.emit();

            const sel = this.Selection;
            if (sel && sel.length > 0) {
                const selRows = this.Data.filter((w) => sel.some((x) => x === this.getIdValue(w)));
                if (selRows.length > 0) {
                    this.SelectedRows = selRows;
                    this.onSelect.emit(this.SelectedRows);
                }
            }

            if (this.KeepSelection && this.KeepSelectedRows?.length > 0) {
                const curSelection = [...this.SelectedRows];
                this.SelectedRows = [...this.KeepSelectedRows];
                curSelection.forEach((w) => {
                    const idvalue = this.getIdValue(w);
                    if (!this.SelectedRows.some((x) => this.getIdValue(x) === idvalue)) this.SelectedRows.push(w);
                });

                // this.SelectedRows = [...this.SelectedRows, ...this.KeepSelectedRows];
                // this.SelectedRows = this.SelectedRows.filter((v, i, a) => a.map(w => this.getIdValue(w)).findIndex(w => w === this.getIdValue(v)) === i);
            }

            this.updateDetailViewTemplate();

            if (this.SelectedRows.length === 0 && this.SelectFirstResult && this.Data.length > 0) {
                if (this.SelectionMode == "multiple")
                    this.SelectedRows = [this.Data[0]];
                else
                    this.SelectedRows = this.Data[0];
                this.onSelect.emit(this.SelectedRows);
            }

            this.PageRowNumbers = this.SearcherRows;

            this.updateRowGroupMetaData();
            this.updateHeaderCheckboxCheckedState();

            setTimeout(() => this.recalcColumnFit());
        });
    }

    updateDataSource(sortData = false) {
        this.dataSource.resetFiltering();

        if (sortData)
            this.dataSource.sortData(this.dt.multiSortMeta);

        // Das ist notwendig, damit neu hinzugefügte Records angezeigt werden (v.a. wenn Sortierung aktiv ist).
        this.dt.value= this.Data;

        this.cdr.markForCheck();
    }

    public recalcColumnFit() {
        if (this.NoWordWrap) {
            const settingsKey = this.getSettingsKey();
            if (settingsKey) {
                const state = JSON.parse(localStorage.getItem(settingsKey));
                if (state) {
                    if (state.columnWidths && state.columnWidths.length > 0) {
                        const colWidths = state.columnWidths.replace(/, +/g, ',').split(',').map(Number);

                        let colSumme = colWidths.reduce((sum, current) => sum + current, 0);
                        const colAvg = colSumme / colWidths.length;

                        for (let i = 0; i < colWidths.length; ++i) {
                            if (colWidths[i] < 10) colWidths[i] = this.commonService.round(colAvg, 0);
                        }

                        colSumme = colWidths.reduce((sum, current) => sum + current, 0);

                        const factor = 100.0 / colSumme;
                        const scaledColWidths = colWidths.map((w) => this.commonService.round(w * factor, 0));

                        const scaledSum = scaledColWidths.reduce((sum, current) => sum + current, 0);
                        scaledColWidths[scaledColWidths.length - 1] += 100 - scaledSum;

                        if (this.CheckboxSelection) {
                            this._selectedColumns.forEach((w, index) => (w.Width = scaledColWidths[index + 1] + '%'));
                            this.CheckBoxSelectionWidth = scaledColWidths[0] + '%';
                        } else {
                            this._selectedColumns.forEach((w, index) => (w.Width = scaledColWidths[index] + '%'));
                            this.CheckBoxSelectionWidth = null;
                        }

                        // Hacky hackhack um resizen mit Prozenten zu ermöglichen mit Primeng 13
                        // Aus irgendeinem Grund haben sie das Resizen geändert mit !important, wodurch unsere Styles nicht funktionieren. Hier entfernen wir das wieder
                        if (this.dt?.styleElement) this.dt.styleElement.innerHTML = '';

                        this.ref.markForCheck();
                    }
                }
            }
        }
    }

    public _onStateSave(state: any) {
        this._onStateSaveInternal(state);
    }

    public async _onStateSaveInternal(state: any) {
        let modifyState = false;

        // Hack um dafür zu sorgen dass die Filters nur optional mitgespeichert wird
        // (default nicht - gibt Probleme weil Primeeng state.filters überschreibt und FilterData dann etwas anders ist...)
        if (!this.SaveStateFilter) {
            delete state.filters;
            modifyState = true;
        }

        // Hack um dafür zu sorgen dass die Selektion nur optional mitgespeichert wird (default nicht)
        if (!this.SaveStateSelection) {
            delete state.selection;
            modifyState = true;
        }

        // Hack um dafür zu sorgen dass die Sortierung optional mitgespeichert wird (default ja)
        if (!this.SaveStateSorting) {
            delete state.sortField;
            delete state.sortOrder;
            delete state.multiSortMeta;
        }

        // save parameters
        if (this.SaveStateParameters && this.SearcherParameters) {
            state.parameters = this.SearcherParameters.filter((w) => w.Name).map((w) => {
                return { Name: w.Name, Value: w.Value };
            });
        }

        // inactive
        if (this.ShowInactiveButton)
            state.inactive = this.showInaktive;

        // save selected columns
        const settingsKey = this.getSettingsKey();
        if (settingsKey) {
            state.columnSel = this._selectedColumns.map((w) => w.Field);
            localStorage.setItem(this.dt.stateKey, JSON.stringify(state));
            this.onStateSave.emit({ SettingsKey: settingsKey, State: state });
        } else if (modifyState) localStorage.setItem(this.dt.stateKey, JSON.stringify(state));

        // update selectableColumns nach drag&drop
        this._selectableColumns = this.VisibleColumns.filter((i) => i.Label && i.Label.trim() && !i.IsSystem);
        if (this.ReorderableColumns) {
            this._columnOrder = state.columnOrder;
            if (this._columnOrder)
                this._selectableColumns = this._selectableColumns.sort((a, b) =>
                    this._sortColumnsFunc(a, b, this._columnOrder)
                );
        }

        this.recalcColumnFit();

        this.onUpdateUserSetting.emit(state);
    }

    private async _onUpdateUserSetting(state) {
        const settingsKey = this.getSettingsKey();
        if (settingsKey) await this.authService.updateUserSetting(settingsKey, state);
    }

    public _onStateRestore(state: any) {}

    private deserializeParameter(parameter: AviFormField, value: string) {
        switch (parameter.SourceType) {
            case AviListDetailConst.ATTRTYPE_INTEGER:
                parameter.Value = value ? parseInt(value) : null;
                break;
            case AviListDetailConst.ATTRTYPE_DECIMAL:
                parameter.Value = value ? parseFloat(value) : null;
                break;
            case AviListDetailConst.ATTRTYPE_DATE:
                parameter.Value = value ? new Date(value) : null;
                break;
            case AviListDetailConst.ATTRTYPE_BOOL:
            case AviListDetailConst.ATTRTYPE_TEXT:
            case AviListDetailConst.ATTRTYPE_LISTTYPE:
            case AviListDetailConst.ATTRTYPE_REFERENCE:
                parameter.Value = value;
                break;
        }
    }

    public restoreSettings() {
        const settingsKey = this.getSettingsKey();
        if (settingsKey) {
            const state = JSON.parse(localStorage.getItem(settingsKey));

            // selected columns
            if (state && state.columnSel && this.ShowColumnSelection)
                this._selectedColumns = this.VisibleColumns.filter((col) => state.columnSel.includes(col.Field));

            if (!this.NoWordWrap) {
                if (state) {
                    if (state.columnWidths && state.columnWidths.length > 0) {
                        const colWidths = state.columnWidths.replace(/, +/g, ',').split(',').map(Number);
                        for (let i = 0; i < this._selectedColumns.length; ++i)
                            this._selectedColumns[i].Width = colWidths[i];
                    }
                }
            }

            // column order
            this._selectableColumns = this.VisibleColumns.filter((i) => i.Label && i.Label.trim() && !i.IsSystem);
            if (this.ReorderableColumns && state) {
                this._columnOrder = state.columnOrder;
                if (this._columnOrder) {
                    this._selectedColumns = this._selectedColumns.sort((a, b) =>
                        this._sortColumnsFunc(a, b, this._columnOrder)
                    );
                    this._selectableColumns = this._selectableColumns.sort((a, b) =>
                        this._sortColumnsFunc(a, b, this._columnOrder)
                    );
                }
            }

            // sort order
            if (state && state.multiSortMeta && !this._initialSearchDone)
                this.MultiSortMeta = state.multiSortMeta;

            // parameters
            if (this.SaveStateParameters && state && state.parameters && this.SearcherParameters) {
                for (var item of state.parameters) {
                    const parameter = this.SearcherParameters.find((w) => w.Name === item.Name);
                    if (parameter) this.deserializeParameter(parameter, item.Value);
                }
            }

            // inactive
            if (state && this.ShowInactiveButton)
                this.showInaktive = state.inactive;

            this.onStateRestore.emit({
                SettingsKey: settingsKey,
                State: state,
                InitialSearch: !this._initialSearchDone,
            });
        }
    }

    private _sortColumnsFunc(a: AviSearcherColumn, b: AviSearcherColumn, columnOrder: string[]) {
        var idx1 = columnOrder.findIndex((w) => w === a.Field);
        var idx2 = columnOrder.findIndex((w) => w === b.Field);

        /*        if (idx1 === -1 && idx2 >= 0) return 1;
        if (idx1 >= 0 && idx2 === -1) return -1;
        if (idx1 === -1 && idx2 === -1) return a.Label.localeCompare(b.Label);
        */
        if (idx1 === -1 || idx2 === -1)
            return (
                this.VisibleColumns.findIndex((w) => w.Field === a.Field) -
                this.VisibleColumns.findIndex((w) => w.Field === b.Field)
            );

        return idx1 - idx2;
    }

    private selectionChanged(): boolean {
        if (Array.isArray(this.Selection) && Array.isArray(this.SelectedRows)) {
            if (this.Selection.length !== this.SelectedRows.length) return true;

            for (const w of this.SelectedRows) {
                if (!this.Selection.some((x) => x === this.getIdValue(w))) return true;
                else return false;
            }
        }
        return false;
    }

    private handleSelectionChanged() {
        if (this.selectionChanged() && this.UseRouterParams) {
            this.updateQueryParams();
            this.updateHeaderCheckboxCheckedState();
        } else {
            this.updateDetailViewTemplate();
            this.updateHeaderCheckboxCheckedState();
        }
    }

    public _onRowSelect(event) {
        this.onSelect.emit(this.SelectedRows);
    }

    public _onRowUnselect(event) {
        this.onSelect.emit(this.SelectedRows);
    }

    public _onRowClick(data) {
        this.onClick.emit(data);
    }

    public _onDoubleClick(data) {
        setTimeout(() => this.onDoubleClick.emit(data), 200); // timeout damit double click nach evtl. router param Änderungen vom select kommt
    }

    public _onRowClickRowHeader(data) {
        this.onClickRowHeader.emit(data);
    }

    public _onDoubleClickRowHeader(data) {
        setTimeout(() => this.onDoubleClickRowHeader.emit(data), 200); // timeout damit double click nach evtl. router param Änderungen vom select kommt
    }

    public _onDoubleClickPrevent(event) {
        event.stopPropagation();
    }

    public startNew() {
        this.onStartNew.emit(null);
    }

    getContextMenuRow(col, record) {
        Object.create = function (o) {
            function ContextMenuObject() {}
            ContextMenuObject.prototype = o;
            return new ContextMenuObject();
        };

        // Wir brauchen manchmal den Column im ContextMenu Callback. Das geht nur als neue Property, da der Record auch verwendet wird um SelectedRows anzupassen.
        // Ansonsten haben wir dort ein Problem.
        const x = Object.create(record);

        // Sicherstellen dass unsere neue Property '__column' nicht als Property in JSON.stringify kommt (Problem in table.saveState)
        Object.defineProperty(x, '__column', { value: 'static', writable: true });
        x.__column = col;

        return x;
    }

    crudMenuItemsAdded = false;
    async _onShowContextMenu(e) {
        if (!this.MenuItems) this.MenuItems = [];

        if (this.AddCRUDMenuItems && !this.crudMenuItemsAdded) {
            if (this.CRUDCanCreate && this.CRUDEnableCreate)
                this.MenuItems.push({
                    label: this.translateService.instant('CORE.COMMON.BASESEARCHER_ACTION_NEW'),
                    icon: 'pi pi-plus',
                    command: () => this.createObject(),
                });

            if (this.CRUDCanEdit && this.CRUDEnableEdit)
                this.MenuItems.push({
                    label: this.translateService.instant('CORE.COMMON.BASESEARCHER_ACTION_EDIT'),
                    icon: 'pi pi-pencil',
                    styleClass: 'menuitem-context',
                    command: () => this.editObject(),
                });

            if (this.CRUDCanDelete && this.CRUDEnableDelete)
                this.MenuItems.push({
                    label: this.translateService.instant('CORE.COMMON.BASESEARCHER_ACTION_DELETE'),
                    icon: 'pi pi-trash',
                    styleClass: 'menuitem-context',
                    command: () => this.deleteObject(),
                });

            this.crudMenuItemsAdded = true;
        }

        this.MenuItems.filter((i) => i.styleClass === 'menuitem-context').forEach((w) => (w.visible = e.data != null));

        if (this.getContextMenuItems) this.MenuItems = [...this.getContextMenuItems(e.data, this.MenuItems)];
        else this.MenuItems = [...this.MenuItems];

        if (this.MenuItems.length === 0) this.dt.contextMenu.hide();
    }

    createObject() {
        this.onCreateObject.emit(null);
    }

    editObject() {
        this.onEditObject.emit(this.SelectedRows);
    }

    deleteObject() {
        this.onDeleteObject.emit(this.SelectedRows);
    }

    public addMenuItem(item: MenuItem) {
        if (!this.MenuItems) this.MenuItems = [];
        this.MenuItems.push(item);
    }

    public _getColumn(field: string, onOField: boolean): AviSearcherColumn {
        if (onOField) return this.Columns.filter((w) => w.OField === field)[0];
        else return this.Columns.filter((w) => w.Field === field)[0];
    }

    public _loadData(event) {
        // wenn filters verändert paging resetten
        if (JSON.stringify(this.CurrentFilterData) !== JSON.stringify(this.FilterData)) {
            this.resetPaging();
        }

        this.CurrentFilterData = { ...this.FilterData };

        this.MultiSortMeta = event.multiSortMeta;

        if (this._initialSearchDone || this.AutoSearch) {
            this.updateQueryParams();
        } else {
            // console.log('_loadData abbruch, weil !(_initialSearchDone || AutoSearch) ');
            this.setLoading(false);
        }
    }

    public _searchValueChanged(text: string) {
        if (this.AutoSearchOnType) this.searchValueChanged.next(text);
    }

    public _showInactiveValueChanged(text: any) {
        this.showInactiveValueChanged.next(text);
    }

    public _parameterValueChanged(text: any) {
        this.parameterValueChanged.next(text);
    }

    updateRowGroupMetaData() {
        this.rowGroupMetadata = {};
        if (this.Data && this.GroupField) {
            for (let i = 0; i < this.Data.length; i++) {
                const rowData = this.Data[i];
                const brand = rowData[this.GroupField];
                if (i === 0) {
                    this.rowGroupMetadata[brand] = { index: 0, size: 1, grpcnt: 1 };
                } else {
                    const previousRowData = this.Data[i - 1];
                    const previousRowGroup = previousRowData[this.GroupField];
                    if (brand === previousRowGroup) {
                        this.rowGroupMetadata[brand].size++;
                        this.rowGroupMetadata[brand].grpcnt++;
                    } else this.rowGroupMetadata[brand] = { index: i, size: i + 1, grpcnt: 1 };
                }

                this.Data[i].__grpindex = this.rowGroupMetadata[brand].grpcnt;
            }
        }
    }

    public click2Call(data) {
        this.communicationService.StartCall(data);
    }

    public click2Email(data) {
        this.communicationService.StartEmail(data);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.FocusSearchField();
            this.IsInitialized = true;
        });
    }

    ngOnDestroy() {
        this.searchValueChanged.unsubscribe();
        this.showInactiveValueChanged.unsubscribe();
        this.parameterValueChanged.unsubscribe();
        this.onSelect.unsubscribe();
        this.querySub.unsubscribe();
    }

    public updateQueryParams(setExecuteSearch: boolean = false, forceRefresh: boolean = false) {
        if (this.SuppressSearchEvents) return;

        if (setExecuteSearch || forceRefresh) this.ExecuteSearch = true;

        const queryParams: Params = {};

        if (forceRefresh && this.UseRouterParams) {
            if (this.urlParams.do === this.doValue) this.doValue = this.doValue === '1' ? '2' : '1';
        }

        if (this.ExecuteSearch) queryParams.do = this.doValue;

        if (this.SearchValue) queryParams.search = this.SearchValue;

        if (this.currentPage() > 1) queryParams.page = this.currentPage();

        if (this.showInaktive) queryParams.inactive = '1';

        if (this.FilterData && Object.keys(this.FilterData).length > 0)
            queryParams.cflt = Object.keys(this.FilterData).map(
                (w) => `${w}=${(<FilterMetadata>this.FilterData[w]).value}`
            );

        if (this.MultiSortMeta && this.MultiSortMeta.length > 0)
            queryParams.csort = this.MultiSortMeta.map((w) => `${w.field}=${w.order}`);

        if (this.SearcherParameters && this.SearcherParameters.length > 0)
            queryParams.param = this.SearcherParameters.filter(
                (w) => this.formFieldService.SerializeValue(w) !== undefined
            ).map((w) => `${w.Name}=${this.formFieldService.SerializeValue(w)}`);

        if (this.UseRouterParamsSelection && this.SelectedRows && this.SelectedRows.length > 0) {
            queryParams.sel = this.SelectedRows.map((w) => this.getIdValue(w));
        }

        if (this.Context) queryParams.context = this.Context;

        if (this.UseRouterParams) {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                replaceUrl: true,
            });
        } else {
            if (JSON.stringify(queryParams) !== JSON.stringify(this.urlParams) || forceRefresh) {
                this.startSearch();

                this.urlParams = {
                    search: queryParams.search,
                    page: queryParams.page,
                    inactive: queryParams.inactive,
                    do: queryParams.do,
                    cflt: queryParams.cflt,
                    csort: queryParams.csort,
                    param: queryParams.param,
                    context: queryParams.context,
                };
            }
        }
    }

    public toggleShowAdvanced() {
        if (this.ShowAdvanced) {
            this.AdvancedMode = !this.AdvancedMode;
            this.ref.markForCheck();
        }
    }

    public searchButtonPressed() {
        this.resetPaging();
        this.forceRefresh();
    }

    // public exportButtonPressed() {
    //     this.onExportClick.emit(this);
    // }

    public deselectAllAndForceRefresh() {
        this.SelectedRows = [];
        this.KeepSelectedRows = [];
        this.forceRefresh();
    }

    public forceRefresh() {
        if (this.IsInitialized) {
            this.SuppressSearchEvents = false;
            this.updateQueryParams(true, true);
        } else {
            setTimeout(() => {
                this.SuppressSearchEvents = false;
                this.updateQueryParams(true, true);
            }, 0);
        }
    }

    private _handleParamMapChanged(params: ParamMap) {
        if (!this.UseRouterParams) return;

        const oldUrlParams = { ...this.urlParams };

        this.urlParams = {
            search: params.get('search'),
            page: Number(params.get('page')),
            inactive: params.get('inactive'),
            do: params.get('do'),
            cflt: params.getAll('cflt'),
            csort: params.getAll('csort'),
            param: params.getAll('param'),
            context: params.get('context'),
        };

        const update = JSON.stringify(oldUrlParams) !== JSON.stringify(this.urlParams);

        this.Selection = params.getAll('sel');

        if (!this.SuppressSearchEvents) {
            // Wenn sich die Url Parameters verändern wird eine neue Suche ausgeführt
            // Wenn nur die Selektion verändert machen wir nichts (nur der Detail View wird aktualisiert)
            if (update) this.startSearch();
            else if (this.Selection && this.Selection.length > 0) {
                this.updateDetailViewTemplate();
                this.updateHeaderCheckboxCheckedState();
            }
        }
    }

    private _sortData(a: any, b: any): number {
        let sortValue: number = 0;

        this.MultiSortMeta.some((w) => {
            const column = this._getColumn(w.field, false);
            const field = column?.SortColumn ?? w.field;
            const order = w.order;

            const value1 = column?.FormatDelegate ? column.FormatDelegate(a) : ObjectUtils.getByPath(a, field); // Wenn ein Format-Delegate angegeben ist, soll dieser Wert als Sortierwert verwendet werden
            const value2 = column?.FormatDelegate ? column.FormatDelegate(b) : ObjectUtils.getByPath(b, field); // Wenn ein Format-Delegate angegeben ist, soll dieser Wert als Sortierwert verwendet werden

            if (value1 == null && value2 == null) {
                sortValue = 0;
                return true;
            } else if (value1 == null && value2 != null) {
                sortValue = -order;
                return true;
            } else if (value1 != null && value2 == null) {
                sortValue = order;
                return true;
            } else if (typeof value1 === 'string' || value1 instanceof String) {
                if (value1.localeCompare && value1 !== value2) {
                    sortValue = order * value1.localeCompare(value2);
                    return true;
                } else {
                    return false;
                }
            } else if (value1 instanceof ListType && value2 instanceof ListType) {
                sortValue = order * value1['Bezeichnung'].localeCompare(value2['Bezeichung']);
                return true;
            } else if (value1 < value2) {
                sortValue = -order;
                return true;
            } else if (value1 > value2) {
                sortValue = order;
                return true;
            } else {
                return false;
            }
        });

        return sortValue;
    }

    public getColumnFiltersMapped() {
        const res = this.ColumnFilters.map((w) => {
            if (Array.isArray(w.value)) {
                if (w.column.Type !== AviSearcherColumnType.DATE)
                    return { column: w.column, value: w.value.map((x) => this._getFilterValue(w.column, x)).join(',') };
                else return { column: w.column, value: w.value.join(',') };
            } else return w;
        });

        return res;
    }

    private _filterData(data) {
        let localMatch: boolean = true;

        this.ColumnFilters.some((w) => {
            if (w.column.Type === AviSearcherColumnType.DATE) {
                const dateValue = this.commonService.getDate00(new Date(data[w.column.OField]));
                if (dateValue) {
                    if (Array.isArray(w.value) && w.value.length === 2 && w.value[1] != null && w.value[1] !== '') {
                        const valueRangeValueStart = this._getFilterValue(w.column, w.value[0]);
                        const valueRangeValueEnd = this._getFilterValue(w.column, w.value[1]);

                        const dateStart = valueRangeValueStart
                            ? this.commonService.parseDate(valueRangeValueStart)
                            : null;
                        const dateEnd = valueRangeValueEnd ? this.commonService.parseDate(valueRangeValueEnd) : null;

                        if (dateStart && dateEnd && !(dateStart <= dateValue && dateEnd >= dateValue))
                            localMatch = false;
                    } else {
                        const valueRangeValueStart = this._getFilterValue(w.column, w.value[0]);
                        const dateStart = valueRangeValueStart
                            ? this.commonService.parseDate(valueRangeValueStart)
                            : null;
                        if (dateStart && dateStart.getTime() !== dateValue.getTime()) localMatch = false;
                    }
                }
            } else if (w.column.Type === AviSearcherColumnType.LISTTYPE) {
                if (Array.isArray(w.value)) {
                    const filterFieldSplit = w.column.OField.split('.');
                    let res: boolean = false;
                    w.value.forEach((rv, index) => {
                        if (
                            filterFieldSplit.length > 1 &&
                            filterFieldSplit[0] !== null &&
                            filterFieldSplit[1] !== null
                        ) {
                            if (rv === data[filterFieldSplit[0]][filterFieldSplit[1]]) {
                                res = true;
                            }
                        } else if (filterFieldSplit.length > 0 && filterFieldSplit[0] !== null) {
                            const val = data[filterFieldSplit[0]];
                            if (val instanceof ListType && rv === val['Bezeichnung']) {
                                res = true;
                            }
                        }
                    });

                    if (!res) localMatch = false;
                }
            } 
            else if (w.column.Type === AviSearcherColumnType.BOOLEAN) {
                if (w.value !== null) {
                    let booleanValue = data[w.column.OField] as boolean;
                    let filterBooleanValue = w.value === "true";
                    
                    localMatch = booleanValue === filterBooleanValue;
                }
            }
            else {
                const matchmode = w.matchmode || 'contains';
                const filterConstraint = FilterUtils[matchmode];

                if (!data[w.column.OField] || !filterConstraint(data[w.column.OField], w.value)) localMatch = false;
            }

            if (!localMatch) {
                return true;
            } else {
                return false;
            }
        });

        return localMatch;
    }

    public ApplyColumnSorting(res: any): any {
        if (this.MultiSortMeta && this.MultiSortMeta.length > 0 && res?.length > 0) {
            res = res.sort((a, b) => this._sortData(a, b));
        }

        return res;
    }

    public ApplyColumnFilters(res: any): any {
        if (this.ColumnFilters && this.ColumnFilters.length > 0) {
            if (Array.isArray(res)) {
                res = res.filter((data) => this._filterData(data));
            } else {
                res.Results = res.Results.filter((data) => this._filterData(data));
            }
        }

        return res;
    }

    public initColumns() {
        this._selectedColumns = this.VisibleColumns;
    }

    public beginInit() {
        this.SuppressSearchEvents = true;
    }

    public endInit() {
        this.initColumns();

        if (this.ShowAutofilter && !this.Columns.some((w) => w.Filterable)) this.ShowAutofilter = false;

        this.SuppressSearchEvents = false;

        this.ExecuteSearch =
            this.AutoSearch ||
            this.ExecuteSearch ||
            (this.urlParams && (this.urlParams.do === '1' || this.urlParams.do === '2'));
        if (this.ExecuteSearch) {
            // Autosearch bei endInit aber noch kein do=1 in Url -> parameters anpassen
            // Ansonsten Suche ausführen
            if (this.AutoSearch && !(this.urlParams && (this.urlParams.do === '1' || this.urlParams.do === '2'))) {
                this.updateQueryParams();
            } else this.startSearch();
        } else {
            setTimeout(() => this.recalcColumnFit());
        }
    }

    public getInaktivLabel() {
        if (this.InaktivLabel) return this.InaktivLabel;

        if (
            (this.FieldNamesGueltigAb && this.FieldNamesGueltigAb.length > 0) ||
            (this.FieldNamesGueltigBis && this.FieldNamesGueltigBis.length > 0)
        )
            return 'inaktiv/ungültig';

        return 'inaktiv';
    }

    public fillSplitButtonItems(btn: SplitButton) {
        btn.model = this.getSplitButtonItems();
    }

    public getSplitButtonItems() {
        const items: MenuItem[] = [];

        // items.push({ label: 'Spaltenauswahl', icon: this.SelectColumns ? 'ui-icon-check-box' : 'ui-icon-check-box-outline-blank', command: (data) => { this.SelectColumns = !this.SelectColumns; data.originalEvent.stopPropagation(); } });

        if (this.ExportableCSV)
            items.push({ label: 'Export CSV', icon: 'pi pi-download', command: () => this.exportCSV() });

        items.push(...this.splitButtonItems);

        return items;
    }

    public hasEmptySearchresult() {
        return (
            this._initialSearchDone &&
            (!this.TotalRecords || this.TotalRecords === 0) &&
            (!this.ColumnFilters || this.ColumnFilters.length === 0)
        );
    }

    // Header checkbox mit KeepSelection - BEGIN
    async onHeaderCheckboxClick(event: Event) {
        if (!this.isHeaderCheckboxDisabled()) {
            if (this.dt.value && this.dt.value.length > 0) {
                if (this.dt.dataKey && this.Data?.length > 0) {
                    if (this.SelectAllDelegate) {
                        if (this.headerCheckboxChecked) this.SelectedRows = [];
                        else
                            this.SelectedRows = await this.SelectAllDelegate(
                                this.SearchValue,
                                this.buildSearchConfigAsObject(),
                                this
                            );

                        if (this.KeepSelection) this.KeepSelectedRows = this.SelectedRows;
                    } else {
                        if (this.KeepSelection) this.KeepSelectedRows = this.SelectedRows;

                        if (!this.KeepSelectedRows) {
                            this.SelectedRows = [];
                            this.dt.selectionKeys = {};
                        }

                        this.SelectedRows = [...this.Data, ...this.KeepSelectedRows];
                        if (this.headerCheckboxChecked)
                            this.SelectedRows = this.SelectedRows.filter(
                                (x) =>
                                    this.Data.map((w) => this.getIdValue(w)).findIndex(
                                        (w) => w === this.getIdValue(x)
                                    ) < 0
                            );

                        this.SelectedRows = this.SelectedRows.filter(
                            (v, i, a) =>
                                a.map((w) => this.getIdValue(w)).findIndex((w) => w === this.getIdValue(v)) === i
                        );
                    }

                    this.onSelect.emit(this.SelectedRows);

                    this.updateHeaderCheckboxCheckedState();

                    setTimeout(() => {
                        this.dt.updateSelectionKeys();
                        this.dt.tableService.onSelectionChange();
                    });
                }
            }
        }
    }

    isHeaderCheckboxDisabled() {
        return /* this.disabled || */ !!!this.dt || !this.dt.value || !this.dt.value.length;
    }

    updateHeaderCheckboxCheckedState() {
        if (this.LazyLoading && this.KeepSelection) {
            if (this.Data && this.SelectedRows) {
                const val = this.Data;
                const length = this.Data?.length;
                const diff = this.Data.filter(
                    (x) =>
                        this.SelectedRows.map((w) => this.getIdValue(w)).findIndex((w) => w === this.getIdValue(x)) >= 0
                );
                this.headerCheckboxChecked = val && length > 0 && diff && diff.length > 0 && diff.length === length;
                this.ref.markForCheck();
            } else {
                this.headerCheckboxChecked = false;
                this.ref.markForCheck();
            }
        }
    }
    // Header checkbox mit KeepSelection - END

    public ResetSelection() {
        this.KeepSelectedRows = [];
        this.Selection = [];
    }

    getExportHeader(column) {
        return this.translateInstant(column.Label) || this.translateInstant(column.Field);
    }

    public async exportCSV() {
        let r;
        const rows = this.SearcherRows;
        this.SearcherRows = -1;
        if (this.SearchDelegate) {
            if (typeof this.SearchDelegate === 'string')
                r = await this.Find(this.buildSearchConfig(this.SearchDelegate as string));
            else r = await this.SearchDelegate(this.SearchValue, this.buildSearchConfigAsObject(), this);
        }
        this.SearcherRows = rows;

        const data = r && 'Results' in r ? r.Results : r;
        const columns = this.dt.columns;
        let csv = '';

        // headers
        for (let i = 0; i < columns.length; i++) {
            let column = columns[i];
            if (
                column.Type !== AviSearcherColumnType.BUTTON &&
                column.Type !== AviSearcherColumnType.SPLITBUTTON &&
                column.Field
            ) {
                csv += '"' + this.getExportHeader(column) + '"';

                if (i < columns.length - 1) csv += this.csvSeparator;
            }
        }

        // body
        data.forEach((record, i) => {
            csv += '\n';
            for (let i = 0; i < columns.length; i++) {
                let column = columns[i];
                if (
                    column.Type !== AviSearcherColumnType.BUTTON &&
                    column.Type !== AviSearcherColumnType.SPLITBUTTON &&
                    column.Field
                ) {
                    let cellData = this.getFormattedValue(record, column);

                    if (cellData != null) {
                        if (this.dt.exportFunction) {
                            cellData = this.dt.exportFunction({
                                data: cellData,
                                field: column.Field,
                            });
                        } else cellData = String(cellData).replace(/"/g, '""');
                    } else cellData = '';

                    csv += '"' + cellData + '"';

                    if (i < columns.length - 1) csv += this.csvSeparator;
                }
            }
        });

        const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' });
        this.fileSaverService.save(blob, this.exportFilename + '.csv');
    }

    getFormattedValue(record: any, c: AviSearcherColumn) {
        let res = record[c.Field] || '';

        switch (c.Type) {
            case AviSearcherColumnType.DATE:
                res = this.datePipe.transform(record[c.Field] || '', c.DateFormat);
                break;
            case AviSearcherColumnType.DECIMAL:
                res = this.percentPipe.transform(
                    record[c.Field] || '',
                    '1.' + c.MinNachkommastellen + '-' + c.MaxNachkommastellen
                );
                break;
            case AviSearcherColumnType.PERCENT:
                res = this.percentPipe.transform(
                    (record[c.Field] || '') / 100,
                    '1.' + c.MinNachkommastellen + '-' + c.MaxNachkommastellen
                );
                break;
            case AviSearcherColumnType.LISTTYPE:
                res = c.FormatDelegate ? c.stripHtmlTags(c.FormatCustom(record)) : record[c.Field]?.Bezeichnung;
                break;
            case AviSearcherColumnType.CUSTOM:
                res = c.stripHtmlTags(c.FormatCustom(record));
                break;
        }

        return res;
    }

    initHotkeys() {
        this.commonService.addHotkey('f3', () => this.FocusSearchField(), 'Suchfeld-Fokus');
        this.commonService.addHotkey('up', () => this.selectPrev());
        this.commonService.addHotkey('down', () => this.selectNext());
        this.commonService.addHotkey('left', () => this.selectPrevPage(), 'Vorherige Seite');
        this.commonService.addHotkey('right', () => this.selectNextPage(), 'Nächste Seite');
        this.commonService.addHotkey(
            ['enter', 'return'],
            () => {
                if (this.SelectedRows.length > 0) {
                    this.onDoubleClick.emit(this.SelectedRows[0]);
                }
            },
            'Ausgewählte Zeile öffnen',
            false,
            false
        );
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.SettingsKey) {
            const settingsKey = this.getSettingsKey();
            if (settingsKey) {
                const state = this.authService.getUserSetting(settingsKey);
                if (state) localStorage.setItem(settingsKey, JSON.stringify(state));
            }
        }
    }

    ngOnInit() {
        this.initHotkeys();
        this.ResetSelection();

        this.querySub = this.activatedRoute.queryParamMap.subscribe((params) => {
            this._handleParamMapChanged(params);
        });

        this.ExpandedRowKeys = {};

        if (this.AutoSearchOnType) {
            this.searchValueChanged
                .pipe(debounceTime(500), distinctUntilChanged())
                .subscribe((model) => this.updateQueryParams(true));
        }

        this.onSelect
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe((model) => this.handleSelectionChanged());

        this.onUpdateUserSetting
            .pipe(debounceTime(100))
            .subscribe(async (state) => await this._onUpdateUserSetting(state));

        this.showInactiveValueChanged
            .pipe(debounceTime(250), distinctUntilChanged())
            .subscribe((model) => {
                this.dt.saveState();
                this.updateQueryParams(true);
            });

        this.parameterValueChanged
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                filter((changes) => this.parameterForm.isFormValid())
            )
            .subscribe((model) => {
                this.resetPaging();
                this.updateQueryParams();
            });

        if (this.RowGroupFooterDelegate || this.RowGroupHeaderDelegate) this.RowGroupMode = 'subheader';

        // this.splitButtonItems.push({ label: 'Neuer Eintrag', icon: 'pi pi-plus', command: () => { this.startNew(); } });

        if (this.AutoSearch || !this.ShowToolbar) {
            if (!this.SuppressSearchEvents) this.updateQueryParams(true);
        }

        this.updateHeaderCheckboxCheckedState();

        // Hide autofilter wenn Responsive...
        if (this.responsiveLayout === 'stack' && !this.Scrollable) {
            var responsiveStyleElement = document.createElement('style');
            responsiveStyleElement.type = 'text/css';
            document.head.appendChild(responsiveStyleElement);

            let innerHTML = `
@media screen and (max-width: ${this.breakpoint}) {
    .searcher-filter-row {
        display: none !important;
    }`;

            responsiveStyleElement.innerHTML = innerHTML;
        }

        setTimeout(() => this.onInit.emit(this));
    }

    enableDroppable() {
        this.droppableDisabled = false;
        this.droppableDirective.bindDragOverListener();
    }

    dragStart(row: any) {
        if (!this.dragDropHandler)
            return;
        if (this.SelectedRows.includes(row))
            this.dragDropHandler.dragStart(this, this.SelectedRows);
        else
            this.dragDropHandler.dragStart(this, [row]);
    }

    dragEnd() {
        if (!this.dragDropHandler)
            return;

        this.dragDropHandler.dragEnd(this);
    }

    drop() {
        if (!this.dragDropHandler)
            return;

        this.dragDropHandler.drop(this);
    }
}

@Component({
    selector: 'avi-core-sorticon',
    template: `
        <i class="ui-sortable-column-icon pi pi-fw" [ngClass]="{'pi-sort-up': sortOrder === 1, 'pi-sort-down': sortOrder === -1, 'pi-sort': sortOrder === 0}"></i>
    `
})
export class AviSortIconComponent implements OnInit, OnDestroy {
    @Input() field: string;

    subscription: Subscription;

    sortOrder: number;

    constructor(public dt: Table) {
        this.subscription = this.dt.tableService.sortSource$.subscribe(sortMeta => {
            this.updateSortState();
        });
    }

    ngOnInit() {
        this.updateSortState();
    }

    onClick(event) {
        event.preventDefault();
    }

    updateSortState() {
        if (this.dt.sortMode === 'single') {
            this.sortOrder = this.dt.isSorted(this.field) ? this.dt.sortOrder : 0;
        } else if (this.dt.sortMode === 'multiple') {
            const sortMeta = this.dt.getSortMeta(this.field);
            this.sortOrder = sortMeta ? sortMeta.order : 0;
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
