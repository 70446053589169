
import { Expose } from 'class-transformer';
import TransformDate from '../shared/transformdate';
import TransformNullableString from '../shared/transformnullablestring';

export class TextblockDto {
    @Expose()
    public Id: string;

    @Expose()
    public Identifier: string;

    @Expose()
    @TransformDate()
    public GueltigAb: Date;

    @Expose()
    @TransformDate()
    public GueltigBis: Date;
    
    @Expose()
    public BezeichnungDe: string;

    @Expose()
    @TransformNullableString()
    public TextDe: string;

    @Expose()
    public BezeichnungEn: string;

    @Expose()
    @TransformNullableString()
    public TextEn: string;
}
