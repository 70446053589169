import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AviApiService } from '../../../services/api.service';
import { AviApiErrorObject, AviBaseFormComponent, AviChangedAttr, AviCommonService, AviFormField, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { SerializationDto } from '@avi-x/avi-dto/serialization/serialization.model';
import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';

const nameof = (nameFunction: ((obj: SerializationDto) => any)) => typed_nameof<SerializationDto>(nameFunction);

@Component({
    selector: 'avi-core-serialization-import',
    templateUrl: './serialization-import.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviSerializationImportComponent implements OnInit {

    public Loading: boolean = false;

    @Input('api-prefix')
    public ApiPrefix: string = '';

    private _SelectedSerialization: ReferenceDto;

    public get SelectedSerialization() {
        return this._SelectedSerialization;
    }

    public set SelectedSerialization(value) {
        this._SelectedSerialization = value;
    }

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    public SaveButtonLabel: string = 'Importieren';

    public Model: SerializationDto = new SerializationDto();

    public File: File = null;

    public Title: string = 'Serialization Import';

    public LogTotalCreated: string = null;
    public LogTotalUpdated: string = null;

    public Fields: AviFormField[] = [];
   
    public Card = true;

    public FormTitle: string = 'Model Serialization';

    constructor(
        private apiService: AviApiService,
        private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef
    ) { 
    }


    async ngOnInit() {
        if ('apiprefix' in this.activatedRoute.snapshot.data)
            this.ApiPrefix = this.activatedRoute.snapshot.data['apiprefix'];

        this.initFields();

		await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Type), 'Typ', `${this.ApiPrefix ?? ''}/modelserialization/serializations`, true, true, 'Typ wählen...', (m) => (`${m.Representation}`), (m) => m, true, false, 'contains').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Filename), 'Dateiname').setRequired(true).setMDSize(9).setReadonly());
        this.Fields.push(this.formFieldService.CreateTemplate(1, ' ').setLabelVisible(true).setLabelClass('inline-block').setMDSize(3));
    }

    async onAttrChange(data: AviChangedAttr) {
        this.LogTotalCreated = null;
        this.LogTotalUpdated = null;
    }

    async onSelect(file) {
        if (file) {
            this.File = file;
            this.Model.Filename = file.name.replace(/\.[^\/\\.]+$/, '');
        }

        this.LogTotalCreated = null;
        this.LogTotalUpdated = null;
    }

    saveModel() {
        this.Form.clearFormMessages();

        this.Loading = true;

        let delegate: Promise<any> = this.apiService.postFileWithData(`${this.ApiPrefix ?? ''}/modelserialization/import`, this.File, this.Model);

        delegate.then(r => {
            this.commonService.notificateSuccess('Erfolgreich importiert');
            this.Model.Type = null;
            this.Model.Filename = null;
            this.File = null;
            
            this.LogTotalCreated = r.TotalCreated;
            this.LogTotalUpdated = r.TotalUpdated;

            this.Loading = false;
            this.cdr.detectChanges();
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
            this.Loading = false;
            
            this.cdr.detectChanges();
        });
    }

    ValidateData = () => {
        let valid = true;

        if(!this.File) {
            valid = false;
        }

        return valid;
    }

    getLogLabel(value: string): string {
        return value.split(',')[0];
    }
}
