import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviApiService, AviCommonService, AviJobService, AviListDetailConst, InputmaskService } from '@avi-x/avi-core';
import { VwbPInternSchwaerzenService } from '../../services/schwaerzen.service';
import { VwbPInternTaskBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-tasks/task-base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';

@Component({
    selector: 'vwbp-intern-schwaerzen-antrag-gueltigkeit-setzen-task',
    template: `
      <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
            {{ 'VWBP.INTERN.SCHWAERZEN.ANTRAG_KONTROLLIEREN_BUTTON' | translate}}
        </div>
        <div class="grid form-group p-fluid">
            <div class="col-12 lg:col-12">
                <span class="p-float-label mb-4p">
                    <p-calendar #calendar2 id="cal2" appendTo="body" [baseZIndex]="10000" [firstDayOfWeek]="1" (onInput)="onInputDate($event, calendar2)" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [(ngModel)]="Datum" [selectOtherMonths]="true" dateFormat="dd.mm.yy" [readonlyInput]="false" [showOnFocus]="false" [showIcon]="true"></p-calendar>
                    <label for="cal2">{{ 'VWBP.INTERN.SCHWAERZEN.GUELTIGBIS' | translate }}*</label>
                </span>

                <span class="p-float-label mb-4p">
                    <p-dropdown id="dd" [options]="FristablaufGrundOptions" [autoDisplayFirst]="false" [required]="false" [filter]="false" name="dd" [(ngModel)]="FristablaufGrund"></p-dropdown>
                    <label for="dd">{{ 'VWBP.INTERN.SCHWAERZEN.GRUND_FRISTABLAUF' | translate }}*</label>
                </span>

                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ 'VWBP.INTERN.SCHWAERZEN.ANTRAG_GUELTIGKEIT_SETZEN_KOMMENTAR' | translate }}*</label>
                </span>
            </div>
            <div *ngIf="Dialog && HasDoku" class="p-col-12 p-lg-12">
                <p-checkbox [(ngModel)]="DocuGenerieren" label="{{ 'VWBP.INTERN.DOKUMENTE.GENERIEREN' | translate }}" name="doc" [binary]="true"></p-checkbox>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-clock" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-primary"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternSchwaerzenAntragGueltigkeitSetzenTaskComponent extends VwbPInternTaskBaseComponent {
    
    @Input('taskId')
    public TaskId: string;
    
    FristablaufGrund: ListType = null;
    FristablaufGrundOptions: any[];

    constructor(public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public schwaerzenService: VwbPInternSchwaerzenService, public inputMaskService: InputmaskService, public dialogService: DialogService, public postfachService: VwbPInternPostfachService, public jobService: AviJobService, public cdr: ChangeDetectorRef) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);

        this.initFristablaufGrund();
    }

    ngOnInit() {
        if (this.TaskId)
            this.InitDoku(this.TaskId);
    }

    async initFristablaufGrund() {
        const ret = [];
        const p = await this.apiService.get(`v1/vwbp/model/representations/list/vwbp_schwaerzen_antrag_fristablauf`);
        p.filter(w => w.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_FRISTABLAUF_NONE &&
            w.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_FRISTABLAUF_WB_NICHT_MEHR_VORHANDEN &&
            w.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_FRISTABLAUF_RT_GELOESCHT).forEach(elem => {
            ret.push({ label: elem.Representation, value: new ListType(elem.Id, 'vwbp_schwaerzen_antrag_fristablauf', elem.Representation), valueFull: elem });
        });

        this.FristablaufGrundOptions = ret;
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + this.commonService.translateInstant('VWBP.INTERN.SCHWAERZEN.ANTRAG_GUELTIGKEIT_SETZEN_BUTTON');
    }

    CanExecute(): boolean {
        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung && !!this.Datum && this.FristablaufGrund != null;
    }

    async Execute() {
        this.PerformAction('VWBP.INTERN.SCHWAERZEN.ANTRAG_GUELTIGKEIT_SETZEN_BUTTON', this.GetResourceName('VWBP.INTERN.SCHWAERZEN.ANTRAG_GUELTIGKEIT_SETZEN_BESTAETIGEN'), 'VWBP.INTERN.SCHWAERZEN.ANTRAG_GUELTIGKEIT_SETZEN_BUTTON',
            async (selectedRT) => {
                const jobId: string = await this.schwaerzenService.SetSchwaerzungGueltigkeit(selectedRT.map(w => w.AntragId), this.FristablaufGrund.Id, this.Datum, this.Bemerkung, this.DocuGenerieren);
                if (jobId) {
                    const jobStatus = await this.jobService.OpenJobDialog(jobId, 'VWBP.INTERN.SCHWAERZEN.ANTRAG_GUELTIGKEIT_SETZEN_BUTTON', 'v1/intern/vwbp/job', false);
                    if (jobStatus !== AviListDetailConst.JOB_STATUS_SUCCEEDED)
                        return;
                }
                if (this.HasDoku && this.DocuGenerieren)
                    this.postfachService.OpenPostfach();
            }
        );
    }
}
