<div
    *ngIf="Model"
    class="comment ui-fluid ui-g-nopad"
    [ngClass]="{ 'comment-erledigt': this.CommentErledigt, 'comment-edit': !isReadOnly() }"
>
    <div [ngClass]="{ 'comment-wrapper': isReadOnly() }">
        <button
            *ngIf="isReadOnly()"
            pButton
            [icon]="CommentCollapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-down'"
            class="comment-collapse-button p-button-secondary p-button-rounded p-button-text"
            (click)="toggleCollapsed()"
        ></button>

        <div class="comment-container">
            <div *ngIf="!isNewComment()" class="comment-header-container">
                <span class="comment-header">{{ this.Model.ErstelltVon }} </span>
                <span class="comment-subheader truncate margin-right-auto">{{ getCommentHeader() }}</span>

                <div class="comment-tags">
                    <ng-container *ngIf="isReadOnly()">
                        <div class="tag-item tag-item-value" *ngFor="let option of SelectedKeywords">
                            <div>{{ option.name }}</div>
                        </div>
                    </ng-container>
                </div>

                <ng-container *ngIf="isReadOnly() && !Readonly">
                    <ng-container *ngIf="!CommentErledigt">
                        <button
                            pButton
                            icon="pi pi-pencil"
                            class="p-button-secondary p-button-rounded p-button-text p-button-icon-only"
                            aviThrottledClick
                            (throttledClick)="EditComment()"
                        ></button>
                        <button
                            pButton
                            icon="pi pi-trash"
                            class="p-button-secondary p-button-rounded p-button-text p-button-icon-only"
                            aviThrottledClick
                            (throttledClick)="DeleteComment()"
                        ></button>
                    </ng-container>
                    <p-checkbox
                        class="pl-2"
                        [ngModel]="CommentErledigt"
                        (ngModelChange)="SetCommentErledigt($event)"
                        label="Erledigt"
                        [binary]="true"
                    ></p-checkbox>
                </ng-container>
            </div>

            <div
                *ngIf="!isReadOnly() || !CommentCollapsed"
                [ngClass]="{ 'comment-content': true, 'ui-g-nopad': true, 'mt-2': !Model.IsRTF }"
            >
                <div *ngIf="isReadOnly()" [innerText]="Model.Comment"></div>

                <textarea
                    *ngIf="!isReadOnly()"
                    [rows]="4"
                    id="Comment"
                    [readonly]="isReadOnly()"
                    [ngClass]="{ 'nobackground-image': isReadOnly(), 'w-100p': true }"
                    pInputTextarea
                    class="inputtextarea-with-min-height"
                    [(ngModel)]="Model.Comment"
                    [autoResize]="true"
                    name="Comment"
                    attr.name="Comment"
                >
                </textarea>
            </div>
        </div>
    </div>

    <div *ngIf="!isReadOnly()" class="pl-0">
        <div class="comment-keyword-wrapper">
            <p-checkbox
                *ngIf="isVersionEnabled()"
                class="pr-32p"
                [(ngModel)]="IsVersion"
                label="Bemerkung gilt nur für diese Version"
                [binary]="true"
            ></p-checkbox>
            <label class="pr-2">Keywords:</label>
            <p-multiSelect
                appendTo="body"
                scrollHeight="400px"
                [options]="Keywords"
                [(ngModel)]="SelectedKeywords"
                defaultLabel="Selektiere Keywords"
                optionLabel="name"
            >
                <ng-template let-value pTemplate="selectedItems">
                    <div class="tag-item tag-item-value" *ngFor="let option of SelectedKeywords">
                        <div>{{ option.name }}</div>
                    </div>
                    <div *ngIf="!SelectedKeywords || SelectedKeywords.length === 0" class="tag-placeholder">
                        Selektiere Keywords
                    </div>
                </ng-template>
            </p-multiSelect>
        </div>

        <div class="pt-2">
            <button
                pButton
                icon="pi pi-save"
                type="submit"
                style="width: auto;"
                class="mr-2 p-button-primary"
                aviThrottledClick
                (throttledClick)="SaveComment()"
                [label]="'CORE.COMMON.SPEICHERN_BUTTON' | translate"
                [title]="'CORE.COMMON.SPEICHERN_BUTTON' | translate"
            ></button>
            <button
                pButton
                type="button"
                style="width: auto;"
                class="mr-2 p-button-secondary p-button-outlined"
                aviThrottledClick
                (throttledClick)="CancelComment()"
                id="rteCancel"
                [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
                [title]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
            ></button>
        </div>
    </div>
</div>
