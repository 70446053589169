import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiService, AviCommonService, AviDialogComponent } from '@avi-x/avi-core';
import { AviBaseSearcherComponent } from '@avi-x/avi-core';
import { TranslateService } from '@ngx-translate/core';
import { AviLandFormComponent } from '../land-form/land-form.component';

@Component({
    selector: 'avi-crm-land-searcher',
    templateUrl: './land-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviLandSearcherComponent {

    @ViewChild('formDialog', { static: false })
    formDialog: AviDialogComponent;

    @ViewChild('form', { static: false })
    public form: AviLandFormComponent;

    FormModelId: string = null;
    formActionButtons: any = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.closeForm(); } }];

    public PageTitle: string = 'LandSearcher';

    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    constructor( private commonService: AviCommonService,
                 private route: ActivatedRoute,
                 private router: Router,
                 private apiService: AviApiService,
                 private translateService: TranslateService
    ) { }

    public onDoubleClick(data) {
        this.router.navigate(['/crm/land/form', data.id]);
    }

    public onInit(data) {
        this.searcher.beginInit();
        this.searcher.addTextColumn('ISOCode', 'ISOCode', null, '10%');
        this.searcher.addTextColumn('Bezeichnung1', 'Name');
        this.searcher.addTextColumn('KfzCode', 'KfzCode', null, '10%');

        // this.searcher.addMenuItem({ label: 'Neuer Eintrag', icon: 'pi pi-plus', command: () =>  this.createObject() });
        this.searcher.addMenuItem({ label: this.translateService.instant('CORE.COMMON.BASESEARCHER_ACTION_EDIT'), icon: 'pi pi-pencil', command: () => this.editObject() });
        this.searcher.addMenuItem({ label: this.translateService.instant('CORE.COMMON.BASESEARCHER_ACTION_DELETE'), icon: 'pi pi-trash', command: () => this.deleteObject() });
        this.searcher.endInit();
    }
    
    // Form Dialog
    public createObject() {
        this.FormModelId = '0';
        this.formDialog.open();
    }

    public editObject() {
        if (this.searcher.SelectedRows.length > 0) {
            this.FormModelId = this.searcher.SelectedRows[0].Id;
            this.formDialog.open();
        }
    }

    public deleteObject() {
        this.apiService.deleteObject('land', this.searcher.SelectedRows[0]?.Id, () => this.searcher.forceRefresh());
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.searcher.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.formDialog.close();
    }
}
