import { Component, Input, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService, AviBaseSearcherComponent, AviSearcherColumn, AviDialogComponent, AviAuthService, typed_nameof } from '@avi-x/avi-core';
import { AviVerlaufFormComponent } from './verlauf-form.component';

import { AttrType, VerlaufDto } from '@avi-x/avi-dto/system/verlauf.model';

const nameof = (nameFunction: ((obj: VerlaufDto) => any)) => typed_nameof<VerlaufDto>(nameFunction);

@Component({
    selector: 'avi-verlauf-searcher',
    templateUrl: './verlauf-searcher.component.html',
    styleUrls: ['./verlauf-searcher.component.scss'],
    providers: [DecimalPipe, PercentPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviVerlaufSearcherComponent {
    SettingsKey = 'settings.searcher.verlauf';

    @ViewChild('verlaufDialog', { static: false })
    verlaufDialog: AviDialogComponent;

    @ViewChild('verlaufForm', { static: false })
    verlaufForm: AviVerlaufFormComponent;

    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    _readonly: boolean = false;
    public get Readonly() {
        return this._readonly;
    }

    @Input('readonly')
    public set Readonly(value: boolean) {
        this._readonly = value;
    }

    _KontextId: string = null;
    @Input('kontext-id')
    public set KontextId(value: string) {
        this._KontextId = value;
        if (this.KontextUrl && this.searcher)
            this.forceRefresh();
    }

    public get KontextId(): string {
        return this._KontextId;
    }

    @Input('kontext-url')
    public KontextUrl: string = null;

    public Collapsed: boolean = true;
    private RefreshRequested: boolean = false;

    Items: VerlaufDto[] = [];

    FormModelId: string = null;
    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => this.closeForm() }];

    constructor(public apiService: AviApiService, public router: Router, public commonService: AviCommonService, public authService: AviAuthService, private cdr: ChangeDetectorRef) {
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => this.doSearch(searchConfig);

    async doSearch(searchConfig) {
        this.Items = await this.apiService.getModelList(VerlaufDto, `verlauf/${this.KontextUrl}/${this.KontextId}`);

        this.Items = this.searcher.ApplyColumnFilters(this.Items);
        this.Items = this.searcher.ApplyColumnSorting(this.Items);

        return this.Items;
    }

    OnCollapsedChanged(data) {
        this.Collapsed = data;

        if (!this.Collapsed && (!this.searcher._initialSearchDone || this.RefreshRequested))
            this.forceRefresh();
    }

    onSearcherInit(searcher) {
        searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.Username), 'CORE.COMMON.MODEL.CAFVERLAUF.USERNAME', true).setSortable(false);
        searcher.addDateColumn(nameof(c => c.Datum), 'CORE.COMMON.MODEL.CAFVERLAUF.DATUM', 'short', true, '120px').setSortable(false);

        searcher.addTextColumn(nameof(c => c.EventBez), 'CORE.COMMON.MODEL.CAFVERLAUF.EVENT', true).setSortable(false);

        searcher.addColumn(
            AviSearcherColumn.CreateCustom(
                nameof(c => c.Bemerkung),
                'CORE.COMMON.MODEL.CAFVERLAUF.BEMERKUNG',
                (row, col) => {
                    if (row['Bemerkung'])
                        return `<div>${this.commonService.nl2br(this.commonService.escapeHtml(row['Bemerkung']))}</div>`;

                    return this.commonService.nl2br(this.commonService.escapeHtml(row['Bemerkung']));
                },
                true
            )
                .setSortable(false)
                .setFormatTitleDelegate((row) => this.commonService.escapeHtml(row['Bemerkung']))
        );

        searcher.addColumn(
            AviSearcherColumn.CreateButton('btnAct', 'CORE.COMMON.MODEL.CAFVERLAUF.AKTIONEN', null, null, null, null, '90px', ['edit'])
                .setButtonOnClickDelegate((row, col, buttonId) => {
                    if (buttonId === 'edit')
                        this.editVerlaufseintrag(row['Id']);
                    // else
                    //     this.deleteVerlaufseintrag(row['Id']);
                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-flat': true,
                        'p-button-text': true
                    };
                })
                .setButtonIconDelegate((row, col, buttonId) => {
                    if (buttonId === 'edit')
                        return 'pi pi-pencil';
                    // else
                    //     return 'pi pi-trash';
                })
                .setCellStyleDelegate(() => {
                    return { 'text-align': 'left' };
                })
                .setSortable(false)
        );

        searcher.getColumn('btnAct').Visible = !this._readonly;

        searcher.endInit();
    }

    getVerlaufskommentar(kommentar: string) {
        return kommentar?.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }

    forceRefresh() {
        if (this.Collapsed)
            this.RefreshRequested = true;
        else {
            this.searcher.forceRefresh();
            this.RefreshRequested = false;
        }
    }

    editVerlaufseintrag(id) {
        this.FormModelId = id;
        this.verlaufDialog.open();
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.verlaufDialog.close();
    }
}
