import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { Expose, Type } from 'class-transformer';

export class VwbPRechtsformDef extends BaseModel {
    @Expose()
    public get Rechtsform(): string { return this.getProperty('Rechtsform'); }
    public set Rechtsform(value: string) { this.setProperty('Rechtsform', value); }

    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }
}
