import { Expose, Type } from "class-transformer";
import { IDTO, ListType } from "../shared";

export class BusinesscaseDataDetailDocumentDto extends IDTO {  
    @Expose()
    public Titel: string;

    @Expose()
    public vonBusinesscaseData_ID: string;

    @Expose()
    public DocId: string;
    
    @Expose()
    public DocArchiv: string;
     
    @Expose()
    public Internal: boolean;

    @Expose()
    public DMDraftId: number;

    @Expose()
    public DMEditorUrl: string;

    @Expose()
    public CreatedByName: string;

    @Expose()
    @Type(() => Date)
    public CreatedDate: Date;
}
