import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviApiService, AviCommonService, AviListDetailConst, InputmaskService } from '@avi-x/avi-core';
import { VwbPInternTaskBaseComponent } from './task-base.component';
import { VwbPInternRechtstraegerService } from '../../services/rechtstraeger.service';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
    selector: 'vwbp-intern-rechtstraeger-bemerkung-teilfonds-task',
    template: `
      <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
            {{ (this.GetIsActivated() ? 'VWBP.INTERN.BEMERKUNG_TEILFONDS.AKTIVIEREN_BUTTON' : 'VWBP.INTERN.BEMERKUNG_TEILFONDS.DEAKTIVIEREN_BUTTON') | translate }}
        </div>
         <div class="grid form-group">
            <div class="col-12">
                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ (this.GetIsActivated() ? 'VWBP.INTERN.BEMERKUNG_TEILFONDS.AKTIVIEREN_KOMMENTAR' : 'VWBP.INTERN.BEMERKUNG_TEILFONDS.DEAKTIVIEREN_KOMMENTAR') | translate }}</label>
                </span>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-thumbs-up" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-primary"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternRechtstraegerBemerkungTeilfondsTaskComponent extends VwbPInternTaskBaseComponent {
    @Input('bemerkung-teilfonds')
    public BemerkungTeilfonds: string = null;

    constructor(public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public inputMaskService: InputmaskService, public dialogService: DialogService, public cdr: ChangeDetectorRef) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);
    }

    public GetIsActivated() {
        return this.BemerkungTeilfonds === AviListDetailConst.JA;
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + (this.GetIsActivated() ? this.commonService.translateInstant('VWBP.INTERN.BEMERKUNG_TEILFONDS.AKTIVIEREN_BUTTON') : this.commonService.translateInstant('VWBP.INTERN.BEMERKUNG_TEILFONDS.DEAKTIVIEREN_BUTTON'));
    }

    CanExecute(): boolean {
        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung;
    }

    async Execute() {
        if (this.GetIsActivated())
            this.PerformAction('VWBP.INTERN.BEMERKUNG_TEILFONDS.AKTIVIEREN_BUTTON', this.GetResourceName('VWBP.INTERN.BEMERKUNG_TEILFONDS.AKTIVIEREN_BUTTON'), 'VWBP.INTERN.BEMERKUNG_TEILFONDS.AKTIVIEREN_BUTTON',
                async (selectedRT) => this.rechtstraegerService.SetBemerkungTeilfonds(selectedRT.map(w => w.Id), this.Bemerkung, this.BemerkungTeilfonds)
            );
        else
            this.PerformAction('VWBP.INTERN.BEMERKUNG_TEILFONDS.DEAKTIVIEREN_BUTTON', this.GetResourceName('VWBP.INTERN.BEMERKUNG_TEILFONDS.DEAKTIVIEREN_BUTTON'), 'VWBP.INTERN.BEMERKUNG_TEILFONDS.DEAKTIVIEREN_BUTTON',
                async (selectedRT) => this.rechtstraegerService.SetBemerkungTeilfonds(selectedRT.map(w => w.Id), this.Bemerkung, this.BemerkungTeilfonds)
            );
    }
}
