<avi-core-page [title]="'VWBP.PUBLIC.XMLUPLOAD.TITLE' | translate"
    [card]="true"
    [collapsible]="false"
    [expanded]="true"
    [contents-padding]="true"
    [no-box-shadow]="true"
    avi-core-dragdrop
    (fileDropped)="onFileDropped($event)">

    <avi-textblock [identifier]="'vwbp-xml-upload-dialog-header'"></avi-textblock>

    <div class="xml-file-upload">
        <p-fileUpload #inputXML
            [customUpload]="true"
            [disabled]="Uploading"
            (onSelect)="onSelect()"
            [uploadLabel]="'CORE.COMMON.UPLOAD_BUTTON' | translate"
            [cancelLabel]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
            [chooseLabel]="'CORE.COMMON.AUSWAEHLEN_BUTTON' | translate"
            (uploadHandler)="onUploadXML($event)"
            accept=".xml"
            [maxFileSize]="10000000">
        </p-fileUpload>
    </div>

    <div class="grid justify-content-end mt-4">
        <button pButton
            type="button"
            [label]="'CORE.COMMON.SCHLIESSEN_BUTTON' | translate"
            (click)="doClose()"
            class="w-100p-m m mr-4p p-button-secondary p-button-outlined"></button>
    </div>
</avi-core-page>
