import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AviApiService } from '../../../services/api.service';
import { AviBaseFormComponent, AviChangedAttr, AviCommonService, AviFormField, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { FileSaverService } from 'ngx-filesaver';
// import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';

const nameof = (nameFunction: ((obj: ListAuswahlDto) => any)) => typed_nameof<ListAuswahlDto>(nameFunction);

export class ListAuswahlDto {  
    public List: string;
}

@Component({
    selector: 'avi-list-admin',
    templateUrl: './list-admin.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviListAdminComponent implements OnInit {

    public Loading: boolean = false;

    @Input('api-prefix')
    public ApiPrefix: string = '';

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    public Model: ListAuswahlDto = new ListAuswahlDto();

    public File: File = null;

    public LogTotalCreated: string = null;
    public LogTotalUpdated: string = null;
    public LogTotalExported: string = null;

    public Fields: AviFormField[] = [];

    public Title: string = 'Serialization Export';

    public Card = true;

    public FormTitle: string = 'Model Serialization';

    ListName: string = null;

    constructor(
        private apiService: AviApiService,
        private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private formFieldService: AviFormFieldService,
        private fileSaverService: FileSaverService,
        private cdr: ChangeDetectorRef
    ) { 
    }


    async ngOnInit() {
        if ('apiprefix' in this.activatedRoute.snapshot.data)
            this.ApiPrefix = this.activatedRoute.snapshot.data['apiprefix'];

        this.initFields();

        this.cdr.detectChanges();
    }

    initFields() {
        //this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Type), 'Typ', `${this.ApiPrefix ?? ''}/representations/list`, true, true, 'Typ wählen...', (m) => (`${m.Representation}`), (m) => m, true, false, 'contains').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.List), 'List', true, 'Representation', 'Representation', 2, `model/representations/list`).setMDSizeHalf());
    }

    async onAttrChange(data: AviChangedAttr) {
        this.ListName = this.Model.List;
    }
}
