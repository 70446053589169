import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AviCoreModule } from '@avi-x/avi-core';

import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

// import { BrowserModule } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';
import { AviVerlaufFormComponent } from './components/verlauf-form.component';
import { AviVerlaufSearcherComponent } from './components/verlauf-searcher.component';
import { AviVerlaufListComponent } from './components/verlauf-list.component';

@NgModule({
    imports: [
        // BrowserModule,
        FormsModule,
        CommonModule,
        AviCoreModule,

        FlexLayoutModule,
        PanelModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ButtonModule,
        SplitButtonModule,
        InputTextModule,
        MenuModule,

        NgxPermissionsModule.forChild(),

        TranslateModule
    ],
    exports: [
        AviVerlaufFormComponent,
        AviVerlaufSearcherComponent,
        AviVerlaufListComponent
    ],
    declarations: [        
        AviVerlaufFormComponent,
        AviVerlaufSearcherComponent,
        AviVerlaufListComponent
    ]
})
export class AviVerlaufModule { }
