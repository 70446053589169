import { InfoDeskDetail, InfoDeskDetailType } from './infodesk-querydef';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterViewInit, ViewChildren, QueryList, Input, ViewEncapsulation, OnChanges } from '@angular/core';
import { CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { AviCommonService, AviApiService, AviAuthService, AviSlideDownUpAnimation, ISessionData, AviSessionControllerService } from '@avi-x/avi-core';

import { AviCanComponentDeactivate } from '@avi-x/avi-core';
import { AviQueryDefExecutionGraphComponent } from '../../../querydef-exec-graph/components/querydef-exec-graph/querydef-exec-graph.component';
import { InfoDeskDefVM } from '@avi-x/avi-dto/reporting/infodeskdef-vm.model';
import { InfoDeskDefDetailVM } from '@avi-x/avi-dto/reporting/infodeskdefdetail-vm.model';

export interface MyGridsterItem extends GridsterItem {
    id: string;
    type: InfoDeskDetailType;
}

@Component({
    selector: 'avi-report-infodesk-exec',
    templateUrl: './infodesk-exec.component.html',
    animations: [
        AviSlideDownUpAnimation
    ],
    encapsulation: ViewEncapsulation.None
})
export class AviInfoDeskComponent implements OnInit, AfterViewInit, AviCanComponentDeactivate, OnChanges  {
    @Input('session-data')
    SessionData: ISessionData;

    options: GridsterConfig;
    dashboard: Array<MyGridsterItem>;

    FormModelId: string = null;

    formActionButtons: any = [];

    private isMaximized: boolean = false;

    private isEmbedded: boolean = false;
    public set Id(value: string) {
        if (this._Id === value)
            return;
        this._Id = value;
        this.loadModel(this._Id);
    }

    @Input('height')
    height: string = '94vh';


    @ViewChildren('graphs') graphs: QueryList<any>;
    @ViewChildren('searchers') searchers: QueryList<any>;

    public InfoDeskDetailType = InfoDeskDetailType;
    public InfoDeskDetails: InfoDeskDetail[] = [];

    private _Id: string = null;
    public Model: InfoDeskDefVM = null;
    sub: any;

    @Input('id')
    public set InputId(value: string) {
        this.isEmbedded = true;
        this.Id = value;
    }

    public ShowSearcher = false;
    private _maId: string = null;
    public panelWidth = 3;

    constructor(public commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private sessionController: AviSessionControllerService
    ) { }


    public set maId(value: string) {
        if (this._maId !== value) {
            this._maId = value;
            this.FormModelId = null;
        }
    }

    public get maId(): string {
        return this._maId;
    }

    async ngOnChanges() {
        if (this.SessionData?.ContextId && this.SessionData?.ContextId !== this.Id) {
            this.Id = this.SessionData.ContextId;
            await this.loadModel(this._Id);
            this.sessionController.setCurrentSessionTitle(this.Model.Bezeichnung1);
        }
    }

    ngOnInit() {
        this.options = {
            gridType: GridType.Fit,
            compactType: CompactType.None,
            margin: 10,
            outerMargin: false,
            outerMarginTop: null,
            outerMarginRight: null,
            outerMarginBottom: null,
            outerMarginLeft: null,
            useTransformPositioning: true,
            mobileBreakpoint: 640,
            minCols: 1,
            maxCols: 50,
            minRows: 1,
            maxRows: 50,
            maxItemCols: 100,
            minItemCols: 1,
            maxItemRows: 100,
            minItemRows: 1,
            maxItemArea: 2500,
            minItemArea: 1,
            defaultItemCols: 1,
            defaultItemRows: 1,
            fixedColWidth: 105,
            fixedRowHeight: 105,
            keepFixedHeightInMobile: false,
            keepFixedWidthInMobile: false,
            scrollSensitivity: 10,
            scrollSpeed: 20,
            enableEmptyCellClick: false,
            enableEmptyCellContextMenu: false,
            enableEmptyCellDrop: false,
            enableEmptyCellDrag: false,
            emptyCellDragMaxCols: 50,
            emptyCellDragMaxRows: 50,
            ignoreMarginInRow: false,
            draggable: {
              enabled: false,
            },
            resizable: {
              enabled: false
            },
            swap: true,
            pushItems: false,
            disablePushOnDrag: false,
            disablePushOnResize: false,
            pushDirections: {north: true, east: true, south: true, west: true},
            pushResizeItems: false,
            displayGrid: DisplayGrid.None,
            disableWindowResize: false,
            disableWarnings: true,
            scrollToNewItems: false,
            itemResizeCallback: this.itemResize.bind(this),
          };

        this._maId = this.authService.CurrentUser.MitarbeiterId;
        if (!this.isEmbedded) {
            this.formActionButtons = [];
            this.Model = new InfoDeskDefVM();

            this.sub = this.activatedRoute.params.subscribe(params => {
                if (params && params['infodeskid']) {
                    const id = params['infodeskid'];
                    if (id && id !== '0')
                        this.Id = id;
                }
            });
        }
    }

    getSettingsKey: any = () => {
        // if (this.Model)
        //     return 'avix::settings.infodesk.' + this.Model.Id;

        return null;
    }

    canDeactivate() {
        const settingsKey = this.getSettingsKey();
        if (settingsKey)
            localStorage.setItem(settingsKey, JSON.stringify(this.dashboard.map(w => { return { id: w.id, x: w.x, y: w.y, cols: w.cols, rows: w.rows }; })));

        return true;
    }

    itemResize = (item) => {
        // Hack um während der ganzen Animation Graphs zu resizen
        this.setIntervalLimited(_ => this.resizeGridsterGraph(item), 200, 5);
    }

    resizeGridsterGraph(item) {
        const graph = this.graphs.filter(w => w.Model && w.Model.Id === item.id)[0] as AviQueryDefExecutionGraphComponent;
        if (graph)
            graph.refresh();
    }

    ngAfterViewInit() {
    }

    getSearcherState() {
        return this.ShowSearcher ? 'in' : 'out';
    }

    public onGraphParameterValueChange(data) {
        const paramName = data.field;
        const paramValue = data.value;

        this.searchers.forEach(w => w.setSearcherParameter(paramName, paramValue));
        this.graphs.forEach(w => w.setSearcherParameter(paramName, paramValue));

        if (data.mitarbeiter) {
            this.maId = data.mitarbeiter;
        }
    }

    async loadModel(id: string) {
        this.Model = await this.apiService.getModelById(InfoDeskDefVM, '/InfoDeskDef', id);
        const details = await this.apiService.getModelList(InfoDeskDefDetailVM, `/InfoDeskDefDetail?select=id,posx,posy,width,height,querydef_Id,querydef.Darstellung&filter=vonInfoDeskDef_ID eq ${id}&sort=+posy,+posx`);
        this.InfoDeskDetails = [];

        this.Model.AllDetails = details;

        this.dashboard =  this.Model.AllDetails.map(w => { return {
            cols: w.Width,
            rows: w.Height,
            x: w.PosX,
            y: w.PosY,
            id: w.QueryDef_ID,
            type: w.querydefDarstellung.Id === '79458f3c-85d5-4567-8a3f-29f98eb2e96d' ? InfoDeskDetailType.GRAPH : InfoDeskDetailType.QUERYDEF,
            maximized: false,
            maximize_button: 'call_made'
        }; });

        console.log('Dashboard', this.dashboard);

        const settingsKey = this.getSettingsKey();
        if (settingsKey) {
            const settings = JSON.parse(localStorage.getItem(settingsKey));
            if (settings) {
                settings.forEach(setting => {
                    const ditem = this.dashboard.filter(w => w.id === setting.id)[0];
                    if (ditem) {
                        ditem.x = setting.x;
                        ditem.y = setting.y;
                        ditem.cols = setting.cols;
                        ditem.rows = setting.rows;
                    }
                });
            }
        }
    }

    setIntervalLimited(callback, interval, x) {

        for (let i = 0; i < x; i++) {
            setTimeout(callback, (i + 1) * interval);
        }
    }

    onResize(event) {
        console.log(event);
    }

    changeIcon(item) {
        item.maximized = !item.maximized;
        item.maximize_button = item.maximized ? 'call_received' : 'call_made';

        // Beim Maximieren wird der Resize Callback nicht angerufen, dies ist ein Hack um
        // das während der Animation selber zu machen...
        this.setIntervalLimited(_ => this.resizeGridsterGraph(item), 200, 5);
    }
}
