import { Component, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppUserGroup } from '@avi-x/avi-dto/system/appusergroup.model';
import { AviAppUserGroupLogEntryFormComponent } from './appusergrouplogentry-form.component';
import { AviAppUserGroupLinkMultiSelectionComponent } from './appusergrouplink-multiselection.component';
import { AppUserGroupEditDto } from '@avi-x/avi-dto/system/appusergroupeditdto.model';
import { AppUserGroupAddDto } from '@avi-x/avi-dto/system/appusergroupadddto.model';
import { IDTO } from '@avi-x/avi-dto/shared';
import { AviTechRoleLinkMultiSelectionComponent } from './techrolelink-multiselection.component';
import { TechRoleLinkSearchResponseDto } from '@avi-x/avi-dto/system/techrolelinksearchresponsedto.model';

const nameof = (nameFunction: ((obj: AppUserGroup) => any)) => typed_nameof<AppUserGroup>(nameFunction);

@Component({
    selector: 'avi-appusergroup-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [validation-delegate]="ValidateData"
        (onStepChange)="onStepChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

        <div groupTemplate1 *ngIf="!ReadOnly">
            <avi-techrolelink-multiselection></avi-techrolelink-multiselection>
        </div>

        <div groupTemplate2 *ngIf="!ReadOnly">
            <avi-appusergrouplink-multiselection></avi-appusergrouplink-multiselection>
        </div>

        <div groupTemplate3 *ngIf="!ReadOnly">
            <avi-appusergrouplogentry-form [card]="false" [contents-padding]="false" [readonly]="false" [model-id]="'0'" [enable-taskmenu]="false" [show-add-roles]="true" [show-removed-roles]="true"></avi-appusergrouplogentry-form>
        </div>
    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAppUserGroupFormComponent extends AviAbstractModelFormComponent<AppUserGroup> {
    ClassName = 'CORE.COMMON.MODEL.APPUSERGROUP';
    BaseUrl = 'appusergroup';
    ClassType = AppUserGroup;
	FormType = AviAppUserGroupFormComponent;

    @Output('after-edit')
    afterEdit = new EventEmitter<any>();

    @ViewChild(AviTechRoleLinkMultiSelectionComponent)
    roleMultiSelectionComponent: AviTechRoleLinkMultiSelectionComponent;

    @ViewChild(AviAppUserGroupLinkMultiSelectionComponent)
    userMultiSelectionComponent: AviAppUserGroupLinkMultiSelectionComponent;

    @ViewChild(AviAppUserGroupLogEntryFormComponent)
    logEntryComponent: AviAppUserGroupLogEntryFormComponent;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'AppUserGroup';
    }

    	
    onStepChange(step: number) {
        if (step == 3) {
            this.logEntryComponent.Model.RolesAdded = this.concatToString(this.roleMultiSelectionComponent.addedRows);
            if (this.isEditMode) 
                this.logEntryComponent.Model.RolesRemoved = this.concatToString(this.roleMultiSelectionComponent.removedRows);

            this.logEntryComponent.cdr.markForCheck();
        }
    }  

    private concatToString(rows: TechRoleLinkSearchResponseDto[]): string {
        let names: string[] = [];
        rows.forEach(row => names.push(row.TechRoleName));

        return names.join(",");
    }


    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        if (!this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateStep("CORE.COMMON.MODEL.APPUSERGROUP.STEP_USERGROUP"));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez), 'CORE.COMMON.MODEL.APPUSERGROUP.KURZBEZ', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichung), 'CORE.COMMON.MODEL.APPUSERGROUP.BEZEICHUNG', true).setMDSizeHalf());

        if (!this.ReadOnly) {
            this.Fields.push(this.formFieldService.CreateStep("CORE.COMMON.MODEL.APPUSERGROUP.STEP_ROLES"));
            this.Fields.push(this.formFieldService.CreateGroupTemplate(1));

            this.Fields.push(this.formFieldService.CreateStep("CORE.COMMON.MODEL.APPUSERGROUP.STEP_USERS"));
            this.Fields.push(this.formFieldService.CreateGroupTemplate(2));

            this.Fields.push(this.formFieldService.CreateStep("CORE.COMMON.MODEL.APPUSERGROUP.STEP_LOG", "logStep"));
            this.Fields.push(this.formFieldService.CreateGroupTemplate(3));
        }
    }

    public async afterInitForm() {
        if (!this.isEditMode) {
            this.formFieldService.setFieldVisible(this.Fields, "logStep", false);
            this.formFieldService.setFieldVisible(this.Fields, "groupTemplate3", false);
        }
    }

    ValidateData = () => {
        if (this.ReadOnly || !this.isEditMode)
            return true;

        return this.logEntryComponent && this.logEntryComponent.Form.isFormValid();
    }

    saveModel(model: AppUserGroup) {
        let dto: IDTO;

        if (this.isEditMode) {
            let saveDto = new AppUserGroupEditDto();
            saveDto.AppUserGroup = this.Model;

            saveDto.AddedTechRolesIds = this.roleMultiSelectionComponent.addedIds;
            saveDto.RemovedTechRolesIds = this.roleMultiSelectionComponent.removedIds;
            saveDto.AddedAppUserIds = this.userMultiSelectionComponent.addedIds;
            saveDto.RemovedAppUserIds = this.userMultiSelectionComponent.removedIds;
            saveDto.LogEntry = this.logEntryComponent.Model;
            saveDto.LogEntry.vonAppUserGroup_ID = this.Model.Id; 

            dto = saveDto;
        }
        else {
            let saveDto = new AppUserGroupAddDto();
            saveDto.AppUserGroup = this.Model;
            saveDto.AddedTechRolesIds = this.roleMultiSelectionComponent.addedIds;
            saveDto.AddedAppUserIds = this.userMultiSelectionComponent.addedIds;

            dto = saveDto;
        }
       
        this.saveDto(model.Id, dto);
    }

    async openEditDialog() {
        await super.openEditDialog();

        this.afterEdit.emit();
    }
}
