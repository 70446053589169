import { Component, Input } from '@angular/core';
import { AviCommonService } from '../services/common.service';

@Component({
    selector: 'avi-core-dbg',
    template: `
  <style>

  .dbg { font-family: monospace;background-color: #0C2238;color: #EEEEEE;border-radius:0px; border: 1px solid red;font-size:small;overflow:auto;padding:10px;}
  </style>
  <div *ngIf="debugenabled" style="margin-top:12px;margin-bottom:12px">

<p-panel [collapsed]="collapsed" expandIcon="pi pi-caret-down" collapseIcon="pi pi-caret-up" [header]="getTitle()" toggler="header" [toggleable]="true">
<div class="dbg">
    <pre>{{getModelCopy() | json}}</pre>
</div>
</p-panel>
</div>
`,
})
export class AviDebugComponent {

    public get debugenabled(): boolean {
        return localStorage.getItem('avix::debug::enabled') === 'YES' && !this.commonService.IsProduction;
    }

    @Input('m')
    public Model: any;

    @Input('collapsed')
    public collapsed: boolean = false;

    @Input('t')
    public Title: any;

    constructor(private commonService: AviCommonService) { }

    public getModelCopy() {
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
        const getCircularReplacer = () => {
            const seen = new WeakSet();
            return (key, value) => {
              if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                  return;
                }
                seen.add(value);
              }
              return value;
            };
          };

        if (this.Model === null || this.Model === undefined)
            return null;
        try {
            // return JSON.parse(JSON.stringify(this.Model));
            return JSON.parse(JSON.stringify(this.Model, getCircularReplacer()).replace(/\\r\\n/g, '\\n').replace(/\\n/g, '\\r\\n'));
        } catch (err) {
            console.warn('JSONPARSE', err, this.Model);
            return { error: (<Error>err).message };
        }
    }

    public getTitle() {
        return !this.Title ? 'Debug' : `${this.Title}`;
    }


}
