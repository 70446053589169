<avi-core-view-panel [toggleable]="false" [header]="Title | translate" class="w-100p" [enable-panel]="EnablePanel" panel-class="mt-2">
    <avi-core-base-searcher
        #searcher
        [settingskey]="SettingsKey"
        [search-delegate]="SearchDelegate"
        (onInit)="onSearcherInit($event)"
        (onError)="onSearcherError($event)"
        [use-router-params]="false"
        (onDoubleClick)="onDoubleClickSearcher($event)"
        [rows]="50"
        [auto-search]="true"
        selectionMode="single"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [toolbar-show-columnselection]="true"
        [toolbar-show-inactivebutton]="false"
        [toolbar-show-generic-export]="true"
        [disable-inactive-filter]="true"
        (onSelect)="onSelectionChanged($event)"
		[card]="!EnablePanel"
		[contents-padding]="EnablePanel"
        [searcher-title]="Title | translate"
        [show-totalrecords]="false"
        [add-crud-menuitems]="true"
        [toolbar-show-createbutton]="true"
        crud-base-right="CAFStatusWechselDef"
        (onCreateObject)="createObject()"
        (onEditObject)="editObject($event)"
        (onDeleteObject)="deleteObject($event)"
    >
    </avi-core-base-searcher>    
</avi-core-view-panel>
