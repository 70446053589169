import { Expose, Type } from 'class-transformer';
import { IDTO } from '../shared/idto.model';

import { ListType } from '../shared/listtype.model';
import TransformDate from '../shared/transformdate';

export class ScriptRuleDefSearchResultDto extends IDTO {    
    @Expose()
    public Id: string;
    
    @Expose()
    @Type(() => ListType)
    public Aktiv: ListType;

    @Expose()
    @Type(() => Date)
    public ErstellDatum: Date;

    @Expose()
    public ErstellUser: string;

    @Expose()
    @Type(() => Date)
    public MutDatum: Date;

    @Expose()
    public MutUser: string;

    @Expose()
    public Bezeichnung1: string;

    @Expose()
    public RuleID: number;
    
    @Expose()
    @Type(() => ListType)
    public Assignment1: ListType;
    
    @Expose()
    @Type(() => ListType)
    public Assignment2: ListType;
    
    @Expose()
    @Type(() => ListType)
    public AutoClose: ListType;    
    
    @Expose()
    @TransformDate()
    public GueltigAb: Date;
    
    @Expose()
    @TransformDate()
    public GueltigBis: Date;
}
