import { Expose, Type } from 'class-transformer';

import { AenderungsGrundDef } from '../workflow/aenderungsgrunddef.model';
import { CustomPropCollectionDef } from '../system/custompropcollectiondef.model';
import { TechRole } from '../system/techrole.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class AktenNotizDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get AktenNotizChangeDef_ID(): string { return this.getModelId('AktenNotizChangeDef_ID'); }
    public set AktenNotizChangeDef_ID(value: string) { this.setModelId('AktenNotizChangeDef_ID', value); }
 
    @Expose()
    @Type(() => AenderungsGrundDef)
    public get AktenNotizChangeDef(): AenderungsGrundDef { return this.getModel('AktenNotizChangeDef'); }
    public set AktenNotizChangeDef(value: AenderungsGrundDef) { this.setModel('AktenNotizChangeDef', value); }
    
    @Expose()
    public get CustomPropDef_ID(): string { return this.getModelId('CustomPropDef_ID'); }
    public set CustomPropDef_ID(value: string) { this.setModelId('CustomPropDef_ID', value); }
 
    @Expose()
    @Type(() => CustomPropCollectionDef)
    public get CustomPropDef(): CustomPropCollectionDef { return this.getModel('CustomPropDef'); }
    public set CustomPropDef(value: CustomPropCollectionDef) { this.setModel('CustomPropDef', value); }
    
    @Expose()
    public get CreateRole_ID(): string { return this.getModelId('CreateRole_ID'); }
    public set CreateRole_ID(value: string) { this.setModelId('CreateRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get rCreateRole(): TechRole { return this.getModel('rCreateRole'); }
    public set rCreateRole(value: TechRole) { this.setModel('rCreateRole', value); }
    
    @Expose()
    public get UpdateRole_ID(): string { return this.getModelId('UpdateRole_ID'); }
    public set UpdateRole_ID(value: string) { this.setModelId('UpdateRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get rUpdateRole(): TechRole { return this.getModel('rUpdateRole'); }
    public set rUpdateRole(value: TechRole) { this.setModel('rUpdateRole', value); }
    
    @Expose()
    public get DeleteRole_ID(): string { return this.getModelId('DeleteRole_ID'); }
    public set DeleteRole_ID(value: string) { this.setModelId('DeleteRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get rDeleteRole(): TechRole { return this.getModel('rDeleteRole'); }
    public set rDeleteRole(value: TechRole) { this.setModel('rDeleteRole', value); }
    
    @Expose()
    public get ShowRole_ID(): string { return this.getModelId('ShowRole_ID'); }
    public set ShowRole_ID(value: string) { this.setModelId('ShowRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get rShowRole(): TechRole { return this.getModel('rShowRole'); }
    public set rShowRole(value: TechRole) { this.setModel('rShowRole', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }
    
    @Expose()
    @Type(() => ListType)
    public get UpdateLetzterKundenkontakt(): ListType { return this.getProperty('UpdateLetzterKundenkontakt'); }
    public set UpdateLetzterKundenkontakt(value: ListType) { this.setProperty('UpdateLetzterKundenkontakt', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VonSetzen(): ListType { return this.getProperty('VonSetzen'); }
    public set VonSetzen(value: ListType) { this.setProperty('VonSetzen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get BisSetzen(): ListType { return this.getProperty('BisSetzen'); }
    public set BisSetzen(value: ListType) { this.setProperty('BisSetzen', value); }
    
}
