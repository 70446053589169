import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Job } from '@avi-x/avi-dto/system/job.model';
import { typed_nameof } from '../utils/nameof';
import { AviAbstractModelFormComponent } from '../base-form/abstract-model-form.component';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviFormFieldService } from '../../services/form-field.service';
import { AviSessionControllerService } from '../../services/session-controller.service';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AviRoleConst } from '../../shared/constants/constants';
import { AviJobService } from '../../services/job.service';

const nameof = (nameFunction: ((obj: Job) => any)) => typed_nameof<Job>(nameFunction);

@Component({
    selector: 'avi-job-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

        <div header class="font-size-medium" *ngIf="statusEditMode">
            <span style="font-weight: 500">{{'CORE.COMMON.MODEL.JOB.STATUSUPDATE_ACHTUNG' | translate}}: </span>
            <span>{{'CORE.COMMON.MODEL.JOB.STATUSUPDATE_WARNING' | translate}}</span>
        </div>
    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJobFormComponent extends AviAbstractModelFormComponent<Job> {  
    ClassName = 'CORE.COMMON.MODEL.JOB';
    BaseUrl = 'job';
    ClassType = Job;
	FormType = AviJobFormComponent;

    statusEditMode = false;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        private translateService: TranslateService,
		public cdr: ChangeDetectorRef,
        public jobservice: AviJobService) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'Job';
    }

    ngOnInit(): void {
        this.BaseUrlPrefix = this.jobservice.ApiPrefix;
        super.ngOnInit();
    }

    private getApiPrefix() {
        var prefix = '';
        if (this.BaseUrlPrefix) {
            prefix = this.BaseUrlPrefix;
            if (!prefix.endsWith('/'))
                prefix = prefix + '/'
        }
        return prefix;
    }
	
    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        //this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.JobID), 'CORE.COMMON.MODEL.JOB.JOBID', 0, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.fromJobDef_ID), 'CORE.COMMON.MODEL.JOB.FROMJOBDEF', `${this.getApiPrefix()}model/representations/JobDef?id=$id`, true, false, null, (m) => (`${m.Representation}`), 'Id', true, true).setMDSizeHalf().setReadonly(true)); 
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Status), 'CORE.COMMON.MODEL.JOB.STATUS', 'job_status', true, true, this.ReadOnly).setMDSizeHalf());

        if (!this.statusEditMode) {
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.FilePath), 'CORE.COMMON.MODEL.JOB.FILEPATH', false).setMDSizeFull());

            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.StartedAt), 'CORE.COMMON.MODEL.JOB.STARTEDAT', false).setMDSizeHalf().setDateTime());
            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.FinishedAt), 'CORE.COMMON.MODEL.JOB.FINISHEDAT', false).setMDSizeHalf().setDateTime());

            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.TotalItems), 'CORE.COMMON.MODEL.JOB.TOTALITEMS', 0, false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.TotalWorkups), 'CORE.COMMON.MODEL.JOB.TOTALWORKUPS', 0, false).setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.TotalSucceeded), 'CORE.COMMON.MODEL.JOB.TOTALSUCCEEDED', 0, false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.TotalErrors), 'CORE.COMMON.MODEL.JOB.TOTALERRORS', 0, false).setMDSizeHalf());
        }
    }

    public async afterInitForm() {
        if (!this.Model.FilePath && !this.statusEditMode)
            this.formFieldService.getField(this.Fields, nameof(c => c.FilePath)).setVisible(false);
    }

    async getTaskMenuItems(): Promise<MenuItem[]> {
        var items: MenuItem[] = [];

        if (this.permissionsService.hasPermission([AviRoleConst.Job_SCHREIBEN, AviRoleConst.Job_EDITIEREN]) && this.EnableEdit) {
            items.push({ label: this.translateService.instant('CORE.COMMON.MODEL.JOB.STATUSUPDATE'), command: () => this.openStatusUpdateDialog(), icon: 'pi pi-pencil' });
        }

        return items;
    }

    private async openStatusUpdateDialog() {
        await this.commonService.openFormDialog(this.FormType, 'CORE.COMMON.MODEL.JOB.STATUSUPDATE_TITLE', this.ModelId, this.ContextId, {'statusEditMode': true}, true);
        await this.refresh();
    }

    initParams(params: { [k: string]: any; }) {
        if (params.statusEditMode)
            this.statusEditMode = params.statusEditMode as boolean;

        this.cdr.markForCheck();
    }
}
