import { VwbPCommonService, VwbPRoleConst } from '@avi-x/vwbp-public';
import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AviCommonService, AviApiService, AviAuthService } from '@avi-x/avi-core';
import { MatTabNav } from '@angular/material/tabs';

@Component({
    selector: 'vwbp-intern-rechtstraeger-view-index',
    templateUrl: './rechtstraeger-view-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPInternRechtstraegerViewIndexComponent implements OnInit {

    navLinks = [
        { path: './rechtstraeger', label: 'Rechtsträger', roles: [VwbPRoleConst.VWBP_ROLE_VWBP_AJU_SCHREIBEN, VwbPRoleConst.VWBP_ROLE_VWBP_AJU_LESEN, VwbPRoleConst.VWBP_ROLE_VWBP_BEHOERDEN] },
        { path: './dokumente', label: 'Dokumente', roles: [VwbPRoleConst.VWBP_ROLE_VWBP_AJU_SCHREIBEN, VwbPRoleConst.VWBP_ROLE_VWBP_AJU_LESEN] }
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        private authService: AviAuthService
    ) {
    }

    ngOnInit() {
        this.navLinks = this.navLinks.filter(w => !w.roles || this.authService.currentUserHasOneOfRoles(w.roles));
    }
}
