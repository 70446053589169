<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

    <avi-tabView #tabView *ngIf="ModelId" width="14rem" tabview-class="tabstyle-session" orientation="vertical" [session-modul]="true">

        <avi-tabPanel title="CORE.COMMON.MODEL.JOB.JOB_1" id="job-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-job-form [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true"></avi-job-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
	
        <avi-tabPanel title="CORE.COMMON.MODEL.JOB.ALLEPARAMETER" id="alleparameter-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-jobparam-searcher [context-id]="ModelId" [enable-panel]="true"></avi-jobparam-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
        <avi-tabPanel title="CORE.COMMON.MODEL.JOB.ALLELOGGING" id="allelogging-list">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <div class="p-1">
                        <avi-joblogging-searcherwithdetail [context-id]="ModelId"></avi-joblogging-searcherwithdetail>
                    </div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
        <!-- <avi-tabPanel title="CORE.COMMON.MODEL.JOB.ALLEIMPROWS" id="alleimprows-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-jobimpexprow-searcher [context-id]="ModelId" [enable-panel]="true"></avi-jobimpexprow-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel> -->
    </avi-tabView>

</avi-core-page>

