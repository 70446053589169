<avi-core-page title="Dokumente" [card]="Card" [collapsible]="false" [style]="{ overflow: 'auto' }">

    <div class="document-container">
    <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-around end" class="p-2">
        <div fxFlex>
            <form (ngSubmit)="searchButtonPressed()" #form="ngForm" autocomplete="off" name="lastpass-disable-search">
                <!-- <label class="label-nonmd">Suchbegriff</label> -->

                <div class="p-inputgroup">
                    <input
                        pInputText
                        #searcherInput
                        style="width: 100%;"
                        [(ngModel)]="SearchValue"
                        (ngModelChange)="_searchValueChanged($event)"
                        name="SearchValue"
                        placeholder="Suchbegriff"
                        type="text"
                        autocomplete="off"
                    />
                    <div *ngIf="SearchValue" (click)="clearSearchValue()" class="p-inputgroup-addon color-red"><i class="pi pi-times"></i></div>
                </div>
            </form>
        </div>

        <div fxFlex="initial">
            <button pButton label="Suchen" icon="pi pi-search" class="p-button-primary" (onClick)="searchButtonPressed()"></button>
        </div>

        <div fxFlex="initial">
            <div class="pb-2">
                <p-checkbox name="groupname" [binary]="true" [(ngModel)]="FullTextSearch" label="Volltext Suche"></p-checkbox>
            </div>
        </div>
    </div>

    <div class="p-2">

        <span *ngIf="createItems?.length > 0">
            <button
                type="button"
                pButton
                label="Neu"
                [disabled]="blockedButtons"
                icon="pi pi-plus"
                class="p-button-success w-100p-m mr-2 mb-1"
                (click)="menuCreate.toggle($event)"
            ></button>
            <p-menu
                #menuCreate
                appendTo="body"
                [popup]="true"
                [model]="createItems"
                [style]="{ minWidth: '18em', width: 'auto' }"
            ></p-menu>
        </span>

        <span *ngIf="editItems?.length > 0">
            <button
                type="button"
                pButton
                label="Ändern"
                [disabled]="blockedButtons"
                icon="pi pi-pencil"
                class="p-button-primary w-100p-m mr-2 mb-1"
                (click)="menuEdit.toggle($event)"
            ></button>
            <p-menu
                #menuEdit
                appendTo="body"
                [popup]="true"
                [model]="editItems"
                [style]="{ minWidth: '18em', width: 'auto' }"
            ></p-menu>
        </span>

        <span *ngIf="exportItems?.length > 0">
            <button
                type="button"
                pButton
                label="Öffnen"
                [disabled]="blockedButtons"
                icon="pi pi-external-link"
                class="p-button-primary w-100p-m mr-2 mb-1"
                (click)="menuEdit.toggle($event)"
            ></button>
            <p-menu
                #menuEdit
                appendTo="body"
                [popup]="true"
                [model]="exportItems"
                [style]="{ minWidth: '18em', width: 'auto' }"
            ></p-menu>
        </span>

        <span>
            <button
                type="button"
                pButton
                label="aviDocHandler"
                [icon]="getIcon()"
                [ngClass]="getAviDocBtnClass()"
                (click)="connectAviDocHandlerButtonPressed()"
                [hidden]="!docService.DocumentServiceEnabled"
            ></button>
        </span>

        <span>
            <button
                type="button"
                pButton
                label="Alles einchecken"
                [disabled]="blockedButtons"
                icon="pi pi-sign-in"
                class="p-button-primary w-100p-m mr-2 mb-1"
                (click)="checkinAllOpenedFiles()"
                [hidden]="!docService.IsOnline()"
            ></button>
        </span>

        <!-- [ngClass]="{'p-button-success': blockedBtnAviDocHandler, 'p-button-primary p-button-outlined': !blockedBtnAviDocHandler,'p-button-raised': false, 'w-100p-m mr-2 mb-1': true}" -->
        <!-- <p-splitButton
            *ngIf="createItems?.length > 0"
            label="Neu"
            icon="pi pi-plus"
            (onClick)="createDocument()"
            [model]="createItems"
            [disabled]="blockedButtons"
            [menuStyle]="{ minWidth: '18em' }"
            styleClass="p-button-success w-100p-m mr-2 mb-1"
        ></p-splitButton> -->

        <!-- <p-splitButton
            *ngIf="editItems?.length > 0"
            label="Ändern"
            icon="pi pi-pencil"
            (onClick)="editDocument(SelectedDocument)"
            [model]="editItems"
            [disabled]="blockedButtons"
            [menuStyle]="{ minWidth: '18em' }"
            styleClass="w-100p-m mr-2 mb-1"
        ></p-splitButton> -->

        <!-- <p-splitButton
            *ngIf="exportItems?.length > 0"
            label="Öffnen"
            icon="pi pi-external-link"
            (onClick)="exportDocument(SelectedDocument)"
            [disabled]="blockedButtons"
            [model]="exportItems"
            [menuStyle]="{ minWidth: '18em' }"
            styleClass="w-100p-m mr-2 mb-1"
        ></p-splitButton> -->
     </div>

    <p-splitter [panelSizes]="[10, 50, 40]" (onResizeStart)="disablePointerEvents()" (onResizeEnd)="enablePointerEvents()" [stateKey]="SettingsKey" stateStorage="local">
        <ng-template pTemplate>

        <div class="flex-column flex-1 ">
            <p-tree
                [value]="folderTree"
                [ngClass]="{ flattree: isFlatTree }"
                [style]="{ overflow: 'auto', 'height': '100%' }"
                selectionMode="single"
                [(selection)]="SelectedNode"
            >
            </p-tree>
        </div>
        </ng-template>
        <ng-template pTemplate>

        <div class="flex-column flex-1 ui-widget-content" avi-core-dragdrop (fileDropped)="onFileDropped($event)">
            <div class="docsearcher">
                <avi-core-base-searcher
                    #searcher
                    [use-router-params]="UseRouterParams"
                    [contents-padding]="false"
                    [settingskey-delegate]="getSettingsKey"
                    [toolbar]="true"
                    [toolbar-show-searchinput]="false"
                    [toolbar-show-inactivebutton]="false"
                    [toolbar-show-columnselection]="true"
                    [card]="false"
                    [rows]="0"
                    [lazy-loading]="true"
                    (onInit)="onInit($event)"
                    [search-delegate]="SearchDelegate"
                    (onSelect)="onSelectDocument($event)"
                    (onDoubleClick)="onDoubleClickDocument($event)"
                    field-name-id="ArchivDocumentID"
                    [select-first-result]="false"
                    [selectionMode]="'single'"
                    [row-detailview-template]="docdetailview"
                    [getContextMenuItems]="getContextMenuItems"
                    >
                </avi-core-base-searcher>
            </div>
        </div>

        <ng-template #docdetailview let-record>
            <ng-container>
                <avi-crm-dokument-details-view
                    [context-id]="contextId"
                    [context-type]="contextType"
                    [card]="false"
                    [api]="Api"
                    [api-prefix]="ApiPrefix"
                    [document]="record"
                ></avi-crm-dokument-details-view>
            </ng-container>
        </ng-template>

        </ng-template>

        <ng-template pTemplate *ngIf="ShowPreview">
            <avi-crm-dokument-preview #preview [api]="Api" [pdfviewer-class]="splitterIFrameHack"></avi-crm-dokument-preview>
        </ng-template>

    </p-splitter>

    <div *ngIf="Mode == 'selection'" class="grid justify-content-end mt-4">
        <p-checkbox *ngIf="ShowInternal" class="mr-2" [(ngModel)]="Internal" label="{{ 'CORE.COMMON.MODEL.AVIDOC.INTERNAL' | translate }}" [binary]="true" [readonly]="InternalReadonly"></p-checkbox>

        <button
            pButton
            type="button"
            [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
            (click)="doSchliessen()"
            class="w-100p-m m mr-2 p-button-secondary"
        ></button>
        <button
            pButton
            type="button"
            [label]="'Selektieren'"
            (click)="doSelektieren()"
            [disabled]="_selectedDocument == null"
            class="w-100p-m m"
        ></button>
    </div>

    <!-- <div fxFlex="initial">
        <button pButton label="Suchen" icon="pi pi-search" class="p-button-primary" (onClick)="searchButtonPressed()"></button>
    </div> -->
</div>
    
</avi-core-page>

<!-- <avi-core-dbg
[m]="blockedBtnAviDocHandler"
></avi-core-dbg> -->
