import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { Injectable } from '@angular/core';

import { AviApiService, AviListDetailConst } from '@avi-x/avi-core';
import { StatusWechselDef } from '@avi-x/avi-dto/workflow/statuswechseldef.model';

@Injectable()
export class AviActivityService {
    constructor(public apiService: AviApiService) { }

    public async GetStatusWorkflowDefStatusWechsel(activityId: string): Promise<StatusWechselDef[]> {
        return this.apiService.getModelList(StatusWechselDef, `activity/getCAFStatusWorkflowDefStatusWechsel/${activityId}`);
    }

    public async IsFinishedState(activityId: string, newstatusdefId: string) {
        return this.apiService.get(`activity/IsFinishedState?activity_id=${activityId}&newstatusdef_id=${newstatusdefId}`);
    }

    public async IsRTF(activityId: string) {
        const res = await this.apiService.get(`activity/IsRTF/${activityId}`);
        return res;
    }

    public GetPriorityIcon(priority: ListType) {
        let icon = 'pi-circle-off';
        if (priority.Id === AviListDetailConst.AP_HOCH)
            icon = 'pi-arrow-up color-red';
        else if (priority.Id === AviListDetailConst.AP_TIEF)
            icon = 'pi-arrow-down color-green';

        return icon;
    }
}
