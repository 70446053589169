import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { AppComponent } from './app.component';

@Component({
    selector: 'avi-vwbp-menu',
    template: `
        <ul app-submenu [item]="model" [root]="true" class="ultima-menu ultima-main-menu clearfix" [reset]="reset" [visible]="true"></ul>
    `
})
export class AviVwbPMenuComponent implements OnInit {
    @Input() reset: boolean;

    model: any[];

    constructor(public app: AppComponent) { }

    IsLoggedin(): boolean {
        return this.app.IsLoggedIn;
    }

    IsAdmin() {
        return false;
        // return this.app.authService.IsAdmin;
    }

    IsDebugEnabled(): boolean {
        return localStorage.getItem('avix::debug::enabled') === 'YES';
    }

    HasRole(role: string): boolean {
        return false;
        // return this.app.authService.currentUserHasRole(role);
    }

    ngOnInit() {
        this.model = [
            { label: 'Startseite', icon: 'home', routerLink: ['/'] },
            {
                label: 'VwbP', icon: 'accessibility', auth: (event) => { return this.HasRole('CAFNatperson suchen'); }, command: (event) => { console.log('crm link mit untergruppe'); },
                items: [
                    { label: 'Rechtsträger suchen', routerLink: ['/vwbp/rtlist'] },
                ]
            },

            {
                label: 'Stammdaten', icon: 'build', auth: (event) => { return this.IsDebugEnabled() && this.IsLoggedin(); },
                items: [
                    { label: 'Länder', icon: 'flag', routerLink: ['/crm/land'] },
                    { label: 'Währungen', icon: 'attach_money', routerLink: ['/crm/land'] },
                    { label: 'Abfragedefinitionen', /* icon: 'flag', */routerLink: ['/reporting/querydef'] },
                ]
            },

            {
                label: 'Themes', icon: 'palette',
                auth: (event) => { return this.IsAdmin()/* && this.IsDebugEnabled() */; },
                items: [
                    { label: 'Aviita', icon: 'brush', command: (event) => { this.changeTheme('aviita'); } },
                    { label: 'Indigo - Pink', icon: 'brush', command: (event) => { this.changeTheme('indigo'); } },
                    { label: 'Brown - Green', icon: 'brush', command: (event) => { this.changeTheme('brown'); } },
                    { label: 'Blue - Amber', icon: 'brush', command: (event) => { this.changeTheme('blue'); } },
                    { label: 'Blue Grey - Green', icon: 'brush', command: (event) => { this.changeTheme('blue-grey'); } },
                    { label: 'Dark - Blue', icon: 'brush', command: (event) => { this.changeTheme('dark-blue'); } },
                    { label: 'Dark - Green', icon: 'brush', command: (event) => { this.changeTheme('dark-green'); } },
                    { label: 'Green - Yellow', icon: 'brush', command: (event) => { this.changeTheme('green'); } },
                    { label: 'Purple - Cyan', icon: 'brush', command: (event) => { this.changeTheme('purple-cyan'); } },
                    { label: 'Purple - Amber', icon: 'brush', command: (event) => { this.changeTheme('purple-amber'); } },
                    { label: 'Teal - Lime', icon: 'brush', command: (event) => { this.changeTheme('teal'); } },
                    { label: 'Cyan - Amber', icon: 'brush', command: (event) => { this.changeTheme('cyan'); } },
                    { label: 'Grey - Deep Orange', icon: 'brush', command: (event) => { this.changeTheme('grey'); } }
                ]
            }
        ];
    }

    changeTheme(theme) {
        this.app.changeTheme(theme);
    }
}


@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
          <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
              <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass" *ngIf="child.visible === false ? false : (child.auth == null || child.auth == undefined || child.auth())">
                  <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
                     class="ripplelink" *ngIf="!child.routerLink"
                      [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                      <i *ngIf="child.icon" class="material-icons">{{child.icon}}</i>
                      <span>{{child.label}}</span>
                      <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                      <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
                  </a>

                  <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink" *ngIf="child.routerLink    && (child.auth == null || child.auth == undefined || child.auth())"
                      [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                     [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                      <i *ngIf="child.icon" class="material-icons">{{child.icon}}</i>
                      <span>{{child.label}}</span>
                      <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                      <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
                  </a>
                  <div class="layout-menu-tooltip">
                      <div class="layout-menu-tooltip-arrow"></div>
                      <div class="layout-menu-tooltip-text">{{child.label}}</div>
                  </div>
                  <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset" [parentActive]="isActive(i)"
                      [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                      'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
              </li>
          </ng-template>
      `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                'height': '0px'
            })),
            state('visibleAnimated', style({
                'height': '*'
            })),
            state('visible', style({
                'height': '*',
                'z-index': 100
            })),
            state('hidden', style({
                'height': '0px',
                'z-index': '*'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {
    @Input() item: any;

    @Input() root: boolean;

    @Input() visible: boolean;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    constructor(public app: AppComponent) { }

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;

        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                this.app.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isHorizontal() || this.app.isSlim()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
