import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { ListDetail } from '@avi-x/avi-dto/system/listdetail.model';
import { Expose, Type } from 'class-transformer';

export class VwbPEmailDef extends BaseModel {
    @Expose()
    public get Recipient(): string { return this.getProperty('Recipient'); }
    public set Recipient(value: string) { this.setProperty('Recipient', value); }

    @Expose()
    public get Subject(): string { return this.getProperty('Subject'); }
    public set Subject(value: string) { this.setProperty('Subject', value); }

    @Expose()
    public get Message(): string { return this.getProperty('Message'); }
    public set Message(value: string) { this.setProperty('Message', value); }

    @Expose()
    public get OldStatus_ID(): string { return this.getModelId('OldStatus_ID'); }
    public set OldStatus_ID(value: string) { this.setModelId('OldStatus_ID', value); }

    @Expose()
    @Type(() => ListDetail)
    public get OldStatus(): ListDetail { return this.getModel('OldStatus'); }
    public set OldStatus(value: ListDetail) { this.setModel('OldStatus', value); }

    @Expose()
    public get NewStatus_ID(): string { return this.getModelId('NewStatus_ID'); }
    public set NewStatus_ID(value: string) { this.setModelId('NewStatus_ID', value); }

    @Expose()
    @Type(() => ListDetail)
    public get NewStatus(): ListDetail { return this.getModel('NewStatus'); }
    public set NewStatus(value: ListDetail) { this.setModel('NewStatus', value); }

    @Expose()
    @Type(() => ListType)
    public get Event(): ListType { return this.getProperty('Event'); }
    public set Event(value: ListType) { this.setProperty('Event', value); }

    @Expose()
    @Type(() => ListType)
    public get SendenBeiErfolg(): ListType { return this.getProperty('SendenBeiErfolg'); }
    public set SendenBeiErfolg(value: ListType) { this.setProperty('SendenBeiErfolg', value); }

    @Expose()
    @Type(() => ListType)
    public get SendenBeiWarnung(): ListType { return this.getProperty('SendenBeiWarnung'); }
    public set SendenBeiWarnung(value: ListType) { this.setProperty('SendenBeiWarnung', value); }

    @Expose()
    @Type(() => ListType)
    public get SendenBeiFehler(): ListType { return this.getProperty('SendenBeiFehler'); }
    public set SendenBeiFehler(value: ListType) { this.setProperty('SendenBeiFehler', value); }
}
