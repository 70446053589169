<h1 mat-dialog-title>{{pageTitle}}</h1>
<div mat-dialog-content>
  <mat-form-field style="width: 100%">
    <mat-label>Titel</mat-label>
    <input #inputTitle cdkFocusInitial matInput [(ngModel)]="data.Title">
  </mat-form-field>

  <mat-form-field style="width: 100%">
    <mat-label>Url</mat-label>
    <input matInput [(ngModel)]="data.Url">
  </mat-form-field>

  <mat-form-field style="width: 100%">
    <mat-label>Sorter</mat-label>
    <input matInput type="number" [(ngModel)]="data.Sorter">
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Abbrechen</button>
  <button mat-button [mat-dialog-close]="data" >Ok</button>
</div>
