<div class="ui-g">
    <div class="ui-g-12 ui-md-12 pl-0">
        <div *ngIf="NewCommentId">
            <div class="new-comment">Neue Bemerkung</div>

            <vwbp-intern-unstimmigkeit-comment-view
                [comment-id]="NewCommentId"
                [context-id]="ContextId"
                (onCancelled)="OnCreateCommentCancelled()"
                (onSaved)="OnCreateCommentSaved()"
            >
            </vwbp-intern-unstimmigkeit-comment-view>
        </div>

        <div class="pb-4p">
            <button
                *ngIf="!NewCommentId && !EditCommentId"
                pButton
                icon="pi pi-comment"
                type="button"
                style="width: auto;"
                class="mr-2 p-button-primary"
                aviThrottledClick
                (throttledClick)="CreateComment()"
                label="Bemerkung erfassen"
                title="Kommentieren"
            ></button>
        </div>
    </div>
</div>

<div *ngIf="CommentIds.length > 0">
    <div [ngClass]="{ 'separator-nomargin': true }"></div>
</div>

<div *ngFor="let commentId of CommentIds">
    <vwbp-intern-unstimmigkeit-comment-view
        [show-buttons]="!EditCommentId"
        [comment-id]="commentId"
        (onDeleted)="OnDeleteComment(commentId)"
        (onEdit)="OnEditComment(commentId)"
        (onCancelled)="OnEditCommentCancelled(commentId)"
        (onSaved)="OnEditCommentSaved(commentId)"
    >
    </vwbp-intern-unstimmigkeit-comment-view>

    <div class="separator-nomargin"></div>
</div>
