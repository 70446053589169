<!-- <ngx-smart-modal
    #myDialog
    [identifier]="identifier"
    [closable]="showCloseIcon"
    [dismissable]="closeOnEscape"
    [escapable]="closeOnEscape"
    customClass="nsm-centered nopad-force avi-dialog"
    (onOpen)="onOpen()"
    (onOpenFinished)="onOpenFinished()"
>
    <ng-content></ng-content>
</ngx-smart-modal> -->

<ejs-dialog
    [id]="identifier"
    #Dialog
    isModal="true"
    [closeOnEscape]="closeOnEscape"
    [showCloseIcon]="showCloseIcon"
    [width]="width"
    [focus]="true"
    [visible]="false"
    (open)="onOpenFinished($event)"
    cssClass="nopad-force avi-dialog avi-dialog-contents"
>
    <div [class]="DialogContentsClass">
        <ng-content></ng-content>
    </div>
</ejs-dialog>