import { GenericRef } from '@avi-x/avi-dto/shared/genericref.model';
import { AviDocumentContainerVM } from '@avi-x/avi-dto/avidoc/avidocumentcontainer-vm.model';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviAuthService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviDokumentAblageListDetailConst, AviListDetailConst, ObjectUtils } from '@avi-x/avi-core';
import { AviDocArt } from '@avi-x/avi-dto/avidoc_def/avidocart.model';
import { AviDocumentKeywordContainer } from '@avi-x/avi-dto/avidoc/avidocumentkeywordcontainer.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ListType } from '@avi-x/avi-dto/shared';

const nameof = (nameFunction: ((obj: AviDocumentContainerVM) => any)) => typed_nameof<AviDocumentContainerVM>(nameFunction);

export enum DokumentFormMode {
    // eslint-disable-next-line no-unused-vars
    CREATE, UPDATE, UPDATESTATUS, UPDATEKEYWORDS
}

@Component({
    selector: 'avi-crm-dokument-erfassen-form',
    template: `
    <avi-core-base-form #form
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons"
        [validation-delegate]="ValidateData">

        <div formFieldTemplate1>
            <div class="form-field">
                <avi-file-upload-button label="Datei wählen" (onUpload)="onSelect($event)"></avi-file-upload-button>
            </div>
        </div>

    </avi-core-base-form>`,
    styleUrls: ['./dokument-erfassen-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDokumentErfassenFormComponent implements OnInit, OnDestroy {
    DokumentFormMode: DokumentFormMode = DokumentFormMode.CREATE;

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    // @ViewChild('upfile') upfile: ElementRef;

    public get FormTitle(): string {
        if (this.DokumentFormMode === DokumentFormMode.CREATE)
            return 'Dokument ablegen';
        else if (this.DokumentFormMode === DokumentFormMode.UPDATE)
            return 'Dokument ändern';
        else if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS)
            return 'Status ändern';
        else if (this.DokumentFormMode === DokumentFormMode.UPDATEKEYWORDS)
            return 'Metadaten ändern';
    }

    public Loading: boolean = false;
    public Model: AviDocumentContainerVM = null;

    private contextId: string;
    private contextType: string;

    public Fields: AviFormField[] = [];

    @Input('card')
    public Card: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('context-id')
    public set ContextId(value: string) {
        this.contextId = value;
    }

    @Input('context-type')
    public set ContextType(value: string) {
        this.contextType = value;
    }

    @Input('has-esignatur')
    public HasEsignatur: boolean = false;

    @Input('api')
    public Api: string = '/avidoc';

    @Input('api-prefix')
    public ApiPrefix: string = '';

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    File: File;

    DocKategorieID: string = null;

    abbrechenButton: any = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { if (this.ref) this.ref.close(null); } }];

    private blockKeywordUpdate: boolean = false;

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: any, dokumentFormMode: DokumentFormMode, file: File = null, docArtId: string = null, docArtGruppe: string = null, docArtUntergruppe: string = null): Promise<boolean> {
        this.Form?.clearFormMessages();

        if (value == '0' && dokumentFormMode == DokumentFormMode.CREATE) {
            this.DokumentFormMode = dokumentFormMode;
            await this.initNewModel(file, docArtId, docArtGruppe, docArtUntergruppe);
        } else if (value != null && (dokumentFormMode == DokumentFormMode.UPDATE || dokumentFormMode == DokumentFormMode.UPDATESTATUS || dokumentFormMode == DokumentFormMode.UPDATEKEYWORDS)) {
            this.DokumentFormMode = dokumentFormMode;
            await this.loadModel(value);
        } else {
            this.Model = null;
            return;
        }

        // this.upfile.nativeElement.value = '';

        this.cdr.markForCheck();

        return true;
    }

    async onAttrChange(data: AviChangedAttr) {
        if (this.Model) {
            const paramGruppe = this.Fields.find(w => w.Name === nameof(c => c.DocArtGruppe));
            const paramUntergruppe = this.Fields.find(w => w.Name === nameof(c => c.DocArtUntergruppe));
            const paramDocArt = this.Fields.find(w => w.Name === nameof(c => c.DocArt_ID));

            if (data.field == nameof(c => c.DocArt_ID)) {
                await this.SetFromDocArt();
                await this.handleDocArtChanged();
                await this.updateDropdownSources();
            } else if (data.field === nameof(c => c.DocArtGruppe)) {
                await this.updateDropdownSources();

                if (this.Model.DocArtGruppe) {
                    if (!paramUntergruppe.DropdownDatasourceInternal?.some(w => w.value === this.Model.DocArtUntergruppe))
                        this.Model.DocArtUntergruppe = null;
                    else if (paramUntergruppe.DropdownDatasourceInternal?.length == 1)
                        this.Model.DocArtUntergruppe = paramUntergruppe.DropdownDatasourceInternal[0].value;

                    if (paramDocArt.DropdownDatasourceInternal?.length == 1 && paramDocArt.DropdownDatasourceInternal[0].value !== this.Model.DocArt_ID) {
                        this.Model.DocArt_ID = paramDocArt.DropdownDatasourceInternal[0].value;
                        await this.SetFromDocArt();
                        await this.handleDocArtChanged();
                        await this.updateDropdownSources();
                    } else
                        this.clearDocArt();
                }
            } else if (data.field === nameof(c => c.DocArtUntergruppe)) {
                await this.updateDropdownSources();

                if (paramDocArt.DropdownDatasourceInternal?.length == 1) {
                    this.Model.DocArt_ID = paramDocArt.DropdownDatasourceInternal[0].value;
                    this.SetFromDocArt();
                }
            }

            if ((data.field == nameof(c => c.DocArtGruppe) || data.field == nameof(c => c.DocArtUntergruppe)) &&
                ((this.Model.DocArtGruppe && !paramGruppe.DropdownDatasourceInternal?.some(w => w.value === this.Model.DocArtGruppe)) ||
                 (this.Model.DocArtUntergruppe && !paramUntergruppe.DropdownDatasourceInternal?.some(w => w.value === this.Model.DocArtUntergruppe)))) {
                this.clearDocArt();
            }

            if (data.field == nameof(c => c.DocArtGruppe) && !this.Model.DocArtGruppe && paramGruppe.DropdownDatasourceInternal?.length > 0) {
                this.clearDocArt();
            }

            if (data.field == nameof(c => c.DocArtUntergruppe) && !this.Model.DocArtUntergruppe && paramUntergruppe.DropdownDatasourceInternal?.length > 0) {
                this.Model.DocArtGruppe = null;
                this.clearDocArt();
            }

            await this.updateKeywords();
        }
    }

    private clearDocArt() {
        this.Model.DocArt_ID = null;
        this.Model.DocArt = null;
        this.removeKeywordFields();
        this.cdr.markForCheck();
    }

    async updateKeywords(ignoreBlock: boolean = false) {
        if (this.Model) {
            console.log(this.Model);
            const res = await this.apiService.postModel(AviDocumentContainerVM, `${this.Api}/CreateAndFillDocKeywords`, this.Model);
            
            // soll verhindern, dass beim Speichern ältere results verwendet werden (manchmal wird sonst der Titel abgeschnitten)
            if (!this.blockKeywordUpdate || ignoreBlock) {
                this.Model.alleKeywords = res?.alleKeywords;
                this.cdr.markForCheck();
            }
        }
    }

    async SetFromDocArt() {
        if (this.Model.DocArt_ID) {
            this.Model.DocArt = await this.apiService.getModelById(AviDocArt, `${this.Api}/GetDocArt`, this.Model.DocArt_ID);
            this.Model.DocArtGruppe = this.Model.DocArt.KeywordGruppeName;
            this.Model.DocArtUntergruppe = this.Model.DocArt.KeywordUntergruppeName;
        }
    }

    async handleDocArtChanged() {
        if (this.Model.DocArt) {
            this.Model.Status = this.Model.DocArt.DefaultStatus;
            if (this.Model.DocKategorie_ID !== this.Model.DocArt.vonDocKategorie_ID) {
                this.Model.DocKategorie_ID = this.Model.DocArt.vonDocKategorie_ID;
                await this.initFromDocKategorie();
            }
        }

        if (!this.Model.DocArt || !this.Model.DocKategorie_ID)
            this.removeKeywordFields();
    }

    private showKeyword(w: AviDocumentKeywordContainer): boolean {
        // Keywords die eh schon im View sind entfernen...
        if (w.DocKeywordDef.ManuellAenderbar.Id === AviListDetailConst.NEIN ||
            (w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocArt' && (
                w.DocKeywordDef.Fachschluessel_AttrName === 'KeywordGruppeName' ||
                w.DocKeywordDef.Fachschluessel_AttrName === 'KeywordUntergruppeName' ||
                w.DocKeywordDef.Fachschluessel_AttrName === 'KeywordDocArtName')) ||
            (w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocumentContainer' && (
                w.DocKeywordDef.Fachschluessel_AttrName === 'Dateidatum' ||
                w.DocKeywordDef.Fachschluessel_AttrName === 'Status' ||
                w.DocKeywordDef.Fachschluessel_AttrName === 'Dateiname' ||
                w.DocKeywordDef.Fachschluessel_AttrName === 'Titel' ||
                w.DocKeywordDef.Fachschluessel_AttrName === 'Visibility' ||
                w.DocKeywordDef.Fachschluessel_AttrName === 'Abstract')))
            return false;

        return true;
    }

    private removeKeywordFields() {
        const index = this.Fields.findIndex(w => w.Name && w.Name.startsWith('Keywords_'));
        if (index > 0)
            this.Fields.splice(index);
    }

    async initFromDocKategorie() {
        this.removeKeywordFields();

        await this.updateKeywords();

        this.Model.alleKeywords.forEach((w, index) => {
            if (!this.showKeyword(w))
                return;

            const kwtyp = w.DocKeywordDef.Keywordtype.Id;
            const zwingend: boolean = w.DocKeywordDef.Zwingend.Id === AviListDetailConst.JA;
            if (kwtyp == AviListDetailConst.ATTRTYPE_INTEGER)
                this.Fields.push(this.formFieldService.CreateNumber('Keywords_' + index, w.DocKeywordDef.Bezeichnung1, null, zwingend).setMDSizeFull());
            else if (kwtyp == AviListDetailConst.ATTRTYPE_DECIMAL)
                this.Fields.push(this.formFieldService.CreateNumber('Keywords_' + index, w.DocKeywordDef.Bezeichnung1, null, zwingend).setMDSizeFull());
            else if (kwtyp == AviListDetailConst.ATTRTYPE_TEXT)
                this.Fields.push(this.formFieldService.CreateText('Keywords_' + index, w.DocKeywordDef.Bezeichnung1, zwingend).setMDSizeFull());
            else if (kwtyp == AviListDetailConst.ATTRTYPE_DATE)
                this.Fields.push(this.formFieldService.CreateDate('Keywords_' + index, w.DocKeywordDef.Bezeichnung1, zwingend).setMDSizeFull());
            else if (kwtyp == AviListDetailConst.ATTRTYPE_LISTTYPE) 
                this.Fields.push(this.formFieldService.CreateDropdown('Keywords_' + index, w.DocKeywordDef.Bezeichnung1,
                async (field) => {
                    const ret = [];
        
                    if (!(w.DocKeywordDef.vonListName))
                        return ret;
                
                    const p = await this.apiService.get(`${this.Api}/model/representations/list/${w.DocKeywordDef.vonListName}`);
                    p.forEach(elem => {
                        ret.push({ label: elem.Representation, value: elem.Id });
                    });
                
                    return ret.sort((a, b) => a.label > b.label ? 1 : -1);
                }, zwingend, false, null, (m) => (`${m.Representation}`), 'Id', false, false).setMDSizeHalf());            
            
            // else if (kwtyp == SysListDetailConst.ATTRTYPE_REFERENCE)    // TODO, wenn's das mal braucht...
            // else if (kwtyp == SysListDetailConst.ATTRTYPE_GUID)
        });
    }

    public async initNewModel(file: File = null, docArtId: string = null, docArtGruppe: string = null, docArtUntergruppe: string = null) {
        this.Model = null;

        this.cdr.markForCheck();

        this.Model = await this.apiService.getModel(AviDocumentContainerVM, `${this.Api}/CreateContainer`);
        this.Model.DocumentBaseModel = new GenericRef(this.contextId, this.contextType);
        this.Model.DocArtGruppe = docArtGruppe;
        this.Model.DocArtUntergruppe = docArtUntergruppe;
        this.Model.DocArt_ID = docArtId;

        if (file)
            this.setFile(file);

        this.initFromModel();

        await this.SetFromDocArt();
        await this.handleDocArtChanged();

        await this.updateDropdownSources();

        this.initFocus();
    }

    public async loadModel(cont: any) {
        this.Model = null;

        this.cdr.markForCheck();

        this.Model = await this.apiService.postModel(AviDocumentContainerVM, `${this.Api}/CreateDocumentContainer`, cont);
        this.DocKategorieID = this.Model.DocKategorie_ID;
        this.Model.DocumentBaseModel = new GenericRef(this.contextId, this.contextType);
        this.Model.alleKeywords = await this.apiService.postModelList(AviDocumentKeywordContainer, `${this.Api}/GetDocumentKeywords?model_id=${this.contextId}&model_type=${this.contextType}&filter_editable=false`, cont);

        // --------------------------------------------------------------------------------------------------------------------
        // Analyse: VWBP-107 (Dokumenten Titel wird beim Speichern manchmal abgeschnitten)
        // --------------------------------------------------------------------------------------------------------------------
        var kwTitel = this.Model?.alleKeywords?.find(w => w?.DocKeywordDef?.IstFachschluessel?.Id === AviListDetailConst.JA
            && w?.DocKeywordDef?.Fachschluessel_AttrName === 'Titel');

        console.log('Dokument-Titel nach dem Laden des Dokumentes (Container): ' + this.Model?.Titel);
        console.log('Dokument-Titel nach dem Laden des Dokumentes (alleKeywords): ' + kwTitel?.ValueText);
        // --------------------------------------------------------------------------------------------------------------------

        if (this.DokumentFormMode === DokumentFormMode.UPDATE)
            this.Model.Dateiname = null;

        this.initFromModel();

        await this.initFromDocKategorie();
        await this.SetFromDocArt();

        this.Model.AktuellerStatus = this.Model.Status;

        await this.updateDropdownSources();

        this.initFocus();
    }

    initFocus() {
        setTimeout(() => this.Form.focusField(nameof(c => c.DocArt_ID), false), 200);
    }

    ValidateData = (): boolean => {
        if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS && this.Model && this.Model.AktuellerStatus?.Id === this.Model.Status?.Id)
            return false;

        return true;
    }

    private canEditTitel(): boolean {
        if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS) return false;

        if (this.DokumentFormMode === DokumentFormMode.UPDATEKEYWORDS ||
            this.DokumentFormMode === DokumentFormMode.UPDATE) {
            const keyword = this.Model.alleKeywords.find(w => w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocumentContainer' && w.DocKeywordDef.Fachschluessel_AttrName === 'Titel');
            return keyword == null || keyword.DocKeywordDef.ManuellAenderbar.Id === AviListDetailConst.JA;
        }

        return true;
    }

    private canEditVisibility(): boolean {
        if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS) return false;

        if (this.DokumentFormMode === DokumentFormMode.UPDATEKEYWORDS ||
            this.DokumentFormMode === DokumentFormMode.UPDATE) {
            const keyword = this.Model.alleKeywords.find(w => w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocumentContainer' && w.DocKeywordDef.Fachschluessel_AttrName === 'Visibility');
            return keyword != null && keyword.DocKeywordDef.ManuellAenderbar.Id === AviListDetailConst.JA;
        }

        return true;
    }

    private canEditAbstract(): boolean {
        if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS) return false;

        if (this.DokumentFormMode === DokumentFormMode.UPDATEKEYWORDS ||
            this.DokumentFormMode === DokumentFormMode.UPDATE) {
            const keyword = this.Model.alleKeywords.find(w => w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocumentContainer' && w.DocKeywordDef.Fachschluessel_AttrName === 'Abstract');
            return keyword == null || keyword.DocKeywordDef.ManuellAenderbar.Id === AviListDetailConst.JA;
        }

        return true;
    }

    private canEditDateidatum(): boolean {
        if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS) return false;

        if (this.DokumentFormMode === DokumentFormMode.UPDATEKEYWORDS ||
            this.DokumentFormMode === DokumentFormMode.UPDATE) {
            const keyword = this.Model.alleKeywords.find(w => w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocumentContainer' && w.DocKeywordDef.Fachschluessel_AttrName === 'Dateidatum');
            return keyword == null || keyword.DocKeywordDef.ManuellAenderbar.Id === AviListDetailConst.JA;
        }

        return true;
    }

    private canEditDocArt(): boolean {
        if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS) return false;

        if (this.DokumentFormMode === DokumentFormMode.UPDATEKEYWORDS ||
            this.DokumentFormMode === DokumentFormMode.UPDATE) {
            const keyword = this.Model.alleKeywords.find(w => w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocArt' && w.DocKeywordDef.Fachschluessel_AttrName === 'KeywordDocArtName');
            return keyword == null || keyword.DocKeywordDef.ManuellAenderbar.Id === AviListDetailConst.JA;
        }

        return true;
    }

    private canEditDocArtGruppe(): boolean {
        if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS) return false;

        if (this.DokumentFormMode === DokumentFormMode.UPDATEKEYWORDS ||
            this.DokumentFormMode === DokumentFormMode.UPDATE) {
            const keyword = this.Model.alleKeywords.find(w => w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocArt' && w.DocKeywordDef.Fachschluessel_AttrName === 'KeywordGruppeName');
            return keyword == null || keyword.DocKeywordDef.ManuellAenderbar.Id === AviListDetailConst.JA;
        }

        return true;
    }

    private canEditDocArtUntergruppe(): boolean {
        if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS) return false;

        if (this.DokumentFormMode === DokumentFormMode.UPDATEKEYWORDS ||
            this.DokumentFormMode === DokumentFormMode.UPDATE) {
            const keyword = this.Model.alleKeywords.find(w => w.DocKeywordDef.Fachschluessel_ModelMeta_ModelName === 'AviDocArt' && w.DocKeywordDef.Fachschluessel_AttrName === 'KeywordUntergruppeName');
            return keyword == null || keyword.DocKeywordDef.ManuellAenderbar.Id === AviListDetailConst.JA;
        }

        return true;
    }

    private initFromModel() {
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DocArt_ID), !this.canEditDocArt());
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DocArtGruppe), !this.canEditDocArtGruppe());
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DocArtUntergruppe), !this.canEditDocArtUntergruppe());
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Titel), !this.canEditTitel());
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Abstract), !this.canEditAbstract());
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Dateidatum), !this.canEditDateidatum());
        
        let canEditVisibility: boolean = this.canEditVisibility();
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Visibility), canEditVisibility);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Visibility), canEditVisibility);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DocArt_ID), this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.DocArt_ID), this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS);
        this.formFieldService.setFieldVisible(this.Fields, 'formFieldTemplate1', this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS && this.DokumentFormMode !== DokumentFormMode.UPDATEKEYWORDS);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Dateiname), this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS && this.DokumentFormMode !== DokumentFormMode.UPDATEKEYWORDS);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Dateidatum), this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Abstract), this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS);

        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Dateiname), this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS && this.DokumentFormMode !== DokumentFormMode.UPDATEKEYWORDS);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Dateidatum), this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Titel), this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Status), this.DokumentFormMode === DokumentFormMode.UPDATESTATUS || this.DokumentFormMode === DokumentFormMode.CREATE || this.DokumentFormMode === DokumentFormMode.UPDATE);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AktuellerStatus), this.DokumentFormMode === DokumentFormMode.UPDATESTATUS);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DigitalSignieren), this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS && this.DokumentFormMode !== DokumentFormMode.UPDATEKEYWORDS && this.HasEsignatur);

        if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS) {
            // Archiviert -> Freigegeben
            if (this.Model.Status.Id == AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_ARCHIV)
                this.formFieldService.addFieldHiddenItems(this.Fields, nameof(c => c.Status), [AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_BEARBEITUNG, AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_PRUEFUNG], 'Id');

            // In Bearbeitung -> Freigegeben
            // In Bearbeitung -> Prüfung
            // Prüfung -> Freigegeben
            // Prüfung -> In Bearbeitung
            if (this.Model.Status.Id == AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_BEARBEITUNG || this.Model.Status.Id == AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_PRUEFUNG)
                this.formFieldService.addFieldHiddenItems(this.Fields, nameof(c => c.Status), [AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_ARCHIV], 'Id');

            // Freigegeben -> Archiviert
            if (this.Model.Status.Id == AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_FREIGABE)
                this.formFieldService.addFieldHiddenItems(this.Fields, nameof(c => c.Status), [AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_BEARBEITUNG, AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_PRUEFUNG], 'Id');
        } else {
            this.formFieldService.clearFieldHiddenItems(this.Fields, nameof(c => c.Status));

            if (this.DokumentFormMode === DokumentFormMode.CREATE)
                this.formFieldService.addFieldHiddenItems(this.Fields, nameof(c => c.Status), [AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_ARCHIV], 'Id');
            
            if (this.DokumentFormMode === DokumentFormMode.UPDATE)
                this.formFieldService.addFieldHiddenItems(this.Fields, nameof(c => c.Status), [AviDokumentAblageListDetailConst.AVIDOC_DOKUMENTSTATUS_ARCHIV], 'Id');
        }
    }

    ngOnInit() {

        if (this.config && this.config.data) {
            if (this.config.data.Id) {
                this.ContextId = this.config.data.Params?.DocContextId;
                this.ContextType = this.config.data.Params?.DocContextType;
                if (this.config.data.Params?.Api)
                    this.Api = this.config.data.Params?.Api;
                if (this.config.data.Params?.ApiPrefix)
                    this.ApiPrefix = this.config.data.Params?.ApiPrefix;                    
                this.HasEsignatur = this.config.data.Params?.HasEsignatur;   
                this.Card = false;
                this.actionButtons = [...this.actionButtons, ...this.abbrechenButton];
                this.initFields();
                
                this.InitForm(this.config.data.Id, this.config.data.Params?.DokumentFormMode, this.config.data.Params?.File, 
                    this.config.data.Params?.DocErfassenDocArtVorschlag_ID, this.config.data.Params?.DocErfassenDocArtGruppeVorschlag, this.config.data.Params?.DocErfassenDocArtUntergruppeVorschlag);               
            }
        }
        else 
            this.initFields();
    }

    public async Save() {
        if (this.Model)
            await this.saveModel(this.Model);
    }

    async saveModel(model: AviDocumentContainerVM) {
        
        try {
            this.Form.Saving = true;
            this.commonService.showGlobalLoader();
            this.Form.clearFormMessages();

            // Keywords vor dem Speichern ein letztes mal aktuallisieren (falls Antwort von älteren Requests reinkommen, werden diese blockiert bzw. ignoriert)
            this.blockKeywordUpdate = true;
            await this.updateKeywords(true);

            if (this.DokumentFormMode == DokumentFormMode.CREATE && this.File) {
                this.apiService.postFileWithData(`${this.Api}/WriteDocument`, this.File, model).then(r => {
                    const res = ObjectUtils.ObjectToBaseModel(AviDocumentContainerVM, r);
                    this.handleSaveDocumentSuccess(res);
                }).catch(err => {
                    this.handleSaveDocumentError(err);
                });
            }
            else if (this.DokumentFormMode === DokumentFormMode.UPDATE && this.File) {
                model.OverrideNewerVersion = false;
                this.apiService.postFileWithData(`${this.Api}/UpdateDocument`, this.File, model).then(r => {
                    this.handleSaveDocumentSuccess(model);
                }).catch(err => {
                    if (err instanceof AviApiErrorObject && err.OriginalError.error.code === 'docarchiv.document.newer.version') {
                        model.OverrideNewerVersion = true;
                        const act = () => this.apiService.postFileWithData(`${this.Api}/UpdateDocument`, this.File, model).then(r =>{
                            this.handleSaveDocumentSuccess(model);
                        }).catch(err => {
                            this.handleSaveDocumentError(err);
                        });
                        this.commonService.confirm({ message: 'Es existiert bereits eine neuere Dokumentenversion. Diese wurde in der Zwischenzeit eventuell durch einen anderen Benutzer erstellt. Soll die existierende Version ersetzt werden?', accept: act });
                    } else {
                        this.handleSaveDocumentError(err);
                    }
                });
            } else if (this.DokumentFormMode === DokumentFormMode.UPDATESTATUS && model.AktuellerStatus.Id !== model.Status.Id) {
                this.apiService.post(`${this.Api}/UpdateStatus`, model).then(r => {
                    this.handleSaveDocumentSuccess(model);
                }).catch(err => {
                    this.handleSaveDocumentError(err);
                });
            }
            else if (this.DokumentFormMode === DokumentFormMode.UPDATEKEYWORDS) {            
                
                // --------------------------------------------------------------------------------------------------------------------
                // Analyse: VWBP-107 (Dokumenten Titel wird beim Speichern manchmal abgeschnitten)
                // --------------------------------------------------------------------------------------------------------------------
                var kwTitel = this.Model?.alleKeywords?.find(w => w?.DocKeywordDef?.IstFachschluessel?.Id === AviListDetailConst.JA
                    && w?.DocKeywordDef?.Fachschluessel_AttrName === 'Titel');
                
                console.log('Dokument-Titel vor dem Speichern der Keywords (Container): ' + this.Model?.Titel);
                console.log('Dokument-Titel vor dem Speichern der Keywords (alleKeywords): ' + kwTitel?.ValueText);
                // --------------------------------------------------------------------------------------------------------------------

                this.apiService.post(`${this.Api}/UpdateKeywords`, model).then(r => {
                    this.handleSaveDocumentSuccess(model);
                }).catch(err => {
                    this.handleSaveDocumentError(err);
                });
            }
        } catch (err) {
            this.commonService.hideGlobalLoader();
            this.Form.Saving = false;
            throw err;
        }
    }

    private handleSaveDocumentSuccess(model: AviDocumentContainerVM): void
    {
        this.commonService.hideGlobalLoader();
        this.Form.Saving = false;

        this.commonService.notificateSuccess('Gespeichert');
        this.onSavedSuccessfulDelegate.emit(model);

        if (this.ref)
            this.ref.close(model);
    }

    private handleSaveDocumentError(err: any)
    {
        this.commonService.hideGlobalLoader();
        this.Form.Saving = false;

        if (err instanceof AviApiErrorObject) {
            this.Form.addFormMessage(err.ErrorMessage, err.Type);
        } else {
            this.Form.addFormMessage(JSON.stringify(err));
        }
    }

    private getParamGruppeVisible(): boolean {
        const paramGruppe = this.Fields.find(w => w.Name === nameof(c => c.DocArtGruppe));
        return paramGruppe?.Visible;
    }

    private getParamUntergruppeVisible(): boolean {
        const paramUntergruppe = this.Fields.find(w => w.Name === nameof(c => c.DocArtUntergruppe));
        return paramUntergruppe?.Visible;
    }

    async updateDropdownSources() {
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model, true);

        const paramGruppe = this.Fields.find(w => w.Name === nameof(c => c.DocArtGruppe));
        if (paramGruppe) {
            paramGruppe.Visible = paramGruppe.DropdownDatasourceInternal?.length > 0 && this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS;
            // paramGruppe.Required = paramGruppe.Visible;

            // if (this.Model && paramGruppe.DropdownDatasourceInternal.length == 1) {
            //     ObjectUtils.setByPath(this.Model, nameof(c => c.DocArtGruppe), paramGruppe.DropdownDatasourceInternal[0]);
            // }
        }

        const paramUntergruppe = this.Fields.find(w => w.Name === nameof(c => c.DocArtUntergruppe));
        if (paramUntergruppe) {
            paramUntergruppe.Visible = paramUntergruppe.DropdownDatasourceInternal?.length > 0 && this.DokumentFormMode !== DokumentFormMode.UPDATESTATUS;
            // paramUntergruppe.Required = paramUntergruppe.Visible;

            // if (this.Model && paramUntergruppe.DropdownDatasourceInternal.length == 1) {
            //     ObjectUtils.setByPath(this.Model, nameof(c => c.DocArtUntergruppe), paramUntergruppe.DropdownDatasourceInternal[0]);
            // }
        }


        if (this.Model && !this.Model.DocArt_ID) {
            const paramDocArt = this.Fields.find(w => w.Name === nameof(c => c.DocArt_ID));
            const docArtList = paramDocArt.DropdownDatasourceInternal.filter(w => w.tag !== 'HEADER');
            if (this.Model && docArtList.length == 1) {
                this.Model.DocArt_ID = docArtList[0].value;
                await this.SetFromDocArt();
                await this.handleDocArtChanged();
                await this.updateDropdownSources();
            }
        }

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    public FileChangeEvent(fileInput: any) {
        const CurrentFile = fileInput.target.files[0];

        const reader = new FileReader();
        reader.readAsDataURL(CurrentFile);
    }

    onRemove(evt) {
        this.File = null;
        if (this.Model)
            this.Model.Dateiname = null;
    }

    onClear(evt) {
        this.File = null;
        if (this.Model)
            this.Model.Dateiname = null;
    }

    // getFile() {
    //     document.getElementById('upfile').click();
    // }

    async onSelect(file) {
        if (file) {
            this.setFile(file);
            setTimeout(() => this.Form.focusField(nameof(c => c.Dateidatum), false));
            await this.updateKeywords();
        }
    }

    private setFile(file: any) {
        this.Model.Titel = file.name.replace(/\.[^\/\\.]+$/, '');
        this.Model.Dateidatum = file.lastModifiedDate;
        this.Model.Dateiname = file.name;
        this.File = file;

        const isPDF = !this.Model.Dateiname || this.Model.Dateiname.endsWith('.pdf');
        if(!isPDF)
        {
            this.Model.DigitalSignieren = new ListType(AviListDetailConst.NEIN, 'janein', 'Nein');
        }
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DigitalSignieren), !isPDF);
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DocArtGruppe), 'CORE.COMMON.MODEL.AVIDOC.DOCGRUPPE',
            async (field) => {
                const ret = [];

                if (!this.Model || !this.contextId || !this.contextType)
                    return ret;

                let url = `${this.Api}/getDocArtGruppenForModel/${this.contextId}/${this.contextType}`;
                if (this.DocKategorieID)
                    url = `${url}?kategorieId=${this.DocKategorieID}`;

                const p = await this.apiService.get(url);
                p.forEach(elem => ret.push({ label: elem, value: elem }));

                console.log(ret);

                return ret.sort((a, b) => a.label > b.label ? 1 : -1);
            }, false, false, 'Bitte wählen...', null, null, null, null).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DocArtUntergruppe), 'CORE.COMMON.MODEL.AVIDOC.DOCUNTERGRUPPE',
            async (field) => {
                const ret = [];

                if (!this.Model || !this.contextId || !this.contextType)
                    return ret;

                const filters = [];
                let url = `${this.Api}/GetDocArtUntergruppenForModel/${this.contextId}/${this.contextType}`;
                if (this.Model.DocArtGruppe)
                    filters.push(`gruppe=${this.Model.DocArtGruppe}`);
                if (this.DocKategorieID)
                    filters.push(`kategorieId=${this.DocKategorieID}`);
                if (filters.length > 0)
                    url = `${url}?${filters.join('&')}`;
    
                const p = await this.apiService.get(url);
                p.forEach(elem => ret.push({ label: elem, value: elem }));

                console.log(ret);

                return ret.sort((a, b) => a.label > b.label ? 1 : -1);
            }, false, false, 'Bitte wählen...', null, null, null, null).setMDSizeFull());

        const colDocArt = this.formFieldService.CreateDropdown(nameof(c => c.DocArt_ID), 'CORE.COMMON.MODEL.AVIDOC.DOCART',
        async (field) => {
            const ret = [];

            if (!this.Model || !this.contextId || !this.contextType)
                return ret;

            let url = `${this.Api}/GetDocArteForModel/${this.contextId}/${this.contextType}`;
            const filters = [];
            if (this.Model.DocArtGruppe)
                filters.push(`gruppe=${this.Model.DocArtGruppe}`);
            if (this.Model.DocArtUntergruppe)
                filters.push(`untergruppe=${this.Model.DocArtUntergruppe}`);
            if (this.DocKategorieID)
                filters.push(`kategorieId=${this.DocKategorieID}`);
            if (filters.length > 0)
                url = `${url}?${filters.join('&')}`;

            const p = await this.apiService.getModelList(AviDocArt, url);
            p.forEach(elem => ret.push({ label: elem.Bezeichnung1, value: elem.Id, tag: elem }));

            const sorted = ret.sort((a, b) => {
                if (a.tag.KeywordGruppeName && !b.tag.KeywordGruppeName)
                    return 1;
                else if (!a.tag.KeywordGruppeName && b.tag.KeywordGruppeName)
                    return -1;

                if (a.tag.KeywordGruppeName && b.tag.KeywordGruppeName) {
                    if (a.tag.KeywordGruppeName > b.tag.KeywordGruppeName)
                        return 1;
                    else if (a.tag.KeywordGruppeName < b.tag.KeywordGruppeName)
                        return -1;
                }

                if (a.tag.KeywordUntergruppeName && !b.tag.KeywordUntergruppeName)
                    return 1;
                else if (!a.tag.KeywordUntergruppeName && b.tag.KeywordUntergruppeName)
                    return -1;

                if (a.tag.KeywordUntergruppeName && b.tag.KeywordUntergruppeName) {
                    if (a.tag.KeywordUntergruppeName > b.tag.KeywordUntergruppeName)
                        return 1;
                    else if (a.tag.KeywordUntergruppeName < b.tag.KeywordUntergruppeName)
                        return -1;
                }

                return a.label > b.label ? 1 : -1;
            });

            sorted.unshift({ label: 'hdr', disabled: true, tag: 'HEADER' });

            return sorted;
        }, false, false, 'Bitte wählen...', null, null, null, null).setMDSizeFull();

        this.Fields.push(colDocArt);

        colDocArt.CustomTemplateDelegate = (item) => {
            const hasGruppe = this.getParamGruppeVisible();
            const hasUntergruppe = this.getParamUntergruppeVisible();
            if (item?.tag === 'HEADER') {
                if (hasGruppe && hasUntergruppe)
                    return `<div class="row"><div class="col33p">Dokumentenart</div><div class="col33p">Ordner</div><div class="col33p">Unterordner</div></div>`;
                else if (hasGruppe && !hasUntergruppe)
                    return `<div class="row row-hdr"><div class="col50p">Dokumentenart</div><div class="col50p">Ordner</div></div>`;
                else if (!hasGruppe && hasUntergruppe)
                    return `<div class="row row-hdr"><div class="col50p">Dokumentenart</div><div class="col50p">Unterordner</div></div>`;
                else 
                    return `<div class="row row-hdr"><div class="col100p">Dokumentenart</div></div>`;
            } else {
                if (hasGruppe || hasUntergruppe) {
                    if (item.value) {
                        if (hasGruppe && hasUntergruppe)
                            return `<div class="row"><div class="col33p">${item.label}</div><div class="col33p">${item.tag.KeywordGruppeName ?? ''}</div><div class="col33p">${item.tag.KeywordUntergruppeName ?? ''}</div></div>`;
                        else if (hasGruppe && !hasUntergruppe)
                            return `<div class="row"><div class="col50p">${item.label}</div><div class="col50p">${item.tag.KeywordGruppeName ?? ''}</div></div>`;
                        else if (!hasGruppe && hasUntergruppe)
                            return `<div class="row"><div class="col50p">${item.label}</div><div class="col50p">${item.tag.KeywordUntergruppeName ?? ''}</div></div>`;
                    }
                }
                return item.label;
            }
        };


        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Dateiname), 'CORE.COMMON.MODEL.AVIDOC.FILENAME', false).setReadonly(true).setMDSize(10).setMaxLength(255));
        this.Fields.push(this.formFieldService.CreateTemplate(1, ' ').setMDSize(2).setLabelVisible(true).setLabelClass('inline-block'));

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Dateidatum), 'CORE.COMMON.MODEL.AVIDOC.DATUM', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Titel), 'CORE.COMMON.MODEL.AVIDOC.TITLE', true).setMDSizeFull().setMaxLength(255));

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Abstract), 'CORE.COMMON.MODEL.AVIDOC.ABSTRACT', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AktuellerStatus), 'CORE.COMMON.MODEL.AVIDOC.CURRENT_STATUS', 'avidoc_dokumentstatus', true, false, true, this.ApiPrefix).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Status), 'CORE.COMMON.MODEL.AVIDOC.STATUS', 'avidoc_dokumentstatus', true, true, false, this.ApiPrefix).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DigitalSignieren), 'CORE.COMMON.MODEL.AVIDOC.DIGITAL_SIGNIEREN', 'janein', true, true, false, this.ApiPrefix).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Visibility), 'CORE.COMMON.MODEL.AVIDOC.SICHTBARKEIT', 'avidoc_visibility', true, true, false, this.ApiPrefix).setMDSizeFull());
    }
}
