import { Injectable } from '@angular/core';
import { AviApiService, AviAuthService } from '@avi-x/avi-core';
import { TranslateService } from '@ngx-translate/core';
import { VwbPRoleConst } from '../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class VwbPCommonService {


    constructor(
        private authService: AviAuthService,
        private apiService: AviApiService,
        private translateService: TranslateService
    ) {
    }

    public currentUserIsBehoerdenUser() {
        return this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_BEHOERDEN);
    }

    public currentUserIsOnlyBehoerdenUser() {
        return this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_BEHOERDEN) && !this.currentUserIsAjuUser();
    }

    public currentUserIsAdminUser() {
        return this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_ADMIN);
    }

    public currentUserIsAjuUser() {
        return this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_AJU_LESEN) || this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_AJU_SCHREIBEN);
    }

    public currentUserIsAjuUserSchreiben() {
        return this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_AJU_SCHREIBEN);
    }

    public currentUserIsEnduser() {
        return !this.currentUserIsAjuUser() && !this.currentUserIsAdminUser() && !this.currentUserIsBehoerdenUser();
    }
}
