import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService, AviFormFieldService, AviListDetailConst } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviActivityFormComponent } from '../activity-form/activity-form.component';
import { ActivitySearchResponseDto } from '@avi-x/avi-dto/activity/activitysearchresponsedto.model';
import { SqlQueryParameterVM } from '@avi-x/avi-dto/reporting/sqlqueryparameter-vm.model';

const nameof = (nameFunction: ((obj: ActivitySearchResponseDto) => any)) => typed_nameof<ActivitySearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-activity-searcher',
    templateUrl: './activity-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviActivitySearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.ACTIVITY';
    SettingsKey = 'settings.searcher.activity';
    BaseUrl = 'activity';
	FormType = AviActivityFormComponent;

    CanEdit: boolean = false;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('Activity schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.sessionController.openSession('activity-index', this.SelectedRows.Id);
            // this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();

        this.searcher.SearcherParameters.push(this.formFieldService.CreateFieldFromSearcherParameter(
            new SqlQueryParameterVM('fltOwner', AviListDetailConst.ATTRTYPE_BOOL, 'CORE.COMMON.MODEL.ACTIVITY.MEINE', AviListDetailConst.JA, AviListDetailConst.JA), 3));
    
        this.searcher.SearcherParameters.push(this.formFieldService.CreateFieldFromSearcherParameter(
            new SqlQueryParameterVM('fltUnassigned', AviListDetailConst.ATTRTYPE_BOOL, 'CORE.COMMON.MODEL.ACTIVITY.NICHTZUGEWIESENE', AviListDetailConst.JA, AviListDetailConst.JA), 3));

        this.searcher.getSearcherParameter('fltOwner').Value = true;

        searcher.addIntegerColumn(nameof(c => c.ActivityID), 'CORE.COMMON.MODEL.ACTIVITY.ACTIVITYID', true);
        searcher.addTextColumn(nameof(c => c.Titel), 'CORE.COMMON.MODEL.ACTIVITY.TITEL', true);
        
        searcher.addTextColumn(nameof(c => c.Description), 'CORE.COMMON.MODEL.ACTIVITY.DESCRIPTION', true);
        
        searcher.addTextColumn(nameof(c => c.ActRepresentation), 'CORE.COMMON.MODEL.ACTIVITY.ACTREPRESENTATION', true);

        searcher.addTextColumn(nameof(c => c.AssignedToName), 'CORE.COMMON.MODEL.ACTIVITY.ASSIGNEDTO', true);
        searcher.addTextColumn(nameof(c => c.OwnerName), 'CORE.COMMON.MODEL.ACTIVITY.OWNER', true);
        
        searcher.addDateColumn(nameof(c => c.StartDate), 'CORE.COMMON.MODEL.ACTIVITY.STARTDATE', null, true);
        searcher.addDateColumn(nameof(c => c.DueDate), 'CORE.COMMON.MODEL.ACTIVITY.DUEDATE', null, true);
        
        searcher.addDateColumn(nameof(c => c.FinishDate), 'CORE.COMMON.MODEL.ACTIVITY.FINISHDATE', null, true);

        // searcher.addListTypeColumn(nameof(c => c.ActivityRead), 'CORE.COMMON.MODEL.ACTIVITY.ACTIVITYREAD', 'yesno', true, true);        
        
        this.searcher.addTextColumn('StatusBez', 'CORE.COMMON.MODEL.ACTIVITY.ACTIVITYSTATE', true)
            .FormatDelegate = (row) => `<span class="status-badge status-${this.commonService.str2CSSName(row['StatusBez'])}">${row['StatusBez']}</span>`;

        searcher.addListTypeColumn(nameof(c => c.Priority), 'CORE.COMMON.MODEL.ACTIVITY.PRIORITY', 'activity_priority', true, true);
                
        searcher.addListTypeColumn(nameof(c => c.PeriodTyp), 'CORE.COMMON.MODEL.ACTIVITY.PERIODTYP', 'activity_periodtyp', true, true);
        
        // searcher.addTextColumn(nameof(c => c.ActContext1Representation), 'CORE.COMMON.MODEL.ACTIVITY.ACTCONTEXT1REPRESENTATION', true);
        searcher.addTextColumn(nameof(c => c.ActContext2Representation), 'CORE.COMMON.MODEL.ACTIVITY.ACTCONTEXT2REPRESENTATION', true);        
        // searcher.addTextColumn(nameof(c => c.ActContext3Representation), 'CORE.COMMON.MODEL.ACTIVITY.ACTCONTEXT3REPRESENTATION', true);
        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        cfg.FilterOwner = this.searcher.getSearcherParameter('fltOwner').Value;
        cfg.FilterUnassigned = this.searcher.getSearcherParameter('fltUnassigned').Value;
        const res = await this.apiService.postPagedResult(ActivitySearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }

    public _onParameterValueChange(data) {
        if (data.field === 'fltOwner' && data.value === true && this.searcher.getSearcherParameter('fltUnassigned').Value === true) 
        this.searcher.setSearcherParameter('fltUnassigned', false);
    
        if (data.field === 'fltUnassigned' && data.value === true && this.searcher.getSearcherParameter('fltOwner').Value === true) 
            this.searcher.setSearcherParameter('fltOwner', false);
    }
}
