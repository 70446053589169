import { Job } from '@avi-x/avi-dto/system/job.model';
import { AviApiService } from './../../services/api.service';
import { Component, OnDestroy, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AviSignalRService } from '../../services/signalr.service';
import { AviListDetailConst } from '../../shared/constants/constants';
import { AviBaseSearcherComponent } from '../base-searcher/base-searcher.component';
import { AviSearcherColumn } from '../base-searcher/searcher-column';

@Component({
    template: `
        <avi-core-page title="" [card]="false" [collapsible]="false" [expanded]="true" [contents-padding]="true">
            <div *ngIf="!isFailed()" class="pb-4">{{ message }}</div>

            <p-progressBar *ngIf="!isFailed()" [value]="value"></p-progressBar>

            <div *ngIf="isFailed()" class="mb-16p">
                <p-message severity="error" [text]="FailedMessage | translate" class="w-100p"></p-message>
            </div>

            <avi-core-base-searcher
                    #logSearcher
                    [use-router-params]="false"
                    [hide-when-noresults]="true"
                    [search-delegate]="SearchDelegate"
                    [rows]="0"
                    [auto-search]="false"
                    [show-autofilter]="false"
                    [toolbar]="false"
                    [no-word-wrap]="false"
                    [toolbar-show-searchinput]="false"
                    [toolbar-show-advanced]="false"
                    [lazy-loading]="true"
                    [card]="false"
                    (onInit)="onLogInit($event)"
                    searcher-title=""
                    [show-totalrecords]="true"
                    [select-first-result]="false"
                >
            </avi-core-base-searcher>

            <div *ngIf="isSuccess()" class="mb-16p mt-8p">
                <p-message severity="success" [text]="SuccessMessage | translate" class="w-100p"></p-message>
            </div>

            <div class="ui-g pt-3">
            <div class="w-100p">
                <button
                    *ngIf="isRunning() && canCancel"
                    pButton
                    type="button"
                    icon="pi pi-undo"
                    [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
                    [title]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
                    (click)="Abbrechen()"
                    class="w-100p-m m ml-2 pull-right p-button-secondary p-button-outlined"
                ></button>
                <button
                    *ngIf="!isRunning() || !canCancel"
                    pButton
                    type="button"
                    icon="pi pi-undo"
                    label="Schliessen"
                    [disabled]="isRunning()"
                    (click)="Close()"
                    class="w-100p-m m ml-2 pull-right p-button-secondary"
                ></button>
            </div>
            </div>
        </avi-core-page>
    `
})
export class AviJobDialogComponent implements OnInit, OnDestroy {
    @ViewChild('logSearcher', { static: true })
    public logSearcher: AviBaseSearcherComponent;

    value = 0;
    jobId: any;
    status: string;
    message: string;
    canCancel: boolean = true;

    baseUrl: string;
    interval;

    SuccessMessage: string = 'CORE.COMMON.JOB_SUCCESS_MSG';
    FailedMessage: string = 'CORE.COMMON.JOB_FAILED_MSG';

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, public cdr: ChangeDetectorRef, public apiService: AviApiService, public signalRService: AviSignalRService) { }

    async ngOnInit() {
        this.jobId = this.config.data.jobId;
        this.baseUrl = this.config.data.baseUrl;
        this.canCancel = this.config.data.canCancel;

        if (this.config.data.successMessage) this.SuccessMessage = this.config.data.successMessage;
        if (this.config.data.failedMessage) this.FailedMessage = this.config.data.failedMessage;

        this.status = AviListDetailConst.JOB_STATUS_RUNNING;

        await this.initSignalR();
        await this.apiService.post(`${this.baseUrl}/start/${this.jobId}`, null);

        this.interval = setInterval(() => this.checkStatus(), 1000);
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get(`${this.baseUrl}/log/${this.jobId}`);
        res = res.filter(w => w.LogType.Id !== AviListDetailConst.LOGGING_TYPE_INFO);
        return res;
    }

    onLogInit(data) {
        this.logSearcher.beginInit();

        this.logSearcher.addColumn(
            AviSearcherColumn.CreateCustom(
                '',
                ' ',
                (row, col) => {
                    if (row['LogType'].Id === AviListDetailConst.LOGGING_TYPE_ERROR)
                        return '<i class="pi pi-exclamation-circle color-red"></i>';
                    else if (row['LogType'].Id === AviListDetailConst.LOGGING_TYPE_WARNING)
                        return '<i class="pi pi-exclamation-triangle color-orange"></i>';
                    else if (row['LogType'].Id === AviListDetailConst.LOGGING_TYPE_INFO)
                        return '<i class="pi pi-info-circle color-green"></i>';

                    return '';
                },
                false,
                '35px'
            ).setSortable(false)
        );

        this.logSearcher.addTextColumn('LogMessage', 'Beschreibung').setSortable(false);
        this.logSearcher.endInit();
    }

    async checkStatus() {
        try {
            const res = await this.apiService.getModel(Job, `${this.baseUrl}/${this.jobId}`);
            if (res) {
            if (res.Status.Id !== AviListDetailConst.JOB_STATUS_FAILED && res.Status.Id === AviListDetailConst.JOB_STATUS_FAILED)
                    this.logSearcher.forceRefresh();

                if (res.Status.Id !== AviListDetailConst.JOB_STATUS_RUNNING)
                    clearInterval(this.interval);

                this.value = Math.floor(100 * res.TotalWorkups / res.TotalItems);
                this.status = res.Status.Id;
                this.message = null;
                this.cdr.markForCheck();
            }
        } catch (err) {
        }
    }

    isSuccess() {
        return this.status === AviListDetailConst.JOB_STATUS_SUCCEEDED;
    }

    isFailed() {
        return this.status === AviListDetailConst.JOB_STATUS_FAILED;
    }

    isRunning() {
        return this.status === AviListDetailConst.JOB_STATUS_RUNNING;
    }

    private async initSignalR() {
        const connection = await this.signalRService.GetConnection();
        this.signalRService.Subscribe(this.jobId);

        if (connection) {
            connection.on('Jobstatus', this.handleStatusUpdate);
        }
    }

    private handleStatusUpdate = async (data: any) => {
        if (data.jobId === this.jobId) {
            if (this.status !== AviListDetailConst.JOB_STATUS_FAILED && data.status === AviListDetailConst.JOB_STATUS_FAILED)
                this.logSearcher.forceRefresh();

            this.value = Math.floor(100 * data.itemsProcessed / data.itemsCount);
            this.status = data.status;
            this.message = data.message;
            this.cdr.markForCheck();

            clearInterval(this.interval);
        }
    }

    async ngOnDestroy() {
        const connection = await this.signalRService.GetConnection();
        this.signalRService.Unsubscribe(this.jobId);

        if (connection) {
            connection.off('Jobstatus', this.handleStatusUpdate);
        }
    }

    async Abbrechen() {
        try {
            await this.apiService.post(`${this.baseUrl}/cancel/${this.jobId}`, null);
        } catch (err) {
            //
        }

        this.ref.close(this.status);
    }

    Close() {
        this.ref.close(this.status);
    }
}
