import { Expose, Type } from 'class-transformer';

import { JobDef } from '../system/jobdef.model';
import { TechRole } from '../system/techrole.model';
import { ListDetail } from '../system/listdetail.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared/genericref.model';

export class JobParamDef extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get ListTypeName(): string { return this.getProperty('ListTypeName'); }
    public set ListTypeName(value: string) { this.setProperty('ListTypeName', value); }
    
    @Expose()
    public get RefType(): string { return this.getProperty('RefType'); }
    public set RefType(value: string) { this.setProperty('RefType', value); }
    
    @Expose()
    public get Sequence(): number { return this.getProperty('Sequence'); }
    public set Sequence(value: number) { this.setProperty('Sequence', value); }
    
    @Expose()
    public get DefaultValueInt(): number { return this.getProperty('DefaultValueInt'); }
    public set DefaultValueInt(value: number) { this.setProperty('DefaultValueInt', value); }
    
    @Expose()
    public get DefaultValueDec(): number { return this.getProperty('DefaultValueDec'); }
    public set DefaultValueDec(value: number) { this.setProperty('DefaultValueDec', value); }
    
    @Expose()
    public get DefaultValueText(): string { return this.getProperty('DefaultValueText'); }
    public set DefaultValueText(value: string) { this.setProperty('DefaultValueText', value); }
    
    @Expose()
    @Type(() => Date)
    public get DefaultValueDate(): Date { return this.getProperty('DefaultValueDate'); }
    public set DefaultValueDate(value: Date) { this.setProperty('DefaultValueDate', value); }
    
    @Expose()
    public get CollectionModelName(): string { return this.getProperty('CollectionModelName'); }
    public set CollectionModelName(value: string) { this.setProperty('CollectionModelName', value); }
    
    @Expose()
    public get Description(): string { return this.getProperty('Description'); }
    public set Description(value: string) { this.setProperty('Description', value); }
    
    @Expose()
    public get vonJobDef_ID(): string { return this.getModelId('vonJobDef_ID'); }
    public set vonJobDef_ID(value: string) { this.setModelId('vonJobDef_ID', value); }
 
    @Expose()
    @Type(() => JobDef)
    public get vonJobDef(): JobDef { return this.getModel('vonJobDef'); }
    public set vonJobDef(value: JobDef) { this.setModel('vonJobDef', value); }
    
    @Expose()
    public get vonTechRole_ID(): string { return this.getModelId('vonTechRole_ID'); }
    public set vonTechRole_ID(value: string) { this.setModelId('vonTechRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get vonTechRole(): TechRole { return this.getModel('vonTechRole'); }
    public set vonTechRole(value: TechRole) { this.setModel('vonTechRole', value); }
    
    @Expose()
    public get DefaultValueList_ID(): string { return this.getModelId('DefaultValueList_ID'); }
    public set DefaultValueList_ID(value: string) { this.setModelId('DefaultValueList_ID', value); }
 
    @Expose()
    @Type(() => ListDetail)
    public get DefaultValueList(): ListDetail { return this.getModel('DefaultValueList'); }
    public set DefaultValueList(value: ListDetail) { this.setModel('DefaultValueList', value); }
    
    @Expose()
    @Type(() => ListType)
    public get mandatory(): ListType { return this.getProperty('mandatory'); }
    public set mandatory(value: ListType) { this.setProperty('mandatory', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ParamType(): ListType { return this.getProperty('ParamType'); }
    public set ParamType(value: ListType) { this.setProperty('ParamType', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get DefaultValueRef(): GenericRef { return this.getProperty('DefaultValueRef'); }
    public set DefaultValueRef(value: GenericRef) { this.setProperty('DefaultValueRef', value); }
    
}
