import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AviCanActivateWhenLoggedInService, AviCanDeactivateService } from './services/router-helper.service';

import { AviLoginComponent } from './components/login/login.component';
import { AviProfileComponent } from './components/profile/profile.component';
import { AviAboutComponent } from './components/about/about.component';

@NgModule({
    imports: [RouterModule.forChild([
        { path: 'login', component: AviLoginComponent },
        { path: 'about', component: AviAboutComponent },
        { path: 'pw-reset/:f', component: AviLoginComponent },
        { path: 'pw-reset/:f/:username', component: AviLoginComponent },
        { path: 'pw-reset/:f/:username/:token', component: AviLoginComponent },
        // { path: 'core/pw-reset', redirectTo: 'core/login?f=dpr'/*, pathMatch: 'full'*/ }, // redirect to `first-component`

        { path: 'profile', component: AviProfileComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    ])],
    exports: [RouterModule]
})
export class AviCoreRoutingModule {
}
