import { Expose, Type } from "class-transformer";
import { GenericRef, IDTO } from "../shared";

export class BusinesscaseContextSearchRequestDto extends IDTO {
    @Expose()
    public Query: string;

    @Expose()
    public BusinesscaseDefId: string;

    @Expose()
    public ModelId: string;

    @Expose()
    public ContextIndex: number;

    @Expose()
    @Type(() => GenericRef)
    public Context1: GenericRef;

    @Expose()
    @Type(() => GenericRef)
    public Context2: GenericRef;
}
