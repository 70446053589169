import { Expose, Type } from "class-transformer";
import { GenericRef, IDTO } from "../shared";

export class DocuMatrixCreateDocumentRequest extends IDTO {

    @Expose()
    public TemplateId: string;

    @Expose()
    @Type(() => GenericRef)
    public Kontext: GenericRef;
}
