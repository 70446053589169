import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';
import { VwbPVersion } from '../../dto/vwbpversion.model';
import { VwbPRechtstraeger } from '../../dto/vwbprechtstraeger.model';

@Injectable({
    providedIn: 'root'
})
export class VwbPPublicRechtstraegerVersionResolver implements Resolve<VwbPRechtstraeger> {
    constructor(private apiService: AviApiService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<VwbPRechtstraeger> {
        const idProperty = route.data['resolveId'] || 'id';
        const id: string = route.params[idProperty];

        return this.loadVersion(id);
    }

    async loadVersion(id: string) {
        const version = await this.apiService.getModel(VwbPVersion, `v1/vwbp/rechtstraeger/version/${id}`);
        const rechtstraeger = await this.apiService.FindModel(VwbPRechtstraeger, `v1/vwbp/rechtstraeger/${version.Rechtstraeger_ID}`, null, null, null);
        rechtstraeger.SelectedVersion = version;

        return rechtstraeger;
    }
}
