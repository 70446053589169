import { BaseModelState } from '@avi-x/avi-dto/shared/basemodel.model';
import { Expose, Type } from 'class-transformer';
import { AviDocumentReference } from '../avidoc/avidocumentreference.model';
import { GenericRef } from '../shared';

export class Email extends BaseModelState {
    @Expose()
    public get Recipient(): string { return this.getProperty('Recipient'); }
    public set Recipient(value: string) { this.setProperty('Recipient', value); }
    
    @Expose()
    public get CCRecipient(): string { return this.getProperty('CCRecipient'); }
    public set CCRecipient(value: string) { this.setProperty('CCRecipient', value); }

    @Expose()
    public get BCCRecipient(): string { return this.getProperty('BCCRecipient'); }
    public set BCCRecipient(value: string) { this.setProperty('BCCRecipient', value); }

    @Expose()
    public get Subject(): string { return this.getProperty('Subject'); }
    public set Subject(value: string) { this.setProperty('Subject', value); }

    @Expose()
    public get Message(): string { return this.getProperty('Message'); }
    public set Message(value: string) { this.setProperty('Message', value); }
    
    @Expose()
    public get IsBodyHtml(): boolean { return this.getProperty('IsBodyHtml'); }
    public set IsBodyHtml(value: boolean) { this.setProperty('IsBodyHtml', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get Context(): GenericRef { return this.getProperty('Context'); }
    public set Context(value: GenericRef) { this.setProperty('Context', value); }

    @Expose()    
    public Documents: AviDocumentReference[];

    //
    public get MessageRTF(): string { return this.Message; }
    public set MessageRTF(value: string) { this.Message = value; }
}
