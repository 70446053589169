import { StatusDef } from '@avi-x/avi-dto/workflow/statusdef.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'avi-crm-activity-status',
    templateUrl: './activity-status.component.html',
    styleUrls: ['./activity-status.component.scss']
})
export class AviActivityStatusComponent {

    @Input('status')
    public Status: StatusDef;

    private getStatusColor() {
        if (this.Status) {

            const statusBez = this.Status.Bezeichnung1.toLocaleLowerCase();
            if (statusBez === 'erledigt' || statusBez === 'done')
                return 'green';

            if (statusBez === 'open' || statusBez === 'open')
                return 'dodgerblue';

            if (statusBez === 'in arbeit')
                return 'brown';
        }

        return 'grey';
    }

    public getStatusStyle() {
        const res = {
            'padding': '0px 4px 2px 4px',
            'font-weight': 'bold',
            'color': 'white',
            'border-radius': '3px',
            'font-variant': 'all-small-caps',
            'background': this.getStatusColor()
        };

        return res;
    }

}
