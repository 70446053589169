import { Component, Input, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService, AviBaseSearcherComponent, AviSearcherColumn, AviDialogComponent, AviAuthService, typed_nameof, SysEventConst } from '@avi-x/avi-core';
import { AttrType, VerlaufDto } from '@avi-x/avi-dto/system/verlauf.model';

const nameof = (nameFunction: ((obj: VerlaufDto) => any)) => typed_nameof<VerlaufDto>(nameFunction);

@Component({
    selector: 'avi-verlauf-list',
    templateUrl: './verlauf-list.component.html',
    styleUrls: ['./verlauf-list.component.scss'],
    providers: [DecimalPipe, PercentPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviVerlaufListComponent {
    AttrType = AttrType;
    SysEventConst = SysEventConst;

    _readonly: boolean = false;
    public get Readonly() {
        return this._readonly;
    }

    @Input('readonly')
    public set Readonly(value: boolean) {
        this._readonly = value;
    }

    _KontextId: string = null;
    @Input('kontext-id')
    public set KontextId(value: string) {
        this._KontextId = value;
        if (this.KontextUrl)
            this.forceRefresh();
    }

    public get KontextId(): string {
        return this._KontextId;
    }

    @Input('kontext-url')
    public KontextUrl: string = null;

    public Collapsed: boolean = true;

    Items: VerlaufDto[] = [];
    
    HasMore: boolean = false;
    MaxNumItems: number = 10;

    constructor(public apiService: AviApiService, public router: Router, public commonService: AviCommonService, public authService: AviAuthService, private cdr: ChangeDetectorRef) {
    }

    async forceRefresh() {
        if (!this.Collapsed) {
            this.Items = await this.apiService.getModelList(VerlaufDto, `verlauf/${this.KontextUrl}/${this.KontextId}`);
            this.HasMore = this.Items.length > this.MaxNumItems;
            this.cdr.markForCheck();
        }
    }

    showMore() {
        this.MaxNumItems = this.Items.length;
        this.HasMore = false;
    }

    OnCollapsedChanged(data) {
        this.Collapsed = data;
        this.forceRefresh();
    }
}
