import { GenericRef } from './../../../../avi-dto/src/lib/shared/genericref.model';
import { AviFormField, AviFormFieldType } from './../components/base-form/form-field';
import { Injectable } from '@angular/core';
import { AviCommonService } from './common.service';
import { AviAuthService } from './auth.service';
import { AviApiService } from './api.service';
import { IAviSearcherParameter } from '@avi-x/avi-dto/interface/avisearcher-parameter.interface';
import { AviListDetailConst } from '../shared/constants/constants';

import * as moment from 'moment-timezone';
import { ObjectUtils } from '../components/utils/object-utils';

import { findIndex, findLastIndex, get } from 'lodash-es';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { AviBaseFormComponent } from '../components/base-form/base-form.component';

@Injectable({
    providedIn: 'root'
})
export class AviFormFieldService {
    constructor(public apiService: AviApiService, public authService: AviAuthService, public commonService: AviCommonService) {
    }

    public CreateFieldFromSearcherParameter(data: IAviSearcherParameter, width: number, dropdownDataSource: any = null, dropdownAddChooseItem: boolean = true): AviFormField {
        const field: AviFormField = new AviFormField(data.ParamName, data.Label1);

        field.Visible = data.HasControl.Id === AviListDetailConst.JA;

        // field.Row = data.Row;
        // field.Sorter = data.Sorter;
        field.Label = data.Label1;
        field.MDSize = width;
        field.Required = data.AllowNull.Id === AviListDetailConst.NEIN;
        field.BoolRadioGroupID = data.BoolRadioGroupID;
        field.DateRangeButtons = true;
        field.SourceType = data.ParamType.Id;

        const paramType = data.ParamType.Id;
        if (paramType === AviListDetailConst.ATTRTYPE_INTEGER) {
            field.Type = AviFormFieldType.NUMBER;
            field.Value = data.DefaultIntParamValue;
        } else if (paramType === AviListDetailConst.ATTRTYPE_DECIMAL) {
            field.Type = AviFormFieldType.NUMBER;
            field.Value = data.DefaultDecParamValue;
        } else if (paramType === AviListDetailConst.ATTRTYPE_BOOL) {
            field.Type = AviFormFieldType.CHECKBOX;
            field.Value = data.DefaultBoolParamValue;
            field.CheckboxText = field.Label;
            field.Label = null;
        } else if (paramType === AviListDetailConst.ATTRTYPE_DATE) {
            field.Type = AviFormFieldType.DATE;

            const date = data.DefaultDateParamValue != null ? new Date(data.DefaultDateParamValue) : null;
            // const today = moment().startOf('day');
            let value = null;

            if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_DEFINED && date != null)
                value = date;
            else if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_TODAY)
                value = moment().startOf('day');
            else if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_TOMORROW)
                value = moment().startOf('day').add(1, 'days');
            else if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_YESTERDAY)
                value = moment().startOf('day').add(-1, 'days');
            else if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_WEEKBEGIN)
                value = moment().startOf('isoWeek').startOf('day');
            else if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_WEEKEND)
                value = moment().endOf('isoWeek').startOf('day');
            else if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_MONTHBEGIN)
                value = moment().startOf('month').startOf('day');
            else if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_MONTHEND)
                value = moment().endOf('month').startOf('day');
            else if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_YEARBEGIN)
                value = moment().startOf('year').startOf('day');
            else if (data.DefaultDateParamListChoice.Id === AviListDetailConst.CAF_DATE_CHOICE_YEAREND)
                value = moment().endOf('year').startOf('day');

            if (value)
                field.Value = value.toDate();
        } else if (paramType === AviListDetailConst.ATTRTYPE_LISTTYPE) {
            field.Type = AviFormFieldType.DROPDOWN;
            const listID = data.List_ID;
            if (listID) {
                //                this.DropdownPlaceholder = "Alle";
                field.DropdownDatasource = `listdetaillink?filter=listRef.id eq ${listID}&select=listDetailRef.Id,listDetailRef.Bezeichnung1`;
                field.DropdownDisplayField = 'listDetailRefBezeichnung1';
                field.DropdownValueField = 'listDetailRefId';
                this.BuildDropdownDatasource(field);
            }
            field.Value = data.DefaultListDetailParamValue_ID;
        } else if (paramType === AviListDetailConst.ATTRTYPE_REFERENCE) {
            field.Type = AviFormFieldType.DROPDOWN;
            field.DropdownFilterable = true;
            field.DropdownDatasource = dropdownDataSource;
            field.DropdownAddChooseItem = dropdownAddChooseItem;

            if (field.DropdownDatasource == null) {
                field.DropdownDatasource = async (w) => {
                    const idProp = data.RefTypeName === 'Mitarbeiter' ? 'PartnerRolleID' : 'Id';
                    const bezProp = data.RefTypeName === 'Mitarbeiter' ? 'PartnerRolle.vonPartner.Name,PartnerRolle.vonPartner.ZusatzName' : 'Bezeichnung1';
                    const aktivProp = data.RefTypeName === 'Mitarbeiter' ? 'PartnerRolle.Aktiv' : 'Aktiv';
                    const gueltigFilter = data.RefTypeName === 'Mitarbeiter' ? ' and (PartnerRolle.GueltigAb le today) and (PartnerRolle.GueltigBis ge today)' : '';
                    const bezPropJS = bezProp.split('.').join('');
                    const bezPropJSArr = bezPropJS.split(',');
                    const p = await this.apiService.get(`${data.RefTypeName}?filter=(${aktivProp} eq aktiv())${gueltigFilter}&select=${idProp},${bezProp}&sort=${bezProp}`);
                    //                    const p = await this.apiService.get(`${data.refTypeName}?filter=filterAktivGueltig()&select=Id(),${bezProp}&sort=${bezProp}`);
                    const ret = [];
                    p.forEach(elem => {
                        const label = bezPropJSArr.map(e => elem[e]).join(' ');
                        ret.push({ label: label, value: elem[idProp] }); // TODO: This assumes a Bezeichnung1 property, should be more dynamic
                    });

                    return ret;
                };
            }
            if (!ObjectUtils.isObjectEmpty(data.DefaultRefParamValue))
                field.Value = data.DefaultRefParamValue.ModelID;

            if (data.DefaultRefParamValueCurrentUser)
                field.Value = this.authService.CurrentUser.MitarbeiterId;

            if (field.Visible)
                this.BuildDropdownDatasource(field);
        }

        return field;
    }

    public CreateGroupTemplate(nr: number) {
        const name = `groupTemplate${nr}`;
        const field = new AviFormField(name, name, AviFormFieldType.GROUP);
        field.GroupTemplate = true;
        return field;
    }

    public CreateGroup(name: string, label: string, toggleable: boolean = true, expanded: boolean = true) {
        const field = new AviFormField(name, label, AviFormFieldType.GROUP);
        field.GroupToggleable = toggleable;
        field.GroupExpanded = expanded;
        return field;
    }


    public CreateStep(label: string, name: string = null) {
        const field = new AviFormField(name || label, label, AviFormFieldType.STEP);
        return field;
    }

    public CreateField(name: string, label: string = null, required: boolean = false) {
        return new AviFormField(name, label, AviFormFieldType.TEXT, required);
    }

    public CreateTemplate(nr: number, label: string) {
        if (nr > 15 || nr <= 0)
            throw new Error('Template-Nummer muss zwischen 1 und 15 liegen');
        const field = new AviFormField(`formFieldTemplate${nr}`, label, AviFormFieldType.TEMPLATE, false);
        field.LabelVisible = false;
        return field;
    }

    public CreateEmpty(name: string, label: string = null) {
        const res = new AviFormField(name, label, AviFormFieldType.EMPTY, false);
        res.LabelVisible = !!label;
        return res;
    }

    public CreateText(name: string, label: string = null, required: boolean = false) {
        return new AviFormField(name, label, AviFormFieldType.TEXT, required);
    }

    public CreateTextWithButton(name: string, label: string = null, required: boolean, buttonLabel: string, buttonEvent: (field, event?) => void , buttonIcon: string = null) {
        const res = new AviFormField(name, label, AviFormFieldType.TEXT, required);
        res.ButtonEvent = buttonEvent;
        res.ButtonLabel = buttonLabel;
        res.ButtonIcon = buttonIcon;
        return res;
    }

    public CreateButton(name: string, buttonLabel: string = null, buttonEvent: (field, event?) => void = null, buttonIcon: string = null) {
        const res = new AviFormField(name, '&nbsp;', AviFormFieldType.BUTTON, false);
        res.ButtonEvent = buttonEvent;
        res.ButtonLabel = buttonLabel;
        res.ButtonIcon = buttonIcon;
        return res;
    }

    public CreateTextBlock(name: string, label: string = null, text: string, identifier: string = null) {
        const field = new AviFormField(name, label, AviFormFieldType.TEXTBLOCK, false);
        field.TextblockText = text;
        field.TextblockIdentifier = identifier;
        field.LabelVisible = label ? true : false;
        return field;
    }

    public CreateInputMask(name: string, label: string = null, inputMask: string, required: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.INPUTMASK, required);
        field.Mask = inputMask;
        return field;
    }

    public CreateRichText(name: string, label: string = null, required: boolean = false) {
        return new AviFormField(name, label, AviFormFieldType.RICHTEXT, required);
    }

    public CreateTextQuill(name: string, label: string = null, required: boolean = false) {
        return new AviFormField(name, label, AviFormFieldType.TEXT_QUILL, required);
    }

    public CreateLabel(name: string, label: string, bold: boolean = false, labelClass: string = null) {
        const field = new AviFormField(name, label, AviFormFieldType.LABEL, false);
        field.LabelBold = bold;
        if (labelClass)
            field.LabelClass = labelClass;
        return field;
    }

    public CreateNumber(name: string, label: string = null, precision: number = 0, required: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.NUMBER, required);
        field.PrecisionMin = precision;
        field.PrecisionMax = precision;
        return field;
    }

    public CreatePercentage(name: string, label: string = null, precision: number = 0, required: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.PERCENTAGE, required);
        field.PrecisionMin = precision;
        field.PrecisionMax = precision;
        return field;
    }

    public CreateCurrency(name: string, label: string = null, currency: string = 'CHF', precision: number = 0, required: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.CURRENCY, required);
        field.PrecisionMin = precision;
        field.PrecisionMax = precision;
        field.Currency = currency;
        return field;
    }

    public CreateHidden(name: string, required: boolean = false) {
        return new AviFormField(name, null, AviFormFieldType.HIDDEN, required);
    }

    // TODO(msc): Evtl mal das hier: https://www.npmjs.com/package/ngx-material-timepicker einbauen
    public CreateTime(name: string, label: string = null, required: boolean = false) {
        return new AviFormField(name, label, AviFormFieldType.TIME, required);
    }

    public CreateDate(name: string, label: string = null, required: boolean = false, dataType: 'date' | 'string' = 'date', view: 'date' | 'month' | 'year' = 'date') {
        const field = new AviFormField(name, label, AviFormFieldType.DATE, required);
        field.DataType = dataType;
        field.DateView = view;

        const nameUC = name.toUpperCase();

        if (nameUC.endsWith('BIS') || nameUC.endsWith('VON') || nameUC.endsWith('TO') || nameUC.endsWith('FROM')) {
            field.DateRangeButtons = true;
        }

        return field;
    }

    public CreateTextarea(name: string, label: string = null, required: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.TEXTAREA, required);
        return field;
    }

    public CreateCheckbox(name: string, label: string = null, checkboxText: string = null, required: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.CHECKBOX, required);
        field.CheckboxText = checkboxText === null ? field.Label : checkboxText;
        if (label == null && field.CheckboxText)
            field.Label = null;

        return field;
    }

    public CreateToggleButton(name: string, label: string = null, checkboxText: string = null, required: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.TOGGLEBUTTON, required);
        field.CheckboxText = checkboxText === null ? field.Label : checkboxText;
        if (label == null && field.CheckboxText)
            field.Label = null;
        return field;
    }

    public CreateRadioButton(name: string, label: string = null, options: any[] = [], required: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.RADIO, required);
        field.Options = options;
        return field;
    }

    public CreateDropdown(name: string, label: string = null, dataSource: any = null, required: boolean = false, filterable: boolean = false, placeholder: string = null, displayField: any = null, valueField: any = null, addChooseItem: boolean = true, readonly: boolean = false, filterMatchMode: 'contains' | 'startsWith' = 'startsWith', buildDatasource: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.DROPDOWN, required);
        if (!filterMatchMode)
            filterMatchMode = 'startsWith';

        if (filterable)
            console.warn(`Dropdown ${name} wurde hinzugefügt mit filterable=true. Beim verlassen vom Filter gibt es ein Problem mit dem Keyboard-Fokus, bitte überlegen ob ein Autocomplete Control nicht besser wäre!`);

        field.DropdownFilterable = filterable;
        field.DropdownDatasource = dataSource;
        field.DropdownDisplayField = displayField;
        field.DropdownValueField = valueField;
        field.DropdownAddChooseItem = addChooseItem;
        field.DropdownMatchMode = filterMatchMode;
        if (placeholder != null)
            field.DropdownPlaceholder = readonly ? '' : placeholder;

            if (buildDatasource)
             this.BuildDropdownDatasource(field);

        return field;
    }

    public async UpdateDropdownDatasources(fields: AviFormField[], model: any, includeListTypes: boolean = true) {
        const dropdownFields = fields.filter(w => w.Type === AviFormFieldType.DROPDOWN || w.Type === AviFormFieldType.REFERENCE || (includeListTypes && w.Type === AviFormFieldType.LISTTYPE));
        for (let index = 0; index < dropdownFields.length; index++) {
            await this.BuildDropdownDatasource(dropdownFields[index], model);
        }
    }

    public CreateAutocomplete(name: string, label: string = null, required: boolean = false, displayField: string = null, valueField: string = null, minChars: number = 3, datasource: any) {
        const field = new AviFormField(name, label, AviFormFieldType.AUTOCOMPLETE, required);
        field.DropdownDatasource = datasource;
        field.DropdownDisplayField = displayField;
        field.DropdownValueField = valueField;
        field.AutocompleteMinChars = minChars;
        field.AutocompleteDelegate = async (query: string) => await this.BuildAutocompleteDatasource(field, null, query);
        field.AutocompleteField = field.Name + '_Autocomplete';
        //        field.Name = field.Name + "_Autocomplete";
        field.setMDSizeFull();

        return field;
    }

    public CreateTypelessAutocomplete(name: string, label: string = null, required: boolean = false, displayField: any = null, minChars: number = 3, datasource: any) {
        const field = new AviFormField(name, label, AviFormFieldType.AUTOCOMPLETE_TYPELESS, required);
        field.DropdownDatasource = datasource;
        field.DropdownDisplayField = displayField;
        field.AutocompleteForceSelection = false;
        field.DropdownValueField = null;
        field.AutocompleteMinChars = minChars;
        field.AutocompleteDelegate = async (query: string) => await this.BuildAutocompleteDatasource(field, null, query);
        field.AutocompleteField = field.Name + '_Autocomplete';
        //        field.Name = field.Name + "_Autocomplete";
        field.setMDSizeFull();

        return field;
    }

    public async UpdateAutocompleteDatasources(fields: AviFormField[], model: any) {
        if (model) {
            const dropdownFields = fields.filter(w => w.Type === AviFormFieldType.AUTOCOMPLETE);
            for (let index = 0; index < dropdownFields.length; index++)
                await this.UpdateAutocompleteDatasource(dropdownFields[index], model);
        }
    }

    private getIndexInDatasource(field: AviFormField, model: any): number {
        if (field.DropdownDatasourceInternal.length <= 0) return -1;

        const obj = ObjectUtils.getByPath(model, field.Name);
        if (obj instanceof GenericRef)
            return field.DropdownDatasourceInternal.findIndex(w => w.value?.ModelID === obj.ModelID);
        else
            return field.DropdownDatasourceInternal.findIndex(w => w.value === ObjectUtils.getByPath(model, field.Name));
    }

    public async UpdateAutocompleteDatasource(field: AviFormField, model: any): Promise<any[]> {
        if (model) {
            field.DropdownDatasourceInternal = await this.BuildAutocompleteDatasource(field, model, null);

            const idx = this.getIndexInDatasource(field, model);
            if (field.DropdownDatasourceInternal.length > 0 && idx !== -1)
                ObjectUtils.setByPath(model, field.AutocompleteField, field.DropdownDatasourceInternal[idx]);
            else if (field.DropdownDatasourceInternal.length == 1 && field.AutoselectSingle) {
                ObjectUtils.setByPath(model, field.AutocompleteField, field.DropdownDatasourceInternal[0]);
                ObjectUtils.setByPath(model, field.Name, field.DropdownDatasourceInternal[0].value);
            }
            else {
                ObjectUtils.setByPath(model, field.AutocompleteField, null);
                ObjectUtils.setByPath(model, field.Name, null);
            }

            return field.DropdownDatasourceInternal;
        }

        return [];
    }

    public async BuildAutocompleteDatasource(field: AviFormField, model: any, query: string): Promise<any[]> {
        let ret = [];

        if (typeof field.DropdownDatasource === 'string') {
            let path = field.DropdownDatasource;

            if (!path)
                return ret;

            // aktuell noch unschön, nur api's mit parameter id und query werden unterstützt...
            if (model) {
                let id = model ? ObjectUtils.getByPath(model, field.Name) : null;
                if (id instanceof GenericRef)
                    id = id.ModelID;

                if (id)
                    path = path + '?id=' + id;
                else
                    return ret;
            }

            if (query) {
                if (!query || query.length < field.AutocompleteMinChars || field.AutocompleteDelegate == null)
                    return ret;
                else {
                    path = path + '?query=' + query;
                }
            }

            //const p = await this.apiService.get(path);

            let p: any;
            if (field.DropdownDatasourceMethod == 'POST'){
                const data = field.DropdownDatasourceRequestDataDelegate ? field.DropdownDatasourceRequestDataDelegate(field) : null;
                p = await this.apiService.post(path, data ?? {});
            } else {
                p = await this.apiService.get(path);
            }

            p.forEach(elem => {
                ret.push({ label: field.DropdownDisplayField ? this.getDisplayField(elem, field.DropdownDisplayField) : elem, value: field.DropdownValueField ? this.getValueField(elem, field.DropdownValueField) : elem, valueFull: elem });
            });

        } else if (typeof field.DropdownDatasource === 'function') {
            let modelId = model ? ObjectUtils.getByPath(model, field.Name) : null;
            if (modelId instanceof GenericRef)
                modelId = modelId.ModelID;

            ret = await field.DropdownDatasource(field, modelId, query);
        } else if (field.DropdownDatasource instanceof Array) {
            for (const elem of field.DropdownDatasource) {
                ret.push({ label: field.DropdownDisplayField ? this.getDisplayField(elem, field.DropdownDisplayField) : elem, value: field.DropdownValueField ? this.getValueField(elem, field.DropdownValueField) : elem, valueFull: elem });
            }
        }

        return ret;
    }

    public CreateListType(name: string, label: string = null, listName: string, filterable: boolean = true, required: boolean = true, readonly: boolean = false, apiPrefix: string = null, buildDatasource: boolean = false) {
        if (readonly) {
            const field = this.CreateText(name, label, required);
            field.setReadonly(true);
            field.UnderlyingType = AviFormFieldType.LISTTYPE;
            return field;
        } else {
            const col = new AviFormField(name, label, AviFormFieldType.LISTTYPE, required);
            if (filterable && listName) {
                col.DropdownPlaceholder = 'Bitte wählen...';
                col.DropdownDatasource = `model/representations/list/${listName}`;

                if (apiPrefix)
                    col.DropdownDatasource = apiPrefix + (apiPrefix.endsWith('/') ? '' : '/') + col.DropdownDatasource;

                col.DropdownDisplayField = 'Representation';
                col.DropdownValueField = (elem) => elem ? new ListType(elem.Id, listName, elem.Representation, elem.RepresentationShort) : null;

                if (buildDatasource)
                    this.BuildDropdownDatasource(col);
            }
            return col;
        }
    }

    public CreateEnum(name: string, label: string = null, enumType: any, filterable: boolean = true, required: boolean = true, readonly: boolean = false) {
        if (readonly) {
            const field = this.CreateText(name, label, required);
            field.setReadonly(true);
            field.UnderlyingType = AviFormFieldType.ENUM;
            return field;
        } else {
            const col = new AviFormField(name, label, AviFormFieldType.ENUM, required);
            if (filterable) {
                col.DropdownPlaceholder = 'Bitte wählen...';
                col.DropdownDatasource = Object.keys(enumType).filter((item) => { return isNaN(Number(item)); });

                col.DropdownValueField = (elem) => elem ? enumType[elem] : null;
                this.BuildDropdownDatasource(col);
            }
            return col;
        }
    }

    public CreateLink(name: string, linkText: string, url: string, icon: string = null, label: string = null, target: string = '_blank') {
        const field = new AviFormField(name, label, AviFormFieldType.LINK, false);
        field.LinkUrl = url;
        field.LinkText = linkText;
        field.LinkIcon = icon;
        field.LinkTarget = target;
        field.LabelVisible = false;
        field.setMDSizeFull();
        return field;
    }

    public CreateReference(name: string, label: string,  target: string, dataSource: any = null, displayField: any = null, valueField: any = null, buildDatasource: boolean = false) {
        const field = new AviFormField(name, label, AviFormFieldType.REFERENCE, false);

        field.DropdownDisplayField = displayField;
        field.DropdownValueField = valueField;
        field.DropdownDatasource = dataSource;
        field.DropdownDisplayField = displayField;

        field.LinkUrl = target;

        if (buildDatasource)
             this.BuildDropdownDatasource(field);

        return field;
    }

    public SerializeValue(field: AviFormField): string {
        const paramType = field.Type;
        if (paramType === AviFormFieldType.NUMBER) {
            return field.Value;
        } else if (paramType === AviFormFieldType.CHECKBOX || paramType === AviFormFieldType.TOGGLEBUTTON) {
            return field.Value ? 'true' : 'false';
        } else if (paramType === AviFormFieldType.DATE) {
            if (field.Value)
                return moment(field.Value).format('YYYY-MM-DD');
            else
                return null;
        } else if (paramType === AviFormFieldType.DROPDOWN || paramType === AviFormFieldType.TEXT || paramType === AviFormFieldType.RICHTEXT || paramType === AviFormFieldType.TEXTAREA) {
            return field.Value;
        } else {
            return null;
        }
    }

    public DeserializeValue(field: AviFormField, value: string) {
        const paramType = field.Type;
        if (paramType === AviFormFieldType.NUMBER) {
            field.Value = Number(value) || null;
        } else if (paramType === AviFormFieldType.CHECKBOX || paramType === AviFormFieldType.TOGGLEBUTTON) {
            field.Value = value === 'true';
        } else if (paramType === AviFormFieldType.DATE) {
            if (value) {
                if (value === 'null' || value === 'undefined')
                    field.Value = null;
                else
                    field.Value = moment(value, 'YYYY-MM-DD').toDate();
            }
        } else if (paramType === AviFormFieldType.DROPDOWN || paramType === AviFormFieldType.TEXT || paramType === AviFormFieldType.RICHTEXT || paramType === AviFormFieldType.TEXTAREA) {
            field.Value = value;
        }
    }

    public async BuildDropdownDatasource(field: AviFormField, model: any = null) {
        let ret = [];

        if (typeof field.DropdownDatasource === 'string') {
            ret = await this.BuildDropdownDatasourceFromApi(field, model);
        } else if (typeof field.DropdownDatasource === 'function') {
            ret = await field.DropdownDatasource(field);
        } else if (field.DropdownDatasource instanceof Array) {
            for (const elem of field.DropdownDatasource) {
                ret.push({ label: field.DropdownDisplayField ? this.getDisplayField(elem, field.DropdownDisplayField) : elem, value: field.DropdownValueField ? this.getValueField(elem, field.DropdownValueField) : elem, valueFull: elem });
            }
        }

        /*        if (field.DropdownAddChooseItem) {
                    // An anfang noch ein "Bitte wählen..." einfügen
                    ret.unshift({ label: field.DropdownPlaceholder, value: null, nosearch: true });
                }*/

        if (field.HiddenDropdownItems && field.HiddenDropdownItems.length > 0 && field.HiddenDropdownField)
            ret = ret.filter(w => !field.HiddenDropdownItems.some(x => x == get(w.value, field.HiddenDropdownField)));

        field.DropdownDatasourceInternal = ret;

        if (model && field.DropdownDatasourceInternal.length == 1 && field.AutoselectSingle) {
            ObjectUtils.setByPath(model, field.Name, field.DropdownDatasourceInternal[0].value);
        }
    }

    public async IsDrowpdownValueInDatasource(field: AviFormField, value: any, rebuildDatasource = false) {
        if (rebuildDatasource)
            await this.BuildDropdownDatasource(field);
        const valFound = field.DropdownDatasourceInternal.find(i => (field.DropdownValueField ? this.getValueField(i, field.DropdownValueField) : (i && i.value ? i.value : i)) === value);
        return valFound ? true : false;
    }

    public GetDropdownEntryLabelFromDatasource(field: AviFormField, value: any) {
        const valFound = field.DropdownDatasourceInternal.find(i => i.value === value);
        if (valFound)
            return valFound.label;
        return null;
    }

    private async BuildDropdownDatasourceFromApi(field: AviFormField, model: any): Promise<any[]> {
        let path = field.DropdownDatasource;

        const ret = [];

        if (model) {
            let id = model ? ObjectUtils.getByPath(model, field.Name) : null;
            if (id instanceof GenericRef)
                id = id.ModelID;

            if (id) {
                path = path.replace(/\$id/, id);
            } else if (path.indexOf('$id') >= 0)
                return ret;
        }

        const p = await this.apiService.get(path);
        p.forEach(elem => {
            ret.push({ label: field.DropdownDisplayField ? this.getDisplayField(elem, field.DropdownDisplayField) : elem, value: field.DropdownValueField ? this.getValueField(elem, field.DropdownValueField) : elem, valueFull: elem });
        });

        return ret;
    }

    private getValueField(element, valueField) {
        if (typeof valueField === 'string') {
            return get(element, valueField);
        } else if (typeof valueField === 'function') {
            return valueField(element);
        } else {
            return `Unknown Typ: ${typeof valueField}`;
        }
    }

    private getDisplayField(element, displayField) {
        if (typeof displayField === 'string') {
            return get(element, displayField);
        } else if (typeof displayField === 'function') {
            return displayField(element);
        } else {
            return `Unknown Typ: ${typeof displayField}`;
        }
    }

    public getField(fields: AviFormField[], fieldName: string) {
        return fields.find(w => w.Name === fieldName);
    }

    public setFieldValue(form: AviBaseFormComponent, fields: AviFormField[], paramName: string, paramValue: any, notifyUpdate: boolean = true) {
        if (paramValue != null) {
            const param = fields.find(w => w.Name === paramName);
            if (param) {
                if (param.BoolRadioGroupID != null) {
                    const otherParams = fields.filter(w => w.BoolRadioGroupID === param.BoolRadioGroupID && w !== param);
                    if (paramValue === true) {
                        otherParams.forEach(element => {
                            element.Value = false;
                            if (notifyUpdate)
                                form.formAttrChange(element.Name, element.Value);
                        });
                    } else {
                        setTimeout(() => {
                            param.Value = true; if (notifyUpdate) form.formAttrChange(param.Name, param.Value);
                        });
                    }
                } else if (param.Value !== paramValue) {
                    param.Value = paramValue;

                    if (notifyUpdate)
                        form.formAttrChange(param.Name, param.Value);
                }
            }
        }
    }

    public clearFieldHiddenItems(fields: AviFormField[], paramName: string) {
        const param = fields.find(w => w.Name === paramName && (w.Type === AviFormFieldType.DROPDOWN || w.Type === AviFormFieldType.LISTTYPE));
        if (param) {
            param.HiddenDropdownItems = null;
            param.HiddenDropdownField = null;
            this.BuildDropdownDatasource(param);
        }
    }

    public addFieldHiddenItems(fields: AviFormField[], paramName: string, hiddenItems: any[], hiddenDropdownField: string) {
        const param = fields.find(w => w.Name === paramName && (w.Type === AviFormFieldType.DROPDOWN || w.Type === AviFormFieldType.LISTTYPE));
        if (param) {
            param.HiddenDropdownItems = hiddenItems;
            param.HiddenDropdownField = hiddenDropdownField;
            this.BuildDropdownDatasource(param);
        }
    }

    public setFieldDisabled(fields: AviFormField[], paramName: string, disabled: boolean) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.Disabled = Boolean(disabled);
    }

    public setFieldButtonDisabled(fields: AviFormField[], paramName: string, disabled: boolean) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.ButtonDisabled = Boolean(disabled);
    }

    public setFieldReadonly(fields: AviFormField[], paramName: string, readonly: boolean) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.Readonly = Boolean(readonly);
    }

    public setFieldVisible(fields: AviFormField[], paramName: string, visible: boolean) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.Visible = Boolean(visible);
    }

    public setFieldRequired(fields: AviFormField[], paramName: string, required: boolean) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.Required = Boolean(required);
    }

    public setFieldLabel(fields: AviFormField[], paramName: string, label: string) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.Label = label;
    }

    public setFieldSize(fields: AviFormField[], paramName: string, mdsize: number) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.MDSize = mdsize;
    }

    public setDateRangeButtonDisabled(fields: AviFormField[], paramName: string, disabled: boolean) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.DateRangeButtons = Boolean(disabled);
    }

    public setFieldPattern(fields: AviFormField[], paramName: string, pattern: string) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.Pattern = pattern;
    }

    public setFieldIsEmail(fields: AviFormField[], paramName: string, isEmail: boolean) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.Pattern = isEmail ? '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$' : null;
    }

    public setFieldIsPhoneNumber(fields: AviFormField[], paramName: string, isPhoneNumber: boolean) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.IsPhoneNumber = isPhoneNumber;
    }

    public setFieldNoHighLowDate(fields: AviFormField[], paramName: string, noHighLowDate: boolean) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.NoHighLowDate = noHighLowDate;
    }

    public setGroupExpanded(fields: AviFormField[], groupName: string, expanded: boolean) {
        const param = fields.find(w => w.Name === groupName);
        if (param)
            param.GroupExpanded = expanded;
    }

    public setFieldPrefix(fields: AviFormField[], paramName: string, prefix: string) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.Prefix = prefix;
    }

    public setFieldSuffix(fields: AviFormField[], paramName: string, suffix: string) {
        const param = fields.find(w => w.Name === paramName);
        if (param)
            param.Suffix = suffix;
    }

    // Subforms
    addSubformIfNotExists(model: any, newItems: AviFormField[], existingFields: AviFormField[], attrName: string, idx: number, insertAfterIfFirstItem: string, extendModel: boolean) {

        if (!existingFields.find(f => new RegExp(`${attrName}\\[${idx}\\]`).test(f.Name))) {
            let lastSubformIdx = findLastIndex(existingFields, i => new RegExp(`${attrName}\\[\\d\\].`).test(i.Name));

            if (lastSubformIdx < 0)
                lastSubformIdx = existingFields.findIndex(i => i.Name === insertAfterIfFirstItem); // Wenn noch kein Element existiert, nimm den Index der Gruppe

            existingFields.splice(lastSubformIdx + 1, 0, ...newItems);

            if (extendModel) {
                if (!ObjectUtils.getByPath(model, attrName))
                    ObjectUtils.setByPath(model, attrName, []);
                ObjectUtils.getByPath(model, attrName).push({});
            }

        } else {
            console.warn("Existiert schon..", `${attrName}\\[${idx}\\]`);
        }

    }

    public removeSubformItems(model: any, existingFields: AviFormField[], attrName: string, idx: number) {

        if (idx === null) {
            // Entferne alle Subforms
            let firstSubformIdx = findIndex(existingFields, i => new RegExp(`${attrName}\\[\\d\\].`).test(i.Name));
            let lastSubformIdx = findLastIndex(existingFields, i => new RegExp(`${attrName}\\[\\d\\].`).test(i.Name));
            let numberOfFieldsToRemove = existingFields.filter(i => new RegExp(`${attrName}\\[\\d\\]`).test(i.Name)).length;

            if (firstSubformIdx >= 0 && lastSubformIdx >= 0 && numberOfFieldsToRemove > 0) {
                existingFields.splice(lastSubformIdx - numberOfFieldsToRemove + 1, numberOfFieldsToRemove);
                ObjectUtils.getByPath(model, attrName).splice(firstSubformIdx, 1);
            }
        } else {
            // Entferne eine einzelne Subform
            let lastSubformIdx = findLastIndex(existingFields, i => new RegExp(`${attrName}\\[\\d\\].`).test(i.Name));
            let numberOfFieldsToRemove = existingFields.filter(i => new RegExp(`${attrName}\\[${idx}\\]`).test(i.Name)).length;
            if (idx >= 0 && lastSubformIdx >= 0 && numberOfFieldsToRemove > 0) {
                existingFields.splice(lastSubformIdx - numberOfFieldsToRemove + 1, numberOfFieldsToRemove);
                ObjectUtils.getByPath(model, attrName).splice(idx, 1);
            }
        }

    }

}
