import { OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { AviCommonService, AviApiService, ISessionData, AviSessionControllerService, AviTabView } from '@avi-x/avi-core';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'avi-appusergrouplogentry-index',
    templateUrl: './appusergrouplogentry-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAppUserGroupLogEntryIndexComponent implements OnInit, OnDestroy, OnChanges {  
    @Input('session-data')
    SessionData: ISessionData;

    @ViewChild('tabView')
    tabView: AviTabView;

    ModelId: string = null;

    sub: Subscription;

   constructor(public apiService: AviApiService,
        public commonService: AviCommonService,
        public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) {
    }
	
    ngOnInit(): void {
        this.sub = this.sessionController.sessionChanged$.subscribe(sessionId => {
            this.tabView.Active = sessionId === this.SessionData?.Id;
        });
    }
		
    ngOnDestroy(): void {
        if (this.sub)
            this.sub.unsubscribe();
    }
	
    ngOnChanges(changes: SimpleChanges) {
		if (this.SessionData?.ContextId) {
			this.ModelId = this.SessionData.ContextId;
            // this.sessionController.setCurrentSessionTitle();
            if (this.SessionData.Modul)
                setTimeout(() => this.tabView.selectTab(this.SessionData.Modul));
        }
    }
}
