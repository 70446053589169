import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AviCommonService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbpInternCommonService } from '../../../common/vwbp-intern-common.service';
import { VwbPUserDto } from '../../../dto/vwbpuser.model';
import { VwbPInternRechtstraegerVersionenSearcherComponent } from '../../../rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-versionen-searcher.component';
import { VwbPInternUebergabeService } from '../../service/uebergabe.service';

@Component({
    selector: 'vwbp-intern-uebergabe-abgegeben',
    templateUrl: './uebergabe-abgegeben.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPInternUebergabeAbgegebenComponent {
    @ViewChild('searcher', { static: true })
    private searcher: VwbPInternRechtstraegerVersionenSearcherComponent;

    Bemerkung: string;

    // SelectedUser: VwbPUserDto;
    UserSuggestions: VwbPUserDto[];

    constructor(
        private commonService: AviCommonService,
        private cdr: ChangeDetectorRef,
        private vwbpInternCommonService: VwbpInternCommonService,
        private uebergabeService: VwbPInternUebergabeService,
        public vwbpCommonService: VwbPCommonService
    ) { }

    GetFilters(): string[] {
        return [`Uebergabestatus eq ${VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_ABGEGEBEN}`];
    }

    GetSelectionCount() {
        return this.searcher.GetSelectedRechtstraeger().length;
    }

    get ButtonParams() {
        return { cnt: this.GetSelectionCount() };
    }

    IsNothingSelected(): boolean {
        return this.GetSelectionCount() === 0;
    }

    // CanStartRechtstraegerAnfragen() {
    //     return !this.IsNothingSelected() && this.Bemerkung && this.SelectedUser;
    // }

    CanStartRechtstraegerInaktivieren() {
        return !this.IsNothingSelected() && this.Bemerkung;
    }

    // async StartRechtstraegerAnfragen() {
    //     const selectedRT = this.searcher.GetSelectedRechtstraeger();
    //     console.log('selectedRT', selectedRT);
    //     this.commonService.confirm({
    //         header: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_ANFRAGE_STARTEN'),
    //         message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_ANFRAGE_STARTEN_Q', { cnt: selectedRT.length }), acceptLabel: 'Ja', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: () => {
    //             this.uebergabeService.StartRechtstraegerAnfordern(selectedRT.map(w => w.RechtstraegerId), this.SelectedUser.Id, this.Bemerkung).then(r => {
    //                 this.Bemerkung = '';
    //                 this.SelectedUser = null;
    //                 this.searcher.forceRefresh();
    //                 this.cdr.markForCheck();
    //                 this.commonService.notificateSuccess(this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_ANGEFORDERT'));
    //             }).catch(err => this.commonService.notificateError(err));
    //         }
    //     });
    // }

    async StartRechtstraegerInaktivieren() {
        const selectedRT = this.searcher.GetSelectedRechtstraeger();
        console.log('selectedRT', selectedRT);
        this.commonService.confirm({
            header: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_INAKTIVIEREN'),
            message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_INAKTIVIEREN_Q', { cnt: selectedRT.length }), acceptLabel: 'Ja', acceptButtonStyleClass: 'p-button-primary', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: () => {
                this.uebergabeService.StartRechtstraegerInaktivieren(selectedRT.map(w => w.RechtstraegerId), this.Bemerkung).then(r => {
                    this.Bemerkung = '';
                    // this.SelectedUser = null;
                    this.searcher.forceRefresh();
                    this.cdr.markForCheck();
                    this.commonService.notificateSuccess(this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_INAKTIVIERT'));
                }).catch(err => this.commonService.notificateError(err));
            }
        });
    }


    searchUser(event) {
        if (!event.query || event.query.length < 3) {
            this.UserSuggestions = [];
            this.cdr.markForCheck();
        } else {
            this.vwbpInternCommonService.searchUser(event.query).then(data => {
                this.UserSuggestions = data;
                this.cdr.markForCheck();
            });
        }
    }
}
