import { AviCoreModule } from '@avi-x/avi-core';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AviLandSearcherComponent } from './components/land-searcher/land-searcher.component';
import { AviLandFormComponent } from './components/land-form/land-form.component';

import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AviCoreModule,

        CalendarModule,
        CheckboxModule,
        PanelModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        DropdownModule,
        TableModule,
        DialogModule,
        ContextMenuModule
    ],
    exports: [
        AviLandSearcherComponent,
        AviLandFormComponent,
        RouterModule
    ],
    declarations: [
        AviLandSearcherComponent,
        AviLandFormComponent
    ],
})
export class AviLandModule { }
