import {Component} from '@angular/core';

@Component({
    selector: 'avi-vwbp-footer',
    styleUrls: ['./app.footer.component.scss'],
    template: `
        <div class="footer">
            <!--
            <div class="card clearfix">
                <span class="footer-text-left">AviX</span>
                <span class="footer-text-right"><span class="ui-icon ui-icon-copyright"></span>  <span>All Rights Reserved</span></span>
            </div>
            -->
            <a href="https://www.llv.li/serviceportal2/amtsstellen/amt-fuer-justiz/stifa_gwp/vwbp/datenschutzerklaerung-vwbp.pdf" target="_blank" rel="noopener noreferrer">{{ 'VWBP.PUBLIC.FOOTER.PRIVACY_POLICY' | translate }}</a>
        </div>
    `
})
export class AviVwbPFooterComponent {

}
