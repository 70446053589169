import { TranslateLoader } from "@ngx-translate/core";
import { from, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";


export function AppServerLoaderFactory(http: HttpClient, activatedRoute: ActivatedRoute) {
  return new AppServerTranslateLoader(http, activatedRoute);
}

@Injectable()
export class AppServerTranslateLoader implements TranslateLoader {

  private ApiPrefix: string;
  private BaseUrl: string = null;
  private BaseUrlIntern: string = null;
  private BaseUrl_prefix: string = null;
  private _useInternBaseUrl: boolean = false;

  // AviApiService darf nicht verwendet werden, ansonsten gibt es einen Dependency Injection Zyklus!
  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {
      this.ApiPrefix = this.activatedRoute.snapshot.data['apiprefix'];
  }

  public getTranslation(lang: string): Observable<any> {    
    const url = `${this.ApiPrefix ?? ''}/localization/${lang}`;

    return from(this.ensureBaseUrl().then((s) => {
      const intUrl: string = this.buildApiUrl(url);
      console.log('getTranslation: ' + lang + ' => ' + intUrl);
      let res = this.http.get(intUrl).toPromise();
      return res;
    }));
  }

  public UseInternBaseUrl(use: boolean = true) {
    this._useInternBaseUrl = use;
  }

  private buildApiUrl(url: string): string {
    if (!url.startsWith(this.getBaseUrl()) && !url.startsWith('http'))
      url = this.combineUrl(this.getBaseUrl(), this.BaseUrl_prefix, url);
    return url;
  }

  private combineUrl(...params: string[]): string {
    var url: string = null;
    
    if (params) {
      for (var i = 0; i < params.length; i++) {
        var part = params[i];

        if (!part)
          continue;

        if (part.startsWith('/'))
          part = part.substring(1);
        
        if (url) {
          url = url + (url.endsWith('/') ? '' : '/') + part;
        } else {
          url = part;
        }
      }
    }

    return url;
  }

  private getBaseUrl(): string {
    if (this._useInternBaseUrl && this.BaseUrlIntern)
        return this.BaseUrlIntern;

    return this.BaseUrl;
  }

  private async ensureBaseUrl(): Promise<string> {
    return new Promise((resolve, reject) => {
        if (this.BaseUrl) {
            resolve(this.getBaseUrl());
        } else {
            this.http.get('assets/config.txt')
                .toPromise<any>().then(jsonConfig => {
                    this.BaseUrl = jsonConfig.baseURL;
                    if (jsonConfig.baseUrl_intern)
                      this.BaseUrlIntern = jsonConfig.baseUrl_intern;
                    if (jsonConfig.baseUrl_prefix)
                      this.BaseUrl_prefix = jsonConfig.baseUrl_prefix;
                    resolve(this.getBaseUrl());
                });
        }
    });
  }
}