import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'avi-file-upload-button',
    template: `
        <input #upfile id="upfile" type="file" [accept]="accept" (change)="onSelect($event)" [multiple]="multiple" hidden/>
        <button pButton type="button" [label]="label" [icon]="icon" [class]="styleClass" (click)="upfile.click()" [disabled]="disabled"></button>
    `,
})
export class AviFileUploadComponent {
    @ViewChild('upfile') upfile: ElementRef;
    
    @Input('accept')
    accept: string;

    @Input('label')
    label: string = 'Datei wählen';

    @Input('icon')
    icon: string = null;

    @Input('styleClass')
    styleClass: string = 'p-button-primary';

    @Input('disabled')
    disabled: boolean = false;

    @Input('multiple')
    multiple: boolean = false;

    @Output('onUpload')
    onUpload: EventEmitter<File> = new EventEmitter<File>();

    @Output('onMultipleUpload')
    onMultipleUpload: EventEmitter<File[]> = new EventEmitter<File[]>();

    constructor() {
    }

    async onSelect(evt) {
        const files = evt.dataTransfer ? evt.dataTransfer.files : evt.target.files;
        if (files && files.length > 0) {
            if (this.multiple) {
                this.onMultipleUpload.emit(Array.from(files));
            }
            else
                this.onUpload.emit(files[0]);
            this.upfile.nativeElement.value = '';
        }
    }

    reset() {
        this.upfile.nativeElement.value = '';
    }
}
