<avi-core-view-panel *ngIf="CanCreateActivities || Activities.length > 0" [toggleable]="true" header="Pendenzen" [no-pad]="true" panel-class="p-2">
    <div class="ui-g businesscase-card-contents">
        <div class="ui-g-12">
            <button
                *ngIf="CanCreateActivities"
                pButton
                type="button"
                label="Pendenz erfassen"
                icon="pi pi-plus"
                (click)="createActivity()"
                class="w-100p-m mb-2 mt-2"
            ></button>

            <table class="subtask-list">
                <!-- <tr>
                    <th>Titel</th>
                    <th>Fällig</th>
                    <th>Status</th>
                    <th>Zuständig</th>
                </tr> -->
                <tr *ngFor="let subtask of Activities; let i = index" class="selectable">
                    <td (click)="openActivity(subtask.Id)">
                        <div class="subtask-title">{{ subtask.Titel }}</div>
                        <div class="subtask-subtitle">{{ subtask.AssignedToName }}</div>
                        <div class="subtask-subtitle">{{ subtask.StartDate | dateFormat: "dd.MM.y" }}<span *ngIf="!commonService.isHighLowOrEmptyDate(subtask.DueDate)">({{ (subtask.DueDate | dateFormat: "dd.MM.y") }})</span></div>
                    </td>
                    <!-- <td>{{ subtask.DueDate | dateFormat: "dd.MM.y" }}</td> -->
                    <!-- <td><span [class]="'status-badge status-' + commonService.str2CSSName(subtask.StatusBez)">{{ subtask.StatusBez }}</span></td> -->
                    <!-- <td></td> -->
                    <td class="tasks">
                        <span [class]="'mr-1 status-badge status-' + commonService.str2CSSName(subtask.StatusBez)">{{ subtask.StatusBez }}</span>
                        <div class="pt-1">
                            <button pButton
                                type="button"
                                icon="pi pi-pencil"
                                aviThrottledClick
                                (throttledClick)="editActivity(subtask.Id)"
                                pTooltip="Bearbeiten"
                                class="w-100p-m p-button-secondary p-button-flat">
                            </button>
                                
                            <button pButton
                                type="button"
                                icon="pi pi-trash"
                                aviThrottledClick
                                (throttledClick)="deleteActivity(subtask.Id)"
                                pTooltip="Löschen"
                                class="red-btn w-100p-m p-button-secondary p-button-flat">
                            </button>           
                        </div> 
                    </td>
                </tr>
            </table>
        </div>
    </div>
</avi-core-view-panel>