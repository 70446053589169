import { Expose } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';

export class AdressBlockZeileDef extends BaseModel {  
    @Expose()
    public get Sequenz(): number { return this.getProperty('Sequenz'); }
    public set Sequenz(value: number) { this.setProperty('Sequenz', value); }
    
    @Expose()
    public get Zeile(): string { return this.getProperty('Zeile'); }
    public set Zeile(value: string) { this.setProperty('Zeile', value); }
    
    @Expose()
    public get BeibehaltenWennLeer(): boolean { return this.getProperty('BeibehaltenWennLeer'); }
    public set BeibehaltenWennLeer(value: boolean) { this.setProperty('BeibehaltenWennLeer', value); }
    
    @Expose()
    public get SprachRelevant(): boolean { return this.getProperty('SprachRelevant'); }
    public set SprachRelevant(value: boolean) { this.setProperty('SprachRelevant', value); }
    
    @Expose()
    public get vonCAFAdressBlockDef_ID(): string { return this.getModelId('vonCAFAdressBlockDef_ID'); }
    public set vonCAFAdressBlockDef_ID(value: string) { this.setModelId('vonCAFAdressBlockDef_ID', value); }     
}
