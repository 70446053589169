import { Expose, Type } from 'class-transformer';

import { TechRole } from '../system/techrole.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class StatusDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get ExternKurzBez1(): string { return this.getProperty('ExternKurzBez1'); }
    public set ExternKurzBez1(value: string) { this.setProperty('ExternKurzBez1', value); }
    
    @Expose()
    public get ExternBezeichnung1(): string { return this.getProperty('ExternBezeichnung1'); }
    public set ExternBezeichnung1(value: string) { this.setProperty('ExternBezeichnung1', value); }
    
    @Expose()
    public get ExternKurzBez2(): string { return this.getProperty('ExternKurzBez2'); }
    public set ExternKurzBez2(value: string) { this.setProperty('ExternKurzBez2', value); }
    
    @Expose()
    public get ExternBezeichnung2(): string { return this.getProperty('ExternBezeichnung2'); }
    public set ExternBezeichnung2(value: string) { this.setProperty('ExternBezeichnung2', value); }
    
    @Expose()
    public get ExternKurzBez3(): string { return this.getProperty('ExternKurzBez3'); }
    public set ExternKurzBez3(value: string) { this.setProperty('ExternKurzBez3', value); }
    
    @Expose()
    public get ExternBezeichnung3(): string { return this.getProperty('ExternBezeichnung3'); }
    public set ExternBezeichnung3(value: string) { this.setProperty('ExternBezeichnung3', value); }
    
    @Expose()
    public get ExternKurzBez4(): string { return this.getProperty('ExternKurzBez4'); }
    public set ExternKurzBez4(value: string) { this.setProperty('ExternKurzBez4', value); }
    
    @Expose()
    public get ExternBezeichnung4(): string { return this.getProperty('ExternBezeichnung4'); }
    public set ExternBezeichnung4(value: string) { this.setProperty('ExternBezeichnung4', value); }
    
    @Expose()
    public get ExternKurzBez5(): string { return this.getProperty('ExternKurzBez5'); }
    public set ExternKurzBez5(value: string) { this.setProperty('ExternKurzBez5', value); }
    
    @Expose()
    public get ExternBezeichnung5(): string { return this.getProperty('ExternBezeichnung5'); }
    public set ExternBezeichnung5(value: string) { this.setProperty('ExternBezeichnung5', value); }
    
    @Expose()
    public get ExternKurzBez6(): string { return this.getProperty('ExternKurzBez6'); }
    public set ExternKurzBez6(value: string) { this.setProperty('ExternKurzBez6', value); }
    
    @Expose()
    public get ExternBezeichnung6(): string { return this.getProperty('ExternBezeichnung6'); }
    public set ExternBezeichnung6(value: string) { this.setProperty('ExternBezeichnung6', value); }
    
    @Expose()
    public get ExternKurzBez7(): string { return this.getProperty('ExternKurzBez7'); }
    public set ExternKurzBez7(value: string) { this.setProperty('ExternKurzBez7', value); }
    
    @Expose()
    public get ExternBezeichnung7(): string { return this.getProperty('ExternBezeichnung7'); }
    public set ExternBezeichnung7(value: string) { this.setProperty('ExternBezeichnung7', value); }
    
    @Expose()
    public get Sorter(): number { return this.getProperty('Sorter'); }
    public set Sorter(value: number) { this.setProperty('Sorter', value); }
    
    @Expose()
    public get IsStartState(): boolean { return this.getProperty('IsStartState'); }
    public set IsStartState(value: boolean) { this.setProperty('IsStartState', value); }
    
    @Expose()
    public get IsDefaultFinishState(): boolean { return this.getProperty('IsDefaultFinishState'); }
    public set IsDefaultFinishState(value: boolean) { this.setProperty('IsDefaultFinishState', value); }
    
    @Expose()
    public get IsFinishState(): boolean { return this.getProperty('IsFinishState'); }
    public set IsFinishState(value: boolean) { this.setProperty('IsFinishState', value); }
    
    @Expose()
    public get Bearbeitbar(): boolean { return this.getProperty('Bearbeitbar'); }
    public set Bearbeitbar(value: boolean) { this.setProperty('Bearbeitbar', value); }
    
    @Expose()
    public get vonStatusWorkflowDef_ID(): string { return this.getModelId('vonStatusWorkflowDef_ID'); }
    public set vonStatusWorkflowDef_ID(value: string) { this.setModelId('vonStatusWorkflowDef_ID', value); }
 
    @Expose()
    public get RoleSichtbar_ID(): string { return this.getModelId('RoleSichtbar_ID'); }
    public set RoleSichtbar_ID(value: string) { this.setModelId('RoleSichtbar_ID', value); }

    @Expose()
    public get RoleAssignment_ID(): string { return this.getModelId('RoleAssignment_ID'); }
    public set RoleAssignment_ID(value: string) { this.setModelId('RoleAssignment_ID', value); }

    @Expose()
    @Type(() => ListType)
    public get Sichtbarkeit(): ListType { return this.getProperty('Sichtbarkeit'); }
    public set Sichtbarkeit(value: ListType) { this.setProperty('Sichtbarkeit', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Verantwortlichkeit(): ListType { return this.getProperty('Verantwortlichkeit'); }
    public set Verantwortlichkeit(value: ListType) { this.setProperty('Verantwortlichkeit', value); }
    
    @Expose()
    public get FollowupBusinesscaseDef_ID(): string { return this.getModelId('FollowupBusinesscaseDef_ID'); }
    public set FollowupBusinesscaseDef_ID(value: string) { this.setModelId('FollowupBusinesscaseDef_ID', value); }
}
