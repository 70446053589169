import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';

export class AppUserGroup extends BaseModel {  
    @Expose()
    public get AppUserGroupTyp(): string { return this.getProperty('AppUserGroupTyp'); }
    public set AppUserGroupTyp(value: string) { this.setProperty('AppUserGroupTyp', value); }
    
    @Expose()
    public get Bezeichung(): string { return this.getProperty('Bezeichung'); }
    public set Bezeichung(value: string) { this.setProperty('Bezeichung', value); }
    
    @Expose()
    public get KurzBez(): string { return this.getProperty('KurzBez'); }
    public set KurzBez(value: string) { this.setProperty('KurzBez', value); }
    
}
