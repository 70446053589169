<p-panel
    header="Verlauf"
    [toggleable]="true"
    [collapsed]="Collapsed"
    expandIcon="pi pi-chevron-down"
    collapseIcon="pi pi-chevron-up"
    toggler="header"
    (collapsedChange)="OnCollapsedChanged($event)"
>
    <div class="mt-2 pb-3">
        <avi-core-base-searcher
            #searcher
            [settingskey]="SettingsKey"
            [contents-padding]="false"
            [use-router-params]="false"
            [search-delegate]="SearchDelegate"
            [rows]="0"
            [auto-search]="false"
            [show-autofilter]="true"
            [toolbar]="false"
            [toolbar-show-searchinput]="false"
            [toolbar-show-advanced]="false"
            [lazy-loading]="true"
            [card]="false"
            (onInit)="onSearcherInit($event)"
            searcher-title="Verlauf"
            [show-totalrecords]="false"
            [select-first-result]="false"
            [row-detailview-template]="rowdetailview"
        >
        </avi-core-base-searcher>
    </div>

</p-panel>

<ng-template #rowdetailview let-record>
    <ng-container>
        <div class="pt-2">
            <avi-verlauf-form
                class="summary"
                [card]="false"
                [model-id]="record['Id']"
                [readonly]="true"
            ></avi-verlauf-form>
        </div>
    </ng-container>
</ng-template>

<avi-core-dialog #verlaufDialog identifier="verlaufDialog" [showCloseIcon]="false" [closeOnEscape]="false">
    <avi-verlauf-form
        #verlaufForm
        [model-id]="FormModelId"
        (onSavedSuccessful)="onSavedSuccessful($event)"
        [action-buttons]="formActionButtons"
    >
    </avi-verlauf-form>
</avi-core-dialog>
