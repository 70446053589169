import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AviCommonService, typed_nameof } from "@avi-x/avi-core";
import { TechRoleLinkSearchResponseDto } from "@avi-x/avi-dto/system/techrolelinksearchresponsedto.model";
import { TechRoleSearchResponseDto } from "@avi-x/avi-dto/system/techrolesearchresponsedto.model";
import { AviAbstractModelMultiSelectionComponent } from "libs/avi-core/src/lib/components/controls/multiselection/abstract-model-multiselection";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { AviTechRoleLinkSearcherComponent } from "./techrolelink-searcher.component";
import { TechRoleLinkInfo } from "./TechRoleLinkInfo";

const nameofL = (nameFunction: ((obj: TechRoleSearchResponseDto) => any)) => typed_nameof<TechRoleSearchResponseDto>(nameFunction);
const nameofR = (nameFunction: ((obj: TechRoleLinkSearchResponseDto) => any)) => typed_nameof<TechRoleLinkSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-techrolelink-multiselection',
    templateUrl: './techrolelink-multiselection.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviTechRoleLinkMultiSelectionComponent extends AviAbstractModelMultiSelectionComponent<TechRoleSearchResponseDto, TechRoleLinkSearchResponseDto> {
    
    techRoleLinkInfo: TechRoleLinkInfo;
    
    constructor(config: DynamicDialogConfig, commonService: AviCommonService) { 
        super(config, commonService, nameofL(c => c.Id), nameofR(c => c.Id), nameofR(c => c.TechRoleId));
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.techRoleLinkInfo = new TechRoleLinkInfo();
        this.techRoleLinkInfo.appUserGroupId = this.ModelId;
    }

    createNewRecord(techRole: TechRoleSearchResponseDto, newId: string): any {
        let newRecord = new TechRoleLinkSearchResponseDto();
        newRecord.Id = newId;
        newRecord.AppUserGroupId = (this.multiSelectionComponent.rightSearcher as AviTechRoleLinkSearcherComponent).techRoleLinkInfo.appUserGroupId;
        newRecord.TechRoleId = techRole.Id;
        newRecord.TechRoleName = techRole.RoleName;

        return newRecord;
    }
}