<div class=""

    fxLayout="column"
    fxLayoutAlign="space-between stretch">
    <div fxFlex="none">
        <h3 class="m-4"
            *ngIf="!Card && ShowTitle && Model && Model.Bezeichnung1">{{Model.Bezeichnung1}}</h3>

    </div>

    <div fxFlex
        fxFill>
        <!-- <h3 class="m-4" *ngIf="Model && Model.Bezeichnung1">{{Model.Bezeichnung1}}</h3> -->
        <avi-core-base-searcher #searcher
            [use-router-params]="UseRouterParams"
            [search-delegate]=SearchDelegate
            [search-fields]="['Bezeichnung1']"
            [rows]="SearcherRows"
            [toolbar]="ShowToolbar"
            [auto-search]="getAutoSearch()"
            (onInit)="onInit($event)"
            (onDoubleClick)="_onDoubleClick($event)"
            (onError)="_onError($event)"
            (onSelect)="onSelectionChanged($event)"
            [best-fit]="true"
            [get-row-class]="getRowClass"
            [show-autofilter]="ShowAutofilter"
            [toolbar-show-searchinput]="getShowSearchInput()"
            [toolbar-show-inactivebutton]="getShowInactiveButton()"
            [toolbar-show-advanced]="ShowAdvanced"
            [card]="Card"
            [collapsible]="Collapsible"
            [expanded]="Expanded"
            [fill-height]="false"
            (onParameterValueChange)="_onParameterValueChange($event)"
            [settingskey-delegate]="getSettingsKey"
            [row-detailview-template]="rowDetailViewTemplate"
            [responsive]="Responsive"
            [field-name-id]="FieldNameId"
            [searcher-title]="SearcherTitle"
            [searcher-icon]="SearcherIcon"
            [pretoolbar-template]="pretoolbarTemplate"
            [inaktiv-label]="InaktivLabel"
            >

        </avi-core-base-searcher>

    </div>
</div>
