import { ChangeDetectorRef, Component } from '@angular/core';
import { AviBaseSearcherComponent, AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { TranslateService } from '@ngx-translate/core';
import { VwbPInternSchwaerzenService } from '../../services/schwaerzen.service';
import { VwbPInternSchwaerzenBaseComponent } from './schwaerzen-base.component';

@Component({
    selector: 'vwbp-intern-schwaerzen-abgelehnt',
    template: `
        <vwbp-intern-schwaerzen-antrag-searcher #searcher settingskey="settings.searcher.schwaerzen.abgelehnt" (onInit)="onInitSearcher($event)" [last-version]="true" [get-filters]="GetFilters" [visible-columns]="['Name', 'Rechtsform', 'RegisterNummer', 'Sitz', 'AntragGueltigAb', 'AntragRechtskraftAm', 'AntragEntscheidAm', 'AntragBegruendung']">
        </vwbp-intern-schwaerzen-antrag-searcher>
        `
})
export class VwbPInternSchwaerzenAbgelehntComponent extends VwbPInternSchwaerzenBaseComponent {
    constructor(public translateService: TranslateService, public commonService: AviCommonService, public schwaerzenService: VwbPInternSchwaerzenService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, schwaerzenService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters = (): string[] => {
        return [`AntragStatus eq ${VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELEHNT}`];
    }

    onInitSearcher(searcher: AviBaseSearcherComponent) {
        //this.translateService.get('VWBP.INTERN.SCHWAERZEN.RECHTSKRAFT').toPromise().then(w => searcher.getColumn('AntragGueltigBis').Label = w);
    }
}
