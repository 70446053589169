import { VwbPInternSchwaerzenService } from './../../services/schwaerzen.service';
import { ViewChild, ChangeDetectorRef, Component } from '@angular/core';
import { VwbPInternRechtstraegerBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-searcher-base.component';
import { VwbPCommonService } from '@avi-x/vwbp-public';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';

import { Calendar } from 'primeng/calendar';

import * as moment from 'moment-timezone';

@Component({
    selector: 'vwbp-intern-schwaerzen-base',
    template: ''
})
export class VwbPInternSchwaerzenBaseComponent {
    @ViewChild('searcher', { static: true })
    protected searcher: VwbPInternRechtstraegerBaseComponent;

    constructor(public commonService: AviCommonService, public schwaerzenService: VwbPInternSchwaerzenService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
    }

    public onInputDate(event, calendar: Calendar): void {
        this.inputMaskService.handleDateInput(event, calendar);
    }

    getSelectedIndex() {
        return this.GetAktivValue() ? 0 : 1;
    }

    setPausiert(ev) {
        const aktiv = ev.index === 0;
        this.searcher.searcher.ResetSelection();
        this.SetAktivValue(aktiv);
    }

    public GetAktivValue() {
        return this.searcher.searcher.getSearcherParameter('aktiv')?.Value ?? true;
    }

    public SetAktivValue(text: any) {
        this.searcher.searcher.setSearcherParameter('aktiv', text);
        this.searcher.searcher.updateQueryParams(true, true);
    }

    currentUserIsAjuUserSchreiben() {
        return this.vwbpCommonService.currentUserIsAjuUserSchreiben();
    }

    public GetSelectionCount() {
        return this.searcher.GetSelectedRechtstraeger().length;
    }

    GetFristDatum(): string {
        const date = moment().startOf('day').add(120, 'days');
        return date.format('YYYY-MM-DD');
    }

    public GetSelectedRechtstraeger(): any[] {
        return this.searcher.GetSelectedRechtstraeger();
    }

    Refresh() {
        this.searcher.forceRefresh();
        this.cdr.markForCheck();
    }
}
