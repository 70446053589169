<form #form="ngForm">

    <p-steps [model]="steps"
        [activeIndex]="currentStep"
        [readonly]="true"></p-steps>

    <h1 mat-dialog-title>{{pageTitle}}</h1>

    <div mat-dialog-content>
        <div *ngIf="currentStep == 0">
            <mat-form-field style="width: 100%">
                <mat-label>Titel</mat-label>
                <input #inputTitle
                    cdkFocusInitial
                    required
                    matInput
                    name="title"
                    [(ngModel)]="data.Data.Title">
            </mat-form-field>

            <mat-form-field *ngIf="data.Data.Path"
                style="width: 100%">
                <mat-label>Pfad</mat-label>
                <input matInput
                    name="path"
                    readonly
                    [(ngModel)]="data.Data.Path">
            </mat-form-field>


            <div class="mt-2 mb-2"
                *ngIf="!data.Data.Path">
                <label class="label-nonmd">Datei *</label>
                <p-fileUpload #inputFile
                    mode="advanced"
                    name="file"
                    (onSelect)="onSelect($event)"
                    (onRemove)="onRemove($event)"
                    (onClear)="onClear($event)"
                    [multiple]="false"
                    [showUploadButton]="false"
                    chooseLabel="Datei wählen"
                    cancelLabel="Abbrechen"
                    [showCancelButton]="false"
                    [customUpload]="true"></p-fileUpload>
            </div>

            <div>
                <label [ngClass]="{'label-nonmd':true}">Beschreibung</label>
                <textarea rows="5"
                    name="description"
                    style="width: 100%;"
                    pInputTextarea
                    [(ngModel)]="data.Data.Description"></textarea>

            </div>

            <avi-core-dbg [m]="data"></avi-core-dbg>
        </div>


        <div *ngIf="currentStep == 1">
            <mat-form-field style="width: 100%">
                <mat-label>Schlüsselwort 1</mat-label>
                <input #inputTitle
                    cdkFocusInitial
                    required
                    matInput
                    name="keyword1"
                    [(ngModel)]="data.Data.Title">
            </mat-form-field>
        </div>

    </div>
    <div mat-dialog-actions>

        <button mat-button
            *ngIf="currentStep === 0"
            (click)="onNoClick()">Abbrechen</button>

        <button mat-button
            *ngIf="currentStep === 0"
            [disabled]="!form.valid || (!data.Data.File && !data.Data.Path)"
            (click)="onNextClick()">Weiter</button>

        <button mat-button
            *ngIf="currentStep === 1"
            (click)="onPrevClick()">Zur&uuml;ck</button>

        <button mat-button
            *ngIf="currentStep === 1"
            [disabled]="!form.valid || (!data.Data.File && !data.Data.Path)"
            (click)="onOkClick()">Speichern</button>

    </div>
</form>
