import { Expose, Type } from 'class-transformer';

import { Job } from '../system/job.model';
import { JobParamDef } from '../system/jobparamdef.model';
import { ListDetail } from '../system/listdetail.model';
import { BaseModel } from '../shared/basemodel.model';
import { GenericRef } from '../shared/genericref.model';

export class JobParam extends BaseModel {  
    @Expose()
    public get ValueInt(): number { return this.getProperty('ValueInt'); }
    public set ValueInt(value: number) { this.setProperty('ValueInt', value); }
    
    @Expose()
    public get ValueDec(): number { return this.getProperty('ValueDec'); }
    public set ValueDec(value: number) { this.setProperty('ValueDec', value); }
    
    @Expose()
    public get ValueText(): string { return this.getProperty('ValueText'); }
    public set ValueText(value: string) { this.setProperty('ValueText', value); }
    
    @Expose()
    @Type(() => Date)
    public get ValueDate(): Date { return this.getProperty('ValueDate'); }
    public set ValueDate(value: Date) { this.setProperty('ValueDate', value); }
    
    @Expose()
    public get Sequence(): number { return this.getProperty('Sequence'); }
    public set Sequence(value: number) { this.setProperty('Sequence', value); }
    
    @Expose()
    public get fromJob_ID(): string { return this.getModelId('fromJob_ID'); }
    public set fromJob_ID(value: string) { this.setModelId('fromJob_ID', value); }
 
    @Expose()
    @Type(() => Job)
    public get fromJob(): Job { return this.getModel('fromJob'); }
    public set fromJob(value: Job) { this.setModel('fromJob', value); }
    
    @Expose()
    public get fromJobParamDef_ID(): string { return this.getModelId('fromJobParamDef_ID'); }
    public set fromJobParamDef_ID(value: string) { this.setModelId('fromJobParamDef_ID', value); }
 
    @Expose()
    @Type(() => JobParamDef)
    public get fromJobParamDef(): JobParamDef { return this.getModel('fromJobParamDef'); }
    public set fromJobParamDef(value: JobParamDef) { this.setModel('fromJobParamDef', value); }
    
    @Expose()
    public get ValueList_ID(): string { return this.getModelId('ValueList_ID'); }
    public set ValueList_ID(value: string) { this.setModelId('ValueList_ID', value); }
 
    @Expose()
    @Type(() => ListDetail)
    public get ValueList(): ListDetail { return this.getModel('ValueList'); }
    public set ValueList(value: ListDetail) { this.setModel('ValueList', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get ValueRef(): GenericRef { return this.getProperty('ValueRef'); }
    public set ValueRef(value: GenericRef) { this.setProperty('ValueRef', value); }

    // Transient
    @Expose()
    public get ParamRefType(): string { return this.getProperty('ParamRefType'); }
    public set ParamRefType(value: string) { this.setProperty('ParamRefType', value); }
}
