import { Expose, Type } from 'class-transformer';

import { AviDocKategorie } from '../avidoc_def/avidockategorie.model';
import { TechRole } from '../system/techrole.model';
import { AktenNotizDef } from '../crm/aktennotizdef.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class AviDocArt extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get KeywordDocArtName(): string { return this.getProperty('KeywordDocArtName'); }
    public set KeywordDocArtName(value: string) { this.setProperty('KeywordDocArtName', value); }
    
    @Expose()
    public get KeywordGruppeName(): string { return this.getProperty('KeywordGruppeName'); }
    public set KeywordGruppeName(value: string) { this.setProperty('KeywordGruppeName', value); }
    
    @Expose()
    public get KeywordUntergruppeName(): string { return this.getProperty('KeywordUntergruppeName'); }
    public set KeywordUntergruppeName(value: string) { this.setProperty('KeywordUntergruppeName', value); }
    
    @Expose()
    public get Dateifilter(): string { return this.getProperty('Dateifilter'); }
    public set Dateifilter(value: string) { this.setProperty('Dateifilter', value); }
    
    @Expose()
    public get DateidatumDefaultValue(): string { return this.getProperty('DateidatumDefaultValue'); }
    public set DateidatumDefaultValue(value: string) { this.setProperty('DateidatumDefaultValue', value); }
    
    @Expose()
    public get AblaufDatumTage(): number { return this.getProperty('AblaufDatumTage'); }
    public set AblaufDatumTage(value: number) { this.setProperty('AblaufDatumTage', value); }
    
    @Expose()
    public get vonDocKategorie_ID(): string { return this.getModelId('vonDocKategorie_ID'); }
    public set vonDocKategorie_ID(value: string) { this.setModelId('vonDocKategorie_ID', value); }
 
    @Expose()
    @Type(() => AviDocKategorie)
    public get vonDocKategorie(): AviDocKategorie { return this.getModel('vonDocKategorie'); }
    public set vonDocKategorie(value: AviDocKategorie) { this.setModel('vonDocKategorie', value); }
    
    @Expose()
    public get AblaufDatumEditRole_ID(): string { return this.getModelId('AblaufDatumEditRole_ID'); }
    public set AblaufDatumEditRole_ID(value: string) { this.setModelId('AblaufDatumEditRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get AblaufDatumEditRole(): TechRole { return this.getModel('AblaufDatumEditRole'); }
    public set AblaufDatumEditRole(value: TechRole) { this.setModel('AblaufDatumEditRole', value); }
    
    @Expose()
    public get AktenNotizDef_ID(): string { return this.getModelId('AktenNotizDef_ID'); }
    public set AktenNotizDef_ID(value: string) { this.setModelId('AktenNotizDef_ID', value); }
 
    @Expose()
    @Type(() => AktenNotizDef)
    public get AktenNotizDef(): AktenNotizDef { return this.getModel('AktenNotizDef'); }
    public set AktenNotizDef(value: AktenNotizDef) { this.setModel('AktenNotizDef', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AblaufDatumZwingend(): ListType { return this.getProperty('AblaufDatumZwingend'); }
    public set AblaufDatumZwingend(value: ListType) { this.setProperty('AblaufDatumZwingend', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DefaultStatus(): ListType { return this.getProperty('DefaultStatus'); }
    public set DefaultStatus(value: ListType) { this.setProperty('DefaultStatus', value); }
    
}
