<div [ngClass]="{'full-screen': FullScreen}">
    <div class="grid">
        <div class="col-3 lg:col-4 sm:col-1"></div>
        <div class="col-6 lg:col-4 sm:col-10">
            <div class="mat-elevation-z1">
                <avi-core-view-panel [header]="Title" [toggleable]="false" [no-pad]="true">
                    <div *ngIf="!ShowTotpInputSSO && displayedForm == 'login'">
                        <form [formGroup]="loginForm"
                            (ngSubmit)="onSubmit()">
                            <div class="grid form-group"
                                [ngClass]="{'p-4': commonService.IsDesktopView(), 'p-2': !commonService.IsDesktopView()}">

                                <div class="col-12">
                                    <ng-content select="[header]"></ng-content>
                                </div>

                                <div *ngIf="!ShowTotpInput"
                                    class="col-12 md:col-6">
                                    <span class="mb-12">
                                        <label for="login-username">Benutzername</label>
                                        <input formControlName="username"
                                            [focus]="true"
                                            required
                                            name="username"
                                            id="login-username"
                                            type="text"
                                            class="w-100p"
                                            pInputText>
                                    </span>

                                </div>
                                <div *ngIf="!ShowTotpInput"
                                    class="col-12 md:col-6">
                                    <span class="mb-12">
                                        <label for="login-password">Passwort</label>
                                        <input formControlName="password"
                                            id="login-password"
                                            type="password"
                                            class="w-100p"
                                            pInputText>
                                    </span>
                                </div>

                                <div *ngIf="ShowTotpInput"
                                    class="col-12 md:col-12 animated fadeInDown">
                                    <span class="md-inputfield mb-12">
                                        <label>Time-based One-time (TOTP)</label>
                                        <input formControlName="totp"
                                            id="totpInput"
                                            type="password"
                                            class="w-100p"
                                            pInputText>
                                    </span>
                                </div>

                                <div class="col-12 md:col-12 pt-4">
                                    <button [disabled]="busy || !loginForm.valid"
                                        pButton
                                        type="submit"
                                        class="w-100p"
                                        label="Login"></button>
                                    <p><a (click)="initPwReset('')"
                                            class="small text-light">Passwort vergessen</a></p>

                                </div>

                                <div class="col-12">
                                    <ng-content select="[footer]"></ng-content>
                                </div>

                            </div>
                        </form>
                    </div>

                    <div *ngIf="ShowSsoLoginForm && displayedForm == 'login'">
                        <form [formGroup]="loginFormSSO"
                            (ngSubmit)="onSubmitSSO()">
                            <div class="grid form-group"
                                [ngClass]="{'p-0': commonService.IsDesktopView(), 'p-4': !commonService.IsDesktopView(), 'pt-4': false}">

                                <div class="col-12">
                                    <ng-content select="[header]"></ng-content>
                                </div>

                                <div *ngIf="ShowTotpInputSSO"
                                    class="col-12 md:col-12">
                                    <span class="md-inputfield mb-12">
                                        <input formControlName="totp"
                                            id="totpInputSSO"
                                            type="password"
                                            pInputText>
                                        <label>Time-based One-time (TOTP)</label>
                                    </span>
                                </div>

                                <div class="col-12 md:col-12">
                                    <button [disabled]="busy || !loginFormSSO.valid"
                                        pButton
                                        type="submit"
                                        class="w-100p p-button-secondary mt-20"
                                        label="Login mit Windows (SSO)"
                                        icon="pi pi-unlock"></button>

                                </div>

                                <div class="col-12">
                                    <ng-content select="[footer]"></ng-content>
                                </div>

                            </div>
                        </form>
                    </div>




                    <div *ngIf="displayedForm == 'initPwReset'">
                        <form [formGroup]="initPwResetForm"
                            (ngSubmit)="onSubmitInitPwReset()">
                            <div class="grid form-group"
                                [ngClass]="{'p-0': commonService.IsDesktopView(), 'p-4': !commonService.IsDesktopView(), 'pt-4': true}">

                                <div class="col-12">
                                    <ng-content select="[headerInitPwReset]"></ng-content>
                                </div>

                                <div class="col-12 md:col-12">
                                    <span class="md-inputfield mb-12">
                                        <input formControlName="username"
                                            [focus]="true"
                                            required
                                            name="username"
                                            type="text"
                                            pInputText>
                                        <label>Benutzername</label>
                                    </span>
                                </div>
                                <div class="col-12 md:col-12">
                                    <button [disabled]="busy || !initPwResetForm.valid"
                                        pButton
                                        type="submit"
                                        label="Passwort zurücksetzen"></button>
                                    <p><a (click)="displayedForm = 'login'"
                                            class="small text-light">Abbrechen</a></p>

                                </div>
                                <div class="col-12">
                                    <ng-content select="[footerInitPwReset]"></ng-content>
                                </div>

                            </div>
                        </form>
                    </div>


                    <div *ngIf="displayedForm == 'doPwReset'">
                        <form [formGroup]="doPwResetForm"
                            (ngSubmit)="onSubmitDoPwReset()">
                            <div class="grid form-group"
                                [ngClass]="{'p-0': commonService.IsDesktopView(), 'p-4': !commonService.IsDesktopView(), 'pt-4': true}">

                                <div class="col-12">
                                    <ng-content select="[headerDoPwReset]"></ng-content>
                                </div>

                                <div class="col-12 md:col-6">
                                    <span class="md-inputfield mb-12">
                                        <input formControlName="username"
                                            required
                                            name="username"
                                            type="text"
                                            pInputText>
                                        <label>Benutzername</label>
                                    </span>
                                </div>

                                <div class="col-12 md:col-6">
                                    <span class="md-inputfield mb-12">
                                        <input formControlName="token"
                                            required
                                            [focus]="true"
                                            name="token"
                                            type="text"
                                            mask="AAA-AAA-AAA"
                                            pInputText>
                                        <label>Token</label>
                                    </span>
                                </div>

                                <div class="col-12 md:col-6">
                                    <span class="md-inputfield mb-12">
                                        <input formControlName="password1"
                                            type="password"
                                            pInputText>
                                        <label>Passwort</label>
                                    </span>
                                </div>

                                <div class="col-12 md:col-6">
                                    <span class="md-inputfield mb-12">
                                        <input formControlName="password2"
                                            type="password"
                                            pInputText>
                                        <label>Passwort wiederholen</label>
                                    </span>
                                </div>

                                <div class="col-12 md:col-12">
                                    <button [disabled]="busy || !doPwResetForm.valid"
                                        pButton
                                        type="submit"
                                        label="Passwort speichern"></button>
                                    <p><a (click)="displayedForm = 'login'"
                                            class="small text-light">Abbrechen</a></p>

                                </div>

                                <div class="col-12">
                                    <ng-content select="[footerDoPwReset]"></ng-content>
                                </div>

                            </div>
                        </form>
                    </div>


                    <div style="height:6px;">
                        <p-progressBar *ngIf="busy"
                            mode="indeterminate"
                            [style]="{'height': '6px'}"></p-progressBar>
                    </div>


                </avi-core-view-panel>
            </div>

        </div>

        <div class="col-3  lg:col-4 sm:col-1"></div>
    </div>
</div>
