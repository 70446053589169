<avi-core-page
    [card]="true"
    [collapsible]="false"
    [fill-height]="true"
    [contents-padding]="false">

    <div class="grid">       

        <div class="col-12">
            <p-toolbar styleClass="no-pad">
                <div class="p-toolbar-group-left flex-gap-2">
                    <ng-container *ngFor="let item of TaskMenuItems">
                        <button pButton *ngIf="!item.separator"
                            type="button"
                            [disabled]="item.disabled"
                            [label]="item.label"
                            [icon]="item.icon"
                            (click)="item.command()"
                            class="w-100p-m p-button-secondary">
                        </button>
                    </ng-container>
                </div>
            </p-toolbar>  
        </div>

        <div class="col-12">
            <ng-content select="[searcher]">        
            </ng-content>

            <div class="pt-3">
                <ng-content #form select="[form]">      
                </ng-content>
            </div>
        </div>
    </div>

</avi-core-page>