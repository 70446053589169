<avi-core-page
    [title]="Titel"
    [card]="Card"
    [collapsible]="false"
    [expanded]="true"
    [contents-padding]="true"
    [no-box-shadow]="true"
>

    <avi-core-base-searcher
        #searcher
        [settingskey]="SettingsKey"
        [keep-selection]="CheckboxSelection"
        [hide-when-noresults]="false"
        [use-router-params]="UseRouterParams"
        [use-router-params-selection]="false"
        [disable-inactive-filter]="true"
        [card]="false"
        [contents-padding]="false"
        [search-delegate]="SearchDelegate"
        [selectall-delegate]="SelectAllDelegate"
        [reorderable-columns]="true"
        [rows]="20"
        [toolbar]="true"
        [toolbar-show-searchinput]="false"
        [toolbar-show-inactivebutton]="false"
        [toolbar-show-columnselection]="true"
        [toolbar-buttons]="toolbarButtons"
        [visible-columns]="VisibleColumns"
        [no-word-wrap]="true"
        [auto-search]="true"
        [show-autofilter]="true"
        [get-filters]="GetFilters"
        (onClick)="onClick($event)"
        (onDoubleClick)="onDoubleClick($event)"
        (onSelect)="onSelect($event)"
        [select-first-result]="false"
        [checkbox-selection]="CheckboxSelection"
        [checkbox-selectionmode]="CheckboxSelectionMode"
        searcher-title=""
        [get-row-class]="GetRowClass"
        [responsive]="false"
        (onInit)="onSearcherInit($event)"
        [show-totalrecords]="true"
        [show-totalrecordsunfiltered]="true"
        [show-totalselected]="CheckboxSelection"
        [total-label]="'VWBP.COMMON.RT_INANSICHT' | translate"
        [total-unfiltered-label]="'VWBP.COMMON.TOTALRT' | translate"
        [total-selected-label]="'VWBP.COMMON.RT_SELECTIERT' | translate"
        [toolbarcontrols-template]="deletedTemplate"
    >
        <ng-template #deletedTemplate>
            <ng-container>
                <div *ngIf="ShowDeletedFilter" fxFlex="initial">
                    <!-- <div> -->
                        <p-checkbox
                            name="chkdeleted"
                            [binary]="true"
                            [ngModel]="GetIncludeDeletedValue()"
                            (ngModelChange)="SetIncludeDeletedValue($event)"
                            [label]="'VWBP.COMMON.AUCH_GELOESCHTE_RTS_SUCHEN' | translate"
                        ></p-checkbox>
                    <!-- </div> -->
                </div>
            </ng-container>
        </ng-template>

    </avi-core-base-searcher>
</avi-core-page>

<vwbp-intern-rechtstraeger-comment-list
    *ngIf="ShowCommentDetailView && DetailModel && DetailModel.BemerkungCount > 0"
    [readonly]="!currentUserIsAjuUserSchreiben()"
    [rechtstraeger-id]="DetailModel.RechtstraegerId"
    [version-id]="DetailModel.Id"
    [version-typ]="DetailModel.VersionTyp"
    [version-number]="DetailModel.Version"
    (onChange)="forceRefresh()"
>
</vwbp-intern-rechtstraeger-comment-list>
<!--
<vwbp-intern-rechtstraeger-log *ngIf="DetailModel" [rechtstraeger-id]="DetailModel.RechtstraegerId">
</vwbp-intern-rechtstraeger-log> -->
