import { Expose, Type } from 'class-transformer';

import { CustomPropCollectionDef } from '../system/custompropcollectiondef.model';
import { List } from '../system/list.model';
import { ModelMeta } from '../system/modelmeta.model';
import { ListDetail } from '../system/listdetail.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared/genericref.model';

export class CustomPropDef extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get NullValuePrompt1(): string { return this.getProperty('NullValuePrompt1'); }
    public set NullValuePrompt1(value: string) { this.setProperty('NullValuePrompt1', value); }
    
    @Expose()
    public get NullValuePrompt2(): string { return this.getProperty('NullValuePrompt2'); }
    public set NullValuePrompt2(value: string) { this.setProperty('NullValuePrompt2', value); }
    
    @Expose()
    public get NullValuePrompt3(): string { return this.getProperty('NullValuePrompt3'); }
    public set NullValuePrompt3(value: string) { this.setProperty('NullValuePrompt3', value); }
    
    @Expose()
    public get NullValuePrompt4(): string { return this.getProperty('NullValuePrompt4'); }
    public set NullValuePrompt4(value: string) { this.setProperty('NullValuePrompt4', value); }
    
    @Expose()
    public get Sequenz(): number { return this.getProperty('Sequenz'); }
    public set Sequenz(value: number) { this.setProperty('Sequenz', value); }
    
    @Expose()
    public get PropertyName(): string { return this.getProperty('PropertyName'); }
    public set PropertyName(value: string) { this.setProperty('PropertyName', value); }
    
    @Expose()
    public get NotNullWennDependentAttrNull(): string { return this.getProperty('NotNullWennDependentAttrNull'); }
    public set NotNullWennDependentAttrNull(value: string) { this.setProperty('NotNullWennDependentAttrNull', value); }
    
    @Expose()
    public get PropertyLength(): number { return this.getProperty('PropertyLength'); }
    public set PropertyLength(value: number) { this.setProperty('PropertyLength', value); }
    
    @Expose()
    public get Mask(): string { return this.getProperty('Mask'); }
    public set Mask(value: string) { this.setProperty('Mask', value); }
    
    @Expose()
    public get RegEx(): string { return this.getProperty('RegEx'); }
    public set RegEx(value: string) { this.setProperty('RegEx', value); }
    
    @Expose()
    public get IsPassword(): boolean { return this.getProperty('IsPassword'); }
    public set IsPassword(value: boolean) { this.setProperty('IsPassword', value); }
    
    @Expose()
    public get Nachkommastellen(): number { return this.getProperty('Nachkommastellen'); }
    public set Nachkommastellen(value: number) { this.setProperty('Nachkommastellen', value); }
    
    @Expose()
    public get minValue(): string { return this.getProperty('minValue'); }
    public set minValue(value: string) { this.setProperty('minValue', value); }
    
    @Expose()
    public get maxValue(): string { return this.getProperty('maxValue'); }
    public set maxValue(value: string) { this.setProperty('maxValue', value); }
    
    @Expose()
    public get ValidateService(): string { return this.getProperty('ValidateService'); }
    public set ValidateService(value: string) { this.setProperty('ValidateService', value); }
    
    @Expose()
    public get DefaultBoolPropValue(): boolean { return this.getProperty('DefaultBoolPropValue'); }
    public set DefaultBoolPropValue(value: boolean) { this.setProperty('DefaultBoolPropValue', value); }
    
    @Expose()
    public get DefaultIntPropValue(): number { return this.getProperty('DefaultIntPropValue'); }
    public set DefaultIntPropValue(value: number) { this.setProperty('DefaultIntPropValue', value); }
    
    @Expose()
    public get DefaultDecPropValue(): number { return this.getProperty('DefaultDecPropValue'); }
    public set DefaultDecPropValue(value: number) { this.setProperty('DefaultDecPropValue', value); }
    
    @Expose()
    public get DefaultTextPropValue(): string { return this.getProperty('DefaultTextPropValue'); }
    public set DefaultTextPropValue(value: string) { this.setProperty('DefaultTextPropValue', value); }
    
    @Expose()
    public get DefaultDatePropValue(): string { return this.getProperty('DefaultDatePropValue'); }
    public set DefaultDatePropValue(value: string) { this.setProperty('DefaultDatePropValue', value); }
    
    @Expose()
    public get Columns(): number { return this.getProperty('Columns'); }
    public set Columns(value: number) { this.setProperty('Columns', value); }
    
    @Expose()
    public get MemoTextEdit(): boolean { return this.getProperty('MemoTextEdit'); }
    public set MemoTextEdit(value: boolean) { this.setProperty('MemoTextEdit', value); }
    
    @Expose()
    public get Paging(): boolean { return this.getProperty('Paging'); }
    public set Paging(value: boolean) { this.setProperty('Paging', value); }
    
    @Expose()
    public get WithoutLabel(): boolean { return this.getProperty('WithoutLabel'); }
    public set WithoutLabel(value: boolean) { this.setProperty('WithoutLabel', value); }
    
    @Expose()
    public get MemoEditOptimalHeight(): boolean { return this.getProperty('MemoEditOptimalHeight'); }
    public set MemoEditOptimalHeight(value: boolean) { this.setProperty('MemoEditOptimalHeight', value); }
    
    @Expose()
    public get BulletedText(): boolean { return this.getProperty('BulletedText'); }
    public set BulletedText(value: boolean) { this.setProperty('BulletedText', value); }
    
    @Expose()
    public get GroupID(): number { return this.getProperty('GroupID'); }
    public set GroupID(value: number) { this.setProperty('GroupID', value); }
    
    @Expose()
    public get RadioGroupIndex(): number { return this.getProperty('RadioGroupIndex'); }
    public set RadioGroupIndex(value: number) { this.setProperty('RadioGroupIndex', value); }
    
    @Expose()
    public get IsRadioGroup(): boolean { return this.getProperty('IsRadioGroup'); }
    public set IsRadioGroup(value: boolean) { this.setProperty('IsRadioGroup', value); }
    
    @Expose()
    public get Height(): number { return this.getProperty('Height'); }
    public set Height(value: number) { this.setProperty('Height', value); }

    @Expose()
    public get Description(): string { return this.getProperty('Description'); }
    public set Description(value: string) { this.setProperty('Description', value); }
    
    @Expose()
    public get DefaultAttributPath(): string { return this.getProperty('DefaultAttributPath'); }
    public set DefaultAttributPath(value: string) { this.setProperty('DefaultAttributPath', value); }
    
    @Expose()
    public get vonCollection_ID(): string { return this.getModelId('vonCollection_ID'); }
    public set vonCollection_ID(value: string) { this.setModelId('vonCollection_ID', value); }

    // @Expose()
    // @Type(() => CustomPropCollectionDef)
    // public get vonCollection(): CustomPropCollectionDef { return this.getModel('vonCollection'); }
    // public set vonCollection(value: CustomPropCollectionDef) { this.setModel('vonCollection', value); }
    
    @Expose()
    public get List_ID(): string { return this.getModelId('List_ID'); }
    public set List_ID(value: string) { this.setModelId('List_ID', value); }
 
    @Expose()
    @Type(() => List)
    public get List(): List { return this.getModel('List'); }
    public set List(value: List) { this.setModel('List', value); }
    
    @Expose()
    public get Model_ID(): string { return this.getModelId('Model_ID'); }
    public set Model_ID(value: string) { this.setModelId('Model_ID', value); }
 
    // @Expose()
    // @Type(() => ModelMeta)
    // public get Model(): ModelMeta { return this.getModel('Model'); }
    // public set Model(value: ModelMeta) { this.setModel('Model', value); }
    
    @Expose()
    public get DefaultListDetailPropValue_ID(): string { return this.getModelId('DefaultListDetailPropValue_ID'); }
    public set DefaultListDetailPropValue_ID(value: string) { this.setModelId('DefaultListDetailPropValue_ID', value); }
 
    // @Expose()
    // @Type(() => ListDetail)
    // public get DefaultListDetailPropValue(): ListDetail { return this.getModel('DefaultListDetailPropValue'); }
    // public set DefaultListDetailPropValue(value: ListDetail) { this.setModel('DefaultListDetailPropValue', value); }
    
    @Expose()
    @Type(() => ListType)
    public get PropertyType(): ListType { return this.getProperty('PropertyType'); }
    public set PropertyType(value: ListType) { this.setProperty('PropertyType', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Mandatory(): ListType { return this.getProperty('Mandatory'); }
    public set Mandatory(value: ListType) { this.setProperty('Mandatory', value); }
    
    @Expose()
    @Type(() => ListType)
    public get IsReadOnlyInGui(): ListType { return this.getProperty('IsReadOnlyInGui'); }
    public set IsReadOnlyInGui(value: ListType) { this.setProperty('IsReadOnlyInGui', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Visibility(): ListType { return this.getProperty('Visibility'); }
    public set Visibility(value: ListType) { this.setProperty('Visibility', value); }
    
    @Expose()
    @Type(() => ListType)
    public get LabelPosition(): ListType { return this.getProperty('LabelPosition'); }
    public set LabelPosition(value: ListType) { this.setProperty('LabelPosition', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerwendungAls(): ListType { return this.getProperty('VerwendungAls'); }
    public set VerwendungAls(value: ListType) { this.setProperty('VerwendungAls', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get DefaultRefPropValue(): GenericRef { return this.getProperty('DefaultRefPropValue'); }
    public set DefaultRefPropValue(value: GenericRef) { this.setProperty('DefaultRefPropValue', value); }
    

    @Expose()
    public get MandatoryWennAttrName(): string { return this.getProperty('MandatoryWennAttrName'); }
    public set MandatoryWennAttrName(value: string) { this.setProperty('MandatoryWennAttrName', value); }

    
    @Expose()
    public get MandatoryWennAttrValue(): string { return this.getProperty('MandatoryWennAttrValue'); }
    public set MandatoryWennAttrValue(value: string) { this.setProperty('MandatoryWennAttrValue', value); }

    
    @Expose()
    public get ReadonlyWennAttrName(): string { return this.getProperty('ReadonlyWennAttrName'); }
    public set ReadonlyWennAttrName(value: string) { this.setProperty('ReadonlyWennAttrName', value); }

    
    @Expose()
    public get ReadonlyWennAttrValue(): string { return this.getProperty('ReadonlyWennAttrValue'); }
    public set ReadonlyWennAttrValue(value: string) { this.setProperty('ReadonlyWennAttrValue', value); }
}
