import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviDokumentAblageListDetailConst, AviFormField, AviFormFieldService, AviMetaBrowserComponent, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocPropContainerReference } from '@avi-x/avi-dto/documatrix/docpropcontainerreference.model';

const nameof = (nameFunction: ((obj: DocPropContainerReference) => any)) => typed_nameof<DocPropContainerReference>(nameFunction);

@Component({
    selector: 'avi-docpropcontainerreference-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocPropContainerReferenceFormComponent extends AviAbstractModelFormComponent<DocPropContainerReference> {  
    ClassName = 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERREFERENCE';
    BaseUrl = 'documatrixdef/reference';
    ClassType = DocPropContainerReference;
	FormType = AviDocPropContainerReferenceFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
        public dialogService: DialogService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFDocuMatrixTemplate';
    }
	
    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.vonModelMeta_ID)) 
            this.formFieldService.setFieldButtonDisabled(this.Fields, nameof(c => c.Path), this.Model.vonModelMeta_ID == null);
        else if (data.field === nameof(c => c.Typ))
            this.setFromTyp();
    }

    public async afterInitForm(): Promise<void> {
        this.formFieldService.setFieldButtonDisabled(this.Fields, nameof(c => c.Path), this.Model.vonModelMeta_ID == null);

        this.setFromTyp();
    }

    public async afterCreateModel(bm: DocPropContainerReference) {
        bm.ParentContainer_ID = this.ContextId;
    }

    private setFromTyp() {
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SqlText), this.Model.Typ.Id === AviDokumentAblageListDetailConst.CAF_DOCPROP_TYP_SQLQUERY);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Path), this.Model.Typ.Id === AviDokumentAblageListDetailConst.CAF_DOCPROP_TYP_ATTR);        
    }
    
    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERREFERENCE.BEZEICHNUNG', false).setMDSizeFull());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Typ), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERREFERENCE.TYP', 'caf_docprop_typ', true, true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonModelMeta_ID), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERREFERENCE.VONMODELMETA', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeFull());
        
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.SqlText), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERREFERENCE.SQLTEXT', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTextWithButton(nameof(c => c.Path), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERREFERENCE.PATH', false, '...', this.openMetaBrowser).setReadonly(true).setMDSizeFull().setMaxLength(255));
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.SourceContainer_ID), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINERREFERENCE.SOURCECONTAINER', `model/representations/CAFDocPropContainer${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull());
    }

    openMetaBrowser = async (field: AviFormField) => {
        const res = await this.commonService.openMetaBrowser(this.Model.vonModelMeta_ID, 'reference');
        if (res) {
            this.Model.Path = res.FullPath;
            this.cdr.markForCheck();
        }
    }
}
