import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class BusinesscaseDataDef extends BaseModel {  
    @Expose()
    public get Bezeichnung(): string { return this.getProperty('Bezeichnung'); }
    public set Bezeichnung(value: string) { this.setProperty('Bezeichnung', value); }
    
    @Expose()
    public get Description(): string { return this.getProperty('Description'); }
    public set Description(value: string) { this.setProperty('Description', value); }

    @Expose()
    public get DraftWatermark(): string { return this.getProperty('DraftWatermark'); }
    public set DraftWatermark(value: string) { this.setProperty('DraftWatermark', value); }
    
    @Expose()
    public get CustomFormId(): string { return this.getProperty('CustomFormId'); }
    public set CustomFormId(value: string) { this.setProperty('CustomFormId', value); }
    
    @Expose()
    public get CustomDataId(): string { return this.getProperty('CustomDataId'); }
    public set CustomDataId(value: string) { this.setProperty('CustomDataId', value); }
    
    @Expose()
    public get vonBusinesscaseDef_ID(): string { return this.getModelId('vonBusinesscaseDef_ID'); }
    public set vonBusinesscaseDef_ID(value: string) { this.setModelId('vonBusinesscaseDef_ID', value); }
 
    @Expose()
    public get StatusDef_ID(): string { return this.getModelId('StatusDef_ID'); }
    public set StatusDef_ID(value: string) { this.setModelId('StatusDef_ID', value); }
 
    @Expose()
    public get DocArt_ID(): string { return this.getModelId('DocArt_ID'); }
    public set DocArt_ID(value: string) { this.setModelId('DocArt_ID', value); }
  
    @Expose()
    public get DocTemplate_ID(): string { return this.getModelId('DocTemplate_ID'); }
    public set DocTemplate_ID(value: string) { this.setModelId('DocTemplate_ID', value); }
 
    @Expose()
    public get DocuMatrixTemplate_ID(): string { return this.getModelId('DocuMatrixTemplate_ID'); }
    public set DocuMatrixTemplate_ID(value: string) { this.setModelId('DocuMatrixTemplate_ID', value); }

    @Expose()
    public get ActivityType_ID(): string { return this.getModelId('ActivityType_ID'); }
    public set ActivityType_ID(value: string) { this.setModelId('ActivityType_ID', value); }
 
    @Expose()
    public get FormDef_ID(): string { return this.getModelId('FormDef_ID'); }
    public set FormDef_ID(value: string) { this.setModelId('FormDef_ID', value); }
 
    @Expose()
    public get CorrectDataDef_ID(): string { return this.getModelId('CorrectDataDef_ID'); }
    public set CorrectDataDef_ID(value: string) { this.setModelId('CorrectDataDef_ID', value); }
 
    @Expose()
    @Type(() => ListType)
    public get DataType(): ListType { return this.getProperty('DataType'); }
    public set DataType(value: ListType) { this.setProperty('DataType', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Mandatory(): ListType { return this.getProperty('Mandatory'); }
    public set Mandatory(value: ListType) { this.setProperty('Mandatory', value); }
    
    @Expose()
    @Type(() => ListType)
    public get EditDocument(): ListType { return this.getProperty('EditDocument'); }
    public set EditDocument(value: ListType) { this.setProperty('EditDocument', value); }
    
    @Expose()
    @Type(() => ListType)
    public get FinishDocument(): ListType { return this.getProperty('FinishDocument'); }
    public set FinishDocument(value: ListType) { this.setProperty('FinishDocument', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DocFormat(): ListType { return this.getProperty('DocFormat'); }
    public set DocFormat(value: ListType) { this.setProperty('DocFormat', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DocSign(): ListType { return this.getProperty('DocSign'); }
    public set DocSign(value: ListType) { this.setProperty('DocSign', value); }
    
    @Expose()
    @Type(() => ListType)
    public get MustBeFinished(): ListType { return this.getProperty('MustBeFinished'); }
    public set MustBeFinished(value: ListType) { this.setProperty('MustBeFinished', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Versioning(): ListType { return this.getProperty('Versioning'); }
    public set Versioning(value: ListType) { this.setProperty('Versioning', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ShowBemerkung(): ListType { return this.getProperty('ShowBemerkung'); }
    public set ShowBemerkung(value: ListType) { this.setProperty('ShowBemerkung', value); }

    @Expose()
    public get Internal(): boolean { return this.getProperty('Internal'); }
    public set Internal(value: boolean) { this.setProperty('Internal', value); }

    @Expose()
    @Type(() => ListType)
    public get Context(): ListType { return this.getProperty('Context'); }
    public set Context(value: ListType) { this.setProperty('Context', value); }
}
