import { Component, Input, ChangeDetectorRef, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService, AviDialogComponent } from '@avi-x/avi-core';
import { VwbPFormularDaten, VwbPListDetailConst, VwbPSchwaerzenAntragDaten, VwbPVersion } from '@avi-x/vwbp-public';

import { SchwaerzenRueckgaengigMode } from '../schwaerzen-tasks/schwaerzen-antrag-rueckgaengig-task.component';
import { AntragEditMode, VwbPInternSchwaerzenAntragFormComponent } from '../schwaerzen-form/schwaerzen-form.component';

export enum SchwaerzenTaskMode {
    // eslint-disable-next-line no-unused-vars
    ANTRAG_BEWILLIGEN, ANTRAG_ABLEHNEN, RECHTSTRAEGER_INFORMIEREN, KONTROLLIEREN, ANTRAG_RECHTSMITTELFRIST_SETZEN, ANTRAG_RECHTSKRAFT_SETZEN, ANTRAG_GUELTIGKEIT_SETZEN, ANTRAG_WB_NICHT_MEHR_VORHANDEN, RUECKGAENGIG
}

@Component({
    selector: 'vwbp-intern-schwaerzen-taskbar',
    templateUrl: './schwaerzen-taskbar.component.html',
    styleUrls: ['./schwaerzen-taskbar.component.scss'],
    providers: [DecimalPipe, PercentPipe]
})
export class VwbPInternSchwaerzenTaskbarComponent {
    @ViewChild('AntragFormDialog', { static: false })
    AntragFormDialog: AviDialogComponent;

    @ViewChild('AntragForm', { static: false })
    public AntragForm: VwbPInternSchwaerzenAntragFormComponent;

    public FormularDaten: VwbPFormularDaten[];

    formActionButtons: any[] = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];
    formActionButtonsReadonly: any[] = [{ title: 'CORE.COMMON.SCHLIESSEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];

    //
    @ViewChild('taskDialog', { static: false })
    taskDialog: AviDialogComponent;

    AntragId: string = null;
    _Antrag: VwbPSchwaerzenAntragDaten = null;
    public get Antrag(): VwbPSchwaerzenAntragDaten {
        return this._Antrag;
    }

    @Input('antrag')
    public set Antrag(value: VwbPSchwaerzenAntragDaten) {
        this._Antrag= value;
        this.AntragId = this.Antrag?.Id;
        this.LoadData();
    }

    VersionId: string = null;
    _Version: VwbPVersion = null;
    public get Version(): VwbPVersion {
        return this._Version;
    }

    @Input('version')
    public set Version(value: VwbPVersion) {
        this._Version = value;
        this.VersionId = this.Version?.Id;
        this.LoadData();
    }

    @Input('readonly')
    public Readonly: boolean = false;

    @Output('onChanged')
    public OnChanged: EventEmitter<any> = new EventEmitter();

    selectedRT: any[] = [];

    RueckgaengigMode: SchwaerzenRueckgaengigMode;
    VwbPListDetailConst: VwbPListDetailConst;

    public SchwaerzenTaskMode = SchwaerzenTaskMode;
    Modus: SchwaerzenTaskMode;

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, private cdr: ChangeDetectorRef) {
    }

    async LoadData() {
        this.cdr.markForCheck();
    }

    doTask(selectedRT: any[], modus: SchwaerzenTaskMode) {
        this.selectedRT = selectedRT;
        this.Modus = modus;
        this.cdr.detectChanges();
        this.taskDialog.open();
    }

    getNeuEntscheidung() {
        return this.Antrag?.Status.Id !== VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ANTRAG;
    }

    enableTaskWBNichtMehrVorhanden() {
        return this.Antrag?.AntragKontrollieren === true;
    }

    doTaskAntragWBNichtMehrVorhanden() {
        this.doTask([{ AntragId: this.AntragId }], SchwaerzenTaskMode.ANTRAG_WB_NICHT_MEHR_VORHANDEN);
    }

    enableTaskInformieren() {
        return this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_FRIST_3MT;
    }

    doTaskAntragInformieren() {
        this.doTask([{ VersionId: this.VersionId }], SchwaerzenTaskMode.RECHTSTRAEGER_INFORMIEREN);
    }

    enableTaskAntragBewilligen() {
        return this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ANTRAG ||
            this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_BEARBEITET ||
            this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_RECHTMITTELFRIST_ABGELAUFEN;
    }

    doTaskAntragBewilligen() {
        this.doTask([{ AntragId: this.AntragId, AntragBegruendung: this.Antrag?.Begruendung, AntragGueltigBis: this.Antrag?.GueltigBis, AntragEntscheidung: this.Antrag?.Entscheidung }], SchwaerzenTaskMode.ANTRAG_BEWILLIGEN);
    }

    enableTaskAntragAblehnen() {
        return this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ANTRAG ||
            this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_BEARBEITET ||
            this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_RECHTMITTELFRIST_ABGELAUFEN;
    }

    doTaskAntragAblehnen() {
        this.doTask([{ AntragId: this.AntragId, AntragBegruendung: this.Antrag?.Begruendung, AntragGueltigBis: this.Antrag?.GueltigBis, AntragEntscheidung: this.Antrag?.Entscheidung }], SchwaerzenTaskMode.ANTRAG_ABLEHNEN);
    }

    enableTaskRechtsmittelfristSetzen() {
        return this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_BEARBEITET;
    }

    doTaskRechtsmittelfristSetzen() {
        this.doTask([{ AntragId: this.AntragId, AntragBegruendung: this.Antrag?.Begruendung, AntragGueltigBis: this.Antrag?.GueltigBis, AntragEntscheidung: this.Antrag?.Entscheidung }], SchwaerzenTaskMode.ANTRAG_RECHTSMITTELFRIST_SETZEN);
    }

    enableTaskRechtskraftSetzen() {
        return this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_RECHTMITTELFRIST_ABGELAUFEN;
    }

    doTaskRechtskraftSetzen() {
        this.doTask([{ AntragId: this.AntragId, AntragBegruendung: this.Antrag?.Begruendung, AntragGueltigBis: this.Antrag?.GueltigBis, AntragEntscheidung: this.Antrag?.Entscheidung }], SchwaerzenTaskMode.ANTRAG_RECHTSKRAFT_SETZEN);
    }

    enableTaskGueltigkeitSetzen() {
        return this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_BEWILLIGT ||
            this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_FRIST_3MT ||
            this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_RT_INFORMIERT;
    }

    doTaskGueltigkeitSetzen() {
        this.doTask([{ AntragId: this.AntragId, AntragBegruendung: this.Antrag?.Begruendung, AntragGueltigBis: this.Antrag?.GueltigBis, AntragEntscheidung: this.Antrag?.Entscheidung }], SchwaerzenTaskMode.ANTRAG_GUELTIGKEIT_SETZEN);
    }

    // Rückgängig machen Tasks
    enableTaskRechtstraegerInformierenRueckgaengig() {
        return this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_RT_INFORMIERT;
    }

    doTaskRechtstraegerInformierenRueckgaengig() {
        this.RueckgaengigMode = SchwaerzenRueckgaengigMode.RECHTSTRAEGER_INFORMIEREN;
        this.doTask([{ AntragId: this.AntragId, AntragBegruendung: this.Antrag?.Begruendung, AntragGueltigBis: this.Antrag?.GueltigBis, AntragEntscheidung: this.Antrag?.Entscheidung }], SchwaerzenTaskMode.RUECKGAENGIG);
    }

    enableTaskAntragRechtskraftSetzenRueckgaengig() {
        return this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_BEWILLIGT ||
            this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELEHNT ||
            this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_FRIST_3MT;
    }

    doTaskAntragRechtskraftSetzenRueckgaengig() {
        this.RueckgaengigMode = SchwaerzenRueckgaengigMode.ANTRAG_RECHTSKRAFT;
        this.doTask([{ AntragId: this.AntragId, AntragBegruendung: this.Antrag?.Begruendung, AntragGueltigBis: this.Antrag?.GueltigBis, AntragEntscheidung: this.Antrag?.Entscheidung }], SchwaerzenTaskMode.RUECKGAENGIG);
    }

    OnSave() {
        this.CloseDialog();
        this.OnChanged.emit(null);
        this.LoadData();
    }

    CloseDialog() {
        this.selectedRT = [];
        this.taskDialog.close();
    }

    //
    enableTaskKontrollieren() {
        return this.Antrag?.AntragKontrollieren === true;
    }

    doTaskAntragKontrollieren() {
        this.checkAntrag();
    }

    private async loadVersion(id: string): Promise<VwbPVersion> {
        const res = await this.apiService.getModel(VwbPVersion, `v1/intern/vwbp/rechtstraeger/version/${id}`);

        res.alleFormularDaten.forEach(w => {
            w['FormularTypBezeichnung'] = w.FormularTyp.Bezeichnung;
            w['WBPartnerTypBezeichnung'] = w.WBPartnerTyp.Bezeichnung;
            w['WBPartnerTypId'] = w.WBPartnerTyp.Id;
        });

        return res;
    }

    public async checkAntrag() {
        const version = await this.loadVersion(this.VersionId);
        this.FormularDaten = version.alleFormularDaten;
        this.cdr.detectChanges();
        await this.AntragForm.InitForm(AntragEditMode.CHECK, this.AntragId);
        this.AntragFormDialog.open();
    }

    public getGueltigSetzenTaskId(): string {
        var taskId: string;
        
        if (this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_BEWILLIGT)
            taskId = VwbPListDetailConst.VWBP_VORLAGE_TASK_SCHWAERZEN_GUELTIGKEIT_BEWILLIGT;
        else if (this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_FRIST_3MT)
            taskId = VwbPListDetailConst.VWBP_VORLAGE_TASK_SCHWAERZEN_GUELTIGKEIT_FRISTABLAUF_3M;
        else if (this.Antrag?.Status.Id === VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_RT_INFORMIERT)
            taskId = VwbPListDetailConst.VWBP_VORLAGE_TASK_SCHWAERZEN_GUELTIGKEIT_RT_INFORMIERT;

        return taskId
    }

    onAntragSavedSuccessful(event) {
        this.AntragFormDialog.close();
        this.AntragForm.resetForm();
        this.OnSave();
    }

    private closeForm() {
        this.AntragFormDialog.close();
        this.AntragForm?.resetForm();
    }
}
