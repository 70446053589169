import { Expose, Type } from 'class-transformer';
import { BaseModelState } from './basemodel.model';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

export class ZustandInfoDto extends BaseModelState {

    @Expose()
    @TransformDate()
    public get ZustandAb(): Date { return this.getProperty('ZustandAb'); }
    public set ZustandAb(value: Date) { this.setProperty('ZustandAb', value); }

    @Expose()
    @TransformDate()
    public get ZustandBis(): Date { return this.getProperty('ZustandBis'); }
    public set ZustandBis(value: Date) { this.setProperty('ZustandBis', value); }
}
