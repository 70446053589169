import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SysParameterDto } from '@avi-x/avi-dto/system/sysparameterdto.model';
import { GenericRef } from '@avi-x/avi-dto/shared';
import { AviAbstractModelFormComponent } from '../../base-form/abstract-model-form.component';
import { AviCommonService } from '../../../services/common.service';
import { AviApiService } from '../../../services/api.service';
import { AviFormFieldService } from '../../../services/form-field.service';
import { typed_nameof } from '../../utils/nameof';
import { AviListDetailConst } from '../../../shared/constants/constants';
import { AviApiErrorObject } from '../../../dto/aviapierrorobject';
import { AviSessionControllerService } from '../../../services/session-controller.service';

const nameof = (nameFunction: ((obj: SysParameterDto) => any)) => typed_nameof<SysParameterDto>(nameFunction);

@Component({
    selector: 'avi-sysparameter-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviSysParameterFormComponent extends AviAbstractModelFormComponent<SysParameterDto> {  
    ClassName = 'SysParameter';
    BaseUrl = 'sysparameter';
    ClassType = SysParameterDto;
    FormType = AviSysParameterFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'SysParameter';
    }

    public async afterInitForm() {        
        if (this.Model?.RefType) {
            const ctx1 = this.Fields.find(c => c.Name === nameof(x => x.RefValue));
        
            let res = this.Model.RefType;
            let idx = res.indexOf(',');
            if (idx != -1)
              res = res.substring(0, idx);
            
            ctx1.DropdownPlaceholder = `Kontext wählen...`;
            ctx1.DropdownValueField = (elem) => new GenericRef(elem.Id, res);
            ctx1.ValueTransformer = (id) => new GenericRef(id, res);
            ctx1.DropdownDatasource = `model/representations/${res}`;
        }

        const attrType = this.Model.ParamType.Id;
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DefaultIntParamValue), attrType === AviListDetailConst.ATTRTYPE_INTEGER);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.IntValue), attrType === AviListDetailConst.ATTRTYPE_INTEGER);
        
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DefaultDecParamValue), attrType === AviListDetailConst.ATTRTYPE_DECIMAL);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DecValue), attrType === AviListDetailConst.ATTRTYPE_DECIMAL);
        
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DefaultTextParamValue), attrType === AviListDetailConst.ATTRTYPE_TEXT);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.TextValue), attrType === AviListDetailConst.ATTRTYPE_TEXT);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DefaultDateParamValue), attrType === AviListDetailConst.ATTRTYPE_DATE);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DateValue), attrType === AviListDetailConst.ATTRTYPE_DATE);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DefaultListDetailParamValue_ID), attrType === AviListDetailConst.ATTRTYPE_LISTTYPE);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ListDetailValue_ID), attrType === AviListDetailConst.ATTRTYPE_LISTTYPE);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DefaultRefParamValue), attrType === AviListDetailConst.ATTRTYPE_REFERENCE);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RefValue), attrType === AviListDetailConst.ATTRTYPE_REFERENCE);
    }

    saveModel(model: SysParameterDto) {

        this.Form.clearFormMessages();

        let delegate: Promise<any> = this.apiService.put(`/${this.getBaseUrl()}/${model.Id}`, model);

        delegate?.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);
            
            if (this.ref)
                this.ref.close(model);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez), 'KurzBez', false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung), 'Bezeichnung', false).setMDSizeHalf().setReadonly());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ParamName), 'Parameter Name', false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateEmpty('filler1').setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Beschreibung), 'Beschreibung', false).setMDSizeFull().setReadonly());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ParamType), 'Parameter Typ', 'attribute_type', true, false, true).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ListTypeName), 'List Name', false).setMDSizeHalf().setReadonly());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.minValue), 'Min Wert', 2, false).setMDSizeHalf().setReadonly());        
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.maxValue), 'Max Wert', 2, false).setMDSizeHalf().setReadonly());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Mask), 'Mask', false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RegEx), 'Regular Expression', false).setMDSizeHalf().setReadonly());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.ParamLength), 'Länge', 0, false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ValidateService), 'Validier Service', false).setMDSizeHalf().setReadonly());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DefaultIntParamValue), 'Default Int', 0, false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.IntValue), 'Int', 0, false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DefaultDecParamValue), 'Default Dezimal', 2, false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DecValue), 'Dezimal', 2, false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.DefaultTextParamValue), 'Default Text', false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.TextValue), 'Text', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.DefaultDateParamValue), 'Default Datum', false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.DateValue), 'Datum', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DefaultListDetailParamValue_ID), 'Default ListDetail', `model/representations/ListDetail?id=$id`, false, false, 'Default ListDetail wählen...', (m) => (`${m.Representation}`), 'Id', true, true).setMDSizeHalf().setReadonly());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.ListDetailValue_ID), 'ListDetail',
            async (field) => {
                const ret = [];
    
                if (!(this.Model?.ListTypeName))
                    return ret;
            
                const p = await this.apiService.get(`model/representations/list/${this.Model?.ListTypeName}`);
                p.forEach(elem => {
                    ret.push({ label: elem.Representation, value: elem.Id });
                });
            
                return ret.sort((a, b) => a.label > b.label ? 1 : -1);
            }, false, false, 'ListDetail wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
       
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DefaultRefParamValue), 'Default Referenz',
        async (field) => {
            const ret = [];
        
            if (!this.Model?.DefaultRefParamValue?.ModelID)
                return ret;
        
            const elem = await this.apiService.get(`model/representation/${this.Model.DefaultRefParamValue.ModelID}/${this.Model.DefaultRefParamValue.ModelType}`);
            if (elem)
                ret.push({ label: elem, value: this.Model.DefaultRefParamValue });
        
            return ret.sort((a, b) => a.label > b.label ? 1 : -1);
        }, false, false, 'ListDetail wählen...', (m) => (`${m.Representation}`), 'Id', true, true).setReadonly().setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.RefValue), 'Referenz', false, 'Representation', 'Id', 1, null).setMDSizeFull());
    }
}
