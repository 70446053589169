export class VwbPRechtstraegerAnfordernDto {
    public Name: string;
    public RegisterNummer: string;
}


export class VwbPRechtstraegerLoeschenDto {
    public Id: string;
    public Name:string;
    public Loeschdatum: Date;
}
