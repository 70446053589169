
<p-calendar
    appendTo="body"
    [inputId]="calId"
    [baseZIndex] = "baseZIndex"
    [required] = "required"
    [style] = "style"
    [disabled] = "disabled"
    [placeholder] = "placeholder"
    [tabindex] = "tabindex"
    [dateFormat] = "dateFormat"
    [dataType] = "dataType"
    [view]="dateView"
    [selectionMode] = "selectionMode"
    [defaultDate] = "defaultDate"
    [showIcon] = "showIcon"
    [readonlyInput] = "readonlyInput"
    [showOnFocus] = "showOnFocus"
    [showTime] = "showTime"
    [firstDayOfWeek] = "firstDayOfWeek"
    [name] = "name"
    [minDate] = "minDate"
    [maxDate] = "maxDate"
    [timeOnly] = "timeOnly"
    [inputStyle] = "inputStyle"
    [selectOtherMonths] = "selectOtherMonths"
    [monthNavigator]="monthNavigator"
    [showButtonBar]="showButtonBar"
    [(ngModel)]="value"
    (onFocus)="onFocus.emit($event)"
    (onInput)="onInput.emit($event)"
    (onSelect)="onSelect.emit($event)"
    (onBlur)="onBlur.emit($event)"
    (onClickOutside)="onClickOutside.emit($event)"
>
</p-calendar>
