import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerDokumenteSearcherComponent } from './rechtstraeger-dokumente-searcher.component';

@Component({
    template: `
        <avi-core-page title="" [card]="false" [collapsible]="false" [expanded]="true" [contents-padding]="true" [no-box-shadow]="true">

            <div class="card-contents">{{ message }}</div>

            <vwbp-intern-rechtstraeger-dokumente-searcher #searcher
                [RechtstraegerId]="rechtstraegerId"
            ></vwbp-intern-rechtstraeger-dokumente-searcher>

            <div class="grid m-0 justify-content-end">
                    <button
                        pButton
                        type="button"
                        icon="pi pi-check"
                        [label]="acceptLabel"
                        [disabled]="GetSelectedRows().length === 0"
                        (click)="Close()"
                        class="w-100p-m m ml-2"
                    ></button>
                    <button
                        pButton
                        type="button"
                        icon="pi pi-times"
                        [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
                        [title]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
                        (click)="Abbrechen()"
                        class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                    ></button>
            </div>

        </avi-core-page>
    `
})
export class VwbPInternRechtstraegerGesamtAktDialogComponent implements OnInit {
    @ViewChild('searcher', { static: false })
    public searcher: VwbPInternRechtstraegerDokumenteSearcherComponent;

    rechtstraegerId: string;
    message: string;
    acceptLabel: string = 'OK';

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, public cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.rechtstraegerId = this.config.data.rechtstraegerId;
        this.message = this.config.data.message;
        this.acceptLabel = this.config.data.acceptLabel;
    }

    GetSelectedRows() {
        return this.searcher?.searcher.SelectedRows ?? [];
    }

    Close() {
        this.ref.close(this.GetSelectedRows());
    }

    Abbrechen() {
        this.ref.close(null);
    }
}
