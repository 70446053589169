<div class="card-contents" *ngIf="VersionId && (NumUnstimmigkeiten > 0 || !Readonly) && KannUnstimmigkeitenHaben">
    <p-panel
        header=""
        [toggleable]="true"
        [collapsed]="NumUnstimmigkeiten === 0"
        expandIcon="pi pi-chevron-down"
        collapseIcon="pi pi-chevron-up"
        toggler="header"
    >
        <p-header>
            <div class="p-panel-title">
                Unstimmigkeiten

                <p-badge
                    *ngIf="NumOpenUnstimmigkeiten > 0"
                    styleClass="unstimmigkeit-open valign-center"
                    [value]="NumOpenUnstimmigkeiten?.toString()"
                    [pTooltip]="'VWBP.INTERN.UNSTIMMIGKEITEN.HINWEIS_OFFENE_MELDUNG' | translate">
                </p-badge>

                <p-badge
                    *ngIf="NumClosedUnstimmigkeiten > 0"
                    styleClass="unstimmigkeit-closed valign-center"
                    [value]="NumClosedUnstimmigkeiten?.toString()"
                    [pTooltip]="'VWBP.INTERN.UNSTIMMIGKEITEN.HINWEIS_ABGESCHLOSSENE_MELDUNG' | translate">
                </p-badge>
            </div>

        </p-header>

        <div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <div *ngIf="CanAdd()" class="ui-g">
                        <div class="ui-g-12 ui-md-12 pl-0 pt-0 pb-0">
                            <div *ngIf="NewUnstimmigkeitId">
                                <div class="new-unstimmigkeit">Neue Unstimmigkeitsmeldung</div>

                                <vwbp-intern-unstimmigkeit-view
                                    [unstimmigkeit-id]="NewUnstimmigkeitId"
                                    [version-id]="VersionId"
                                    (onCancelled)="OnCreateUnstimmigkeitCancelled()"
                                    (onSaved)="OnCreateUnstimmigkeitSaved()"
                                >
                                </vwbp-intern-unstimmigkeit-view>
                            </div>

                            <div>
                                <button
                                    *ngIf="!NewUnstimmigkeitId && !EditUnstimmigkeitId"
                                    pButton
                                    icon="pi pi-exclamation-circle"
                                    type="button"
                                    style="width: auto;"
                                    class="p-button-primary mr-2"
                                    aviThrottledClick
                                    (throttledClick)="CreateUnstimmigkeit()"
                                    label="Unstimmigkeitsmeldung erfassen"
                                    title="Unstimmigkeitsmeldung erfassen"
                                ></button>
                            </div>
                        </div>
                    </div>

                    <!-- <div *ngIf="CanAdd()" class="separator-nomargin"></div> -->

                    <vwbp-intern-unstimmigkeit-view
                        #unstimmigkeitView
                        *ngIf="Unstimmigkeit?.Id"
                        [show-buttons]="!EditUnstimmigkeitId"
                        [unstimmigkeit-id]="Unstimmigkeit?.Id"
                        [readonly]="Readonly"
                        [version-id]="VersionId"
                        (onDelete)="OnDeleteUnstimmigkeit()"
                        (onEdit)="OnEditUnstimmigkeit()"
                        (onCancelled)="OnEditUnstimmigkeitCancelled()"
                        (onSaved)="OnEditUnstimmigkeitSaved()"
                    >
                    </vwbp-intern-unstimmigkeit-view>
                </div>
            </div>

            <div *ngIf="Unstimmigkeit">
                <div class="mt-2 grid justify-content-end m-0">
                    <!-- Rückgängig machen Tasks -->
                    <button
                        *ngIf="enableTaskAbschliessenRueckgaengig()"
                        pButton
                        type="button"
                        icon="pi pi-undo"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.ABSCHLIESSEN_RUECKGAENGIG_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskAbschliessenRueckgaengig()"
                        class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                    ></button>
                    <button
                        *ngIf="enableTaskInformierenRueckgaengig()"
                        pButton
                        type="button"
                        icon="pi pi-undo"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.INFORMIEREN_RUECKGAENGIG_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskInformierenRueckgaengig()"
                        class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                    ></button>
                    <button
                        *ngIf="enableTaskMahnenRueckgaengig()"
                        pButton
                        type="button"
                        icon="pi pi-undo"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.MAHNEN_RUECKGAENGIG_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskMahnenRueckgaengig()"
                        class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                    ></button>
                    <button
                        *ngIf="enableTaskBuessenRueckgaengig()"
                        pButton
                        type="button"
                        icon="pi pi-undo"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.BUESSEN_RUECKGAENGIG_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskBuessenRueckgaengig()"
                        class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                    ></button>
                    <button
                        *ngIf="enableTaskLoeschverfahrenRueckgaengig()"
                        pButton
                        type="button"
                        icon="pi pi-undo"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.LOESCHVERFAHREN_RUECKGAENGIG_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskLoeschverfahrenRueckgaengig()"
                        class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                    ></button>
                    <button
                        *ngIf="enableTaskEinzugsauftragRueckgaengig()"
                        pButton
                        type="button"
                        icon="pi pi-credit-card"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.EINZUGSAUFTRAG_RUECKGAENGIG_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskEinzugsauftragRueckgaengig()"
                        class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                    ></button>
                    <!-- Tasks -->
                    <button
                        *ngIf="enableTaskZurueckAufInformiert()"
                        pButton
                        type="button"
                        icon="pi pi-info-circle"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.ZURUECK_AUF_INFORMIERT_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskZurueckAufInformiert()"
                        class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                    ></button>
                    <button
                        *ngIf="enableTaskAbschliessen()"
                        pButton
                        type="button"
                        icon="pi pi-times-circle"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.ABSCHLIESSEN_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskAbschliessen()"
                        class="w-100p-m m ml-2 p-button-primary"
                    ></button>
                    <button
                        *ngIf="enableTaskInformieren()"
                        pButton
                        type="button"
                        icon="pi pi-info-circle"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_INFORMIEREN_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskInformieren()"
                        class="w-100p-m m ml-2 p-button-primary"
                    ></button>
                    <button
                        *ngIf="enableTaskMahnen()"
                        pButton
                        type="button"
                        icon="pi pi-exclamation-triangle"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_MAHNEN_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskMahnen()"
                        class="w-100p-m m ml-2 p-button-primary"
                    ></button>
                    <button
                        *ngIf="enableTaskBuessen()"
                        pButton
                        type="button"
                        icon="pi pi-money-bill"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSTRAEGER_BUESSEN_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskBuessen()"
                        class="w-100p-m m ml-2 p-button-warning"
                    ></button>
                    <button
                        *ngIf="enableTaskMahnfristSetzen()"
                        pButton
                        type="button"
                        icon="pi pi-clock"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.MAHNFRIST_ANPASSEN_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskMahnfristSetzen()"
                        class="w-100p-m m ml-2 p-button-primary"
                    ></button>
                    <button
                        *ngIf="enableTaskLoeschverfahren()"
                        pButton
                        type="button"
                        icon="pi pi-trash"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.AMTLICHES_LIQUIDATIONSVERFAHREN_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskLoeschverfahren()"
                        class="w-100p-m m ml-2 p-button-danger"
                    ></button>
                    <button
                        *ngIf="enableTaskEinzugsauftrag()"
                        pButton
                        type="button"
                        icon="pi pi-credit-card"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.EINZUGSAUFTRAG_AN_LK_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskEinzugsauftrag()"
                        class="w-100p-m m ml-2 p-button-warning"
                    ></button>
                    <button
                        *ngIf="enableTaskRechtsmittelfristSetzen()"
                        pButton
                        type="button"
                        icon="pi pi-clock"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.RECHTSMITTELFRIST_ANPASSEN_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskRechtsmittelfristSetzen()"
                        class="p-button-primary w-100p-m m ml-2"
                    ></button>
                    <button
                        *ngIf="enableTaskBussfristSetzen()"
                        pButton
                        type="button"
                        icon="pi pi-clock"
                        [label]="'VWBP.INTERN.UNSTIMMIGKEITEN.BUSSFRIST_ANPASSEN_BUTTON' | translate"
                        aviThrottledClick
                        (throttledClick)="doTaskBussfristSetzen()"
                        class="p-button-primary w-100p-m m ml-2"
                    ></button>
                </div>
            </div>
        </div>
    </p-panel>
</div>

<avi-core-dialog #taskDialog identifier="taskDialog" [showCloseIcon]="false" [closeOnEscape]="false">
    <vwbp-intern-unstimmigkeit-rueckgaengig-task
        *ngIf="Modus === UnstimmigkeitTaskMode.RUECKGAENGIG"
        [modus]="RueckgaengigMode"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-rueckgaengig-task>
    <vwbp-intern-unstimmigkeit-abschliessen-task
        *ngIf="Modus === UnstimmigkeitTaskMode.ABSCHLIESSEN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-abschliessen-task>
    <vwbp-intern-unstimmigkeit-informieren-task
        *ngIf="Modus === UnstimmigkeitTaskMode.INFORMIEREN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-informieren-task>
    <vwbp-intern-unstimmigkeit-mahnen-task
        *ngIf="Modus === UnstimmigkeitTaskMode.MAHNEN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-mahnen-task>
    <vwbp-intern-unstimmigkeit-mahnfrist-setzen-task
        *ngIf="Modus === UnstimmigkeitTaskMode.MAHNFRIST"
        [selected-rechtstraeger]="selectedRT"
        [datum]="Mahnfrist"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-mahnfrist-setzen-task>
    <vwbp-intern-unstimmigkeit-bussen-task
        *ngIf="Modus === UnstimmigkeitTaskMode.BUESSEN && !ErneutBuessen"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-bussen-task>
    <vwbp-intern-unstimmigkeit-erneut-bussen-task
        *ngIf="Modus === UnstimmigkeitTaskMode.BUESSEN && ErneutBuessen"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-erneut-bussen-task>
    <vwbp-intern-unstimmigkeit-amtliche-aufloesung-task
        *ngIf="Modus === UnstimmigkeitTaskMode.LOESCHVERFAHREN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-amtliche-aufloesung-task>
    <vwbp-intern-unstimmigkeit-rechtsmittelfrist-setzen-task
        *ngIf="Modus === UnstimmigkeitTaskMode.RECHTSMITTELFRIST"
        [selected-rechtstraeger]="selectedRT"
        [datum]="Rechtsmittelfrist"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-rechtsmittelfrist-setzen-task>
    <vwbp-intern-unstimmigkeit-einzugsauftrag-task
        *ngIf="Modus === UnstimmigkeitTaskMode.EINZUGSAUFTRAG"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-einzugsauftrag-task>
    <vwbp-intern-unstimmigkeit-bussfrist-setzen-task
        *ngIf="Modus === UnstimmigkeitTaskMode.BUSSFRIST"
        [selected-rechtstraeger]="selectedRT"
        [datum]="Bussfrist"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-bussfrist-setzen-task>
    <vwbp-intern-unstimmigkeit-loeschen-task
        *ngIf="Modus === UnstimmigkeitTaskMode.LOESCHEN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-loeschen-task>
    <vwbp-intern-unstimmigkeit-zurueck-auf-informiert-task
        *ngIf="Modus === UnstimmigkeitTaskMode.ZURUECK_AUF_INFORMIERT"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-unstimmigkeit-zurueck-auf-informiert-task>
</avi-core-dialog>
