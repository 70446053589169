import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviAppUserGroupLinkFormComponent } from './appusergrouplink-form.component';
import { AppUserGroupLinkSearchResponseDto } from '@avi-x/avi-dto/system/appusergrouplinksearchresponsedto.model';
import { AviAppUserGroupLogEntryFormComponent } from './appusergrouplogentry-form.component';
import { TranslateService } from '@ngx-translate/core';

const nameof = (nameFunction: ((obj: AppUserGroupLinkSearchResponseDto) => any)) => typed_nameof<AppUserGroupLinkSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-appusergrouplink-searcher',
    templateUrl: './appusergrouplink-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: AviAbstractModelSearcherComponent, useExisting: AviAppUserGroupLinkSearcherComponent }]
})
export class AviAppUserGroupLinkSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.APPUSERGROUPLINK';
    SettingsKey = 'settings.searcher.appusergrouplink';
    BaseUrl = 'appusergroup/link';
	FormType = AviAppUserGroupLinkFormComponent;

    CanEdit: boolean = false;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        private translateService: TranslateService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('AppUserGroup schreiben').then(w => this.CanEdit = w);
    }

    onDoubleClickSearcher(data) {
/*         if (this.SelectedRows) {
            // this.sessionController.openSession('appusergrouplink-index', this.SelectedRows.Id);
            this.editObject(this.SelectedRows);
        } */
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();

        searcher.addTextColumn(nameof(c => c.Kurzz), 'CORE.COMMON.MODEL.APPUSERGROUPLINK.KURZZ', true);
        searcher.addTextColumn(nameof(c => c.Username), 'CORE.COMMON.MODEL.APPUSERGROUPLINK.USERNAME', true);
        searcher.addTextColumn(nameof(c => c.Vorname), 'CORE.COMMON.MODEL.APPUSERGROUPLINK.VORNAME', true);
        searcher.addTextColumn(nameof(c => c.Name), 'CORE.COMMON.MODEL.APPUSERGROUPLINK.NAME', true);
        searcher.addTextColumn(nameof(c => c.Domain), 'CORE.COMMON.MODEL.APPUSERGROUPLINK.DOMAIN', true);
        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(AppUserGroupLinkSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }

    public async deleteObject(selectedRows) {
        if (!selectedRows)
            return;

        let params = new Map<string, any>();

        params["UserGroupLinkToDelete"] = selectedRows.Id;
        params["AppUserGroupId"] = selectedRows.AppUserGroupId;
        await this.commonService.openFormDialog(AviAppUserGroupLogEntryFormComponent, this.translateService.instant("CORE.COMMON.MODEL.APPUSERGROUPLINK.APPUSERGROUPLINK_DELETE_FROM_APPUSERGROUP"), '0', this.ContextId, params, true);
        this.onSavedSuccessful();
    }
}
