import { Expose, Type } from 'class-transformer';

import { TechRole } from '../system/techrole.model';
import { AppUserGroup } from '../system/appusergroup.model';
import { BaseModel } from '../shared/basemodel.model';

export class TechRoleLink extends BaseModel {  
    @Expose()
    public get TechRoleRef_ID(): string { return this.getModelId('TechRoleRef_ID'); }
    public set TechRoleRef_ID(value: string) { this.setModelId('TechRoleRef_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleRef(): TechRole { return this.getModel('TechRoleRef'); }
    public set TechRoleRef(value: TechRole) { this.setModel('TechRoleRef', value); }
    
    @Expose()
    public get AppUserGroupRef_ID(): string { return this.getModelId('AppUserGroupRef_ID'); }
    public set AppUserGroupRef_ID(value: string) { this.setModelId('AppUserGroupRef_ID', value); }
 
    @Expose()
    @Type(() => AppUserGroup)
    public get AppUserGroupRef(): AppUserGroup { return this.getModel('AppUserGroupRef'); }
    public set AppUserGroupRef(value: AppUserGroup) { this.setModel('AppUserGroupRef', value); }
    
}
