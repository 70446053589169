import { NgxPermissionsService } from 'ngx-permissions';
import { AviTextblockService } from './textblock.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AviCommonService, AviFormField, AviFormFieldService, AviTypeUtil, InputmaskService, IDocument } from '@avi-x/avi-core';
import { TextblockDto } from '@avi-x/avi-dto/system/textblockdto.model';
import { MenuItem } from 'primeng/api';
import moment from 'moment';

@Component({
    selector: 'avi-admin-textblock',
    templateUrl: './admin-textblock.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AviAdminTextblockComponent implements OnInit {
    breadcrumbItems: MenuItem[];

    allTextblock: TextblockDto[];
    allTextblockFlt: any[];
    selectedEntry: any;
    selectedTextblock: TextblockDto;
    newTextblockIdentifier: string;

    LowDate: Date = AviTypeUtil.SqlLowDate.toDate();
    HighDate: Date = AviTypeUtil.HighDate.toDate();

    public Fields: AviFormField[] = [];

    HistoryStates: any[] = null;
    SelectedState: any = null;
    ReadOnly: boolean = true;

    constructor(
        private textblockService: AviTextblockService,
        private commonService: AviCommonService,
        public inputMaskService: InputmaskService,
        public formFieldService: AviFormFieldService,
        public permissionService: NgxPermissionsService,
        private cdr: ChangeDetectorRef
    ) {
        this.permissionService.hasPermission(['CAFTextBlock erfassen', 'CAFTextBlock schreiben']).then(w => this.ReadOnly = !w);
    }

    ngOnInit() {
        this.initFields();
        this.loadAllTextblocks();
    }

    public onInputDate(event, calendar: any): void {
        this.inputMaskService.handleDateInput(event, calendar);
    }

    async createTextblock(identifier: string) {
        const tbexists = await this.textblockService.HasTextblock(identifier);
        if (tbexists) return;

        const tb = new TextblockDto();
        tb.Identifier = identifier;
        tb.GueltigAb = this.LowDate;
        tb.GueltigBis = this.HighDate;

        await this.textblockService.SaveTextblock(tb);        
        await this.loadAllTextblocks();
        this.selectedEntry = identifier;
        this.selectTextBlock(this.selectedEntry);
        this.newTextblockIdentifier = '';
        this.cdr.markForCheck();
    }

    async loadAllTextblocks() {
        const r = await this.textblockService.GetAllTextblock();
        this.allTextblock = r.sort(this.commonService.dynamicSort('Identifier'));
        this.allTextblockFlt = [...new Map(this.allTextblock.map(item => [item['Identifier'], item])).values()].map(w => { return { label: w.Identifier, value: w.Identifier } });
        this.cdr.markForCheck();
    }

    selectTextBlock(tb) {
        if (tb) {
            const stichtag = new Date();
            this.HistoryStates = this.allTextblock.filter(w => w.Identifier === tb)
                .sort((a, b) => a.GueltigAb.getTime() - b.GueltigAb.getTime())
                .map(w => { return { label: moment(w.GueltigAb).format('DD.MM.YYYY') + ' - ' + moment(w.GueltigBis).format('DD.MM.YYYY'), value: w } });
    
            this.SelectedState = this.HistoryStates.find(w => w.value.GueltigAb <= stichtag && w.value.GueltigBis >= stichtag);
            this.selectedTextblock = this.allTextblock.find(x => x.Id === this.SelectedState.value.Id);
            this.cdr.markForCheck();
        }
    } 
    
    initFields() {
        this.Fields.push(this.formFieldService.CreateText('Identifier', 'Identifier', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateText('BezeichnungDe', 'Bezeichnung', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateDate('GueltigAb', 'Gültig ab', false).setNullDateLow().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate('GueltigBis', 'Gültig bis', false).setNullDateHigh().setMDSizeHalf());

        const field = this.formFieldService.CreateRichText('TextDe', 'Text', false).setMDSizeFull();
        field.EnableTribute = false;
        this.Fields.push(field);
    }

    saveTextblock(data: TextblockDto) {
        this.textblockService.SaveTextblock(data)
            .then(async (r) => {
                this.commonService.notificateSuccess('Gespeichert');
                this.HistoryStates = null;
                this.cdr.markForCheck();
                await this.loadAllTextblocks();
                this.selectedEntry = data.Identifier;
                this.selectTextBlock(this.selectedEntry)
            })
            .catch((err) => {
                this.commonService.notificateError(err);
            });
    }
}
