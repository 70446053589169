import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AviApiService, AviBaseSearcherComponent, AviCommonService, AviFilterSettings, AviJobService, AviSearcherButtonType, AviSearcherColumn } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';
import { RTVerMode } from '../../../rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';
import { VwbPInternUebergabeService } from '../../service/uebergabe.service';

@Component({
    selector: 'vwbp-intern-uebergabe-angefragt',
    template: `
    <avi-core-base-searcher
        #searcher
        settingskey="settings.searcher.ueb.uebernahme_angefragt"
        [search-delegate]="SearchDelegate"
        [reorderable-columns]="true"
        [selectall-delegate]="SelectAllDelegate"
        (onInit)="onSearcherInit($event)"
        [use-router-params]="true"
        [use-router-params-selection]="false"
        [checkbox-selection]="true"
        [keep-selection]="true"
        (onDoubleClick)="onDoubleClickAnfrage($event)"
        [rows]="10"
        [auto-search]="true"
        [show-autofilter]="true"
        [toolbar]="true"
        [toolbar-show-inactivebutton]="false"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [toolbar-show-columnselection]="true"
        [toolbar-buttons]="toolbarButtons"
        [lazy-loading]="true"
        [card]="false"
        [show-totalrecords]="true"
        [select-first-result]="false"
        [show-totalrecords]="true"
        [show-totalrecordsunfiltered]="true"
        [total-label]="'VWBP.COMMON.RT_INANSICHT' | translate"
        [total-unfiltered-label]="'VWBP.COMMON.TOTALRT' | translate"
        >
    </avi-core-base-searcher>
         `
})
export class VwbPInternUebergabeAngefragtComponent implements OnInit {
    toolbarButtons : any[] = [
        { title: 'Excel Export', class: 'p-button-secondary', icon: 'pi pi-download', action: () => this.OnExportClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0 },
        { title: 'Serienbrief', class: 'p-button-secondary', icon: 'pi pi-envelope', action: () => this.OnSerienbriefClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0 },
    ];

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    constructor(
        public apiService: AviApiService,
        private router: Router,
        private commonService: AviCommonService,
        private uebergabeService: VwbPInternUebergabeService,
        private vwbpCommonService: VwbPCommonService,
        private postfachService: VwbPInternPostfachService,
        private rechtstraegerService: VwbPInternRechtstraegerService,
        private jobService: AviJobService
    ) { }

    ngOnInit() {
        // this.uebergabeService.GetAnfragenList().then(r => {
        //     console.log("Anfragen", r);
        // });
    }

    async OnExportClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.RechtstraegerId);
        this.rechtstraegerService.OpenExcelExportJobDialog(rechtstraegerIds);
    }

    async OnSerienbriefClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.RechtstraegerId);
        this.rechtstraegerService.OpenSerienbriefJobDialog(rechtstraegerIds, RTVerMode.RECHTSTRAEGER);
    }

    GetSelectedRechtstraeger(): any[] {
        return this.searcher.SelectedRows;
    }

    onSearcherInit(data) {
        this.searcher.beginInit();
        this.searcher.addTextColumn('RechtstraegerName', 'VWBP.COMMON.RT').setFilterable(true).setVisible(true);
        this.searcher.addTextColumn('RechtstraegerRegisternummer', 'VWBP.COMMON.REGISTERNR').setFilterable(true).setVisible(true);
        this.searcher.addListTypeColumn('Anfragestatus', 'VWBP.COMMON.ANFRAGESTATUS', 'vwbp_rt_anfragestatus', true, true, null, 'v1/vwbp', [VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_ABGELEHNT, VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_BEWILLIGT]);
        this.searcher.addTextColumn('OldOwnerMaster', 'VWBP.INTERN.UEBERGABE_MANAGEMENT.AKTUELLER_BENUTZER').setFilterable(true).setVisible(true);
        this.searcher.addTextColumn('RequestOwnerMaster', 'VWBP.INTERN.UEBERGABE_MANAGEMENT.ANFRAGENDER_BENUTZER').setFilterable(true).setVisible(true);
        this.AddColumnActions();
        this.searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        return this.uebergabeService.GetAnfragenList(searchConfig);
    }

    public SelectAllDelegate: any = async (searchValue: string, searchConfig: any = null) => {
        return await this.uebergabeService.GetAnfragenIdList(searchConfig);
    }


    public AddColumnActions() {
        this.searcher.addColumn(AviSearcherColumn.CreateButton('btnDokumenteErhalten', ' ', null, null, null, null, '160px',
                ['btnDokumenteErhalten', 'btnVisumBewilligen', 'btnVisumAblehnen', 'b1', 'b2'],
                [AviSearcherButtonType.BUTTON, AviSearcherButtonType.BUTTON, AviSearcherButtonType.BUTTON, AviSearcherButtonType.BADGE, AviSearcherButtonType.BUTTON])
            .setButtonOnClickDelegate((row, col, buttonId) => {
                switch (buttonId) {
                    case 'btnDokumenteErhalten': this.SetDokumenteErhalten(row.Id); break;
                    case 'btnVisumBewilligen': this.AnfrageBewilligen(row.Id); break;
                    case 'btnVisumAblehnen': this.AnfrageAblehnen(row.Id); break;
                    case 'b2': this.onDoubleClickAnfrage(row); break;
                }
            })
            .setButtonValueDelegate((row, col, buttonId) => {
                if (buttonId === 'b1')
                    return row['BemerkungCount'];

                return null;
            })
            .setButtonClassesDelegate((row, col, buttonId) => {
                if (buttonId === 'b1') {
                    return row['BemerkungCount'] ? 'comment' : 'comment-empty';
                } else {
                    let hidden = false;
                    let buttonColorGreen = false;
                    switch (buttonId) {
                        case 'btnDokumenteErhalten': hidden = row.Anfragestatus.Id !== VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_WARTE_AUF_DOKUMENTE; break;
                        case 'btnVisumBewilligen':
                            hidden = row.Anfragestatus.Id !== VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG;
                            buttonColorGreen = (row.VisaBewilligtCurrentUserCount && row.VisaBewilligtCurrentUserCount > 0);
                            break;
                        case 'btnVisumAblehnen':
                            hidden = row.Anfragestatus.Id !== VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG || (row.VisaBewilligtCurrentUserCount && row.VisaBewilligtCurrentUserCount > 0);
                            break;
                    }

                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-green-icon': buttonColorGreen,
                        'p-button-outlined': false,
                        'p-button-rounded': true,
                        'p-button-text': true,

                        'hidden': hidden
                    };
                }
            })
            .setButtonIconDelegate((row, col, buttonId) => {
                switch (buttonId) {
                    case 'btnDokumenteErhalten': return 'pi pi-paperclip';
                    case 'btnVisumBewilligen': return 'pi pi-check-circle';
                    case 'btnVisumAblehnen': return 'pi pi-times-circle';
                    case 'b2': return 'pi pi-eye';
                }
            })
            .setTooltipDelegate((row, col, buttonId) => {
                switch (buttonId) {
                    case 'btnDokumenteErhalten': return 'VWBP.INTERN.UEBERGABE_MANAGEMENT.DOKUMENTE_ERHALTEN';
                    // case 'btnVisumBewilligen': return `Anfrage bewilligen (${row.VisaBewilligtCount || 'keine'} Bewilligungen erhalten)`;
                    // case 'btnVisumAblehnen': return `Anfrage ablehnen (${row.VisaAbgelehntCount || 'keine'} Ablehnungen erhalten)`;
                    case 'btnVisumBewilligen': return `VWBP.INTERN.UEBERGABE_MANAGEMENT.ANFRAGE_BEWILLIGEN`; // TODO(msc): Translate-Parameter für Anzahl
                    case 'btnVisumAblehnen': return `VWBP.INTERN.UEBERGABE_MANAGEMENT.ANFRAGE_ABLEHNEN`; // TODO(msc): Translate-Parameter für Anzahl
                    case 'b1': return row['BemerkungCount'] + ' offene Bemerkungen';
                    case 'b2': return 'Rechtsträger öffnen';
                }
            })
            .setDisabledDelegate((row, col, buttonId) => {
                switch (buttonId) {
                    case 'btnDokumenteErhalten': return row.Anfragestatus.Id !== VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_WARTE_AUF_DOKUMENTE;
                    case 'btnVisumBewilligen': return row.Anfragestatus.Id !== VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG || (row.VisaBewilligtCurrentUserCount && row.VisaBewilligtCurrentUserCount > 0);
                    case 'btnVisumAblehnen': return row.Anfragestatus.Id !== VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG || (row.VisaAbgelehntCurrentUserCount && row.VisaAbgelehntCurrentUserCount > 0);
                }
                return false;
            })
            .setCellStyleDelegate(() => {
                return { 'disabled': true };
            })
            .setSortable(false)
        );
    }

    GetFilters(): AviFilterSettings[] {
        return [
            { Field: 'IsLastVersion', Filter: '1' },
            { Field: 'UebergabestatusId', Filter: VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_ANGEFRAGT },
        ];
    }

    onDoubleClickAnfrage(data) {
        this.router.navigate(['/intern/vwbp/index/rt', data.CurrentVersionId], { queryParams: { openMode: 'uebergabe-anfrage', anfrageId: data.Id } });
    }

    SetDokumenteErhalten(anfrageId: string) {
        const act = () => {
            this.uebergabeService.SetAnfrageStatus(anfrageId, VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG)
                .then(r => {
                    this.searcher.forceRefresh();
                }).catch(err =>
                    this.commonService.notificateError(err));
        };
        this.commonService.confirm({ message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.DOKUMENTE_WIRKLICH_ERHALTEN'), acceptButtonStyleClass: 'p-button-primary', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: act });
    }

    AnfrageBewilligen(anfrageId: string) {
        const act = () => {
            this.uebergabeService.SetAnfrageVisumBewilligen(anfrageId)
                .then(r => {
                    this.commonService.notificateSuccess(this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.VISUM_ERSTELLT'));
                    this.searcher.forceRefresh();
                }).catch(err =>
                    this.commonService.notificateError(err));
        };
        this.commonService.confirm({ message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.WIRKLICH_BEWILLIGEN'), acceptButtonStyleClass: 'p-button-primary', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: act });
    }

    AnfrageAblehnen(anfrageId: string) {
        const act = () => {
            this.uebergabeService.SetAnfrageVisumAblehnen(anfrageId)
                .then(r => {
                    this.commonService.notificateSuccess(this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.VISUM_ERSTELLT'));
                    this.searcher.forceRefresh();
                }).catch(err =>
                    this.commonService.notificateError(err));
        };
        this.commonService.confirm({ message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.WIRKLICH_ABLEHNEN'), acceptButtonStyleClass: 'p-button-primary', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: act });
    }
}
