import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviBaseSearcherComponent, AviCommonService, typed_nameof } from '@avi-x/avi-core';
import { ScriptDefSearchResponseDto } from '@avi-x/avi-dto/workflow/scriptdefsearchresponsedto.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviScriptDefFormComponent } from './scriptdef-form.component';

const nameof = (nameFunction: ((obj: ScriptDefSearchResponseDto) => any)) => typed_nameof<ScriptDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-scriptdef-searcher',
    templateUrl: './scriptdef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFSCRIPTDEF';
    SettingsKey = 'settings.searcher.scriptdef';
    BaseUrl = 'workflow/scriptdef';
    FormType = AviScriptDefFormComponent;

    CanEdit: boolean = false;
    
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFStatusWorkflowDef schreiben').then(w => this.CanEdit = w);
    }

    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            // this.sessionController.openSession('scriptdef-index', this.SelectedRows.Id);
            this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFSCRIPTDEF.BEZEICHNUNG1', true);       
		searcher.endInit();
    }
    
    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        if (this.ContextId) {
            const cfg =  { ...searchConfig };
            cfg.Query = searchValue;
            cfg.ContextId = this.ContextId;
            const res = await this.apiService.postPagedResult(ScriptDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
            return res;
        }

        return null;
    }
}
