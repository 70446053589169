<avi-core-base-searcher
    #searcher
    [use-router-params]="false"
    field-name-id="ArchivDocumentID"
    [card]="false"
    [contents-padding]="false"
    [search-delegate]="SearchDelegate"
    [rows]="0"
    [toolbar]="false"
    [auto-search]="true"
    [show-autofilter]="false"
    [select-first-result]="false"
    [checkbox-selection]="false"
    [metakeyselection]="false"
    searcher-title=""
    (onInit)="onSearcherInit($event)"
    (onDoubleClick)="onDoubleClickDocument($event)"
>
</avi-core-base-searcher>
