import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviListDetailConst, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EmailDef } from '@avi-x/avi-dto/workflow/emaildef.model';

const nameof = (nameFunction: ((obj: EmailDef) => any)) => typed_nameof<EmailDef>(nameFunction);

@Component({
    selector: 'avi-emaildef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviEmailDefFormComponent extends AviAbstractModelFormComponent<EmailDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFEMAILDEF';
    BaseUrl = 'workflow/emaildef';
    ClassType = EmailDef;
	FormType = AviEmailDefFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFEmailDef';
    }

    public async afterInitForm() {
        this.setFromTyp();
    }

    setFromTyp() {
        this.formFieldService.setFieldVisible(this.Fields, 'styles', this.Model.Typ.Id === AviListDetailConst.CAF_EMAILDEF_TYP_HTML_TEMPLATE);

        const isRichtext = this.Model.Typ.Id === AviListDetailConst.CAF_EMAILDEF_TYP_HTML;
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EmailText1), !isRichtext);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EmailText2), !isRichtext);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EmailText3), !isRichtext);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EmailText4), !isRichtext);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EmailText1RTF), isRichtext);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EmailText2RTF), isRichtext);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EmailText3RTF), isRichtext);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EmailText4RTF), isRichtext);

        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.EmailText1), !isRichtext);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.EmailText1RTF), isRichtext);
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Typ))
            this.setFromTyp();
    }

    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateStep('Email Definition', 's1'));
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Typ), 'CORE.COMMON.MODEL.CAFEMAILDEF.TYP', 'caf_emaildef_typ', true, true, this.ReadOnly).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFEMAILDEF.BEZEICHNUNG', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.EmailSubject1), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILSUBJECT1', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.EmailText1), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT1').setCustomStyleDelegate(() => { return { 'max-height': '50vh' } }).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateRichText(nameof(c => c.EmailText1RTF), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT1').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateStep('Andere Sprachen', 'languages'));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.EmailSubject2), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILSUBJECT2', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.EmailText2), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT2', false).setCustomStyleDelegate(() => { return { 'max-height': '12vh' } }).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateRichText(nameof(c => c.EmailText2RTF), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT2').setMDSizeFull());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.EmailSubject3), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILSUBJECT3', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.EmailText3), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT3', false).setCustomStyleDelegate(() => { return { 'max-height': '12vh' } }).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateRichText(nameof(c => c.EmailText3RTF), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT3').setMDSizeFull());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.EmailSubject4), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILSUBJECT4', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.EmailText4), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT4', false).setCustomStyleDelegate(() => { return { 'max-height': '12vh' } }).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateRichText(nameof(c => c.EmailText4RTF), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT4').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateStep('Styles', 'styles'));
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Styles), 'CORE.COMMON.MODEL.CAFEMAILDEF.STYLES', false).setCustomStyleDelegate(() => { return { 'max-height': '50vh' } }).setMDSizeFull());

        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.EmailSubject5), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILSUBJECT5', false).setMDSizeFull());
        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.EmailText5), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT5', false).setMDSizeFull());
        
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.EmailSubject6), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILSUBJECT6', false).setMDSizeFull());
        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.EmailText6), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT6', false).setMDSizeFull());
        
        // this.Fields.push(this.formFieldService.CreateText(nameof(c => c.EmailSubject7), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILSUBJECT7', false).setMDSizeFull());
        // this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.EmailText7), 'CORE.COMMON.MODEL.CAFEMAILDEF.EMAILTEXT7', false).setMDSizeFull());
        
    }
}
