import { VwbPPublicXMLUploadFormComponent } from './../../../xmlupload-form/components/xmlupload-form/xmlupload-form.component';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AviCommonService, AviBaseSearcherComponent, AviApiService, AviCommunicationService, AviDialogComponent, AviSearcherColumn, AviJobService } from '@avi-x/avi-core';
import { VwbPPublicRechtstraegerImportierenFormComponent } from '../rechtstraeger-importieren-form/rechtstraeger-importieren-form.component';

import { VwbPPublicRechtstraegerAnfordernComponent } from '../rechtstraeger-anfordern/rechtstraeger-anfordern.component';
import { VwbPPublicRechtstraegerDetailFormComponent } from '../rechtstraegerdetail-form/rechtstraegerdetail-form.component';
import { VwbPUebergabeService } from '../../../uebergabe/service/uebergabe.service';
import { VwbPRechtstraegerDetail } from '../../../dto/vwbprechtstraegerdetail.model';
import { VwbPRechtstraeger } from '../../../dto/vwbprechtstraeger.model';
import { TranslateService } from '@ngx-translate/core';
import { VwbPListDetailConst } from '../../../constants/constants';
import { VwbPPublicRechtstraegerService } from '../../services/rechtstraeger.service';

@Component({
    selector: 'vwbp-public-rechtstraeger-searcher',
    templateUrl: './rechtstraeger-searcher.component.html',
    styleUrls: ['./rechtstraeger-searcher.component.scss']
})
export class VwbPPublicRechtstraegerSearcherComponent implements OnInit {
    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    @ViewChild('abgegebeneRtSearcher', { static: true })
    private abgegebeneRtSearcher: AviBaseSearcherComponent;

    @ViewChild('geloeschteRtSearcher', { static: true })
    private geloeschteRtSearcher: AviBaseSearcherComponent;

    @ViewChild('ewrRegisterRtSearcher', { static: true })
    private ewrRegisterRtSearcher: AviBaseSearcherComponent;

    @ViewChild('angeforderteRtSearcher', { static: true })
    private angeforderteRtSearcher: AviBaseSearcherComponent;

    @ViewChild('rechtstraegerForm', { static: false })
    rechtstraegerForm: VwbPPublicRechtstraegerDetailFormComponent;

    @ViewChild('rechtstraegerLIForm', { static: false })
    rechtstraegerLIForm: VwbPPublicRechtstraegerImportierenFormComponent;

    @ViewChild('rechtstraegerFormDialog', { static: false })
    rechtstraegerFormDialog: AviDialogComponent;


    @ViewChild('rechtstraegerAnfordernForm', { static: false })
    rechtstraegerAnfordernForm: VwbPPublicRechtstraegerAnfordernComponent;

    @ViewChild('rechtstraegerAnfordernDialog', { static: false })
    rechtstraegerAnfordernDialog: AviDialogComponent;


    @ViewChild('xmlUploadForm', { static: false })
    xmlUploadForm: VwbPPublicXMLUploadFormComponent;

    @ViewChild('xmlUploadDialog', { static: false })
    xmlUploadDialog: AviDialogComponent;

    FormModelId: string = null;
    private SelectedRows: any[] = [];

    formActionButtons: any[] = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];

    public showAbgegebeneRechtstraeger = false;
    public showAngeforderteRechtstraeger = false;

    CreateMode: 'RTAusland' | 'RTInland';

    constructor(
        public apiService: AviApiService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public commonService: AviCommonService,
        private communicationService: AviCommunicationService,
        private uebergabeService: VwbPUebergabeService,
        private rechtstraegerService: VwbPPublicRechtstraegerService,
        private translateService: TranslateService,
        public jobService: AviJobService,
        private cdr: ChangeDetectorRef
    ) {
    }


    ngOnInit() {
    }

    public onInit(data) {
        this.searcher.beginInit();

        // this.searcher.addColumn(SearcherColumn.CreateText("partnerType"));

        this.searcher.addTextColumn('Name', 'VWBP.COMMON.RT', true);
        this.searcher.addTextColumn('RegisterNummer', 'VWBP.COMMON.REGISTERNR', true).setWidth('150px');
        this.searcher.addDateColumn('EingangsDatum', 'VWBP.COMMON.EINTRAGUNGSDATUM', null, true).setWidth('190px'); // TA-KAMM 20210323 Z35...
        this.searcher.addListTypeColumn('Erfassungsstatus', 'VWBP.COMMON.ERFASSUNGSSTATUS', 'vwbp_erfassungsstatus', true, true, null, 'v1/vwbp',
            [VwbPListDetailConst.VWEG_ERFASSUNGSSTATUS_VERANTWORTLICHKEIT_ABGEGEGEBEN, VwbPListDetailConst.VWEG_ERFASSUNGSSTATUS_RECHTSTRAEGER_STATUS_GELOESCHT, VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN])
            .setFormatDelegate((record) => record.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN ? 'Erfassung abgeschlossen' : record.Erfassungsstatus.Bezeichnung)
            .setWidth('200px');

        // this.searcher.addIntegerColumn('Version', 'Version', true);

        this.searcher.addColumn(
            AviSearcherColumn.CreateButton('btnOpen', ' ', null, null, null, null, '60px')
                .setButtonOnClickDelegate((row, col, buttonId) => {
                    this.openRechtstraeger(row['Id']);
                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-flat': true,
                        'p-button-outlined': false,
                        'p-button-rounded': true,
                        'p-button-text': true,
                        'hidden': false // this.commonService.isEqualIgnoreCase(row.UebergabestatusId, VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_NORMAL) ? false : true
                    };
                })
                .setButtonIconDelegate((row, col, buttonId) => {
                    return 'pi pi-eye';
                })
                .setSortable(false)
        );

        this.searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => this.doSearch(searchValue, searchConfig);

    public async doSearch(searchValue: string, searchConfig: any): Promise<any> {
        this.searcher.SelectedRows = [];
        return this.apiService.post('/v1/vwbp/rechtstraeger', searchConfig);
    }

    public onDoubleClick(data) {
        /*        console.log(data.UebergabestatusId, VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_NORMAL, data);
                if (this.commonService.isEqualIgnoreCase(data.UebergabestatusId, VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_NORMAL))*/
        this.openRechtstraeger(data.Id);
    }

    openRechtstraeger(id: string) {
        this.router.navigate(['/vwbp/rt', id]);
    }

    public onStartNew() {
        // this.router.navigate(['/crm/partner/form', '0']);
    }


    keinRechtstraegerErfasst() {
        return this.searcher && this.searcher.hasEmptySearchresult();
    }

    async onSelect(data: any[]) {
        this.SelectedRows = data;
    }

    public createAuslandRechtstraeger() {
        this.CreateMode = 'RTAusland';
        this.cdr.detectChanges();
        this.rechtstraegerForm.InitForm('0');
        this.rechtstraegerFormDialog.open();
    }

    ImportHRRechtstraeger() {
        this.CreateMode = 'RTInland';
        this.cdr.detectChanges();
        this.rechtstraegerLIForm.InitForm();
        this.rechtstraegerFormDialog.open();
    }

    importXML() {
        this.xmlUploadDialog.open();
    }

    public async closeXMLUploadForm(file: any) {
        if (file) {
            const jobId: string = await this.apiService.postFile('v1/vwbp/xmlimport', file);

            this.xmlUploadForm.Init();
            this.xmlUploadDialog.close();

            if (jobId) {
                await this.jobService.OpenJobDialog(
                    jobId,
                    'VWBP.PUBLIC.XMLUPLOAD.JOB_TITLE',
                    'v1/vwbp/xmlimport',
                    false,
                    'VWBP.PUBLIC.XMLUPLOAD.SUCCESS',
                    'VWBP.PUBLIC.XMLUPLOAD.FAILED'
                );
                this.searcher.forceRefresh();
            }
        } else {
            this.xmlUploadForm.Init();
            this.xmlUploadDialog.close();
        }
    }

    async onNewRTSavedSuccessful(event) {
        this.closeForm();
        this.closeRechtstraegerAnfordernForm();
        const rtDetail = await this.apiService.getModelById(VwbPRechtstraegerDetail, 'v1/vwbp/rechtstraeger/detail', event.Id);
        const rt = await this.apiService.getModelById(VwbPRechtstraeger, 'v1/vwbp/rechtstraeger', rtDetail.Rechtstraeger_ID);
        this.openRechtstraeger(rt.CurrentVersionId);
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.closeRechtstraegerAnfordernForm();
        this.searcher.forceRefresh();

        if (this.showAngeforderteRechtstraeger)
            this.angeforderteRtSearcher.doSearch();
    }

    private closeForm() {
        this.FormModelId = null;
        this.rechtstraegerFormDialog.close();
        this.closeRechtstraegerAnfordernForm();
    }

    public createRechtstraegerAnfordern() {
        this.rechtstraegerAnfordernForm.InitForm();
        this.rechtstraegerAnfordernDialog.open();
    }

    private closeRechtstraegerAnfordernForm() {
        this.rechtstraegerAnfordernDialog.close();
    }

    public GetRowClass = (row: any): string => {
        const ret = '';
        // if (row && !this.commonService.isEqualIgnoreCase(row.UebergabestatusId, VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_NORMAL))
        //     ret += ' rt-inaktiv';
        return ret.trim();
    }


    onClickManuelleErfassung(event: Event) {
        if (event.target['tagName'].toLowerCase() === 'u') {
            this.commonService.confirm({
                header: this.translateService.instant('VWBP.PUBLIC.RT_AUSLAND_ERFASSEN'),
                message: this.translateService.instant('VWBP.PUBLIC.RT_AUSLAND_ERFASSEN_CONDITIONS_MSG'),
                rejectLabel: this.translateService.instant('CORE.COMMON.ABBRECHEN_BUTTON'),
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
                acceptLabel: this.translateService.instant('VWBP.PUBLIC.RT_AUSLAND_ERFASSEN_BUTTON'),
                acceptButtonStyleClass: 'p-button-primary',
                accept: () => {
                    this.createAuslandRechtstraeger();
                }
            });

            this.cdr.markForCheck();
        }
    }

    public ShowHideAbgebebeneRechtstraeger() {
        this.showAbgegebeneRechtstraeger = !this.showAbgegebeneRechtstraeger;
        if (this.showAbgegebeneRechtstraeger)
            this.StartSearchAbgegebeneRechtstraeger();
    }

    public StartSearchAbgegebeneRechtstraeger() {
        this.abgegebeneRtSearcher.doSearch();
        this.geloeschteRtSearcher.doSearch();
        this.ewrRegisterRtSearcher.doSearch();
    }

    public AbgegebeneRtSearchDelegate: any = async (searchValue: string) => {
        const res = await this.uebergabeService.GetAbgegebeneRechtstraeger();
        return this.abgegebeneRtSearcher.ApplyColumnSorting(res);
    }

    initAbgegebeneRechtstraegerSearcher() {
        this.abgegebeneRtSearcher.beginInit();
        this.abgegebeneRtSearcher.addTextColumn('RechtstraegerName', 'Rechtsträger');
        this.abgegebeneRtSearcher.addTextColumn('RechtstraegerRegisternummer', 'Registernummer').setWidth('150px');
        this.abgegebeneRtSearcher.addDateColumn('RechtstraegerAbgabeDatum', 'Abgabedatum', null, true).setWidth('190px');
        this.abgegebeneRtSearcher.endInit();
    }

    public GeloeschteRtSearchDelegate: any = async (searchValue: string) => {
        const res = await this.rechtstraegerService.GetGeloeschteRechtstraeger();
        return this.geloeschteRtSearcher.ApplyColumnSorting(res);
    }

    public EwrRtSearchDelegate: any = async (searchValue: string) => {
        const res = await this.rechtstraegerService.GetEwrRegisterRechtstraeger();
        return this.ewrRegisterRtSearcher.ApplyColumnSorting(res);
    }

    initGeloeschteRechtstraegerSearcher() {
        this.geloeschteRtSearcher.beginInit();
        this.geloeschteRtSearcher.addTextColumn('Name', 'Rechtsträger');
        this.geloeschteRtSearcher.addTextColumn('RegisterNummer', 'Registernummer').setWidth('150px');
        this.geloeschteRtSearcher.addDateColumn('HrLoeschungsdatum', 'VWBP.COMMON.LOESCHUNGSDATUM', null, true).setWidth('190px');
        this.geloeschteRtSearcher.endInit();
    }

    initEwrRechtstraegerSearcher() {
        this.ewrRegisterRtSearcher.beginInit();
        this.ewrRegisterRtSearcher.addTextColumn('Name', 'VWBP.COMMON.RT');
        this.ewrRegisterRtSearcher.addTextColumn('RegisterNummer', 'VWBP.COMMON.REGISTERNR').setWidth('150px');
        this.ewrRegisterRtSearcher.endInit();
    }

    keinGeloeschteRT() {
        return this.geloeschteRtSearcher && this.geloeschteRtSearcher.hasEmptySearchresult();
    }

    keinAbgegebeneRT() {
        return this.abgegebeneRtSearcher && this.abgegebeneRtSearcher.hasEmptySearchresult();
    }

    keinEwrRT() {
        return this.ewrRegisterRtSearcher && this.ewrRegisterRtSearcher.hasEmptySearchresult();
    }

    public ShowHideAngeforderteRechtstraeger() {
        this.showAngeforderteRechtstraeger = !this.showAngeforderteRechtstraeger;
        if (this.showAngeforderteRechtstraeger)
            this.angeforderteRtSearcher.doSearch();
    }

    public AngeforderteRtSearchDelegate: any = async (searchValue: string) => {
        const res = await this.rechtstraegerService.GetAngeforderteRechtstraeger();
        return this.angeforderteRtSearcher.ApplyColumnSorting(res);
    }

    initAngeforderteRechtstraegerSearcher() {
        this.angeforderteRtSearcher.beginInit();
        this.angeforderteRtSearcher.addTextColumn('Name', 'Rechtsträger');
        this.angeforderteRtSearcher.addTextColumn('RegisterNummer', 'Registernummer').setWidth('150px');
        this.angeforderteRtSearcher.addDateColumn('ErstellDatum', 'VWBP.PUBLIC.ANFORDERUNGSDATUM', null, true).setWidth('190px');
        this.angeforderteRtSearcher.endInit();
    }

    keinAngeforderteRT() {
        return this.angeforderteRtSearcher && this.angeforderteRtSearcher.hasEmptySearchresult();
    }
}
