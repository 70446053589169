import { Injectable } from "@angular/core";
import { AviApiService, AviCommonService } from "@avi-x/avi-core";
import { GenericRef } from "@avi-x/avi-dto/shared";
import { DecisionTableParam } from "@avi-x/avi-dto/system/decisiontableparam.model";
import { DialogService } from "primeng/dynamicdialog";

@Injectable()
export class AviDecisionTableService {
    constructor(public apiService: AviApiService, public commonService: AviCommonService, public dialogService: DialogService) {

    }

    async getDecisionTableParamsOfEntry(entryId: string, baseUrl: string): Promise<DecisionTableParam[]> {

        return await this.apiService.get(`${baseUrl}/getfromEntry/${entryId}`);
    }

    async getPosibleParams(entryId: string, baseUrl: string): Promise<string[]> {
        return await this.apiService.get(`${baseUrl}/getposibleparams/${entryId}`);
    }

    // async createDraft(templateId: string, context: GenericRef): Promise<DocuMatrixCreateDocumentResponse> {
    //     const draftRequest: DocuMatrixCreateDocumentRequest = new DocuMatrixCreateDocumentRequest();
    //     draftRequest.TemplateId = templateId;
    //     draftRequest.Kontext = context;
    //     return await this.apiService.postModel(DocuMatrixCreateDocumentResponse, 'documatrix/draft/create', draftRequest);
    // }

    // async editDraft(editorUrl: string) {

    //     const ref = this.dialogService.open(AviDocMatrixEditorComponent, {
    //         header: 'Dokument bearbeiten',
    //         closable: true,
    //         width: '98%',
    //         baseZIndex: 10000,
    //         styleClass: this.commonService.InputStyle === 'filled' ? 'p-input-filled max-height-dialog' : 'max-height-dialog',
    //         data: {
    //             DocuMatrixUrl: editorUrl
    //         }
    //     });

    //     const res = await this.commonService.waitForDialogClose(ref);
    //     return res != null;
    // }

    // async showDraft(draftId: number) {
    //     let blob: Blob;
    //     try {
    //         this.commonService.showGlobalLoader();
    //         blob = await this.apiService.getBlob(`documatrix/draft/${draftId}/preview`);
    //     } finally {
    //         this.commonService.hideGlobalLoader();
    //     }
    //     await this.dokumentService.openBlobPreviewDialog(blob);
    // }

    // async deleteDraft(draftId: number) {
    //     await this.apiService.delete(`documatrix/draft/${draftId}`);
    // }
    
}
