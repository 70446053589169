import { ChangeDetectorRef, Component } from '@angular/core';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternMahnwesenService } from '../../services/mahnwesen.service';
import { VwbPInternMahnwesenBaseComponent } from './mahnwesen-base.component';

@Component({
    selector: 'vwbp-intern-mahnwesen-nichterfasst',
    template: `
        <mat-tab-group class="mat-tab-tertiary" mat-align-tabs="center" animationDuration="0ms" (selectedTabChange)="setPausiert($event)" [selectedIndex]="getSelectedIndex()">
          <mat-tab label="Aktiv" ></mat-tab>
          <mat-tab label="Pausiert"></mat-tab>
        </mat-tab-group>

        <vwbp-intern-rechtstraeger-searcher #searcher settingskey="settings.searcher.mw.nicht-erfasst" [show-aktiv]="true" [use-router-params]="true" [get-filters]="GetFilters" [regfrist-abgelaufen]="true" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'btnOpen']">
        </vwbp-intern-rechtstraeger-searcher>

        <div *ngIf="currentUserIsAjuUserSchreiben()" fxLayout="row">
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-mahnwesen-mahnen-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-mahnwesen-mahnen-task>
            </div>
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-mahnwesen-nicht-relevant-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-mahnwesen-nicht-relevant-task>
            </div>
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-mahnwesen-pausieren-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-mahnwesen-pausieren-task>
            </div>
            <div *ngIf="!GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-mahnwesen-aktivieren-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-mahnwesen-aktivieren-task>
            </div>
            <div *ngIf="!GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-mahnwesen-aktivieren-nicht-relevant-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-mahnwesen-aktivieren-nicht-relevant-task>
            </div>
        </div>
        `
})
export class VwbPInternMahnwesenNichtErfasstComponent extends VwbPInternMahnwesenBaseComponent {
    constructor(public commonService: AviCommonService, public mahnwesenService: VwbPInternMahnwesenService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, mahnwesenService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters = (): string[] => {
        return [
            `HrStatus eq ${VwbPListDetailConst.VWBP_RT_HRSTATUS_NICHT_VON_BENUTZER_IMPORTIERT}`,
            `MahnwesenStatus eq ${VwbPListDetailConst.VWBP_MAHNWESENSTATUS_OFFEN}`,
            `MahnwesenAktiv eq ${this.GetAktivValue() ? VwbPListDetailConst.VWBP_MAHNWESEN_AKTIV : VwbPListDetailConst.VWBP_MAHNWESEN_PAUSIERT}`
        ];
    }
}
