import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { JobParam } from '@avi-x/avi-dto/system/jobparam.model';
import { AviAbstractModelFormComponent } from '../base-form/abstract-model-form.component';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviFormFieldService } from '../../services/form-field.service';
import { AviSessionControllerService } from '../../services/session-controller.service';
import { typed_nameof } from '../utils/nameof';

const nameof = (nameFunction: ((obj: JobParam) => any)) => typed_nameof<JobParam>(nameFunction);

@Component({
    selector: 'avi-jobparam-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJobParamFormComponent extends AviAbstractModelFormComponent<JobParam> {  
    ClassName = 'CORE.COMMON.MODEL.JOBPARAM';
    BaseUrl = 'job/param';
    ClassType = JobParam;
	FormType = AviJobParamFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'Job';
    }
	
    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.fromJob_ID), 'CORE.COMMON.MODEL.JOBPARAM.FROMJOB', `model/representations/Job${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.fromJobParamDef_ID), 'CORE.COMMON.MODEL.JOBPARAM.FROMJOBPARAMDEF', `model/representations/JobParamDef${this.ReadOnly ? '?id=$id' : ''}`, true, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.ValueInt), 'CORE.COMMON.MODEL.JOBPARAM.VALUEINT', 0, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.ValueDec), 'CORE.COMMON.MODEL.JOBPARAM.VALUEDEC', 2, false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ValueText), 'CORE.COMMON.MODEL.JOBPARAM.VALUETEXT', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.ValueDate), 'CORE.COMMON.MODEL.JOBPARAM.VALUEDATE', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.ValueList_ID), 'CORE.COMMON.MODEL.JOBPARAM.VALUELIST', `model/representations/ListDetail${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sequence), 'CORE.COMMON.MODEL.JOBPARAM.SEQUENCE', 0, true).setMDSizeHalf());
        
    }
}
