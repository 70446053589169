import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternPostfachSearcherComponent } from '../components/postfach-searcher/postfach-searcher.components';

@Injectable({
    providedIn: 'root'
})
export class VwbPInternPostfachService {
    constructor(public dialogService: DialogService) { }

    public OpenPostfach() {
        this.dialogService.open(VwbPInternPostfachSearcherComponent, {
            header: 'Postfach',
            width: '75%',
            closable: true,
            closeOnEscape: true,
            contentStyle: { 'max-height': '90vh', 'overflow': 'auto' },
            baseZIndex: 1000
        });
    }
}
