import { VwbPListDetailConst } from '@avi-x/vwbp-public';
import { Component, ChangeDetectorRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { AviCommonService, AviApiService, AviListDetailConst, AviFormFieldService, AviJobService } from '@avi-x/avi-core';
import { VwbPCommonService } from '@avi-x/vwbp-public';
import { VwbPInternRechtstraegerBaseComponent } from './rechtstraeger-searcher-base.component';
import { VwbPInternPostfachService } from '../../../postfach/services/postfach.service';
import { VwbPInternRechtstraegerService } from '../../services/rechtstraeger.service';
import { RTVerMode } from '../rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';


@Component({
    selector: 'vwbp-intern-rechtstraeger-comments-searcher',
    templateUrl: './rechtstraeger-comments-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternRechtstraegerVersionenCommentsSearcherComponent extends VwbPInternRechtstraegerBaseComponent {
    toolbarButtons : any[] = [
        { title: 'Excel Export', class: 'p-button-secondary', icon: 'pi pi-download', action: () => this.OnExportClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0, visible: () => this.CheckboxSelection, },
        { title: 'Serienbrief', class: 'p-button-secondary', icon: 'pi pi-envelope', action: () => this.OnSerienbriefClick(), disabled: () => this.GetSelectedRechtstraeger()?.length === 0, visible: () => this.CheckboxSelection }
    ];

    @Input('use-router-params')
    public UseRouterParams: boolean = false;

    constructor(
        public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        public cdr: ChangeDetectorRef,
        public vwbpCommonService: VwbPCommonService,
        private formFieldService: AviFormFieldService,
        private postfachService: VwbPInternPostfachService,
        private jobService: AviJobService,
        private rechtstraegerService: VwbPInternRechtstraegerService
    ) {
        super(apiService, router, commonService, vwbpCommonService, cdr);
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any = null) => {
        return this.doSearch(searchValue, '/v1/intern/vwbp/rechtstraeger/comments', searchConfig);
    }

    public SelectAllDelegate: any = async (searchValue: string, searchConfig: any = null) => {
        const settings = this.buildSearchConfigAsObject();
        return await this.apiService.post('v1/intern/vwbp/rechtstraeger/comments/ids', settings);
    }

    async OnExportClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.RechtstraegerId);
        this.rechtstraegerService.OpenExcelExportJobDialog(rechtstraegerIds);
    }

    async OnSerienbriefClick() {
        const rechtstraegerIds = this.GetSelectedRechtstraeger().map(w => w.RechtstraegerId);
        this.rechtstraegerService.OpenSerienbriefJobDialog(rechtstraegerIds, RTVerMode.RECHTSTRAEGER);
    }

    public async onSearcherInit(data) {
        this.searcher.beginInit();

        this.searcher.addTextColumn('Name', 'VWBP.COMMON.RT', true);
        this.searcher.addTextColumn('Rechtsform', 'VWBP.COMMON.RECHTSFORM', true);
        this.searcher.addTextColumn('RegisterNummer', 'VWBP.COMMON.REGISTERNR', true).setWidth('150px');
        this.searcher.addListTypeColumn('Typ', 'VWBP.COMMON.RT_TYP', 'vwbp_rechtstraeger_typ', true, true, null, 'v1/vwbp').setWidth('250px');
        this.searcher.addDateColumn('Eingangsdatum', 'VWBP.COMMON.EINGANGSDATUM', null, true).setWidth('190px');
        this.searcher.addDateColumn('RegisterDatum', 'VWBP.COMMON.REGISTERDATUM', null, true).setWidth('190px');
        this.searcher.addListTypeColumn('Erfassungsstatus', 'VWBP.COMMON.ERFASSUNGSSTATUS', 'vwbp_erfassungsstatus', true, true, null, 'v1/vwbp',
            [VwbPListDetailConst.VWEG_ERFASSUNGSSTATUS_VERANTWORTLICHKEIT_ABGEGEGEBEN, VwbPListDetailConst.VWEG_ERFASSUNGSSTATUS_RECHTSTRAEGER_STATUS_GELOESCHT])
            .setWidth('200px');

        this.searcher.addListTypeColumn('Uebergabestatus', 'VWBP.COMMON.UEBERGABESTATUS', 'vwbp_rt_uebergabestatus', true, true, null, 'v1/vwbp').setWidth('200px');
        this.searcher.addDateColumn('AbgegebenAm', 'VWBP.COMMON.ABGEGEBEN_AM', 'medium', true).setWidth('190px');
        this.searcher.addIntegerColumn('Version', 'VWBP.COMMON.VERSION', true).setWidth('90px');
        this.searcher.addDateColumn('Versionsdatum', 'VWBP.COMMON.VERSIONSDATUM', null, true).setWidth('190px');
        this.searcher.addDateColumn('Freigabedatum', 'VWBP.COMMON.FREIGABEDATUM', null, true).setWidth('190px');
        this.searcher.addDateColumn('HrLoeschungsdatum', 'VWBP.COMMON.LOESCHUNGSDATUM', null, true).setWidth('190px');
        this.searcher.addDateColumn('InformiertAm', 'VWBP.COMMON.INFORMIERT_AM', null, true).setWidth('190px');
        this.searcher.addListTypeColumn('ValidierungAusnahme', 'VWBP.COMMON.VALIDIERUNG_AUSNAHME', 'janein', true, true, null, 'v1/vwbp').setFormatDelegate(w => w.ValidierungAusnahme.Id === AviListDetailConst.JA ? w.ValidierungAusnahme.Bezeichnung : '');
        this.searcher.addListTypeColumn('BemerkungTeilfonds', 'VWBP.COMMON.BEMERKUNG_TEILFONDS', 'janein', true, true, null, 'v1/vwbp').setFormatDelegate(w => w.BemerkungTeilfonds.Id === AviListDetailConst.JA ? w.BemerkungTeilfonds.Bezeichnung : '');

        await this.AddColumnKeywords();

        this.searcher.addListTypeColumn('VersionTyp', 'VersionTyp', 'vwbp_version_typ', true, false, null, 'v1/vwbp').setVisible(false).setWidth('250px');
        this.searcher.addIntegerColumn('BemerkungCount', 'Offene Bemerkungen', true).setVisible(false).setWidth('45px');
        this.searcher.addTextColumn('RechtstraegerId', 'RechtstraegerId', true).setVisible(false);
        this.searcher.addListTypeColumn('Unstimmigkeitsstatus', 'UnstimmigkeitsstatusId', 'vwbp_unstimmigkeitsstatus', true, false, null, 'v1/vwbp').setVisible(false);
        this.searcher.addListTypeColumn('UnstimmigkeitMahnwesenAktiv', 'UnstimmigkeitMahnwesenAktivId', 'vwbp_mahnwesen_aktiv', true, false, null, 'v1/vwbp').setVisible(false);

        // this.AddColumBemerkungen();
        this.AddColumnAuge();

        this.searcher.endInit();

        this.onInit.emit(null);
    }
}
