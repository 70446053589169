import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[tdContainer]',
})
export class AviTextDiffContainerDirective {
    @Input() id: string;

    element: HTMLTableCellElement;

    constructor(private _el: ElementRef) {
        this.element = _el.nativeElement;
    }
}
