import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { AviApiService, AviCommonService } from "@avi-x/avi-core";

@Component({
    selector: 'avi-custompropcollectiondef-view',   
    templateUrl: './custompropcollectiondef-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviCustomPropCollectionDefViewComponent {  
    @Input('model-id')
    public ModelId: string;

    @Output('onTestCustomPropCollectionDef')
    public onTestCustomPropCollectionDef: EventEmitter<string> = new EventEmitter();
        
    constructor(public apiService: AviApiService, public commonService: AviCommonService, public cdr: ChangeDetectorRef) {
    }

    TestCustomPropCollectionDef() {
        this.onTestCustomPropCollectionDef.emit(this.ModelId);
    }

    // async CopyFormularDef() {
    //     this.commonService.confirm({
    //         header: 'Vorlage kopieren',
    //         message: `Möchten Sie die Abrechnungsvorlage kopieren?`, 
    //         acceptButtonStyleClass: 'p-button-primary', 
    //         rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
    //         accept: async () => {
    //             const modelId = await this.apiService.get(`stammdaten/emwformulardef/copy/${this.ModelId}`);
    //             this.ModelId = modelId;
    //             this.cdr.markForCheck();        
    //         } 
    //     }); 
    // }

}
