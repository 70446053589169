import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AviApiService, AviFormField, AviCommonService, AviFormFieldService } from '@avi-x/avi-core';
import { Land } from '@avi-x/avi-dto/partner/land.model';

@Component({
    selector: 'avi-crm-land-form',
    templateUrl: './land-form.component.html',
    styleUrls: ['./land-form.component.scss']
})
export class AviLandFormComponent implements OnInit, OnDestroy {

    public PageTitle: string = 'LandForm';

    public Loading: boolean = false;
    public Model: Land = null;
    
    private _ModelId: string = null;
    public get ModelId(): string { return this._ModelId; }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Fields: AviFormField[] = [];

    sub: any;

    public get FormTitle(): string {
        return this.isEditMode() ? 'Land bearbeiten' : 'Land erstellen';
    }

    isEditMode(): boolean {
        return this.ModelId && this.ModelId !== '0';
    }

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef
    ) {
        this.Fields.push(this.formFieldService.CreateText('ISOCode', 'Isocode', true).setMDSize(3));
        this.Fields.push(this.formFieldService.CreateText('KfzCode', 'Kfzcode', true).setMDSize(3));
        this.Fields.push(this.formFieldService.CreateText('Bezeichnung1', 'Bezeichnung1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText('Bezeichnung2', 'Bezeichnung2', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText('Bezeichnung3', 'Bezeichnung3', true).setMDSizeHalf());
    }

    public async InitForm(value: string): Promise<boolean> {

        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                // this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        // this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            // this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));
        } else {
            //await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    ngOnInit() {

        this.Model = new Land();
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.ModelId = params['id'];
                if (this.ModelId && this.ModelId !== '0')
                    this.loadModel(this.ModelId);

            }
        });
    }

    deleteModel(model: any) {
        this.apiService.delete(`/land/${model.id}`).then(r => { this.router.navigate(['crm/country']); });
    }

    saveModel(model: any) {
        this.apiService.putPost('land', model).then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.router.navigate(['crm/country']);
        });
    }

    async loadModel(id: string) {
        this.Loading = true;
        this.Model = await this.apiService.getModelById(Land, 'land', id);
        this.Loading = false;
        console.log(this.Model);

    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
