import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviTechRoleLinkFormComponent } from './techrolelink-form.component';
import { TechRoleLinkSearchResponseDto } from '@avi-x/avi-dto/system/techrolelinksearchresponsedto.model';
import { TranslateService } from '@ngx-translate/core';
import { AviAppUserGroupLogEntryFormComponent } from './appusergrouplogentry-form.component';
import { TechRoleLinkInfo } from './TechRoleLinkInfo';

const nameof = (nameFunction: ((obj: TechRoleLinkSearchResponseDto) => any)) => typed_nameof<TechRoleLinkSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-techrolelink-searcher',
    templateUrl: './techrolelink-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: AviAbstractModelSearcherComponent, useExisting: AviTechRoleLinkSearcherComponent }]
})
export class AviTechRoleLinkSearcherComponent extends AviAbstractModelSearcherComponent implements AfterViewInit {  
    ClassName = 'CORE.COMMON.MODEL.TECHROLELINK';
    SettingsKey = 'settings.searcher.techrolelink';
    BaseUrl = 'techrole/link';
	FormType = AviTechRoleLinkFormComponent;

    CanEdit: boolean = false;

    @Input('techrolelink-info')
    techRoleLinkInfo: TechRoleLinkInfo;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        private translateService: TranslateService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('TechRole schreiben').then(w => this.CanEdit = w);
    }

    ngAfterViewInit(): void {
        this.searcher.onStateRestore.subscribe(() => {
            this.searcher.initColumns();
        });
    }

    protected getFormHeader(readonly: boolean, editmode: boolean): string {
        let header = super.getFormHeader(readonly, editmode);
        if (this.techRoleLinkInfo.isForAppUserGroup)
            return header + "_FOR_APPUSERGROUP";
        else 
            return header + "_FOR_TECHROLE";
    }

    getParams(): { [k: string]: any; } {
        return {'transferInfo': this.techRoleLinkInfo};
    }
	
    onDoubleClickSearcher(data) {
        // if (this.SelectedRows) {
        //     // this.sessionController.openSession('techrolelink-index', this.SelectedRows.Id);
        //     this.editObject(this.SelectedRows);
        // }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        
        if (this.techRoleLinkInfo.isForAppUserGroup)
            searcher.addTextColumn(nameof(c => c.TechRoleName), 'CORE.COMMON.MODEL.TECHROLELINK.ROLENAME', true);
        else
            searcher.addTextColumn(nameof(c => c.AppUserGroupBezeichnung), 'CORE.COMMON.MODEL.TECHROLELINK.APPUSERGROUPBEZEICHNUNG', true);

		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        cfg.AppUserGroupId = this.techRoleLinkInfo.appUserGroupId;
        const res = await this.apiService.postPagedResult(TechRoleLinkSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }

    public async deleteObject(selectedRows) {
        if (!selectedRows)
            return;

        let params = this.getParams();

        params["TechRoleLinkIdToDelete"] = selectedRows.Id;
        params["AppUserGroupId"] = selectedRows.AppUserGroupId;
        params["TechRoleName"] = selectedRows.TechRoleName;
        await this.commonService.openFormDialog(AviAppUserGroupLogEntryFormComponent, this.translateService.instant("CORE.COMMON.MODEL.TECHROLELINK.TECHROLELINK_DELETE_FROM_APPUSERGROUP"), '0', this.ContextId, params, true);
        this.onSavedSuccessful();
    }
}
