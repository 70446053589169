import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class DocPropContainerTemplateLinkSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

	@Expose()
	public ContainerBezeichnung: string;

	@Expose()
	public ContainerNodeName: string;

	@Expose()
	public ContainerId: string;
}
