import { AviCommonService } from './common.service';
import { Injectable } from '@angular/core';
import { AviApiService } from './api.service';

import * as signalR from '@microsoft/signalr';

import { Observable } from 'rxjs/internal/Observable';
import { Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AviSignalRService {
    private connection: signalR.HubConnection;

    private connected: boolean = false;
    private connectedSubject = new Subject<boolean>();

    constructor(public apiService: AviApiService, public commonService: AviCommonService) {
    }

    public ConnectedObservable(): Observable<boolean> {
        return this.connectedSubject.asObservable();
    }

    public get IsConnected() {
        return this.connected;
    }

    private SetConnected(value: boolean) {
        this.connected = value;
        this.connectedSubject.next(value);
        if (!this.commonService.IsProduction)
            console.debug('SignalRService.SetConnected', value);
    }

    private async InitSignalR() {
        console.debug('AviSignalRService.initSignalR...');
        
        if (this.connection)
            return;

        let url = await this.apiService.getAppServerUrl();
        url = this.commonService.getUrlOrigin(url);

        this.connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Information)
            .withUrl(this.commonService.trimUrl(url) + (this.commonService.config.signalRBasicHub ?? '/signalr/basichub'))
            .withAutomaticReconnect([0, 2000, 2000, 5000, 5000, 5000, 5000, 10000, 10000, 10000, 10000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000, 60000])
            .build();

        try {
            this.connection.onreconnecting(e => {
                if (!this.commonService.IsProduction)
                    console.debug('SignalRService.onreconnecting', e);
                if (e)
                    this.SetConnected(false);
            });

            this.connection.onreconnected(connectionId => {
                if (!this.commonService.IsProduction)
                    console.debug('SignalRService.onreconnected', connectionId);
                this.SetConnected(true);
            });


            this.connection.onclose(e => {
                if (!this.commonService.IsProduction)
                    console.debug('SignalRService.onclose');
                this.SetConnected(false);
            });

            await this.connection.start();

            this.SetConnected(true);
        } catch (err) {
            this.connection = null;
            if (!this.commonService.IsProduction)
                console.error(err.toString());
            return err;
        }
    }

    public async Subscribe(topic: string) {
        if (this.connection) {
            const act = async () => await this.connection.send('Subscribe', topic);
            if (this.IsConnected) {
                await act();
                // console.warn('SUB DIRECT');
            } else {
                const sub: Subscription = this.ConnectedObservable().subscribe(async o => {
                    await act();
                    // console.warn('SUB LATER');
                    sub.unsubscribe();
                });
            }
            if (!this.commonService.IsProduction)
                console.debug(`SignalRService.Subscribe(${topic})`);
        }
    }

    public async Unsubscribe(topic: string) {
        if (this.connection) {
            const act = async () => await this.connection.send('Unsubscribe', topic);

            if (this.IsConnected) {
                await act();
                // console.warn('SUB DIRECT');
            } else {
                const sub: Subscription = this.ConnectedObservable().subscribe(async o => {
                    await act();
                    // console.warn('SUB LATER');
                    sub.unsubscribe();
                });
            }

            if (!this.commonService.IsProduction)
                console.debug(`SignalRService.Unsubscribe(${topic})`);
        }
    }

    public async on(methodName: string, newMethod: (...args: any[]) => void) {
        await this.GetConnection();
        this.connection?.on(methodName, newMethod);
        if (!this.commonService.IsProduction)
            console.debug(`SignalRService.on(${methodName})`);
        return Promise.resolve();
    }

    public async off(methodName: string, newMethod: (...args: any[]) => void) {
        await this.GetConnection();
        if (newMethod)
            this.connection?.off(methodName, newMethod);
        else
            this.connection?.off(methodName);
        if (!this.commonService.IsProduction)
            console.debug(`SignalRService.off(${methodName})`);
        return Promise.resolve();
    }

    public async GetConnection(): Promise<signalR.HubConnection> {
        if (!this.connection)
            await this.InitSignalR();

        return this.connection;
    }
}
