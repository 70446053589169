import { Component, ChangeDetectorRef, Input, ViewChild } from '@angular/core';
import { AviCommonService, AviApiService, AviBaseSearcherComponent, AviSearcherColumn } from '@avi-x/avi-core';
import { AviDokumentService } from '@avi-x/avi-crm';

@Component({
    selector: 'avi-dokument-searcher',
    templateUrl: './dokument-searcher.component.html'
})
export class AviDokumentSearcherComponent {
    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @Input('abrechnung-id')
    public AbrechnungId: string;

    @Input('api')
    public Api: string = '/avidoc';

    @Input('context-id')
    public set ContextId(value: string) {
        this.contextId = value;
        this.forceRefresh();
    }

    @Input('context-type')
    public set ContextType(value: string) {
        this.contextType = value;
        this.forceRefresh();
    }
 
    @Input('search-delegate')
    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => this.SearchDocuments;

    public contextId: string;
    public contextType: string;

    constructor(
        public apiService: AviApiService,
        public commonService: AviCommonService,
        public documentService: AviDokumentService,
        public cdr: ChangeDetectorRef
    ) {
    }

    public SearchDocuments: any = async (searchValue: string, searchConfig: any) => {
        if (this.contextId && this.contextType) {
            let res = await this.apiService.get(`${this.Api}/GetDocumentsForModel/${this.contextId}/model_type${this.contextType}`);

            res = this.searcher.ApplyColumnFilters(res);
            res = this.searcher.ApplyColumnSorting(res);

            return res;
        }

        return [];
    }

    public async onSearcherInit(data) {
        this.searcher.beginInit();

        this.searcher.addTextColumn('Titel', 'Titel', true);
        this.searcher.addDateColumn('Created', 'Datum');
        this.searcher.addColumn(
            AviSearcherColumn.CreateCustom(
                'Type',
                'Type',
                (row, col) => {
                    const type = row['Type'] ? row['Type'].toLowerCase() : '';
                    if (type === '.pdf')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-pdf-o"></i>${row['Type']}</div>`;
                    else if (type === '.docx' || type === '.dotx' || type === '.docm' || type === '.dotm' || type === '.doc' || type === '.dot')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-word-o"></i>${row['Type']}</div>`;
                    else if (type === '.xlsx' || type === '.xls')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-excel-o"></i>${row['Type']}</div>`;
                    else if (type === '.pptx' || type === '.ppt')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-powerpoint-o"></i>${row['Type']}</div>`;
                    else if (type === '.xml')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-code-o"></i>${row['Type']}</div>`;
                    else if (type === '.png' || type === '.jpg' || type === '.jpeg' || type === '.tif' || type === '.gif' || type === '.bmp')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-image-o"></i>${row['Type']}</div>`;
                    else if (type === '.msg' || type === '.eml')
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-envelope-o"></i>${row['Type']}</div>`;
                    else
                        return `<div class="valign-center"><i class="font-size-medium mr-4 fa fa-file-o"></i>${row['Type']}</div>`;
                },
                false,
                '85px'
            ).setSortable(false)
        );
        this.searcher.addTextColumn('Size', 'Size', true);

        this.searcher.endInit();
    }

    async forceRefresh() {
        if (this.searcher)
            this.searcher.forceRefresh();
    }

    
    public async onDoubleClickDocument(doc: any) {
        if (doc) {
            await this.documentService.openPreviewDialog(doc.ArchivDocumentID, doc.vonArchivDef_ID, this.Api);
        }
    }
}
