import { TranslateService } from '@ngx-translate/core';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviListDetailConst, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BusinesscaseDataDef } from '@avi-x/avi-dto/businesscase/businesscasedatadef.model';
import { StatusDefSearchResponseDto } from '@avi-x/avi-dto/workflow/statusdefsearchresponsedto.model';
import { AviFieldErrorDisplayModus } from 'libs/avi-core/src/lib/components/base-form/form-field';
import { BusinesscaseDataDefSearchResponseDto } from '@avi-x/avi-dto/businesscase/businesscasedatadefsearchresponsedto.model';
import { AviDocArt } from '@avi-x/avi-dto/avidoc_def/avidocart.model';
import { ListType } from '@avi-x/avi-dto/shared';

const nameof = (nameFunction: ((obj: BusinesscaseDataDef) => any)) => typed_nameof<BusinesscaseDataDef>(nameFunction);

@Component({
    selector: 'avi-businesscasedatadef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly"
        [validation-delegate]="ValidateForm">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseDataDefFormComponent extends AviAbstractModelFormComponent<BusinesscaseDataDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF';
    BaseUrl = 'businesscase/businesscasedatadef';
    ClassType = BusinesscaseDataDef;
    FormType = AviBusinesscaseDataDefFormComponent;
    
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        public translateService: TranslateService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFBusinesscaseDef';
    }

    public async afterCreateModel(bm: BusinesscaseDataDef) {
        bm.vonBusinesscaseDef_ID = this.ContextId;
    }

    public async afterInitForm() {
        this.setFromTyp();
        this.setFromEditDocument();
    }

    setFromEditDocument() {
        if (this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN) {
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.FinishDocument), this.Model.EditDocument.Id === AviListDetailConst.JA);
            if (this.Model.FinishDocument.Id === AviListDetailConst.JA && this.Model.EditDocument.Id === AviListDetailConst.JA)
                this.Model.FinishDocument = new ListType(AviListDetailConst.NEIN, 'janein', 'Nein');
        }
    }

    async setFromTyp() {
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Mandatory), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_VERKNUEPFEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EditDocument), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FinishDocument), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DraftWatermark), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DocArt_ID), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN || this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_VERKNUEPFEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DocFormat), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DocSign), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DocTemplate_ID), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DocuMatrixTemplate_ID), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ActivityType_ID), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_AKTIVITAET_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.MustBeFinished), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_AKTIVITAET_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FormDef_ID), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_FORM_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.CustomFormId), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_FORM_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.CustomDataId), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_CUSTOM_DATA);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.CorrectDataDef_ID), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DATEN_KORRIGIEREN || this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_FINALISIEREN || this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DATEN_VEROEFFENTLICHEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Versioning), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DATEN_KORRIGIEREN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ShowBemerkung), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DATEN_KORRIGIEREN || this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_FORM_ERSTELLEN);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Context), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_VERKNUEPFEN || 
            this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN || this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_FORM_ERSTELLEN);

        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.DocArt_ID), /*this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN ||*/ this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_VERKNUEPFEN);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.ActivityType_ID), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_AKTIVITAET_ERSTELLEN);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.CorrectDataDef_ID), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DATEN_KORRIGIEREN || this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_FINALISIEREN|| this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DATEN_VEROEFFENTLICHEN);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.CustomDataId), this.Model.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_CUSTOM_DATA);
    }
    
    ValidateForm = (): boolean => {
        let result: boolean = true;

        if (this.Model)
        {
            if (this.Model.DataType.Id ===  AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_FORM_ERSTELLEN) {
                const field = this.Fields.find(c => c.Name === nameof(x => x.FormDef_ID));
                if (!this.Model.FormDef_ID && !this.Model.CustomFormId) {
                    const f1 = this.translateService.instant('CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.FORMDEF');
                    const f2 = this.translateService.instant('CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.CUSTOMFORMID'); {
                    field.addError(`Entweder ${f1} oder ${f2} muss gesetzt sein`, 'error', AviFieldErrorDisplayModus.TOUCHED);
                    result = false;
                }
                } else 
                    field.clearErrors();
            }
        }

        return result;
    };

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.DataType))
            this.setFromTyp();
        else if (data.field === nameof(c => c.Context))
            this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
        else if (data.field === nameof(c => c.EditDocument))
            this.setFromEditDocument();
    }

    queryStatusDef = async (field: any) => {
        const ret = [];
        if (this.Model && this.Model.vonBusinesscaseDef_ID) {
            const cfg: any = { BusinesscaseDefId: this.Model.vonBusinesscaseDef_ID, ModelId: this.ReadOnly || field.ReadOnly ? this.Model.StatusDef_ID : null };
            const p = await this.apiService.postPagedResult(StatusDefSearchResponseDto, `workflow/statusdef/search`, cfg);
            p.Results?.forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    queryBusinesscaseDataDef = async (field: any) => {
        const ret = [];
        if (this.Model && this.Model.vonBusinesscaseDef_ID) {
            const cfg: any = { ContextId: this.Model.vonBusinesscaseDef_ID, ModelId: this.ReadOnly || field.ReadOnly ? this.Model.CorrectDataDef_ID : null };
            const p = await this.apiService.postPagedResult(BusinesscaseDataDefSearchResponseDto, `businesscase/businesscasedatadef/search`, cfg);
            p.Results?.filter(w => w.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_FORM_ERSTELLEN || w.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_VERKNUEPFEN || w.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_DOC_ERSTELLEN)
                .forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    queryActivityType = async (field: any) => {
        const ret = [];
        if (this.Model) {
            const cfg: any = { ContextModelName: 'CAFBusinesscase', ModelId: this.ReadOnly || field.ReadOnly ? this.Model.ActivityType_ID : null };
            const p = await this.apiService.postPagedResult(BusinesscaseDataDefSearchResponseDto, `activity/activitytype/search`, cfg);
            p.Results?.forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    queryDokumentenart = async (field: any) => {
        const ret = [];
        if (this.Model && this.Model.vonBusinesscaseDef_ID) {
            const cfg: any = { BusinesscaseDefId: this.Model.vonBusinesscaseDef_ID, ContextType: this.Model.Context.Id };
            const p = await this.apiService.postModelList(AviDocArt, `businesscase/businesscasedatadef/docarte`, cfg);
            p.forEach(elem => ret.push({ label: elem.Bezeichnung1, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    initFields() {
        if (this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DataType), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DATATYPE', 'caf_businesscasedata_typ', true, true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.BEZEICHNUNG', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Description), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DESCRIPTION', false).setMDSizeFull());
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.StatusDef_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.STATUSDEF', this.queryStatusDef, true).setAutoselectSingle().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.Internal), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.INTERNAL', '').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DocTemplate_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DOCTEMPLATE', `model/representations/AviDocTemplate${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DocuMatrixTemplate_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DOCUMATRIXTEMPLATE', `model/representations/CAFDocuMatrixTemplate${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.EditDocument), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.EDITDOCUMENT', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DocArt_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DOCART', this.queryDokumentenart, false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DocFormat), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DOCFORMAT', 'caf_businesscasedata_docformat', true, true, this.ReadOnly).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DocSign), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DOCSIGN', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Context), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.CONTEXT', 'caf_businesscasedata_context', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Mandatory), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.MANDATORY', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.ActivityType_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.ACTIVITYTYPE', this.queryActivityType, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.MustBeFinished), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.MUSTBEFINISHED', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.FormDef_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.FORMDEF', `model/representations/CAFCustomPropCollectionDef${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.CustomFormId), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.CUSTOMFORMID', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.CorrectDataDef_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.CORRECTDATADEF', this.queryBusinesscaseDataDef).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Versioning), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.VERSIONING', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ShowBemerkung), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.SHOWBEMERKUNG', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.FinishDocument), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.FINISHDOCUMENT', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.CustomDataId), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.CUSTOMDATAID', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.DraftWatermark), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDATADEF.DRAFTWATERMARK', false).setMDSizeHalf());
    }
}
