import { Expose, Type } from 'class-transformer';

import { BaseModelState } from '../shared/basemodel.model';

export class ModelMeta extends BaseModelState {  
    @Expose()
    public get Id(): string { return this.getModelId('Id'); }
    public set Id(value: string) { this.setModelId('Id', value); }
    
    @Expose()
    public get Model_Name(): string { return this.getProperty('Model_Name'); }
    public set Model_Name(value: string) { this.setProperty('Model_Name', value); }
    
    @Expose()
    public get Model_Name_Short(): string { return this.getProperty('Model_Name_Short'); }
    public set Model_Name_Short(value: string) { this.setProperty('Model_Name_Short', value); }
    
    @Expose()
    public get Table_Name(): string { return this.getProperty('Table_Name'); }
    public set Table_Name(value: string) { this.setProperty('Table_Name', value); }
    
    @Expose()
    public get Superclass_Name(): string { return this.getProperty('Superclass_Name'); }
    public set Superclass_Name(value: string) { this.setProperty('Superclass_Name', value); }
    
    @Expose()
    public get CanHaveDocuments(): boolean { return this.getProperty('CanHaveDocuments'); }
    public set CanHaveDocuments(value: boolean) { this.setProperty('CanHaveDocuments', value); }
    
    @Expose()
    public get CanHaveActivities(): boolean { return this.getProperty('CanHaveActivities'); }
    public set CanHaveActivities(value: boolean) { this.setProperty('CanHaveActivities', value); }
    
    @Expose()
    public get SearchActivities(): boolean { return this.getProperty('SearchActivities'); }
    public set SearchActivities(value: boolean) { this.setProperty('SearchActivities', value); }
    
    @Expose()
    public get CanHaveAktenNotiz(): boolean { return this.getProperty('CanHaveAktenNotiz'); }
    public set CanHaveAktenNotiz(value: boolean) { this.setProperty('CanHaveAktenNotiz', value); }
    
    @Expose()
    public get SearchAktenNotiz(): boolean { return this.getProperty('SearchAktenNotiz'); }
    public set SearchAktenNotiz(value: boolean) { this.setProperty('SearchAktenNotiz', value); }
    
    @Expose()
    public get CanHaveNotes(): boolean { return this.getProperty('CanHaveNotes'); }
    public set CanHaveNotes(value: boolean) { this.setProperty('CanHaveNotes', value); }
    
    @Expose()
    public get SearchNotes(): boolean { return this.getProperty('SearchNotes'); }
    public set SearchNotes(value: boolean) { this.setProperty('SearchNotes', value); }
    
    @Expose()
    public get CanHaveResponsibilities(): boolean { return this.getProperty('CanHaveResponsibilities'); }
    public set CanHaveResponsibilities(value: boolean) { this.setProperty('CanHaveResponsibilities', value); }
    
    @Expose()
    public get CanHaveForms(): boolean { return this.getProperty('CanHaveForms'); }
    public set CanHaveForms(value: boolean) { this.setProperty('CanHaveForms', value); }
    
    @Expose()
    public get SearchForms(): boolean { return this.getProperty('SearchForms'); }
    public set SearchForms(value: boolean) { this.setProperty('SearchForms', value); }
    
    @Expose()
    public get CanHaveRisks(): boolean { return this.getProperty('CanHaveRisks'); }
    public set CanHaveRisks(value: boolean) { this.setProperty('CanHaveRisks', value); }
    
    @Expose()
    public get SearchRisks(): boolean { return this.getProperty('SearchRisks'); }
    public set SearchRisks(value: boolean) { this.setProperty('SearchRisks', value); }
    
    @Expose()
    public get CanHaveDossiers(): boolean { return this.getProperty('CanHaveDossiers'); }
    public set CanHaveDossiers(value: boolean) { this.setProperty('CanHaveDossiers', value); }
    
    @Expose()
    public get SearchDossiers(): boolean { return this.getProperty('SearchDossiers'); }
    public set SearchDossiers(value: boolean) { this.setProperty('SearchDossiers', value); }
    
    @Expose()
    public get CanHaveKeywords(): boolean { return this.getProperty('CanHaveKeywords'); }
    public set CanHaveKeywords(value: boolean) { this.setProperty('CanHaveKeywords', value); }
    
    @Expose()
    public get SearchKeywords(): boolean { return this.getProperty('SearchKeywords'); }
    public set SearchKeywords(value: boolean) { this.setProperty('SearchKeywords', value); }
    
    @Expose()
    public get CanHaveAdditionalInfo(): boolean { return this.getProperty('CanHaveAdditionalInfo'); }
    public set CanHaveAdditionalInfo(value: boolean) { this.setProperty('CanHaveAdditionalInfo', value); }
    
    @Expose()
    public get SearchAdditionalInfo(): boolean { return this.getProperty('SearchAdditionalInfo'); }
    public set SearchAdditionalInfo(value: boolean) { this.setProperty('SearchAdditionalInfo', value); }
    
    @Expose()
    public get CanHaveWorkflows(): boolean { return this.getProperty('CanHaveWorkflows'); }
    public set CanHaveWorkflows(value: boolean) { this.setProperty('CanHaveWorkflows', value); }
    
    @Expose()
    public get SearchWorkflows(): boolean { return this.getProperty('SearchWorkflows'); }
    public set SearchWorkflows(value: boolean) { this.setProperty('SearchWorkflows', value); }
    
    @Expose()
    public get CanHaveAliases(): boolean { return this.getProperty('CanHaveAliases'); }
    public set CanHaveAliases(value: boolean) { this.setProperty('CanHaveAliases', value); }
    
    @Expose()
    public get SearchAliases(): boolean { return this.getProperty('SearchAliases'); }
    public set SearchAliases(value: boolean) { this.setProperty('SearchAliases', value); }
    
    @Expose()
    public get AutoSearchInDialog(): boolean { return this.getProperty('AutoSearchInDialog'); }
    public set AutoSearchInDialog(value: boolean) { this.setProperty('AutoSearchInDialog', value); }
    
    @Expose()
    public get Lizenz(): string { return this.getProperty('Lizenz'); }
    public set Lizenz(value: string) { this.setProperty('Lizenz', value); }
    
    @Expose()
    public get DefaultRepresentation(): string { return this.getProperty('DefaultRepresentation'); }
    public set DefaultRepresentation(value: string) { this.setProperty('DefaultRepresentation', value); }
    
    @Expose()
    public get DisableAudit(): boolean { return this.getProperty('DisableAudit'); }
    public set DisableAudit(value: boolean) { this.setProperty('DisableAudit', value); }
    
    @Expose()
    public get AliasTypCollection_ID(): string { return this.getModelId('AliasTypCollection_ID'); }
    public set AliasTypCollection_ID(value: string) { this.setModelId('AliasTypCollection_ID', value); }
 
}
