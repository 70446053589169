<ng-template #content><ng-content></ng-content></ng-template>

<div *ngIf="PanelEnabled">
    <div [ngClass]="getClass()">
        <p-panel
            [header]="Header"
            [showHeader]="Header !== null && Header !== ''"
            [toggleable]="Toggleable"
            [collapsed]="Collapsed"
            (collapsedChange)="OnCollapsedChange($event)"
            expandIcon="pi pi-chevron-down"
            [iconPos]="'end'"
            collapseIcon="pi pi-chevron-up"
            [toggler]="toggler"
            [styleClass]="getStyleClass()"
            [ngClass]="{'fullscreen': PanelFullscreen, 'p-panel-nolabel': NoLabel && !PanelFullscreen}"
        >
            <ng-template *ngIf="ShowFullscreenIcon" pTemplate="icons">
                <button type="button" pRipple class="p-panel-header-icon p-link" (click)="toggleFullscreen($event)">
                    <span [class]="PanelFullscreen ? 'pi pi-window-minimize' : 'pi pi-window-maximize'"></span>
                </button>
            </ng-template>

            <ng-container *ngTemplateOutlet="content"></ng-container>
        </p-panel>

    </div>
</div>

<div *ngIf="!PanelEnabled">
    <div [ngClass]="getClass()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</div>
