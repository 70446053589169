import { Expose } from "class-transformer";
import { AppUserGroup } from "./appusergroup.model";
import { IDTO } from "../shared";

export class AppUserGroupAddDto extends IDTO {
    @Expose()
    AppUserGroup: AppUserGroup;

    @Expose()
    AddedTechRolesIds: string[] = [];
    
    @Expose()
    AddedAppUserIds: string[];
}