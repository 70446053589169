<!-- Antrag Formular -->
<avi-core-base-form
    *ngIf="Model"
    #form
    [readonly]="ReadOnly"
    [validation-delegate]="ValidateAntrag"
    [card]="Card"
    [fields]="Fields"
    [contents-padding]="true"
    [form-title]="FormTitle | translate"
    [Model]="Model"
    [loading]="Loading"
    (onSave)="saveModel($event)"
    (onAttrChange)="onAttrChange($event)"
    [action-buttons]="actionButtons"
    [action-buttons-readonly]="actionButtonsReadonly"
>

    <!-- Formular Liste -->
    <div beforeButtons>
        <div *ngIf="showFormulare()" class="mt-4">
            <p-divider class="toolbar-divider"></p-divider>
            <label *ngIf="isNew()">{{ 'VWBP.INTERN.SCHWAERZEN.ANTRAG_WB_SELEKTION_MULTI' | translate }}</label>
            <label *ngIf="!isNew()">{{ 'VWBP.INTERN.SCHWAERZEN.ANTRAG_WB_SELEKTION_EINZEL' | translate }}</label>
            <avi-core-base-searcher
                #formularListe
                [checkbox-selection]="true"
                [checkbox-selectionmode]="SelectionMode"
                [metakeyselection]="MetaKeySelection"
                [contents-padding]="false"
                [use-router-params]="false"
                [search-delegate]="SearchFormularDelegate"
                [rows]="0"
                [auto-search]="true"
                [show-autofilter]="false"
                [toolbar]="false"
                [toolbar-show-searchinput]="false"
                [toolbar-show-advanced]="false"
                [lazy-loading]="true"
                [card]="false"
                (onInit)="onFormularListInit($event)"
                searcher-title="Formulare"
                [show-totalrecords]="true"
                [select-first-result]="false"
            >
            </avi-core-base-searcher>
        </div>
    </div>
</avi-core-base-form>

