<div *ngIf="HasData" class="pl-2 pr-2 pb-2 pt-2">
    <avi-core-view-panel [no-pad]="true" [toggleable]="true" header="Formulare">
        <div class="ui-g businesscase-card-contents">
            <div class="ui-g-12">
            
                <ng-container *ngFor="let status of Data.Status">
                    <div *ngIf="Data.Status.length > 1" class="subtask-title pb-1 pt-2">{{ status.Bezeichnung  }}</div>
                    <div *ngFor="let data of status.Data; let i = index" class="subtask-container">
                        <table class="subtask-list">
                            <tr>
                                <td *ngIf="data.Details.length > 1" class="pr-0 fitwidth">
                                    <button pButton pRipple type="button" [icon]="ShowVersions ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" class="p-button-rounded p-button-secondary p-button-text" (click)="ShowVersions = !ShowVersions"></button>
                                </td>
                                <td>
                                    <div>
                                        <b>{{ data.Bezeichnung }} <span *ngIf="data.Details.length > 0 && data.Details[0].Titel != data.Bezeichnung">{{ data.Details[0].Titel }}</span></b>                                       
                                        <span *ngIf="data.Details.length > 0 && data.Details[0].Internal" [class]="'ml-1 status-badge status-intern'">Intern</span>
                                    </div>
                                    <div *ngIf="data.Details.length > 0" class="subtask-subtitle"><span *ngIf="data.Details[0].Versionsnummer != null">Version {{ data.Details[0].Versionsnummer }} </span><span *ngIf="data.Details[0].Draft">(Entwurf)</span></div>
                                    <div *ngIf="data.Details.length > 0" class="subtask-subtitle">{{ data.Details[0].CreatedByName }} - {{ data.Details[0].CreatedDate | date: "dd.MM.y HH:mm" : "de-CH" }}</div>
                                </td>

                                <td class="tasks fitwidth">
                                    <div>
                                        <button pButton
                                            *ngIf="data.Details.length > 0 && data.Details[0].Bemerkung"
                                            type="button"
                                            icon="pi pi-align-left"
                                            aviThrottledClick
                                            (throttledClick)="showFormularBemerkungen(data.Details[0], !(status.Readonly && data.CanCorrect))"
                                            pTooltip="Bemerkungen anzeigen"
                                            class="w-100p-m p-button-secondary p-button-flat">
                                        </button>

                                        <button pButton
                                            type="button"
                                            icon="pi pi-search"
                                            aviThrottledClick
                                            (throttledClick)="openFormular(data)"
                                            pTooltip="Öffnen"
                                            class="w-100p-m p-button-secondary p-button-flat">
                                        </button>
                                        <button pButton
                                            *ngIf="!status.Readonly"
                                            type="button"
                                            icon="pi pi-pencil"
                                            pTooltip="Bearbeiten"
                                            aviThrottledClick
                                            (throttledClick)="editFormular(data)"
                                            class="w-100p-m p-button-secondary p-button-flat">
                                        </button>
                                        <button pButton
                                            *ngIf="status.Readonly && data.CanCorrect"
                                            type="button"
                                            icon="pi pi-pencil"
                                            pTooltip="Korrigieren"
                                            aviThrottledClick
                                            (throttledClick)="correctFormular(data)"
                                            class="w-100p-m p-button-secondary p-button-flat">
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <ng-container *ngIf="ShowVersions">
                                <tr *ngFor="let detail of data.Details.slice(1)" class="details">
                                    <td class="fitwidth"></td>
                                    <td>
                                        <div>Version {{ detail.Versionsnummer }}
                                            <span *ngIf="detail.Internal" [class]="'ml-2 status-badge status-intern'">Intern</span>
                                        </div>
                                        <div class="subtask-subtitle">{{ detail.CreatedByName }} - {{ detail.CreatedDate | date: "dd.MM.y HH:mm" : "de-CH" }}</div>
                                    </td>
                                    <td class="tasks fitwidth">
                                        <button pButton
                                            type="button"
                                            icon="pi pi-search"
                                            aviThrottledClick
                                            (throttledClick)="openFormular(data, detail.Versionsnummer)"
                                            pTooltip="Öffnen"
                                            class="w-100p-m p-button-secondary p-button-flat">
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </ng-container>
            </div>
        </div>
    </avi-core-view-panel>
</div>
