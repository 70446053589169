import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AviCommonService, AviSessionControllerService } from '@avi-x/avi-core';
import { AviActivityService, AviBusinesscaseService, AviDokumentService } from '@avi-x/avi-crm';
import { BusinesscaseDataDetailDto, BusinesscaseDataDto, BusinesscaseDataTypeDto } from '@avi-x/avi-dto/businesscase/businesscasedatatypedto.model';
import { SplitButton } from 'primeng/splitbutton';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { BusinesscaseSummaryDto } from '@avi-x/avi-dto/businesscase/businesscasesummarydto.model';

@Component({
    selector: 'avi-businesscase-dokumente-view',
    templateUrl: './businesscase-dokumente-view.component.html',
    styleUrls: ['./businesscase-dokumente-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseDokumenteViewComponent implements OnChanges {
    
    @Input('businesscase')
    Businesscase: BusinesscaseSummaryDto;

    @Input('data-verknuepfen')
    public DataVerknuepfen: BusinesscaseDataTypeDto;

    @Input('data-erstellen')
    public DataErstellen: BusinesscaseDataTypeDto;

    @Output('onChanged')
    public onChanged: EventEmitter<any> = new EventEmitter<any>();

    IsIntern: boolean = false;
    HasDataVerknuepfen: boolean = false;
    HasDataErstellen: boolean = false;
    DocumentOpened: boolean = false;

    constructor(public commonService: AviCommonService,
        public dialogService: DialogService,
        public activityService: AviActivityService,
        public dokumentService: AviDokumentService,
        public sessionController: AviSessionControllerService,
        public businesscaseService: AviBusinesscaseService,
        private cdr: ChangeDetectorRef) {
    
        this.IsIntern = this.businesscaseService.isIntern();
    }
    
    fillDocumentMenuItems(btn: SplitButton, t: BusinesscaseDataDetailDto, readonly: boolean, canCorrect: boolean) {
        btn.model = this.getDocumentMenuItems(t, readonly, canCorrect);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.HasDataVerknuepfen = (this.DataVerknuepfen?.Status.length > 0 && this.DataVerknuepfen.Status.some(w => !w.Readonly || w.Data.some(d => d.CanCorrect || d.Details.length > 0)));
        this.HasDataErstellen = (this.DataErstellen?.Status.length > 0 && this.DataErstellen.Status.some(w => !w.Readonly || w.Data.some(d => d.CanCorrect || d.Details.length > 0)));
    }

    getDocumentMenuItems(t: BusinesscaseDataDetailDto, readonly: boolean, canCorrect: boolean): MenuItem[] {
        const itemOpen: MenuItem = { label: 'Dokument öffnen', icon: 'pi pi-external-link', command: () => this.openPreview(t)  };
        const itemRemove: MenuItem = { label: 'Dokument entfernen', icon: 'pi pi-trash', command: () => this.removeDocumentLink(t) };

        const arr: MenuItem[] = [itemOpen];
        if (!readonly || canCorrect) {
            arr.push({ separator: true });
            arr.push(itemRemove);
        }

        return arr;        
    }

    public async addDocument(data: BusinesscaseDataDto, file: File) {
        const res = await this.businesscaseService.addDocument(this.Businesscase, data, file);
        if (res)
            this.onChanged.emit();
    }

    public async addDocumentLink(data: BusinesscaseDataDto) {
        const res = await this.businesscaseService.addDocumentLink(this.Businesscase, data);
        if (res)
            this.onChanged.emit();
    }
    
    public openPreview(doc: BusinesscaseDataDetailDto) {
        if (doc)
            this.dokumentService.openPreviewDialog(doc.DocId, doc.DocArchiv);
    }

    public removeDocumentLink(doc: BusinesscaseDataDetailDto) {
        this.businesscaseService.removeDocumentLink(doc, () => this.onChanged.emit());
    }

    
    async openDocument(data: BusinesscaseDataDto) {
        try {
            this.DocumentOpened = true;
            const res = await this.businesscaseService.openDocumentDialog(this.Businesscase, data, 'show');
            if (res)
                this.onChanged.emit();
        } finally {
            this.DocumentOpened = false;
            this.cdr.markForCheck();
        }
    }

    async editDocument(data: BusinesscaseDataDto) {
        try {
            this.DocumentOpened = true;
            const res = await this.businesscaseService.openDocumentDialog(this.Businesscase, data, 'edit');
            if (res)
                this.onChanged.emit();
        } finally {
            this.DocumentOpened = false;
            this.cdr.markForCheck();
        }
    }    
}
