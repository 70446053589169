import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'vwbp-admin-job',
    template: `
        <avi-job-form
            [card]="false"
            [contents-padding]="false"
            [readonly]="true"
            [model-id]="ModelId"
            [enable-taskmenu]="true"
            [enable-edit]="false">
        </avi-job-form>
        <avi-jobparam-searcher
            [context-id]="ModelId"
            [enable-panel]="true"
            [toolbar]="false">
        </avi-jobparam-searcher>`
})
export class VwbPAdminJobComponent implements OnInit, OnDestroy {
    
    sub: Subscription;

    ModelId: string = null;

    constructor(public activatedRoute: ActivatedRoute
    ) {

    }

    ngOnInit() {

        this.sub = this.activatedRoute.data.subscribe(params => {
            if (params && params['id']) {
                this.setModel(params['id']);
            }
        });
        
    }

    ngOnDestroy(): void {
        if (this.sub)
            this.sub.unsubscribe();
    }

    setModel(modelId: string) {
        this.ModelId = modelId || '0';
    }
}