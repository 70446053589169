import { Expose, Type } from 'class-transformer';

import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { AppUser } from '@avi-x/avi-dto/system/appuser.model';

export class VwbPKontaktInfoData extends BaseModel {

    @Expose()
    public get vonAppUser_ID(): string { return this.getModelId('vonAppUser_ID'); }
    public set vonAppUser_ID(value: string) { this.setModelId('vonAppUser_ID', value); }

    @Expose()
    @Type(() => AppUser)
    public get vonAppUser(): AppUser { return this.getModel('vonAppUser'); }
    public set vonAppUser(value: AppUser) { this.setModel('vonAppUser', value); }

    @Expose()
    public get Name(): string { return this.getProperty('Name'); }
    public set Name(value: string) { this.setProperty('Name', value); }

    @Expose()
    public get Adresse(): string { return this.getProperty('Adresse'); }
    public set Adresse(value: string) { this.setProperty('Adresse', value); }

    @Expose()
    public get KontaktName(): string { return this.getProperty('KontaktName'); }
    public set KontaktName(value: string) { this.setProperty('KontaktName', value); }

    @Expose()
    public get KontaktTelefon(): string { return this.getProperty('KontaktTelefon'); }
    public set KontaktTelefon(value: string) { this.setProperty('KontaktTelefon', value); }

    @Expose()
    public get KontaktEmail(): string { return this.getProperty('KontaktEmail'); }
    public set KontaktEmail(value: string) { this.setProperty('KontaktEmail', value); }

    @Expose()
    public get KontoName(): string { return this.getProperty('KontoName'); }
    public set KontoName(value: string) { this.setProperty('KontoName', value); }

    @Expose()
    public get KontoEmail(): string { return this.getProperty('KontoEmail'); }
    public set KontoEmail(value: string) { this.setProperty('KontoEmail', value); }

    @Expose()
    public get KeycloakId(): string { return this.getProperty('KeycloakId'); }
    public set KeycloakId(value: string) { this.setProperty('KeycloakId', value); }
}
