import { AviUserContactInfoSearcherComponent } from './components/user-contactinfo-searcher.ts/user-contactinfo-searcher.component';
import { AviCoreModule } from '@avi-x/avi-core';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        AviCoreModule
    ],
    exports: [
        AviUserContactInfoSearcherComponent,
        RouterModule
    ],
    declarations: [
        AviUserContactInfoSearcherComponent
    ]
})
export class AviUserContactInfoModule { }
