<div class="p-12">
    <avi-core-page
        title="{{ 'VWBP.INTERN.BEHOERDENABFRAGE.SUBTITLE_RT' | translate }}"
        [no-box-shadow]="true"
        [collapsible]="true"
        [contents-padding]="true"
    >
        <avi-core-base-form
            *ngIf="Model"
            #form
            [card]="false"
            [fields]="Fields"
            [contents-padding]="false"
            [Model]="Model"
            [save-button-label]="'CORE.COMMON.SEARCH'"
            [save-button-icon]="'pi pi-search'"
            [loading]="Loading"
            (onSave)="startSearch($event)"
        >
        </avi-core-base-form>
    </avi-core-page>

    <div class="mt-20 card-container">
        <avi-core-base-searcher
            #searcher
            [search-delegate]="SearchDelegate"
            (onInit)="onSearcherInit($event)"
            (onError)="onSearcherError($event)"
            [use-router-params]="false"
            (onDoubleClick)="onDoubleClickSearcher($event)"
            [rows]="50"
            [auto-search]="false"
            [show-autofilter]="true"
            [lazy-loading]="true"
            [get-row-class]="GetRowClass"
            [card]="false"
            [show-totalrecords]="true"
            [select-first-result]="false"
            [keep-selection]="CheckboxSelection"
            [selectall-delegate]="SelectAllDelegate"
            [checkbox-selection]="CheckboxSelection"
            [show-totalselected]="CheckboxSelection"

            [toolbar]="true"
            [toolbar-show-searchinput]="false"
            [toolbar-show-inactivebutton]="false"
            [toolbar-show-columnselection]="false"
            [toolbar-show-advanced]="false"
            [toolbar-show-refresh]="false"
            [toolbar-show-createbutton]="false"
            [toolbar-show-inaktive]="false"
            [toolbar-buttons]="toolbarButtons"
        >
        </avi-core-base-searcher>
    </div>
</div>

<avi-core-dialog #rtDialog identifier="rtDialog" [showCloseIcon]="false" [closeOnEscape]="false">
    <vwbp-intern-rechtstraeger-view #rtView [standalone]="false"></vwbp-intern-rechtstraeger-view>

    <div class="mb-4 mr-5">
        <div class="col grid justify-content-end">
            <button
                pButton
                type="button"
                [label]="'CORE.COMMON.SCHLIESSEN_BUTTON' | translate"
                (click)="CloseRTView()"
                class="w-100p-m m p-button-secondary p-button-outlined"
            ></button>
        </div>
    </div>
</avi-core-dialog>
