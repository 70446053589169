import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { ListType } from '../shared';
import TransformDate from '../shared/transformdate';

export class BusinesscaseSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;

	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
    public ErstellDatum?: Date;

    @Expose()
	public Number: number;

    @Expose()
	public NumberBez: string;

    @Expose()
	public Title: string;

	@Expose()
	@Type(() => ListType)
	public Priority: ListType;

    @Expose()
    @Type(() => Date)
	public DueDate: Date;
    @Expose()
    public StatusBez: string

    @Expose()
    public StatusBearbeitbar: boolean;

    @Expose()
	public AssignedTo_ID: string;

    @Expose()
	public Applicant_ID: string;

    @Expose()
	public CreatedBy_ID: string;

    @Expose()
	public AssignedToUsername: string;

    @Expose()
	public ApplicantUsername: string;

    @Expose()
	public CreatedByUsername: string;

    @Expose()
	@Type(() => ListType)
	public Verantwortlichkeit: ListType;

    @Expose()
    BusinesscaseDef_ID: string;

    @Expose()
    BusinesscaseDefBez: string;

    @Expose()
    BusinesscaseDefKurzBez: string;

    @Expose()
    @TransformDate()
	public Frist: Date;

    @Expose()
    public KulanzFrist: number;

    @Expose()
    public Erledigt: boolean;

    @Expose()
    public Approved: boolean;
}
