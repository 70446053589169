import { Injectable } from '@angular/core';
import { AviApiService, AviAuthService, AviCommonService } from '@avi-x/avi-core';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';
import { FileSaverService } from 'ngx-filesaver';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class VwbPAdminService {


    constructor(private authService: AviAuthService,
        private commonService: AviCommonService,
        private fileSaverService: FileSaverService,
        private apiService: AviApiService) {

    }

    public GetLogs(dtFrom: Date, dtUntil: Date, userId?: string, logTypes?: string[]) {
        const data = { DateFrom: dtFrom, DateUntil: dtUntil, UserId: userId, LogTypes: logTypes };
        return this.apiService.post('/v1/intern/vwbp/admin/logs', data);
    }

    public async DownloadBehoerdensucheLogs(dtFrom: Date, dtUntil: Date) {
        try {
            this.commonService.showGlobalLoader();
            const data = { DateFrom: dtFrom, DateUntil: dtUntil, UserId: null, LogTypes: [VwbPListDetailConst.VWBP_LOG_TYP_BEHOERDENSUCHE] };
            const blob = await this.apiService.getBlobWithPost('/v1/intern/vwbp/admin/logs/behoerdensuche/download', data);
            if (blob) {
                const fname = `behoerdensuche_${moment(dtFrom).format('YYYY-MM-DD')}-${moment(dtUntil).format('YYYY-MM-DD')}.csv`;
                this.fileSaverService.save(blob, fname);
            }
            this.commonService.hideGlobalLoader();
        } catch (err) {
            this.commonService.hideGlobalLoader();
            throw err;
        }

    }


}
