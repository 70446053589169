import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof } from '@avi-x/avi-core';
import { ScriptRuleDefSearchResultDto } from '@avi-x/avi-dto/system/scriptruledefsearchresultdto.model';
import { AviScriptRuleDefFormComponent } from './scriptruledef-form.component';

const nameof = (nameFunction: ((obj: ScriptRuleDefSearchResultDto) => any)) => typed_nameof<ScriptRuleDefSearchResultDto>(nameFunction);

@Component({
    selector: 'avi-scriptruledef-searcher',
    templateUrl: './scriptruledef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRuleDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFSCRIPTRULEDEF';
    SettingsKey = 'settings.searcher.scriptruledef';
    BaseUrl = 'scriptruledef';
    FormType = AviScriptRuleDefFormComponent;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);
    }

    onSearcherInit(searcher) {
	    searcher.beginInit();
        searcher.addIntegerColumn(nameof(c => c.RuleID), 'Regel Nr.');
        searcher.addDateColumn(nameof(c => c.GueltigAb), 'Gültig ab');
        searcher.addDateColumn(nameof(c => c.GueltigBis), 'Gültig bis');
        searcher.addTextColumn(nameof(c => c.Bezeichnung1), 'Bezeichnung');               
        searcher.addListTypeColumn(nameof(c => c.Assignment1), 'Zuweisung 1', 'caf_ruledef_assignment');        
        searcher.addListTypeColumn(nameof(c => c.Assignment2), 'Zuweisung 2', 'caf_ruledef_assignment');
        searcher.addListTypeColumn(nameof(c => c.AutoClose), 'Auto-erledigen', 'janein');        
        searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        if (this.ContextId) {
            const cfg =  { ...searchConfig };
            cfg.Query = searchValue;
            cfg.ContextId = this.ContextId;
            const res = await this.apiService.postModelList(ScriptRuleDefSearchResultDto, `scriptruledef/search`, cfg);
            return res;
        }

        return null;
    }
}
