import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

import * as moment from 'moment-timezone';
import 'reflect-metadata';

if (environment.production) {
  enableProdMode();
}

moment.locale('de-CH');

platformBrowserDynamic().bootstrapModule(AppModule);
