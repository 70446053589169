import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviDocPropertyDefFormComponent } from './docpropertydef-form.component';
import { DocPropertyDefSearchResponseDto } from '@avi-x/avi-dto/document_def/docpropertydefsearchresponsedto.model';

const nameof = (nameFunction: ((obj: DocPropertyDefSearchResponseDto) => any)) => typed_nameof<DocPropertyDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-docpropertydef-searcher',
    templateUrl: './docpropertydef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocPropertyDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF';
    SettingsKey = 'settings.searcher.docpropertydef';
    BaseUrl = 'documentdef/docpropertydef';
	FormType = AviDocPropertyDefFormComponent;

    CanEdit: boolean = false;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFDocProperty schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.sessionController.openSession('docpropertydef-index', this.SelectedRows.Id);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.KurzBez), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.KURZBEZ1', true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.BEZEICHNUNG1', true);
        
        searcher.addTextColumn(nameof(c => c.PropertyName), 'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.PROPERTYNAME', true);
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(DocPropertyDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
