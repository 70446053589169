<ejs-richtexteditor
    #Editor
    #name='ngModel' 
    [id]="Id"
    [inlineMode]="{ enable: ReadOnly, onSelection: true }"
    [insertImageSettings]='insertImageSettings'
    [toolbarSettings]='richTextEditToolbarItems'
    [enableTabKey]="true"
    [required]="Required"
    [name]="Name"
    [(ngModel)]="Model"
    [readonly]="ReadOnly"
    [focus]="Focus"    
    [disabled]="Disabled"
    (created)="onCreate($event)"
    (focus)="onFocusInt($event)"
    (blur)="onBlurInt($event)"
    [saveInterval]="1000"
>
</ejs-richtexteditor>
<!-- 
<ejs-dialog #SymbolDialog id="SymbolDialog" [buttons]='SymbolDialogButtons' (overlayClick)="SymbolDialogOverlay()" [header]='SymbolHeader'
    [visible]='false' [showCloseIcon]='false' width='400px' [target]="target"
    [isModal]='true'>
<ng-template #content>
    <div *ngIf="SymbolDialog.visible" id="rteSpecial_char" (click)="SelectSymbol($event)">
        <div class="char_block" title="^">^</div>
        <div class="char_block" title="_">_</div>
        <div class="char_block" title="`">`</div>
        <div class="char_block" title="&#123;">&#123;</div>
        <div class="char_block" title="|">|</div>
        <div class="char_block" title="&#125;">&#125;</div>
        <div class="char_block" title="~">~</div>
        <div class="char_block" title="&nbsp;">&nbsp;</div>
        <div class="char_block" title="¡">¡</div>
        <div class="char_block" title="¢">¢</div>
        <div class="char_block" title="£">£</div>
        <div class="char_block" title="¤">¤</div>
        <div class="char_block" title="¥">¥</div>
        <div class="char_block" title="₹">₹</div>
        <div class="char_block" title="¦">¦</div>
        <div class="char_block" title="§">§</div>
        <div class="char_block" title="¨">¨</div>
        <div class="char_block" title="©">©</div>
        <div class="char_block" title="ª">ª</div>
        <div class="char_block" title="«">«</div>
        <div class="char_block" title="¬">¬</div>
        <div class="char_block" title="­">­</div>
        <div class="char_block" title="®">®</div>
        <div class="char_block" title="¯">¯</div>
        <div class="char_block" title="°">°</div>
        <div class="char_block" title="±">±</div>
        <div class="char_block" title="²">²</div>
        <div class="char_block" title="³">³</div>
        <div class="char_block" title="´">´</div>
        <div class="char_block" title="µ">µ</div>
        <div class="char_block" title="¶">¶</div>
        <div class="char_block" title="·">·</div>
        <div class="char_block" title="¸">¸</div>
        <div class="char_block" title="¹">¹</div>
        <div class="char_block" title="º">º</div>
        <div class="char_block" title="»">»</div>
        <div class="char_block" title="¼">¼</div>
        <div class="char_block" title="½">½</div>
        <div class="char_block" title="¾">¾</div>
        <div class="char_block" title="¿">¿</div>
        <div class="char_block" title="À">À</div>
        <div class="char_block" title="Á">Á</div>
        <div class="char_block" title="Â">Â</div>
        <div class="char_block" title="Ã">Ã</div>
    </div>
</ng-template>
</ejs-dialog>

<ejs-dialog #SmileyDialog id="SmileyDialog" [buttons]='SmileyDialogButtons' (overlayClick)="SmileyDialogOverlay()" [header]='SmileyHeader'
    [visible]='false' [showCloseIcon]='false' width='390px' [target]="target"
    [isModal]='true'>
<ng-template #content>
    <emoji-mart *ngIf="SmileyDialog.visible" title="" emoji="" [showPreview]="false" [darkMode]="false" [isNative]="true" (emojiClick)="SelectSmiley($event)"></emoji-mart>
</ng-template>
</ejs-dialog> -->

<avi-core-dbg [m]="Model"></avi-core-dbg>
