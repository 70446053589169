import { ListType } from './listtype.model';

import { Expose, Type } from 'class-transformer';
import { IDTO } from './idto.model';

export type ClassType<T> = new (...args: any[]) => T;

export class BaseModelState extends IDTO {
    protected _state: any = {};

    @Expose()
    public get Id(): string { return this.getProperty('Id'); }
    public set Id(value: string) { this.setProperty('Id', value); }

    @Expose()
    public get version(): number { return this.getProperty('version'); }
    public set version(value: number) { this.setProperty('version', value); }

    public getPropertyNames() {
        return Object.keys(this._state);
    }

    public getProperty<T>(attrname: string): T {
        return this._state[attrname];
    }

    public setProperty<T>(attrname: string, value: T) {
        this._state[attrname] = value;
    }

    protected getModelId(idname: string): string {
        return this.getProperty(idname);
    }

    protected setModelId(idname: string, value: string) {
        this._state[idname] = value;
        const attrname = idname.substring(0, idname.length - 3);

        const bm = this._state[attrname] as BaseModelState;
        if (bm && bm.Id !== value) this._state[attrname] = null;
    }

    public getModel<T>(attrname: string) {
        return this.getProperty<T>(attrname);
    }

    public setModel<T extends BaseModelState>(attrname: string, value: T) {
        this._state[attrname] = value;
        const idname = attrname + '_ID';

        if (value) this._state[idname] = value.Id;
    }
}

export class BaseModel extends BaseModelState {
    @Expose()
    @Type(() => ListType)
    public get Aktiv(): ListType { return this.getProperty('Aktiv'); }
    public set Aktiv(value: ListType) { this.setProperty('Aktiv', value); }

    @Expose()
    @Type(() => Date)
    public get ErstellDatum(): Date { return this.getProperty('ErstellDatum'); }
    public set ErstellDatum(value: Date) { this.setProperty('ErstellDatum', value); }

    @Expose()
    @Type(() => Date)
    public get MutDatum(): Date { return this.getProperty('MutDatum'); }
    public set MutDatum(value: Date) { this.setProperty('MutDatum', value); }
}
