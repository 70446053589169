import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { AviApiService, AviCommonService, AviSessionControllerService } from '@avi-x/avi-core';
import { AviActivityService, AviBusinesscaseService, AviDokumentService } from '@avi-x/avi-crm';
import { BusinesscaseDataDetailDto, BusinesscaseDataDto, BusinesscaseDataTypeDto } from '@avi-x/avi-dto/businesscase/businesscasedatatypedto.model';
import { DialogService } from 'primeng/dynamicdialog';
import { BusinesscaseSummaryDto } from '@avi-x/avi-dto/businesscase/businesscasesummarydto.model';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'avi-businesscase-formulare-view',
    templateUrl: './businesscase-formulare-view.component.html',
    styleUrls: ['./businesscase-formulare-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseFormulareViewComponent implements OnChanges {
    @Input('businesscase')
    Businesscase: BusinesscaseSummaryDto;

    @Input('data')
    public Data: BusinesscaseDataTypeDto;

    @Output('onChanged')
    public onChanged: EventEmitter<any> = new EventEmitter<any>();

    ShowVersions: boolean = false;
    IsIntern: boolean = false;
    HasData: boolean = false;

    constructor(private apiService: AviApiService, public commonService: AviCommonService,
        public dialogService: DialogService,
        public activityService: AviActivityService,
        public dokumentService: AviDokumentService,
        public sessionController: AviSessionControllerService,
        public businesscaseService: AviBusinesscaseService,
        private cdr: ChangeDetectorRef) {
            
        this.IsIntern = this.businesscaseService.isIntern();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.HasData = this.Data && this.Data.Status.length > 0 && this.Data.Status.some(w => !w.Readonly || w.Data.some(d => d.CanCorrect || d.Details.length > 0));
    }

    async openFormular(data: BusinesscaseDataDto, version: number = null) {
        const res = await this.businesscaseService.openFormularDialog(this.Businesscase, data, version, 'show');
        if (res)
            this.onChanged.emit();
    }

    async editFormular(data: BusinesscaseDataDto) {
        const res = await this.businesscaseService.openFormularDialog(this.Businesscase, data, null, 'edit');
        if (res)
            this.onChanged.emit();
    }
    
    async correctFormular(data: BusinesscaseDataDto) {
        const res = await this.businesscaseService.openFormularDialog(this.Businesscase, data, null, 'correct');
        if (res)
            this.onChanged.emit();
    }

    async showFormularBemerkungen(detail: BusinesscaseDataDetailDto, readonly: boolean) {
        const res = await this.businesscaseService.showFormularBemerkungen(detail, readonly);
        if (res)
            this.onChanged.emit();
    }
}

