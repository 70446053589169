import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'avi-core-actionbuttons',
    template: `
        <p-button
            *ngFor="let ab of actionButtons"
            type="button"
            [icon]="ab.icon"
            [styleClass]="getActionButtonClass(ab)"
            [label]="ab.title | translate"
            [disabled]="ab.disabled ? ab.disabled(): false"
            [pTooltip]="ab.tooltip ? ab.tooltip() : ''"
            aviThrottledOnClick
            (throttledClick)="ab.action()"
        ></p-button> `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviActionButtonsComponent {
    @Input('action-buttons')
    actionButtons: any[] = [];

    getActionButtonClass(ab: any) {
        const ret = `mr-2 ${ab.class ?? ''}`;
        return ret;
    }
}
