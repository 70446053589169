import { Component, Input, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiService, AviFormField, AviCommonService, AviAuthService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviDialogComponent } from '@avi-x/avi-core';
import { VwbPListDetailConst } from '../../../constants/constants';
import { VwbPFormularDaten } from '../../../dto/vwbpformulardaten.model';
import { VwbPPublicRechtstraegerImportierenFormComponent } from '../../../rechtstraeger/components/rechtstraeger-importieren-form/rechtstraeger-importieren-form.component';

const nameof = (nameFunction: ((obj: VwbPFormularDaten) => any)) => typed_nameof<VwbPFormularDaten>(nameFunction);

@Component({
    selector: 'vwbp-public-vweg-formular-form',
    templateUrl: './vweg-formular-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPPublicVwEGFormularFormComponent implements OnInit, OnDestroy {
    private _className = 'VwbPFormularDaten';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('rechtstraegerLIForm', { static: false })
    rechtstraegerLIForm: VwbPPublicRechtstraegerImportierenFormComponent;

    @ViewChild('rechtstraegerFormDialog', { static: false })
    rechtstraegerFormDialog: AviDialogComponent;

    public get FormTitle(): string {
        return this._className;
    }

    @Input('card')
    public Card: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('action-buttons-readonly')
    actionButtonsReadonly: any[] = [];

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    public Loading: boolean = false;
    public Model: VwbPFormularDaten = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string {
        return this._ModelId;
    }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Input('api')
    public Api: string = 'v1/vwbp';

    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => this.closeForm() }];

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string, formTyp: string = null, detailId: string = null): Promise<boolean> {
        if (this._ModelId === value || value == null) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
            }
            return;
        }

        this._ModelId = value;
        await this.loadModel(this._ModelId);
        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    private setFromFormType() {
        const formC = this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_VWEG_C;
        const formT = this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_VWEG_T;

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.WBC_WB), formC);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.WBC_KONT), formC);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.WBC_SMO), formC);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.WBT_SETTL), formT);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.WBT_ORG), formT);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.WBT_PRO), formT);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.WBT_BEG), formT);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.WBT_KONT), formT);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.WBT_SMO), formT);

        if (formC) {
            this._className = 'Formular C-VwEG';
            this.formFieldService.setFieldLabel(this.Fields, 'lblTitle', 'Formular zur Feststellung des letztlich wirtschaftlichen Eigentümers von Rechtsträgern nach Art. 2 Abs. 1 Bst. a VwEG');
            this.formFieldService.setFieldLabel(this.Fields, 'lbl1', 'Als letztlich wirtschaftlicher Eigentümer nach Art. 2 Abs. 1 Bst. a VwEG wurde festgestellt:');
        } else {
            this._className = 'Formular T-VwEG';
            this.formFieldService.setFieldLabel(this.Fields, 'lblTitle', 'Formular zur Feststellung des letztlich wirtschaftlichen Eigentümers von Rechtsträgern nach Art. 2 Abs. 1 Bst. b VwEG');
            this.formFieldService.setFieldLabel(this.Fields, 'lbl1', 'Als wirtschaftliche Eigentümer nach Art. 2 Abs. 1 Bst. b VwEG wurden folgende natürlichen Personen festgestellt, in deren Eigentum oder unter deren Kontrolle der Rechtsträger letztlich steht (Mehrfachauswahl möglich):');
        }
    }

    ngOnInit() {
        this.initFields();
    }

    async loadModel(id: string) {
        try {
            this.commonService.showGlobalLoader();

            this.Model = await this.apiService.FindModel(VwbPFormularDaten, `${this.Api}/formular/${id}`, null, null, null);

            this.setFromFormType();
            this.updateDropdownSources();
        } finally {
            this.commonService.hideGlobalLoader();
        }

        this._ModelId = this.Model.Id;

        return this.Model;
    }

    async updateDropdownSources() {
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
        await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        const lblTitle = this.formFieldService.CreateLabel('lblTitle', '', false).setMDSizeFull();
        lblTitle.LabelClass = 'form-title';
        this.Fields.push(lblTitle);

        this.Fields.push(this.formFieldService.CreateLabel('lbl1', '').setMDSizeFull());

        // FORM C
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WBC_WB), null, 'VWBP.PUBLIC.FORM.VWEG_WBC_WB', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WBC_KONT), null, 'VWBP.PUBLIC.FORM.VWEG_WBC_KONT', false).setMDSizeFull());

        // FORM T
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WBT_SETTL), null, 'VWBP.PUBLIC.FORM.VWEG_WBT_SETTL', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WBT_ORG), null, 'VWBP.PUBLIC.FORM.VWEG_WBT_ORG', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WBT_PRO), null, 'VWBP.PUBLIC.FORM.VWEG_WBT_PRO', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WBT_BEG), null, 'VWBP.PUBLIC.FORM.VWEG_WBT_BEG', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WBT_KONT), null, 'VWBP.PUBLIC.FORM.VWEG_WBT_KONT', false).setMDSizeFull());

        // Kontrolle Beschreibung
        const colKontrolle = this.formFieldService.CreateTextarea(nameof(c => c.KontrolleBeschreibung), 'VWBP.PUBLIC.FORM.VWEG_WBT_KDB', false).setMDSizeFull();
        colKontrolle.LabelVisible = false;
        colKontrolle.Placeholder = 'VWBP.PUBLIC.FORM.VWEG_WBT_KDB_PLACEHOLDER';
        this.Fields.push(colKontrolle);

        this.Fields.push(this.formFieldService.CreateLabel('lbl2', 'VWBP.PUBLIC.FORM.VWEG_SMO_HINWEIS').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WBC_SMO), null, 'VWBP.PUBLIC.FORM.VWEG_WBC_SMO', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WBT_SMO), null, 'VWBP.PUBLIC.FORM.VWEG_WBT_SMO', false).setMDSizeFull());

        // WB = Natürliche Person
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Vorname), 'CORE.COMMON.VORNAME', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'CORE.COMMON.NAME', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Geburtsdatum), 'CORE.COMMON.GEBURTSDATUM', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Land), 'CORE.COMMON.WOHNSITZSTAAT', true, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Nationalitaet1), 'CORE.COMMON.STAATSANGEHOERIGKEIT', true, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
    }

    private closeForm() {
        this.rechtstraegerFormDialog.close();
    }
}
