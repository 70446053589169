import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared';

export class DocPropContainerReference extends BaseModel {  
    @Expose()
    public get Bezeichnung(): string { return this.getProperty('Bezeichnung'); }
    public set Bezeichnung(value: string) { this.setProperty('Bezeichnung', value); }
    
    @Expose()
    public get SqlText(): string { return this.getProperty('SqlText'); }
    public set SqlText(value: string) { this.setProperty('SqlText', value); }
    
    @Expose()
    public get Path(): string { return this.getProperty('Path'); }
    public set Path(value: string) { this.setProperty('Path', value); }
    
    @Expose()
    public get vonModelMeta_ID(): string { return this.getModelId('vonModelMeta_ID'); }
    public set vonModelMeta_ID(value: string) { this.setModelId('vonModelMeta_ID', value); }
     
    @Expose()
    public get ParentContainer_ID(): string { return this.getModelId('ParentContainer_ID'); }
    public set ParentContainer_ID(value: string) { this.setModelId('ParentContainer_ID', value); }
     
    @Expose()
    public get SourceContainer_ID(): string { return this.getModelId('SourceContainer_ID'); }
    public set SourceContainer_ID(value: string) { this.setModelId('SourceContainer_ID', value); } 

    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }
}
