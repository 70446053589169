import { Injectable, Inject, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route, Router, CanActivate, CanActivateChild, CanLoad, CanDeactivate } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AviApiService, AviAuthService, AviCommonService } from '@avi-x/avi-core';
import { VwbPRoleConst } from '@avi-x/vwbp-public';


@Injectable({ providedIn: 'root' })
export class VwbPPublicAuthGuardService implements CanActivate {

    constructor(
        private commonService: AviCommonService,
        private authService: AviAuthService,
        private oauthService: OAuthService,
        private apiService: AviApiService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


        const hasValidAccessToken = this.oauthService.hasValidAccessToken();
        // console.log("hasValidAccessToken", hasValidAccessToken);
        // if (hasValidAccessToken)
        //     console.log("getAccessToken", this.oauthService.getAccessToken());
        let canActivate = hasValidAccessToken;

        if (!this.authService.currentUserHasRole(VwbPRoleConst.VWBP_ROLE_VWBP_BASIS))
            canActivate = false;

        if (!canActivate) {

            this.commonService.logWarn('canActivate is false in VwbPPublicAuthGuardService for ' + state.url);
            this.router.navigate([this.commonService.loginPath, { source: state.url }]);
        } else if (!state.url.startsWith('/vwbp/profile')) {

            return this.apiService.get('v1/vwbp/user/profile/exists').then(w => {
                if (w !== true) {
                    this.router.navigate(['vwbp/profile']);
                }
                return hasValidAccessToken;
            });
        }

        return hasValidAccessToken;
    }
}
