import { Expose, Type } from 'class-transformer';

import { AppUser } from '../system/appuser.model';
import { ScriptRuleDef } from '../system/scriptruledef.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared';

export class ScriptRuleOutput extends BaseModel {  
    @Expose()
    public get RuleId(): number { return this.getProperty('RuleId'); }
    public set RuleId(value: number) { this.setProperty('RuleId', value); }
    
    @Expose()
    public get Message1(): string { return this.getProperty('Message1'); }
    public set Message1(value: string) { this.setProperty('Message1', value); }
    
    @Expose()
    public get Message2(): string { return this.getProperty('Message2'); }
    public set Message2(value: string) { this.setProperty('Message2', value); }

    @Expose()
    public get Vermerk(): string { return this.getProperty('Vermerk'); }
    public set Vermerk(value: string) { this.setProperty('Vermerk', value); }

    @Expose()
    public get OutputWert(): number { return this.getProperty('OutputWert'); }
    public set OutputWert(value: number) { this.setProperty('OutputWert', value); }
    
    @Expose()
    public get Wert1(): number { return this.getProperty('Wert1'); }
    public set Wert1(value: number) { this.setProperty('Wert1', value); }

    @Expose()
    public get Wert2(): number { return this.getProperty('Wert2'); }
    public set Wert2(value: number) { this.setProperty('Wert2', value); }

    @Expose()
    public get Wert3(): number { return this.getProperty('Wert3'); }
    public set Wert3(value: number) { this.setProperty('Wert3', value); }

    @Expose()
    public get Wert4(): number { return this.getProperty('Wert4'); }
    public set Wert4(value: number) { this.setProperty('Wert4', value); }

    @Expose()
    @Type(() => Date)
    public get Geprueft1Am(): Date { return this.getProperty('Geprueft1Am'); }
    public set Geprueft1Am(value: Date) { this.setProperty('Geprueft1Am', value); }
    
    @Expose()
    @Type(() => Date)
    public get Geprueft2Am(): Date { return this.getProperty('Geprueft2Am'); }
    public set Geprueft2Am(value: Date) { this.setProperty('Geprueft2Am', value); }
    
    @Expose()
    public get Kommentar(): string { return this.getProperty('Kommentar'); }
    public set Kommentar(value: string) { this.setProperty('Kommentar', value); }
    
    @Expose()
    public get Geprueft1Von_ID(): string { return this.getModelId('Geprueft1Von_ID'); }
    public set Geprueft1Von_ID(value: string) { this.setModelId('Geprueft1Von_ID', value); }
 
    @Expose()
    @Type(() => AppUser)
    public get Geprueft1Von(): AppUser { return this.getModel('Geprueft1Von'); }
    public set Geprueft1Von(value: AppUser) { this.setModel('Geprueft1Von', value); }
    
    @Expose()
    public get Geprueft2Von_ID(): string { return this.getModelId('Geprueft2Von_ID'); }
    public set Geprueft2Von_ID(value: string) { this.setModelId('Geprueft2Von_ID', value); }
 
    @Expose()
    @Type(() => AppUser)
    public get Geprueft2Von(): AppUser { return this.getModel('Geprueft2Von'); }
    public set Geprueft2Von(value: AppUser) { this.setModel('Geprueft2Von', value); }
    
    @Expose()
    public get Owner_ID(): string { return this.getModelId('Owner_ID'); }
    public set Owner_ID(value: string) { this.setModelId('Owner_ID', value); }
 
    @Expose()
    @Type(() => AppUser)
    public get Owner(): AppUser { return this.getModel('Owner'); }
    public set Owner(value: AppUser) { this.setModel('Owner', value); }
    
    @Expose()
    public get vonRuleDef_ID(): string { return this.getModelId('vonRuleDef_ID'); }
    public set vonRuleDef_ID(value: string) { this.setModelId('vonRuleDef_ID', value); }
 
    @Expose()
    @Type(() => ScriptRuleDef)
    public get vonRuleDef(): ScriptRuleDef { return this.getModel('vonRuleDef'); }
    public set vonRuleDef(value: ScriptRuleDef) { this.setModel('vonRuleDef', value); }
    
    @Expose()
    @Type(() => ListType)
    public get RuleViolated(): ListType { return this.getProperty('RuleViolated'); }
    public set RuleViolated(value: ListType) { this.setProperty('RuleViolated', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Status(): ListType { return this.getProperty('Status'); }
    public set Status(value: ListType) { this.setProperty('Status', value); }
     
    @Expose()
    @Type(() => GenericRef)
    public get ModelRef(): GenericRef { return this.getProperty('ModelRef'); }
    public set ModelRef(value: GenericRef) { this.setProperty('ModelRef', value); }
}
