import { GenericRef } from '@avi-x/avi-dto/shared/genericref.model';
import { Expose, Type } from "class-transformer";
import { IDTO } from '../shared';
import TransformDate from '../shared/transformdate';

export class ScriptRulesetDefCreationParametersDto extends IDTO {
    @Expose()
    public RulesetDefId: string;

    @Expose()
    @Type(() => GenericRef)
    public ModelRef: GenericRef;    

    @Expose()
    @TransformDate()
    public Stichtag: Date;
}