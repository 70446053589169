import { SplitterModule } from 'primeng/splitter';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AviDokumentDashboardComponent } from './components/dokument-dashboard/dokument-dashboard.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {  AviCoreModule } from '@avi-x/avi-core';

import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TreeModule } from 'primeng/tree';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonModule } from 'primeng/togglebutton';

// import { BrowserModule } from '@angular/platform-browser';

import { HighlightModule } from 'ngx-highlightjs';
import { FormsModule } from '@angular/forms';

import { FileSaverModule } from 'ngx-filesaver';
import { AviDokumentErfassenFormComponent } from './components/dokument-erfassen-form/dokument-erfassen-form.component';
import { AviDokumentVersionInfoComponent } from './components/dokument-versioninfo/dokument-versioninfo.component';
import { AviDokumentSignierenFormComponent } from './components/dokument-signieren-form/dokument-signieren-form.component';
import { AviDokumentDetailsViewComponent } from './components/dokument-details-view/dokument-details-view.component';
import { AviDokumentService } from './services/dokument.service';
import { AviDokumentPreviewComponent } from './components/dokument-preview/dokument-preview.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AviDocPropertyDefFormComponent } from './components/docproperty/docpropertydef-form.component';
import { AviDocPropertyDefSearcherComponent } from './components/docproperty/docpropertydef-searcher.component';
import { AviDocPropertyDefIndexComponent } from './components/docproperty/docpropertydef-index.component';
import { AviDocPropertyFormComponent } from './components/docproperty/docproperty-form.component';
import { AviDocPropertySearcherComponent } from './components/docproperty/docproperty-searcher.component';
import { AviDokumentSearcherComponent } from './components/dokument-searcher/dokument-searcher.component';

@NgModule({
    imports: [
        // BrowserModule,
        FormsModule,
        CommonModule,
        AviCoreModule,

        TreeModule,
        FlexLayoutModule,
        HighlightModule,
        PanelModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ButtonModule,
        SplitButtonModule,
        FileUploadModule,
        InputTextModule,
        MenuModule,
        SplitterModule,
        ProgressSpinnerModule,

        FileSaverModule,

        TranslateModule
    ],
    exports: [
        AviDokumentDashboardComponent,
        AviDokumentPreviewComponent,
        AviDokumentSearcherComponent,
        AviDocPropertyDefSearcherComponent,
        AviDocPropertyDefIndexComponent,
        AviDocPropertyFormComponent,
        AviDocPropertySearcherComponent,
        RouterModule
    ],
    declarations: [
        AviDokumentDashboardComponent,
        AviDokumentSearcherComponent,
        AviDokumentErfassenFormComponent,
        AviDokumentDetailsViewComponent,
        AviDokumentSignierenFormComponent,
        AviDokumentVersionInfoComponent,
        AviDokumentPreviewComponent,
        AviDocPropertyDefFormComponent,
        AviDocPropertyDefSearcherComponent,
        AviDocPropertyDefIndexComponent,
        AviDocPropertyFormComponent,
        AviDocPropertySearcherComponent        
    ],
    providers: [ AviDokumentService ]
})
export class AviDokumentModule { }
