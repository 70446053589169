import { StatusWechselDef } from '@avi-x/avi-dto/workflow/statuswechseldef.model';
import { AviListDetailConst } from '@avi-x/avi-core';
import { ModelMeta } from '@avi-x/avi-dto/system/modelmeta.model';
import { ActivityVM } from '@avi-x/avi-dto/activity/activity-vm.model';
import { ActivityType } from '@avi-x/avi-dto/activity/activitytype.model';
import { ActivityDates } from '@avi-x/avi-dto/activity/activitydates.model';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviCommonService, AviAuthService, AviBaseFormComponent, ObjectUtils, AviFormFieldService, typed_nameof, AviChangedAttr } from '@avi-x/avi-core';


import * as moment from 'moment-timezone';
import { GenericRef } from '@avi-x/avi-dto/shared/genericref.model';
import { StatusDef } from '@avi-x/avi-dto/workflow/statusdef.model';
import { AviActivityService } from '../../services/activity.service';
import { Activity } from '@avi-x/avi-dto/activity/activity.model';
import { map } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';

const nameof = (nameFunction: ((obj: ActivityVM) => any)) => typed_nameof<ActivityVM>(nameFunction);

@Component({
    selector: 'avi-crm-activity-form',
    templateUrl: './activity-form.component.html',
    styleUrls: ['./activity-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviActivityFormComponent implements OnInit, OnDestroy {
    public contextId: string;
    public contextType: string;

    public PageTitle: string = 'Aktivität';
    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._statusWechselDef ? this._statusWechselDef.Bezeichnung1 : 'Aktivität bearbeiten';
        } else
            return 'Aktivität erstellen';
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('contents-padding')
    public ContentsPadding: boolean = true;
    
    @Input('inline-modus')
    public InlineModus = false;

    @Input('auto-focus')
    public AutoFocus = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('label-alignment')
    public LabelAlignment: 'left' | 'top' = 'top';

    @Input('label-width')
    public LabelWidth: any = '120px';

    @Input('show-buttons')
    public ShowButtons: boolean = true;

    @Input('api-prefix')
    public ApiPrefix: string = '';

    public Loading: boolean = false;
    public Model: ActivityVM = null;

    private _ModelId: string = null;
    public get ModelId(): string { return this._ModelId; }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    private _statusWechselDef: StatusWechselDef = null;

    @Input('status-wechsel')
    public set StatusWechselDef(value: StatusWechselDef) {
        this._statusWechselDef = value;
        if (this.Model && value)
            this.initStatusEditing();
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    public Fields: AviFormField[] = [];

    abbrechenButton: any = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { if (this.ref) this.ref.close(null); } }];
    abbrechenButtonReadonly: any = [{ title: 'Schliessen', class: 'p-button-secondary', action: () => { if (this.ref) this.ref.close(null); } }];

    constructor(public commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private apiService: AviApiService,
        private authService: AviAuthService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formFieldService: AviFormFieldService,
        private activityService: AviActivityService) {

    }

    public RefreshEditors() {
        this.Form.RefreshEditors();
    }

    public async InitForm(value: string, parentActivity: string = null, contextId: string = null): Promise<boolean> {
        if (this._ModelId !== '0' && (/*this._ModelId === value ||*/ value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this.Loading = true;
        this._ModelId = value;
        this.isEditMode = false;

        let res = true;

        this.resetFields();
        if (this.InlineModus)
            this.SetInlineModus();

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            if (this._statusWechselDef)
                res = await this.initStatusEditing();

            if (!this.InlineModus)
                setTimeout(() => this.Form.focusField(nameof(c => c.Titel), false));
        } else {
            if (parentActivity) {
                const parent = await this.apiService.FindModel(ActivityVM, `${this.ApiPrefix}/activity/${parentActivity}`, null, null);
                if (parent.ModelRef && parent.ModelRef.ModelID && parent.ModelRef.ModelType) {
                    this.contextId = parent.ModelRef.ModelID;
                    this.contextType = parent.ModelRef.ModelType;
                }
            }

            if (contextId)
                this.contextId = contextId;

            await this.initNewModel();
            this.Model.ParentActivity_ID = parentActivity;

            if (!this.InlineModus)
                setTimeout(() => this.Form.focusField(nameof(c => c.Titel), false));
        }

        this.Loading = false;
        this.cdr.markForCheck();

        return res;
    }

    public async Refresh() {
        if (this._ModelId && this.Model && this.Model.Id === this._ModelId && this.ReadOnly) {
            await this.loadModel(this._ModelId);

            if (!this.InlineModus)
                setTimeout(() => this.Form.focusField(nameof(c => c.Titel), false));
        }
    }

    public async ClearForm() {
        this._ModelId = null;
        this.Model = null;
    }

    async onAttrChange(data: AviChangedAttr) {
        if (this.Loading) return;

        if (data.field === nameof(c => c.ActivityType_ID) && this.Model.ActivityType_ID != null) {
            await this.initFromActivityType(this.Model.ActivityType_ID);
        } else if (data.field === nameof(c => c.StartDate)) {
            await this.CalculateDates();
        } else if (data.field === nameof(c => c.StatusDef_ID)) {
            await this.handleFinishedStatus();
        }
    }

    async onAttrBlur(data: AviChangedAttr) {
        if (this.InlineModus) {
			this._ModelId = this.Model.Id;
            await this.apiService.put(`${this.ApiPrefix}/activity/${this.Model.Id}`, this.Model);
            await this.loadModel(this._ModelId);
            this.Form.markAsPristine();

            this.onSavedSuccessfulDelegate.emit(this.Model);
        }
    }

    private async CalculateDates() {
        if (this.Model.ActivityType_ID != null && this.Model.StartDate) {
            let dateValue;
            if (this.Fields.find(c => c.Name === nameof(x => x.StartDate)).DateTime)
                dateValue = moment(new Date()).format('YYYY-MM-DD HH:mm');
            else
                dateValue = moment(new Date()).format('YYYY-MM-DD');

            const dates = await this.apiService.getModel(ActivityDates, `${this.ApiPrefix}/activity/CalculateActivityDates?activity_type=${this.Model.ActivityType_ID}&start_date=${dateValue}`);
            if (dates) {
                this.Model.RememberDate = dates.RememberDate;
                this.Model.DueDate = dates.DueDate;
                this.Model.EscalationDate = dates.EscalationDate;
            }
        }
    }

    private async initFromActivityType(actTypeId: string) {
        const activityType = await this.apiService.getModel(ActivityType, `${this.ApiPrefix}/activity/activitytype/${actTypeId}`);

        if (!this.isEditMode) {
            this.Model.ActivityQueue_ID = activityType.ActivityQueue_ID;
            if (!this.Model.Titel)
                this.Model.Titel = activityType.DefaultTitel;

            this.Model.IsRTF = activityType.IsRTF.Id === AviListDetailConst.JA;

            if (!this.Model.Description) {
                if (this.Model.IsRTF === true)
                    this.Model.DescriptionRTF = activityType.DefaultDescription ?? '';
                else
                    this.Model.Description = activityType.DefaultDescription ?? '';
            }

            this.Model.Priority = activityType.DefaultPriority;

            if (activityType.StatusWorkflowDef_ID) {
                const statusLinks = await this.apiService.getModelList(StatusDef, `${this.ApiPrefix}/activity/statusdef/start/${activityType.StatusWorkflowDef_ID}`);
                if (statusLinks) {
                    this.Model.StatusDef = statusLinks[0];
                    this.Model.StatusDef_ID = statusLinks[0].Id;

                    await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.StatusDef_ID)));
                }
            }

            await this.CalculateDates();
        }

        const dateTime: boolean = activityType.DatePrecision.Id === AviListDetailConst.ACTIVITY_DATEPRECISION_DAY_HOUR_MINUTE;
        this.Fields.find(c => c.Name === nameof(x => x.StartDate)).DateTime = dateTime;
        this.Fields.find(c => c.Name === nameof(x => x.DueDate)).DateTime = dateTime;
        this.Fields.find(c => c.Name === nameof(x => x.EscalationDate)).DateTime = dateTime;

        this.Fields.find(c => c.Name === nameof(x => x.DescriptionRTF)).Visible = this.Model.IsRTF === true;
        this.Fields.find(c => c.Name === nameof(x => x.Description)).Visible = this.Model.IsRTF !== true;

        if (this.Model.IsRTF === true)
            this.Fields.find(c => c.Name === nameof(x => x.DescriptionRTF)).Required = activityType.DescriptionPflicht.Id === AviListDetailConst.JA;
        else
            this.Fields.find(c => c.Name === nameof(x => x.Description)).Required = activityType.DescriptionPflicht.Id === AviListDetailConst.JA;

        const hasEscalationDate = activityType.EscalationActivityType_ID != null || activityType.EscalationToType.Id !== AviListDetailConst.ACTIVITY_ESKALATION_TO_TYP_KEINE;
        this.Fields.find(c => c.Name === nameof(x => x.EscalationDate)).Visible = hasEscalationDate;
        if (!hasEscalationDate)
            this.Fields.find(c => c.Name === nameof(x => x.RememberDate)).setMDSizeHalf();

        const ctx1 = this.Fields.find(c => c.Name === nameof(x => x.ModelRef));
        const ctx2 = this.Fields.find(c => c.Name === nameof(x => x.ContextRef2));
        const ctx3 = this.Fields.find(c => c.Name === nameof(x => x.ContextRef3));

        await this.initActivityContext(ctx1, activityType.Context1Bezeichnung1, activityType.Context1Zwingend, activityType.vonContext1ModelMeta, true);
        await this.initActivityContext(ctx2, activityType.Context2Bezeichnung1, activityType.Context2Zwingend, activityType.vonContext2ModelMeta, false);
        await this.initActivityContext(ctx3, activityType.Context3Bezeichnung1, activityType.Context3Zwingend, activityType.vonContext3ModelMeta, false);
    }

    private async initActivityContext(ctx1: AviFormField, contextBezeichnung: string, contextZwingend: boolean, modelMeta: ModelMeta, isFirstContext: boolean) {
        const propValue: GenericRef = this.Model.getProperty(ctx1.Name);
        if (modelMeta && (!this.ReadOnly || (propValue && propValue.ModelID && propValue.ModelType))) {
            ctx1.Visible = true;
            ctx1.Required = contextZwingend;
            ctx1.Label = contextBezeichnung;
            ctx1.LabelLiteral = true;
            ctx1.DropdownPlaceholder = this.ReadOnly ? '' : `${contextBezeichnung} wählen...`;
            ctx1.DropdownValueField = (elem) => new GenericRef(elem.Id, modelMeta.Model_Name);
            ctx1.DropdownDatasource = `${this.ApiPrefix}/model/representations/${modelMeta.Model_Name_Short}`; // `basemandat?filter=(aktiv eq aktiv()) and (GueltigAb le today) and (GueltigBis ge today)&sort=MandatsNr,Bezeichnung1`;

            if (isFirstContext && this.contextId && modelMeta.Model_Name === this.contextType) {
                if (!this.isEditMode)
                    this.Model.ModelRef = new GenericRef(this.contextId, this.contextType);
                ctx1.Readonly = true;
            }

            if (this.isEditMode)
                ctx1.Readonly = true;

            if (this.Model) {
                await this.formFieldService.UpdateAutocompleteDatasource(ctx1, this.Model);
                // this.formFieldService.BuildDropdownDatasource(ctx1, this.Model);
            }
        } else {
            ctx1.Visible = false;
            ctx1.Required = false;
        }
    }

    public SetInlineModus() {
        this.Fields.forEach((w) => this.formFieldService.setFieldDisabled(this.Fields, w.Name, true));

        this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.StatusDef_ID), false);
        this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.AssignedTo_ID), false);
        this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.Priority), false);
        this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.Titel), false);
    }

    public async initNewModel(data?: any) {
		this.Form?.resetForm();

        this.Model = await this.apiService.getModel(ActivityVM, `${this.ApiPrefix}/activity/create`);
        this.isEditMode = false;

        if (data) {
            // eslint-disable-next-line guard-for-in
            for (const key in data) {
                ObjectUtils.setByPath(this.Model, key, data[key]);
                await this.onAttrChange({ field: key, value: data[key], model: this.Model, form: this.Form  });
            }
        }

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FinishDate), false);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FinishedBy_ID), false);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ModelRef), false);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ContextRef2), false);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ContextRef3), false);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.ActivityType_ID), false);
		this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.StatusDef_ID), true);

        // await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.ActivityType_ID)));
		// await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.StatusDef_ID)));

        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model, true);


        console.log(this.Model);

        const atype = this.Fields.find(w => w.Name === nameof(w => w.ActivityType_ID));
        console.log(atype);
        if (atype.DropdownDatasourceInternal?.length === 1) {
            this.Model.ActivityType_ID = atype.DropdownDatasourceInternal[0].value;
            console.log(this.Model);
            await this.initFromActivityType(this.Model.ActivityType_ID);
            this.cdr.markForCheck();
        }

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ActivityType_ID), atype.DropdownDatasourceInternal?.length > 1);
    }

    private resetFields() {

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FinishDate), true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FinishedBy_ID), true);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Owner_ID), false);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AssignedTo_ID), false);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.StartDate), false);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DueDate), false);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Priority), false);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.FinishDate), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.FinishedBy_ID), true);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RememberDate), true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EscalationDate), true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Priority), true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.StartDate), true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DueDate), true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ActivityType_ID), true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ActivityID), true);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.ModelRef), false);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.ContextRef2), false);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.ContextRef3), false);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ModelRef), true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ContextRef2), true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ContextRef3), true);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Description), false);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DescriptionRTF), false);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.StatusDef_ID), true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.StatusDef_ID), false);
    }

    async handleFinishedStatus() {
        if (this.Model.Id && this.Model.StatusDef_ID) {
            const isFinishedState = await this.activityService.IsFinishedState(this.Model.Id, this.Model.StatusDef_ID);
    
            if (isFinishedState) {
                this.Model.FinishDate = new Date();
                this.Model.FinishedBy_ID = this.authService.CurrentUser.Uid;
    
                this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FinishDate), true);
                this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FinishedBy_ID), true);
    
                await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.FinishedBy_ID)));
    
                return true;
            }
    
            return false;
        }
    }

    public async initStatusEditing(): Promise<boolean> {
        let res = false;
        if (this._statusWechselDef && this.Model) {
            this.Model.StatusDef = await this.apiService.getModelById(StatusDef, `${this.ApiPrefix}/activity/statusdef`, this._statusWechselDef.AusgangStatus_ID);
            this.Model.StatusDef_ID = this.Model.StatusDef.Id;
            console.log(this.Model.StatusDef);

            res = await this.handleFinishedStatus();

            // TODO: Handle Berechtigung, assignedTo, Description (Siehe ActivityStatusProcessController)

            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Owner_ID), true);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.AssignedTo_ID), this._statusWechselDef.AllowChangeAssignedTo.Id !== AviListDetailConst.JA);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.StartDate), true);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DueDate), true);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.FinishedBy_ID), true);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Priority), true);

            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RememberDate), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EscalationDate), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Priority), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.StartDate), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DueDate), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ActivityType_ID), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ActivityID), false);

            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.ModelRef), true);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.ContextRef2), true);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.ContextRef3), true);

            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ModelRef), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ContextRef2), false);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ContextRef3), false);

            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Description), false);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DescriptionRTF), false);

            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.StatusDef_ID), true);
        }

        return res;
    }

    initParams(params: { [k: string]: any; }) {
        if (params) {
            if (params.contextId)
                this.contextId = params.contextId;

            if (params.contextType)
                this.contextType = params.contextType;
        }
    }
    
    ngOnInit() {
        this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
            this.contextId = res;
        });

        this.contextType = this.activatedRoute.snapshot.data['contextType'];

        // this.initModel();
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.ModelId = params['id'];
            }
        });

        if (this.config && this.config.data) {
            if (this.config.data.Id) {
                this.initParams(this.config.data.Params);
                this.ModelId = this.config.data.Id;
                this.ReadOnly = this.config.data.readonly ?? false;
                this.Card = false;
                this.ContentsPadding = false;
                this.actionButtons = [...this.actionButtons, ...this.abbrechenButton];
                // this.actionButtonsReadonly = [...this.actionButtonsReadonly, ...this.abbrechenButtonReadonly];
            }
        }

        this.initFields();
    }

    queryActivityAppUser = async (field: any) => {
        const ret = [];
        if (this.Model) {
            const cfg: any = { ModelId: this.ReadOnly || field.Readonly || field.Disabled ? this.Model[field.Name] : null };
            const p = await this.apiService.postModelList(ReferenceDto, `${this.ApiPrefix}/activity/appuser/search`, cfg);
            p.forEach(elem => ret.push({ label: elem.Representation, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    queryActivityType = async (field: any) => {
        const ret = [];
        if (!(this.Model))
            return ret;

        if (this.ReadOnly) {
            const elem = await this.apiService.get(`${this.ApiPrefix}/model/representations/ActivityType?id=${this.Model.ActivityType_ID}`);
            if (elem && elem.length > 0)
                ret.push({ label: elem[0].Representation, value: elem[0].Id });
            return ret;
        }
        if (!this.contextType && this.Model.ActivityType_ID) {
            const elem = await this.apiService.getModelById(ActivityType, `${this.ApiPrefix}/activity/activitytype`, this.Model.ActivityType_ID);
            ret.push({ label: elem.Bezeichnung1, value: elem.Id });
            return ret;
        }

        let p;
        if (!this.contextType)
            p = await this.apiService.getModelList(ActivityType, `${this.ApiPrefix}/activity/activitytype/getall`);
        else 
            p = await this.apiService.getModelList(ActivityType, `${this.ApiPrefix}/activity/activitytype/getforcontext?model_type=${this.contextType}`);

        p.forEach(elem => {
            ret.push({ label: elem.Bezeichnung1, value: elem.Id });
        });

        return ret.sort((a, b) => a.label > b.label ? 1 : -1);
    }

    queryStatusDef = async (field: any) => {
        const ret = [];
        if (!(this.Model))
            return ret;

        if (this.ReadOnly) {
            const elem = await this.apiService.get(`${this.ApiPrefix}/model/representations/CAFStatusDef?id=${this.Model.StatusDef_ID}`);
            if (elem && elem.length > 0)
                ret.push({ label: elem[0].Representation, value: elem[0].Id });
            return ret;
        }

        const p = await this.activityService.GetStatusWorkflowDefStatusWechsel(this.Model.Id);
        p.forEach(elem => {
            ret.push({ label: elem.AusgangStatus.Bezeichnung1, value: elem.AusgangStatus_ID });
        });

        if (this.Model.StatusDef_ID && !ret.some(w => w.value === this.Model.StatusDef_ID)) {
            const elem = await this.apiService.get(`${this.ApiPrefix}/model/representations/CAFStatusDef?id=${this.Model.StatusDef_ID}`);
            if (elem && elem.length > 0)
                ret.push({ label: elem[0].Representation, value: elem[0].Id });
        }

        return ret.sort((a, b) => a.label > b.label ? 1 : -1);
    }

    initFields() {
        const titelField = this.formFieldService.CreateText(nameof(c => c.Titel), 'CORE.COMMON.MODEL.ACTIVITY.TITEL', true).setMDSizeFull();
        this.Fields.push(titelField);
        titelField.CustomStyle = () => { return { 'font-weight': 'bold', 'font-size': 'medium' }; };

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.ActivityType_ID), 'CORE.COMMON.MODEL.ACTIVITY.ACTIVITYTYPE', this.queryActivityType, true).setMDSizeHalf());

        if (this.ReadOnly || this.InlineModus)
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.ActivityID), 'AktivitätsID').setReadonly().setMDSize(6));

        const priorityField = this.formFieldService.CreateListType(nameof(c => c.Priority), 'CORE.COMMON.MODEL.ACTIVITY.PRIORITY', 'activity_priority', true, null, null, this.ApiPrefix).setMDSizeHalf();
        priorityField.CustomTemplateDelegate = (item) => {
            if (item.value) {
                const icon = this.activityService.GetPriorityIcon(item.value);
                return `<div class="align-icon"><i class="mr-4p pi ${icon}"></i><span>${item.label}</span></div>`;
            }
            return item.label;
        };
        priorityField.CustomTemplateSelectedItemDelegate = priorityField.CustomTemplateDelegate;
        this.Fields.push(priorityField);

        // this.Fields.push(this.formFieldService.CreateTemplate(1, 'Status').setMDSizeHalf());

        const statusField = this.formFieldService.CreateDropdown(nameof(c => c.StatusDef_ID), 'CORE.COMMON.MODEL.ACTIVITY.STATUSDEF', this.queryStatusDef, true, false, '', (m) => (`${m.Bezeichnung1}`), 'Id', false, this.ReadOnly).setMDSizeHalf();
        statusField.CustomTemplateDelegate = (item) => {
            if (item.value) {
                return `<div><span class="status-badge status-${this.commonService.str2CSSName(item.label)}">${item.label}</span></div>`;
            }
            return item.label;
        };
        statusField.CustomTemplateSelectedItemDelegate = statusField.CustomTemplateDelegate;
        statusField.Readonly = this.ReadOnly;
        this.Fields.push(statusField);

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Owner_ID), 'CORE.COMMON.MODEL.ACTIVITY.OWNER', this.queryActivityAppUser, true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.AssignedTo_ID), 'CORE.COMMON.MODEL.ACTIVITY.ASSIGNEDTO', this.queryActivityAppUser, true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateRichText(nameof(c => c.DescriptionRTF), 'CORE.COMMON.MODEL.ACTIVITY.BESCHREIBUNG').setVisible(false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Description), 'CORE.COMMON.MODEL.ACTIVITY.BESCHREIBUNG').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.StartDate), 'CORE.COMMON.MODEL.ACTIVITY.STARTDATE', true).setMDSizeOneQuarter());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.DueDate), 'CORE.COMMON.MODEL.ACTIVITY.DUEDATE', true).setNullDateHigh().setMDSizeOneQuarter());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.RememberDate), 'CORE.COMMON.MODEL.ACTIVITY.REMEMBERDATE', false).setNullDateHigh().setMDSizeOneQuarter());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.EscalationDate), 'CORE.COMMON.MODEL.ACTIVITY.ESCALATIONDATE', false).setNullDateHigh().setMDSizeOneQuarter());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.FinishDate), 'CORE.COMMON.MODEL.ACTIVITY.FINISHDATE', false).setDateTime().setNullDateHigh().setReadonly(true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.FinishedBy_ID), 'CORE.COMMON.MODEL.ACTIVITY.FINISHEDBY', this.queryActivityAppUser, false).setReadonly(true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.ModelRef), 'CORE.COMMON.MODEL.ACTIVITY.MODELREF', false, 'Representation', 'Id', 2, null).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.ContextRef2), 'CORE.COMMON.MODEL.ACTIVITY.CONTEXTREF2', false, 'Representation', 'Id', 2, null).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.ContextRef3), 'CORE.COMMON.MODEL.ACTIVITY.CONTEXTREF3', false, 'Representation', 'Id', 2, null).setMDSizeFull());
    }

    deleteModel(model: any) {
        this.apiService.delete(`${this.ApiPrefix}/activity/${model.id}`).then(r => {
            if (this.RedirectAfterDelete)
                this.router.navigate([this.RedirectAfterDelete]);
        });
    }

    public async Save() {
        if (this.Model)
            await this.saveModel(this.Model);
    }

    async saveModel(model: Activity) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            delegate = this.apiService.put(`${this.ApiPrefix}/activity/${model.Id}`, model);
        } else {
            delegate = this.apiService.post(`${this.ApiPrefix}/activity`, model);
        }
        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.ref)
                this.ref.close(model);

            if (this.RedirectAfterSave)
                this.router.navigate([this.RedirectAfterSave]);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {
        this.commonService.showGlobalLoader();

        this.Model = await this.apiService.getModelById(ActivityVM, `${this.ApiPrefix}/activity`, id);

        this.Fields.find(c => c.Name === nameof(x => x.DescriptionRTF)).Visible = this.Model.IsRTF === true;
        this.Fields.find(c => c.Name === nameof(x => x.Description)).Visible = this.Model.IsRTF !== true;

        this.commonService.hideGlobalLoader();
        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ModelRef), false);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ContextRef2), false);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ContextRef3), false);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.ActivityType_ID), true);

        await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.ActivityType_ID)));
        await this.formFieldService.BuildDropdownDatasource(this.Fields.find(f => f.Name === nameof(c => c.StatusDef_ID)));

        await this.initFromActivityType(this.Model.ActivityType_ID);

        const isFinishedState = await this.activityService.IsFinishedState(this.Model.Id, this.Model.StatusDef_ID);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FinishDate), isFinishedState);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FinishedBy_ID), isFinishedState);

        // TODO: Berechtigungen Datumsfelder, Periodische Aktivitäten

        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model, true);

        const atype = this.Fields.find(w => w.Name === nameof(w => w.ActivityType_ID));
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ActivityType_ID), atype.DropdownDatasourceInternal?.length > 1);

		this.cdr.markForCheck();

        return this.Model;
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }
}
