import { VwbPVorlageDef } from '@avi-x/vwbp-intern';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviAuthService, AviBaseFormComponent, ObjectUtils, AviFormFieldService, typed_nameof, AviListDetailConst } from '@avi-x/avi-core';
import { AviDocArt } from '@avi-x/avi-dto/avidoc_def/avidocart.model';
import { AviDocTemplate } from '@avi-x/avi-dto/avidoc_def/avidoctemplate.model';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';

const nameof = (nameFunction: ((obj: VwbPVorlageDef) => any)) => typed_nameof<VwbPVorlageDef>(nameFunction);

@Component({
    selector: 'vwbp-admin-vorlagedef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons">

    </avi-core-base-form>`,
    styleUrls: ['./vorlagedef-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminVorlageDefFormComponent implements OnInit, OnDestroy {
    private _className = 'Vorlage Zuweisung';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: VwbPVorlageDef = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string {
        return this._ModelId;
    }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {
        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));
        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Format))
            this.setFromFormat();
        if (data.field === nameof(c => c.Berechtigung))
            this.setOpenWithDocHandler();

        if (data.field === nameof(c => c.Typ)) {
            this.setFromTyp();
            this.setOpenWithDocHandler();
        }
        
        if (data.field === nameof(c => c.KannAbgelegtWerden)) {
            this.setFromKannAbgelegtWerden();
            this.setOpenWithDocHandler();
        }

        if (data.field === nameof(c => c.TemplateLI_ID)) {
            if (this.Model.TemplateLI_ID) {
                const templates = await this.apiService.getModelList(AviDocTemplate, `v1/intern/vwbp/document/template/list?id=${this.Model.TemplateLI_ID}`);
                const template = templates[0];
                this.Model.Bezeichnung1 = template.Bezeichnung1;
                this.Model.Bezeichnung2 = template.Bezeichnung2;
                this.Model.Bezeichnung3 = template.Bezeichnung3;
                this.Model.Bezeichnung4 = template.Bezeichnung4;
                this.Model.Bezeichnung5 = template.Bezeichnung5;
                this.Model.Bezeichnung6 = template.Bezeichnung6;
                this.Model.Bezeichnung7 = template.Bezeichnung7;
            } else {
                this.Model.Bezeichnung1 = null;
                this.Model.Bezeichnung2 = null;
                this.Model.Bezeichnung3 = null;
                this.Model.Bezeichnung4 = null;
                this.Model.Bezeichnung5 = null;
                this.Model.Bezeichnung6 = null;
                this.Model.Bezeichnung7 = null;
            }
        } else if (data.field === nameof(c => c.Bezeichnung1)) {
            if (!this.Model.Bezeichnung2) this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
            if (!this.Model.Bezeichnung3) this.Model.Bezeichnung3 = this.Model.Bezeichnung1;
            if (!this.Model.Bezeichnung4) this.Model.Bezeichnung4 = this.Model.Bezeichnung1;
            if (!this.Model.Bezeichnung5) this.Model.Bezeichnung5 = this.Model.Bezeichnung1;
            if (!this.Model.Bezeichnung6) this.Model.Bezeichnung6 = this.Model.Bezeichnung1;
            if (!this.Model.Bezeichnung7) this.Model.Bezeichnung7 = this.Model.Bezeichnung1;
        }
    }

    public async initNewModel(data?: any) {
        const emptyGuid = this.commonService.GuidEmpty;
        this.Model = await this.apiService.getModel(VwbPVorlageDef, `v1/intern/vwbp/vorlagedef/${emptyGuid}`);
        this.isEditMode = false;

        this.setFromTyp();
        this.setFromFormat();
        this.setFromKannAbgelegtWerden();
        this.setOpenWithDocHandler();
    }

    ngOnInit() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.ModelId = params['id'];
            }
        });

        this.initFields();
        this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model, true);
    }

    deleteModel(model: any) {
        this.apiService.delete(`v1/intern/vwbp/vorlagedef/${model.id}`).then(r => {
            if (this.RedirectAfterDelete)
                this.router.navigate([this.RedirectAfterDelete]);
        });
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: VwbPVorlageDef) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            delegate = this.apiService.put(`/v1/intern/vwbp/vorlagedef/${model.Id}`, model);
        } else {
            delegate = this.apiService.post('/v1/intern/vwbp/vorlagedef', model);
        }
        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.RedirectAfterSave)
                this.router.navigate([this.RedirectAfterSave]);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {
        this.Model = await this.apiService.FindModel(VwbPVorlageDef, `v1/intern/vwbp/vorlagedef/${id}`, null, null, null);

        this.updateDropdownSources();
        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        this.setFromTyp();
        this.setFromFormat();
        this.setFromKannAbgelegtWerden();
        this.setOpenWithDocHandler();

        return this.Model;
    }

    setFromTyp() {
        if (this.Model) {
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Task), this.Model.Typ.Id === VwbPListDetailConst.VWBP_VORLAGE_TYP_TASK);

            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Berechtigung), this.Model.Typ.Id === VwbPListDetailConst.VWBP_VORLAGE_TYP_RECHTSTRAEGER_VIEW);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ViewVersion), this.Model.Typ.Id === VwbPListDetailConst.VWBP_VORLAGE_TYP_RECHTSTRAEGER_VIEW);
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.ViewEwrRegister), this.Model.Typ.Id === VwbPListDetailConst.VWBP_VORLAGE_TYP_RECHTSTRAEGER_VIEW);

            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.DocArt_ID), this.Model.Typ.Id === VwbPListDetailConst.VWBP_VORLAGE_TYP_TASK);

            const serienBriefTyp = this.Model.Typ.Id === VwbPListDetailConst.VWBP_VORLAGE_TYP_SERIENBRIEF;
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SerienbriefMoeglich), !serienBriefTyp);
            if (serienBriefTyp)
                this.Model.SerienbriefMoeglich = new ListType(AviListDetailConst.JA, 'janein', 'Ja');
        }
    }

    setFromFormat() {
        const isPDF = this.Model.Format.Id === VwbPListDetailConst.VWBP_VORLAGE_FORMAT_PDF;
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DigitalSignieren), !isPDF);
        if (!isPDF)
            this.Model.DigitalSignieren = new ListType(AviListDetailConst.NEIN, 'janein', 'Nein');
    }

    setFromKannAbgelegtWerden() {
        if (this.Model.KannAbgelegtWerden.Id === AviListDetailConst.NEIN && this.Model.DocArt_ID)
            this.Model.DocArt_ID = null;

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DocArt_ID), this.Model.KannAbgelegtWerden.Id === AviListDetailConst.NEIN);
    }

    setOpenWithDocHandler() {
        var allowed = this.Model.KannAbgelegtWerden.Id === AviListDetailConst.JA
            && this.Model.Typ.Id === VwbPListDetailConst.VWBP_VORLAGE_TYP_RECHTSTRAEGER_VIEW // TODO: PETER: eventuell auch Task!
            && this.Model.Berechtigung.Id === VwbPListDetailConst.VWBP_VORLAGE_BERECHTIGUNG_AJU; // nur AJU hat den aviDocHandler

        if (!allowed)
            this.Model.OpenWithDocHandler = new ListType(AviListDetailConst.NEIN, 'janein', 'Nein');
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.OpenWithDocHandler), !allowed)    
    }

    async updateDropdownSources() {
        if (this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.TemplateLI_ID), 'Vorlage Inland', `v1/intern/vwbp/document/template/list${this.ReadOnly ? '?id=$id' : ''}`, true, false, 'Template wählen...', (m) => (`${m.Bezeichnung1}`), 'Id', true, this.ReadOnly).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.TemplateAusland_ID), 'Vorlage Ausland', `v1/intern/vwbp/document/template/list${this.ReadOnly ? '?id=$id' : ''}`, true, false, 'Template wählen...', (m) => (`${m.Bezeichnung1}`), 'Id', true, this.ReadOnly).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'Bezeichnung1 (DE)', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'Bezeichnung2 (EN)', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Typ), 'Typ', 'vwbp_vorlage_typ', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Task), 'Task', 'vwbp_vorlage_task', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Berechtigung), 'Berechtigung', 'vwbp_vorlage_berechtigung', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ViewVersion), 'Anzeigen auf', 'vwbp_vorlage_viewversion', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ViewEwrRegister), 'VWBP.ADMIN.VORLAGEN.VIEW_EWR_REGISTER', 'janein', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Format), 'VWBP.ADMIN.VORLAGEN.DATEI_FORMAT', 'vwbp_vorlage_format', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DigitalSignieren), 'Digital signieren', 'janein', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Schwaerzen), 'Einschränkung der Offenlegung berücksichtigen', 'janein', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.SerienbriefMoeglich), 'Serienbrief möglich', 'janein', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.KannAbgelegtWerden), 'Kann abgelegt werden', 'janein', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());
        //this.Fields.push(this.formFieldService.CreateLabel('task_filler2', ' ').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.OpenWithDocHandler), 'VWBP.ADMIN.VORLAGEN.OPEN_WITH_DOCHANDLER', 'janein', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeHalf());

        const colDocArt = this.formFieldService.CreateDropdown(nameof(c => c.DocArt_ID), 'Ablage Dokumentenart',
        async (field) => {
            const ret = [];

            let path = `v1/intern/vwbp/document/docart/list${this.ReadOnly ? `?id=${this.Model.Id}` : ''}`;

            if (this.Model) {
                const id = this.Model ? ObjectUtils.getByPath(this.Model, field.Name) : null;

                if (id) {
                    path = path.replace(/\$id/, id);
                } else
                    return ret;
            }

            const p = await this.apiService.getModelList(AviDocArt, path);
            p.forEach(elem => ret.push({ label: elem.Bezeichnung1, value: elem.Id, tag: elem }));

            return ret.sort((a, b) => {
                if (a.tag.KeywordGruppeName && !b.tag.KeywordGruppeName)
                    return 1;
                else if (!a.tag.KeywordGruppeName && b.tag.KeywordGruppeName)
                    return -1;

                if (a.tag.KeywordGruppeName && b.tag.KeywordGruppeName) {
                    if (a.tag.KeywordGruppeName > b.tag.KeywordGruppeName)
                        return 1;
                    else if (a.tag.KeywordGruppeName < b.tag.KeywordGruppeName)
                        return -1;
                }

                if (a.tag.KeywordUntergruppeName && !b.tag.KeywordUntergruppeName)
                    return 1;
                else if (!a.tag.KeywordUntergruppeName && b.tag.KeywordUntergruppeName)
                    return -1;

                if (a.tag.KeywordUntergruppeName && b.tag.KeywordUntergruppeName) {
                    if (a.tag.KeywordUntergruppeName > b.tag.KeywordUntergruppeName)
                        return 1;
                    else if (a.tag.KeywordUntergruppeName < b.tag.KeywordUntergruppeName)
                        return -1;
                }

                return a.label > b.label ? 1 : -1;
            });
        },
        false, false, 'DocArt wählen...', (m) => (`${m.Bezeichnung1}`), 'Id', true, this.ReadOnly).setMDSizeFull();
        this.Fields.push(colDocArt);

        colDocArt.CustomTemplateDelegate = (item) => {
            const hasGruppe = true;
            const hasUntergruppe = false;

            if (hasGruppe || hasUntergruppe) {
                if (item.value) {
                    if (hasGruppe && hasUntergruppe)
                        return `<div class="row"><div class="col33p">${item.label}</div><div class="col33p">${item.tag.KeywordGruppeName}</div><div class="col33p">${item.tag.KeywordUntergruppeName}</div></div>`;
                    else if (hasGruppe && !hasUntergruppe)
                        return `<div class="row"><div class="col50p">${item.label}</div><div class="col50p">${item.tag.KeywordGruppeName}</div></div>`;
                    else if (!hasGruppe && hasUntergruppe)
                        return `<div class="row"><div class="col50p">${item.label}</div><div class="col50p">${item.tag.KeywordUntergruppeName}</div></div>`;
                }
            }
            return item.label;
        };
    }
}
