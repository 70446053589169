import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { AppComponent } from './app.component';
import { ToastrService } from 'ngx-toastr';
import { AviApiService, AviCommunicationService, AviCommonService, ObjectUtils, AviAuthService } from '@avi-x/avi-core';
import * as signalR from '@microsoft/signalr';

import { MitarbeiterNetworkDeviceStatus } from '@avi-x/avi-dto/presence/mitarbeiternetworkdevicestatus.model';
import { PersonalContactData } from '@avi-x/avi-dto/partner/personalcontactdata.model';
import { Router } from '@angular/router';


declare var jQuery: any;

@Component({
    selector: 'avi-vwbp-rightpanel',
    template: `
        <div class="layout-rightpanel" [ngClass]="{'layout-rightpanel-active': app.rightPanelActive}" (click)="app.onRightPanelClick()">


            <div #rightPanelMenuScroller class="nano">
                <div class="nano-content right-panel-scroll-content">

                <div class="p-2">

                </div>

                </div>
            </div>

        </div>
    `
})
export class AviVwbPRightPanelComponent implements AfterViewInit, OnDestroy {

    public ContactData: PersonalContactData[];


    private rightPanelMenuScroller: HTMLDivElement;

    @ViewChild('rightPanelMenuScroller', { static: false }) rightPanelMenuScrollerViewChild: ElementRef;

    constructor(public app: AppComponent,
        public toastrService: ToastrService,
        public apiService: AviApiService,
        private router: Router,
        public commonService: AviCommonService,
        public authService: AviAuthService,
        public communicationService: AviCommunicationService) {

            this.authService.UserChanged.subscribe(user => {
                if (user)
                    this.Init();
                else
                    this.ContactData = null;
            });


    }

    private async Init() {

        this.ContactData = await this.apiService.getModelList(PersonalContactData, '/personal/GetContactData');
    }

    public click2Call(data) {
        this.communicationService.StartCall(data);
    }

    public click2Email(data) {
        this.communicationService.StartEmail(data);
    }

    ngAfterViewInit() {
        this.rightPanelMenuScroller = <HTMLDivElement>this.rightPanelMenuScrollerViewChild.nativeElement;

        setTimeout(() => {
            jQuery(this.rightPanelMenuScroller).nanoScroller({ flash: true });
        }, 10);
    }

    ngOnDestroy() {
        jQuery(this.rightPanelMenuScroller).nanoScroller({ flash: true });
    }
}
