import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AviApiService } from '../../../services/api.service';
import { AviBaseFormComponent, AviChangedAttr, AviCommonService, AviFormField, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { SerializationDto } from '@avi-x/avi-dto/serialization/serialization.model';
import { FileSaverService } from 'ngx-filesaver';
import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';

const nameof = (nameFunction: ((obj: SerializationDto) => any)) => typed_nameof<SerializationDto>(nameFunction);

@Component({
    selector: 'avi-core-serialization-export',
    templateUrl: './serialization-export.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviSerializationExportComponent implements OnInit {

    public Loading: boolean = false;

    @Input('api-prefix')
    public ApiPrefix: string = '';

    private _SelectedSerialization: ReferenceDto;

    public get SelectedSerialization() {
        return this._SelectedSerialization;
    }

    public set SelectedSerialization(value) {
        this._SelectedSerialization = value;
    }

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    public SaveButtonLabel: string = 'Exportieren';

    public Model: SerializationDto = new SerializationDto();

    public File: File = null;

    public LogTotalCreated: string = null;
    public LogTotalUpdated: string = null;
    public LogTotalExported: string = null;

    public Fields: AviFormField[] = [];

    public Title: string = 'Serialization Export';

    public Card = true;

    public FormTitle: string = 'Model Serialization';

    constructor(
        private apiService: AviApiService,
        private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private formFieldService: AviFormFieldService,
        private fileSaverService: FileSaverService,
        private cdr: ChangeDetectorRef
    ) { 
    }


    async ngOnInit() {
        if ('apiprefix' in this.activatedRoute.snapshot.data)
            this.ApiPrefix = this.activatedRoute.snapshot.data['apiprefix'];

        this.initFields();

		await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Type), 'Typ', `${this.ApiPrefix ?? ''}/modelserialization/serializations`, true, true, 'Typ wählen...', (m) => (`${m.Representation}`), (m) => m, true, false, 'contains').setMDSizeFull());
    }

    async onAttrChange(data: AviChangedAttr) {
        this.LogTotalExported = null;
    }

    async saveModel() {
        try {
            this.commonService.showGlobalLoader();

            this.Loading = true;

            var response = await this.apiService.getBlobWithPost(`${this.ApiPrefix ?? ''}/modelserialization/export`, this.Model, null, null, 'response');

            if (response.body) {
                const fname = `${this.Model.Type.Representation}.xml`;
                this.fileSaverService.save(response.body, fname);

                var log = JSON.parse(response.headers.get('X-AVIITA-Data'));

                this.LogTotalExported = log?.TotalExported;
            }
            
            this.Loading = false;

            this.commonService.hideGlobalLoader();
            
            this.cdr.detectChanges();

        } catch (err) {
            this.commonService.hideGlobalLoader();
            
            this.Loading = false;

            this.cdr.detectChanges();

            throw err;
        }
    }

    ValidateData = () => {
        let valid = true;

        return valid;
    }

    getLogLabel(value: string): string {
        return value.split(',')[0];
    }
}
