import { AuthConfig } from 'angular-oauth2-oidc';

export abstract class AviAuthConfig extends AuthConfig {
    public storage?: string;
}

export abstract class AviCoreOAuthConfigs {
    [key: string]: AviAuthConfig;

}

export abstract class AviCoreConfig {
    public loginPath: string;
    public saveLoginUserInSessionStorage?: boolean;
    public signalRBasicHub?: string;
    public environment: any;
    public oAuthConfig?: AviCoreOAuthConfigs;

    public publicUrl?: string;
    public privateUrl?: string;

}
