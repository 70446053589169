import { Expose, Type } from 'class-transformer';

import { AppUser } from '../system/appuser.model';
import { AppUserGroup } from '../system/appusergroup.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class SysMessageDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get vonFreigabeAppUser_ID(): string { return this.getModelId('vonFreigabeAppUser_ID'); }
    public set vonFreigabeAppUser_ID(value: string) { this.setModelId('vonFreigabeAppUser_ID', value); }
 
    @Expose()
    @Type(() => AppUser)
    public get vonFreigabeAppUser(): AppUser { return this.getModel('vonFreigabeAppUser'); }
    public set vonFreigabeAppUser(value: AppUser) { this.setModel('vonFreigabeAppUser', value); }
    
    @Expose()
    public get vonFreigabeAppUserGroup_ID(): string { return this.getModelId('vonFreigabeAppUserGroup_ID'); }
    public set vonFreigabeAppUserGroup_ID(value: string) { this.setModelId('vonFreigabeAppUserGroup_ID', value); }
 
    @Expose()
    @Type(() => AppUserGroup)
    public get vonFreigabeAppUserGroup(): AppUserGroup { return this.getModel('vonFreigabeAppUserGroup'); }
    public set vonFreigabeAppUserGroup(value: AppUserGroup) { this.setModel('vonFreigabeAppUserGroup', value); }
    
    @Expose()
    @Type(() => ListType)
    public get SysMessageType(): ListType { return this.getProperty('SysMessageType'); }
    public set SysMessageType(value: ListType) { this.setProperty('SysMessageType', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AnzeigenBeiLogin(): ListType { return this.getProperty('AnzeigenBeiLogin'); }
    public set AnzeigenBeiLogin(value: ListType) { this.setProperty('AnzeigenBeiLogin', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Anzeigen1hVorBeginn(): ListType { return this.getProperty('Anzeigen1hVorBeginn'); }
    public set Anzeigen1hVorBeginn(value: ListType) { this.setProperty('Anzeigen1hVorBeginn', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Anzeigen15minVorBeginn(): ListType { return this.getProperty('Anzeigen15minVorBeginn'); }
    public set Anzeigen15minVorBeginn(value: ListType) { this.setProperty('Anzeigen15minVorBeginn', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Anzeigen5minVorBeginn(): ListType { return this.getProperty('Anzeigen5minVorBeginn'); }
    public set Anzeigen5minVorBeginn(value: ListType) { this.setProperty('Anzeigen5minVorBeginn', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Anzeigen2minVorBeginn(): ListType { return this.getProperty('Anzeigen2minVorBeginn'); }
    public set Anzeigen2minVorBeginn(value: ListType) { this.setProperty('Anzeigen2minVorBeginn', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Anzeigen30sVorBeginn(): ListType { return this.getProperty('Anzeigen30sVorBeginn'); }
    public set Anzeigen30sVorBeginn(value: ListType) { this.setProperty('Anzeigen30sVorBeginn', value); }
    
}
