import { OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { AviSessionControllerService, ISessionData } from '../../services/session-controller.service';
import { AviTabView } from '../controls/tabview/tabview.component';
import { AviApiService } from '../../services/api.service';
import { AviCommonService } from '../../services/common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'avi-job-index',
    templateUrl: './job-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJobIndexComponent implements OnInit, OnDestroy, OnChanges {  
    @Input('session-data')
    SessionData: ISessionData;

    @ViewChild('tabView')
    tabView: AviTabView;

    ModelId: string = null;

    sub: Subscription;

   constructor(public apiService: AviApiService,
        public activatedRoute: ActivatedRoute,
        public commonService: AviCommonService,
        public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) {
    }
	
    ngOnInit(): void {
        // this.sub = this.sessionController.sessionChanged$.subscribe(sessionId => {
        //     this.tabView.Active = sessionId === this.SessionData?.Id;
        // });

        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.setModel(params['id']);
                // this.sessionController.setCurrentSessionTitle();
            }
        });
    }
		
    ngOnDestroy(): void {
        if (this.sub)
            this.sub.unsubscribe();
    }
	
    ngOnChanges(changes: SimpleChanges) {
		if (this.SessionData?.ContextId) {
			this.ModelId = this.SessionData.ContextId;
            // this.sessionController.setCurrentSessionTitle();
            if (this.SessionData.Modul)
                setTimeout(() => this.tabView.selectTab(this.SessionData.Modul));
        }
    }

    setModel(modelId: string) {
        this.ModelId = modelId;
    }
}
