import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService } from '@avi-x/avi-core';
import { AviAppUserGroupFormComponent } from './components/appusergroup-form.component';
import { AviAppUserGroupSearcherComponent } from './components/appusergroup-searcher.component';
import { AviTechRoleFormComponent } from './components/techrole-form.component';
import { AviTechRoleSearcherComponent } from './components/techrole-searcher.component';
import { AviAppUserGroupLogEntrySearcherComponent } from './components/appusergrouplogentry-searcher.component';
import { AviAppUserGroupLogEntryFormComponent } from './components/appusergrouplogentry-form.component';

const routes: Routes = [
    { path: 'usergroup', component: AviAppUserGroupSearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    { path: 'usergroup/form/:id', component: AviAppUserGroupFormComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    { path: 'usergrouplog', component: AviAppUserGroupLogEntrySearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    { path: 'usergrouplog/form/:id', component: AviAppUserGroupLogEntryFormComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    { path: 'role', component: AviTechRoleSearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    { path: 'role/form/:id', component: AviTechRoleFormComponent, canActivate: [AviCanActivateWhenLoggedInService] }
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviSystemRoutingModule {
}