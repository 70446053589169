import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AviCommonService, AviBaseSearcherComponent, AviApiService, ISessionData, AviSessionControllerService } from '@avi-x/avi-core';
import { QueryDefVM } from '@avi-x/avi-dto/reporting/querydef-vm.model';

@Component({
    selector: 'avi-report-querydef-searcher',
    template: `
    
    <div class="mt-2">
        <avi-core-view-panel [toggleable]="false" [header]="'Abfragedefinitionen'" class="w-100p">

            <avi-core-base-searcher #searcher
                [use-router-params]="false"
                [search-delegate] = "'querydef'"
                [search-fields]   = "['bezeichnung1']"
                [card]            = "false"
                [rows]            = "20"
                [auto-search]     = "true"
                (onInit)          = "onInit()"
                (onDoubleClick)   = "onDoubleClick($event)"
                (onSelect)        = "onSelect($event)"

                searcher-title    = "Abfragedefinition suchen">

            </avi-core-base-searcher>
        </avi-core-view-panel>
    </div>`
})
export class AviQueryDefSearcherComponent {
    @Input('session-data')
    SessionData: ISessionData;

    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    constructor(public apiService: AviApiService, public sessionController: AviSessionControllerService, public commonService: AviCommonService) {
    }

    public onInit() {
        this.searcher.beginInit();

        this.searcher.addTextColumn('KurzBez1', 'KurzBez');
        this.searcher.addTextColumn('Bezeichnung1', 'Bezeichnung');

        this.searcher.endInit();
    }

    public onSelect(data) {
        // console.log('onSelect', data);
    }

    public onDoubleClick(data) {
        this.apiService.getModelById(QueryDefVM, `/querydef/cached/${data.Id}`, null)
            .then(w => {
                if (w.Darstellung.Id === '1939830e-4419-470b-b333-bc6389fb2b9b')
                    this.sessionController.openSession('querydef-exec-searcher', w.Id);
                else
                    this.sessionController.openSession('querydef-exec-graph', w.Id);
            });
    }
}
