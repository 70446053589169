import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AviCommonService } from './common.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(error) {
        // TODO(msc): Evtl. noch einen Logger-Service anbinden...
        const commonService = this.injector.get(AviCommonService);
        commonService.notificateError(error);
        // throw error;
    }
}
