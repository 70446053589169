import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared';

export class DocPropContainer extends BaseModel {  
    @Expose()
    public get Bezeichnung(): string { return this.getProperty('Bezeichnung'); }
    public set Bezeichnung(value: string) { this.setProperty('Bezeichnung', value); }
    
    @Expose()
    public get NodeName(): string { return this.getProperty('NodeName'); }
    public set NodeName(value: string) { this.setProperty('NodeName', value); }
    
    @Expose()
    public get Template(): string { return this.getProperty('Template'); }
    public set Template(value: string) { this.setProperty('Template', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ContainerTyp(): ListType { return this.getProperty('ContainerTyp'); }
    public set ContainerTyp(value: ListType) { this.setProperty('ContainerTyp', value); }    
}
