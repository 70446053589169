import { Expose, Type } from "class-transformer";
import { GenericRef, IDTO, ListType } from "../shared";

export class ScriptRuleOutputPruefenDto extends IDTO {
    @Expose()
    public RuleOutputId: string;
    
    @Expose()
    public RuleId: number;
    
    @Expose()
    public Message1: string;
    
    @Expose()
    public Vermerk: string;

    @Expose()
    @Type(() => ListType)
    public Status: ListType;     

    @Expose()
    public Kommentar: string;

    @Expose()
    @Type(() => GenericRef)
    public ModelRef: GenericRef;
}