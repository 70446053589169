<avi-core-base-form-designer *ngIf="EnableDesigner" #designer [(fields)]="Fields"> </avi-core-base-form-designer>

<div #mainContent>
<avi-core-page
    [title]="FormTitle | translate"
    [collapsible]="false"
    [card]="Card"
    [card-content-class]="CardContentClass"
    [contents-padding]="ContentsPadding"
    *ngIf="Model || DirectBinding"
    [style.display]="!HiddenForm && getVisibleFields().length > 0 ? 'block' : 'none'"
    [ngClass]="PageStyleClass"
>
    <ng-content select="[header]"></ng-content>

    <ng-container *ngIf="TaskMenuPosition === 'top'" ><ng-container *ngTemplateOutlet="taskMenuItems"></ng-container></ng-container>

    <p-steps *ngIf="HasRealSteps" (activeIndexChange)="onStepActiveIndexChange($event)" [readonly]="!StepsClickable" [model]="getStepsModel()" [activeIndex]="StepsActiveIndex"></p-steps>

    <div *ngIf="HasRealSteps && ShowStepNavigationTop" class="m-2 clearfix">
        <div style="width: 50%; float:left">
            <button pButton *ngIf="IsPrevStepVisible" type="button" [disabled]="!IsPrevStepEnabled" class="p-button  p-button-outlined p-button-primary" [icon]="PrevButtonIcon"
            (click)="goToPrevStep()" label="{{ PrevButtonLabel | translate }}"></button>
        </div>

        <div style="width: 50%; float:right" class="text-right">

        <button pButton *ngIf="IsNextStepVisible" type="button"  [disabled]="!IsNextStepEnabled" class="p-button p-button-outlined p-button-primary ml-2" [icon]="NextButtonIcon" iconPos="right"
            (click)="goToNextStep()" label="{{ NextButtonLabel | translate }}"></button>
        </div>
    </div>

    <div class="ui-g ui-fluid ui-g-nopad base-form-container" [ngClass]="{'form-inline' : InlineModus, 'form-readonly': ReadOnly }">
        <div *ngIf="getAllFieldErrors(null).length > 0 && (FieldErrorPosition === 'top' || FieldErrorPosition === 'both')" class="ui-g-12 mb-2 ui-g-nopad field-errors-container-top">
            <p-message *ngFor="let error of getAllFieldErrors('error')" severity="error" [text]="error.Message | translate" [escape]="false" styleClass="w-100p mb-2"></p-message>
            <p-message *ngFor="let error of getAllFieldErrors('warn')" severity="warn" [text]="error.Message | translate" [escape]="false" styleClass="w-100p mb-2"></p-message>
            <p-message *ngFor="let error of getAllFieldErrors('info')" severity="info" [text]="error.Message | translate" [escape]="false" styleClass="w-100p mb-2"></p-message>
        </div>

        <div *ngIf="FormMessages.length > 0" class="ui-g-12 mb-2 ui-g-nopad">
            <p-messages [(value)]="FormMessages" [escape]="false" [closable]="false"></p-messages>
        </div>

        <div class="ui-g-12 ui-lg-12 ui-g-nopad">
            <form autocomplete="off" name="lastpass-disable-search" (ngSubmit)="saveModel(form)" #form="ngForm">
                <!-- <button pButton *ngIf="ReadOnly" type="button" class="pull-right p-button-secondary p-button-flat" icon="pi pi-cog" (click)="Customize()"></button> -->

                <div *ngFor="let step of getSteps(false);let ii=index">
                    <!-- <i>{{step.MetaData.Index}}</i> STEP {{step.Label}} (NextGroupOrStepIndex: {{step.MetaData.NextGroupOrStepIndex}}, NextStepIndex: {{step.MetaData.NextStepIndex}}), NextGroupIndex: {{step.MetaData.NextGroupIndex}}) -->
                    <div *ngFor="let group of getGroupsForStep(step);let gi=index" [hidden]="ii !== StepsActiveIndex || !group.Visible"  [ngStyle]="getFieldStyle(group)">

                        <!-- <i>{{group.MetaData.Index}}</i> GROUP: <strong>{{group.Label}}</strong>  (NextGroupOrStepIndex: {{group.MetaData.NextGroupOrStepIndex}}, NextStepIndex: {{group.MetaData.NextStepIndex}}), NextGroupIndex: {{group.MetaData.NextGroupIndex}})
                        <div *ngFor="let field of getVisibleFieldsByIndexrange(group.MetaData.Index, group.MetaData.NextGroupOrStepIndex -1); let i = index" class="ml-4">
                            {{field.Label}} ({{field.Name}} / {{field.Type.toString()}})  (Index: {{field.MetaData.Index}})
                        </div> -->

                        <div *ngIf="group.GroupTemplate">
                            <div [ngSwitch]="group.Name">
                                <ng-content *ngSwitchCase="'groupTemplate1'" select="[groupTemplate1]"></ng-content>
                                <ng-content *ngSwitchCase="'groupTemplate2'" select="[groupTemplate2]"></ng-content>
                                <ng-content *ngSwitchCase="'groupTemplate3'" select="[groupTemplate3]"></ng-content>
                            </div>
                        </div>

                        <div *ngIf="!group.Name && !group.GroupTemplate" class="pt-2 pl-2 pr-2" >
                            <ng-container *ngTemplateOutlet="formFields; context: { $implicit: group }"></ng-container>
                        </div>

                        <div *ngIf="group.Name && !group.GroupTemplate">
                            <div class="p-2" [class.p-2]="ViewsPadding">
                                <avi-core-view-panel
                                    [header]="group.Label | translate"
                                    [toggleable]="group.GroupToggleable"
                                    [collapsed]="!group.GroupExpanded"
                                    toggler="header"
                                    [card-class]="group.GroupCardClass"
                                    expandIcon="pi pi-chevron-down"
                                    collapseIcon="pi pi-chevron-up"
                                    class="ui-g-12 ui-md-12"
                                    [no-pad]=true
                                >
                                    <div class="p-2">
                                        <ng-container *ngTemplateOutlet="formFields; context: { $implicit: group }"></ng-container>
                                    </div>
                                </avi-core-view-panel>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Hidden Fields -->
                <span *ngFor="let field of getInvisibleFields()">
                    <span class="md-inputfield">
                        <input
                            [ngModel]="GetField(field.Name)"
                            (ngModelChange)="SetField(field.Name, $event)"
                            [required]="field.Required"
                            [name]="field.Name"
                            type="hidden"
                            (change)="formAttrChange(field.Name, $event.target['value'])"
                        />
                    </span>
                </span>
                <!-- / Hidden Fields -->

                <div class="ui-g-12 p-0 ui-g-nopad">
                    <ng-content select="[beforeButtons]"></ng-content>
                </div>

                <div *ngIf="HasRealSteps && ShowStepNavigationBottom" class="m-2">
                    <div class="clearfix">
                        <div style="width: 50%; float:left">
                            <button pButton *ngIf="IsPrevStepVisible" [tabindex]="9500" type="button" [disabled]="!IsPrevStepEnabled" class="p-button p-button-outlined p-button-primary" [icon]="PrevButtonIcon"
                                (click)="goToPrevStep()" label="{{ PrevButtonLabel | translate }}">
                            </button>

                            <span *ngIf="!ReadOnly && ShowButtons">
                                <avi-core-actionbuttons [ngClass]="{ 'ml-2': IsPrevStepVisible }" [action-buttons]="actionButtons"></avi-core-actionbuttons>
                            </span>

                        </div>
                        <div style="width: 50%; float:right" class="text-right">

                            <!-- <span *ngIf="!ReadOnly && ShowButtons">
                                <avi-core-actionbuttons [ngClass]="{ 'mr-2': IsPrevStepVisible }" [action-buttons]="actionButtons"></avi-core-actionbuttons>
                            </span> -->

                            <button pButton *ngIf="IsNextStepVisible" type="button"  [disabled]="!IsNextStepEnabled" class="p-button p-button-outlined p-button-primary ml-2" [icon]="NextButtonIcon" iconPos="right"
                                (click)="goToNextStep()"  [tabindex]="9900" label="{{ NextButtonLabel | translate }}">
                            </button>

                            <span *ngIf="IsLastStep && !ReadOnly && ShowButtons" class="ui-g-12 ui-md-12 pt-{{ ButtonsPadding }}  mb-3" [ngClass]="{'form-buttons-right': true}">
                                <button
                                    [disabled]="!form.form.valid || Saving || (CustomValidationDelegate != null && !CustomValidationDelegate())"
                                    [tabindex]="9950"
                                    [icon]="SaveButtonIcon"
                                    pButton
                                    type="submit"
                                    class="p-button-primary"
                                    label="{{ SaveButtonLabel | translate }}"
                                    title="{{ SaveButtonLabel | translate }}"
                                ></button>
                            </span>
                        </div>
                    </div>
                    <!-- <div *ngIf="IsLastStep && !ReadOnly && ShowButtons" class="ui-g-12 ui-md-12 pt-{{ ButtonsPadding }}  mb-3" [ngClass]="{'form-buttons-right': ButtonsAlignment === 'right'}">
                        <button
                            [disabled]="!form.form.valid || Saving || (CustomValidationDelegate != null && !CustomValidationDelegate())"
                            [icon]="SaveButtonIcon"
                            pButton
                            type="submit"
                            class="p-button-primary"
                            label="{{ SaveButtonLabel | translate }}"
                            title="{{ SaveButtonLabel | translate }}"
                        ></button>

                        <avi-core-actionbuttons class="ml-2" [action-buttons]="actionButtons"></avi-core-actionbuttons>
                    </div>

                    <div *ngIf="!IsLastStep && !ReadOnly && ShowButtons">
                        <avi-core-actionbuttons [action-buttons]="actionButtons"></avi-core-actionbuttons>
                    </div>
                     -->
                </div>

                <div *ngIf="!HasRealSteps && !ReadOnly && ShowButtons" class="ui-g-12 ui-md-12 pt-{{ ButtonsPadding }} mx-2 mb-3 " [ngClass]="{'form-buttons-right': ButtonsAlignment === 'right', 'flex flex-row-reverse': ButtonsInvert}">
                    <button
                        [disabled]="!form.form.valid || Saving || (CustomValidationDelegate != null && !CustomValidationDelegate())"
                        [icon]="SaveButtonIcon"
                        pButton
                        type="submit"
                        class="p-button-primary"
                        label="{{ SaveButtonLabel | translate }}"
                        title="{{ SaveButtonLabel | translate }}"
                    ></button>

                    <avi-core-actionbuttons class="ml-2" [action-buttons]="actionButtons"></avi-core-actionbuttons>
                </div>

                <div *ngIf="ReadOnly && ShowButtons && actionButtonsReadonly.length > 0" class="ui-g-12 ui-md-12 pt-{{ ButtonsPadding }} " [ngClass]="{'form-buttons-right': ButtonsAlignment === 'right'}">
                    <avi-core-actionbuttons [action-buttons]="actionButtonsReadonly"></avi-core-actionbuttons>
                </div>

                <ng-template let-group #formFields>
                    <div *ngIf="getVisibleFieldsByIndexrange(group.MetaData.Index + 1, group.MetaData.NextGroupOrStepIndex -1).length > 0" class="grid p-fluid form-group ui-g-12 ui-md-12 {{ group.GroupClass }}">
                        <div
                            *ngFor="let field of getVisibleFieldsByIndexrange(group.MetaData.Index + 1, group.MetaData.NextGroupOrStepIndex -1); let i = index"
                            class="col-12 md:col-{{ field.MDSize }} {{ field.FieldClass }} field-container-{{field.Name?.replace('.','-')}}"
                            [ngClass]="{
                                'field': this.LabelAlignment === 'left',
                                'grid': this.LabelAlignment === 'left',
                                'grid-nogutter': this.LabelAlignment === 'left',
                                'form-label-left': this.LabelAlignment === 'left',
                                'align-self-end': field.Type === FormFieldType.BUTTON,
                                'field-button-container': field.Type === FormFieldType.BUTTON
                            }"
                        >
                            <label
                                *ngIf="field.LabelVisible"
                                class="{{ field.LabelClass }}"
                                [ngClass]="{
                                    'label-required': (field.Required || field.NoHighLowDate) && !ReadOnly && (!field.NullDate || field.NoHighLowDate),
                                    'label-nonmd': field.Type !== FormFieldType.LABEL,
                                    'label-bold': field.LabelBold,
                                    'form-field': field.Type === FormFieldType.LABEL,
                                    'col-fixed': LabelAlignment === 'left',
                                    'label-align-right': field.LabelTextAlignment === 'right'
                                }"
                                [ngStyle]="{'width': field.Type === FormFieldType.LABEL ? field.LabelWidth ?? 'fit-content' : (LabelAlignment === 'left' ? field.LabelWidth ?? LabelWidth : 'auto') }"
                            >
                                <ng-container>
                                    <span *ngIf="field.LabelLiteral && field.Label != null" [innerHtml]="field.Label | translate | safeHtml"> </span>
                                    <avi-textblock-span *ngIf="!field.LabelLiteral && field.Label != null" [identifier]="field.Label" [disable-html]="true"></avi-textblock-span>

                                    <!-- <a *ngIf="field.Hinweis" class="info-icon" pRipple (click)="PopupHinweis($event, field.Hinweis)"><i class="ml-2 pi pi-info-circle {{ field.HinweisClass }}"></i></a> -->
                                    <!-- <a *ngIf="field.Hinweis" class="info-icon" pRipple [pTooltip]="field.Hinweis" [escape]="false"><i class="ml-2 pi pi-info-circle"></i></a> -->
                                </ng-container>
                            </label>

                            <a *ngIf="field.LabelVisible && (field.Hinweis || field.HinweisTextblock)" class="info-icon" pRipple (click)="PopupHinweis($event, field.Hinweis, field.HinweisTextblock)"><i class="ml-2 pi pi-info-circle {{ field.HinweisClass }}"></i></a>

                            <div
                                [ngSwitch]="field.Type"
                                [ngClass]="{
                                    'field-readonly': field.Readonly || ReadOnly,
                                    'col': LabelAlignment === 'left'
                                }"
                            >
                                <span class="form-field" *ngSwitchCase="FormFieldType.LABEL"></span>
                                <span class="form-field" *ngSwitchCase="FormFieldType.EMPTY"></span>

                                <div class="p-field form-field" *ngSwitchCase="FormFieldType.TEXT">
                                    <span class="p-inputgroup">
                                        <span *ngIf="field.Prefix" class="p-inputgroup-addon {{ field.PrefixClass }}" readonly>{{ field.Prefix }}</span>
                                        <input
                                            pInputText
                                            [readonly]="field.Readonly || ReadOnly"
                                            [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                            [ngModel]="GetField(field.Name)"
                                            [ngStyle]="getFieldStyle(field)"
                                            [ngClass]="getFieldCustomClass(field)"
                                            [placeholder]="field.Placeholder | translate"
                                            [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                            [disabled]="field.Disabled && !ReadOnly"
                                            (ngModelChange)="SetField(field.Name, $event, true)"
                                            [required]="field.Required"
                                            [name]="field.Name"
                                            [attr.name]="field.Name"
                                            [maxlength]="field.MaxLength"
                                            [pattern]="field.Pattern"
                                            [phoneNumber]="!(field.Readonly || ReadOnly) && field.IsPhoneNumber"
                                            [type]="field.TextType"
                                            [autocomplete]="field.TextAutocompleteType"
                                            (change)="formAttrChange(field.Name, $event.target['value'])"
                                            (keydown.enter)="onKeyDownEvent(field, $event)"
                                            (blur)="formAttrBlur(field.Name)"
                                        />
                                        <span *ngIf="field.Suffix" class="p-inputgroup-addon {{ field.SuffixClass }}">{{ field.Suffix }}</span>
                                        <button *ngIf="field.ButtonEvent" type="button" pButton class="" [ngClass]="field.ButtonClass ?? 'p-inputgroup-addon p-button-secondary'" [icon]="field.ButtonIcon" [disabled]="field.ButtonDisabled || field.Readonly || ReadOnly" [label]="field.ButtonLabel | translate" (click)="field.ButtonEvent(field, $event)"></button>
                                    </span>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                    >
                                        <ng-container *ngIf="GetField(field.Name)?.length > 0; else pflichfeld">
                                            Ungültige Eingabe
                                        </ng-container>
                                        <ng-template #pflichfeld>
                                            Pflichtfeld
                                        </ng-template>
                                    </span>
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </div>

                                <span class="form-field" *ngSwitchCase="FormFieldType.INPUTMASK">
                                    <p-inputMask
                                        [readonly]="field.Readonly || ReadOnly"
                                        [tabindex]="field.Readonly || ReadOnly ? '-1' : '0'"
                                        [mask]="field.Mask"
                                        [placeholder]="field.Placeholder | translate"
                                        [ngModel]="GetField(field.Name)"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        (ngModelChange)="SetField(field.Name, $event, true)"
                                        [required]="field.Required"
                                        [name]="field.Name"
                                        (change)="formAttrChange(field.Name, $event.target['value'])"
                                        (onBlur)="formAttrBlur(field.Name)"
                                    >
                                    </p-inputMask>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.RICHTEXT">
                                    <!-- <label [ngClass]="{'label-required':field.Required && !ReadOnly, 'label-nonmd':true}">{{field.Label}}</label> -->
                                    <avi-core-richtexteditor
                                        #richtextEditors
                                        [id]="field.Name"
                                        [required]="field.Required"
                                        [name]="field.Name"
                                        [enable-tribute]="field.EnableTribute"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [ngModel]="GetField(field.Name)"
                                        (ngModelChange)="SetField(field.Name, $event, true)"
                                        [readonly]="field.Readonly || ReadOnly"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [toolbar-modus]="InlineModus ? 'onfocus': 'normal'"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        (onBlur)="formAttrBlur(field.Name)"
                                    >
                                    </avi-core-richtexteditor>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.TEXT_QUILL">
                                    <p-editor
                                        [id]="field.Name"
                                        [name]="field.Name"
                                        [required]="field.Required"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [ngModel]="GetField(field.Name)"
                                        (ngModelChange)="SetField(field.Name, $event, true)"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [readonly]="field.Readonly || ReadOnly"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [style]="{'height':'45vh'}">
                                        <p-header>
                                            <span class="ql-formats">
                                                <button class="ql-bold"
                                                    aria-label="Fett"></button>
                                                <button class="ql-italic"
                                                    aria-label="Kursiv"></button>
                                                <button class="ql-underline"
                                                    aria-label="Unterstrichen"></button>
                                                <button class="ql-strike"
                                                    aria-label="Durchgestrichen"></button>
                                                <select class="ql-size"
                                                    aria-label="Size"></select>
                                            </span>

                                            <span class="ql-formats">
                                                <button class="ql-list"
                                                    value="ordered"
                                                    aria-label="Numerierte Liste"></button>
                                                <button class="ql-list"
                                                    value="bullet"
                                                    aria-label="Liste"></button>
                                                <button class="ql-indent"
                                                    value="-1"
                                                    aria-label="Einzug verkleinern"></button>
                                                <button class="ql-indent"
                                                    value="+1"
                                                    aria-label="Einzug vergrössern"></button>
                                            </span>
                                            <!--
                                            <span class="ql-formats">
                                                <select class="ql-color"
                                                    aria-label="Farbe"></select>
                                                <select class="ql-background"
                                                    aria-label="Hintergrund"></select>
                                            </span> -->

                                            <span class="ql-formats">
                                                <button class="ql-link"
                                                    aria-label="Link"></button>
                                                <button class="ql-script"
                                                    value="sub"
                                                    aria-label="Link"></button>
                                                <button class="ql-script"
                                                    value="super"
                                                    aria-label="Link"></button>
                                            </span>

                                            <span class="ql-formats">
                                                <button class="ql-align"
                                                    aria-label="Links ausrichten"></button>
                                                <button class="ql-align"
                                                    value="center"
                                                    aria-label="Zentriert ausrichten"></button>
                                                <button class="ql-align"
                                                    value="right"
                                                    aria-label="Rechts ausrichten"></button>
                                                <button class="ql-align"
                                                    value="justify"
                                                    aria-label="Blocksatz"></button>
                                                <button class="ql-clean"
                                                    aria-label="Link"></button>
                                            </span>

                                        </p-header>
                                    </p-editor>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.NUMBER">
                                    <span class="p-inputgroup">
                                        <button *ngIf="field.ButtonEvent" type="button" pButton class="p-button-secondary" [icon]="field.ButtonIcon" [label]="field.ButtonLabel | translate" (click)="field.ButtonEvent(field, $event)"></button>

                                        <p-inputNumber
                                            [id]="field.Name"
                                            [readonly]="field.Readonly || ReadOnly"
                                            [tabindex]="field.Readonly || ReadOnly ? '-1' : '0'"
                                            [ngModel]="GetField(field.Name)"
                                            [inputStyle]="getFieldStyle(field)"
                                            [inputStyleClass]="'p-inputtext'"
                                            [ngClass]="getFieldCustomClass(field)"
                                            [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                            [placeholder]="field.Placeholder | translate"
                                            [disabled]="field.Disabled && !ReadOnly"
                                            (ngModelChange)="SetField(field.Name, $event, true)"
                                            [required]="field.Required"
                                            [name]="field.Name"
                                            (change)="formAttrChange(field.Name, $event.target['value'])"
                                            (onBlur)="formAttrBlur(field.Name)"
                                            [minFractionDigits]="field.PrecisionMin"
                                            [maxFractionDigits]="field.PrecisionMax"
                                            [prefix]="field.Prefix"
                                            [suffix]="field.Suffix"
                                            [min]="field.MinValue"
                                            [max]="field.MaxValue"
                                            mode="decimal"
                                            [useGrouping]="field.UseSeparators"
                                            locale="de-CH"
                                            [pTooltip]="getFieldErrorString(field)" [escape]="false" [showDelay]="500" tooltipStyleClass="autofit-tooltip"
                                        ></p-inputNumber>
                                        <span *ngIf="field.InputAddonText !== null" class="p-inputgroup-addon">{{field.InputAddonText}}</span>
                                    </span>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.PERCENTAGE">
                                    <p-inputNumber
                                        [tabindex]="field.Readonly || ReadOnly ? '-1' : '0'"
                                        [ngModel]="GetField(field.Name)"
                                        [inputStyle]="getFieldStyle(field)"
                                        [inputStyleClass]="'p-inputtext'"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [placeholder]="field.Placeholder | translate"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        (ngModelChange)="SetField(field.Name, $event, true)"
                                        [required]="field.Required"
                                        [name]="field.Name"
                                        (change)="formAttrChange(field.Name, $event.target['value'])"
                                        (onBlur)="formAttrBlur(field.Name)"
                                        [minFractionDigits]="field.PrecisionMin"
                                        [maxFractionDigits]="field.PrecisionMax"
                                        [min]=0
                                        [max]=100
                                        mode="decimal"
                                        locale="de-CH"
                                        suffix="%"
                                    ></p-inputNumber>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.CURRENCY">
                                    <div class="p-inputgroup">

                                        <p-inputNumber
                                            [tabindex]="field.Readonly || ReadOnly ? '-1' : '0'"
                                            [ngModel]="GetField(field.Name)"
                                            [inputStyle]="getFieldStyle(field)"
                                            [inputStyleClass]="'p-inputtext'"
                                            [ngClass]="getFieldCustomClass(field)"
                                            [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                            [placeholder]="field.Placeholder | translate"
                                            [disabled]="field.Disabled && !ReadOnly"
                                            [readonly]="field.Readonly || ReadOnly"
                                            (ngModelChange)="SetField(field.Name, $event, true)"
                                            [required]="field.Required"
                                            [name]="field.Name"
                                            (change)="formAttrChange(field.Name, $event.target['value'])"
                                            (onBlur)="formAttrBlur(field.Name)"
                                            [minFractionDigits]="field.PrecisionMin"
                                            [maxFractionDigits]="field.PrecisionMax"
                                            mode="currency"
                                            locale="de-CH"
                                            [currency]="field.Currency"
                                        ></p-inputNumber>

                                    <!-- <span class="p-inputgroup-addon">{{field.Currency}}</span> -->
                                    </div>

                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.DATE">
                                    <span class="p-inputgroup">
                                        <span class="title-spacer">
                                            <avi-core-calendar
                                                #calendar
                                                appendTo="body"
                                                [baseZIndex]="10000"
                                                [ngModel]="GetField(field.Name)"
                                                [required]="field.Required"
                                                (onInput)="onInputDate($event, calendar)"
                                                (ngModelChange)="SetField(field.Name, $event, true)"
                                                (onBlur)="formAttrBlur(field.Name)"
                                                [ngStyle]="getFieldStyle(field)"
                                                [ngClass]="getFieldCustomClass(field)"
                                                [disabled]="field.Disabled && !ReadOnly"
                                                [firstDayOfWeek]="1"
                                                [placeholder]="field.Placeholder | translate"
                                                [focus-calendar]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                                [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                                [dateFormat]="field.DateFormat"
                                                [dataType]="field.DataType"
                                                [dateView]="field.DateView"
                                                [defaultDate]="field.DateDefaultValue"
                                                [showIcon]="!(field.Readonly || ReadOnly || field.Disabled)"
                                                [required]="field.Required"
                                                [readonlyInput]="field.Readonly || ReadOnly"
                                                [showOnFocus]="false"
                                                [showTime]="field.DateTime"
                                                [name]="field.Name"
                                                [minDate]="field.DateMinValue"
                                                [maxDate]="field.DateMaxValue"
                                                [noHighLowDate]="field.NoHighLowDate"
                                                [nullDate]="field.NullDate"
                                            >
                                            </avi-core-calendar>
                                        </span>
                                        <div *ngIf="AutoBtnDatepair && field.DateRangeButtons &&
                                            !(field.Readonly || ReadOnly || field.Disabled)" (click)="onDatumDecrease(field.Name)" class="p-inputgroup-addon"><i class="pi-dark pi pi-chevron-left"></i></div>
                                        <div *ngIf="AutoBtnDatepair && field.DateRangeButtons &&
                                            !(field.Readonly || ReadOnly || field.Disabled)" (click)="onDatumIncrease(field.Name)" class="p-inputgroup-addon"><i class="pi-dark pi pi-chevron-right"></i></div>
                                    </span>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                    >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.TEXTAREA">
                                    <!-- <label [ngClass]="{'label-required':field.Required && !ReadOnly, 'label-nonmd':true}">{{field.Label}}</label> -->
                                    <textarea
                                        [rows]="field.Readonly || ReadOnly || field.Disabled ? 1 : (LabelAlignment === 'left' ? 1 : 5)"
                                        [readonly]="field.Readonly || ReadOnly"
                                        [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                        cols="30"
                                        aviInputTextarea
                                        [ngModel]="GetField(field.Name)"
                                        (ngModelChange)="SetField(field.Name, $event, true)"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [placeholder]="field.Placeholder | translate"
                                        [autoResize]="true"
                                        [required]="field.Required"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [maxlength]="field.MaxLength"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [name]="field.Name"
                                        [attr.name]="field.Name"
                                        (change)="formAttrChange(field.Name, $event.target['value'])"
                                        (blur)="formAttrBlur(field.Name)"
                                    >
                                    </textarea>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field form-field-{{field.Name}}" *ngSwitchCase="FormFieldType.RADIO">

                                    <div *ngFor="let o of field.Options" class="mb-1">
                                        <p-radioButton
                                         [name]="field.Name" [value]="o.Value" [label]="o.Label | translate"
                                            [ngModel]="GetField(field.Name)"
                                            [disabled]="field.Disabled || ReadOnly"
                                            [required]="field.Required"
                                            (ngModelChange)="SetField(field.Name, $event, true)"
                                        ></p-radioButton>
                                    </div>

                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.BUTTON">
                                    <span class="p-inputgroup">
                                        <button type="button" pButton [class]="field.ButtonClass" [tabindex]="field.Readonly || ReadOnly ? -1 : 0" [icon]="field.ButtonIcon" [label]="field.ButtonLabel | translate" [disabled]="field.Disabled || ReadOnly" (click)="field?.ButtonEvent(field, $event)"></button>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.TEXTBLOCK">
                                    <div class="flex align-items-center">
                                        <div class="flex-grow-1">
                                            <div *ngIf="field.TextblockText" [ngClass]="{'label-required': field.Required && !ReadOnly}" [innerHTML] ="field.TextblockText | translate"></div>
                                            <avi-textblock *ngIf="field.TextblockIdentifier" [classes]="field.Required && !ReadOnly ? 'label-required' : ''" [identifier]="field.TextblockIdentifier" [params]="field.TextblockParams"></avi-textblock>
                                        </div>
                                        <div *ngIf="!field.LabelVisible && (field.Hinweis || field.HinweisTextblock)"><a class="info-icon" pRipple (click)="PopupHinweis($event, field.Hinweis, field.HinweisTextblock)"><i class="ml-2 pi pi-info-circle {{ field.HinweisClass }}"></i></a></div>
                                    </div>

                                    <div class="mt-2">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </div>


                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.TIME">
                                    <!-- <label [ngClass]="{'label-required':field.Required && !ReadOnly, 'label-nonmd':true}">{{field.Label}}</label> -->
                                    <p-calendar
                                        #time
                                        [ngModel]="GetField(field.Name)"
                                        (onInput)="onInputTime($event, time)"
                                        [required]="field.Required"
                                        (ngModelChange)="SetField(field.Name, $event, true)"
                                        (onBlur)="formAttrBlur(field.Name)"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        (onSelect)="formAttrChange(field.Name, $event)"
                                        [placeholder]="field.Placeholder | translate"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [timeOnly]="true"
                                        [firstDayOfWeek]="1"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        dateFormat="HH:mm"
                                        [showIcon]="!(field.Readonly || ReadOnly || field.Disabled)"
                                        [required]="field.Required"
                                        [showOnFocus]="false"
                                        [name]="field.Name"
                                    >
                                    </p-calendar>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field form-field-checkbox" *ngSwitchCase="FormFieldType.CHECKBOX">
                                    <br *ngIf="field.Label === ' '" />
                                    <p-checkbox
                                        [ngModel]="GetField(field.Name)"
                                        [readonly]="field.Readonly || ReadOnly"
                                        [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                        (ngModelChange)="SetField(field.Name, $event, true); formAttrBlur(field.Name)"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        (click)="onCheckboxChange(field)"
                                        [required]="field.Required"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        label="{{ field.CheckboxText | translate }}"
                                        [name]="field.Name"
                                        [binary]="true"
                                        [trueValue]="field.TrueValue"
                                        [falseValue]="field.FalseValue"
                                    >
                                    </p-checkbox>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.TOGGLEBUTTON">
                                    <br *ngIf="field.Label === ' '" />
                                    <p-toggleButton
                                        [ngModel]="GetField(field.Name)"
                                        [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                        (ngModelChange)="SetField(field.Name, $event, true)"
                                        (onBlur)="formAttrBlur(field.Name)"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [required]="field.Required"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        onLabel="{{ field.CheckboxText | translate }}"
                                        offLabel="{{ field.CheckboxText | translate }}"
                                        [onIcon]="field.IconOn"
                                        [offIcon]="field.IconOff"
                                        [iconPos]="field.IconPos"
                                        [name]="field.Name"
                                    >
                                    </p-toggleButton>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <div class="p-field form-field" *ngSwitchCase="FormFieldType.AUTOCOMPLETE">
                                    <p-autoComplete
                                        #dd
                                        appendTo="body"
                                        type="search"
                                        [delay]="150"
                                        [baseZIndex]="10000"
                                        [readonly]="field.Readonly || ReadOnly"
                                        [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [minLength]="field.AutocompleteMinChars"
                                        [placeholder]="field.Placeholder | translate"
                                        [autoHighlight]="dd.inputEL?.nativeElement?.value?.length > 0"
                                        [dropdown]="!field.Readonly && !ReadOnly"
                                        [ngModel]="GetField(field.AutocompleteField)"
                                        (onSelect)="SetAutocomplete(field, $event)"
                                        (onClear)="ClearAutocomplete(field)"
                                        field="label"
                                        [name]="field.Name"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [required]="field.Required"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [suggestions]="field.DropdownDatasourceInternal"
                                        (completeMethod)="autoComplete(field, $event)"
                                        (onBlur)="formAttrBlur(field.Name)"
                                        [forceSelection]="true"
                                    >
                                        <ng-template *ngIf="field.CustomTemplateSelectedItemDelegate" let-item pTemplate="selectedItem">
                                            <div [innerHTML]="field.CustomTemplateSelectedItemDelegate(item)"></div>
                                        </ng-template>
                                        <ng-template *ngIf="field.CustomTemplateDelegate" let-car pTemplate="item">
                                            <div [innerHTML]="field.CustomTemplateDelegate(car)"></div>
                                        </ng-template>
                                        <ng-template *ngIf="field.CustomTemplateHeaderDelegate" pTemplate="header">
                                            <div [innerHTML]="field.CustomTemplateHeaderDelegate()"></div>
                                        </ng-template>
                                        <ng-template *ngIf="field.CustomTemplateFooterDelegate" pTemplate="footer">
                                            <div [innerHTML]="field.CustomTemplateFooterDelegate()"></div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </div>

                                <div class="p-field form-field" *ngSwitchCase="FormFieldType.AUTOCOMPLETE_TYPELESS">
                                    <p-autoComplete
                                        #dd
                                        appendTo="body"
                                        type="text"
                                        [delay]="150"
                                        [baseZIndex]="10000"
                                        [readonly]="field.Readonly || ReadOnly"
                                        [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [minLength]="field.AutocompleteMinChars"
                                        [placeholder]="field.Placeholder | translate"
                                        [autoHighlight]="dd.inputEL?.nativeElement?.value?.length > 0 && !field.AutocompleteForceSelection"
                                        [dropdown]="!field.Readonly && !ReadOnly"
                                        [ngModel]="GetField(field.Name)"
                                        (ngModelChange)="SetAutocompleteModel(field, $event)"
                                        (onSelect)="SetAutocomplete(field, $event)"
                                        (onClear)="ClearAutocomplete(field)"
                                        [field]="field.DropdownDisplayField"
                                        [name]="field.Name"
                                        [focus]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [required]="field.Required"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [suggestions]="field.DropdownDatasourceInternal"
                                        (completeMethod)="autoComplete(field, $event)"
                                        (onBlur)="formAttrBlur(field.Name)"
                                        [forceSelection]="field.AutocompleteForceSelection"
                                    >
                                        <ng-template let-item pTemplate="selectedItem">
                                            <div *ngIf="field.CustomTemplateSelectedItemDelegate; else noSelectedItemTemplate" [innerHTML]="field.CustomTemplateSelectedItemDelegate(item)">asdfasdf</div>
                                            <ng-template #noSelectedItemTemplate>{{getAutocompleteDisplayValue(field, item)}}</ng-template>
                                        </ng-template>
                                        <ng-template let-item pTemplate="item">
                                            <div  *ngIf="field.CustomTemplateDelegate; else noItemTemplate" [innerHTML]="field.CustomTemplateDelegate(item)"></div>
                                            <ng-template #noItemTemplate>{{getAutocompleteDisplayValue(field, item)}}</ng-template>
                                        </ng-template>
                                        <ng-template *ngIf="field.CustomTemplateHeaderDelegate" pTemplate="header">
                                            <div [innerHTML]="field.CustomTemplateHeaderDelegate()"></div>
                                        </ng-template>
                                        <ng-template *ngIf="field.CustomTemplateFooterDelegate" pTemplate="footer">
                                            <div [innerHTML]="field.CustomTemplateFooterDelegate()"></div>
                                        </ng-template>
                                    </p-autoComplete>
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </div>

                                <span class="form-field" *ngSwitchCase="field.Type == FormFieldType.DROPDOWN && (!(field.Readonly || ReadOnly) || !field.ReadonlyDropdownAsReference) ? FormFieldType.DROPDOWN : -1">
                                    <!-- <label [ngClass]="{'label-required':field.Required && !ReadOnly, 'label-nonmd':true}">{{field.Label}}</label><br /> -->
                                    <avi-core-dropdown
                                        #dropdownFields
                                        #dd
                                        [placeholder]="field.DropdownPlaceholder | translate"
                                        [autofocus]="false"
                                        [showClear]="!field.Readonly && !ReadOnly && !field.Disabled"
                                        [readonly]="field.Readonly || ReadOnly"
                                        [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [options]="field.DropdownDatasourceInternal"
                                        [dataKey]="field.DropdownDataKey"
                                        [required]="field.Required"
                                        [filter]="field.DropdownFilterable"
                                        [resetFilterOnHide]="true"
                                        [name]="field.Name"
                                        [focus-dropdown]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [filterMatchMode]="field.DropdownMatchMode"
                                        [virtualScroll]="field.DropdownEnableVirtualScroll"
                                        [itemSize]="30"
                                        emptyFilterMessage="Keine Einträge gefunden"
                                        (onShow)="onDropdownShow(dd, $event)"
                                        (onFocus)="onDropdownFocus(dd, $event)"
                                        [ngModel]="GetField(field.Name)"
                                        (ngModelChange)="setDropdownByPath(field, $event)"
                                        (onBlur)="formAttrBlur(field.Name)"
                                        [customTemplateDelegate]="field.CustomTemplateDelegate"
                                        [customTemplateSelectedItemDelegate]="field.CustomTemplateSelectedItemDelegate"
                                    >
                                    </avi-core-dropdown>
                                    <!-- <dbg [m]="field.DropdownDatasourceInternal"></dbg> -->
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.LISTTYPE">
                                    <!-- <label [ngClass]="{'label-required':field.Required && !ReadOnly, 'label-nonmd':true}">{{field.Label}}</label><br /> -->
                                    <avi-core-dropdown
                                        #listFields
                                        [placeholder]="field.DropdownPlaceholder | translate"
                                        [showClear]="!field.Readonly && !ReadOnly && !field.Disabled && !field.Required"
                                        [options]="field.DropdownDatasourceInternal"
                                        [readonly]="field.Readonly || ReadOnly"
                                        [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [required]="field.Required"
                                        [filter]="field.DropdownFilterable"
                                        [name]="field.Name"
                                        [focus-dropdown]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [ngModel]="GetField(field.Name)"
                                        (ngModelChange)="setDropdownByPath(field, $event)"
                                        (onBlur)="formAttrBlur(field.Name)"
                                        [customTemplateDelegate]="field.CustomTemplateDelegate"
                                        [customTemplateSelectedItemDelegate]="field.CustomTemplateSelectedItemDelegate"
                                    >
                                    </avi-core-dropdown>
                                    <!-- <dbg [m]="field.DropdownDatasourceInternal"></dbg> -->
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.ENUM">
                                    <!-- <label [ngClass]="{'label-required':field.Required && !ReadOnly, 'label-nonmd':true}">{{field.Label}}</label><br /> -->
                                    <avi-core-dropdown
                                        #listFields
                                        [placeholder]="field.DropdownPlaceholder | translate"
                                        [showClear]="!field.Readonly && !ReadOnly && !field.Disabled && !field.Required"
                                        [options]="field.DropdownDatasourceInternal"
                                        [readonly]="field.Readonly || ReadOnly"
                                        [tabindex]="field.Readonly || ReadOnly ? -1 : 0"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [required]="field.Required"
                                        [filter]="field.DropdownFilterable"
                                        [name]="field.Name"
                                        [focus-dropdown]="i == 0 && !(field.Readonly || ReadOnly) && AutoFocus"
                                        [disabled]="field.Disabled && !ReadOnly"
                                        [ngModel]="GetField(field.Name)"
                                        (ngModelChange)="setDropdownByPath(field, $event)"
                                        (onBlur)="formAttrBlur(field.Name)"
                                        [customTemplateDelegate]="field.CustomTemplateDelegate"
                                        [customTemplateSelectedItemDelegate]="field.CustomTemplateSelectedItemDelegate"
                                    >
                                    </avi-core-dropdown>
                                    <!-- <dbg [m]="field.DropdownDatasourceInternal"></dbg> -->
                                    <span
                                        [hidden]="validOrPristine(field.Name, form)"
                                        class="ui-message ui-messages-error ui-corner-all"
                                        >Pflichtfeld</span
                                    >
                                    <span [hidden]="pristine(field.Name, form)">
                                        <ng-container *ngTemplateOutlet="errors; context: { errors: field.Errors }"></ng-container>
                                    </span>
                                </span>

                                <span class="form-field" *ngSwitchCase="FormFieldType.LINK">
                                    <br *ngIf="field.Label === ' '" />

                                    <a [href]="field.LinkUrl"
                                        class="flex align-items-center avi-link-{{field.Name}}"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)"
                                        [target]="field.LinkTarget" >
                                        <i *ngIf="field.LinkIcon" class="pi {{field.LinkIcon}} mr-1"></i>
                                        <span [innerHTML]="field.LinkText"></span>
                                        <i *ngIf="field.LinkTarget === '_blank'" class="ml-2 pi pi-external-link text-xs"></i>
                                    </a>
                                </span>

                                <span class="form-field flex" *ngSwitchCase="field.Type == FormFieldType.DROPDOWN && (field.Readonly || ReadOnly) && field.ReadonlyDropdownAsReference ? FormFieldType.DROPDOWN : FormFieldType.REFERENCE">
                                    <a class="flex align-items-center avi-link"
                                        [ngStyle]="getFieldStyle(field)"
                                        [ngClass]="getFieldCustomClass(field)" (click)="onFollowLink(field.Name)">{{GetResolvedField(field.Name)}}
                                    </a>
                                </span>

                                <span *ngSwitchCase="FormFieldType.TEMPLATE">
                                    <div [ngSwitch]="field.Name">
                                        <div *ngSwitchCase="'formFieldTemplate1'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet1"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate2'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet2"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate3'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet3"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate4'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet4"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate5'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet5"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate6'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet6"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate7'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet7"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate8'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet8"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate9'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet9"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate10'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet10"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate11'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet11"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate12'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet12"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate13'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet13"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate14'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet14"></ng-container>
                                        </div>
                                        <div *ngSwitchCase="'formFieldTemplate15'">
                                            <ng-container *ngTemplateOutlet="formFieldOutlet15"></ng-container>
                                        </div>
                                        <span *ngSwitchDefault
                                            >UNBEKANNTER TEMPLATENAME
                                            {{ field.Name }}
                                        </span>
                                    </div>
                                </span>

                                <span *ngSwitchDefault
                                    >UNBEKANNTER TYP ({{ field.Type }}) für Attribut {{ field.Name }}</span
                                >
                            </div>
                        </div>
                    </div>
                </ng-template>
            </form>
        </div>
    </div>

    <ng-container *ngIf="TaskMenuPosition === 'bottom'" ><ng-container *ngTemplateOutlet="taskMenuItems"></ng-container></ng-container>

    <ng-content select="[footer]"></ng-content>
</avi-core-page>
</div>

<avi-core-dbg [m]="Model" t="Model aus BaseFormComponent"></avi-core-dbg>

<ng-template #taskMenuItems>

    <div *ngIf="(ReadOnly || InlineModus) && TaskMenuItems?.length > 0" class="taskMenuItems ui-g pl-2 pr-2 pt-2">
        <div class="ui-g-12">
            <p-toolbar styleClass="no-pad">
                <div class="p-toolbar-group-left flex-gap-2">
                    <ng-container *ngFor="let item of TaskMenuItems">
                        <button pButton *ngIf="!item.separator"
                            type="button"
                            [label]="item.label"
                            [icon]="item.icon"
                            (click)="item.command()"
                            class="w-100p-m {{item.styleClass ? item.styleClass : 'p-button-secondary'}}">
                        </button>

                        <p-divider *ngIf="item.separator" layout="vertical" styleClass="m-0"></p-divider>

                    </ng-container>
                </div>
<!--
                <div class="p-toolbar-group-right">
                    <button pButton type="button"  class="w-100p-m p-button-secondary" icon="pi pi-bars" label="" (click)="menu.toggle($event)"></button>
                    <p-tieredMenu #menu appendTo="body" [model]="TaskMenuItems" [popup]="true"></p-tieredMenu>
                </div> -->
            </p-toolbar>
        </div>
    </div>

</ng-template>

<ng-template #errors let-errors="errors">
    <ng-container *ngIf="FieldErrorPosition === 'inline' || FieldErrorPosition === 'both'" >
        <div *ngFor="let error of errors">
            <span class="ui-message ui-messages-error ui-corner-all">{{error.Message | translate}}</span>
        </div>
    </ng-container>
</ng-template>

<ng-template #formFieldOutlet1>
    <ng-content select="[formFieldTemplate1]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet2>
    <ng-content select="[formFieldTemplate2]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet3>
    <ng-content select="[formFieldTemplate3]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet4>
    <ng-content select="[formFieldTemplate4]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet5>
    <ng-content select="[formFieldTemplate5]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet6>
    <ng-content select="[formFieldTemplate6]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet7>
    <ng-content select="[formFieldTemplate7]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet8>
    <ng-content select="[formFieldTemplate8]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet9>
    <ng-content select="[formFieldTemplate9]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet10>
    <ng-content select="[formFieldTemplate10]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet11>
    <ng-content select="[formFieldTemplate11]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet12>
    <ng-content select="[formFieldTemplate12]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet13>
    <ng-content select="[formFieldTemplate13]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet14>
    <ng-content select="[formFieldTemplate14]"></ng-content>
</ng-template>

<ng-template #formFieldOutlet15>
    <ng-content select="[formFieldTemplate15]"></ng-content>
</ng-template>


<p-overlayPanel #hinweisPanel [styleClass]="'avi-baseform-hinweispanel'" [baseZIndex]="88888" [showCloseIcon]="true" [style]="{ 'width': 'auto', 'max-width': '550px' }">
    <ng-template pTemplate>
        <div *ngIf="Hinweis" [innerHtml]="Hinweis"></div>
        <avi-textblock *ngIf="HinweisTextblock" [identifier]="HinweisTextblock"></avi-textblock>
    </ng-template>
</p-overlayPanel>
