import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, ObjectUtils, typed_nameof } from '@avi-x/avi-core';
import { CustomPropCollection } from '@avi-x/avi-dto/system/custompropcollection.model';
import { CustomPropCollectionDef } from '@avi-x/avi-dto/system/custompropcollectiondef.model';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AviCustomPropCollectionDefFormComponent } from './custompropcollectiondef-form.component';

const nameof = (nameFunction: ((obj: CustomPropCollectionDef) => any)) => typed_nameof<CustomPropCollectionDef>(nameFunction);

@Component({
    selector: 'avi-custompropcollectiondef-searcher',
    templateUrl: './custompropcollectiondef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviCustomPropCollectionDefSearcherComponent extends AviAbstractModelSearcherComponent {
    ClassName = 'CORE.COMMON.MODEL.CAFCUSTOMPROPCOLLECTIONDEF';
    SettingsKey = 'settings.searcher.custompropcollectiondef';
    BaseUrl = 'custompropcollectiondef';
    FormType = AviCustomPropCollectionDefFormComponent;

    Page: 'searcher' | 'custompropcollectiondef' | 'collection' = 'searcher';

    bcHome: MenuItem = { icon: 'pi pi-home', command: () => this.Page = 'searcher' };
    bcFormdef: MenuItem = { label: 'Formular Definition', command: () => this.Page = 'custompropcollectiondef' };
    bcAbrechnung: MenuItem = { label: 'Abrechnung' };

    dialogRef: DynamicDialogRef;

    PropCollectionDefId: string = null;
    PropCollectionContextId: string = null;
    PropCollectionModelId: string = null;

    getBreadcrumbs() {
        if (this.Page === 'searcher') return [];
        if (this.Page === 'custompropcollectiondef') return [ this.bcFormdef ];
        if (this.Page === 'collection') return [ this.bcFormdef, this.bcAbrechnung ];
    }

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);
    }

    onSearcherInit(searcher) {
        searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.Bezeichnung1), 'Bezeichnung');
        searcher.endInit();
    }

    onDoubleClickSearcher(data) {
        this.Page = 'custompropcollectiondef';
        this.cdr.markForCheck();
    }

    async onTestCustomPropCollectionDef(id: string) {     
        this.Page = 'collection';
        this.PropCollectionDefId = id;
        this.PropCollectionContextId = 'b2fe1ba8-fce2-4543-8084-cbfae10b05f7';
        this.PropCollectionModelId = '0';
        this.cdr.markForCheck();
    }     
}

