import { Component, OnInit } from '@angular/core';
import { AviAuthService, AviCommonService } from '@avi-x/avi-core';
import { VwbPPublicRechtstraegerService } from '../../services/rechtstraeger.service';

@Component({
    selector: 'vwbp-public-rechtstraeger-owner-form',
    templateUrl: './rechtstraeger-owner-form.component.html',
    styleUrls: ['./rechtstraeger-owner-form.component.scss']
})
export class VwbPPublicRechtstraegerOwnerFormComponent implements OnInit {

    constructor(
        private commonService: AviCommonService,
        private authService: AviAuthService,
        private rechtstraegerService: VwbPPublicRechtstraegerService) { }

    ownerData: any;
    loading: boolean = false;

    userToAdd: string = '';

    isOwner(id: string) {
        return this.ownerData && this.ownerData.MasterOwnerId === id;
    }

    isCurrentUserOwner() {
        return this.ownerData && this.ownerData.MasterOwnerId === this.authService.CurrentUser.Uid;
    }

    addUser(evt) {
        const act = () => {
            this.rechtstraegerService.AddOwnerContainerUser(this.ownerData.Id, this.userToAdd).then(r => {
                this.loadData();
                this.commonService.notificateSuccess('Benutzer hinzugefügt');
            }).catch(err => {
                this.commonService.notificateError(err);
            });
        };

        this.commonService.confirm({ message: `Möchten Sie den Benutzer ${this.userToAdd} wirklich hinzufügen?`, accept: act });
    }

    removeUser(id: string) {
        const act = () => {
            this.rechtstraegerService.RemoveOwnerContainerUser(this.ownerData.Id, id).then(r => {
                this.loadData();
                this.commonService.notificateSuccess('Benutzer entfernt');
            }).catch(err => {
                this.commonService.notificateError(err);
            });
        };

        this.commonService.confirm({ message: 'Möchten Sie den Benutzer wirklich entfernen?', accept: act });
    }

    setMaster(id: string) {
        const act = () => {
            this.rechtstraegerService.SetOwnerContainerMaster(this.ownerData.Id, id).then(r => {
                this.loadData();
                this.commonService.notificateSuccess('Benutzer geändert');
            }).catch(err => {
                this.commonService.notificateError(err);
            });
        };

        this.commonService.confirm({ message: `Möchten Sie den Benutzer ${this.userToAdd} wirklich zum Owner machen?`, accept: act });
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.loading = true;
        this.rechtstraegerService.GetOwnerContainerData().then(r => {
            this.ownerData = r;
            this.loading = false;
        }).catch(err => {
            this.loading = false;
            this.commonService.notificateError(err);
        });
    }

    onTextblockClicked(evt) {
        console.log('onTextblockClicked 2', evt);
    }

}
