<avi-core-page icon="work"
    [contents-padding]="false"
    [no-box-shadow]="true">
    <div class="vwb-public-container">
        <div [ngClass]="{ 'mat-tab-header-autohide': navLinks.length <= 1 }">
            <nav *ngIf="navLinks.length > 1"
                mat-tab-nav-bar
                #tabNavbar>
                <a mat-tab-link
                    *ngFor="let link of navLinks"
                    [routerLink]="link.path"
                    routerLinkActive
                    #rla="routerLinkActive"
                    [active]="rla.isActive">{{ link.label }}</a>
            </nav>
            <div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</avi-core-page>
