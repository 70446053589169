import { Expose } from 'class-transformer';

export class RgbaColor {
    private _R: number;
    private _G: number;
    private _B: number;
    private _A: number;

    @Expose()
    public get R(): number { return this._R; }
    public set R(value: number) { this._R = value; }

    @Expose()
    public get G(): number { return this._G; }
    public set G(value: number) { this._G = value; }

    @Expose()
    public get B(): number { return this._B; }
    public set B(value: number) { this._B = value; }

    @Expose()
    public get A(): number { return this._A; }
    public set A(value: number) { this._A = value; }    
}

export class ColorType {
    private _Value: RgbaColor;

    @Expose()
    public get Value(): RgbaColor { return this._Value; }
    public set Value(value: RgbaColor) { this._Value = value; }
}
