import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AviApiService, AviCommonService, AviDialogComponent, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPFormularDaten, VwbPListDetailConst, VwbPVersion } from '@avi-x/vwbp-public';
import { VwbPInternSchwaerzenService } from '../../services/schwaerzen.service';
import { AntragEditMode, VwbPInternSchwaerzenAntragFormComponent } from '../schwaerzen-form/schwaerzen-form.component';
import { VwbPInternSchwaerzenBaseComponent } from './schwaerzen-base.component';

@Component({
    selector: 'vwbp-intern-schwaerzen-neue-version',
    template: `
        <mat-tab-group class="mat-tab-tertiary" mat-align-tabs="center" animationDuration="0ms" (selectedTabChange)="setPausiert($event)" [selectedIndex]="getSelectedIndex()">
            <mat-tab label="Aktiv" ></mat-tab>
            <mat-tab label="Pausiert"></mat-tab>
        </mat-tab-group>

        <vwbp-intern-schwaerzen-antrag-searcher #searcher settingskey="settings.searcher.schwaerzen.neueversion" [show-aktiv]="true" [last-version]="true" [get-filters]="GetFilters" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'Sitz', 'AntragGueltigAb', 'AntragEntscheidung', 'AntragEntscheidAm', 'AntragGueltigBis', 'AntragBegruendung' ]">
        </vwbp-intern-schwaerzen-antrag-searcher>

        <div *ngIf="currentUserIsAjuUserSchreiben()" fxLayout="row">
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <div class="grid form-group">
                    <div class="col-12 lg:col-12">
                        <button pButton type="button" [label]="'VWBP.INTERN.SCHWAERZEN.ANTRAG_KONTROLLIEREN_BUTTON' | translate" [disabled]="GetSelectionCount() !== 1" icon="pi pi-thumbs-up" aviThrottledClick (throttledClick)="checkAntrag()" class="mb-8p p-button-primary"></button>
                    </div>
                </div>
            </div>
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <schwaerzen-antrag-wbnichtmehrvorhanden-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></schwaerzen-antrag-wbnichtmehrvorhanden-task>
            </div>
            <div *ngIf="GetAktivValue()" class="pl-15 pr-15" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-pausieren-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-pausieren-task>
            </div>
            <div *ngIf="!GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-aktivieren-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-aktivieren-task>
            </div>
        </div>

        <!-- EDIT ANTRAG DIALOG -->
        <avi-core-dialog
            #AntragFormDialog
            identifier="AntragFormDialog"
            [showCloseIcon]="false"
            [closeOnEscape]="false"
        >
            <vwbp-intern-schwaerzen-antrag-form
                #AntragForm
                [contents-padding]="false"
                (onSavedSuccessful)="onAntragSavedSuccessful($event)"
                [action-buttons]="formActionButtons"
                [action-buttons-readonly]="formActionButtonsReadonly"
                [formular-daten]="FormularDaten"
            >
            </vwbp-intern-schwaerzen-antrag-form>
        </avi-core-dialog>

        `
})
export class VwbPInternSchwaerzenNeueVersionComponent extends VwbPInternSchwaerzenBaseComponent {
    @ViewChild('AntragFormDialog', { static: false })
    AntragFormDialog: AviDialogComponent;

    @ViewChild('AntragForm', { static: false })
    public AntragForm: VwbPInternSchwaerzenAntragFormComponent;

    public FormularDaten: VwbPFormularDaten[];

    formActionButtons: any[] = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];
    formActionButtonsReadonly: any[] = [{ title: 'CORE.COMMON.SCHLIESSEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];

    constructor(public commonService: AviCommonService, public apiService: AviApiService, public schwaerzenService: VwbPInternSchwaerzenService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, schwaerzenService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters = (): string[] => {
        return [
            `AntragKontrollieren eq true`,
            `AntragStatus ne ${VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_ABGELEHNT}`,
            `AntragAktiv eq ${this.GetAktivValue() ? VwbPListDetailConst.VWBP_MAHNWESEN_AKTIV : VwbPListDetailConst.VWBP_MAHNWESEN_PAUSIERT}`
        ];
    }

    private async loadVersion(id: string): Promise<VwbPVersion> {
        const res = await this.apiService.getModel(VwbPVersion, `v1/intern/vwbp/rechtstraeger/version/${id}`);

        res.alleFormularDaten.forEach(w => {
            w['FormularTypBezeichnung'] = w.FormularTyp.Bezeichnung;
            w['WBPartnerTypBezeichnung'] = w.WBPartnerTyp.Bezeichnung;
            w['WBPartnerTypId'] = w.WBPartnerTyp.Id;
        });

        return res;
    }

    public async checkAntrag() {
        const version = await this.loadVersion(this.GetSelectedRechtstraeger()[0].VersionId);
        this.FormularDaten = version.alleFormularDaten;
        this.cdr.detectChanges();
        await this.AntragForm.InitForm(AntragEditMode.CHECK, this.GetSelectedRechtstraeger()[0].AntragId);
        this.AntragFormDialog.open();
    }

    onAntragSavedSuccessful(event) {
        this.AntragFormDialog.close();
        this.AntragForm.resetForm();
        this.Refresh();
    }

    private closeForm() {
        this.AntragFormDialog.close();
        this.AntragForm?.resetForm();
    }
}
