import { Injectable } from '@angular/core';
import { AviRouterHistoryService } from '@avi-x/avi-core';

@Injectable({
    providedIn: 'root'
})
export class VwbPNavigationService {

    constructor(private routerHistoryService: AviRouterHistoryService) {
    }

    public canNavigateBack(): boolean {
        return false;
        // return this.routerHistoryService.getCurrentRoute() &&
        //     (this.routerHistoryService.getCurrentRoute().startsWith('/artobject/detail') ||
        //      this.routerHistoryService.getCurrentRoute().startsWith('/core/profile'));
    }

    public getNavigateBackRoute(): string {
        return '';
    }
}
