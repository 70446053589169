import { Component, ChangeDetectorRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { AviCommonService, AviApiService, AviBaseSearcherComponent } from '@avi-x/avi-core';
import { RTVerMode } from '../../../rechtstraeger/components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';

@Component({
    selector: 'vwbp-intern-behoerden-version-searcher',
    templateUrl: './behoerden-version-searcher.component.html',
    styleUrls: ['./behoerden-version-searcher.component.scss']
})
export class VwbPInternBehoerdenVersionSearcherComponent {
    RTVerMode = RTVerMode;
    VisibleColumns: string[] = ['Name', 'RegisterNummer', 'Rechtsform', 'Sitz', 'Version', 'Erfassungsstatus', 'Erfassungsdatum', 'Loeschungsdatum', 'Uebergabestatus'];

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @Input('ModelIds')
    public ModelIds: string[] = [];

    _Mode: RTVerMode = RTVerMode.RECHTSTRAEGER;
    @Input('Mode')
    public set Mode(value: RTVerMode) {
        this._Mode = value;
        if (this._Mode === RTVerMode.BEHOERDEN_VERSION_AUS)
            this.VisibleColumns = ['Name', 'Rechtsform', 'Sitz', 'Errichtung', 'RegisterLand', 'RegisterOrt', 'RegisterDatum', 'Version', 'Erfassungsstatus', 'Erfassungsdatum', 'Loeschungsdatum', 'Uebergabestatus'];
        else if (this._Mode === RTVerMode.BEHOERDEN_VERSION_IN_AUS)
            this.VisibleColumns = ['Name', 'RegisterNummer', 'Rechtsform', 'Sitz', 'Errichtung', 'RegisterLand', 'RegisterOrt', 'RegisterDatum', 'Version', 'Erfassungsstatus', 'Erfassungsdatum', 'Loeschungsdatum', 'Uebergabestatus']
        else
            this.VisibleColumns = ['Name', 'RegisterNummer', 'Rechtsform', 'Sitz', 'Version', 'Erfassungsstatus', 'Erfassungsdatum', 'Loeschungsdatum', 'Uebergabestatus'];
    }

    public get Mode(): RTVerMode {
        return this._Mode;
    }

    @Output()
    public onSelect: EventEmitter<any[]> = new EventEmitter();

    constructor(
        public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
        public cdr: ChangeDetectorRef
    ) {
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        searchConfig.Mode = this.Mode;
        searchConfig.ModelIds = this.ModelIds;

        const res = await this.apiService.post(`v1/intern/vwbp/rechtstraeger/behoerden/versionen`, searchConfig);

        return res;
    }

    public SelectAllDelegate: any = async (searchValue: string, searchConfig: any = null) => {
        searchConfig.Mode = this.Mode;
        searchConfig.ModelIds = this.ModelIds;

        return await this.apiService.post(`v1/intern/vwbp/rechtstraeger/behoerden/versionen/ids`, searchConfig);
    }

    public async onSearcherInit(data) {
        this.searcher.beginInit();

        this.searcher.addTextColumn('Name', 'VWBP.COMMON.RT', true).setWidth('25%');
        this.searcher.addTextColumn('RegisterNummer', 'VWBP.COMMON.REGISTERNR', true);
        this.searcher.addTextColumn('Rechtsform', 'VWBP.COMMON.RECHTSFORM', true);
        this.searcher.addTextColumn('Sitz', 'VWBP.COMMON.SITZ', true);

        this.searcher.addTextColumn('Errichtung', 'VWBP.COMMON.GRUENDUNGSDATUM_AUSLAND')
        this.searcher.addTextColumn('RegisterLand', 'VWBP.COMMON.REGISTERLAND', true);
        this.searcher.addTextColumn('RegisterOrt', 'VWBP.COMMON.REGISTERORT', true);
        this.searcher.addTextColumn('RegisterDatum', 'VWBP.COMMON.REGISTERDATUM', true);

        this.searcher.addIntegerColumn('Version', 'VWBP.COMMON.VERSION', true).setWidth('90px');
        this.searcher.addListTypeColumn('Erfassungsstatus', 'VWBP.COMMON.ERFASSUNGSSTATUS', 'vwbp_erfassungsstatus', true, true, null, 'v1/vwbp');
        this.searcher.addDateColumn('Erfassungsdatum', 'VWBP.COMMON.ERFASSUNGSDATUM', null, true).setWidth('90px');
        this.searcher.addDateColumn('Loeschungsdatum', 'VWBP.COMMON.LOESCHUNGSDATUM', null, true).setWidth('90px');
        this.searcher.addListTypeColumn('Uebergabestatus', 'VWBP.COMMON.UEBERGABESTATUS', 'vwbp_rt_uebergabestatus', true, true, null, 'v1/vwbp');

        this.searcher.KeepSelectedRows = this.ModelIds.map(w => { return { Id: w }; });
        this.searcher.endInit();

        this.onSelectionChanged(this.searcher.KeepSelectedRows);
    }

    onSelectionChanged(evt) {
        this.onSelect.emit(evt);
    }
}