import { trigger, animate, style, group, query, transition, state, animateChild } from '@angular/animations';

export const AviSlideDownUpAnimation = trigger('slideInOut', [
    state('in', style({
        height: '*',
        opacity: 1
    })),
    state('out', style({
        height: 0,
        opacity: 0
    })),
    transition('in => out', animate('200ms ease-in-out')),
    transition('out => in', animate('200ms ease-in-out'))
]);

const fade = [
    query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    group([
        query(':leave', [animate('0.3s ease-out', style({ opacity: 0 }))], { optional: true }),
        query(':enter', [ style({ opacity: 0 }), animate('0.3s ease-out', style({ opacity: 1 }))], { optional: true }),
    ]),
];

const slideLeftToRight = [
    query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
    group([
        query(':enter', [style({ transform: 'translateX(-100%)' }), animate('.2s ease-out', style({ transform: 'translateX(0%)' }))], { optional: true }),
        query(':leave', [style({ transform: 'translateX(0%)' }), animate('.2s ease-out', style({ transform: 'translateX(100%)' }))], { optional: true }),
    ]),
];

const slideRightToLeft = [
    query(':enter, :leave', style({ position: 'absolute', width: '100%' }), { optional: true }),
    group([
        query(':enter', [style({ transform: 'translateX(100%)' }), animate('.2s ease-in-out', style({ transform: 'translateX(0%)' }))], { optional: true }),
        query(':leave', [style({ transform: 'translateX(0%)' }), animate('.2s ease-in-out', style({ transform: 'translateX(-100%)' }))], { optional: true })
    ])
];

const scale = [
    group([
        query(':leave', [style({ overflow: 'hidden', height: '*' }), animate('200ms ease-in-out', style({ overflow: 'hidden', height: '0' }))], { optional: true }),
        query(':enter', [style({ overflow: 'hidden', height: '0' }), animate('200ms ease-in-out', style({ overflow: 'hidden', height: '*' }))], { optional: true })
    ])
];

export const AviRouterTransition = trigger('routerTransition', [
    transition('* => inital', fade),
    transition('* => section', fade),
    transition('* => forward', slideRightToLeft),
    transition('* => backward', slideLeftToRight),
]);

export const AviAnimateHeightAnimation = trigger('scaleHeight', [
    transition(':enter', scale),
    transition(':leave', scale)
]);
