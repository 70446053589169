import { ChangeDetectorRef, Component } from '@angular/core';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternSchwaerzenService } from '../../services/schwaerzen.service';
import { VwbPInternSchwaerzenBaseComponent } from './schwaerzen-base.component';


@Component({
    selector: 'vwbp-intern-schwaerzen-rechtsmittelfrist-abgelaufen',
    template: `
        <vwbp-intern-schwaerzen-antrag-searcher #searcher settingskey="settings.searcher.schwaerzen.rechtsmittelfrist" [last-version]="true" [get-filters]="GetFilters" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'Sitz', 'AntragGueltigAb', 'AntragEntscheidung', 'AntragEntscheidAm', 'AntragGueltigBis', 'AntragRechtsmittelfrist', 'AntragBegruendung' ]">
        </vwbp-intern-schwaerzen-antrag-searcher>

        <div *ngIf="currentUserIsAjuUserSchreiben()" fxLayout="row">
            <div class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-antrag-rechtskraft-setzen-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-antrag-rechtskraft-setzen-task>
            </div>
            <div class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-antrag-bewilligen-task [neu-setzen]="true" [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-antrag-bewilligen-task>
            </div>
            <div class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-antrag-ablehnen-task [neu-setzen]="true" [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-antrag-ablehnen-task>
            </div>
        </div>
        `
})
export class VwbPInternSchwaerzenRechtsmittelfristAbgelaufenComponent extends VwbPInternSchwaerzenBaseComponent {
    constructor(public commonService: AviCommonService, public schwaerzenService: VwbPInternSchwaerzenService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, schwaerzenService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters = (): string[] => {
        return [`AntragStatus eq ${VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_RECHTMITTELFRIST_ABGELAUFEN}`];
    }
}
