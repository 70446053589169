import { Comment } from '@avi-x/avi-dto/system/comment.model';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AviCommonService, AviApiService, AviRichTextEditorComponent, AviAuthService } from '@avi-x/avi-core';
import { GenericRef } from '@avi-x/avi-dto/shared/genericref.model';

export enum AviCommentMode {
    // eslint-disable-next-line no-unused-vars
    Readonly, Create, Edit
}

@Component({
    selector: 'vwbp-intern-unstimmigkeit-comment-view',
    templateUrl: './unstimmigkeit-comment-view.component.html',
    styleUrls: ['./unstimmigkeit-comment-view.component.scss']
})
export class VwbPInternUnstimmigkeitCommentViewComponent implements OnInit {

    private _CommentId: string = null;

    public get CommentId(): string {
        return this._CommentId;
    }

    @Input('comment-id')
    public set CommentId(value: string) {
        this._CommentId = value;
        this.init();
    }

    @Input('context-id')
    public ContextId: string = null;

    private ContextType: string = 'aviita.VwbP.common.modul.unstimmigkeit.model.VwbPUnstimmigkeit';

    @Input('show-buttons')
    public ShowButtons: boolean = true;

    @Output()
    public onSaved: EventEmitter<any> = new EventEmitter();

    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter();

    @Output()
    public onCancelled: EventEmitter<any> = new EventEmitter();

    @Output()
    public onDeleted: EventEmitter<any> = new EventEmitter();

    @ViewChild('rtEditor')
    rtEditor: AviRichTextEditorComponent;

    public Mode: AviCommentMode = AviCommentMode.Readonly;

    public Model: Comment;
    sub: any = null;

    CommentErledigt: boolean = false;
    CommentCollapsed: boolean = false;

    Keywords = [];

    SelectedKeywords: any[];

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, public authService: AviAuthService, public cdr: ChangeDetectorRef) {
    }

    ngOnInit() {

        // this.formActionButtons = [];
        // this.formActionButtons.push({  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.closeForm(); } });
    }

    async init() {

        if (this._CommentId === this.commonService.GuidEmpty) {
            this.CreateComment();
        } else if (this._CommentId) {
            this.Model = await this.apiService.getModelById(Comment, 'v1/intern/vwbp/comment', this._CommentId);
            this.CommentErledigt = this.Model.ErledigtAm != null;

            this.SelectedKeywords = this.Model.Tags ? this.Model.Tags.split(';').map(w => {
                return { name: w, code: w };
            }) : null;

            this.CommentCollapsed = this.CommentErledigt;
            this.cdr.markForCheck();
        }
    }


    async CreateComment() {
        this.Model = await this.apiService.FindModel(Comment, `v1/intern/vwbp/comment/${this.commonService.GuidEmpty}`, null, null);
        this.Model.IsRTF = false;
        this.Model.ModelRef = new GenericRef(this.ContextId, this.ContextType);
        this.Model.Comment = '';
        this.Mode = AviCommentMode.Create;
        this.cdr.detectChanges();
        this.setFocus();
    }

    setFocus() {
        setTimeout(() => {
            if (this.Model.IsRTF)
                this.rtEditor.Editor.focusIn();
            else {
                if (document.getElementById('Comment'))
                    document.getElementById('Comment').focus();
            }
        }, 100);
    }

    isNewComment() {
        return this.Mode === AviCommentMode.Create;
    }

    isReadOnly() {
        return this.Mode === AviCommentMode.Readonly;
    }

    EditComment() {
        this.Mode = AviCommentMode.Edit;
        this.onEdit.emit();
        this.cdr.detectChanges();
        this.setFocus();
    }

    DeleteComment() {
        if (this.Model) {
            const act = () => {
                this.apiService.delete(`v1/intern/vwbp/comment/${this.Model.Id}`).then(r => {
                    this.Mode = AviCommentMode.Readonly;
                    this.Model = null;
                    this.onDeleted.emit();
                });
            };

            this.commonService.confirm({ message: 'Möchten sie den Kommentar wirklich löschen!', acceptLabel: 'Löschen', accept: act, header: 'Kommentar löschen', icon: 'fa fa-question-circle' });
        }
    }

    async CancelComment() {
        if (this.Mode === AviCommentMode.Edit)
            this.Model = await this.apiService.getModelById(Comment, 'v1/intern/vwbp/comment', this.Model.Id);
        else
            this.Model = null;

        this.Mode = AviCommentMode.Readonly;

        this.onCancelled.emit();
    }

    SetCommentErledigt(data) {

        if (this.isReadOnly()) {
            this.CommentErledigt = data;
            this.CommentCollapsed = this.CommentErledigt;

            if (this.CommentErledigt && !this.Model.ErledigtAm) {
                this.Model.ErledigtAm = new Date();
                this.Model.ErledigtDurch = this.authService.CurrentUserName;
            } else {
                this.Model.ErledigtAm = null;
                this.Model.ErledigtDurch = null;
            }

            const delegate = this.apiService.put(`/v1/intern/vwbp/comment/${this.Model.Id}`, this.Model);
            delegate.then(async r => {
                this.onSaved.emit();
                // this.commonService.notificateSuccess('Gespeichert');
                this.Model = await this.apiService.getModelById(Comment, 'v1/intern/vwbp/comment', this.Model.Id);
                this.cdr.detectChanges();
            }).catch(err => {
                console.log(err); this.cdr.detectChanges();
            });
        }
    }

    async SaveComment() {
        if (this.Model && this.Model.Comment) {

            if (this.CommentErledigt && !this.Model.ErledigtAm) {
                this.Model.ErledigtAm = new Date();
                this.Model.ErledigtDurch = this.authService.CurrentUserName;
            } else {
                this.Model.ErledigtAm = null;
                this.Model.ErledigtDurch = null;
            }

            this.Model.Tags = this.SelectedKeywords?.map(w => w.name)?.join(';');

            let delegate: Promise<any> = null;

            if (this.Mode === AviCommentMode.Edit) {
                delegate = this.apiService.put(`/v1/intern/vwbp/comment/${this.Model.Id}`, this.Model);
            } else if (this.Mode === AviCommentMode.Create) {
                delegate = this.apiService.post('/v1/intern/vwbp/comment', this.Model);
            }

            if (delegate) {
                delegate.then(async r => {
                    this.onSaved.emit();
                    this.commonService.notificateSuccess('Gespeichert');
                    this.Model = await this.apiService.getModelById(Comment, 'v1/intern/vwbp/comment', this.Model.Id);
                    this.Mode = AviCommentMode.Readonly;
                    this.cdr.detectChanges();
                }).catch(err => {
                    console.log(err);
                    this.Mode = AviCommentMode.Readonly;
                    this.cdr.detectChanges();
                });
            }
        }
    }

    toggleCollapsed() {
        this.CommentCollapsed = !this.CommentCollapsed;
        this.cdr.markForCheck();
    }

    getCommentHeader() {
        let res = ' - ' + this.Model.ErstellDatum?.toLocaleString('de-CH');

        if (this.CommentCollapsed && this.Model.Comment && this.isReadOnly()) {
            const comment = this.Model.Comment.split(/(?:\r\n|\r|\n)/)[0];
            res = res + ' - ' + comment;
        }

        return res;
    }
}
