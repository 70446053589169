<avi-core-base-form
    *ngIf="Model"
    #form
    [readonly]="true"
    [card]="Card"
    [fields]="Fields"
    [contents-padding]="true"
    [form-title]="FormTitle"
    [Model]="Model"
    [loading]="Loading"
    [action-buttons]="actionButtons"
    [action-buttons-readonly]="actionButtonsReadonly"
>
</avi-core-base-form>
