import { BreadcrumbModule } from 'primeng/breadcrumb';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AviCoreModule } from '@avi-x/avi-core';

import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TreeModule } from 'primeng/tree';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonModule } from 'primeng/togglebutton';

// import { BrowserModule } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';
import { AviScriptRulesetDefFormComponent } from './components/scriptrulesetdef-form.component';
import { AviScriptRulesetDefSearcherComponent } from './components/scriptrulesetdef-searcher.component';
import { AviScriptRuleOutputFormComponent } from './components/scriptruleoutput-form.component';
import { AviScriptRuleOutputSearcherComponent } from './components/scriptruleoutput-searcher.component';
import { AviScriptRuleDefFormComponent } from './components/scriptruledef-form.component';
import { AviScriptRuleDefSearcherComponent } from './components/scriptruledef-searcher.component';
import { AviScriptRulesetDefViewComponent } from './components/scriptrulesetdef-view.component';
import { AviScriptRulesetDefCreationParametersFormComponent } from './components/scriptrulesetdef-creationparameters-form.component';
import { AviScriptRuleOutputListComponent } from './components/scriptruleoutput-list.component';
import { AviScriptRuleOutputPruefenFormComponent } from './components/scriptruleoutput-pruefen-form.component';
import { AviScriptRuleOutputCommentFormComponent } from './components/scriptruleoutput-comment-form.component';


@NgModule({
    imports: [
        // BrowserModule,
        FormsModule,
        CommonModule,
        AviCoreModule,

        TreeModule,
        FlexLayoutModule,
        BreadcrumbModule,
        PanelModule,
        ToggleButtonModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ButtonModule,
        SplitButtonModule,
        FileUploadModule,
        InputTextModule,
        MenuModule,

        NgxPermissionsModule.forChild(),

        TranslateModule
    ],
    exports: [
        AviScriptRulesetDefViewComponent,
        AviScriptRulesetDefFormComponent,
        AviScriptRulesetDefSearcherComponent,
        AviScriptRuleOutputFormComponent,
        AviScriptRuleOutputSearcherComponent,
        AviScriptRuleDefFormComponent,
        AviScriptRuleDefSearcherComponent,
        AviScriptRuleOutputPruefenFormComponent,
        AviScriptRuleOutputCommentFormComponent
    ],
    declarations: [        
        AviScriptRulesetDefViewComponent,
        AviScriptRulesetDefFormComponent,
        AviScriptRulesetDefSearcherComponent,
        AviScriptRuleOutputFormComponent,
        AviScriptRuleOutputSearcherComponent,
        AviScriptRuleDefFormComponent,
        AviScriptRuleDefSearcherComponent,
        AviScriptRulesetDefCreationParametersFormComponent,
        AviScriptRuleOutputListComponent,
        AviScriptRuleOutputPruefenFormComponent,
        AviScriptRuleOutputCommentFormComponent
    ]
})
export class AviScriptingModule { }
