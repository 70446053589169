import { Component, Input, ViewChild } from '@angular/core';

import { AviPartnerService } from '../../services/partner.service';
import { AviCommonService, AviBaseSearcherComponent, AviApiService, AviPartnerListDetailConst, ISessionData, AviSessionControllerService } from '@avi-x/avi-core';

@Component({
    selector: 'avi-crm-partner-searcher',
    styleUrls: ['./partner-searcher.component.scss'],
    templateUrl: './partner-searcher.component.html'
})
export class AviPartnerSearcherComponent {
    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    @Input('session-data')
    SessionData: ISessionData;
    
    constructor(
        public partnerService: AviPartnerService,
        public apiService: AviApiService,
        public commonService: AviCommonService,
        public sessionController: AviSessionControllerService
    ) {
    }

    public onInit(data) {
        this.searcher.beginInit();

        // this.searcher.addColumn(SearcherColumn.CreateText("partnerType"));

        this.searcher.addTextColumn('Name', 'Name', false);
        this.searcher.addTextColumn('ZusatzName', 'Vorname / Zusatz', false);
        this.searcher.addDateColumn(
            'erstellDatum',
            'Erstelldatum',
            null,
            false
        );
        this.searcher
            .addListTypeColumn(
                'PartnerArt',
                'Partnerart',
                'caf_partner_art',
                false,
                false
            )
            .setSortable(false);

        this.searcher
            .addTextColumn(
                'PartnerArt.Id',
                'PartnerArtId',
                false)
            .setVisible(false);


        // this.searcher.addMenuItem({ label: "Neuer Eintrag", icon: 'pi pi-plus', command: (event) => this.onStartNew() /* routerLink: ['/crm/partner/form', "0"] */ });
        // this.searcher.addMenuItem({ label: 'Bearbeiten', icon: 'pi pi-pencil', command: (event) => this.router.navigate(['/crm/partner/form', this.searcher.SelectedRows[0].id]) });
        // this.searcher.addMenuItem({ label: 'Löschen', icon: 'pi pi-trash', command: (event) => this.deleteRecord(this.searcher.SelectedRows[0]) });

        this.searcher.endInit();
    }

    public onSelect(data) {
        //        if (data[0]) this.detailView.loadDetailView(data[0].Id);
    }

    public onDoubleClick(data) {
        if (data.PartnerArtId === AviPartnerListDetailConst.CAF_PARTNER_ART_JURPERSON) {
            this.sessionController.openSession('jurperson-index', data.Id);
        } else if (data.PartnerArtId === AviPartnerListDetailConst.CAF_PARTNER_ART_NATPERSON) {
            this.sessionController.openSession('natperson-index', data.Id);
        }
    }

    public onStartNew() {
        // this.router.navigate(['/crm/partner/form', '0']);
    }

    public deleteRecord(record: any) {
        this.commonService.confirmDelete(
            `Datensatz '${record.Id}' wirklich löschen?`,
            () => {
                this.partnerService.Delete(record.Id).then(r => {
                    this.commonService.notificateSuccess('Gelöscht');
                    this.searcher.startSearch();
                });
            }
        );
    }

}
