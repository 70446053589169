import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviListDetailConst, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { ListType } from '@avi-x/avi-dto/shared';
import { StatusDefSearchResponseDto } from '@avi-x/avi-dto/workflow/statusdefsearchresponsedto.model';
import { StatusWechselDef } from '@avi-x/avi-dto/workflow/statuswechseldef.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ScriptDefSearchResponseDto } from '@avi-x/avi-dto/workflow/scriptdefsearchresponsedto.model';
import { SqlJobDefSearchResponseDto } from '@avi-x/avi-dto/system/sqljobdefsearchresponsedto.model';
import { StatusWechselDefSearchResponseDto } from '@avi-x/avi-dto/workflow/statuswechseldefsearchresponsedto.model';

const nameof = (nameFunction: ((obj: StatusWechselDef) => any)) => typed_nameof<StatusWechselDef>(nameFunction);

@Component({
    selector: 'avi-statuswechseldef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviStatusWechselDefFormComponent extends AviAbstractModelFormComponent<StatusWechselDef> {
    ClassName = 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF';
    BaseUrl = 'workflow/statuswechseldef';
    ClassType = StatusWechselDef;
    FormType = AviStatusWechselDefFormComponent;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFStatusWorkflowDef';
    }

    public async afterCreateModel(bm: StatusWechselDef) {
        bm.vonStatusWorkflowDef_ID = this.ContextId;
    }

    public async afterInitForm() {
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.UebergangValidieren_ID), this.Model.AutoAusfuehren.Id === AviListDetailConst.JA);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.BemerkungEinblenden), this.Model.AutoAusfuehren.Id === AviListDetailConst.JA);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.BemerkungZwingend), this.Model.AutoAusfuehren.Id === AviListDetailConst.JA);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.UebergangGenerierenOutput), !this.Model.UebergangGenerieren_ID);
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1)) {
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung3 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung4 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung5 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung6 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung7 = this.Model.Bezeichnung1;
        }

        if (data.field === nameof(c => c.UebergangGenerieren_ID))
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.UebergangGenerierenOutput), !this.Model.UebergangGenerieren_ID);

        if (data.field === nameof(c => c.AutoAusfuehren)) {
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.UebergangValidieren_ID), this.Model.AutoAusfuehren.Id === AviListDetailConst.JA);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.BemerkungEinblenden), this.Model.AutoAusfuehren.Id === AviListDetailConst.JA);
            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.BemerkungZwingend), this.Model.AutoAusfuehren.Id === AviListDetailConst.JA);

            if (this.Model.AutoAusfuehren.Id === AviListDetailConst.JA) {
                if (this.Model.UebergangValidieren_ID != null) this.Model.UebergangValidieren_ID = null;
                if (this.Model.BemerkungEinblenden.Id == AviListDetailConst.JA) this.Model.BemerkungEinblenden = new ListType(AviListDetailConst.NEIN, 'janein', 'Nein');
                if (this.Model.BemerkungZwingend.Id == AviListDetailConst.JA) this.Model.BemerkungZwingend = new ListType(AviListDetailConst.NEIN, 'janein', 'Nein');
            }
        }

        if (data.field === nameof(c => c.BemerkungEinblenden)) {

            this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.BemerkungZwingend), this.Model.AutoAusfuehren.Id === AviListDetailConst.JA || this.Model.BemerkungEinblenden.Id == AviListDetailConst.NEIN);
            if (this.Model.BemerkungEinblenden.Id == AviListDetailConst.NEIN && this.Model.BemerkungZwingend.Id == AviListDetailConst.JA)
                this.Model.BemerkungZwingend = new ListType(AviListDetailConst.NEIN, 'janein', 'Nein');
        }
    }

    querySqlJobDef = async (field: any, modelId: string, query: string) => {
        const ret = [];
        if (this.Model) {
            const cfg: any = { Query: query, ContextId: this.ContextId, ModelId: this.ReadOnly || field.Readonly ? this.Model[field.Name] : null };
            const p = await this.apiService.postPagedResult(SqlJobDefSearchResponseDto, `sqljob/sqljobdef/search`, cfg);
            p.Results?.forEach(elem => ret.push({ label: elem.Beschreibung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    queryStatusDef = async (field: any, modelId: string, query: string) => {
        const ret = [];
        if (this.Model) {
            const cfg: any = { Query: query, ContextId: this.ContextId, ModelId: this.ReadOnly || field.Readonly ? this.Model[field.Name] : null };
            const p = await this.apiService.postPagedResult(StatusDefSearchResponseDto, `workflow/statusdef/search`, cfg);
            p.Results?.forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    queryScriptDef = async (field: any, modelId: string, query: string) => {
        const ret = [];
        if (this.Model) {
            const cfg: any = { Query: query, ContextId: this.ContextId, ModelId: this.ReadOnly || field.Readonly ? this.Model[field.Name] : null };
            const p = await this.apiService.postPagedResult(ScriptDefSearchResponseDto, `workflow/scriptdef/search`, cfg);
            p.Results?.forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    initFields() {
        if (this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.BEZEICHNUNG1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.BEZEICHNUNG2', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.BEZEICHNUNG3', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.BEZEICHNUNG4', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.EingangStatus_ID), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.EINGANGSTATUS', this.queryStatusDef, true).setAutoselectSingle().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.AusgangStatus_ID), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.AUSGANGSTATUS', this.queryStatusDef, true).setAutoselectSingle().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DefaultUserVonStatusWechsel_ID), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.DEFAULTUSERVONSTATUSWECHSEL', this.queryStatusDef, false).setAutoselectSingle().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DisallowUserVonStatusWechsel_ID), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.DISALLOWUSERVONSTATUSWECHSEL', this.queryStatusDef, false).setAutoselectSingle().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.BemerkungEinblenden), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.BEMERKUNGEINBLENDEN', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.BemerkungZwingend), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.BEMERKUNGZWINGEND', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.FristSetzen), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.FRISTSETZEN', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.FristBearbeitbar), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.FRISTBEARBEITBAR', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.FristTage), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.FRISTTAGE', 0, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.KulanzFristTage), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.KULANZFRISTTAGE', 0, false).setMDSizeHalf());

        // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.KommentarEinblenden), 'KommentarEinblenden', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateEmpty('filler2').setMDSizeHalf());

        // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AllowChangeAssignedTo), 'AllowChangeAssignedTo', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AssignedTo), 'AssignedTo', 'caf_statuswechseldef_assignedto', true, true, this.ReadOnly).setMDSizeHalf());

        // this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AssignedToContext1), 'AssignedToContext1', 'caf_statuswechseldef_assignedto_context1', true, true, this.ReadOnly).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.FixerMitarbeiter_ID), 'FixerMitarbeiter', `model/representations/AppUser${this.ReadOnly ? '?id=$id' : ''}`, true, false, 'FixerMitarbeiter wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.TechRole_ID), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.TECHROLE', false, 'Representation', 'Id', 2, `model/representations/TechRole`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateEmpty('filler3').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonSqlJobDefCheck_ID), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.VONSQLJOBDEFCHECK', this.querySqlJobDef, false).setAutoselectSingle().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.UebergangValidieren_ID), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.UEBERGANGVALIDIEREN', this.queryScriptDef, false).setAutoselectSingle().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.UebergangGenerieren_ID), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.UEBERGANGGENERIEREN', this.queryScriptDef, false).setAutoselectSingle().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.UebergangGenerierenOutput), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.UEBERGANGGENERIERENOUTPUT', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AutoAusfuehren), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.AUTOAUSFUEHREN', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AutoAusfuehrenNachFrist), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.AUTOAUSFUEHRENNACHFRIST', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Vollstaendigkeitspruefung), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.VOLLSTAENDIGKEITSPRUEFUNG', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Icon), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.ICON', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.StyleClass), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.STYLECLASS', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sorter), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.SORTER', 0, false).setMDSizeHalf());

        //this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Task_ID), 'Task', `model/representations/CAFTask${this.ReadOnly ? '?id=$id' : ''}`, true, false, 'Task wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());

    }
}
