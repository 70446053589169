import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { RahmenPartner } from '../partnerdef/rahmenpartner.model';
import { BasePartnerExtension } from '../partner/basepartnerextension.model';
import { Rechtsform } from '../partner/rechtsform.model';
import { Waehrung } from '../finanzendef/waehrung.model';
import { SteuerStatusDef } from '../partner/steuerstatusdef.model';
import { Land } from '../partner/land.model';
import { CustomPropCollection } from '../system/custompropcollection.model';
import { BaseModelState } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { Sprache } from '../system/sprache.model';

export class JurpersonHist extends BaseModelState {

    // Zusatzdaten
    @Expose()
    @Type(() => ListType)
    public get VerwendungChangeDef(): ListType { return this.getProperty('VerwendungChangeDef'); }
    public set VerwendungChangeDef(value: ListType) { this.setProperty('VerwendungChangeDef', value); }

    @Expose()
    public get ChangeDefRahmenPartner_ID(): string { return this.getProperty('ChangeDefRahmenPartner_ID'); }
    public set ChangeDefRahmenPartner_ID(value: string) { this.setProperty('ChangeDefRahmenPartner_ID', value); }

    @Expose()
    public get ChangeDefRechtsform_ID(): string { return this.getProperty('ChangeDefRechtsform_ID'); }
    public set ChangeDefRechtsform_ID(value: string) { this.setProperty('ChangeDefRechtsform_ID', value); }

    // BasePartner
    @Expose()
    public get PartnerType(): string { return this.getProperty('PartnerType'); }
    public set PartnerType(value: string) { this.setProperty('PartnerType', value); }

    @Expose()
    @TransformDate()
    public get ZustandAb(): Date { return this.getProperty('ZustandAb'); }
    public set ZustandAb(value: Date) { this.setProperty('ZustandAb', value); }

    @Expose()
    @TransformDate()
    public get ZustandBis(): Date { return this.getProperty('ZustandBis'); }
    public set ZustandBis(value: Date) { this.setProperty('ZustandBis', value); }

    @Expose()
    public get ExterneReferenz(): string { return this.getProperty('ExterneReferenz'); }
    public set ExterneReferenz(value: string) { this.setProperty('ExterneReferenz', value); }

    @Expose()
    public get ExterneId(): string { return this.getProperty('ExterneId'); }
    public set ExterneId(value: string) { this.setProperty('ExterneId', value); }

    @Expose()
    public get PartnerNr(): string { return this.getProperty('PartnerNr'); }
    public set PartnerNr(value: string) { this.setProperty('PartnerNr', value); }

    @Expose()
    public get Encrypted(): boolean { return this.getProperty('Encrypted'); }
    public set Encrypted(value: boolean) { this.setProperty('Encrypted', value); }

    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }

    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }

    @Expose()
    public get DomizilAdresseGUID(): string { return this.getProperty('DomizilAdresseGUID'); }
    public set DomizilAdresseGUID(value: string) { this.setProperty('DomizilAdresseGUID', value); }

    @Expose()
    public get Name(): string { return this.getProperty('Name'); }
    public set Name(value: string) { this.setProperty('Name', value); }

    @Expose()
    public get Suchbegriff(): string { return this.getProperty('Suchbegriff'); }
    public set Suchbegriff(value: string) { this.setProperty('Suchbegriff', value); }

    @Expose()
    public get ZusatzName(): string { return this.getProperty('ZusatzName'); }
    public set ZusatzName(value: string) { this.setProperty('ZusatzName', value); }

    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }

    @Expose()
    public get CustomPropXML(): string { return this.getProperty('CustomPropXML'); }
    public set CustomPropXML(value: string) { this.setProperty('CustomPropXML', value); }

    @Expose()
    public get vonRahmenPartner_ID(): string { return this.getModelId('vonRahmenPartner_ID'); }
    public set vonRahmenPartner_ID(value: string) { this.setModelId('vonRahmenPartner_ID', value); }

    @Expose()
    @Type(() => RahmenPartner)
    public get vonRahmenPartner(): RahmenPartner { return this.getModel('vonRahmenPartner'); }
    public set vonRahmenPartner(value: RahmenPartner) { this.setModel('vonRahmenPartner', value); }

    @Expose()
    public get Sprache_ID(): string { return this.getModelId('Sprache_ID'); }
    public set Sprache_ID(value: string) { this.setModelId('Sprache_ID', value); }

    @Expose()
    @Type(() => Sprache)
    public get Sprache(): Sprache { return this.getModel('Sprache'); }
    public set Sprache(value: Sprache) { this.setModel('Sprache', value); }

    @Expose()
    public get BasePartnerExtension_ID(): string { return this.getModelId('BasePartnerExtension_ID'); }
    public set BasePartnerExtension_ID(value: string) { this.setModelId('BasePartnerExtension_ID', value); }

    @Expose()
    @Type(() => BasePartnerExtension)
    public get BasePartnerExtension(): BasePartnerExtension { return this.getModel('BasePartnerExtension'); }
    public set BasePartnerExtension(value: BasePartnerExtension) { this.setModel('BasePartnerExtension', value); }

    @Expose()
    @Type(() => ListType)
    public get PartnerArt(): ListType { return this.getProperty('PartnerArt'); }
    public set PartnerArt(value: ListType) { this.setProperty('PartnerArt', value); }

    @Expose()
    @Type(() => ListType)
    public get PVSPersBesteuerung(): ListType { return this.getProperty('PVSPersBesteuerung'); }
    public set PVSPersBesteuerung(value: ListType) { this.setProperty('PVSPersBesteuerung', value); }

    @Expose()
    @Type(() => ListType)
    public get PflichtCode(): ListType { return this.getProperty('PflichtCode'); }
    public set PflichtCode(value: ListType) { this.setProperty('PflichtCode', value); }

    // Jurperson
    @Expose()
    public get Name2(): string { return this.getProperty('Name2'); }
    public set Name2(value: string) { this.setProperty('Name2', value); }

    @Expose()
    public get Name3(): string { return this.getProperty('Name3'); }
    public set Name3(value: string) { this.setProperty('Name3', value); }

    @Expose()
    public get Name4(): string { return this.getProperty('Name4'); }
    public set Name4(value: string) { this.setProperty('Name4', value); }

    @Expose()
    public get NameHR(): string { return this.getProperty('NameHR'); }
    public set NameHR(value: string) { this.setProperty('NameHR', value); }

    @Expose()
    @TransformDate()
    public get Gruendungsdatum(): Date { return this.getProperty('Gruendungsdatum'); }
    public set Gruendungsdatum(value: Date) { this.setProperty('Gruendungsdatum', value); }

    @Expose()
    @TransformDate()
    public get Loeschdatum(): Date { return this.getProperty('Loeschdatum'); }
    public set Loeschdatum(value: Date) { this.setProperty('Loeschdatum', value); }

    @Expose()
    public get Homepage(): string { return this.getProperty('Homepage'); }
    public set Homepage(value: string) { this.setProperty('Homepage', value); }

    @Expose()
    public get Kapital(): number { return this.getProperty('Kapital'); }
    public set Kapital(value: number) { this.setProperty('Kapital', value); }

    @Expose()
    @TransformDate()
    public get RegisterDatum(): Date { return this.getProperty('RegisterDatum'); }
    public set RegisterDatum(value: Date) { this.setProperty('RegisterDatum', value); }

    @Expose()
    public get VerwaltungOrt(): string { return this.getProperty('VerwaltungOrt'); }
    public set VerwaltungOrt(value: string) { this.setProperty('VerwaltungOrt', value); }

    @Expose()
    public get HRNr(): string { return this.getProperty('HRNr'); }
    public set HRNr(value: string) { this.setProperty('HRNr', value); }

    @Expose()
    @TransformDate()
    public get HRDatum(): Date { return this.getProperty('HRDatum'); }
    public set HRDatum(value: Date) { this.setProperty('HRDatum', value); }

    @Expose()
    public get MwStNr(): string { return this.getProperty('MwStNr'); }
    public set MwStNr(value: string) { this.setProperty('MwStNr', value); }

    @Expose()
    public get SteuerNr(): string { return this.getProperty('SteuerNr'); }
    public set SteuerNr(value: string) { this.setProperty('SteuerNr', value); }

    @Expose()
    public get Rechtsform_ID(): string { return this.getModelId('Rechtsform_ID'); }
    public set Rechtsform_ID(value: string) { this.setModelId('Rechtsform_ID', value); }

    @Expose()
    @Type(() => Rechtsform)
    public get Rechtsform(): Rechtsform { return this.getModel('Rechtsform'); }
    public set Rechtsform(value: Rechtsform) { this.setModel('Rechtsform', value); }

    @Expose()
    public get KapitalWaehrung_ID(): string { return this.getModelId('KapitalWaehrung_ID'); }
    public set KapitalWaehrung_ID(value: string) { this.setModelId('KapitalWaehrung_ID', value); }

    @Expose()
    @Type(() => Waehrung)
    public get KapitalWaehrung(): Waehrung { return this.getModel('KapitalWaehrung'); }
    public set KapitalWaehrung(value: Waehrung) { this.setModel('KapitalWaehrung', value); }

    @Expose()
    public get vonSteuerStatus_ID(): string { return this.getModelId('vonSteuerStatus_ID'); }
    public set vonSteuerStatus_ID(value: string) { this.setModelId('vonSteuerStatus_ID', value); }

    @Expose()
    @Type(() => SteuerStatusDef)
    public get vonSteuerStatus(): SteuerStatusDef { return this.getModel('vonSteuerStatus'); }
    public set vonSteuerStatus(value: SteuerStatusDef) { this.setModel('vonSteuerStatus', value); }

    @Expose()
    public get vonSteuerStatusSteG_ID(): string { return this.getModelId('vonSteuerStatusSteG_ID'); }
    public set vonSteuerStatusSteG_ID(value: string) { this.setModelId('vonSteuerStatusSteG_ID', value); }

    @Expose()
    @Type(() => SteuerStatusDef)
    public get vonSteuerStatusSteG(): SteuerStatusDef { return this.getModel('vonSteuerStatusSteG'); }
    public set vonSteuerStatusSteG(value: SteuerStatusDef) { this.setModel('vonSteuerStatusSteG', value); }

    @Expose()
    public get GoverningLaw_ID(): string { return this.getModelId('GoverningLaw_ID'); }
    public set GoverningLaw_ID(value: string) { this.setModelId('GoverningLaw_ID', value); }

    @Expose()
    @Type(() => Land)
    public get GoverningLaw(): Land { return this.getModel('GoverningLaw'); }
    public set GoverningLaw(value: Land) { this.setModel('GoverningLaw', value); }

    @Expose()
    public get VerwaltungLand_ID(): string { return this.getModelId('VerwaltungLand_ID'); }
    public set VerwaltungLand_ID(value: string) { this.setModelId('VerwaltungLand_ID', value); }

    @Expose()
    @Type(() => Land)
    public get VerwaltungLand(): Land { return this.getModel('VerwaltungLand'); }
    public set VerwaltungLand(value: Land) { this.setModel('VerwaltungLand', value); }

    @Expose()
    public get CustomProperties_ID(): string { return this.getModelId('CustomProperties_ID'); }
    public set CustomProperties_ID(value: string) { this.setModelId('CustomProperties_ID', value); }

    @Expose()
    @Type(() => CustomPropCollection)
    public get CustomProperties(): CustomPropCollection { return this.getModel('CustomProperties'); }
    public set CustomProperties(value: CustomPropCollection) { this.setModel('CustomProperties', value); }

    @Expose()
    @Type(() => ListType)
    public get Registerart(): ListType { return this.getProperty('Registerart'); }
    public set Registerart(value: ListType) { this.setProperty('Registerart', value); }

    @Expose()
    @Type(() => ListType)
    public get Klassifizierung(): ListType { return this.getProperty('Klassifizierung'); }
    public set Klassifizierung(value: ListType) { this.setProperty('Klassifizierung', value); }

    @Expose()
    @Type(() => ListType)
    public get Diskretionaer(): ListType { return this.getProperty('Diskretionaer'); }
    public set Diskretionaer(value: ListType) { this.setProperty('Diskretionaer', value); }

    @Expose()
    @Type(() => ListType)
    public get BeguenstigungsRegel(): ListType { return this.getProperty('BeguenstigungsRegel'); }
    public set BeguenstigungsRegel(value: ListType) { this.setProperty('BeguenstigungsRegel', value); }

    @Expose()
    @Type(() => ListType)
    public get Ermessen(): ListType { return this.getProperty('Ermessen'); }
    public set Ermessen(value: ListType) { this.setProperty('Ermessen', value); }

    @Expose()
    @Type(() => ListType)
    public get SPV12(): ListType { return this.getProperty('SPV12'); }
    public set SPV12(value: ListType) { this.setProperty('SPV12', value); }

    @Expose()
    @Type(() => ListType)
    public get KlassifizierungSteG(): ListType { return this.getProperty('KlassifizierungSteG'); }
    public set KlassifizierungSteG(value: ListType) { this.setProperty('KlassifizierungSteG', value); }

    @Expose()
    @Type(() => ListType)
    public get AuthShareCapital(): ListType { return this.getProperty('AuthShareCapital'); }
    public set AuthShareCapital(value: ListType) { this.setProperty('AuthShareCapital', value); }

    @Expose()
    @Type(() => ListType)
    public get IssuedShare(): ListType { return this.getProperty('IssuedShare'); }
    public set IssuedShare(value: ListType) { this.setProperty('IssuedShare', value); }

    @Expose()
    @Type(() => ListType)
    public get Ausgestaltung(): ListType { return this.getProperty('Ausgestaltung'); }
    public set Ausgestaltung(value: ListType) { this.setProperty('Ausgestaltung', value); }

    @Expose()
    @Type(() => ListType)
    public get Boersenkotiert(): ListType { return this.getProperty('Boersenkotiert'); }
    public set Boersenkotiert(value: ListType) { this.setProperty('Boersenkotiert', value); }

}
