import { Expose, Type } from 'class-transformer';

import { TechRole } from '../system/techrole.model';
import { ReportDef } from '../system/reportdef.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class JobDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get JobClass(): string { return this.getProperty('JobClass'); }
    public set JobClass(value: string) { this.setProperty('JobClass', value); }
    
    @Expose()
    public get JobSequenzName(): string { return this.getProperty('JobSequenzName'); }
    public set JobSequenzName(value: string) { this.setProperty('JobSequenzName', value); }
    
    @Expose()
    public get Beschreibung(): string { return this.getProperty('Beschreibung'); }
    public set Beschreibung(value: string) { this.setProperty('Beschreibung', value); }
    
    @Expose()
    public get SupportUser(): boolean { return this.getProperty('SupportUser'); }
    public set SupportUser(value: boolean) { this.setProperty('SupportUser', value); }

    @Expose()
    public get CanResume(): boolean { return this.getProperty('CanResume'); }
    public set CanResume(value: boolean) { this.setProperty('CanResume', value); }
    
    @Expose()
    public get ApiJobClass(): string { return this.getProperty('ApiJobClass'); }
    public set ApiJobClass(value: string) { this.setProperty('ApiJobClass', value); }
    
    @Expose()
    public get IsExternalDB(): boolean { return this.getProperty('IsExternalDB'); }
    public set IsExternalDB(value: boolean) { this.setProperty('IsExternalDB', value); }
    
    @Expose()
    public get ExternalInterval(): number { return this.getProperty('ExternalInterval'); }
    public set ExternalInterval(value: number) { this.setProperty('ExternalInterval', value); }

    @Expose()
    public get vonTechRole_ID(): string { return this.getModelId('vonTechRole_ID'); }
    public set vonTechRole_ID(value: string) { this.setModelId('vonTechRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get vonTechRole(): TechRole { return this.getModel('vonTechRole'); }
    public set vonTechRole(value: TechRole) { this.setModel('vonTechRole', value); }
    
    @Expose()
    public get UebersichtsReport_ID(): string { return this.getModelId('UebersichtsReport_ID'); }
    public set UebersichtsReport_ID(value: string) { this.setModelId('UebersichtsReport_ID', value); }
 
    @Expose()
    public get CAFAppServerDef_ID(): string { return this.getModelId('CAFAppServerDef_ID'); }
    public set CAFAppServerDef_ID(value: string) { this.setModelId('CAFAppServerDef_ID', value); }

    @Expose()
    @Type(() => ReportDef)
    public get UebersichtsReport(): ReportDef { return this.getModel('UebersichtsReport'); }
    public set UebersichtsReport(value: ReportDef) { this.setModel('UebersichtsReport', value); }
    
    @Expose()
    @Type(() => ListType)
    public get JobType(): ListType { return this.getProperty('JobType'); }
    public set JobType(value: ListType) { this.setProperty('JobType', value); }

    @Expose()
    @Type(() => ListType)
    public get EventLogLevel(): ListType { return this.getProperty('EventLogLevel'); }
    public set EventLogLevel(value: ListType) { this.setProperty('EventLogLevel', value); }

    @Expose()
    @Type(() => ListType)
    public get EventLogPrio(): ListType { return this.getProperty('EventLogPrio'); }
    public set EventLogPrio(value: ListType) { this.setProperty('EventLogPrio', value); }
}
