import { Component, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timeout } from 'rxjs-compat/operator/timeout';
import { IFav } from '../../shared/interfaces';

@Component({
    selector: 'avi-core-fav-form',
    templateUrl: 'avi-fav-form.component.html',
})
export class AviFavFormDialogComponent implements AfterViewInit {

    public pageTitle: string = 'Favorit hinzufügen';


    @ViewChild('inputTitle')
    inputTitle: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<AviFavFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IFav) {
        if (data.Id != null) {
            this.pageTitle = 'Favorit bearbeiten';
        }

    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.inputTitle.nativeElement.select();
        }, 1);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
