import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AviSearcherWithDetailComponent } from "./searcher-with-detail.component";
import { MenuItem } from "primeng/api";
import { AviAbstractModelSearcherComponent } from "../../base-searcher/abstract-model-searcher.component";
import { IAviAbstractModelFormComponent } from "../../base-form/abstract-model-form.component";

export interface ISearcherWithDetail {
    SelectedRecordId: string;
    TaskMenuItems: MenuItem[];
}

@Component({
    template: ''
})
export abstract class AviAbstractModelSearcherWithDetail<ST extends AviAbstractModelSearcherComponent, FT extends IAviAbstractModelFormComponent> implements OnInit, ISearcherWithDetail {
    @ViewChild(AviSearcherWithDetailComponent, {static: true})
    protected searcherWithDetailComponent: AviSearcherWithDetailComponent;

    @Input("context-id")
    ContextId: string;

    TaskMenuItems: MenuItem[] = [];
    
    SelectedRecordId: string;

    ngOnInit() {
        this.searcherWithDetailComponent.searcherWithDetailModel = this;
    }

    protected get searcher(): ST {
        if (this.searcherWithDetailComponent)
            return this.searcherWithDetailComponent.searcher as ST;
        
        return null;
    }

    protected get form(): FT {
        if (this.searcherWithDetailComponent)
            return this.searcherWithDetailComponent.form as FT;
        
        return null;
    }
}