import { Expose, Type } from 'class-transformer';

import { InfoDeskDef } from '../reporting/infodeskdef.model';
import { QueryDef } from '../reporting/querydef.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class InfoDeskDefDetail extends BaseModel {  
    @Expose()
    public get PosX(): number { return this.getProperty('PosX'); }
    public set PosX(value: number) { this.setProperty('PosX', value); }
    
    @Expose()
    public get PosY(): number { return this.getProperty('PosY'); }
    public set PosY(value: number) { this.setProperty('PosY', value); }
    
    @Expose()
    public get Width(): number { return this.getProperty('Width'); }
    public set Width(value: number) { this.setProperty('Width', value); }
    
    @Expose()
    public get Height(): number { return this.getProperty('Height'); }
    public set Height(value: number) { this.setProperty('Height', value); }
    
    @Expose()
    public get OpenTabpage(): string { return this.getProperty('OpenTabpage'); }
    public set OpenTabpage(value: string) { this.setProperty('OpenTabpage', value); }
    
    @Expose()
    public get vonInfoDeskDef_ID(): string { return this.getModelId('vonInfoDeskDef_ID'); }
    public set vonInfoDeskDef_ID(value: string) { this.setModelId('vonInfoDeskDef_ID', value); }
 
    @Expose()
    @Type(() => InfoDeskDef)
    public get vonInfoDeskDef(): InfoDeskDef { return this.getModel('vonInfoDeskDef'); }
    public set vonInfoDeskDef(value: InfoDeskDef) { this.setModel('vonInfoDeskDef', value); }
    
    @Expose()
    public get QueryDef_ID(): string { return this.getModelId('QueryDef_ID'); }
    public set QueryDef_ID(value: string) { this.setModelId('QueryDef_ID', value); }
 
    @Expose()
    @Type(() => QueryDef)
    public get QueryDef(): QueryDef { return this.getModel('QueryDef'); }
    public set QueryDef(value: QueryDef) { this.setModel('QueryDef', value); }
    
    @Expose()
    @Type(() => ListType)
    public get OpenAction(): ListType { return this.getProperty('OpenAction'); }
    public set OpenAction(value: ListType) { this.setProperty('OpenAction', value); }
    
}
