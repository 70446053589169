import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { Expose, Type } from 'class-transformer';

export class VwbPServerInfoDto extends BaseModel {
    @Expose()
    public get ServerVersion(): string { return this.getProperty('ServerVersion'); }
    public set ServerVersion(value: string) { this.setProperty('ServerVersion', value); }

    @Expose()
    public get ServerBuildDate(): string { return this.getProperty('ServerBuildDate'); }
    public set ServerBuildDate(value: string) { this.setProperty('ServerBuildDate', value); }

    @Expose()
    public get ServerBuildTime(): string { return this.getProperty('ServerBuildTime'); }
    public set ServerBuildTime(value: string) { this.setProperty('ServerBuildTime', value); }

    @Expose()
    public get DBServer(): string { return this.getProperty('DBServer'); }
    public set DBServer(value: string) { this.setProperty('DBServer', value); }

    @Expose()
    public get DBName(): string { return this.getProperty('DBName'); }
    public set DBName(value: string) { this.setProperty('DBName', value); }

    @Expose()
    public get DBTimeout(): number { return this.getProperty('DBTimeout'); }
    public set DBTimeout(value: number) { this.setProperty('DBTimeout', value); }

    @Expose()
    public get ESignaturRestApiBaseUrl(): string { return this.getProperty('ESignaturRestApiBaseUrl'); }
    public set ESignaturRestApiBaseUrl(value: string) { this.setProperty('ESignaturRestApiBaseUrl', value); }

    @Expose()
    public get ESignaturVersion(): string { return this.getProperty('ESignaturVersion'); }
    public set ESignaturVersion(value: string) { this.setProperty('ESignaturVersion', value); }

    @Expose()
    public get ESignaturProfilId(): string { return this.getProperty('ESignaturProfilId'); }
    public set ESignaturProfilId(value: string) { this.setProperty('ESignaturProfilId', value); }

    @Expose()
    public get ESignaturTyp(): string { return this.getProperty('ESignaturTyp'); }
    public set ESignaturTyp(value: string) { this.setProperty('ESignaturTyp', value); }

    @Expose()
    public get HrRestApiBaseUrl(): string { return this.getProperty('HrRestApiBaseUrl'); }
    public set HrRestApiBaseUrl(value: string) { this.setProperty('HrRestApiBaseUrl', value); }

    @Expose()
    public get DaysToKeepPostfach(): number { return this.getProperty('DaysToKeepPostfach'); }
    public set DaysToKeepPostfach(value: number) { this.setProperty('DaysToKeepPostfach', value); }
}
