<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

    <avi-tabView #tabView *ngIf="ModelId" width="14rem" tabview-class="tabstyle-session" orientation="vertical" [session-modul]="true">

        <avi-tabPanel title="CORE.COMMON.MODEL.APPUSERGROUP.APPUSERGROUP_1" id="appusergroup-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-appusergroup-form [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true" (after-edit)="onAfterEdit()"></avi-appusergroup-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
	
        <avi-tabPanel title="CORE.COMMON.MODEL.APPUSERGROUP.MYTECHROLELINKS" id="mytechrolelinks-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-techrolelink-searcher [context-id]="ModelId" [techrolelink-info]="techRoleLinkInfo" [enable-panel]="true"></avi-techrolelink-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
        <avi-tabPanel title="CORE.COMMON.MODEL.APPUSERGROUP.MYAPPUSERGROUPLINK" id="myappusergrouplink-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-appusergrouplink-searcher [context-id]="ModelId" [enable-panel]="true"></avi-appusergrouplink-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
        <avi-tabPanel title="CORE.COMMON.MODEL.APPUSERGROUP.ALLELOGENTRIES" id="allelogentries-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-appusergrouplogentry-searcher [context-id]="ModelId" [enable-panel]="true" ></avi-appusergrouplogentry-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
    </avi-tabView>

</avi-core-page>

