import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';

export class TechRole extends BaseModel {  
    @Expose()
    public get RoleName(): string { return this.getProperty('RoleName'); }
    public set RoleName(value: string) { this.setProperty('RoleName', value); }
    
    @Expose()
    public get SysRole(): number { return this.getProperty('SysRole'); }
    public set SysRole(value: number) { this.setProperty('SysRole', value); }
    
}
