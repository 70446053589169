<div class="mt-2">
    <avi-core-view-panel [toggleable]="false" header="Daten Import">

        <avi-core-base-form #form
            [readonly]="false"
            [card]="false"
            [fields]="Fields"
            [(Model)]="Model"
            [contents-padding]="false"
            [label-alignment]="'top'"
            [show-buttons]="true"
            [auto-focus]="true"
            [validation-delegate]="ValidateData"
            [save-button-label]="SaveButtonLabel"
            (onAttrChange)="onAttrChange($event)"
            (onSave)="saveModel()"
        >

            <div formFieldTemplate1>
                <div class="form-field">
                    <avi-file-upload-button label="Datei wählen" (onUpload)="onSelect($event)"></avi-file-upload-button>
                </div>
            </div>

        </avi-core-base-form>

        <div style="margin-top: 15px">
            <p-progressBar mode="indeterminate" *ngIf="Loading"></p-progressBar>
        </div>

        <avi-core-page
            title="Import Log"
            [card]="Card"
            [collapsible]="false"
            [expanded]="true"
            [no-box-shadow]="true"
            [contents-padding]="true"
            *ngIf="LogTotalCreated || LogTotalUpdated"
        >

            <div *ngIf="LogTotalCreated" style="margin-top: 15px">
                <div *ngFor="let logEntry of LogTotalCreated | keyvalue" style="margin: 5px" >
                    <p-button [label]="'Created ' + getLogLabel(logEntry.key)" badge="{{logEntry.value}}" styleClass="p-button-warning mr-1"></p-button>
                </div>    
            </div>
                
            <div *ngIf="LogTotalUpdated" style="margin-top: 15px">
                <div *ngFor="let logEntry of LogTotalUpdated | keyvalue" style="margin: 5px" >
                    <p-button [label]="'Updated ' + getLogLabel(logEntry.key)" badge="{{logEntry.value}}" styleClass="mr-1"></p-button>
                </div>    
            </div>

        </avi-core-page>

    </avi-core-view-panel>
</div>