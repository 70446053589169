import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { AviJobLoggingSearcherComponent } from "./joblogging-searcher.component";
import { AviJobLoggingFormComponent } from "./joblogging-form.component";
import { AviCommonService } from "../../services/common.service";
import { AviAbstractModelSearcherWithDetail } from "../controls/searcher-with-detail/abstract-model-searcher-with-detail";
import { NgxPermissionsService } from "ngx-permissions";
import { AviRoleConst } from "../../shared/constants/constants";

@Component({
    selector: 'avi-joblogging-searcherwithdetail',
    templateUrl: './joblogging-searcherwithdetail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJobLoggingSearcherWithDetailComponent extends AviAbstractModelSearcherWithDetail<AviJobLoggingSearcherComponent, AviJobLoggingFormComponent>  {
    
    @Input('enable-erledigen')
    EnableErledigen: boolean = true;
    
    constructor(
        private commonService: AviCommonService,
        private permissionsService: NgxPermissionsService,
        private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        
        if (this.EnableErledigen && this.permissionsService.hasPermission([AviRoleConst.JobLogging_STATUSAENDERN, AviRoleConst.JobLogging_SCHREIBEN])) {
            this.TaskMenuItems.push({ label: this.commonService.translateInstant("CORE.COMMON.MODEL.JOBLOGGING.ERLEDIGT_UPDATE"), command: () => this.updateErledigt(), icon: 'pi pi-pencils' });
        }

        this.cdr.markForCheck();
    }

    async updateErledigt(): Promise<void> {
        await this.commonService.openFormDialog(AviJobLoggingFormComponent, 'CORE.COMMON.MODEL.JOBLOGGING.ERLEDIGTUPDATE_TITLE', this.SelectedRecordId, null, {'statusEditMode': true}, true);
        await this.searcher.searcher.forceRefresh();
        await this.form.refresh();
    }
}