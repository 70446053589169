import { ChangeDetectorRef, Component } from '@angular/core';
import { AviApiService, AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPInternMahnwesenService } from '../../services/mahnwesen.service';
import { VwbPInternTaskBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-tasks/task-base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';

@Component({
    selector: 'vwbp-intern-mahnwesen-aktivieren-mahnfrist-setzen-task',
    template: `
      <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
        {{ 'VWBP.INTERN.MAHNWESEN.AKTIVIEREN_BUTTON' | translate}}
        </div>
        <div class="grid form-group">
            <div class="col-12">
                <span class="p-float-label mb-4p">
                    <p-calendar #calendar2 id="cal2" appendTo="body" [baseZIndex]="10000" (onInput)="onInputDate($event, calendar2)" [style]="{'width':'100%'}" [inputStyle]="{'width':'100%'}" [firstDayOfWeek]="1" [(ngModel)]="Datum" [selectOtherMonths]="true" dateFormat="dd.mm.yy" [readonlyInput]="false" [showOnFocus]="false" [showIcon]="true"></p-calendar>
                    <label for="cal2">Mahnfrist*</label>
                </span>

                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ 'VWBP.INTERN.MAHNWESEN.AKTIVIEREN_KOMMENTAR' | translate }}*</label>
                </span>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-play" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-primary"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternMahnwesenAktivierenMahnfristSetzenTaskComponent extends VwbPInternTaskBaseComponent {
    constructor(public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public mahnwesenService: VwbPInternMahnwesenService, public inputMaskService: InputmaskService, public dialogService: DialogService, public cdr: ChangeDetectorRef) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + this.commonService.translateInstant('VWBP.INTERN.MAHNWESEN.AKTIVIEREN_BUTTON');
    }

    CanExecute(): boolean {
        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung && !!this.Datum;
    }

    async Execute() {
        this.PerformAction('VWBP.INTERN.MAHNWESEN.AKTIVIEREN_BUTTON', this.GetResourceName('VWBP.INTERN.MAHNWESEN.AKTIVIEREN_BESTAETIGEN'), 'VWBP.INTERN.MAHNWESEN.AKTIVIEREN_BUTTON',
            async (selectedRT) => this.mahnwesenService.SetMahnwesenAktivMitMahnfrist(selectedRT.map(w => w.MahnungId), this.Datum, this.Bemerkung)
        );
    }
}
