<avi-core-view-panel header="Bankverbindungen">
    <avi-core-base-searcher
        #searcher
        [use-router-params]="false"
        [search-delegate]="SearchDelegate"
        settingskey="settings.searcher.bankverbindung"
        [get-filters]="getFilters"
        [field-names-gueltigab]="['GueltigAb']"
        [field-names-gueltigbis]="['GueltigBis']"
        [search-fields]="[
            'Bezeichnung',
            'Bemerkung',
            'vonBankVerbindungArt.Bezeichnung1'
        ]"
        [default-sort-data]="[{ field: 'GueltigAb', order: 1 }]"
        [rows]="0"
        [auto-search]="true"
        [show-autofilter]="false"
        [toolbar]="true"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [card]="false"
        (onInit)="onInit($event)"
        (onSelect)="onSelect($event)"
        searcher-icon="account_balance"
        searcher-title="Bankverbindungen"
        [show-totalrecords]="false"
    >
    </avi-core-base-searcher>
</avi-core-view-panel>

<!-- <div class="pt-3">
    <p-accordion
        *ngIf="DetailModelId"
        [multiple]="true"
        class="light-accordion"
    >
        <p-accordionTab header="Konten" [selected]="true">
            <avi-crm-konto-liste
                [bankverbindung-id]="DetailModelId"
            ></avi-crm-konto-liste>
        </p-accordionTab>
        <p-accordionTab header="Bankzeichnungsrechte" [selected]="true">
            <avi-crm-bankzeichnungsrecht-liste
                [bankverbindung-id]="DetailModelId"
            ></avi-crm-bankzeichnungsrecht-liste>
        </p-accordionTab>
    </p-accordion>
</div> -->
<avi-core-view-panel *ngIf="DetailModelId" header="Konten">
    <avi-crm-konto-liste
        [bankverbindung-id]="DetailModelId"
    ></avi-crm-konto-liste>
</avi-core-view-panel>
<avi-core-view-panel *ngIf="DetailModelId" header="Bankzeichnungsrechte">
    <avi-crm-bankzeichnungsrecht-liste
        [bankverbindung-id]="DetailModelId"
    ></avi-crm-bankzeichnungsrecht-liste>
</avi-core-view-panel>
