// import { NgxSmartModalComponent } from 'ngx-smart-modal';
import { Component, Input, ViewChild, Output, EventEmitter, ViewEncapsulation, Inject, forwardRef } from '@angular/core';

import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { HotkeysService } from 'angular2-hotkeys';
import { AviCommonService } from '../../services/common.service';

@Component({
    selector: 'avi-core-dialog',
    templateUrl: './dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AviDialogComponent {
    public width: string = 'auto';

    // @Input() public closable: boolean = true;
    @Input() public closeOnEscape: boolean = true;
    @Input() public showCloseIcon: boolean = false;
    @Input() public identifier: string = '';

    @Output('onOpen')
    public onOpenEvent: EventEmitter<any> = new EventEmitter();

    @Output('onOpenFinished')
    public onOpenFinishedEvent: EventEmitter<any> = new EventEmitter();

    // @ViewChild('myDialog', { static: false })
    // myDialog: NgxSmartModalComponent;

    display: boolean = false;

    @ViewChild('Dialog')
    public dialogObj: DialogComponent;

    DialogContentsClass: string = null;

    constructor(private commonService: AviCommonService) {
        if (this.commonService.InputStyle === 'filled')
            this.DialogContentsClass = 'p-input-filled';
    }

    public open(top?: boolean): void {
        this.dialogObj.show();
    }

    public close(): void {
        this.dialogObj.hide();
    }

    public isVisible(): boolean {
        return this.dialogObj.visible;
    }

    // public open(top?: boolean): void {
    //     this.myDialog.open(top);
    // }

    // public close(): void {
    //     this.myDialog.close();
    // }

    // public isVisible(): boolean {
    //     return this.myDialog.isVisible();
    // }

    onOpen() {
        this.onOpenEvent.emit();
    }

    onOpenFinished(args) {
        // args.preventFocus = true;
        this.onOpenFinishedEvent.emit();
    }
}
