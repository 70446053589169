import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviCommonService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternUebergabeService } from '../../../service/uebergabe.service';

@Component({
    selector: 'vwbp-intern-uebergabe-anfrage-view',
    templateUrl: './uebergabe-anfrage-view.component.html',
    styleUrls: ['./uebergabe-anfrage-view.component.scss']
})
export class VwbPInternUebergabeAnfrageViewComponent implements OnInit {

    Anfrage: any;
    VisaBewilligt: any[];
    VisaAbgelehnt: any[];
    VisaAll: any[];


    @Input('view-mode')
    ViewMode: string;

    @Input('anfrage-id')
    AnfrageId: string;


    public get IsEmbedded(): boolean {
        return this.ViewMode == 'embedded';
    }

    constructor(private activatedRoute: ActivatedRoute,
        private commonService: AviCommonService,
        private uebergabeService: VwbPInternUebergabeService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private location: Location,
        private vwbpCommonService: VwbPCommonService) { }

    ngOnInit() {

        if (this.IsEmbedded) {
            this.loadData(this.AnfrageId);
        } else {
            this.activatedRoute.params.subscribe(params => {
                if (params.id)
                    this.loadData(params.id);
            });
        }


    }


    loadData(id: string) {
        const p1 = this.uebergabeService.GetAnfrage(id);
        const p2 = this.uebergabeService.GetVisa(id, VwbPListDetailConst.VWBP_VISUM_TYP_UEBERGABE_ANFRAGE_BEWILLIGUNG);
        const p3 = this.uebergabeService.GetVisa(id, VwbPListDetailConst.VWBP_VISUM_TYP_UEBERGABE_ANFRAGE_ABLEHNUNG);
        Promise.all([p1, p2, p3]).then(r => {

            this.Anfrage = r[0];
            this.VisaBewilligt = r[1];
            this.VisaAbgelehnt = r[2];
            this.VisaAll = this.VisaBewilligt.concat(this.VisaAbgelehnt);
            this.cdr.markForCheck();

        }).catch(err => this.commonService.notificateError(err));
    }
    NavigateBack() {
        this.location.back();
    }

    IstStatusWarteAufDokumente() {
        return this.Anfrage && this.commonService.isEqualIgnoreCase(this.Anfrage.Anfragestatus.Id, VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_WARTE_AUF_DOKUMENTE);
    }

    IstStatusBereitFuerVisum() {
        return this.Anfrage && this.commonService.isEqualIgnoreCase(this.Anfrage.Anfragestatus.Id, VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG);
    }

    SetDokumenteErhalten() {
        const act = () => {
            this.uebergabeService.SetAnfrageStatus(this.Anfrage.Id, VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG)
                .then(r => {
                    this.loadData(this.Anfrage.Id);
                }).catch(err =>
                    this.commonService.notificateError(err));
        };
        this.commonService.confirm({ message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.DOKUMENTE_WIRKLICH_ERHALTEN'), acceptButtonStyleClass: 'p-button-primary', accept: act });
    }

    AnfrageBewilligen() {
        const act = () => {
            this.uebergabeService.SetAnfrageVisumBewilligen(this.Anfrage.Id)
                .then(r => {
                    this.commonService.notificateSuccess(this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.VISUM_ERSTELLT'));
                    this.loadData(this.Anfrage.Id);
                }).catch(err =>
                    this.commonService.notificateError(err));
        };
        this.commonService.confirm({ message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.WIRKLICH_BEWILLIGEN'), acceptButtonStyleClass: 'p-button-primary', accept: act });
    }

    AnfrageAblehnen() {
        const act = () => {
            this.uebergabeService.SetAnfrageVisumAblehnen(this.Anfrage.Id)
                .then(r => {
                    this.commonService.notificateSuccess(this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.VISUM_ERSTELLT'));
                    this.loadData(this.Anfrage.Id);
                }).catch(err =>
                    this.commonService.notificateError(err));
        };
        this.commonService.confirm({ message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.WIRKLICH_ABLEHNEN'), acceptButtonStyleClass: 'p-button-primary', accept: act });
    }


    isVisumTypBewilligt(typId: string) {
        return this.commonService.isEqualIgnoreCase(typId, VwbPListDetailConst.VWBP_VISUM_TYP_UEBERGABE_ANFRAGE_BEWILLIGUNG);
    }
}
