import { OnChanges } from '@angular/core';
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AviCommonService, AviApiService, ISessionData, AviSessionControllerService } from '@avi-x/avi-core';
import { MatTabNav } from '@angular/material/tabs';
import { Activity } from '@avi-x/avi-dto/activity/activity.model';

@Component({
    selector: 'avi-activity-index',
    templateUrl: './activity-index.component.html',
    styleUrls: ['./activity-index.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AviActivityIndexComponent implements OnInit, OnChanges {
    @Input('session-data')
    SessionData: ISessionData;

    navLinks = [
        { path: './view', label: 'Aktivität' }
    ];


    @ViewChild('tabNavbar', { static: true })
    tabNavbar: MatTabNav;

    Model: Activity = null;
    sub: any = null;
    PageTitle: string = null;

    constructor(public apiService: AviApiService,
                public router: Router,
                private activatedRoute: ActivatedRoute,
                public commonService: AviCommonService,
                public sessionController: AviSessionControllerService,
                private cdr: ChangeDetectorRef
                ) {
    }

    async ngOnChanges() {
        if (this.SessionData?.ContextId) {
            await this.loadModel(this.SessionData.ContextId);
            this.sessionController.setCurrentSessionTitle(this.PageTitle);
        }
    }

    ngOnInit() {
        // this.Model = {};
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {

            }
        });
    }

    public async loadModel(id: string) {
        this.PageTitle = await this.apiService.get(`model/representation/${id}/activity`);

        this.cdr.markForCheck();
    }
}
