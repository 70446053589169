import { Component, OnInit, Input, ViewChild, EventEmitter, Output, TemplateRef, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AviCommonService, AviBaseSearcherComponent, AviSearcherColumn, AviApiService, AviListDetailConst, AviSearcherColumnType, ObjectUtils, AviFormFieldType, ISessionData, AviSessionControllerService } from '@avi-x/avi-core';
import { DomSanitizer } from '@angular/platform-browser';
import { DecimalPipe, PercentPipe, DatePipe } from '@angular/common';

import { TinyColor } from '@ctrl/tinycolor';

import { QueryDefVM } from '@avi-x/avi-dto/reporting/querydef-vm.model';
import { SqlQueryTableStyleVM } from '@avi-x/avi-dto/reporting/sqlquerytablestyle-vm.model';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'avi-report-querydef-exec-searcher',
    templateUrl: './querydef-exec-searcher.component.html',
    providers: [DecimalPipe, PercentPipe, DatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviQueryDefExecutionSearcherComponent implements OnInit, OnChanges {
    @Input('session-data')
    SessionData: ISessionData;

    private isEmbedded: boolean = false;

    public set Id(value: string) {
        if (this._Id === value)
            return;
        this._Id = value;
    }

    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    public Model: QueryDefVM = null;

    public SearcherTitle: string = null;
    public FieldNameId: string = 'Id';

    private styleMap: Map<string, SqlQueryTableStyleVM[]> = new Map<string, SqlQueryTableStyleVM[]>();

    sub: any;

    @Input('use-router-params')
    public UseRouterParams: boolean = false;

    @Input('inaktiv-label')
    public InaktivLabel: string = null;

    @Input('card')
    public Card: boolean = true;

    @Input('collapsible')
    public Collapsible: boolean = true;

    @Input('expanded')
    public Expanded: boolean = true;

    @Input('fill-height')
    public FillHeight: boolean = false;

    @Input('hidden-columns')
    public HiddenColumns: string[] = null;

    @Input('showtitle')
    public ShowTitle = true;

    @Input('toolbar')
    public ShowToolbar = true;

    @Input('toolbar-show-searchinput')
    public ShowSearchInputManual: boolean = null;

    @Input('toolbar-show-inactivebutton')
    public ShowInactiveButtonManual: boolean = null;

    @Input('toolbar-show-advanced')
    public ShowAdvanced: boolean = false;

    @Input('show-autofilter')
    public ShowAutofilter = true;

    @Input('rows')
    public SearcherRows = 20;

    @Input('row-detailview-template')
    public rowDetailViewTemplate: TemplateRef<any>;

    @Input('responsive')
    public Responsive = true;

    @Input('searcher-icon')
    public SearcherIcon: string = null;

    @Input('auto-search')
    public AutoSearchManuel: boolean = null;

    @Input('pretoolbar-template')
    public pretoolbarTemplate: TemplateRef<any>;

    public AutoSearch: boolean = false;
    public ShowSearchInput: boolean = true;
    public ShowInactiveButton: boolean = true;

    @Output()
    public onDoubleClick: EventEmitter<any> = new EventEmitter();

    private _Id: string = null;

    @Output()
    public onSelect: EventEmitter<any[]> = new EventEmitter();

    @Output()
    public onModelLoaded: EventEmitter<any> = new EventEmitter();

    @Output('onParameterValueChange')
    public onParameterValueChangeEmitter: EventEmitter<any> = new EventEmitter();

    @Input('id')
    public set InputId(value: string) {
        this.isEmbedded = true;
        this.Id = value;
    }

    @Input('search-delegate')
    public SearchDelegate: any = async (searchValue: string) => { return this.doSearch(searchValue); }

    public reload(): void {
        this.loadModel(this._Id);
    }

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public sessionController: AviSessionControllerService, 
        public commonService: AviCommonService, private cdr: ChangeDetectorRef, public decimalPipe: DecimalPipe, public percentPipe: PercentPipe, public datePipe: DatePipe) {
    }

    async ngOnChanges() {
        if (this.SessionData?.ContextId && this.SessionData?.ContextId !== this.Id) {
            this.Id = this.SessionData.ContextId;
            await this.loadModel(this._Id);
            this.sessionController.setCurrentSessionTitle(this.SearcherTitle);
        }
    }

    public onInit(data) {
    }

    ngOnInit() {
        if (!this.isEmbedded) {
            this.Model = new QueryDefVM();
            this.sub = this.activatedRoute.params.subscribe(params => {
                if (params && params['id']) {
                    const id = params['id'];
                    if (id && id !== '0') {
                        this.Id = id;
                        this.loadModel(this._Id);
                    }
                }
            });
        } else {
            this.loadModel(this._Id);
        }
    }

    getRowClass = (row: any): string => {
        let ret = this.searcher.getGridLinesRowClass();
        let inaktiv = false;
        let ungueltig = false;
        let ungueltigFuture = false;
        if (row) {
            const today = this.commonService.getDate00(new Date());

            this.Model.SqlColumnList.forEach(element => {
                if (row[element.Name]) {
                    if (element.AktivFilter === true &&
                        !((row[element.Name] === AviListDetailConst.AKTIV) || row[element.Name] === 'aktiv')) {
                        inaktiv = true;
                    }

                    if (element.GueltigAbFilter === true && new Date(row[element.Name]) > today)
                    ungueltigFuture = true;

                    if (element.GueltigBisFilter === true && new Date(row[element.Name]) < today)
                        ungueltig = true;
                }
            });
        }

        if (inaktiv)
            ret += ' row-inaktiv';

        if (ungueltig)
            ret += ' row-ungueltig';

        if (ungueltigFuture)
            ret += ' row-ungueltig-future';

        return ret;
    }

    public getShowSearchInput(): boolean {
        return this.ShowSearchInputManual != null ? this.ShowSearchInputManual : this.ShowSearchInput;
    }

    public getShowInactiveButton(): boolean {
        return this.ShowInactiveButtonManual != null ? this.ShowInactiveButtonManual : this.ShowInactiveButton;
    }

    public getAutoSearch(): boolean {
        return this.AutoSearchManuel != null ? this.AutoSearchManuel : this.AutoSearch;
    }

    public addMenuItem(item: MenuItem) {
        this.searcher.addMenuItem(item);
    }

    async loadModel(id: string) {
        this.searcher.beginInit();
        this.Model = await this.apiService.getModelById(QueryDefVM, `/querydef/cached/${id}`, null);

        if (this.ShowToolbar) {
            this.ShowSearchInput = this.Model.SearchBarAnzeigen.Id === AviListDetailConst.JA;
            this.ShowInactiveButton = this.Model.SearchBarAnzeigen.Id === AviListDetailConst.JA;
        }

        // parameters
        this.searcher.clearSearcherParameters();
        const params = this.Model.SqlParameterList.sort((x, y) => (x.Row * 10 + x.Sorter) - (y.Row * 10 + y.Sorter));
        params.forEach(element => {
            const npars = params.filter(w => w.Row === element.Row).length;
            this.searcher.addSearcherParameters(element, 12 / npars);
        });

        this.ShowToolbar = this.ShowToolbar && (this.getShowSearchInput() || this.getShowInactiveButton() || params.some(w => w.HasControl.Id === AviListDetailConst.JA));

        // columns
        this.searcher.clearColumns();
        this.Model.SqlColumnList = this.Model.SqlColumnList.sort((x, y) => x.Sorter - y.Sorter);

        if (this.HiddenColumns != null)
            this.Model.SqlColumnList = this.Model.SqlColumnList.filter(w => !this.HiddenColumns.find(x => x === w.Name));

        this.FieldNameId = this.Model.SqlColumnList[0].Name;

        if (this.InaktivLabel == null && this.Model.SqlColumnList.some(element => {
            if (element.GueltigAbFilter === true || element.GueltigBisFilter === true)
                return true;
        }))
            this.InaktivLabel = 'inaktiv/ungültig';

        this.Model.SqlColumnList.filter((w, idx) => {
            return w.Sorter > 0 && !w.HiddenColumn && (this.Model.SqlPagingEnabled.Id !== AviListDetailConst.JA || idx < this.Model.SqlColumnList.length - 1);
        }).forEach(element => {
            if (element.DataType === 'System.DateTime')
                this.searcher.addDateColumn(element.Name, element.Label1, 'mediumDate', true);
            else if (element.DataType === 'System.Int32')
                this.searcher.addIntegerColumn(element.Name, element.Label1, true);
            else if (element.DataType === 'System.Decimal')
                this.searcher.addDecimalColumn(element.Name, element.Label1, Number(element.Nachkommastellen) || 2, Number(element.Nachkommastellen) || 2, true);
            else if (element.DataType === 'System.String' && element.Listname)
                this.searcher.addListTypeColumn(element.Name, element.Label1, element.Listname, true, true);
            else
                this.searcher.addTextColumn(element.Name, element.Label1, true);
        });

        this.SearcherTitle = this.Model.Bezeichnung1;

        // table styles
        this.Model.TableStyleList = this.Model.TableStyleList.sort((x, y) => x.Sorter - y.Sorter);
        const columnNames = Array.from(new Set(this.searcher.Columns.map(w => w.OField)));
        this.Model.TableStyleList.forEach(element => {
            if (element.Bereich.Id === AviListDetailConst.CAF_COLORMAPPING_RANGE_CELL)
                this.addOrCreateStyleMapDetail(element.CellColumnName, element);
            else
                columnNames.forEach(w => this.addOrCreateStyleMapDetail(w, element));

            const textStyle: any = {};
            if (element.TextItalic)
                textStyle.FontStyle = 'italic';
            if (element.TextBold)
                textStyle.FontWeight = 'bold';
            if (!ObjectUtils.isObjectEmpty(element.TextColor) && !this.commonService.isTransparentColor(element.TextColor.Value))
                textStyle.Color = `${new TinyColor(`rgb (${this.commonService.colorToString(element.TextColor.Value)})`).toHexString()}`;

            element.TextStyle = textStyle;

            if (!ObjectUtils.isObjectEmpty(element.BackgroundColor) && !this.commonService.isTransparentColor(element.BackgroundColor.Value))
                element.BackgroundColorString = `${new TinyColor(`rgb (${this.commonService.colorToString(element.BackgroundColor.Value)})`).toHexString()}`;

            if (!ObjectUtils.isObjectEmpty(element.BarsColor) && !this.commonService.isTransparentColor(element.BarsColor.Value)) {
                element.BarsColorString = `${new TinyColor(`rgb (${this.commonService.colorToString(element.BarsColor.Value)})`).toHexString()}`;
                element.BarsColorLightString = new TinyColor(element.BarsColorString).lighten(20).toHexString();
            }
        });

        this.AutoSearch = this.searcher.ExecuteSearch || this.Model.AutoSearch.Id === AviListDetailConst.JA || !this.ShowToolbar || !this.getShowSearchInput();

        this.onModelLoaded.emit(this.Model);

        this.cdr.markForCheck();

        setTimeout(() => this.searcher.endInit());
    }

    private addOrCreateStyleMapDetail(colName: string, element: any) {
        const res = this.styleMap.get(colName);
        if (res === undefined)
            this.styleMap.set(colName, [element]);
        else
            res.push(element);

        const col = this.searcher.getColumn(colName);
        col.CellStyleDelegate = this.CellStyleDelegate;
    }

    private isMatchingMappingDetail1(mappingDetail: any, value: any): boolean {
        if (value !== undefined) {
            if (mappingDetail.DataType1.Id === AviListDetailConst.ATTRTYPE_INTEGER && value !== undefined) {
                const valueInt = Number(value);
                if (mappingDetail.valueIntVon1 != null && mappingDetail.ValueIntBis1 != null)
                    return valueInt >= mappingDetail.ValueIntVon1 && valueInt <= mappingDetail.ValueIntBis1;
                else if (mappingDetail.ValueIntVon1 != null)
                    return valueInt >= mappingDetail.ValueIntVon1;
                else if (mappingDetail.ValueIntBis1 != null)
                    return valueInt <= mappingDetail.ValueIntBis1;
            } else if (mappingDetail.DataType1.Id === AviListDetailConst.ATTRTYPE_DECIMAL && value !== undefined) {
                const valueDec = Number(value);
                if (mappingDetail.ValueDecVon1 != null && mappingDetail.ValueDecBis1 != null)
                    return valueDec >= mappingDetail.ValueDecVon1 && valueDec <= mappingDetail.ValueDecBis1;
                else if (mappingDetail.ValueDecVon1 != null)
                    return valueDec >= mappingDetail.ValueDecVon1;
                else if (mappingDetail.ValueDecBis1 != null)
                    return valueDec <= mappingDetail.ValueDecBis1;
            } else if (mappingDetail.DataType1.Id === AviListDetailConst.ATTRTYPE_TEXT && value !== undefined) {
                const valueText = value as string;
                if (mappingDetail.ValueTextVon1 != null && mappingDetail.ValueTextBis1 != null)
                    return valueText >= mappingDetail.ValueTextVon1 && valueText <= mappingDetail.ValueTextBis1;
                else if (mappingDetail.ValueTextVon1 != null)
                    return valueText >= mappingDetail.ValueTextVon1;
                else if (mappingDetail.ValueTextBis1 != null)
                    return valueText <= mappingDetail.ValueTextBis1;
            } else if (mappingDetail.DataType1.Id === AviListDetailConst.ATTRTYPE_DATE && value !== undefined) {
                const valueDate = new Date(value);
                if (mappingDetail.ValueDateVon1 != null && mappingDetail.ValueDateBis1 != null)
                    return valueDate >= mappingDetail.ValueDateVon1 && valueDate <= mappingDetail.ValueDateBis1;
                else if (mappingDetail.valueDateVon1 != null)
                    return valueDate >= mappingDetail.ValueDateVon1;
                else if (mappingDetail.valueDateBis1 != null)
                    return valueDate <= mappingDetail.ValueDateBis1;
            } else if (mappingDetail.DataType1.Id === AviListDetailConst.ATTRTYPE_TIMESPAN && value !== undefined) {
                // TODO
            } else if (mappingDetail.DataType1.Id === AviListDetailConst.ATTRTYPE_GUID && value !== undefined)
                return value === mappingDetail.ValueGuid1;
        }

        return false;
    }

    private isMatchingMappingDetail2(mappingDetail: any, value: any): boolean {
        if (value !== undefined) {
            if (mappingDetail.DataType2.Id === AviListDetailConst.ATTRTYPE_INTEGER && value !== undefined) {
                const valueInt = Number(value);
                if (mappingDetail.ValueIntVon2 != null && mappingDetail.ValueIntBis2 != null)
                    return valueInt >= mappingDetail.ValueIntVon2 && valueInt <= mappingDetail.ValueIntBis2;
                else if (mappingDetail.ValueIntVon2 != null)
                    return valueInt >= mappingDetail.ValueIntVon2;
                else if (mappingDetail.ValueIntBis2 != null)
                    return valueInt <= mappingDetail.ValueIntBis2;
            } else if (mappingDetail.DataType2.Id === AviListDetailConst.ATTRTYPE_DECIMAL && value !== undefined) {
                const valueDec = Number(value);
                if (mappingDetail.ValueDecVon2 != null && mappingDetail.ValueDecBis2 != null)
                    return valueDec >= mappingDetail.ValueDecVon2 && valueDec <= mappingDetail.ValueDecBis2;
                else if (mappingDetail.ValueDecVon2 != null)
                    return valueDec >= mappingDetail.ValueDecVon2;
                else if (mappingDetail.ValueDecBis2 != null)
                    return valueDec <= mappingDetail.ValueDecBis2;
            } else if (mappingDetail.DataType2.Id === AviListDetailConst.ATTRTYPE_TEXT && value !== undefined) {
                const valueText = value as string;
                if (mappingDetail.ValueTextVon2 != null && mappingDetail.ValueTextBis2 != null)
                    return valueText >= mappingDetail.ValueTextVon2 && valueText <= mappingDetail.ValueTextBis2;
                else if (mappingDetail.ValueTextVon2 != null)
                    return valueText >= mappingDetail.ValueTextVon2;
                else if (mappingDetail.ValueTextBis2 != null)
                    return valueText <= mappingDetail.ValueTextBis2;
            } else if (mappingDetail.DataType2.Id === AviListDetailConst.ATTRTYPE_DATE && value !== undefined) {
                const valueDate = new Date(value);
                if (mappingDetail.ValueDateVon2 != null && mappingDetail.ValueDateBis2 != null)
                    return valueDate >= mappingDetail.ValueDateVon2 && valueDate <= mappingDetail.ValueDateBis2;
                else if (mappingDetail.ValueDateVon2 != null)
                    return valueDate >= mappingDetail.ValueDateVon2;
                else if (mappingDetail.ValueDateBis2 != null)
                    return valueDate <= mappingDetail.ValueDateBis2;
            } else if (mappingDetail.DataType2.Id === AviListDetailConst.ATTRTYPE_TIMESPAN && value !== undefined) {
                // TODO
            } else if (mappingDetail.DataType2.Id === AviListDetailConst.ATTRTYPE_GUID && value !== undefined)
                return value === mappingDetail.ValueGuid2;
        }

        return false;
    }

    public CellStyleDelegate: Function = (row: any, col: AviSearcherColumn) => {
        const style: any = {};

        if (col.Type === AviSearcherColumnType.INTEGER || col.Type === AviSearcherColumnType.DECIMAL || col.Type === AviSearcherColumnType.PERCENT)
            style.textAlign = 'right';

        const styleColumns = this.styleMap.get(col.OField);
        if (styleColumns !== undefined) {
            styleColumns.every(element => {
                let isMatch: boolean = false;

                const valueFlt1 = row[element.ColumnName1];
                isMatch = this.isMatchingMappingDetail1(element, valueFlt1);
                if (typeof element.ColumnName2 !== 'undefined' && element.ColumnName2) {
                    const valueFlt2 = row[element.ColumnName2];
                    isMatch = isMatch && this.isMatchingMappingDetail2(element, valueFlt2);
                }

                if (isMatch) {
                    style.fontStyle = element.TextStyle.FontStyle;
                    style.fontWeight = element.TextStyle.FontWeight;
                    style.color = element.TextStyle.Color;

                    if (element.Bereich.Id === AviListDetailConst.CAF_COLORMAPPING_RANGE_CELL) {
                        if (element.ShowBars && element.BarsColorString) {
                            const minValue = element.ValueBarsMin;
                            const maxValue = element.ValueBarsMax;

                            const valueCol = row[col.Field];
                            const prozent = Math.min(100, 100 * valueCol / (maxValue - minValue));

                            const color = element.BarsColorString;
                            const lcolor = element.BarsColorLightString;

                            const backgroundColor = element.BackgroundColorString ? element.BackgroundColorString : 'transparent';
                            style.background = `linear-gradient(to right, ${color} 0%, ${lcolor} ${prozent}% ${prozent}%, ${backgroundColor} ${prozent}%)`;
                        } else {
                            if (element.BackgroundColorString)
                                style.background = element.BackgroundColorString;
                        }
                    } else if (element.Bereich.Id === AviListDetailConst.CAF_COLORMAPPING_RANGE_ROW) {
                        if (element.BackgroundColorString)
                            style.background = element.BackgroundColorString;
                    }
                }

                return !isMatch;
            });
        }

        return style;
    }

    public async doSearch(searchValue: string): Promise<any> {
        // console.log('SEEAEAAARCCCGGGGHHHH');
        this.searcher.SelectedRows = [];

        return this.apiService.post('/querydef/search', {
            'queryDef': this.Model.Id,
            'searchString': this.searcher.SearchValue,
            'aktivId': this.searcher.showInaktive ? null : AviListDetailConst.AKTIV,
            'contextId': this.searcher.Context,
            'pageNumber': this.searcher.currentPage() || 1,
            'pageSize': this.searcher.SearcherRows,
            'filterSettings': this.searcher.ColumnFilters ? this.searcher.getColumnFiltersMapped().map(w => { return { Field: w.column.OField, Filter: w.value }; }) : [],
            'sortSettings': this.searcher.SortInfo ? this.searcher.SortInfo.map(w => { return { Field: w.column.OField, Direction: (w.order === -1 ? 'desc' : 'asc') }; }) : [],
            'searchParameters': this.searcher.SearcherParameters ? this.searcher.SearcherParameters.map(w => { return { ParamName: w.Name, Value: w.Value && w.Value !== 'null' ? w.Value : (w.Type === AviFormFieldType.CHECKBOX ? false : null) }; }) : []
        });
    }

    public forceRefresh() {
        this.searcher.forceRefresh();
    }


    getSettingsKey: any = () => {
        if (this.Model)
            return 'avix::settings.querydef.' + this.Model.Id;

        return null;
    }

    public setContext(context: string) {
        this.searcher.Context = context;
    }

    public setSearcherParameter(paramName: string, paramValue: any) {
        this.searcher.setSearcherParameter(paramName, paramValue);
    }

    public getColumn(field: string): AviSearcherColumn {
        return this.searcher.getColumn(field);
    }

    public getColumns(): AviSearcherColumn[] {
        return this.searcher.Columns;
    }

    public setBarColumn(field: string, minValue: number, maxValue: number) {
        this.searcher.setBarColumn(field, minValue, maxValue);
    }

    public onSelectionChanged(data) {
        this.onSelect.emit(data);
    }

    public _onDoubleClick(data) {
        this.onDoubleClick.emit(data);
    }

    public _onParameterValueChange(data) {
        this.onParameterValueChangeEmitter.emit(data);
    }
    public _onError(err) {
        this.commonService.notificateError(err);
    }
}
