import { Component, ViewChild, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { AviApiErrorObject, AviApiService } from '@avi-x/avi-core';
import { FileSaverService } from 'ngx-filesaver';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';

@Component({
    selector: 'avi-crm-dokument-signieren-form',
    templateUrl: './dokument-signieren-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDokumentSignierenFormComponent implements OnInit {
    @ViewChild('inputFile', { static: false })
    inputFile: FileUpload;

    @Input('api')
    public Api: string = '/avidoc';

    @Output('onClose')
    public onClose: EventEmitter<boolean> = new EventEmitter();

    Uploading: boolean = false;
    File: File;

    constructor(private apiService: AviApiService, 
        private fileSaverService: FileSaverService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        if (this.config && this.config.data) {
            this.Init();
        }
    }

    async dokumentSignieren() {
        this.Uploading = true;
        this.cdr.markForCheck();

        if (this.File) {

            const blob = await this.apiService.getBlobWithPostFile(`${this.Api}/GetSignedDocument`, this.File);
            if (blob) {
                this.fileSaverService.save(blob, this.File.name);
            }
        }
    }

    public FileChangeEvent(fileInput: any) {
        const CurrentFile = fileInput.target.files[0];

        let reader = new FileReader();
        reader.readAsDataURL(CurrentFile);
    }

    onRemove(evt) {
        this.File = null;
    }

    onClear(evt) {
        this.File = null;
    }

    onSelect(evt) {
        if (evt && evt.currentFiles && evt.currentFiles.length > 0) {
            const file = evt.currentFiles[0];
            this.File = file;
        }
    }

    public Init() {
        this.inputFile?.clear();
        this.Uploading = false;

        if (this.config.data.Params?.Api)
            this.Api = this.config.data.Params?.Api;
    }

    async doSignieren() {
        await this.dokumentSignieren();
        this.doClose();
    }

    doClose() {
        this.onClose.emit(null);

        if (this.ref)
            this.ref.close();
    }
}
