<p-panel
    header="Verlauf"
    [toggleable]="true"
    [collapsed]="Collapsed"
    expandIcon="pi pi-chevron-down"
    collapseIcon="pi pi-chevron-up"
    toggler="header"
    (collapsedChange)="OnCollapsedChanged($event)"
>
   
    <div class="mt-2 pb-3">
        <div *ngFor="let item of Items.slice(0, MaxNumItems)" class="verlauf-entry">
            <div class="verlauf-header">
                <span>{{ item.EventBez }}</span>
            </div>
            <div class="verlauf-user">
                {{ item.Username == '-' ? 'System' : item.Username }} - {{ item.Datum | date: "dd.MM.y HH:mm" : "de-CH" }}
            </div>
            <ng-container *ngIf="item.Changes">
                <div *ngFor="let change of item.Changes" class="verlauf-changes">
                    <span *ngIf="item.Event_ID !== SysEventConst.COMMENT_CREATED && item.Event_ID !== SysEventConst.COMMENT_UPDATED && item.Event_ID !== SysEventConst.COMMENT_DELETED" class="verlauf-attrib">
                        {{ change.Name | translate }}:
                    </span>
                    <div [ngSwitch]="change.ValueOld.AttrType" class="grid grid-nogutter">
                        <ng-container *ngSwitchCase="AttrType.Text">
                            <avi-core-richtexteditor
                                *ngIf="change.ValueOld.TextValue != null"
                                class="col-5 verlauf-editor"
                                [ngModel]="change.ValueOld.TextValue"
                                [readonly]="true"
                            >
                            </avi-core-richtexteditor>
                            <div *ngIf="change.ValueOld.TextValue == null" class="col-5">{{ 'CORE.COMMON.KEIN_WERT' | translate }}</div>

                            <div class="col text-center align-self-center"><i class="pi pi-arrow-right"></i></div>

                            <avi-core-richtexteditor
                                *ngIf="change.ValueNew.TextValue != null"
                                class="col-5 verlauf-editor"
                                [ngModel]="change.ValueNew.TextValue"
                                [readonly]="true"
                            >
                            </avi-core-richtexteditor>
                            <div *ngIf="change.ValueNew.TextValue == null" class="col-5">{{ 'CORE.COMMON.KEIN_WERT' | translate }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="AttrType.Int">
                            <div class="col-5">{{ change.ValueOld.IntValue === 0 ? 0 : change.ValueOld.IntValue || ('CORE.COMMON.KEIN_WERT' | translate) }}</div>
                            <div class="col text-center align-self-center"><i class="pi pi-arrow-right"></i></div>
                            <div class="col-5">{{ change.ValueNew.IntValue === 0 ? 0 : change.ValueNew.IntValue || ('CORE.COMMON.KEIN_WERT' | translate) }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="AttrType.Decimal">
                            <div class="col-5">{{ change.ValueOld.DecimalValue === 0 ? 0 : change.ValueOld.DecimalValue | number: '1.2-2' || ('CORE.COMMON.KEIN_WERT' | translate) }}</div>
                            <div class="col text-center align-self-center"><i class="pi pi-arrow-right"></i></div>
                            <div class="col-5">{{ change.ValueNew.DecimalValue === 0 ? 0 : change.ValueNew.DecimalValue | number: '1.2-2' || ('CORE.COMMON.KEIN_WERT' | translate) }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="AttrType.DateTime">
                            <div class="col-5">{{ change.ValueOld.DateTimeValue != null ? (change.ValueOld.DateTimeValue | dateFormat: 'medium') : ('CORE.COMMON.KEIN_WERT' | translate) }}</div>
                            <div class="col text-center align-self-center"><i class="pi pi-arrow-right"></i></div>
                            <div class="col-5">{{ change.ValueNew.DateTimeValue != null ? (change.ValueNew.DateTimeValue | dateFormat: 'medium') : ('CORE.COMMON.KEIN_WERT' | translate) }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="AttrType.Date">
                            <div class="col-5">{{ change.ValueOld.DateValue != null ? (change.ValueOld.DateValue | dateFormat: 'mediumDate') : ('CORE.COMMON.KEIN_WERT' | translate) }}</div>
                            <div class="col text-center align-self-center"><i class="pi pi-arrow-right"></i></div>
                            <div class="col-5">{{ change.ValueNew.DateValue != null ? (change.ValueNew.DateValue | dateFormat: 'mediumDate') : ('CORE.COMMON.KEIN_WERT' | translate) }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="AttrType.List">
                            <div class="col-5">{{ change.ValueOld.ListValue?.Bezeichnung ?? "Kein Wert" }}</div>
                            <div class="col text-center align-self-center"><i class="pi pi-arrow-right"></i></div>
                            <div class="col-5">{{ change.ValueNew.ListValue?.Bezeichnung ?? "Kein Wert" }}</div>
                        </ng-container>

                        <ng-container *ngSwitchCase="AttrType.Ref">
                            <div class="col-5">{{ change.ValueOld.RefValueBez ?? "Kein Wert" }}</div>
                            <div class="col text-center align-self-center"><i class="pi pi-arrow-right"></i></div>
                            <div class="col-5">{{ change.ValueNew.RefValueBez ?? "Kein Wert" }}</div>
                        </ng-container>

                    </div>
                </div>
            </ng-container>
            <div *ngIf="item.Bemerkung" class="verlauf-changes">
                <span *ngIf="item.Changes && item.Changes.length > 0" class="verlauf-attrib">Bemerkung:</span>
                <avi-core-richtexteditor
                    class="verlauf-editor"
                    [ngModel]="item.Bemerkung"
                    [readonly]="true"
                >
                </avi-core-richtexteditor>
            </div>

            <div class="separator pt-1"></div>
        </div>

        <div *ngIf="HasMore"><a class="verlauf-mehr-link" (click)="showMore()">Mehr...</a></div>
    </div>

</p-panel>
