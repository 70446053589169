import { Component, OnInit } from '@angular/core';
import { AviDocumentService } from '../../../services/document.service';
import { AviCommonService } from '../../../services/common.service';

@Component({
    selector: 'avi-core-docs-open',
    templateUrl: './avi-core-docs-open.component.html',
    styleUrls: ['./avi-core-docs-open.component.css']
})
export class AviCoreDocsOpenComponent implements OnInit {


    constructor(public docService: AviDocumentService, public commonService: AviCommonService) { }

    ngOnInit() {
        this.docService.LoadCheckedOutDocuments();
    }

    public CheckInDocument(doc: any) {

        const document = this.docService.InitDoc();
        document.Id = doc.id;
        // document.Title = doc.fileName;
        document.Path = doc.path;
        document.Title = doc.title;
        document.Description = doc.description;
        this.docService.ShowCheckinDialog(document);

        // const act = () => this.docService.CheckInDocument(doc.id);
        // this.commonService.confirm({message: 'Dokument einchecken?', accept: act});
    }

    public OpenDocumentAsProcess(doc: any) {
        return this.docService.OpenDocumentAsProcess(doc.id, doc.title);
    }

    public DiscardCheckoutDocument(doc: any) {
        const act = () => this.docService.DiscardCheckoutDocument(doc.id, doc.title);
        this.commonService.confirm({message: 'Dokument wirklich verwerfen? Änderungen gehen verloren!', acceptLabel: 'Ja, verwerfen!', accept: act, header: 'Dokument verwerfen', icon: 'fa fa-question-circle'});
    }


}
