<avi-core-page [card]="false" [collapsible]="false" [expanded]="true" [contents-padding]="true" [no-box-shadow]="true">
    <avi-core-base-searcher
        #searcher
        [use-router-params]="false"
        field-name-id="ArchivDocumentID"
        [card]="false"
        [contents-padding]="false"
        [search-delegate]="SearchDelegate"
        [rows]="0"
        [toolbar]="false"
        [auto-search]="true"
        [show-autofilter]="false"
        [select-first-result]="false"
        [checkbox-selection]="true"
        [checkbox-selectionmode]="'multiple'"
        [metakeyselection]="false"
        searcher-title=""
        (onInit)="onSearcherInit($event)"
    >
    </avi-core-base-searcher>
</avi-core-page>
