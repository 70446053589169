import { Expose, Type } from 'class-transformer';

import { AppUser } from '../system/appuser.model';
import { AppUserGroup } from '../system/appusergroup.model';
import { BaseModel } from '../shared/basemodel.model';

export class SearcherFilter extends BaseModel {
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }

    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }

    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }

    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }

    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }

    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }

    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }

    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }

    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }

    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }

    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }

    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }

    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }

    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }

    @Expose()
    public get Sequenz(): number { return this.getProperty('Sequenz'); }
    public set Sequenz(value: number) { this.setProperty('Sequenz', value); }

    @Expose()
    public get SearcherType(): string { return this.getProperty('SearcherType'); }
    public set SearcherType(value: string) { this.setProperty('SearcherType', value); }

    @Expose()
    public get Settings(): string { return this.getProperty('Settings'); }
    public set Settings(value: string) { this.setProperty('Settings', value); }

    @Expose()
    public get vonUser_ID(): string { return this.getModelId('vonUser_ID'); }
    public set vonUser_ID(value: string) { this.setModelId('vonUser_ID', value); }

    // @Expose()
    // @Type(() => AppUser)
    // public get vonUser(): AppUser { return this.getModel('vonUser'); }
    // public set vonUser(value: AppUser) { this.setModel('vonUser', value); }

    @Expose()
    public get vonAppUserGroup_ID(): string { return this.getModelId('vonAppUserGroup_ID'); }
    public set vonAppUserGroup_ID(value: string) { this.setModelId('vonAppUserGroup_ID', value); }

    @Expose()
    @Type(() => AppUserGroup)
    public get vonAppUserGroup(): AppUserGroup { return this.getModel('vonAppUserGroup'); }
    public set vonAppUserGroup(value: AppUserGroup) { this.setModel('vonAppUserGroup', value); }

}
