import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviAuthService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AviBusinesscaseService } from '../services/businesscase.service';
import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';
import { BusinesscaseActionDto } from '@avi-x/avi-dto/businesscase/businesscaseactiondto.model';

const nameof = (nameFunction: ((obj: BusinesscaseActionDto) => any)) => typed_nameof<BusinesscaseActionDto>(nameFunction);

@Component({
    selector: 'avi-businesscase-statuswechsel-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

        <div formFieldTemplate1>
            <div class="form-field">
                <button pButton type="button" class="p-button-secondary w-100p-m" label="{{ 'CORE.COMMON.MODEL.CAFBUSINESSCASE.ASSIGN_ME' | translate }}" (click)="doAssignMe()"></button>
            </div>
        </div>
    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseActionFormComponent extends AviAbstractModelFormComponent<BusinesscaseActionDto> {  
    ClassName = 'CORE.COMMON.MODEL.CAFSTATUSWECHSEL';
    BaseUrl = 'businesscase';
    ClassType = BusinesscaseActionDto;
    FormType = AviBusinesscaseActionFormComponent;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        public businesscaseService: AviBusinesscaseService,
        private authService: AviAuthService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFBusinesscase';
    }

    public async createModel(): Promise<BusinesscaseActionDto> {
        return null;
    }

    async getModel(id: string): Promise<BusinesscaseActionDto> {
        return this.Model;
    }

    ngOnInit() {
        if (this.config && this.config.data) {
            if (this.config.data) {
                this.Model = this.config.data.model;
                this.Card = false;
                this.ContentsPadding = false;
                this.actionButtons = [...this.actionButtons, ...this.abbrechenButton];
                this.actionButtonsReadonly = [...this.actionButtonsReadonly, ...this.abbrechenButtonReadonly];
                this.setDialogMode();
            }
        }

        this.init();
    }

    async init() {        
        this.initFields();
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Bemerkung), this.Model.BemerkungZwingend == true);
        this.Model.BemerkungIntern = this.businesscaseService.isIntern();

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.BemerkungIntern), this.Model.BemerkungInternEinblenden == true);

        const canChangeAssignee = this.Model.CanChangeAssignee == true;
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AssignedTo_ID), canChangeAssignee);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.AssignedTo_ID), canChangeAssignee);
        if (canChangeAssignee && this.Model.AssignedTo_ID == null)
            this.Model.AssignedTo_ID = this.Model.Businesscase.AssignedTo_ID;

        const canChangeApplicant = this.Model.CanChangeApplicant == true;
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Applicant_ID), canChangeApplicant);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Applicant_ID), canChangeApplicant);
        if (canChangeApplicant && this.Model.Applicant_ID == null)
            this.Model.Applicant_ID = this.Model.Businesscase.Applicant_ID;

        this.formFieldService.setFieldVisible(this.Fields, `formFieldTemplate1`, canChangeAssignee || canChangeApplicant);
        this.ReadOnly = this.Model.Readonly == true;

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Frist), this.Model.FristEinblenden === true);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.KulanzFrist), this.Model.FristEinblenden === true);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.Frist), this.Model.FristBearbeitbar !== true);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.KulanzFrist), this.Model.FristBearbeitbar !== true);

        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model, false);

        setTimeout(() => this.RefreshEditors());
    }

    async saveModel(model: BusinesscaseActionDto) {
        if (this.ref)
            this.ref.close(model);
    }

    public RefreshEditors() {
        this.Form?.RefreshEditors();
        this.cdr.markForCheck();
    }

    queryBusinesscaseAppUser = async (field: any) => {
        const ret = [];
        if (this.Model && this.Model.Businesscase) {
            const cfg: any = { ModelId: null, BusinesscaseDefId: this.Model.Businesscase.vonBusinesscaseDef_ID, Context1Id: this.Model.Businesscase.Context1?.ModelID, Context2Id: this.Model.Businesscase.Context2?.ModelID, Status_ID: this.Model.Businesscase.Status_ID };
            const p = await this.apiService.postModelList(ReferenceDto, `${this.getBaseUrl()}/appuser/search`, cfg);
            p.forEach(elem => ret.push({ label: elem.Representation, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    doAssignMe() {
        if (this.businesscaseService.isIntern())
            this.Model.AssignedTo_ID = this.authService.CurrentUser?.Uid;
        else if (this.businesscaseService.isExtern())
            this.Model.Applicant_ID = this.authService.CurrentUser?.Uid;

        this.updateDropdownSources();
    }

    initFields() {
        if (this.businesscaseService.isIntern()) {
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.AssignedTo_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.NEW_ASSIGNEDTO', this.queryBusinesscaseAppUser, false).setMDSize(9));
            this.Fields.push(this.formFieldService.CreateTemplate(1, ' ').setLabelVisible(true).setLabelClass('inline-block').setMDSize(3));

            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Frist), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.FRIST', false).setMDSize(6));
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.KulanzFrist), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.KULANZFRIST', 0, false).setMDSize(6));
        }
        else if (this.businesscaseService.isExtern()) {
            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Applicant_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.NEW_APPLICANT', this.queryBusinesscaseAppUser, false).setMDSize(9));
            this.Fields.push(this.formFieldService.CreateTemplate(1, ' ').setLabelVisible(true).setLabelClass('inline-block').setMDSize(3));
        }

        this.Fields.push(this.formFieldService.CreateRichText(nameof(c => c.Bemerkung), 'CORE.COMMON.MODEL.CAFSTATUSWECHSEL.BEMERKUNG', false).setMDSizeFull());

        if (this.businesscaseService.isIntern())
            this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.BemerkungIntern), null, 'CORE.COMMON.MODEL.CAFSTATUSWECHSEL.INTERN').setMDSizeFull());
    }
}
