import { Expose, Type } from 'class-transformer';

import { JobDef } from '../system/jobdef.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class Job extends BaseModel {  
    @Expose()
    public get JobID(): number { return this.getProperty('JobID'); }
    public set JobID(value: number) { this.setProperty('JobID', value); }
    
    @Expose()
    @Type(() => Date)
    public get StartedAt(): Date { return this.getProperty('StartedAt'); }
    public set StartedAt(value: Date) { this.setProperty('StartedAt', value); }
    
    @Expose()
    @Type(() => Date)
    public get FinishedAt(): Date { return this.getProperty('FinishedAt'); }
    public set FinishedAt(value: Date) { this.setProperty('FinishedAt', value); }
    
    @Expose()
    public get FilePath(): string { return this.getProperty('FilePath'); }
    public set FilePath(value: string) { this.setProperty('FilePath', value); }
    
    @Expose()
    public get TotalItems(): number { return this.getProperty('TotalItems'); }
    public set TotalItems(value: number) { this.setProperty('TotalItems', value); }
    
    @Expose()
    public get TotalSucceeded(): number { return this.getProperty('TotalSucceeded'); }
    public set TotalSucceeded(value: number) { this.setProperty('TotalSucceeded', value); }
    
    @Expose()
    public get TotalErrors(): number { return this.getProperty('TotalErrors'); }
    public set TotalErrors(value: number) { this.setProperty('TotalErrors', value); }
    
    @Expose()
    public get TotalWorkups(): number { return this.getProperty('TotalWorkups'); }
    public set TotalWorkups(value: number) { this.setProperty('TotalWorkups', value); }
    
    @Expose()
    public get fromJobDef_ID(): string { return this.getModelId('fromJobDef_ID'); }
    public set fromJobDef_ID(value: string) { this.setModelId('fromJobDef_ID', value); }
 
    @Expose()
    @Type(() => JobDef)
    public get fromJobDef(): JobDef { return this.getModel('fromJobDef'); }
    public set fromJobDef(value: JobDef) { this.setModel('fromJobDef', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Status(): ListType { return this.getProperty('Status'); }
    public set Status(value: ListType) { this.setProperty('Status', value); }
    
}
