import { Expose, Type } from 'class-transformer';

import { Mandant } from '../system/mandant.model';
import { TechRole } from '../system/techrole.model';
import { ModelMeta } from '../system/modelmeta.model';
import { PageGroup } from '../system/pagegroup.model';
import { PageRegister } from '../system/pageregister.model';
import { AviDocArt } from '../avidoc_def/avidocart.model';
import { BarcodeDocDef } from '../reporting/barcodedocdef.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class ReportDef extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get ReportPath(): string { return this.getProperty('ReportPath'); }
    public set ReportPath(value: string) { this.setProperty('ReportPath', value); }
    
    @Expose()
    public get DefaultMimeType(): string { return this.getProperty('DefaultMimeType'); }
    public set DefaultMimeType(value: string) { this.setProperty('DefaultMimeType', value); }
    
    @Expose()
    public get Sorter(): number { return this.getProperty('Sorter'); }
    public set Sorter(value: number) { this.setProperty('Sorter', value); }
    
    @Expose()
    public get UsePageSetup(): boolean { return this.getProperty('UsePageSetup'); }
    public set UsePageSetup(value: boolean) { this.setProperty('UsePageSetup', value); }
    
    @Expose()
    public get MarginLeft(): number { return this.getProperty('MarginLeft'); }
    public set MarginLeft(value: number) { this.setProperty('MarginLeft', value); }
    
    @Expose()
    public get MarginRight(): number { return this.getProperty('MarginRight'); }
    public set MarginRight(value: number) { this.setProperty('MarginRight', value); }
    
    @Expose()
    public get MarginTop(): number { return this.getProperty('MarginTop'); }
    public set MarginTop(value: number) { this.setProperty('MarginTop', value); }
    
    @Expose()
    public get MarginBottom(): number { return this.getProperty('MarginBottom'); }
    public set MarginBottom(value: number) { this.setProperty('MarginBottom', value); }
    
    @Expose()
    public get AutoprintPrinter(): string { return this.getProperty('AutoprintPrinter'); }
    public set AutoprintPrinter(value: string) { this.setProperty('AutoprintPrinter', value); }
    
    @Expose()
    public get Timeout(): number { return this.getProperty('Timeout'); }
    public set Timeout(value: number) { this.setProperty('Timeout', value); }
    
    @Expose()
    public get MandantRef_ID(): string { return this.getModelId('MandantRef_ID'); }
    public set MandantRef_ID(value: string) { this.setModelId('MandantRef_ID', value); }
 
    @Expose()
    @Type(() => Mandant)
    public get vonMandant(): Mandant { return this.getModel('vonMandant'); }
    public set vonMandant(value: Mandant) { this.setModel('vonMandant', value); }
    
    @Expose()
    public get vonTechRole_ID(): string { return this.getModelId('vonTechRole_ID'); }
    public set vonTechRole_ID(value: string) { this.setModelId('vonTechRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get vonTechRole(): TechRole { return this.getModel('vonTechRole'); }
    public set vonTechRole(value: TechRole) { this.setModel('vonTechRole', value); }
    
    @Expose()
    public get vonModel_ID(): string { return this.getModelId('vonModel_ID'); }
    public set vonModel_ID(value: string) { this.setModelId('vonModel_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get vonModel(): ModelMeta { return this.getModel('vonModel'); }
    public set vonModel(value: ModelMeta) { this.setModel('vonModel', value); }
    
    @Expose()
    public get vonPageGroup_ID(): string { return this.getModelId('vonPageGroup_ID'); }
    public set vonPageGroup_ID(value: string) { this.setModelId('vonPageGroup_ID', value); }
 
    @Expose()
    @Type(() => PageGroup)
    public get vonPageGroup(): PageGroup { return this.getModel('vonPageGroup'); }
    public set vonPageGroup(value: PageGroup) { this.setModel('vonPageGroup', value); }
    
    @Expose()
    public get vonPageRegister_ID(): string { return this.getModelId('vonPageRegister_ID'); }
    public set vonPageRegister_ID(value: string) { this.setModelId('vonPageRegister_ID', value); }
 
    @Expose()
    @Type(() => PageRegister)
    public get vonPageRegister(): PageRegister { return this.getModel('vonPageRegister'); }
    public set vonPageRegister(value: PageRegister) { this.setModel('vonPageRegister', value); }
    
    @Expose()
    public get DocumentDef_ID(): string { return this.getModelId('DocumentDef_ID'); }
    public set DocumentDef_ID(value: string) { this.setModelId('DocumentDef_ID', value); }
 
    @Expose()
    public get DocumentFolder_ID(): string { return this.getModelId('DocumentFolder_ID'); }
    public set DocumentFolder_ID(value: string) { this.setModelId('DocumentFolder_ID', value); }
 
    @Expose()
    public get AviDocArt_ID(): string { return this.getModelId('AviDocArt_ID'); }
    public set AviDocArt_ID(value: string) { this.setModelId('AviDocArt_ID', value); }
 
    @Expose()
    @Type(() => AviDocArt)
    public get AviDocArt(): AviDocArt { return this.getModel('AviDocArt'); }
    public set AviDocArt(value: AviDocArt) { this.setModel('AviDocArt', value); }
    
    @Expose()
    public get BarcodeDocDef_ID(): string { return this.getModelId('BarcodeDocDef_ID'); }
    public set BarcodeDocDef_ID(value: string) { this.setModelId('BarcodeDocDef_ID', value); }
 
    @Expose()
    @Type(() => BarcodeDocDef)
    public get BarcodeDocDef(): BarcodeDocDef { return this.getModel('BarcodeDocDef'); }
    public set BarcodeDocDef(value: BarcodeDocDef) { this.setModel('BarcodeDocDef', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Druckbar(): ListType { return this.getProperty('Druckbar'); }
    public set Druckbar(value: ListType) { this.setProperty('Druckbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Exportierbar(): ListType { return this.getProperty('Exportierbar'); }
    public set Exportierbar(value: ListType) { this.setProperty('Exportierbar', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ContextErforderlich(): ListType { return this.getProperty('ContextErforderlich'); }
    public set ContextErforderlich(value: ListType) { this.setProperty('ContextErforderlich', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Protokollierung(): ListType { return this.getProperty('Protokollierung'); }
    public set Protokollierung(value: ListType) { this.setProperty('Protokollierung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AnzeigenInSuche(): ListType { return this.getProperty('AnzeigenInSuche'); }
    public set AnzeigenInSuche(value: ListType) { this.setProperty('AnzeigenInSuche', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AnzeigenInController(): ListType { return this.getProperty('AnzeigenInController'); }
    public set AnzeigenInController(value: ListType) { this.setProperty('AnzeigenInController', value); }
    
    @Expose()
    @Type(() => ListType)
    public get PaperKind(): ListType { return this.getProperty('PaperKind'); }
    public set PaperKind(value: ListType) { this.setProperty('PaperKind', value); }
    
    @Expose()
    @Type(() => ListType)
    public get PaperOrientation(): ListType { return this.getProperty('PaperOrientation'); }
    public set PaperOrientation(value: ListType) { this.setProperty('PaperOrientation', value); }
    
}
