import { TooltipModule } from 'primeng/tooltip';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { AviCoreModule } from '@avi-x/avi-core';

import { TranslateModule } from '@ngx-translate/core';
import { AviEmailConfigFormComponent } from './components/emailconfig-form/emailconfig-form.component';
import { AviEmailFormComponent } from './components/email-form/email-form.component';
import { HighlightModule } from 'ngx-highlightjs';
import { AviEmailConfigTestFormComponent } from './components/emailconfig-form/emailconfigtest-form.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        
        HighlightModule,

        AviCoreModule,
        InputTextModule,
        InputTextareaModule,
        ButtonModule,
        CheckboxModule,
        MultiSelectModule,
        TranslateModule,
        SplitButtonModule,
        TooltipModule

    ],
    exports: [
        AviEmailConfigFormComponent,
        AviEmailFormComponent,
        AviEmailConfigTestFormComponent
    ],
    declarations: [
        AviEmailConfigFormComponent,
        AviEmailFormComponent,
        AviEmailConfigTestFormComponent
    ]
})
export class AviEmailModule { }
