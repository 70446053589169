import { VwbPPublicFormularFormComponent } from './../formular-form/formular-form.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { AviApiService, AviBaseSearcherComponent, AviCommonService, AviDialogComponent, AviSearcherColumn } from '@avi-x/avi-core';
import { ActivatedRoute } from '@angular/router';
import { VwbPFormularDaten } from '../../../dto/vwbpformulardaten.model';
import { VwbPListDetailConst } from '../../../constants/constants';
import { VwbPPublicVwEGFormularFormComponent } from '../formular-form/vweg-formular-form.component';
import { VwbPCommonService } from '../../../common/vwbp-common.service';

@Component({
    selector: 'vwbp-public-formular-liste',
    templateUrl: './formular-liste.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPPublicFormularListeComponent {
    IntVisCols: string[] = ['NameVorname', 'WBPartnerTyp', 'FormularTyp', 'Rollen2', 'btnAct'];
    VisibleColumns: string[] = [...this.IntVisCols];

    FormularMode: 'vweg' | 'vwbp' = 'vwbp';

    formActionButtons: any[] = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];
    formActionButtonsReadonly: any[] = [{ title: 'CORE.COMMON.SCHLIESSEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];

    @ViewChild('VwbPFormularForm', { static: false })
    public VwbPFormularForm: VwbPPublicFormularFormComponent;

    @ViewChild('VWeGFormularForm', { static: false })
    public VwEGFormularForm: VwbPPublicVwEGFormularFormComponent;

    @ViewChild('FormularFormDialog', { static: false })
    FormularFormDialog: AviDialogComponent;

    @ViewChild('formularListe', { static: true })
    public formularListe: AviBaseSearcherComponent;

    @Input('formular-daten')
    public FormularDaten: VwbPFormularDaten[];

    @Input('can-edit')
    public CanEdit: boolean = false;

    public _ShowSchwaerzen: boolean = false;

    @Input('show-schwaerzen')
    public set ShowSchwaerzen(value: boolean) {
        this._ShowSchwaerzen = value;
        if (this.ShowSchwaerzen)
            this.VisibleColumns = ['btnschwaerzen', ...this.IntVisCols];
        else
            this.VisibleColumns = [...this.IntVisCols];

        setTimeout(() => {
            this.formularListe?.initColumns();
            this.formularListe?.forceRefresh();
        });
    }

    public get ShowSchwaerzen(): boolean {
        return this._ShowSchwaerzen;
    }

    @Input('context')
    public Context: string = null;

    @Input('api')
    public Api: string = 'v1/vwbp';

    @Input('hide-when-noresults')
    public HideWhenNoresults: boolean = false;

    @Output('formular-changed')
    FormularChanged: EventEmitter<any> = new EventEmitter;

    public SearchDelegate: any = async (searchValue: string) => this.doSearch();

    doSearch(): Promise<any> {
        return new Promise((resolve) => {
            return resolve(this.formularListe.ApplyColumnSorting(this.FormularDaten));
        });
    }

    constructor(private activatedRoute: ActivatedRoute, private apiService: AviApiService, private commonService: AviCommonService, private vwbpCommonService: VwbPCommonService, private cdr: ChangeDetectorRef) {
    }

    onFormularListInit(data) {
        // const isAJUUserSchreiben = this.vwbpCommonService.currentUserIsAjuUserSchreiben();

        this.formularListe.beginInit();

        this.formularListe.addColumn(
            AviSearcherColumn.CreateButton('btnschwaerzen', ' ', null, null, null, null, '40px', ['black'])
                .setTooltipDelegate((row, col, buttonId) => {
                    const schwaerzenStatus = row['SchwaerzenStatus'].Id;
                    if (schwaerzenStatus === VwbPListDetailConst.VWBP_FORMULAR_SCHWAERZEN_STATUS_ANTRAG)
                        return 'Eine Einschränkung der Offenlegung wurde beantragt';
                    else if (schwaerzenStatus === VwbPListDetailConst.VWBP_FORMULAR_SCHWAERZEN_STATUS_FRIST_3MT)
                        return 'Die Einschränkung der Offenlegung läuft in weniger als 3 Monaten ab';
                    else if (schwaerzenStatus === VwbPListDetailConst.VWBP_FORMULAR_SCHWAERZEN_STATUS_BEWILLIGT)
                        return 'Die Einschränkung der Offenlegung wurde bewilligt';

                    return '';
                })
                .setButtonOnClickDelegate((row, col, buttonId) => {

                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-flat': true,
                        'p-button-text': true,
                        'p-button-rounded': true,
                        'p-button-black-icon': row['SchwaerzenStatus'].Id === VwbPListDetailConst.VWBP_FORMULAR_SCHWAERZEN_STATUS_BEWILLIGT,
                        'p-button-red-icon': row['SchwaerzenStatus'].Id === VwbPListDetailConst.VWBP_FORMULAR_SCHWAERZEN_STATUS_ANTRAG,
                        'p-button-orange-icon': row['SchwaerzenStatus'].Id === VwbPListDetailConst.VWBP_FORMULAR_SCHWAERZEN_STATUS_FRIST_3MT,
                        'hidden': row['WBPartnerTypId'] === VwbPListDetailConst.VWBP_WBPARTNERTYP_KDB
                    };
                })
                .setButtonIconDelegate((row, col, buttonId) => {
                    const schwaerzenStatus = row['SchwaerzenStatus'].Id;
                    if (schwaerzenStatus !== VwbPListDetailConst.VWBP_FORMULAR_SCHWAERZEN_STATUS_KEIN)
                        return 'pi pi-star-fill'; // : 'pi pi-star-o';
                })
                .setCellStyleDelegate(() => {
                    return { 'text-align': 'left' };
                })
                .setSortable(false)
                // .setDisabledDelegate((val) => !isAJUUserSchreiben)
        );

        this.formularListe.addTextColumn('Vorname', 'Vorname').setVisible(false);
        this.formularListe.addTextColumn('Name', 'Name').setVisible(false);
        this.formularListe.addTextColumn('RechtstraegerName', 'RechtstraegerName').setVisible(false);

        const colName = AviSearcherColumn.CreateCustom(
            'NameVorname',
            'VWBP.PUBLIC.FORM.NAME',
            (row, col) => {
                if (row['WBPartnerTypId'] === VwbPListDetailConst.VWBP_WBPARTNERTYP_KDB)
                    return this.commonService.nl2br(this.commonService.escapeHtml(row['KDB']));
                else if (row['WBPartnerTypId'] === VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON)
                    return row['Name'] && row['Vorname'] ? this.commonService.nl2br(this.commonService.escapeHtml(row['Vorname'] + ', ' + row['Name'])) : '';
                else
                    return this.commonService.nl2br(this.commonService.escapeHtml(row['RechtstraegerName']));
            },
            false);
        colName.SortColumn = 'Name';
        this.formularListe.addColumn(colName);

        this.formularListe.addTextColumn('WBPartnerTypId', 'PID').setVisible(false);
        this.formularListe.addListTypeColumn('WBPartnerTyp', 'VWBP.PUBLIC.FORM.PARTNERTYP', 'vwbp_wbpartnertyp', true, true, null, 'v1/vwbp');

        this.formularListe.addListTypeColumn('FormularTyp', 'VWBP.PUBLIC.FORM.FORMULARTYP', 'vwbp_formulartyp', true, true, null, 'v1/vwbp');

        this.formularListe.addTextColumn('Rollen', 'Rolle(n)').setVisible(false);

        const colRolle = AviSearcherColumn.CreateCustom(
            'Rollen2',
            'VWBP.PUBLIC.FORM.ROLLEN',
            (row, col) => {
                return row['Rollen'].replace(/(?:\r\n|\r|\n)/g, '<br>');
            },
            false
        );
        colRolle.SortColumn = 'Rollen';
        this.formularListe.addColumn(colRolle);


        this.formularListe.addColumn(
            AviSearcherColumn.CreateButton('btnAct', 'VWBP.COMMON.AKTIONEN', null, null, null, null, '120px', ['show', 'edit', 'delete'])
                .setTooltipDelegate((row, col, buttonId) => {
                    if (buttonId === 'show')
                        return 'Formular anzeigen';
                    else if (buttonId === 'edit')
                        return 'Formular ändern';
                    else if (buttonId === 'delete')
                        return 'Formular löschen';
                })
                .setButtonOnClickDelegate((row, col, buttonId) => {
                    if (buttonId === 'show')
                        this.showFormular(row['Id'], row['FormularTyp']);
                    else if (buttonId === 'edit')
                        this.editFormular(row['Id']);
                    else if (buttonId === 'delete')
                        this.deleteFormular(row['Id']);
                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-flat': true,
                        'p-button-text': true,
                        'p-button-rounded': true,
                        'hidden': (buttonId === 'edit' || buttonId === 'delete') && (!this.CanEdit || row['FormularTyp'].Id === VwbPListDetailConst.VWBP_FORMULARTYP_VWEG_C || row['FormularTyp'].Id === VwbPListDetailConst.VWBP_FORMULARTYP_VWEG_T)
                    };
                })
                .setButtonIconDelegate((row, col, buttonId) => {
                    if (buttonId === 'show')
                        return 'pi pi-eye';
                    else if (buttonId === 'edit')
                        return 'pi pi-pencil';
                    else if (buttonId === 'delete')
                        return 'pi pi-trash';
                })
                .setHeaderColumnClass('text-right')
                .setSortable(false)
        );

        this.formularListe.endInit();
    }


    private closeForm() {
        this.FormularFormDialog.close();
        this.VwbPFormularForm?.InitForm(null);
        this.VwEGFormularForm?.InitForm(null);
    }

    public async showFormular(formId, formTyp) {
        if (formTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_VWEG_C || formTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_VWEG_T) {
            this.FormularMode = 'vweg';
            this.cdr.detectChanges();
            await this.VwEGFormularForm.InitForm(formId);
        } else {
            this.FormularMode = 'vwbp';
            this.cdr.detectChanges();
            this.VwbPFormularForm.ReadOnly = true;
            await this.VwbPFormularForm.InitForm(formId);
        }
        this.FormularFormDialog.open();
    }

    public async editFormular(formId) {
        this.VwbPFormularForm.ReadOnly = false;
        await this.VwbPFormularForm.InitForm(formId);
        this.FormularFormDialog.open();
    }

    public async deleteFormular(formId) {
        this.commonService.confirmDelete(`Möchten Sie das Formular wirklich löschen?`,
            () => {
                this.apiService.delete(`${this.Api}/formular/${formId}`).then(r => {
                    this.commonService.notificateSuccess('Gelöscht');
                    this.FormularChanged.emit(null);
                });
            }
        );
    }

    public async createFormularC() {
        this.VwbPFormularForm.ReadOnly = false;
        await this.VwbPFormularForm.InitForm('0', VwbPListDetailConst.VWBP_FORMULARTYP_C, this.Context);
        this.FormularFormDialog.open();
    }

    public async createFormularT() {
        this.VwbPFormularForm.ReadOnly = false;
        await this.VwbPFormularForm.InitForm('0', VwbPListDetailConst.VWBP_FORMULARTYP_T, this.Context);
        this.FormularFormDialog.open();
    }

    onFormularSavedSuccessful(event) {
        this.FormularFormDialog.close();
        this.VwbPFormularForm.InitForm(null);
        this.FormularChanged.emit(null);
    }

    public async forceRefresh() {
        this.formularListe.forceRefresh();
    }
}
