import { VwbPActionConst, VwbPActionData, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { Injectable } from '@angular/core';
import { AviApiService, AviCommonService, AviJobService, AviListDetailConst } from '@avi-x/avi-core';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerSerienbriefDialogComponent } from '../components/rechtstraeger-dokumente/rechtstraeger-serienbrief-dialog.component';
import { first, tap } from 'rxjs/operators';
import { RTVerMode } from '../components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-searcher.component';
import { VwbPInternPostfachService } from '../../postfach/services/postfach.service';
import { VwbPInternRechtstraegerAdresseDialogComponent } from '../components/rechtstraeger-adresse-searcher/rechtstraeger-adresse-dialog.component';
import { VwbPInternBehoerdenVersionDialogComponent } from '../../behoerden/components/behoerden-version-searcher/behoerden-version-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class VwbPInternRechtstraegerService {
    constructor(private apiService: AviApiService,
                private commonService: AviCommonService,
                private jobService: AviJobService,
                private dialogService: DialogService,
                private postfachService: VwbPInternPostfachService) { }

    async SetFreigegeben(rechtstraegerIds: string[], bemerkung: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_RECHTSTRAEGER_STATUS_SETZEN,
            Status: VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN,
            Bemerkung: bemerkung
        };
        return await this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/rechtstraeger/action', data);
    }

    async FreigabeRueckgaengig(rechtstraegerIds: string[], bemerkung: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_RECHTSTRAEGER_STATUS_SETZEN_RUECKGAENGIG,
            Status: VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN,
            Bemerkung: bemerkung
        };
        return await this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/rechtstraeger/action', data);
    }

    async SetVereinfachteValidierung(rechtstraegerIds: string[], bemerkung: string, vereinfachteValidierung: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_RECHTSTRAEGER_SET_VEREINFACHTE_VALIDIERUNG,
            Status: vereinfachteValidierung,
            Bemerkung: bemerkung
        };
        return await this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/rechtstraeger/action', data);
    }

    async SetBemerkungTeilfonds(rechtstraegerIds: string[], bemerkung: string, bemerkungTeilfonds: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_RECHTSTRAEGER_SET_BEMERKUNG_TEILFONDS,
            Status: bemerkungTeilfonds,
            Bemerkung: bemerkung
        };
        return await this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/rechtstraeger/action', data);
    }

    async SetEingetragenEWRRegister(rechtstraegerIds: string[], bemerkung: string, eingetragenEWRRegister: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_RECHTSTRAEGER_SET_EINGETRAGEN_EWR_REGISTER,
            Status: eingetragenEWRRegister,
            Bemerkung: bemerkung
        };
        return await this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/rechtstraeger/action', data);
    }

    async OpenSerienbriefJobDialog(modelIds: string[], mode: RTVerMode) {
        if (modelIds?.length === 0)
            return;

        const data = await this.OpenSerienbriefDialog(
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.SERIENBRIEF_HDR'),
            modelIds, mode);

        if (data) {
            const jobId: string = await this.apiService.post('v1/intern/vwbp/document/serienbrief', data);

            if (jobId) {
                const jobStatus = await this.jobService.OpenJobDialog(jobId, 'VWBP.INTERN.DOKUMENTE.SERIENBRIEF_BUTTON', 'v1/intern/vwbp/job', false);
                if (jobStatus === AviListDetailConst.JOB_STATUS_SUCCEEDED)
                    this.postfachService.OpenPostfach();
            }
        }
    }

    async OpenExcelExportJobDialog(modelIds: string[]) {
        if (modelIds?.length === 0)
            return;

        const rtIds = await this.OpenRTAdresseDialog(
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.EXCEL_EXPORT_HDR'),
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.EXCEL_EXPORT_MSG'),
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.EXCEL_EXPORT_BUTTON'),
            modelIds, RTVerMode.RECHTSTRAEGER);

        if (rtIds && rtIds.length > 0) {
            const jobId: string = await this.apiService.post('v1/intern/vwbp/document/excel_export', { ModelIds: rtIds });

            if (jobId) {
                const jobStatus = await this.jobService.OpenJobDialog(jobId, 'VWBP.INTERN.DOKUMENTE.EXCEL_EXPORT_HDR', 'v1/intern/vwbp/job', false);
                if (jobStatus === AviListDetailConst.JOB_STATUS_SUCCEEDED)
                    this.postfachService.OpenPostfach();
            }
        }
    }

    async OpenBehoerdenExcelExportJobDialog(modelIds: string[], mode: RTVerMode) {
        if (modelIds?.length === 0)
            return;

        const rtIds = await this.OpenBehoerdenVersionDialog(
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.EXCEL_EXPORT_HDR'),
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.EXCEL_EXPORT_MSG'),
            this.commonService.translateInstant('VWBP.INTERN.DOKUMENTE.EXCEL_EXPORT_BUTTON'),
            modelIds, mode);

        if (rtIds && rtIds.length > 0) {
            const jobId: string = await this.apiService.post('v1/intern/vwbp/document/behoerden_excel_export', { ModelIds: rtIds });

            if (jobId) {
                const jobStatus = await this.jobService.OpenJobDialog(jobId, 'VWBP.INTERN.DOKUMENTE.EXCEL_EXPORT_HDR', 'v1/intern/vwbp/job', false);
                if (jobStatus === AviListDetailConst.JOB_STATUS_SUCCEEDED)
                    this.postfachService.OpenPostfach();
            }
        }
    }

    public async OpenBehoerdenVersionDialog(header: string, message: string, acceptlabel: string, rtIds: string[], mode: RTVerMode): Promise<string[]> {
        const ref = this.dialogService.open(VwbPInternBehoerdenVersionDialogComponent, {
            data: {
                modelIds: rtIds,
                message: message,
                acceptLabel: acceptlabel,
                mode: mode,
                formType: VwbPInternBehoerdenVersionDialogComponent
            },
            header: header,
            width: '80%',
            closable: false,
            closeOnEscape: false,
            contentStyle: { 'max-height': '90vh', 'overflow': 'auto' },
            styleClass: 'p-input-filled',
            baseZIndex: 10000
        });

        const res = await ref
            .onClose
            .pipe(tap((data: string[]) => data), first())
            .toPromise();

        ref.destroy();

        return res;
    }

    public async OpenRTAdresseDialog(header: string, message: string, acceptlabel: string, rtIds: string[], mode: RTVerMode): Promise<string[]> {
        const ref = this.dialogService.open(VwbPInternRechtstraegerAdresseDialogComponent, {
            data: {
                modelIds: rtIds,
                message: message,
                acceptLabel: acceptlabel,
                mode: mode,
                formType: VwbPInternRechtstraegerAdresseDialogComponent
            },
            header: header,
            width: '80%',
            closable: false,
            closeOnEscape: false,
            contentStyle: { 'max-height': '90vh', 'overflow': 'auto' },
            styleClass: 'p-input-filled',
            baseZIndex: 10000
        });

        const res = await ref
            .onClose
            .pipe(tap((data: string[]) => data), first())
            .toPromise();

        ref.destroy();

        return res;
    }

    public async OpenSerienbriefDialog(header: string, modelIds: string[], mode: RTVerMode): Promise<any[]> {
        const ref = this.dialogService.open(VwbPInternRechtstraegerSerienbriefDialogComponent, {
            data: {
                modelIds: modelIds,
                mode: mode,
                formType: VwbPInternRechtstraegerSerienbriefDialogComponent
            },
            header: header,
            width: '80%',
            closable: false,
            closeOnEscape: false,
            contentStyle: { 'max-height': '90vh', 'overflow': 'auto' },
            styleClass: 'p-input-filled',
            baseZIndex: 10000
        });

        const res = await ref
            .onClose
            .pipe(tap((data: any[]) => data), first())
            .toPromise();

        ref.destroy();

        return res;
    }
}
