<span [ngSwitch]="SelectedDetail.Typ.Id" class="panel-header">
    <div *ngSwitchCase="VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_AUSLAENDISCH"
        class="grid m-0">

        <div class="col-6 lg:col-3 summary-label">
            <ng-container *ngIf="SelectedDetail.RegisterNummer">{{ 'VWBP.COMMON.REGISTERNR' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedDetail.RegisterOrt">{{ 'VWBP.COMMON.REGISTERORT' | translate }}<br /></ng-container>
            <ng-container *ngIf="InterneId && !IsBehoerdenUser">{{ 'VWBP.COMMON.INTERNEID' | translate }}<br /></ng-container>
            <!-- <ng-container *ngIf="SelectedVersion && (IsAJUSchreibenUser || IsSchwaerzungVorhanden())">{{ 'VWBP.COMMON.SCHWAERZUNG_VORHANDEN' | translate }}<br /></ng-container> -->
            <ng-container *ngIf="(IsAJUSchreibenUser || IsEingetragenEWRRegister())">{{ 'VWBP.COMMON.EINGETRAGEN_EWR_REGISTER' | translate }}</ng-container>
        </div>

        <div class="col-6 lg:col-3 ">
            <ng-container *ngIf="SelectedDetail.RegisterNummer">{{ SelectedDetail.RegisterNummer }}<br /></ng-container>
            <ng-container *ngIf="SelectedDetail.RegisterOrt">{{ SelectedDetail.RegisterOrt }}<br /></ng-container>
            <ng-container *ngIf="InterneId && !IsBehoerdenUser">{{ InterneId }}<br /></ng-container>
            <!-- <p-checkbox *ngIf="SelectedVersion && (IsAJUSchreibenUser || IsSchwaerzungVorhanden())" class="mb-2" [ngModel]="IsSchwaerzungVorhanden()" [readonly]="true"
                (click)="onSchwaerzungChange($event)" name="verval" [binary]="true"></p-checkbox> -->
            <p-checkbox *ngIf="(IsAJUSchreibenUser || IsEingetragenEWRRegister())" class="mb-2" [ngModel]="IsEingetragenEWRRegister()" [readonly]="true"
                (click)="onEingetragenEWRRegisterChange($event)" name="verval" [binary]="true"></p-checkbox>
        </div>

        <div class="col-6 lg:col-3 summary-label">
            <ng-container *ngIf="!IsBehoerdenUser">{{ 'VWBP.COMMON.ERFASSUNGSSTATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="!SelectedVersion && !IsBehoerdenUser && IsAJUUser">{{ 'VWBP.COMMON.HRSTATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT && IsMahnprozess() && !IsBehoerdenUser && IsAJUUser">{{ 'VWBP.INTERN.MAHNWESEN.STATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="Unstimmigkeit && !IsBehoerdenUser && IsAJUUser">{{ 'VWBP.INTERN.UNSTIMMIGKEITEN.STATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.HrLoeschungsdatum">{{ 'VWBP.PUBLIC.RT_LOESCHUNGSDATUM' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.AbgegebenAm">{{ 'VWBP.COMMON.ABGEGEBEN_AM' | translate }}<br /></ng-container>
        </div>

        <div class="col-6 lg:col-3 ">
            <span *ngIf="!IsBehoerdenUser" [ngClass]="{ 'color-red': isInBearbeitung() }">{{ GetErfassungsstatus() }}<br /></span>
            <span *ngIf="!SelectedVersion && !IsBehoerdenUser && IsAJUUser" [ngClass]="{ 'color-red': true }">{{ SelectedRT.HrStatus.Bezeichnung }}<br /></span>
            <span *ngIf="SelectedRT && IsMahnprozess() && !IsBehoerdenUser && IsAJUUser" [ngClass]="{ 'color-red': !IsMahnprozessAbgeschlossen() }">{{ getMahnwesenStatus() }}<br /></span>
            <span *ngIf="Unstimmigkeit && !IsBehoerdenUser && IsAJUUser" [ngClass]="{ 'color-red': !IsUnstimmigkeitProzessAbgeschlossen() }">{{ getUnstimmigkeitStatus() }}<br /></span>
            <span *ngIf="SelectedRT.HrLoeschungsdatum" [ngClass]="{ 'color-red': true }">{{ SelectedRT.HrLoeschungsdatum | date: 'shortDate' }}<br /></span>
            <span *ngIf="SelectedRT.AbgegebenAm" [ngClass]="{ 'color-red': true }">{{ SelectedRT.AbgegebenAm | date: 'shortDate'}}</span>
        </div>
    </div>

    <div *ngSwitchCase="VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_LI_HRNR"
        class="grid m-0">

        <div class="col-6 lg:col-3 summary-label">
            {{ 'VWBP.COMMON.REGISTERNR' | translate }}<br />
            {{ 'VWBP.COMMON.RECHTSFORM' | translate }}
            <ng-container *ngIf="SelectedDetail.Sitz"><br />{{ 'VWBP.COMMON.SITZ' | translate }}</ng-container>
            <ng-container *ngIf="InterneId && !IsBehoerdenUser"><br />{{ 'VWBP.COMMON.INTERNEID' | translate }}</ng-container>
            <ng-container *ngIf="SelectedVersion && (IsAJUSchreibenUser || IsValidierungAusnahme())"><br />{{ 'VWBP.COMMON.VALIDIERUNG_AUSNAHME' | translate }}</ng-container>
            <!-- <ng-container *ngIf="SelectedVersion && (IsAJUSchreibenUser || IsSchwaerzungVorhanden())"><br />{{ 'VWBP.COMMON.SCHWAERZUNG_VORHANDEN' | translate }}</ng-container> -->
            <ng-container *ngIf="IsTrust() && (IsAJUSchreibenUser || IsEingetragenEWRRegister())"><br />{{ 'VWBP.COMMON.EINGETRAGEN_EWR_REGISTER' | translate }}</ng-container>
            <ng-container *ngIf="SelectedVersion && (IsAJUSchreibenUser || IsBemerkungTeilfonds())"><br />{{ 'VWBP.COMMON.BEMERKUNG_TEILFONDS' | translate }}</ng-container>
        </div>

        <div class="col-6 lg:col-3 ">
            {{ SelectedDetail.RegisterNummer }}<br />
            {{ SelectedDetail.Rechtsform }}
            <ng-container *ngIf="SelectedDetail.Sitz"><br />{{ SelectedDetail.Sitz }}</ng-container>
            <ng-container *ngIf="InterneId && !IsBehoerdenUser"><br />{{ InterneId }}</ng-container>
            <p-checkbox *ngIf="SelectedVersion && (IsAJUSchreibenUser || IsValidierungAusnahme())" class="mb-2" [ngModel]="IsValidierungAusnahme()" [readonly]="true"
                (click)="onValidierungAusnahmeChange($event)" name="verval" [binary]="true"></p-checkbox>
            <!-- <p-checkbox *ngIf="SelectedVersion && (IsAJUSchreibenUser || IsSchwaerzungVorhanden())" class="mb-2" [ngModel]="IsSchwaerzungVorhanden()" [readonly]="true"
                (click)="onSchwaerzungChange($event)" name="verval" [binary]="true"></p-checkbox> -->
            <p-checkbox *ngIf="IsTrust() && (IsAJUSchreibenUser || IsEingetragenEWRRegister())" class="mb-2" [ngModel]="IsEingetragenEWRRegister()" [readonly]="true"
                (click)="onEingetragenEWRRegisterChange($event)" name="verval" [binary]="true"></p-checkbox>
            <p-checkbox *ngIf="SelectedVersion && (IsAJUSchreibenUser || IsBemerkungTeilfonds())" class="mb-2" [ngModel]="IsBemerkungTeilfonds()" [readonly]="true"
                (click)="onBemerkungTeilfondsChange($event)" name="verval" [binary]="true"></p-checkbox>
        </div>

        <div class="col-6 lg:col-3 summary-label">
            <ng-container *ngIf="SelectedVersion && !IsBehoerdenUser">{{ 'VWBP.COMMON.ERFASSUNGSSTATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="!SelectedVersion && !IsBehoerdenUser && IsAJUUser">{{ 'VWBP.COMMON.HRSTATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT && IsMahnprozess() && !IsBehoerdenUser && IsAJUUser">{{ 'VWBP.INTERN.MAHNWESEN.STATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="Unstimmigkeit && !IsBehoerdenUser && IsAJUUser">{{ 'VWBP.INTERN.UNSTIMMIGKEITEN.STATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.HrLoeschungsdatum">{{ 'VWBP.PUBLIC.RT_LOESCHUNGSDATUM' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.AbgegebenAm">{{ 'VWBP.COMMON.ABGEGEBEN_AM' | translate }}<br /></ng-container>
        </div>
        <div class="col-6 lg:col-3 ">
            <span *ngIf="SelectedVersion && !IsBehoerdenUser" [ngClass]="{ 'color-red': isInBearbeitung() }">{{ SelectedVersion?.Erfassungsstatus.Bezeichnung }}<br /></span>
            <span *ngIf="!SelectedVersion && !IsBehoerdenUser && IsAJUUser" [ngClass]="{ 'color-red': true }">{{ SelectedRT.HrStatus.Bezeichnung }}<br /></span>
            <span *ngIf="SelectedRT && IsMahnprozess() && !IsBehoerdenUser && IsAJUUser" [ngClass]="{ 'color-red': !IsMahnprozessAbgeschlossen() }">{{ getMahnwesenStatus() }}<br /></span>
            <span *ngIf="Unstimmigkeit && !IsBehoerdenUser && IsAJUUser" [ngClass]="{ 'color-red': !IsUnstimmigkeitProzessAbgeschlossen() }">{{ getUnstimmigkeitStatus() }}<br /></span>
            <span *ngIf="SelectedRT.HrLoeschungsdatum" [ngClass]="{ 'color-red': true }">{{ SelectedRT.HrLoeschungsdatum | date: 'shortDate' }}<br /></span>
            <span *ngIf="SelectedRT.AbgegebenAm" [ngClass]="{ 'color-red': true }">{{ SelectedRT.AbgegebenAm | date: 'shortDate'}}</span>
        </div>
    </div>
</span>
