import { AfterViewInit, Directive } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import Inputmask from 'inputmask';
import { AviCalendarComponent } from '../components/controls/calendar/calendar.component';

@Directive({
    selector: '[avi-calendar-mask]',
})
export class AviCalendarMaskDirective implements AfterViewInit {
    constructor(private primeCalendar: AviCalendarComponent) {}

    ngAfterViewInit() {
        new Inputmask('datetime', { inputFormat: this.getDateMask(), autoUnmask: true, min: '01.01.1753', max: '31.12.9999' }).mask(
            this.getHTMLInput()
        );
    }

    getHTMLInput(): HTMLInputElement {
        return this.primeCalendar._calendar.el.nativeElement.querySelector('input');
    }

    getDateMask(): string {
        if (this.primeCalendar.timeOnly) {
            return 'HH:MM';
        } else if (this.primeCalendar.showTime) {
            return 'dd.mm.yyyy HH:MM';
        } else {
            return 'dd.mm.yyyy';
        }
    }
}
