import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviCommonService, AviAuthService, AviBaseFormComponent, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { VerlaufDto } from '@avi-x/avi-dto/system/verlauf.model';

const nameof = (nameFunction: ((obj: VerlaufDto) => any)) => typed_nameof<VerlaufDto>(nameFunction);

@Component({
    selector: 'avi-verlauf-form',
    template: `
    <avi-core-base-form #form
        page-style-class="bg-white"
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        [action-buttons]="actionButtons">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviVerlaufFormComponent implements OnInit, OnDestroy {
    private _className = 'Verlauf';

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('kontext-id')
    public KontextId: string = null;

    @Input('kontext2-id')
    public Kontext2Id: string = null;

    public Loading: boolean = false;

    private _ModelId: string = null;
    public get ModelId(): string {
        return this._ModelId;
    }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    public Model: VerlaufDto = null;

    public Fields: AviFormField[] = [];

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {
        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));
        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    ngOnInit() {
        this.initFields();
    }


    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    public async initNewModel() {
    }

    async loadModel(id: string) {
        try {
            this.commonService.showGlobalLoader();
            this.Model = await this.apiService.FindModel(VerlaufDto, `verlauf/${id}`, null, null, null);
        } finally {
            this.commonService.hideGlobalLoader();
        }

        if (!this.ReadOnly)
            this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        return this.Model;
    }

    saveModel(model: VerlaufDto) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = this.apiService.put(`verlauf/${model.Id}`, model);

        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    ngOnDestroy() {
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Username), 'Sachbearbeiter').setMDSizeFull().setReadonly());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.EventBez), 'Event').setMDSizeFull().setReadonly());

        const colDatum = this.formFieldService.CreateDate(nameof(c => c.Datum), 'Datum', true).setMDSizeFull().setReadonly();
        colDatum.DateTime = true;
        this.Fields.push(colDatum);

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Bemerkung), 'Bemerkung', false).setMDSizeFull().setMaxLength(4096));
    }
}
