import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

export interface GoogleMapsAdresse {
    LandISO: string;
    Strasse: string;
    PLZ: string;
    Ort: string;
}

@Injectable({
    providedIn: 'root'
})
export class AviCommunicationService {

    constructor(private sanitizer: DomSanitizer) {
    }

    public StartCall(number: string) {
        number = number.replace(/[a-zA-Z]/g, '');

        const w = 500;
        const h = 500;
        const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
        return window.open(`https://192.168.1.134:5001/webclient/#/call?phone=${number}`, '_blank', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
    }

    public StartEmail(adress: string) {
        location.href = `mailto:${adress}`;
    }

    public OpenHomepage(address: string) {
        address = !/^https?:\/\//i.test(address) ? `http://${address}` : address;
        return window.open(address, '_blank');
    }

    public  GetGoogleMapsUrl(item: GoogleMapsAdresse) {
        const landAddon = item.LandISO ? `${item.LandISO}` : '';
        const url = `https://www.google.com/maps/embed/v1/place?key=AIzaSyDb4R0Yzacu1E3QsP8qHqMIkc3TXOYLxGA&q=${encodeURI(item.Strasse)}%2C${encodeURI(item.PLZ)}%20${encodeURI(item.Ort)}%2C${landAddon}`;
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

}
