import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService } from '@avi-x/avi-core';
import { AviLandFormComponent } from './components/land-form/land-form.component';
import { AviLandSearcherComponent } from './components/land-searcher/land-searcher.component';

const routes: Routes = [
    { path: 'land', component: AviLandSearcherComponent, canActivate: [AviCanActivateWhenLoggedInService] },
    { path: 'land/form/:id', component: AviLandFormComponent, canActivate: [AviCanActivateWhenLoggedInService] }
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviLandRoutingModule {
}
