import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof } from '@avi-x/avi-core';
import { ScriptRuleOutput } from '@avi-x/avi-dto/system/scriptruleoutput.model';
import { AviScriptRuleOutputFormComponent } from './scriptruleoutput-form.component';

const nameof = (nameFunction: ((obj: ScriptRuleOutput) => any)) => typed_nameof<ScriptRuleOutput>(nameFunction);

@Component({
    selector: 'avi-scriptruleoutput-list',
    templateUrl: './scriptruleoutput-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRuleOutputListComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFSCRIPTRULEOUTPUT';
    SettingsKey = 'settings.list.scriptruleoutput';
    BaseUrl = 'scriptruleoutput';
    FormType = AviScriptRuleOutputFormComponent;
	
    private _Results: ScriptRuleOutput[] = [];
    public get Results(): ScriptRuleOutput[] { return this._Results; }

    @Input('results')
    public set Results(value: ScriptRuleOutput[]) {
        this._Results = value;
        if (value)
            setTimeout(() => this.searcher.forceRefresh());
    }

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);
    }

    onSearcherInit(searcher) {
	    searcher.beginInit();
        searcher.addIntegerColumn(nameof(c => c.RuleId), 'Regel Nr.');
        searcher.addTextColumn(nameof(c => c.Message1), 'Bezeichnung');
        searcher.addDecimalColumn(nameof(c => c.OutputWert), 'Ergebnis', 2, 2);
        searcher.addListTypeColumn(nameof(c => c.Status), 'Status', 'caf_scriptruleoutput_status');        
        searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = this.Results;
        res = this.searcher.ApplyColumnFilters(res);
        res = this.searcher.ApplyColumnSorting(res);
        return res;
    }
}
