import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ScriptRulesetDef } from '@avi-x/avi-dto/system/scriptrulesetdef.model';
import { NgxPermissionsService } from 'ngx-permissions';

const nameof = (nameFunction: ((obj: ScriptRulesetDef) => any)) => typed_nameof<ScriptRulesetDef>(nameFunction);

@Component({
    selector: 'avi-scriptrulesetdef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRulesetDefFormComponent extends AviAbstractModelFormComponent<ScriptRulesetDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFSCRIPTRULESETDEF';
    BaseUrl = 'scriptrulesetdef';
    ClassType = ScriptRulesetDef;
    FormType = AviScriptRulesetDefFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
        public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);
        
        this.CRUDBaseRight = 'CAFScriptRulesetDef';
    }
    
    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1) && this.Model.Bezeichnung1 && !this.Model.Bezeichnung2)
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'Bezeichnung1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'Bezeichnung2', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'Gültig ab', false).setNullDateLow().setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'Gültig bis', false).setNullDateHigh().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Context_ID), 'Kontext', true, 'Representation', 'Id', 2, `model/representations/ModelMeta`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.SaveRuleOutput), 'Regeln speichern', 'caf_rulesetdef_saveoutput', true, true, this.ReadOnly).setMDSizeHalf());        
    }
}
