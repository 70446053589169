import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AviApiService, AviBaseFormComponent, AviBaseSearcherComponent, AviCommonService, AviDialogComponent } from '@avi-x/avi-core';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPAdminVorlageDefFormComponent } from '../vorlagedef-form/vorlagedef-form.component';


@Component({
    selector: 'vwbp-admin-vorlagedef-searcher',
    templateUrl: './vorlagedef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminVorlageDefSearcherComponent implements OnInit {
    public Loading: boolean = false;

    FormModelId: string = null;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @ViewChild('vorlageDefFormDialog', { static: false })
    vorlageDefFormDialog: AviDialogComponent;

    @ViewChild('vorlageDefForm', { static: false })
    public vorlageDefForm: VwbPAdminVorlageDefFormComponent;

    public Card = true;

    private SelectedRows: any[] = [];
    public DetailModelId: string = null;

    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];

    constructor(
        private commonService: AviCommonService,
        private apiService: AviApiService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {

    }

    async onSelectionChanged(data: any[]) {
        this.SelectedRows = data;

        if (this.SelectedRows.length > 0) {
            this.DetailModelId = this.SelectedRows[0].Id;
        }
    }

    onSearcherInit() {
        this.searcher.beginInit();
        // this.searcher.addTextColumn('TemplateBezeichnung', 'Vorlage');
        this.searcher.addTextColumn('Bezeichnung', 'Bezeichnung');
        this.searcher.addListTypeColumn('Format', 'VWBP.ADMIN.VORLAGEN.DATEI_FORMAT', 'vwbp_vorlage_format', true, false, null, 'v1/vwbp');
        this.searcher.addTextColumn('DocArtBezeichnung', 'Ablage Dokumentenart');
        this.searcher.addListTypeColumn('Typ', 'Typ', 'vwbp_vorlage_typ', true, false, null, 'v1/vwbp');
        this.searcher.addListTypeColumn('Task', 'Task', 'vwbp_vorlage_task', true, false, null, 'v1/vwbp').setFormatDelegate((record) => record.Typ.Id === VwbPListDetailConst.VWBP_VORLAGE_TYP_TASK ? record.Task.Bezeichnung : '');
        this.searcher.addListTypeColumn('Berechtigung', 'Berechtigung', 'vwbp_vorlage_berechtigung', true, false, null, 'v1/vwbp').setFormatDelegate((record) => record.Typ.Id === VwbPListDetailConst.VWBP_VORLAGE_TYP_RECHTSTRAEGER_VIEW ? record.Berechtigung.Bezeichnung : '');
        this.searcher.addListTypeColumn('SerienbriefMoeglich', 'Serienbrief', 'janein', true, false, null, 'v1/vwbp');

        this.searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        return this.apiService.post('v1/intern/vwbp/vorlagedef/list', { ...searchConfig });
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    onDoubleClickSearcher(data) {
        this.editVorlageDef();
    }


    public createVorlageDef() {
        this.FormModelId = '0';
        this.vorlageDefFormDialog.open();
    }

    public editVorlageDef() {
        if (this.SelectedRows.length > 0) {
            this.FormModelId = this.SelectedRows[0].Id;
            this.vorlageDefFormDialog.open();
        }
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.searcher.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.vorlageDefFormDialog.close();
    }

    public deleteVorlageDef() {
        if (this.SelectedRows.length > 0) {
            const id = this.SelectedRows[0].Id;

            this.commonService.confirmDelete(`Möchten Sie die Vorlage Zuweisung wirklich löschen?`,
                () => {
                    this.apiService.delete(`v1/intern/vwbp/vorlagedef/${id}`).then(r => {
                        this.commonService.notificateSuccess('Gelöscht');
                        this.searcher.forceRefresh();
                    });
                }
            );
        }
    }
}
