import { ChangeDetectorRef, Component, Injectable, Input, OnInit, Type, ViewChild } from '@angular/core';
import { ClassType } from '@avi-x/avi-dto/shared';
import { AviApiService } from '../../services/api.service';
import { AviCommonService } from '../../services/common.service';
import { AviBaseSearcherComponent } from './base-searcher.component';
import { ISessionData } from '../../services/session-controller.service';
import { Subscription } from 'rxjs';

@Component({
    template: ''
})
export abstract class AviAbstractModelSearcherComponent implements OnInit {
    abstract ClassName: string;
    abstract BaseUrl: string;
    abstract SettingsKey: string;
    abstract FormType: ClassType<any>;

    public get Title() {
        const isResource = this.ClassName.toUpperCase() === this.ClassName && this.ClassName.indexOf('.') > 0;
        if (isResource) {
            const cn = this.ClassName.substring(this.ClassName.lastIndexOf('.') + 1);
            return `${this.ClassName}.${cn}_N`;
        }

        return `${this.ClassName}`;
    }

    public Loading: boolean = false;

    @Input("session-data")
    SessionData: ISessionData;

    @ViewChild('searcher', { static: false })
    public searcher: AviBaseSearcherComponent;

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('enable-panel')
    public EnablePanel: boolean = true;

    @Input('card')
    public Card: boolean = false;

    @Input('auto-search')
    public AutoSearch = true;

    @Input('toolbar')
    public Toolbar: boolean = true;

    @Input('show-auto-filter')
    public ShowAutoFilter: boolean = true;

    private _ContextId: string = null;
    public get ContextId(): string { return this._ContextId; }

    @Input('context-id')
    public set ContextId(value: string) {
        const valueOld = this._ContextId;
        this._ContextId = value;
        if (value && value !== valueOld && this.searcher)
            setTimeout(() => this.searcher.forceRefresh());
    }

    SelectedRows: any = null;
    public DetailModelId: string = null;

    sessionChangedSubscription: Subscription;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
        public cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.sessionChangedSubscription = this.commonService.subscribeSessionChanged(sessionId => {
            if (this.SessionData && sessionId === this.SessionData?.Id && this.searcher)
                this.searcher.forceRefresh();
        });
    }

    ngOnDestroy() {
        if (this.sessionChangedSubscription)
            this.sessionChangedSubscription.unsubscribe();
    }

    async onSelectionChanged(data: any) {
        this.SelectedRows = data;

        if (this.SelectedRows) {
            this.DetailModelId = this.SelectedRows.Id;
        }
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    onDoubleClickSearcher(data) {
        this.editObject(this.SelectedRows);
    }

    protected getFormHeader(readonly: boolean, editmode: boolean): string {
        const isResource = this.ClassName.toUpperCase() === this.ClassName && this.ClassName.indexOf('.') > 0;
        if (isResource) {
            const cn = this.ClassName.substring(this.ClassName.lastIndexOf('.') + 1);
            if (readonly) return `${this.ClassName}.${cn}_1`;
            return editmode ? `${this.ClassName}.${cn}_EDIT` : `${this.ClassName}.${cn}_NEW`;
        }

        if (readonly) return this.ClassName;
        return this.ClassName + (editmode ? ' bearbeiten' : ' erstellen');
    }

    getParams(): { [k: string]: any; } {
        return null;
    }

    public async createObject() {
        await this.commonService.openFormDialog(this.FormType, this.getFormHeader(false, false), '0', this.ContextId, this.getParams(), true);
        this.onSavedSuccessful();
    }

    public async editObject(selectedRows) {
        if (selectedRows) {
            await this.commonService.openFormDialog(this.FormType, this.getFormHeader(false, true), selectedRows.Id, this.ContextId, this.getParams(), true);
            this.onSavedSuccessful();
        }
    }

    onSavedSuccessful() {
        this.searcher.forceRefresh();
    }

    public deleteObject(selectedRows) {

        if (selectedRows && this.BaseUrl) {
            const id = selectedRows.Id;

            this.commonService.confirmDelete(`Möchten Sie dieses Objekt wirklich löschen?`,
                () => {
                    this.apiService.delete(`${this.BaseUrl}/${id}`).then(r => {
                        this.commonService.notificateSuccess('Gelöscht');
                        this.searcher.forceRefresh();
                    });
                }
            );
        }
    }
}
