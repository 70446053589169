import { Expose } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';

export class PersonalContactData extends BaseModel {
    @Expose()
    public get PartnerId(): string { return this.getProperty('PartnerId'); }
    public set PartnerId(value: string) { this.setProperty('PartnerId', value); }

    @Expose()
    public get AktivId(): string { return this.getProperty('AktivId'); }
    public set AktivId(value: string) { this.setProperty('AktivId', value); }

    @Expose()
    public get Kurzz(): string { return this.getProperty('Kurzz'); }
    public set Kurzz(value: string) { this.setProperty('Kurzz', value); }

    @Expose()
    public get Name(): string { return this.getProperty('Name'); }
    public set Name(value: string) { this.setProperty('Name', value); }

    @Expose()
    public get Vorname(): string { return this.getProperty('Vorname'); }
    public set Vorname(value: string) { this.setProperty('Vorname', value); }

    @Expose()
    public get TelIntern(): string { return this.getProperty('TelIntern'); }
    public set TelIntern(value: string) { this.setProperty('TelIntern', value); }

    @Expose()
    public get TelInternMobile(): string { return this.getProperty('TelInternMobile'); }
    public set TelInternMobile(value: string) { this.setProperty('TelInternMobile', value); }

    @Expose()
    public get Email(): string { return this.getProperty('Email'); }
    public set Email(value: string) { this.setProperty('Email', value); }

    public get VornameName(): string {
        if (this.Vorname)
            return this.Vorname + ' ' + this.Name;

        return this.Name;
    }
}
