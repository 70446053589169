import { Injectable } from "@angular/core";
import { NXBookmark } from "@avi-x/avi-dto/system/nxbookmark.model";
import { AviApiService } from "./api.service";

@Injectable({ providedIn: 'root' })
export class AviBookmarkService {
    
    constructor(private apiService: AviApiService) {
    }

    public async createBookmark(): Promise<NXBookmark> {
        return await this.apiService.getModel(NXBookmark, 'nxbookmark/create');
    }

    public async writeBookmark(bm: NXBookmark): Promise<any> {
        return await this.apiService.post('nxbookmark', bm);
    }

    public async updateBookmark(bm: NXBookmark): Promise<any> {
        return await this.apiService.put(`nxbookmark/${bm.Id}`, bm);
    }

    public async loadBookmark(id: string): Promise<NXBookmark> {
        return await this.apiService.FindModel(NXBookmark, `nxbookmark/${id}`, null, null, null);
    }

    public async getBookmarks(): Promise<NXBookmark[]> {
        return await this.apiService.getModelList(NXBookmark, 'nxbookmark');
    }

    public async deleteBookmark(id: string): Promise<any> {
        return this.apiService.delete(`nxbookmark/${id}`);
    }
}
