import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { CustomPropDef } from '@avi-x/avi-dto/system/custompropdef.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: CustomPropDef) => any)) => typed_nameof<CustomPropDef>(nameFunction);

@Component({
    selector: 'avi-custompropdef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviCustomPropDefFormComponent extends AviAbstractModelFormComponent<CustomPropDef> {  

    ClassName = 'CORE.COMMON.MODEL.CAFCUSTOMPROPDEF';
    BaseUrl = 'custompropdef';
    ClassType = CustomPropDef;
    FormType = AviCustomPropDefFormComponent;

    fieldsProTyp: { [typ: string] : string[] } = {};

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
        public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

        this.CRUDBaseRight = 'CAFCustomPropCollectionDef';

        // this.fieldsProTyp[EMWListDetailConst.EMW_FORMULAR_FAKTORTYP_GRUPPE] = [
        //     nameof(c => c.Bezeichnung)
        // ];

        // this.fieldsProTyp[EMWListDetailConst.EMW_FORMULAR_FAKTORTYP_HEADER] = [
        // ];

        // this.fieldsProTyp[EMWListDetailConst.EMW_FORMULAR_FAKTORTYP_TOGGLER] = [
        // ];

        // this.fieldsProTyp[EMWListDetailConst.EMW_FORMULAR_FAKTORTYP_FAKTOR] = [
        // ];

        // this.fieldsProTyp[EMWListDetailConst.EMW_FORMULAR_FAKTORTYP_STEUERSATZ] = [
        // ];
    }

    public async afterInitForm() {
        this.initFromTyp();
    }
    
    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.PropertyType))
            this.initFromTyp();
    }

    public async createModel() {
        return await this.apiService.getModel(CustomPropDef, `${this.getBaseUrl()}/create/${this.ContextId}`);
    }

    initFromTyp() {
        // this.Fields.forEach(w => w.Visible = false);
        // this.fieldsProTyp[this.Model.PropertyType.Id].forEach(w => this.formFieldService.setFieldVisible(this.Fields, w, true));        
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.PropertyType), 'PropertyType', 'property_type', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.PropertyName), 'PropertyName', false).setMDSizeHalf());        

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sequenz), 'Sequenz', 0, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Columns), 'Columns', 0, false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'Bezeichnung1', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'Bezeichnung2', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Visibility), 'Visibility', 'caf_sichtbarkeit', true, true, this.ReadOnly).setMDSizeHalf());       
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.LabelPosition), 'LabelPosition', 'caf_text_location', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.MemoTextEdit), 'MemoTextEdit', null, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.MemoEditOptimalHeight), 'MemoEditOptimalHeight', null, false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.BulletedText), 'BulletedText', null, false).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.PropertyLength), 'PropertyLength', 0, false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Mandatory), 'Mandatory', 'janein', true, true, this.ReadOnly).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.IsReadOnlyInGui), 'IsReadOnlyInGui', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.MandatoryWennAttrName), 'MandatoryWennAttrName', false).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.MandatoryWennAttrValue), 'MandatoryWennAttrValue', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ReadonlyWennAttrName), 'ReadonlyWennAttrName', false).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ReadonlyWennAttrValue), 'ReadonlyWennAttrValue', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.WithoutLabel), 'WithoutLabel', null, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Nachkommastellen), 'Nachkommastellen', 0, false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.RadioGroupIndex), 'RadioGroupIndex', 0, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.IsRadioGroup), 'IsRadioGroup', null, false).setMDSizeHalf());        

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.GroupID), 'GroupID', 0, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.Paging), 'Paging', null, false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.List_ID), 'List', `model/representations/List${this.ReadOnly ? '?id=$id' : ''}`, true, false, 'List wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
//        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Model_ID), 'Model', `model/representations/ModelMeta${this.ReadOnly ? '?id=$id' : ''}`, true, false, 'Model wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Model_ID), 'Model', true, 'Representation', 'Id', 2, `model/representations/ModelMeta`).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RegEx), 'RegEx', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Mask), 'Mask', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.minValue), 'minValue', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.maxValue), 'maxValue', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Height), 'Height', 0, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.IsPassword), 'IsPassword', null, false).setMDSizeHalf());        
        
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DefaultIntPropValue), 'DefaultIntPropValue', 0, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DefaultDecPropValue), 'DefaultDecPropValue', 2, false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.DefaultBoolPropValue), 'DefaultBoolPropValue', null, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.DefaultDatePropValue), 'DefaultDatePropValue', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.DefaultTextPropValue), 'DefaultTextPropValue', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.DefaultListDetailPropValue_ID), 'DefaultListDetailPropValue', `model/representations/ListDetail${this.ReadOnly ? '?id=$id' : ''}`, true, false, 'DefaultListDetailPropValue wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.DefaultAttributPath), 'DefaultAttributPath', false).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.VerwendungAls), 'VerwendungAls', 'caf_customproperty_verwendung', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.NullValuePrompt1), 'NullValuePrompt1', false).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.NullValuePrompt2), 'NullValuePrompt2', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.NullValuePrompt3), 'NullValuePrompt3', false).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.NullValuePrompt4), 'NullValuePrompt4', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Description), 'Description', false).setMDSizeHalf());
    }
}
