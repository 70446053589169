<div class="mt-2">
    <avi-core-view-panel [toggleable]="false" header="Listenverwaltung">

        <avi-core-base-form #form
            [readonly]="false"
            [card]="false"
            [fields]="Fields"
            [(Model)]="Model"
            [contents-padding]="false"
            [auto-focus]="true"
            [show-buttons]="false"
            (onAttrChange)="onAttrChange($event)"
        >
        </avi-core-base-form>

        <div class="p-2">
            <avi-listdetail-searcher *ngIf="ListName" [listname]="ListName" [title]="null" [enable-panel]="false" [card]="false" [contents-padding]="false"></avi-listdetail-searcher>
        </div>

    </avi-core-view-panel>
</div>