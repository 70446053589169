<avi-core-base-form
    *ngIf="Model"
    #form
    [readonly]="ReadOnly"
    [card]="Card"
    [fields]="Fields"
    [contents-padding]="true"
    [form-title]="FormTitle | translate"
    [Model]="Model"
    [loading]="Loading"
    (onSave)="saveModel($event)"
    (onAttrChange)="onAttrChange($event)"
    [validation-delegate]="ValidateFormular"
    [action-buttons]="actionButtons"
    [action-buttons-readonly]="actionButtonsReadonly"
>
    <!-- Form C -->
    <div formFieldTemplate1>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
                <p-checkbox
                    class="checkbox-inline form-field"
                    [ngModel]="this.form.GetField('WBC_WB')"
                    (ngModelChange)="this.form.SetField('WBC_WB', $event, true)"
                    [readonly]="ReadOnly"
                    name="WBC_WB"
                    [label]=""
                    [binary]="true"
                ></p-checkbox>
            </div>
            <label
                class="col ui-chkbox-label"
                (click)="!ReadOnly && this.form.SetField('WBC_WB', !this.Model.WBC_WB, true)"
                [innerHTML]="'VWBP.PUBLIC.FORM.WBC_WB' | translate"
            >
            </label>
        </div>
    </div>
    <div formFieldTemplate2>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
            <p-checkbox
                class="checkbox-inline form-field"
                [ngModel]="this.form.GetField('WBC_KONT')"
                (ngModelChange)="this.form.SetField('WBC_KONT', $event, true)"
                [readonly]="ReadOnly"
                name="WBC_KONT"
                [label]=""
                [binary]="true"
            ></p-checkbox>
            </div>
            <label
                class="ui-chkbox-label col"
                (click)="!ReadOnly && this.form.SetField('WBC_KONT', !this.Model.WBC_KONT, true)"
                [innerHTML]="'VWBP.PUBLIC.FORM.WBC_KONT' | translate"
            >
            </label>
        </div>
    </div>
    <div formFieldTemplate3>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
                <p-checkbox
                    class="checkbox-inline form-field"
                    [ngModel]="this.form.GetField('WBC_SMO')"
                    (ngModelChange)="this.form.SetField('WBC_SMO', $event, true)"
                    [readonly]="ReadOnly"
                    name="WBC_SMO"
                    [label]=""
                    [binary]="true"
                ></p-checkbox>
            </div>
            <label
                class="col ui-chkbox-label"
                (click)="!ReadOnly && this.form.SetField('WBC_SMO', !this.Model.WBC_SMO, true)"
                [innerHTML]="'VWBP.PUBLIC.FORM.WBC_SMO' | translate"
            >
            </label>
        </div>
    </div>

    <!-- Form T -->
    <div formFieldTemplate4>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
            <p-checkbox
                class="checkbox-inline form-field"
                [ngModel]="this.form.GetField('WBT_SETTL')"
                (ngModelChange)="this.form.SetField('WBT_SETTL', $event, true)"
                [readonly]="ReadOnly"
                name="WBT_SETTL"
                [label]=""
                [binary]="true"
            ></p-checkbox>
            </div>
            <label
                class="ui-chkbox-label col"
                (click)="!ReadOnly && this.form.SetField('WBT_SETTL', !this.Model.WBT_SETTL, true)"
                [innerHTML]="'VWBP.PUBLIC.FORM.WBT_SETTL' | translate"
            >
            </label>
        </div>
    </div>
    <div formFieldTemplate5>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
            <p-checkbox
                class="checkbox-inline form-field"
                [ngModel]="this.form.GetField('WBT_SETTL_VS')"
                (ngModelChange)="this.form.SetField('WBT_SETTL_VS', $event, true)"
                [readonly]="ReadOnly"
                name="WBT_SETTL_VS"
                [label]=""
                [binary]="true"
            ></p-checkbox>
            </div>
            <label
                class="ui-chkbox-label col"
                (click)="!ReadOnly && this.form.SetField('WBT_SETTL_VS', !this.Model.WBT_SETTL_VS, true)"
                [innerHTML]="'VWBP.PUBLIC.FORM.WBT_SETTL_VS' | translate"
            >
            </label>
        </div>
    </div>
    <div formFieldTemplate6>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
            <p-checkbox
                class="checkbox-inline form-field"
                [ngModel]="this.form.GetField('WBT_ORG')"
                (ngModelChange)="this.form.SetField('WBT_ORG', $event, true)"
                [readonly]="ReadOnly"
                name="WBT_ORG"
                [label]=""
                [binary]="true"
            ></p-checkbox>
            </div>
            <label
                class="ui-chkbox-label col"
                (click)="!ReadOnly && this.form.SetField('WBT_ORG', !this.Model.WBT_ORG, true)"
                [innerHTML]="
                    (natPersonMode ? 'VWBP.PUBLIC.FORM.WBT_ORG_NP' : 'VWBP.PUBLIC.FORM.WBT_ORG_JP') | translate
                "
            >
            </label>
        </div>
    </div>
    <div formFieldTemplate7>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
            <p-checkbox
                class="checkbox-inline form-field"
                [ngModel]="this.form.GetField('WBT_PRO')"
                (ngModelChange)="this.form.SetField('WBT_PRO', $event, true)"
                [readonly]="ReadOnly"
                name="WBT_PRO"
                [label]=""
                [binary]="true"
            ></p-checkbox>
            </div>
            <label
                class="ui-chkbox-label col"
                (click)="!ReadOnly && this.form.SetField('WBT_PRO', !this.Model.WBT_PRO, true)"
                [innerHTML]="'VWBP.PUBLIC.FORM.WBT_PROT' | translate"
            ></label>
        </div>
    </div>
    <div formFieldTemplate8>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
            <p-checkbox
                class="checkbox-inline form-field"
                [ngModel]="this.form.GetField('WBT_BEG')"
                (ngModelChange)="this.form.SetField('WBT_BEG', $event, true)"
                [readonly]="ReadOnly"
                name="WBT_BEG"
                [label]=""
                [binary]="true"
            ></p-checkbox>
            </div>
            <label
                class="ui-chkbox-label col"
                (click)="!ReadOnly && this.form.SetField('WBT_BEG', !this.Model.WBT_BEG, true)"
                [innerHTML]="
                    (natPersonMode ? 'VWBP.PUBLIC.FORM.WBT_BEG_NP' : 'VWBP.PUBLIC.FORM.WBT_BEG_JP') | translate
                "
            ></label>
        </div>
    </div>
    <div formFieldTemplate9>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
            <p-checkbox
                class="checkbox-inline form-field"
                [ngModel]="this.form.GetField('WBT_KONT')"
                (ngModelChange)="this.form.SetField('WBT_KONT', $event, true)"
                [readonly]="ReadOnly"
                name="WBT_KONT"
                [label]=""
                [binary]="true"
            ></p-checkbox>
            </div>
            <label
                class="ui-chkbox-label col"
                (click)="!ReadOnly && this.form.SetField('WBT_KONT', !this.Model.WBT_KONT, true)"
                [innerHTML]="'VWBP.PUBLIC.FORM.WBT_KONT' | translate"
            ></label>
        </div>
    </div>
    <div formFieldTemplate10>
        <div class="grid p-inputgroup">
            <div class="col-fixed" style="width:35px" >
            <p-checkbox
                class="checkbox-inline form-field"
                [ngModel]="this.form.GetField('WBT_KDB')"
                (ngModelChange)="this.form.SetField('WBT_KDB', $event, true)"
                [readonly]="ReadOnly"
                name="WBT_KDB"
                [label]=""
                [binary]="true"
            ></p-checkbox>
            </div>
            <label
                class="ui-chkbox-label col"
                (click)="!ReadOnly && this.form.SetField('WBT_KDB', !this.Model.WBT_KDB, true)"
                [innerHTML]="'VWBP.PUBLIC.FORM.WBT_KDB' | translate"
            ></label>
        </div>
    </div>

    <!-- Special stuff -->
    <div formFieldTemplate11>
        <div class="pt-3">
            <div class="red-border">
                <label
                    *ngIf="natPersonMode && !ReadOnly"
                    class="form-field"
                    (click)="initJPFormT($event)"
                    [innerHTML]="'VWBP.PUBLIC.FORM.WB_JURPERSON' | translate"
                >
                </label>
                <label
                    *ngIf="!natPersonMode && !ReadOnly"
                    class="form-field"
                    (click)="cancelJPFormT($event)"
                    [innerHTML]="'VWBP.PUBLIC.FORM.WB_NATPERSON' | translate"
                >
                </label>
            </div>
        </div>
    </div>

    <div formFieldTemplate12>
        <div *ngIf="!ReadOnly" class="pt-2">
            <div class="red-border">
                <label class="form-field" [innerHTML]="'VWBP.PUBLIC.FORM.HINWEIS_WBTBEG_RT' | translate"></label>
            </div>
        </div>
    </div>

    <div formFieldTemplate13>
        <div class="p-inputgroup pt-2">
            <div class="ui-toolbar-group-left">
                <span class="mr-2">
                    <button
                        pButton
                        type="button"
                        [label]="'VWBP.PUBLIC.FORM.WB_JURPERSON_LI_BUTTON' | translate"
                        icon="pi pi-plus"
                        (click)="ImportHRRechtstraeger()"
                        class="w-100p-m mr-2 mb-1"
                    ></button>
                </span>

                <span>
                    <button
                        pButton
                        type="button"
                        [label]="'VWBP.PUBLIC.FORM.WB_JURPERSON_MANUELL_BUTTON' | translate"
                        icon="pi pi-pencil"
                        (click)="SetJPTypManuell()"
                        class="w-100p-m mr-2 mb-1"
                    ></button>
                </span>
            </div>
        </div>
    </div>
</avi-core-base-form>

<avi-core-dialog
    #rechtstraegerFormDialog
    identifier="rechtstraegerFormDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
>
    <vwbp-public-rechtstraeger-importieren-form
        #rechtstraegerLIForm
        (onSelect)="onSelectFromHR($event)"
        [redirect-after-save]="null"
        mode="select"
        submit-label="Rechtsträger selektieren"
        [action-buttons]="formActionButtons"
    >
    </vwbp-public-rechtstraeger-importieren-form>
</avi-core-dialog>
