<div *ngIf="Rechtstraeger">
    <div class="grid justify-content-end m-0">
        <!-- Rückgängig machen Tasks -->
        <button
            *ngIf="enableTaskMahnenRueckgaengig()"
            pButton
            type="button"
            icon="pi pi-undo"
            [label]="'VWBP.INTERN.MAHNWESEN.MAHNEN_RUECKGAENGIG_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskMahnenRueckgaengig()"
            class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
        ></button>
        <button
            *ngIf="enableTaskBuessenRueckgaengig()"
            pButton
            type="button"
            icon="pi pi-undo"
            [label]="'VWBP.INTERN.MAHNWESEN.BUESSEN_RUECKGAENGIG_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskBuessenRueckgaengig()"
            class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
        ></button>
        <button
            *ngIf="enableTaskLoeschverfahrenRueckgaengig()"
            pButton
            type="button"
            icon="pi pi-undo"
            [label]="'VWBP.INTERN.MAHNWESEN.LOESCHVERFAHREN_RUECKGAENGIG_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskLoeschverfahrenRueckgaengig()"
            class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
        ></button>
        <button
            *ngIf="enableTaskEinzugsauftragRueckgaengig()"
            pButton
            type="button"
            icon="pi pi-credit-card"
            [label]="'VWBP.INTERN.MAHNWESEN.EINZUGSAUFTRAG_RUECKGAENGIG_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskEinzugsauftragRueckgaengig()"
            class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
        ></button>
        <button
            *ngIf="enableTaskNichtRelevantRueckgaengig()"
            pButton
            type="button"
            icon="pi pi-undo"
            [label]="'VWBP.INTERN.MAHNWESEN.NICHT_RELEVANT_RUECKGAENGIG_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskNichtRelevantRueckgaengig()"
            class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
        ></button>
        <!-- Tasks -->
        <button
            *ngIf="enableTaskNichtRelevant()"
            pButton
            type="button"
            icon="pi pi-ban"
            [label]="'VWBP.INTERN.MAHNWESEN.NICHT_RELEVANT_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskNichtRelevant()"
            class="w-100p-m m ml-2 p-button-secondary"
        ></button>
        <button
            *ngIf="enableTaskMahnen()"
            pButton
            type="button"
            icon="pi pi-exclamation-triangle"
            [label]="'VWBP.INTERN.MAHNWESEN.RECHTSTRAEGER_MAHNEN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskMahnen()"
            class="w-100p-m m ml-2 p-button-primary"
        ></button>
        <button
            *ngIf="enableTaskBuessen()"
            pButton
            type="button"
            icon="pi pi-money-bill"
            [label]="'VWBP.INTERN.MAHNWESEN.RECHTSTRAEGER_BUESSEN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskBuessen()"
            class="w-100p-m m ml-2 p-button-warning"
        ></button>
        <button
            *ngIf="enableTaskMahnfristSetzen()"
            pButton
            type="button"
            icon="pi pi-clock"
            [label]="'VWBP.INTERN.MAHNWESEN.MAHNFRIST_ANPASSEN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskMahnfristSetzen()"
            class="w-100p-m m ml-2 p-button-primary"
        ></button>
        <button
            *ngIf="enableTaskLoeschverfahren()"
            pButton
            type="button"
            icon="pi pi-trash"
            [label]="'VWBP.INTERN.MAHNWESEN.AMTLICHES_LIQUIDATIONSVERFAHREN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskLoeschverfahren()"
            class="w-100p-m m ml-2 p-button-danger"
        ></button>
        <button
            *ngIf="enableTaskEinzugsauftrag()"
            pButton
            type="button"
            icon="pi pi-credit-card"
            [label]="'VWBP.INTERN.MAHNWESEN.EINZUGSAUFTRAG_AN_LK_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskEinzugsauftrag()"
            class="w-100p-m m ml-2 p-button-warning"
        ></button>
        <button
            *ngIf="enableTaskRechtsmittelfristSetzen()"
            pButton
            type="button"
            icon="pi pi-clock"
            [label]="'VWBP.INTERN.MAHNWESEN.RECHTSMITTELFRIST_ANPASSEN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskRechtsmittelfristSetzen()"
            class="p-button-primary w-100p-m m ml-2"
        ></button>
        <button
            *ngIf="enableTaskBussfristSetzen()"
            pButton
            type="button"
            icon="pi pi-clock"
            [label]="'VWBP.INTERN.MAHNWESEN.BUSSFRIST_ANPASSEN_BUTTON' | translate"
            aviThrottledClick
            (throttledClick)="doTaskBussfristSetzen()"
            class="p-button-primary w-100p-m m ml-2"
        ></button>
    </div>
</div>

<avi-core-dialog #taskDialog identifier="taskDialog" [showCloseIcon]="false" [closeOnEscape]="false">
    <vwbp-intern-mahnwesen-rueckgaengig-task
        *ngIf="Modus === MahnwesenTaskMode.RUECKGAENGIG"
        [modus]="RueckgaengigMode"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-rueckgaengig-task>
    <vwbp-intern-mahnwesen-nicht-relevant-task
        *ngIf="Modus === MahnwesenTaskMode.NICHT_RELEVANT"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-nicht-relevant-task>
    <vwbp-intern-mahnwesen-mahnen-task
        *ngIf="Modus === MahnwesenTaskMode.MAHNEN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-mahnen-task>
    <vwbp-intern-mahnwesen-mahnfrist-setzen-task
        *ngIf="Modus === MahnwesenTaskMode.MAHNFRIST"
        [selected-rechtstraeger]="selectedRT"
        [datum]="Mahnfrist"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-mahnfrist-setzen-task>
    <vwbp-intern-mahnwesen-bussen-task
        *ngIf="Modus === MahnwesenTaskMode.BUESSEN && !ErneutBuessen"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-bussen-task>
    <vwbp-intern-mahnwesen-erneut-bussen-task
        *ngIf="Modus === MahnwesenTaskMode.BUESSEN && ErneutBuessen"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-erneut-bussen-task>
    <vwbp-intern-mahnwesen-amtliche-aufloesung-task
        *ngIf="Modus === MahnwesenTaskMode.LOESCHVERFAHREN"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-amtliche-aufloesung-task>
    <vwbp-intern-mahnwesen-rechtsmittelfrist-setzen-task
        *ngIf="Modus === MahnwesenTaskMode.RECHTSMITTELFRIST"
        [selected-rechtstraeger]="selectedRT"
        [datum]="Rechtsmittelfrist"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-rechtsmittelfrist-setzen-task>
    <vwbp-intern-mahnwesen-einzugsauftrag-task
        *ngIf="Modus === MahnwesenTaskMode.EINZUGSAUFTRAG"
        [selected-rechtstraeger]="selectedRT"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-einzugsauftrag-task>
    <vwbp-intern-mahnwesen-bussfrist-setzen-task
        *ngIf="Modus === MahnwesenTaskMode.BUSSFRIST"
        [selected-rechtstraeger]="selectedRT"
        [datum]="Bussfrist"
        [dialog]="true"
        (onCancel)="CloseDialog()"
        (onSave)="OnSave()"
    ></vwbp-intern-mahnwesen-bussfrist-setzen-task>
</avi-core-dialog>
