import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { BusinesscaseDefSearchResponseDto } from '@avi-x/avi-dto/businesscase/businesscasedefsearchresponsedto.model';
import { StatusDef } from '@avi-x/avi-dto/workflow/statusdef.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: StatusDef) => any)) => typed_nameof<StatusDef>(nameFunction);

@Component({
    selector: 'avi-statusdef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviStatusDefFormComponent extends AviAbstractModelFormComponent<StatusDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFSTATUSDEF';
    BaseUrl = 'workflow/statusdef';
    ClassType = StatusDef;
    FormType = AviStatusDefFormComponent;
    
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFStatusWorkflowDef';
    }

    public async afterCreateModel(bm: StatusDef) {
        bm.vonStatusWorkflowDef_ID = this.ContextId;
    }

    public async afterInitForm() {
        this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.FollowupBusinesscaseDef_ID), !this.Model.IsFinishState);
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1)) {
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung3 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung4 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung5 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung6 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung7 = this.Model.Bezeichnung1;
        }

        if (data.field === nameof(c => c.KurzBez1)) {
            this.Model.KurzBez2 = this.Model.KurzBez1;
            this.Model.KurzBez3 = this.Model.KurzBez1;
            this.Model.KurzBez4 = this.Model.KurzBez1;
            this.Model.KurzBez5 = this.Model.KurzBez1;
            this.Model.KurzBez6 = this.Model.KurzBez1;
            this.Model.KurzBez7 = this.Model.KurzBez1;
        }

        if (data.field === nameof(c => c.ExternBezeichnung1)) {
            this.Model.ExternBezeichnung2 = this.Model.ExternBezeichnung1;
            this.Model.ExternBezeichnung3 = this.Model.ExternBezeichnung1;
            this.Model.ExternBezeichnung4 = this.Model.ExternBezeichnung1;
            this.Model.ExternBezeichnung5 = this.Model.ExternBezeichnung1;
            this.Model.ExternBezeichnung6 = this.Model.ExternBezeichnung1;
            this.Model.ExternBezeichnung7 = this.Model.ExternBezeichnung1;
        }

        if (data.field === nameof(c => c.ExternKurzBez1)) {
            this.Model.ExternKurzBez2 = this.Model.ExternKurzBez1;
            this.Model.ExternKurzBez3 = this.Model.ExternKurzBez1;
            this.Model.ExternKurzBez4 = this.Model.ExternKurzBez1;
            this.Model.ExternKurzBez5 = this.Model.ExternKurzBez1;
            this.Model.ExternKurzBez6 = this.Model.ExternKurzBez1;
            this.Model.ExternKurzBez7 = this.Model.ExternKurzBez1;
        }

        if (data.field === nameof(c => c.IsFinishState)) { 
            this.formFieldService.setFieldDisabled(this.Fields, nameof(c => c.FollowupBusinesscaseDef_ID), !this.Model.IsFinishState);
            if (!this.Model.IsFinishState)
                this.Model.FollowupBusinesscaseDef_ID = null;
        }
    }
    
    queryBusinesscaseDef = async (field: any) => {
        const ret = [];
        if (this.Model) {
            const cfg: any = { ModelId: this.ReadOnly || field.Readonly || field.Disabled ? this.Model.FollowupBusinesscaseDef_ID : null };
            const p = await this.apiService.postPagedResult(BusinesscaseDefSearchResponseDto, `businesscase/businesscasedef/search`, cfg);  
            p.Results.forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }

    initFields() {
        if (this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.CAFSTATUSDEF.KURZBEZ1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.CAFSTATUSDEF.BEZEICHNUNG1', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'CORE.COMMON.MODEL.CAFSTATUSDEF.KURZBEZ2', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.CAFSTATUSDEF.BEZEICHNUNG2', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'CORE.COMMON.MODEL.CAFSTATUSDEF.KURZBEZ3', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.CAFSTATUSDEF.BEZEICHNUNG3', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'CORE.COMMON.MODEL.CAFSTATUSDEF.KURZBEZ4', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.CAFSTATUSDEF.BEZEICHNUNG4', true).setMDSizeHalf());
                
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ExternKurzBez1), 'CORE.COMMON.MODEL.CAFSTATUSDEF.EXTERNKURZBEZ1', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ExternBezeichnung1), 'CORE.COMMON.MODEL.CAFSTATUSDEF.EXTERNBEZEICHNUNG1', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ExternKurzBez2), 'CORE.COMMON.MODEL.CAFSTATUSDEF.EXTERNKURZBEZ2', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ExternBezeichnung2), 'CORE.COMMON.MODEL.CAFSTATUSDEF.EXTERNBEZEICHNUNG2', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ExternKurzBez3), 'CORE.COMMON.MODEL.CAFSTATUSDEF.EXTERNKURZBEZ3', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ExternBezeichnung3), 'CORE.COMMON.MODEL.CAFSTATUSDEF.EXTERNBEZEICHNUNG3', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ExternKurzBez4), 'CORE.COMMON.MODEL.CAFSTATUSDEF.EXTERNKURZBEZ4', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ExternBezeichnung4), 'CORE.COMMON.MODEL.CAFSTATUSDEF.EXTERNBEZEICHNUNG4', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sorter), 'CORE.COMMON.MODEL.CAFSTATUSDEF.SORTER', 0, true).setMDSizeHalf());       
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Verantwortlichkeit), 'CORE.COMMON.MODEL.CAFSTATUSDEF.VERANTWORTLICHKEIT', 'caf_statusdef_verantwortlichkeit', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.IsStartState), null, 'CORE.COMMON.MODEL.CAFSTATUSDEF.ISSTARTSTATE', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.IsFinishState), null, 'CORE.COMMON.MODEL.CAFSTATUSDEF.ISFINISHSTATE', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.IsDefaultFinishState), null, 'CORE.COMMON.MODEL.CAFSTATUSDEF.ISDEFAULTFINISHSTATE', false).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.Bearbeitbar), null, 'CORE.COMMON.MODEL.CAFSTATUSDEF.BEARBEITBAR', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.RoleSichtbar_ID), 'CORE.COMMON.MODEL.CAFSTATUSDEF.ROLESICHTBAR', false, 'Representation', 'Id', 2, `model/representations/TechRole`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Sichtbarkeit), 'CORE.COMMON.MODEL.CAFSTATUSDEF.SICHTBARKEIT', 'caf_statusdef_sichtbarkeit', true, true, this.ReadOnly).setMDSizeHalf());        

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.RoleAssignment_ID), 'CORE.COMMON.MODEL.CAFSTATUSDEF.ROLEASSIGNMENT', false, 'Representation', 'Id', 2, `model/representations/TechRole`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.FollowupBusinesscaseDef_ID), 'CORE.COMMON.MODEL.CAFSTATUSDEF.FOLLOWUPBUSINESSCASEDEF', this.queryBusinesscaseDef, false).setMDSizeFull());
    }
}
