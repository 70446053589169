<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

	<div class="p-1">
        <avi-core-view-panel 
            [toggleable]="true" 
            [header]="'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.CAFDOCPROPERTYDEF_1' | translate" 
            class="w-100p">
            <avi-docpropertydef-form [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true" taskmenu-position="bottom"></avi-docpropertydef-form>
        </avi-core-view-panel>
        
        <avi-core-view-panel 
            [toggleable]="true" 
            [header]="'CORE.COMMON.MODEL.CAFDOCPROPERTYDEF.DEFAULTDOCPROPERTIES' | translate" 
            class="w-100p">
            <avi-docproperty-searcher [context-id]="ModelId" [contents-padding]="false" [enable-panel]="false"></avi-docproperty-searcher>
        </avi-core-view-panel>

	</div>

</avi-core-page>

