import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared';

export class EmailDef extends BaseModel {  

	@Expose()
	@Type(() => ListType)
	public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }

    @Expose()
    public get Styles(): string { return this.getProperty('Styles'); }
    public set Styles(value: string) { this.setProperty('Styles', value); }
    
    @Expose()
    public get Bezeichnung(): string { return this.getProperty('Bezeichnung'); }
    public set Bezeichnung(value: string) { this.setProperty('Bezeichnung', value); }

    @Expose()
    public get EmailSubject1(): string { return this.getProperty('EmailSubject1'); }
    public set EmailSubject1(value: string) { this.setProperty('EmailSubject1', value); }
    
    @Expose()
    public get EmailText1(): string { return this.getProperty('EmailText1'); }
    public set EmailText1(value: string) { this.setProperty('EmailText1', value); }
    
    @Expose()
    public get EmailSubject2(): string { return this.getProperty('EmailSubject2'); }
    public set EmailSubject2(value: string) { this.setProperty('EmailSubject2', value); }
    
    @Expose()
    public get EmailText2(): string { return this.getProperty('EmailText2'); }
    public set EmailText2(value: string) { this.setProperty('EmailText2', value); }
    
    @Expose()
    public get EmailSubject3(): string { return this.getProperty('EmailSubject3'); }
    public set EmailSubject3(value: string) { this.setProperty('EmailSubject3', value); }
    
    @Expose()
    public get EmailText3(): string { return this.getProperty('EmailText3'); }
    public set EmailText3(value: string) { this.setProperty('EmailText3', value); }
    
    @Expose()
    public get EmailSubject4(): string { return this.getProperty('EmailSubject4'); }
    public set EmailSubject4(value: string) { this.setProperty('EmailSubject4', value); }
    
    @Expose()
    public get EmailText4(): string { return this.getProperty('EmailText4'); }
    public set EmailText4(value: string) { this.setProperty('EmailText4', value); }
    
    @Expose()
    public get EmailSubject5(): string { return this.getProperty('EmailSubject5'); }
    public set EmailSubject5(value: string) { this.setProperty('EmailSubject5', value); }
    
    @Expose()
    public get EmailText5(): string { return this.getProperty('EmailText5'); }
    public set EmailText5(value: string) { this.setProperty('EmailText5', value); }
    
    @Expose()
    public get EmailSubject6(): string { return this.getProperty('EmailSubject6'); }
    public set EmailSubject6(value: string) { this.setProperty('EmailSubject6', value); }
    
    @Expose()
    public get EmailText6(): string { return this.getProperty('EmailText6'); }
    public set EmailText6(value: string) { this.setProperty('EmailText6', value); }
    
    @Expose()
    public get EmailSubject7(): string { return this.getProperty('EmailSubject7'); }
    public set EmailSubject7(value: string) { this.setProperty('EmailSubject7', value); }
    
    @Expose()
    public get EmailText7(): string { return this.getProperty('EmailText7'); }
    public set EmailText7(value: string) { this.setProperty('EmailText7', value); }

    // Transients
    public get EmailText1RTF(): string { return this.EmailText1; }
    public set EmailText1RTF(value: string) { this.EmailText1 = value; }    

    public get EmailText2RTF(): string { return this.EmailText2; }
    public set EmailText2RTF(value: string) { this.EmailText2 = value; }    

    public get EmailText3RTF(): string { return this.EmailText3; }
    public set EmailText3RTF(value: string) { this.EmailText3 = value; }    

    public get EmailText4RTF(): string { return this.EmailText4; }
    public set EmailText4RTF(value: string) { this.EmailText4 = value; }    
}
