import { Component, ViewChild, OnInit, AfterViewInit, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AviCommonService, AviBaseSearcherComponent, AviApiService, AviPartnerListDetailConst, AviCommunicationService } from '@avi-x/avi-core';

@Component({
    selector: 'avi-crm-konto-liste',
    templateUrl: './konto-liste.component.html'
})
export class AviKontoListeComponent implements OnInit {
    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    private _bvId: string = null;
    @Input('bankverbindung-id')
    public set bvId(id: string) {
        this._bvId = id;
        this.searcher.doSearch();
    }
    public get bvId(): string {
        return this._bvId;
    }

    public DetailModelId: string = null;

    constructor(
        public apiService: AviApiService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        public commonService: AviCommonService,
        private communicationService: AviCommunicationService
    ) {
    }

    public onInit(data) {
        this.searcher.beginInit();
        this.searcher.addTextColumn('vonBankVerbindung_ID', 'vonBankVerbindung_ID').setVisible(false);

        // KontoNr,IBAN,vonKontoArt.Bezeichnung1,vonWaehrung.ISOCode,KontoBezeichnung
        this.searcher.addDateColumn('GueltigAb', 'Gültig ab');
        this.searcher.addDateColumn('GueltigBis', 'Gültig bis');
        this.searcher.addTextColumn('KontoNr', 'KontoNr');
        this.searcher.addTextColumn('IBAN', 'IBAN');
        this.searcher.addTextColumn('vonKontoArt.Bezeichnung1', 'KontoArt');
        this.searcher.addTextColumn('vonWaehrung.ISOCode', 'Währung');
        this.searcher.addTextColumn('KontoBezeichnung', 'Kontobezeichnung');

        this.searcher.endInit();
    }

    onSelect(data) {
        if (data && data[0])
            this.DetailModelId = data[0].Id;
    }

    ngOnInit() {
    }

    public getFilters: any = (): string[] => {
        let res = [ ];
        if (this.bvId != null)
            res = [...res, `vonBankVerbindung_ID eq ${this.bvId}`];

        if (!this.searcher.showInaktive)
            res = [...res, 'GueltigAb le today', 'GueltigBis ge today' ];

        return res;
    }
}
