import { Expose, Type } from "class-transformer";

export enum MessageType { Error, Warning, Info };

export class Message
{
    @Expose()
    public Type: MessageType;

    @Expose()
    public Section: string;

    @Expose()
    public Title: string;

    @Expose()
    public Message: string;

    @Expose()
    public MessageDetail: string;

    @Expose()
    public Tag: any;
}

export class MessageContainer
{
    public get HasErrors() { return this.Messages.some(w => w.Type === MessageType.Error); }
    public get HasWarnings() { return this.Messages.some(w => w.Type === MessageType.Warning); }
    public get HasInfos() { return this.Messages.some(w => w.Type === MessageType.Info); }

    @Expose()
    @Type(() => Message)
    public Messages: Message[] = [];
    public Title: string;
}