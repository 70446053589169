import { Directive, ElementRef, Input, AfterContentChecked } from '@angular/core';
import { AviCalendarComponent } from '../components/controls/calendar/calendar.component';
import { AviDropdownComponent } from '../components/controls/dropdown/dropdown.component';

@Directive({
    selector: '[focus]'
})
export class AviFocusDirective implements AfterContentChecked {
    @Input() focus: boolean;
    private element: HTMLElement;
    private hasFocused = false;

    constructor($element: ElementRef) {
        this.element = $element.nativeElement;
    }

    ngAfterContentChecked() {
        this.giveFocus();
    }

    giveFocus() {
        if (this.focus && !this.hasFocused) {
            setTimeout(() => {
                this.element.focus();
                this.hasFocused = true;
            }, 5);    
        }
    }
}


@Directive({
    selector: '[focus-calendar]'
})
export class AviFocusCalDirective implements AfterContentChecked {
    @Input('focus-calendar') focus: boolean;
    private hasFocused = false;

    constructor(private calendar: AviCalendarComponent) {
    }

    ngAfterContentChecked() {
        this.giveFocus();
    }

    giveFocus() {
        if (this.focus && !this.hasFocused) {
            setTimeout(() => {
                this.calendar.focus();
                this.hasFocused = true;
            }, 5);    
        }
    }
}


@Directive({
    selector: '[focus-dropdown]'
})
export class AviFocusDropdownDirective implements AfterContentChecked {
    @Input('focus-dropdown') focus: boolean;
    private hasFocused = false;

    constructor(private dropdown: AviDropdownComponent) {
    }

    ngAfterContentChecked() {
        this.giveFocus();
    }

    giveFocus() {
        if (this.focus && !this.hasFocused) {
            setTimeout(() => {
                this.dropdown.focus();
                this.hasFocused = true;
            }, 5);
        }
    }
}
