import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function TranslateParserFactory() {
    return new CustomTranslateParser();
}

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';

@Injectable()
export class CustomTranslateParser extends TranslateDefaultParser {

    getValue(target: any, key: string): any {
        target = super.getValue(target, key);
        if (target instanceof Array) {
            target = target.join(' ');
        }
        return target;
    }
}
