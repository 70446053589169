<div *ngIf="config && config.is_internal; else loadingConfig"
    class="p-2">


<div class="pl-2 pr-2" [innerHTML]="'VWBP.PUBLIC.LOGINFORM.HEADER' | translate"></div>
    <div fxLayout="row">
        <div fxFlex="50"
            class="p-2">
            <div [innerHTML]="'VWBP.PUBLIC.LOGINFORM.BEHOERDEN' | translate"></div>
            <button pButton
                class="login-button p-button-secondary p-button-outlined"
                type="button"
                label="Behördenlogin"
                (click)="initInternalLogin()"></button>
        </div>

        <div fxFlex="50"
            class="p-2">
            <div [innerHTML]="'VWBP.PUBLIC.LOGINFORM.EXTERN' | translate"></div>
            <button pButton
                class="login-button p-button-secondary p-button-outlined"
                type="button"
                label="Login als externer Benutzer"
                (click)="initPublicLogin()"></button>
        </div>
    </div>

</div>

<ng-template #loadingConfig>
    <div class="box-container">

        <div *ngIf="initError"
            class="error-box">{{initError | translate}}
        </div>

        <div *ngIf="config && !config.is_internal">
            Bitte warten, sie werden gleich weitergeleitet...
        </div>

        <div *ngIf="!config && !initError"
            class="loader-box">
            Lade Einstellungen...
        </div>

    </div>
</ng-template>
