<avi-report-querydef-exec-searcher
    #searcher
    id = "b1fae81d-d3a4-4040-a5e2-ccf20914b8b4"
    searcher-icon = "people"
    [use-router-params]="false"
    [showtitle]="false"
    [responsive]="false"
    [collapsible]="false"
    inaktiv-label="inaktiv/ungültig"
    (onModelLoaded) = "onModelLoaded($event)"
    (onDoubleClick)       = "_onDoubleClick($event)"
    [pretoolbar-template] = "toolbar"
    [row-detailview-template] = "rowdetailview">
>
    <ng-template #toolbar>
        <div class="ui-g">
            <div class="w-100p pt-3 pb-4">
                <p-toolbar>
                    <div class="ui-toolbar-group-left flex">
                        <button
                            pButton
                            type="button"
                            label="Neu"
                            icon="pi pi-plus"
                            (click)="createObject()"
                            class="w-100p-m mb-4-m p-button-secondary"
                        ></button>

                        <p-divider layout="vertical" ></p-divider>

                        <button
                            pButton
                            type="button"
                            label="Ändern"
                            icon="pi pi-pencil"
                            (click)="editObject()"
                            class="w-100p-m mb-4-m p-button-secondary"
                        ></button>

                        <p-divider layout="vertical" ></p-divider>

                        <button
                            pButton
                            type="button"
                            label="Delete"
                            icon="pi pi-trash"
                            (click)="deleteObject()"
                            class="red-btn w-100p-m mb-4-m p-button-secondary"
                        ></button>
                    </div>
                </p-toolbar>
            </div>
            <p-divider class="toolbar-divider"></p-divider>
        </div>
    </ng-template>
</avi-report-querydef-exec-searcher>

<ng-template #rowdetailview let-record>

    <avi-crm-partner-searcher-detailview [partnerId]="record.PartnerId" [adresseId]="record.AdresseId"></avi-crm-partner-searcher-detailview>

</ng-template>
