import { AviListDetailConst } from './../../../../avi-core/src/lib/shared/constants/constants';
import { Expose, Type } from "class-transformer";
import { AviDocKeywordDef } from "../avidoc_def/avidockeyworddef.model";
import { ListType } from "../shared/listtype.model";
import { AviDocumentContainer } from "./avidocumentcontainer.model";
import { AviDocumentKeywordContainer } from "./avidocumentkeywordcontainer.model";

export class AviDocumentContainerVM extends AviDocumentContainer {

    @Expose()
    @Type(() => ListType)
    public get AktuellerStatus(): ListType { return this.getProperty('AktuellerStatus'); }
    public set AktuellerStatus(value: ListType) { this.setProperty('AktuellerStatus', value); }

    // Benötigt in dokument-erfassen-form (Keyword editieren)
    public get Keywords_0() { return this.getKeywordValue(this.alleKeywords[0]); }
    public set Keywords_0(value: any) { this.setKeywordValue(this.alleKeywords[0], value); }

    public get Keywords_1() { return this.getKeywordValue(this.alleKeywords[1]); }
    public set Keywords_1(value: any) { this.setKeywordValue(this.alleKeywords[1], value); }

    public get Keywords_2() { return this.getKeywordValue(this.alleKeywords[2]); }
    public set Keywords_2(value: any) { this.setKeywordValue(this.alleKeywords[2], value); }

    public get Keywords_3() { return this.getKeywordValue(this.alleKeywords[3]); }
    public set Keywords_3(value: any) { this.setKeywordValue(this.alleKeywords[3], value); }

    public get Keywords_4() { return this.getKeywordValue(this.alleKeywords[4]); }
    public set Keywords_4(value: any) { this.setKeywordValue(this.alleKeywords[4], value); }

    public get Keywords_5() { return this.getKeywordValue(this.alleKeywords[5]); }
    public set Keywords_5(value: any) { this.setKeywordValue(this.alleKeywords[5], value); }

    public get Keywords_6() { return this.getKeywordValue(this.alleKeywords[6]); }
    public set Keywords_6(value: any) { this.setKeywordValue(this.alleKeywords[6], value); }

    public get Keywords_7() { return this.getKeywordValue(this.alleKeywords[7]); }
    public set Keywords_7(value: any) { this.setKeywordValue(this.alleKeywords[7], value); }

    public get Keywords_8() { return this.getKeywordValue(this.alleKeywords[8]); }
    public set Keywords_8(value: any) { this.setKeywordValue(this.alleKeywords[8], value); }

    public get Keywords_9() { return this.getKeywordValue(this.alleKeywords[9]); }
    public set Keywords_9(value: any) { this.setKeywordValue(this.alleKeywords[9], value); }

    @Expose()
    public get OverrideNewerVersion() { return this.getProperty('OverrideNewerVersion'); }
    public set OverrideNewerVersion(value: boolean) { this.setProperty('OverrideNewerVersion', value); }

    @Expose()
    @Type(() => Date)
    public get ContentErstellDatum() { return this.getProperty('ContentErstellDatum'); }
    public set ContentErstellDatum(value: Date) { this.setProperty('ContentErstellDatum', value); }

    @Expose()
    public get ContentErstellUser(): string { return this.getProperty('ContentErstellUser'); }
    public set ContentErstellUser(value: string) { this.setProperty('ContentErstellUser', value); }

    @Expose()
    @Type(() => Date)
    public get ContentMutDatum() { return this.getProperty('ContentMutDatum'); }
    public set ContentMutDatum(value: Date) { this.setProperty('ContentMutDatum', value); }

    @Expose()
    public get ContentMutUser(): string { return this.getProperty('ContentMutUser'); }
    public set ContentMutUser(value: string) { this.setProperty('ContentMutUser', value); }

    @Expose()
    public get Dateiversion() { return this.getProperty('Dateiversion'); }
    public set Dateiversion(value: number) { this.setProperty('Dateiversion', value); }

    public setKeywordValue(keyword: AviDocumentKeywordContainer, value: any) {
        if (!keyword) return;

        const kwtyp = keyword.DocKeywordDef.Keywordtype.Id;
        if (kwtyp == AviListDetailConst.ATTRTYPE_INTEGER)
            keyword.ValueInt = value;
        else if (kwtyp == AviListDetailConst.ATTRTYPE_DECIMAL)
            keyword.ValueDec = value;
        else if (kwtyp == AviListDetailConst.ATTRTYPE_TEXT)
            keyword.ValueText = value;
        else if (kwtyp == AviListDetailConst.ATTRTYPE_DATE)
            keyword.ValueDate = value;
        else if (kwtyp == AviListDetailConst.ATTRTYPE_LISTTYPE)
            keyword.ValueList_ID = value;
        else if (kwtyp == AviListDetailConst.ATTRTYPE_GUID)
            keyword.ValueGuid = value;
        // else if (kwtyp == AviListDetailConst.ATTRTYPE_REFERENCE)    // TODO, wenn's das mal braucht...
        //     keyword.Value = value;
    }

    public getKeywordValue(keyword: AviDocumentKeywordContainer): any {
        if (!keyword) return null;

        const kwtyp = keyword.DocKeywordDef.Keywordtype.Id;
        if (kwtyp == AviListDetailConst.ATTRTYPE_INTEGER)
            return keyword.ValueInt;
        else if (kwtyp == AviListDetailConst.ATTRTYPE_DECIMAL)
            return keyword.ValueDec;
        else if (kwtyp == AviListDetailConst.ATTRTYPE_TEXT)
            return keyword.ValueText;
        else if (kwtyp == AviListDetailConst.ATTRTYPE_DATE)
            return keyword.ValueDate ? new Date(keyword.ValueDate) : null;
        else if (kwtyp == AviListDetailConst.ATTRTYPE_LISTTYPE)
            return keyword.ValueList_ID; 
        else if (kwtyp == AviListDetailConst.ATTRTYPE_GUID)
            return keyword.ValueGuid;
        // else if (kwtyp == AviListDetailConst.ATTRTYPE_REFERENCE)    // TODO, wenn's das mal braucht...
        //     keyword.Value = value;

        return null;
    }
}
