import { FileSaverService } from 'ngx-filesaver';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiService, AviCommonService, AviDialogComponent, AviListDetailConst } from '@avi-x/avi-core';
import { Location } from '@angular/common';
import { VwbPPublicRechtstraegerDetailFormComponent } from '../rechtstraegerdetail-form/rechtstraegerdetail-form.component';
import { VwbPRechtstraeger } from '../../../dto/vwbprechtstraeger.model';
import { VwbPVersion } from '../../../dto/vwbpversion.model';
import { VwbPPublicFormularListeComponent } from '../../../formular/components/formular-liste/formular-liste.component';
import { VwbPListDetailConst } from '../../../constants/constants';
import { VwbPRechtstraegerDetail } from '../../../dto/vwbprechtstraegerdetail.model';
import { VwbPUebergabeService } from '../../../uebergabe/service/uebergabe.service';

import { MenuItem, Message } from 'primeng/api';
import { VwbPVorlageDefInfoDto } from '../../../dto/vwbpvorlagedefinfo.model';
import { VwbPPublicRechtstraegerService } from '../../services/rechtstraeger.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { VwbPRechtstraegerVersionInfo } from '../../../dto/vwbprechtstraegerversioninfo.model';
import { VwbPPublicRechtstraegerLoeschenFormComponent } from '../rechtstraeger-loeschen-form/rechtstraeger-loeschen-form.component';

type ActionFunction = () => Promise<any>;

@Component({
    selector: 'vwbp-public-rechtstraeger-view',
    styleUrls: ['./rechtstraeger-view.component.scss'],
    templateUrl: './rechtstraeger-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPPublicRechtstraegerViewComponent implements OnInit {
    @ViewChild('formularListe', { static: false })
    private formularListe: VwbPPublicFormularListeComponent;

    @ViewChild('editRechtstraegerFormDialog', { static: false })
    editRechtstraegerFormDialog: AviDialogComponent;

    @ViewChild('deleteRechtstraegerFormDialog', { static: false })
    deleteRechtstraegerFormDialog: AviDialogComponent;

    @ViewChild('deleteRechtstraegerForm', { static: false })
    deleteRechtstraegerForm: VwbPPublicRechtstraegerLoeschenFormComponent;

    @ViewChild('editRechtstraegerForm', { static: false })
    editRechtstraegerForm: VwbPPublicRechtstraegerDetailFormComponent;

    @ViewChild('showRechtstraegerForm', { static: false })
    showRechtstraegerForm: VwbPPublicRechtstraegerDetailFormComponent;

    Model: VwbPRechtstraeger = null;
    SelectedVersion: VwbPVersion = null;
    SelectedDetail: VwbPRechtstraegerDetail = null;
    Versions: any[] = null;
    id: any = null;
    RTName: string = null;
    HasUnstimmigkeit: boolean = false;
    msgs: Message[] = [];

    formActionButtons: any[] = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];
    formActionButtonsReadonly: any[] = [{ title: 'CORE.COMMON.SCHLIESSEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];
    deleteFormActionButtons: any[] = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeDeleteForm() }];

    ExportItems: MenuItem[] = [];

    SchwaerzungVorhanden: boolean = false;
    SchwaerzungAntragVorhanden: boolean = false;
    SchwaerzungRechtskraftVorhanden: boolean = false;
    SchwaerzungFrist3MtVorhanden: boolean = false;

    hinweisWBErfassung: string = `Als wirtschaftlich berechtigt bei Körperschaften, einschliesslich körperschaftlich strukturierten Anstalten oder Treuunternehmen, sowie Gesellschaften ohne Persönlichkeit, gelten:<br />
    <ol>
      <li>diejenigen natürlichen Personen, die letztlich direkt oder indirekt:
        <ul>
          <li>einen Anteil oder Stimmrechte von 25 % oder mehr an diesen Rechtsträgern halten oder kontrollieren;</li>
          <li>mit 25 % oder mehr am Gewinn dieser Rechtsträger beteiligt sind; oder</li>
          <li>auf andere Weise die Kontrolle über die Geschäftsführung dieser Rechtsträger ausüben;</li>
          </ul>
      </li>
      <li>diejenigen natürliche Personen, die Mitglieder des leitenden Organs sind, wenn - nach Ausschöpfung aller Möglichkeiten und sofern keine Verdachtsmomente vorliegen - keine Personen nach Ziff. 1 ermittelt worden sind;</li>
    </ol>

    Als wirtschaftlich berechtigt bei Stiftungen, Treuhänderschaften sowie stiftungsähnlich strukturierten Anstalten oder Treuunternehmen, gelten:
    <ol>
      <li>diejenigen natürlichen Personen, die effektive, nicht treuhänderische Stifter, Gründer bzw. Treugeber sind, unabhängig davon, ob sie nach der Gründung des Rechtsträgers die Kontrolle über diesen ausüben;</li>
      <li>diejenigen natürlichen oder juristischen Personen, die Mitglieder des Stiftungsrates oder Verwaltungsrates bzw. des Treunehmers sind;</li>
      <li>allfällige natürliche Personen, die Protektoren oder Personen in ähnlichen oder gleichwertigen Funktionen sind;</li>
      <li>diejenigen natürlichen Personen, die Begünstigte sind; </li>
      <li>falls noch keine Begünstigten bestimmt sein sollten, die Gruppe von Personen, in deren Interesse der Rechtsträger in erster Linie errichtet oder betrieben wird;</li>
      <li>darüber hinaus zusätzlich diejenigen natürlichen Personen, die den Rechtsträger durch direkte oder indirekte Eigentumsrechte oder auf andere Weise letztlich kontrollieren</li>
    </ol>
    `;

    constructor(private activatedRoute: ActivatedRoute,
        private apiService: AviApiService,
        private commonService: AviCommonService,
        private router: Router,
        private location: Location,
        private cdr: ChangeDetectorRef,
        private fileSaverService: FileSaverService,
        private rechtstraegerService: VwbPPublicRechtstraegerService,
        private uebergabeService: VwbPUebergabeService,
        private translateService: TranslateService
    ) {
    }

    async NavigateBack() {
        const lastVersion = await this.apiService.getModel(VwbPVersion, `v1/vwbp/rechtstraeger/version/${this.Model.CurrentVersionId}`);
        if (lastVersion.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_INBEARBEITUNG ||
            lastVersion.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_MUTIEREN) {
            this.commonService.confirm({
                header: this.translateService.instant('VWBP.PUBLIC.ERFASSUNG_NICHT_ABGESCHLOSSEN_HEADER'),
                message: this.translateService.instant('VWBP.PUBLIC.ERFASSUNG_NICHT_ABGESCHLOSSEN_MSG'),
                key: 'k1',
                rejectLabel: this.translateService.instant('CORE.COMMON.ABBRECHEN_BUTTON'),
                rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
                acceptLabel: this.translateService.instant('VWBP.PUBLIC.ERFASSUNG_NICHT_ABGESCHLOSSEN_ACCEPT_BUTTON'),
                acceptButtonStyleClass: 'p-button-primary',
                rejectIcon: 'pi pi-times',
                acceptIcon: 'pi pi-list',
                accept: () => {
                    this.location.back();
                }
            });

            this.cdr.markForCheck();
        } else
            this.location.back();
    }

    private initMessages() {
        const msglist = [];

        this.SchwaerzungVorhanden = this.Model?.SchwaerzungVorhanden.Id === AviListDetailConst.JA;
        this.SchwaerzungAntragVorhanden = this.Model?.SchwaerzungAntragVorhanden.Id === AviListDetailConst.JA;
        this.SchwaerzungRechtskraftVorhanden = this.Model?.SchwaerzungRechtskraftVorhanden.Id === AviListDetailConst.JA;
        this.SchwaerzungFrist3MtVorhanden = this.Model?.SchwaerzungFrist3MtVorhanden.Id === AviListDetailConst.JA;

        if (this.SchwaerzungAntragVorhanden) {
            msglist.push({
                severity: 'black',
                detail: this.translateService.instant('VWBP.PUBLIC.SCHWAERZUNG_ANTRAG_VORHANDEN'),
                icon: 'pi-exclamation-circle'
            });
        }

        if (this.SchwaerzungRechtskraftVorhanden) {
            msglist.push({
                severity: 'black',
                detail: this.translateService.instant('VWBP.PUBLIC.SCHWAERZUNG_RECHTSKRAFT_VORHANDEN'),
                icon: 'pi-exclamation-circle'
            });
        }

        if (this.SchwaerzungFrist3MtVorhanden) {
            msglist.push({
                severity: 'black',
                detail: this.translateService.instant('VWBP.PUBLIC.SCHWAERZUNG_FRIST_3MT_VORHANDEN'),
                icon: 'pi-exclamation-circle'
            });
        }

        if (this.HasUnstimmigkeit) {
            msglist.push({
                severity: 'warn-red',
                detail: this.translateService.instant('VWBP.PUBLIC.UNSTIMMIGKEIT_VORHANDEN'),
                icon: 'pi-exclamation-circle'
            });
        }

        if (this.Model?.EingetragenEWRRegister.Id === AviListDetailConst.JA) {
            msglist.push({
                severity: 'warn-yellow',
                detail: this.translateService.instant('VWBP.COMMON.EINGETRAGEN_EWR_REGISTER'),
                icon: 'pi-exclamation-circle'
            });
        }

        this.msgs = msglist;
    }

    ngOnInit() {
        this.activatedRoute.data.pipe(map(w => w.id)).subscribe(async res => {
            this.Model = res;
            this.SelectedVersion = res.SelectedVersion;

            this.SelectedVersion.alleFormularDaten.forEach(w => {
                w['FormularTypBezeichnung'] = w.FormularTyp.Bezeichnung;
                w['WBPartnerTypBezeichnung'] = w.WBPartnerTyp.Bezeichnung;
                w['WBPartnerTypId'] = w.WBPartnerTyp.Id;
            });

            this.SelectedDetail = await this.apiService.FindModel(VwbPRechtstraegerDetail, `v1/vwbp/rechtstraeger/detail/${this.Model.CurrentDetailId}`, null, null, null);
            this.RTName = this.SelectedDetail.Name;

            this.Versions = [];
            this.Model.alleVersionDetails.forEach(w => this.Versions.push({ 'label': this.getVersionLabel(w), 'value': w.Id }));

            this.HasUnstimmigkeit = await this.apiService.get(`v1/vwbp/rechtstraeger/hasunstimmigkeit/${this.SelectedVersion.Id}`);

            this.initMessages();

            await this.getExportItems();

            setTimeout(() => {
                this.cdr.detectChanges();
                this.formularListe.forceRefresh();
            });
        });
    }

    private getVersionLabel(versionInfo: VwbPRechtstraegerVersionInfo): string {
        let res = `Version ${versionInfo.Laufnummer} - ${this.commonService.formatDate(versionInfo.Versionsdatum, 'L LT')}`;
        if (versionInfo.VersionTyp === VwbPListDetailConst.VWBP_VERSION_TYP_VWEG)
            res = res + ' (VwEG)';

        return res;
    }

    private async loadRechtstraeger() {
        this.Model = await this.apiService.FindModel(VwbPRechtstraeger, `v1/vwbp/rechtstraeger/${this.Model.Id}`, null, null, null);
        this.SelectedDetail = await this.apiService.FindModel(VwbPRechtstraegerDetail, `v1/vwbp/rechtstraeger/detail/${this.Model.CurrentDetailId}`, null, null, null);
        this.RTName = this.SelectedDetail.Name;

        this.HasUnstimmigkeit = await this.apiService.get(`v1/vwbp/rechtstraeger/hasunstimmigkeit/${this.SelectedVersion.Id}`);

        this.initMessages();

        await this.getExportItems();

        this.cdr.detectChanges();
    }

    private async loadLastVersion() {
        this.Model = await this.apiService.FindModel(VwbPRechtstraeger, `v1/vwbp/rechtstraeger/${this.Model.Id}`, null, null, null);

        await this.loadVersion(this.Model.CurrentVersionId);

        this.Versions = [];
        this.Model.alleVersionDetails.forEach(w => this.Versions.push({ 'label': `Version ${w.Laufnummer} - ${this.commonService.formatDate(w.Versionsdatum)}`, 'value': w.Id }));

        this.HasUnstimmigkeit = await this.apiService.get(`v1/vwbp/rechtstraeger/hasunstimmigkeit/${this.SelectedVersion.Id}`);

        this.initMessages();

        await this.getExportItems();
        this.cdr.detectChanges();
        this.formularListe.forceRefresh();
    }

    private async loadVersion(id: string) {
        this.SelectedVersion = await this.apiService.getModel(VwbPVersion, `v1/vwbp/rechtstraeger/version/${id}`);

        this.SelectedVersion.alleFormularDaten.forEach(w => {
            w['FormularTypBezeichnung'] = w.FormularTyp.Bezeichnung;
            w['WBPartnerTypBezeichnung'] = w.WBPartnerTyp.Bezeichnung;
            w['WBPartnerTypId'] = w.WBPartnerTyp.Id;
        });
    }

    async SetSelectedVersion(data: string) {
        this.router.navigate(['/vwbp/rt', data], { skipLocationChange: true });
    }

    isVwEGVersion() {
        return this.SelectedVersion?.Typ?.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWEG;
    }

    getFormularCount(): number {
        return this.SelectedVersion.alleFormularDaten.length;
    }

    isInBearbeitung() {
        return this.Model && this.Model.CurrentVersionId === this.SelectedVersion.Id && this.SelectedVersion.Typ.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWBP &&
            (this.SelectedVersion.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_INBEARBEITUNG || this.SelectedVersion.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_MUTIEREN);
    }

    isCurrentVersion() {
        return this.Model && this.SelectedVersion && this.Model.CurrentVersionId === this.SelectedVersion.Id;
    }

    canEditRechtstraeger() {
        return this.SelectedDetail.Typ.Id !== VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_LI_HRNR;
    }

    isLiHrRechtstraeger() {
        return this.SelectedDetail && this.SelectedDetail.Typ.Id === VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_LI_HRNR;
    }

    canRtAbgeben() {
        return this.isCurrentVersion() && this.isLiHrRechtstraeger() &&
            (this.SelectedVersion.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_ERFASSUNG_ABGESCHLOSSEN || this.SelectedVersion.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN);
    }

    canDeleteRechtstraeger() {
        return this.isCurrentVersion() && !this.isLiHrRechtstraeger() &&
            (this.SelectedVersion.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_ERFASSUNG_ABGESCHLOSSEN || this.SelectedVersion.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN);
    }

    canDeleteRechtstraegerDraft() {
        return this.Model && this.Model.CurrentVersionId === this.SelectedVersion.Id && this.Model.IsDraft === true;
    }

    public deleteRechtstraegerDraft() {
        this.commonService.confirm({
            header: this.translateService.instant('VWBP.PUBLIC.RT_LOESCHEN_HEADER'),
            message: this.translateService.instant('VWBP.PUBLIC.RT_LOESCHEN_MSG', { rt: this.SelectedDetail?.Name }),
            key: 'k1', acceptLabel: this.translateService.instant('VWBP.PUBLIC.RT_LOESCHEN_BUTTON'), acceptButtonStyleClass: 'p-button-primary', rejectLabel: this.translateService.instant('CORE.COMMON.ABBRECHEN_BUTTON'), rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: () => {
                this.performDeleteRechtstraegerDraft();
            }
        });
    }

    private async performDeleteRechtstraegerDraft() {
        try {
            await this.apiService.delete(`v1/vwbp/rechtstraeger/${this.Model.Id}`, null);
            this.commonService.confirm({ message: this.translateService.instant('VWBP.PUBLIC.RT_LOESCHEN_SUCCESS'), header: this.translateService.instant('VWBP.PUBLIC.RT_LOESCHEN_SUCCESS_HEADER'), icon: '', acceptLabel: 'OK', acceptButtonStyleClass: 'p-button-primary', rejectVisible: false, key: 'k2' });
            this.location.back();
        } catch (err) {
            const msglist = err.ErrorMessage.split(/(?:\r\n|\r|\n)/g);
            this.commonService.confirm({ message: '<ul>' + msglist.map(w => '<li>' + w + '</li>').join('') + '</ul>', header: this.translateService.instant('VWBP.PUBLIC.RT_LOESCHEN_ERROR_HEADER'), acceptLabel: 'OK', acceptButtonStyleClass: 'p-button-primary', icon: '', rejectVisible: false, key: 'k2' });
            this.cdr.markForCheck();
        }
    }

    public async startDeleteRechtstraeger() {
        await this.deleteRechtstraegerForm.InitForm(this.Model.Id, this.SelectedDetail?.Name);
        this.deleteRechtstraegerFormDialog.open();
    }

    onRechtstraegerDeletedSuccessful(event) {
        this.deleteRechtstraegerFormDialog.close();
        this.router.navigate(['/']);
    }

    private closeDeleteForm() {
        this.deleteRechtstraegerFormDialog.close();
    }


    public async editRechtstraeger() {
        await this.editRechtstraegerForm.InitForm(this.Model.CurrentDetailId);
        this.editRechtstraegerFormDialog.open();
    }


    public startRechtstraegerAbgeben() {
        this.commonService.confirm({
            header: this.commonService.translateInstant('VWBP.PUBLIC.RT_VERANTWORTLICHKEIT_ABGEBEN_HEADER'),
            message: this.translateService.instant('VWBP.PUBLIC.RT_VERANTWORTLICHKEIT_ABGEBEN_MSG', { rt: this.RTName }),
            acceptLabel: this.translateService.instant('VWBP.PUBLIC.RT_VERANTWORTLICHKEIT_ABGEBEN_OK_BUTTON'),
            acceptButtonStyleClass: 'p-button-primary',
            rejectLabel: this.translateService.instant('CORE.COMMON.ABBRECHEN_BUTTON'),
            rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
            accept: () => {
                this.uebergabeService.StartRechtstraegerAbgeben(this.Model.Id).then(r => {
                    this.commonService.notificateSuccess(this.translateService.instant('VWBP.PUBLIC.RT_VERANTWORTLICHKEIT_ABGEBEN_SUCCESS'));
                    this.NavigateBack();
                }).catch(err => this.commonService.notificateError(err));
            }
        });
    }


    public async refreshFormulare() {
        await this.loadVersion(this.SelectedVersion.Id);
        this.cdr.detectChanges();
        this.formularListe.forceRefresh();
    }

    // public editRechtstraeger() {
    //     if (this.SelectedRows.length > 0) {
    //         this.FormModelId = this.SelectedRows[0].Id;
    //         this.formularFormDialog.open();
    //     }
    // }

    onRechtstraegerSavedSuccessful(event) {
        this.editRechtstraegerFormDialog.close();
        this.editRechtstraegerForm.InitForm(null);

        this.loadRechtstraeger();

        this.showRechtstraegerForm.InitForm(null);
        this.showRechtstraegerForm.InitForm(this.SelectedDetail.Id);
    }


    private closeForm() {
        this.editRechtstraegerFormDialog.close();
        this.editRechtstraegerForm.InitForm(null);
    }

    public async createFormularC() {
        this.formularListe.createFormularC();
    }

    public async createFormularT() {
        this.formularListe.createFormularT();
    }

    performStatusUebergang(action: ActionFunction, header: string, message: string, acceptLabel: string, successMsg: string, failedMsg: string, navigateBackOnSuccess: boolean = false, successHeader: string = null) {
        this.commonService.confirm({
            header: this.translateService.instant(header),
            message: this.translateService.instant(message), key: 'k1', acceptLabel: this.translateService.instant(acceptLabel), acceptButtonStyleClass: 'p-button-primary', rejectLabel: this.translateService.instant('CORE.COMMON.ABBRECHEN_BUTTON'), rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: async () => {
                try {
                    await action();

                    this.commonService.notificateSuccess(this.translateService.instant(successMsg), this.translateService.instant(successHeader || header));
                    if (navigateBackOnSuccess)
                        this.location.back();
                    else
                        this.loadLastVersion();
                } catch (err) {
                    const msglist = err.ErrorMessage.split(/(?:\r\n|\r|\n)/g);
                    this.commonService.confirm({ message: '<ul>' + msglist.map(w => '<li>' + w + '</li>').join('') + '</ul>', header: this.translateService.instant(failedMsg), acceptLabel: this.translateService.instant('CORE.COMMON.SCHLIESSEN_BUTTON'), acceptButtonStyleClass: 'p-button-primary', icon: '', rejectVisible: false, key: 'k2' });
                }

                this.cdr.markForCheck();
            }
        });

        this.cdr.markForCheck();
    }

    showHinweisWBErfassung() {
        this.commonService.confirm({
            header: 'Definition wirtschaftlich berechtigte Person',
            message: this.hinweisWBErfassung,
            key: 'k1',
            acceptLabel: 'Schliessen',
            acceptButtonStyleClass: 'p-button-primary',
            rejectVisible: false
        });
    }

    enableTaskBearbeitungRueckgaengig() {
        return this.Model && this.Model.CurrentVersionId === this.SelectedVersion.Id && this.SelectedVersion.Typ.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWBP &&
            (this.SelectedVersion.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_MUTIEREN);
    }

    BearbeitungRueckgaengig() {
        this.performStatusUebergang(async () => await this.rechtstraegerService.InBearbeitungRueckgaengig(this.Model.Id),
            'VWBP.PUBLIC.BEARBEITEN_RUECKGAENGIG_HEADER',
            'VWBP.PUBLIC.BEARBEITEN_RUECKGAENGIG_MSG',
            'VWBP.PUBLIC.BEARBEITEN_RUECKGAENGIG_ACCEPT_BUTTON',
            'VWBP.PUBLIC.BEARBEITEN_RUECKGAENGIG_SUCCESS',
            'VWBP.PUBLIC.BEARBEITEN_RUECKGAENGIG_FAILED');
    }

    ErfassungAbschliessen() {
        this.performStatusUebergang(async () => await this.rechtstraegerService.SetErfassungAbgeschlossen(this.Model.Id),
            'VWBP.PUBLIC.ERFASSUNG_ABSCHLIESSEN_HEADER',
            'VWBP.PUBLIC.ERFASSUNG_ABSCHLIESSEN_MSG',
            'VWBP.PUBLIC.ERFASSUNG_ABSCHLIESSEN_ACCEPT_BUTTON',
            'VWBP.PUBLIC.ERFASSUNG_ABSCHLIESSEN_SUCCESS',
            'VWBP.PUBLIC.ERFASSUNG_ABSCHLIESSEN_FAILED', true,
            'VWBP.PUBLIC.ERFASSUNG_ABSCHLIESSEN_SUCCESS_HEADER');
    }

    InBearbeitungSetzen() {
        this.performStatusUebergang(async () => await this.rechtstraegerService.SetInBearbeitung(this.Model.Id),
            'VWBP.PUBLIC.IN_BEARBEITUNG_HEADER',
            'VWBP.PUBLIC.IN_BEARBEITUNG_MSG',
            'VWBP.PUBLIC.IN_BEARBEITUNG_ACCEPT_BUTTON',
            'VWBP.PUBLIC.IN_BEARBEITUNG_SUCCESS',
            'VWBP.PUBLIC.IN_BEARBEITUNG_FAILED');
    }

    public async getExportItems() {
        if (this.Model == null)
            this.ExportItems = [];
        else {
            const items: MenuItem[] = [];

            const templates = await this.apiService.getModelList(VwbPVorlageDefInfoDto, `v1/vwbp/document/vorlagedefs/rt/${this.Model.Id}/version/${this.SelectedVersion.Id}`);

            templates.forEach(w => {
                items.push({ label: w.Bezeichnung, command: () => this.GenerateDocument(w) });
            });

            this.ExportItems = items;
        }
    }

    public async GenerateDocument(vorlageDef: VwbPVorlageDefInfoDto) {
        try {
            this.commonService.showGlobalLoader();
            const blob = await this.apiService.getBlob(`v1/vwbp/document/generate/${vorlageDef.Id}/rt/${this.Model.Id}/version/${this.SelectedVersion.Id}`);
            if (blob) {
                let fname = vorlageDef.DefaultFilename ?? vorlageDef.Bezeichnung;
                if (vorlageDef.Format.Id === VwbPListDetailConst.VWBP_VORLAGE_FORMAT_WORD)
                    fname = fname + '.docx';
                else
                    fname = fname + '.pdf';
                this.fileSaverService.save(blob, fname);
            }
        } catch (err) {
            this.cdr.markForCheck();
            this.commonService.notificateError(err);
        } finally {
            this.commonService.hideGlobalLoader();
        }
    }
}
