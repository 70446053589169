import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService } from '@avi-x/avi-core';

export enum AviCommentMode {
    // eslint-disable-next-line no-unused-vars
    Readonly, Create, Edit
}

@Component({
    selector: 'vwbp-intern-rechtstraeger-comment-list',
    templateUrl: './rechtstraeger-comment-list.component.html',
    styleUrls: ['./rechtstraeger-comment-list.component.scss'],
    providers: [DecimalPipe, PercentPipe]
})
export class VwbPInternRechtstraegerCommentListComponent {
    RechtstraegerCommentIds: any[] = [];
    VersionCommentIds: any[] = [];

    CommentIds: any[] = [];

    NewCommentId: string = null;
    EditCommentId: string = null;

    _RechtstraegerId: string = null;
    public get RechtstraegerId(): string {
        return this._RechtstraegerId;
    }

    @Input('rechtstraeger-id')
    public set RechtstraegerId(value: string) {
        this._RechtstraegerId = value;
    }

    @Input('readonly')
    public Readonly: boolean = false;

    @Input('force-collapsed')
    public ForceCollapsed: boolean = false;

    _VersionId: string = null;
    public get VersionId(): string {
        return this._VersionId;
    }

    @Input('version-id')
    public set VersionId(value: string) {
        this._VersionId = value || this.commonService.GuidEmpty;
        this.LoadComments(true);
    }

    @Input('version-typ')
    public VersionTyp: any = null;

    @Input('version-number')
    VersionNumber;

    @Input('toggleable')
    Toggleable: boolean = true;

    @Output('onChange')
    OnChange: EventEmitter<any> = new EventEmitter<any>();

    NumOpenComments = 0;
    NumClosedComments = 0;

    Expanded = false;

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, private cdr: ChangeDetectorRef) {
    }

    async LoadComments(autoExpand: boolean) {
        if (this.RechtstraegerId && this.VersionId) {
            this.CommentIds = await this.apiService.get(`v1/intern/vwbp/comment/idlist/${this.RechtstraegerId}/version/${this.VersionId}`);
            await this.GetOpenCommentCount();
            if (autoExpand)
                this.Expanded = this.NumOpenComments > 0;
            this.cdr.markForCheck();
        }
    }


    async GetOpenCommentCount() {
        if (this.RechtstraegerId && this.VersionId) {
            const res = await this.apiService.get(`v1/intern/vwbp/comment/numopen/${this.RechtstraegerId}/version/${this.VersionId}`);
            this.NumOpenComments = res ?? 0;
            this.NumClosedComments = this.CommentIds.length - this.NumOpenComments;
        }
    }

    OnDeleteComment(id: string) {
        this.LoadComments(false);
        this.OnChange.emit(null);
    }

    OnEditComment(id: string) {
        this.EditCommentId = id;
    }

    OnEditCommentCancelled(id: string) {
        this.EditCommentId = null;
    }

    OnEditCommentSaved(id: string) {
        this.EditCommentId = null;
        this.LoadComments(false);
        this.OnChange.emit(null);
    }

    OnCreateCommentCancelled() {
        this.NewCommentId = null;
    }

    OnCreateCommentSaved() {
        this.NewCommentId = null;
        this.LoadComments(false);
        this.OnChange.emit(null);
    }

    CreateComment() {
        this.NewCommentId = this.commonService.GuidEmpty;
    }
}
