import { Router } from '@angular/router';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService, AviListDetailConst, AviFormFieldService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviBusinesscaseFormComponent } from './businesscase-form.component';
import { BusinesscaseSearchResponseDto } from '@avi-x/avi-dto/businesscase/businesscasesearchresponsedto.model';
import { AviBusinesscaseService } from '../services/businesscase.service';
import { SqlQueryParameterVM } from '@avi-x/avi-dto/reporting/sqlqueryparameter-vm.model';

const nameof = (nameFunction: ((obj: BusinesscaseSearchResponseDto) => any)) => typed_nameof<BusinesscaseSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-businesscase-searcher',
    templateUrl: './businesscase-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseSearcherComponent extends AviAbstractModelSearcherComponent {
    ClassName = 'CORE.COMMON.MODEL.CAFBUSINESSCASE';
    SettingsKey = 'settings.searcher.businesscase';
    BaseUrl = 'businesscase';
    FormType = AviBusinesscaseFormComponent;

    CanEdit: boolean = false;

    @Input('get-filters-extension')
    public GetFiltersExtension: () => string[] = (): string[] => [];

    private _Context2Id: string = null;
    public get Context2Id(): string { return this._Context2Id; }

    @Input('context2-id')
    public set Context2Id(value: string) {
        const valueOld = this._Context2Id;
        this._Context2Id = value;
        if (value && value !== valueOld && this.searcher)
            setTimeout(() => this.searcher.forceRefresh());
    }

    constructor(
        public commonService: AviCommonService,
        public router: Router,
        public formFieldService: AviFormFieldService,
        public apiService: AviApiService,
        public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        public businesscaseService: AviBusinesscaseService,
        public cdr: ChangeDetectorRef
    ) {
        super(commonService, apiService, cdr);

        this.permissionsService.hasPermission('CAFBusinesscase schreiben').then(w => this.CanEdit = w);
    }

    onDoubleClickSearcher(data) {
        if (this.SelectedRows)
            this.businesscaseService.openBusinesscase(this.SelectedRows.Id);
    }

    public GetRowClass = (row: any): string => {
        let ret = ''; //'no-border-left no-border-right';
        if (row) {
            if (row['Erledigt'] === true) {
                ret += row['Approved'] === true ? ' businesscase-row-green' : ' businesscase-row-red';
            }
        }

        return ret;
    }

    getFilters() {
        console.warn("getfiltersext", this.GetFiltersExtension)
        if (this.GetFiltersExtension)
            return this.GetFiltersExtension;
        else
            return [];
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
        searcher.beginInit();

        if (this.businesscaseService.isIntern()) {
            this.searcher.SearcherParameters.push(this.formFieldService.CreateFieldFromSearcherParameter(
                new SqlQueryParameterVM('fltOwner', AviListDetailConst.ATTRTYPE_BOOL, 'CORE.COMMON.MODEL.CAFBUSINESSCASE.MEINE', AviListDetailConst.JA, AviListDetailConst.JA), 3));

            this.searcher.SearcherParameters.push(this.formFieldService.CreateFieldFromSearcherParameter(
                new SqlQueryParameterVM('fltUnassigned', AviListDetailConst.ATTRTYPE_BOOL, 'CORE.COMMON.MODEL.CAFBUSINESSCASE.NICHTZUGEWIESENE', AviListDetailConst.JA, AviListDetailConst.JA), 3));

            this.searcher.SearcherParameters.push(this.formFieldService.CreateFieldFromSearcherParameter(
                new SqlQueryParameterVM('fltAlle', AviListDetailConst.ATTRTYPE_BOOL, 'CORE.COMMON.MODEL.CAFBUSINESSCASE.ALLE_ANZEIGEN', AviListDetailConst.JA, AviListDetailConst.JA), 3));

            this.searcher.getSearcherParameter('fltOwner').Value = true;
            this.searcher.getSearcherParameter('fltAlle').Value = true;
        }

        searcher.addDateColumn(nameof(c => c.ErstellDatum), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.ERSTELLDATUM', 'short', true);
        searcher.addTextColumn(nameof(c => c.NumberBez), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.NUMBER', true);
        searcher.addTextColumn(nameof(c => c.Title), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.TITLE', true);
        searcher.addTextColumn(nameof(c => c.StatusBez), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.STATUS', true);
        searcher.addListTypeColumn(nameof(c => c.Priority), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.PRIORITY', 'caf_businesscase_priority', true, true);
        searcher.addListTypeColumn(nameof(c => c.Verantwortlichkeit), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.VERANTWORTLICHKEIT', 'caf_statusdef_verantwortlichkeit', true, true);
        searcher.addDateColumn(nameof(c => c.DueDate), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.DUEDATE', null, true);
        searcher.addDateColumn(nameof(c => c.Frist), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.FRIST', null, true);

        if (this.businesscaseService.isIntern())
            searcher.addTextColumn(nameof(c => c.AssignedToUsername), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.ASSIGNEDTO', true);

        searcher.addTextColumn(nameof(c => c.ApplicantUsername), 'CORE.COMMON.MODEL.CAFBUSINESSCASE.APPLICANT', true);

        searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg = { ...searchConfig };
        cfg.Query = searchValue;
        cfg.ContextId = this.ContextId;
        if (this.businesscaseService.isIntern()) {
            cfg.FilterOwner = this.searcher.getSearcherParameter('fltOwner').Value;
            cfg.FilterUnassigned = this.searcher.getSearcherParameter('fltUnassigned').Value;
            cfg.FilterAlle = this.searcher.getSearcherParameter('fltAlle').Value;
        }
        const res = await this.apiService.postPagedResult(BusinesscaseSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }

    public async createObject() {
        const modelId = await this.businesscaseService.createBusinesscase(null, null, this.ContextId, this.Context2Id);
        if (modelId) {
            this.searcher.forceRefresh();
            this.businesscaseService.openBusinesscase(modelId);
        }
    }

    public _onParameterValueChange(data) {
        if (data.field === 'fltOwner' && data.value === true && this.searcher.getSearcherParameter('fltUnassigned').Value === true)
        this.searcher.setSearcherParameter('fltUnassigned', false);

        if (data.field === 'fltUnassigned' && data.value === true && this.searcher.getSearcherParameter('fltOwner').Value === true)
            this.searcher.setSearcherParameter('fltOwner', false);
    }
}
