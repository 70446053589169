import { ChangeDetectorRef, Component } from '@angular/core';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternSchwaerzenService } from '../../services/schwaerzen.service';
import { VwbPInternSchwaerzenBaseComponent } from './schwaerzen-base.component';


@Component({
    selector: 'vwbp-intern-schwaerzen-bearbeitet',
    template: `
        <mat-tab-group class="mat-tab-tertiary" mat-align-tabs="center" animationDuration="0ms" (selectedTabChange)="setPausiert($event)" [selectedIndex]="getSelectedIndex()">
            <mat-tab label="Aktiv" ></mat-tab>
            <mat-tab label="Pausiert"></mat-tab>
        </mat-tab-group>

        <vwbp-intern-schwaerzen-antrag-searcher #searcher settingskey="settings.searcher.schwaerzen.bearbeitet" [show-aktiv]="true" [last-version]="true" [get-filters]="GetFilters" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'Sitz', 'AntragGueltigAb', 'AntragEntscheidung', 'AntragEntscheidAm', 'AntragGueltigBis', 'AntragRechtsmittelfrist', 'AntragBegruendung' ]">
        </vwbp-intern-schwaerzen-antrag-searcher>

        <div *ngIf="currentUserIsAjuUserSchreiben()" fxLayout="row">
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-antrag-rechtsmittelfrist-anpassen-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-antrag-rechtsmittelfrist-anpassen-task>
            </div>
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-antrag-bewilligen-task [neu-setzen]="true" [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-antrag-bewilligen-task>
            </div>
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-antrag-ablehnen-task [neu-setzen]="true" [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-antrag-ablehnen-task>
            </div>
            <div *ngIf="GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-pausieren-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-pausieren-task>
            </div>
            <div *ngIf="!GetAktivValue()" class="pl-3 pr-3" fxLayout="column" fxLayoutAlign="end left" fxFlex="30" fxLayoutGap="1em">
                <vwbp-intern-schwaerzen-aktivieren-rechtsmittelfrist-setzen-task [selected-rechtstraeger]="GetSelectedRechtstraeger()" (onSave)="Refresh()" ></vwbp-intern-schwaerzen-aktivieren-rechtsmittelfrist-setzen-task>
            </div>
        </div>
        `
})
export class VwbPInternSchwaerzenBearbeitetComponent extends VwbPInternSchwaerzenBaseComponent {
    constructor(public commonService: AviCommonService, public schwaerzenService: VwbPInternSchwaerzenService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, schwaerzenService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters = (): string[] => {
        return [
            `AntragStatus eq ${VwbPListDetailConst.VWBP_SCHWAERZEN_ANTRAG_STATUS_BEARBEITET}`,
            `AntragAktiv eq ${this.GetAktivValue() ? VwbPListDetailConst.VWBP_MAHNWESEN_AKTIV : VwbPListDetailConst.VWBP_MAHNWESEN_PAUSIERT}`
        ];
    }
}
