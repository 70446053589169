import { Component, ContentChildren, Input, QueryList, ChangeDetectorRef, Output, EventEmitter, AfterViewInit, AfterContentInit } from '@angular/core';
import { AviCommonService } from '../../../services/common.service';
import { AviSessionControllerService } from '../../../services/session-controller.service';
import { AviTabPanel } from './tabpanel.component';

@Component({
    selector: 'avi-tabView',
    template: ` 
        <div [class]="orientation === 'vertical' ? 'vertical-tabs' : ''">
            <mat-tab-group
                [ngClass]="tabViewClass"
                animationDuration="0ms"
                (selectedTabChange)="handleTabChange($event)"
                [(selectedIndex)]="activeIndex"
            >
                <mat-tab *ngFor="let item of contentItems" [disabled]="_disabled">
                    <ng-template mat-tab-label>
                        <span [style]="getLabelStyle()">
                            <i class="{{ item.icon }} mr-2"></i>
                            <avi-textblock-span *ngIf="item.title != null" [identifier]="item.title" [disable-html]="true"></avi-textblock-span>
                            <p-badge *ngIf="item.badge != null" class="ml-1" styleClass="tabview-badge" [value]="item.badge" [severity]="item.badgeSeverity">
                            </p-badge>
                        </span>
                    </ng-template>
                    <ng-container *ngTemplateOutlet="item.contentTemplate"></ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    `,
    styleUrls: ['./tabview.component.scss'],
})
export class AviTabView implements AfterContentInit {
    @ContentChildren(AviTabPanel) public contentItems: QueryList<AviTabPanel>;

    @Input('orientation')
    public orientation : 'horizontal' | 'vertical' = 'vertical';

    @Input('width')
    public width = '12.5rem';

    @Input('tabview-class')
    public tabViewClass;
    
    @Input('session-modul')
    public IsSessionModul: boolean = false;

    _disabled = false;
    @Input('disabled')
    public set disabled(value: boolean) {
        this._disabled = value;
    }
    
    _active: boolean = false;
    @Input('active')
    public set Active(value: boolean) {
        this._active = value;
        this.handleTabViewActive();
    }

    @Output() activeIndexChange: EventEmitter<number> = new EventEmitter();

    _lastActiveIndex: number = -1;
    _activeIndex: number;
    @Input() get activeIndex(): number {
        return this._activeIndex;
    }

    set activeIndex(val: number) {
        if (val !== this._activeIndex) this.selectTab(val);
    }

    constructor(public commonService: AviCommonService, public cdr: ChangeDetectorRef, public sessionController: AviSessionControllerService) {}

    ngAfterContentInit() {
        const tab = this.contentItems.toArray()[0];
        if (tab) {
            tab.active = true;
            this._activeIndex = 0;

            if (this.IsSessionModul)
                this.sessionController.setCurrentSessionModul(tab.Id);
        }
    }

    handleTabViewActive() {
        const tabs = this.contentItems.toArray();
        if (!this._active) {
            this._lastActiveIndex = this._activeIndex;
            tabs.forEach((tab) => (tab.active = false));
            this.cdr.markForCheck();
        } else {
            if (this._lastActiveIndex >= 0) {
                tabs[this._lastActiveIndex].active = true;
                this.cdr.markForCheck();
            }
        }
    }

    getLabelStyle() {
        return this.orientation === 'vertical' ? { 'width': this.width, 'text-align': 'left'} : {};
    }
    
    handleTabChange(e) {
        const tabs = this.contentItems.toArray();
        tabs.forEach((tab) => (tab.active = false));
        tabs[e.index].active = true;
        this._activeIndex = e.index;
    }

    selectTab(val: number | string) {

        if (typeof val === "number")
            this._activeIndex = val;
        else {
            const idx = this.findTab(val);
            if (idx >= 0)
                this._activeIndex = idx;
            else
                return;
        }

        if (this.contentItems && this.contentItems.length && this._activeIndex != null && this.contentItems.length > this._activeIndex) {
            const tab = this.contentItems.toArray()[this._activeIndex];
    
            // deactivate all tabs
            this.contentItems.toArray().forEach((tab) => (tab.active = false));
    
            // activate the tab the user has clicked on.
            tab.active = true;

            if (this.IsSessionModul)
                this.sessionController.setCurrentSessionModul(tab.Id);

            this.activeIndexChange.emit(this._activeIndex);                    
        }
    }

    findTab(id: string) {
        return this.contentItems.toArray().findIndex(w => w.Id === id);
    }
}
