import { OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AviApiService } from './../../services/api.service';
import { OnChanges, ViewChild } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import DOMPurify from 'dompurify';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { AviResourceFormComponent, ResourceFormMode } from '../resource/resource-form/resource-form.component';
import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';
import { AppLevel, ResourceLabel } from '@avi-x/avi-dto/system/resourcelabel.model';
import { first, tap } from 'rxjs/operators';
import { AviAuthService } from '../../services/auth.service';
import { AviCommonService } from '../../services/common.service';
import { ObjectUtils } from '../utils/object-utils';

@Component({
    template: ''
})
export abstract class AviTextblockBaseComponent implements OnChanges, OnInit {
    constructor(public authService: AviAuthService,
        protected sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef,
        private apiService: AviApiService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private commonService: AviCommonService
    ) { }

    formModel: ResourceLabel = null;
    selectedSprache: ReferenceDto = { Id: 'ed3902c9-322e-480d-b327-72df33ef5266', Representation: 'Deutsch' };

    @ViewChild('cm') copyMenu: ContextMenu;
    items: MenuItem[] = [
        { label: 'Text editieren', icon: 'pi pi-pencil', command: (event) => this.editResource() }
    ];

    @Input('identifier')
    public Identifier: string;

    Key: string;
    KeyClass: string;
    IsIdentifier: boolean = false;


    TextRaw:string = null;

    Text: string | SafeHtml = null;

    @Input('style')
    public Style: any = {};

    @Input('params')
    public params: { [klass: string]: any };

    @Input('classes')
    public Classes: string;

    @Input('disable-html')
    public DisableHtml: boolean = false;

    @Output('onTextblockClicked')
    public onTextblockClicked: EventEmitter<any> = new EventEmitter();

    onRightClicked(e: any): void {
        if (this.copyMenu)
            this.copyMenu.show(e);
    }

    private isPanSelected = true;
    ngOnInit() {
        this.authService.hasInlineEditingRole().subscribe(v => {
            this.isPanSelected = v;
        });
    }

    public async editResource() {
        var resource = await this.apiService.getModel(ResourceLabel, `/resource/${this.Key}/sprache/${this.selectedSprache.Id}`);
        var mode = ResourceFormMode.UPDATE;

        if (!resource) {
            resource = ObjectUtils.ObjectToBaseModel(ResourceLabel, new ResourceLabel());
            resource.AppLevel = AppLevel.WebClient;
            resource.Key = this.Key;
            mode = ResourceFormMode.CREATE;
        }

        this.formModel = resource;

        const ref = this.dialogService.open(AviResourceFormComponent, {
            header: mode === ResourceFormMode.CREATE ? this.translateService.instant('CORE.RESOURCE.CREATE') : this.translateService.instant('CORE.RESOURCE.EDIT'),
            closable: true,
            width: '80%',
            baseZIndex: 10000,
            data: {
                sprache: this.selectedSprache,
                mode: mode,
                model: this.formModel,
                alwaysHtml: !this.DisableHtml
            }
        });

        const res = await this.commonService.waitForDialogClose(ref);

        if (res) {
            this.translateService.reloadLang('de').toPromise().then(x => {
                this.translateService.get(this.Key, this.params).toPromise().then(w => this.setTextValue(w));
            });
        }
    }

    logTextblockInfo() {
        console.log(`AviTextblockComponent[${this.Identifier}]`, this.Text);
    }

    ngOnChanges() {
        if (this.Identifier) {
            this.setTextValue(`!!! Kein Textblock definiert mit dem Identifier <strong>${this.Identifier}</strong> !!!`);
            this.Key = this.Identifier.replace(/-/g, '.');
            this.IsIdentifier = new RegExp('^([a-zA-Z0-9_]+\\.)+([a-zA-Z0-9_]+)$').test(this.Key);
            if (this.IsIdentifier) {
                this.Key = this.Key.toUpperCase();
                this.KeyClass = this.Key.replace(/\./g, '-').toLowerCase();
                this.translateService.get(this.Key, this.params).toPromise().then(w => this.setTextValue(w));
            }
            else 
                this.setTextValue(this.Identifier);            
        }
    }

    setTextValue(text: string) {
        this.TextRaw = text;
        // Wir verwenden DOMPurify anstelle "this.sanitizer.sanitize(SecurityContext.HTML, text)" damit die Farben (resp. alle Style-Attribute) im Textblock funktionieren
        const sHtml = text ? this.sanitizer.bypassSecurityTrustHtml(DOMPurify.sanitize(text, { ADD_ATTR: ['target'] })) : '';
        this.Text = sHtml;
        this.cdr.markForCheck();
    }

    getClasses(): string {
        return `avi-textblock ${this.KeyClass} ${this.Classes || ''} ${this.isPanSelected ? 'inlineediting' : ''}`;
    }

    getStyle() {
        return { ...{ 'padding': '0px', 'height': 'auto' }, ...this.Style };
    }

    onTextblockClick(evt) {
        this.onTextblockClicked.emit(evt);
    }

    get isEmpty() {
        const trimmed = this.TextRaw?.toString().replace(/<[^>]*>/g, '');
        return  trimmed == '' || trimmed == '-';
    }
}


@Component({
    selector: 'avi-textblock',
    template: `
        <p-contextMenu *ngIf="(authService.hasInlineEditingRole() | async) && IsIdentifier" #cm [model]="items" appendTo="body" [baseZIndex]="10000"></p-contextMenu>
        <div
            *ngIf="Text && !isEmpty"
            (dblclick)="logTextblockInfo()"
            [attr.data-textblock-identifier]="Identifier"
            [ngClass]="getClasses()"
            [ngStyle]="getStyle()"
            (click)="onTextblockClick($event)"
            [innerHTML]="Text"
            (contextmenu)="onRightClicked($event)">
        </div><!--<avi-core-dbg [m]="Identifier" t="avi-textblock"></avi-core-dbg>-->
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviTextblockComponent extends AviTextblockBaseComponent {
}

@Component({
    selector: 'avi-textblock-span',
    template: `
        <p-contextMenu *ngIf="(authService.hasInlineEditingRole() | async) && IsIdentifier" #cm [model]="items" appendTo="body" [baseZIndex]="10000"></p-contextMenu>
        <span
            *ngIf="Text && !isEmpty"
            (dblclick)="logTextblockInfo()"
            [attr.data-textblock-identifier]="Identifier"
            [ngClass]="getClasses()"
            [ngStyle]="getStyle()"
            (click)="onTextblockClick($event)"
            [innerHTML]="Text"
            (contextmenu)="onRightClicked($event)">
        </span><!--<avi-core-dbg [m]="Identifier" t="avi-textblock"></avi-core-dbg>-->
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviTextblockSpanComponent extends AviTextblockBaseComponent {
}

