import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { GenericRef } from '../shared/genericref.model';
import { CommentKeywordDto } from './comment-keyword-dto.model';

export class Comment extends BaseModel {
    
    @Expose()
    public get ErstelltVon(): string { return this.getProperty('ErstelltVon'); }
    public set ErstelltVon(value: string) { this.setProperty('ErstelltVon', value); }

    @Expose()
    public get IsRTF(): boolean { return this.getProperty('IsRTF'); }
    public set IsRTF(value: boolean) { this.setProperty('IsRTF', value); }

    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }

    @Expose()
    public get Tags(): string { return this.getProperty('Tags'); }
    public set Tags(value: string) { this.setProperty('Tags', value); }

    @Expose()
    public get Comment(): string { return this.getProperty('Comment'); }
    public set Comment(value: string) { this.setProperty('Comment', value); }

    @Expose()
    @Type(() => Date)
    public get ErledigtAm(): Date { return this.getProperty('ErledigtAm'); }
    public set ErledigtAm(value: Date) { this.setProperty('ErledigtAm', value); }

    @Expose()
    public get ErledigtDurch(): string { return this.getProperty('ErledigtDurch'); }
    public set ErledigtDurch(value: string) { this.setProperty('ErledigtDurch', value); }

    @Expose()
    @Type(() => GenericRef)
    public get ModelRef(): GenericRef { return this.getProperty('ModelRef'); }
    public set ModelRef(value: GenericRef) { this.setProperty('ModelRef', value); }

    @Expose()
    public Keywords: CommentKeywordDto[] = [];
    
    @Expose()
    public get Internal(): boolean { return this.getProperty('Internal'); }
    public set Internal(value: boolean) { this.setProperty('Internal', value); }

    // Transient 
    @Expose()
    public get Eingangsstatus(): string { return this.getProperty('Eingangsstatus'); }
    public set Eingangsstatus(value: string) { this.setProperty('Eingangsstatus', value); }

    @Expose()
    public get Ausgangsstatus(): string { return this.getProperty('Ausgangsstatus'); }
    public set Ausgangsstatus(value: string) { this.setProperty('Ausgangsstatus', value); }
}
