import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { AviCommonService } from '@avi-x/avi-core';
import { VwbPListDetailConst, VwbPCommonService } from '@avi-x/vwbp-public';
import { VwbPInternRechtstraegerVersionenSearcherComponent } from '../../../rechtstraeger/components/rechtstraeger-searcher/rechtstraeger-versionen-searcher.component';
import { VwbPInternUebergabeService } from '../../service/uebergabe.service';

@Component({
    selector: 'vwbp-intern-uebergabe-mit-verantwortlicher',
    templateUrl: './uebergabe-mit-verantwortlicher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,

})
export class VwbPInternUebergabeMitVerantwortlicherComponent {
    @ViewChild('searcher', { static: true })
    private searcher: VwbPInternRechtstraegerVersionenSearcherComponent;

    // private _includeDeleted: boolean = false;

    // public get IncludeDeleted(): boolean {
    //     return this._includeDeleted;
    // }
    // public set IncludeDeleted(val: boolean) {
    //     this._includeDeleted = val;
    //     this.searcher.forceRefresh();
    // }

    Bemerkung: string;

    constructor(
        private commonService: AviCommonService,
        private cdr: ChangeDetectorRef,
        private uebergabeService: VwbPInternUebergabeService,
        public vwbpCommonService: VwbPCommonService
    ) { }


    GetFilters = (): string[] => {
        const ret = [`Uebergabestatus eq ${VwbPListDetailConst.VWBP_RT_UEBERGABESTATUS_NORMAL}`];
        // if (this._includeDeleted === false)
        //     ret = [...ret, 'OwnerContainerId ne null'];
        return ret;
    }

    get ButtonParams() {
        return { cnt: this.GetSelectionCount() };
    }

    GetSelectionCount() {
        return this.searcher.GetSelectedRechtstraeger().length;
    }

    IsNothingSelected(): boolean {
        return this.GetSelectionCount() === 0;
    }

    StartRechtstraegerLoeschen() {
        const selectedRT = this.searcher.GetSelectedRechtstraeger();
        this.commonService.confirm({
            header: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_LOESCHEN'),
            message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_LOESCHEN_Q', { cnt: selectedRT.length }), acceptLabel: 'Ja, löschen', acceptButtonStyleClass: 'p-button-primary', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: () => {
                this.commonService.showGlobalLoader();
                this.uebergabeService.StartRechtstraegerLoeschen(selectedRT.map(w => w.RechtstraegerId), this.Bemerkung).then(r => {
                    this.Bemerkung = '';
                    this.searcher.forceRefresh();
                    this.commonService.hideGlobalLoader();
                    this.cdr.markForCheck();
                }).catch(err => {
                    this.commonService.hideGlobalLoader();
                    this.commonService.notificateError(err);
                });
            }
        });
    }

    StartRechtstraegerZuAjuHolen() {
        const selectedRT = this.searcher.GetSelectedRechtstraeger();
        this.commonService.confirm({
            header: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_ZU_AJU_HOLEN'),
            message: this.commonService.translateInstant('VWBP.INTERN.UEBERGABE_MANAGEMENT.RT_ZU_AJU_HOLEN_Q', { cnt: selectedRT.length }), acceptLabel: 'Ja', acceptButtonStyleClass: 'p-button-primary', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: () => {
                this.commonService.showGlobalLoader();
                this.uebergabeService.StartRechtstraegerAbgeben(selectedRT.map(w => w.RechtstraegerId), this.Bemerkung).then(r => {
                    this.Bemerkung = '';
                    this.searcher.forceRefresh();
                    this.commonService.hideGlobalLoader();
                    this.cdr.markForCheck();
                }).catch(err => {
                    this.commonService.hideGlobalLoader();
                    this.commonService.notificateError(err);
                });
            }
        });
    }
}
