import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviBaseSearcherComponent, AviCommonService, typed_nameof } from '@avi-x/avi-core';
import { StatusWechselDefSearchResponseDto } from '@avi-x/avi-dto/workflow/statuswechseldefsearchresponsedto.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviStatusWechselDefFormComponent } from './statuswechseldef-form.component';

const nameof = (nameFunction: ((obj: StatusWechselDefSearchResponseDto) => any)) => typed_nameof<StatusWechselDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-statuswechseldef-searcher',
    templateUrl: './statuswechseldef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviStatusWechselDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF';
    SettingsKey = 'settings.searcher.statuswechseldef';
    BaseUrl = 'workflow/statuswechseldef';
    FormType = AviStatusWechselDefFormComponent;

    CanEdit: boolean = false;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFStatusWorkflowDef schreiben').then(w => this.CanEdit = w);
    }

    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            // this.sessionController.openSession('statuswechseldef-index', this.SelectedRows.Id);
            this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.BEZEICHNUNG1', true);
        searcher.addTextColumn(nameof(c => c.Eingangsstatus), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.EINGANGSTATUS', true);
        searcher.addTextColumn(nameof(c => c.Ausgangsstatus), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.AUSGANGSTATUS', true);
        searcher.addListTypeColumn(nameof(c => c.BemerkungEinblenden), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.BEMERKUNGEINBLENDEN', 'janein', true, true);
        searcher.addListTypeColumn(nameof(c => c.BemerkungZwingend), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.BEMERKUNGZWINGEND', 'janein', true, true);
        searcher.addListTypeColumn(nameof(c => c.AutoAusfuehren), 'CORE.COMMON.MODEL.CAFSTATUSWECHSELDEF.AUTOAUSFUEHREN', 'janein', true, true);        
		searcher.endInit();
    }
    
    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        if (this.ContextId) {
            const cfg =  { ...searchConfig };
            cfg.Query = searchValue;
            cfg.ContextId = this.ContextId;
            const res = await this.apiService.postPagedResult(StatusWechselDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
            return res;
        }

        return null;
    }
}
