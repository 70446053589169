import { AviDokumentModule } from './../dokument/dokument.module';
import { AviNatPersonSummaryComponent } from './components/natperson-summary/natperson-summary.component';
import { AviPartnerKommunikationFormComponent } from './components/partner-kommunikation-form/partner-kommunikation-form.component';
import { AviActivityModule } from './../activity/activity.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AviPartnerKommunikationListeComponent } from './components/partner-kommunikation-liste/partner-kommunikation-liste.component';
import { AviAdresseListeComponent } from './components/adresse-liste/adresse-liste.component';

import { AviCoreModule } from '@avi-x/avi-core';
import { AviQueryDefExecutionSearcherModule } from '@avi-x/avi-report';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AviPartnerSearcherComponent } from './components/partner-searcher/partner-searcher.component';
import { AviPartnerSearcherDetailviewComponent } from './components/partner-searcher-detailview/partner-searcher-detailview.component';
import { AviAdresseFormComponent } from './components/adresse-form/adresse-form.component';

import { AviJurPersonSearcherComponent } from './components/jurperson-searcher/jurperson-searcher.component';
import { AviJurpersonFormComponent } from './components/jurperson-form/jurperson-form.component';
import { AviJurPersonViewComponent } from './components/jurperson-view/jurperson-view.component';
import { AviJurPersonIndexComponent } from './components/jurperson-index/jurperson-index.component';

import { AviNatPersonSearcherComponent } from './components/natperson-searcher/natperson-searcher.component';
import { AviNatpersonFormComponent } from './components/natperson-form/natperson-form.component';
import { AviNatPersonIndexComponent } from './components/natperson-index/natperson-index.component';
import { AviNatPersonViewComponent } from './components/natperson-view/natperson-view.component';

import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DividerModule } from 'primeng/divider';
import { StepsModule } from 'primeng/steps';

import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';

import { MatMenuModule } from '@angular/material/menu';

import { AviPartnerService } from './services/partner.service';
import { AviPartnerRolleListeComponent } from './components/partnerrolle-liste/partnerrolle-liste.component';
import { MatChipsModule } from '@angular/material/chips';

import { AviAdresseListeDetailviewComponent } from './components/adresse-liste/adresse-liste-detailview.component';
import { AviJurPersonSummaryComponent } from './components/jurperson-summary/jurperson-summary.component';
import { AviPartnerIdentifikationListeComponent } from './components/partner-identifikation-liste/partner-identifikation-liste.component';
import { AviPartnerIdentifikationFormComponent } from './components/partner-identifikation-form/partner-identifikation-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { AviAdressBlockDefFormComponent } from './components/adressblockdef/adressblockdef-form.component';
import { AviAdressBlockDefSearcherComponent } from './components/adressblockdef/adressblockdef-searcher.component';
import { AviAdressBlockDefIndexComponent } from './components/adressblockdef/adressblockdef-index.component';
import { AviAdressBlockZeileDefFormComponent } from './components/adressblockdef/adressblockzeiledef-form.component';
import { AviAdressBlockZeileDefSearcherComponent } from './components/adressblockdef/adressblockzeiledef-searcher.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        FlexLayoutModule,

        AviCoreModule,
        AviActivityModule,
        AviQueryDefExecutionSearcherModule,
        AviDokumentModule,

        MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatInputModule,
        MatTabsModule, MatMenuModule, MatChipsModule,

        ScheduleModule,
        CalendarModule,
        CheckboxModule,
        PanelModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        DropdownModule,
        TableModule,
        DialogModule,
        ContextMenuModule,
        TabViewModule,
        InputTextModule,
        InputMaskModule,
        InputTextareaModule,
        AccordionModule,
        DividerModule,
        StepsModule,
        RadioButtonModule,

        TranslateModule
    ],
    exports: [
        AviAdresseFormComponent,
        AviPartnerKommunikationFormComponent,
        AviPartnerKommunikationListeComponent,
        AviPartnerIdentifikationFormComponent,
        AviPartnerIdentifikationListeComponent,
        AviPartnerSearcherComponent,

        AviNatPersonSummaryComponent,
        AviNatPersonSearcherComponent,
        AviNatpersonFormComponent,
        AviNatPersonViewComponent,
        AviNatPersonIndexComponent,

        AviJurPersonSummaryComponent,
        AviJurPersonSearcherComponent,
        AviJurpersonFormComponent,
        AviJurPersonViewComponent,
        AviJurPersonIndexComponent,

        AviPartnerRolleListeComponent,

        AviAdressBlockDefSearcherComponent,
        AviAdressBlockDefIndexComponent,

        RouterModule
    ],
    declarations: [
        AviAdresseFormComponent,
        AviPartnerKommunikationFormComponent,
        AviPartnerKommunikationListeComponent,
        AviPartnerIdentifikationFormComponent,
        AviPartnerIdentifikationListeComponent,

        AviPartnerSearcherComponent,
        AviPartnerSearcherDetailviewComponent,
        AviPartnerRolleListeComponent,

        AviNatPersonSummaryComponent,
        AviNatPersonSearcherComponent,
        AviNatpersonFormComponent,
        AviNatPersonIndexComponent,
        AviNatPersonViewComponent,

        AviJurPersonSummaryComponent,
        AviJurPersonSearcherComponent,
        AviJurpersonFormComponent,
        AviJurPersonViewComponent,
        AviJurPersonIndexComponent,

        AviAdresseListeComponent,
        AviAdresseListeDetailviewComponent,

        AviAdressBlockDefFormComponent,
        AviAdressBlockDefSearcherComponent,
        AviAdressBlockDefIndexComponent,
        AviAdressBlockZeileDefFormComponent,
        AviAdressBlockZeileDefSearcherComponent

    ],
    providers: [AviPartnerService]
})
export class AviPartnerModule { }
