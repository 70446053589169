<label [ngClass]="{ 'label-nonmd': true }">Zustand ab/bis</label>

<div *ngIf="this.CurrentHistory" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign=" center" class="form-field">
    <div fxFlex="initial">
        <avi-core-calendar
            #calendar
            [ngModel]="this.CurrentHistory.ZustandAb"
            [disabled]="false"
            [firstDayOfWeek]="1"
            [dateFormat]="DateFormat"
            dataType="date"
            [showIcon]="false"
            [readonlyInput]="true"
            [showOnFocus]="false"
            [showTime]="false"
            [nullDate]="LowDate"
            name="ZustandAb"
        >
        </avi-core-calendar>
    </div>

    <div fxFlex="initial">
        <avi-core-calendar
            #calendar
            [ngModel]="this.CurrentHistory.ZustandBis"
            [disabled]="false"
            [firstDayOfWeek]="1"
            [dateFormat]="DateFormat"
            dataType="date"
            [showIcon]="false"
            [readonlyInput]="true"
            [showOnFocus]="false"
            [showTime]="false"
            [nullDate]="HighDate"
            name="ZustandBis"
        >
        </avi-core-calendar>
    </div>

    <div fxFlex="initial">
        <button
            pButton
            [disabled]="this.CurrentHistory === this.HistoryStates[0]"
            type="button"
            icon="pi pi-chevron-left"
            class="p-0 h-2em p-button-secondary p-button-text mr-2"
            (click)="SelectPrevHist()"
        ></button>

        <button
            pButton
            [disabled]="this.CurrentHistory === this.HistoryStates[this.HistoryStates.length - 1]"
            type="button"
            icon="pi pi-chevron-right"
            class="p-0 h-2em p-button-secondary p-button-text"
            (click)="SelectNextHist()"
        ></button>

        <button
            pButton
            type="button"
            icon="pi pi-replay"
            class="p-0 h-2em p-button-secondary p-button-text"
            (click)="SelectLastHist()"
        ></button>
    </div>
</div>
