import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TreeNode } from 'primeng/api';
import { AviApiService } from "../../services/api.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

@Component({
    selector: 'avi-meta-browser',
    template: `<p-tree class="metabrowser" [value]="MetaDaten" (onNodeExpand)="nodeExpand($event)" [loading]="loading" selectionMode="single" [(selection)]="selectedMetaDaten"></p-tree>
        <div class="grid justify-content-end mt-4 mr-2">
        <button
            pButton
            type="button"
            [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate"
            (click)="doSchliessen()"
            class="w-100p-m m mr-2 p-button-secondary"
        ></button>
        <button
            pButton
            type="button"
            [label]="'Selektieren'"
            (click)="doSelektieren()"
            [disabled]="!selectedMetaDaten"
            class="w-100p-m m"
        ></button>
    </div>
    `,
    styleUrls: [`./meta-browser.component.scss`],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviMetaBrowserComponent implements OnInit {
    MetaDaten: TreeNode[];
    loading: boolean;
    selectedMetaDaten: TreeNode;

    ModelMetaId: string;
    SelectionMode: 'any' | 'reference' = 'any';

    constructor(private apiService: AviApiService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, public cdr: ChangeDetectorRef) {
    }
    
    async ngOnInit() {
        if (this.config && this.config.data) {
            this.loading = true;
            this.ModelMetaId = this.config.data.Id;
            this.SelectionMode = this.config.data.SelectionMode;
            this.MetaDaten = await this.getMetaDaten(this.ModelMetaId);
            this.loading = false;
        }

        this.cdr.markForCheck();
    }

    private async getMetaDaten(modelMetaId: string) {
        const res = await this.apiService.get(`model/modelmeta/tree/${modelMetaId}`);
        return res.map(w => {
            return {
                label: w.Label,
                leaf: !w.IsReference,
                selectable: this.SelectionMode === 'any' || (this.SelectionMode === 'reference' && w.ModelMetaId != null),
                data: w
            } as TreeNode;
        })
    }

    async nodeExpand(event) {
        if (event.node) {
            event.node.children = await this.getMetaDaten(event.node.data.ModelMetaId);
            for (const node of event.node.children) 
                node.parent = event.node;
            this.cdr.markForCheck();
        }
    }

    doSchliessen() {
        if (this.ref)
            this.ref.close(null);
    }

    getPath() {
        let path = this.selectedMetaDaten.data.AttrName;
        let node = this.selectedMetaDaten.parent;
        while (node) {
            path = `${node.data.AttrName}.${path}`;
            node = node.parent;
        }

        return path;
    }
    
    doSelektieren() {
        const fullPath = this.getPath();
        const attrName = this.selectedMetaDaten.data.AttrName;
        const attrPath = fullPath.substring(0, fullPath.length - attrName.length - 1);

        if (this.ref)
            this.ref.close({
                Attribute: attrName,
                Path: attrPath,
                FullPath: fullPath
            });
    }
}

