import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { ScriptRuleOutputPruefenDto } from '@avi-x/avi-dto/system/scriptruleoutputpruefendto.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: ScriptRuleOutputPruefenDto) => any)) => typed_nameof<ScriptRuleOutputPruefenDto>(nameFunction);

@Component({
    selector: 'avi-scriptruleoutput-pruefen-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptRuleOutputPruefenFormComponent extends AviAbstractModelFormComponent<ScriptRuleOutputPruefenDto> {  
    ClassName = 'Regelverletzung prüfen';
    BaseUrl = 'scriptruleoutput';
    ClassType = ScriptRuleOutputPruefenDto;
    FormType = AviScriptRuleOutputPruefenFormComponent;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
        public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);
    }

    public async createModel(): Promise<ScriptRuleOutputPruefenDto> {
        return null;
    }

    async getModel(id: string): Promise<ScriptRuleOutputPruefenDto> {
        return await this.apiService.getModel(ScriptRuleOutputPruefenDto, `scriptruleoutput/${id}/pruefen/create`);
    }

    async saveModel(model: ScriptRuleOutputPruefenDto) {
       await this.apiService.post('scriptruleoutput/pruefen', model);

       if (this.ref)
           this.ref.close(model);
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.RuleId), 'Regel Nr.', 0, false).setMDSizeHalf().setReadonly());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Status), 'Status', 'caf_scriptruleoutput_status', true, false, true).setMDSizeHalf().setReadonly());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Message1), 'Bezeichnung', false).setMDSizeFull().setReadonly());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Kommentar), 'Kommentar', false).setMDSizeFull());
    }
}
