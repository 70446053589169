<div class="">
    <div *ngIf="!keinePostfachEintrage()" class="mt-4 mb-2"><strong>Dokumente</strong></div>
    <avi-core-base-searcher
        #postfachSearcher
        [contents-padding]="false"
        [get-row-class]="GetRowClass"
        [hide-when-noresults]="true"
        [use-router-params]="false"
        [search-delegate]="PostfachSearchDelegate"
        [rows]="0"
        [auto-search]="true"
        [show-autofilter]="false"
        [toolbar]="false"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [lazy-loading]="true"
        [card]="false"
        (onInit)="onPostfachSearcherInit($event)"
        searcher-title="Postfach"
        [show-totalrecords]="false"
        [select-first-result]="false"
    >
    </avi-core-base-searcher>

    <div *ngIf="keinePostfachEintrage()">
        <div class="pb-3">
            <p-message severity="success" text="Das Postfach ist leer" class="w-100p"></p-message>
        </div>
    </div>

    <div *ngIf="!keineJobEintrage()" class="mt-4 mb-2"><strong>Jobs</strong></div>
    <avi-core-base-searcher
        #jobSearcher
        [contents-padding]="false"
        [hide-when-noresults]="true"
        [use-router-params]="false"
        [search-delegate]="JobSearchDelegate"
        [rows]="0"
        [auto-search]="true"
        [show-autofilter]="false"
        [toolbar]="false"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [lazy-loading]="true"
        [card]="false"
        (onInit)="onJobSearcherInit($event)"
        searcher-title="Jobs"
        [show-totalrecords]="false"
        [select-first-result]="false"
    >
    </avi-core-base-searcher>

    <div class="grid m-0 justify-content-end mt-4">

            <button
                pButton
                type="button"
                icon="pi pi-check"
                label="Schliessen"
                (click)="Close()"
                class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
            ></button>

    </div>

</div>
