import { Expose, Exclude } from 'class-transformer';

import * as moment from 'moment';

export class TimeSpanType {
    private _value: string;
    private _valueDate: Date;

    constructor(value?: string) {
        this.Value = value;
    }

    @Exclude()
    public get ValueDate(): Date { return this._valueDate; }
    public set ValueDate(value: Date ) { this._valueDate = value; this._value = value ? moment(value).format( 'HH:mm:ss') : null; }

    @Expose()
    public get Value(): string { return this._value; }
    public set Value(value: string) { this._value = value; this._valueDate = value ? moment(value, 'HH:mm:ss').toDate() : null; }
}
