import { Expose, Type } from 'class-transformer';

import { ModelMeta } from '../system/modelmeta.model';
import { TechRole } from '../system/techrole.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class SqlJobDef extends BaseModel {  
    @Expose()
    public get Beschreibung1(): string { return this.getProperty('Beschreibung1'); }
    public set Beschreibung1(value: string) { this.setProperty('Beschreibung1', value); }
    
    @Expose()
    public get Beschreibung2(): string { return this.getProperty('Beschreibung2'); }
    public set Beschreibung2(value: string) { this.setProperty('Beschreibung2', value); }
    
    @Expose()
    public get Beschreibung3(): string { return this.getProperty('Beschreibung3'); }
    public set Beschreibung3(value: string) { this.setProperty('Beschreibung3', value); }
    
    @Expose()
    public get Beschreibung4(): string { return this.getProperty('Beschreibung4'); }
    public set Beschreibung4(value: string) { this.setProperty('Beschreibung4', value); }
    
    @Expose()
    public get Beschreibung5(): string { return this.getProperty('Beschreibung5'); }
    public set Beschreibung5(value: string) { this.setProperty('Beschreibung5', value); }
    
    @Expose()
    public get Beschreibung6(): string { return this.getProperty('Beschreibung6'); }
    public set Beschreibung6(value: string) { this.setProperty('Beschreibung6', value); }
    
    @Expose()
    public get Beschreibung7(): string { return this.getProperty('Beschreibung7'); }
    public set Beschreibung7(value: string) { this.setProperty('Beschreibung7', value); }
    
    @Expose()
    public get ConnectionString(): string { return this.getProperty('ConnectionString'); }
    public set ConnectionString(value: string) { this.setProperty('ConnectionString', value); }
    
    @Expose()
    public get IDbConnectionTypeName(): string { return this.getProperty('IDbConnectionTypeName'); }
    public set IDbConnectionTypeName(value: string) { this.setProperty('IDbConnectionTypeName', value); }
    
    @Expose()
    public get DbParameterTypeName(): string { return this.getProperty('DbParameterTypeName'); }
    public set DbParameterTypeName(value: string) { this.setProperty('DbParameterTypeName', value); }
    
    @Expose()
    public get JobDisplayContext_ID(): string { return this.getModelId('JobDisplayContext_ID'); }
    public set JobDisplayContext_ID(value: string) { this.setModelId('JobDisplayContext_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get JobDisplayContext(): ModelMeta { return this.getModel('JobDisplayContext'); }
    public set JobDisplayContext(value: ModelMeta) { this.setModel('JobDisplayContext', value); }
    
    @Expose()
    public get ExecuteRole_ID(): string { return this.getModelId('ExecuteRole_ID'); }
    public set ExecuteRole_ID(value: string) { this.setModelId('ExecuteRole_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get ExecuteRole(): TechRole { return this.getModel('ExecuteRole'); }
    public set ExecuteRole(value: TechRole) { this.setModel('ExecuteRole', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AnzeigenInSuche(): ListType { return this.getProperty('AnzeigenInSuche'); }
    public set AnzeigenInSuche(value: ListType) { this.setProperty('AnzeigenInSuche', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AnzeigenInController(): ListType { return this.getProperty('AnzeigenInController'); }
    public set AnzeigenInController(value: ListType) { this.setProperty('AnzeigenInController', value); }
    
}
