import { VwbPActionConst, VwbPActionData, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { Injectable } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';
import { Router, UrlSerializer } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class VwbPInternUebergabeService {
    constructor(private apiService: AviApiService,
        private router: Router,
        private serializer: UrlSerializer
    ) { }

    StartRechtstraegerLoeschen(rechtstraegerIds: string[], bemerkung: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UEBERGABE_RT_LOESCHEN,
            Bemerkung: bemerkung
        };
        return this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/uebergabe/action', data);
    }

    StartRechtstraegerAbgeben(rechtstraegerIds: string[], bemerkung: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UEBERGABE_RT_ABGEBEN,
            Bemerkung: bemerkung
        };
        return this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/uebergabe/action', data);
    }

    StartRechtstraegerAnfordern(rechtstraegerIds: string[], ownerId: string, bemerkung: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UEBERGABE_RT_ANFORDERN,
            Bemerkung: bemerkung,
            Context: ownerId
        };
        return this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/uebergabe/action', data);
    }

    StartRechtstraegerInaktivieren(rechtstraegerIds: string[], bemerkung: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UEBERGABE_RT_INAKTIVIEREN,
            Bemerkung: bemerkung,
        };
        return this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/uebergabe/action', data);
    }

    StartRechtstraegerReaktivieren(rechtstraegerIds: string[], bemerkung: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UEBERGABE_RT_REAKTIVIEREN,
            Bemerkung: bemerkung,
        };
        return this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/uebergabe/action', data);
    }

    GetAnfragenList(filterConfig: any) {
        const status = [VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_WARTE_AUF_DOKUMENTE, VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG];
        const data: any = { ...filterConfig, status: status };
        return this.apiService.post(`v1/intern/vwbp/uebergabe/anfragen/list`, data);
    }

    GetAnfragenIdList(filterConfig: any) {
        const status = [VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_WARTE_AUF_DOKUMENTE, VwbPListDetailConst.VWBP_RT_ANFRAGESTATUS_IN_PRUEFUNG];
        const data: any = { ...filterConfig, status: status };
        return this.apiService.post(`v1/intern/vwbp/uebergabe/anfragen/ids/list`, data);
    }

    GetAnfrage(id: string) {
        return this.apiService.get(`v1/intern/vwbp/uebergabe/anfragen/${id}`);
    }

    SetAnfrageVisumBewilligen(anfrageId: string) {
        const data: any = { AnfrageId: anfrageId, VisumTypId: VwbPListDetailConst.VWBP_VISUM_TYP_UEBERGABE_ANFRAGE_BEWILLIGUNG };
        return this.apiService.post(`v1/intern/vwbp/uebergabe/visum`, data);
    }

    SetAnfrageVisumAblehnen(anfrageId: string) {
        const data: any = { AnfrageId: anfrageId, VisumTypId: VwbPListDetailConst.VWBP_VISUM_TYP_UEBERGABE_ANFRAGE_ABLEHNUNG };
        return this.apiService.post(`v1/intern/vwbp/uebergabe/visum`, data);
    }

    GetVisa(anfrageId: string, visumTypId: string) {
        return this.apiService.get(`v1/intern/vwbp/uebergabe/visum/${anfrageId}/${visumTypId}`);
    }

    SetAnfrageStatus(anfrageId: string, statusId: string) {
        const data: VwbPActionData = {
            ModelIdList: [],
            Action: VwbPActionConst.VWBP_ACTION_UEBERGABEANFRAGE_STATUS_SETZEN,
            Context: anfrageId,
            Status: statusId
        };
        return this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/uebergabe/action', data);
    }
}
