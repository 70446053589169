import { Expose, Type } from 'class-transformer';

import { ModelMeta } from '../system/modelmeta.model';
import { BaseModel } from '../shared/basemodel.model';

export class Collection extends BaseModel {  
    @Expose()
    public get KurzBez(): string { return this.getProperty('KurzBez'); }
    public set KurzBez(value: string) { this.setProperty('KurzBez', value); }
    
    @Expose()
    public get Bezeichnung(): string { return this.getProperty('Bezeichnung'); }
    public set Bezeichnung(value: string) { this.setProperty('Bezeichnung', value); }
    
    @Expose()
    public get ListName(): string { return this.getProperty('ListName'); }
    public set ListName(value: string) { this.setProperty('ListName', value); }
    
    @Expose()
    public get Beschreibung(): string { return this.getProperty('Beschreibung'); }
    public set Beschreibung(value: string) { this.setProperty('Beschreibung', value); }
    
    @Expose()
    public get System(): boolean { return this.getProperty('System'); }
    public set System(value: boolean) { this.setProperty('System', value); }
    
    @Expose()
    public get SystemVerwendung(): string { return this.getProperty('SystemVerwendung'); }
    public set SystemVerwendung(value: string) { this.setProperty('SystemVerwendung', value); }
    
    @Expose()
    public get Model_ID(): string { return this.getModelId('Model_ID'); }
    public set Model_ID(value: string) { this.setModelId('Model_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get Model(): ModelMeta { return this.getModel('Model'); }
    public set Model(value: ModelMeta) { this.setModel('Model', value); }
    
}
