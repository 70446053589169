import { Component, OnInit, ViewChild, forwardRef, Input, Output, EventEmitter, DoCheck, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';
import { ObjectUtils } from '../../utils/object-utils';

@Component({
    selector: 'avi-core-dropdown',
    templateUrl: './dropdown.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AviDropdownComponent),
        multi: true
    }],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AviDropdownComponent implements ControlValueAccessor, OnInit  {
    // dropdown1: FormControl;

    @ViewChild(Dropdown, { static: true }) private _dropdown: Dropdown;

    @Input() placeholder: string;
    @Input() showClear: boolean;
    @Input() readonly: boolean;
    @Input() required: boolean;
    @Input() tabindex: number;
    @Input() style: any;
    @Input() filter: boolean;
    @Input() resetFilterOnHide: boolean = false;
    @Input() name: string;
    @Input() disabled: boolean;
    @Input() options: any[];
    @Input() dataKey:string;
    @Input() autofocus: boolean;
    @Input() emptyFilterMessage: string = 'No results found';
    @Input() filterMatchMode = 'contains';
    @Input() virtualScroll: boolean;
    @Input() itemSize: number;
    @Input() customTemplateDelegate: (item) => any = null;
    @Input() customTemplateSelectedItemDelegate: (item) => any = null;
    @Output() onFocus: EventEmitter<any> = new EventEmitter();
    @Output() onShow: EventEmitter<any> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onBlur: EventEmitter<any> = new EventEmitter();

    private _value: any = null;
    public get value(): any {
        return this._value;
    }
    public set value(value: any) {
        if (value !== this._value) {
            this._value = value;
            if (this.onModelChange)
                this.onModelChange(value);
        }
    }

    constructor(private cdr: ChangeDetectorRef) {

    }

    focus() {
        this._dropdown.focus();
    }

    ngOnInit() {
        // this.dropdown1 = new FormControl();

        if (this._dropdown) {
            this._dropdown.searchOptionInRange = (start, end) => this.searchOptionInRange(start, end);
            this._dropdown.searchOptionWithinGroup = (index) => this.searchOptionWithinGroup(index);
            this._dropdown.search = (event) => this.search(event);
            this._dropdown.findOptionIndex = (val, opts) => this.findOptionIndex(val, opts);
        }
    }

    public applyFocus() {
        this._dropdown.applyFocus();
    }

    writeValue(obj: any): void {
        if (obj !== this._value) {
            this._value = obj;
        }
    }

    onModelChange: (val) => {};
    onModelTouch: () => {};
    registerOnChange(fn: any): void {
        this.onModelChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onModelTouch = fn;
    }

    findOptionIndex(val: any, opts: any[]): number {
        let index: number = -1;
        if (opts) {
            for (let i = 0; i < opts.length; i++) {
                if ((val == null && this._dropdown.getOptionValue(opts[i]) == null) || ObjectUtils.equals(val, this._dropdown.getOptionValue(opts[i]), this._dropdown.dataKey)) {
                    index = i;
                    break;
                }
            }
        }

        return index;
    }

    search(event) {
        if (this._dropdown.searchTimeout)
            clearTimeout(this._dropdown.searchTimeout);

        if (event.altKey || event.ctrlKey || event.shiftKey)
            return;

        const char = event.key;
        this._dropdown.previousSearchChar = this._dropdown.currentSearchChar;
        this._dropdown.currentSearchChar = char;

        this._dropdown.searchValue = this._dropdown.searchValue ? this._dropdown.searchValue + char : char;

        let newOption;
        if (this._dropdown.group) {
            let searchIndex = { groupIndex: 0, itemIndex: 0 };
            if (this.filterMatchMode === 'startsWith')
                searchIndex = this._dropdown.selectedOption ? this._dropdown.findOptionGroupIndex(this._dropdown.selectedOption.value, this._dropdown.optionsToDisplay) : { groupIndex: 0, itemIndex: 0 };
            newOption = this._dropdown.searchOptionWithinGroup(searchIndex);
        } else {
            let searchIndex = 0;
            if (this.filterMatchMode === 'startsWith')
                searchIndex = this._dropdown.selectedOption ? this._dropdown.findOptionIndex(this._dropdown.selectedOption.value, this._dropdown.optionsToDisplay) : 0;
            newOption = this._dropdown.searchOption(searchIndex);
        }

        if (newOption && !newOption.disabled && !newOption.nosearch) {
            this._dropdown.selectItem(event, newOption);
            this._dropdown.selectedOptionUpdated = true;
        }

        this._dropdown.searchTimeout = setTimeout(() => {
            this._dropdown.searchValue = null;
        }, 1000);
    }

    searchOptionInRange(start, end) {
        for (let i = start; i < end; i++) {
            const opt = this._dropdown.optionsToDisplay[i];
            if (opt.label && typeof opt.label === 'string' && this.matchStrings(opt.label.toLocaleLowerCase(this._dropdown.filterLocale), (this._dropdown.searchValue as any).toLocaleLowerCase(this._dropdown.filterLocale)) && !opt.disabled && !opt.nosearch) {
                return opt;
            }
        }

        return null;
    }

    searchOptionWithinGroup(index) {
        let option;

        if (this._dropdown.searchValue) {
            for (let i = index.groupIndex; i < this._dropdown.optionsToDisplay.length; i++) {
                for (let j = (index.groupIndex === i) ? (index.itemIndex + 1) : 0; j < this._dropdown.optionsToDisplay[i].items.length; j++) {
                    const opt = this._dropdown.optionsToDisplay[i].items[j];
                    if (this.matchStrings(opt.label.toLocaleLowerCase(this._dropdown.filterLocale), (this._dropdown.searchValue as any).toLocaleLowerCase(this._dropdown.filterLocale)) && !opt.disabled && !opt.nosearch) {
                        return opt;
                    }
                }
            }

            if (!option) {
                for (let i = 0; i <= index.groupIndex; i++) {
                    for (let j = 0; j < ((index.groupIndex === i) ? index.itemIndex : this._dropdown.optionsToDisplay[i].items.length); j++) {
                        const opt = this._dropdown.optionsToDisplay[i].items[j];
                        if (this.matchStrings(opt.label.toLocaleLowerCase(this._dropdown.filterLocale), (this._dropdown.searchValue as any).toLocaleLowerCase(this._dropdown.filterLocale)) && !opt.disabled && !opt.nosearch) {
                            return opt;
                        }
                    }
                }
            }
        }

        return null;
    }

    matchStrings(a: string, b: string): boolean {
        if (this.filterMatchMode === 'startsWith') {
            return a.startsWith(b);
        } else
            return a.includes(b);
    }
}
