import { Expose, Type } from 'class-transformer';

import { BaseModelState } from '../shared/basemodel.model';

export class ListDetail extends BaseModelState {  
    @Expose()
    public get Id(): string { return this.getModelId('Id'); }
    public set Id(value: string) { this.setModelId('Id', value); }
    
    @Expose()
    public get Aktiv(): number { return this.getProperty('Aktiv'); }
    public set Aktiv(value: number) { this.setProperty('Aktiv', value); }
    
    @Expose()
    public get Zustand(): number { return this.getProperty('Zustand'); }
    public set Zustand(value: number) { this.setProperty('Zustand', value); }
    
    @Expose()
    public get System(): number { return this.getProperty('System'); }
    public set System(value: number) { this.setProperty('System', value); }
    
    @Expose()
    @Type(() => Date)
    public get ErstellDatum(): Date { return this.getProperty('ErstellDatum'); }
    public set ErstellDatum(value: Date) { this.setProperty('ErstellDatum', value); }

    @Expose()
    @Type(() => Date)
    public get MutDatum(): Date { return this.getProperty('MutDatum'); }
    public set MutDatum(value: Date) { this.setProperty('MutDatum', value); }
    
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
}
