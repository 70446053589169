<avi-core-page title="Datenimport" [card]="true" [collapsible]="false" [expanded]="true" [contents-padding]="true"  [no-box-shadow]="true">
    <h3>Benutzer</h3>
    <p-fileUpload
        #inputUser
        name="demo[]"
        [customUpload]="true"
        (uploadHandler)="onUploadUser($event)"
        accept=".csv"
        [maxFileSize]="50000000"
    >
        <!-- <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
            </ul>
        </ng-template> -->
    </p-fileUpload>

    <h3 class="pt-3">CRB Daten</h3>
    <p-fileUpload
        #inputCRB
        name="demo[]"
        [customUpload]="true"
        (uploadHandler)="onUploadCRB($event)"
        accept=".csv"
        [maxFileSize]="50000000"
    >
        <!-- <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
            </ul>
        </ng-template> -->
    </p-fileUpload>

    <h3 class="pt-3">Rechtsträger</h3>
    <p-fileUpload
        #inputRT
        name="demo[]"
        [customUpload]="true"
        (uploadHandler)="onUploadRT($event)"
        accept=".csv"
        [maxFileSize]="50000000"
    >
        <!-- <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
            </ul>
        </ng-template> -->
    </p-fileUpload>

    <h3 class="pt-3">Formulare</h3>
    <p-fileUpload
        #inputFormular
        name="demo[]"
        [customUpload]="true"
        (uploadHandler)="onUploadFormular($event)"
        accept=".csv"
        [maxFileSize]="50000000"
    >
        <!-- <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
            </ul>
        </ng-template> -->
    </p-fileUpload>
</avi-core-page>
