import { Injectable, Inject, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route, Router, CanActivate, CanActivateChild, CanLoad, CanDeactivate } from '@angular/router';
import { AviCommonService } from './common.service';
import { AviAuthService } from './auth.service';


@Injectable({ providedIn: 'root' })
export class AviCanActivateWhenLoggedInService implements CanActivate {

    constructor(
        private authService: AviAuthService,
        private commonService: AviCommonService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // this.commonService.logDebug("canact state",state, this.userService);
        let canActivate = this.authService.IsLoggedIn;

        canActivate = canActivate && (!route.data['role'] || this.authService.currentUserHasRole(route.data['role']));

        if (!canActivate) {

            this.commonService.logWarn('canActivate is false in CanActivateWhenLoggedInService for ' + state.url, state, this.authService.CurrentUser);
            console.log(this.commonService.loginPath, state.url);
            this.router.navigate([this.commonService.loginPath, { source: state.url }]);
        }

        return canActivate;
    }
}


@Injectable({ providedIn: 'root' })
export class AviCanActivateWhenIsAdminService implements CanActivate {

    constructor(
        private authService: AviAuthService,
        private commonService: AviCommonService,
    ) { }

    canActivate() {
        const c = this.authService.CurrentUser;
        const canActivate = c && c.IsAdmin;
        if (!canActivate)
            this.commonService.logWarn('canActivate is false in CanActivateWhenIsAdminService');
        return canActivate;
    }
}

export interface AviCanComponentDeactivate {
    canDeactivate: (
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({ providedIn: 'root' })
export class AviCanDeactivateService implements CanDeactivate<AviCanComponentDeactivate> {

    canDeactivate(
        component: AviCanComponentDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ) {
        return component.canDeactivate ? component.canDeactivate(currentRoute, currentState, nextState) : true;
    }
}
