import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AviAuthService } from '../../services/auth.service';
import { AviCurrentUser } from '../../services/auth.service';

@Component({
    selector: 'avi-core-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class AviProfileComponent implements OnInit {

    public totpImageUrl;

    constructor(public authService: AviAuthService, private sanitizer: DomSanitizer) { }

    public get CurrentUser(): AviCurrentUser {
        return this.authService.CurrentUser;
    }

    ngOnInit() {
        this.authService.getTotpProvImage().then(data => {
            if (data && data.image)
                this.totpImageUrl = 'data:image/jpg;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(data.image) as any).changingThisBreaksApplicationSecurity;
        });
    }
}
