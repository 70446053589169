import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviDokumentAblageListDetailConst, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocPropContainer } from '@avi-x/avi-dto/documatrix/docpropcontainer.model';
import { MenuItem } from 'primeng/api';

const nameof = (nameFunction: ((obj: DocPropContainer) => any)) => typed_nameof<DocPropContainer>(nameFunction);

@Component({
    selector: 'avi-docpropcontainer-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [taskmenu-position]="TaskMenuPosition"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocPropContainerFormComponent extends AviAbstractModelFormComponent<DocPropContainer> {  
    ClassName = 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINER';
    BaseUrl = 'documatrixdef/container';
    ClassType = DocPropContainer;
	FormType = AviDocPropContainerFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFDocuMatrixTemplate';
    }
    
    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.ContainerTyp)) {
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Template), this.Model.ContainerTyp.Id === AviDokumentAblageListDetailConst.CAF_DOCPROPCONTAINER_TYP_TEMPLATE);
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Template), this.Model.ContainerTyp.Id === AviDokumentAblageListDetailConst.CAF_DOCPROPCONTAINER_TYP_TEMPLATE);    
        }
    }

    public async afterInitForm(): Promise<void> {
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Template), this.Model.ContainerTyp.Id === AviDokumentAblageListDetailConst.CAF_DOCPROPCONTAINER_TYP_TEMPLATE);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Template), this.Model.ContainerTyp.Id === AviDokumentAblageListDetailConst.CAF_DOCPROPCONTAINER_TYP_TEMPLATE);
    }
	
    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINER.BEZEICHNUNG', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.NodeName), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINER.NODENAME', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ContainerTyp), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINER.TYP', 'caf_docpropcontainer_typ', true, true, this.ReadOnly).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Template), 'CORE.COMMON.MODEL.CAFDOCPROPCONTAINER.TEMPLATE', false).setCustomStyleDelegate(() => { return { 'max-height': '50vh' } }).setMDSizeFull());
    }
    
    async getTaskMenuItems(): Promise<MenuItem[]> {
        var res = await super.getTaskMenuItems();

        if (this.ModelId && this.EnableTasks) {
            if (this.CRUDCanEdit)
                res.push({ label: 'Workflow Definition kopieren', icon: 'pi pi-clone', command: () => this.CopyContainer() });
        }
        
        return res;
    }

    
    async CopyContainer() {
        this.commonService.confirm({
            header: 'Container kopieren',
            message: `Möchten Sie den Container kopieren?`, 
            acceptButtonStyleClass: 'p-button-primary', 
            rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
            accept: async () => {
                const modelId = await this.apiService.get(`${this.getBaseUrl()}/copy/${this.ModelId}`);
                this.sessionController.openSession('docpropcontainer-index', modelId);
            }
        }); 
    }
}
