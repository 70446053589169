import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { SysParameterSearchResultDto } from '@avi-x/avi-dto/system/sysparametersearchresultdto.model';
import { typed_nameof } from '../../utils/nameof';
import { AviAbstractModelSearcherComponent } from '../../base-searcher/abstract-model-searcher.component';
import { AviCommonService } from '../../../services/common.service';
import { AviApiService } from '../../../services/api.service';
import { AviSysParameterFormComponent } from './sysparameter-form.component';

const nameof = (nameFunction: ((obj: SysParameterSearchResultDto) => any)) => typed_nameof<SysParameterSearchResultDto>(nameFunction);

@Component({
    selector: 'avi-sysparameter-searcher',
    templateUrl: './sysparameter-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviSysParameterSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'Systemparameter';
    SettingsKey = 'settings.searcher.sysparameter';
    BaseUrl = 'sysparameter';
    FormType = AviSysParameterFormComponent;

    CanEdit: boolean = false;

    @Input('filter')
    public Filter: string = null;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('SysParameter schreiben').then(w => this.CanEdit = w);
    }

    onSearcherInit(searcher) {
	    searcher.beginInit();
        
        searcher.addTextColumn(nameof(c => c.ParamName), 'Systemparameter Name', true);
        searcher.addTextColumn(nameof(c => c.KurzBez), 'KurzBez', true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'Bezeichnung', true);
        
		searcher.endInit();
    }
    
    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = this.Filter;
        const res = await this.apiService.postModelList(SysParameterSearchResultDto, `sysparameter/search`, cfg);
        return res;
    }
}
