import { SortMeta } from "primeng/api";

export class SearcherDataSource {

    data: any[] = null;

    private _filteredData = new Array<any>();
    private _filtered = false;

    filterDelegate: (dataRow: any) => boolean;

    get filteredData(): any[] {
        if (!this.data)
            return this.data;

        if (!this.filterDelegate)
            return this.data;

        if (!this._filtered)
            this.refreshFiltering();
        
        return this._filteredData;
    }

    addRow(dataRow: any) {
        this.data.push(dataRow);
        this._filtered = false;
    }

    setData(data: any[]) {
        this.data = data;
        this.resetFiltering();
    }

    resetFiltering() {
        this._filtered = false;
        this._filteredData = [];
    }

    sortData(sortMeta?: SortMeta[]) {
        if (!sortMeta)
            return;

        let multiLevelSorter = new MultiLevelSorter(sortMeta);

        this.data = this.data.sort(multiLevelSorter.compare.bind(multiLevelSorter));
    }

    private refreshFiltering() {
        if (!this.filterDelegate)
            return;

        this._filtered = true;

        this._filteredData.splice(0);

        this.data.forEach((dataRow) => {
            if (this.filterDelegate(dataRow))
                this._filteredData.push(dataRow);
        })
    }
}

class MultiLevelSorter {
    constructor(private sortMeta: SortMeta[]) {

    }

    compare(a: any, b: any): number {
        for (let i = 0; i < this.sortMeta.length; i++) {
            let sort = this.sortMeta[i];

            let aValue = a[sort.field];
            let bValue = b[sort.field];

            if (typeof aValue === "string")
                aValue = (aValue as string).toLocaleLowerCase();

            if (typeof bValue === "string")
                bValue = (bValue as string).toLocaleLowerCase();

            if (aValue != bValue) {
                if (aValue > bValue)
                    return 1 * sort.order;
                return -1 * sort.order;
            }
        }

        return 0;
    }
}