import { ListType } from '../shared';
import { IDTO } from '../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class EmailDefSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

	@Expose()
	@Type(() => ListType)
	public Typ: ListType;

	@Expose()
	public Bezeichnung: string;

    @Expose()
	public EmailSubject1: string;
}
