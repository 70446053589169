import { Injectable } from '@angular/core';
import { AviApiService, AviCommonService, AviListDetailConst, AviSessionControllerService } from '@avi-x/avi-core';
import { BusinesscaseDataDetailFormularDto } from '@avi-x/avi-dto/businesscase/businesscasedatadetailformulardto.model';
import { BusinesscaseDataDetailDto, BusinesscaseDataDto, BusinesscaseDataTypeDto } from '@avi-x/avi-dto/businesscase/businesscasedatatypedto.model';
import { AviCustomPropCollectionFormComponent } from '../../formular/components/custompropcollection-form.component';
import { Businesscase } from '@avi-x/avi-dto/businesscase/businesscase.model';
import { BusinesscaseSummaryDto } from '@avi-x/avi-dto/businesscase/businesscasesummarydto.model';
import { DialogService } from 'primeng/dynamicdialog';
import { AviDokumentDashboardComponent } from '../../dokument/components/dokument-dashboard/dokument-dashboard.component';
import { BusinesscaseDataDetailDocumentDto } from '@avi-x/avi-dto/businesscase/businesscasedatadetaildocumentdto.model';
import { AviDokumentService } from '../../dokument/services/dokument.service';
import { GenericRef } from '@avi-x/avi-dto/shared';
import { AviBusinesscaseFormComponent } from '../components/businesscase-form.component';
import { StatusWechselRequestInfo } from '@avi-x/avi-dto/workflow/statuswechselrequestinfo.model';
import { AviBusinesscaseActionFormComponent } from '../components/businesscase-action-form.component';
import { BusinesscaseActionDto } from '@avi-x/avi-dto/businesscase/businesscaseactiondto.model';
import { MessageContainer } from '@avi-x/avi-dto/system/messagecontainer.model';
import { BusinesscaseDataDetailFormularChangesResultDto, BusinesscaseDataDetailKorrigierenDto } from '@avi-x/avi-dto/businesscase/businesscasedatadetailkorrigierendto.model';
import { AviBusinesscaseDataDetailKorrigierenFormComponent } from '../components/businesscase-formular-korrigieren-form.component';
import { AviDocuMatrixService } from '../../documatrix/services/documatrix.service';
import { BusinesscaseStatusWechselRequestDto } from '@avi-x/avi-dto/businesscase/businesscasestatuswechselrequestdto.model';

import * as moment from 'moment-timezone';
import { Router } from '@angular/router';
import { BusinesscaseDefSearchResponseDto } from '@avi-x/avi-dto/businesscase/businesscasedefsearchresponsedto.model';

type tCustomFormFunc = (params: CustomFormParams) => Promise<CustomFormResult>;

export interface CustomFormParams {
    FormDefId: string;
    Model: string;
    Title: string;
    Mode: 'show' | 'edit' | 'correct';
    ContextId?: string;
    ContextType?: string;
    MetaData?: any;
}

export interface CustomFormResult {
    Model: string;
    Draft: boolean;
}

@Injectable({ providedIn: 'root' })
export class AviBusinesscaseService {
    private static readonly s_CUSTOMPROPCOLL_ID = '50E7292A-1FAA-48E7-887B-798A49887FBD';

    private isInternDelegate: () => boolean = null;
    private customFormDict: { [formId: string] : tCustomFormFunc; } = {};

    private _UseRouting: boolean = false;
    public set UseRouting(val: boolean) { this._UseRouting = val; }
    public get UseRouting() { return this._UseRouting; }

    constructor(public apiService: AviApiService,
        public commonService: AviCommonService,
        public dialogService: DialogService,
        public dokumentService: AviDokumentService,
        public docuMatrixService: AviDocuMatrixService,
        public router: Router,
        public sessionController: AviSessionControllerService) {

        this.registerCustomFormFunction(AviBusinesscaseService.s_CUSTOMPROPCOLL_ID, this.openFormularAttributSammlungDialog);
    }

    public registerIsInternDelegate(func: () => boolean) {
        this.isInternDelegate = func;
    }

    public async loadBusinesscase(id: string): Promise<Businesscase> {
        return await this.apiService.getModelById(Businesscase, 'businesscase', id);
    }

    public async loadBusinesscaseSummary(id: string): Promise<BusinesscaseSummaryDto> {
        return await this.apiService.getModelById(BusinesscaseSummaryDto, 'businesscase/summary', id);
    }

    public isIntern() {
        if (this.isInternDelegate != null)
            return this.isInternDelegate();

        return true;
    }

    public isExtern() {
        return !this.isIntern();
    }

    public openBusinesscase(id: string) {
        if (this.UseRouting)
            this.router.navigate([`/businesscase/index/${id}`]);
        else
            this.sessionController.openSession('businesscase-index', id);
    }

    public async getBusinesscaseDefList(modelId: string, businesscaseDefId: string, context1Type: string = null, context2Type: string = null): Promise<BusinesscaseDefSearchResponseDto[]> {
        const cfg: any = { ModelId: modelId, BusinesscaseDefId: businesscaseDefId, Context1Type: context1Type, Context2Type: context2Type };
        return await this.apiService.postModelList(BusinesscaseDefSearchResponseDto, `businesscase/businesscasedef/auswahl`, cfg);
    }

    public async createBusinesscase(parentId: string = null, bcdefId: string = null, context1: string = null, context2: string = null): Promise<string> {
        const params = { 'ParentId': parentId, 'BusinesscaseDefId': bcdefId, 'Context1': context1, 'Context2': context2 };
        const model = await this.commonService.openFormDialog(AviBusinesscaseFormComponent, 'CORE.COMMON.MODEL.CAFBUSINESSCASE.CAFBUSINESSCASE_NEW', '0', null, params, true);

        // Formulare direkt bearbeiten
        if (model instanceof Businesscase) {
            const businesscase = await this.loadBusinesscaseSummary(model.Id);
            if (businesscase.EditFormOnCreate.Id === AviListDetailConst.JA) {
                const data = await this.apiService.getModelList(BusinesscaseDataTypeDto, `businesscase/${businesscase.Id}/data`);
                if (data) {
                    const formData = data.filter(w => w.DataType.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_TYP_FORM_ERSTELLEN)[0];
                    if (formData) {
                        for (let status of formData.Status.filter(w => !w.Readonly)) {
                            for (let form of status.Data)
                                await this.openFormularDialog(businesscase, form, null, 'edit');
                        }
                    }
                }
            }

            return model.Id;
        }

        return null;
    }

    public async openChangeAssigneeDialog(businesscase: Businesscase): Promise<boolean> {
        if (this.isIntern()) {
            const dto = new BusinesscaseActionDto();
            dto.Businesscase = businesscase;
            dto.CanChangeAssignee = true;
            dto.BemerkungZwingend = false;

            const res = await this.openBusinesscaseActionDialog('Zuständigkeit ändern', dto);
            if (res) {
                try {
                await this.apiService.post(`businesscase/assignee`, { BusinesscaseId: businesscase.Id, Bemerkung: dto.Bemerkung, AssignedTo_ID: dto.AssignedTo_ID, BemerkungIntern: dto.BemerkungIntern });
                } catch (err) {
                    this.commonService.notificateError(err);
                    return false;
                }
            }

            return res != null;
        }
    }

    public async openChangeApplicantDialog(businesscase: Businesscase): Promise<boolean> {
        if (!this.isIntern()) {
            const dto = new BusinesscaseActionDto();
            dto.Businesscase = businesscase;
            dto.CanChangeApplicant = true;
            dto.BemerkungZwingend = false;

            const res = await this.openBusinesscaseActionDialog('Antragsteller ändern', dto);
            if (res) {
                try {
                    await this.apiService.post(`businesscase/applicant`, { BusinesscaseId: businesscase.Id, Bemerkung: dto.Bemerkung, Applicant_ID: dto.Applicant_ID, BemerkungIntern: dto.BemerkungIntern });
                } catch (err) {
                    this.commonService.notificateError(err);
                    return false;
                }
            }

            return res != null;
        }
    }

    public async openStatusWechselDialog(businesscase: Businesscase, task: StatusWechselRequestInfo): Promise<boolean> {
        const msgCont = await this.apiService.postModel(MessageContainer, 'businesscase/task/validate', { Id: task.Id });
        if (msgCont?.HasErrors) {
            this.commonService.notificateError(msgCont.Messages.map(w => w.Message).join('\n'));
            return false;
        }

        if (task.BemerkungEinblenden.Id === AviListDetailConst.NEIN && task.FristSetzen.Id === AviListDetailConst.NEIN && task.FristBearbeitbar.Id === AviListDetailConst.NEIN) {
            try {
                await this.apiService.post('businesscase/task/perform', { Id: task.Id, Bemerkung: null, AssignedTo_ID: null });
            } catch (err) {
                this.commonService.notificateError(err);
                return false;
            }
            return true;
        } else {
            const dto = new BusinesscaseActionDto();
            dto.Businesscase = businesscase;
            dto.CanChangeAssignee = this.isIntern() && businesscase.Verantwortlichkeit.Id === AviListDetailConst.CAF_STATUSDEF_VERANTWORTLICHKEIT_SACHBEARBEITER && task.Verantwortlichkeit.Id === AviListDetailConst.CAF_STATUSDEF_VERANTWORTLICHKEIT_SACHBEARBEITER;
            dto.CanChangeApplicant = this.isExtern() && businesscase.Verantwortlichkeit.Id === AviListDetailConst.CAF_STATUSDEF_VERANTWORTLICHKEIT_ANTRAGSTELLER && task.Verantwortlichkeit.Id === AviListDetailConst.CAF_STATUSDEF_VERANTWORTLICHKEIT_ANTRAGSTELLER;
            if (dto.CanChangeAssignee && task.DefaultUser_ID)
                dto.AssignedTo_ID = task.DefaultUser_ID;
            dto.BemerkungZwingend = task.BemerkungZwingend.Id === AviListDetailConst.JA;
            dto.FristEinblenden = this.isIntern() && (task.FristSetzen.Id === AviListDetailConst.JA || task.FristBearbeitbar.Id === AviListDetailConst.JA);
            dto.FristBearbeitbar = this.isIntern() && task.FristBearbeitbar.Id === AviListDetailConst.JA;
            dto.Frist = task.FristSetzen.Id === AviListDetailConst.JA ? moment().add(task.FristTage, 'days').toDate() : businesscase.Frist;
            dto.KulanzFrist = task.FristSetzen.Id === AviListDetailConst.JA ? task.KulanzFristTage : businesscase.KulanzFrist;

            const res = await this.openBusinesscaseActionDialog(task.Bezeichnung, dto);
            if (res) {
                try {
                    const request = new BusinesscaseStatusWechselRequestDto();
                    request.Id = task.Id;
                    request.Bemerkung = dto.Bemerkung;
                    request.BemerkungIntern = dto.BemerkungIntern;
                    request.AssignedTo_ID = dto.AssignedTo_ID;
                    request.Applicant_ID = dto.Applicant_ID;
                    request.Frist = dto.Frist;
                    request.KulanzFrist = dto.KulanzFrist;
                    await this.apiService.post('businesscase/task/perform', request);
                } catch (err) {
                    this.commonService.notificateError(err);
                    return false;
                }
            }

            return res != null;
        }
    }

    private async openBusinesscaseActionDialog(header: string, actionDto: BusinesscaseActionDto) {
        const ref = this.dialogService.open(AviBusinesscaseActionFormComponent, {
            header: header,
            closable: false,
            width: '80%',
            baseZIndex: 10000,
            styleClass: 'max-height-dialog',
            data: {
                model: actionDto,
                formType: AviBusinesscaseActionFormComponent
            }
        });

        return await this.commonService.waitForDialogClose(ref);
    }

    // *** DOKUMENTE ***
    public async addDocumentLink(businesscase: BusinesscaseSummaryDto, data: BusinesscaseDataDto): Promise<boolean> {
        const ctx = this.getContext(businesscase, data);
        const ref = this.dialogService.open(AviDokumentDashboardComponent, {
            header: 'Dokument verknüpfen',
            closable: true,
            width: '95%',
            height: '98%',
            baseZIndex: 10,
            styleClass: 'max-height-dialog',
            data: {
                ContextId: ctx?.ModelID,
                ContextType: ctx?.ModelType,
                formType: AviDokumentDashboardComponent,
                showInternal: true,
                internal: data.Internal || this.isIntern(),
                internalReadonly: data.AlwaysInternal
            }
        });

        const res = await this.commonService.waitForDialogClose(ref);
        if (res && res.Document) {
            try {
                const detail = this.createBusinessDataDetailDocumentDto(data.Id, res.Document.ArchivDocumentID, res.Document.DocArchivDef_ID, res.Document.Name, res.Internal);
                await this.apiService.post('businesscase/data/linkdocument', detail);
                return true;
            } catch (err) {
                this.commonService.notificateError(err);
            }
        }

        return false;
    }

    public async addDocument(businesscase: BusinesscaseSummaryDto, data: BusinesscaseDataDto, file: File) {

        if (file) {
            const filename = file.name;
            const detail = this.createBusinessDataDetailDocumentDto(data.Id, null, null, filename, data.Internal);
            await this.apiService.postFileWithData('businesscase/data/uploaddocument', file, detail);

            return true;
        }

        return false;
    }

    private createBusinessDataDetailDocumentDto(dataId: string, docId: string, archivId: string, titel: string, isInternal: boolean): BusinesscaseDataDetailDocumentDto {
        const detail = new BusinesscaseDataDetailDocumentDto;
        detail.vonBusinesscaseData_ID = dataId;
        detail.DocId = docId;
        detail.DocArchiv = archivId;
        detail.Titel = titel;
        detail.Internal = isInternal;
        return detail;
    }

    public removeDocumentLink(doc: BusinesscaseDataDetailDto, onSuccess: Function) {
        this.apiService.deleteObject('businesscase/data/detail', doc.Id, () => onSuccess());
    }

    // *** FORMULARE ***
    public registerCustomFormFunction(formId: string, func: tCustomFormFunc) {
        this.customFormDict[formId] = func;
    }

    public getCustomFormFunction(formId: string): tCustomFormFunc {
        return this.customFormDict[formId];
    }

    public async showFormularBemerkungen(detail: BusinesscaseDataDetailDto, readonly: boolean) {
        const dto = new BusinesscaseActionDto();
        dto.CanChangeAssignee = false;
        dto.BemerkungZwingend = false;
        dto.Bemerkung = detail.Bemerkung;
        dto.BemerkungZwingend = true;
        dto.Readonly = readonly;
        dto.BemerkungInternEinblenden = false;

        const res = await this.openBusinesscaseActionDialog(readonly ? 'Bemerkung' : 'Bemerkung bearbeiten', dto);
        if (res) {
            try {
            await this.apiService.post(`businesscase/data/detail/bemerkung`, { Id: detail.Id, Bemerkung: dto.Bemerkung });
            } catch (err) {
                this.commonService.notificateError(err);
                return false;
            }
        }

        return res != null && !readonly;
    }

    public async openFormularDialog(businesscase: BusinesscaseSummaryDto, data: BusinesscaseDataDto, version: number, mode: 'show' | 'edit' | 'correct'): Promise<boolean> {
        let title = data.Bezeichnung;

        const formular = await this.apiService.getModel(BusinesscaseDataDetailFormularDto, `businesscase/data/formular/${data.Id}/${version == null ? 'latest': version}`);
        if (formular?.Titel != data.Bezeichnung)
            title = `${title} ${formular?.Titel}`;

        if (mode === 'edit')
            title = `${title} ausfüllen`;
        else if (mode === 'correct')
            title = `${title} korrigieren`;

        if (mode !== 'show') {
            try 
            {
                await this.apiService.get(`businesscase/data/formular/${data.Id}/canedit`);
            } catch (err) {
                this.commonService.notificateError(err);
                return false;
            }
        }

        const params: CustomFormParams = {
            FormDefId: data.FormDef_ID ?? data.CustomFormId,
            Model: formular?.FormData,
            Title : title,
            Mode: mode,
            ContextId: this.getContext(businesscase, data)?.ModelID,
            ContextType: this.getContext(businesscase, data)?.ModelType
        };
        const formDel = this.getCustomFormFunction(data.FormDef_ID ? AviBusinesscaseService.s_CUSTOMPROPCOLL_ID: data.CustomFormId);
        if (formDel == null)
            this.commonService.notificateError(`Es wurde kein Handler für Custom Formular ${data.CustomFormId} gefunden!!!`);
        else {
            const res = await formDel(params);

            if (res){
                 // todo(msc): @avr: darf ich das soumbauen, dass res.Model string | object ist? Dann kann ich hier serialisieren
                return await this.saveNewFormularData(data.Id, title, res.Model, res.Draft, formular?.Internal, data.Details?.length, formular?.AlwaysInternal ?? data.AlwaysInternal, data.ShowBemerkung, mode === 'correct');
            }
        }

        return false;
    }

    private async saveNewFormularData(dataId: string, title: string, newFormularData: string, draft: boolean, internal: boolean, numVersionen: number, alwaysInternal: boolean, showBemerkung: boolean, correction: boolean = true): Promise<boolean> {
        try {
            if (this.isIntern() && showBemerkung === true) {
                const formChanges = await this.apiService.postModel(BusinesscaseDataDetailFormularChangesResultDto, `businesscase/data/formular/changes`, { vonBusinesscaseData_ID: dataId, FormData: newFormularData });
                const model: BusinesscaseDataDetailKorrigierenDto = new BusinesscaseDataDetailKorrigierenDto();
                model.Bemerkung = formChanges.Bemerkung;
                model.FormChanges = formChanges.FormChanges;
                model.IsCorrection = correction;
                model.Internal = (internal || alwaysInternal) ?? true;
                model.AlwaysInternal = alwaysInternal;
                model.NumVersions = numVersionen;
                model.SelectedVersion = 1;
                model.FormData = newFormularData;
                model.BusinessDataId = dataId;

                const ref = this.dialogService.open(AviBusinesscaseDataDetailKorrigierenFormComponent, {
                    header: title,
                    closable: false,
                    width: '95%',
                    baseZIndex: 10000,
                    styleClass: 'max-height-dialog',
                    data: {
                        model: model,
                        Id: '0',
                        formType: AviBusinesscaseDataDetailKorrigierenFormComponent
                    }
                });

                const res = await this.commonService.waitForDialogClose(ref);
                if (res) {
                    const detail = new BusinesscaseDataDetailFormularDto;
                    detail.vonBusinesscaseData_ID = dataId;
                    detail.FormData = newFormularData;
                    detail.Draft = draft;
                    detail.Bemerkung = model.Bemerkung;
                    detail.Internal = model.Internal;
                    await this.apiService.post('businesscase/data/formular', detail);
                    this.commonService.notificateSuccess('Gespeichert');
                    return true;
                }
            } else {
                const detail = new BusinesscaseDataDetailFormularDto;
                detail.vonBusinesscaseData_ID = dataId;
                detail.FormData = newFormularData;
                detail.Draft = draft;
                detail.Internal = (internal || alwaysInternal) ?? this.isIntern();
                await this.apiService.post('businesscase/data/formular', detail);
                this.commonService.notificateSuccess('Gespeichert');
                return true;
            }
        } catch (err) {
            this.commonService.notificateError(err);
        }

        return false;
    }


    public async openDocumentDialog(businesscase: BusinesscaseSummaryDto, data: BusinesscaseDataDto, mode: 'show' | 'edit'): Promise<boolean> {
        let title = data.Bezeichnung;
        if (mode === 'edit')
            title = `${title} bearbeiten`;

        if (mode === 'edit') {

            let editor;
            try {
                this.commonService.showGlobalLoader();
                editor = await this.apiService.get(`businesscase/data/document/${data.Id}/editor`);
            } finally {
                this.commonService.hideGlobalLoader();
            }

            if (editor)
                this.docuMatrixService.editDraft(editor);
        } else {
            let blob;
            try {
                this.commonService.showGlobalLoader();
                blob = await this.apiService.getBlob(`businesscase/data/document/${data.Id}/pdf`);
            } finally {
                this.commonService.hideGlobalLoader();
            }
            await this.dokumentService.openBlobPreviewDialog(blob);
        }

        return true;
    }

    private getContext(businesscase: BusinesscaseSummaryDto, data: BusinesscaseDataDto): GenericRef {
        if (data.Context.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_CONTEXT_BUSINESSCASE) return new GenericRef(businesscase.Id, 'aviita.CAF.common.modul.businesscase.model.CAFBusinesscase');
        if (data.Context.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_CONTEXT_CONTEXT1) return businesscase.Context1;
        if (data.Context.Id === AviListDetailConst.CAF_BUSINESSCASEDATA_CONTEXT_CONTEXT2) return businesscase.Context2;
        return null;
    }

    private openFormularAttributSammlungDialog = async (params: CustomFormParams): Promise<CustomFormResult> => {
        const ref = this.dialogService.open(AviCustomPropCollectionFormComponent, {
            header: params.Title,
            closable: false,
            width: '95%',
            baseZIndex: 10000,
            styleClass: 'max-height-dialog',
            data: {
                Params: {
                    CollectionDefId: params.FormDefId,
                    FormularJSON: params.Model,
                    Mode: 'json',
                    ButtonSaveDraft: params.Mode === 'edit' || params.Mode === 'correct',
                },
                readonly: params.Mode === 'show',
                Id: params.Model == null ? '0': this.commonService.GuidEmpty,
                ContextId: params.ContextId,
                baseUrlPrefix: 'businesscase',
                formType: AviCustomPropCollectionFormComponent
            }
        });

        return await this.commonService.waitForDialogClose(ref);
    }
}
