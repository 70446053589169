import { QueryDef } from './querydef.model';
import { SqlQueryColumn } from './sqlquerycolumn.model';
import { SqlQueryParameterVM } from './sqlqueryparameter-vm.model';
import { SqlQueryTableStyleVM } from './sqlquerytablestyle-vm.model';

export class QueryDefVM extends QueryDef {
    public SqlParameterList: SqlQueryParameterVM[] = [];
    public SqlColumnList: SqlQueryColumn[] = [];
    public TableStyleList: SqlQueryTableStyleVM[] = [];
}
