<div class="m-2">
    <div *ngIf="Adresse">
        <div class="valign-center"><strong>Adresse:</strong><a *ngIf="MapsUrl != null" (click)="MapsVisible = !MapsVisible" class="ml-1"><i class="material-icons" style="color:#666;">map</i></a></div>
        <div>{{Adresse.Strasse}} {{Adresse.HausNr}}</div>
        <div>{{Adresse.PLZ}} {{Adresse.Ort}}</div>
        <div>{{Adresse.Land.Bezeichnung1}}</div>

        <div *ngIf="MapsVisible" class="ui-g-12">
            <iframe  width="100%" height="350" frameborder="0" style="border:0;width:100%;" [src]="MapsUrl" allowfullscreen></iframe>
        </div>
    </div>

    <div *ngIf="Kommunikationen && Kommunikationen.length > 0">
        <br>
        <div><strong>Kommunikationsmittel:</strong></div>
        <table class="kommTable">
            <tbody>
                <tr *ngFor="let item of Kommunikationen">
                    <td class="fitColumn">{{item.KommArt.Bezeichnung}} ({{item.KommTyp.Bezeichnung}})</td>
                    <td class="fitColumn"><div class="valign-center">{{item.Eingabe}}
                        <a *ngIf="isHomepage(item)"  (click)="openHomepage(item.Eingabe)" class="ml-4"><i class="material-icons" style="color:#666;">home</i></a>
                        <a *ngIf="isTelefonnummer(item)"  (click)="click2Call(item.Eingabe)" class="ml-4"><i class="material-icons" style="color:#666;">call</i></a>
                        <a *ngIf="isEmail(item)" (click)="click2Email(item.Eingabe)" class="ml-4"><i class="material-icons" style="color:#666;">email</i></a>
                    </div></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
