<avi-core-base-searcher
    #searcher
    [search-delegate]="SearchDelegate"
    settingskey="settings.searcher.bankzr"
    [use-router-params]="false"
    [field-names-gueltigab]="['ZRGueltigAb']"
    [field-names-gueltigbis]="['ZRGueltigBis']"
    [default-sort-data]="[{ field: 'ZRGueltigAb', order: 1 }]"
    [rows]="0"
    [auto-search]="true"
    [show-autofilter]="false"
    [toolbar]="true"
    [toolbar-show-searchinput]="false"
    [toolbar-show-advanced]="false"
    [card]="false"
    (onInit)="onInit($event)"
    (onSelect)="onSelect($event)"
    searcher-icon="account_balance"
    searcher-title="Zeichnungsrechte"
    [show-totalrecords]="false"
    [select-first-result]="false"
    row-group-mode="subheader"
    [row-group-header-delegate]="getRowGroupHeader"
    [group-field]="'ZRDefBez'"
>
</avi-core-base-searcher>
