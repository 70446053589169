import { DialogService } from 'primeng/dynamicdialog';
import { Injectable } from '@angular/core';
import { AviJobDialogComponent } from '../components/job-dialog/job-dialog.component';
import { first, tap } from 'rxjs/operators';
import { AviCommonService } from './common.service';
import { AviApiService } from './api.service';
import { JobParam } from '@avi-x/avi-dto/system/jobparam.model';
import { JobScheduleRequestDto } from "@avi-x/avi-dto/job/JobScheduleRequestDto";
import { FileSaverService } from 'ngx-filesaver';
import { JobStartInfoDto } from '@avi-x/avi-dto/job/startjobinfodto';
import { AviListDetailConst } from '../shared/constants/constants';
import { JobParameterDto } from '@avi-x/avi-dto/job/JobParamaterDto';
import { AviJobStartFormComponent } from '../components/joblogging/job-start-form.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AviSessionControllerService } from './session-controller.service';

export class StartJobConfig {
    defaultParameterValues = new Map<string, any>();
    customDataSources = new Map<string, any>();
    hiddenParams = new Array<string>();
    readonly = false;
    jobFinishedCallBackDelegate: () => void;
}

@Injectable({
    providedIn: 'root',
})
export class AviJobService {

    // falls benötigt in app.component bzw. app.main.component pro Kundenprojekt setzen
    public ApiPrefix: string = '';

    private _UseRouting: boolean = false;
    public set UseRouting(val: boolean) { this._UseRouting = val; }
    public get UseRouting() { return this._UseRouting; }

    private _RouterPrefix: string = null;
    public set RouterPrefix(val: string) { this._RouterPrefix = val; }
    public get RouterPrefix() { return this._RouterPrefix; }

    constructor(private apiService: AviApiService,
        public dialogService: DialogService,
        private commonService: AviCommonService,
        private fileSaverService: FileSaverService,
        private translateService: TranslateService,
        public router: Router,
        public sessionController: AviSessionControllerService) {
    }

    private getRouterBaseUrl() {
        return this.RouterPrefix ? `/${this.RouterPrefix}` : '/job';
    }

    private getApiPrefix() {
        var prefix = '';
        if (this.ApiPrefix)
        {
            prefix = this.ApiPrefix;
            if (!prefix.endsWith('/'))
                prefix = prefix + '/'
        }
        return prefix;
    }

    public async startJob(jobId: string, header: string, config?: StartJobConfig) {
        const model = await this.commonService.openFormDialog(AviJobStartFormComponent, header, jobId, null, {config: config}, !config || !config.readonly, null, '60%');
        if (model)
            await this._startJob(model, config);
    }

    private async getParamValue(param: JobParam) {
        const paramType = param.fromJobParamDef.ParamType.Id;

        if (paramType === AviListDetailConst.ATTRTYPE_TEXT)
            return param.ValueText;
        else if (paramType === AviListDetailConst.ATTRTYPE_DATE)
            return param.ValueDate;
        else if (paramType === AviListDetailConst.ATTRTYPE_DECIMAL)
            return param.ValueDec;
        else if (paramType === AviListDetailConst.ATTRTYPE_INTEGER)
            return param.ValueInt;
        else if (paramType === AviListDetailConst.ATTRTYPE_REFERENCE)
            return param.ValueRef;
        else if (paramType === AviListDetailConst.ATTRTYPE_LISTTYPE) {
            return await this.apiService.getListType(param.fromJobParamDef.ListTypeName, param.ValueList_ID);
        }
    }

    async _startJob(model: JobStartInfoDto, jobConfig: StartJobConfig) {
        let request = new JobScheduleRequestDto();
        request.JobDefId = model.JobDefId;

        for (const param of model.Params) 
            request.JobParameters.push(new JobParameterDto(param.fromJobParamDef_ID, param.fromJobParamDef.ParamType.Id, await this.getParamValue(param)));

        let jobId: string;
        if (model.JobType.Id == AviListDetailConst.JOBDEF_JOBTYPE_FILEIMPORT)
            jobId = await this.scheduleJobWithFile(request, model.File);
        else
            jobId = await this.scheduleJob(request);

        if (jobId)
            await this.OpenJobDialog(jobId, this.translateService.instant('CORE.COMMON.JOB_AUSFUEHREN_TITLE', { name: model.Title }), `${this.getApiPrefix()}job`, false);
             
        if (jobConfig?.jobFinishedCallBackDelegate)
            jobConfig.jobFinishedCallBackDelegate();
    }

    async OpenJobDialog(jobId: string, header: string, baseUrl: string, canCancel: boolean = true, successMessage: string = null, failedMessage: string = null): Promise<string> {
        const ref = this.dialogService.open(AviJobDialogComponent, {
            data: {
                jobId: jobId,
                baseUrl: baseUrl,
                canCancel: canCancel,
                successMessage: successMessage,
                failedMessage: failedMessage
            },
            header: this.commonService.translateInstant(header),
            width: '70%',
            closable: false,
            closeOnEscape: false,
            contentStyle: { 'max-height': '700px', 'overflow': 'auto' },
            baseZIndex: 10000
        });

        const res = await ref
            .onClose
            .pipe(tap((data: string) => data), first())
            .toPromise();

        ref.destroy();

        return res;
    }

    public openJobDef(id: string) {
        if (this.UseRouting)
            this.router.navigate([`${this.getRouterBaseUrl()}/jobdef-index/${id}`])
        else
            this.sessionController.openSession('jobdef-index', id);
    }

    public openJob(id: string) {
        if (this.UseRouting)
            this.router.navigate([`${this.getRouterBaseUrl()}/job-index/${id}`])
        else
            this.sessionController.openSession('job-index', id);
    }

    async scheduleJob(request: JobScheduleRequestDto): Promise<string> {
        return await this.apiService.postAsFormData(`${this.getApiPrefix()}job/web/schedule`, request);
    }

    async scheduleJobWithFile(request: JobScheduleRequestDto, file: File): Promise<string> {
        try {
            this.commonService.showGlobalLoader();

            return this.apiService.postFileWithData(`${this.getApiPrefix()}job/web/schedule`, file, request);
        }
        finally{
            this.commonService.hideGlobalLoader();
        }
    }

    async loadLastJob(jobDefId: string): Promise<JobParam[]> {
        return await this.apiService.getModelList(JobParam, `${this.getApiPrefix()}job/lastjobparams/${jobDefId}`);
    }

    async createStartJobInfo(jobDefId: string): Promise<JobStartInfoDto> {
        return await this.apiService.getModel(JobStartInfoDto, `${this.getApiPrefix()}job/createstartjob/${jobDefId}`);
    }

    async uploadAdditionalFile(jobId: string, file: File): Promise<void> {
        try {
            this.commonService.showGlobalLoader();

            await this.apiService.postFile(`${this.getApiPrefix()}job/upload/${jobId}`, file);
        }
        finally{
            this.commonService.hideGlobalLoader();
        }
    }



    async downLoadExportFile(jobId: string): Promise<void> {
        try {
            this.commonService.showGlobalLoader();

            let response = await this.apiService.getBlobWithPost(`${this.getApiPrefix()}job/download/${jobId}`, null, null, null, 'response');

            if (response.body) {
                let fileName = response.headers.get('X-AVIITA-Data');
                this.fileSaverService.save(response.body, fileName);
            }
        }
        finally{
            this.commonService.hideGlobalLoader();
        }
    }
}
