<avi-core-page icon="work" title="Unstimmigkeitsmanagement" [contents-padding]="false" [no-box-shadow]="true">
    <div [ngClass]="{ 'mat-tab-header-autohide': navLinks.length <= 1 }" class="mat-tab-secondary">
        <!-- <nav *ngIf="navLinks.length > 1" mat-tab-nav-bar mat-align-tabs="center" #tabNavbar> -->
        <nav *ngIf="navLinks.length > 1" mat-tab-nav-bar #tabNavbar>
            <a
                mat-tab-link
                *ngFor="let link of navLinks"
                [routerLink]="link.path"
                routerLinkActive
                #rla="routerLinkActive"
                [active]="rla.isActive"
                >{{ link.label }}</a
            >
        </nav>
        <div>
            <router-outlet></router-outlet>
        </div>
    </div>
</avi-core-page>
