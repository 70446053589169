<avi-core-dialog
    #emailDefFormDialog
    identifier="emailDefFormDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
>
    <vwbp-admin-emaildef-form
        #emailDefForm
        [model-id]="FormModelId"
        (onSavedSuccessful)="onSavedSuccessful($event)"
        [action-buttons]="formActionButtons"
    >
    </vwbp-admin-emaildef-form>
</avi-core-dialog>

<avi-core-dialog
    #emailConfigFormDialog
    identifier="emailConfigFormDialog"
    [showCloseIcon]="false"
    [closeOnEscape]="false"
>
    <avi-crm-emailconfig-form
        #emailConfigForm
        api-prefix="v1/intern/vwbp"
        (onSavedSuccessful)="onEmailConfigSavedSuccessful($event)"
        [action-buttons]="formActionButtonsEmailConfig"
    >
    </avi-crm-emailconfig-form>
</avi-core-dialog>

<avi-core-page
    title="Email Definitionen"
    [card]="Card"
    [no-box-shadow]="true"
    [collapsible]="false"
    [expanded]="true"
    [contents-padding]="true"
>
    <avi-core-base-searcher
        #searcher
        [search-delegate]="SearchDelegate"
        (onInit)="onSearcherInit()"
        (onError)="onSearcherError($event)"
        [use-router-params]="false"
        (onDoubleClick)="onDoubleClickSearcher($event)"
        [rows]="50"
        [auto-search]="true"
        [show-autofilter]="true"
        [toolbar]="false"
        [toolbar-show-searchinput]="false"
        [toolbar-show-advanced]="false"
        [lazy-loading]="true"
        (onSelect)="onSelectionChanged($event)"
        [card]="false"
        searcher-title="Email Definitionen"
        [show-totalrecords]="true"
        [pretoolbar-template]="toolbar"
        [select-first-result]="false"
    >
        <ng-template #toolbar>
            <div class="ui-g">
                <div class="w-100p pt-3">
                    <p-toolbar>
                        <div class="ui-toolbar-group-left flex">
                            <button
                                pButton
                                type="button"
                                label="Neu"
                                icon="pi pi-plus"
                                (click)="createEmailDef()"
                                class="p-button-success w-100p-m mr-2 mb-1"
                            ></button>

                            <button
                                pButton
                                type="button"
                                label="Ändern"
                                icon="pi pi-pencil"
                                (click)="editEmailDef()"
                                class="p-button-primary w-100p-m mr-2 mb-1"
                            ></button>

                            <button
                                pButton
                                type="button"
                                label="Löschen"
                                icon="pi pi-trash"
                                (click)="deleteEmailDef()"
                                class="p-button-danger w-100p-m mr-2 mb-1"
                            ></button>

                            <p-divider layout="vertical" ></p-divider>

                            <button
                                pButton
                                type="button"
                                label="Email Konfiguration"
                                icon="pi pi-cog"
                                (click)="editEmailConfiguration()"
                                class="p-button-secondary w-100p-m ml-2 mb-1"
                            ></button>

                        </div>
                    </p-toolbar>
                </div>
            </div>
        </ng-template>
    </avi-core-base-searcher>
</avi-core-page>
