<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

    <avi-tabView #tabView *ngIf="ModelId" width="14rem" tabview-class="tabstyle-session" orientation="vertical" [session-modul]="true">

        <avi-tabPanel title="CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.CAFSTATUSWORKFLOWDEF_1" id="statusworkflowdef-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-statusworkflowdef-form [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true"></avi-statusworkflowdef-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
	
        <avi-tabPanel title="CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.ALLESTATUSDEF" id="allestatusdef-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-statusdef-searcher [context-id]="ModelId" [enable-panel]="true"></avi-statusdef-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.ALLESTATUSWECHSELDEF" id="allestatuswechseldef-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-statuswechseldef-searcher [context-id]="ModelId" [enable-panel]="true"></avi-statuswechseldef-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.ALLESKRIPTS" id="alleskripts-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-scriptdef-searcher [context-id]="ModelId" [enable-panel]="true"></avi-scriptdef-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>
        
        <!-- <avi-tabPanel title="CORE.COMMON.MODEL.CAFSTATUSWORKFLOWDEF.ALLESQLJOBDEFS" icon="pi pi-folder-open" id="allesqljobdefs-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-sqljobdef-searcher [context-id]="ModelId">
					</avi-sqljobdef-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel> -->
    </avi-tabView>

</avi-core-page>

