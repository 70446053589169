import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ListDetailContainer } from '@avi-x/avi-dto/system/listdetailcontainer.model';

import { AviFormFieldService } from './../../../services/form-field.service';
import { AviApiService } from './../../../services/api.service';
import { AviCommonService } from './../../../services/common.service';
import { typed_nameof } from '../../utils/nameof';
import { AviChangedAttr } from '../../base-form/form-field';
import { AviAbstractModelFormComponent } from '../../base-form/abstract-model-form.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviApiErrorObject } from '../../../dto/aviapierrorobject';
import { AviSessionControllerService } from '../../../services/session-controller.service';

const nameof = (nameFunction: ((obj: ListDetailContainer) => any)) => typed_nameof<ListDetailContainer>(nameFunction);

@Component({
    selector: 'avi-listdetail-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviListDetailFormComponent extends AviAbstractModelFormComponent<ListDetailContainer> {
    ClassName = 'Listdetail';
    BaseUrl = 'listdetail/container';
    ClassType = ListDetailContainer;
    FormType = AviListDetailFormComponent;

    @Input('listname')
    public ListName: string;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'ListDetail';
    }
    
    initParams(params: { [k: string]: any; }) {
        if (params && params.ListName)
            this.ListName = params.ListName;
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1)) {
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung3 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung4 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung5 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung6 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung7 = this.Model.Bezeichnung1;
        }

        if (data.field === nameof(c => c.KurzBez1)) {
            this.Model.KurzBez2 = this.Model.KurzBez1;
            this.Model.KurzBez3 = this.Model.KurzBez1;
            this.Model.KurzBez4 = this.Model.KurzBez1;
            this.Model.KurzBez5 = this.Model.KurzBez1;
            this.Model.KurzBez6 = this.Model.KurzBez1;
            this.Model.KurzBez7 = this.Model.KurzBez1;
        }
    }
    
    public async createModel(): Promise<ListDetailContainer> {
        const res = await this.apiService.getModel(ListDetailContainer, `listdetail/container/create/${this.ListName}`);
        await this.afterCreateModel(res);
        return res;
    }
    
    async getModel(id: string): Promise<ListDetailContainer> {
        return await this.apiService.getModel(ListDetailContainer, `listdetail/container/${this.ListName}/${id}`);
    }

    saveModel(model: ListDetailContainer) {

        this.Form.clearFormMessages();

        let delegate: Promise<any> = this.apiService.put(`listdetail/container`, model);

        delegate?.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);
            
            if (this.ref)
                this.ref.close(model);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'KurzBez1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'Bezeichnung1', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'KurzBez2', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'Bezeichnung2', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'KurzBez3', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'Bezeichnung3', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'KurzBez4', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'Bezeichnung4', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez5), 'KurzBez5', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung5), 'Bezeichnung5', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez6), 'KurzBez6', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung6), 'Bezeichnung6', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez7), 'KurzBez7', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung7), 'Bezeichnung7', true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Sorter), 'Sorter', 0).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.Hide), 'Hide', '').setMDSizeHalf());
    }
}
