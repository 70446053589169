<avi-core-resource-searcher></avi-core-resource-searcher>

<!-- <avi-core-page title="Textblöcke"
    [no-box-shadow]="true"
    [style]="{'min-height':'500px'}">

    <avi-admin-textblock></avi-admin-textblock>

</avi-core-page> -->

<!-- <avi-core-dbg [m]="text"><avi-core-dbg> -->
