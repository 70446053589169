import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviApiService, AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPInternUnstimmigkeitService } from '../../services/unstimmigkeit.service';
import { VwbPInternTaskBaseComponent } from '../../../rechtstraeger/components/rechtstraeger-tasks/task-base.component';
import { DialogService } from 'primeng/dynamicdialog';
import { VwbPInternRechtstraegerService } from '../../../rechtstraeger/services/rechtstraeger.service';

export enum UnstimmigkeitRueckgaengigMode {
    // eslint-disable-next-line no-unused-vars
    INFORMIEREN, MAHNEN, BUESSEN, LOESCHVERFAHREN, EINZUGSAUFTRAG, ABSCHLIESSEN
}

@Component({
    selector: 'vwbp-intern-unstimmigkeit-rueckgaengig-task',
    template: `
     <div [ngClass]="{ 'card-contents': Dialog, 'mt-16p': Dialog }">
        <div *ngIf="Dialog" class="mat-expansion-panel-header-title">
            {{ 'VWBP.INTERN.UNSTIMMIGKEITEN.RUECKGAENGIG_MACHEN' | translate }}
        </div>
        <div class="grid form-group">
            <div class="col-12">
                <span class="p-float-label">
                    <textarea id="comment" [rows]="8" class="inputtextarea-with-min-height" pInputTextarea [(ngModel)]="Bemerkung" [autoResize]="true" name="inform"></textarea>
                    <label for="comment">{{ 'VWBP.INTERN.UNSTIMMIGKEITEN.RUECKGAENGIG_MACHEN_KOMMENTAR' | translate }}*</label>
                </span>
            </div>
            <div class="col-12 lg:col-{{ Dialog ? 6 : 12 }}">
                <button pButton type="button" [label]="GetLabel()" [disabled]="!CanExecute()" icon="pi pi-info-circle" aviThrottledClick (throttledClick)="Execute()" class="mb-8p p-button-primary"></button>
            </div>
            <div *ngIf="Dialog" class="col-12 lg:col-6">
                <button pButton type="button" [label]="'CORE.COMMON.ABBRECHEN_BUTTON' | translate" icon="pi pi-times" aviThrottledClick (throttledClick)="Cancel()" class="mb-8p p-button-secondary p-button-outlined"></button>
            </div>
        </div>
    </div>
    `
})
export class VwbPInternUnstimmigkeitRueckgaengigTaskComponent extends VwbPInternTaskBaseComponent {
    @Input('modus')
    public Modus: UnstimmigkeitRueckgaengigMode;

    constructor(public commonService: AviCommonService, public apiService: AviApiService, public rechtstraegerService: VwbPInternRechtstraegerService, public unstimmigkeitService: VwbPInternUnstimmigkeitService, public inputMaskService: InputmaskService, public dialogService: DialogService, public cdr: ChangeDetectorRef) {
        super(commonService, apiService, rechtstraegerService, inputMaskService, dialogService);
    }

    public GetLabel() {
        return `(${this.SelectedRechtstraeger.length}) ` + this.commonService.translateInstant('VWBP.INTERN.UNSTIMMIGKEITEN.RUECKGAENGIG_MACHEN');
    }

    CanExecute(): boolean {
        return this.SelectedRechtstraeger.length > 0 && !!this.Bemerkung;
    }

    async Execute() {
        this.PerformAction('VWBP.INTERN.UNSTIMMIGKEITEN.RUECKGAENGIG_MACHEN', this.GetMessage(), 'VWBP.INTERN.UNSTIMMIGKEITEN.RUECKGAENGIG_MACHEN',
            async (selectedRT) => this.RueckgaengigMachen(selectedRT)
        );
    }

    async RueckgaengigMachen(selectedRT: any[]) {
        const versionIds: string[] = selectedRT.map(w => w.Id);

        if (this.Modus === UnstimmigkeitRueckgaengigMode.MAHNEN)
            return await this.unstimmigkeitService.MahnenRueckgaengig(versionIds, this.Bemerkung);
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.BUESSEN)
            return await this.unstimmigkeitService.BuessenRueckgaengig(versionIds, this.Bemerkung);
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.EINZUGSAUFTRAG)
            return await this.unstimmigkeitService.EinzugsauftragRueckgaengig(versionIds, this.Bemerkung);
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.LOESCHVERFAHREN)
            return await this.unstimmigkeitService.LoeschverfahrenRueckgaengig(versionIds, this.Bemerkung);
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.INFORMIEREN)
            return await this.unstimmigkeitService.InformierenRueckgaengig(versionIds, this.Bemerkung);
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.ABSCHLIESSEN)
            return await this.unstimmigkeitService.AbschliessenRueckgaengig(versionIds, this.Bemerkung);
    }

    GetMessage() {
        if (this.Modus === UnstimmigkeitRueckgaengigMode.MAHNEN)
            return this.commonService.translateInstant(this.GetResourceName('VWBP.INTERN.UNSTIMMIGKEITEN.MAHNEN_RUECKGAENGIG_BESTAETIGEN'));
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.BUESSEN)
            return this.commonService.translateInstant(this.GetResourceName('VWBP.INTERN.UNSTIMMIGKEITEN.BUESSEN_RUECKGAENGIG_BESTAETIGEN'));
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.EINZUGSAUFTRAG)
            return this.commonService.translateInstant(this.GetResourceName('VWBP.INTERN.UNSTIMMIGKEITEN.EINZUGSAUFTRAG_RUECKGAENGIG_BESTAETIGEN'));
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.LOESCHVERFAHREN)
            return this.commonService.translateInstant(this.GetResourceName('VWBP.INTERN.UNSTIMMIGKEITEN.LIQUIDATIONSVERFAHREN_RUECKGAENGIG_BESTAETIGEN'));
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.INFORMIEREN)
            return this.commonService.translateInstant(this.GetResourceName('VWBP.INTERN.UNSTIMMIGKEITEN.INFORMIEREN_RUECKGAENGIG_BESTAETIGEN'));
        else if (this.Modus === UnstimmigkeitRueckgaengigMode.ABSCHLIESSEN)
            return this.commonService.translateInstant(this.GetResourceName('VWBP.INTERN.UNSTIMMIGKEITEN.ABSCHLIESSEN_RUECKGAENGIG_BESTAETIGEN'));
    }
}
