<avi-core-page [contents-padding]="false" [no-box-shadow]="true">
<!--
    <avi-tabView #tabView *ngIf="ModelId" width="14rem" tabview-class="tabstyle-session" orientation="vertical" [session-modul]="true">

        <avi-tabPanel title="CORE.COMMON.MODEL.CAFBUSINESSCASE.CAFBUSINESSCASE_1" id="businesscase-view">
            <avi-core-lazy-loader>
                <ng-template #content> -->
					<div class="p-2">
						<avi-businesscase-view [model-id]="ModelId"></avi-businesscase-view>
					</div>
                <!-- </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="Dokumente" id="dokumente">
            <avi-core-lazy-loader>
                <ng-template #content>
                    <avi-crm-dokumente [context-id]="ModelId" context-type="aviita.CAF.common.modul.businesscase.model.CAFBusinesscase" [card]="false" [use-router-params]="false"></avi-crm-dokumente>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

    </avi-tabView> -->

</avi-core-page>

