import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AviTypeUtil } from '../../shared/constants/constants';

import * as moment from 'moment-timezone';

@Pipe({
    name: 'dateFormat'
})
export class AviDateFormatPipe extends DatePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        const dateValue = moment(value);

        if (dateValue.isSame(AviTypeUtil.HighDate) || dateValue.isSame(AviTypeUtil.SqlLowDate))
            return null;

        return super.transform(value, args);
    }
}
