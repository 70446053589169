import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class TechRoleLinkSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

	@Expose()
	public AppUserGroupId: string

	@Expose()
	public AppUserGroupBezeichnung : string;

	@Expose()
	public TechRoleId : string;

	@Expose()
	public TechRoleName : string;
}
