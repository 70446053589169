import { Expose, Type } from 'class-transformer';

import { AviDocArchivDef } from '../avidoc_def/avidocarchivdef.model';
import { TechRole } from '../system/techrole.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class AviDocKategorie extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get KeywordKategorieName(): string { return this.getProperty('KeywordKategorieName'); }
    public set KeywordKategorieName(value: string) { this.setProperty('KeywordKategorieName', value); }
    
    @Expose()
    public get vonArchivDef_ID(): string { return this.getModelId('vonArchivDef_ID'); }
    public set vonArchivDef_ID(value: string) { this.setModelId('vonArchivDef_ID', value); }
 
    @Expose()
    @Type(() => AviDocArchivDef)
    public get vonArchivDef(): AviDocArchivDef { return this.getModel('vonArchivDef'); }
    public set vonArchivDef(value: AviDocArchivDef) { this.setModel('vonArchivDef', value); }
    
    @Expose()
    public get TechRoleErfassen_ID(): string { return this.getModelId('TechRoleErfassen_ID'); }
    public set TechRoleErfassen_ID(value: string) { this.setModelId('TechRoleErfassen_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleErfassen(): TechRole { return this.getModel('TechRoleErfassen'); }
    public set TechRoleErfassen(value: TechRole) { this.setModel('TechRoleErfassen', value); }
    
    @Expose()
    public get TechRoleAendern_ID(): string { return this.getModelId('TechRoleAendern_ID'); }
    public set TechRoleAendern_ID(value: string) { this.setModelId('TechRoleAendern_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleAendern(): TechRole { return this.getModel('TechRoleAendern'); }
    public set TechRoleAendern(value: TechRole) { this.setModel('TechRoleAendern', value); }
    
    @Expose()
    public get TechRoleLoeschen_ID(): string { return this.getModelId('TechRoleLoeschen_ID'); }
    public set TechRoleLoeschen_ID(value: string) { this.setModelId('TechRoleLoeschen_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleLoeschen(): TechRole { return this.getModel('TechRoleLoeschen'); }
    public set TechRoleLoeschen(value: TechRole) { this.setModel('TechRoleLoeschen', value); }
    
    @Expose()
    public get TechRoleVernichten_ID(): string { return this.getModelId('TechRoleVernichten_ID'); }
    public set TechRoleVernichten_ID(value: string) { this.setModelId('TechRoleVernichten_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleVernichten(): TechRole { return this.getModel('TechRoleVernichten'); }
    public set TechRoleVernichten(value: TechRole) { this.setModel('TechRoleVernichten', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DefaultStatus(): ListType { return this.getProperty('DefaultStatus'); }
    public set DefaultStatus(value: ListType) { this.setProperty('DefaultStatus', value); }
    
}
