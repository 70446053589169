import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { ActivityTypeSearchResponseDto } from '@avi-x/avi-dto/activity/activitytypesearchresponsedto.model';
import { AviActivityTypeFormComponent } from './activitytype-form.component';

const nameof = (nameFunction: ((obj: ActivityTypeSearchResponseDto) => any)) => typed_nameof<ActivityTypeSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-activitytype-searcher',
    templateUrl: './activitytype-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviActivityTypeSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.ACTIVITYTYPE';
    SettingsKey = 'settings.searcher.activitytype';
    BaseUrl = 'activity/activitytype';
    FormType = AviActivityTypeFormComponent;

    CanEdit: boolean = false;

    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('ActivityType schreiben').then(w => this.CanEdit = w);
    }

    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.sessionController.openSession('activitytype-index', this.SelectedRows.Id);
            // this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();        
        searcher.addTextColumn(nameof(c => c.KurzBez), 'CORE.COMMON.MODEL.ACTIVITYTYPE.KURZBEZ1', true);        
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.ACTIVITYTYPE.BEZEICHNUNG1', true);
        searcher.addListTypeColumn(nameof(c => c.PeriodPossible), 'CORE.COMMON.MODEL.ACTIVITYTYPE.PERIODPOSSIBLE', 'janein', true, true);        
        searcher.addTextColumn(nameof(c => c.Context1ModelName), 'CORE.COMMON.MODEL.ACTIVITYTYPE.VONCONTEXT1MODELMETA', true);
        searcher.addTextColumn(nameof(c => c.Context2ModelName), 'CORE.COMMON.MODEL.ACTIVITYTYPE.VONCONTEXT2MODELMETA', true);
        searcher.addTextColumn(nameof(c => c.Context3ModelName), 'CORE.COMMON.MODEL.ACTIVITYTYPE.VONCONTEXT3MODELMETA', true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.ACTIVITYTYPE.BEZEICHNUNG1', true);
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
        cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(ActivityTypeSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
