import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { Expose, Type } from 'class-transformer';

export class VwbPVorlageDefInfoDto {

    @Expose()
    public Id: string;

    @Expose()
    public Bezeichnung: string;

    @Expose()
    public DefaultFilename: string;

    @Expose()
    public KannAbgelegtWerden: boolean;

    @Expose()
    @Type(() => ListType)
    public Format: ListType;

    @Expose()
    @Type(() => ListType)
    public OpenWithDocHandler: ListType;
}
