import { TelefonVorwahl } from '@avi-x/avi-dto/partner/telefonvorwahl.model';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviPartnerListDetailConst, AviCommunicationService, AviTypeUtil } from '@avi-x/avi-core';
import { PartnerKommunikationVM } from '@avi-x/avi-dto/partner/partnerkommunikation-vm.model';

const nameof = (nameFunction: ((obj: PartnerKommunikationVM) => any)) => typed_nameof<PartnerKommunikationVM>(nameFunction);

@Component({
    selector: 'avi-crm-partner-kommunikation-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [label-alignment]="ReadOnly ? 'left' : 'top'"
        [label-width]="'160px'"
        [(Model)]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons">

        <div formFieldTemplate1>
            <label [ngClass]="{'label-nonmd': true}">{{ getEingabeLabel() }}</label>

            <div *ngIf="Model" class="pt-4">
                <div class="valign-center">
                    <a *ngIf="isHomepage()" (click)="openHomepage(Model.Eingabe)" class="mr-4p"><i class="material-icons" style="color:#666;">home</i></a>
                    <a *ngIf="isTelefonnummer()" (click)="click2Call(Model.Eingabe)" class="mr-4p"><i class="material-icons searcher-icon" style="color:#666;">call</i></a>
                    <a *ngIf="isEmail()" (click)="click2Email(Model.Eingabe)" class="mr-4p"><i class="material-icons searcher-icon" style="color:#666;">email</i></a>
                    <span>{{ Model.Eingabe || '' }}</span>
                </div>
            </div>
        </div>
    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviPartnerKommunikationFormComponent implements OnInit, OnDestroy {

    private _className = 'PartnerKommunikation';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = '/crm/partnerkommunikation';

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = '/crm/partnerkommunikation';

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: PartnerKommunikationVM = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string { return this._ModelId; }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Input('partner-id')
    partnerId: string = null;

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    UseVorwahl: boolean = true;
    UseE164: boolean = true;

    private Vorwahl: TelefonVorwahl;

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private communicationService: AviCommunicationService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {
    }

    public async InitForm(value: string): Promise<boolean> {

        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        // this.UseVorwahl = (await this.apiService.get(`sysparameter/value/${AviPartnerParamConst.PARTNER_KOMM_TELEFONVORWAHL}`))?.Id === AviListDetailConst.JA;
        // this.UseE164 = (await this.apiService.get(`sysparameter/value/${AviPartnerParamConst.PARTNER_KOMM_PHONEVALIDATION_E164}`))?.Id === AviListDetailConst.JA;

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));

        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        setTimeout(() => this.Form.focusFirstEnabledField());

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    private isTelephoneType(): boolean {
        if ((this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_MOBILE || this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_TELEFON) && this.Model.KommTyp.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_TYP_OFFICE_INTERN)
            return false;

        return (this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_MOBILE
            || this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_TELEFON
            || this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_PAGER_NUMMERISCH
            || this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_FAX
            );
    }

    private updateTelefonNrPart() {
        if (this.Model.TelefonVorwahl_ID != null && this.Model.Eingabe && this.Vorwahl) {
            const vorwahl = this.Vorwahl.Vorwahl;
            this.Model.TelefonNrPart = this.Model.Eingabe.startsWith(vorwahl) ? this.Model.Eingabe.substr(vorwahl.length).trim() : this.Model.Eingabe.trim();
        }
    }

    private setKommArt() {

        if (this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_CHAT || this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_VOIP) {
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EingabeZusatz), true);
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.EingabeZusatz), true);
        }
        else {
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.EingabeZusatz), false);
            this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.EingabeZusatz), false);
            this.Model.EingabeZusatz = null;
        }

        const isTelephoneType = this.isTelephoneType();

        if (this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_EMAIL) {
            this.formFieldService.setFieldIsEmail(this.Fields, nameof(c => c.Eingabe), true);
            this.formFieldService.setFieldIsPhoneNumber(this.Fields, nameof(c => c.Eingabe), false);
        }
        else if (isTelephoneType)
        {
//            this.Model.PhoneValidationE164 = this.E164;
            if (this.UseE164)
            {
//                this.Model.DeactivateE164Validierung = false;
                this.formFieldService.setFieldIsPhoneNumber(this.Fields, nameof(c => c.Eingabe), true);
                this.formFieldService.setFieldIsEmail(this.Fields, nameof(c => c.Eingabe), false);
            }
            else
            {
                this.formFieldService.setFieldIsPhoneNumber(this.Fields, nameof(c => c.Eingabe), false);
                this.formFieldService.setFieldIsEmail(this.Fields, nameof(c => c.Eingabe), true);
            }
        }
        else
        {
            this.formFieldService.setFieldIsEmail(this.Fields, nameof(c => c.Eingabe), false);
            this.formFieldService.setFieldIsPhoneNumber(this.Fields, nameof(c => c.Eingabe), false);
        }

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.TelefonVorwahl_ID), this.UseVorwahl && isTelephoneType);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.TelefonNrPart), this.UseVorwahl && isTelephoneType);
    }

    async getFormattedPhonenumber() {
        if (this.Model.TelefonVorwahl_ID && this.Model.TelefonNrPart && this.UseE164) {
            const number = this.Vorwahl.Vorwahl.trim() + " " + this.Model.TelefonNrPart.trim();
            this.Model.Eingabe = await this.apiService.get(`partnerkommunikation/formatphonenumber/${number}`);
            this.Form.markAsDirty(nameof(c => c.Eingabe));
            this.cdr.markForCheck();
        }
    }

    async onAttrChange(data: AviChangedAttr) {

        if (data.field === nameof(c => c.TelefonNrPart)) {
            await this.getFormattedPhonenumber();
        }

        if (data.field === nameof(c => c.TelefonVorwahl_ID)) {
            await this.loadVorwahl();
            await this.getFormattedPhonenumber();
        }

        if (data.field === nameof(c => c.KommArt) || data.field === nameof(c => c.KommTyp)) {
            this.setKommArt();
        }

/*        if (data.field === nameof(c => c.Eingabe) && this.Model.PhoneValidationE164) {
            this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.DeactivateE164Validierung),
                this.Model.Eingabe && !m_CAFPartnerKommunikation.matchPhoneNumber(CAFPartnerKommunikation.c_Eingabe));
        }*/

        if (this.Vorwahl && data.field === nameof(c => c.Eingabe) && this.Model.Eingabe) {

            const numParts = await this.apiService.get(`partnerkommunikation/GetPhonenumberParts/${this.Model.Eingabe}`);
            if (numParts) {
                this.Model.Eingabe = numParts.FormattedNumber;
                this.Model.TelefonNrPart = numParts.NationalNumber;
                this.Vorwahl = (await this.apiService.getModel(TelefonVorwahl, `telefonvorwahl?filter=(land.isocode eq ${numParts.RegionCode})`))[0];
                console.log(this.Vorwahl);
                this.Model.TelefonVorwahl_ID = this.Vorwahl?.Id;
                this.updateDropdownSources();
            }
        }
    }

    public async initNewModel(data?: any) {

        this.Model = await this.apiService.getModel(PartnerKommunikationVM, 'partnerkommunikation/Create');
        this.Model.vonPartner_ID = this.partnerId;
        this.isEditMode = false;

        // if (data) {
        //     for (const key in data) {
        //         ObjectUtils.setByPath(this.Model, key, data[key]);
        //         this.onAttrChange({ field: key, value: data[key] });
        //     }
        // }

        this.setKommArt();
    }

    ngOnInit() {
        // this.sub = this.activatedRoute.params.subscribe(params => {
        //     if (params && params['id']) {
        //         this.ModelId = params['id'];
        //     }
        // });

        this.initFields();
    }


    deleteModel(model: any) {
        this.apiService.delete(`/partnerkommunikation/${model.id}`).then(r => {

            if (this.RedirectAfterDelete)
                this.router.navigate([this.RedirectAfterDelete]);
        });
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: PartnerKommunikationVM) {

        if (model.GueltigAb == null) model.GueltigAb = AviTypeUtil.SqlLowDate.toDate();
        if (model.GueltigBis == null) model.GueltigBis = AviTypeUtil.HighDate.toDate();

        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            delegate = this.apiService.put(`/partnerkommunikation/${model.Id}`, model);
        } else {
            delegate = this.apiService.post('/partnerkommunikation', model);
        }
        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.RedirectAfterSave)
                this.router.navigate([this.RedirectAfterSave]);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {

        this.commonService.showGlobalLoader();

        this.Model = await this.apiService.FindModel(PartnerKommunikationVM, `partnerkommunikation/${id}`, null, null, null);

        this.updateDropdownSources();

        this.commonService.hideGlobalLoader();
        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        this.setKommArt();

        await this.loadVorwahl();
        this.updateTelefonNrPart();

        return this.Model;
    }

    private async loadVorwahl() {
        this.Vorwahl = this.Model.TelefonVorwahl_ID ? await this.apiService.getModelById(TelefonVorwahl, 'telefonvorwahl', this.Model.TelefonVorwahl_ID) : null;
    }

    async updateDropdownSources() {
        if (this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    isEmail(): boolean {
        return this.Model &&
            this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_EMAIL;
    }

    isTelefonnummer(): boolean {
        return this.Model &&
            (this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_TELEFON ||
                this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_FAX ||
                this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_MOBILE ||
                this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_VOIP);
    }

    isHomepage(): boolean {
        return this.Model && this.Model.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_HOMEPAGE;
    }

    getEingabeLabel(): string {
        if (this.isTelefonnummer())
            return 'Telefonnummer';
        else if (this.isEmail())
            return 'Emailadresse';
        else if (this.isHomepage())
            return 'Homepage';

        return 'Eingabe';
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'Gültig ab', true).setNullDateLow().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'Gültig bis', true).setNullDateHigh().setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.KommTyp), 'Typ', 'caf_partnerkomm_typ', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.KommArt), 'Kommunikationsart', 'caf_partnerkomm_art', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.TelefonVorwahl_ID), 'Vorwahl', false, 'Representation', 'Id', 2, `model/representations/CAFTelefonVorwahl`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.TelefonNrPart), 'Nummer').setMDSizeHalf());

        if (!this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Eingabe), 'Eingabe', true).setMDSizeHalf());
        else
            this.Fields.push(this.formFieldService.CreateTemplate(1, 'Eingabe').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.KommSync), 'Synchronisation', 'caf_partnerkomm_sync', true, false, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Bemerkung), 'Bemerkung', false).setMDSizeFull());

        // this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.verwendePartnerKomm_ID), 'Verwende Kommunikation', false, 'Representation', 'Id', 2, `model/representations/CAFPartnerKommunikation`).setMDSizeHalf());
        // this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonAdresse_ID), 'Adresse', false, 'Representation', 'Id', 2, `model/representations/CAFAdresse`).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.KontaktaufnahmeNichtErwuenscht), null, 'Kontaktaufnahme nicht erwünscht', false).setMDSizeFull());
    }

    public click2Call(data: string) {
        this.communicationService.StartCall(data);
    }

    public click2Email(data: string) {
        this.communicationService.StartEmail(data);
    }

    public openHomepage(data: string) {
        this.communicationService.OpenHomepage(data);
    }
}
