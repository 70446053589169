import { VwbPUnstimmigkeit } from './../../../dto/vwbpunstimmigkeit.model';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AviCommonService, AviApiService, AviAuthService } from '@avi-x/avi-core';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';

export enum AviCommentMode {
    // eslint-disable-next-line no-unused-vars
    Readonly, Create, Edit
}

@Component({
    selector: 'vwbp-intern-unstimmigkeit-view',
    templateUrl: './unstimmigkeit-view.component.html',
    styleUrls: ['./unstimmigkeit-view.component.scss']
})
export class VwbPInternUnstimmigkeitViewComponent implements OnInit {
    private _UnstimmigkeitId: string = null;

    public get UnstimmigkeitId(): string {
        return this._UnstimmigkeitId;
    }

    @Input('unstimmigkeit-id')
    public set UnstimmigkeitId(value: string) {
        this._UnstimmigkeitId = value;
        this.init();
    }

    @Input('version-id')
    public VersionId: string = null;

    @Input('show-buttons')
    public ShowButtons: boolean = true;

    @Input('readonly')
    public Readonly: boolean = false;

    @Output()
    public onSaved: EventEmitter<any> = new EventEmitter();

    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter();

    @Output()
    public onCancelled: EventEmitter<any> = new EventEmitter();

    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter();

    public Mode: AviCommentMode = AviCommentMode.Readonly;

    public Model: VwbPUnstimmigkeit;
    sub: any = null;

    UnstimmigkeitErledigt: boolean = false;
    UnstimmigkeitCollapsed: boolean = false;

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, public authService: AviAuthService, public cdr: ChangeDetectorRef) {
    }

    ngOnInit() {

        // this.formActionButtons = [];
        // this.formActionButtons.push({  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.closeForm(); } });
    }

    async init() {
        if (this._UnstimmigkeitId === this.commonService.GuidEmpty) {
            this.CreateUnstimmigkeit();
        } else if (this._UnstimmigkeitId) {
            await this.loadUnstimmigkeit();
        }
    }

    async refresh() {
        if (this._UnstimmigkeitId) {
            await this.loadUnstimmigkeit();
        }
    }

    async loadUnstimmigkeit() {
        this.Model = await this.apiService.getModelById(VwbPUnstimmigkeit, 'v1/intern/vwbp/unstimmigkeit', this._UnstimmigkeitId);
        this.UnstimmigkeitErledigt = this.Model?.Unstimmigkeitsstatus.Id === VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_ABGESCHLOSSEN;
        this.UnstimmigkeitCollapsed = this.UnstimmigkeitErledigt;
        this.cdr.markForCheck();
    }

    async CreateUnstimmigkeit() {
        this.Model = await this.apiService.FindModel(VwbPUnstimmigkeit, `v1/intern/vwbp/unstimmigkeit/${this.commonService.GuidEmpty}`, null, null);
        this.Model.Meldung = '';
        this.Model.vonVersion_ID = this.VersionId;
        this.Mode = AviCommentMode.Create;
        this.cdr.detectChanges();
        this.setFocus();
    }

    setFocus() {
        setTimeout(() => {
            if (document.getElementById('Meldung'))
                document.getElementById('Meldung').focus();
        }, 100);
    }

    isNewUnstimmigkeit() {
        return this.Mode === AviCommentMode.Create;
    }

    isReadOnlyMode() {
        return this.Mode === AviCommentMode.Readonly;
    }

    EditUnstimmigkeit() {
        this.Mode = AviCommentMode.Edit;
        this.onEdit.emit();
        this.cdr.detectChanges();
        this.setFocus();
    }

    DeleteUnstimmigkeit() {
        this._UnstimmigkeitId = null;
        this.onDelete.emit();
    }

    async CancelUnstimmigkeit() {
        if (this.Mode === AviCommentMode.Edit)
            this.Model = await this.apiService.getModelById(VwbPUnstimmigkeit, 'v1/intern/vwbp/unstimmigkeit', this.Model.Id);
        else
            this.Model = null;

        this.Mode = AviCommentMode.Readonly;

        this.onCancelled.emit();
    }

    // SetUnstimmigkeitErledigt(data) {

    //     if (this.isReadOnly()) {

    //         const delegate = this.apiService.put(`/vwbp/unstimmigkeit/${this.Model.Id}`, this.Model);
    //         delegate.then(async r => {
    //             this.onSaved.emit();
    //             // this.commonService.notificateSuccess('Gespeichert');
    //             this.Model = await this.apiService.getModelById(VwbPUnstimmigkeit, 'vwbp/unstimmigkeit', this.Model.Id);
    //             this.cdr.detectChanges();
    //         }).catch(err => {
    //             console.log(err); this.cdr.detectChanges();
    //         });
    //     }
    // }

    async SaveUnstimmigkeit() {
        if (this.Model && this.Model.Meldung) {
            let delegate: Promise<any> = null;

            if (this.Mode === AviCommentMode.Edit) {
                delegate = this.apiService.put(`v1/intern/vwbp/unstimmigkeit/${this.Model.Id}`, this.Model);
            } else if (this.Mode === AviCommentMode.Create) {
                delegate = this.apiService.post('v1/intern/vwbp/unstimmigkeit', this.Model);
            }

            delegate.then(async r => {
                this.onSaved.emit();
                this.commonService.notificateSuccess('Gespeichert');
                this.Model = await this.apiService.getModelById(VwbPUnstimmigkeit, 'v1/intern/vwbp/unstimmigkeit', this.Model.Id);
                this.Mode = AviCommentMode.Readonly;
                this.cdr.detectChanges();
            }).catch(err => {
                console.log(err);
                this.Mode = AviCommentMode.Readonly;
                this.cdr.detectChanges();
            });
        }
    }

    toggleCollapsed() {
        this.UnstimmigkeitCollapsed = !this.UnstimmigkeitCollapsed;
        this.cdr.markForCheck();
    }

    getUnstimmigkeitHeader() {
        let res = ' - ' + this.Model.ErstellDatum?.toLocaleString('de-CH');

        if (this.UnstimmigkeitCollapsed && this.Model.Meldung) {
            const comment = this.Model.Meldung.split(/(?:\r\n|\r|\n)/)[0];
            res = res + ' - ' + comment;
        }

        return res;
    }
}
