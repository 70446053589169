<div *ngIf="HasDataVerknuepfen || HasDataErstellen" class="pl-2 pr-2 pb-2 pt-2">
    <avi-core-view-panel [no-pad]="true" [toggleable]="true" header="Dokumente">
        <div class="ui-g businesscase-card-contents">
            <div class="ui-g-12">
            
                <!-- Dokument Verknüpfungen -->
                <ng-container *ngIf="HasDataVerknuepfen">
                    <ng-container *ngFor="let status of DataVerknuepfen?.Status">
                        <div *ngIf="DataVerknuepfen.Status.length > 1" class="subtask-title pb-1 pt-2">{{ status.Bezeichnung  }}</div>
                        <table class="subtask-list">
                            <tr *ngFor="let data of status.Data; let i = index">
                                <td class="fitwidth">
                                    <b>{{ data.Bezeichnung }}</b>
                                    <div *ngIf="data.Details.length > 0" class="subtask-subtitle">{{ data.Details[0].CreatedByName }} - {{ data.Details[0].CreatedDate | date: "dd.MM.y HH:mm" : "de-CH" }}</div>
                                </td>

                                <td>
                                    <div class="form-field flex flex-wrap flex-gap-4">
                                        <div *ngFor="let detail of data.Details" >
                                            <p-splitButton 
                                                #btn
                                                [label]="detail.Titel" 
                                                (onDropdownClick)="fillDocumentMenuItems(btn, detail, status.Readonly, data.CanCorrect)"
                                                (onClick)="btn.onDropdownButtonClick($event)"
                                                class="documentlink" 
                                                styleClass="documentlink" 
                                                [icon]="'fa '  + dokumentService.getDocumentIcon(detail.Titel)"
                                                [pTooltip]="detail.Titel" 
                                                tooltipPosition="top" 
                                                [showDelay]="500" 
                                                tooltipStyleClass="autofit-tooltip">
                                            </p-splitButton>
                                            <span *ngIf="detail.Internal" [class]="'ml-2 status-badge status-intern'">Intern</span>
                                        </div>
                                    </div>
                                </td>
                                
                                <td class="tasks fitwidth">
                                    <div>
                                        <avi-file-upload-button *ngIf="!IsIntern && (!status.Readonly || data.CanCorrect)" 
                                            pTooltip="Hochladen" 
                                            label=""
                                            (onUpload)="addDocument(data, $event)" 
                                            styleClass="w-100p-m p-button-secondary p-button-flat" 
                                            icon="pi pi-upload">
                                        </avi-file-upload-button>

                                        <button pButton *ngIf="IsIntern && (!status.Readonly || data.CanCorrect)"
                                            type="button"
                                            icon="pi pi-paperclip"
                                            aviThrottledClick
                                            (throttledClick)="addDocumentLink(data)"
                                            pTooltip="Verknüpfen"
                                            class="w-100p-m p-button-secondary p-button-flat">
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </ng-container>
                </ng-container>

                <!-- Dokument Erstellen -->
                <ng-container *ngIf="HasDataErstellen">
                    <ng-container *ngFor="let status of DataErstellen?.Status">
                        <div *ngIf="DataErstellen.Status.length > 1" class="subtask-title pb-1 pt-2">{{ status.Bezeichnung  }}</div>
                        <table class="subtask-list">
                            <tr *ngFor="let data of status.Data; let i = index">
                                <td class="fitwidth">
                                    <b>{{ data.Bezeichnung }}</b>
                                    <span *ngIf="data.Details.length > 0 && data.Details[0].Internal" [class]="'ml-2 status-badge status-intern'">Intern</span>

                                    <div *ngIf="data.Details.length > 0" class="subtask-subtitle">{{ data.Details[0].CreatedByName }} - {{ data.Details[0].CreatedDate | date: "dd.MM.y HH:mm" : "de-CH" }}</div>
                                </td>
                                
                                <td class="tasks fitwidth">
                                    <div>
                                        <button pButton
                                            *ngIf="status.Readonly && data.Details.length > 0"
                                            type="button"
                                            icon="pi pi-search"
                                            [disabled]="DocumentOpened"
                                            aviThrottledClick
                                            (throttledClick)="openDocument(data)"
                                            pTooltip="Öffnen"
                                            class="w-100p-m p-button-secondary p-button-flat">
                                        </button>
                                        <button pButton
                                            *ngIf="!status.Readonly"
                                            type="button"
                                            icon="pi pi-pencil"
                                            [disabled]="DocumentOpened"
                                            pTooltip="Bearbeiten"
                                            aviThrottledClick
                                            (throttledClick)="editDocument(data)"
                                            class="w-100p-m p-button-secondary p-button-flat">
                                        </button>
                                        <button pButton
                                            *ngIf="status.Readonly && data.CanCorrect"
                                            type="button"
                                            icon="pi pi-pencil"
                                            [disabled]="DocumentOpened"
                                            pTooltip="Korrigieren"
                                            aviThrottledClick
                                            (throttledClick)="editDocument(data)"
                                            class="w-100p-m p-button-secondary p-button-flat">
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </avi-core-view-panel>
</div>