import { VwbPListDetailConst } from '@avi-x/vwbp-public';
import { Comment } from '@avi-x/avi-dto/system/comment.model';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AviCommonService, AviApiService, AviRichTextEditorComponent, AviAuthService } from '@avi-x/avi-core';
import { GenericRef } from '@avi-x/avi-dto/shared/genericref.model';
import { CommentKeywordDto } from '@avi-x/avi-dto/system/comment-keyword-dto.model';

export enum AviCommentMode {
    // eslint-disable-next-line no-unused-vars
    Readonly, Create, Edit
}

@Component({
    selector: 'vwbp-intern-rechtstraeger-comment-view',
    templateUrl: './rechtstraeger-comment-view.component.html',
    styleUrls: ['./rechtstraeger-comment-view.component.scss']
})
export class VwbPInternRechtstraegerCommentViewComponent implements OnInit {
    private _CommentId: string = null;

    public get CommentId(): string {
        return this._CommentId;
    }

    @Input('comment-id')
    public set CommentId(value: string) {
        this._CommentId = value;
        this.init();
    }

    @Input('rechtstraeger-id')
    public RechtstraegerId: string = null;

    @Input('version-id')
    public VersionId: string = null;

    @Input('version-number')
    VersionNumber;

    @Input('version-typ')
    public VersionTyp: any = null;

    @Input('readonly')
    public Readonly: boolean = false;

    @Output()
    public onSaved: EventEmitter<any> = new EventEmitter();

    @Output()
    public onEdit: EventEmitter<any> = new EventEmitter();

    @Output()
    public onCancelled: EventEmitter<any> = new EventEmitter();

    @Output()
    public onDeleted: EventEmitter<any> = new EventEmitter();

    @ViewChild('rtEditor')
    rtEditor: AviRichTextEditorComponent;

    public Mode: AviCommentMode = AviCommentMode.Readonly;

    public Model: Comment;
    sub: any = null;

    IsVersion: boolean = false;
    CommentErledigt: boolean = false;
    CommentCollapsed: boolean = false;

    //    Keywords: any[] = [{ id: 0, value: 'Keyword1' }, { id: 1, value: 'Keyword2' }, { id: 2, value: 'Keyword3' }]

    Keywords = [];

    SelectedKeywords: any[];

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, public authService: AviAuthService, public cdr: ChangeDetectorRef) {
    }

    ngOnInit() {

        // this.formActionButtons = [];
        // this.formActionButtons.push({  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.closeForm(); } });
    }

    isVersionEnabled() {
        return this.VersionId && this.VersionTyp?.Id === VwbPListDetailConst.VWBP_VERSION_TYP_VWBP;
    }

    async init() {
        const kwdefs = await this.apiService.getModelList(CommentKeywordDto, 'v1/intern/vwbp/comment/keyworddefs');
        this.Keywords = kwdefs.map(w => {
            return { name: w.Keyword, code: w.Id };
        });

        if (this._CommentId === this.commonService.GuidEmpty) {
            this.CreateComment();
        } else if (this._CommentId) {
            this.Model = await this.apiService.getModelById(Comment, 'v1/intern/vwbp/comment', this._CommentId);
            this.IsVersion = this.Model.ModelRef.ModelType === 'aviita.VwbP.common.modul.partner.model.VwbPVersion';
            this.CommentErledigt = this.Model.ErledigtAm != null;

            this.SelectedKeywords = this.Model.Keywords?.map(w => {
                return { name: w.Keyword, code: w.Id };
            });

            this.CommentCollapsed = this.CommentErledigt;
            this.cdr.markForCheck();
        }
    }


    async CreateComment() {
        this.Model = await this.apiService.FindModel(Comment, `v1/intern/vwbp/comment/${this.commonService.GuidEmpty}`, null, null);
        this.Model.IsRTF = false;
        this.Model.Comment = '';
        this.SetRechtstraegerContext();
        this.Mode = AviCommentMode.Create;
        this.cdr.detectChanges();
        this.setFocus();
    }

    private SetRechtstraegerContext() {
        this.Model.ModelRef = new GenericRef(this.RechtstraegerId, 'aviita.VwbP.common.modul.partner.model.VwbPRechtstraeger');
        this.IsVersion = false;
    }

    private SetVersionContext() {
        this.Model.ModelRef = new GenericRef(this.VersionId, 'aviita.VwbP.common.modul.partner.model.VwbPVersion');
        this.IsVersion = true;
    }

    setFocus() {
        setTimeout(() => {
            if (document.getElementById('Comment'))
                document.getElementById('Comment').focus();
        }, 100);
    }

    isNewComment() {
        return this.Mode === AviCommentMode.Create;
    }

    isReadOnly() {
        return this.Mode === AviCommentMode.Readonly;
    }

    EditComment() {
        this.Mode = AviCommentMode.Edit;
        this.onEdit.emit();
        this.cdr.detectChanges();
        this.setFocus();
    }

    DeleteComment() {
        if (this.Model) {
            const act = () => {
                this.apiService.delete(`v1/intern/vwbp/comment/${this.Model.Id}`).then(r => {
                    this.Mode = AviCommentMode.Readonly;
                    this.Model = null;
                    this.onDeleted.emit();
                });
            };

            this.commonService.confirm({ message: 'Möchten sie den Kommentar wirklich löschen?', acceptLabel: 'Löschen', acceptButtonStyleClass: 'p-button-primary', accept: act, header: 'Kommentar löschen', icon: 'fa fa-question-circle' });
        }
    }

    async CancelComment() {
        if (this.Mode === AviCommentMode.Edit)
            this.Model = await this.apiService.getModelById(Comment, 'v1/intern/vwbp/comment', this.Model.Id);
        else
            this.Model = null;

        this.Mode = AviCommentMode.Readonly;

        this.onCancelled.emit();
    }

    SetCommentErledigt(data) {
        if (this.isReadOnly()) {
            this.CommentErledigt = data;
            this.CommentCollapsed = this.CommentErledigt;

            if (this.CommentErledigt && !this.Model.ErledigtAm) {
                this.Model.ErledigtAm = new Date();
                this.Model.ErledigtDurch = this.authService.CurrentUserName;
            } else {
                this.Model.ErledigtAm = null;
                this.Model.ErledigtDurch = null;
            }

            const delegate = this.apiService.put(`v1/intern/vwbp/comment/${this.Model.Id}`, this.Model);
            delegate.then(async r => {
                this.onSaved.emit();
                // this.commonService.notificateSuccess('Gespeichert');
                this.Model = await this.apiService.getModelById(Comment, 'v1/intern/vwbp/comment', this.Model.Id);
                this.cdr.detectChanges();
            }).catch(err => {
                console.log(err); this.cdr.detectChanges();
            });
        }
    }

    async SaveComment() {
        if (this.Model && this.Model.Comment) {
            if (this.CommentErledigt && !this.Model.ErledigtAm) {
                this.Model.ErledigtAm = new Date();
                this.Model.ErledigtDurch = this.authService.CurrentUserName;
            } else {
                this.Model.ErledigtAm = null;
                this.Model.ErledigtDurch = null;
            }

            this.Model.Tags = this.SelectedKeywords?.map(w => w.name)?.join(';');
            this.Model.Keywords = this.SelectedKeywords?.map(w => {
                return { Id: w.code, Keyword: w.name };
            });

            if (this.IsVersion) {
                this.Model.ModelRef = new GenericRef(this.VersionId, 'aviita.VwbP.common.modul.partner.model.VwbPVersion');
                this.Model.Bemerkung = this.VersionNumber;
            } else {
                this.Model.ModelRef = new GenericRef(this.RechtstraegerId, 'aviita.VwbP.common.modul.partner.model.VwbPRechtstraeger');
                this.Model.Bemerkung = null;
            }

            let delegate: Promise<any> = null;

            if (this.Mode === AviCommentMode.Edit) {
                delegate = this.apiService.put(`v1/intern/vwbp/comment/${this.Model.Id}`, this.Model);
            } else if (this.Mode === AviCommentMode.Create) {
                delegate = this.apiService.post('v1/intern/vwbp/comment', this.Model);
            }

            delegate.then(async r => {
                this.onSaved.emit();
                this.commonService.notificateSuccess('Gespeichert');
                this.Model = await this.apiService.getModelById(Comment, 'v1/intern/vwbp/comment', this.Model.Id);
                this.Mode = AviCommentMode.Readonly;
                this.cdr.detectChanges();
            }).catch(err => {
                console.log(err);
                this.Mode = AviCommentMode.Readonly;
                this.cdr.detectChanges();
            });
        }
    }

    toggleCollapsed() {
        this.CommentCollapsed = !this.CommentCollapsed;
        this.cdr.markForCheck();
    }

    getCommentHeader() {
        let res = ' - ' + this.Model.ErstellDatum?.toLocaleString('de-CH');

        if (this.IsVersion)
            res = res + ` (Version ${this.VersionNumber})`;

        if (this.CommentCollapsed && this.Model.Comment && this.isReadOnly()) {
            const comment = this.Model.Comment.split(/(?:\r\n|\r|\n)/)[0];
            res = res + ' - ' + comment;
        }

        return res;
    }
}
