import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviDecisionTableEntryFormComponent } from './avi-decisiontableentry-form.component';
import { DecisionTableEntrySearchResponseDto } from '@avi-x/avi-dto/system/decisiontableentrysearchresponsedto.model';
import { AviDecisionTableService } from '../services/avi-decisiontable.service';
import { DecisionTableParam } from '@avi-x/avi-dto/system/decisiontableparam.model';
import { timeout } from 'rxjs/operators';


const nameof = (nameFunction: ((obj: DecisionTableEntrySearchResponseDto) => any)) => typed_nameof<DecisionTableEntrySearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-decisiontableentry-searcher',
    templateUrl: './avi-decisiontableentry-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDecisionTableEntrySearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFDECISIONTABLEENTRY';
    SettingsKey = null;
    BaseUrl = 'decisiontable/entry';
	FormType = AviDecisionTableEntryFormComponent;

    CanEdit: boolean = false;

    DecitionTableParams: DecisionTableParam[];

    // @ViewChild('form')
    // form: AviDecisionTableFormComponent;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef,
        public decisiontableService: AviDecisionTableService
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFDecisionTable schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        
        searcher.addIntegerColumn(nameof(c => c.Sequenz), 'CORE.COMMON.MODEL.CAFDECISIONTABLEENTRY.SEQUENZ', true);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam1), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam2), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam3), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam4), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam5), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam6), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam7), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam8), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam9), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam10), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam11), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam12), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam13), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam14), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam15), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam16), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam17), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam18), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam19), "", true).setVisible(false);
        searcher.addTextColumn(nameof(c => c.DecisionTableParam20), "", true).setVisible(false);

		searcher.endInit();
    }

    forceRefresh: boolean = false;

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        this.DecitionTableParams = null;
        this.forceRefresh = !this.forceRefresh;
        if(this.forceRefresh){this.addIndividualColumns();};
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(DecisionTableEntrySearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }

    public addIndividualColumns(){
        var i: number= 1;
        this.decisiontableService.getPosibleParams(this.ContextId, this.BaseUrl).then(x => {
            x.forEach(v => {
                var column = this.searcher.Columns.find(x => x.Field == "DecisionTableParam" + i);
                column.Label = v;
                column.LabelTrans = v;
                column.setVisible(true);
                i = i+1;
            }); 
            while (i<21) {
                this.searcher.Columns.find(x => x.Field == "DecisionTableParam" + i).setVisible(false);
                i = i+1;
            }
            this.searcher.initColumns();
            this.searcher.recalcColumnFit();
            this.cdr.markForCheck();
            this.searcher.forceRefresh();
        });
    }

    public async createObject() {
        await this.commonService.openFormDialog(this.FormType, this.getFormHeader(false, false), '0', this.ContextId, this.getParams(), true);
        this.onSavedSuccessful();
    }

    public async editObject(selectedRows) {
        if (selectedRows) {
            await this.commonService.openFormDialog(this.FormType, this.getFormHeader(false, true), selectedRows.Id, this.ContextId, this.getParams(), true);
            this.onSavedSuccessful();
        }
    }
}
