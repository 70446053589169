<div class="mt-2">
    <avi-core-view-panel 
        [toggleable]="true" 
        header="Regelwerk" 
        class="w-100p">

        <avi-scriptrulesetdef-form
            [readonly]="true"
            [model-id]="ModelId"
            [contents-padding]="false"
            [card]="false"
            [title]=""
        >
        </avi-scriptrulesetdef-form>

        <button *ngxPermissionsOnly="['CAFScriptRulesetDef testen']" pButton type="button" label="Regelwerk testen" icon="pi pi-eye" aviThrottledClick (throttledClick)="TestRulesetDef()" class="mb-8p p-button-primary ml-2 mt-2"></button>

    </avi-core-view-panel>

    <avi-core-view-panel 
        [toggleable]="true" 
        header="Regel Definitionen" 
        class="w-100p">

        <avi-scriptruledef-searcher
            [context-id]="ModelId"
            [contents-padding]="false"
            [enable-panel]="false"
            [card]="false"
        >
        </avi-scriptruledef-searcher>
       
    </avi-core-view-panel>
</div>