import { NgxPermissionsService } from 'ngx-permissions';
import { BaseModelState, ClassType } from '@avi-x/avi-dto/shared/basemodel.model';
import { Input, Output, EventEmitter, ViewChild, ChangeDetectorRef, OnInit, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AviBaseFormComponent } from './base-form.component';
import { AviChangedAttr, AviFormField } from './form-field';
import { AviCommonService } from '../../services/common.service';
import { AviApiService } from '../../services/api.service';
import { AviFormFieldService } from '../../services/form-field.service';
import { AviApiErrorObject } from '../../dto/aviapierrorobject';
import { MenuItem } from 'primeng/api';
import { IDTO, ListType } from '@avi-x/avi-dto/shared';
import { AviSessionControllerService } from '../../services/session-controller.service';

export interface IAviAbstractModelFormComponent {
    Form: AviBaseFormComponent;
    Card: boolean;
}

@Component({
    template: ''
})
export abstract class AviAbstractModelFormComponent<T> implements OnInit, IAviAbstractModelFormComponent {

    abstract ClassName: string;
    abstract BaseUrl: string;
    abstract ClassType: ClassType<T>;
    abstract FormType: ClassType<any>;
    abstract initFields();

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @Input('base-url-prefix')
    BaseUrlPrefix: string = '';
    
    formTitle: string = null;
    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.formTitle) return this.formTitle;

        return this.getFormHeader(this.ReadOnly, this.isEditMode);
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('views-padding')
    public ViewsPadding: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('action-buttons-readonly')
    actionButtonsReadonly: any[] = [];

    @Input('enable-taskmenu')
    EnableTasks: boolean = false;

    @Input('enable-edit')
    EnableEdit: boolean = true;

    @Input('enable-delete')
    EnableDelete: boolean = true;

    @Input('taskmenu-position')
    public TaskMenuPosition: 'top' | 'bottom' = 'top';

    @Input('get-taskmenu-items')
    public GetTaskMenuItems: () => Promise<MenuItem[]> = async () => await this.getTaskMenuItems();

    public TaskMenuItems: MenuItem[] = null;

    public Loading: boolean = false;
    public Model: T = null;

    public Fields: AviFormField[] = [];

    protected _ModelId: string = null;
    public get ModelId(): string { return this._ModelId; }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Input('context-id')
    public ContextId: string;

    @Input('closesession-on-delete')
    public CloseSessionOnDelete: boolean = true;

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    @Output('onFormError')
    public onFormError: EventEmitter<any> = new EventEmitter();

    CRUDCanRead = true;
    CRUDCanCreate = true;
    CRUDCanEdit = true;
    CRUDCanDelete = true;

    _CRUDBaseRight: string = null;
    @Input('crud-base-right')
    public set CRUDBaseRight(value: string) {
        this._CRUDBaseRight = value;

        if (this.CRUDBaseRight) {
            this.permissionsService.hasPermission([`${this.CRUDBaseRight} anzeigen`, `${this.CRUDBaseRight} lesen`]).then(w => this.CRUDCanRead = w);
            this.permissionsService.hasPermission([`${this.CRUDBaseRight} erfassen`, `${this.CRUDBaseRight} schreiben`]).then(w => this.CRUDCanCreate = w);
            this.permissionsService.hasPermission([`${this.CRUDBaseRight} editieren`, `${this.CRUDBaseRight} schreiben`]).then(w => this.CRUDCanEdit = w);
            this.permissionsService.hasPermission([`${this.CRUDBaseRight} löschen`, `${this.CRUDBaseRight} schreiben`]).then(w => this.CRUDCanDelete = w);
        } else {
            this.CRUDCanRead = true;
            this.CRUDCanCreate = true;
            this.CRUDCanEdit = true;
            this.CRUDCanDelete = true;
        }
    }

    public get CRUDBaseRight() {
        return this._CRUDBaseRight;
    }

    protected getFormHeader(readonly: boolean, editmode: boolean): string {
        const isResource = this.ClassName.toUpperCase() === this.ClassName && this.ClassName.indexOf('.') > 0;
        if (isResource) {
            const cn = this.ClassName.substring(this.ClassName.lastIndexOf('.') + 1);
            if (readonly) return `${this.ClassName}.${cn}_1`;
            return editmode ? `${this.ClassName}.${cn}_EDIT` : `${this.ClassName}.${cn}_NEW`;
        }

        if (readonly) return this.ClassName;
        return this.ClassName + (editmode ? ' bearbeiten' : ' erstellen');
    }

    abbrechenButton: any = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.doCancel(); } }];
    abbrechenButtonReadonly: any = [{ title: 'CORE.COMMON.SCHLIESSEN_BUTTON', class: 'p-button-secondary', action: () => { this.doCancel(); } }];

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
        public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {
    }

    doCancel() {
        if (this.ref) {
            if (this.Form.isFormPristine())
                this.ref.close(null);
            else 
                this.commonService.confirmRes({
                    header: 'CORE.COMMON.BEARBEITUNG_ABBRECHEN_HEADER',
                    message: 'CORE.COMMON.BEARBEITUNG_ABBRECHEN_MSG',
                    acceptLabel: 'CORE.COMMON.BEARBEITUNG_ABBRECHEN_BUTTON',
                    rejectLabel: 'CORE.COMMON.BEARBEITUNG_ABBRECHEN_CANCEL_BUTTON',
                    acceptButtonStyleClass: 'p-button-primary', 
                    rejectButtonStyleClass: 'p-button-secondary p-button-outlined', 
                    accept: () => this.ref.close(null) }); 
        }
    }

    public getBaseUrl(): string {
        return this.BaseUrlPrefix ? `${this.BaseUrlPrefix}/${this.BaseUrl}` : this.BaseUrl;
    }

    public getUrl(typeUrl: string) {
        return this.BaseUrlPrefix ? `${this.BaseUrlPrefix}/${typeUrl}` : typeUrl; 
    }

    public async createModel(): Promise<T> {
        const res = await this.apiService.getModel(this.ClassType, `${this.getBaseUrl()}/create`);
        await this.afterCreateModel(res);
        return res;
    }

    async getModel(id: string): Promise<T> {
        return await this.apiService.getModel(this.ClassType, `${this.getBaseUrl()}/${id}`);
    }

    ngOnInit() {
        if (this.config && this.config.data) {
            if (this.config.data.formType === this.FormType && this.config.data.Id) {
                this.BaseUrlPrefix = this.config.data.baseUrlPrefix;
                this.initParams(this.config.data.Params);
                this.ContextId = this.config.data.ContextId;
                this.ModelId = this.config.data.Id;
                this.ReadOnly = this.config.data.readonly ?? false;
                this.Card = false;
                this.ContentsPadding = false;
                this.actionButtons = [ ...this.abbrechenButton, ...this.actionButtons ];
                this.actionButtonsReadonly = [ ...this.abbrechenButtonReadonly, ...this.actionButtonsReadonly ];
                this.setDialogMode();
            }
        }

        this.initFields();
    }

    setDialogMode() {        
        setTimeout(() => {
            if (this.Form) {
                this.Form.ButtonsAlignment = 'right';
                this.Form.ButtonsInvert = true;
            }
        });
    }

    protected async InitForm(value: string): Promise<boolean> {
        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                 this.Model = null;
                 this._ModelId = value;
                 this.isEditMode = false;
             }
             return;
        }

        this._ModelId = value;
        this.isEditMode = false;

        try {
            if (this._ModelId && this._ModelId !== '0') {
                if (!this.CRUDCanRead)
                    throw Error(`Sie haben nicht das benötigte Recht um das Objekt zu lesen`);
                await this.loadModel(this._ModelId);
                this.isEditMode = this.CRUDCanEdit;
                if (!this.ReadOnly && !this.CRUDCanEdit)
                    this.ReadOnly = true;
            } else {
                if (!this.CRUDCanCreate)
                    throw Error(`Sie haben nicht das benötigte Recht um das Objekt zu erfassen`);
                await this.initNewModel();
            }
        } catch (err) {
            this.commonService.hideGlobalLoader();
            this.ref?.close();
            this.commonService.notificateError(err);
            this.onFormError.emit();
        }

        await this.afterInitForm();
		await this.updateDropdownSources();

        await this.FillTaskMenuItems();
        this.cdr.markForCheck();

        return true;
    }

    public async afterCreateModel(bm: T) {
    }

    public async afterInitForm() {
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: T) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            if (model instanceof BaseModelState)
                delegate = this.apiService.put(`/${this.getBaseUrl()}/${model.Id}`, model);
        } else {
            delegate = this.apiService.post(`/${this.getBaseUrl()}`, model);
        }
        delegate?.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.ref)
                this.ref.close(model);
        }).catch(err => {
            console.log(err);
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    protected saveDto(id: string, dto: IDTO, url?: string, forcePutRquest = false) {
        this.Form.clearFormMessages();

        if (!url)
            url = this.getBaseUrl();

        let delegate: Promise<any> = null;
        if (forcePutRquest || this.isEditMode) {
                delegate = this.apiService.put(`/${url}/${id}`, dto);
        } else {
            delegate = this.apiService.post(`/${url}`, dto);
        }
        delegate?.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(dto);

            if (this.ref)
                this.ref.close(dto);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async onAttrChange(data: AviChangedAttr) {
    }

    public async initNewModel() {
        this.Form?.clearFormMessages();
        this.Form?.resetForm();

        this.Model = await this.createModel();
        this.isEditMode = false;
    }

    async loadModel(id: string) {
        this.Form?.clearFormMessages();

        this.commonService.showGlobalLoader();
        this.Model = await this.getModel(id);

        this.commonService.hideGlobalLoader();

        this.isEditMode = true;

        if (this.Model instanceof BaseModelState)
            this._ModelId = this.Model.Id;

        return this.Model;
    }

    async updateDropdownSources() {
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model, true);
        await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);
        this.cdr.markForCheck();
    }

    initParams(params: { [k: string]: any; }) {
    }

    getParams(): { [k: string]: any; } {
        return null;
    }

    async refresh() {
        if (this.Model)
            await this.loadModel(this.ModelId);

        await this.updateDropdownSources();

        this.cdr.markForCheck();
    }

    async openEditDialog() {
        await this.commonService.openFormDialog(this.FormType, this.getFormHeader(false, true), this.ModelId, this.ContextId, this.getParams(), true);
        await this.refresh();
    }

    openDeleteDialog() {
        this.apiService.deleteObject(this.getBaseUrl(), this.ModelId, () => {
            if (this.CloseSessionOnDelete)
                this.sessionController.closeCurrentSession();
        });
    }

    async FillTaskMenuItems() {
        this.TaskMenuItems = await this.getTaskMenuItems();
    }

    async getTaskMenuItems(): Promise<MenuItem[]> {
        var items: MenuItem[] = [];
        if (this.ModelId && this.EnableTasks) {
            if (this.CRUDCanEdit && this.EnableEdit)
                items.push({ label: 'Bearbeiten', command: () => this.openEditDialog(), icon: 'pi pi-pencil' });
            if (this.CRUDCanDelete && this.EnableDelete)
                items.push({ label: 'Löschen', command: () => this.openDeleteDialog(), icon: 'pi pi-trash' });
        }

        return items;
    }

    async getListType(listname: string, detailId: string) {
        return await this.apiService.getModel(ListType, `model/representations/listtype/${listname}/${detailId}`);
    }
}
