import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AviApiService, AviBaseSearcherComponent, AviChangedAttr, AviCommonService, AviDialogComponent, typed_nameof } from '@avi-x/avi-core';
import { AviEmailConfigFormComponent } from '@avi-x/avi-crm';
import { VwbPEmailDef } from '../../../dto/vwbpemaildef.model';
import { VwbPAdminEmailDefFormComponent } from '../emaildef-form/emaildef-form.component';

const nameof = (nameFunction: ((obj: VwbPEmailDef) => any)) => typed_nameof<VwbPEmailDef>(nameFunction);

@Component({
    selector: 'vwbp-admin-emaildef-searcher',
    templateUrl: './emaildef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminEmailDefSearcherComponent implements OnInit {
    public Loading: boolean = false;

    FormModelId: string = null;

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @ViewChild('emailDefFormDialog', { static: false })
    emailDefFormDialog: AviDialogComponent;

    @ViewChild('emailDefForm', { static: false })
    public emailDefForm: VwbPAdminEmailDefFormComponent;

    @ViewChild('emailConfigFormDialog', { static: false })
    emailConfigFormDialog: AviDialogComponent;

    @ViewChild('emailConfigForm', { static: false })
    public emailConfigForm: AviEmailConfigFormComponent;

    public Card = true;

    private SelectedRows: any[] = [];
    public DetailModelId: string = null;

    private emailConfigDisabled: boolean = true;

    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];
    formActionButtonsEmailConfig: any[] = [
        // { title: 'Test-Email verschicken', class: 'p-button-primary p-button-outlined', icon: 'pi pi-envelope', action: () => this.testEmailConfig(), disabled: () => this.emailConfigDisabled },
        {  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeEmailConfigForm() }
    ];

    constructor(
        private commonService: AviCommonService,
        private apiService: AviApiService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
    }

    async onSelectionChanged(data: any[]) {
        this.SelectedRows = data;

        if (this.SelectedRows.length > 0) {
            this.DetailModelId = this.SelectedRows[0].Id;
        }
    }

    onSearcherInit() {
        this.searcher.beginInit();
        this.searcher.addListTypeColumn(nameof(c => c.Event), 'Event', 'vwbp_event', true, false, null, 'v1/vwbp');
        this.searcher.addTextColumn('Eingangsstatus', 'Eingangsstatus', false);
        this.searcher.addTextColumn('Ausgangsstatus', 'Ausgangsstatus', false);
        this.searcher.addTextColumn(nameof(c => c.Recipient), 'Empfänger', false);

        this.searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get('v1/intern/vwbp/emaildef/list');
        res = this.searcher.ApplyColumnFilters(res);
        res = this.searcher.ApplyColumnSorting(res);
        return res;
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    onDoubleClickSearcher(data) {
        this.editEmailDef();
    }


    public createEmailDef() {
        this.FormModelId = '0';
        this.emailDefFormDialog.open();
    }

    public editEmailDef() {
        if (this.SelectedRows.length > 0 && this.SelectedRows[0]) {
            this.FormModelId = this.SelectedRows[0].Id;
            this.emailDefFormDialog.open();
        }
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.searcher.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.emailDefFormDialog.close();
    }

    public deleteEmailDef() {
        if (this.SelectedRows.length > 0 && this.SelectedRows[0]) {
            const id = this.SelectedRows[0].Id;

            this.commonService.confirmDelete(`Möchten Sie die Email-Definition wirklich löschen?`,
                () => {
                    this.apiService.delete(`v1/intern/vwbp/emaildef/${id}`).then(r => {
                        this.commonService.notificateSuccess('Gelöscht');
                        this.searcher.forceRefresh();
                    });
                }
            );
        }
    }

    //
    public editEmailConfiguration() {
        this.emailConfigForm.InitForm();
        this.emailConfigFormDialog.open();
    }

    onEmailConfigSavedSuccessful(event) {
        this.closeEmailConfigForm();
        this.searcher.forceRefresh();
    }

    private closeEmailConfigForm() {
        this.emailConfigForm.ClearForm();
        this.emailConfigFormDialog.close();
    }
}
