<div class="vwb-internal-container">
    <avi-core-page
        title=""
        [card]="false"
        [collapsible]="false"
        [expanded]="true"
        [contents-padding]="true"
        [no-box-shadow]="true"
    >
        <button
            *ngIf="Standalone"
            pButton
            type="button"
            icon="pi pi-chevron-left"
            aria-label="Go back"
            class="p-button-secondary p-button-text mt-2 back-button"
            aviThrottledClick
            (throttledClick)="NavigateBack()"
        ></button>

        <div *ngIf="!Standalone" class="mt-4"></div>

        <!-- VERSION SELEKTOR -->
        <div class="version-dropdown mb-2">
            <p-dropdown
                *ngIf="Model && Versions && SelectedVersion"
                [showClear]="false"
                [readonly]="false"
                [options]="Versions"
                [required]="true"
                [autofocus]="true"
                [itemSize]="30"
                [ngModel]="SelectedVersion.Id"
                (ngModelChange)="SetSelectedVersion($event)"
            ></p-dropdown>
        </div>

        <p-messages styleClass="card-contents" [(value)]="msgs" [enableService]="false" [escape]="false" [closable]="false" showTransitionOptions="0ms" hideTransitionOptions="0ms"></p-messages>

        <div class="mr-4">
                <span class="grid justify-content-end" *ngIf="ExportItems.length > 0">
                    <button
                        type="button"
                        pButton
                        [pTooltip]="GetPdfIconToolTip()"
                        tooltipPosition="left"
                        icon="pi pi-file-pdf"
                        class="p-button-secondary p-button-text p-button-rounded"
                        (click)="menu.toggle($event)"
                    ></button>
                    <p-menu
                        #menu
                        appendTo="body"
                        [popup]="true"
                        [model]="ExportItems"
                        [style]="{ width: 'auto' }"
                        [baseZIndex]="9999"
                    ></p-menu>
                </span>
        </div>

        <!-- RECHTSTRÄGER DETAILS -->
        <div class="card-contents" *ngIf="SelectedDetail">
            <p-panel
                [toggleable]="true"
                [collapsed]="true"
                expandIcon="pi pi-chevron-down"
                collapseIcon="pi pi-chevron-up"
                toggler="header"
            >
                <p-header class="rtpanel">
                    <span class="p-panel-title rt-view-title">{{ RTName }}</span>

                    <vwbp-intern-rechtstraeger-miniview
                        [SelectedDetail]="SelectedDetail"
                        [SelectedVersion]="SelectedVersion"
                        [SelectedRT]="Model"
                        [InterneId]="Model.InterneId"
                        (onToggleValidierungAusnahme)="doTaskValidierungAusnahme($event)"
                        (onToggleBemerkungTeilfonds)="doTaskBemerkungTeilfonds($event)"
                        (onToggleEingetragenEWRRegister)="doTaskEingetragenEWRRegister($event)"
                    >
                    </vwbp-intern-rechtstraeger-miniview>

                    <!-- MAHNWESEN TASKS -->
                    <div class="rt-mr-min25 mb-2">
                        <vwbp-intern-mahnwesen-taskbar
                            *ngIf="Model && (isCurrentVersion() || !Model.CurrentVersionId) && SelectedDetail && currentUserIsAjuUser()"
                            [readonly]="!currentUserIsAjuUserSchreiben()"
                            [rechtstraeger-detail]="SelectedDetail"
                            [version]="SelectedVersion"
                            [rechtstraeger]="Model"
                            (onChanged)="OnMahnwesenChanged()"
                        >
                        </vwbp-intern-mahnwesen-taskbar>
                    </div>
                </p-header>

                <div>
                    <vwbp-public-rechtstraegerdetail-form
                        #showRechtstraegerForm
                        [api]="'v1/intern/vwbp'"
                        [card]="false"
                        [readonly]="true"
                        [model-id]="SelectedDetail.Id"
                        [contents-padding]="false"
                    >
                    </vwbp-public-rechtstraegerdetail-form>
                </div>
            </p-panel>
        </div>

        <div *ngIf="UebergabeAnfrageId && currentUserIsAjuUser()" class="mb-2">
            <vwbp-intern-uebergabe-anfrage-view [view-mode]="'embedded'" [anfrage-id]="UebergabeAnfrageId">
            </vwbp-intern-uebergabe-anfrage-view>
        </div>

        <!-- KONTAKTINFO -->
        <vwbp-intern-rechtstraeger-kontaktinfo-panel
            *ngIf="VersionMasterOwnerID && currentUserIsAjuUser() && !IsAbgegeben"
            [owner-id]="VersionMasterOwnerID"
            [force-collapsed]="ForceKontaktInfoCollapsed"
        ></vwbp-intern-rechtstraeger-kontaktinfo-panel>

        <!-- UNSTIMMIGKEITSMELDUNGEN -->
        <vwbp-intern-unstimmigkeit-list
            *ngIf="Model && SelectedVersion && SelectedDetail && currentUserIsAjuUser()"
            [readonly]="!currentUserIsAjuUserSchreiben()"
            [rechtstraeger-detail]="SelectedDetail"
            [version]="SelectedVersion"
            (onChanged)="OnUnstimmigkeitenChanged()"
        >
        </vwbp-intern-unstimmigkeit-list>

        <!-- SCHWAERZEN -->
        <vwbp-intern-schwaerzen-panel
            #schwaerzenPanel
            *ngIf="Model && SelectedVersion && currentUserIsAjuUser()"
            [force-collapsed]="ForceSchwaerzenCollapsed"
            [readonly]="!currentUserIsAjuUserSchreiben() || !canEditFormSchwaerzen()"
            [rechtstraeger]="Model"
            [version]="SelectedVersion"
            [formular-daten]="SelectedVersion.alleFormularDaten"
            (onChanged)="OnSchwaerzenChanged()"
        >
        </vwbp-intern-schwaerzen-panel>

        <!-- KOMMENTARE -->
        <vwbp-intern-rechtstraeger-comment-list
            *ngIf="Model && currentUserIsAjuUser()"
            [force-collapsed]="ForceCommentsCollapsed"
            [readonly]="!currentUserIsAjuUserSchreiben()"
            [rechtstraeger-id]="Model.Id"
            [version-number]="SelectedVersion?.Laufnummer"
            [version-id]="SelectedVersion?.Id"
            [version-typ]="SelectedVersion?.Typ"
            (onChange)="OnCommentChanged()"
        >
        </vwbp-intern-rechtstraeger-comment-list>

        <!-- STATUS LOG -->
        <vwbp-intern-verlauf-searcher
            #verlauf
            *ngIf="Model && currentUserIsAjuUser()"
            [readonly]="!currentUserIsAjuUserSchreiben()"
            [rechtstraeger-id]="this.Model.Id"
        >
        </vwbp-intern-verlauf-searcher>

        <!--FORMULARE -->
        <avi-core-view-panel
            *ngIf="SelectedVersion"
            [header]="
                (isVwEGVersion() ? 'VWBP.PUBLIC.WE_ERFASSTE_PERSONEN' : 'VWBP.PUBLIC.WB_ERFASSTE_PERSONEN') | translate
            "
            [collapsed]="ForceFormulareCollapsed"
        >
            <div class="mt-2">
                <vwbp-public-formular-liste
                    #formularListe
                    [api]="'v1/intern/vwbp'"
                    [can-edit]="false"
                    [show-schwaerzen]="SchwaerzungVorhanden && canEditFormSchwaerzen()"
                    [formular-daten]="SelectedVersion.alleFormularDaten"
                    [context]="Model.CurrentVersionId"
                    (formular-changed)="refreshFormulare()"
                >
                </vwbp-public-formular-liste>
            </div>
            <div class="col grid justify-content-end">
                <span *ngIf="currentUserIsOnlyBehoerdenUser() && ExportItems?.length === 1">
                    <button
                        pButton
                        type="button"
                        [label]="'VWBP.COMMON.RT_AUSZUG_ERSTELLEN_BUTTON' | translate"
                        icon="pi pi-file-pdf"
                        aviThrottledClick
                        (throttledClick)="ExportItems[0].command()"
                        class="w-100p-m m  ml-2 p-button-secondary"
                    ></button>
                </span>
                <span *ngIf="currentUserIsOnlyBehoerdenUser() && ExportItems?.length > 1">
                    <button
                        pButton
                        type="button"
                        [label]="'VWBP.COMMON.RT_AUSZUG_ERSTELLEN_BUTTON' | translate"
                        icon="pi pi-file-pdf"
                        class="w-100p-m m  ml-2 p-button-secondary"
                        (click)="menuCreate.toggle($event)"
                    ></button>
                    <p-menu
                        #menuCreate
                        appendTo="body"
                        [popup]="true"
                        [model]="ExportItems"
                        [style]="{ minWidth: '18em', width: 'auto' }"
                    ></p-menu>
                </span>
                <button
                    *ngIf="enableTaskFreigebenRueckgaengig()"
                    pButton
                    type="button"
                    icon="pi pi-undo"
                    [label]="'VWBP.INTERN.FREIGABE.FREIGEBEN_RUECKGAENGIG_BUTTON' | translate"
                    aviThrottledClick
                    (throttledClick)="doTaskFreigebenRueckgaengig()"
                    class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                ></button>
                <button
                    *ngIf="enableTaskFreigeben()"
                    pButton
                    type="button"
                    [label]="'VWBP.INTERN.FREIGABE.FREIGEBEN_BUTTON' | translate"
                    icon="pi pi-thumb-up"
                    aviThrottledClick
                    (throttledClick)="doTaskFreigeben()"
                    class="w-100p-m m ml-2"
                ></button>
            </div>
        </avi-core-view-panel>
    </avi-core-page>

    <p-confirmDialog key="k1"></p-confirmDialog>
    <p-confirmDialog key="k2"></p-confirmDialog>

    <avi-core-dialog #taskDialog identifier="taskDialog" [showCloseIcon]="false" [closeOnEscape]="false">
        <vwbp-intern-rechtstraeger-rueckgaengig-task
            *ngIf="Modus === RechtstraegerTaskMode.RUECKGAENGIG"
            [modus]="RueckgaengigMode"
            [selected-rechtstraeger]="selectedRT"
            [dialog]="true"
            (onCancel)="CloseDialog()"
            (onSave)="OnSave()"
        ></vwbp-intern-rechtstraeger-rueckgaengig-task>
        <vwbp-intern-rechtstraeger-freigeben-task
            *ngIf="Modus === RechtstraegerTaskMode.FREIGEBEN"
            [selected-rechtstraeger]="selectedRT"
            [dialog]="true"
            (onCancel)="CloseDialog()"
            (onSave)="OnSave()"
        ></vwbp-intern-rechtstraeger-freigeben-task>
        <vwbp-intern-rechtstraeger-vereinfachte-validierung-task
            *ngIf="Modus === RechtstraegerTaskMode.VEREINFACHTE_VALIDIERUNG"
            [selected-rechtstraeger]="selectedRT"
            [vereinfachte-validierung]="SetValidierungAusnahme"
            [dialog]="true"
            (onCancel)="CloseDialog()"
            (onSave)="OnSave()"
        ></vwbp-intern-rechtstraeger-vereinfachte-validierung-task>
        <vwbp-intern-rechtstraeger-bemerkung-teilfonds-task
            *ngIf="Modus === RechtstraegerTaskMode.BEMERKUNG_TEILFONDS"
            [selected-rechtstraeger]="selectedRT"
            [bemerkung-teilfonds]="SetBemerkungTeilfonds"
            [dialog]="true"
            (onCancel)="CloseDialog()"
            (onSave)="OnSave()"
        ></vwbp-intern-rechtstraeger-bemerkung-teilfonds-task>
        <vwbp-intern-rechtstraeger-eingetragen-ewr-register-task
            *ngIf="Modus === RechtstraegerTaskMode.EINGETRAGEN_EWR_REGISTER"
            [selected-rechtstraeger]="selectedRT"
            [eingetragen-ewr-register]="SetEingetragenEWRRegister"
            [dialog]="true"
            (onCancel)="CloseDialog()"
            (onSave)="OnSave()"
        ></vwbp-intern-rechtstraeger-eingetragen-ewr-register-task>
        <vwbp-intern-rechtstraeger-schwaerzung-vorhanden-task
            *ngIf="Modus === RechtstraegerTaskMode.SCHWAERZUNG_VORHANDEN"
            [selected-rechtstraeger]="selectedRT"
            [schwaerzung-vorhanden]="SetSchwaerzungVorhanden"
            [dialog]="true"
            (onCancel)="CloseDialog()"
            (onSave)="OnSave()"
        ></vwbp-intern-rechtstraeger-schwaerzung-vorhanden-task>
    </avi-core-dialog>
</div>
