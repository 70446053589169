import { Expose, Type } from 'class-transformer';

import { QueryDefGruppe } from '../reporting/querydefgruppe.model';
import { ModelMeta } from '../system/modelmeta.model';
import { TechRole } from '../system/techrole.model';
import { ReportDef } from '../system/reportdef.model';
import { Farbpalette } from '../system/farbpalette.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class QueryDef extends BaseModel {  
    @Expose()
    @Type(() => Date)
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }
    
    @Expose()
    @Type(() => Date)
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }
    
    @Expose()
    public get Sorter(): number { return this.getProperty('Sorter'); }
    public set Sorter(value: number) { this.setProperty('Sorter', value); }
    
    @Expose()
    public get System(): number { return this.getProperty('System'); }
    public set System(value: number) { this.setProperty('System', value); }
    
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get Beschreibung(): string { return this.getProperty('Beschreibung'); }
    public set Beschreibung(value: string) { this.setProperty('Beschreibung', value); }
    
    @Expose()
    public get SqlText(): string { return this.getProperty('SqlText'); }
    public set SqlText(value: string) { this.setProperty('SqlText', value); }
    
    @Expose()
    public get SearcherUsecase(): string { return this.getProperty('SearcherUsecase'); }
    public set SearcherUsecase(value: string) { this.setProperty('SearcherUsecase', value); }
    
    @Expose()
    public get ControllerUsecase(): string { return this.getProperty('ControllerUsecase'); }
    public set ControllerUsecase(value: string) { this.setProperty('ControllerUsecase', value); }
    
    @Expose()
    public get MinSearchChars(): number { return this.getProperty('MinSearchChars'); }
    public set MinSearchChars(value: number) { this.setProperty('MinSearchChars', value); }
    
    @Expose()
    public get OpenTabpage(): string { return this.getProperty('OpenTabpage'); }
    public set OpenTabpage(value: string) { this.setProperty('OpenTabpage', value); }
    
    @Expose()
    public get ContextColumn(): string { return this.getProperty('ContextColumn'); }
    public set ContextColumn(value: string) { this.setProperty('ContextColumn', value); }
    
    @Expose()
    public get ContextTypeColumn(): string { return this.getProperty('ContextTypeColumn'); }
    public set ContextTypeColumn(value: string) { this.setProperty('ContextTypeColumn', value); }
    
    @Expose()
    public get vonGruppe_ID(): string { return this.getModelId('vonGruppe_ID'); }
    public set vonGruppe_ID(value: string) { this.setModelId('vonGruppe_ID', value); }
 
    @Expose()
    @Type(() => QueryDefGruppe)
    public get vonGruppe(): QueryDefGruppe { return this.getModel('vonGruppe'); }
    public set vonGruppe(value: QueryDefGruppe) { this.setModel('vonGruppe', value); }
    
    @Expose()
    public get vonModelMeta_ID(): string { return this.getModelId('vonModelMeta_ID'); }
    public set vonModelMeta_ID(value: string) { this.setModelId('vonModelMeta_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get vonModelMeta(): ModelMeta { return this.getModel('vonModelMeta'); }
    public set vonModelMeta(value: ModelMeta) { this.setModel('vonModelMeta', value); }
    
    @Expose()
    public get ControllerContext_ID(): string { return this.getModelId('ControllerContext_ID'); }
    public set ControllerContext_ID(value: string) { this.setModelId('ControllerContext_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get ControllerContext(): ModelMeta { return this.getModel('ControllerContext'); }
    public set ControllerContext(value: ModelMeta) { this.setModel('ControllerContext', value); }
    
    @Expose()
    public get TechRoleExecute_ID(): string { return this.getModelId('TechRoleExecute_ID'); }
    public set TechRoleExecute_ID(value: string) { this.setModelId('TechRoleExecute_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleExecute(): TechRole { return this.getModel('TechRoleExecute'); }
    public set TechRoleExecute(value: TechRole) { this.setModel('TechRoleExecute', value); }
    
    @Expose()
    public get TechRoleExport_ID(): string { return this.getModelId('TechRoleExport_ID'); }
    public set TechRoleExport_ID(value: string) { this.setModelId('TechRoleExport_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleExport(): TechRole { return this.getModel('TechRoleExport'); }
    public set TechRoleExport(value: TechRole) { this.setModel('TechRoleExport', value); }
    
    @Expose()
    public get ReportDef_ID(): string { return this.getModelId('ReportDef_ID'); }
    public set ReportDef_ID(value: string) { this.setModelId('ReportDef_ID', value); }
 
    @Expose()
    @Type(() => ReportDef)
    public get ReportDef(): ReportDef { return this.getModel('ReportDef'); }
    public set ReportDef(value: ReportDef) { this.setModel('ReportDef', value); }
    
    @Expose()
    public get TechRoleMeineTeams_ID(): string { return this.getModelId('TechRoleMeineTeams_ID'); }
    public set TechRoleMeineTeams_ID(value: string) { this.setModelId('TechRoleMeineTeams_ID', value); }
 
    @Expose()
    @Type(() => TechRole)
    public get TechRoleMeineTeams(): TechRole { return this.getModel('TechRoleMeineTeams'); }
    public set TechRoleMeineTeams(value: TechRole) { this.setModel('TechRoleMeineTeams', value); }
    
    @Expose()
    public get Farbpalette_ID(): string { return this.getModelId('Farbpalette_ID'); }
    public set Farbpalette_ID(value: string) { this.setModelId('Farbpalette_ID', value); }
 
    @Expose()
    @Type(() => Farbpalette)
    public get Farbpalette(): Farbpalette { return this.getModel('Farbpalette'); }
    public set Farbpalette(value: Farbpalette) { this.setModel('Farbpalette', value); }
    
    @Expose()
    @Type(() => ListType)
    public get SqlPagingEnabled(): ListType { return this.getProperty('SqlPagingEnabled'); }
    public set SqlPagingEnabled(value: ListType) { this.setProperty('SqlPagingEnabled', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DefaultSearcher(): ListType { return this.getProperty('DefaultSearcher'); }
    public set DefaultSearcher(value: ListType) { this.setProperty('DefaultSearcher', value); }
    
    @Expose()
    @Type(() => ListType)
    public get LanguageAware(): ListType { return this.getProperty('LanguageAware'); }
    public set LanguageAware(value: ListType) { this.setProperty('LanguageAware', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ShowInSuche(): ListType { return this.getProperty('ShowInSuche'); }
    public set ShowInSuche(value: ListType) { this.setProperty('ShowInSuche', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ShowInController(): ListType { return this.getProperty('ShowInController'); }
    public set ShowInController(value: ListType) { this.setProperty('ShowInController', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AddTabToController(): ListType { return this.getProperty('AddTabToController'); }
    public set AddTabToController(value: ListType) { this.setProperty('AddTabToController', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ContextErforderlich(): ListType { return this.getProperty('ContextErforderlich'); }
    public set ContextErforderlich(value: ListType) { this.setProperty('ContextErforderlich', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AutoSearch(): ListType { return this.getProperty('AutoSearch'); }
    public set AutoSearch(value: ListType) { this.setProperty('AutoSearch', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Darstellung(): ListType { return this.getProperty('Darstellung'); }
    public set Darstellung(value: ListType) { this.setProperty('Darstellung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get SearchBarAnzeigen(): ListType { return this.getProperty('SearchBarAnzeigen'); }
    public set SearchBarAnzeigen(value: ListType) { this.setProperty('SearchBarAnzeigen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get FooterAnzeigen(): ListType { return this.getProperty('FooterAnzeigen'); }
    public set FooterAnzeigen(value: ListType) { this.setProperty('FooterAnzeigen', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ChartType(): ListType { return this.getProperty('ChartType'); }
    public set ChartType(value: ListType) { this.setProperty('ChartType', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Chart3D(): ListType { return this.getProperty('Chart3D'); }
    public set Chart3D(value: ListType) { this.setProperty('Chart3D', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ChartRotated(): ListType { return this.getProperty('ChartRotated'); }
    public set ChartRotated(value: ListType) { this.setProperty('ChartRotated', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ChartShowLegend(): ListType { return this.getProperty('ChartShowLegend'); }
    public set ChartShowLegend(value: ListType) { this.setProperty('ChartShowLegend', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ChartLegendHAlignment(): ListType { return this.getProperty('ChartLegendHAlignment'); }
    public set ChartLegendHAlignment(value: ListType) { this.setProperty('ChartLegendHAlignment', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ChartLegendVAlignment(): ListType { return this.getProperty('ChartLegendVAlignment'); }
    public set ChartLegendVAlignment(value: ListType) { this.setProperty('ChartLegendVAlignment', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ChartLegendDirection(): ListType { return this.getProperty('ChartLegendDirection'); }
    public set ChartLegendDirection(value: ListType) { this.setProperty('ChartLegendDirection', value); }
    
    @Expose()
    @Type(() => ListType)
    public get OpenAction(): ListType { return this.getProperty('OpenAction'); }
    public set OpenAction(value: ListType) { this.setProperty('OpenAction', value); }
    
}
