<avi-core-base-searcher
    #searcher
    [search-delegate]="'konto'"
    settingskey="settings.searcher.konto"
    [use-router-params]="false"
    [get-filters]="getFilters"
    [field-names-gueltigab]="['GueltigAb']"
    [field-names-gueltigbis]="['GueltigBis']"
    [default-sort-data]="[{ field: 'GueltigAb', order: 1 }]"
    [rows]="0"
    [auto-search]="true"
    [show-autofilter]="false"
    [toolbar]="true"
    [toolbar-show-searchinput]="false"
    [toolbar-show-advanced]="false"
    [card]="false"
    (onInit)="onInit($event)"
    (onSelect)="onSelect($event)"
    searcher-icon="account_balance"
    searcher-title="Bankkonten"
    [show-totalrecords]="false"
    [select-first-result]="false"
>
</avi-core-base-searcher>
