import { FileSaverService } from 'ngx-filesaver';
import { Component, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';

import { AviCommonService, AviApiService, AviBaseSearcherComponent, AviSearcherColumn, AviListDetailConst, AviJobService } from '@avi-x/avi-core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'vwbp-intern-postfach-searcher',
    templateUrl: './postfach-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPInternPostfachSearcherComponent {
    @ViewChild('postfachSearcher', { static: true })
    private postfachSearcher: AviBaseSearcherComponent;

    @ViewChild('jobSearcher', { static: true })
    private jobSearcher: AviBaseSearcherComponent;


    constructor(public ref: DynamicDialogRef, public apiService: AviApiService, public router: Router, public commonService: AviCommonService, private fileSaverService: FileSaverService, public jobService: AviJobService, private cdr: ChangeDetectorRef) {
    }

    public PostfachSearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get(`v1/intern/vwbp/document/postfach`);

        res = this.postfachSearcher.ApplyColumnFilters(res);
        res = this.postfachSearcher.ApplyColumnSorting(res);

        return res;
    }

    public JobSearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get(`v1/intern/vwbp/job/openjobs`);

        res = this.jobSearcher.ApplyColumnFilters(res);
        res = this.jobSearcher.ApplyColumnSorting(res);

        return res;
    }

    keinePostfachEintrage() {
        return this.postfachSearcher && this.postfachSearcher._initialSearchDone && (!this.postfachSearcher.TotalRecords || this.postfachSearcher.TotalRecords === 0) && (!this.postfachSearcher.ColumnFilters || this.postfachSearcher.ColumnFilters.length === 0);
    }

    keineJobEintrage() {
        return this.jobSearcher && this.jobSearcher._initialSearchDone && (!this.jobSearcher.TotalRecords || this.jobSearcher.TotalRecords === 0) && (!this.jobSearcher.ColumnFilters || this.jobSearcher.ColumnFilters.length === 0);
    }

    public GetRowClass = (row: any): string => {
        let ret = 'no-border-left no-border-right';
        if (row) {
            if (row['HasBeenRead'] !== true)
                ret += ' label-bold';
        }
        return ret;
    }

    onPostfachSearcherInit(data) {
        this.postfachSearcher.beginInit();

        this.postfachSearcher.addDateColumn('ErstellDatum', 'Datum', 'short', true, '120px').setSortable(false);
        this.postfachSearcher.addTextColumn('ErstelltVon', 'Sachbearbeiter', true).setSortable(false);
        this.postfachSearcher.addTextColumn('Name', 'Name', true).setSortable(false);
        this.postfachSearcher.addTextColumn('Dateiendung', 'Format', true).setSortable(false);
        this.postfachSearcher.addIntegerColumn('Groesse', 'Grösse', false).setSortable(false);

        this.postfachSearcher.addColumn(
            AviSearcherColumn.CreateButton('btnAct', 'Aktionen', null, null, null, null, '80px', ['download', 'deleteEntry'])
                .setButtonOnClickDelegate((row, col, buttonId) => {
                    if (buttonId === 'download')
                        this.downloadPostfachEntryDocument(row);
                    else if (buttonId === 'deleteEntry')
                        this.deletePostfachEntry(row);
                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-text': true,
                        'p-button-rounded': true,
                    };
                })
                .setButtonIconDelegate((row, col, buttonId) => {
                    if (buttonId === 'download')
                        return 'pi pi-download';
                    else if (buttonId === 'deleteEntry')
                        return 'pi pi-trash';
                })
                .setHeaderColumnClass('text-right')
                .setSortable(false)
        );

        this.postfachSearcher.endInit();
    }

    onJobSearcherInit(data) {
        this.jobSearcher.beginInit();

        this.jobSearcher.addDateColumn('ErstellDatum', 'Datum', 'short', true, '120px').setSortable(false);
        this.jobSearcher.addTextColumn('ErstellUser', 'Sachbearbeiter', true).setSortable(false);
        this.jobSearcher.addTextColumn('Name', 'Name', true).setSortable(false);
        this.jobSearcher.addTextColumn('Status', 'Status', true).setSortable(false);

        this.jobSearcher.addColumn(
            AviSearcherColumn.CreateButton('btnAct', 'Aktionen', null, null, null, null, '80px', ['resumejob', 'abortjob', 'deletejob'])
                .setTooltipDelegate((row, col, buttonId) => {
                    if (buttonId === 'resumejob')
                        return 'Job fortfahren';
                    else if (buttonId === 'abortjob')
                        return 'Job abbrechen';
                    else if (buttonId === 'deletejob')
                        return 'Job löschen';
                })
                .setButtonOnClickDelegate((row, col, buttonId) => {
                    if (buttonId === 'resumejob')
                        this.resumeJob(row);
                    else if (buttonId === 'abortjob')
                        this.abortJob(row);
                    else if (buttonId === 'deletejob')
                        this.deleteJob(row);
                })
                .setButtonClassesDelegate((row, col, buttonId) => {
                    return {
                        'w-100p': true,
                        'p-button-primary': false,
                        'p-button-secondary': true,
                        'p-button-rounded': true,
                        'p-button-text': true,
                        'hidden': !this.getShowButton(row, buttonId)
                    };
                })
                .setButtonIconDelegate((row, col, buttonId) => {
                    if (buttonId === 'resumejob')
                        return 'pi pi-play';
                    else if (buttonId === 'abortjob')
                        return 'pi pi-times';
                    else if (buttonId === 'deletejob')
                        return 'pi pi-trash';
                })
                .setHeaderColumnClass('text-right')
                .setSortable(false)
        );

        this.jobSearcher.endInit();
    }

    getShowButton(row, buttonId) {
        if (buttonId === 'resumejob')
            return (row['StatusId'] === AviListDetailConst.JOB_STATUS_FAILED || row['StatusId'] === AviListDetailConst.JOB_STATUS_CANCELED) && row['CanResume'] === true;
        else if (buttonId === 'abortjob')
            return row['StatusId'] === AviListDetailConst.JOB_STATUS_RUNNING;
        else if (buttonId === 'deletejob')
            return (row['StatusId'] === AviListDetailConst.JOB_STATUS_FAILED || row['StatusId'] === AviListDetailConst.JOB_STATUS_CANCELED);

        return false;
    }

    async resumeJob(row) {
        this.commonService.confirm({
            header: 'Job fortfahren',
            message: 'Möchten sie den Job wirklich fortfahren?', acceptLabel: 'Job fortfahren', acceptButtonStyleClass: 'p-button-primary', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: async () => {
                this.jobService.OpenJobDialog(row['Id'], row['Name'], 'v1/intern/vwbp/job', false);
                this.jobSearcher.forceRefresh();
            }
        });
    }

    async abortJob(row) {
        this.commonService.confirm({
            header: 'Job abbrechen',
            message: 'Möchten sie den Job wirklich abbrechen?', acceptLabel: 'Job abbrechen', acceptButtonStyleClass: 'p-button-primary', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: async () => {
                await this.apiService.post(`v1/intern/vwbp/job/cancel/${row['Id']}`, null);
                this.jobSearcher.forceRefresh();
            }
        });
    }

    async deleteJob(row) {
        this.commonService.confirm({
            header: 'Job löschen',
            message: 'Möchten sie den Job wirklich löschen?', acceptLabel: 'Job löschen', acceptButtonStyleClass: 'p-button-primary', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: async () => {
                await this.apiService.delete(`v1/intern/vwbp/job/${row['Id']}`, null);
                this.jobSearcher.forceRefresh();
            }
        });
    }

    async downloadPostfachEntryDocument(row) {
        if (row['Id'] && row['DocumentId'] && row['vonDocArchivDef_ID'] && row['Name']) {
            try {
                this.commonService.showGlobalLoader();
                const blob = await this.apiService.getBlob(`v1/intern/vwbp/document/Download/${row['vonDocArchivDef_ID']}/${row['DocumentId']}`);
                if (blob) {
                    this.fileSaverService.save(blob, row['Name'] + row['Dateiendung']);
                }
            } finally {
                this.commonService.hideGlobalLoader();
            }

            await this.apiService.post(`v1/intern/vwbp/document/postfach/markread/${row['Id']}`, null);

            this.postfachSearcher.forceRefresh();
        }
    }

    async deletePostfachEntry(row) {
        if (row['Id']) {
            this.commonService.confirm({
                header: 'Postfacheintrag löschen',
                message: 'Möchten sie den Postfacheintrag wirklich löschen?', acceptLabel: 'Eintrag löschen', acceptButtonStyleClass: 'p-button-primary', rejectLabel: 'Abbrechen', rejectButtonStyleClass: 'p-button-secondary p-button-outlined', accept: async () => {
                    await this.apiService.post(`v1/intern/vwbp/document/postfach/delete/${row['Id']}`, null);
                    this.postfachSearcher.forceRefresh();
                }
            });
        }
    }

    Close() {
        this.ref.close();
    }
}
