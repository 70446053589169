import { Injectable } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';
import { VwbPActionConst } from '../../constants/constants';
import { VwbPRechtstraegerLoeschenDto } from '../../dto/vwbp.uebergabe.models';
import { VwbPActionData } from '../../dto/vwbpactiondata.model';

@Injectable({
    providedIn: 'root'
})
export class VwbPPublicRechtstraegerService {
    constructor(private apiService: AviApiService) { }

    async SetInBearbeitung(rechtstraegerId: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: [rechtstraegerId],
            Action: VwbPActionConst.VWBP_ACTION_RECHTSTRAEGER_INBEARBEITUNG
        };
        return await this.apiService.postModel(VwbPActionData, 'v1/vwbp/rechtstraeger/action', data);
    }

    async InBearbeitungRueckgaengig(rechtstraegerId: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: [rechtstraegerId],
            Action: VwbPActionConst.VWBP_ACTION_RECHTSTRAEGER_INBEARBEITUNG_RUECKGAENGIG
        };
        return await this.apiService.postModel(VwbPActionData, 'v1/vwbp/rechtstraeger/action', data);
    }

    async SetErfassungAbgeschlossen(rechtstraegerId: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: [rechtstraegerId],
            Action: VwbPActionConst.VWBP_ACTION_RECHTSTRAEGER_ERFASSUNG_ABGESCHLOSSEN
        };
        return await this.apiService.postModel(VwbPActionData, 'v1/vwbp/rechtstraeger/action', data);
    }

    GetOwnerContainerData() {
        return this.apiService.get('v1/vwbp/rechtstraeger/owner/members');
    }

    AddOwnerContainerUser(containerId: string, username: string) {
        const data = {
            containerId: containerId,
            username: username
        };
        return this.apiService.post('v1/vwbp/rechtstraeger/owner/members', data);
    }

    SetOwnerContainerMaster(containerId: string, userId: string) {
        const data = {
            containerId: containerId,
            userId: userId
        };
        return this.apiService.post('v1/vwbp/rechtstraeger/owner/members/setmaster', data);
    }

    RemoveOwnerContainerUser(containerId: string, userId: string) {
        return this.apiService.delete(`v1/vwbp/rechtstraeger/owner/members/${containerId}/${userId}`);
    }

    StartDeleteRechtstraeger(model: VwbPRechtstraegerLoeschenDto) {
        const data: VwbPActionData = {
            ModelIdList: [model.Id],
            Action: VwbPActionConst.VWBP_ACTION_RECHTSTRAEGER_LOESCHEN,
            Datum: model.Loeschdatum
        };
        return this.apiService.postModel(VwbPActionData, 'v1/vwbp/rechtstraeger/action', data);
    }

    async GetGeloeschteRechtstraeger() {
        return this.apiService.get('/v1/vwbp/rechtstraeger/geloeschte-rechtstraeger');
    }

    async GetEwrRegisterRechtstraeger() {
        return this.apiService.get('/v1/vwbp/rechtstraeger/ewr-register-rechtstraeger');
    }

    async GetAngeforderteRechtstraeger() {
        return this.apiService.get('/v1/vwbp/rechtstraeger/angeforderte-rechtstraeger');
    }
}
