import {
    Component,
    OnInit,
    Inject,
    forwardRef,
    AfterViewInit
} from '@angular/core';
import { AviCommonService } from '@avi-x/avi-core';
import { AppComponent } from '../../app.component';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'avi-vwbp-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
    private _showGraphs: boolean = false;

    chartData: any;
    dateTest: any;


    constructor(
        @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
        private router: Router,
        public dialog: MatDialog,
        private commonService: AviCommonService
    ) {
        this.chartData = {
            labels: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July'
            ],
            datasets: [
                {
                    label: 'Corsin',
                    data: [65, 59, 80, 81, 56, 55, 40],
                    fill: false,
                    borderColor: '#009688',
                    backgroundColor: '#009688'
                },
                {
                    label: 'Jere',
                    data: [28, 48, 40, 19, 86, 27, 90],
                    fill: false,
                    borderColor: '#2196F3',
                    backgroundColor: '#2196F3'
                },
                {
                    label: 'Vigi',
                    data: [15, 50, 20, 23, 35, 45, 10],
                    fill: false,
                    borderColor: '#FF8C00',
                    backgroundColor: '#FF8C00'
                }
            ]
        };
    }

    ngOnInit() {
        this.dateTest = '2018-10-30 00:00:00';


    }

    public showGraphs(): boolean {
        return this._showGraphs;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._showGraphs = true;
        }, 200);
    }

    enableDebug(enabled: boolean) {
        this.commonService.DebugEnabled = enabled;
    }
}
