import { Subject } from 'rxjs';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy, ChangeDetectorRef, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AviListDetailConst, AviCommonService, AviBaseSearcherComponent, AviApiService, ObjectUtils, AviFormField, AviBaseFormComponent, AviFormFieldService, AviFormFieldType, ISessionData, AviSessionControllerService } from '@avi-x/avi-core';

import { QueryDefVM } from '@avi-x/avi-dto/reporting/querydef-vm.model';
import { FarbpaletteVM } from '@avi-x/avi-dto/system/farbpalette-vm.model';

import * as moment from 'moment-timezone';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Farbe } from '@avi-x/avi-dto/system/farbe.model';

// declare var Chart: any;

export enum ChartType {
    // eslint-disable-next-line no-unused-vars
    BAR, HORIZONTALBAR, BARSTACKED, HORIZONTALBARSTACKED, BARSTACKED_NORM, HORIZONTALBARSTACKED_NORM, LINE, AREA, PIE, DOUGHNUT
}

@Component({
    selector: 'avi-report-querydef-exec-graph',
    templateUrl: './querydef-exec-graph.component.html'
})
export class AviQueryDefExecutionGraphComponent implements OnInit, OnDestroy, OnChanges {
    @Input('session-data')
    SessionData: ISessionData;

    private isEmbedded: boolean = false;

    public set Id(value: string) {
        if (this._Id === value)
            return;
        this._Id = value;
        this.loadModel(this._Id);
    }

    public ChartType = ChartType;

    public SearcherTitle: string = null;

    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    @ViewChild('parameters', { static: false })
    private parameterForm: AviBaseFormComponent;

    public SearcherParameters: AviFormField[] = [];

    public Model: QueryDefVM = null;
    public Data: any[] = null; // = [];
    public Options: any;

    public ShowLegend = true;
    public Type: ChartType = ChartType.LINE;

    sub: any;

    @Input('card')
    public Card: boolean = true;

    @Input('collapsible')
    public Collapsible: boolean = true;

    @Input('expanded')
    public Expanded: boolean = true;

    @Input('fill-height')
    public FillHeight: boolean = false;

    @Input('showtitle')
    public ShowTitle = true;

    @Input('showtable')
    public ShowTable = false;

    @Input('graph-min-height')
    graphMinHeight = '600px';

    private _Id: string = null;

    @Input('id')
    public set InputId(value: string) {
        this.isEmbedded = true;
        this.Id = value;
    }

    public CustomColorSets: any[] = [
        {
            name: 'aviita',
            domain: ['#FF8C00', '#3E464C', '#0692bd', '#9DCA00', '#8E44AD', '#81862f', '#1ABC9C']
        }
    ];

    _colorScheme: any = null;
    parameterValueChanged: Subject<string> = new Subject<string>();

    @Output('onParameterValueChange')
    public onParameterValueChangeEmitter: EventEmitter<any> = new EventEmitter();

    public refresh() {
        if (this.Data) {
            this.Data = [...this.Data];
            this.cdr.markForCheck();
        }
    }

    public reload(): void {
        this.loadModel(this._Id);
    }

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public sessionController: AviSessionControllerService, 
        public commonService: AviCommonService, private formFieldService: AviFormFieldService, private cdr: ChangeDetectorRef) {
        this.setColorScheme('aviita');
    }

    public onInit(data) {

    }

    public getTableStyle: any = () => {
        return { 'table-layout': 'auto' };
    }

    async ngOnChanges() {
        if (this.SessionData?.ContextId && this.SessionData?.ContextId !== this.Id) {
            this.Id = this.SessionData.ContextId;
            await this.loadModel(this._Id);
            this.sessionController.setCurrentSessionTitle(this.SearcherTitle);
        }
    }

    ngOnInit() {
        if (!this.isEmbedded) {
            this.Model = new QueryDefVM();
            this.sub = this.activatedRoute.params.subscribe(params => {
                if (params && params['id']) {
                    const id = params['id'];
                    if (id && id !== '0')
                        this.Id = id;
                }
            });
        }

        this.parameterValueChanged
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                filter(changes => this.parameterForm.isFormValid()))
            .subscribe(model => { this.update(); });
    }

    async loadModel(id: string) {
        this.Model = await this.apiService.getModelById(QueryDefVM, `/querydef/cached/${id}`, null);

        if (this.Model.ChartType.Id === AviListDetailConst.CAF_CHARTTYPE_BAR)
            this.Type = this.Model.ChartRotated.Id === AviListDetailConst.NEIN ? ChartType.BAR : ChartType.HORIZONTALBAR;
        else if (this.Model.ChartType.Id === AviListDetailConst.CAF_CHARTTYPE_LINE)
            this.Type = ChartType.LINE;
        else if (this.Model.ChartType.Id === AviListDetailConst.CAF_CHARTTYPE_PIE)
            this.Type = ChartType.PIE;
        else if (this.Model.ChartType.Id === AviListDetailConst.CAF_CHARTTYPE_DOUGHNUT)
            this.Type = ChartType.DOUGHNUT;
        else if (this.Model.ChartType.Id === AviListDetailConst.CAF_CHARTTYPE_AREA)
            this.Type = ChartType.AREA;
        else if (this.Model.ChartType.Id === AviListDetailConst.CAF_CHARTTYPE_BAR_STACKED)
            this.Type = this.Model.ChartRotated.Id === AviListDetailConst.NEIN ? ChartType.BARSTACKED : ChartType.HORIZONTALBARSTACKED;
        else if (this.Model.ChartType.Id === AviListDetailConst.CAF_CHARTTYPE_BAR_FULL_STACKED)
            this.Type = this.Model.ChartRotated.Id === AviListDetailConst.NEIN ? ChartType.BARSTACKED_NORM : ChartType.HORIZONTALBARSTACKED_NORM;
        else
            throw new Error('Unknown Chart Type!');

        if (this.Model.Farbpalette_ID) {
            const farbpaletteVM = await this.apiService.getModelById(FarbpaletteVM, `/farbpalette/${this.Model.Farbpalette_ID}?init=alleFarben`, null);

            if (farbpaletteVM && farbpaletteVM.alleFarbenList.length > 0) {
                const customColorSet = [{
                    name: farbpaletteVM.Name,
                    domain: []
                }];

                const arrSorted = farbpaletteVM.alleFarbenList.sort((o1, o2) => this.compareFarbpalette(o1, o2));
                for (let index=0; index<arrSorted.length; ++index) {
                    const colorTypeValue = farbpaletteVM.alleFarbenList[index].Farbe.Value;
                    const col = colorTypeValue;

                    customColorSet.find(cs => cs.name === farbpaletteVM.Name).domain.push(this.rgba2Hex(col.R, col.G, col.B, col.A));
                }

                this.CustomColorSets = customColorSet;
                this.setColorScheme(farbpaletteVM.Name);
            }
        }

        this.SearcherParameters = [];
        const params = this.Model.SqlParameterList.sort((x, y) => (x.Row * 10 + x.Sorter) - (y.Row * 10 + y.Sorter));
        params.forEach(element => {
            const npars = params.filter(w => w.Row === element.Row).length;
            this.SearcherParameters.push(this.formFieldService.CreateFieldFromSearcherParameter(element, 12 / npars));
        });

        this.SearcherTitle = this.ShowTitle ? this.Model.Bezeichnung1 : null;

        if (this.ShowTable) {
            this.searcher.clearColumns();
            this.Model.SqlColumnList.sort((x, y) => x.Sorter - y.Sorter).filter(w => w.Sorter > 0 && !w.HiddenColumn).forEach(element => {
                if (element.DataType === 'System.DateTime')
                    this.searcher.addDateColumn(element.Name, element.Label1, 'mediumDate', true);
                else if (element.DataType === 'System.Int32')
                    this.searcher.addIntegerColumn(element.Name, element.Label1, true);
                else if (element.DataType === 'System.Decimal')
                    this.searcher.addDecimalColumn(element.Name, element.Label1, Number(element.Nachkommastellen) || 2, Number(element.Nachkommastellen) || 2, true);
                else if (element.DataType === 'System.String' && element.Listname)
                    this.searcher.addListTypeColumn(element.Name, element.Label1, element.Listname, false, true);
                else
                    this.searcher.addTextColumn(element.Name, element.Label1, true);
            });
        }

        this.doSearch();

        if (this.ShowTable)
            this.searcher.doSearch();
    }

    private compareFarbpalette(o1: Farbe, o2: Farbe): number {
        if (o1 == null && o2 == null)
            return 0;

        if (o1 == null)
            return -1;

        if (o2 == null)
            return 1;

        if (o1.Sorter > o2.Sorter)
            return 1;

        if (o2.Sorter > o1.Sorter)
            return -1;

        return 0;
    }

    private rgba2Hex(r, g, b, o) {
        const hex = (r | 1 << 8).toString(16).slice(1) + (g | 1 << 8).toString(16).slice(1) + (b | 1 << 8).toString(16).slice(1);
        const alpha = (o | 1 << 8).toString(16).slice(1);
        return '#' + hex + alpha;
    }

    public doSearch() {
        this.startSearch().catch(err => this.commonService.notificateError(err));
    }

    async startSearch(withLoadingStatus: boolean = true): Promise<any> {
        // console.log('SEARRRCCHCHHH!!!');
        return new Promise((resolve, reject) => {
            try {
//                this.loading = withLoadingStatus;

                this.SearchDelegate('')
                    .then(r => {
                        return this.handleSearchResult(r);
                    })
                    .catch(err => {
//                        this.loading = false;
                        reject(err);
                    });
            } catch (err) {
//                this.loading = false;
                reject(err);
            }
        });
    }

    private async SearchDelegate(searchValue: string): Promise<any> {
        if (!ObjectUtils.isObjectEmpty(this.Model)) {
            const params = this.SearcherParameters ? this.SearcherParameters.map(w => { return { ParamName: w.Name, Value: this._dateToString(w.Type, w.Value) }; }) : [];
            return this.apiService.post('/querydef/search', {
                'queryDef': this.Model.Id,
                'searchString': '',
                'pageNumber': 0,
                'pageSize': -1,
                'filterSettings': [],
                'sortSettings': [],
                'searchParameters': params
            });
        } else
            return Promise.resolve([]);
    }

    searchDelegateTable = async (searchValue: string): Promise<any> => {
        if (!ObjectUtils.isObjectEmpty(this.Model)) {
            const params = this.SearcherParameters ? this.SearcherParameters.map(w => { return { ParamName: w.Name, Value: this._dateToString(w.Type, w.Value) }; }) : [];

            return this.apiService.post('/querydef/search', {
                'queryDef': this.Model.Id,
                'searchString': '',
                'pageNumber': this.searcher.currentPage() || 0,
                'pageSize': this.searcher.SearcherRows,
                'filterSettings': [],
                'sortSettings': [],
                'searchParameters': params
            });
        } else
            return Promise.resolve([]);
    }

    private _dateToString(paramType: AviFormFieldType, value: any): string {
        if (paramType === AviFormFieldType.DATE)
            return moment(value).format('YYYY-MM-DD');

        return value;
    }

    private _getLabelValue(dataType: string, filterValue: string): any {
        const value = filterValue ? filterValue.trim() : filterValue;

        if (dataType === 'System.DateTime') {
            const date = new Date(value);
            if (date && date.getFullYear() > 1970)
                return moment.utc(this.commonService.getUTC00(date)).format('dd DD.MM.YYYY');
        }

        return value;
    }

    private _getLegendPosition(): string {
        if (this.Model.ChartLegendHAlignment.Id === AviListDetailConst.CAF_CHART_LEGEND_HALIGNMENT_RIGHT ||
            this.Model.ChartLegendHAlignment.Id === AviListDetailConst.CAF_CHART_LEGEND_HALIGNMENT_RIGHTOUTSIDE)
            return 'right';
        else if (this.Model.ChartLegendHAlignment.Id === AviListDetailConst.CAF_CHART_LEGEND_HALIGNMENT_LEFT ||
            this.Model.ChartLegendHAlignment.Id === AviListDetailConst.CAF_CHART_LEGEND_HALIGNMENT_LEFTOUTSIDE)
            return 'left';
        else if (this.Model.ChartLegendVAlignment.Id === AviListDetailConst.CAF_CHART_LEGEND_VALIGNMENT_BOTTOM ||
            this.Model.ChartLegendVAlignment.Id === AviListDetailConst.CAF_CHART_LEGEND_VALIGNMENT_BOTTOMOUTSIDE)
            return 'bottom';
        else
            return 'top';
    }

    private getTooltipValue(col, value) {
        if (col.DataType === 'System.Decimal' && col.Formatstring === 'hh:mm')
            return this.commonService.decimalToTimeString(value);

        return value;
    }

    public handleSearchResult(r) {
        const data = r.Results;

        this.ShowLegend = this.Model.ChartShowLegend.Id === AviListDetailConst.JA;

        const columns = this.Model.SqlColumnList
            .sort((x, y) => x.Sorter - y.Sorter)
            .filter(w => w.Sorter > 0 && !w.HiddenColumn);

        if (this.Type === ChartType.BAR || this.Type === ChartType.HORIZONTALBAR ||
            this.Type === ChartType.BARSTACKED || this.Type === ChartType.HORIZONTALBARSTACKED ||
            this.Type === ChartType.BARSTACKED_NORM || this.Type === ChartType.HORIZONTALBARSTACKED_NORM) {
            this.Data = data.map(element => {
                return {
                    'name': this._getLabelValue(columns[0].DataType, element[columns[0].Name]),
                    'series': columns.filter((col, index) => index > 0).map(col => {
                        return {
                            'name': col.Label1,
                            'value': element[col.Name],
                            'tooltip': this.getTooltipValue(col, element[col.Name])
                        };
                    })
                };
            });
        } else if (this.Type === ChartType.LINE || this.Type === ChartType.AREA) {
            this.Data = columns.filter((col, index) => index > 0).map(col => {
                return {
                    'name': col.Label1,
                    'series': data.map(element => {
                        return {
                            'name': this._getLabelValue(columns[0].DataType, element[columns[0].Name]),
                            'value': element[col.Name],
                            'tooltip': this.getTooltipValue(col, element[col.Name])
                        };
                    })
                };
            });
        } else if (this.Type === ChartType.PIE || this.Type === ChartType.DOUGHNUT) {
            this.Data = data.map(element => {
                return {
                    'name': this._getLabelValue(columns[0].DataType, element[columns[0].Name]),
                    'value': element[columns[1].Name],
                    'tooltip': this.getTooltipValue(columns[1], element[columns[1].Name])
                };
            });
        }

        this.cdr.markForCheck();
        // console.log('Data', this.Data);
    }

    public onSelect(data) {
    }

    public onDoubleClick(data) {
    }

    public get ColorScheme(): any {
        if (!this._colorScheme)
            this.setColorScheme('aviita');

        return this._colorScheme;
    }

    private update() {
        if (!ObjectUtils.isObjectEmpty(this.Model)) {
            this.doSearch();

            if (this.ShowTable)
                this.searcher.doSearch();
        }
    }

    public setSearcherParameter(paramName: string, paramValue: any) {
        this.formFieldService.setFieldValue(this.parameterForm, this.SearcherParameters, paramName, paramValue);
        if (this.searcher)
            this.searcher.setSearcherParameter(paramName, paramValue);
    }

    setColorScheme(name) {
        this._colorScheme = this.CustomColorSets.find(s => s.name === name);
    }

    public onParameterValueChange(data) {
        this.onParameterValueChangeEmitter.emit(data);
        this._parameterValueChanged(data);

        // this.update();
    }

    public _parameterValueChanged(text: any) {
        this.parameterValueChanged.next(text);
    }

    ngOnDestroy() {
        this.parameterValueChanged.unsubscribe();
    }
}
