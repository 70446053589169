import { VwbPActionConst, VwbPActionData } from '@avi-x/vwbp-public';
import { Injectable } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';

@Injectable({
    providedIn: 'root'
})
export class VwbPInternSchwaerzenService {
    constructor(private apiService: AviApiService) { }

    async SetAntragBearbeitet(antragIds: string[], entscheidung: string, entscheidAm: Date, frist: Date, dokuGenerieren: boolean, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Datum: entscheidAm,
            Frist: frist,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_BEARBEITET_SETZEN,
            Status: entscheidung,
            Bemerkung: bemerkung,
            DokuGenerieren: dokuGenerieren
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetAntragEntscheidung(antragIds: string[], entscheidung: string, entscheidAm: Date, frist: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Datum: entscheidAm,
            Frist: frist,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_ENSCHEIDUNG_ANPASSEN,
            Status: entscheidung,
            Bemerkung: bemerkung,
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetAntragRechtskraft(antragIds: string[], datum: Date, datumBis: Date, dokuGenerieren: boolean, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Datum: datum,
            DatumBis: datumBis,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_RECHTSKRAFT_SETZEN,
            Bemerkung: bemerkung,
            DokuGenerieren: dokuGenerieren
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetRechtstraegerInformiert(antragIds: string[], dokuGenerieren: boolean, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_RECHTSTRAEGER_INFORMIEREN,
            Bemerkung: bemerkung,
            DokuGenerieren: dokuGenerieren
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetAntragWBNichtMehrVorhanden(antragIds: string[], dokuGenerieren: boolean, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_WB_NICHT_MEHR_VORHANDEN,
            Bemerkung: bemerkung,
            DokuGenerieren: dokuGenerieren
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetAntragKontrolliert(antragIds: string[], datum: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_VERSION_KONTROLLIEREN,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetRechtsmittelfrist(antragIds: string[], datum: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_RECHTSMITTELFRIST_ANPASSEN,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetSchwaerzungEntscheidung(antragIds: string[], entscheidung: string, entscheidAm: Date, datumBis: Date, rechtsmittelfrist: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Status: entscheidung,
            Datum: entscheidAm,
            Frist: rechtsmittelfrist,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_ENSCHEIDUNG_ANPASSEN,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetSchwaerzungGueltigkeit(antragIds: string[], status: string, datumBis: Date, bemerkung: string, dokuGenerieren: boolean): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Datum: datumBis,
            Status: status,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_GUELTIGKEIT_ANPASSEN,
            Bemerkung: bemerkung,
            DokuGenerieren: dokuGenerieren
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetSchwaerzungVorhanden(rechtstraegerIds: string[], bemerkung: string, schwaerzungVorhanden: string): Promise<VwbPActionData> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_SET_SCHWAERZUNG_VORHANDEN,
            Status: schwaerzungVorhanden,
            Bemerkung: bemerkung
        };
        return await this.apiService.postModel(VwbPActionData, 'v1/intern/vwbp/schwaerzen/action', data);
    }

    // Pausieren Tasks

    async SetSchwaerzenAktiv(antragIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_AKTIV,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetSchwaerzenAktivMitRechtsmittelfrist(antragIds: string[], datum: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_AKTIV_MIT_RECHTSMITTELFRIST,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async SetSchwaerzenPausiert(antragIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_PAUSIERT,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    // Rückgängig machen Tasks
    async AntragRechtskraftSetzenRueckgaengig(antragIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_RECHTSKRAFT_SETZEN_RUECKGAENGIG,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }

    async RechtstraegerInformierenRueckgaengig(antragIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: antragIds,
            Action: VwbPActionConst.VWBP_ACTION_SCHWAERZEN_RECHTSTRAEGER_INFORMIEREN_RUECKGAENGIG,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/schwaerzen/action', data);
    }
}
