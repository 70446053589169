import { Expose, Type } from 'class-transformer';

import { DecisionTableDef } from '../system/decisiontabledef.model';
import { List } from '../system/list.model';
import { ModelMeta } from '../system/modelmeta.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class DecisionTableParamDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get Sequenz(): number { return this.getProperty('Sequenz'); }
    public set Sequenz(value: number) { this.setProperty('Sequenz', value); }
    
    @Expose()
    public get PropertyName(): string { return this.getProperty('PropertyName'); }
    public set PropertyName(value: string) { this.setProperty('PropertyName', value); }
    
    @Expose()
    public get vonDecisionTableDef_ID(): string { return this.getModelId('vonDecisionTableDef_ID'); }
    public set vonDecisionTableDef_ID(value: string) { this.setModelId('vonDecisionTableDef_ID', value); }
 
    @Expose()
    @Type(() => DecisionTableDef)
    public get vonDecisionTableDef(): DecisionTableDef { return this.getModel('vonDecisionTableDef'); }
    public set vonDecisionTableDef(value: DecisionTableDef) { this.setModel('vonDecisionTableDef', value); }
    
    @Expose()
    public get List_ID(): string { return this.getModelId('List_ID'); }
    public set List_ID(value: string) { this.setModelId('List_ID', value); }
 
    @Expose()
    @Type(() => List)
    public get List(): List { return this.getModel('List'); }
    public set List(value: List) { this.setModel('List', value); }
    
    @Expose()
    public get Model_ID(): string { return this.getModelId('Model_ID'); }
    public set Model_ID(value: string) { this.setModelId('Model_ID', value); }
 
    @Expose()
    @Type(() => ModelMeta)
    public get Model(): ModelMeta { return this.getModel('Model'); }
    public set Model(value: ModelMeta) { this.setModel('Model', value); }
    
    @Expose()
    @Type(() => ListType)
    public get ParamArt(): ListType { return this.getProperty('ParamArt'); }
    public set ParamArt(value: ListType) { this.setProperty('ParamArt', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AttributeType(): ListType { return this.getProperty('AttributeType'); }
    public set AttributeType(value: ListType) { this.setProperty('AttributeType', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VergleichsOperator(): ListType { return this.getProperty('VergleichsOperator'); }
    public set VergleichsOperator(value: ListType) { this.setProperty('VergleichsOperator', value); }
    
}
