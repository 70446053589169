import { Expose } from 'class-transformer';
import { IDTO } from '../shared';

export class ChangePasswordDto extends IDTO {
    @Expose()
    public OldPassword: string;

    @Expose()
    public NewPassword: string;
}
