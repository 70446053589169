import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

export enum RouterEventType {
    Imperative,
    Popstate
}

@Injectable({
    providedIn: 'root'
})
export class AviRouterHistoryService {
    private routerHistory: string[] = [];
    private lastEvent: RouterEventType;

    constructor(router: Router) {

        router.events
            .pipe(
                filter(w => w instanceof NavigationStart)
            )
            .subscribe(
                (event: NavigationStart) => {
                    if (event.restoredState) {
                        this.routerHistory.pop();
                        this.lastEvent = RouterEventType.Popstate;
                    } else {
                        this.routerHistory.push(event.url);
                        this.lastEvent = RouterEventType.Imperative;
                    }
                }
            );
    }

    public canNavigateBack(): boolean {
        return this.routerHistory.length > 1;
    }

    public getLastRouterEventType(): RouterEventType {
        return this.lastEvent;
    }

    public getCurrentRoute(): string {
        return this.routerHistory[this.routerHistory.length - 1];
    }
}
