import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { AppUser } from '@avi-x/avi-dto/system/appuser.model';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: AppUser) => any)) => typed_nameof<AppUser>(nameFunction);

@Component({
    selector: 'avi-appuser-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAppUserFormComponent extends AviAbstractModelFormComponent<AppUser> {  
    ClassName = 'CORE.COMMON.MODEL.APPUSER';
    BaseUrl = 'appuser';
    ClassType = AppUser;
	FormType = AviAppUserFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'AppUser';
    }
	
    initFields() {
		if (this.ReadOnly)
			this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AppUserTyp), 'CORE.COMMON.MODEL.APPUSER.APPUSERTYP', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'CORE.COMMON.MODEL.APPUSER.GUELTIGAB', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'CORE.COMMON.MODEL.APPUSER.GUELTIGBIS', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.System), 'CORE.COMMON.MODEL.APPUSER.SYSTEM', 0, true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Username), 'CORE.COMMON.MODEL.APPUSER.USERNAME', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Passwort), 'CORE.COMMON.MODEL.APPUSER.PASSWORT', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Kurzz), 'CORE.COMMON.MODEL.APPUSER.KURZZ', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'CORE.COMMON.MODEL.APPUSER.NAME', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Vorname), 'CORE.COMMON.MODEL.APPUSER.VORNAME', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Email), 'CORE.COMMON.MODEL.APPUSER.EMAIL', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.ExterneReferenz), 'CORE.COMMON.MODEL.APPUSER.EXTERNEREFERENZ', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.AdminUser), 'CORE.COMMON.MODEL.APPUSER.ADMINUSER', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.SupportUser), 'CORE.COMMON.MODEL.APPUSER.SUPPORTUSER', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.StrongPassword), 'CORE.COMMON.MODEL.APPUSER.STRONGPASSWORD', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.PasswdExpirationMonths), 'CORE.COMMON.MODEL.APPUSER.PASSWDEXPIRATIONMONTHS', 0, false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.NextPwdExpiration), 'CORE.COMMON.MODEL.APPUSER.NEXTPWDEXPIRATION', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Authentizierung), 'CORE.COMMON.MODEL.APPUSER.AUTHENTIZIERUNG', 'caf_authentication', true, true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Domain), 'CORE.COMMON.MODEL.APPUSER.DOMAIN', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.AcitivityReminderDisabled), 'CORE.COMMON.MODEL.APPUSER.ACITIVITYREMINDERDISABLED', '', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Sprache_ID), 'CORE.COMMON.MODEL.APPUSER.SPRACHE', `model/representations/Sprache${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Partner_ID), 'CORE.COMMON.MODEL.APPUSER.PARTNER', `model/representations/CAFBasePartner${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DsgvoAktiv), 'CORE.COMMON.MODEL.APPUSER.DSGVOAKTIV', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DsgvoBeauftragter), 'CORE.COMMON.MODEL.APPUSER.DSGVOBEAUFTRAGTER', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
    }
}
