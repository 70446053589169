import { Expose, Type } from 'class-transformer';

import { ScriptRulesetDef } from '../system/scriptrulesetdef.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import TransformDate from '../shared/transformdate';

export class ScriptRuleDef extends BaseModel {  
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get Precondition(): string { return this.getProperty('Precondition'); }
    public set Precondition(value: string) { this.setProperty('Precondition', value); }
    
    @Expose()
    public get Expression(): string { return this.getProperty('Expression'); }
    public set Expression(value: string) { this.setProperty('Expression', value); }
    
    @Expose()
    public get RuleID(): number { return this.getProperty('RuleID'); }
    public set RuleID(value: number) { this.setProperty('RuleID', value); }
    
    @Expose()
    public get vonRulesetDef_ID(): string { return this.getModelId('vonRulesetDef_ID'); }
    public set vonRulesetDef_ID(value: string) { this.setModelId('vonRulesetDef_ID', value); }
 
    @Expose()
    @Type(() => ScriptRulesetDef)
    public get vonRulesetDef(): ScriptRulesetDef { return this.getModel('vonRulesetDef'); }
    public set vonRulesetDef(value: ScriptRulesetDef) { this.setModel('vonRulesetDef', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Assignment1(): ListType { return this.getProperty('Assignment1'); }
    public set Assignment1(value: ListType) { this.setProperty('Assignment1', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Assignment2(): ListType { return this.getProperty('Assignment2'); }
    public set Assignment2(value: ListType) { this.setProperty('Assignment2', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AutoClose(): ListType { return this.getProperty('AutoClose'); }
    public set AutoClose(value: ListType) { this.setProperty('AutoClose', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AutoCloseSameRule(): ListType { return this.getProperty('AutoCloseSameRule'); }
    public set AutoCloseSameRule(value: ListType) { this.setProperty('AutoCloseSameRule', value); }

    @Expose()
    @TransformDate()
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }
    
    @Expose()
    @TransformDate()
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }    
}
