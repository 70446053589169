import { Expose, Type } from 'class-transformer';

import { ModelMeta } from '../system/modelmeta.model';
import { List } from '../system/list.model';
import { AviDocKategorie } from '../avidoc_def/avidockategorie.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class AviDocKeywordDef extends BaseModel {
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }

    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }

    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }

    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }

    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }

    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }

    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }

    @Expose()
    public get SortOrder(): number { return this.getProperty('SortOrder'); }
    public set SortOrder(value: number) { this.setProperty('SortOrder', value); }

    @Expose()
    public get FeldName(): string { return this.getProperty('FeldName'); }
    public set FeldName(value: string) { this.setProperty('FeldName', value); }

    @Expose()
    public get FeldNr(): number { return this.getProperty('FeldNr'); }
    public set FeldNr(value: number) { this.setProperty('FeldNr', value); }

    @Expose()
    public get Prefix(): string { return this.getProperty('Prefix'); }
    public set Prefix(value: string) { this.setProperty('Prefix', value); }

    @Expose()
    public get MaxLength(): number { return this.getProperty('MaxLength'); }
    public set MaxLength(value: number) { this.setProperty('MaxLength', value); }

    @Expose()
    public get Fachschluessel_PathToObject(): string { return this.getProperty('Fachschluessel_PathToObject'); }
    public set Fachschluessel_PathToObject(value: string) { this.setProperty('Fachschluessel_PathToObject', value); }

    @Expose()
    public get Fachschluessel_AttrName(): string { return this.getProperty('Fachschluessel_AttrName'); }
    public set Fachschluessel_AttrName(value: string) { this.setProperty('Fachschluessel_AttrName', value); }

    @Expose()
    public get DefaultValue(): string { return this.getProperty('DefaultValue'); }
    public set DefaultValue(value: string) { this.setProperty('DefaultValue', value); }

    @Expose()
    public get Fachschluessel_ModelMeta_ID(): string { return this.getModelId('Fachschluessel_ModelMeta_ID'); }
    public set Fachschluessel_ModelMeta_ID(value: string) { this.setModelId('Fachschluessel_ModelMeta_ID', value); }

    @Expose()
    @Type(() => ModelMeta)
    public get Fachschluessel_ModelMeta(): ModelMeta { return this.getModel('Fachschluessel_ModelMeta'); }
    public set Fachschluessel_ModelMeta(value: ModelMeta) { this.setModel('Fachschluessel_ModelMeta', value); }

    @Expose()
    public get vonList_ID(): string { return this.getModelId('vonList_ID'); }
    public set vonList_ID(value: string) { this.setModelId('vonList_ID', value); }

    @Expose()
    @Type(() => List)
    public get vonList(): List { return this.getModel('vonList'); }
    public set vonList(value: List) { this.setModel('vonList', value); }

    @Expose()
    public get vonModelMeta_ID(): string { return this.getModelId('vonModelMeta_ID'); }
    public set vonModelMeta_ID(value: string) { this.setModelId('vonModelMeta_ID', value); }

    @Expose()
    @Type(() => ModelMeta)
    public get vonModelMeta(): ModelMeta { return this.getModel('vonModelMeta'); }
    public set vonModelMeta(value: ModelMeta) { this.setModel('vonModelMeta', value); }

    @Expose()
    public get vonDocKategorie_ID(): string { return this.getModelId('vonDocKategorie_ID'); }
    public set vonDocKategorie_ID(value: string) { this.setModelId('vonDocKategorie_ID', value); }

    @Expose()
    @Type(() => AviDocKategorie)
    public get vonDocKategorie(): AviDocKategorie { return this.getModel('vonDocKategorie'); }
    public set vonDocKategorie(value: AviDocKategorie) { this.setModel('vonDocKategorie', value); }

    @Expose()
    @Type(() => ListType)
    public get Keywordtype(): ListType { return this.getProperty('Keywordtype'); }
    public set Keywordtype(value: ListType) { this.setProperty('Keywordtype', value); }

    @Expose()
    @Type(() => ListType)
    public get IstFachschluessel(): ListType { return this.getProperty('IstFachschluessel'); }
    public set IstFachschluessel(value: ListType) { this.setProperty('IstFachschluessel', value); }

    @Expose()
    @Type(() => ListType)
    public get ManuellAenderbar(): ListType { return this.getProperty('ManuellAenderbar'); }
    public set ManuellAenderbar(value: ListType) { this.setProperty('ManuellAenderbar', value); }

    @Expose()
    @Type(() => ListType)
    public get Zwingend(): ListType { return this.getProperty('Zwingend'); }
    public set Zwingend(value: ListType) { this.setProperty('Zwingend', value); }

    // transient
    @Expose()
    public get Fachschluessel_ModelMeta_ModelName(): string { return this.getModelId('Fachschluessel_ModelMeta_ModelName'); }
    public set Fachschluessel_ModelMeta_ModelName(value: string) { this.setModelId('Fachschluessel_ModelMeta_ModelName', value); }

    @Expose()
    public get vonListName(): string { return this.getModelId('vonListName'); }
    public set vonListName(value: string) { this.setModelId('vonListName', value); }

}
