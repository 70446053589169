import { Expose, Type } from 'class-transformer';
import TransformDate from '@avi-x/avi-dto/shared/transformdate';

import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';

export class VwbPFormularDaten extends BaseModel {
    @Expose()
    public get vonVersion_ID(): string { return this.getModelId('vonVersion_ID'); }
    public set vonVersion_ID(value: string) { this.setModelId('vonVersion_ID', value); }

    @Expose()
    @Type(() => ListType)
    public get SchwaerzenStatus(): ListType { return this.getProperty('SchwaerzenStatus'); }
    public set SchwaerzenStatus(value: ListType) { this.setProperty('SchwaerzenStatus', value); }

    @Expose()
    @Type(() => ListType)
    public get WBPartnerTyp(): ListType { return this.getProperty('WBPartnerTyp'); }
    public set WBPartnerTyp(value: ListType) { this.setProperty('WBPartnerTyp', value); }

    @Expose()
    @Type(() => ListType)
    public get FormularTyp(): ListType { return this.getProperty('FormularTyp'); }
    public set FormularTyp(value: ListType) { this.setProperty('FormularTyp', value); }

    @Expose()
    public get WBC_WB(): boolean { return this.getProperty('WBC_WB'); }
    public set WBC_WB(value: boolean) { this.setProperty('WBC_WB', value); }

    @Expose()
    public get WBC_KONT(): boolean { return this.getProperty('WBC_KONT'); }
    public set WBC_KONT(value: boolean) { this.setProperty('WBC_KONT', value); }

    @Expose()
    public get WBC_SMO(): boolean { return this.getProperty('WBC_SMO'); }
    public set WBC_SMO(value: boolean) { this.setProperty('WBC_SMO', value); }

    @Expose()
    public get WBT_SETTL(): boolean { return this.getProperty('WBT_SETTL'); }
    public set WBT_SETTL(value: boolean) { this.setProperty('WBT_SETTL', value); }

    @Expose()
    public get WBT_SETTL_VS(): boolean { return this.getProperty('WBT_SETTL_VS'); }
    public set WBT_SETTL_VS(value: boolean) { this.setProperty('WBT_SETTL_VS', value); }

    @Expose()
    public get WBT_ORG(): boolean { return this.getProperty('WBT_ORG'); }
    public set WBT_ORG(value: boolean) { this.setProperty('WBT_ORG', value); }

    @Expose()
    public get WBT_BEG(): boolean { return this.getProperty('WBT_BEG'); }
    public set WBT_BEG(value: boolean) { this.setProperty('WBT_BEG', value); }

    @Expose()
    public get WBT_PRO(): boolean { return this.getProperty('WBT_PRO'); }
    public set WBT_PRO(value: boolean) { this.setProperty('WBT_PRO', value); }

    @Expose()
    public get WBT_KONT(): boolean { return this.getProperty('WBT_KONT'); }
    public set WBT_KONT(value: boolean) { this.setProperty('WBT_KONT', value); }

    @Expose()
    public get WBT_KDB(): boolean { return this.getProperty('WBT_KDB'); }
    public set WBT_KDB(value: boolean) { this.setProperty('WBT_KDB', value); }

    // Nur VwEG
    @Expose()
    public get WBT_SMO(): boolean { return this.getProperty('WBT_SMO'); }
    public set WBT_SMO(value: boolean) { this.setProperty('WBT_SMO', value); }

    // Nur VwEG
    @Expose()
    public get KontrolleBeschreibung(): string { return this.getModelId('KontrolleBeschreibung'); }
    public set KontrolleBeschreibung(value: string) { this.setModelId('KontrolleBeschreibung', value); }

    @Expose()
    public get KDB(): string { return this.getModelId('KDB'); }
    public set KDB(value: string) { this.setModelId('KDB', value); }

    @Expose()
    public get BemerkungTeilfonds(): string { return this.getModelId('BemerkungTeilfonds'); }
    public set BemerkungTeilfonds(value: string) { this.setModelId('BemerkungTeilfonds', value) }

    @Expose()
    public get Name(): string { return this.getModelId('Name'); }
    public set Name(value: string) { this.setModelId('Name', value); }

    @Expose()
    public get Land(): string { return this.getModelId('Land'); }
    public set Land(value: string) { this.setModelId('Land', value); }

    @Expose()
    public get Titel(): string { return this.getModelId('Titel'); }
    public set Titel(value: string) { this.setModelId('Titel', value); }

    @Expose()
    public get Vorname(): string { return this.getModelId('Vorname'); }
    public set Vorname(value: string) { this.setModelId('Vorname', value); }

    @Expose()
    public get Nationalitaet1(): string { return this.getModelId('Nationalitaet1'); }
    public set Nationalitaet1(value: string) { this.setModelId('Nationalitaet1', value); }

    @Expose()
    public get Nationalitaet2(): string { return this.getModelId('Nationalitaet2'); }
    public set Nationalitaet2(value: string) { this.setModelId('Nationalitaet2', value); }

    @Expose()
    public get Nationalitaet3(): string { return this.getModelId('Nationalitaet3'); }
    public set Nationalitaet3(value: string) { this.setModelId('Nationalitaet3', value); }

    @Expose()
    public get Nationalitaet4(): string { return this.getModelId('Nationalitaet4'); }
    public set Nationalitaet4(value: string) { this.setModelId('Nationalitaet4', value); }

    @Expose()
    @TransformDate()
    public get Geburtsdatum(): Date { return this.getProperty('Geburtsdatum'); }
    public set Geburtsdatum(value: Date) { this.setProperty('Geburtsdatum', value); }

    @Expose()
    @TransformDate()
    public get Gruendungsdatum(): Date { return this.getProperty('Gruendungsdatum'); }
    public set Gruendungsdatum(value: Date) { this.setProperty('Gruendungsdatum', value); }

    @Expose()
    public get Rechtsform(): string { return this.getModelId('Rechtsform'); }
    public set Rechtsform(value: string) { this.setModelId('Rechtsform', value); }

    @Expose()
    public get RegisterNummer(): string { return this.getProperty('RegisterNummer'); }
    public set RegisterNummer(value: string) { this.setProperty('RegisterNummer', value); }

    @Expose()
    @TransformDate()
    public get RegisterDatum(): Date { return this.getProperty('RegisterDatum'); }
    public set RegisterDatum(value: Date) { this.setProperty('RegisterDatum', value); }

    @Expose()
    public get RegisterOrt(): string { return this.getProperty('RegisterOrt'); }
    public set RegisterOrt(value: string) { this.setProperty('RegisterOrt', value); }

    @Expose()
    public get RegisterLand(): string { return this.getProperty('RegisterLand'); }
    public set RegisterLand(value: string) { this.setProperty('RegisterLand', value); }

    @Expose()
    public get RechtstraegerName(): string { return this.getProperty('RechtstraegerName'); }
    public set RechtstraegerName(value: string) { this.setProperty('RechtstraegerName', value); }

    @Expose()
    public get RechtstraegerSitz(): string { return this.getProperty('RechtstraegerSitz'); }
    public set RechtstraegerSitz(value: string) { this.setProperty('RechtstraegerSitz', value); }

    // Zustelladresse
    @Expose()
    public get RTZustellAdresseCO(): string { return this.getModelId('RTZustellAdresseCO'); }
    public set RTZustellAdresseCO(value: string) { this.setModelId('RTZustellAdresseCO', value); }

    @Expose()
    public get RTZustellAdresseStrasse(): string { return this.getModelId('RTZustellAdresseStrasse'); }
    public set RTZustellAdresseStrasse(value: string) { this.setModelId('RTZustellAdresseStrasse', value); }

    @Expose()
    public get RTZustellAdressePostfach(): string { return this.getModelId('RTZustellAdressePostfach'); }
    public set RTZustellAdressePostfach(value: string) { this.setModelId('RTZustellAdressePostfach', value); }

    @Expose()
    public get RTZustellAdressePLZ(): string { return this.getModelId('RTZustellAdressePLZ'); }
    public set RTZustellAdressePLZ(value: string) { this.setModelId('RTZustellAdressePLZ', value); }

    @Expose()
    public get RTZustellAdresseOrt(): string { return this.getModelId('RTZustellAdresseOrt'); }
    public set RTZustellAdresseOrt(value: string) { this.setModelId('RTZustellAdresseOrt', value); }

    @Expose()
    public get RTZustellAdresseLand(): string { return this.getModelId('RTZustellAdresseLand'); }
    public set RTZustellAdresseLand(value: string) { this.setModelId('RTZustellAdresseLand', value); }

    // HELPER ATTRIBS
    // Inländische RT
    public get InlandRechtstraegerName(): string { return this.getProperty('RechtstraegerName'); }
    public set InlandRechtstraegerName(value: string) { this.setProperty('RechtstraegerName', value); }

    public get InlandRegisterNummer(): string { return this.getProperty('RegisterNummer'); }
    public set InlandRegisterNummer(value: string) { this.setProperty('RegisterNummer', value); }

    public get InlandRechtsform(): string { return this.getModelId('Rechtsform'); }
    public set InlandRechtsform(value: string) { this.setModelId('Rechtsform', value); }

    public get InlandRechtstraegerSitz(): string { return this.getProperty('RechtstraegerSitz'); }
    public set InlandRechtstraegerSitz(value: string) { this.setProperty('RechtstraegerSitz', value); }

    // Ausländische RT
    public get AuslandRechtstraegerName(): string { return this.getProperty('RechtstraegerName'); }
    public set AuslandRechtstraegerName(value: string) { this.setProperty('RechtstraegerName', value); }

    public get AuslandRechtsform(): string { return this.getModelId('Rechtsform'); }
    public set AuslandRechtsform(value: string) { this.setModelId('Rechtsform', value); }

    public get AuslandRechtstraegerSitz(): string { return this.getProperty('RechtstraegerSitz'); }
    public set AuslandRechtstraegerSitz(value: string) { this.setProperty('RechtstraegerSitz', value); }

    public get AuslandGruendungsdatum(): Date { return this.getProperty('Gruendungsdatum'); }
    public set AuslandGruendungsdatum(value: Date) { this.setProperty('Gruendungsdatum', value); }

    public get AuslandRegisterNummer(): string { return this.getProperty('RegisterNummer'); }
    public set AuslandRegisterNummer(value: string) { this.setProperty('RegisterNummer', value); }

    public get AuslandRegisterDatum(): Date { return this.getProperty('RegisterDatum'); }
    public set AuslandRegisterDatum(value: Date) { this.setProperty('RegisterDatum', value); }

    public get AuslandRegisterOrt(): string { return this.getProperty('RegisterOrt'); }
    public set AuslandRegisterOrt(value: string) { this.setProperty('RegisterOrt', value); }

    public get AuslandRegisterLand(): string { return this.getProperty('RegisterLand'); }
    public set AuslandRegisterLand(value: string) { this.setProperty('RegisterLand', value); }

    // Transient
    public get Rollen(): string { return this.getProperty('Rollen'); }
    public set Rollen(value: string) { this.setProperty('Rollen', value); }
}
