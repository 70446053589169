import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviAuthService, AviBaseFormComponent, ObjectUtils, AviFormFieldService, typed_nameof, AviDialogComponent } from '@avi-x/avi-core';
import { VwbPListDetailConst } from '../../../constants/constants';
import { VwbPFormularDaten } from '../../../dto/vwbpformulardaten.model';
import { VwbPRechtstraegerDetail } from '../../../dto/vwbprechtstraegerdetail.model';
import { VwbPPublicRechtstraegerImportierenFormComponent } from '../../../rechtstraeger/components/rechtstraeger-importieren-form/rechtstraeger-importieren-form.component';
import { version } from 'process';

const nameof = (nameFunction: ((obj: VwbPFormularDaten) => any)) => typed_nameof<VwbPFormularDaten>(nameFunction);

const WBC_WB = 1;
const WBC_KONT = 2;
const WBC_SMO = 3;
const WBT_SETTL = 4;
const WBT_SETTL_VS = 5;
const WBT_ORG = 6;
const WBT_PRO = 7;
const WBT_BEG = 8;
const WBT_KONT = 9;
const WBT_KDB = 10;
const WBJP = 11;
const WBJP_HINWEIS = 12;
const WBJP_TYPAUSWAHL = 13;

@Component({
    selector: 'vwbp-public-formular-form',
    templateUrl: './formular-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPPublicFormularFormComponent implements OnInit, OnDestroy {
    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('rechtstraegerLIForm', { static: false })
    rechtstraegerLIForm: VwbPPublicRechtstraegerImportierenFormComponent;

    @ViewChild('rechtstraegerFormDialog', { static: false })
    rechtstraegerFormDialog: AviDialogComponent;

    isEditMode: boolean = false;
    private teilfondsEnabled: boolean = false;
    private rtHRStatus: string = null;

    public FormTitle: string;

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('action-buttons-readonly')
    actionButtonsReadonly: any[] = [];

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    public Loading: boolean = false;
    public Model: VwbPFormularDaten = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string {
        return this._ModelId;
    }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    @Input('api')
    public Api: string = 'v1/vwbp';

    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => this.closeForm() }];

    natPersonMode: boolean = true;

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string, formTyp: string = null, detailId: string = null): Promise<boolean> {
        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (!this.ReadOnly)
            this.Form?.clearFormMessages();

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);
            this.isEditMode = true;
        } else {
            this.natPersonMode = true;
            await this.initNewModel(formTyp, detailId);
        }

        this.cdr.detectChanges();

        if (this.isEditMode) {
            if (this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_JURPERSON_LI_HRN)
                setTimeout(() => this.Form.focusField(nameof(c => c.InlandRechtstraegerName), false));
            else if (this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_JURPERSON_AUSLAENDISCH)
                setTimeout(() => this.Form.focusField(nameof(c => c.AuslandRechtstraegerName), false));
            else if (this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_KDB)
                setTimeout(() => this.Form.focusField(nameof(c => c.KDB), false));
            else if (!this.Model.WBT_SETTL_VS)
                setTimeout(() => this.Form.focusField(nameof(c => c.Titel), false));
            else if (this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_C)
                setTimeout(() => this.Form.focusField('WBC_WB', false));
            else if (this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_T)
                setTimeout(() => this.Form.focusField('WBT_SETTL', false));
        } else {
            if (this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_C)
                setTimeout(() => this.Form.focusField('WBC_WB', false));
            else if (this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_T)
                setTimeout(() => this.Form.focusField('WBT_SETTL', false));
        }

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    private setPartnerDatenVisibility() {
        let wbVisibile: boolean = true;

        if (this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_C)
            wbVisibile = this.Model.WBC_WB || this.Model.WBC_KONT || this.Model.WBC_SMO;
        else
            wbVisibile = this.Model.WBT_SETTL || this.Model.WBT_ORG || this.Model.WBT_PRO || this.Model.WBT_BEG || this.Model.WBT_KONT;

        const anyChecked = this.Model.WBT_SETTL || this.Model.WBT_ORG || this.Model.WBT_PRO || this.Model.WBT_BEG || this.Model.WBT_KONT || this.Model.WBT_SETTL_VS || this.Model.WBT_KDB;

        const formT = this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_T;

        const npVisible = wbVisibile && this.natPersonMode;
        const jpAusland = wbVisibile && !this.natPersonMode && this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_JURPERSON_AUSLAENDISCH;
        const jpInland = wbVisibile && !this.natPersonMode && this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_JURPERSON_LI_HRN;
        const ausland = this.rtHRStatus === VwbPListDetailConst.VWBP_RT_HRSTATUS_NICHT_IM_REGISTER;

        // auf abgeschlossenen Versionen soll die Bemerkung angezeigt werden, falls vorhanden
        const teilfonds = (!ausland && this.natPersonMode && this.teilfondsEnabled && (this.Model.WBC_WB || this.Model.WBC_KONT || this.Model.WBC_SMO || this.Model.WBT_SETTL || this.Model.WBT_ORG || this.Model.WBT_PRO || this.Model.WBT_BEG || this.Model.WBT_KONT))
            || (!ausland && this.natPersonMode && !this.teilfondsEnabled && this.Model.BemerkungTeilfonds !== null && this.Model.BemerkungTeilfonds !== undefined && this.Model.BemerkungTeilfonds !== '');

        this.formFieldService.setFieldVisible(this.Fields, 'lbl4', teilfonds);
        this.formFieldService.setFieldVisible(this.Fields, 'lbl5', teilfonds);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.BemerkungTeilfonds), teilfonds);
        this.formFieldService.setFieldLabel(this.Fields, 'lbl3', this.commonService.translateInstant('VWBP.PUBLIC.FORM.DATEN_WB', { nr: teilfonds ? 'III' : 'II' }));
        
        this.formFieldService.setFieldVisible(this.Fields, 'lbl3', wbVisibile);

        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBJP), formT && !anyChecked && !this.isEditMode);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBJP_HINWEIS), formT && this.Model.WBT_BEG && !this.natPersonMode);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBJP_TYPAUSWAHL), formT && anyChecked && !this.natPersonMode && this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.KDB), formT && this.Model.WBT_KDB);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.KDB), formT && this.Model.WBT_KDB);

        // WB = Natürliche Person
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Vorname), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Titel), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, 'titel_filler', npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Name), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Geburtsdatum), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Land), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet1), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet2), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet3), npVisible);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.Nationalitaet4), npVisible);

        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Vorname), npVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Name), npVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Geburtsdatum), npVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Land), npVisible);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.Nationalitaet1), npVisible);

        // WB = Inländischer RT
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.InlandRechtstraegerName), jpInland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.InlandRegisterNummer), jpInland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.InlandRechtsform), jpInland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.InlandRechtstraegerSitz), jpInland);

        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.InlandRechtstraegerName), jpInland);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.InlandRegisterNummer), jpInland);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.InlandRechtsform), jpInland);

        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.InlandRechtstraegerName), jpInland);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.InlandRechtstraegerSitz), jpInland);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.InlandRegisterNummer), jpInland);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.InlandRechtsform), jpInland);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdresseCO), jpInland);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdresseStrasse), jpInland);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdressePostfach), jpInland);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdressePLZ), jpInland);
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.RTZustellAdresseOrt), jpInland);

        // WE = Ausländischer RT
        this.formFieldService.setFieldVisible(this.Fields, 'lblAuslandEintrag', jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, 'lblAuslandAdresse', jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRechtstraegerName), jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRechtsform), jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRechtstraegerSitz), jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandGruendungsdatum), jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRegisterLand), jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRegisterOrt), jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRegisterNummer), jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AuslandRegisterDatum), jpAusland);

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdresseLand), jpAusland);

        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.AuslandRechtstraegerName), jpAusland);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.AuslandRechtsform), jpAusland);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.AuslandGruendungsdatum), jpAusland);

        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.RTZustellAdresseStrasse), jpAusland);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.RTZustellAdressePostfach), jpAusland);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.RTZustellAdressePLZ), jpAusland);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.RTZustellAdresseOrt), jpAusland);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.RTZustellAdresseLand), jpAusland);

        // Adresse
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdresseCO), jpInland || jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdresseStrasse), jpInland || jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdressePostfach), jpInland || jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdressePLZ), jpInland || jpAusland);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.RTZustellAdresseOrt), jpInland || jpAusland);
    }

    private clearPartnerDaten() {
        
        this.Model.BemerkungTeilfonds = null;
        
        this.Model.Titel = null;
        this.Model.Vorname = null;
        this.Model.Name = null;
        this.Model.Geburtsdatum = null;
        this.Model.Land = null;
        this.Model.Nationalitaet1 = null;
        this.Model.Nationalitaet2 = null;
        this.Model.Nationalitaet3 = null;
        this.Model.Nationalitaet4 = null;
        this.Model['Land_Autocomplete'] = null;
        this.Model['Nationalitaet1_Autocomplete'] = null;
        this.Model['Nationalitaet2_Autocomplete'] = null;
        this.Model['Nationalitaet3_Autocomplete'] = null;
        this.Model['Nationalitaet4_Autocomplete'] = null;
    }

    getTemplateName(num: number): string {
        return `formFieldTemplate${num}`;
    }

    private setFromFormType() {
        const formC = this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_C;
        const formT = this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_T;

        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBC_WB), formC);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBC_KONT), formC);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBC_SMO), formC);

        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBT_SETTL), formT && this.natPersonMode);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBT_SETTL_VS), formT && this.natPersonMode);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBT_ORG), formT);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBT_PRO), formT && this.natPersonMode);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBT_BEG), formT);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBT_KONT), formT && this.natPersonMode);
        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBT_KDB), formT && this.natPersonMode);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.KDB), formT && this.Model.WBT_KDB);

        this.formFieldService.setFieldVisible(this.Fields, this.getTemplateName(WBJP), formT);

        if (formC) {
            if (this.ReadOnly)
                this.FormTitle = 'VWBP.PUBLIC.FORM.C_ANZEIGEN';
            else
                this.FormTitle = this.isEditMode ? 'VWBP.PUBLIC.FORM.C_EDITIEREN' : 'VWBP.PUBLIC.FORM.C_ERFASSEN';

            this.formFieldService.setFieldLabel(this.Fields, 'lblTitle', 'VWBP.PUBLIC.FORM.C_TITLE');
        } else {
            if (this.ReadOnly)
                this.FormTitle = 'VWBP.PUBLIC.FORM.T_ANZEIGEN';
            else
                this.FormTitle = this.isEditMode ? 'VWBP.PUBLIC.FORM.T_EDITIEREN' : 'VWBP.PUBLIC.FORM.T_ERFASSEN';

            this.formFieldService.setFieldLabel(this.Fields, 'lblTitle', 'VWBP.PUBLIC.FORM.T_TITLE');
        }
    }

    async onAttrChange(data: AviChangedAttr) {
        const formC = this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_C;

        if (formC) {
            if (data.field === nameof(c => c.WBC_WB) || data.field === nameof(c => c.WBC_KONT) || data.field === nameof(c => c.WBC_SMO))
                this.setPartnerDatenVisibility();

            // WBC_SMO gewählt -> WBC_WB/WBC_KONT entfernen (Ausnahme: Teilfonds aktiviert -> dann dürfen alle Rollen gleichzeitig ausgewählt werden)
            if (!this.teilfondsEnabled && data.field === nameof(c => c.WBC_SMO) && this.Model.WBC_SMO) {
                if (this.Model.WBC_WB)
                    this.Model.WBC_WB = null;
                if (this.Model.WBC_KONT)
                    this.Model.WBC_KONT = null;
            }

            // WBC_WB/WBC_KONT gewählt -> WBC_SMO entfernen (Ausnahme: Teilfonds aktiviert -> dann dürfen alle Rollen gleichzeitig ausgewählt werden)
            if (!this.teilfondsEnabled && ((data.field === nameof(c => c.WBC_WB) && this.Model.WBC_WB) || (data.field === nameof(c => c.WBC_KONT) && this.Model.WBC_KONT))) {
                if (this.Model.WBC_SMO)
                    this.Model.WBC_SMO = null;
            }
        } else {
            if (data.field === nameof(c => c.WBT_SETTL) || data.field === nameof(c => c.WBT_ORG) || data.field === nameof(c => c.WBT_PRO) || data.field === nameof(c => c.WBT_BEG) || data.field === nameof(c => c.WBT_KONT)) {
                const anyChecked = this.Model.WBT_SETTL || this.Model.WBT_ORG || this.Model.WBT_PRO || this.Model.WBT_BEG || this.Model.WBT_KONT;

                if (anyChecked) {
                    this.Model.WBT_KDB = null;
                    this.Model.WBPartnerTyp.Id = VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON;
                    this.Model.KDB = null;
                    this.Model.WBT_SETTL_VS = null;
                }

                this.setPartnerDatenVisibility();
            } else if (data.field === nameof(c => c.WBT_SETTL_VS)) {
                if (this.Model.WBT_SETTL_VS) {
                    this.Model.WBT_SETTL = null;
                    this.Model.WBT_ORG = null;
                    this.Model.WBT_PRO = null;
                    this.Model.WBT_BEG = null;
                    this.Model.WBT_KONT = null;
                    this.Model.WBT_KDB = null;
                    this.Model.KDB = null;

                    this.clearPartnerDaten();
                }

                this.setPartnerDatenVisibility();
            } else if (data.field === nameof(c => c.WBT_KDB)) {
                if (this.Model.WBT_KDB) {
                    this.Model.WBPartnerTyp.Id = VwbPListDetailConst.VWBP_WBPARTNERTYP_KDB;
                    this.Model.WBT_SETTL = null;
                    this.Model.WBT_SETTL_VS = null;
                    this.Model.WBT_ORG = null;
                    this.Model.WBT_PRO = null;
                    this.Model.WBT_BEG = null;
                    this.Model.WBT_KONT = null;

                    this.clearPartnerDaten();
                } else
                    this.Model.WBPartnerTyp.Id = VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON;

                this.setPartnerDatenVisibility();
            }
        }

        this.cdr.markForCheck();
    }

    public async initNewModel(formTyp: string, versionId: string, data?: any) {
        this.Model = await this.apiService.getModel(VwbPFormularDaten, `${this.Api}/formular/create`);
        await this.checkTeilfonds(versionId);
        this.isEditMode = false;

        this.Model.vonVersion_ID = versionId;
        this.Model.FormularTyp.Id = formTyp;

        this.setFromFormType();
        this.setPartnerDatenVisibility();

        if (data) {
            // eslint-disable-next-line guard-for-in
            for (const key in data) {
                ObjectUtils.setByPath(this.Model, key, data[key]);
                this.onAttrChange({ field: key, value: data[key], form: this.Form, model: this.Model });
            }
        }

        this.cdr.markForCheck();
    }

    public async checkTeilfonds(versionId: string)
    {      
        if (versionId)
        {
            this.teilfondsEnabled = await this.apiService.get(`${this.Api}/rechtstraeger/version/${versionId}/teilfonds`);
            this.rtHRStatus = await this.apiService.get(`${this.Api}/rechtstraeger/version/${versionId}/hrstatus`);
        }
        else
        {
            this.teilfondsEnabled = false;
            this.rtHRStatus = null;
        }
    }

    ngOnInit() {
        this.initFields();
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: VwbPFormularDaten) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        delegate = this.apiService.post(`${this.Api}/formular`, model);

        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {
        try {
            this.commonService.showGlobalLoader();

            this.Model = await this.apiService.FindModel(VwbPFormularDaten, `${this.Api}/formular/${id}`, null, null, null);
            await this.checkTeilfonds(this.Model?.vonVersion_ID);

            this.natPersonMode = this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON || this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_KDB;
            this.isEditMode = true;

            this.setFromFormType();
            this.setPartnerDatenVisibility();

            this.updateDropdownSources();
        } finally {
            this.commonService.hideGlobalLoader();
        }

        this._ModelId = this.Model.Id;

        return this.Model;
    }

    async updateDropdownSources() {
        if (this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    ValidateFormular = (): boolean => {
        const formC = this.Model.FormularTyp.Id === VwbPListDetailConst.VWBP_FORMULARTYP_C;

        if (formC)
            return this.Model.WBC_WB || this.Model.WBC_KONT || this.Model.WBC_SMO;
        else
            return ((this.Model.WBT_SETTL || this.Model.WBT_ORG || this.Model.WBT_PRO || this.Model.WBT_BEG || this.Model.WBT_KONT || this.Model.WBT_SETTL_VS) &&
                ((this.natPersonMode && this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON) ||
                    (!this.natPersonMode && this.Model.WBPartnerTyp.Id !== VwbPListDetailConst.VWBP_WBPARTNERTYP_NATPERSON))) || (this.Model.WBT_KDB && this.Model.WBPartnerTyp.Id === VwbPListDetailConst.VWBP_WBPARTNERTYP_KDB);
    }

    initFields() {
        const lblTitle = this.formFieldService.CreateLabel('lblTitle', '', false).setMDSizeFull();
        lblTitle.LabelClass = 'form-title';
        this.Fields.push(lblTitle);

        this.Fields.push(this.formFieldService.CreateLabel('lbl1', 'VWBP.PUBLIC.FORM.FESTELLUNG_WB', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateLabel('lbl2', 'VWBP.PUBLIC.FORM.ALS_WB_FESTGESTELLT').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTemplate(WBC_WB, 'WBC_WB').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(WBC_KONT, 'WBC_KONT').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(WBC_SMO, 'WBC_SMO').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(WBT_SETTL, 'WBT_SETTL').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(WBT_SETTL_VS, 'WBT_SETTL_VS').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(WBT_ORG, 'WBT_ORG').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(WBT_PRO, 'WBT_PRO').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(WBT_BEG, 'WBT_BEG').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(WBT_KONT, 'WBT_KONT').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTemplate(WBT_KDB, 'WBT_KDB').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTemplate(WBJP, 'BLA').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTemplate(WBJP_HINWEIS, 'BLA').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateLabel('lbl4', 'VWBP.PUBLIC.FORM.TITLE_TEILFONDS', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateLabel('lbl5', 'VWBP.PUBLIC.FORM.HINWEIS_TEILFONDS', false).setMDSizeFull());
        
        var field = this.formFieldService.CreateTextarea(nameof(c => c.BemerkungTeilfonds), 'VWBP.PUBLIC.FORM.BEMERKUNG_TEILFONDS', false).setMDSizeFull();
        field.CustomStyle = () => { return { 'max-height': "350px" }; };
        this.Fields.push(field);

        this.Fields.push(this.formFieldService.CreateLabel('lbl3', 'VWBP.PUBLIC.FORM.DATEN_WB', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateTemplate(WBJP_TYPAUSWAHL, 'BLA').setMDSizeFull());

        // WB = Natürliche Person
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Titel), 'CORE.COMMON.TITEL', false).setMDSizeHalf().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateLabel('titel_filler', ' ').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Vorname), 'CORE.COMMON.VORNAME', true).setMDSizeHalf().setMaxLength(256));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Name), 'CORE.COMMON.NAME', true).setMDSizeHalf().setMaxLength(256));

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Geburtsdatum), 'CORE.COMMON.GEBURTSDATUM', true).setMDSizeHalf().setMinMaxDate(this.commonService.getDateAddYears(new Date(), -110), new Date()));
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Land), 'CORE.COMMON.WOHNSITZSTAAT', true, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Nationalitaet1), 'CORE.COMMON.STAATSANGEHOERIGKEIT', true, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Nationalitaet2), 'CORE.COMMON.STAATSANGEHOERIGKEIT2', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Nationalitaet3), 'CORE.COMMON.STAATSANGEHOERIGKEIT3', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Nationalitaet4), 'CORE.COMMON.STAATSANGEHOERIGKEIT4', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());

        // WB = KDB
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.KDB), 'VWBP.PUBLIC.FORM.KDB', false).setMDSizeFull().setMaxLength(2048));

        // WB = Juristische Person

        // inländisch
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.InlandRechtstraegerName), 'VWBP.PUBLIC.FORM.WB_NAME_FIRMA', false).setMDSizeHalf().setMaxLength(512));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.InlandRegisterNummer), 'VWBP.COMMON.REGISTERNR', false).setMDSizeHalf().setMaxLength(128));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.InlandRechtsform), 'VWBP.COMMON.RECHTSFORM', false).setMDSizeHalf().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.InlandRechtstraegerSitz), 'VWBP.COMMON.SITZ_ERRICHTUNGSORT', false).setMDSizeHalf().setMaxLength(128));

        // ausländisch
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRechtstraegerName), 'VWBP.PUBLIC.FORM.WB_NAME_FIRMA', false).setMDSizeHalf().setMaxLength(512));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRechtsform), 'VWBP.COMMON.RECHTSFORM', false).setMDSizeHalf().setMaxLength(128));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRechtstraegerSitz), 'VWBP.COMMON.SITZ_ERRICHTUNGSORT', false).setMDSizeHalf().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.AuslandGruendungsdatum), 'VWBP.COMMON.GRUENDUNGSDATUM_AUSLAND', false).setMDSizeHalf().setMinMaxDate(new Date(1800, 1, 1), new Date()));

        this.Fields.push(this.formFieldService.CreateLabel('lblAuslandEintrag', 'VWBP.COMMON.REGISTEREINTRAG_AUSLAND', false).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.AuslandRegisterLand), 'VWBP.COMMON.REGISTERLAND', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRegisterOrt), 'VWBP.COMMON.REGISTERORT', false).setMDSizeHalf().setMaxLength(128));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.AuslandRegisterNummer), 'VWBP.COMMON.REGISTERNR', false).setMDSizeHalf().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.AuslandRegisterDatum), 'VWBP.COMMON.REGISTERDATUM', false).setMDSizeHalf().setMinMaxDate(new Date(1800, 1, 1), new Date()));

        // adresse
        this.Fields.push(this.formFieldService.CreateLabel('lblAuslandAdresse', 'VWBP.COMMON.ADRESSE', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RTZustellAdresseCO), 'VWBP.COMMON.CO', false).setMDSizeFull().setReadonly().setMaxLength(256));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RTZustellAdresseStrasse), 'VWBP.COMMON.STRASSE_NUMMER', false).setMDSizeFull().setReadonly().setMaxLength(512));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RTZustellAdressePostfach), 'VWBP.COMMON.POSTFACH', false).setMDSizeFull().setReadonly().setMaxLength(256));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RTZustellAdressePLZ), 'VWBP.COMMON.PLZ', false).setMDSizeHalf().setReadonly().setMaxLength(128));
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.RTZustellAdresseOrt), 'VWBP.COMMON.ORT', false).setMDSizeHalf().setReadonly().setMaxLength(128));

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.RTZustellAdresseLand), 'VWBP.COMMON.LAND', false, 'Representation', 'Id', 0, `v1/vwbp/model/representations/land`).setMDSizeHalf());
    }

    initJPFormT(event) {
        if (event.target['tagName'].toLowerCase() === 'u') {
            this.natPersonMode = false;
            this.initNewModel(this.Model.FormularTyp.Id, this.Model.vonVersion_ID);
        }
    }

    cancelJPFormT(event) {
        if (event.target['tagName'].toLowerCase() === 'u') {
            this.natPersonMode = true;
            this.initNewModel(this.Model.FormularTyp.Id, this.Model.vonVersion_ID);
        }
    }

    ImportHRRechtstraeger() {
        this.rechtstraegerLIForm.InitForm();
        this.rechtstraegerFormDialog.open();
    }

    onSelectFromHR(detail: VwbPRechtstraegerDetail) {
        this.Model.RechtstraegerName = detail.Name;
        this.Model.RegisterNummer = detail.RegisterNummer;

        this.Model.Rechtsform = detail.Rechtsform;
        this.Model.RechtstraegerSitz = detail.Sitz;

        this.Model.RTZustellAdresseCO = detail.ZustellAdresseCO;
        this.Model.RTZustellAdresseStrasse = detail.ZustellAdresseStrasse;
        this.Model.RTZustellAdressePostfach = detail.ZustellAdressePostfach;
        this.Model.RTZustellAdressePLZ = detail.ZustellAdressePLZ;
        this.Model.RTZustellAdresseOrt = detail.ZustellAdresseOrt;

        this.Model.WBPartnerTyp.Id = VwbPListDetailConst.VWBP_WBPARTNERTYP_JURPERSON_LI_HRN;

        this.setPartnerDatenVisibility();

        this.closeForm();
    }

    SetJPTypManuell() {
        this.Model.WBPartnerTyp.Id = VwbPListDetailConst.VWBP_WBPARTNERTYP_JURPERSON_AUSLAENDISCH;
        this.setPartnerDatenVisibility();
    }

    private closeForm() {
        this.rechtstraegerFormDialog.close();
    }
}
