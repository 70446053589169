<avi-core-page [contents-padding]="false" [no-box-shadow]="true">

    <avi-tabView #tabView *ngIf="ModelId" width="14rem" tabview-class="tabstyle-session" orientation="vertical" [session-modul]="true">

        <avi-tabPanel title="CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_ACTIVITYTYPE" id="activitytype-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-activitytype-form mode="activity" [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true"></avi-activitytype-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_PERIODE" id="activitytype-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-activitytype-form mode="periode" [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true"></avi-activitytype-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_DEFAULTS" id="activitytype-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-activitytype-form mode="defaults" [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true"></avi-activitytype-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_KONTEXTE" id="activitytype-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-activitytype-form mode="contexts" [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true"></avi-activitytype-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_DATES" id="activitytype-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-activitytype-form mode="dates" [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true"></avi-activitytype-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>

        <avi-tabPanel title="CORE.COMMON.MODEL.ACTIVITYTYPE.ACTIVITYTYPE_GRP_RIGHTS" id="activitytype-view">
            <avi-core-lazy-loader>
                <ng-template #content>
					<div class="p-1">
						<avi-activitytype-form mode="rights" [card]="false" [contents-padding]="false" [readonly]="true" [model-id]="ModelId" [enable-taskmenu]="true"></avi-activitytype-form>
					</div>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel>


<!-- 	
        <avi-tabPanel title="CORE.COMMON.MODEL.ACTIVITYTYPE.ALLEWATCHER" id="allewatcher-list">
            <avi-core-lazy-loader>
                <ng-template #content>
					<avi-activitytypewatcherlink-searcher [context-id]="ModelId"></avi-activitytypewatcherlink-searcher>
                </ng-template>
            </avi-core-lazy-loader>
        </avi-tabPanel> -->
    </avi-tabView>

</avi-core-page>

