import { Pipe, PipeTransform } from '@angular/core';
import { AviCommonService } from '../../services/common.service';

@Pipe({
    name: 'formatAsHtml',
})
export class AviFormatAsHtmlPipe implements PipeTransform {
    constructor(public commonService: AviCommonService) {}

    transform(line: string): string {
        if (!line) {
            return ' ';
        }

        return this.commonService.text2html(line);
    }
}
