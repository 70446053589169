import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviHistoryService, AviListDetailConst } from '@avi-x/avi-core';
import { NatpersonHist } from '@avi-x/avi-dto/partner/natperson-hist.model';
import { ZustandInfoDto } from '@avi-x/avi-dto/shared/zustandinfo.model';
import { AenderungsGrundAttrLink } from '@avi-x/avi-dto/workflow/aenderungsgrundattrlink.model';

import * as moment from 'moment-timezone';

const nameof = (nameFunction: ((obj: NatpersonHist) => any)) => typed_nameof<NatpersonHist>(nameFunction);

@Component({
    selector: 'avi-crm-natperson-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [label-alignment]="ReadOnly ? 'left' : 'top'"
        [label-width]="'160px'"
        [(Model)]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [contents-padding]="ContentsPadding"
        [action-buttons]="actionButtons">

        <div formFieldTemplate1>
            <avi-core-history-control [history-states]="HistoryStates" (stichtagChange)="OnStichtagChange($event)">
            </avi-core-history-control>
        </div>

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviNatpersonFormComponent implements OnInit, OnDestroy {

    private _className = 'Natperson';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = '/crm/natperson';

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = '/crm/natperson';

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: NatpersonHist = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string { return this._ModelId; }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    private stichtag: Date = new Date();
    public HistoryStates: ZustandInfoDto[];

    constructor(public commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private historyService: AviHistoryService,
        private apiService: AviApiService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {

        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));

        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    async onAttrChange(data: AviChangedAttr) {
    }

    public async initNewModel(data?: any) {

        /*        this.Model = await this.apiService.getModel(NatpersonHist, 'natperson/Create');
                this.isEditMode = false;

                if (data) {
                    for (const key in data) {
                        ObjectUtils.setByPath(this.Model, key, data[key]);
                        this.onAttrChange({ field: key, value: data[key] });
                    }
                }*/
    }

    ngOnInit() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.ModelId = params['id'];
            }
        });

        this.initFields();
    }


    deleteModel(model: any) {
        /*        this.apiService.delete(`/natperson/${model.id}`).then(r => {

                    if (this.RedirectAfterDelete)
                        this.router.navigate([this.RedirectAfterDelete]);
                });*/
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: NatpersonHist) {

        /*
                this.Form.clearFormMessages();

                let delegate: Promise<any> = null;
                if (this.isEditMode) {
                    delegate = this.apiService.put(`/natperson/${model.Id}`, model);
                } else {
                    delegate = this.apiService.post('/natperson', model);
                }
                delegate.then(r => {
                    this.commonService.notificateSuccess('Gespeichert');
                    this.onSavedSuccessfulDelegate.emit(model);

                    if (this.RedirectAfterSave)
                        this.router.navigate([this.RedirectAfterSave]);
                }).catch(err => {
                    if (err instanceof AviApiErrorObject) {
                        this.Form.addFormMessage(err.ErrorMessage, err.Type);
                    } else {
                        this.Form.addFormMessage(JSON.stringify(err));
                    }
                });*/
    }

    async loadModel(id: string) {

        this.commonService.showGlobalLoader();

        this.historyService.clearHistoryHighlighting(this.Fields);
        this.stichtag = new Date();
        const strStichtag = moment(this.stichtag).format('YYYY-MM-DD');
        this.Model = await this.apiService.getModel(NatpersonHist, `natperson/hist/${strStichtag}/${id}`);

        await this.updateDropdownSources();

        this.commonService.hideGlobalLoader();
        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        this.HistoryStates = await this.apiService.getModelList(ZustandInfoDto, `natperson/hist/states/${id}`);

        // änderungsgrunddef
        const changeDefId = this.Model.ChangeDefRahmenPartner_ID;
        const attribs = changeDefId ? await this.apiService.getModelList(AenderungsGrundAttrLink, `aenderungsgrunddef/attribs/${changeDefId}`) : null;
        if (attribs)
            attribs.filter(w => w.Sichtbar.Id === AviListDetailConst.NEIN).forEach(w => this.formFieldService.setFieldVisible(this.Fields, w.Attribute, false));

        return this.Model;
    }

    async updateDropdownSources() {
        if (this.ReadOnly)
            await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'Gültig ab', false).setNullDateLow().setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'Gültig bis', false).setNullDateHigh().setMDSizeHalf());

        if (this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateTemplate(1, 'Zustand ab/bis').setMDSizeFull());
        else {
            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.ZustandAb), 'Zustand ab', false).setNullDateLow().setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.ZustandBis), 'Zustand bis', false).setNullDateHigh().setMDSizeHalf());
        }

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.PartnerNr), 'PartnerNr', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Geschlecht), 'Geschlecht', 'caf_geschlecht', false, false, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Vorname), 'Vorname', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Name), 'Name', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Vorname2), 'Vorname2', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Ledigname), 'Ledigname', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.PreTitle), 'Titel vor Name', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.PostTitle), 'Titel nach Name', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.Sprache_ID), 'Sprache', `model/representations/Sprache${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'Sprache wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Beruf), 'Beruf', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Anrede), 'Anrede', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Briefanrede), 'Briefanrede', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Geburtsdatum), 'Geburtsdatum', false).setMDSize(4));
        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.GeburtsdatumUnbekannt), ' ', 'Unbekannt', false).setMDSize(2));
        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Todesdatum), 'Todesdatum', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.GeburtsOrt), 'Geburtsort', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.GeburtsLand_ID), 'Geburtsland', `model/representations/CAFLand${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'Geburtsland wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Zivilstand), 'Zivilstand', 'caf_zivilstand', true, false, this.ReadOnly).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.BuergerOrt), 'Bürgerort', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.NatpersonStatus_ID), 'Status', `model/representations/CAFModelState${this.ReadOnly ? '?id=$id' : ''}`, true, true, 'Status wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSize(6));
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.PflichtCode), 'PflichtCode', 'caf_mandat_pflichtcode', true, false, this.ReadOnly).setMDSizeHalf());

        // TODO: Nationalität?

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.SozialVersNr), 'Sozial Vers.Nr.', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.PVSPersBesteuerung), 'Steuerstatus', 'caf_pvs_besteuerung', true, false, this.ReadOnly).setMDSizeHalf());
    }

    async OnStichtagChange(stichtag: Date) {
        this.stichtag = stichtag;
        const strStichtag = moment(this.stichtag).format('YYYY-MM-DD');

        const newModel = await this.apiService.getModel(NatpersonHist, `natperson/hist/${strStichtag}/${this.Model.Id}`);
        this.historyService.handleHistoryHighlighting(this.Fields, this.Model, newModel);
        this.Model = newModel;

        this.cdr.markForCheck();
    }
}
