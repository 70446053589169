import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { AviApiService, AviCommonService, AviSessionControllerService } from '@avi-x/avi-core';
import { AviActivityFormComponent, AviActivityService } from '@avi-x/avi-crm';
import { ActivitySearchResponseDto } from '@avi-x/avi-dto/activity/activitysearchresponsedto.model';


@Component({
    selector: 'avi-businesscase-activities-view',
    templateUrl: './businesscase-activities-view.component.html',
    styleUrls: ['./businesscase-activities-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseActivitiesViewComponent {
    
    _ModelID: any = null;
    @Input('model-id')
    public set ModelID(value: any)
    {
        this._ModelID = value;

        if (this._ModelID)
            this.initView();
    }
    public get ModelID(): any {
        return this._ModelID;
    }

    _active: boolean = false;
    @Input('active')
    public set Active(value: boolean) {
        this._active = value;
        if (value)
            setTimeout(() => this.initView());
    }

    @Input('enable-erfassen') CanCreateActivities: boolean = true;

    @Output('onChanged') 
    onChanged = new EventEmitter<any>();

    Activities: ActivitySearchResponseDto[] = [];


    constructor(private apiService: AviApiService, public commonService: AviCommonService,
        public activityService: AviActivityService,
        public sessionController: AviSessionControllerService,
        private cdr: ChangeDetectorRef) {
    }

    
    private async initView() {
        if (this._ModelID === null) {
            this.Activities = [];
            this.cdr.detectChanges();
        } else {
            await this.loadActivities();
        }

        this.cdr.markForCheck();
    }
    
    public async loadActivities() {
        const actRes = await this.apiService.postPagedResult(ActivitySearchResponseDto, 'activity/search', { ContextId: this._ModelID });
        this.Activities = actRes.Results;
    }

    openActivity(actId) {
        this.sessionController.openSession('activity-index', actId);
    }

    getParams(): { [k: string]: any; } {
        return { contextId: this._ModelID, contextType: 'aviita.CAF.common.modul.businesscase.model.CAFBusinesscase' };
    }

    async createActivity() {
        const res = await this.commonService.openFormDialog(AviActivityFormComponent, 'Aktivität erfassen', '0', null, this.getParams(), true);
        if (res) {
            await this.initView();
            this.cdr.markForCheck();
            this.onChanged.emit();
        }
    }

    async editActivity(actId) {
        const res = await this.commonService.openFormDialog(AviActivityFormComponent, 'Aktivität bearbeiten', actId, null, this.getParams(), true);
        if (res) {
            await this.initView();
            this.cdr.markForCheck();
            this.onChanged.emit();
        }
    }

    async deleteActivity(act) {
        if (act) {
            this.commonService.confirmDelete(`Möchten Sie dieses Objekt wirklich löschen?`,
                async () => {
                    await this.apiService.delete(`activity/${act}`);
                    this.commonService.notificateSuccess('Gelöscht');
                    await this.initView();
                    this.onChanged.emit();
                }
            );
        }
    }
}