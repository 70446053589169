import { EmailConfig } from '@avi-x/avi-dto/system/emailconfig.model';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviPasswordService } from '@avi-x/avi-core';
import { AviEmailConfigTestFormComponent } from './emailconfigtest-form.component';

const nameof = (nameFunction: ((obj: EmailConfig) => any)) => typed_nameof<EmailConfig>(nameFunction);

@Component({
    selector: 'avi-crm-emailconfig-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [contents-padding]="ContentsPadding"
        [Model]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="ActionButtons"
        >

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviEmailConfigFormComponent implements OnInit, OnDestroy, OnChanges {
    private _className = 'Email-Konfiguration';

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
   
    @Input('form-title')
    public FormTitle: string = this._className + ' bearbeiten';

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('action-buttons')
    actionButtons: any[] = [];

    @Input('api-prefix')
    public ApiPrefix: string = '';

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    public Loading: boolean = false;
    public Model: EmailConfig = null;
    public Fields: AviFormField[] = [];

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    @Output('onAttrChange')
    public onAttrChangeEvent: EventEmitter<AviChangedAttr> = new EventEmitter();

    menuItems: any = [
        { title: 'Test Konfiguration', class: 'p-button-secondary', icon: 'pi pi-envelope', action: () => { this.openTestEmailConfigDialog(); } },
        { title: 'Passwort setzen', class: 'p-button-secondary', icon: 'pi pi-lock', action: () => { this.passwordService.OpenChangePasswordDialog(`${this.ApiPrefix ?? ''}/emaildef/config/setpassword`, false, false, false); } }
    ];

    ActionButtons: any[] = [];

    constructor(private commonService: AviCommonService,
        private router: Router,
        private apiService: AviApiService,
        private passwordService: AviPasswordService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.ActionButtons = [...this.actionButtons, ...this.menuItems];
    }

    public async InitForm(): Promise<boolean> {
        await this.loadModel();

        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this.Model = null;
    }

    async onAttrChange(data: AviChangedAttr) {
        this.onAttrChangeEvent.emit(data);
    }

    ngOnInit() {
        this.initFields();
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: EmailConfig) {
        this.Form.clearFormMessages();

        const delegate: Promise<any> = this.apiService.post(`${this.ApiPrefix ?? ''}/emaildef/config`, model);

        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    public getTestEmailConfigEnabled() {
        const res = this.Form?.form?.valid;
        return res;
    }

    openTestEmailConfigDialog() {
        this.commonService.openFormDialog(AviEmailConfigTestFormComponent, 'Test Konfiguration', '0', null, { ApiPrefix: this.ApiPrefix }, false);
    }

    async loadModel() {
        this.Model = await this.apiService.FindModel(EmailConfig, `${this.ApiPrefix ?? ''}/emaildef/config`, null, null, null);
        this.Form.clearFormMessages();
        return this.Model;
    }

    ngOnDestroy() {
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Username), 'Loginname', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Host), 'Host', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.Port), 'Port', null, true).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.SSL), 'SSL', 'janein', true, true, false, this.ApiPrefix ?? '').setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Sender), 'Sender', true).setMDSizeHalf());
    }
}
