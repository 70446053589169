import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';
import { VwbPRechtstraeger, VwbPVersion } from '@avi-x/vwbp-public';

@Injectable({
    providedIn: 'root'
})
export class VwbPInternRechtstraegerVersionResolver implements Resolve<VwbPRechtstraeger> {
    constructor(private apiService: AviApiService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<VwbPRechtstraeger> {
        const idProperty = route.data['resolveId'] || 'id';
        const id: string = route.params[idProperty];

        return this.loadVersion(id);
    }

    async loadVersion(id: string) {
        const version = await this.apiService.getModel(VwbPVersion, `v1/intern/vwbp/rechtstraeger/version/${id}`);
        if (version != null) {
            const rechtstraeger = await this.apiService.getModel(VwbPRechtstraeger, `v1/intern/vwbp/rechtstraeger/${version.Rechtstraeger_ID}`);
            rechtstraeger.SelectedVersion = version;
            return rechtstraeger;
        } else {
            const rechtstraeger = await this.apiService.getModel(VwbPRechtstraeger, `v1/intern/vwbp/rechtstraeger/${id}`);
            if (rechtstraeger.CurrentVersionId)
                rechtstraeger.SelectedVersion = await this.apiService.getModel(VwbPVersion, `v1/intern/vwbp/rechtstraeger/version/${rechtstraeger.CurrentVersionId}`);

            return rechtstraeger;
        }
    }
}
