
export class AviApiErrorObject {
    public StatusCode: number;
    public StatusText: string;
    public ErrorMessage: any;
    public Details: any;
    public OriginalError: any;
    public Type: string;

    public constructor(errorMessage: string, statusCode: number = null, statusText: string = null, originalError: any = null, details: any = null) {
        this.StatusCode = statusCode;
        this.StatusText = statusText;
        this.ErrorMessage = errorMessage;
        this.OriginalError = originalError;
        this.Details = details;
    }

    public setType(type: string): AviApiErrorObject {
        this.Type = type;
        return this;
    }

    public toString() {
        if (this.Type === 'AviApiException')
            return this.ErrorMessage;
        else
            return `${this.StatusCode}:${this.StatusText} - ${this.ErrorMessage}`;
    }
}
