import { Component, Input, Output, EventEmitter, OnInit, forwardRef } from '@angular/core';

@Component({
    selector: 'avi-core-full-dialog',
    template: `
	<div *ngIf="visible" class="full-dialog-outer" [ngStyle]="{'background-color': backgroundColor,'opacity':opacity, 'z-index':900}"></div>
	<div *ngIf="visible" [ngClass]="{'full-dialog-inner center':true, 'center':width != '100%'}"  [ngStyle]="{'z-index':901, 'width':width, 'height':height, 'top':top, 'background-color':innerBackgroundColor, 'left':left}">
		<div *ngIf="closable" class="full-dialog-close-button">
			<a (click)="close()"><i class="pi pi-times-circle"></i></a>
		</div>
		<div [ngStyle]="{'padding':padding}">
			<ng-content></ng-content>
		</div>
	</div>

`,
    styles: [
        '.full-dialog-outer { position:fixed;top:0;left:0;width:100%;height:100%;}',
        '.full-dialog-inner { position:fixed}',
        '.full-dialog-close-button { position:absolute;right:5px;top:5px;width:20px;height:20px;}',
        '.center {   transform: translate(-50%, 0);}'
    ],

})
export class AviFullDialogDirectiveComponent implements OnInit {

    private _visible: boolean = false;

    @Input()
    set visible(value: boolean) {
        this._visible = value;
        this.visibleChange.emit(value);
    }

    get visible(): boolean {
        return this._visible;
    }

    @Output()
    visibleChange = new EventEmitter();

    @Output('onClose')
    onClose: EventEmitter<any> = new EventEmitter();


    @Input()
    closable: boolean = true;

    @Input('background-color')
    backgroundColor: string = '#FEFEFE';

    @Input('inner-background-color')
    innerBackgroundColor: string = '#FEFEFE';

    @Input('padding')
    padding: string = '25px';

    @Input('opacity')
    opacity: Number = 1;



    @Input()
    width: string = '100%';

    @Input()
    top: string = '0px';

    height: string = '100%';
    left: string = '0px';
    marginLeft: any = null;

    constructor() {
    }

    ngOnInit() {
        if (this.width !== '100%') {
            this.opacity = 0.5;
            this.height = 'auto';
            this.top = '20px';
            this.left = '50%';
        }
    }

    close() {
        this.visible = false;
        this.onClose.emit(true);
    }

}
