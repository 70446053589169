import { ChangeDetectorRef, Component } from '@angular/core';
import { AviCommonService, InputmaskService } from '@avi-x/avi-core';
import { VwbPCommonService, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPInternMahnwesenService } from '../../services/mahnwesen.service';
import { VwbPInternMahnwesenBaseComponent } from './mahnwesen-base.component';

@Component({
    selector: 'vwbp-intern-mahnwesen-nichtrelevant',
    template: `
        <vwbp-intern-rechtstraeger-searcher #searcher settingskey="settings.searcher.mw.nicht-relevant" [use-router-params]="true" [get-filters]="GetFilters" [visible-columns]="['Name', 'RegisterNummer', 'Rechtsform', 'RegisterDatum', 'btnOpen']">
        </vwbp-intern-rechtstraeger-searcher>
        `
})
export class VwbPInternMahnwesenNichtRelevantComponent extends VwbPInternMahnwesenBaseComponent {
    constructor(public commonService: AviCommonService, public mahnwesenService: VwbPInternMahnwesenService, public vwbpCommonService: VwbPCommonService, public inputMaskService: InputmaskService, public cdr: ChangeDetectorRef) {
        super(commonService, mahnwesenService, vwbpCommonService, inputMaskService, cdr);
    }

    GetFilters = (): string[] => {
        return [
            `MahnwesenStatus eq ${VwbPListDetailConst.VWBP_MAHNWESENSTATUS_NICHT_RELEVANT}`
        ];
    }
}
