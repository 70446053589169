import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';

export class Waehrung extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get ISOCode(): string { return this.getProperty('ISOCode'); }
    public set ISOCode(value: string) { this.setProperty('ISOCode', value); }
    
    @Expose()
    public get Basis(): number { return this.getProperty('Basis'); }
    public set Basis(value: number) { this.setProperty('Basis', value); }
    
    @Expose()
    public get Symbol(): string { return this.getProperty('Symbol'); }
    public set Symbol(value: string) { this.setProperty('Symbol', value); }
    
    @Expose()
    public get Minor(): string { return this.getProperty('Minor'); }
    public set Minor(value: string) { this.setProperty('Minor', value); }
    
    @Expose()
    public get MinorSymbol(): string { return this.getProperty('MinorSymbol'); }
    public set MinorSymbol(value: string) { this.setProperty('MinorSymbol', value); }
    
    @Expose()
    public get MinorRate(): number { return this.getProperty('MinorRate'); }
    public set MinorRate(value: number) { this.setProperty('MinorRate', value); }
    
    @Expose()
    public get KtoFindungTeilschluessel(): string { return this.getProperty('KtoFindungTeilschluessel'); }
    public set KtoFindungTeilschluessel(value: string) { this.setProperty('KtoFindungTeilschluessel', value); }
    
    @Expose()
    public get KtoFindungFreierSchluessel1(): string { return this.getProperty('KtoFindungFreierSchluessel1'); }
    public set KtoFindungFreierSchluessel1(value: string) { this.setProperty('KtoFindungFreierSchluessel1', value); }
    
}
