import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AviApiService, AviBaseFormComponent, AviBaseSearcherComponent, AviCommonService, AviDialogComponent } from '@avi-x/avi-core';
import { VwbPAdminRechtsformDefFormComponent } from '../rechtsformdef-form/rechtsformdef-form.component';


@Component({
    selector: 'vwbp-admin-rechtsformdef-searcher',
    templateUrl: './rechtsformdef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminRechtsformDefSearcherComponent implements OnInit {
    public Loading: boolean = false;

    FormModelId: string = null;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @ViewChild('searcher', { static: true })
    public searcher: AviBaseSearcherComponent;

    @ViewChild('rechtsformDefFormDialog', { static: false })
    rechtsformDefFormDialog: AviDialogComponent;

    @ViewChild('rechtsformDefForm', { static: false })
    public rechtsformDefForm: VwbPAdminRechtsformDefFormComponent;

    public Card = true;

    private SelectedRows: any[] = [];
    public DetailModelId: string = null;

    formActionButtons: any[] = [{  title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary p-button-outlined', icon: 'pi pi-times', action: () => this.closeForm() }];

    constructor(
        private commonService: AviCommonService,
        private apiService: AviApiService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {

    }

    async onSelectionChanged(data: any[]) {
        this.SelectedRows = data;

        if (this.SelectedRows.length > 0) {
            this.DetailModelId = this.SelectedRows[0].Id;
        }
    }

    onSearcherInit() {
        this.searcher.beginInit();
        this.searcher.addTextColumn('Rechtsform', 'Rechtsform');
        this.searcher.addListTypeColumn('Typ', 'Typ', 'vwbp_rechtsform_typ', true, false, null, 'v1/vwbp');
        this.searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        let res = await this.apiService.get('v1/intern/vwbp/rechtstraeger/rechtsformdefs');
        res = this.searcher.ApplyColumnFilters(res);
        res = this.searcher.ApplyColumnSorting(res);
        return res;
    }

    onSearcherError(error: any) {
        this.commonService.notificateError(error);
    }

    onDoubleClickSearcher(data) {
        this.editRechtsformDef();
    }


    public createRechtsformDef() {
        this.FormModelId = '0';
        this.rechtsformDefFormDialog.open();
    }

    public editRechtsformDef() {
        if (this.SelectedRows.length > 0 && this.SelectedRows[0]) {
            this.FormModelId = this.SelectedRows[0].Id;
            this.rechtsformDefFormDialog.open();
        }
    }

    onSavedSuccessful(event) {
        this.closeForm();
        this.searcher.forceRefresh();
    }

    private closeForm() {
        this.FormModelId = null;
        this.rechtsformDefFormDialog.close();
    }

    public deleteRechtsformDef() {
        if (this.SelectedRows.length > 0 && this.SelectedRows[0]) {
            const id = this.SelectedRows[0].Id;

            this.commonService.confirmDelete(`Möchten Sie die Rechtsform-Definition wirklich löschen?`,
                () => {
                    this.apiService.delete(`v1/intern/vwbp/rechtstraeger/rechtsformdef/${id}`).then(r => {
                        this.commonService.notificateSuccess('Gelöscht');
                        this.searcher.forceRefresh();
                    });
                }
            );
        }
    }
}
