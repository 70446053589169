import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { DecimalPipe, PercentPipe } from '@angular/common';

import { AviCommonService, AviApiService } from '@avi-x/avi-core';

export enum AviCommentMode {
    // eslint-disable-next-line no-unused-vars
    Readonly, Create, Edit
}

@Component({
    selector: 'vwbp-intern-unstimmigkeit-comment-list',
    templateUrl: './unstimmigkeit-comment-list.component.html',
    styleUrls: ['./unstimmigkeit-comment-list.component.scss'],
    providers: [DecimalPipe, PercentPipe]
})
export class VwbPInternUnstimmigkeitCommentListComponent {
    CommentIds: any[] = [];

    NewCommentId: string = null;
    EditCommentId: string = null;

    _ContextId: string = null;
    public get ContextId(): string {
        return this._ContextId;
    }

    @Input('context-id')
    public set ContextId(value: string) {
        this._ContextId = value;
        this.LoadComments();
    }

    constructor(public apiService: AviApiService, public router: Router, private activatedRoute: ActivatedRoute, public commonService: AviCommonService, private cdr: ChangeDetectorRef) {
    }

    async LoadComments() {
        if (this.ContextId) {
            this.CommentIds = await this.apiService.get(`v1/intern/vwbp/unstimmigkeit/comment/idlist/${this.ContextId}`);
            this.cdr.markForCheck();
        }
    }

    OnDeleteComment(id: string) {
        const index = this.CommentIds.indexOf(id, 0);
        if (index > -1)
            this.CommentIds.splice(index, 1);
    }

    OnEditComment(id: string) {
        this.EditCommentId = id;
    }

    OnEditCommentCancelled(id: string) {
        this.EditCommentId = null;
    }

    OnEditCommentSaved(id: string) {
        this.EditCommentId = null;
    }

    OnCreateCommentCancelled() {
        this.NewCommentId = null;
    }

    OnCreateCommentSaved() {
        this.NewCommentId = null;
        this.LoadComments();
    }

    CreateComment() {
        this.NewCommentId = this.commonService.GuidEmpty;
    }
}
