import { TranslateModule } from '@ngx-translate/core';
import { DividerModule } from 'primeng/divider';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AviCoreModule } from '@avi-x/avi-core';

// import { BrowserModule } from '@angular/platform-browser';
import { AviQueryDefExecutionSearcherModule } from '@avi-x/avi-report';
import { AviActivitySearcherComponent } from './components/activity-searcher/activity-searcher.component';

import { PanelModule } from 'primeng/panel';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeModule } from 'primeng/tree';

import { AviActivityFormComponent } from './components/activity-form/activity-form.component';
import { FormsModule } from '@angular/forms';
import { AviActivityIndexComponent } from './components/activity-index/activity-index.component';
import { AviActivityViewComponent } from './components/activity-view/activity-view.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { AviActivityService } from './services/activity.service';
import { AviCommentModule } from '../comment/comment.module';
import { AviActivityStatusComponent } from './components/activity-status/activity-status.component';
import { AviActivityTypeSearcherComponent } from './components/activitytype/activitytype-searcher.component';
import { AviActivityTypeFormComponent } from './components/activitytype/activitytype-form.component';
import { AviActivityTypeIndexComponent } from './components/activitytype/activitytype-index.component';


@NgModule({
    imports: [
        // BrowserModule,
        CommonModule,
        FormsModule,

        AviCoreModule,
        AviCommentModule,
        AviQueryDefExecutionSearcherModule,

        TreeModule,
        PanelModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ButtonModule,
        SplitButtonModule,
        InputTextModule,
        InputTextareaModule,
        DividerModule,

        MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatFormFieldModule, MatInputModule,
        MatTabsModule, MatMenuModule,

        TranslateModule,
        RichTextEditorModule
    ],
    exports: [
        AviActivitySearcherComponent,
        AviActivityFormComponent,
        AviActivityIndexComponent,
        AviActivityStatusComponent,
        AviActivityViewComponent,

        AviActivityTypeFormComponent,
        AviActivityTypeSearcherComponent,
        AviActivityTypeIndexComponent,

        RouterModule
    ],
    declarations: [
        AviActivitySearcherComponent,
        AviActivityFormComponent,
        AviActivityIndexComponent,
        AviActivityStatusComponent,
        AviActivityViewComponent,

        AviActivityTypeFormComponent,
        AviActivityTypeSearcherComponent,
        AviActivityTypeIndexComponent
    ],
    providers: [
        AviActivityService
    ]
})
export class AviActivityModule { }
