import { CustomProperty } from './customproperty.model';
import { Expose, Type } from 'class-transformer';

import { CustomPropCollectionDef } from '../system/custompropcollectiondef.model';
import { BaseModel } from '../shared/basemodel.model';

export class CustomPropCollection extends BaseModel {  
    @Expose()
    public get vonCustomPropCollectionDef_ID(): string { return this.getModelId('vonCustomPropCollectionDef_ID'); }
    public set vonCustomPropCollectionDef_ID(value: string) { this.setModelId('vonCustomPropCollectionDef_ID', value); }
 
    @Expose()
    @Type(() => CustomPropCollectionDef)
    public get vonCustomPropCollectionDef(): CustomPropCollectionDef { return this.getModel('vonCustomPropCollectionDef'); }
    public set vonCustomPropCollectionDef(value: CustomPropCollectionDef) { this.setModel('vonCustomPropCollectionDef', value); }
        
    // transient
    @Expose()
    @Type(() => CustomProperty)
    public get Properties(): CustomProperty[] { return this.getProperty('Properties'); }
    public set Properties(value: CustomProperty[]) { this.setProperty('Properties', value); }
}
