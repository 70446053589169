import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiErrorObject, AviApiService, AviBaseFormComponent, AviChangedAttr, AviCommonService, AviFormField, AviFormFieldService, AviListDetailConst, AviSessionControllerService } from '@avi-x/avi-core';
import { GenericRef, ListType } from '@avi-x/avi-dto/shared';
import { CustomPropCollection } from '@avi-x/avi-dto/system/custompropcollection.model';
import { CustomProperty } from '@avi-x/avi-dto/system/customproperty.model';
import { AviFieldErrorDisplayModus } from 'libs/avi-core/src/lib/components/base-form/form-field';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'avi-custompropcollection-form',
    template: ` <avi-core-base-form
        #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [(Model)]="Model"
        [loading]="Loading"
        [contents-padding]="ContentsPadding"
        [inline-modus]="InlineModus"
        field-error-position="inline"
        [label-alignment]="LabelAlignment"
        [label-width]="LabelWidth"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        (onAttrBlur)="onAttrBlur($event)"
        [validation-delegate]="ValidateFormular"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly"
        [show-buttons]="ShowButtons"
        [auto-focus]="AutoFocus"
    >
    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AviCustomPropCollectionFormComponent extends AviAbstractModelFormComponent<CustomPropCollection>  {
    ClassName = 'CORE.COMMON.MODEL.CAFCUSTOMPROPCOLLECTION';
    BaseUrl = 'custompropcollection';
    ClassType = CustomPropCollection;
	FormType = AviCustomPropCollectionFormComponent;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    @Input('collectiondef-id')
    CollectionDefId: string;

    @Input('show-buttons')
    public ShowButtons: boolean = true;

    @Input('inline-modus')
    public InlineModus = false;

    @Input('mode')
    Mode: 'normal' | 'json' = 'normal';

    @Input('json')
    public FormularJSON: string = null;

    @Input('auto-focus')
    public AutoFocus = true;

    @Input('label-alignment')
    public LabelAlignment: 'left' | 'top' = 'top';

    @Input('label-width')
    public LabelWidth: any = '160px';

    @Input('parent-editmode')
    public ParentEditMode: boolean = null;

    @Output() onCustomPropAttrBlur = new EventEmitter<AviChangedAttr>();

    saveDraftButton: any = [{ title: 'Zwischenspeichern', class: 'p-button-secondary', action: () => this.saveDraft() }];

    SaveDelegate: (model: any) => Promise<any> = null;

    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFCustomPropCollection';
    }

    public async createModel(): Promise<CustomPropCollection> {
        if (this.CollectionDefId) {
            const res = await this.apiService.postModel(CustomPropCollection, `${this.getBaseUrl()}/create`, { PropCollectionDefId: this.CollectionDefId, ContextId: this.ContextId });
            await this.afterCreateModel(res);
            return res;
        }

        return null;
    }

    async getModel(id: string): Promise<CustomPropCollection> {
        if (this.Mode == 'normal')
            return await super.getModel(id);
        else if (this.Mode == 'json' && this.FormularJSON) 
            return await this.apiService.postModel(CustomPropCollection, `${this.getBaseUrl()}/deserialize`, JSON.stringify(this.FormularJSON));

        return null;
    }

    saveModel(model: CustomPropCollection, draft: boolean = false) {
        if (this.Mode == 'normal')
            super.saveModel(model);
        else if (this.Mode == 'json') {
            this.Form.clearFormMessages();

            let delegate: Promise<any> = this.apiService.post(`${this.getBaseUrl()}/serialize`, this.Model);
            delegate.then(r => {
                        
                if (this.SaveDelegate) {
                    this.SaveDelegate(r).then(x => {
                        this.onSavedSuccessfulDelegate.emit(model);
                
                        if (!draft && this.ref)
                            this.ref.close({ Model: r, Draft: draft });
                        else {
                            this.Form.markAsPristine();
                        }
                    });
                } else {        
                    this.onSavedSuccessfulDelegate.emit(model);
                    
                    if (this.ref)
                        this.ref.close({ Model: r, Draft: draft });
                }
            }).catch(err => {
                if (err instanceof AviApiErrorObject) {
                    this.Form.addFormMessage(err.ErrorMessage, err.Type);
                } else {
                    this.Form.addFormMessage(JSON.stringify(err));
                }
            });
        }
    }

    public async afterInitForm() {
        this.initFields();
    }

    saveDraft() {
        this.saveModel(this.Model, true);
    }

    initParams(params: { [k: string]: any; }) {
        if (params) {
            this.CollectionDefId = params.CollectionDefId;
            this.FormularJSON = params.FormularJSON;
            this.Mode = params.Mode;
            this.SaveDelegate = params.SaveDelegate;
            if (params.ButtonSaveDraft)
                this.actionButtons = [...this.actionButtons, ...this.saveDraftButton ];
        }
    }
    
    getParams(): { [k: string]: any; } {
        return {
            CollectionDefId: this.CollectionDefId,
            FormularJSON: this.FormularJSON,
            Mode: this.Mode
        }
    }

    async onAttrChange(data: AviChangedAttr) {
    }

    async onAttrBlur(data: AviChangedAttr) {
        this.Form.markAsPristine();

        this.onCustomPropAttrBlur.emit(data);
    }

    getPropertyName(lProp: CustomProperty): string {
        const index = this.Model.Properties.findIndex((w) => w.Id === lProp.Id);
        const PropType = lProp.vonCAFCustomPropDef.PropertyType.Id;
        if (PropType === AviListDetailConst.ATTRTYPE_TEXT) {
            if (lProp.vonCAFCustomPropDef.IsPassword === true) return `Properties[${index}].TextValueEncrypted.Value`;
            else return `Properties[${index}].TextValue`;
        } else if (PropType === AviListDetailConst.ATTRTYPE_BOOL) return `Properties[${index}].BoolValue`;
        else if (PropType === AviListDetailConst.ATTRTYPE_DATE) return `Properties[${index}].DateValue`;
        else if (PropType === AviListDetailConst.ATTRTYPE_DECIMAL) return `Properties[${index}].DecimalValue`;
        else if (PropType === AviListDetailConst.ATTRTYPE_INTEGER) return `Properties[${index}].IntValue`;
        else if (PropType === AviListDetailConst.ATTRTYPE_LISTTYPE) return `Properties[${index}].InternalListType`;
        else if (PropType === AviListDetailConst.ATTRTYPE_REFERENCE) return `Properties[${index}].RefValue`;

        return null;
    }

    getPropertyValue(lProp: CustomProperty): any {
        const PropType = lProp.vonCAFCustomPropDef.PropertyType.Id;
        if (PropType === AviListDetailConst.ATTRTYPE_TEXT) {
            if (lProp.vonCAFCustomPropDef.IsPassword === true) return lProp.TextValueEncrypted.Value;
            else return lProp.TextValue;
        } else if (PropType === AviListDetailConst.ATTRTYPE_BOOL) return lProp.BoolValue;
        else if (PropType === AviListDetailConst.ATTRTYPE_DATE) return lProp.DateValue;
        else if (PropType === AviListDetailConst.ATTRTYPE_DECIMAL) return lProp.DecimalValue;
        else if (PropType === AviListDetailConst.ATTRTYPE_INTEGER) return lProp.IntValue;
        else if (PropType === AviListDetailConst.ATTRTYPE_LISTTYPE) return lProp.InternalListType;
        else if (PropType === AviListDetailConst.ATTRTYPE_REFERENCE) return lProp.RefValue;

        return null;
    }

    checkDependentAttribReadonly(lProp: CustomProperty) {
        // TODO
    }

    setCustomPropertyStates() {
        var propliste = this.Model.Properties.sort((a, b) => a.vonCAFCustomPropDef.Sequenz - a.vonCAFCustomPropDef.Sequenz);

        propliste
            .filter((w) => w.vonCAFCustomPropDef.Visibility.Id != AviListDetailConst.CAF_SICHTBARKEIT_SICHTBAR && w.vonCAFCustomPropDef.Visibility.Id != AviListDetailConst.CAF_SICHTBARKEIT_SICHTBAR_GUI_AUSBLENDEN_DRUCKEN)
            .forEach((lProp) => {
                const PropType = lProp.vonCAFCustomPropDef.PropertyType.Id;
                if (PropType !== AviListDetailConst.PROPERTY_TYPE_GROUP) {
                    const propname = this.getPropertyName(lProp);
                    if (propname) {

                        if (lProp.vonCAFCustomPropDef.Visibility.Id == AviListDetailConst.CAF_SICHTBARKEIT_AUSBLENDEN ||
                            lProp.vonCAFCustomPropDef.Visibility.Id == AviListDetailConst.CAF_SICHTBARKEIT_AUSBLENDEN_GUI_SICHTBAR_DRUCKEN) {
                            const field = this.Fields.find((w) => w.Name === propname);
                            if (field)
                                field.Visible = false;
                        } else if (lProp.vonCAFCustomPropDef.Visibility.Id == AviListDetailConst.CAF_SICHTBARKEIT_AUSBLENDEN_WENN_LEER &&this.ReadOnly) {
                            const wert = this.getPropertyValue(lProp);
    
                            let hidden = wert == null;
                            if (wert != null && typeof wert === 'string' && !wert?.trim()) hidden = true;
    
                            const field = this.Fields.find((w) => w.Name === propname);
                            if (field)
                                field.Visible = !hidden;
                        }
                    }
                }
            });

        if (!this.ReadOnly) propliste.forEach((prop) => this.checkDependentAttribReadonly(prop));
    }

    private getPageName(groupName: string): string {
        let tabLabel: string = null;

        let prop: CustomProperty[] = null;
        if (groupName == null)
            prop = this.Model.Properties.filter(item => item.vonCAFCustomPropDef.PropertyType.Id === AviListDetailConst.PROPERTY_TYPE_GROUP).sort((a, b) => a.vonCAFCustomPropDef.Sequenz - b.vonCAFCustomPropDef.Sequenz);
        else
            prop = this.Model.Properties.filter(item => item.vonCAFCustomPropDef.PropertyName === groupName);

        if (prop && prop.length > 0)
            tabLabel = prop[0].vonCAFCustomPropDef.Bezeichnung1;

        return tabLabel;
    }

    initFields() {
        if (!this.Model) return;

        this.Fields = [];

        const propliste = this.Model.Properties.sort((a, b) => a.vonCAFCustomPropDef.Sequenz - b.vonCAFCustomPropDef.Sequenz);
        let groups = [...new Set(propliste.map((item) => item.vonCAFCustomPropDef.GroupID?.toString() ?? item.vonCAFCustomPropDef.Id))];

        let hiddenGroup = false;
        let groupItemCnt = 0;

        let cols = this.Model.vonCustomPropCollectionDef.Columns ?? 2;

        const hasPages = !this.ReadOnly && propliste.some(item => item.vonCAFCustomPropDef.Paging === true);
        if (hasPages)
            this.Fields.push(this.formFieldService.CreateStep(this.getPageName(null)));

        groups.forEach((groupId, groupIndex) => {
            const propGroup = propliste
                .filter((item) => (item.vonCAFCustomPropDef.GroupID?.toString() ?? item.vonCAFCustomPropDef.Id) === groupId)
                .sort((a, b) => {
                    if (a.Sequenz && b.Sequenz) return a.Sequenz - b.Sequenz;
                    return a.vonCAFCustomPropDef.Sequenz - b.vonCAFCustomPropDef.Sequenz;
                });

            propGroup.forEach((lProp) => {
                const PropType = lProp.vonCAFCustomPropDef.PropertyType.Id;
                const label = lProp.vonCAFCustomPropDef.Bezeichnung1.replace('\\n', '\n');
                const propname = this.getPropertyName(lProp);

                if (PropType === AviListDetailConst.PROPERTY_TYPE_GROUP) {
                    if (lProp.vonCAFCustomPropDef.Paging === true && hasPages)
                        this.Fields.push(this.formFieldService.CreateStep(this.getPageName(lProp.vonCAFCustomPropDef.PropertyName)));

                    if (lProp.vonCAFCustomPropDef.Visibility.Id === AviListDetailConst.CAF_SICHTBARKEIT_AUSBLENDEN) {
                        hiddenGroup = true;
                        return;
                    }

                    hiddenGroup = false;
                    if (lProp.vonCAFCustomPropDef.Columns)
                        cols = lProp.vonCAFCustomPropDef.Columns;

                    //Das Layout der alten Gruppe mit Empty sauber auffuellen
                    if (groupItemCnt % cols != 0) 
                        this.Fields.push(this.formFieldService.CreateEmpty('empty').setMDSize((cols - (groupItemCnt % cols)) * (12 / cols)));

                    groupItemCnt = 0;

                    this.Fields.push(this.formFieldService.CreateGroup(label, label, true, true).setMDSize(cols * (12 / cols)));
                } else {
                    if (hiddenGroup) return;

                    let newField: AviFormField = null;

                    const nullValuePrompt = lProp.vonCAFCustomPropDef.NullValuePrompt1;
                    const mandatory = lProp.vonCAFCustomPropDef.Mandatory.Id === AviListDetailConst.JA;
                    const editMode = this.ParentEditMode != null ? this.ParentEditMode : this.isEditMode;
                    const isReadonlyInGui = lProp.vonCAFCustomPropDef.IsReadOnlyInGui.Id === AviListDetailConst.JA || this.ReadOnly || (lProp.vonCAFCustomPropDef.IsReadOnlyInGui.Id === AviListDetailConst.CAF_CUSTOMPROPERTY_READONLY_NUR_BEARBEITBAR_ERFASSEN && editMode);

                    if (PropType === AviListDetailConst.ATTRTYPE_TEXT) {
                        // TODO: lProp.vonCAFCustomPropDef.Mask, lProp.vonCAFCustomPropDef.VerwendungAls?.Id == SysListDetailConst.CAF_CUSTOMPROPERTY_VERWENDUNG_TELEFON
                        if (lProp.vonCAFCustomPropDef.MemoTextEdit === true)
                            newField = this.formFieldService.CreateTextarea(propname, label, mandatory);
                        else newField = this.formFieldService.CreateText(propname, label, mandatory);

                        if (lProp.vonCAFCustomPropDef.RegEx) newField.setPattern(lProp.vonCAFCustomPropDef.RegEx);

                        if (lProp.vonCAFCustomPropDef.PropertyLength)
                            newField.setMaxLength(lProp.vonCAFCustomPropDef.PropertyLength);
                    } else if (PropType === AviListDetailConst.PROPERTY_TYPE_LABEL) {
                        newField = this.formFieldService.CreateLabel(propname, label, false).setRequired(mandatory);
                    } else if (PropType === AviListDetailConst.ATTRTYPE_REFERENCE) {
                        if (lProp.ModelName) {
                            newField = this.formFieldService.CreateAutocomplete(propname, label, mandatory, 'Representation', 'Id', 2, `model/representations/${lProp.ModelName}`);
                            newField.DropdownValueField = (elem) => new GenericRef(elem.Id, lProp.ModelName);
                        }
                    } else if (PropType === AviListDetailConst.ATTRTYPE_LISTTYPE) {
                        if (lProp.ListName)
                            newField = this.formFieldService.CreateListType(propname, label, lProp.ListName, true, mandatory, isReadonlyInGui);
                    } else if (PropType === AviListDetailConst.ATTRTYPE_INTEGER || PropType == AviListDetailConst.ATTRTYPE_DECIMAL) {
                        newField = this.formFieldService.CreateNumber(propname, label, PropType === AviListDetailConst.ATTRTYPE_INTEGER ? 0 : lProp.vonCAFCustomPropDef.Nachkommastellen ?? 2, mandatory);

                        const minValue = lProp.vonCAFCustomPropDef.minValue?.trim() ? +lProp.vonCAFCustomPropDef.minValue : null;
                        const maxValue = lProp.vonCAFCustomPropDef.maxValue?.trim() ? +lProp.vonCAFCustomPropDef.maxValue : null;
                        newField.setMinMaxValue(isNaN(minValue) ? null : minValue, isNaN(maxValue) ? null : maxValue);
                    } else if (PropType === AviListDetailConst.ATTRTYPE_DATE) {
                        newField = this.formFieldService.CreateDate(propname, label, mandatory);

                        const minValue = lProp.vonCAFCustomPropDef.minValue?.trim() ? this.commonService.tryParseDate(lProp.vonCAFCustomPropDef.minValue) : null;
                        const maxValue = lProp.vonCAFCustomPropDef.maxValue?.trim() ? this.commonService.tryParseDate(lProp.vonCAFCustomPropDef.maxValue) : null;
                        newField.setMinMaxDate(minValue, maxValue);
                    } else if (PropType === AviListDetailConst.PROPERTY_TYPE_EMPTY) {
                        newField = this.formFieldService.CreateEmpty(propname);
                    } else if (PropType === AviListDetailConst.ATTRTYPE_BOOL) {
                        newField = this.formFieldService.CreateCheckbox(propname, null, label, false).setLabelVisible(false);
                        if (lProp.vonCAFCustomPropDef.IsRadioGroup === true) 
                            newField.BoolRadioGroupID = lProp.vonCAFCustomPropDef.RadioGroupIndex;
                        newField.FieldGroupID = lProp.vonCAFCustomPropDef.RadioGroupIndex;
                    } else if (PropType === AviListDetailConst.ATTRTYPE_MULTI_AUSWAHL) {
                        throw Error('Multi-Auswahl Felder wurden noch nicht implementiert!');
                    } else if (PropType === AviListDetailConst.ATTRTYPE_DOCUMENT_MULTI_AUSWAHL) {
                        throw Error('Multi-Auswahl Dokumente Felder wurden noch nicht implementiert!');
                    }

                    if (lProp.vonCAFCustomPropDef.Description?.trim()) {
                        // TODO: Tooltips...
                    }

                    if (newField) {
                        newField.setReadonly(isReadonlyInGui);
    
                        if (nullValuePrompt) newField.setPlaceholder(nullValuePrompt);
    
                        //Span inkl. Korrektur auf max. Anzahl Cols
                        const rest = cols - (groupItemCnt % cols);
                        let span = Math.min(lProp.vonCAFCustomPropDef.Columns ?? 0, rest);
                        span = (span == 0 ? 1 : span);
                        groupItemCnt = groupItemCnt + span;
    
                        newField.setMDSize(span * (12 / cols));
                        this.Fields.push(newField);
                    }
                }
            });
        });

        this.setCustomPropertyStates();
    }

    public canSaveForm(): boolean {
        return this.Form && this.Form.canSaveForm();
    }

    private getDependentMandatoryProps(property: CustomProperty): string[]
    {
        if (!property.vonCAFCustomPropDef.MandatoryWennAttrName) return [];

        return property.vonCAFCustomPropDef.MandatoryWennAttrName.split(',').map(w => w?.trim()).filter(w => w);
    }

    public SetInlineModus() {
        this.Fields.forEach((w) => this.formFieldService.setFieldDisabled(this.Fields, w.Name, true));
        this.cdr.markForCheck();
    }

    private checkNull(value: any, boolFalseAsNull = true)
    {
        if (typeof value === 'string')
            return value == null || value == '';
        else if (value instanceof GenericRef)
            return value == null || (<GenericRef>value).ModelID == null || (<GenericRef>value).ModelID === this.commonService.GuidEmpty;
        else if (typeof value === 'boolean')
            return value == null || (boolFalseAsNull && (<boolean>value) === false);

        return value == null;
    }

    private getStringValue(property: CustomProperty): string
    {
        const value = this.getPropertyValue(property);

        if (value instanceof ListType)
            return (<ListType>value).Bezeichnung;

        return value as string;
    }

    public checkAttribut(property: CustomProperty): string
    {
        let res: string = null;
        if (property != null)
        {
            if (property.vonCAFCustomPropDef.Visibility.Id === AviListDetailConst.CAF_SICHTBARKEIT_AUSBLENDEN)
                return null;

            const isRadiogroup = property.vonCAFCustomPropDef.PropertyType.Id === AviListDetailConst.ATTRTYPE_BOOL && property.vonCAFCustomPropDef.RadioGroupIndex != null && property.vonCAFCustomPropDef.RadioGroupIndex >= 0;

            // mandatory dependent on attrib value
            this.getDependentMandatoryProps(property).forEach(dependentPropertyName => {
                const dependentPropertyValue = property.vonCAFCustomPropDef.MandatoryWennAttrValue;

                let dependentProperty: CustomProperty = null;
                let value = null;
                let dependentValue = null;
                if (dependentPropertyName)
                {
                    const dp = this.Model.Properties.filter(item => item.vonCAFCustomPropDef.PropertyName === dependentPropertyName);
                    dependentProperty = dp.length > 0 ? dp[0] : null;
                    if (dependentProperty != null)
                    {
                        value = this.getPropertyValue(property);
                        dependentValue = this.getPropertyName(dependentProperty);

                        if (!isRadiogroup)
                        {
                            if (this.checkNull(dependentPropertyValue))
                            {
                                if (this.checkNull(dependentValue, false) && this.checkNull(value, false))
                                    res = `Entweder '${property.vonCAFCustomPropDef.Bezeichnung1}' oder '${dependentProperty.vonCAFCustomPropDef.Bezeichnung1}' muss gesetzt sein`.replace('\n', ' ');
                            }
                            else
                            {
                                if (this.checkNull(value, false) && !this.checkNull(dependentValue, false) && this.getStringValue(dependentProperty) == dependentPropertyValue)
                                res = `Feld '${property.vonCAFCustomPropDef.Bezeichnung1}' darf nicht leer sein`.replace('\n', ' ');
                            }
                        }
                    }
                }

                // selektion in Radiogroup
                if (isRadiogroup && (dependentProperty != null && !this.checkNull(dependentValue, false) && this.getStringValue(dependentProperty) == dependentPropertyValue))
                {
/*                    const firstProp = this._Collection.Properties.filter(w => w.vonCAFCustomPropDef.RadioGroupIndex === property.vonCAFCustomPropDef.RadioGroupIndex && w.vonCAFCustomPropDef.PropertyType.Id === AviListDetailConst.ATTRTYPE_BOOL).sort((a,b) => a.vonCAFCustomPropDef.Sequenz - b.vonCAFCustomPropDef.Sequenz)[0];
                    if (firstProp?.Id === property.Id)*/
                    {
                        if (!this.Model.Properties.some(w => w.vonCAFCustomPropDef.RadioGroupIndex === property.vonCAFCustomPropDef.RadioGroupIndex && w.vonCAFCustomPropDef.PropertyType.Id === AviListDetailConst.ATTRTYPE_BOOL && w.BoolValue === true))
                            res = 'Es muss eine Auswahl gemacht werden';
                    }
                }
            });

            // selektion in Radiogroup
            if (isRadiogroup && (property.vonCAFCustomPropDef.Mandatory.Id === AviListDetailConst.JA))
            {
/*                const firstProp = this._Collection.Properties.filter(w => w.vonCAFCustomPropDef.RadioGroupIndex === property.vonCAFCustomPropDef.RadioGroupIndex && w.vonCAFCustomPropDef.PropertyType.Id === AviListDetailConst.ATTRTYPE_BOOL).sort((a,b) => a.vonCAFCustomPropDef.Sequenz - b.vonCAFCustomPropDef.Sequenz)[0];
                if (firstProp?.Id === property.Id)*/
                {
                    if (!this.Model.Properties.some(w => w.vonCAFCustomPropDef.RadioGroupIndex === property.vonCAFCustomPropDef.RadioGroupIndex && w.vonCAFCustomPropDef.PropertyType.Id === AviListDetailConst.ATTRTYPE_BOOL && w.BoolValue === true))
                        res = 'Es muss eine Auswahl gemacht werden';
                }
            }

            return res;
        }
    }
    
    ValidateFormular = (): boolean => {
        let result: boolean = true;

        if (this.Model)
        {
            var propliste = this.Model.Properties;
    
            propliste
                .forEach((lProp) => {
                    const res = this.checkAttribut(lProp);
                    const propname = this.getPropertyName(lProp);
                        if (propname) {
                        const field = this.Fields.find(c => c.Name === propname);
                        if (field) {
                            if (res) {
                                result = false;
                                field.addError(res, 'error', AviFieldErrorDisplayModus.TOUCHED);
                            }
                            else 
                                field.clearErrors();
                        }
                    }
                });
        }

        return result;
    };
}
