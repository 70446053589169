import { Component, OnInit, AfterViewInit, Input, ViewEncapsulation, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridType, GridsterComponent, GridsterItemComponentInterface, GridsterComponentInterface } from 'angular-gridster2';
import { AviCommonService } from '../../services/common.service';
import { AviFormField } from '../base-form/form-field';
import { AviDialogComponent } from '../dialog/dialog.component';

export interface MyGridsterItem extends GridsterItem {
    id: string;
    label: string;
}

@Component({
    selector: 'avi-core-base-form-designer',
    templateUrl: './base-form-designer.component.html',
    styleUrls: ['./base-form-designer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AviBaseFormDesignerComponent implements OnInit, AfterViewInit {
    options: GridsterConfig;
    dashboard: Array<MyGridsterItem>;
    VisibleItems: Array<MyGridsterItem>;

    @Input('height')
    height: string = '60vh';

    @ViewChild('dialog', { static: false })
    dialog: AviDialogComponent;

    private _Fields: AviFormField[];

    @Input()
    set fields(value: AviFormField[]) {
        this._Fields = value;
        this.fieldsChange.emit(value);
    }

    get fields(): AviFormField[] {
        return this._Fields;
    }

    @Output()
    fieldsChange = new EventEmitter();

    constructor(public commonService: AviCommonService, public ref: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.options = {
            gridType: GridType.VerticalFixed,
            compactType: CompactType.CompactLeftAndUp,
            margin: 4,
            outerMargin: false,
            outerMarginTop: null,
            outerMarginRight: null,
            outerMarginBottom: null,
            outerMarginLeft: null,
            useTransformPositioning: true,
            mobileBreakpoint: 0,
            minCols: 12,
            maxCols: 12,
            minRows: 1,
            maxRows: 50,
            maxItemCols: 12,
            minItemCols: 1,
            maxItemRows: 1,
            minItemRows: 1,
            maxItemArea: 2500,
            minItemArea: 1,
            defaultItemCols: 6,
            defaultItemRows: 1,
            fixedColWidth: 80,
            fixedRowHeight: 55,
            keepFixedHeightInMobile: false,
            keepFixedWidthInMobile: false,
            scrollSensitivity: 10,
            scrollSpeed: 20,
            enableEmptyCellClick: false,
            enableEmptyCellContextMenu: false,
            enableEmptyCellDrop: false,
            enableEmptyCellDrag: false,
            emptyCellDragMaxCols: 12,
            emptyCellDragMaxRows: 50,
            ignoreMarginInRow: false,
            draggable: {
                enabled: true,
            },
            resizable: {
                enabled: true
            },
            swap: true,
            pushItems: true,
            disablePushOnDrag: false,
            disablePushOnResize: false,
            pushDirections: { north: true, east: true, south: true, west: true },
            pushResizeItems: false,
            displayGrid: DisplayGrid.None,
            disableWindowResize: false,
            disableWarnings: true,
            scrollToNewItems: false
        };
    }

    ngAfterViewInit() {
    }

    public openDialog() {
        this.dashboard = [];

        let idx = 0;
        this._Fields.forEach(w => {
            const res = {
                id: w.Name,
                label: w.Label,
                x: idx % 12,
                y: Math.floor(idx / 12),
                rows: 1,
                cols: w.MDSize,
                hidden: w.HiddenToCustomization,
                required: w.Required
            };

            idx += w.MDSize;

            this.dashboard.push(res);
        });

        this.VisibleItems = this.getVisibleItems();

        this.dialog.open();
    }

    getVisibleItems() {
        if (this.dashboard)
            return this.dashboard.filter(w => !w.hidden);
        return null;
    }

    setVisibleItems(evt) {
        this.dashboard.filter(w => w.hidden = evt.findIndex(x => x.id === w.id) === -1);
    }

    doCancel() {
        this.dialog.close();
    }

    HideItem(item: MyGridsterItem) {
        item.hidden = true;

        this.VisibleItems = this.getVisibleItems();
    }

    doSave() {
        this.dashboard.forEach(w => {
            const field = this._Fields.filter(x => x.Name === w.id);
            if (field.length === 1) {
                field[0].MDSize = w.cols;
                field[0].HiddenToCustomization = w.hidden;
            }
        });

        this.fields = this._Fields.filter(w => !w.HiddenToCustomization).sort((x, y) => {
            const item1 = this.dashboard.find(w => w.id === x.Name);
            const item2 = this.dashboard.find(w => w.id === y.Name);
            return (item1.x + item1.y * 12) - (item2.x + item2.y * 12);
        }).concat(this._Fields.filter(w => w.HiddenToCustomization).sort((x, y) => {
            const item1 = this.dashboard.find(w => w.id === x.Name);
            const item2 = this.dashboard.find(w => w.id === y.Name);
            return (item1.x + item1.y * 12) - (item2.x + item2.y * 12);
        }));

        this.dialog.close();
    }
}
