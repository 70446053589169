import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatTabNav } from '@angular/material/tabs';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'vwbp-admin-jobdef-index',
    templateUrl: './vwbp-admin-jobdef-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPAdminJobDefIndexComponent {

    navLinks = [
        { path: './def', label: 'CORE.COMMON.MODEL.JOBDEF.JOBDEF_1' },
        { path: './jobs', label: 'CORE.COMMON.MODEL.JOBDEF.ALLEJOBS' },
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public translateService: TranslateService
    ) {
    }
}
