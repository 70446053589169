import { Component, OnInit, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AviApiService, AviCommunicationService, AviPartnerListDetailConst } from '@avi-x/avi-core';
import { AviPartnerService } from '../../services/partner.service';

import { AdresseDetail } from '@avi-x/avi-dto/partner/adressedetail.model';
import { BasePartner } from '@avi-x/avi-dto/partner/basepartner.model';
import { PartnerKommunikation } from '@avi-x/avi-dto/partner/partnerkommunikation.model';

@Component({
    selector: 'avi-crm-partner-searcher-detailview',
    templateUrl: './partner-searcher-detailview.component.html',
    styleUrls: ['./partner-searcher-detailview.component.css']
})
export class AviPartnerSearcherDetailviewComponent implements OnInit {
    public Partner: BasePartner;
    public Kommunikationen: PartnerKommunikation[];
    public Adresse: AdresseDetail;
    public MapsUrl: SafeResourceUrl;
    public MapsVisible: boolean = false;

    @Input('partnerId')
    public PartnerId: string;

    @Input('adresseId')
    public AdresseId: string;

    constructor(public partnerService: AviPartnerService,
                public apiService: AviApiService,
                private communicationService: AviCommunicationService) {
    }

    ngOnInit() {
        this.loadDetailView();
    }

    public async loadDetailView() {
        this.MapsVisible = false;
        this.Partner = null;
        this.Adresse = null;
        this.Kommunikationen = [];

        this.Partner = await this.apiService.getModelById(BasePartner, `/basePartner/${this.PartnerId}`, null);

        if (this.AdresseId != null)
            this.Adresse = (await this.apiService.getModelList(AdresseDetail,
                `adressedetail?init=vonAdresse,land&filter=(vonAdresse.vonPartner_ID eq ${this.PartnerId}) and (zustandBeginn le today) and (zustandEnde ge today) and (vonAdresse.id eq ${this.AdresseId})`
            ))[0];
        else
            this.Adresse = (await this.apiService.getModelList(AdresseDetail,
            `adressedetail?init=vonAdresse,land&filter=(vonAdresse.id eq ${this.Partner.DomizilAdresseGUID}) and (zustandBeginn le today) and (zustandEnde ge today)`
        ))[0];

        this.Kommunikationen = await this.apiService.getModelList(PartnerKommunikation,
            `partnerkommunikation?filter=(vonPartner_ID eq ${this.PartnerId}) and (gueltigAb le today) and (gueltigBis ge today)`
        );

        this.Kommunikationen = this.Kommunikationen.sort((x, y) => this.kommIndex(x) - this.kommIndex(y));
        this.MapsUrl = this.communicationService.GetGoogleMapsUrl({
            LandISO: this.Adresse.Land ? this.Adresse.Land.ISOCode : null,
            Strasse: this.Adresse.Strasse,
            Ort: this.Adresse.Ort,
            PLZ: this.Adresse.PLZ
        });
    }

    private kommIndex(x: PartnerKommunikation): number {
        if (x.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_TELEFON) return 1;
        if (x.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_MOBILE) return 2;
        if (x.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_VOIP) return 3;
        if (x.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_EMAIL) return 4;
        if (x.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_HOMEPAGE) return 5;
        if (x.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_CHAT) return 6;
        if (x.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_FAX) return 7;
        if (x.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_PAGER) return 8;
        if (x.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_PAGER_NUMMERISCH) return 9;
        return 10;
    }

    public isHomepage(eingabe: PartnerKommunikation): boolean {
        return eingabe.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_HOMEPAGE;
    }

    public isTelefonnummer(eingabe: PartnerKommunikation): boolean {
        return eingabe.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_TELEFON ||
            eingabe.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_FAX ||
            eingabe.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_MOBILE ||
            eingabe.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_VOIP;
    }

    public isEmail(eingabe: PartnerKommunikation): boolean {
        return eingabe.KommArt.Id === AviPartnerListDetailConst.CAF_PARTNERKOMM_ART_EMAIL;
    }

    public openHomepage(data: string) {
        this.communicationService.OpenHomepage(data);
    }

    public click2Call(data: string) {
        this.communicationService.StartCall(data);
    }

    public click2Email(data: string) {
        this.communicationService.StartEmail(data);
    }
}
