import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AviCanActivateWhenLoggedInService } from '@avi-x/avi-core';
import { AviDokumentDashboardComponent } from './components/dokument-dashboard/dokument-dashboard.component';

const routes: Routes = [
    { path: 'dokument-dashboard', component: AviDokumentDashboardComponent, canActivate: [AviCanActivateWhenLoggedInService] }
];

@NgModule({
    imports: [        
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class AviDokumentRoutingModule {
}
