import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviListDetailConst, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BusinesscaseDef } from '@avi-x/avi-dto/businesscase/businesscasedef.model';
import { StatusWorkflowDefSearchResponseDto } from '@avi-x/avi-dto/workflow/statusworkflowdefsearchresponsedto.model';
import { ReferenceDto } from '@avi-x/avi-dto/shared/referencedto.model';

const nameof = (nameFunction: ((obj: BusinesscaseDef) => any)) => typed_nameof<BusinesscaseDef>(nameFunction);

@Component({
    selector: 'avi-businesscasedef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [label-alignment]="ReadOnly ? 'left' : 'top'"
        [label-width]="'160px'"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviBusinesscaseDefFormComponent extends AviAbstractModelFormComponent<BusinesscaseDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF';
    BaseUrl = 'businesscase/businesscasedef';
    ClassType = BusinesscaseDef;
    FormType = AviBusinesscaseDefFormComponent;
	
    @Input('mode')
    public Mode: 'normal' | 'def' | 'contexts' = 'normal';
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFBusinesscaseDef';
    }

    public async afterInitForm() {
        await this.setFromContext(1, false);
        await this.setFromContext(2, false);
        await this.setFromDescription();
    }

    async setFromContext(idx: number, setBezeichnung: boolean) {
        const modelMetaID = this.Model.getProperty<string>(`Context${idx}ModelMeta_ID`);
        this.formFieldService.setFieldReadonly(this.Fields, `Context${idx}Mandatory`, !modelMetaID);
        this.formFieldService.setFieldReadonly(this.Fields, `Context${idx}CtxRights `, !modelMetaID);

        if (modelMetaID == null) {
            for (let i=1; i<=7; ++i) {
                const fieldName = `Context${idx}Bezeichnung${i}`;
                if (setBezeichnung)
                    this.Model.setProperty<string>(fieldName, null);
                this.formFieldService.setFieldReadonly(this.Fields, fieldName, true);
                this.formFieldService.setFieldRequired(this.Fields, fieldName, false);
            }
            const nein = await this.getListType('janein', AviListDetailConst.NEIN);
            this.Model.setProperty(`Context${idx}Mandatory`, nein);
            const nicht_pruefen = await this.getListType('caf_businesscase_contextrights', AviListDetailConst.CAF_BUSINESSCASE_CONTEXTRIGHTS_NICHT_PRUEFEN);
            this.Model.setProperty(`Context${idx}CtxRights`, nicht_pruefen);
        }
        else
        {
            const rlist = await this.apiService.getModelList(ReferenceDto, `model/representations/ModelMeta?id=${modelMetaID}`);
            if (rlist?.length === 1) {
                let repr = rlist[0].Representation;
                if (repr.startsWith('CAF'))
                    repr = repr.substring(3);

                for (let i=1; i<=7; ++i) {
                    const fieldName = `Context${idx}Bezeichnung${i}`;
                    if (setBezeichnung)
                        this.Model.setProperty<string>(fieldName, repr);
                    this.formFieldService.setFieldReadonly(this.Fields, fieldName, false);
                    this.formFieldService.setFieldRequired(this.Fields, fieldName, true);
                }
            }
        }    
    }


    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1)) {
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung3 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung4 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung5 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung6 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung7 = this.Model.Bezeichnung1;
        }

        else if (data.field === nameof(c => c.KurzBez1)) {
            this.Model.KurzBez2 = this.Model.KurzBez1;
            this.Model.KurzBez3 = this.Model.KurzBez1;
            this.Model.KurzBez4 = this.Model.KurzBez1;
            this.Model.KurzBez5 = this.Model.KurzBez1;
            this.Model.KurzBez6 = this.Model.KurzBez1;
            this.Model.KurzBez7 = this.Model.KurzBez1;
        }

        else if (data.field === nameof(c => c.Context1ModelMeta_ID))
            await this.setFromContext(1, !this.ReadOnly);

        else if (data.field === nameof(c => c.Context2ModelMeta_ID))
            await this.setFromContext(2, !this.ReadOnly);

        else if (data.field === nameof(c => c.ShowDescription))
            await this.setFromDescription();
    }

    async setFromDescription() {
        this.formFieldService.setFieldReadonly(this.Fields, nameof(c => c.DescriptionMandatory), this.Model.ShowDescription.Id === AviListDetailConst.NEIN);
        if (this.Model.ShowDescription.Id === AviListDetailConst.NEIN && this.Model.DescriptionMandatory.Id === AviListDetailConst.JA)
            this.Model.DescriptionMandatory = await this.getListType('janein', AviListDetailConst.NEIN);
    }

    queryStatusWorkflowDef = async (field: any, modelId: string, query: string) => {
        const ret = [];
        if (this.Model) {
            const cfg: any = { Query: query, ModelId: modelId, Typ: AviListDetailConst.CAF_STATUSWORKFLOW_TYP_BEWILLIGUNGSPROZESS, ContextModelName: 'CAFBusinesscase', };
            const p = await this.apiService.postPagedResult(StatusWorkflowDefSearchResponseDto, `workflow/statusworkflowdef/search`, cfg);
            p.Results?.forEach(elem => ret.push({ label: elem.Bezeichnung, value: elem.Id, valueFull: elem }));
        }
        return ret;
    }
    
    getParams(): { [k: string]: any; } {
        return { 'Mode': this.Mode };
    }
    
    initParams(params: { [k: string]: any; }) {
        if (params && params.Mode)
            this.Mode = params.Mode;
    }

    initFields() {
        // BusinesscaseDef
        if (this.Mode === 'normal' || this.Mode === 'def') {
            if (this.Mode === 'normal')
                this.Fields.push(this.formFieldService.CreateStep('Geschäftsfall Definition'));
    
            if (this.Mode === 'def' || this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonWorkflowDef_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.VONWORKFLOWDEF', this.queryStatusWorkflowDef, true).setAutoselectSingle().setMDSizeFull());
    
            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigAb), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.GUELTIGAB', true).setMDSizeHalf().setNullDateLow());
            this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.GueltigBis), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.GUELTIGBIS', true).setMDSizeHalf().setNullDateHigh());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.KURZBEZ1', true).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.BEZEICHNUNG1', true).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.KURZBEZ2', true).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.BEZEICHNUNG2', true).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.KURZBEZ3', true).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.BEZEICHNUNG3', true).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.KURZBEZ4', true).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.BEZEICHNUNG4', true).setMDSizeHalf());        
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.DefaultTitle), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.DEFAULTTITLE', true).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.StartNumber), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.STARTNUMBER', 0, false).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ShowDescription), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.SHOWDESCRIPTION', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DescriptionMandatory), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.DESCRIPTIONMANDATORY', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.NumberPrefix), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.NUMBERPREFIX', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ShowDef), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.SHOWDEF', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DefaultPriority), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.DEFAULTPRIORITY', 'caf_businesscase_priority', true, true, this.ReadOnly).setMDSizeHalf());            
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ShowPriority), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.SHOWPRIORITY', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.CreateRole_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CREATEROLE', false, 'Representation', 'Id', 2, `model/representations/TechRole`).setMDSizeHalf());    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.CreateVisibility), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CREATEVISIBILITY', 'caf_businesscase_sichtbarkeit', true, true, this.ReadOnly).setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.EditFormOnCreate), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.EDITFORMONCREATE', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.CanCreateComments), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CANCREATECOMMENTS', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.CanCreateActivities), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CANCREATEACTIVITIES', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.BusinesscaseDefId), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.BUSINESSCASEDEFID', false).setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.CustomPropCollectionDef_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CUSTOMPROPCOLLECTIONDEF', `model/representations/CAFCustomPropCollectionDef${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Watcher), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.WATCHER', 'caf_businesscase_watcher', true, true, this.ReadOnly).setMDSizeHalf());
        }

        // Kontexte
        if (this.Mode === 'normal' || this.Mode === 'contexts') {    
            if (this.Mode === 'normal')
                this.Fields.push(this.formFieldService.CreateStep('Kontexte'));
    
            if (this.Mode === 'contexts' || this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateGroup('grp2', 'Kontexte'));
            
            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Context1ModelMeta_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1MODELMETA', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Context1Mandatory), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1MANDATORY', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Context1CtxRights), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1CTXRIGHTS', 'caf_businesscase_contextrights', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('ctxfill1'));

            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung1), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1BEZEICHNUNG1', false).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung2), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1BEZEICHNUNG2', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung3), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1BEZEICHNUNG3', false).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung4), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1BEZEICHNUNG4', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung5), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1BEZEICHNUNG5', false).setMDSizeHalf());        
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung6), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1BEZEICHNUNG6', false).setMDSizeHalf());
    
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context1Bezeichnung7), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT1BEZEICHNUNG7', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('ctxfill2'));

            this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Context2ModelMeta_ID), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2MODELMETA', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Context2Mandatory), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2MANDATORY', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Context2CtxRights), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2CTXRIGHTS', 'caf_businesscase_contextrights', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('ctxfill3'));

            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung1), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2BEZEICHNUNG1', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung2), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2BEZEICHNUNG2', false).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung3), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2BEZEICHNUNG3', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung4), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2BEZEICHNUNG4', false).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung5), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2BEZEICHNUNG5', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung6), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2BEZEICHNUNG6', false).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Context2Bezeichnung7), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.CONTEXT2BEZEICHNUNG7', false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('ctxfill4'));
        }
        
        // Fälligkeiten
        if (this.Mode === 'normal' || this.Mode === 'def') {
            if (this.Mode === 'normal')
                this.Fields.push(this.formFieldService.CreateStep('Fälligkeiten'));
            
            if (this.Mode === 'def' || this.ReadOnly)
                this.Fields.push(this.formFieldService.CreateGroup('grp1', 'Fälligkeiten'));
    
            this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.ShowDueDate), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.SHOWDUEDATE', 'janein', true, true, this.ReadOnly).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateEmpty('filler1'));
            
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DueIn_Months), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.DUEIN_MONTHS', 0, false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DueIn_Days), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.DUEIN_DAYS', 0, false).setMDSizeHalf());
            
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DueIn_Hours), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.DUEIN_HOURS', 0, false).setMDSizeHalf());
            this.Fields.push(this.formFieldService.CreateNumber(nameof(c => c.DueIn_Minutes), 'CORE.COMMON.MODEL.CAFBUSINESSCASEDEF.DUEIN_MINUTES', 0, false).setMDSizeHalf());
        }
    }
}
