import { Component } from '@angular/core';

@Component({
    selector: 'vwbp-intern-kontrollwesen-allerechtstraeger',
    template: `
         <vwbp-intern-rechtstraeger-searcher
             [use-router-params]="true"
             settingskey="settings.searcher.kw.alle_rt"
             [show-deleted-filter]="true"
             [checbox-selection]="true"
             [visible-columns]="['Name', 'RegisterNummer', 'HrStatus', 'Rechtsform', 'RegisterDatum', 'Erfassungsstatus', 'Uebergabestatus', 'Freigabedatum', 'HrLoeschungsdatum', 'ValidierungAusnahme', 'BemerkungTeilfonds', 'SchwaerzungVorhanden', 'EingetragenEWRRegister', 'OwnerUsername', 'OnwerName', 'OwnerVorname', 'btnOpen']">
         </vwbp-intern-rechtstraeger-searcher>`
})
export class VwbPInternKontrollwesenAlleRechtstraegerComponent {
    constructor() { }
}
