import { Expose, Type } from 'class-transformer';

import { AppUserGroup } from '../system/appusergroup.model';
import { BaseModel } from '../shared/basemodel.model';

export class AppUserGroupLogEntry extends BaseModel {  
    @Expose()
    public get Hintergrund(): string { return this.getProperty('Hintergrund'); }
    public set Hintergrund(value: string) { this.setProperty('Hintergrund', value); }
    
    @Expose()
    public get GroupsAdded(): string { return this.getProperty('GroupsAdded'); }
    public set GroupsAdded(value: string) { this.setProperty('GroupsAdded', value); }
    
    @Expose()
    public get GroupsRemoved(): string { return this.getProperty('GroupsRemoved'); }
    public set GroupsRemoved(value: string) { this.setProperty('GroupsRemoved', value); }
    
    @Expose()
    public get RolesAdded(): string { return this.getProperty('RolesAdded'); }
    public set RolesAdded(value: string) { this.setProperty('RolesAdded', value); }
    
    @Expose()
    public get RolesRemoved(): string { return this.getProperty('RolesRemoved'); }
    public set RolesRemoved(value: string) { this.setProperty('RolesRemoved', value); }
    
    @Expose()
    public get vonAppUserGroup_ID(): string { return this.getModelId('vonAppUserGroup_ID'); }
    public set vonAppUserGroup_ID(value: string) { this.setModelId('vonAppUserGroup_ID', value); }
 
    @Expose()
    @Type(() => AppUserGroup)
    public get vonAppUserGroup(): AppUserGroup { return this.getModel('vonAppUserGroup'); }
    public set vonAppUserGroup(value: AppUserGroup) { this.setModel('vonAppUserGroup', value); }

    get rolesAddAsMultiLineString(): string {
        if (!this.RolesAdded)
            return "";
        return this.RolesAdded.split(",").join("\r\n");
    }

    get rolesRemovedAsMultiLineString(): string {
        if (!this.RolesRemoved)
            return "";
        return this.RolesRemoved.split(",").join("\r\n");
    }
    
}
