import { Expose, Type } from 'class-transformer';

import { IDTO } from './../shared/idto.model';
import { ListType } from '../shared/listtype.model';

export class AppUserGroupSearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

    @Expose()
	public AppUserGroupTyp: string;

    @Expose()
	public Bezeichung: string;

    @Expose()
	public KurzBez: string;
}
