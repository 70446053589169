import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviAuthService, AviBaseFormComponent, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { VwbPRechtsformDef } from '../../../dto/vwbprechtsformdef.model';

const nameof = (nameFunction: ((obj: VwbPRechtsformDef) => any)) => typed_nameof<VwbPRechtsformDef>(nameFunction);

@Component({
    selector: 'vwbp-admin-rechtsformdef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminRechtsformDefFormComponent implements OnInit, OnDestroy {
    private _className = 'Rechtsform-Definition';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: VwbPRechtsformDef = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string {
        return this._ModelId;
    }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {
        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));
        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    async onAttrChange(data: AviChangedAttr) {
    }

    public async initNewModel(data?: any) {
        const emptyGuid = this.commonService.GuidEmpty;
        this.Model = await this.apiService.getModel(VwbPRechtsformDef, `v1/intern/vwbp/rechtstraeger/rechtsformdef/${emptyGuid}`);
        this.isEditMode = false;
        this.updateDropdownSources();
    }

    ngOnInit() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.ModelId = params['id'];
            }
        });

        this.initFields();
    }


    deleteModel(model: any) {
        this.apiService.delete(`v1/intern/vwbp/rechtstraeger/rechtsformdef/${model.id}`).then(r => {
            if (this.RedirectAfterDelete)
                this.router.navigate([this.RedirectAfterDelete]);
        });
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: VwbPRechtsformDef) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            delegate = this.apiService.put(`/v1/intern/vwbp/rechtstraeger/rechtsformdef/${model.Id}`, model);
        } else {
            delegate = this.apiService.post('/v1/intern/vwbp/rechtstraeger/rechtsformdef', model);
        }
        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.RedirectAfterSave)
                this.router.navigate([this.RedirectAfterSave]);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {
        this.Model = await this.apiService.FindModel(VwbPRechtsformDef, `v1/intern/vwbp/rechtstraeger/rechtsformdef/${id}`, null, null, null);

        this.updateDropdownSources();
        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        return this.Model;
    }

    async updateDropdownSources() {
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);
        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Rechtsform), 'Rechtsform', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Typ), 'Typ', 'vwbp_rechtsform_typ', true, true, false, 'v1/vwbp').setMDSizeFull());
    }
}
