import { Component, Inject, forwardRef } from '@angular/core';
import { AppComponent } from './app.component';

@Component({
    selector: 'avi-vwbp-topbar',
    template: `

        <div class="layout-topbar clearfix">

            <div class="layout-topbar-left">
                <div class="logo"></div>
               <!-- <div class="logo-text">AviX</div> -->
            </div>

            <div class="layout-topbar-right">

                <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
                    <i></i>
                </a>

                <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
                    <i class="material-icons">menu</i>
                </a>

                <a id="rightpanel-menu-button" href="#" (click)="app.onRightPanelButtonClick($event)">
                    <!-- <i class="material-icons">more_vert</i> -->
                    <i class="pi pi-ellipsis-v fs-large"></i>
                </a>

                <div id="header-title-container">

                    <a routerLink="/" class="mr-20" pTooltip="Startseite" tooltipPosition="bottom"><i class="material-icons">home</i></a>
                    <a routerLink="/crm/partner" class="mr-20" pTooltip="Partner suchen" tooltipPosition="bottom"><i class="material-icons">perm_identity</i></a>

                    <!-- <a routerLink="/core/profile" class="mr-20 ml-20" pTooltip="Mein Profil" tooltipPosition="bottom"><i class="material-icons">account_box</i></a> -->

                </div>

                <ul class="topbar-items animated fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">
                    <li *ngIf="!app.authService.IsLoggedIn" role="menuitem">
                        <a [routerLink]="['core/login']"><i class="topbar-icon material-icons">person</i></a>
                    </li>

                    <li #profile class="profile-item" *ngIf="app.authService.IsLoggedIn && (app.profileMode==='top'||app.isHorizontal())"
                        [ngClass]="{'active-top-menu':app.activeTopbarItem === profile}">

                        <a href="#" (click)="app.onTopbarItemClick($event,profile)">
                            <!--<div class="profile-image"></div>-->
							<span *ngIf="app.isDesktop()" class="topbar-username" >{{app.authService.CurrentUser.Username}}</span>
                            <span class="topbar-item-name">{{app.authService.CurrentUser.Username}}</span>
                        </a>

                        <ul class="ultima-menu animated fadeInDown">
                            <li role="menuitem">
                                <a [routerLink]="['vwbp/profile']">
                                    <i class="material-icons">person</i>
                                    <span>Profil</span>
                                </a>
                            </li>
<!--
                            <li role="menuitem">
                                <a href="#">
                                    <i class="material-icons">security</i>
                                    <span>Privacy</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="material-icons">settings_applications</i>
                                    <span>Settings</span>
                                </a>
                            </li>
-->
                            <li role="menuitem">
                                <a (click)="app.Logout()">
                                    <i class="material-icons">power_settings_new</i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li #settings [ngClass]="{'active-top-menu':app.activeTopbarItem === settings}">
                        <a href="#" (click)="app.onTopbarItemClick($event,settings)">
                            <i class="topbar-icon material-icons">settings</i>
                            <span class="topbar-item-name">Settings</span>
                        </a>
                        <ul class="ultima-menu animated fadeInDown">
                            <li role="menuitem">
                                <a href="#">
                                    <i class="material-icons">palette</i>
                                    <span>Hier was</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="material-icons">favorite_border</i>
                                    <span>Favoriten</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a (click)="app.Logout()">
                                    <i class="material-icons">lock</i>
                                    <span>Logout</span>
                                </a>
                            </li>

                        </ul>
                    </li>
                    <!--
                    <li #messages [ngClass]="{'active-top-menu':app.activeTopbarItem === messages}">
                        <a href="#" (click)="app.onTopbarItemClick($event,messages)">
                            <i class="topbar-icon material-icons animated swing">message</i>
                            <span class="topbar-badge animated rubberBand">5</span>
                            <span class="topbar-item-name">Messages</span>
                        </a>
                        <ul class="ultima-menu animated fadeInDown">
                            <li role="menuitem">
                                <a href="#" class="topbar-message">
                                    <img src="assets/layout/images/avatar1.png" width="35"/>
                                    <span>Give me a call</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message">
                                    <img src="assets/layout/images/avatar2.png" width="35"/>
                                    <span>Sales reports attached</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message">
                                    <img src="assets/layout/images/avatar3.png" width="35"/>
                                    <span>About your invoice</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message">
                                    <img src="assets/layout/images/avatar2.png" width="35"/>
                                    <span>Meeting today at 10pm</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message">
                                    <img src="assets/layout/images/avatar4.png" width="35"/>
                                    <span>Out of office</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li #notifications [ngClass]="{'active-top-menu':app.activeTopbarItem === notifications}">
                        <a href="#" (click)="app.onTopbarItemClick($event,notifications)">
                            <i class="topbar-icon material-icons">timer</i>
                            <span class="topbar-badge animated rubberBand">4</span>
                            <span class="topbar-item-name">Notifications</span>
                        </a>
                        <ul class="ultima-menu animated fadeInDown">
                            <li role="menuitem">
                                <a href="#">
                                    <i class="material-icons">bug_report</i>
                                    <span>Pending tasks</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="material-icons">event</i>
                                    <span>Meeting today at 3pm</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="material-icons">file_download</i>
                                    <span>Download documents</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#">
                                    <i class="material-icons">flight</i>
                                    <span>Book flight</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li #search class="search-item" [ngClass]="{'active-top-menu':app.activeTopbarItem === search}"
                        (click)="app.onTopbarItemClick($event,search)">
                        <span class="md-inputfield">
                            <input type="text" pInputText>
                            <label>Search</label>
                            <i class="topbar-icon material-icons">search</i>
                        </span>
                    </li>

-->

                    <li *ngIf="app.docService.IsOnline()" role="menuitem">
                        <a routerLink="open-docs" pTooltip="Meine Dokumente">
                            <i [ngClass]="{'topbar-icon': true, 'material-icons': true}">description</i>
                        <span *ngIf="app.docService.CheckedOutDocuments.length > 0" class="topbar-badge animated rubberBand">{{app.docService.CheckedOutDocuments.length}}</span>
                    </a>
                    </li>

                </ul>
            </div>
        </div>
    `
})
export class AviVwbPTopbarComponent {

    constructor(public app: AppComponent) { }

}
