import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ClassType, GenericRef, ListType } from "@avi-x/avi-dto/shared";
import { JobParam } from "@avi-x/avi-dto/system/jobparam.model";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

import { JobStartInfoDto } from '@avi-x/avi-dto/job/startjobinfodto';
import { TranslateService } from "@ngx-translate/core";
import { AviJobService, StartJobConfig } from "../../services/job.service";
import { AviChangedAttr, AviFormField } from "../base-form/form-field";
import { AviFormFieldService } from "../../services/form-field.service";
import { AviApiService } from "../../services/api.service";
import { AviListDetailConst } from "../../shared/constants/constants";

@Component({
    selector: 'avi-jobstart-form',
    template: `
    <avi-core-base-form #form
        [readonly]="false"
        [card]="false"
		[contents-padding]="false"
        [fields]="Fields"
        form-title="Job ausführen"
        [(Model)]="Model"
        (onSave)="saveModel($event)"
        [save-button-label]="SaveButtonLabel"
        save-button-icon="pi pi-play"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="abbrechenButton">

        <div formFieldTemplate1>
            <div class="form-field">
                <div class="p-inputgroup">
                    <avi-file-upload-button styleClass="p-button-outlined" [label]="'CORE.COMMON.JOB_CHOOSEFILE' | translate" (onUpload)="onSelect($event)" [multiple]="false"></avi-file-upload-button>
                </div>
            </div>
        </div>
    </avi-core-base-form>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviJobStartFormComponent implements OnInit {
    FormType: ClassType<any> = AviJobStartFormComponent;

    @ViewChild('form', { static: true }) Form: any;

    abbrechenButton: any = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.doCancel(); } }];

    public Fields: AviFormField[] = [];

    Model: JobStartInfoDto;

    SaveButtonLabel: string = 'CORE.COMMON.JOB_AUSFUEHREN_BUTTON';

    private readonly: boolean = false;
    private jobConfig: StartJobConfig = new StartJobConfig();

    constructor(protected formFieldService: AviFormFieldService,
        protected jobService: AviJobService,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private translateService: TranslateService,
        private apiService: AviApiService,
        protected cdr: ChangeDetectorRef) {
    }

    async ngOnInit() {
        if (this.config && this.config.data) {
            if (this.config.data.Params?.config)
                this.jobConfig = this.config.data.Params.config;

            if (this.config.data.formType === this.FormType && this.config.data.Id) {
                this.Model = await this.jobService.createStartJobInfo(this.config.data.Id);
                if (!this.config.header)
                    this.config.header = this.Model.Title;
                this.setDefaultValues();
                this.Form.ButtonsAlignment = 'right';
                this.Form.ButtonsInvert = true;
            }

            if (this.config.data.readonly)
                this.readonly = this.config.data.readonly;
        }

        this.initFields();
    }

    onAttrChange(attr: AviChangedAttr) {
    }

    saveModel(model: JobStartInfoDto) {
        if (this.ref)
            this.ref.close(model);
    }

    initFields() {
        const tb = this.formFieldService.CreateTextBlock('TB0', '', null, 'CORE.COMMON.JOB_AUSFUEHREN_MSG').setLabelVisible(false).setMDSizeFull();
        tb.TextblockParams = { name: this.Model.Title };
        this.Fields.push(tb);

        if (this.Model.JobType.Id == AviListDetailConst.JOBDEF_JOBTYPE_FILEIMPORT) {
            this.Fields.push(this.formFieldService.CreateText('FileName', 'CORE.COMMON.JOB_FILE', true).setReadonly(true).setMDSize(11));
            this.Fields.push(this.formFieldService.CreateTemplate(1, ' ').setLabelVisible(true).setLabelClass('inline-block').setMDSize(1));
        }

        for (const [index, param] of this.Model.Params.entries()) {
            const paramType = param.fromJobParamDef.ParamType.Id;
            const required = param.fromJobParamDef.mandatory.Id === AviListDetailConst.JA;

            if (this.jobConfig.hiddenParams.includes(param.fromJobParamDef.Id))
                continue;

            if (paramType === AviListDetailConst.ATTRTYPE_TEXT)
                this.Fields.push(this.formFieldService.CreateText(`Params[${index}].ValueDate`, param.fromJobParamDef.Bezeichnung1, required).setLabelLiteral().setReadonly(this.readonly).setMDSizeFull());
            else if (paramType === AviListDetailConst.ATTRTYPE_DATE)
                this.Fields.push(this.formFieldService.CreateDate(`Params[${index}].ValueDate`, param.fromJobParamDef.Bezeichnung1, required).setLabelLiteral().setReadonly(this.readonly).setMDSizeFull());
            else if (paramType === AviListDetailConst.ATTRTYPE_DECIMAL)
                this.Fields.push(this.formFieldService.CreateNumber(`Params[${index}].ValueDec`, param.fromJobParamDef.Bezeichnung1, 2, required).setLabelLiteral().setReadonly(this.readonly).setMDSizeFull());
            else if (paramType === AviListDetailConst.ATTRTYPE_INTEGER)
                this.Fields.push(this.formFieldService.CreateNumber(`Params[${index}].ValueInt`, param.fromJobParamDef.Bezeichnung1, 0, required).setLabelLiteral().setReadonly(this.readonly).setMDSizeFull());
            else if (paramType === AviListDetailConst.ATTRTYPE_REFERENCE) {
                let ds: any;
                if (this.jobConfig.customDataSources.has(param.fromJobParamDef.Id))
                    ds = this.jobConfig.customDataSources.get(param.fromJobParamDef.Id);
                else
                    ds = `model/representations/${param.ParamRefType}`
                const field = this.formFieldService.CreateAutocomplete(`Params[${index}].ValueRef`, param.fromJobParamDef.Bezeichnung1, false, 'Representation', 'Id', 0, ds).setLabelLiteral().setReadonly(this.readonly).setMDSizeFull();
                field.DropdownDatasourceMethod = 'POST';
                field.DropdownDatasourceRequestDataDelegate = (field) => this.Model;
                field.ValueTransformer = (id) => new GenericRef(id, param.ParamRefType);
                field.DropdownEnableVirtualScroll = true;
                this.Fields.push(field);
            }
            else if (paramType === AviListDetailConst.ATTRTYPE_LISTTYPE) {
                const field = this.formFieldService.CreateDropdown(`Params[${index}].ValueList_ID`, param.fromJobParamDef.Bezeichnung1, `model/representations/list/${param.fromJobParamDef.ListTypeName}`, required, false, null, (m) => (`${m.Representation}`), 'Id', false, false).setLabelLiteral().setReadonly(this.readonly).setMDSizeFull();
                this.Fields.push(field);
            }
        }

        this.updateDropdownSources();
    }

    private setDefaultValues() {
        if (this.jobConfig.defaultParameterValues.size == 0)
            return;

        for(let parameter of this.Model.Params) {
            if (this.jobConfig.defaultParameterValues.has(parameter.fromJobParamDef.Id))
                this.setParamValue(parameter, this.jobConfig.defaultParameterValues.get(parameter.fromJobParamDef.Id));
        }

        console.log(this.Model);
    }

    private setParamValue(param: JobParam, value: any){
        const paramType = param.fromJobParamDef.ParamType.Id;

        if (paramType === AviListDetailConst.ATTRTYPE_TEXT)
            param.ValueText = value;
        else if (paramType === AviListDetailConst.ATTRTYPE_DATE)
            param.ValueDate = value;
        else if (paramType === AviListDetailConst.ATTRTYPE_DECIMAL)
            param.ValueDec = value;
        else if (paramType === AviListDetailConst.ATTRTYPE_INTEGER)
            param.ValueInt = value;
        else if (paramType === AviListDetailConst.ATTRTYPE_REFERENCE)
            param.ValueRef = new GenericRef(value, param.ParamRefType);
        else if (paramType === AviListDetailConst.ATTRTYPE_LISTTYPE) {
            param.ValueList_ID = value;
        }

        console.log(value, param.ValueRef);
    }

    async updateDropdownSources() {
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model, true);
        await this.formFieldService.UpdateAutocompleteDatasources(this.Fields, this.Model);
        this.cdr.markForCheck();

        console.log(this.Model);
    }

    async onSelect(file: File) {
        if (file) {
            this.Model.FileName = file.name;
            this.Model.File = file;
            setTimeout(() => this.Form.focusField('Filename', false));
        }
    }

    doCancel() {
        if (this.ref)
            this.ref.close(null);
    }
}
