import { Expose, Type } from 'class-transformer';

import { Job } from '../system/job.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared/genericref.model';

export class JobLogging extends BaseModel {  
    @Expose()
    public get LogMessage(): string { return this.getProperty('LogMessage'); }
    public set LogMessage(value: string) { this.setProperty('LogMessage', value); }
    
    @Expose()
    public get LogNr(): number { return this.getProperty('LogNr'); }
    public set LogNr(value: number) { this.setProperty('LogNr', value); }
    
    @Expose()
    public get fromJob_ID(): string { return this.getModelId('fromJob_ID'); }
    public set fromJob_ID(value: string) { this.setModelId('fromJob_ID', value); }
 
    @Expose()
    @Type(() => Job)
    public get fromJob(): Job { return this.getModel('fromJob'); }
    public set fromJob(value: Job) { this.setModel('fromJob', value); }
    
    @Expose()
    @Type(() => ListType)
    public get LogType(): ListType { return this.getProperty('LogType'); }
    public set LogType(value: ListType) { this.setProperty('LogType', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Erledigt(): ListType { return this.getProperty('Erledigt'); }
    public set Erledigt(value: ListType) { this.setProperty('Erledigt', value); }
    
    @Expose()
    @Type(() => GenericRef)
    public get ModelRef(): GenericRef { return this.getProperty('ModelRef'); }
    public set ModelRef(value: GenericRef) { this.setProperty('ModelRef', value); }
    
}
