<avi-core-page
    [title]="Titel"
    [card]="Card"
    [collapsible]="false"
    [expanded]="true"
    [contents-padding]="true"
    [no-box-shadow]="true"
>
    <avi-core-base-searcher
        #searcher
        [settingskey]="SettingsKey"
        [keep-selection]="CheckboxSelection"
        [hide-when-noresults]="false"
        [use-router-params]="true"
        [use-router-params-selection]="false"
        [disable-inactive-filter]="true"
        [card]="false"
        [contents-padding]="false"
        [search-delegate]="SearchDelegate"
        [reorderable-columns]="true"
        [selectall-delegate]="SelectAllDelegate"
        [rows]="20"
        [toolbar]="true"
        [toolbar-show-searchinput]="false"
        [toolbar-show-inactivebutton]="false"
        [toolbar-show-columnselection]="true"
        [toolbar-buttons]="toolbarButtons"
        [get-filters]="GetFilters"
        [visible-columns]="VisibleColumns"
        [auto-search]="true"
        [show-autofilter]="true"
        (onInit)="onSearcherInit($event)"
        (onClick)="onClick($event)"
        (onDoubleClick)="onDoubleClick($event)"
        (onSelect)="onSelect($event)"
        [select-first-result]="false"
        [checkbox-selection]="CheckboxSelection"
        searcher-title=""
        [get-row-class]="GetRowClass"
        [show-totalrecords]="true"
        [show-totalselected]="CheckboxSelection"
        [show-totalrecordsunfiltered]="true"
        [total-label]="'VWBP.COMMON.RT_INANSICHT' | translate"
        [total-selected-label]="'VWBP.COMMON.RT_SELECTIERT' | translate"
        [total-unfiltered-label]="'VWBP.COMMON.TOTALRT' | translate"
    >
    </avi-core-base-searcher>
</avi-core-page>
