import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviDecisionTableFormComponent } from './avi-decisiontable-form.component';
import { DecisionTableSearchResponseDto } from '@avi-x/avi-dto/system/decisiontablesearchresponsedto.model';


const nameof = (nameFunction: ((obj: DecisionTableSearchResponseDto) => any)) => typed_nameof<DecisionTableSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-decisiontable-searcher',
    templateUrl: './avi-decisiontable-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDecisionTableSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFDECISIONTABLE';
    SettingsKey = 'settings.searcher.decisiontable';
    BaseUrl = 'decisiontable';
	FormType = AviDecisionTableFormComponent;

    CanEdit: boolean = false;

    @ViewChild('form')
    form: AviDecisionTableFormComponent;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFDecisionTable schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            // this.sessionController.openSession('decisiontabledef-index', this.SelectedRows.Id);
            this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addIntegerColumn(nameof(c => c.Sequenz), 'CORE.COMMON.MODEL.CAFDECISIONTABLE.SEQUENZ', true);
        searcher.addTextColumn(nameof(c => c.KurzBez), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.KURZBEZ1', true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFDECISIONTABLEDEF.BEZEICHNUNG1', true);
        
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(DecisionTableSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
