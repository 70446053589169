import { ListType } from '../shared';
import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";

export class DocPropertySearchResponseDto extends IDTO
{
	@Expose()
	public _TotalRowCount: number;

	@Expose()
	public Id: string;
		
	@Expose()
	@Type(() => ListType)
	public Aktiv: ListType;

	@Expose()
	@Type(() => ListType)
	public DocPropType: ListType;

    @Expose()
	public PathToObject: string;

    @Expose()
	public AttributName: string;

    @Expose()
	public SprachRelevant: boolean;

    @Expose()
	public FormatString: string;

    @Expose()
	public FixedRegionCode: string;

    @Expose()
	public ModelMetaName: string;

	@Expose()
	public PropertyName: string;
}
