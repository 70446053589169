<p-dropdown
    appendTo="body"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [autofocus]="autofocus"
    [baseZIndex]="10000"
    [showClear]="showClear"
    [readonly]="readonly"
    [styleClass]="readonly ? 'p-dropdown-readonly' : ''"
    [tabindex]="tabindex"
    [options]="options"
    [dataKey]="dataKey"
    [required]="required"
    [filter]="filter"
    [resetFilterOnHide]="resetFilterOnHide"
    [filterMatchMode]="filterMatchMode"
    [name]="name"
    [disabled]="disabled"
    [virtualScroll]="virtualScroll"
    [itemSize]="itemSize"
    [emptyFilterMessage]="emptyFilterMessage"
    [autoDisplayFirst]="false"
    (onShow)="onShow.emit($event)"
    (onFocus)="onFocus.emit($event)"
    (onChange)="onChange.emit($event)"
    (onBlur)="onBlur.emit($event)"
>
    <ng-template *ngIf="customTemplateSelectedItemDelegate" let-item pTemplate="selectedItem">
        <div [innerHTML]="customTemplateSelectedItemDelegate(item)"></div>
    </ng-template>
    <ng-template *ngIf="customTemplateDelegate" let-car pTemplate="item">
        <div [innerHTML]="customTemplateDelegate(car)"></div>
    </ng-template>
</p-dropdown>
