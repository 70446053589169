import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AviBaseFormComponent, AviCommonService, AviFormField, AviFormFieldService, ObjectUtils, typed_nameof } from '@avi-x/avi-core';
import { VwbPRechtstraegerLoeschenDto } from '../../../dto/vwbp.uebergabe.models';
import { VwbPPublicRechtstraegerService } from '../../services/rechtstraeger.service';

const nameof = (nameFunction: ((obj: VwbPRechtstraegerLoeschenDto) => any)) => typed_nameof<VwbPRechtstraegerLoeschenDto>(nameFunction);

@Component({
    selector: 'vwbp-public-rechtstraeger-loeschen-form',
    templateUrl: './rechtstraeger-loeschen-form.component.html',
    styleUrls: ['./rechtstraeger-loeschen-form.component.scss']
})
export class VwbPPublicRechtstraegerLoeschenFormComponent implements OnInit, OnDestroy {

    sub: any;

    showSuccessMessage = false;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    public get FormTitle(): string {
        return 'Rechtsträger löschen';
    }

    @Input('contents-padding')
    public ContentsPadding: boolean = true;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: VwbPRechtstraegerLoeschenDto = null;

    public Fields: AviFormField[] = [];


    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();


    //  public HistoryStates: ZustandInfoDto[];

    constructor(private commonService: AviCommonService,
        private formFieldService: AviFormFieldService,
        private rechtstraegerService: VwbPPublicRechtstraegerService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(id: string, name: string): Promise<boolean> {

        await this.initNewModel({ Id: id, Name: name });
        this.cdr.markForCheck();
        return true;
    }


    public async initNewModel(data?: any) {
        this.showSuccessMessage = false;
        this.Model = new VwbPRechtstraegerLoeschenDto();
        if (data) {
            for (const key in data) {
                ObjectUtils.setByPath(this.Model, key, data[key]);
            }
        }
    }

    ngOnInit() {
        this.initFields();
        // this.InitForm();
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: VwbPRechtstraegerLoeschenDto) {
        this.Form.clearFormMessages();
        this.rechtstraegerService.StartDeleteRechtstraeger(model).then(() => {
            this.showSuccessMessage = true;
            this.cdr.markForCheck();

        }).catch(err => {
            const errText = this.commonService.getErrorText(err);
            this.Form.addFormMessage(errText);
        });

    }


    onBtnClosePress() {
        this.onSavedSuccessfulDelegate.emit(this.Model);
    }


    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    initFields() {

        this.Fields.push(this.formFieldService.CreateDate(nameof(c => c.Loeschdatum), 'VWBP.PUBLIC.RT_LOESCHUNGSDATUM_V2', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateHidden(nameof(c => c.Id), true));
    }
}
