import { VwbPActionConst, VwbPActionData, VwbPListDetailConst } from '@avi-x/vwbp-public';
import { Injectable } from '@angular/core';
import { AviApiService } from '@avi-x/avi-core';

@Injectable({
    providedIn: 'root'
})
export class VwbPInternUnstimmigkeitService {
    constructor(private apiService: AviApiService) { }

    async SetMahnwesenAktiv(rechtstraegerIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_AKTIV,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async SetMahnwesenAktivMitMahnfrist(mahnungIds: string[], datum: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: mahnungIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_AKTIV_MIT_MAHNFRIST,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async SetMahnwesenAktivMitRechtsmittelfrist(bussIds: string[], datum: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: bussIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_AKTIV_MIT_RECHTSMITTELFRIST,
            Bemerkung: bemerkung
        };
        console.log(data);
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async SetMahnwesenAktivMitBussfrist(bussIds: string[], datum: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: bussIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_AKTIV_MIT_BUSSFRIST,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async SetMahnwesenPausiert(mahnungIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: mahnungIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_MAHNWESEN_PAUSIERT,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async SetUnstimmigkeitsstatus(rechtstraegerIds: string[], newStatus: string, dokuGenerieren: boolean, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEITSSTATUS_SETZEN,
            DokuGenerieren: dokuGenerieren,
            Status: newStatus,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }


    async SetUnstimmigkeitZurueckAufInformiert(rechtstraegerIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_ZURUECK_AUF_INFORMIERT,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }


    async SetMahnfrist(mahnungIds: string[], datum: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: mahnungIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_MAHNFRIST_ANPASSEN,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async Mahnen(rechtstraegerIds: string[], datum: Date, dokuGenerieren: boolean, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_MAHNEN,
            DokuGenerieren: dokuGenerieren,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async SetRechtsmittelfrist(bussIds: string[], datum: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: bussIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_RECHTSMITTELFRIST_ANPASSEN,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async Buessen(mahnungIds: string[], datum: Date, dokuGenerieren: boolean, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: mahnungIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_BUESSEN,
            DokuGenerieren: dokuGenerieren,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async ErneutBuessen(bussIds: string[], datum: Date, dokuGenerieren: boolean, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: bussIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_ERNEUT_BUESSEN,
            DokuGenerieren: dokuGenerieren,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async Einzugsauftrag(bussIds: string[], datum: Date, dokuGenerieren: boolean, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: bussIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_EINZUGSAUFTRAG,
            DokuGenerieren: dokuGenerieren,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async SetBussfrist(bussIds: string[], datum: Date, bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: bussIds,
            Datum: datum,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_BUSSFRIST_ANPASSEN,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    // Rückgängig machen Tasks
    async MahnenRueckgaengig(rechtstraegerIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_MAHNEN_RUECKGAENGIG,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async BuessenRueckgaengig(rechtstraegerIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_BUESSEN_RUECKGAENGIG,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async EinzugsauftragRueckgaengig(rechtstraegerIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_EINZUGSAUFTRAG_RUECKGAENGIG,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async LoeschverfahrenRueckgaengig(rechtstraegerIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_LOESCHVERFAHREN_RUECKGAENGIG,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }


    async InformierenRueckgaengig(rechtstraegerIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_STATUS_SETZEN_RUECKGAENGIG,
            Status: VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_INFORMIERT,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async AbschliessenRueckgaengig(rechtstraegerIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_STATUS_SETZEN_RUECKGAENGIG,
            Status: VwbPListDetailConst.VWBP_UNSTIMMIGKEITSSTATUS_ABGESCHLOSSEN,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }

    async UnstimmigkeitLoeschen(rechtstraegerIds: string[], bemerkung: string): Promise<any> {
        const data: VwbPActionData = {
            ModelIdList: rechtstraegerIds,
            Action: VwbPActionConst.VWBP_ACTION_UNSTIMMIGKEIT_LOESCHEN,
            Bemerkung: bemerkung
        };
        return await this.apiService.post('v1/intern/vwbp/unstimmigkeit/action', data);
    }
}
