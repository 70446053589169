import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AviApiErrorObject, AviApiService, AviFormField, AviChangedAttr, AviCommonService, AviAuthService, AviBaseFormComponent, AviFormFieldService, typed_nameof } from '@avi-x/avi-core';
import { VwbPListDetailConst } from '@avi-x/vwbp-public';
import { VwbPEmailDef } from '../../../dto/vwbpemaildef.model';

const nameof = (nameFunction: ((obj: VwbPEmailDef) => any)) => typed_nameof<VwbPEmailDef>(nameFunction);

@Component({
    selector: 'vwbp-admin-emaildef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
        [fields]="Fields"
        [form-title]="FormTitle"
        [Model]="Model"
        [loading]="Loading"
        (onDelete)="deleteModel($event)"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [action-buttons]="actionButtons">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPAdminEmailDefFormComponent implements OnInit, OnDestroy {
    private _className = 'Email-Notifizierung';

    sub: any;

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;

    isEditMode: boolean = false;
    public get FormTitle(): string {
        if (this.isEditMode) {
            return this._className + ' bearbeiten';
        } else
            return this._className + ' erstellen';
    }

    @Input('readonly')
    public ReadOnly: boolean = false;

    @Input('card')
    public Card: boolean = true;

    @Input('redirect-after-save')
    public RedirectAfterSave: string = null;

    @Input('redirect-after-delete')
    public RedirectAfterDelete: string = null;

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: VwbPEmailDef = null;

    public Fields: AviFormField[] = [];

    private _ModelId: string = null;
    public get ModelId(): string {
        return this._ModelId;
    }

    @Input('model-id')
    public set ModelId(value: string) {
        this.InitForm(value);
    }

    @Output('onSavedSuccessful')
    public onSavedSuccessfulDelegate: EventEmitter<any> = new EventEmitter();

    constructor(private commonService: AviCommonService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private apiService: AviApiService,
        private authService: AviAuthService,
        private formFieldService: AviFormFieldService,
        private cdr: ChangeDetectorRef) {

    }

    public async InitForm(value: string): Promise<boolean> {
        
        if (this._ModelId !== '0' && (this._ModelId === value || value == null)) {
            if (value == null) {
                this.Model = null;
                this._ModelId = value;
                this.isEditMode = false;
            }
            return;
        }

        this._ModelId = value;

        this.isEditMode = false;

        if (this._ModelId && this._ModelId !== '0') {
            await this.loadModel(this._ModelId);

            this.isEditMode = true;

            // this.Form.focusField(nameof(c => c.Titel));
        } else {
            await this.initNewModel();

            // this.Form.focusField(nameof(c => c.Titel));
        }

        this.initFromEvent();

        this.cdr.markForCheck();

        return true;
    }

    public async ClearForm() {
        this._ModelId = null;
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Event)) {
            await this.updateDropdownSources();
            this.initFromEvent();
        }
    }

    async initFromEvent() {
        var jobEvent: boolean = this.Model && this.Model.Event.Id === VwbPListDetailConst.VWBP_EVENT_HRIMPORT_JOB_STATUS;
        
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.OldStatus_ID), !jobEvent);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.NewStatus_ID), !jobEvent);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SendenBeiErfolg), jobEvent);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SendenBeiWarnung), jobEvent);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SendenBeiFehler), jobEvent);
        
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.NewStatus_ID), !jobEvent);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.SendenBeiErfolg), jobEvent);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.SendenBeiWarnung), jobEvent);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.SendenBeiFehler), jobEvent);

        if (this.Model) {
            if (jobEvent)
                this.Model.NewStatus_ID = VwbPListDetailConst.VWBP_EVENT_DETAIL_UNSTIMMIGKEIT_MAHNFRIST_ANGEPASST;
            else
                this.Model.NewStatus_ID = null;
        }
    }

    public async initNewModel(data?: any) {
        const emptyGuid = this.commonService.GuidEmpty;
        this.Model = await this.apiService.getModel(VwbPEmailDef, `v1/intern/vwbp/emaildef/${emptyGuid}`);

        await this.updateDropdownSources();

        this.isEditMode = false;
    }

    ngOnInit() {
        this.sub = this.activatedRoute.params.subscribe(params => {
            if (params && params['id']) {
                this.ModelId = params['id'];
            }
        });

        this.initFields();
    }


    deleteModel(model: any) {
        this.apiService.delete(`v1/intern/vwbp/emaildef/${model.id}`).then(r => {
            if (this.RedirectAfterDelete)
                this.router.navigate([this.RedirectAfterDelete]);
        });
    }

    public Save() {
        if (this.Model)
            this.saveModel(this.Model);
    }

    saveModel(model: VwbPEmailDef) {
        this.Form.clearFormMessages();

        let delegate: Promise<any> = null;
        if (this.isEditMode) {
            delegate = this.apiService.put(`/v1/intern/vwbp/emaildef/${model.Id}`, model);
        } else {
            delegate = this.apiService.post('/v1/intern/vwbp/emaildef', model);
        }
        delegate.then(r => {
            this.commonService.notificateSuccess('Gespeichert');
            this.onSavedSuccessfulDelegate.emit(model);

            if (this.RedirectAfterSave)
                this.router.navigate([this.RedirectAfterSave]);
        }).catch(err => {
            if (err instanceof AviApiErrorObject) {
                this.Form.addFormMessage(err.ErrorMessage, err.Type);
            } else {
                this.Form.addFormMessage(JSON.stringify(err));
            }
        });
    }

    async loadModel(id: string) {
        this.Model = await this.apiService.FindModel(VwbPEmailDef, `v1/intern/vwbp/emaildef/${id}`, null, null, null);

        this.Form.clearFormMessages();

        this.isEditMode = true;
        this._ModelId = this.Model.Id;

        await this.updateDropdownSources();

        return this.Model;
    }

    async updateDropdownSources() {
        await this.formFieldService.UpdateDropdownDatasources(this.Fields, this.Model);

        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        if (this.sub)
            this.sub.unsubscribe();
    }

    private getEventListName() {
        if (this.Model?.Event.Id === VwbPListDetailConst.VWBP_EVENT_SCHWAERZEN)
            return `v1/vwbp/model/representations/list/vwbp_email_events_schwaerzen`;
        return null;
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Event), 'Event', 'vwbp_event', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeFull());

        const colEingangsstatus = this.formFieldService.CreateDropdown(nameof(c => c.OldStatus_ID), 'Eingangsstatus',
        async (field) => {
            const ret = [];

            const path = this.getEventListName();
            if (path == null) return [];

            const p = await this.apiService.get(path);
            p.forEach(elem => ret.push({ label: elem.Representation, value: elem.Id, tag: elem }));

            return ret;
        }, false, false, 'Eingangsstatus wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull();
        this.Fields.push(colEingangsstatus);

        const colAusgangsstatus = this.formFieldService.CreateDropdown(nameof(c => c.NewStatus_ID), 'Ausgangsstatus',
        async (field) => {
            const ret = [];

            const path = this.getEventListName();
            if (path == null) return ret;

            const p = await this.apiService.get(path);
            p.forEach(elem => ret.push({ label: elem.Representation, value: elem.Id, tag: elem }));

            return ret;
        }, true, false, 'Ausgangsstatus wählen...', (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeFull();
        this.Fields.push(colAusgangsstatus);

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.SendenBeiErfolg), 'Senden bei Erfolg', 'janein', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.SendenBeiWarnung), 'Senden bei Warnung', 'janein', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.SendenBeiFehler), 'Senden bei Fehler', 'janein', true, true, this.ReadOnly, 'v1/vwbp').setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Recipient), 'Recipient', true).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Subject), 'Subject', true).setMDSizeFull());
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Message), 'Message', true).setMDSizeFull());

        this.initFromEvent();
    }
}
