import { Expose, Type } from "class-transformer";
import { IDTO } from "../shared";
import { JobParameterDto } from "./JobParamaterDto";

export class JobScheduleRequestDto extends IDTO {

    @Expose()
    public JobDefId: string;

    @Expose()
    @Type(() => JobParameterDto)
    public JobParameters: JobParameterDto[] = [];

    @Expose()
    public ImportMultipleFiles: boolean;

    @Expose()
    public FilePath: string;
}