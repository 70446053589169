import { set, get, findLastIndex } from 'lodash-es';
import { plainToClass, classToPlain } from 'class-transformer';

import { BaseModelState, ClassType, ListType } from '@avi-x/avi-dto/shared';

export class ObjectUtils {

    public static ObjectToBaseModel<T extends Object, V extends Array<any>>(cls: ClassType<T>, object: V);
    public static ObjectToBaseModel<T, V>(cls: ClassType<T>, object: V);
    public static ObjectToBaseModel<T extends Object, V>(cls: ClassType<T>, object: V | V[]): T | T[] {
        const obj = plainToClass(cls, object, { enableCircularCheck: true });
        if (obj instanceof BaseModelState)
            this.UndefinedToNull(obj)

        return obj;
    }

    public static UndefinedToNull(obj: any) {
        if (obj instanceof BaseModelState) {
            const bm = <BaseModelState>obj;
            bm.getPropertyNames().forEach(w => {
                if (bm.getProperty(w) == undefined)
                    bm.setProperty(w, null);

                if (bm.getProperty(w) instanceof BaseModelState)
                    this.UndefinedToNull(bm.getProperty(w));

                if (Array.isArray(bm.getProperty(w))) {
                    const arr = bm.getProperty(w) as any[];
                    if (arr.every(x => x instanceof BaseModelState))
                        arr.forEach(x => this.UndefinedToNull(x));
                }
            });
        }
    }

    public static BaseModelToObject(data: any): Object {
        return classToPlain(data, {
            excludePrefixes: ['_'],
            enableCircularCheck: true
        });
    }

    public static getByPath(model: any, path: string, nullValue: any = null): any {

        let ret = get(model, path);
        if (!ret && nullValue !== null)
            ret = nullValue;

        // console.log("getbypath", path, ret);
        return ret;
    }

    public static setByPath(model: any, path: string, value: any) {
        // console.log("setByPath", path, value);
        set(model, path, value);
    }

    public static isObjectEmpty(obj) {
        if (obj === null) return true;
        for (const key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    public static equals(obj1: any, obj2: any, field?: string): boolean {
        if (field)
            return (this.getByPath(obj1, field) === this.getByPath(obj2, field));
        else
            return this.equalsByValue(obj1, obj2);
    }

    public static equalsByValue(obj1: any, obj2: any): boolean {
        if (obj1 === obj2) return true;

        if (obj1 && obj2 && typeof obj1 === 'object' && typeof obj2 === 'object') {
            const arrA = Array.isArray(obj1)
                , arrB = Array.isArray(obj2);

            let i
                , length
                , key;

            if (arrA && arrB) {
                length = obj1.length;
                if (length !== obj2.length) return false;
                for (i = length; i-- !== 0;)
                    if (!this.equalsByValue(obj1[i], obj2[i])) return false;
                return true;
            }

            if (arrA !== arrB) return false;

            const dateA = obj1 instanceof Date
                , dateB = obj2 instanceof Date;
            if (dateA !== dateB) return false;
            if (dateA && dateB) return obj1.getTime() === obj2.getTime();

            const regexpA = obj1 instanceof RegExp
                , regexpB = obj2 instanceof RegExp;
            if (regexpA !== regexpB) return false;
            if (regexpA && regexpB) return obj1.toString() === obj2.toString();

            const ltA = obj1 instanceof ListType
                , ltB = obj2 instanceof ListType;
            if (ltA !== ltB) return false;
            if (ltA && ltB) return (<ListType>obj1).Id === (<ListType>obj2).Id;

            const keys = Object.keys(obj1);
            length = keys.length;

            if (length !== Object.keys(obj2).length)
                return false;

            for (i = length; i-- !== 0;)
                if (!Object.prototype.hasOwnProperty.call(obj2, keys[i])) return false;

            for (i = length; i-- !== 0;) {
                key = keys[i];
                if (!this.equalsByValue(obj1[key], obj2[key])) return false;
            }

            return true;
        }

        if ((obj1 === undefined && obj2 == null) || (obj1 == null && obj2 === undefined)) return true;
        if (typeof obj1 === 'string' || typeof obj2 === 'string') {
            if ((obj1 == null || obj1 === undefined || obj1 === '') &&
                (obj2 == null || obj2 === undefined || obj2 === ''))
                return true;
        }

        return obj1 !== obj1 && obj2 !== obj2;
    }

    public static removeAccents(str) {
        if (str && str.search(/[\xC0-\xFF]/g) > -1) {
            str = str
                .replace(/[\xC0-\xC5]/g, 'A')
                .replace(/[\xC6]/g, 'AE')
                .replace(/[\xC7]/g, 'C')
                .replace(/[\xC8-\xCB]/g, 'E')
                .replace(/[\xCC-\xCF]/g, 'I')
                .replace(/[\xD0]/g, 'D')
                .replace(/[\xD1]/g, 'N')
                .replace(/[\xD2-\xD6\xD8]/g, 'O')
                .replace(/[\xD9-\xDC]/g, 'U')
                .replace(/[\xDD]/g, 'Y')
                .replace(/[\xDE]/g, 'P')
                .replace(/[\xE0-\xE5]/g, 'a')
                .replace(/[\xE6]/g, 'ae')
                .replace(/[\xE7]/g, 'c')
                .replace(/[\xE8-\xEB]/g, 'e')
                .replace(/[\xEC-\xEF]/g, 'i')
                .replace(/[\xF1]/g, 'n')
                .replace(/[\xF2-\xF6\xF8]/g, 'o')
                .replace(/[\xF9-\xFC]/g, 'u')
                .replace(/[\xFE]/g, 'p')
                .replace(/[\xFD\xFF]/g, 'y');
        }

        return str;
    }

    public static resolveFieldData(data: any, field: any): any {
        if (data && field) {
            if (this.isFunction(field)) {
                return field(data);
            }
            else if (field.indexOf('.') == -1) {
                return data[field];
            }
            else {
                let fields: string[] = field.split('.');
                let value = data;
                for(let i = 0, len = fields.length; i < len; ++i) {
                    if (value == null) {
                        return null;
                    }
                    value = value[fields[i]];
                }
                return value;
            }
        }
        else {
            return null;
        }
    }

    public static isFunction(obj: any) {
        return !!(obj && obj.constructor && obj.call && obj.apply);
    }
}
