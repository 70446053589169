import { Expose, Type } from 'class-transformer';

import { ModelState } from '../system/modelstate.model';
import { AppUser } from '../system/appuser.model';
import { StatusDef } from '../workflow/statusdef.model';
import { CustomPropCollection } from '../system/custompropcollection.model';
import { Task } from '../system/task.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { GenericRef } from '../shared/genericref.model';
import { ActivityType } from './activitytype.model';
import { ActivityQueue } from './activityqueue.model';

export class Activity extends BaseModel {
    @Expose()
    public get ActivityID(): number { return this.getProperty('ActivityID'); }
    public set ActivityID(value: number) { this.setProperty('ActivityID', value); }

    @Expose()
    public get Titel(): string { return this.getProperty('Titel'); }
    public set Titel(value: string) { this.setProperty('Titel', value); }

    @Expose()
    public get Description(): string { return this.getProperty('Description'); }
    public set Description(value: string) { this.setProperty('Description', value); }

    @Expose()
    public get IsRTF(): boolean { return this.getProperty('IsRTF'); }
    public set IsRTF(value: boolean) { this.setProperty('IsRTF', value); }

    @Expose()
    public get Comment(): string { return this.getProperty('Comment'); }
    public set Comment(value: string) { this.setProperty('Comment', value); }

    @Expose()
    public get ActRepresentation(): string { return this.getProperty('ActRepresentation'); }
    public set ActRepresentation(value: string) { this.setProperty('ActRepresentation', value); }

    @Expose()
    @Type(() => Date)
    public get StartDate(): Date { return this.getProperty('StartDate'); }
    public set StartDate(value: Date) { this.setProperty('StartDate', value); }

    @Expose()
    @Type(() => Date)
    public get DueDate(): Date { return this.getProperty('DueDate'); }
    public set DueDate(value: Date) { this.setProperty('DueDate', value); }

    @Expose()
    @Type(() => Date)
    public get FinishDate(): Date { return this.getProperty('FinishDate'); }
    public set FinishDate(value: Date) { this.setProperty('FinishDate', value); }

    @Expose()
    @Type(() => Date)
    public get RememberDate(): Date { return this.getProperty('RememberDate'); }
    public set RememberDate(value: Date) { this.setProperty('RememberDate', value); }

    @Expose()
    @Type(() => Date)
    public get EscalationDate(): Date { return this.getProperty('EscalationDate'); }
    public set EscalationDate(value: Date) { this.setProperty('EscalationDate', value); }

    @Expose()
    @Type(() => Date)
    public get AcknowledgeDate(): Date { return this.getProperty('AcknowledgeDate'); }
    public set AcknowledgeDate(value: Date) { this.setProperty('AcknowledgeDate', value); }

    @Expose()
    public get Dauer(): number { return this.getProperty('Dauer'); }
    public set Dauer(value: number) { this.setProperty('Dauer', value); }

    @Expose()
    public get ExterneReferenz(): string { return this.getProperty('ExterneReferenz'); }
    public set ExterneReferenz(value: string) { this.setProperty('ExterneReferenz', value); }

    @Expose()
    public get Period(): number { return this.getProperty('Period'); }
    public set Period(value: number) { this.setProperty('Period', value); }

    @Expose()
    @Type(() => Date)
    public get ExtendUntil(): Date { return this.getProperty('ExtendUntil'); }
    public set ExtendUntil(value: Date) { this.setProperty('ExtendUntil', value); }

    @Expose()
    public get TextUebernehmen(): boolean { return this.getProperty('TextUebernehmen'); }
    public set TextUebernehmen(value: boolean) { this.setProperty('TextUebernehmen', value); }

    @Expose()
    public get PeriodMonthDay(): number { return this.getProperty('PeriodMonthDay'); }
    public set PeriodMonthDay(value: number) { this.setProperty('PeriodMonthDay', value); }

    @Expose()
    public get ActContext1Representation(): string { return this.getProperty('ActContext1Representation'); }
    public set ActContext1Representation(value: string) { this.setProperty('ActContext1Representation', value); }

    @Expose()
    public get ActContext2Representation(): string { return this.getProperty('ActContext2Representation'); }
    public set ActContext2Representation(value: string) { this.setProperty('ActContext2Representation', value); }

    @Expose()
    public get ActContext3Representation(): string { return this.getProperty('ActContext3Representation'); }
    public set ActContext3Representation(value: string) { this.setProperty('ActContext3Representation', value); }

    @Expose()
    @Type(() => Date)
    public get ActivityPeriodeVon(): Date { return this.getProperty('ActivityPeriodeVon'); }
    public set ActivityPeriodeVon(value: Date) { this.setProperty('ActivityPeriodeVon', value); }

    @Expose()
    @Type(() => Date)
    public get ActivityPeriodeBis(): Date { return this.getProperty('ActivityPeriodeBis'); }
    public set ActivityPeriodeBis(value: Date) { this.setProperty('ActivityPeriodeBis', value); }

    @Expose()
    public get isContextMigrated(): boolean { return this.getProperty('isContextMigrated'); }
    public set isContextMigrated(value: boolean) { this.setProperty('isContextMigrated', value); }

    @Expose()
    public get isStateMigrated(): boolean { return this.getProperty('isStateMigrated'); }
    public set isStateMigrated(value: boolean) { this.setProperty('isStateMigrated', value); }

    @Expose()
    public get ModelState_ID(): string { return this.getModelId('ModelState_ID'); }
    public set ModelState_ID(value: string) { this.setModelId('ModelState_ID', value); }

    @Expose()
    @Type(() => ModelState)
    public get ModelState(): ModelState { return this.getModel('ModelState'); }
    public set ModelState(value: ModelState) { this.setModel('ModelState', value); }

    @Expose()
    public get Owner_ID(): string { return this.getModelId('Owner_ID'); }
    public set Owner_ID(value: string) { this.setModelId('Owner_ID', value); }

    @Expose()
    @Type(() => AppUser)
    public get rOwner(): AppUser { return this.getModel('rOwner'); }
    public set rOwner(value: AppUser) { this.setModel('rOwner', value); }

    @Expose()
    public get AssignedTo_ID(): string { return this.getModelId('AssignedTo_ID'); }
    public set AssignedTo_ID(value: string) { this.setModelId('AssignedTo_ID', value); }

    @Expose()
    @Type(() => AppUser)
    public get rAssignedTo(): AppUser { return this.getModel('rAssignedTo'); }
    public set rAssignedTo(value: AppUser) { this.setModel('rAssignedTo', value); }

    @Expose()
    public get Ersteller_ID(): string { return this.getModelId('Ersteller_ID'); }
    public set Ersteller_ID(value: string) { this.setModelId('Ersteller_ID', value); }

    @Expose()
    @Type(() => AppUser)
    public get Ersteller(): AppUser { return this.getModel('Ersteller'); }
    public set Ersteller(value: AppUser) { this.setModel('Ersteller', value); }

    @Expose()
    public get FinishedBy_ID(): string { return this.getModelId('FinishedBy_ID'); }
    public set FinishedBy_ID(value: string) { this.setModelId('FinishedBy_ID', value); }

    @Expose()
    @Type(() => AppUser)
    public get FinishedBy(): AppUser { return this.getModel('FinishedBy'); }
    public set FinishedBy(value: AppUser) { this.setModel('FinishedBy', value); }

    @Expose()
    public get ParentActivity_ID(): string { return this.getModelId('ParentActivity_ID'); }
    public set ParentActivity_ID(value: string) { this.setModelId('ParentActivity_ID', value); }

    // @Expose()
    // @Type(() => Activity)
    // public get rParentActivity(): Activity { return this.getModel('rParentActivity'); }
    // public set rParentActivity(value: Activity) { this.setModel('rParentActivity', value); }

    @Expose()
    public get ActivityType_ID(): string { return this.getModelId('ActivityType_ID'); }
    public set ActivityType_ID(value: string) { this.setModelId('ActivityType_ID', value); }

    @Expose()
    @Type(() => ActivityType)
    public get rActivityType(): ActivityType { return this.getModel('rActivityType'); }
    public set rActivityType(value: ActivityType) { this.setModel('rActivityType', value); }

    @Expose()
    public get ActivityQueue_ID(): string { return this.getModelId('ActivityQueue_ID'); }
    public set ActivityQueue_ID(value: string) { this.setModelId('ActivityQueue_ID', value); }

    @Expose()
    @Type(() => ActivityQueue)
    public get rActivityQueue(): ActivityQueue { return this.getModel('rActivityQueue'); }
    public set rActivityQueue(value: ActivityQueue) { this.setModel('rActivityQueue', value); }

    @Expose()
    public get StatusDef_ID(): string { return this.getModelId('StatusDef_ID'); }
    public set StatusDef_ID(value: string) { this.setModelId('StatusDef_ID', value); }

    @Expose()
    @Type(() => StatusDef)
    public get StatusDef(): StatusDef { return this.getModel('StatusDef'); }
    public set StatusDef(value: StatusDef) { this.setModel('StatusDef', value); }

    @Expose()
    public get CustomProperties_ID(): string { return this.getModelId('CustomProperties_ID'); }
    public set CustomProperties_ID(value: string) { this.setModelId('CustomProperties_ID', value); }

    @Expose()
    @Type(() => CustomPropCollection)
    public get CustomProperties(): CustomPropCollection { return this.getModel('CustomProperties'); }
    public set CustomProperties(value: CustomPropCollection) { this.setModel('CustomProperties', value); }

    @Expose()
    public get PeriodActivity_ID(): string { return this.getModelId('PeriodActivity_ID'); }
    public set PeriodActivity_ID(value: string) { this.setModelId('PeriodActivity_ID', value); }

    @Expose()
    @Type(() => Activity)
    public get PeriodActivity(): Activity { return this.getModel('PeriodActivity'); }
    public set PeriodActivity(value: Activity) { this.setModel('PeriodActivity', value); }

    @Expose()
    public get Task_ID(): string { return this.getModelId('Task_ID'); }
    public set Task_ID(value: string) { this.setModelId('Task_ID', value); }

    @Expose()
    @Type(() => Task)
    public get Task(): Task { return this.getModel('Task'); }
    public set Task(value: Task) { this.setModel('Task', value); }

    @Expose()
    @Type(() => ListType)
    public get ActivityRead(): ListType { return this.getProperty('ActivityRead'); }
    public set ActivityRead(value: ListType) { this.setProperty('ActivityRead', value); }

    @Expose()
    @Type(() => ListType)
    public get ActivityState(): ListType { return this.getProperty('ActivityState'); }
    public set ActivityState(value: ListType) { this.setProperty('ActivityState', value); }

    @Expose()
    @Type(() => ListType)
    public get Priority(): ListType { return this.getProperty('Priority'); }
    public set Priority(value: ListType) { this.setProperty('Priority', value); }

    @Expose()
    @Type(() => ListType)
    public get PeriodTyp(): ListType { return this.getProperty('PeriodTyp'); }
    public set PeriodTyp(value: ListType) { this.setProperty('PeriodTyp', value); }

    @Expose()
    @Type(() => ListType)
    public get PeriodDayTyp(): ListType { return this.getProperty('PeriodDayTyp'); }
    public set PeriodDayTyp(value: ListType) { this.setProperty('PeriodDayTyp', value); }

    @Expose()
    @Type(() => GenericRef)
    public get ModelRef(): GenericRef { return this.getProperty('ModelRef'); }
    public set ModelRef(value: GenericRef) { this.setProperty('ModelRef', value); }

    @Expose()
    @Type(() => GenericRef)
    public get ContextRef1(): GenericRef { return this.getProperty('ContextRef1'); }
    public set ContextRef1(value: GenericRef) { this.setProperty('ContextRef1', value); }

    @Expose()
    @Type(() => GenericRef)
    public get ContextRef2(): GenericRef { return this.getProperty('ContextRef2'); }
    public set ContextRef2(value: GenericRef) { this.setProperty('ContextRef2', value); }

    @Expose()
    @Type(() => GenericRef)
    public get ContextRef3(): GenericRef { return this.getProperty('ContextRef3'); }
    public set ContextRef3(value: GenericRef) { this.setProperty('ContextRef3', value); }

}
