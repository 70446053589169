import { Expose, Type } from 'class-transformer';

import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class Sprache extends BaseModel {  
    @Expose()
    public get SpracheKurz(): string { return this.getProperty('SpracheKurz'); }
    public set SpracheKurz(value: string) { this.setProperty('SpracheKurz', value); }
    
    @Expose()
    public get ISOCode(): string { return this.getProperty('ISOCode'); }
    public set ISOCode(value: string) { this.setProperty('ISOCode', value); }
    
    @Expose()
    public get SpracheBezeichnung(): string { return this.getProperty('SpracheBezeichnung'); }
    public set SpracheBezeichnung(value: string) { this.setProperty('SpracheBezeichnung', value); }
    
    @Expose()
    public get Bemerkung(): string { return this.getProperty('Bemerkung'); }
    public set Bemerkung(value: string) { this.setProperty('Bemerkung', value); }
    
    @Expose()
    public get SprachNr(): number { return this.getProperty('SprachNr'); }
    public set SprachNr(value: number) { this.setProperty('SprachNr', value); }
    
    @Expose()
    public get RegionCode(): string { return this.getProperty('RegionCode'); }
    public set RegionCode(value: string) { this.setProperty('RegionCode', value); }
    
    @Expose()
    @Type(() => ListType)
    public get IstDokSprache(): ListType { return this.getProperty('IstDokSprache'); }
    public set IstDokSprache(value: ListType) { this.setProperty('IstDokSprache', value); }
    
}
