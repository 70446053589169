import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDocument, IDocDialogConfig } from '../../../shared/interfaces';
import { MenuItem } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AviCommonService } from '../../../services/common.service';
import { FileUpload } from 'primeng/fileupload';

@Component({
    selector: 'avi-core-doc-dialog',
    templateUrl: './avi-core-doc-dialog.component.html',
    styleUrls: ['./avi-core-doc-dialog.component.scss']
})
export class AviCoreDocDialogComponent implements AfterViewInit {

    public pageTitle: string = 'Dokument hinzufügen';
    files: any[] = [];
    // form: FormGroup;

    steps: MenuItem[];
    currentStep = 0;


    @ViewChild('inputTitle')
    inputTitle: ElementRef;

    @ViewChild('inputFile')
    inputFile: FileUpload;

    constructor(
        public dialogRef: MatDialogRef<AviCoreDocDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IDocDialogConfig,
        private commonService: AviCommonService
    ) {
        if (data.Data.Id != null) {
            this.pageTitle = 'Dokument einchecken';
        }

        this.steps = [
            {
                label: 'Dokument',
                command: (event: any) => { this.currentStep = 0; }
            },
            {
                label: 'Schlüsselwörter',
                command: (event: any) => { this.currentStep = 1; }
            }
        ];

    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.inputTitle.nativeElement.select();
        }, 1);
    }

    onNoClick(): void {
        if (confirm('Wirklich schliessen?'))
            this.dialogRef.close();
        // this.commonService.confirm({message: 'Wirklich schliessen?', accept: () => this.dialogRef.close() });
    }

    onNextClick() {
        this.currentStep++;
    }

    onPrevClick() {
        this.currentStep--;
    }

    async onOkClick() {
        if (this.data.Action != null) {
            try {
                const res = await this.data.Action(this.data.Data);
                this.dialogRef.close(res);
            } catch (err) {
                throw err;
            }

        } else {
            this.dialogRef.close(this.data);
        }
    }

    onRemove(evt) {
        this.data.Data.File = null;
    }

    onClear(evt) {
        this.data.Data.File = null;
    }

    onSelect(evt) {
        if (evt && evt.currentFiles && evt.currentFiles.length > 0) {
            const file = evt.currentFiles[0];
            this.data.Data.Title = file.name;
            console.log('FielUp', this.inputFile);
            this.data.Data.File = file;
        }
    }


}
