import { AviDocArt } from '@avi-x/avi-dto/avidoc_def/avidocart.model';
import { AviDocTemplate } from '@avi-x/avi-dto/avidoc_def/avidoctemplate.model';
import { BaseModel } from '@avi-x/avi-dto/shared/basemodel.model';
import { ListType } from '@avi-x/avi-dto/shared/listtype.model';
import { Expose, Type } from 'class-transformer';

export class VwbPVorlageDef extends BaseModel {
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }

    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }

    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }

    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }

    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }

    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }

    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }

    @Expose()
    public get DocArt_ID(): string { return this.getModelId('DocArt_ID'); }
    public set DocArt_ID(value: string) { this.setModelId('DocArt_ID', value); }

    @Expose()
    @Type(() => AviDocArt)
    public get DocArt(): AviDocArt { return this.getModel('DocArt'); }
    public set DocArt(value: AviDocArt) { this.setModel('DocArt', value); }

    @Expose()
    public get TemplateLI_ID(): string { return this.getModelId('TemplateLI_ID'); }
    public set TemplateLI_ID(value: string) { this.setModelId('TemplateLI_ID', value); }

    @Expose()
    @Type(() => AviDocTemplate)
    public get TemplateLI(): AviDocTemplate { return this.getModel('TemplateLI'); }
    public set TemplateLI(value: AviDocTemplate) { this.setModel('TemplateLI', value); }

    @Expose()
    public get TemplateAusland_ID(): string { return this.getModelId('TemplateAusland_ID'); }
    public set TemplateAusland_ID(value: string) { this.setModelId('TemplateAusland_ID', value); }

    @Expose()
    @Type(() => AviDocTemplate)
    public get TemplateAusland(): AviDocTemplate { return this.getModel('TemplateAusland'); }
    public set TemplateAusland(value: AviDocTemplate) { this.setModel('TemplateAusland', value); }

    @Expose()
    @Type(() => ListType)
    public get Typ(): ListType { return this.getProperty('Typ'); }
    public set Typ(value: ListType) { this.setProperty('Typ', value); }

    @Expose()
    @Type(() => ListType)
    public get Task(): ListType { return this.getProperty('Task'); }
    public set Task(value: ListType) { this.setProperty('Task', value); }

    @Expose()
    @Type(() => ListType)
    public get Berechtigung(): ListType { return this.getProperty('Berechtigung'); }
    public set Berechtigung(value: ListType) { this.setProperty('Berechtigung', value); }

    @Expose()
    @Type(() => ListType)
    public get ViewVersion(): ListType { return this.getProperty('ViewVersion'); }
    public set ViewVersion(value: ListType) { this.setProperty('ViewVersion', value); }

    @Expose()
    @Type(() => ListType)
    public get ViewEwrRegister(): ListType { return this.getProperty('ViewEwrRegister'); }
    public set ViewEwrRegister(value: ListType) { this.setProperty('ViewEwrRegister', value); }

    @Expose()
    @Type(() => ListType)
    public get Format(): ListType { return this.getProperty('Format'); }
    public set Format(value: ListType) { this.setProperty('Format', value); }

    @Expose()
    @Type(() => ListType)
    public get DigitalSignieren(): ListType { return this.getProperty('DigitalSignieren'); }
    public set DigitalSignieren(value: ListType) { this.setProperty('DigitalSignieren', value); }

    @Expose()
    @Type(() => ListType)
    public get SerienbriefMoeglich(): ListType { return this.getProperty('SerienbriefMoeglich'); }
    public set SerienbriefMoeglich(value: ListType) { this.setProperty('SerienbriefMoeglich', value); }

    @Expose()
    @Type(() => ListType)
    public get KannAbgelegtWerden(): ListType { return this.getProperty('KannAbgelegtWerden'); }
    public set KannAbgelegtWerden(value: ListType) { this.setProperty('KannAbgelegtWerden', value); }

    @Expose()
    @Type(() => ListType)
    public get OpenWithDocHandler(): ListType { return this.getProperty('OpenWithDocHandler'); }
    public set OpenWithDocHandler(value: ListType) { this.setProperty('OpenWithDocHandler', value); }

    @Expose()
    @Type(() => ListType)
    public get Schwaerzen(): ListType { return this.getProperty('Schwaerzen'); }
    public set Schwaerzen(value: ListType) { this.setProperty('Schwaerzen', value); }
}
