import { Pipe, PipeTransform } from '@angular/core';

import { AviCommonService } from '../../services/common.service';

@Pipe({
    name: 'lineBreak'
})
export class AviLineBreakPipe implements PipeTransform {
    constructor(private commonService: AviCommonService) {
    }

    transform(value: any, args?: any): any {
        return this.commonService.nl2br(value);
    }
}
