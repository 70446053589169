<span [ngSwitch]="SelectedDetail.Typ.Id" class="panel-header">
    <div *ngSwitchCase="VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_AUSLAENDISCH"
        class="grid m-0">

        <div class="col-6 lg:col-3 summary-label">
            {{ 'VWBP.COMMON.RECHTSFORM' | translate }}
        </div>

        <div class="col-6 lg:col-3 ">
            {{ SelectedDetail.Rechtsform }}
        </div>

        <div class="col-6 lg:col-3 summary-label">
            <ng-container >{{ 'VWBP.COMMON.ERFASSUNGSSTATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.HrLoeschungsdatum">{{ 'VWBP.PUBLIC.RT_LOESCHUNGSDATUM' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.AbgegebenAm">{{ 'VWBP.COMMON.ABGEGEBEN_AM' | translate }}</ng-container>
        </div>
        <div class="col-6 lg:col-3 ">
            <span
                [ngClass]="{ 'color-red': isInBearbeitung() }">{{ GetErfassungsstatus() }}<br /></span>
            <ng-container *ngIf="SelectedRT.HrLoeschungsdatum">{{ SelectedRT.HrLoeschungsdatum | date: 'shortDate' }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.AbgegebenAm">{{ SelectedRT.AbgegebenAm | date: 'shortDate' }}</ng-container>
        </div>
    </div>

    <div *ngSwitchCase="VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_AUSLAENDISCH && SelectedDetail.RegisterNummer"
        class="grid m-0">

        <div class="col-6 lg:col-3 summary-label">
            {{ 'VWBP.COMMON.REGISTERNR' | translate }}<br />
            {{ 'VWBP.COMMON.REGISTERORT' | translate }}<br />
            {{ 'VWBP.COMMON.RECHTSFORM' | translate }}
        </div>

        <div class="col-6 lg:col-3 ">
            {{ SelectedDetail.RegisterNummer }}<br />
            {{ SelectedDetail.RegisterOrt }}<br />
            {{ SelectedDetail.Rechtsform }}
        </div>

        <div class="col-6 lg:col-3 summary-label">
            <ng-container >{{ 'VWBP.COMMON.ERFASSUNGSSTATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.HrLoeschungsdatum">{{ 'VWBP.PUBLIC.RT_LOESCHUNGSDATUM' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.AbgegebenAm">{{ 'VWBP.COMMON.ABGEGEBEN_AM' | translate }}<br /></ng-container>
        </div>

        <div class="col-6 lg:col-3 ">
            <span
                [ngClass]="{ 'color-red': isInBearbeitung() }">{{ GetErfassungsstatus() }}<br /></span>
            <ng-container *ngIf="SelectedRT.HrLoeschungsdatum">{{ SelectedRT.HrLoeschungsdatum | date: 'shortDate' }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.AbgegebenAm">{{
                    SelectedRT.AbgegebenAm | date: 'shortDate'
                }}</ng-container>
        </div>

    </div>

    <div *ngSwitchCase="VwbPListDetailConst.VWBP_RECHTSTRAEGER_TYP_LI_HRNR"
        class="grid m-0">

        <div class="col-6 lg:col-3 summary-label">
            {{ 'VWBP.COMMON.REGISTERNR' | translate }}<br />
            {{ 'VWBP.COMMON.RECHTSFORM' | translate }}
            <ng-container *ngIf="SelectedDetail.Sitz"><br />{{ 'VWBP.COMMON.SITZ' | translate }}</ng-container>
        </div>

        <div class="col-6 lg:col-3 ">
            {{ SelectedDetail.RegisterNummer }}<br />
            {{ SelectedDetail.Rechtsform }}
            <ng-container *ngIf="SelectedDetail.Sitz"><br />{{ SelectedDetail.Sitz }}</ng-container>
        </div>

        <div class="col-6 lg:col-3 summary-label">
            <ng-container *ngIf="SelectedVersion">{{ 'VWBP.COMMON.ERFASSUNGSSTATUS' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.HrLoeschungsdatum">{{ 'VWBP.PUBLIC.RT_LOESCHUNGSDATUM' | translate }}<br /></ng-container>
            <ng-container *ngIf="SelectedRT.AbgegebenAm">{{ 'VWBP.COMMON.ABGEGEBEN_AM' | translate }}<br /></ng-container>
        </div>
        <div class="col-6 lg:col-3 ">
            <span *ngIf="SelectedVersion" [ngClass]="{ 'color-red': isInBearbeitung() }">{{ GetErfassungsstatus() }}<br /></span>
            <span *ngIf="SelectedRT.HrLoeschungsdatum" [ngClass]="{ 'color-red': true }">{{ SelectedRT.HrLoeschungsdatum | date: 'shortDate' }}<br /></span>
            <span *ngIf="SelectedRT.AbgegebenAm" [ngClass]="{ 'color-red': true }">{{ SelectedRT.AbgegebenAm | date: 'shortDate'}}</span>
        </div>
    </div>
</span>
