
    <div class="grid">
        <div class="col-12 md:col-3">

            <p-panel 
                *ngxPermissionsOnly="['CAFTextBlock erfassen', 'CAFTextBlock schreiben']"
                header="Neuen Textblock erstellen"
                [toggleable]="true"
                [collapsed]="true"
                toggler="header"
                styleClass="mb-4">

                <div class="grid">
                    <div class="col-12">
                        <input pInputText
                            [(ngModel)]="newTextblockIdentifier"
                            class="w-100p">
                    </div>
                    <div class="col-12">
                        <button [disabled]="!newTextblockIdentifier"
                            class="w-100p"
                            pButton
                            (click)="createTextblock(newTextblockIdentifier)"
                            icon="pi pi-check"
                            label="Speichern">
                        </button>
                    </div>
                </div>
            </p-panel>

            <strong>Textblock wählen, um zu bearbeiten: </strong>

            <p-listbox [options]="allTextblockFlt" [(ngModel)]="selectedEntry" (ngModelChange)="selectTextBlock($event)" optionLabel="label" optionValue="value" [filter]="true"></p-listbox>

        </div>

        <div class="col-12 md:col-9">

            <p-panel header="Textblock bearbeiten" [toggleable]="false">

                <div *ngIf="HistoryStates?.length > 1" class="p-1 mb-2">
                    <p-dropdown
                        [options]="HistoryStates"
                        [baseZIndex]="100"
                        styleClass="w-100p"
                        [(ngModel)]="selectedTextblock"
                        placeholder="Textblock wählen">
                    </p-dropdown>
                </div>

                <div *ngIf="selectedTextblock">
                    <avi-core-base-form #form
                        [readonly]="ReadOnly"
                        [card]="false"
                        [fields]="Fields"
                        [(Model)]="selectedTextblock"
                        [contents-padding]="false"
                        [label-alignment]="'top'"
                        [show-buttons]="true"
                        [auto-focus]="true"
                        (onSave)="saveTextblock(selectedTextblock)"
                        >
                    </avi-core-base-form>
                </div>
            </p-panel>

        </div>

    </div>
