import { Component, OnInit, ChangeDetectionStrategy, ViewChild, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AviBaseSearcherComponent } from '@avi-x/avi-core';

@Component({
    selector: 'avi-crm-adresse-liste',
    templateUrl: './adresse-liste.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAdresseListeComponent implements OnInit, AfterViewInit {
    @ViewChild('searcher', { static: true })
    private searcher: AviBaseSearcherComponent;

    public Filters: string[] = null;

    @Input('partner-id')
    public partnerId: string = null;

    public DetailModelId: string = null;

    constructor(private activatedRoute: ActivatedRoute) {
    }

    public onInit(data) {
        // http://localhost:8086/api/AdresseDetail?filter=(aktiv eq aktiv()) and (zustandBeginn le today) and (zustandEnde ge today)&select=Id,vonAdresse_ID,vonAdresse.AdressTyp,Strasse,HausNr,PLZ,Land.Bezeichnung1,SteuerDomizil,ZustandAb,ZustandBis,vonAdresse.GueltigAb,vonAdresse.GueltigBis
        this.searcher.beginInit();
        this.searcher.addTextColumn('vonAdresse_ID', 'Id').setVisible(false);
        this.searcher.addListTypeColumn('vonAdresse.AdressTyp', 'Typ', 'caf_adresstyp', false, false);
        this.searcher.addListTypeColumn('vonAdresse.Klassifizierung', 'Klassifizierung', 'caf_adressklassifizierung', false, false);
        this.searcher.addTextColumn('Strasse', 'Strasse', false);
        this.searcher.addTextColumn('HausNr', 'HausNr', false);
        this.searcher.addTextColumn('PLZ', 'PLZ', false);
        this.searcher.addTextColumn('Land.Bezeichnung1', 'Land', false);
        this.searcher.addListTypeColumn('SteuerDomizil', 'SteuerDomizil', 'janein', false, false);
        this.searcher.addDateColumn('ZustandAb', 'Zustand ab');
        this.searcher.addDateColumn('ZustandBis', 'Zustand bis');
        this.searcher.addDateColumn('vonAdresse.GueltigAb', 'Gültig ab');
        this.searcher.addDateColumn('vonAdresse.GueltigBis', 'Gültig bis');
        this.searcher.endInit();
    }

    onSelect(data) {
        if (data && data[0])
            this.DetailModelId = data[0].vonAdresse_ID;
    }

    ngOnInit() {
    }

    public getFilters: any = (): string[] => {
        let res = ['zustandBeginn le today', 'zustandEnde ge today'];
        if (this.partnerId != null)
            res = [...res, `vonAdresse.vonPartner_ID eq ${this.partnerId}`];

        if (!this.searcher.showInaktive)
            res = [...res, 'vonAdresse.GueltigAb le today', 'vonAdresse.GueltigBis ge today'];

        return res;
    }

    ngAfterViewInit() {
        // this.activatedRoute.data.pipe(map(w => w.id)).subscribe(res => {
        //     this.partnerId = res;
        //     setTimeout(() => this.searcher.endInit(), 0);
        // });

    }
}
