import { Injectable } from "@angular/core";
import { AviApiService, AviCommonService } from "@avi-x/avi-core";
import { DialogService } from "primeng/dynamicdialog";
import { AviDokumentPreviewComponent } from "../components/dokument-preview/dokument-preview.component";
import { first, tap } from "rxjs/operators";
import { AviDokumentErfassenFormComponent, DokumentFormMode } from "../components/dokument-erfassen-form/dokument-erfassen-form.component";

@Injectable()
export class AviDokumentService {
    constructor(public apiService: AviApiService, public commonService: AviCommonService, public dialogService: DialogService) { }

    public getDocumentIcon(dateiendung: string) {
        if (dateiendung) {
            const idx = dateiendung.indexOf('.');
            const type = idx >= 0 ? dateiendung.substring(idx).toLowerCase() : '';
            if (type === '.pdf')
                return 'fa-file-pdf-o';
            else if (type === '.docx' || type === '.dotx' || type === '.docm' || type === '.dotm' || type === '.doc' || type === '.dot')
                return 'fa fa-file-word-o';
            else if (type === '.xlsx' || type === '.xls')
                return 'fa fa-file-excel-o';
            else if (type === '.pptx' || type === '.ppt')
                return 'fa fa-file-powerpoint-o';
            else if (type === '.xml')
                return 'fa-file-code-o';
            else if (type === '.png' || type === '.jpg' || type === '.jpeg' || type === '.tif' || type === '.gif' || type === '.bmp')
                return 'fa fa-file-image-o';
            else if (type === '.msg' || type === '.eml')
                return 'fa-envelope-o';
        }

        return 'fa-file-o';
    }

    public async openBase64PreviewDialog(base64: string, type: string = 'application/pdf') {
        const res = await fetch(`data:${type};base64,${base64}`);
        const blob = await res.blob();
        this.openBlobPreviewDialog(blob);
    }

    public async openBlobPreviewDialog(blob: Blob) { 
        if (blob) {
            const ref = this.dialogService.open(AviDokumentPreviewComponent, {
                header: 'Dokument',
                closable: true,
                width: '80%',
                baseZIndex: 10000,
                styleClass: 'dokument-preview-dialog doc-preview-dialog',
                data: { 
                    Blob: blob,
                    formType: AviDokumentPreviewComponent
                }
            });
            
            const res = await ref
                .onClose
                .pipe(tap(w => w), first())
                .toPromise();
    
            ref.destroy();

            return res;
        }
    }

    public async openPreviewDialog(docId: string, archivDefId: string, api: string = '/avidoc') {
        
        if (docId && archivDefId) {
            const ref = this.dialogService.open(AviDokumentPreviewComponent, {
                header: 'Dokument',
                closable: true,
                width: '80%',
                baseZIndex: 10000,
                styleClass: 'dokument-preview-dialog doc-preview-dialog',
                data: { 
                    ArchivDefId: archivDefId,
                    DocId: docId,
                    Api: api,
                    formType: AviDokumentPreviewComponent
                }
            });
            
            const res = await ref
                .onClose
                .pipe(tap(w => w), first())
                .toPromise();
    
            ref.destroy();

            return res;
        }
    }

    public async openDokumentEditingDialog(formMode: DokumentFormMode, model: any, contextId: string, contextType: string, file: File = null,
        docArtVorschlagId: string = null, docArtGruppeVorschlag: string = null, docArtUntergruppeVorschlag: string = null,
        hasESignatur: boolean = false, api: string = '/avidoc', apiPrefix: string = '') {
        
        const params = { 
            DokumentFormMode: formMode,
            DocContextId: contextId, DocContextType: contextType, 
            File: file,
            DocErfassenDocArtVorschlag_ID: docArtVorschlagId, 
            DocErfassenDocArtGruppeVorschlag: docArtGruppeVorschlag, 
            DocErfassenDocArtUntergruppeVorschlag: docArtUntergruppeVorschlag,
            Api: api,
            ApiPrefix: apiPrefix,
            HasEsignatur: hasESignatur
        };

        let title = 'Dokument';
        if (formMode === DokumentFormMode.CREATE)
            title = 'Dokument ablegen';
        else if (formMode === DokumentFormMode.UPDATE)
            title = 'Dokument ändern';
        else if (formMode === DokumentFormMode.UPDATESTATUS)
            title = 'Status ändern';
        else if (formMode === DokumentFormMode.UPDATEKEYWORDS)
            title = 'Metadaten ändern';

        return await this.commonService.openFormDialog(AviDokumentErfassenFormComponent, title, model, null, params, true);
    }
}
