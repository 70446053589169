import { ScriptDef } from '@avi-x/avi-dto/workflow/scriptdef.model';
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: ScriptDef) => any)) => typed_nameof<ScriptDef>(nameFunction);

@Component({
    selector: 'avi-scriptdef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
        [taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviScriptDefFormComponent extends AviAbstractModelFormComponent<ScriptDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFSCRIPTDEF';
    BaseUrl = 'workflow/scriptdef';
    ClassType = ScriptDef;
    FormType = AviScriptDefFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFStatusWorkflowDef';
    }

    public async afterCreateModel(bm: ScriptDef) {
        bm.vonStatusWorkflowDef_ID = this.ContextId;
    }

    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.Bezeichnung1)) {
            this.Model.Bezeichnung2 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung3 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung4 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung5 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung6 = this.Model.Bezeichnung1;
            this.Model.Bezeichnung7 = this.Model.Bezeichnung1;
        }
    }
    
    initFields() {
        if (this.ReadOnly)
            this.Fields.push(this.formFieldService.CreateGroup('grpRO', this.getFormHeader(true, false)));

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.CAFSCRIPTDEF.BEZEICHNUNG1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.CAFSCRIPTDEF.BEZEICHNUNG2', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.CAFSCRIPTDEF.BEZEICHNUNG3', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.CAFSCRIPTDEF.BEZEICHNUNG4', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.Context_ID), 'CORE.COMMON.MODEL.CAFSCRIPTDEF.CONTEXT', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateEmpty('e1').setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.Script), 'CORE.COMMON.MODEL.CAFSCRIPTDEF.SCRIPT', true).setMDSizeFull()); 
    }
}
