import { IDTO } from './../shared/idto.model';
import { Expose, Type } from "class-transformer";
import { DecisionTableParamDto } from './decisiontableparamdto.model';

export class DecisionTableEntryDto extends IDTO
{
	@Expose()
	public Id: string;

    @Expose()
	public Sequenz: number;

    @Expose()
	public vonDecisionTable_ID: string;

    @Expose()
    @Type(() => DecisionTableParamDto)
    public InputDecisionTableParam: DecisionTableParamDto[] = [];

    @Expose()
    @Type(() => DecisionTableParamDto)
    public OutputDecisionTableParam: DecisionTableParamDto[] = [];
}