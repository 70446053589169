import { TabViewModule } from 'primeng/tabview';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AviCoreModule } from '@avi-x/avi-core';

import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

// import { BrowserModule } from '@angular/platform-browser';

import { FormsModule } from '@angular/forms';
import { AviScriptDefFormComponent } from './components/scriptdef-form.component';
import { AviScriptDefSearcherComponent } from './components/scriptdef-searcher.component';
import { AviStatusDefFormComponent } from './components/statusdef-form.component';
import { AviStatusDefSearcherComponent } from './components/statusdef-searcher.component';
import { AviStatusWechselDefFormComponent } from './components/statuswechseldef-form.component';
import { AviStatusWechselDefSearcherComponent } from './components/statuswechseldef-searcher.component';
import { AviStatusWorkflowDefFormComponent } from './components/statusworkflowdef-form.component';
import { AviStatusWorkflowDefSearcherComponent } from './components/statusworkflowdef-searcher.component';
import { AviStatusWorkflowDefIndexComponent } from './components/statusworkflowdef-index.component';
import { AviEmailDefFormComponent } from './components/emaildef-form.component';
import { AviEmailDefIndexComponent } from './components/emaildef-index.component';
import { AviEmailDefSearcherComponent } from './components/emaildef-searcher.component';

@NgModule({
    imports: [
        // BrowserModule,
        FormsModule,
        CommonModule,
        AviCoreModule,

        FlexLayoutModule,
        PanelModule,
        TooltipModule,
        DropdownModule,
        CheckboxModule,
        ButtonModule,
        SplitButtonModule,
        InputTextModule,
        MenuModule,
        BreadcrumbModule,
        TabViewModule,

        NgxPermissionsModule.forChild(),

        TranslateModule
    ],
    exports: [
        AviScriptDefFormComponent,
        AviScriptDefSearcherComponent,
        AviStatusDefFormComponent,
        AviStatusDefSearcherComponent,
        AviStatusWechselDefFormComponent,
        AviStatusWechselDefSearcherComponent,
        AviStatusWorkflowDefFormComponent,
        AviStatusWorkflowDefSearcherComponent,
        AviStatusWorkflowDefIndexComponent,
        AviEmailDefFormComponent,
        AviEmailDefIndexComponent,
        AviEmailDefSearcherComponent
    ],
    declarations: [        
        AviScriptDefFormComponent,
        AviScriptDefSearcherComponent,
        AviStatusDefFormComponent,
        AviStatusDefSearcherComponent,
        AviStatusWechselDefFormComponent,
        AviStatusWechselDefSearcherComponent,
        AviStatusWorkflowDefFormComponent,
        AviStatusWorkflowDefSearcherComponent,
        AviStatusWorkflowDefIndexComponent,
        AviEmailDefFormComponent,
        AviEmailDefIndexComponent,
        AviEmailDefSearcherComponent
    ]
})
export class AviWorkflowModule { }
