import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AviAbstractModelSearcherComponent, AviApiService, AviCommonService, typed_nameof, AviBaseSearcherComponent, AviSessionControllerService } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AviAdressBlockDefFormComponent } from './adressblockdef-form.component';

import { AdressBlockDefSearchResponseDto } from '@avi-x/avi-dto/partner/adressblockdefsearchresponsedto.model';

const nameof = (nameFunction: ((obj: AdressBlockDefSearchResponseDto) => any)) => typed_nameof<AdressBlockDefSearchResponseDto>(nameFunction);

@Component({
    selector: 'avi-adressblockdef-searcher',
    templateUrl: './adressblockdef-searcher.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAdressBlockDefSearcherComponent extends AviAbstractModelSearcherComponent {  
    ClassName = 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF';
    SettingsKey = 'settings.searcher.adressblockdef';
    BaseUrl = 'adressblockdef/def';
	FormType = AviAdressBlockDefFormComponent;

    CanEdit: boolean = false;
	
    constructor(
        public commonService: AviCommonService,
        public apiService: AviApiService,
		public permissionsService: NgxPermissionsService,
		public sessionController: AviSessionControllerService,
        public cdr: ChangeDetectorRef
    ) { 
        super(commonService, apiService, cdr);

		this.permissionsService.hasPermission('CAFAdressBlockDef schreiben').then(w => this.CanEdit = w);
    }
	
    onDoubleClickSearcher(data) {
        if (this.SelectedRows) {
            this.sessionController.openSession('adressblockdef-index', this.SelectedRows.Id);
            // this.editObject(this.SelectedRows);
        }
    }

    onSearcherInit(searcher: AviBaseSearcherComponent) {
	    searcher.beginInit();
        searcher.addTextColumn(nameof(c => c.KurzBez), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.KURZBEZ1', true);
        searcher.addTextColumn(nameof(c => c.Bezeichnung), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.BEZEICHNUNG1', true);
        
        searcher.addListTypeColumn(nameof(c => c.VerwendungInFaktura), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.VERWENDUNGINFAKTURA', 'janein', true, true);        
        searcher.addListTypeColumn(nameof(c => c.VerwendungInDokumente), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.VERWENDUNGINDOKUMENTE', 'janein', true, true);
        searcher.addListTypeColumn(nameof(c => c.VerwendungInZwischenablage), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.VERWENDUNGINZWISCHENABLAGE', 'janein', true, true);
        
        searcher.addListTypeColumn(nameof(c => c.Zustellanweisung), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.ZUSTELLANWEISUNG', 'caf_zustellanweisung', true, true);
		searcher.endInit();
    }

    public SearchDelegate: any = async (searchValue: string, searchConfig: any) => {
        const cfg =  { ...searchConfig };
        cfg.Query = searchValue;
		cfg.ContextId = this.ContextId;
        const res = await this.apiService.postPagedResult(AdressBlockDefSearchResponseDto, `${this.BaseUrl}/search`, cfg);
        return res;
    }
}
