import { NgxPermissionsService } from 'ngx-permissions';
import { EventEmitter, Injectable } from '@angular/core';

import { AviApiService } from './api.service';
import { AviCommonService } from './common.service';
import { UserInfo, UserSetting } from '../dto/userinfo.model';
import { AviListDetailConst } from '../shared/constants/constants';
import { filter, map } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

export interface IAuthData {
    username: string;
    password: string;
    totp: string;
    usernameProxy: string;
}

export interface IAuthDataOIDC {
    provider: string;
    code: string;
    // eslint-disable-next-line camelcase
    session_state?: string;
}

export interface IAuthDataPwChange {
    uid: string;
    current_password: string;
    new_password1: string;
    new_password2: string;
}

export interface IAuthDataDoPwReset{
    username: string;
    token: string;
    password1: string;
    password2: string;
}

export class AviCurrentUser {
    public Uid: string;
    public Username: string;
    public FirstName: string;
    public LastName: string;
    public Roles: Array<string>;
    public ExpiresIn: number;
    public ExpiresAtUtc: string;

    public IsAdmin: boolean;

    public AccessToken: string;
    public RefreshToken: string;

    public PartnerId: string;
    public MitarbeiterId: string;
    public Authentizierung: string;
    public ShortName: string;
    public LanguageId: number;
    public CustomInfo?:any;
    public UserSettings: UserSetting[];

    public hasRole(role: string, ignoreAdmin: boolean = false): boolean {
        if (!ignoreAdmin && this.IsAdmin)
            return true;

        return this.Roles && this.Roles.some((value, index, array) => value === role);

        // console.log(`hasRole(${role}) = ${ret}`);
    }

    public get FullName(): string {
        return `${this.FirstName} ${this.LastName}`.trim();
    }
}

// eslint-disable-next-line no-unused-vars
export enum UserTotpState { UNKNOWN = 0, DISABLED = 10, VERIFICATION_PENDING = 20, ACTIVATED = 30 };

@Injectable({ providedIn: 'root' })
export class AviAuthService {
    private tlo: any = null; // Timer für TimedLoggout
    private ilo: any = null; // Timer für InactivityLogout
    private _CurrentUser: AviCurrentUser;
    public UserChanged = new EventEmitter<AviCurrentUser>();

    private inlineEditingRoleDelegate: () => boolean = null;

    public inlineEditingObs;
    private ApiPrefix: string = '';

    constructor(public apiService: AviApiService, 
        public commonService: AviCommonService, 
        public permissionService: NgxPermissionsService,
        private oauthService: OAuthService,
        public router: Router,
        private http: HttpClient) {
        this.UserChanged.emit(this.CurrentUser);

        this.inlineEditingObs = this.permissionService.permissions$.pipe(map(w => w['Resourcen lesen']));
    }
    
    async ensureApiPrefix(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.ApiPrefix) {
                resolve(this.ApiPrefix);
            } else {
                this.http.get('assets/config.txt')
                    .toPromise<any>().then(jsonConfig => {
                        this.ApiPrefix = jsonConfig.baseUrl_prefix;
                        resolve(this.ApiPrefix);
                    });
            }
        });
    }

    public getTotpState() : Promise<UserTotpState> {
        return new Promise<UserTotpState>((resolve, reject)=>{
            this.apiService.get('user/totp-state').then(r => {
                resolve(r.StateId);
            }).catch(err => {
                reject(err);
            });
        });
    }

    public getTotpProvImage() {
        return this.apiService.get('user/totp-prov-image');
    }

    public initTotp() {
        return this.apiService.get('user/totp-init');
    }

    public activateTotp(pin:string) {
        return this.apiService.post('user/totp-activate', {pin: pin});
    }

    public removeTotp() {
        return this.apiService.delete('user/totp-remove');
    }

    public registerInactivityLogout(appName: string, timeoutInMinutes: number, delegate: Function, checkIntervalInSeconds = 10) {
        const keyName = `avix::${appName}/lastactive`;
        localStorage.setItem(keyName, new Date().toString());

        document.onmousemove = (evt) => { localStorage.setItem(keyName, new Date().toString()) };
        document.onkeydown = (evt) => { localStorage.setItem(keyName, new Date().toString()) };
        // clearInterval(this.ilo);
        this.ilo = setInterval(() => {
            if (!this.IsLoggedIn)
                return;

            const lastUserInteraction = new Date(localStorage.getItem(keyName));
            const reamingTime = (timeoutInMinutes || 15) * 60 - (Math.floor((new Date().getTime() - lastUserInteraction.getTime()) / 1000));

            if (!this.commonService.config.environment?.production === true)
                console.debug(`InactivityCheck - Logout in ${reamingTime} Sekunden`);
            if (reamingTime <= 0) {
                delegate();
                clearInterval(this.ilo);
                this.ilo = null;
            }
        }, (checkIntervalInSeconds * 1000));
    }

    public registerTimedLoggout(delegate: Function, checkIntervalInSeconds = 10) {
        if (this.CurrentUser && this.CurrentUser.ExpiresAtUtc) {
            this.tlo = setInterval(() => {
                const timeOutZero = 0;

                const dt = new Date(this.CurrentUser.ExpiresAtUtc);
                const inSeconds = (dt.getTime() / 1000) - ((new Date().getTime() / 1000) + timeOutZero);

                if (!this.commonService.config.environment?.production === true)
                    console.debug(`Current User Expiration / autologout in ${Math.round(inSeconds)} Sekunden (${this.CurrentUser.ExpiresAtUtc})`);
                if (inSeconds < 0) {
                    clearInterval(this.tlo);
                    this.tlo = null;
                    delegate();
                }
            }, (checkIntervalInSeconds * 1000));
        }
    }

    public registerDefaultTimedLogout(logoutOAuth: boolean) {
        this.registerTimedLoggout(() => {
            this.Logout();
            if (logoutOAuth) 
                this.oauthService.logOut();

            this.router.navigate([this.commonService.config.loginPath]);
            this.commonService.notificateSuccess('Sie wurden automatisch abgemeldet.');
            this.LogoutServerSide();
        });
    }

    public registerDefaultInactivityLogout(appName: string, timeoutInMinutes: number, logoutOAuth: boolean) {
        
        this.registerInactivityLogout(appName, timeoutInMinutes, () => {
            this.Logout();
            if (logoutOAuth) 
                this.oauthService.logOut();
            this.router.navigate([this.commonService.config.loginPath]);
            this.commonService.notificateSuccess('Sie wurden aufgrund von Inaktivität automatisch abgemeldet.');
            this.LogoutServerSide();
        });
    }

    
    public subscribeOAuthTokenReceived(): Subscription {

        return this.oauthService.events
            .pipe(filter((e: any) => {
                return e.type === 'token_received';
            }))
            .subscribe(() => {
                if (this.IsLoggedIn && this.oauthService.hasValidAccessToken()) {
                    this.CurrentUser.AccessToken = this.oauthService.getAccessToken();
                    this.CurrentUser.ExpiresAtUtc = new Date(this.oauthService.getAccessTokenExpiration()).toISOString(); // res.expires_at_utc;
                    this.SetCurrentUser(this.CurrentUser);
                    // console.info("token aktualisiert auf currentuser", new Date(), this.authService.CurrentUser.AccessToken);
                } else {
                    // console.warn("nicht angemeldet... token nicht aktualisiert",  new Date());
                }
            });        
    }


    public get BaseUrl(): string {
        return this.apiService.getBaseUrl();
    }

    public async loadUserInfos(token: string = null, apiPrefix: string = null): Promise<UserInfo> {
        let url = 'user/userinfo';
        if (apiPrefix)
            url = apiPrefix + (apiPrefix.endsWith('/') ? '' : '/') + url;
        return await this.apiService.getModel(UserInfo, url, token ? [this.apiService.buildHeader('Authorization', `Bearer ${token}`)] : null);
    }

    public async IsSsoEnabled(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.apiService.get('user/win-auth-info').then(r => resolve(r.SSOEnabled === true)).catch(err => reject(err));
        });
    }

    public async UpdateAccessToken(refreshToken: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.commonService.logDebug('UpdateAccessToken: ' + refreshToken);
            const data = `refresh_token=${encodeURIComponent(refreshToken)}`;
            this.apiService.post('token/refresh', data, [this.apiService.buildHeader('Content-Type', 'application/x-www-form-urlencoded')])
                .then(
                    async res => {
                        this.commonService.logDebug('UpdateAccessToken Result', res);
                        const cu = await this._buildCurrentUser(this.CurrentUser.Username, res);
                        this.SetCurrentUser(cu);
                        resolve(cu);
                    }).catch(err => { reject(err); });
        });
    }

    public registerDefaultRefreshTokenHandler(timeoutInMinutes: number) {

        const refreshTokenDelegate = () => {
            if (this.IsLoggedIn && this.CurrentUser.RefreshToken) {
                this.UpdateAccessToken(this.CurrentUser.RefreshToken)
                    .then(r => {
                        // if (!environment.production)
                        //     console.log('Refresh-Token Aktualisiert', r);
                    })
                    .catch(err => {
                        this.Logout();
                        this.router.navigate([this.commonService.config.loginPath]);
                        console.error("UpdateAccessToken error", err);
                        this.commonService.notificateSuccess('Sie wurden automatisch abgemeldet.');
                        this.LogoutServerSide();
                    });
            }
        };

        // Jetzt und alle 5 Minuten Refresh-Token aktualisieren
        refreshTokenDelegate();
        setInterval(async () => {
            refreshTokenDelegate();
        }, (timeoutInMinutes * 60 * 1000));        
    }

    public async FillCurrentUserWithUserInfos() {
        const cu = this.CurrentUser;
        const userInfos = await this.loadUserInfos(cu.AccessToken);
        cu.Uid = userInfos.LoginuserID;
        cu.PartnerId = userInfos.LoginPartnerID;
        cu.MitarbeiterId = userInfos.MitarbeiterID;
        cu.Roles = userInfos.Roles;
        cu.IsAdmin = userInfos.IsAdmin;
        cu.FirstName = userInfos.Vorname;
        cu.LastName = userInfos.Name;
        cu.ShortName = userInfos.KurzZeichen;
        cu.LanguageId = userInfos.UserLanguageID;
        cu.Authentizierung = userInfos.Authentizierung;
        cu.CustomInfo = userInfos.CustomInfo;
        this.SetCurrentUser(cu);
    }

    public async Login(authData: IAuthData, afterLoginDelegate: Function = null): Promise<AviCurrentUser> {
        return new Promise(async (resolve, reject) => {
            try {
                this.commonService.logDebug('starting login for ' + authData.username);

                let data = `username=${encodeURIComponent(authData.username)}&password=${encodeURIComponent(authData.password)}`;
                if (authData.totp)
                    data += `&totp=${encodeURIComponent(authData.totp)}`;
                const res = await this.apiService.post('token', data, [this.apiService.buildHeader('Content-Type', 'application/x-www-form-urlencoded')]);
                this.commonService.logDebug('Login Result', res);

                if (res && res.message === 'totp_required') {
                    reject(new Error(res.message));
                    return;
                }

                const cu = await this._buildCurrentUser(authData.username, res);
                this.SetCurrentUser(cu);

                if (afterLoginDelegate != null)
                    afterLoginDelegate(cu);

                resolve(cu);
            } catch (err) {
                reject(err);
            }
        });
    }

    public async LoginWithOIDC(authData: IAuthDataOIDC, afterLoginDelegate: Function = null): Promise<AviCurrentUser> {
        return new Promise(async (resolve, reject) => {
            try {
                this.commonService.logDebug('starting login for ' + authData.code);

                const res = await this.apiService.post('token-oidc', authData);
                this.commonService.logDebug('Login Result', res);

                if (res && res.message === 'account_required') {
                    reject(new Error(res.message));
                    return;
                }

                const cu = await this._buildCurrentUser(null, res);
                this.SetCurrentUser(cu);

                if (afterLoginDelegate != null)
                    afterLoginDelegate(cu);

                resolve(cu);
            } catch (err) {
                reject(err);
            }
        });
    }


    public async LoginGetTokenWithSSO(authData: IAuthData, afterLoginDelegate: Function = null): Promise<AviCurrentUser> {
        return new Promise(async (resolve, reject) => {
            try {
                this.commonService.logDebug('starting sso login');

                if (!await this.IsSsoEnabled()) {
                    reject(new Error('sso_not_enabled'));
                    return;
                }

                const res = await this.apiService.post('user/win-auth', { totp: authData.totp }, null, true);
                this.commonService.logDebug('Login Result', res);

                if (res && res.message === 'totp_required') {
                    reject(new Error(res.message));
                    return;
                }

                const cu = await this._buildCurrentUser(res.username, res.jwt);
                this.SetCurrentUser(cu);

                if (afterLoginDelegate != null)
                    afterLoginDelegate(cu);

                resolve(cu);
            } catch (err) {
                console.warn(err);
                reject(err);
            }
        });
    }

    public async LoginWithSSO(afterLoginDelegate: Function = null): Promise<AviCurrentUser> {
        return new Promise(async (resolve, reject) => {
            try {
                this.commonService.logDebug('starting sso login ');

                const res = await this.apiService.get('user/userinfo');
                this.commonService.logDebug('Login Result', res);
                const cu = await this._buildCurrentUser(res.Username, res);
                this.SetCurrentUser(cu);

                if (afterLoginDelegate != null)
                    afterLoginDelegate(cu);

                resolve(cu);
            } catch (err) {
                reject(err);
            }
        });
    }

    public async InitPwReset(username: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = { username: username };
                const res = await this.apiService.post('user/init-pw-reset', data);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    public async DoPwReset(data: IAuthDataDoPwReset) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await this.apiService.post('user/do-pw-reset', data);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    public async DoPwChange(data: IAuthDataPwChange) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await this.apiService.post('user/do-pw-change', data);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    private async _buildCurrentUser(username: string, authRes: any): Promise<AviCurrentUser> {
        const cu = new AviCurrentUser();

        cu.AccessToken = authRes.access_token;
        cu.RefreshToken = authRes.refresh_token;
        cu.Username = username; // res.username;
        cu.ExpiresIn = authRes.expires_in || 99999999;
        // cu.FirstName = res.first_name;
        // cu.LastName = res.last_name;
        const dt = new Date();
        dt.setTime(dt.getTime() + (cu.ExpiresIn * 1000));
        cu.ExpiresAtUtc = dt.toISOString(); // res.expires_at_utc;
        // this.SetCurrentUser(cu);

        const userInfos = await this.loadUserInfos(cu.AccessToken);
        cu.Uid = userInfos.LoginuserID;
        cu.PartnerId = userInfos.LoginPartnerID;
        cu.MitarbeiterId = userInfos.MitarbeiterID;
        cu.Roles = userInfos.Roles;
        cu.IsAdmin = userInfos.IsAdmin;
        cu.FirstName = userInfos.Vorname;
        cu.LastName = userInfos.Name;
        cu.ShortName = userInfos.KurzZeichen;
        cu.LanguageId = userInfos.UserLanguageID;
        cu.Authentizierung = userInfos.Authentizierung;
        cu.CustomInfo = userInfos.CustomInfo;
        if (!cu.Username)
            cu.Username = userInfos.Username;

        cu.UserSettings = userInfos.UserSettings;
        this.permissionService.addPermission(userInfos.Roles);

        return cu;
    }

    public Logout(): void {
        this.commonService.localStorageDelete('currentUser');
        this._CurrentUser = null;
        this.UserChanged.emit(null);
        if (this.tlo) {
            clearInterval(this.tlo);
            this.tlo = null;
        }
        if (this.ilo) {
            clearInterval(this.ilo);
            this.ilo = null;
        }
    }

    public LogoutServerSide(){
        return new Promise(async (resolve, reject) => {
            try {
                this.commonService.logDebug('starting server side logout');
                const res = await this.apiService.post('token-logout', {logout:1});
                this.commonService.logDebug('Server Side Logout Result', res);
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
    }


    public get CurrentUserName(): string {
        return this.CurrentUser ? this.CurrentUser.Username : null;
    }

    public GetCurrentUserFromLocalStorage(): AviCurrentUser {
        const sessionStorage = this.commonService.config.saveLoginUserInSessionStorage === true;
        const cu = this.commonService.localStorageGet('currentUser', null, true, sessionStorage) as AviCurrentUser;
        return cu ? Object.assign(new AviCurrentUser(), cu) : null;
    }

    public get CurrentUser(): AviCurrentUser {
        if (!this._CurrentUser) {
            this._CurrentUser = this.GetCurrentUserFromLocalStorage();
        }
        return this._CurrentUser;
    }

    public currentUserHasTotpEnabled() {
        if (!this.IsLoggedIn)
            return false;

        return this.CurrentUser.Authentizierung === AviListDetailConst.CAF_AUTHENTICATION_TOTP;
    }

    public currentUserHasOneOfRoles(roles: string[], ignoreAdmin: boolean = false) {
        if (!this.IsLoggedIn)
            return false;

        if (!roles || roles.length == 0)
            return true;

        return roles.some(w => this.CurrentUser.hasRole(w, ignoreAdmin));
    }

    public currentUserHasRole(role: string, ignoreAdmin: boolean = false) {
        if (!this.IsLoggedIn)
            return false;
        return this.CurrentUser.hasRole(role, ignoreAdmin);
    }

    public getUserImage(uid: string = null) {
        if (uid == null && !this.CurrentUser)
            return null;

        if (uid == null)
            uid = this.CurrentUser.Uid;

        return `${this.apiService.getBaseUrl()}account/account/user/${uid}/image`;
    }


    public get IsAdmin(): boolean {
        const cu = this.CurrentUser;
        if (cu == null)
            return false;
        return cu.IsAdmin;
    }

    public get IsLoggedIn(): boolean {
        const cu = this.CurrentUser;
        return cu != null;
    }

    public SetCurrentUser(user: AviCurrentUser) {
        const sessionStorage = this.commonService.config.saveLoginUserInSessionStorage === true;
        this.commonService.localStorageSet('currentUser', user, true, sessionStorage);
        this._CurrentUser = user;
        this.UserChanged.emit(this.CurrentUser);
    }

    public GetProfile(): Promise<any> {
        return this.apiService.get('account/user/profile');
    }

    public SaveProfile(profile: any): Promise<any> {
        return this.apiService.post('account/user/profile', profile);
    }

    public ChangePassword(uid: string, currentPw: string, newPassword: string): Promise<any> {
        return this.apiService.post(`account/user/${uid}/changepassword`, { CurrentPassword: currentPw, NewPassword: newPassword });
    }

    public GetUserIdentityProviderLinks() {
        return this.apiService.get('user/identity-provider-links');
    }

    public AddUserIdentityProviderLink(authData: IAuthDataOIDC) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await this.apiService.post('user/identity-provider-links/add', authData);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    public DeleteUserIdentityProviderLink(id:string) {
        return this.apiService.delete(`user/identity-provider-links/${id}`);
    }

    public hasInlineEditingRole(): Observable<boolean> {
        if (this.inlineEditingRoleDelegate != null)
            return of(this.inlineEditingRoleDelegate());
        
        return this.permissionService.permissions$.pipe(map(w => !!w['Resourcen lesen']));
    }

    public registerInlineEditingRoleDelegate(func: () => boolean) {
        this.inlineEditingRoleDelegate = func; 
    }

    // user settings
    
    public hasUserSettings(): boolean {
        return !!this.CurrentUser?.UserSettings;
    }
    
    getUserSetting(key: string): any {
        const value = this.CurrentUser?.UserSettings ? this.CurrentUser.UserSettings.find(x => x.Key === key)?.Value : null;
        if (value)
            return JSON.parse(value);

        return null;
    }
    
    async updateUserSetting(key: string, value: any) {
        if (this.hasUserSettings()) {
            const strval = JSON.stringify(value);
            const setting = this.CurrentUser.UserSettings.find(x => x.Key === key);
            if (setting)
                setting.Value = strval;
            else 
                this.CurrentUser.UserSettings.push({Key: key, Value: strval});
            await this.ensureApiPrefix();
            await this.apiService.post(`${this.ApiPrefix ?? ''}/user/setting`, { Key: key, Value: strval });
        }
    }
}
