import { Expose, Type } from 'class-transformer';

import { Sprache } from '../system/sprache.model';
import { BasePartner } from '../partner/basepartner.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';
import { EncryptedType } from '../shared/encryptedtype.model';

export class AppUser extends BaseModel {  
    @Expose()
    public get AppUserTyp(): string { return this.getProperty('AppUserTyp'); }
    public set AppUserTyp(value: string) { this.setProperty('AppUserTyp', value); }
    
    @Expose()
    @Type(() => Date)
    public get GueltigAb(): Date { return this.getProperty('GueltigAb'); }
    public set GueltigAb(value: Date) { this.setProperty('GueltigAb', value); }
    
    @Expose()
    @Type(() => Date)
    public get GueltigBis(): Date { return this.getProperty('GueltigBis'); }
    public set GueltigBis(value: Date) { this.setProperty('GueltigBis', value); }
    
    @Expose()
    public get System(): number { return this.getProperty('System'); }
    public set System(value: number) { this.setProperty('System', value); }
    
    @Expose()
    public get Username(): string { return this.getProperty('Username'); }
    public set Username(value: string) { this.setProperty('Username', value); }
    
    @Expose()
    public get Passwort(): string { return this.getProperty('Passwort'); }
    public set Passwort(value: string) { this.setProperty('Passwort', value); }
    
    @Expose()
    public get Kurzz(): string { return this.getProperty('Kurzz'); }
    public set Kurzz(value: string) { this.setProperty('Kurzz', value); }
    
    @Expose()
    public get Name(): string { return this.getProperty('Name'); }
    public set Name(value: string) { this.setProperty('Name', value); }
    
    @Expose()
    public get Vorname(): string { return this.getProperty('Vorname'); }
    public set Vorname(value: string) { this.setProperty('Vorname', value); }
    
    @Expose()
    public get Email(): string { return this.getProperty('Email'); }
    public set Email(value: string) { this.setProperty('Email', value); }
    
    @Expose()
    public get ExterneReferenz(): string { return this.getProperty('ExterneReferenz'); }
    public set ExterneReferenz(value: string) { this.setProperty('ExterneReferenz', value); }
    
    @Expose()
    public get PasswdExpirationMonths(): number { return this.getProperty('PasswdExpirationMonths'); }
    public set PasswdExpirationMonths(value: number) { this.setProperty('PasswdExpirationMonths', value); }
    
    @Expose()
    @Type(() => Date)
    public get NextPwdExpiration(): Date { return this.getProperty('NextPwdExpiration'); }
    public set NextPwdExpiration(value: Date) { this.setProperty('NextPwdExpiration', value); }
    
    @Expose()
    public get Domain(): string { return this.getProperty('Domain'); }
    public set Domain(value: string) { this.setProperty('Domain', value); }
    
    @Expose()
    public get AcitivityReminderDisabled(): boolean { return this.getProperty('AcitivityReminderDisabled'); }
    public set AcitivityReminderDisabled(value: boolean) { this.setProperty('AcitivityReminderDisabled', value); }
    
    @Expose()
    public get Sprache_ID(): string { return this.getModelId('Sprache_ID'); }
    public set Sprache_ID(value: string) { this.setModelId('Sprache_ID', value); }
 
    @Expose()
    @Type(() => Sprache)
    public get Sprache(): Sprache { return this.getModel('Sprache'); }
    public set Sprache(value: Sprache) { this.setModel('Sprache', value); }
    
    @Expose()
    public get Partner_ID(): string { return this.getModelId('Partner_ID'); }
    public set Partner_ID(value: string) { this.setModelId('Partner_ID', value); }
 
    @Expose()
    @Type(() => BasePartner)
    public get Partner(): BasePartner { return this.getModel('Partner'); }
    public set Partner(value: BasePartner) { this.setModel('Partner', value); }
    
    @Expose()
    @Type(() => ListType)
    public get AdminUser(): ListType { return this.getProperty('AdminUser'); }
    public set AdminUser(value: ListType) { this.setProperty('AdminUser', value); }
    
    @Expose()
    @Type(() => ListType)
    public get SupportUser(): ListType { return this.getProperty('SupportUser'); }
    public set SupportUser(value: ListType) { this.setProperty('SupportUser', value); }
    
    @Expose()
    @Type(() => ListType)
    public get StrongPassword(): ListType { return this.getProperty('StrongPassword'); }
    public set StrongPassword(value: ListType) { this.setProperty('StrongPassword', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Authentizierung(): ListType { return this.getProperty('Authentizierung'); }
    public set Authentizierung(value: ListType) { this.setProperty('Authentizierung', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DsgvoAktiv(): ListType { return this.getProperty('DsgvoAktiv'); }
    public set DsgvoAktiv(value: ListType) { this.setProperty('DsgvoAktiv', value); }
    
    @Expose()
    @Type(() => ListType)
    public get DsgvoBeauftragter(): ListType { return this.getProperty('DsgvoBeauftragter'); }
    public set DsgvoBeauftragter(value: ListType) { this.setProperty('DsgvoBeauftragter', value); }
    
    @Expose()
    @Type(() => EncryptedType)
    public get AppServerApiKey(): EncryptedType { return this.getProperty('AppServerApiKey'); }
    public set AppServerApiKey(value: EncryptedType) { this.setProperty('AppServerApiKey', value); }
    
    @Expose()
    @Type(() => EncryptedType)
    public get TOTPKey(): EncryptedType { return this.getProperty('TOTPKey'); }
    public set TOTPKey(value: EncryptedType) { this.setProperty('TOTPKey', value); }
    
}
