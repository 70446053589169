import { Inject, Injectable, InjectionToken } from "@angular/core";
import { AviApiService, AviCoreConfig } from "@avi-x/avi-core";
import { TextblockDto } from '@avi-x/avi-dto/system/textblockdto.model';

import moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class AviTextblockService {
    constructor(@Inject('config') public config: AviCoreConfig, public apiService: AviApiService) {
    }

    public GetTextblock(identifier: string, stichtag: Date): Promise<TextblockDto> {
        const strStichtag = moment(stichtag).format('YYYY-MM-DD');
        return this.apiService.getModel(TextblockDto, `${this.config.publicUrl ?? ''}/textblock/${identifier}/${strStichtag}`);
    }

    public HasTextblock(identifier: string): Promise<boolean> {
        return this.apiService.get(`${this.config.privateUrl ?? ''}/textblock/exists/${identifier}`);
    }

    public GetAllTextblock(filter: string = null) : Promise<TextblockDto[]> {
        return this.apiService.getModelList(TextblockDto, `${this.config.privateUrl ?? ''}/textblock`);
}

    public SaveTextblock(data: TextblockDto) {
        return this.apiService.post(`${this.config.privateUrl ?? ''}/textblock`, data);
    }
}
