import { Injectable } from '@angular/core';
import { AviCurrentUser } from './auth.service';
import { AviCommonService } from './common.service';
export class AviCustomHeader { key: string; value: string; constructor(key: string, value: string) { this.key = key; this.value = value; } }

// import 'rxjs/add/operator/toPromise';
// import 'rxjs/add/operator/map';


import { IDTO } from '@avi-x/avi-dto/shared/idto.model';
import { ClassType } from '@avi-x/avi-dto/shared/basemodel.model';
import { ObjectUtils } from '../components/utils/object-utils';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AviApiErrorObject } from '../dto/aviapierrorobject';
import { TranslateService } from '@ngx-translate/core';
import { PagedResult } from '@avi-x/avi-dto/shared/pagedresult.model';
import { ListType } from '@avi-x/avi-dto/shared';

/*
export class ApiServiceConfig {
    public ApiBaseUrl: string = null;
}
*/

@Injectable({ providedIn: 'root' })
export class AviApiService {
    private BaseUrl: string = null;
    private BaseUrlIntern: string = null;

    private _useInternBaseUrl: boolean = false;

    public GetHeadersAsArray(includeAuthHeaderIfPossible: boolean, contentType: string = 'application/json'): Array<AviCustomHeader> {
        const headers = []; // new Array<AviCustomHeader>();
        // headers.push(new CustomHeader("",""));
        const existingCT = headers.find((h) => h.key === 'Content-Type');
        if (contentType != null && existingCT == null)
            headers.push(new AviCustomHeader('Content-Type', contentType));
        headers.push(new AviCustomHeader('Accept', 'application/json'));


        if (includeAuthHeaderIfPossible === true) {
            const sessionStorage = this.commonService.config.saveLoginUserInSessionStorage === true;
            const currentUser = <AviCurrentUser>AviCommonService.localStorageGet<any>('currentUser', null, true, sessionStorage);
            if (currentUser) {
                headers.push(new AviCustomHeader('Authorization', 'Bearer ' + currentUser.AccessToken));
            }
        }

        return headers;
    }

    constructor(public http: HttpClient, public commonService: AviCommonService, private translateService: TranslateService) {
        this.ensureBaseUrl();
    }


    public getAccessToken(): string {
        const sessionStorage = this.commonService.config.saveLoginUserInSessionStorage === true;
        const currentUser = <AviCurrentUser>AviCommonService.localStorageGet<any>('currentUser', null, true, sessionStorage);
        if (currentUser)
            return currentUser.AccessToken;
        else
            return null;
    }

    public UseInternBaseUrl(use: boolean = true) {
        this._useInternBaseUrl = use;
    }


    public async getAppServerUrl(): Promise<string> {
        return await this.ensureBaseUrl();
    }

    public getBaseUrl(): string {
        if (this._useInternBaseUrl && this.BaseUrlIntern)
            return this.BaseUrlIntern;

        return this.BaseUrl;
    }

    async ensureBaseUrl(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.BaseUrl) {
                resolve(this.getBaseUrl());
            } else {
                this.http.get('assets/config.txt')
                    // .map(res => res.json())
                    .toPromise<any>().then(jsonConfig => {
                        if (!this.commonService.IsProduction)
                            console.debug('jsonConfig', jsonConfig);
                        this.BaseUrl = jsonConfig.baseURL;
                        if (jsonConfig.baseUrl_intern)
                            this.BaseUrlIntern = jsonConfig.baseUrl_intern;
                        resolve(this.getBaseUrl());
                    });
            }
        });
    }

    public encodeQueryData(params: any, useBracketsForArrays = false) {
        let queryString = Object.keys(params).map(key => {
            if (params[key].constructor === Array) {
                let theArrSerialized = '';
                for (const singleArrIndex of params[key]) {
                    theArrSerialized = theArrSerialized + key + (useBracketsForArrays ? '[]' : '') + '=' + singleArrIndex + '&';
                }
                return theArrSerialized;
            } else {
                return key + '=' + params[key] + '&';
            }
        }
        ).join('');
        if (queryString.endsWith('&'))
            queryString = queryString.substring(0, queryString.length - 1);
        console.log('encodeQueryData', params, queryString);
        return queryString;
    }

    public extendsApiUrl(url: string): boolean {
        return url.toLowerCase().startsWith(this.getBaseUrl().toLowerCase());
    }

    public async Find(url: string, q: string = null, filter: string = null, init: string = null, select: string = null, sort: string = null, page: string = null, perPage: string = null): Promise<any[] | any> {
        // console.log('ApiService.Find', url, q, filter, init);
        if (url.indexOf('?') < 0)
            url += '?gen=true';

        if (filter)
            url += filter; // '?filter=name contains ' + q;

        if (init)
            url += init;

        if (sort)
            url += sort;

        if (select)
            url += select;

        if (page)
            url += page;

        if (perPage)
            url += perPage;

        // console.log('Find', url);
        return this.get(url, [this.buildHeader('q', q)]);
    }

    public async get(url: string, customHeaders: Array<AviCustomHeader> = [], withCredentials: boolean = false, withAuthHeaders: boolean = true, params: HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;} = null): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);
        // console.trace("ApiService.get", intUrl);
        const start = new Date().getTime();
        return this.http.get(intUrl, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders), withCredentials: withCredentials, params: params })
            .toPromise()
            .then(response => { this._logMS(start, intUrl); return AviApiService.handleJsonResponse(response); })
            .catch(this.handleError);
    }

    public async getWithParams(url: string, params: any, customHeaders: Array<AviCustomHeader> = [], withCredentials: boolean = false, withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);
        // console.trace("ApiService.get", intUrl);
        const start = new Date().getTime();
        return this.http.get(intUrl, { params: params, headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders), withCredentials: withCredentials })
            .toPromise()
            .then(response => { this._logMS(start, intUrl); return AviApiService.handleJsonResponse(response); })
            .catch(this.handleError);
    }

    public async getOne(url: string, id: string, customHeaders: Array<AviCustomHeader> = [], withCredentials: boolean = false, withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        let intUrl: string = this.buildApiUrl(url);
        if (id != null) intUrl += (intUrl.endsWith('/') ? '' : '/') + /* "?Id="+*/id;
        const start = new Date().getTime();
        return this.http.get(intUrl, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders), withCredentials: withCredentials })
            .toPromise()
            .then(response => { this._logMS(start, intUrl); return AviApiService.handleJsonResponse(response); })
            .catch(this.handleError);
    }

    public async getBlob(url: string, customHeaders: Array<AviCustomHeader> = [], withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);
        const start = new Date().getTime();

        let headers = this.getHeaders(customHeaders, false, withAuthHeaders);
        if (this.extendsApiUrl(intUrl)) {
            const sessionStorage = this.commonService.config.saveLoginUserInSessionStorage === true;
            const currentUser = <AviCurrentUser>AviCommonService.localStorageGet<any>('currentUser', null, true, sessionStorage);
            if (currentUser) {
                headers = headers.set('Authorization', 'Bearer ' + currentUser.AccessToken);
            }
            headers = headers.set('Accept', 'application/octet-stream');
        }

        return this.http.get(intUrl, { headers: headers, responseType: 'blob' as 'json' })
            .toPromise()
            .then(response => {
                this._logMS(start, intUrl);
                return response;
            })
            .catch(this.handleError);
    }

    public async getBlobWithPost(url: string, data: any, customHeaders: Array<AviCustomHeader> = [], withAuthHeaders: boolean = true, customObserve: any = 'body'): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);
        const start = new Date().getTime();

        let headers = this.getHeaders(customHeaders, false, withAuthHeaders);
        if (this.extendsApiUrl(intUrl)) {
            const sessionStorage = this.commonService.config.saveLoginUserInSessionStorage === true;
            const currentUser = <AviCurrentUser>AviCommonService.localStorageGet<any>('currentUser', null, true, sessionStorage);
            if (currentUser) {
                headers = headers.set('Authorization', 'Bearer ' + currentUser.AccessToken);
            }
            headers = headers.set('Accept', 'application/octet-stream');
        }

        return this.http.post(intUrl, data, { headers: headers, responseType: 'blob' as 'json', observe: customObserve })
            .toPromise()
            .then(response => {
                this._logMS(start, intUrl);
                return response;
            })
            .catch(this.handleError);
    }

    public async getBlobWithPostFile(url: string, fileToUpload: File, customHeaders: Array<AviCustomHeader> = [], withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);

        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        const start = new Date().getTime();

        customHeaders = [new AviCustomHeader('Content-Disposition', 'multipart/form-data')];

        return this.http.post(intUrl, formData, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders, null), responseType: 'blob' as 'json' })
            .toPromise()
            .then(response => {
                this._logMS(start, intUrl);
                return response;
            })
            .catch(this.handleError);
    }

    public async delete(url: string, customHeaders: Array<AviCustomHeader> = [], withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);
        return this.http.delete(intUrl, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders) })
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }

    public deleteObject(url: string, objectId: string, onSuccess: Function) {
        if (objectId) {
            this.commonService.confirmDelete(this.translateService.instant('CORE.COMMON.CONFIRM_DELETE'),
                () => {
                    this.delete(`${url}/${objectId}`).then(r => {
                        this.commonService.notificateSuccess(this.translateService.instant('CORE.COMMON.GELOESCHT'));
                        if (onSuccess)
                            onSuccess();
                    });
                }
            );
        } else {
            this.translateService.get('CORE.COMMON.CHOOSE_RECORD')
                .toPromise()
                .then(w => this.commonService.notificateError(w));
        }
    }

    public async post(url: string, data: any, customHeaders: Array<AviCustomHeader> = [], withCredentials: boolean = false, withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);

        if (data instanceof IDTO)
            data = ObjectUtils.BaseModelToObject(data);

        if (Array.isArray(data)) {
            for (let index = 0; index < data.length; index++) {
                if (data[index] instanceof IDTO)
                    data[index] = ObjectUtils.BaseModelToObject(data[index]);
            }
        }

        const body = typeof data === 'string' ? data : this.commonService.stringifyWithNl(data);
        // console.trace("ApiService.post", intUrl, body);
        const start = new Date().getTime();
        return this.http.post(intUrl, body, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders), withCredentials: withCredentials })
            .toPromise<any>()
            .then(response => { this._logMS(start, intUrl); return AviApiService.handleJsonResponse(response); })
            .catch(this.handleError);
    }

    public async postFile(url: string, fileToUpload: File, customHeaders: Array<AviCustomHeader> = [], withCredentials: boolean = false, withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);

        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        const start = new Date().getTime();

        customHeaders = [new AviCustomHeader('Content-Disposition', 'multipart/form-data')];

        return this.http
            .post(intUrl, formData, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders, null), withCredentials: withCredentials })
            .toPromise<any>()
            .then(response => { this._logMS(start, intUrl); return AviApiService.handleJsonResponse(response); })
            .catch(this.handleError);
    }

    public async postFormData(url: string, formData: FormData, customHeaders: Array<AviCustomHeader> = [], withCredentials: boolean = false, withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);

        const start = new Date().getTime();

        customHeaders = [new AviCustomHeader('Content-Disposition', 'multipart/form-data')];

        return this.http
            .post(intUrl, formData, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders, null), withCredentials: withCredentials })
            .toPromise<any>()
            .then(response => { this._logMS(start, intUrl); return AviApiService.handleJsonResponse(response); })
            .catch(this.handleError);
    }

    public async postAsFormData(url: string, data: any, customHeaders: Array<AviCustomHeader> = [], withCredentials: boolean = false, withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);

        if (data instanceof IDTO)
            data = ObjectUtils.BaseModelToObject(data);

        const body = typeof data === 'string' ? data : this.commonService.stringifyWithNl(data);

        const formData: FormData = new FormData();
        formData.append('data', body);
        const start = new Date().getTime();

        customHeaders = [new AviCustomHeader('Content-Disposition', 'multipart/form-data')];

        return this.http
            .post(intUrl, formData, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders, null), withCredentials: withCredentials })
            .toPromise<any>()
            .then(response => { this._logMS(start, intUrl); return AviApiService.handleJsonResponse(response); })
            .catch(this.handleError);
    }

    public async postFileWithData(url: string, fileToUpload: File, data: any, customHeaders: Array<AviCustomHeader> = [], withCredentials: boolean = false, withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);

        if (data instanceof IDTO)
            data = ObjectUtils.BaseModelToObject(data);

        const body = typeof data === 'string' ? data : this.commonService.stringifyWithNl(data);

        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        formData.append('data', body);
        const start = new Date().getTime();

        customHeaders = [new AviCustomHeader('Content-Disposition', 'multipart/form-data')];

        return this.http
            .post(intUrl, formData, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders, null), withCredentials: withCredentials })
            .toPromise<any>()
            .then(response => { this._logMS(start, intUrl); return AviApiService.handleJsonResponse(response); })
            .catch(this.handleError);
    }

    public async put(url: string /* , id:string*/, data: any, customHeaders: Array<AviCustomHeader> = [], withCredentials: boolean = false, withAuthHeaders: boolean = true): Promise<any> {
        await this.ensureBaseUrl();
        const intUrl: string = this.buildApiUrl(url);
        // if (id != null) intUrl += (intUrl.endsWith('/') ? '' : '/') + id;

        if (data instanceof IDTO)
            data = ObjectUtils.BaseModelToObject(data);

        const body = typeof data === 'string' ? data : this.commonService.stringifyWithNl(data);
        // console.trace("ApiService.put", intUrl, body);
        const start = new Date().getTime();
        return this.http.put(intUrl, body, { headers: this.getHeaders(customHeaders, this.extendsApiUrl(intUrl), withAuthHeaders), withCredentials: withCredentials })
            .toPromise<any>()
            .then(response => { this._logMS(start, intUrl); return AviApiService.handleJsonResponse(response); })
            .catch(this.handleError);
    }

    public async putPost(url: string, dataObject: any): Promise<any> {
        if (dataObject && dataObject.id)
            return this.put(this.buildApiUrl(url) + '/' + dataObject.id, dataObject);
        else
            return this.post(this.buildApiUrl(url), dataObject);
    }

    public async getModelList<T>(cls: ClassType<T>, url: string, customHeaders: Array<AviCustomHeader> = [], params: HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;} = null): Promise<T[]> {
        const json = await this.get(url, customHeaders, false, true, params);
        return ObjectUtils.ObjectToBaseModel(cls, json);
    }

    public async getModel<T>(cls: ClassType<T>, url: string, customHeaders: Array<AviCustomHeader> = []): Promise<T> {
        const json = await this.get(url, customHeaders);
        return ObjectUtils.ObjectToBaseModel(cls, json);
    }

    public async getModelById<T>(cls: ClassType<T>, url: string, id: string, customHeaders: Array<AviCustomHeader> = []): Promise<T> {
        const json = await this.getOne(url, id, customHeaders);
        return ObjectUtils.ObjectToBaseModel(cls, json);
    }

    public async postModel<T>(cls: ClassType<T>, url: string, data: any, customHeaders: Array<AviCustomHeader> = []): Promise<T> {
        const json = await this.post(url, data, customHeaders);

        const res = ObjectUtils.ObjectToBaseModel(cls, json);
        if (res instanceof PagedResult)
            res.Results = ObjectUtils.ObjectToBaseModel(cls, res.Results);

        return res;
    }

    public async postPagedResult<T>(cls: ClassType<T>, url: string, data: any, customHeaders: Array<AviCustomHeader> = []): Promise<PagedResult<T>> {
        const json = await this.post(url, data, customHeaders);

        const res = ObjectUtils.ObjectToBaseModel(PagedResult, json);
        res.Results = ObjectUtils.ObjectToBaseModel(cls, res.Results);

        return res;
    }

    public async postModelList<T>(cls: ClassType<T>, url: string, data: any, customHeaders: Array<AviCustomHeader> = []): Promise<T[]> {
        const json = await this.post(url, data, customHeaders);
        return ObjectUtils.ObjectToBaseModel(cls, json);
    }

    public async FindModel<T>(cls: ClassType<T>, url: string, q: string = null, filter: string = null, init: string = null, select: string = null, sort: string = null, page: string = null, per_page: string = null): Promise<T> {
        const json = await this.Find(url, q, filter, init, select, sort, page, per_page);
        return ObjectUtils.ObjectToBaseModel(cls, json);
    }

    public static resolveReferences(json) {
        if (typeof json === 'string')
            json = JSON.parse(json);

        const byid = {}; // all objects by id
        const refs = []; // references to objects that could not be resolved
        json = (function recurse(obj, prop, parent) {
            if (typeof obj !== 'object' || !obj) // a primitive value
                return obj;
            if (Object.prototype.toString.call(obj) === '[object Array]') {
                for (let i = 0; i < obj.length; i++)
                    // check also if the array element is not a primitive value
                    if (typeof obj[i] !== 'object' || !obj[i]) // a primitive value
                        continue;
                    else if ('$ref' in obj[i])
                        obj[i] = recurse(obj[i], i, obj);
                    else
                        obj[i] = recurse(obj[i], prop, obj);
                return obj;
            }
            if ('$ref' in obj) { // a reference
                const ref = obj.$ref;
                if (ref in byid)
                    return byid[ref];
                // else we have to make it lazy:
                refs.push([parent, prop, ref]);
                return;
            } else if ('$id' in obj) {
                const id = obj.$id;
                delete obj.$id;
                if ('$values' in obj) // an array
                    obj = obj.$values.map(recurse);
                else // a plain object
                    // eslint-disable-next-line guard-for-in
                    for (const prop2 in obj)
                        obj[prop2] = recurse(obj[prop2], prop2, obj);
                byid[id] = obj;
            }
            return obj;
        })(json); // run it!

        for (let i = 0; i < refs.length; i++) { // resolve previously unknown references
            const ref = refs[i];
            ref[0][ref[1]] = byid[ref[2]];
            // Notice that this throws if you put in a reference at top-level
        }
        return json;
    }

    public static handleJsonResponse(obj: any): any {
        if (!obj)
            return null; // z.B. bei 204-Status
        // console.log("handleJsonResult", obj);
        // const ms = new Date().getTime();
        if (obj && obj.$values) {
            return AviApiService.resolveReferences(obj);
            /*
            let r = this.parseAndResolve(JSON.stringify(obj.$values));
            console.log("parseAndResolve $values durchgeführt in ms: ", (new Date().getTime() - ms));
            console.log("r",r);
            return r;// obj.$values;*/
        } else if (obj && obj.$id) {
            return AviApiService.resolveReferences(obj);
            /* let r = this.parseAndResolve(JSON.stringify(obj));
            console.log("parseAndResolve $id durchgeführt in ms: ", (new Date().getTime() - ms));
            //console.log("r",r);
            return r;*/
        }
        return obj;
    }

    public parseAndResolve(json) {
        const refMap = {};

        return JSON.parse(json, (key, value) => {
            if (key === '$id') {
                refMap[value] = this;
                // return undefined so that the property is deleted
                return void (0);
            }

            if (value && value.$ref) { return refMap[value.$ref]; }

            return value;
        });
    }

    private _logMS(startTS: number, url: string) {
        // const ms = new Date().getTime() - startTS;
        // if (!environment.production)
        //     console.debug(`${url} geladen in ${ms} ms`.trim());
    }

    public getHeaders(customHeaders: Array<AviCustomHeader> = [], includeApiHeaders: boolean, includeAuthHeaderIfPossible: boolean, contentType: string = 'application/json'): HttpHeaders {
        let rHeaders = new HttpHeaders().set('X-avi-h1', 'h1h');
        if (includeApiHeaders) {
            for (const item of this.GetHeadersAsArray(includeAuthHeaderIfPossible, contentType)) {
                if (item.value)
                    rHeaders = rHeaders.set(item.key, item.value);
            }
        }

        if (customHeaders == null || customHeaders === undefined)
            customHeaders = [];
        for (const item of customHeaders) {
            if (item.value)
                rHeaders = rHeaders.set(item.key, item.value);
        }
        return rHeaders;
    }

    public buildHeader(key: string, value: string): AviCustomHeader {
        return new AviCustomHeader(key, value);
    }

    // public GetHeadersAsArray(includeAuthHeaderIfPossible: boolean, contentType: string = 'application/json'): Array<AviCustomHeader> {
    //     return AviApiService.GetHeadersAsArray(includeAuthHeaderIfPossible, contentType);
    // }

    public buildApiUrl(url: string): string {
        if (url.startsWith('/')) url = url.substr(1);
        if (!url.startsWith(this.getBaseUrl()) && !url.startsWith('http'))
            url = this.getBaseUrl() + (this.getBaseUrl().endsWith('/') ? '' : '/') + url;
        return url;
    }

    private handleError = async (error: any): Promise<any> => {
        if (!this.commonService.IsProduction)
            console.warn('handleError', error);
        const statusCode = error.status === 0 ? 0 : (error.status || 500);
        const statusText = error.statusText || error.error || 'UNKNOWN';

        let errJson: any = null;
        let errorText = null;
        try {
            errJson = JSON.parse(error);
            errorText = AviApiService.handleJsonResponse(errJson);
            if (!this.commonService.IsProduction)
                console.warn('ERROR TEXT AS JSON: ', errorText);
        } catch (ex) {
            try {
                errorText = error.error;
                if (errorText instanceof Blob)
                    errorText = await errorText.text();
                if (!this.commonService.IsProduction)
                    console.warn('ERROR TEXT AS TEXT: ', errorText);
                try {
                    const errorDetails = typeof errorText === 'object' ? errorText : JSON.parse(errorText);
                    if (errorDetails.message) {
                        const err = new AviApiErrorObject(errorDetails.message, statusCode, statusText, error, errorDetails).setType(errorDetails.type);
                        if (!this.commonService.IsProduction)
                            console.warn('ApiService.handleError from API', err, error);
                        return Promise.reject(err);
                    }
                    // console.warn('ERROR TEXT AS JSON FROM TEXT', errorText);
                } catch (ex3) {
                    if (!this.commonService.IsProduction)
                        console.debug('Konnte nicht in JSON geparsed werden:', errorText);
                }
            } catch (ex2) {
                errorText = error;
            }
        }

        if (errorText)
            errorText = errorText.error || errorText.message || errorText;

        let errorDetails2 = null;


        // if (statusCode === 0 && statusText === 'UNKNOWN')
        //     errorText = 'UNKNOWN';
        if (statusCode === 401 /* && statusText === 'Unauthorized'*/) {
            errorText = 'UNAUTHORIZED';
            errorDetails2 = error.url;
        }
        if (statusCode === 403) {
            errorText = 'ACCESS_DENIED';
            errorDetails2 = error.url;
        }
        if (statusCode === 404) {
            errorText = 'NOT_FOUND';
            errorDetails2 = error.url;
        }
        if (statusCode === 0) {
            errorText = 'SERVER_NOT_REACHABLE';
        }
        if (statusCode === 500 && errJson && errJson.message)
            errorText = errJson.message;

        const err2 = new AviApiErrorObject(errorText, statusCode, statusText, error, errorDetails2);

        if (!this.commonService.IsProduction)
            console.warn('ApiService.handleError reject: ', err2);

        return Promise.reject(err2);
    }

    public get Token(): string {
        return AviApiService.Token;
    }

    public set Token(value: string) {
        AviApiService.Token = value;
    }

    public static get Token(): string {
        const value = localStorage.getItem('avix::user::token');
        return value ? value : null;
    }

    public static set Token(value: string) {
        localStorage.setItem('avix::user::token', value ? value : '');
    }

    public get TokenExirationUtc(): string {
        const value = localStorage.getItem('avix::user::tokenExpirationUTC');
        return value ? value : null;
    }

    public set TokenExirationUtc(value: string) {
        localStorage.setItem('avix::user::tokenExpirationUTC', value ? value : '');
    }

    async getListType(listname: string, detailId: string) {
        return await this.getModel(ListType, `model/representations/listtype/${listname}/${detailId}`);
    }
}
