import { AviApiService } from './../../services/api.service';
import { AbstractControl, NG_ASYNC_VALIDATORS, AsyncValidator, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Directive, Input, ChangeDetectorRef, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { first, map, mergeMap, tap } from 'rxjs/operators';

@Directive({
    selector: '[phoneNumber][formControlName],[phoneNumber][formControl],[phoneNumber][ngModel]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: forwardRef(() => AviPhoneNumberValidator),
            multi: true
        }
    ],
    host: { '[attr.phoneNumber]': 'phoneNumber ? phoneNumber : null' }
})
export class AviPhoneNumberValidator implements AsyncValidator, OnChanges {
    private _validator: AsyncValidatorFn = null;

    private _onChange?: () => void;

    @Input()
    phoneNumber!: boolean;

    constructor(private apiService: AviApiService, private cdr: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('phoneNumber' in changes) {
            this._createValidator();
            if (this._onChange) this._onChange();
        }
    }

    registerOnValidatorChange(fn: () => void): void {
        this._onChange = fn;
    }

    private _createValidator(): void {
        this._validator = this.phoneNumberValidator();
    }

    validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return !this.phoneNumber || this._validator == null ? of(null) : this._validator(control);
    }

    phoneNumberValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (!control.value)
                return of(null);

            return timer(300).pipe(
                mergeMap(_ => this.apiService.get(`partnerkommunikation/validphonenumber/${control.value}`)),
                map(res => {
                    if (res != true)
                        return ({ validatePhonenumber: { valid: false } });
                    else
                        return null;
                }),
                tap(() => setTimeout(() => this.cdr.detectChanges(), 0)),
                first()
            );
        };
    }
}
