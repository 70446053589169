import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviChangedAttr, AviCommonService, AviDokumentAblageListDetailConst, AviFormField, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocProperty } from '@avi-x/avi-dto/document_def/docproperty.model';

const nameof = (nameFunction: ((obj: DocProperty) => any)) => typed_nameof<DocProperty>(nameFunction);

export enum DocPropertyMode {
    Normal,
    Standalone
}

@Component({
    selector: 'avi-docproperty-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviDocPropertyFormComponent extends AviAbstractModelFormComponent<DocProperty> {  
    ClassName = 'CORE.COMMON.MODEL.CAFDOCPROPERTY';
    BaseUrl = 'documentdef/docproperty';
    ClassType = DocProperty;
	FormType = AviDocPropertyFormComponent;

    @Input('mode') Mode: DocPropertyMode = DocPropertyMode.Normal;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFDocProperty';
    }
	
    async onAttrChange(data: AviChangedAttr) {
        if (data.field === nameof(c => c.vonModelMeta_ID)) 
            this.formFieldService.setFieldButtonDisabled(this.Fields, nameof(c => c.AttributName), this.Model.vonModelMeta_ID == null);
        else if (data.field === nameof(c => c.DocPropType)) {
            this.setDocPropType();
            this.setAddressBlock();
        }
        else if (data.field === nameof(c => c.AttributName)) {
            this.setAddressBlock();
        }
    }

    public async afterInitForm(): Promise<void> {
        this.formFieldService.setFieldButtonDisabled(this.Fields, nameof(c => c.AttributName), this.Model.vonModelMeta_ID == null);

        this.setDocPropType();
        this.setDefaultProp();
        this.setAddressBlock();
    }

    public async afterCreateModel(bm: DocProperty) {
        if (this.Mode === DocPropertyMode.Normal)
            bm.vonPropertyDef_ID = this.ContextId;
        else if (this.Mode === DocPropertyMode.Standalone)
            bm.vonDocPropContainer_ID = this.ContextId;
    }
    
    getParams(): { [k: string]: any; } {
        return { 'Mode': this.Mode };
    }
    
    initParams(params: { [k: string]: any; }) {
        if (params && params.Mode)
            this.Mode = params.Mode;
    }

    private setDefaultProp() {
        const isDefaultProp = this.Model.defaultPropertyDef_ID != null;

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.defaultPropertyDef_ID), isDefaultProp && this.Mode === DocPropertyMode.Normal);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.vonPropertyDef_ID), !isDefaultProp && this.Mode === DocPropertyMode.Normal);
    }

    private setDocPropType() {
        const isAttr = this.Model.DocPropType.Id  === AviDokumentAblageListDetailConst.CAF_DOCPROP_TYP_ATTR;
        const isAbfrage = this.Model.DocPropType.Id === AviDokumentAblageListDetailConst.CAF_DOCPROP_TYP_SQLQUERY;

        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.PathToObject ), isAttr);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.AttributName ), isAttr);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SprachRelevant ), isAttr);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FormatString ), isAttr);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.FixedRegionCode ), isAttr);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.SqlText ), isAbfrage);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.TrennerCol ), isAbfrage);
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.TrennerRow ), isAbfrage);
    }
    
    private setAddressBlock() {
        const visible = this.Model.AttributName === 'Adressblock' && this.Model.DocPropType.Id === AviDokumentAblageListDetailConst.CAF_DOCPROP_TYP_ATTR;
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.vonAdressBlockDef_ID ), visible);
        if (!visible)
            this.Model.vonAdressBlockDef_ID = null;
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.PropertyName), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.PROPERTYNAME', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonModelMeta_ID), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.VONMODELMETA', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.DocPropType), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.DOCPROPTYPE', 'caf_docprop_typ', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.PathToObject), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.PATHTOOBJECT', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateTextWithButton(nameof(c => c.AttributName), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.ATTRIBUTNAME', false, '...', this.openMetaBrowser).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.FormatString), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.FORMATSTRING', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.FixedRegionCode), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.FIXEDREGIONCODE', false).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateTextarea(nameof(c => c.SqlText), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.SQLTEXT', false).setMDSizeFull());

        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.TrennerRow), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.TRENNERROW', false).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.TrennerCol), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.TRENNERCOL', false).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateCheckbox(nameof(c => c.SprachRelevant), '', 'CORE.COMMON.MODEL.CAFDOCPROPERTY.SPRACHRELEVANT', false).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateEmpty('filler1'));

        this.Fields.push(this.formFieldService.CreateDropdown(nameof(c => c.vonAdressBlockDef_ID), 'CORE.COMMON.MODEL.CAFDOCPROPERTY.VONADRESSBLOCKDEF', `model/representations/CAFAdressBlockDef${this.ReadOnly ? '?id=$id' : ''}`, false, false, null, (m) => (`${m.Representation}`), 'Id', true, this.ReadOnly).setMDSizeHalf());
    }
    
    openMetaBrowser = async (field: AviFormField) => {
        const res = await this.commonService.openMetaBrowser(this.Model.vonModelMeta_ID);
        if (res) {
            this.Model.PathToObject = res.Path;
            this.Model.AttributName = res.Attribute;
            this.cdr.markForCheck();
        }
    }
}
