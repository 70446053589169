import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { AviCommentViewComponent } from './components/comment-view/comment-view.component';


import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { AviCommentListComponent } from './components/comment-list/comment-list.component';
import { AviCoreModule } from '@avi-x/avi-core';

import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        AviCoreModule,

        InputTextModule,
        InputTextareaModule,
        ButtonModule,
        CheckboxModule,
        MultiSelectModule,
        TranslateModule

    ],
    exports: [
        AviCommentViewComponent,
        AviCommentListComponent
    ],
    declarations: [
        AviCommentViewComponent,
        AviCommentListComponent
    ]
})
export class AviCommentModule { }
