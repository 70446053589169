import { ChangePasswordDto } from '@avi-x/avi-dto/system/changepassworddto.model';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviFormField, AviBaseFormComponent, AviFormFieldService, typed_nameof, AviCommonService } from '@avi-x/avi-core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

const nameof = (nameFunction: ((obj: ChangePasswordDto) => any)) => typed_nameof<ChangePasswordDto>(nameFunction);

@Component({
    selector: 'avi-changepassword-form',
    template: `
    <avi-core-base-form #form
        [readonly]="false"
        [card]="false"
        [fields]="Fields"
        [form-title]="FormTitle"
        [contents-padding]="false"
        [Model]="Model"
        [loading]="Loading"
        (onSave)="Save()"
        [action-buttons]="actionButtons">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviChangePasswordFormComponent implements OnInit {
    private _className = 'Password ändern';

    @ViewChild('form', { static: false })
    Form: AviBaseFormComponent = null;
 
    @Input('form-title')
    public FormTitle: string = this._className + ' bearbeiten';

    @Input('action-buttons')
    actionButtons: any[] = [];

    public Loading: boolean = false;
    public Model: ChangePasswordDto = null;
    public Fields: AviFormField[] = [];

    @Output('onSave')
    public onSave: EventEmitter<any> = new EventEmitter();

    abbrechenButton: any = [{ title: 'CORE.COMMON.ABBRECHEN_BUTTON', class: 'p-button-secondary', icon: 'pi pi-times', action: () => { this.tryCloseDialog(null); } }];

    constructor(
        public config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
        private formFieldService: AviFormFieldService,
        private commonService: AviCommonService,
        private cdr: ChangeDetectorRef) {

    }
    
    initParams(params: { [k: string]: any; }) {
        this.formFieldService.setFieldVisible(this.Fields, nameof(c => c.OldPassword), params?.oldPasswordVisible ?? false);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.OldPassword), params?.oldPasswordRequired ?? false);
        this.formFieldService.setFieldRequired(this.Fields, nameof(c => c.NewPassword), params?.newPasswordRequired ?? true);
    }    

    ngOnInit() {
        if (this.config && this.config.data) {
            this.Model = new ChangePasswordDto();
            this.initFields();
            this.initParams(this.config.data.Params);

            this.actionButtons = [ ...this.abbrechenButton, ...this.actionButtons ];
        }
    }

    public Save() {
        if (this.Model && this.ref) 
            this.ref.close(this.Model);
    }

    tryCloseDialog(model: ChangePasswordDto) {
        if (this.ref) 
            this.ref.close(model);
    }

    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.OldPassword), 'Altes Passwort', false).setMDSizeFull().setTextTypePassword());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.NewPassword), 'Neues Passwort', false).setMDSizeFull().setTextTypePassword());
    }
}
