<div class=container>
    <div class="searcherContent" >
        <ng-content select="[leftSearcher]">
        
        </ng-content>
    </div>

    <div class="buttonColumn" style="align-self: center;">
        <button pButton type="button" class="p-button p-button-outlined p-button-primary" [icon]="'pi pi-arrow-right'" (click)="buttonAddClicked()"></button>
        <button pButton type="button" class="p-button p-button-outlined p-button-primary" [icon]="'pi pi-arrow-left'" (click)="buttonRemoveClicked()"></button>
    </div>

    <div class="searcherContent" >
        <ng-content select="[rightSearcher]">
        
        </ng-content>
    </div>
</div>