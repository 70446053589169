import { Businesscase } from "./businesscase.model";

export class BusinesscaseActionDto {
    public Bemerkung: string = null;
    public BemerkungIntern: boolean = false;
    public AssignedTo_ID: string;
    public Applicant_ID: string;

    public Businesscase: Businesscase;
    public CanChangeAssignee: boolean = false;
    public CanChangeApplicant: boolean = false;
    public BemerkungZwingend: boolean = false;
    public Readonly: boolean = false;

    public FristEinblenden: boolean = false;
    public FristBearbeitbar: boolean = false;
    public Frist: Date = null;
    public KulanzFrist: number = null;
    public BemerkungInternEinblenden: boolean = true;
}
