import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AviAbstractModelFormComponent, AviApiService, AviCommonService, AviFormFieldService, AviSessionControllerService, typed_nameof } from '@avi-x/avi-core';
import { NgxPermissionsService } from 'ngx-permissions';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { AdressBlockDef } from '@avi-x/avi-dto/partner/adressblockdef.model';

const nameof = (nameFunction: ((obj: AdressBlockDef) => any)) => typed_nameof<AdressBlockDef>(nameFunction);

@Component({
    selector: 'avi-adressblockdef-form',
    template: `
    <avi-core-base-form #form
        [readonly]="ReadOnly"
        [card]="Card"
		[contents-padding]="ContentsPadding"
        [fields]="Fields"
        [form-title]="FormTitle"
        [(Model)]="Model"
        [loading]="Loading"
        (onSave)="saveModel($event)"
        (onAttrChange)="onAttrChange($event)"
		[taskmenu-items]="TaskMenuItems"
        [taskmenu-position]="TaskMenuPosition"
        [action-buttons]="actionButtons"
		[action-buttons-readonly]="actionButtonsReadonly">

    </avi-core-base-form>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AviAdressBlockDefFormComponent extends AviAbstractModelFormComponent<AdressBlockDef> {  
    ClassName = 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF';
    BaseUrl = 'adressblockdef/def';
    ClassType = AdressBlockDef;
	FormType = AviAdressBlockDefFormComponent;
	
    constructor(public commonService: AviCommonService,
        public apiService: AviApiService,
		public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public formFieldService: AviFormFieldService,
		public permissionsService: NgxPermissionsService,
        public sessionController: AviSessionControllerService,
		public cdr: ChangeDetectorRef) {

        super(commonService, apiService, ref, config, formFieldService, permissionsService, sessionController, cdr);

		this.CRUDBaseRight = 'CAFAdressBlockDef';
    }
	
    initFields() {
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez1), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.KURZBEZ1', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung1), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.BEZEICHNUNG1', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez2), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.KURZBEZ2', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung2), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.BEZEICHNUNG2', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez3), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.KURZBEZ3', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung3), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.BEZEICHNUNG3', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez4), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.KURZBEZ4', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung4), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.BEZEICHNUNG4', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez5), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.KURZBEZ5', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung5), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.BEZEICHNUNG5', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez6), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.KURZBEZ6', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung6), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.BEZEICHNUNG6', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.KurzBez7), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.KURZBEZ7', true).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateText(nameof(c => c.Bezeichnung7), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.BEZEICHNUNG7', true).setMDSizeHalf());
        
        this.Fields.push(this.formFieldService.CreateAutocomplete(nameof(c => c.vonModelMeta_ID), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.VONMODELMETA', false, 'Representation', 'Id', 1, `model/representations/ModelMeta`).setMDSizeHalf());
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.Zustellanweisung), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.ZUSTELLANWEISUNG', 'caf_zustellanweisung', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.VerwendungInFaktura), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.VERWENDUNGINFAKTURA', 'janein', true, true, this.ReadOnly).setMDSizeHalf());        
        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.VerwendungInDokumente), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.VERWENDUNGINDOKUMENTE', 'janein', true, true, this.ReadOnly).setMDSizeHalf());

        this.Fields.push(this.formFieldService.CreateListType(nameof(c => c.VerwendungInZwischenablage), 'CORE.COMMON.MODEL.CAFADRESSBLOCKDEF.VERWENDUNGINZWISCHENABLAGE', 'janein', true, true, this.ReadOnly).setMDSizeHalf());        
    }
}
