import { Subject } from 'rxjs/internal/Subject';
import { ChangeDetectorRef, Injectable } from "@angular/core";

export interface ISessionData {
    Session: string;
    Caption: string;
    ContextId?: string;
    Modul?: string;
    SelectedModelId?: string;
    OpenExistingSession?: boolean;
    Id?: string;
    CurrentModul?: string;
    Fixed?: boolean;
    Data?: any;
}

export interface IRegisteredSession {
    Session: string;
    Caption: string;
    RequireContext?: boolean;
}

@Injectable()
export class AviSessionControllerService {
    activeSessionIndex = -1;
    sessions: ISessionData[] = [];

    sessionDict: { [sessionKey: string] : IRegisteredSession; } = {};

    private sessionChanged = new Subject<string>();
    private sessionClosed = new Subject<string>();
  
    // Observable string streams
    sessionChanged$ = this.sessionChanged.asObservable();
    sessionClosed$ = this.sessionClosed.asObservable();

    constructor() {
    }

    public selectedSessionChanged(index: number) {
        if (this.activeSessionIndex >= 0)
            this.onSessionChange(this.getCurrentSessionId());
    }

    public onSessionChange(sessionId: string) {
        this.sessionChanged.next(sessionId);
    }

    public onSessionClosed(sessionId: string) {
        this.sessionClosed.next(sessionId);
    }

    public setCurrentSessionTitle(heading: string) {
        if (this.activeSessionIndex >= 0 && this.activeSessionIndex < this.sessions.length)
            this.sessions[this.activeSessionIndex].Caption = heading;
    }

    public setCurrentSessionModul(modul: string) {
        if (this.activeSessionIndex >= 0 && this.activeSessionIndex < this.sessions.length)
            this.sessions[this.activeSessionIndex].CurrentModul = modul;
    }

    public registerSession(sessionData: IRegisteredSession) {
        this.sessionDict[sessionData.Session] = sessionData;
    }

    public getRegisteredSessions(): IRegisteredSession[] {
        return Object.keys(this.sessionDict).map(k => this.sessionDict[k]);
    }

    public openSession(sessionKey: string, contextId: string = null, modul: string = null, selectedModelId: string = null, fixed: boolean = false, data: any = null) {
        const registedSession = this.sessionDict[sessionKey];

        if (registedSession == null)
            throw new Error(`Es wurde keine Session ${sessionKey} registriert`);

        if (contextId == null && registedSession.RequireContext === true)
            throw new Error(`Es wurde kein Context definiert für Session ${registedSession.Session}`);

        this._openSession({ Session: registedSession.Session, Caption: registedSession.Caption, Modul: modul, ContextId: contextId, SelectedModelId: selectedModelId, Fixed: fixed, Data: data });
    }

    private _openSession(sessionData: ISessionData) {
        let navFlag = false;
        if (sessionData.OpenExistingSession === true) {
            for (var i = 0; i < this.sessions.length; i++) {
                if (this.sessions[i].Session === sessionData.Session) {
                    navFlag = true;
                    this.activeSessionIndex = i;
                    break;
                } else {
                    navFlag = false;
                }
            }
        }

        if (navFlag === false) {
            this.sessions.push({...sessionData, Id: this.generateUUID()});
            this.activeSessionIndex = this.sessions.length-1;
        }
    }

    private generateUUID(): string { // Public Domain/MIT
        let d = new Date().getTime(); // Timestamp
        let d2 = (performance && performance.now && (performance.now() * 1000)) || 0;// Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16; // random number between 0 and 16
            if (d > 0) { // Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else { // Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    public getNumSessions(): number {
        return this.sessions.length;
    }

    public getCurrentSessionId(): string {
        if (this.activeSessionIndex >= 0 && this.activeSessionIndex < this.sessions.length) 
            return this.sessions[this.activeSessionIndex].Id;
        
        return null;
    }

    public getCurrentSession(): ISessionData {
        if (this.activeSessionIndex >= 0 && this.activeSessionIndex < this.sessions.length) 
            return this.sessions[this.activeSessionIndex];
        
        return null;
    }

    public closeCurrentSession() {
        if (this.activeSessionIndex >= 0)
            this.closeSession(this.activeSessionIndex);
    }

    public closeSession(index: number) {
        this.onSessionClosed(this.sessions[index].Id);

        this.sessions.splice(index, 1);

        if (this.activeSessionIndex > this.sessions.length - 1)
            this.activeSessionIndex = this.sessions.length - 1;

    }

    public closeOtherSessions(index: number) {
        const currentSession = this.sessions[index];

        this.sessions.filter(w => w.Id !== currentSession.Id).forEach(w => this.onSessionClosed(w.Id));

        this.sessions = [currentSession];
        this.activeSessionIndex = 0;
    }

    public closeAllSessions() {
        this.sessions.forEach(w => this.onSessionClosed(w.Id));

        this.activeSessionIndex = -1;
        this.sessions = [];
    }
}
