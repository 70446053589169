<avi-core-base-form
    #form
    [readonly]="ReadOnly"
    [card]="Card"
    [contents-padding]="ContentsPadding"
    [inline-modus]="InlineModus"
    [fields]="Fields"
    [form-title]="FormTitle"
    [label-alignment]="LabelAlignment"
    [label-width]="'120px'"
    [show-buttons]="ShowButtons"
    [auto-focus]="AutoFocus"
    [(Model)]="Model"
    [loading]="Loading"
    (onDelete)="deleteModel($event)"
    (onSave)="saveModel($event)"
    (onAttrBlur)="onAttrBlur($event)"
    (onAttrChange)="onAttrChange($event)"
    [action-buttons]="actionButtons"
>
    <div formFieldTemplate1>
        <label 
            [ngClass]="{ 'label-nonmd': true, 'col-fixed': LabelAlignment === 'left' }"
            [ngStyle]="{ 'width': LabelAlignment === 'left' ? 'LabelWidth' : 'auto'}"
        >
            Status
        </label>

        <div class="pt-4p form-field">
            <div><span *ngIf="Model" [class]="'status-badge status-' + commonService.str2CSSName(Model.StatusDef?.Bezeichnung1)">{{ Model.StatusDef?.Bezeichnung1 }}</span></div>
            <!-- <avi-crm-activity-status *ngIf="Model" [status]="Model.StatusDef"></avi-crm-activity-status> -->
        </div>
    </div>
</avi-core-base-form>
