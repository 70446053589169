import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AviCommonService, AviApiService } from '@avi-x/avi-core';
import { MatTabNav } from '@angular/material/tabs';

@Component({
    selector: 'vwbp-intern-schwaerzen-index',
    templateUrl: './schwaerzen-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPInternSchwaerzenIndexComponent implements OnInit {
    navLinks = [
        { path: './antrag', label: 'Antrag' },
        { path: './bearbeitet', label: 'Bearbeitet' },
        { path: './rechtsmittelfrist', label: 'Rechtsmittelfrist abgelaufen' },
        { path: './bewilligt', label: 'Bewilligt' },
        { path: './frist-lauft-ab', label: 'Fristablauf 3 Mt.' },
        { path: './rechtstraeger-informiert', label: 'RT Informiert' },
        { path: './neue-version', label: 'Neue Version' },
        { path: './abgelehnt', label: 'Abgewiesen' },
        { path: './frist-abgelaufen', label: 'Frist abgelaufen' }
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
    ) {
    }

    ngOnInit() {
    }
}
