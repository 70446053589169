import { Expose, Type } from 'class-transformer';

import { ModelMeta } from '../system/modelmeta.model';
import { BaseModel } from '../shared/basemodel.model';
import { ListType } from '../shared/listtype.model';

export class AdressBlockDef extends BaseModel {  
    @Expose()
    public get KurzBez1(): string { return this.getProperty('KurzBez1'); }
    public set KurzBez1(value: string) { this.setProperty('KurzBez1', value); }
    
    @Expose()
    public get Bezeichnung1(): string { return this.getProperty('Bezeichnung1'); }
    public set Bezeichnung1(value: string) { this.setProperty('Bezeichnung1', value); }
    
    @Expose()
    public get KurzBez2(): string { return this.getProperty('KurzBez2'); }
    public set KurzBez2(value: string) { this.setProperty('KurzBez2', value); }
    
    @Expose()
    public get Bezeichnung2(): string { return this.getProperty('Bezeichnung2'); }
    public set Bezeichnung2(value: string) { this.setProperty('Bezeichnung2', value); }
    
    @Expose()
    public get KurzBez3(): string { return this.getProperty('KurzBez3'); }
    public set KurzBez3(value: string) { this.setProperty('KurzBez3', value); }
    
    @Expose()
    public get Bezeichnung3(): string { return this.getProperty('Bezeichnung3'); }
    public set Bezeichnung3(value: string) { this.setProperty('Bezeichnung3', value); }
    
    @Expose()
    public get KurzBez4(): string { return this.getProperty('KurzBez4'); }
    public set KurzBez4(value: string) { this.setProperty('KurzBez4', value); }
    
    @Expose()
    public get Bezeichnung4(): string { return this.getProperty('Bezeichnung4'); }
    public set Bezeichnung4(value: string) { this.setProperty('Bezeichnung4', value); }
    
    @Expose()
    public get KurzBez5(): string { return this.getProperty('KurzBez5'); }
    public set KurzBez5(value: string) { this.setProperty('KurzBez5', value); }
    
    @Expose()
    public get Bezeichnung5(): string { return this.getProperty('Bezeichnung5'); }
    public set Bezeichnung5(value: string) { this.setProperty('Bezeichnung5', value); }
    
    @Expose()
    public get KurzBez6(): string { return this.getProperty('KurzBez6'); }
    public set KurzBez6(value: string) { this.setProperty('KurzBez6', value); }
    
    @Expose()
    public get Bezeichnung6(): string { return this.getProperty('Bezeichnung6'); }
    public set Bezeichnung6(value: string) { this.setProperty('Bezeichnung6', value); }
    
    @Expose()
    public get KurzBez7(): string { return this.getProperty('KurzBez7'); }
    public set KurzBez7(value: string) { this.setProperty('KurzBez7', value); }
    
    @Expose()
    public get Bezeichnung7(): string { return this.getProperty('Bezeichnung7'); }
    public set Bezeichnung7(value: string) { this.setProperty('Bezeichnung7', value); }
    
    @Expose()
    public get vonModelMeta_ID(): string { return this.getModelId('vonModelMeta_ID'); }
    public set vonModelMeta_ID(value: string) { this.setModelId('vonModelMeta_ID', value); }
     
    @Expose()
    @Type(() => ListType)
    public get VerwendungInFaktura(): ListType { return this.getProperty('VerwendungInFaktura'); }
    public set VerwendungInFaktura(value: ListType) { this.setProperty('VerwendungInFaktura', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerwendungInDokumente(): ListType { return this.getProperty('VerwendungInDokumente'); }
    public set VerwendungInDokumente(value: ListType) { this.setProperty('VerwendungInDokumente', value); }
    
    @Expose()
    @Type(() => ListType)
    public get VerwendungInZwischenablage(): ListType { return this.getProperty('VerwendungInZwischenablage'); }
    public set VerwendungInZwischenablage(value: ListType) { this.setProperty('VerwendungInZwischenablage', value); }
    
    @Expose()
    @Type(() => ListType)
    public get Zustellanweisung(): ListType { return this.getProperty('Zustellanweisung'); }
    public set Zustellanweisung(value: ListType) { this.setProperty('Zustellanweisung', value); }
    
}
