import { Component, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AviCommonService, AviApiService } from '@avi-x/avi-core';
import { MatTabNav } from '@angular/material/tabs';

@Component({
    selector: 'vwbp-intern-kontrollwesen-index',
    templateUrl: './kontrollwesen-index.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VwbPInternKontrollwesenIndexComponent implements OnInit {

    navLinks = [
        { path: './allerechtstraeger', label: 'Alle Rechtsträger' },
        { path: './pendenzen', label: 'Nicht geprüfte RT' },
        { path: './bemerkungen', label: 'Offene Bemerkungen' }
    ];

    @ViewChild('tabNavbar', { static: false })
    tabNavbar: MatTabNav;

    constructor(public apiService: AviApiService,
        public router: Router,
        public commonService: AviCommonService,
    ) {
    }

    ngOnInit() {
    }
}
