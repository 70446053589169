import { Component, ElementRef, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
    selector: 'avi-tabPanel',  
    template: `
        <ng-template>
            <ng-content></ng-content>
        <ng-template>        
    `,
})
export class AviTabPanel {
    @ViewChild(TemplateRef) public contentTemplate: TemplateRef<any>;

    Title: string = null;

    @Input() get title(): string {
      return this.Title;
    }
    set title(value: string) {
        this.Title = value;
        this.elementRef.nativeElement.title = ''; // prevent tooltip
    }

    @Input('icon') icon: string;
    @Input() active = false;

    @Input('id')
    public Id: string = null;

    @Input() badge: string = null;
    @Input() badgeSeverity: string = 'danger';

    constructor(private elementRef: ElementRef) {
    }
}
