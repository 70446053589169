import { Injectable } from '@angular/core';
import { AviApiService, AviAuthService } from '@avi-x/avi-core';
import { VwbPCommonQueryDto } from '@avi-x/vwbp-public';
import { VwbPUserDto } from '../dto/vwbpuser.model';


@Injectable({ providedIn: 'root' })
export class VwbpInternCommonService {

    constructor(private authService: AviAuthService, private apiService: AviApiService) {
    }

    public async searchUser(query: string): Promise<VwbPUserDto[]> {
        const q: VwbPCommonQueryDto = { Query: query };
        return this.apiService.post('/v1/intern/vwbp/common/user/search', q);
    }

}
