<div class="card-contents" *ngIf="RechtstraegerId && (CommentIds.length > 0 || !Readonly)">
    <p-panel
        header=""
        [toggleable]="Toggleable"
        [collapsed]="!Expanded || ForceCollapsed"
        expandIcon="pi pi-chevron-down"
        collapseIcon="pi pi-chevron-up"
        toggler="header"
    >
        <p-header>
            <div class="p-panel-title">
                Bemerkungen

                <p-badge
                    *ngIf="NumOpenComments > 0"
                    styleClass="comment valign-center"
                    [value]="NumOpenComments?.toString()"
                    [pTooltip]="NumOpenComments + (NumOpenComments === 1 ? ' offene Bemerkung' : ' offene Bemerkungen')"
                    >
                </p-badge>

                <!-- <span *ngIf="NumClosedComments > 0" class="closed-comment-badge"> {{ NumClosedComments }}</span> -->
            </div>
        </p-header>

        <div class="ui-g">
            <div class="ui-g-12">
                <div class="ui-g">
                    <div class="ui-g-12 ui-md-12 pl-0 pt-0 pb-0">
                        <div *ngIf="!Readonly && NewCommentId">
                            <div class="new-comment">Neue Bemerkung</div>

                            <vwbp-intern-rechtstraeger-comment-view
                                [comment-id]="NewCommentId"
                                [rechtstraeger-id]="RechtstraegerId"
                                [version-id]="VersionId"
                                [version-number]="VersionNumber"
                                [version-typ]="VersionTyp"
                                (onCancelled)="OnCreateCommentCancelled()"
                                (onSaved)="OnCreateCommentSaved()"
                            >
                            </vwbp-intern-rechtstraeger-comment-view>
                        </div>

                        <div>
                            <button
                                *ngIf="!Readonly && !NewCommentId && !EditCommentId"
                                pButton
                                icon="pi pi-comment"
                                type="button"
                                style="width: auto;"
                                class="mr-2"
                                aviThrottledClick
                                (throttledClick)="CreateComment()"
                                label="Bemerkung erfassen"
                                title="Bemerkung erfassen"
                            ></button>
                        </div>
                    </div>
                </div>

                <div *ngIf="CommentIds.length > 0">
                    <div [ngClass]="{ 'separator-nomargin': true, 'pt-3': !Readonly }"></div>
                </div>

                <div *ngFor="let commentId of CommentIds">
                    <vwbp-intern-rechtstraeger-comment-view
                        [readonly]="Readonly"
                        [comment-id]="commentId"
                        [rechtstraeger-id]="RechtstraegerId"
                        [version-id]="VersionId"
                        [version-typ]="VersionTyp"
                        [version-number]="VersionNumber"
                        (onDeleted)="OnDeleteComment(commentId)"
                        (onEdit)="OnEditComment(commentId)"
                        (onCancelled)="OnEditCommentCancelled(commentId)"
                        (onSaved)="OnEditCommentSaved(commentId)"
                    >
                    </vwbp-intern-rechtstraeger-comment-view>

                    <div class="separator-nomargin"></div>
                </div>
            </div>
        </div>
    </p-panel>
</div>
