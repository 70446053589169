import { AviListDetailConst, AviApiService } from '@avi-x/avi-core';
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { VwbPCommonService } from '../../../common/vwbp-common.service';
import { VwbPListDetailConst } from '../../../constants/constants';
import { VwbPRechtstraeger } from '../../../dto/vwbprechtstraeger.model';
import { VwbPRechtstraegerDetail } from '../../../dto/vwbprechtstraegerdetail.model';
import { VwbPVersion } from '../../../dto/vwbpversion.model';


@Component({
    selector: 'vwbp-public-rechtstraeger-miniview',
    templateUrl: './rechtstraeger-miniview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VwbPPublicRechtstraegerMiniViewComponent {
    VwbPListDetailConst = VwbPListDetailConst;

    @Input('SelectedRT')
    SelectedRT: VwbPRechtstraeger = null;

    @Input('SelectedDetail')
    SelectedDetail: VwbPRechtstraegerDetail = null;

    @Input('SelectedVersion')
    SelectedVersion: VwbPVersion = null;

    @Output()
    public onToggleValidierungAusnahme: EventEmitter<string> = new EventEmitter();

    constructor(public vwbpCommonService: VwbPCommonService, public apiService: AviApiService) {
    }

    isInBearbeitung() {
        return this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_INBEARBEITUNG || this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_MUTIEREN;
    }

    IsValidierungAusnahme() {
        return this.SelectedRT.ValidierungAusnahme?.Id === AviListDetailConst.JA;
    }

    IsMahnprozess() {
        return this.SelectedRT.MahnwesenStatus.Id !== VwbPListDetailConst.VWBP_MAHNWESENSTATUS_OFFEN;
    }

    IsMahnprozessAbgeschlossen() {
        return this.SelectedRT.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_NICHT_RELEVANT || this.SelectedRT.MahnwesenStatus.Id === VwbPListDetailConst.VWBP_MAHNWESENSTATUS_ABGESCHLOSSEN;
    }

    GetErfassungsstatus() {
        if (!this.vwbpCommonService.currentUserIsAjuUser() && this.SelectedVersion?.Erfassungsstatus.Id === VwbPListDetailConst.VWBP_ERFASSUNGSSTATUS_AJU_FREIGEGEBEN)
            return 'Erfassung abgeschlossen';

        return this.SelectedVersion?.Erfassungsstatus.Bezeichnung;
    }
}
