<avi-core-page title="Profil">
    <p>
        Hallo <strong>{{CurrentUser.Username}}</strong> aka <strong>{{CurrentUser.FullName}}</strong> aka <strong>{{CurrentUser.ShortName}}</strong>
    </p>

    <p-panel *ngIf="totpImageUrl"
        header="TOTP Provisioning Image">
        <img class="shadow-box ui-shadow-4"
            [src]=totpImageUrl>
    </p-panel>


    <avi-core-dbg [m]="CurrentUser.hasRole('CAFAnwesenheit löschen')"
        t="CurrentUser.hasRole('CAFAnwesenheit löschen')"></avi-core-dbg>
    <avi-core-dbg [m]="authService.currentUserHasRole('CAFAnwesenheit löschen')"
        t="authService.currentUserHasRole('CAFAnwesenheit löschen')"></avi-core-dbg>
    <avi-core-dbg [m]="CurrentUser.hasRole('CAFAnwesenheit löschen2', true)"
        t="CurrentUser.hasRole('CAFAnwesenheit löschen2', ignoreAdmin=true)"></avi-core-dbg>
    <avi-core-dbg [m]="CurrentUser.hasRole('CAFAnwesenheit löschen2', false)"
        t="CurrentUser.hasRole('CAFAnwesenheit löschen2', ignoreAdmin=false)"></avi-core-dbg>
    <avi-core-dbg [m]="authService.currentUserHasRole('CAFAnwesenheit löschen2')"
        t="authService.currentUserHasRole('CAFAnwesenheit löschen2')"></avi-core-dbg>


    <avi-core-dbg [m]="authService.CurrentUser"
        t="authService.CurrentUser"></avi-core-dbg>

    <avi-core-dbg [m]="authService.GetCurrentUserFromLocalStorage()"
        t="authService.GetCurrentUserFromLocalStorage()"></avi-core-dbg>

</avi-core-page>
