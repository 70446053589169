<div class="vwb-public-container">
    <avi-core-page
        title=""
        [card]="false"
        [no-box-shadow]="true"
        [collapsible]="false"
        [expanded]="true"
        [contents-padding]="true"
    >
        <button
            pButton
            type="button"
            icon="pi pi-chevron-left"
            aria-label="Go back"
            class="p-button-secondary p-button-text p-button-rounded mt-2 back-button"
            aviThrottledClick
            (throttledClick)="NavigateBack()"
        ></button>

        <!-- VERSION SELEKTOR -->
        <div class="version-dropdown mb-2">
            <p-dropdown
                *ngIf="Model"
                [showClear]="false"
                [readonly]="false"
                [options]="Versions"
                [required]="true"
                [autofocus]="true"
                [itemSize]="30"
                [ngModel]="SelectedVersion.Id"
                (ngModelChange)="SetSelectedVersion($event)"
            ></p-dropdown>
        </div>

        <p-messages styleClass="card-contents" [(value)]="msgs" [enableService]="false" [escape]="false" [closable]="false" showTransitionOptions="0ms" hideTransitionOptions="0ms"></p-messages>
        
        <div class="mr-4">
            <div class="grid m-0 justify-content-end">
                    <button
                        *ngIf="canRtAbgeben()"
                        pButton
                        type="button"
                        [pTooltip]="'VWBP.PUBLIC.RT_VERANTWORTLICHKEIT_ABGEBEN_BUTTON' | translate"
                        icon="pi pi-user-minus"
                        class="p-button-secondary p-button-text p-button-rounded"
                        aviThrottledClick
                        (throttledClick)="startRechtstraegerAbgeben()"
                    ></button>

                    <button
                        pButton
                        *ngIf="canDeleteRechtstraeger()"
                        type="button"
                        [pTooltip]="'VWBP.PUBLIC.RT_LOESCHEN_BUTTON' | translate"
                        icon="pi pi-trash"
                        class="p-button-secondary p-button-text p-button-rounded"
                        aviThrottledClick
                        (throttledClick)="startDeleteRechtstraeger()"
                    ></button>

                    <span *ngIf="ExportItems.length > 0">
                        <button
                            type="button"
                            pButton
                            [pTooltip]="'VWBP.COMMON.RT_AUSZUG_ERSTELLEN_BUTTON' | translate"
                            icon="pi pi-file-pdf"
                            class="p-button-secondary p-button-text p-button-rounded"
                            aviThrottledClick
                            (throttledClick)="menu.toggle($event)"
                        ></button>
                        <p-menu
                            #menu
                            appendTo="body"
                            [popup]="true"
                            [model]="ExportItems"
                            [style]="{ width: 'auto' }"
                        ></p-menu>
                    </span>
            </div>
        </div>

        <!-- RECHTSTRÄGER DETAILS -->
        <div class="card-contents" *ngIf="SelectedVersion && SelectedDetail">
            <p-panel
                [toggleable]="true"
                [collapsed]="true"
                expandIcon="pi pi-chevron-down"
                collapseIcon="pi pi-chevron-up"
                toggler="header"
            >
                <p-header class="rtpanel">
                    <span class="p-panel-title rt-view-title">{{ RTName }}</span>

                    <vwbp-public-rechtstraeger-miniview
                        [SelectedRT]="Model"
                        [SelectedDetail]="SelectedDetail"
                        [SelectedVersion]="SelectedVersion"
                    >
                    </vwbp-public-rechtstraeger-miniview>
                </p-header>

                <vwbp-public-rechtstraegerdetail-form
                    #showRechtstraegerForm
                    [card]="false"
                    [readonly]="true"
                    [model-id]="SelectedDetail.Id"
                    [contents-padding]="false"
                >
                </vwbp-public-rechtstraegerdetail-form>

                <div class="pb-2 pt-2">
                    <div class="grid justify-content-end m-0">

                            <button
                                *ngIf="canEditRechtstraeger()"
                                pButton
                                type="button"
                                [label]="'VWBP.PUBLIC.RT_BEARBEITEN_BUTTON' | translate"
                                icon="pi pi-pencil"
                                aviThrottledClick
                                (throttledClick)="editRechtstraeger()"
                                class="p-button-primary w-100p-m"
                            ></button>

                    </div>
                </div>
            </p-panel>
        </div>

        <!--FORMULARE -->
        <avi-core-view-panel
            [header]="
                (isVwEGVersion() ? 'VWBP.PUBLIC.WE_ERFASSTE_PERSONEN' : 'VWBP.PUBLIC.WB_ERFASSTE_PERSONEN') | translate
            "
        >
            <div *ngIf="isInBearbeitung()" class="mt-2">
                <div class="pb-2">
                    {{ 'VWBP.PUBLIC.WB_ERFASSEN_HINWEIS' | translate }}
                </div>
                <div class="pb-2">
                    {{ 'VWBP.PUBLIC.WB_ERFASSEN_HINWEIS_MULTIPLE_WBS' | translate }}
                </div>
                <div class="pb-2 color-red">
                    {{ 'VWBP.PUBLIC.WB_ERFASSEN_HINWEIS_FALSCHE_INFO' | translate }}
                </div>
            </div>

            <vwbp-public-formular-liste
                #formularListe
                [hide-when-noresults]="true"
                [show-schwaerzen]="SchwaerzungVorhanden"
                [can-edit]="isInBearbeitung()"
                [formular-daten]="SelectedVersion.alleFormularDaten"
                [context]="Model.CurrentVersionId"
                (formular-changed)="refreshFormulare()"
            >
            </vwbp-public-formular-liste>

            <div *ngIf="getFormularCount() === 0" class="pb-3">
                <p-message
                    severity="error"
                    [text]="'VWBP.PUBLIC.WB_KEINE_ERFASST' | translate"
                    class="w-100p"
                ></p-message>
            </div>

            <div *ngIf="isInBearbeitung()" class="ui-g">
                <div class="pb-2">
                    {{ 'VWBP.PUBLIC.WB_ERFASSEN' | translate }}
                    <!-- <button
                    pButton
                    type="button"
                    icon="pi pi-info-circle"
                    class="p-button-secondary p-button-flat hinweis-btn"
                    aviThrottledClick (throttledClick)="showHinweisWBErfassung()"
                ></button> -->
                </div>
                <div class="grid m-0">
                    <div class="col grid m-0">
                        <button
                            pButton
                            type="button"
                            [label]="'VWBP.PUBLIC.FORM.C_ERFASSEN_BUTTON' | translate"
                            aviThrottledClick
                            (throttledClick)="createFormularC()"
                            class="w-100p-m mr-2 p-button-primary"
                        ></button>

                        <button
                            pButton
                            type="button"
                            [label]="'VWBP.PUBLIC.FORM.T_ERFASSEN_BUTTON' | translate"
                            aviThrottledClick
                            (throttledClick)="createFormularT()"
                            class="w-100p-m p-button-primary"
                        ></button>
                    </div>

                    <div class="col grid justify-content-end m-0">
                        <button
                            *ngIf="canRtAbgeben()"
                            pButton
                            type="button"
                            [label]="'VWBP.PUBLIC.RT_VERANTWORTLICHKEIT_ABGEBEN_BUTTON' | translate"
                            icon="pi pi-user-minus"
                            class="w-100p-m m p-button-secondary"
                            aviThrottledClick
                            (throttledClick)="startRechtstraegerAbgeben()"
                        ></button>
                        <button
                            *ngIf="canDeleteRechtstraegerDraft()"
                            pButton
                            type="button"
                            icon="pi pi-trash"
                            [label]="'VWBP.PUBLIC.RT_LOESCHEN_BUTTON' | translate"
                            aviThrottledClick
                            (throttledClick)="deleteRechtstraegerDraft()"
                            class="w-100p-m m ml-2 p-button-danger"
                        ></button>
                        <button
                            *ngIf="canDeleteRechtstraeger()"
                            pButton
                            type="button"
                            icon="pi pi-trash"
                            [label]="'VWBP.PUBLIC.RT_LOESCHEN_BUTTON' | translate"
                            aviThrottledClick
                            (throttledClick)="startDeleteRechtstraeger()"
                            class="w-100p-m m ml-2 p-button-danger"
                        ></button>
                        <button
                            *ngIf="enableTaskBearbeitungRueckgaengig()"
                            pButton
                            type="button"
                            [label]="'VWBP.PUBLIC.RT_BEARBEITUNG_RUECKGAENGIG_BUTTON' | translate"
                            icon="pi pi-undo"
                            aviThrottledClick
                            (throttledClick)="BearbeitungRueckgaengig()"
                            class="w-100p-m m ml-2 p-button-secondary p-button-outlined"
                        ></button>
                        <button
                            *ngIf="getFormularCount() > 0"
                            pButton
                            type="button"
                            [label]="'VWBP.PUBLIC.RT_ERFASSUNG_ABSCHLIESSEN_BUTTON' | translate"
                            icon="pi pi-check"
                            aviThrottledClick
                            (throttledClick)="ErfassungAbschliessen()"
                            class="w-100p-m m ml-2 p-button-success"
                        ></button>
                    </div>
                </div>
            </div>

            <div *ngIf="!isInBearbeitung() && (isCurrentVersion() || canRtAbgeben() || canDeleteRechtstraeger())" class="ui-g card-contents">
                <div class="w-100p">
                    <div class="grid  justify-content-end">
                        <button
                            *ngIf="ExportItems?.length === 1"
                            pButton
                            type="button"
                            [label]="'VWBP.COMMON.RT_AUSZUG_ERSTELLEN_BUTTON' | translate"
                            icon="pi pi-file-pdf"
                            aviThrottledClick
                            (throttledClick)="ExportItems[0].command()"
                            class="w-100p-m m  ml-2 p-button-secondary"
                        ></button>
                        <span *ngIf="ExportItems?.length > 1">
                            <button
                                pButton
                                type="button"
                                [label]="'VWBP.COMMON.RT_AUSZUG_ERSTELLEN_BUTTON' | translate"
                                icon="pi pi-file-pdf"
                                class="w-100p-m m  ml-2 p-button-secondary"
                                (click)="menuCreate.toggle($event)"
                            ></button>
                            <p-menu
                                #menuCreate
                                appendTo="body"
                                [popup]="true"
                                [model]="ExportItems"
                                [style]="{ minWidth: '18em', width: 'auto' }"
                            ></p-menu>
                        </span>
                        <button
                            pButton
                            *ngIf="isCurrentVersion()"
                            type="button"
                            [label]="'VWBP.PUBLIC.RT_INBEARBEITUNG_SETZEN_BUTTON' | translate"
                            icon="pi pi-pencil"
                            aviThrottledClick
                            (throttledClick)="InBearbeitungSetzen()"
                            class="w-100p-m m ml-2"
                        ></button>
                        <button
                            *ngIf="canRtAbgeben()"
                            pButton
                            type="button"
                            [label]="'VWBP.PUBLIC.RT_VERANTWORTLICHKEIT_ABGEBEN_BUTTON' | translate"
                            icon="pi pi-user-minus"
                            class="w-100p-m m  ml-2 p-button-secondary"
                            aviThrottledClick
                            (throttledClick)="startRechtstraegerAbgeben()"
                        ></button>
                        <button
                            *ngIf="canDeleteRechtstraeger()"
                            pButton
                            type="button"
                            icon="pi pi-trash"
                            [label]="'VWBP.PUBLIC.RT_LOESCHEN_BUTTON' | translate"
                            aviThrottledClick
                            (throttledClick)="startDeleteRechtstraeger()"
                            class="w-100p-m m ml-2 p-button-danger"
                        ></button>
                    </div>
                </div>
            </div>
        </avi-core-view-panel>
    </avi-core-page>

    <avi-core-dbg [m]="SelectedVersion" t="SelectedVersion"> </avi-core-dbg>

    <!-- EDIT RECHTSTRÄGER DIALOG -->
    <avi-core-dialog
        #editRechtstraegerFormDialog
        identifier="editRechtstraegerFormDialog"
        [showCloseIcon]="false"
        [closeOnEscape]="false"
    >
        <vwbp-public-rechtstraegerdetail-form
            #editRechtstraegerForm
            [card]="true"
            [model-id]="null"
            [readonly]="false"
            (onSavedSuccessful)="onRechtstraegerSavedSuccessful($event)"
            [action-buttons]="formActionButtons"
        >
        </vwbp-public-rechtstraegerdetail-form>
    </avi-core-dialog>

    <avi-core-dialog
        #deleteRechtstraegerFormDialog
        identifier="deleteRechtstraegerFormDialog"
        [showCloseIcon]="false"
        [closeOnEscape]="false"
    >
        <vwbp-public-rechtstraeger-loeschen-form
            #deleteRechtstraegerForm
            (onSavedSuccessful)="onRechtstraegerDeletedSuccessful($event)"
            [redirect-after-save]="null"
            [redirect-after-delete]="null"
            [action-buttons]="deleteFormActionButtons"
        >
        </vwbp-public-rechtstraeger-loeschen-form>
    </avi-core-dialog>

    <p-confirmDialog key="k1"></p-confirmDialog>
    <p-confirmDialog key="k2"></p-confirmDialog>
</div>
